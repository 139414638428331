<div class="ui-g ui-fluid" style="min-height: calc(80vh);">
    <div class="ui-g-12">
        <div class="card card-w-title">
            <div class="ui-g">
                <div class="ui-g-3">
                    <h1 id="serviceTaskNum">Service Task: {{serviceTasksDetails?.serviceTaskId}}</h1>
                    <!-- <p *ngIf="serviceTasksDetails?.baseBuildSpf !== null">{{serviceTasksDetails?.baseBuildSpf}}</p>
                    <p id="serviceTaskBaseBuild">{{serviceTasksDetails?.baseBuild}}</p> -->
                    
                    <div *ngIf="app.sharedData.visibility.createServivceTask.subjectLine">
                      <div class="ui-inputgroup">
                          <span *ngIf="!app.updateSTCaptionProcess.updateSTCaption">{{serviceTasksDetails.updatedCaption}}
                              <i id="editSTCaption" class="material-icons" 
                              (click)="app.updateSTCaptionStart()" pTooltip="Edit" style="display: inline-block; cursor: pointer;">edit</i>
                          </span>
                          <span class="md-inputfield" *ngIf="app.updateSTCaptionProcess.updateSTCaption">
                              <input pInputText id="inputSTCaption" type="text" [(ngModel)]="serviceTasksDetails.updatedCaption" [disabled]="app.updateSTCaptionProcess.updateSTCaptionInProgress"
                              (click)="app.updateSTCaptionStart()" placeholder="Enter Service Task Subject Line">
                          </span>
                          <span class="ui-inputgroup-addon" *ngIf="app.updateSTCaptionProcess.updateSTCaption">
                            <i id="updateSTCaption" class="material-icons" 
                              *ngIf="app.updateSTCaptionProcess.updateSTCaption && !app.updateSTCaptionProcess.updateSTCaptionInProgress" 
                              (click)="app.updateSTCaption(serviceTasksDetails)" pTooltip="Update" style="display: inline-block; cursor: pointer;">check_box</i>
                            <i id="cancelSTCaption" class="material-icons" 
                              *ngIf="app.updateSTCaptionProcess.updateSTCaption && !app.updateSTCaptionProcess.updateSTCaptionInProgress"
                              (click)="app.updateSTCaptionCancel(serviceTasksDetails)" pTooltip="Cancel" style="display: inline-block; cursor: pointer;">close</i>
                            <p-progressSpinner id="stCaptionUpdateSpinner" *ngIf="app.updateSTCaptionProcess.updateSTCaptionInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                          </span>
                      </div>
                      <div class="ui-g-12" id="stCaptionUpdateError" *ngIf="app.updateSTCaptionProcess.updateSTCaptionError !== ''" class="message message-error">
                        {{app.updateSTCaptionProcess.updateSTCaptionError}}
                      </div>
                    </div>

                    <div class="ui-g-12" *ngIf="serviceTasksDetails?.baseBuild === null && serviceTasksDetails.abandoned === false && app.sharedData.visibility.serviceTask.updateBaseBuild" style="padding-left: 0px;width:'400px'">
                      <div class="ui-g-8">
                        <p-dropdown id="selectBaseBuild" [options]="updateBaseBuildProcess.releasesSelectItems" placeholder="Select Base Build"
                              [(ngModel)]="updateBaseBuildProcess.selectedRelease" filter="true" filterBy="label" [virtualScroll]="true" [style]="{width: '75%'}" [panelStyle] = "{width:'400px'}">
                              <ng-template let-item pTemplate="selectedItem">
                                <div>
                                  <div style="font-size: x-small">Family: {{item.value.spfTag}}</div>
                                  <div>{{item.value.softwareProductBuild}}</div>
                                </div>
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                <div style="text-indent: -5px;">
                                  <div style="width: 33.3333%; display: inline-block; padding: 5px; overflow-wrap: break-word;">
                                    {{item.value.spfTag}}
                                  </div>
                                  <div style="width: 66.6667%; display: inline-block; padding: 5px; overflow-wrap: break-word;">
                                    {{item.value.softwareProductBuild}}
                                  </div>
                                </div>
                              </ng-template>
                        </p-dropdown>
                      </div>              
                      <div class="ui-g-4">
                        <p-progressSpinner *ngIf="updateBaseBuildProcess.updatingRelease || updateBaseBuildProcess.loadingReleases" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        <button pButton id="baseBuildUpdateButton" type="button" *ngIf="!updateBaseBuildProcess.updatingRelease 
                                                            && !updateBaseBuildProcess.loadingReleases" 
                                [disabled]="updateBaseBuildProcess.selectedRelease === undefined" (click)="onBaseBuildUpdateAction()" label="Update"></button>
                      </div>
                        <div class="ui-g-12 message message-error" *ngIf="updateBaseBuildProcess.errorMessage !== ''"  style="padding-top: 0px;">
                          {{updateBaseBuildProcess.errorMessage}}
                        </div>
                    </div>
                    <p id="serviceTaskAbandonedStatus" *ngIf="serviceTasksDetails.abandoned">Status: Abandoned<span *ngIf="serviceTasksDetails.abandonedByUid !== null" > (by <b>{{serviceTasksDetails?.abandonedByUid}}</b>)</span></p>
                    <p id="serviceTaskActiveStatus" *ngIf="serviceTasksDetails.abandoned == false">Status: Active</p>
                    <button *ngIf="app.sharedData.visibility.serviceTask.abandonTask && serviceTasksDetails.abandoned == false" type="button" pButton id="abandonServiceTaskButton" label="Abandon Task" [style]="{'width': '150px'}" (click)="serviceTasksDetails.displayConfirm=true"></button>
                    <p-progressSpinner *ngIf="app.sharedData.service.common.abandonInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                </div>
                <div class="ui-g-9">
                    <p-steps id="stSteps" [model]="stepsItems" [(activeIndex)]="activeIndex" [readonly]="false"></p-steps>
                </div>     
                
                <!--Update Base Build-->
                <div class="card" *ngIf="updateBaseBuildProcess.displayForm">
                  <p-dialog header="Update Base Build" [(visible)]="updateBaseBuildProcess.displayForm" modal="modal"
                  showEffect="fade" [style]="{width: '550px'}">
                    <div class="ui-g">
                        <div class="ui-g-12">
                        <p>Are you sure to update the Base Build to {{updateBaseBuildProcess.selectedRelease?.softwareProductBuild}}?</p>
                        </div>
                    </div>
                    <p-footer>
                      <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <button pButton id="updateServiceTaskBaseBuildButton" type="button" (click)="updateServiceTaskBaseBuild()" label="Yes"></button>
                        <button pButton id="noUpdateServiceTaskBaseBuildBButton" type="button" (click)="updateBaseBuildProcess.displayForm=false" label="No"></button>
                      </div>
                    </p-footer>
                  </p-dialog>
                </div>
            </div>
        </div>


        <div class="ui-g-12 ui-lg-3 ui-g-nopad">
        <!-- Left Side -->
            <div class="card card-w-title">
                <h1 id="summaryHeader">Summary:</h1>
                <p id="baseServiceTaskNum" *ngIf = "serviceTasksDetails.hasParent"><b>Base Service Task: </b>
                  <span (click)="showParent()"
                      style="cursor: pointer; color: blue; text-decoration: underline;">
                    {{serviceTasksDetails.parentServiceTaskID}}
                  </span>
                </p>
                <p id="SPF" *ngIf="serviceTasksDetails?.baseBuildSpf !== null"><b>SPF: </b>{{serviceTasksDetails?.baseBuildSpf}}</p>
                <p id="SpfTag" *ngIf="serviceTasksDetails?.baseBuildSpfTag !== null && serviceTasksDetails?.baseBuildSpfTag !== undefined"><b>SPF Tag: </b>{{serviceTasksDetails?.baseBuildSpfTag}}</p>
                <p id="spBuild" *ngIf="serviceTasksDetails?.baseBuild !== null"><b>SP Build: </b>{{serviceTasksDetails?.baseBuild}}</p>
                <p id="spTag" *ngIf="serviceTasksDetails?.baseBuildTag !== null"><b>SP Tag: </b>{{serviceTasksDetails?.baseBuildTag}}</p>
                <div *ngIf="serviceTasksDetails?.createdUsingSI">
                  <b id="baseImagesHeader">Base Images: </b>
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <p-table id="baseImageTable" [value]="serviceTasksDetails.serviceTaskImageBuilds">
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td colspan="1">No Base Image(s).</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                          <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                            <td>{{rowData.softwareImageBuild}}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </div>
                  </div>
                </div>
                <div style="border-bottom: grey 1px double;"></div>
                <p id="ShippedSPBuild" *ngIf="serviceTasksDetailsLatestReleases?.shippedRelease !== null"><b>Shipped SP Build: </b>{{serviceTasksDetailsLatestReleases?.shippedRelease}}</p>
                <p id="shippedSPFTag" *ngIf="serviceTasksDetailsLatestReleases?.shippedSpfTag !== null"><b>Shipped SPF Tag: </b>{{serviceTasksDetailsLatestReleases?.shippedSpfTag}}</p>
                <p id="shippedSPTag" *ngIf="serviceTasksDetailsLatestReleases?.shippedBuildTag !== null"><b>Shipped SP Tag : </b>{{serviceTasksDetailsLatestReleases?.shippedBuildTag}}</p>
                <p id="totalReleases"><b>Total Releases: </b>{{serviceTasksDetailsLatestReleases?.shippedReleases === null? 0 : serviceTasksDetailsLatestReleases?.shippedReleases}}</p>
                <p id="master"><b>Master: </b>{{serviceTasksDetails.master? 'Yes':'No'}}</p>
                <p id="autobuild" *ngIf="this.app.sharedData.userInfo.info.internal"><b>Autobuild: </b>{{serviceTasksDetails.autobuild? 'Yes':'No'}} <a *ngIf="(serviceTasksDetails.master && this.app.sharedData.userInfo.isMasterServiceTaskManager) || (!serviceTasksDetails.master && this.app.sharedData.userInfo.info.internal)" 
                  (click)="onClickAutobuild(serviceTasksDetails.autobuild)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block; margin-left: 5px;">({{serviceTasksDetails.autobuild? 'Disable':'Enable'}})</a> 
                </p>
                <p id="isImageFormatAddOn" *ngIf="this.app.sharedData.userInfo.info.internal"><b>SoftwareFormat Addons: </b>{{serviceTasksDetails.isImageFormatAddOn? 'Yes':'No'}} 
                  <a *ngIf="this.app.sharedData.userInfo.info.internal" (click)="onClickSoftwareFormatAddons(serviceTasksDetails.isImageFormatAddOn)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block; margin-left: 5px;">({{serviceTasksDetails.isImageFormatAddOn ? 'Disable':'Enable'}})</a> 
                  <p-progressSpinner *ngIf="updateSWAddonsProcess.imageAddonsInprogress" [style]="{width: '20px', height: '20px', margin: '2px 2px -5px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                </p>
                <p id="imageFormatAddOnErrMsg" class="ui-g-12" *ngIf="updateSWAddonsProcess.errorMessage !== ''" class="message message-error">{{updateSWAddonsProcess.errorMessage}}</p>
                <p id="createDate"><b>Created: </b>{{serviceTasksDetails.requestedDate}}</p>
                <p id="requester"><b>Requester: </b>{{serviceTasksDetails.requester}}</p>
                <div *ngIf="!app.sharedData.visibility.serviceTask.updateExternalId|| serviceTasksDetails.abandoned == true">
                  <p id="fixedExternalId" *ngIf="serviceTasksDetails?.requestSource == 'QPM' && serviceTasksDetails.requestAppId !==''"><b>External ID: </b>{{serviceTasksDetails.requestAppId}}</p>
                </div>
                <div id="externalId" *ngIf="app.sharedData.visibility.serviceTask.updateExternalId && serviceTasksDetails.abandoned == false && serviceTasksDetails?.requestSource == 'QPM'" class="ui-g">
                  <b>External ID:</b>
                  <div class="ui-g-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input id="enterExternalId" pInputText type="text" [(ngModel)]="serviceTasksDetails.updatedRequestedAppid" [disabled]="app.updateExternalIdProcess.updateExternalIdInProgress" 
                            (click)="app.updateExternalIdstart()">
                        </span>
                        <span class="ui-inputgroup-addon">
                          <i id="editExternalId" class="material-icons" 
                            *ngIf="!app.updateExternalIdProcess.updateExternalId"
                            (click)="app.updateExternalIdstart()" pTooltip="Edit" style="display: inline-block; cursor: pointer;">edit</i>
                          <i id="updateExternalId" class="material-icons" 
                            *ngIf="app.updateExternalIdProcess.updateExternalId && !app.updateExternalIdProcess.updateExternalIdInProgress" 
                            (click)="app.updateExternalId(serviceTasksDetails)" pTooltip="Update" style="display: inline-block; cursor: pointer;">check_box</i>
                          <i id="cancelExternalId" class="material-icons" 
                            *ngIf="app.updateExternalIdProcess.updateExternalId && !app.updateExternalIdProcess.updateExternalIdInProgress" 
                            (click)="app.updateExternalIdCancel(serviceTasksDetails)" pTooltip="Cancel" style="display: inline-block; cursor: pointer;">close</i>
                          <p-progressSpinner id="externalIdUpdateSpinner" *ngIf="app.updateExternalIdProcess.updateExternalIdInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        </span>
                    </div>
                    <div class="ui-g-12" id="externalIdError" *ngIf="app.updateExternalIdProcess.updateExternalIdError !== ''" class="message message-error">
                      {{app.updateExternalIdProcess.updateExternalIdError}}
                    </div>
                  </div>
                </div>
                <p *ngIf="serviceTasksDetails?.requestSource !== 'QPM'"><b>{{serviceTasksDetails.requestSource}}: </b>{{serviceTasksDetails.requestAppId}}</p>
                <div id="primaryOwnerFixed" *ngIf="!app.sharedData.visibility.serviceTask.updateOwner|| serviceTasksDetails.abandoned == true">
                  <p><b>Primary Owner: </b>{{serviceTasksDetails.primaryOwner}}</p>
                </div>
                <div *ngIf="app.sharedData.visibility.serviceTask.updateOwner && serviceTasksDetails.abandoned == false" class="ui-g">
                  <b id="primaryOwner">Primary Owner:</b>
                  <div class="ui-g-12">
                    <div class="ui-inputgroup">
                        <span class="md-inputfield">
                            <input pInputText id="inputPrimaryOwner" type="text" [(ngModel)]="serviceTasksDetails.updatedPrimaryOwner" [disabled]="app.updateOwnerProcess.updatePrimaryOwnerInProgress"
                            (click)="app.updatePrimaryOwnerStart()" placeholder="Enter Username">
                        </span>
                        <span class="ui-inputgroup-addon">
                          <i id="editPrimaryOwner" class="material-icons" 
                            *ngIf="!app.updateOwnerProcess.updatePrimaryOwner"
                            (click)="app.updatePrimaryOwnerStart()" pTooltip="Edit" style="display: inline-block; cursor: pointer;">edit</i>
                          <i id="updatePrimaryOwner" class="material-icons" 
                            *ngIf="app.updateOwnerProcess.updatePrimaryOwner && !app.updateOwnerProcess.updatePrimaryOwnerInProgress" 
                            (click)="app.updateServiceTaskOwnersPrimary(serviceTasksDetails)" pTooltip="Update" style="display: inline-block; cursor: pointer;">check_box</i>
                          <i id="cancelPrimaryOwner" class="material-icons" 
                            *ngIf="app.updateOwnerProcess.updatePrimaryOwner && !app.updateOwnerProcess.updatePrimaryOwnerInProgress" 
                            (click)="app.updatePrimaryOwnerCancel(serviceTasksDetails)" pTooltip="Cancel" style="display: inline-block; cursor: pointer;">close</i>
                          <p-progressSpinner id="primaryOnwerUpdateSpinner" *ngIf="app.updateOwnerProcess.updatePrimaryOwnerInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        </span>
                    </div>
                    <div class="ui-g-12" id="primaryOwnerUpdateError" *ngIf="app.updateOwnerProcess.updatePrimaryOwnerError !== ''" class="message message-error">
                      {{app.updateOwnerProcess.updatePrimaryOwnerError}}
                    </div>
                  </div>
                </div>
                <div *ngIf="!app.sharedData.visibility.serviceTask.updateOwner || serviceTasksDetails.abandoned == true">
                  <p id="secondaryOwnerFixed"><b>Secondary Owner: </b>{{serviceTasksDetails.secondaryOwner}}</p>
                </div>
                <div *ngIf="app.sharedData.visibility.serviceTask.updateOwner && serviceTasksDetails.abandoned == false" class="ui-g">
                  <b id="secondaryOnwerValue">Secondary Owner:</b>
                   <div *ngIf="app.sharedData.visibility.serviceTask.updateOwner" class="ui-g-12">
                    <div class="ui-inputgroup">
                      <span class="md-inputfield">
                        <input pInputText id="updateSecondaryOwner" type="text" [(ngModel)]="serviceTasksDetails.updatedSecondaryOwner" [disabled]="app.updateOwnerProcess.updateSecondaryOwnerInProgress"
                        (click)="app.updateSecondaryOwnerStart()" placeholder="Enter Username">
                      </span>
                      <span class="ui-inputgroup-addon">
                        <i id="editSecondaryOwner" class="material-icons" 
                          *ngIf="!app.updateOwnerProcess.updateSecondaryOwner"
                          (click)="app.updateSecondaryOwnerStart()" pTooltip="Edit" style="display: inline-block; cursor: pointer;">edit</i>
                        <i id="updateSecondaryOwner" class="material-icons" 
                          *ngIf="app.updateOwnerProcess.updateSecondaryOwner && !app.updateOwnerProcess.updateSecondaryOwnerInProgress" 
                          (click)="app.updateServiceTaskOwnersSecondary(serviceTasksDetails)" pTooltip="Update" style="display: inline-block; cursor: pointer;">check_box</i>
                        <i id="cancelSecondaryOwner" class="material-icons" 
                          *ngIf="app.updateOwnerProcess.updateSecondaryOwner && !app.updateOwnerProcess.updateSecondaryOwnerInProgress" 
                          (click)="app.updateSecondaryOwnerCancel(serviceTasksDetails)" pTooltip="Cancel" style="display: inline-block; cursor: pointer;">close</i>
                        <p-progressSpinner id="updateSecondaryOwnerSpinner" *ngIf="app.updateOwnerProcess.updateSecondaryOwnerInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                      </span>
                    </div>
                    <div class="ui-g-12" id="updateSecondaryOwnerError" *ngIf="app.updateOwnerProcess.updateSecondaryOwnerError !== ''" class="message message-error">
                      {{app.updateOwnerProcess.updateSecondaryOwnerError}}
                    </div>
                  </div>
                </div>                
                <div *ngIf="app.sharedData.visibility.serviceTask.updateWatcher && serviceTasksDetails.abandoned == false" class="ui-g" style="padding: 10px 0;">
                  <b>Watchers:</b>
                  <a *ngIf="!serviceTasksDetails.isWatcher" (click)="addWatcher(app.sharedData.userInfo.username)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block; margin-left: 5px;">
                    Start watching
                  </a>
                  <a *ngIf="serviceTasksDetails.isWatcher" (click)="deleteWatcher(app.sharedData.userInfo.username)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block; margin-left: 5px;">
                    Stop watching
                  </a>
                  <p-progressSpinner *ngIf="updateWatcherProcess.updateInProgress" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                  <i id="editWatchers" class="material-icons" (click)="onUpdateWatcherClick()" pTooltip="Update Watchers" 
                      style="display: inline-block; cursor: pointer;">edit</i>
                </div>
                <div style="display: inline-block; padding-bottom: 10px; vertical-align: middle; margin-right: 5px;">
                  <b id="customerList">Customers:</b>
                </div>
                <i id="addCustomerButton" *ngIf="app.sharedData.visibility.serviceTask.addCustomer === true && serviceTasksDetails.abandoned == false" class="pi pi-user-plus" pTooltip="Add Customers" 
                  (click)="onAddCustomerClick(serviceTasksDetails)" style="cursor: pointer; font-size: 1.2rem"></i>
                <i id="deleteCustomerButton" *ngIf="app.sharedData.visibility.serviceTask.addCustomer === true && serviceTasksDetails.abandoned == false" class="pi pi-user-minus" pTooltip="Delete Customers" 
                  (click)="onDeleteCustomerClick(serviceTasksDetails)" style="cursor: pointer; font-size: 1.2rem"></i>
                <div class="ui-g">
                  <div class="ui-g-12">
                    <p-table id="customerTable" [value]="serviceTasksDetails.companies">
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td id="noCustomerSelected" colspan="1">No Customers Selected.</td>
                        </tr>
                      </ng-template>
                      <ng-template id="customerTableBody" pTemplate="body" let-rowData>
                        <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                          <td>{{rowData.companyName}}</td>
                        </tr>
                      </ng-template>
                    </p-table>
                  </div>
                </div>
                <!--p>Expires: {{serviceTasksDetails.requestedDate}}</p-->
                <!--p>Owner: {{owner}}</p-->
                <div>
                  <b id="histroyCr">History CRs: </b>
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <p-table id="crHistoryTable" [value]="serviceTasksDetails.historyCRs">
                          <ng-template pTemplate="header" let-columns>
                              <tr>
                                <th id="stRelease" pSortableColumn="productBuild">
                                  ST Release
                                  <p-sortIcon field="productBuild"></p-sortIcon>
                                </th>
                                <th id="crHeader">
                                  CRs
                                </th>
                                <th style="width:35px">
                                </th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td colspan="3">None.</td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                              <tr *ngIf="rowData.productBuild !== null">
                                  <td>{{rowData.productBuild}}</td>
                                  <td>
                                    {{rowData.consolidatedCRs.slice(0, 5).join(', ')}}
                                    <span *ngIf="rowData.consolidatedCRs?.length === 0">Debug Build</span>
                                    <span *ngIf="rowData.consolidatedCRs.length > 5">
                                      ...
                                      <i id="seeMoreRelasedCR" class="pi pi-info-circle" (click)="app.viewAllCRs(rowData.serviceTaskId, rowData.consolidatedCRs)" pTooltip="See More" style="cursor: pointer; font-size: 1.2rem"></i>
                                    </span>
                                  </td>
                                  <td>
                                    <i id="openReleaseCrTable" class="ui-icon-remove-red-eye" (click)="app.viewReleaseCRs(rowData.serviceTaskId, null)" pTooltip="Release CRs" style="cursor: pointer; font-size: 1.2rem">
                                    </i>
                                  </td>
                              </tr>
                          </ng-template>
                      </p-table>
                    </div>
                  </div>
                </div>
                <div>
                  <b id="releaseCRs">Release CRs: </b>
                  <span *ngIf="app.sharedData.visibility.serviceTask.crDelta == true" (click)="showDeltaCRsDialog()" pTooltip="CR Delta" style="cursor: pointer; font-size: 1.2rem" >
                    <img src="assets/images/CRdelta-icon.png" alt="CR Delta Icon" style="width: 15px; margin-left: 4px;margin-bottom: -3.5px;">
                  </span>
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <p-table [value]="serviceTasksDetails.releaseCRs" sortField="buildRequestedDate" [sortOrder]="-1">
                          <ng-template pTemplate="header" let-columns>
                              <tr>
                                <th id="release" pSortableColumn="spinNumber">
                                  Release
                                  <p-sortIcon field="spinNumber"></p-sortIcon>
                                </th>
                                <th id="releasedCrId">
                                  CRs
                                </th>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td colspan="2">None.</td>
                              </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                              <tr>
                                  <td>{{rowData.productBuild}}</td>
                                  <td>{{rowData.buildCRs.join(', ')}}</td>
                              </tr>
                          </ng-template>
                      </p-table>
                    </div>
                  </div>
                </div>        
            </div>
        </div>
        <div class="card" *ngIf="relatedServiceTaskPanel.displayForm">
          <p-dialog #parentStPanel header="Related Service Task" [(visible)]="relatedServiceTaskPanel.displayForm" modal="modal"
            showEffect="fade" [style]="{width: '80%'}">
            <div style="overflow-x: visible;">
              <table>
                    <tr>
                        <td *ngFor="let st of relatedServiceTaskPanel.parentServiceTask; let i = index" style="vertical-align: top;">
                          <div class="ui-g ui-g-nopad" *ngIf="st.serviceTasksDetails !== 'undefined' && st.serviceTasksDetailsLatestReleases !== 'undefined'" style="min-width: 350px;">
                                <div class="card card-w-title">
                                    <div>
                                      <b>Service Task: </b>
                                      <span (click)="goToServiceTask(st.serviceTasksDetails.serviceTaskId)"
                                          style="cursor: pointer; color: blue; text-decoration: underline;">
                                        {{st.serviceTasksDetails.serviceTaskId}}
                                      </span>
                                      <span *ngIf = "st.serviceTasksDetails.hasParent" style="margin-left: 10px;">
                                        <b>Base Service Task: </b>
                                        <span *ngIf="i !== relatedServiceTaskPanel.parentServiceTask.length - 1">
                                          {{st.serviceTasksDetails.parentServiceTaskID}}
                                        </span>
                                        <span *ngIf="i === relatedServiceTaskPanel.parentServiceTask.length - 1" (click)="loadParent(st.serviceTasksDetails)"
                                            style="cursor: pointer; color: blue; text-decoration: underline;">
                                          {{st.serviceTasksDetails.parentServiceTaskID}}
                                        </span>
                                      </span>
                                    </div>
                                    <p><b>SPF: </b>{{st.serviceTasksDetails?.baseBuildSpf}}</p>
                                    <p><b>SPF Tag: </b>{{st.serviceTasksDetails?.baseBuildSpfTag}}</p>
                                    <p><b>SP Build: </b>{{st.serviceTasksDetails?.baseBuild}}</p>
                                    <p><b>SP Tag: </b>{{st.serviceTasksDetails?.baseBuildTag}}</p>
                                    <div *ngIf="st.serviceTasksDetails?.createdUsingSI">
                                      <b>Base Images: </b>
                                      <div class="ui-g">
                                        <div class="ui-g-12">
                                          <p-table [value]="st.serviceTasksDetails.serviceTaskImageBuilds">
                                            <ng-template pTemplate="emptymessage">
                                              <tr>
                                                <td colspan="1">No Base Image(s).</td>
                                              </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData>
                                              <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                                                <td>{{rowData.softwareImageBuild}}</td>
                                              </tr>
                                            </ng-template>
                                          </p-table>
                                        </div>
                                      </div>
                                    </div>
                                    <p><b>Shipped SP Build: </b>{{st.serviceTasksDetailsLatestReleases?.shippedRelease}}</p>
                                    <p><b>Shipped SPF Tag: </b>{{st.serviceTasksDetailsLatestReleases?.shippedSpfTag}}</p>
                                    <p><b>Shipped SP Tag: </b>{{st.serviceTasksDetailsLatestReleases?.shippedBuildTag}}</p>
                                    <p><b>Total Releases: </b>{{st.serviceTasksDetailsLatestReleases?.shippedReleases === null ? 0 : st.serviceTasksDetailsLatestReleases?.shippedReleases}}</p>
                                    <p><b>Master: </b>{{st.serviceTasksDetails?.master? 'Yes':'No'}}</p>
                                    <p><b>Created: </b>{{st.serviceTasksDetails?.requestedDate}}</p>
                                    <p><b>Requester: </b>{{st.serviceTasksDetails?.requester}}</p>
                                    <p *ngIf="st.serviceTasksDetails?.requestSource === 'QPM'"><b>External ID: </b>{{st.serviceTasksDetails.requestAppId}}</p>
                                    <p *ngIf="st.serviceTasksDetails?.requestSource !== 'QPM'"><b>{{st.serviceTasksDetails.requestSource}}: </b>{{st.serviceTasksDetails.requestAppId}}</p>
                                    <p><b>Primary Owner: </b>{{st.serviceTasksDetails?.primaryOwner}}</p>
                                    <p><b>Secondary Owner: </b>{{st.serviceTasksDetails?.secondaryOwner}}</p>
                                    <div style="display: inline-block; padding-bottom: 10px; vertical-align: middle; margin-right: 5px;">
                                      <b>Customers:</b>
                                    </div>
                                    <div class="ui-g">
                                      <div class="ui-g-12">
                                        <p-table [value]="st.serviceTasksDetails?.companies">
                                          <ng-template pTemplate="emptymessage">
                                            <tr>
                                              <td colspan="1">No Customers Selected.</td>
                                            </tr>
                                          </ng-template>
                                          <ng-template pTemplate="body" let-rowData>
                                            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                                              <td>{{rowData.companyName}}</td>
                                            </tr>
                                          </ng-template>
                                        </p-table>
                                      </div>
                                    </div>
                                    <div>
                                      <b>History CRs: </b>
                                      <div class="ui-g">
                                        <div class="ui-g-12">
                                          <p-table [value]="st.serviceTasksDetails?.historyCRs">
                                              <ng-template pTemplate="header" let-columns>
                                                  <tr>
                                                    <th pSortableColumn="spinNumber">
                                                      ST Release
                                                      <p-sortIcon field="spinNumber"></p-sortIcon>
                                                    </th>
                                                    <th>
                                                      CRs
                                                    </th>
                                                    <th style="width:35px">
                                                    </th>
                                                  </tr>
                                              </ng-template>
                                              <ng-template pTemplate="emptymessage">
                                                  <tr>
                                                      <td colspan="3">None.</td>
                                                  </tr>
                                              </ng-template>
                                              <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                  <tr>
                                                      <td>{{rowData.productBuild}}</td>
                                                      <td>
                                                        {{rowData.consolidatedCRs.slice(0, 5).join(', ')}}
                                                        <span *ngIf="rowData.consolidatedCRs.length > 5">
                                                          ...
                                                          <i class="pi pi-info-circle" (click)="app.viewAllCRs(rowData.serviceTaskId, rowData.consolidatedCRs)" pTooltip="See More" style="cursor: pointer; font-size: 1.2rem"></i>
                                                        </span>
                                                      </td>
                                                      <td>
                                                        <i class="ui-icon-remove-red-eye" (click)="app.viewReleaseCRs(rowData.serviceTaskId, null)" pTooltip="Release CRs" style="cursor: pointer; font-size: 1.2rem">
                                                        </i>
                                                      </td>
                                                  </tr>
                                              </ng-template>
                                          </p-table>
                                        </div>
                                      </div>
                                    </div>
                                    <div>
                                      <b>Release CRs: </b>
                                      <div class="ui-g">
                                        <div class="ui-g-12">
                                          <p-table [value]="st.serviceTasksDetails?.releaseCRs" sortField="buildRequestedDate" [sortOrder]="-1">
                                              <ng-template pTemplate="header" let-columns>
                                                  <tr>
                                                    <th pSortableColumn="spinNumber">
                                                      Release
                                                      <p-sortIcon field="spinNumber"></p-sortIcon>
                                                    </th>
                                                    <th>
                                                      CRs
                                                    </th>
                                                  </tr>
                                              </ng-template>
                                              <ng-template pTemplate="emptymessage">
                                                  <tr>
                                                      <td colspan="2">None.</td>
                                                  </tr>
                                              </ng-template>
                                              <ng-template pTemplate="body" let-rowData let-columns="columns">
                                                  <tr>
                                                      <td>{{rowData.productBuild}}</td>
                                                      <td>{{rowData.buildCRs.join(', ')}}</td>
                                                  </tr>
                                              </ng-template>
                                          </p-table>
                                        </div>
                                      </div>
                                    </div>    
                                </div>
                          </div>
                        </td>
                  </tr>
              </table>
            </div>
          </p-dialog>
        </div>
        <div class="card" *ngIf="serviceTasksDetails.displayConfirm">
          <p-dialog id="abandonTaskDialogBoxHeader" header="Abandon Service Task" [(visible)]="serviceTasksDetails.displayConfirm" modal="modal"
          showEffect="fade" [style]="{width: '550px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                <p id="abandonTaskConfirmation">Are you sure you want to abandon service task {{serviceTasksDetails.serviceTaskId}}?</p>
                <P>Once a service task is abandoned, no additional requests, CR commits or build downloads are possible.</P>
                </div>
            </div>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <button id="abandonTask" pButton type="button" (click)="app.abandonServiceTask(serviceTasksDetails)" label="Yes"></button>
                <button id="cancelAbandonTask" pButton type="button" (click)="serviceTasksDetails.displayConfirm=false" label="No"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="addDeleteCustomerProcess.displayForm">
          <p-dialog id="addDeleteCustomerDialogBoxHeader" header="{{addDeleteCustomerProcess.header}}" [(visible)]="addDeleteCustomerProcess.displayForm" modal="modal"
            showEffect="fade" [style]="{width: '550px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                  <p-listbox id="customerToAddDelete" [options]="addDeleteCustomerProcess.companies" [(ngModel)]="addDeleteCustomerProcess.selectedCompanies"
                    [multiple]="true" [checkbox]="true" [filter]="true" [style]="{width: '500px'}" optionLabel="companyName">
                  </p-listbox>
                </div>
            </div>
            <p-footer>
              <div class="ui-g">
                <div class="ui-g-12" id="addDeleteCustomerErrorMsg" *ngIf="addDeleteCustomerProcess.errorMessage !== ''" class="message message-error">
                  {{addDeleteCustomerProcess.errorMessage}}
                </div>
              </div>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <p-progressSpinner *ngIf="addDeleteCustomerProcess.loadCustomerInProgress 
                                          || addDeleteCustomerProcess.addCustomerInProgress 
                                          || addDeleteCustomerProcess.deleteCustomerInProgress !== 0" 
                      [style]="{width: '20px', height: '20px', margin: '-5px 5px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                </p-progressSpinner>
                <button pButton id="addDeleteCustomerSubmit" type="button" [disabled]="addDeleteCustomerProcess.loadCustomerInProgress  
                                                          || addDeleteCustomerProcess.selectedCompanies.length === 0  
                                                          || addDeleteCustomerProcess.addCustomerInProgress  
                                                          || addDeleteCustomerProcess.deleteCustomerInProgress !== 0" 
                      (click)="onaddDeleteServiceTaskCustomer()" label="Submit">
                </button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
      <!--Update Watchers-->
      <div class="card" *ngIf="updateWatcherProcess.displayForm">
        <p-dialog id="updateWatchersDialogBoxHeader" header="Update Watchers" [(visible)]="updateWatcherProcess.displayForm" modal="modal"
          showEffect="fade" [style]="{width: '600px'}">
          <div class="ui-g">
            <div class="ui-g-12">
              <div class="ui-g-9">
                <input pInputText id="updateWatcher" type="text" [(ngModel)]="updateWatcherProcess.userId" placeholder="Enter Username">
              </div>              
              <div class="ui-g-3">
                <p-progressSpinner *ngIf="updateWatcherProcess.updateInProgress || updateWatcherProcess.loadMembersInProgress" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                <button pButton id="addCommentButton" type="button" [disabled]="updateWatcherProcess.userId === '' || updateWatcherProcess.updateInProgress" 
                    (click)="addWatcher(updateWatcherProcess.userId)" label="Add" style="width: 80%; float: right;">
                </button>
              </div>
            </div>
            <div class="ui-g-12" *ngIf="updateWatcherProcess.errorMessage !== ''" class="message message-error">
              {{updateWatcherProcess.errorMessage}}
            </div>
            <div class="ui-g-12">
              <p-table id="watcherTable" [value]="serviceTasksDetails.watchers">
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th id="release" pSortableColumn="fullName">
                      Name
                      <p-sortIcon field="fullName"></p-sortIcon>
                    </th>
                    <th id="releasedCrId" pSortableColumn="uid">
                      UserID
                      <p-sortIcon field="uid"></p-sortIcon>
                    </th>
                    <th id="releasedCrId" style="width: 100px;">
                      Delete
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td id="noWatcherSelected" colspan="3">No Watchers.</td>
                  </tr>
                </ng-template>
                <ng-template id="watcherTableBody" pTemplate="body" let-rowData>
                  <tr [pSelectableRow]="rowData">
                    <td>{{rowData.fullName}}</td>
                    <td>{{rowData.uid}}</td>
                    <td style="width: 35px;">
                      <i id="removerWatcher" class="material-icons" pTooltip="Remove" (click)="deleteWatcher(rowData.uid)" style="display: inline-block; cursor: pointer;">close</i>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </p-dialog>
      </div>

      <!--CR Delta dialog section-->
      <div class="card" *ngIf="deltaCRsProcess.deltaCRsdisplayForm">
              <p-dialog id="deltaCRsDialogBoxHeader" header="Show CR Delta" [(visible)]="deltaCRsProcess.deltaCRsdisplayForm" modal="modal"
                showEffect="fade" [style]="{width: '500px'}" >
                <div class="ui-g">
      
                  <div class="ui-g-12" style="margin-top: -10px;">
                    <p-dropdown [options]="softwareTypes" [(ngModel)]="deltaCRsProcess.isImageBuild" placeholder="Select Software Item Type" [showClear]="true"></p-dropdown>
                  </div>
                  <div class="ui-g-12">
                    <span class="md-inputfield">
                      <input pInputText type="text" [(ngModel)]="deltaCRsProcess.newBuild" placeholder="Enter New Software Build Item" style="width: 94%;" >
                      <i class="ui-icon-cancel" id="newBuildItem" (click)="clearNewBuildInput()" pTooltip="Clear" style="float: right;"></i>
                    </span>
                  </div>
                  <div class="ui-g-12">
                    <span class="md-inputfield">
                      <input pInputText type="text" [(ngModel)]="deltaCRsProcess.oldBuild" placeholder="Enter Old Software Build Item" style="width: 94%;">
                      <i class="ui-icon-cancel" id="oldBuildItem" (click)="clearOldBuildInput()" pTooltip="Clear" style="float: right;"></i>
                    </span>
                  </div>
                
                </div>
      
            
                 <!-- Add Delta CR table-->
                 <div class="ui-g-12" id="deltaCRsSuccess" style="margin-top:12px">
                  
                    <p-table id ="deltaCRsTable" #deltaCRsTable [value]="deltaCRsList" style="width: 'auto'; margin-top:12px" 
                    sortField="crId" [sortOrder]="-1" [globalFilterFields]="['crId','']">
                    <ng-template pTemplate="caption">
                      <div style="display:inline-block">
        
                        <div class="ui-g-12">
                          <span class="md-inputfield">
                            <i class="pi pi-search"  style="float: left;margin: -2px;"></i>
                            <input #deltaCRsGlobalFilter id="deltaCRFilterInput" class="filter-clear" type="text" pInputText size="50" placeholder="  Filter  "
                            (input)="deltaCRsTable.filterGlobal($event.target.value, 'contains')" style="color:white ;width: 90%;">
                            <i class="ui-icon-cancel" id="clearDeltaCrSearchFilter" pTooltip="Clear Filter" (click)="deltaCRsGlobalFilter.value='';deltaCRsTable.filterGlobal('', 'contains')"
                            style="float: right; margin: -10px"></i>
                          </span>
                        </div>
                       
                      </div>
                  </ng-template>
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                            
                          <th pSortableColumn="crId">
                            CR Delta 
                            <p-sortIcon field="crId"></p-sortIcon>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="1">No CR Delta Found.</td>
                        </tr>
                    </ng-template>
                      <ng-template pTemplate="body" let-rowData>
                          <tr>
                              <td>{{rowData.crId}}</td>
                          </tr>
                      </ng-template>
                  </p-table>
                     
                </div> 
      
                <p-footer>
                  <div class="ui-g">
                    <div class="ui-g-12" id="deltaCRsErrorMsg" *ngIf="deltaCRsProcess.errorMessage !== ''" class="message message-error">
                      {{deltaCRsProcess.errorMessage}}
                    </div>
                  </div>
                  <p-progressSpinner *ngIf="deltaCRsProcess.loadResultsInProgress==true" [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                  <button pButton id="searchDeltaCRs" type="button" (click)="onDeltaCRsSearch($event)" label="Find CR Delta"
                  [disabled]="deltaCRsProcess.isImageBuild === undefined || deltaCRsProcess.newBuild === undefined || deltaCRsProcess.oldBuild === undefined ||
                  deltaCRsProcess.isImageBuild==null || deltaCRsProcess.newBuild === '' || deltaCRsProcess.oldBuild === '' " style="float: right;width: 130px;">
                  </button>
                </p-footer>
               
            
           
              </p-dialog>
      </div>

      <!--Add CR Comments-->
      <div class="card" *ngIf="addCommentProcess.displayAddComment">
          <p-dialog id="addCRCommentsDialogBoxHeader" header="Add Comment" [(visible)]="addCommentProcess.displayAddComment" modal="modal"
            showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
              <div class="ui-g-12">
                <label *ngIf="this.addCommentProcess.displayRejectConfirmation" for="crlistTextArea">Enter reason this CR is being rejected</label>
              </div>
              <div class="ui-g-12">
                <label>Comment:</label>
                <textarea id="crCommentText"  [(ngModel)]="addCommentProcess.commentToAdd" [cols]="30" pInputTextarea autoResize="autoResize" [style]="{'margin-top': '10px'}"  (ngModelChange)="commentValidation()"></textarea>
              </div>
            </div>
            <p *ngIf= "addCommentProcess.commentToAdd?.length > 0 &&  256 > addCommentProcess.commentToAdd?.length  "style="color: blue;">{{addCommentProcess.commentToAdd?.length}}/255</p>
            <p *ngIf="app.sharedData.visibility.serviceTask.commentWarning" style="color: #F7921E;">Warning: Comments entered are visible to external customers.</p>
            <p-footer>
              <div class="ui-g">
                <div class="ui-g-12" *ngIf="this.addCommentProcess.errorMessage !== ''" class="message message-error">
                  {{addCommentProcess.errorMessage}}
                </div>
              </div>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <button pButton id="addCommentButton" type="button" [disabled]="this.addCommentProcess.errorMessage !== '' || this.addCommentProcess.commentToAdd === ''" (click)="onAddComment()" label="Add Comment" [style]="{'width': '150px', 'margin-right' : '20px'}"></button>
              </div>
            </p-footer>
          </p-dialog>
      </div>
      <div class="card" *ngIf="addCommentProcess.displayViewAllComments">
        <p-dialog id="viewCrCommentsDialogBoxHeader" header="{{addCommentProcess.isGerrit? 'Gerrit':'CR'}} {{addCommentProcess.id}} Comments" [(visible)]="addCommentProcess.displayViewAllComments" modal="modal"
          showEffect="fade" [style]="{width: '800px'}">
          <div class="ui-g">
            <!-- Add table-->
            <p-table id="viewCRCommentsTable" [value]="addCommentProcess.cr.comments" [style]="{'width': 'auto'}">
              <ng-template pTemplate="header" let-columns>
                <tr>
                    <th id="comment">Comment</th>
                    <th id="username" style="width: 200px;">Username</th>
                    <th id="date" style="width: 170px;">Date</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData>
                  <tr>
                      <td>{{rowData.description}}</td>
                      <td>{{rowData.userId}}</td>
                      <td>{{rowData.creationDateTime}}</td>
                  </tr>
              </ng-template>
          </p-table>
          </div>
        </p-dialog>
      </div>

        <div class="ui-g-12 ui-lg-9 ui-g-nopad" style="padding-left: 10px">
        <!-- Right Side -->
            <router-outlet></router-outlet>
        </div>
      
      <!--Add Base Image-->
      <div class="card" *ngIf="addCustomBaseImageProcess.displayForm">
        <p-dialog id="addBaseImageDialogBox" header="{{(addCustomBaseImageProcess.isUpdate? 'Update Image ':'Add Image ') + addCustomBaseImageProcess.baseSoftwareImage}}" [(visible)]="addCustomBaseImageProcess.displayForm" (visibleChange)="addCustomBaseImageProcess.isUpdateForPassThrough=false" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
                <div class="ui-g-12" *ngIf="addCustomBaseImageProcess.isUpdateForPassThrough===false">
                    <div class="ui-g-3">Build Type</div>
                    <div class="ui-g-1">:</div>
                    <div class="ui-g-8">
                        <p-dropdown id="selectSIOption" [options]="addCustomBaseImageProcess.softwareImageType"
                            [(ngModel)]="addCustomBaseImageProcess.softwareImageTypeSelected" (onChange)="getLatestSIBuilds()" (keyup.enter)="getLatestSIBuilds()"
                            filter="true" [virtualScroll]="true" [style]="{width: '100%'}" appendTo="body">
                            <ng-template let-item pTemplate="selectedItem">
                            {{item.label}}
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                            {{item.label}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>
                <div class="ui-g-12" *ngIf="addCustomBaseImageProcess.isUpdateForPassThrough===false">
                    <div class="ui-g-3">Base SI Build</div>
                    <div class="ui-g-1">:</div>
                    <div class="ui-g-8" *ngIf="addCustomBaseImageProcess.softwareImageTypeSelected === 'default'">
                        <input type="text" pInputText [(ngModel)]="addCustomBaseImageProcess.baseSoftwareImageBuild" [disabled]="true">
                    </div>
                    <div class="ui-g-8" *ngIf="addCustomBaseImageProcess.softwareImageTypeSelected !== 'default'">
                        <p-dropdown id="selectSIBuildOptions" [options]="addCustomBaseImageProcess.softwareImageBuildSelectItem" placeholder="Select SI Build"
                            [(ngModel)]="addCustomBaseImageProcess.softwareImageBuildSelected" filter="true" [virtualScroll]="true" [style]="{width: '100%'}" appendTo="body">
                            <ng-template let-item pTemplate="selectedItem">
                            {{item.label}}
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                            {{item.label}}
                            </ng-template>
                        </p-dropdown>
                    </div>
                </div>

                <!--Region For CPUCP  Start-->
                <div class="ui-g-12" *ngIf="addCustomBaseImageProcess.isPassThroughBuildSystem">
                  <div class="ui-g-3">Git Branch</div>
                  <div class="ui-g-1">:</div>
                  <div class="ui-g-8">
                    <textarea id="gitBranch"  [(ngModel)]="addCustomBaseImageProcess.gitBranch" [cols]="30" [rows]="1" pInputTextarea autoResize="autoResize" ></textarea>
                  </div>
                </div>
                <div class="ui-g-12" *ngIf="addCustomBaseImageProcess.isPassThroughBuildSystem">
                  <div class="ui-g-3">Git Url</div>
                  <div class="ui-g-1">:</div>
                  <div class="ui-g-8">
                    <textarea id="gitUrl"  [(ngModel)]="addCustomBaseImageProcess.gitUrl"  [cols]="30" [rows]="1" pInputTextarea autoResize="autoResize"></textarea>
                  </div>
                </div>
                <!--Region For CPUCP  End-->

            </div>
            <p-footer>
            <div class="ui-g">
                <div class="ui-g-12" *ngIf="addCustomBaseImageProcess.error !== ''" class="message message-error">
                {{addCustomBaseImageProcess.error}}
                </div>
            </div>
            <p-progressSpinner *ngIf="addCustomBaseImageProcess.addImageInProgress || addCustomBaseImageProcess.loadingBuild" 
                                [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            <button *ngIf="!addCustomBaseImageProcess.isPassThroughBuildSystem" pButton id="triageAddBaseImagesButton" type="button" [disabled]="addCustomBaseImageProcess.softwareImageBuildSelected === undefined && addCustomBaseImageProcess.softwareImageTypeSelected !== 'default'" label="{{addCustomBaseImageProcess.isUpdate? 'Update Image ':'Add Image '}}" (click)="addCustomeBaseImageCallback()" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
            <button *ngIf="addCustomBaseImageProcess.isPassThroughBuildSystem" pButton id="triageAddBaseImagesButton" type="button"  [disabled]="(addCustomBaseImageProcess.softwareImageBuildSelected === undefined && addCustomBaseImageProcess.softwareImageTypeSelected !== 'default')||(addCustomBaseImageProcess.gitBranch ===null || addCustomBaseImageProcess.gitBranch ==='') || (addCustomBaseImageProcess.gitUrl ===null || addCustomBaseImageProcess.gitUrl==='')" label="{{addCustomBaseImageProcess.isUpdateForPassThrough? 'Update Image ':'Add Image '}}" (click)="addCustomeBaseImageCallback()" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
            </p-footer>
        </p-dialog>
      </div>
    </div>
</div>