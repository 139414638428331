<div class="layout-wrapper" [ngClass]="{'layout-compact':layoutCompact}" (click)="onLayoutClick()">

    <div #layoutContainer class="layout-container"
         [ngClass]="{'menu-layout-static': !isOverlay(),
            'menu-layout-overlay': isOverlay(),
            'layout-menu-overlay-active': overlayMenuActive,
            'menu-layout-horizontal': isHorizontal(),
            'menu-layout-slim': isSlim(),
            'layout-menu-static-inactive': staticMenuDesktopInactive,
            'layout-menu-static-active': staticMenuMobileActive}">
        
            <app-topbar></app-topbar>

        <div class="layout-menu" [ngClass]="{'layout-menu-dark':darkMenu}" (click)="onMenuClick($event)">
            <app-inline-profile *ngIf="profileMode=='inline'&&!isHorizontal()"></app-inline-profile>
            <app-menu *ngIf="!pklaCheckFailed"></app-menu>
        </div>

        <div class="layout-main">
            <!--app-breadcrumb></app-breadcrumb-->
            <p-messages [(value)]="maintanenceMessage" [style]="{'width': '100%', 'margin-top': '-1px', 'margin-left': '-1px;','text-align': 'center'}"></p-messages>
            <p-messages [(value)]="errorMessages" [style]="{'width': '100%', 'margin-top': '-3px', 'margin-left': '-1px;','text-align': 'center'}"></p-messages>
            <div class="layout-content">
                <div class="ui-g">
                    <div class="ui-g-12" *ngIf="errorMessage !== ''">
                        <div class="card card-w-title" style="color: brown; border-radius: 15px; padding-bottom: 15px; margin-bottom: 0px;">
                        <div style="float: right; cursor:pointer;">
                            <i class="ui-icon-cancel" pTooltip="Close" (click)="onErrorClose($event)"></i>
                        </div>
                        <span>{{errorMessage}}</span>
                        </div>
                    </div>
                </div>
                <router-outlet *ngIf="!pklaCheckFailed"></router-outlet>
                <div *ngIf="dialog.displayMessageBox">
                    <p-dialog [(visible)]="dialog.displayMessageBox" [style]="{width: '500px'}">
                      <p-header>
                          {{dialog.header}}
                      </p-header>
                      <div *ngFor="let line of dialog.content">
                        {{line}}
                      </div>
                      <div *ngIf="sharedData.appInfo.isElectronMode && qpmLogLocation!=undefined">
                        <div  (click)="onOpenFolderClick(qpmLogLocation)"  id="openQPM3Logs" 
                        style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                         Click Here   
                        </div>
                         to open Qualcomm Package Manager logs
                      </div>

                      <p-footer>
                        <div class="ui-dialog-buttonpane ui-helper-clearfix">
                          <button pButton type="button" (click) = "hideMessageBox()" label="{{dialog.ok}}"></button>
                        </div>
                      </p-footer>
                    </p-dialog>
                </div>
                <div *ngIf="aboutDialog.displayAboutBox">
                  <p-dialog [(visible)]="aboutDialog.displayAboutBox" [style]="{width: '500px'}">
                    <div style="text-align: center;">
                      <img src="assets/images/QPM96.png" alt="QPM"/>
                      <h3>Qualcomm Package Manager</h3>
                      <p>{{aboutDialog.versionStr}}</p>
                    </div>
                    <p-footer>
                      <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <button pButton type="button" (click) = "hideAboutBox()" label="Close"></button>
                      </div>
                    </p-footer>
                  </p-dialog>
                </div>
                <div *ngIf="supportDialog.showSupportDialog">
                  <p-dialog [(visible)]="supportDialog.showSupportDialog" [style]="{width: '500px'}">
                    <p-header>Create a support ticket</p-header>

                    <div class="ui-g-12">
                        <h4>Ticket Type</h4>
                        <p-dropdown id="ticket-type-input" [options]="supportDialog.ticketTypes" [(ngModel)]="supportDialog.selectedTicketType" [style]="{width: '450px'}"></p-dropdown>
                        <!--small id="username1-help">Enter your username to reset your password.</small-->
                    </div>

                    <div class="ui-g-12">
                      <h4>Subject</h4>
                      <input id="subject-input" pInputText [(ngModel)]="supportDialog.subject" [style]="{width: '450px'}"/>
                      <!--small id="username1-help">Main topic of support request</small-->
                    </div>

                    <div class="ui-g-12">
                      <h4>Description</h4>
                      <textarea id="description-input" rows="11" [style]="{width: '450px'}" [(ngModel)]="supportDialog.description" pInputTextarea></textarea>
                      <span *ngIf="supportDialog.description?.length > 16350" style="color: red;float: right;font-size: 12px;">{{supportDialog.description?.length}} of 16350 charaters </span>
                    </div>

                    <div class="ui-g-12">
                      <h4>Attachments</h4>
                          <div class="jiraFiledropzone" appDrag (files)="filesDroppedForJira($event)">
                              <div class="text-wrapper">
                                  <div class="centered">
                                      <span>Drop file to upload,or    
                                          <label>click to browse
                                          <input type="file" id="file" style="display: none"  (change)="filesDroppedForJira($event)" multiple>
                                          </label>
                                      </span>
                                  </div>
                              </div>
                          </div>
                     
                        
                                <p-table id="filesSelectedTableForJira" #filesSelectedTableForJira [value]="supportDialog.filesInfo" [rows]="4">
                                      <ng-template pTemplate="header" let-columns>
                                          <tr>
                                              <th style="width: 200px;">Filename</th>
                                              <th style="width: 100px;">File Size</th>
                                              <th style="width: 100px;">Status</th>
                                              <th style="width: 37px;"></th>
                                          </tr>
                                      </ng-template>
                                      <ng-template pTemplate="emptymessage">
                                          <tr>
                                              <td id="noDataFoundMsg" colspan="4">No files selected.</td>
                                          </tr>
                                      </ng-template>
                                      <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                                          <tr>
                                              <td> {{rowData.filename}}</td>
                                              <td>{{(rowData.filesize/1000000).toFixed(2)}}MB</td>
                                              <td>{{rowData.status}}</td>
                                              <td id="actionIcon">
                                                  <div class="action-icon">
                                                      <i class="pi pi-times" id="removeLocalFileForJira"  (click)="onRemoveLocalFileForJira(i)" pTooltip="Remove"></i>
                                                  </div>
                                              </td>
                                          </tr>
                                      </ng-template>
                                </p-table>
                        
                          
                    </div>

                    <div class="ui-g-12">
                      <h4 *ngIf="supportDialog.successful">Successfully created ticket: {{supportDialog.ticket}}</h4>
                      <a *ngIf="supportDialog.successful && sharedData.appInfo.isElectronMode" (click)="goToUrl(supportDialog.ticket_url)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                        {{supportDialog.ticket_url}} 
                      </a>
                      <a *ngIf="supportDialog.successful && !sharedData.appInfo.isElectronMode" href="{{supportDialog.ticket_url}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                        {{supportDialog.ticket_url}}
                      </a>
                      <h4 class="message message-error" *ngIf="!supportDialog.successful">{{supportDialog.error}}</h4>
                    </div>

                    <p-footer>
                      <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <div class="ui-g-12 message message-error" *ngIf="supportDialog.fileErrorMessage !== ''">
                          <span style="float: left;" id="FileUploadForJiraErrorMessage">{{supportDialog.fileErrorMessage}}</span>
                        </div>
                        <div class="ui-g-12" *ngIf="supportDialog.fileSuccessMessage !== ''">
                          <span style="float: left;" id="fileSuccessMessage">{{supportDialog.fileSuccessMessage}}</span>
                        </div>
                        <p-progressSpinner *ngIf="createJIRAInProgress"
                        [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                        </p-progressSpinner>
                        
                        <button *ngIf="!supportDialog.successful" pButton type="button" (click)="closeSupportTicketDialog()" label="Cancel"></button>
                        <button *ngIf="!supportDialog.successful" pButton type="button" (click)="createSupportTicket(supportDialog.issueType,supportDialog.projectKey,supportDialog.component)" [disabled]="supportDialog.fileErrorMessage!=='' || this.createJIRAInProgress || supportDialog.description?.length > 16350" label="Submit"></button>
                       <button *ngIf="supportDialog.successful" pButton type="button" (click)="closeSupportTicketDialog()" label="Close"></button>
                      </div>
                    </p-footer>

                  </p-dialog>
                </div>
                
                <div class="card" *ngIf="viewReleaseCrProcess.displayForm">
                  <p-dialog header="Service Task: {{viewReleaseCrProcess.serviceTaskID}} Release CRs" [(visible)]="viewReleaseCrProcess.displayForm" modal="modal"
                    showEffect="fade" [style]="{'width': '700px'}">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <p-table [value]="viewReleaseCrProcess.releaseCRs" sortField="buildRequestedDate" [sortOrder]="-1">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                  <th pSortableColumn="buildRequestedDate">
                                    Released Date
                                    <p-sortIcon field="buildRequestedDate"></p-sortIcon>
                                  </th>
                                  <th pSortableColumn="spinNumber">
                                    Release
                                    <p-sortIcon field="spinNumber"></p-sortIcon>
                                  </th>
                                  <th>
                                    CRs
                                  </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="3">None.</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                  <td>{{rowData.buildRequestedDate}}</td>
                                  <td>{{rowData.productBuild}}</td>
                                  <td>{{rowData.buildCRs.join(', ')}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </p-dialog>
                </div>
                <div class="card" *ngIf="viewAllCrProcess.displayForm">
                  <p-dialog header="Service Task: {{viewAllCrProcess.serviceTaskID}} CRs" [(visible)]="viewAllCrProcess.displayForm" modal="modal"
                    showEffect="fade" [style]="{'width': '300px'}">
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <p-table [value]="viewAllCrProcess.CRs">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                  <th pSortableColumn="">
                                    Change Request
                                    <p-sortIcon field=""></p-sortIcon>
                                  </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="1">None.</td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns">
                                <tr>
                                    <td>{{rowData}}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </p-dialog>
                </div>
            <!--NEW Modified CREATE SERVICE TASK Section  START-->
                <!-- <div class="card" *ngIf="createServiceTaskProcess.displayCreateRequest 
                                          && (createServiceTaskProcess.displayCreateForm
                                          || createServiceTaskProcess.displaySummary
                                          || createServiceTaskProcess.displayResponse)">
                  <p-dialog header="Create Service Task" [(visible)]="createServiceTaskProcess.displayCreateForm" modal="modal"
                    showEffect="fade" [style]="{width: '600px'}">
                    <div *ngIf="createServiceTaskProcess.isSalesforce && createServiceTaskProcess.caseNumber !== undefined">
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Case Number </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.caseNumber}}</span>
                        </div>
                      </div>
                      excluded_early <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer ID </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.custid}}</span>
                        </div>
                      </div> excluded_early
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.cust}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="!createServiceTaskProcess.baseReleasePreSelected">
                      <div class="ui-g" *ngIf="this.sharedData.visibility.software.showCustomerFilter && !createServiceTaskProcess.isSalesforce">
                          <div class="ui-g-3">
                            <b>Customer</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b>
                            <p-dropdown id="selectCustomer" [options]="customersSelectItems" (click)="onCustomerChange()" (keyup.enter)="onCustomerChange()" placeholder="Select Customer"
                              [(ngModel)]="selectedCustomer" filter="true" [virtualScroll]="true" [style]="{width: '87%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                            <i class="ui-icon-cancel" id="clearSTCustomerFilter" pTooltip="Clear Filter" (click)="onCustomerClear()" style="cursor: pointer;"></i>
                          </div>
                      </div>
                      <div class="ui-g">
                          <div class="ui-g-3">
                            <b>Project</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b>
                            <p-dropdown id="STProjectOptions" [options]="projectsSelectItems" (click)="onProjectChange()" (keyup.enter)="onProjectChange()" placeholder="Select Project"
                              [(ngModel)]="selectedProject" filter="true" [virtualScroll]="true" [style]="{width: '87%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                            <i class="ui-icon-cancel" id="clearSTProjectFilter" pTooltip="Clear Filter" (click)="onProjectClear()" style="cursor: pointer;"></i>
                            <i class="pi pi-exclamation-circle" *ngIf="this.sharedData.visibility.software.showCustomerFilter && !createServiceTaskProcess.isSalesforce" pTooltip="Please select customer to see project list." style="font-size: 1.7em; cursor: pointer;"></i>
                          </div>
                      </div>
                      <div class="ui-g">
                          <div class="ui-g-3">
                            <b>SPF</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b>
                            <p-dropdown id="STProductSPF" [options]="productFamilySelectItems" (click)="onProductFamilyChange()" (keyup.enter)="onProductFamilyChange()" placeholder="Select SPF"
                              [(ngModel)]="selectedProductFamily" filter="true" [virtualScroll]="true" [style]="{width: '87%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                            <i class="ui-icon-cancel" id="clearSTProductFamilyFilter" pTooltip="Clear Filter" (click)="onProductFamilyClear()" style="cursor: pointer;"></i>
                          </div>
                      </div>
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>SP</b>
                        </div>
                        <div class="ui-g-9">
                          <b>: </b> 
                          <p-dropdown id="STSelectSPOptions" [options]="productsSelectItems" (click)="onProductChange()" (keyup.enter)="onProductChange()" placeholder="Select SP"
                            [(ngModel)]="selectedProduct" filter="true" [virtualScroll]="true" [style]="{width: '87%'}">
                            <ng-template let-item pTemplate="selectedItem">
                              {{item.label}}
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                              {{item.label}}
                            </ng-template>
                          </p-dropdown>
                          <i class="ui-icon-cancel" id="clearSTProductFilter" pTooltip="Clear Filter" (click)="onProductClear()" style="cursor: pointer;"></i>
                        </div>
                      </div>
                      <div class="ui-g" *ngIf="!createServiceTaskProcess.addSoftwareImage">
                        <div class="ui-g-3">
                          <b>SP Build </b>
                        </div>
                        <div class="ui-g-9">
                          <b>: </b> 
                          <p-dropdown id="SPBuildReleaseOptions" [options]="releasesSelectItems" (click)="onReleaseChange()" (keyup.enter)="onReleaseChange()" placeholder="Select SP Build"
                            [(ngModel)]="selectedRelease" filter="true" [virtualScroll]="true" [style]="{width: '87%'}"  [panelStyle] = "{width:'400px'}">
                            <ng-template let-item pTemplate="selectedItem">
                              <div>
                                <div style="font-size: x-small">SPF Tag: {{item.value.spfTag !== null? item.value.spfTag : "None"}}</div>
                                <div><span style="font-size: x-small">SP Tag: </span> {{item.value.tag}}</div>
                              </div>
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                              <div style="text-indent: -5px;">
                                <div style="width: 62%; display: inline-block; padding: 5px; overflow-wrap: break-word;">
                                  {{item.value.spfTag !== null? item.value.spfTag : "None"}}
                                </div>
                                <div style="width: 33.3333%; padding: 5px; overflow-wrap: break-word; float: right;">
                                  {{item.value.tag}}
                                </div>
                              </div>
                            </ng-template>
                          </p-dropdown>
                          excluded_early <i class="ui-icon-search" id="searchCr" pTooltip="Search CRs"  (click)="startFindCrStatusProcess()" style="cursor: pointer;"></i>
                          <i class="ui-icon-cancel" id="clearSTReleaseFilter" pTooltip="Clear Filter" (click)="onReleaseClear()" style="cursor: pointer;"></i>
                          <p style="text-align: right;">
                            <b>
                              <div (click)="searchReleaseByImageProcess.displaySearchByImageDialog = true;searchReleaseByImageProcess.displaySearchResults=false;searchReleaseByImageProcess.imageBuild=undefined"
                              style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                              Find release from Image build
                              </div>
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.baseReleasePreSelected 
                                              && createServiceTaskProcess.baseSPF !== undefined">
                      <div class="ui-g-3">
                        <span><b>Base SPF </b></span>
                      </div>
                      <div class="ui-g-9">
                        <b>: </b> 
                        <span>{{createServiceTaskProcess.baseSPF.softwareProductFamily}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.baseReleasePreSelected">
                      <div class="ui-g-3">
                        <span><b>SP Build </b></span>
                      </div>
                      <div class="ui-g-9">
                        <b>: </b> 
                        <span>{{createServiceTaskProcess.baseRelease?.softwareProductBuild}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="!createServiceTaskProcess.baseReleasePreSelected 
                                              && !createServiceTaskProcess.isSalesforce
                                              && sharedData.visibility.software.addBaseImage">
                      <div class="ui-g-3">
                        <b>Add Image</b>
                      </div>
                      <div class="ui-g-9">
                        <b>: </b> 
                        <p-checkbox id="STaddImageforNewBuild" value="0" [binary]="true"
                          [(ngModel)]="createServiceTaskProcess.addSoftwareImage">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.addSoftwareImage">
                      <div class="ui-g-12">
                        <div class="ui-g-12" *ngFor="let imageSelection of createServiceTaskProcess.baseSI; let i = index">
                          <span style="margin-left: 10px;">{{imageSelection.imageBuildSelected.softwareImageBuild}}</span>
                          <i class="pi pi-times" id="STremoveST" pTooltip="Remove" (click)="removeSI(imageSelection, i)" style="cursor: pointer; float: right;"></i>
                        </div>
                        <div class="ui-g-12">
                          <div class="ui-g-4">
                            <p-dropdown id="STOptionsforSI" [options]="imageSelectItems" (click)="getLatestImageBuilds()"
                              (keyup.enter)="getLatestImageBuilds()" placeholder="Select SI"
                              [(ngModel)]="imageSelected" filter="true" [virtualScroll]="true" [style]="{width: '100%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                          </div>
                          <div class="ui-g-7">
                            <p-dropdown id="STselectSIBuild" [options]="imageBuildSelectItems" placeholder="Select SI Build"
                              [(ngModel)]="imageBuildSelected" filter="true" [virtualScroll]="true" [style]="{width: '100%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                          </div>
                          <div class="ui-g-1" class="action-icon">
                              <button pButton id="STAddSI" type="button" [disabled]="imageSelected === undefined || imageBuildSelected === undefined" (click)="addSI()" label="Add"></button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-3">
                        <b>Master Service Task</b>
                      </div>
                      <div class="ui-g-9">
                        <b>: </b> 
                        <p-checkbox id="masterServiceTask" value="1" [binary]="true"
                          [(ngModel)]="createServiceTaskProcess.masterService">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="!createServiceTaskProcess.isSalesforce">
                      <div class="ui-g-3">
                        <span><b>External ID: </b></span>
                      </div>
                      <div class="ui-g-9">
                        <span class="md-inputfield md-inputfield-fill">
                          <textarea id="ExternalIDInput" [(ngModel)]="createServiceTaskProcess.caseNumber" (ngModelChange)="createServiceTaskValidation()" placeholder="Eg: Radar_123456789" 
                            pInputTextarea autoResize="autoResize" [style]="{width: '100%'}">
                          </textarea>
                        </span>
                      </div>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <b><label for="crlistTextArea">CR's to include:</label></b>
                        <span class="md-inputfield md-inputfield-fill">
                          <textarea id="CRListToInclude" [(ngModel)]="createServiceTaskProcess.crList" (ngModelChange)="createServiceTaskValidation()" placeholder="Eg: 45654,78946 or 45654 78946" [rows]="3" [cols]="30"
                            pInputTextarea autoResize="autoResize" [style]="{width: '100%'}">
                          </textarea>
                        </span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="!createServiceTaskProcess.isSalesforce">
                      <div class="ui-g-3">
                        <b>Add Gerrits</b>
                      </div>
                      <div class="ui-g-9">
                        <b>: </b> 
                        <p-checkbox value="0" [binary]="true"
                          [(ngModel)]="createServiceTaskProcess.addGerrit">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.addGerrit">
                      <div class="ui-g-12">
                        <button id="STAddSIGerrit" pButton type="button" (click)="onAddSIGerrit()" label="Add" style="float: right;margin-right: 10px;"></button>
                        <div class="ui-g-12">
                          <p-table [value]="createServiceTaskProcess.gerritSIs" [style]="{'width': 'auto'}">
                            <ng-template pTemplate="header" let-columns>
                              <tr>
                                  <th>Image</th>
                                  <th>Type</th>
                                  excluded_early<th>CR</th>
                                  <th>Gerrit</th>
                                  <th></th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                              <tr>
                                  <td colspan="4">None.</td>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                                <tr>
                                    <td>{{rowData.softwareImage}}</td>
                                    <td>{{rowData.type}}</td>
                                    excluded_early td>{{rowData.crs}}</td>
                                    <td>{{rowData.gerrits}}</td>
                                    <td><i class="pi pi-times" pTooltip="Remove" (click)="removeSIGerrit(rowData, i)" style="cursor: pointer; float: right;"></i></td>
                                </tr>
                            </ng-template>
                          </p-table>
                        </div>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="sharedData.visibility.software.addCustomer && !createServiceTaskProcess.isSalesforce">
                      <div class="ui-g-12">
                        <b>Select Customers : </b>
                        <p-listbox id="STcompanyOptions" #companiesListBox [options]="createServiceTaskProcess.companies" [(ngModel)]="createServiceTaskProcess.selectedCompanies"
                          [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="companyName"  [style]="{width: '100%'}" [listStyle]="{'max-height': '250px'}">
                        </p-listbox>
                      </div>
                    </div>
                    <p-footer>
                      <div class="ui-g">
                        <div class="ui-g-12" *ngIf="this.createServiceTaskProcess.errorMessage !== ''" class="message message-error">
                          {{createServiceTaskProcess.errorMessage}}
                        </div>
                      </div>
                      <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <p-progressSpinner *ngIf="createServiceTaskProcess.loadCustomerInProgress || createServiceTaskProcess.loadCustomerInfoInProgress
                                                    || loadingProductFamily || loadingProduct || loadingReleases || loadingImages || loadingImageBuilds
                                                    || loadingHlosImages" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        <button pButton id= "requestNewSTNextButton" type="button" (click)="newServiceTaskNext()" label="Next >"
                          [disabled]="createServiceTaskProcess.baseRelease === undefined
                                      || (this.createServiceTaskProcess.isSalesforce && this.createServiceTaskProcess.selectedCompanies?.length === 0)
                                      || (createServiceTaskProcess.addSoftwareImage && createServiceTaskProcess.baseSI?.length === 0)
                                      || (createServiceTaskProcess.addGerrit && createServiceTaskProcess.gerritSIs?.length === 0)">
                        </button>
                      </div>
                    </p-footer>
                  </p-dialog>
                  <p-dialog header="Create Service Task Summary" [(visible)]="createServiceTaskProcess.displaySummary" modal="modal"
                    showEffect="fade" [style]="{width: '600px'}">
                    <div *ngIf="createServiceTaskProcess.isSalesforce && createServiceTaskProcess.caseNumber !== undefined">
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Case Number </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.caseNumber}}</span>
                        </div>
                      </div>
                      excluded_early<div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer ID </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.custid}}</span>
                        </div>
                      </div>excluded_early
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.cust}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="createServiceTaskProcess.baseSPF !== undefined" class="ui-g">
                      <div class="ui-g-3">
                        <b>Base SPF </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b> : </b>{{createServiceTaskProcess.baseSPF.softwareProductFamily}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="!createServiceTaskProcess.addSoftwareImage">
                      <div class="ui-g-3">
                        <b>SP Build </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b> : </b>{{createServiceTaskProcess.baseRelease?.softwareProductBuild}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.addSoftwareImage">
                      <div class="ui-g-3">
                        <b>Selected Images</b>
                      </div>
                      <div class="ui-g-12" *ngFor="let imageSelection of createServiceTaskProcess.baseSI; let i = index">
                        <span style="margin-left: 10px;">{{imageSelection.imageBuildSelected.softwareImageBuild}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.addGerrit">
                      <div class="ui-g-12">
                        <b>Gerrits:</b>
                      </div>
                      <p-table [value]="createServiceTaskProcess.gerritSIs" [style]="{'width': 'auto'}">
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th>Image</th>
                              <th>Type</th>
                              excluded_early th>CR</th>
                              <th>Gerrit</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <tr>
                              <td colspan="3">None.</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.softwareImage}}</td>
                                <td>{{rowData.type}}</td>
                              excluded_early  td>{{rowData.crs}}</td>
                                <td>{{rowData.gerrits}}</td>
                            </tr>
                        </ng-template>
                      </p-table>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-3">
                        <b>Master Service Task </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b>: </b> {{createServiceTaskProcess.masterService?'Yes':'No'}}</span>
                      </div>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <b>CR Validations:</b>
                        <p-table [columns]="createServiceTaskProcess.crCols" [value]="createServiceTaskProcess.crLists"  [style]="{'width': '250px'}">
                          <ng-template pTemplate="header" let-columns>
                            <tr>
                              <th *ngFor="let col of columns">
                                {{col.header}}
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="2">No CR numbers entered.</td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                              <td>{{rowData.cr}}</td>
                              <td>{{rowData.valid}}</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="sharedData.visibility.software.addCustomer">
                      <div class="ui-g-12">
                        <b>Customers:</b>
                        <p-table [value]="createServiceTaskProcess.selectedCompanies"  [style]="{'width': '250px'}">
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="1">No Customers Selected.</td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                              <td>{{rowData.companyName}}</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-12" *ngIf="this.createServiceTaskProcess.errorMessageResponse !== ''" class="message message-error">
                        {{createServiceTaskProcess.errorMessageResponse}}
                      </div>
                    </div>
                    <p-footer>
                      <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <p-progressSpinner *ngIf="createServiceTaskProcess.creatingServiceTaskInProgress" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        <button pButton type="button" id= "newServiceTaskBackButton" (click)="newServiceTaskBack()" label="Back"></button>
                        <button pButton type="button" id="reqServiceTaskBuildButton" (click)="createServiceTask()" label="Create Service Task"
                          [disabled]="createServiceTaskProcess.creatingServiceTaskInProgress" icon="ui-icon-send"></button>
                      </div>
                    </p-footer>
                  </p-dialog>
                  <p-dialog header="Request Success" [(visible)]="createServiceTaskProcess.displayResponse" modal="modal"
                    showEffect="fade" [style]="{width: '500px'}">
                    <div class="ui-g">
                      <div class="ui-g-12">
                          New Service Task created for {{createServiceTaskProcess.baseRelease?.softwareProductBuild}}. TaskID : 
                          <span style="cursor: pointer; color: blue; text-decoration: underline;" (click)="goToServiceTask(createServiceTaskProcess.newServiceTask.serviceTaskId)">
                            {{createServiceTaskProcess.newServiceTask.serviceTaskId}}
                          </span>
                      </div>
                    </div>
                  </p-dialog>
                </div> -->

            <!--OLD CREATE SERVICE TASK Section  END-->

              <!--NEW Modified CREATE SERVICE TASK Section  START-->
              <div class="card" *ngIf="createServiceTaskProcess.displayCreateRequest 
                                          && (createServiceTaskProcess.displayCreateForm
                                          || createServiceTaskProcess.displaySummary
                                          || createServiceTaskProcess.displayResponse)">
                  <p-dialog header="Create Service Task" [(visible)]="createServiceTaskProcess.displayCreateForm" modal="modal"
                    showEffect="fade" [style]="{width: '920px'}">
                    <!-- <div *ngIf="createServiceTaskProcess.isSalesforce && createServiceTaskProcess.caseNumber !== undefined">
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Case Number </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.caseNumber}}</span>
                        </div>
                      </div>
                      excluded_early <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer ID </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.custid}}</span>
                        </div>
                      </div> 
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.cust}}</span>
                        </div>
                      </div>
                    </div> -->
               

                    <!--Need to be in two section-->
                    <div *ngIf="!createServiceTaskProcess.baseReleasePreSelected">
                     
                       
                          <!--LEFT side-->
                        <div class="ui-g-12 ui-lg-4">
                         
                          <h4>
                            <span style=" margin-left: 8px;">FILTER BY</span>
                            <span (click)="onClearAllFilters()" style="float: right;margin-right: 26px; color: blue; cursor: pointer">Clear All</span>
                          </h4>

                          <div *ngIf="createServiceTaskProcess.isSalesforce && createServiceTaskProcess.caseNumber !== undefined">
                            <div class="ui-g">
                              <div class="ui-g-12">
                                <b>Customer:</b>
                              </div>
                              <div class="ui-g-12">
                                <span> {{createServiceTaskProcess.cust}}</span>
                              </div>
                            </div>
                          </div>
                          

                            <div class="ui-g" *ngIf="this.sharedData.visibility.software.showCustomerFilter && !createServiceTaskProcess.isSalesforce">
                              <div class="ui-g-12">
                                <b>Customer :</b>
                                <p-dropdown id="selectCustomer" [options]="customersSelectItems" (onChange)="onCustomerChange()" placeholder="Select Customer"
                                  [(ngModel)]="selectedCustomer" filter="true" [virtualScroll]="true" itemSize="30" [style]="{width: '95%'}" [panelStyle] = "{width:'300px'}" appendTo="body">
                                  <ng-template let-item pTemplate="selectedItem">
                                    {{item.label}}
                                  </ng-template>
                                  <ng-template let-item pTemplate="item">
                                    {{item.label}}
                                  </ng-template>
                                </p-dropdown>
                                <!-- <i class="ui-icon-cancel" id="clearSTCustomerFilter" pTooltip="Clear Filter" (click)="onCustomerClear()" style="cursor: pointer;"></i> -->
                              </div>
                          </div>

                          <div class="ui-g" *ngIf="projectsSelectItems.length>0">
                            <div class="ui-g-12">
                              <b>Project :</b>
                            </div>
                            <div class="ui-g-12">
                              
                              <p-dropdown id="STProjectOptions" [options]="projectsSelectItems" (onChange)="onProjectChange()" placeholder="Select Project"
                                [(ngModel)]="selectedProject" filter="true" [virtualScroll]="true" itemSize="30" [style]="{width: '95%'}" [panelStyle] = "{width:'300px'}" appendTo="body">
                                <ng-template let-item pTemplate="selectedItem">
                                  {{item.label}}
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                  {{item.label}}
                                </ng-template>
                              </p-dropdown>
                              <!-- <i class="ui-icon-cancel" id="clearSTProjectFilter" pTooltip="Clear Filter" (click)="onProjectClear()" style="cursor: pointer;"></i>
                              <i class="pi pi-exclamation-circle" *ngIf="this.sharedData.visibility.software.showCustomerFilter && !createServiceTaskProcess.isSalesforce" pTooltip="Please select customer to see project list." style="font-size: 1.7em; cursor: pointer;"></i> -->
                            </div>
                        </div>
                        <div class="ui-g">
                          <div class="ui-g-12">
                            <b>Software Product Family (SPF) :</b>
                          </div>
                          <div class="ui-g-12">
                            
                              <p-dropdown id="STProductSPF" [options]="productFamilySelectItems" (onChange)="onProductFamilyChange()" placeholder="Select SPF"
                                [(ngModel)]="selectedProductFamily" filter="true" [virtualScroll]="true" itemSize="30" [style]="{width: '95%'}" [panelStyle] = "{width:'300px'}" appendTo="body">
                                <ng-template let-item pTemplate="selectedItem">
                                  {{item.label}}
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                  {{item.label}}
                                </ng-template>
                              </p-dropdown>
                              <!-- <i class="ui-icon-cancel" id="clearSTProductFamilyFilter" pTooltip="Clear Filter" (click)="onProductFamilyClear()" style="cursor: pointer;"></i> -->
                            </div>
                        </div>

                          </div>
                          <!--LEFT side end-->

                            <!--RIGHt side-->
                          <div class="ui-g-12 ui-lg-8">
                            <p-tabView style="margin-right: 30px!important;margin-left: 20px!important;">
                              <p-tabPanel header="Select SP Build" leftIcon="ui-icon-assignment">
                                <!-- <div>
                                  <span  (click)="onSPandSPBuildClear()" style="margin-top: -10px;float: right; color: blue; cursor: pointer">Clear All</span>
                                </div> -->
                              <div class="ui-g">
                                <div class="ui-g-12">
                                  <b>Software Product (SP)* :</b>
                                  <span  (click)="onSPandSPBuildClear()" style="margin-top: -10px;float: right; color: blue; cursor: pointer">Clear All</span>
                                </div>
                                <div class="ui-g-12">
                                  <p-dropdown id="STSelectSPOptions" [options]="productsSelectItems" (onChange)="onProductChange()" placeholder="Select SP"
                                    [(ngModel)]="selectedProduct" filter="true" [virtualScroll]="true" itemSize="30" [style]="{width: '100%'}" [panelStyle] = "{width:'400px'}" appendTo="body">
                                    <ng-template let-item pTemplate="selectedItem">
                                      {{item.label}}
                                    </ng-template>
                                    <ng-template let-item pTemplate="item">
                                      {{item.label}}
                                    </ng-template>
                                  </p-dropdown>
                                  <!-- <i class="ui-icon-cancel" id="clearSTProductFilter" pTooltip="Clear Filter" (click)="onProductClear()" style="cursor: pointer;"></i> -->
                                </div>      
                                <div class="ui-g-12" *ngIf="!sharedData.devFeature.allowEOL && selectedProduct !== undefined && selectedProduct?.active === false && selectedProduct?.enabledForServiceTaskInInactiveStatus === false" class="message message-error" style="padding-left: 10px">
                                  {{selectedProduct?.softwareProduct}} is EOL. Unable to create service task with inactive SP.
                                </div>
                              </div>
                              <div class="ui-g" *ngIf="!createServiceTaskProcess.addSoftwareImage">
                                <div class="ui-g-12">
                                  <b>Software Product Build* :</b>
                                </div>
                                <div class="ui-g-12">
                                 
                                  <p-dropdown id="SPBuildReleaseOptions" [options]="releasesSelectItems" (onChange)="onReleaseChange()" placeholder="Select SP Build"
                                    [(ngModel)]="selectedRelease" filter="true" [virtualScroll]="true" itemSize="30" [style]="{width: '100%'}" [panelStyle] = "{width:'400px'}" appendTo="body">
                                    <ng-template let-item pTemplate="selectedItem">
                                      <div>
                                        <div style="font-size: x-small">SPF Tag: {{item.value.spfTag !== null? item.value.spfTag : "None"}}</div>
                                        <div><span style="font-size: x-small">SP Tag: </span> {{item.value.tag}}</div>
                                      </div>
                                    </ng-template>
                                    <ng-template let-item pTemplate="item">
                                      <div style="text-indent: -5px;">
                                        <div style="width: 62%; display: inline-block; padding: 5px; overflow-wrap: break-word;">
                                          {{item.value.spfTag !== null? item.value.spfTag : "None"}}
                                        </div>
                                        <div style="width: 33.3333%; padding: 5px; overflow-wrap: break-word; float: right;">
                                          {{item.value.tag}}
                                        </div>
                                      </div>
                                    </ng-template>
                                  </p-dropdown>
                                  <!--i class="ui-icon-search" id="searchCr" pTooltip="Search CRs"  (click)="startFindCrStatusProcess()" style="cursor: pointer;"></i-->
                                  <!-- <i class="ui-icon-cancel" id="clearSTReleaseFilter" pTooltip="Clear Filter" (click)="onReleaseClear()" style="cursor: pointer;"></i> -->
                                  <!-- <p style="text-align: right;">
                                    <b>
                                      <div (click)="searchReleaseByImageProcess.displaySearchByImageDialog = true;searchReleaseByImageProcess.displaySearchResults=false;searchReleaseByImageProcess.imageBuild=undefined"
                                      style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                      Find release from Image build
                                      </div>
                                    </b>
                                  </p> -->
                                </div>
                              </div>
                            
                            </p-tabPanel>
                             <p-tabPanel header="Find SP Build from Image Build" leftIcon="ui-icon-assignment">
                              
                                <div class="ui-g">
                                  <div class="ui-g-12">
                                    <span style="margin-left: 4px;"><b>Image Build: </b></span>
                                  </div>
                                  <div class="ui-g-12">
                                    <span class="md-inputfield md-inputfield-fill">
                                      <textarea id="ImageBuildInput" [(ngModel)]="searchReleaseByImageProcess.imageBuild"placeholder="Eg: MPSS.HI.4.1.1.c1-00085-LAHAINA_GEN_PACK-1" 
                                        pInputTextarea autoResize="autoResize" [style]="{width: '95%'}">
                                      </textarea>
                                      <i class="ui-icon-cancel" id="clearSTImageFilter" pTooltip="Clear Filter" (click)="searchReleaseByImageProcess.imageBuild = undefined;" style="cursor: pointer;"></i>
                                    </span>
                                  </div>
                                  <div class="ui-g-12 float-right">
                                    <div class="ui-dialog-buttonpane ui-helper-clearfix">
                                    <p-progressSpinner *ngIf="searchReleaseByImageProcess.loadingAvailableRelease" 
                                    [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                                    
                                      <button pButton id="STSearchByImage"  type="button"  (click) = "searchReleaseInfoByImage()" label="Search" [disabled]="searchReleaseByImageProcess.imageBuild===undefined" style="margin-right: 4px;"></button>
                                      <!-- <button pButton id="ClearResults" type="button" [disabled]="searchReleaseByImageProcess.softwareProductReleases.length==0" (click) = "searchReleaseByImageProcess.softwareProductReleases=[];searchReleaseByImageProcess.selectedProductRelease=undefined;searchReleaseByImageProcess.imageBuild=undefined; searchReleaseByImageProcess.selectedProductRelease==undefined" label="Clear"></button> -->
                                      <button pButton id="ClearResults" type="button" [disabled]="searchReleaseByImageProcess.softwareProductReleases.length==0" (click) = "clearAllTableResults()" label="Clear"></button>
                                    </div>
                                  </div>
                                 
                                </div>


                               <!--*ngIf="searchReleaseByImageProcess.displaySearchResults"-->
                               <div class="ui-g">
                                  <div class="ui-g-12">
                                    <p-table id ="searchReleaseByImageTable" #searchReleaseByImageTable selectionMode="single" [(selection)]="searchReleaseByImageProcess.selectedProductRelease" [value]="searchReleaseByImageProcess.softwareProductReleases"  [paginator]="true" [rows]="10" 
                                              sortField="shipDate" [sortOrder]="-1" [globalFilterFields]="['tag','spfTag','spf','softwareProduct']" style="width: 100%;">
                                          <ng-template pTemplate="caption">
                                              <div style="text-align: right">
                                                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                                <input #versionGlobalFilter id="createSTFilterRelease" class="filter-clear" type="text" pInputText size="50" placeholder="SP Tag/SPF Tag/SPF/SP"
                                                  (input)="searchReleaseByImageTable.filterGlobal($event.target.value, 'contains')" style="width:93%">
                                              </div>
                                          </ng-template>
                                          <ng-template pTemplate="header" let-columns>
                                            <tr>
                                              <th pSortableColumn="tag">
                                                SP Tag
                                                <p-sortIcon field="tag"></p-sortIcon>
                                              </th>
                                              <th pSortableColumn="spfTag">
                                                SPF Tag
                                                <p-sortIcon field="spfTag"></p-sortIcon>
                                              </th>
                                              <th pSortableColumn="spf">
                                                SPF
                                                <p-sortIcon field="spf"></p-sortIcon>
                                              </th>
                                              <th pSortableColumn="softwareProduct">
                                                SP
                                                <p-sortIcon field="softwareProduct"></p-sortIcon>
                                              </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage">
                                            <tr>
                                                <td colspan="4">No Valid Release for Given Image</td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                                          <tr [pSelectableRow]="rowData" [pEditableRow]="rowData" (click)="onSelectButtonClick()">
                                              <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                                              <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.spfTag}}</span></td>
                                              <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.spf}}</span></td>
                                              <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProduct}}</span></td>
                                          </tr>
                                      </ng-template>
                                    </p-table>
                                  </div>
                                </div>
                                

                                <!-- <p-footer> 
                                  <div class="ui-dialog-buttonpane ui-helper-clearfix">
                                    <button pButton id="selectRelease" type="button" *ngIf="searchReleaseByImageProcess.displaySearchResults" (click) = "onSelectButtonClick()" label="Select Release" [disabled]="searchReleaseByImageProcess.selectedProductRelease===undefined"></button>
                                  </div>
                                </p-footer> -->

                              
                            </p-tabPanel>
                          </p-tabView>

                          </div>
                        <!--RIGHT side end-->
                        
                      
                    </div>


                    <!--Create Service Task from Software Builds Page-->
                    <div class="ui-g-4 ui-lg-4" *ngIf="createServiceTaskProcess.baseReleasePreSelected 
                                              && createServiceTaskProcess.baseSPF !== undefined" style="float: left;">
                      <div class="ui-g-12">
                        <span><b>Base SPF:</b></span>
                      </div>
                      <div class="ui-g-12">
                        <span>{{createServiceTaskProcess.baseSPF.softwareProductFamily}}</span>
                      </div>
                    </div>
                    <div class="ui-g-4 ui-lg-4" *ngIf="createServiceTaskProcess.baseReleasePreSelected" style="padding-right: 20px;padding-left: 20px;">
                      <div class="ui-g-12">
                        <span><b>SP Build:</b></span>
                      </div>
                      <div class="ui-g-12">
                        <span>{{createServiceTaskProcess.baseRelease?.softwareProductBuild}}</span>
                      </div>
                    </div>
                    <!-- <div class="ui-g" *ngIf="!createServiceTaskProcess.baseReleasePreSelected 
                                              && !createServiceTaskProcess.isSalesforce
                                              && sharedData.visibility.software.addBaseImage">
                      <div class="ui-g-3">
                        <b>Add Image</b>
                      </div>
                      <div class="ui-g-9">
                        <b>: </b> 
                        <p-checkbox id="STaddImageforNewBuild" value="0" [binary]="true"
                          [(ngModel)]="createServiceTaskProcess.addSoftwareImage">
                        </p-checkbox>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.addSoftwareImage">
                      <div class="ui-g-12">
                        <div class="ui-g-12" *ngFor="let imageSelection of createServiceTaskProcess.baseSI; let i = index">
                          <span style="margin-left: 10px;">{{imageSelection.imageBuildSelected.softwareImageBuild}}</span>
                          <i class="pi pi-times" id="STremoveST" pTooltip="Remove" (click)="removeSI(imageSelection, i)" style="cursor: pointer; float: right;"></i>
                        </div>
                        <div class="ui-g-12">
                          <div class="ui-g-4">
                            <p-dropdown id="STOptionsforSI" [options]="imageSelectItems" (click)="getLatestImageBuilds()"
                              (keyup.enter)="getLatestImageBuilds()" placeholder="Select SI"
                              [(ngModel)]="imageSelected" filter="true" [virtualScroll]="true" [style]="{width: '100%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                          </div>
                          <div class="ui-g-7">
                            <p-dropdown id="STselectSIBuild" [options]="imageBuildSelectItems" placeholder="Select SI Build"
                              [(ngModel)]="imageBuildSelected" filter="true" [virtualScroll]="true" [style]="{width: '100%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                          </div>
                          <div class="ui-g-1" class="action-icon">
                              <button pButton id="STAddSI" type="button" [disabled]="imageSelected === undefined || imageBuildSelected === undefined" (click)="addSI()" label="Add"></button>
                          </div>
                        </div>
                      </div>
                    </div> -->
                   <!--Advanced Options   start-->
                 
                   <div class="ui-g-8" style="float: right;padding-right: 25px;padding-left: 25px;">
                    <p-accordion>
                      <p-accordionTab id="advancedOptionsAccordionTab" header="Advanced Options"  [selected]="createServiceTaskProcess.expandAdvanceOptions"> 
                        
                        <div *ngIf="createServiceTaskProcess.isSalesforce && createServiceTaskProcess.caseNumber !== undefined">
                          <div class="ui-g">
                            <div class="ui-g-3">
                              <b>Case Number </b>
                            </div>
                            <div class="ui-g-9">
                              <span><b>: </b> {{createServiceTaskProcess.caseNumber}}</span>
                            </div>
                          </div>
                        </div>

                        <div class="ui-g">
                          <div class="ui-g-3">
                            <b>Master Service Task</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b> 
                            <p-checkbox id="masterServiceTask" value="1" [binary]="true"
                              [(ngModel)]="createServiceTaskProcess.masterService">
                            </p-checkbox>
                          </div>
                        </div>
                           <!--inside advanced-->
                        <div class="ui-g" *ngIf="!createServiceTaskProcess.isSalesforce">
                          <div class="ui-g-3">
                            <span><b>External ID: </b></span>
                          </div>
                          <div class="ui-g-9">
                            <span class="md-inputfield md-inputfield-fill">
                              <textarea id="ExternalIDInput" [(ngModel)]="createServiceTaskProcess.caseNumber" (ngModelChange)="createServiceTaskValidation()" placeholder="Eg: Radar_123456789" 
                                pInputTextarea autoResize="autoResize" [style]="{width: '100%'}">
                              </textarea>
                            </span>
                          </div>
                        </div>
                        <!--inside advanced : SubjectLine visible to MasterSTManager-->
                        <div class="ui-g" *ngIf="sharedData.visibility.createServivceTask.subjectLine">
                          <div class="ui-g-3">
                            <span><b>Subject Line: </b></span>
                          </div>
                          <div class="ui-g-9">
                            <span class="md-inputfield md-inputfield-fill">
                              <textarea id="captionInput" [(ngModel)]="createServiceTaskProcess.caption" placeholder="Enter Service Task Subject Line" 
                                pInputTextarea autoResize="autoResize" [style]="{width: '100%'}">
                              </textarea>
                            </span>
                          </div>
                        </div>
                        <div class="ui-g">
                          <div class="ui-g-3">
                            <b>Add CR's</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b> 
                            <p-checkbox value="0" [binary]="true"
                              [(ngModel)]="createServiceTaskProcess.addCRs">
                            </p-checkbox>
                          </div>
                        </div>
                           <!--inside advanced-->
                        <div class="ui-g" *ngIf="createServiceTaskProcess.addCRs">
                          <div class="ui-g-12">
                            <b><label for="crlistTextArea">CR's to include:</label></b>
                            <span class="md-inputfield md-inputfield-fill">
                              <textarea id="CRListToInclude" [(ngModel)]="createServiceTaskProcess.crList" (ngModelChange)="createServiceTaskValidation()" placeholder="Eg: 45654,78946 or 45654 78946" [rows]="3" [cols]="30"
                                pInputTextarea autoResize="autoResize" [style]="{width: '100%'}">
                              </textarea>
                            </span>
                          </div>
                        </div>
                           <!--inside advanced-->
                        <div class="ui-g" *ngIf="!createServiceTaskProcess.isSalesforce">
                          <div class="ui-g-3">
                            <b>Add Gerrits</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b> 
                            <p-checkbox value="0" [binary]="true"
                              [(ngModel)]="createServiceTaskProcess.addGerrit">
                            </p-checkbox>
                          </div>
                        </div>
                        <div class="ui-g" *ngIf="createServiceTaskProcess.addGerrit">
                          <div class="ui-g-12">
                            <button id="STAddSIGerrit" pButton type="button" (click)="onAddSIGerrit()" label="Add" style="float: right;margin-right: 10px;"></button>
                            <div class="ui-g-12">
                              <p-table [value]="createServiceTaskProcess.gerritSIs" [style]="{'width': 'auto'}">
                                <ng-template pTemplate="header" let-columns>
                                  <tr>
                                      <th>Image</th>
                                      <th>Type</th>
                                      <!--th>CR</th-->
                                      <th>Gerrit</th>
                                      <th></th>
                                  </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                  <tr>
                                      <td colspan="4">None.</td>
                                  </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                                    <tr>
                                        <td>{{rowData.softwareImage}}</td>
                                        <td>{{rowData.type}}</td>
                                        <!--td>{{rowData.crs}}</td-->
                                        <td>{{rowData.gerrits}}</td>
                                        <td><i class="pi pi-times" pTooltip="Remove" (click)="removeSIGerrit(rowData, i)" style="cursor: pointer; float: right;"></i></td>
                                    </tr>
                                </ng-template>
                              </p-table>
                            </div>
                          </div>
                        </div>
                        <!--inside advanced-->
                        <div class="ui-g" *ngIf="sharedData.visibility.software.addCustomer && !createServiceTaskProcess.isSalesforce">
                          <div class="ui-g-3">
                            <b>Add Customers</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b> 
                            <p-checkbox value="0" [binary]="true"
                              [(ngModel)]="createServiceTaskProcess.addCustomers">
                            </p-checkbox>
                          </div>
                        </div>

                        <div class="ui-g" *ngIf="sharedData.visibility.software.addCustomer && !createServiceTaskProcess.isSalesforce && createServiceTaskProcess.addCustomers">
                          <div class="ui-g-12">
                            <b>Select Customers : </b>
                            <p-listbox id="STcompanyOptions" #companiesListBox [options]="createServiceTaskProcess.companies" [(ngModel)]="createServiceTaskProcess.selectedCompanies"
                              [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="companyName"  [style]="{width: '100%'}" [listStyle]="{'max-height': '250px'}">
                            </p-listbox>
                          </div>
                        </div>

                        <div class="ui-g" *ngIf="sharedData.visibility.serviceTaskFinder.autobuild">
                          <div class="ui-g-3">
                            <b>Autobuild</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b> 
                            <p-checkbox id="autobuildToggle" value="1" [binary]="true"
                              [(ngModel)]="createServiceTaskProcess.autobuild">
                            </p-checkbox>
                          </div>
                        </div>
                        <div class="ui-g" *ngIf="sharedData.userInfo.info.internal">
                          <div class="ui-g-3" style="word-break: break-word;">
                            <b>SoftwareFormat Addons</b>
                          </div>
                          <div class="ui-g-9">
                            <b>: </b> 
                            <p-checkbox id="isImageFormatAddOnToggle" value="0" [binary]="true"
                              [(ngModel)]="createServiceTaskProcess.isImageFormatAddOn">
                            </p-checkbox>
                          </div>
                        </div>

                      </p-accordionTab>
                    </p-accordion>   
                   </div> 
                   <!---Advanced Options    end-->
                    <p-footer>
                      <div class="ui-g">
                        <div class="ui-g-12" *ngIf="this.createServiceTaskProcess.errorMessage !== ''" class="message message-error">
                          {{createServiceTaskProcess.errorMessage}}
                        </div>
                      </div>
                      <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <p-progressSpinner *ngIf="createServiceTaskProcess.loadCustomerInProgress || createServiceTaskProcess.loadCustomerInfoInProgress
                                                    || loadingProductFamily || loadingProduct || loadingReleases || loadingImages || loadingImageBuilds
                                                    || loadingHlosImages" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        <button pButton id= "requestNewSTNextButton" type="button" (click)="newServiceTaskNext()" label="Next >"
                          [disabled]="createServiceTaskProcess.baseRelease === undefined
                                      || (this.createServiceTaskProcess.isSalesforce && this.createServiceTaskProcess.selectedCompanies?.length === 0)
                                      || (createServiceTaskProcess.addSoftwareImage && createServiceTaskProcess.baseSI?.length === 0)
                                      || (createServiceTaskProcess.addGerrit && createServiceTaskProcess.gerritSIs?.length === 0)
                                      || (!sharedData.devFeature.allowEOL && selectedProduct?.active === false && selectedProduct?.enabledForServiceTaskInInactiveStatus === false)">
                        </button>
                      </div>
                    </p-footer>
                  </p-dialog>
                  <p-dialog header="Create Service Task Summary" [(visible)]="createServiceTaskProcess.displaySummary" modal="modal"
                    showEffect="fade" [style]="{width: '840px'}">
                    <div *ngIf="createServiceTaskProcess.isSalesforce && createServiceTaskProcess.caseNumber !== undefined">
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Case Number </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.caseNumber}}</span>
                        </div>
                      </div>
                      <!-- <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer ID </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.custid}}</span>
                        </div>
                      </div> -->
                      <div class="ui-g">
                        <div class="ui-g-3">
                          <b>Customer </b>
                        </div>
                        <div class="ui-g-9">
                          <span><b>: </b> {{createServiceTaskProcess.cust}}</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="createServiceTaskProcess.baseSPF !== undefined" class="ui-g">
                      <div class="ui-g-3">
                        <b>Base SPF </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b> : </b>{{createServiceTaskProcess.baseSPF.softwareProductFamily}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="!createServiceTaskProcess.addSoftwareImage">
                      <div class="ui-g-3">
                        <b>SP Build </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b> : </b>{{createServiceTaskProcess.baseRelease?.softwareProductBuild}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.addSoftwareImage">
                      <div class="ui-g-3">
                        <b>Selected Images</b>
                      </div>
                      <div class="ui-g-12" *ngFor="let imageSelection of createServiceTaskProcess.baseSI; let i = index">
                        <span style="margin-left: 10px;">{{imageSelection.imageBuildSelected.softwareImageBuild}}</span>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="createServiceTaskProcess.addGerrit">
                      <div class="ui-g-12">
                        <b>Gerrits:</b>
                      </div>
                      <p-table [value]="createServiceTaskProcess.gerritSIs" [style]="{'width': 'auto'}">
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th>Image</th>
                              <th>Type</th>
                              <!--th>CR</th-->
                              <th>Gerrit</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <tr>
                              <td colspan="3">None.</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>{{rowData.softwareImage}}</td>
                                <td>{{rowData.type}}</td>
                                <!--td>{{rowData.crs}}</td-->
                                <td>{{rowData.gerrits}}</td>
                            </tr>
                        </ng-template>
                      </p-table>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-3">
                        <b>Master Service Task </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b>: </b> {{createServiceTaskProcess.masterService?'Yes':'No'}}</span>
                      </div>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <b>CR Validations:</b>
                        <p-table [columns]="createServiceTaskProcess.crCols" [value]="createServiceTaskProcess.crLists"  [style]="{'width': '250px'}">
                          <ng-template pTemplate="header" let-columns>
                            <tr>
                              <th *ngFor="let col of columns">
                                {{col.header}}
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="2">No CR numbers entered.</td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                              <td>{{rowData.cr}}</td>
                              <td>{{rowData.valid}}</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                    <div class="ui-g" *ngIf="sharedData.visibility.software.addCustomer">
                      <div class="ui-g-12">
                        <b>Customers:</b>
                        <p-table [value]="createServiceTaskProcess.selectedCompanies"  [style]="{'width': '250px'}">
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td colspan="1">No Customers Selected.</td>
                            </tr>
                            <div  class="message message-error" style="width: 420px; color: #ffa500;">
                              Warning : No Customer Selected. Are you sure you want to continue?
                            </div>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData>
                            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                              <td>{{rowData.companyName}}</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-12" *ngIf="this.createServiceTaskProcess.errorMessageResponse !== ''" class="message message-error">
                        {{createServiceTaskProcess.errorMessageResponse}}
                      </div>
                    </div>
                    <p-footer>
                      <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <p-progressSpinner *ngIf="createServiceTaskProcess.creatingServiceTaskInProgress" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        <button pButton type="button" id= "newServiceTaskBackButton" (click)="newServiceTaskBack()" label="Back"></button>
                        <button pButton type="button" id="reqServiceTaskBuildButton" (click)="createServiceTask()" label="Create Service Task"
                          [disabled]="createServiceTaskProcess.creatingServiceTaskInProgress" icon="ui-icon-send"></button>
                      </div>
                    </p-footer>
                  </p-dialog>
                  <p-dialog header="Request Success" [(visible)]="createServiceTaskProcess.displayResponse" modal="modal"
                    showEffect="fade" [style]="{width: '500px'}">
                    <div class="ui-g">
                      <div class="ui-g-12">
                          New Service Task created for {{createServiceTaskProcess.baseRelease?.softwareProductBuild}}. TaskID : 
                          <span style="cursor: pointer; color: blue; text-decoration: underline;" (click)="goToServiceTask(createServiceTaskProcess.newServiceTask.serviceTaskId)">
                            {{createServiceTaskProcess.newServiceTask.serviceTaskId}}
                          </span>
                      </div>
                    </div>
                  </p-dialog>
              </div>
              
              <!--NEW Modified CREATE SERVICE TASK Section  END-->  
                <div class="card" *ngIf="emailProcess.displayForm">
                  <p-dialog header="Email Preview" [(visible)]="emailProcess.displayForm" modal="modal"
                      showEffect="fade" [style]="{width: '80%'}">
                      <div class="ui-g">
                          <div class="ui-g-12">
                              <div class="ui-g-1">
                                  <b>From</b>
                              </div>
                              <div class="ui-g-11">
                                  : {{emailProcess.email?.fromEmailId}}
                              </div>
                          </div>
                          <div class="ui-g-12">
                              <div class="ui-g-1">
                                  <b>To</b>
                              </div>
                              <div class="ui-g-11">:
                                  <div *ngIf="!emailProcess.editTo">
                                    <span *ngFor="let to of emailProcess.email?.toEmailIds">
                                        {{to + "; "}}
                                    </span>
                                  </div>
                                  <input *ngIf="emailProcess.editTo" pInputText type="text" [(ngModel)]="emailProcess.toEmailIds" style="width: '70%';">
                              </div>
                          </div>
                          <div class="ui-g-12">
                              <div class="ui-g-1">
                                  <b>Cc</b>
                              </div>
                              <div class="ui-g-11">:
                                  <input pInputText type="text" [(ngModel)]="emailProcess.ccEmailIds" style="width: '70%';">
                              </div>
                          </div>
                          <div class="ui-g-12">
                              <div class="ui-g-1">
                                  <b>Subject</b>
                              </div>
                              <div class="ui-g-11">
                                <input pInputText type="text" [(ngModel)]="emailProcess.email.subject" style="width: '70%';">
                              </div>
                          </div>
                          <div class="ui-g-12">
                              <div class="ui-g-12">
                                  {{emailProcess.bodyTop}}<br/><br/>
                                  <textarea [(ngModel)]="emailProcess.note" placeholder="Enter your message" [rows]="3" [cols]="30"
                                      pInputTextarea autoResize="autoResize" [style]="{width: '100%', border: '1px solid darkgray'}">
                                  </textarea>
                              </div>
                              <div class="ui-g-12">
                                  <div class="email-table" [innerHTML]="emailProcess.email?.body"></div>
                              </div>
                              <div class="ui-g-12" *ngIf="emailProcess.editRadar">
                                <p-table [value]="emailProcess.radarInfo">
                                  <ng-template pTemplate="header" let-columns>
                                      <tr>
                                        <th>External ID</th>
                                        <th>CR Number</th>
                                        <th>Notes</th>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="emptymessage">
                                      <tr>
                                          <td colspan="3">None.</td>
                                      </tr>
                                  </ng-template>
                                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                                      <tr [pSelectableRow]="rowData">
                                          <td>
                                            <input pInputText type="text" [(ngModel)]="rowData.externalID">
                                          </td>
                                          <td>{{rowData.cr}}</td>
                                          <td>
                                            <input pInputText type="text" [(ngModel)]="rowData.note">
                                          </td>
                                      </tr>
                                  </ng-template>
                                </p-table>
                              </div>
                          </div>
                      </div>
                      <p-footer>
                          <div class="ui-g" *ngIf="this.emailProcess.errorMessage !== ''" >
                            <div class="ui-g-12" class="message message-error">
                              {{emailProcess.errorMessage}}
                            </div>
                          </div>
                          <div class="ui-dialog-buttonpane ui-helper-clearfix">
                              <p-progressSpinner *ngIf="emailProcess.sendingEmail" [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                              <button pButton type="button" [disabled]="emailProcess.sendingEmail || (emailProcess.editRadar && emailProcess.toEmailIds === '')" 
                                [style]="{width: '150px'}" (click)="sendEmail()" label="Send" icon="ui-icon-send"></button>
                          </div>
                      </p-footer>
                  </p-dialog>
                </div>

                <!--Add Gerrits-->
                <div *ngIf="addGerritProcess.displayForm || addGerritProcess.displayFormSummary">
                  <p-dialog header="Add Gerrit(s)" [(visible)]="addGerritProcess.displayForm" modal="modal" showEffect="fade" [style]="{width: '600px'}">
                    <div class="ui-g-12">
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <b>
                              Image
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            <p-dropdown id="triageGerritSIOptions" [options]="addGerritProcess.availableImages"
                              [(ngModel)]="addGerritProcess.selectedImage" [virtualScroll]="true"
                              (click)="validateGerrit()" (keyup.enter)="validateGerrit()" [style]="{width: '100%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                          </div>
                        </div>
                        <div class="ui-g" *ngIf="addGerritProcess.selectedImage !== undefined">
                          <div class="ui-g-2">
                            <b>
                              Build ID
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            <input id="triageGerritSIBuild" type="text" pInputText disabled="true" value="{{addGerritProcess.selectedImage.softwareImageBuild}}" style="width: 100%;"/>
                          </div>
                        </div>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <b>
                              Type
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            <p-dropdown id="triageGerritTypeOptions" [options]="addGerritProcess.availableTypes"
                              [(ngModel)]="addGerritProcess.selectedType" [style]="{width: '100%'}">
                              <ng-template let-item pTemplate="selectedItem">
                                {{item.label}}
                              </ng-template>
                              <ng-template let-item pTemplate="item">
                                {{item.label}}
                              </ng-template>
                            </p-dropdown>
                          </div>
                        </div>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <b>
                              Gerrit(s)
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            <input id="triageEnterGerritList" type="text" pInputText [(ngModel)]="addGerritProcess.imagesHlosGerritList" (input)="validateGerrit()" style="width: 100%;"/>
                          </div>
                        </div>
                    </div>
                    <p-footer>
                      <div class="ui-g">
                        <div class="ui-g-12" *ngIf="addGerritProcess.errorMessage !== ''" class="message message-error">
                          {{addGerritProcess.errorMessage}}
                        </div>
                        <div class="ui-g-12" *ngIf="addGerritProcess.duplicateMessage !== ''" class="message message-error">
                          {{addGerritProcess.duplicateMessage}}
                        </div>
                      </div>
                      <p-progressSpinner *ngIf="addGerritProcess.addGerritInProgress || addGerritProcess.loadingImages" 
                                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                      <button pButton id="triageAddGerritSubmitButton" type="button" *ngIf="!addGerritProcess.isCreateST"
                          [disabled]="addGerritProcess.selectedImage === undefined || addGerritProcess.duplicateMessage !== ''" 
                          label="Next >" (click)="onGerritActionNext()" [style]="{'width': '150px', 'margin-top': '20px'}">
                      </button>
                      <button pButton id="triageAddGerritSubmitButton" type="button" *ngIf="addGerritProcess.isCreateST"
                          [disabled]="addGerritProcess.selectedImage === undefined || addGerritProcess.duplicateMessage !== ''" 
                          label="Add Gerrit(s)" (click)="onGerritActionSubmit()" [style]="{'width': '150px', 'margin-top': '20px'}">
                      </button>
                    </p-footer>
                  </p-dialog>
                  <p-dialog header="Add Gerrit(s) Summary" [(visible)]="addGerritProcess.displayFormSummary" modal="modal" showEffect="fade" [style]="{width: '600px'}">
                    <div class="ui-g-12">
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <b>
                              Image
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            {{addGerritProcess.selectedImage?.softwareImage}}
                          </div>
                        </div>
                        <div class="ui-g" *ngIf="addGerritProcess.selectedImage !== undefined">
                          <div class="ui-g-2">
                            <b>
                              Build ID
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            {{addGerritProcess.selectedImage?.softwareImageBuild}}
                          </div>
                        </div>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <b>
                              Type
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            {{addGerritProcess.selectedType}}
                          </div>
                        </div>
                        <div class="ui-g">
                          <div class="ui-g-2">
                            <b>
                              Gerrit(s)
                              <span style="float: right;">:</span>
                            </b>
                          </div>
                          <div class="ui-g-10">
                            {{addGerritProcess.imagesHlosGerritSummary}}
                          </div>
                        </div>
                    </div>
                    <p-footer>
                      <div class="ui-g">
                        <div class="ui-g-12" *ngIf="addGerritProcess.errorMessage !== ''" class="message message-error">
                          {{addGerritProcess.errorMessage}}
                        </div>
                        <div class="ui-g-12" *ngIf="addGerritProcess.duplicateMessage !== ''" class="message message-error">
                          {{addGerritProcess.duplicateMessage}}
                        </div>
                      </div>
                      <p-progressSpinner *ngIf="addGerritProcess.addGerritInProgress || addGerritProcess.loadingImages" 
                                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                      <button pButton id="triageAddGerritSubmitButton" type="button"
                          label="Back" (click)="onGerritActionBack()" [style]="{'width': '150px', 'margin-top': '20px'}">
                      </button>
                      <button pButton id="triageAddGerritSubmitButton" type="button" 
                          [disabled]="addGerritProcess.selectedImage === undefined || addGerritProcess.duplicateMessage !== ''" 
                          label="Add Gerrit(s)" (click)="onGerritActionSubmit()" [style]="{'width': '150px', 'margin-top': '20px'}">
                      </button>
                    </p-footer>
                  </p-dialog>
                </div>
                
                <!--CR Search-->
                <div class="card" *ngIf="findCrStatusProcess.displayForm">
                  <p-dialog header="Find CR Status" [(visible)]="findCrStatusProcess.displayForm" modal="modal"
                    showEffect="fade" [style]="{width: '80%'}">
                    <!--div class="ui-g">
                      <div class="ui-g-3">
                        <b>Software Item Type </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b>: </b>
                          <p-dropdown [options]="softwareTypes" [disabled] = "true"
                            [(ngModel)]="findCrStatusProcess.selectedSoftwareType" filter="true" [virtualScroll]="true">
                            <ng-template let-item pTemplate="selectedItem">
                              {{item.label}}
                            </ng-template>
                            <ng-template let-item pTemplate="item">
                              {{item.label}}
                            </ng-template>
                          </p-dropdown>
                        </span>
                      </div>
                    </div-->                    
                    <div class="ui-g">
                      <div class="ui-g-3">
                        <b>Software Product Build </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b>: </b>
                          {{findCrStatusProcess.selectedSoftwareBuild}}
                        </span>
                      </div>
                    </div>
                    <div class="ui-g">
                      <div class="ui-g-3">
                        <b>Enter CRs </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b>: </b>
                          <input pInputText type="text" [(ngModel)]="findCrStatusProcess.selectedCrs" (input)="onChangeCRList()">
                          <span *ngIf="findCrStatusProcess.isMaxLength" style="color:red; padding-left: 5px;">Maximum is 20 CRs</span>
                        </span>
                      </div>
                    </div>
                    <!--div class="ui-g">
                      <div class="ui-g-3">
                        <b>Enter Software Item </b>
                      </div>
                      <div class="ui-g-9">
                        <span><b>: </b>
                          <input pInputText type="text" [(ngModel)]="findCrStatusProcess.selectedSoftwareBuild">
                        </span>
                      </div>
                    </div-->
                    <div class="ui-g">
                      <div class="ui-g-12">
                        <p-table #crFixStatusTable [value]="findCrStatusProcess.crList" [columns]="crSearchCols" [scrollable]="true" scrollHeight="380px"
                          [(selection)]="findCrStatusProcess.selectedCrsToAdd" selectionMode="multiple"     
                          [globalFilterFields]="['crId','softwareImageBuild','fixStatus','title','type','area','subsystem','functionality','softwareImageIntegratedBuild','']">
                          <ng-template pTemplate="caption">
                            <div class="ui-g">
                              <div class="ui-g-12" style="padding: 0px;">
                                <div style="float: right">
                                  <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                  <input #crFixStatusGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Filter"
                                    (input)="crFixStatusTable.filterGlobal($event.target.value, 'contains')" style="width: auto;">
                                  <i class="ui-icon-cancel" id="clearCrSearchFilter" pTooltip="Clear Filter" (click)="crFixStatusGlobalFilter.value=''; crFixStatusTable.filterGlobal('', 'contains')"
                                    style="margin:4px 4px 0 0"></i>
                                  <i class="ui-icon-refresh" id="refreshRelease" pTooltip="Refresh" style="margin:4px 4px 0 0"></i>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                          <ng-template pTemplate="header" let-columns>
                            <tr>
                              <th style="width:30px;"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                              <th *ngFor="let col of columns" id ="{{col.field}}" [pSortableColumn]="col.field"  [ngStyle]="col.style">
                                <span>{{col.header}}</span>
                                <p-sortIcon [field]="col.field"></p-sortIcon>
                              </th>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr>
                              <td style="width:30px;"><p-tableCheckbox *ngIf="rowData.fixStatus !== 'Fixed' && rowData.fixStatus !== 'N/A'"
                                [disabled] = "rowData.fixStatus === 'Fixed' || rowData.fixStatus === 'N/A'"
                                [value]="rowData"></p-tableCheckbox></td>
                              <td *ngFor="let col of columns">
                                  {{rowData[col.field]}}
                              </td>
                            </tr>
                          </ng-template>
                          <ng-template pTemplate="emptymessage">
                            <tr>
                              <td>No data found.</td>
                            </tr>
                          </ng-template>
                        </p-table>
                      </div>
                    </div>
                  <p-footer>
                    <div class="ui-g">
                      <div class="ui-g-12" *ngIf="findCrStatusProcess.errorMessage !== ''" class="message message-error">
                        {{findCrStatusProcess.errorMessage}}
                      </div>
                    </div>
                    <p-progressSpinner *ngIf="findCrStatusProcess.searchInProgress" 
                                        [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                    <button pButton id="searchCrButton" type="button" (click) = "getCrFixDetails()"
                            [disabled]="findCrStatusProcess.searchInProgress || findCrStatusProcess.selectedSoftwareType === undefined ||
                                        findCrStatusProcess.selectedSoftwareBuild === '' || findCrStatusProcess.selectedCrs === '' || findCrStatusProcess.isMaxLength" 
                            label="Search CR(s)" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
                    <button pButton id="addCrButton" type="button" (click) = "addCrsToST()"
                            [disabled]="findCrStatusProcess.searchInProgress || findCrStatusProcess.selectedCrsToAdd?.length === 0" 
                            label="{{findCrStatusProcess.serviceTaskID === undefined ? 'Request Service Build': 'Add CR(s)'}}" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
                    
                  </p-footer>
                  </p-dialog>
                </div>
                
                <p-dialog header="Search Request" [(visible)]="crSearchResultProcess.displayFormResponse" modal="modal" showEffect="fade" [style]="{width: '600px'}">
                  <div class="ui-g">
                    <div class="ui-g-12">
                      <p style="margin-top: 0;">A request for CR search is created. Please check later with below request.</p>
                      <span *ngIf="sharedData.appInfo.isElectronMode">
                        <a  (click)="goToUrl(crSearchResultProcess.requestUrl + crSearchResultProcess.crSearchRequestID)" 
                            style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word">
                            {{crSearchResultProcess.requestUrl + crSearchResultProcess.crSearchRequestID}}
                        </a>
                      </span>
                      <span *ngIf="!sharedData.appInfo.isElectronMode">
                          <a href="{{crSearchResultProcess.requestUrl + crSearchResultProcess.crSearchRequestID}}" target="_blank"
                              style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word">
                              {{crSearchResultProcess.requestUrl + crSearchResultProcess.crSearchRequestID}}
                          </a>
                      </span>
                    </div>
                  </div>
                </p-dialog>
                <!--CR Search End-->


                <!--Renew Dialog-->
                <div *ngIf="renewDialogProcess.showRenewDialog">
                  <p-dialog header="Renew "  [(visible)]="renewDialogProcess.showRenewDialog" modal="modal" showEffect="fade" [style]="{width: '600px'}">
                    <div class="ui-g-12">

                      <div class="ui-g">
                        <div class="ui-g-12">
                          <h4 style="margin-top: 0;">Proceeding will renew all licensegroups associated with this serial number.</h4>
                        </div>
                      </div>


                      <hr>
                      <div class="ui-g"  *ngFor="let licenseGroup of renewDialogProcess.selectedLicense?.licenseGroups">
                        <div class="ui-g-12">
                          <b style="color: grey;">License Group :</b>  <span> {{licenseGroup?.id}}</span>
                        </div>
                        <div class="ui-g-12">
                          <b style="color: grey;">Tools :</b>  <span> {{licenseGroup?.displayProductNames}}</span>
                        </div>
                      </div>
                      <hr>

                     
                      <div class="ui-g">
                          <div class="ui-g-1">
                            <i class="pi pi-question-circle"  style="cursor: pointer; font-size: 2rem; margin-top: 10px"></i>
                          </div>
                          <div class="ui-g-11">
                            <h4 style="margin-left: -12px">For pricing details, please refer to cart.</h4>
                          </div>
                      </div>
                     

                    </div>

                    <p-footer>
                      <div class="ui-g">
                        <div class="ui-g-12 message message-error" *ngIf="renewDialogProcess.errorMessageForRenew !== ''">
                          <span style="float: left;" id="errorMessageForRenew">{{renewDialogProcess.errorMessageForRenew}}</span>
                        </div>
                      </div>
                      <p-progressSpinner *ngIf="renewDialogProcess.fetchingPricingDetailsInprogress" 
                                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0',padding:'5px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                      <button pButton id="searchCrButton" type="button" [disabled]="renewDialogProcess.fetchingPricingDetailsInprogress==true || renewDialogProcess.errorMessageForRenew!==''" (click)="AddLicenseToCartWithPricingDetails()"
                      label="Renew" [style]="{'width': '150px', 'margin-top': '20px'}"></button> 
                    </p-footer>

                  </p-dialog>
                </div>
                <!--Renew Dialog End-->
                   
                <p-overlayPanel #commonOverlay [style]="{'max-width':'300px'}">
                    <span style="vertical-align: middle">
                        <p>{{overlayBody}}</p>
                    </span>
                </p-overlayPanel>
                <app-footer></app-footer>
            </div>
        </div>

        <!--app-rightpanel></app-rightpanel-->
        <!--app-config></app-config-->

        <div class="layout-mask"></div>
        <div class="layout-terminal" [style.display]="(sharedData.appInfo.isElectronMode && loggerPanel.terminalVisible)?'block':'none'"
        [ngClass]="{'layout-terminal-active':loggerPanel.terminalActive}" (click)="onTerminalClick($event)">
        <div class="layout-terminal-content">
          <a style="cursor: pointer" id="layout-terminal-button" class="layout-terminal-button"
            (click)="onTerminalButtonClick($event)">
            <i class="ui-icon-keyboard-arrow-up"></i>
          </a>
          <span class="layout-terminal-title">Logs : </span>
          <div #loggerFrame style="overflow-y: scroll; height: 92%;">
            <div #logger class="ui-g-12" style="padding: 0;" *ngFor="let msg of loggerPanel.logMessages">
              {{msg}}
            </div>
          </div>
          <!--p-terminal prompt=">"></p-terminal-->
        </div>
      </div>
      <p-toast key="notifications" position="top-right"  width="'500px'" [style]="{marginTop: '40px'}" ></p-toast>
    </div>
    <p-dialog  position="top-right"  [draggable] = "false" [closable]="false" [style]="{width: '600px'}" [(visible)]="sharedData.appInfo.isElectronMode && downloadQueue.showProgress">
      <p-header>Download Progress</p-header>
      <div class="ui-g">
        <div class="ui-g-12" style="word-break: break-all;">
        Downloading {{downloadStatus.fileName}}
        <h5 *ngIf="downloadStatus.totalDownloadSize!=0">Downloaded {{downloadStatus.downloadedSizeString}} of {{downloadStatus.totalDownloadSizeString}} at {{downloadStatus.downloadSpeedString}}/s</h5>
        </div>
      </div>
    <p-progressBar mode="determinate" [value]="downloadStatus.downloadProgress"></p-progressBar>
    </p-dialog>
    <p-dialog header="Search Release from Image Build" [(visible)]="searchReleaseByImageProcess.displaySearchByImageDialog" modal="modal"
    showEffect="fade" [style]="{width: '800px'}">
    <div class="ui-g" *ngIf="!searchReleaseByImageProcess.displaySearchResults">
      <div class="ui-g-3">
        <span><b>Image Build: </b></span>
      </div>
      <div class="ui-g-9">
        <span class="md-inputfield md-inputfield-fill">
          <textarea id="ImageBuildInput" [(ngModel)]="searchReleaseByImageProcess.imageBuild"placeholder="Eg: MPSS.HI.4.1.1.c1-00085-LAHAINA_GEN_PACK-1" 
            pInputTextarea autoResize="autoResize" [style]="{width: '90%'}">
          </textarea>
          <i class="ui-icon-cancel" id="clearSTImageFilter" pTooltip="Clear Filter" (click)="searchReleaseByImageProcess.imageBuild = undefined;" style="cursor: pointer;"></i>
        </span>
      </div>
    </div>
    <div class="ui-g" *ngIf="searchReleaseByImageProcess.displaySearchResults">
      <div class="ui-g-12">
        <p-table id ="searchReleaseByImageTable" #searchReleaseByImageTable selectionMode="single" [(selection)]="searchReleaseByImageProcess.selectedProductRelease" [value]="searchReleaseByImageProcess.softwareProductReleases"  [paginator]="true" [rows]="10" 
                  sortField="shipDate" [sortOrder]="-1" [globalFilterFields]="['tag','spfTag','spf','softwareProduct']">
              <ng-template pTemplate="caption">
                  <div style="text-align: right">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input #versionGlobalFilter id="createSTFilterRelease" class="filter-clear" type="text" pInputText size="50" placeholder="SP Tag/SPF Tag/SPF/SP"
                      (input)="searchReleaseByImageTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th pSortableColumn="tag">
                    SP Tag
                    <p-sortIcon field="tag"></p-sortIcon>
                  </th>
                  <th pSortableColumn="spfTag">
                    SPF Tag
                    <p-sortIcon field="spfTag"></p-sortIcon>
                  </th>
                  <th pSortableColumn="spf">
                    SPF
                    <p-sortIcon field="spf"></p-sortIcon>
                  </th>
                  <th pSortableColumn="softwareProduct">
                    SP
                    <p-sortIcon field="softwareProduct"></p-sortIcon>
                  </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">No Valid Release for Given Image</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.spfTag}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.spf}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProduct}}</span></td>
              </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <p-footer>
      <p-progressSpinner *ngIf="searchReleaseByImageProcess.loadingAvailableRelease" 
      [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
        <button pButton id="STSearchByImage"  type="button" *ngIf="!searchReleaseByImageProcess.displaySearchResults" (click) = "searchReleaseInfoByImage()" label="Search" [disabled]="searchReleaseByImageProcess.imageBuild===undefined"></button>
        <button pButton id="ClearResults" type="button" *ngIf="searchReleaseByImageProcess.displaySearchResults" (click) = "searchReleaseByImageProcess.displaySearchResults=false;searchReleaseByImageProcess.selectedProductRelease=undefined" label="Back"></button>
        <button pButton id="selectRelease" type="button" *ngIf="searchReleaseByImageProcess.displaySearchResults" (click) = "onSelectButtonClick()" label="Select Release" [disabled]="searchReleaseByImageProcess.selectedProductRelease===undefined"></button>
      </div>
    </p-footer>
    </p-dialog>
    <div class="card" *ngIf="pklaCheckFailed">
      <p-dialog id="pklaCheckHeader" header="Product Kit License Agreement" [(visible)]="pklaCheckFailed" modal="modal"
      showEffect="fade" [style]="{width: '550px'}" [closable]="false">
        <div class="ui-g">
            <div class="ui-g-12">
            <p id="pklaCheck">You have not signed Product Kit License Agreement.Clicking on Accept will redirect you to License Agreement.</p>
            <P>If License Agreement is not signed you will not be able to use Qualcomm Package Manager.</P>
            </div>
        </div>
        <p-footer>
          <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button id="acceptPkla" pButton type="button" (click)="acceptPkla()" label="Accept"></button>
            <button id="rejectPkla" pButton type="button" (click)="onLogout(null)" label="Logout"></button>
          </div>
        </p-footer>
      </p-dialog>
    </div>

        <!--Shared Generic MessageBox-->
   <div class="card" *ngIf="sharedData.sharedMessage.showMessageBox">
    <p-dialog id="MessageBoxDialog" header="{{sharedData.sharedMessage.headLine}}" [(visible)]="sharedData.sharedMessage.showMessageBox" modal="modal"
        showEffect="fade" [style]="{width: '550px'}"  [transitionOptions]="'0ms'">
        <div class="ui-g-12" *ngFor="let msg of sharedData.sharedMessage.contents">
          <span>{{msg}} </span>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <button pButton id="triggerSystemBuildNo" type="button" [style]="{width: '150px'}" (click)="sharedData.sharedMessage.showMessageBox = false" label="OK"></button>
            </div>
        </p-footer>
    </p-dialog>
   </div>

</div>

