<div class="ui-g ui-fluid" style="min-height: calc(80vh);">
  <div class="ui-g-12">
    <div class="card card-w-title" style="height: 100%;">
      <div style="display:flex; justify-content: center; align-items: center; padding-top: 10%">
        <i style="font-size: 64px;" class="pi pi-clock"></i>
      </div>
      <div style="display: flex; justify-content: center; align-items: center;">
        <h1 style="font-size: 48px; font-family: roboto; ">Coming Soon</h1>
      </div>


      <!--p-table  #documentsTable id="documentsTable" *ngIf="app.sharedData.appInfo.workOffline==false"  [value]="products" [paginator]="true" [rows]="20" dataKey="fileName" sortField="fileName" [sortOrder]="-1" reorderableColumns="true" 
        selectionMode="single" (onRowSelect)="onDocumentRowSelect($event)"
        [globalFilterFields]="['fileName','dcnNumber']">
      <ng-template pTemplate="caption">
        <div style="text-align: right">
          <i class="pi pi-search" id="documentSearch" style="margin:4px 4px 0 0"></i>
          <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Filter"
            (input)="documentsTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
          <i class="ui-icon-cancel" id="clearDocumentSearchFilter" pTooltip="Clear Filter" (click)="onFilterClear()"
            style="margin:4px 4px 0 0"></i>
        </div>
      </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 150px;"></th>
                <th id="fileName" style="width: 250px;" pSortableColumn="fileName">Name<p-sortIcon field="fileName"></p-sortIcon></th>
                <th id="dcnNumber" style="width: 250px;" pSortableColumn="dcnNumber">DCN Number<p-sortIcon field="dcnNumber"></p-sortIcon></th>
            </tr> 
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                <td>
                  <div class="p-col-12 p-md-3" style="text-align:center">
                    <img [attr.alt]="rowData.displayName" src="assets/images/QPM48.png">
                  </div>
                </td>
                <td>{{rowData.fileName}}</td>
                <td>{{rowData.dcnNumber}}</td>
            </tr>
      </ng-template>
    </p-table-->
    </div>
   </div>
</div>