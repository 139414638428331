<div class="ui-g" style="min-height: calc(80vh);">
  <div class="ui-g-12">
      <div class="card card-w-title">
          <h1>Download History</h1> 
          <p-table #historyTable  [value]="app.downloadHistory" [columns]="cols" [scrollable]="true" scrollHeight="380px"
 sortField="requestedDate" [sortOrder]="-1" [paginator]="true" [rows]="10"[globalFilterFields]="['tag','baseSoftwareBuild.softwareProductBuild','targetSoftwareBuild?.softwareProductBuild','softwareImageBuild','distro.softwareDistro','downloadDate','status','requestedDate']">
          <ng-template pTemplate="caption">
            <div style="text-align: right">
              <i class="pi pi-search" id="searchDownloads" style="margin:4px 4px 0 0"></i>
              <input #historyGlobalFilter class="filter-clear" id="" type="text" pInputText size="50" placeholder="Filter"
                (input)="historyTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="downloadsVersion" pSortableColumn="tag"><span style="vertical-align: middle">Version </span><p-sortIcon field="tag"></p-sortIcon></th>
              <th id="downloadsBaseSoftwareBuild" pSortableColumn="baseSoftwareBuild?.softwareProductBuild"><span style="vertical-align: middle">Base Software Build </span><p-sortIcon field="baseSoftwareBuild.softwareProductBuild"></p-sortIcon></th>
              <th id="downloadsTargetSoftwareBuild" pSortableColumn="targetSoftwareBuild?.softwareProductBuild"><span style="vertical-align: middle">Target Software Build </span><p-sortIcon field="targetSoftwareBuild.softwareProductBuild"></p-sortIcon></th>
              <th id="downloadsBaseSoftwareImageBuild" pSortableColumn="softwareImageBuild"><span style="vertical-align: middle">Base Software Image Build </span><p-sortIcon field="softwareImageBuild"></p-sortIcon></th>
              <th id="downloadsTargetSoftwareImageBuild" pSortableColumn="targetSoftwareImageBuild"><span style="vertical-align: middle">Target Software Image Build </span><p-sortIcon field="targetSoftwareImageBuild"></p-sortIcon></th>
              <th id="downloadsDistroName" pSortableColumn="distro.softwareDistro"><span style="vertical-align: middle">Distro Name</span><p-sortIcon field="distro"></p-sortIcon></th>
              <th id="downloadsStartDate" pSortableColumn="requestedDate"><span style="vertical-align: middle">Download Start Date</span><p-sortIcon field="requestedDate"></p-sortIcon></th>
              <th id="downloadsEndDate" pSortableColumn="downloadDate"><span style="vertical-align: middle">Download End Date</span><p-sortIcon field="downloadDate"></p-sortIcon></th>
              <th id="downloadsStatus" pSortableColumn="status"><span style="vertical-align: middle">Download Status</span><p-sortIcon field="status"></p-sortIcon></th>
              <th id="downloadsAction"><span style="vertical-align: middle">Action</span></th>   
           </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td><span style="vertical-align: middle ; word-wrap: break-word">{{rowData.tag}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.baseSoftwareBuild?.softwareProductBuild}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.targetSoftwareBuild?.softwareProductBuild}}</span></td>
                  <td><span style="vertical-align: middle ; word-wrap: break-word">{{rowData.softwareImageBuild}}</span></td>
                  <td><span style="vertical-align: middle ; word-wrap: break-word">{{rowData.targetSoftwareImageBuild}}</span></td>
                  <td><span style="vertical-align: middle">{{rowData.distro.softwareDistro}}</span></td>
                  <td><span style="vertical-align: middle">{{rowData.requestedDate}}</span></td>
                  <td><span style="vertical-align: middle">{{rowData.downloadDate}}</span></td>
                  <td><span style="vertical-align: middle">{{rowData.status}}</span></td>
                  <td>
                    <div class="action-icon" *ngIf="app.sharedData.appInfo.isElectronMode && rowData.status==='Success' ">
                      <i class="ui-icon-folder" id="open" pTooltip="Open" style="cursor:pointer" (click)="app.onOpenFolderClick(rowData.downloadLocation)"></i>
                    </div>
                    <div class="action-icon" *ngIf="app.sharedData.appInfo.isElectronMode && rowData.status==='Failed' ">
                      <i class="ui-icon-refresh" id="retrDownload" pTooltip="Retry" style="cursor:pointer" (click)="retryDownload(rowData)"></i>
                    </div>
                    <div class="action-icon" *ngIf="app.sharedData.appInfo.isElectronMode && rowData.status==='Queued' ">
                      <i class="ui-icon-clear" id="cancelDownload" pTooltip="Cancel Download" style="cursor:pointer" (click)="cancelDownload(rowData)"></i>
                    </div>
                  </td>  
                </tr>
          </ng-template>
        </p-table>
    </div>
  </div>
</div>