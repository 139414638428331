import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedData } from 'src/app/common/shared-data';
import { RestResponse } from 'src/app/models/response';
import { String } from 'typescript-string-operations';
import { ApiUrls } from '../ApiUrls';
import { ServiceUtils } from '../ServiceUtils';

@Injectable()
export class UserProfileService {

  constructor(private sharedData: SharedData, private serviceUtils : ServiceUtils, private apiUrls : ApiUrls) { }

  public getUserPic() :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.userPic, this.sharedData.userInfo.username));
    return observable;
  }
}
