import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import isElectron from 'is-electron';
import {SelectItem, MenuItem, TreeNode, ContextMenu} from 'primeng/primeng';
import { routes } from 'src/app/app.routes';
import { QPMResponse } from 'src/app/models/response';
import { interval, Observable } from 'rxjs';
import { DataServiceProducer, ApiType } from 'src/app/service/Factory/DataServiceProducer';
import { AppMainComponent } from 'src/app/app.main.component';
import { commType, RefreshInvokationType, SharedData } from 'src/app/common/shared-data';
import { QLogger } from 'src/app/common/logger';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/service/auth.service';
import { Utils } from 'src/app/common/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input() email;
  @Input() password;
  @Input()  errorMsg;
  @Output() credentails = new EventEmitter<number>();
  @ViewChild('copyMenu') copyMenu: ContextMenu;
  private logSrc:string = "Login-Component";
  public retryInterval: number = 0.25;
  public retryTimer: number = 0;
  retryTimerSubscription:any;

  isElectronMode = false;
  electron = null;
  /*constructor(private router: Router) { 
    if(isElectron()){
      const electron = window.require('electron');
      electron.remote.getCurrentWindow().setMenuBarVisibility(false);
    }
  }*/
  private webClient;
  private limeClient;
  items: MenuItem[];
  figureHeadEmail:string;
  figureHeadPassword:string;
  useFigureHead:boolean;
  loginTimer:any;
  pklaCheckFailed:boolean;
  constructor(private router: Router, public sharedData: SharedData,
              private service:DataServiceProducer, private utils : Utils,
              public authService: AuthService) {
    QLogger.LogInfo(this.logSrc,"Login Component Creation");
    if(isElectron()){
      this.isElectronMode = true;
      this.sharedData.appInfo.isElectronMode = true;
      this.electron = window.require('electron');
      this.limeClient =  service.getServiceInstance(ApiType.Client) as ClientService;
      this.loginTimer = null;
    }

    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
}

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc,"Login Component Initialization");
    this.pklaCheckFailed = false;
    this.retryInterval = 0.25;
    if(this.isElectronMode){
    
      this.electron.remote.getCurrentWindow().unmaximize();
      this.electron.remote.getCurrentWindow().setSize(600,800);
      this.electron.remote.getCurrentWindow().center();
      this.electron.remote.getCurrentWindow().show();
      this.items = [
        {label: 'Work Offline', id:"workOffline",command: (event) => this.workOffline()}
    ];
      //check license
      
      let figureHeadResponse : Observable<QPMResponse>;
      figureHeadResponse = this.webClient.getFigureHeadCredentials();
      figureHeadResponse.subscribe(
      (data:QPMResponse) => {
        try {
          if (data.isSuccess() && data.getData()) {
            QLogger.LogInfo(this.logSrc," Figurehead credentials Present");
            let obj = JSON.parse(data.getData());
            this.figureHeadEmail = obj.username
            this.figureHeadPassword = obj.password;
          }
          else
          {
            this.figureHeadEmail = undefined;
          }
        } catch (error) {
           QLogger.LogInfo(this.logSrc,"Figurehead credentials Failed : "+error);
        }

        if(!this.sharedData.appInfo.isLoggedOut)
        this.autoLogin();
      });
    }
    else{
      this.authService.isLoggedIn$.subscribe((loggedIn: boolean)=>{
        if(loggedIn){
          this.router.navigate(['/main']);
        }
      });
    }
  }
  ngOnDestroy()
  { 
    if(this.loginTimer)
    clearInterval(this.loginTimer);
    clearInterval(this.retryTimerSubscription);
  }
  validateUser(email: string, password: string,useFigureHead:boolean) {
    
    QLogger.LogInfo(this.logSrc,"Login");
    clearInterval(this.retryTimerSubscription);
    var validateInternalUser = new RegExp('@quicinc.com|qti.qualcomm.com$');
    var isInternal = validateInternalUser.test(email);
    this.pklaCheckFailed = false;
    let username :string;
    if(isInternal)
    {
      let usernameList = this.email.split('@');
      if(usernameList)
      username = usernameList[0];
    }
    else
    username = email;
    this.password = password;
    let response : Observable<QPMResponse>;
    response = this.webClient.login(username, password);
    response.subscribe(
      (data:QPMResponse) => {
        let errorStr = (data.isSuccess())? data.getData() : data.getError();
        this.errorMsg ="";
        // Success? 
        if (errorStr == "") {
          QLogger.LogInfo(this.logSrc,"Login Successful");
          this.sharedData.userInfo.username = username;
          this.getTokenAndCheckPkla();
          this.password = "";
          this.retryInterval = 0.25;
          QLogger.LogInfo(this.logSrc, "Set user as: " + this.sharedData.userInfo.username);
        }
        //Retry login in exponential intervals upto 1 hour
        else if(data.getErrorDetail().includes("Could not resolve host")){
          this.retryLogin(useFigureHead, this.retryInterval);
          //Show timer in retry message
          this.retryTimer = this.retryInterval * 60 * 1000;
          this.retryTimerSubscription = setInterval(() => {
            if(this.retryTimer < 1) {
              clearInterval(this.retryTimerSubscription);
            }
            this.retryTimer -= 1000;
            var h = Math.floor(this.retryTimer / 3600000);
            var m = Math.floor((this.retryTimer % 3600000) / 60000);
            var s = Math.floor(((this.retryTimer % 360000) % 60000) / 1000);
            let time = ((h * 60) + m) + ":" + (s < 10? "0" + s : s);
            this.errorMsg ="Failed to login. Retry in "+ time;
          }, 1000);
          if(this.retryInterval < 65){
            this.retryInterval *= 2;
          }
        }
        else{
          if(!useFigureHead)
          this.errorMsg = errorStr;
          else 
          this.errorMsg ="Failed to login using Figurehead Credentials. Please check with your IT Admin";
          QLogger.LogError(this.logSrc,"Login Failed - " + data.getError() + " - " + data.getErrorDetail());
          if(this.sharedData.appInfo.logResponse){
            QLogger.LogError(this.logSrc,  "Login Failed - Response : " +JSON.stringify(data));
          }
        }
        if(!this.isElectronMode){
          this.router.navigate(['/main/home']);
        }
      }
    )
  }

  retryLogin(useFigureHead:boolean, delay: number){
    setTimeout(() => {
      this.validateUser(this.email, this.password, useFigureHead)
    }, delay * 60 * 1000);
  }

  autoLogin(){
      QLogger.LogInfo(this.logSrc,"Auto Login");
      this.pklaCheckFailed = false;
      let response : Observable<QPMResponse>;
      response = this.webClient.login();
      response.subscribe(
        (data:QPMResponse) => {
          if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc,"Auto Login Successful");
            if(this.isElectronMode) {
              //electron.remote.getCurrentWindow().setMenuBarVisibility(false);
              
              this.sharedData.userInfo.username = data.getData();
              this.getTokenAndCheckPkla();
              QLogger.LogInfo(this.logSrc, "Set user as: " + this.sharedData.userInfo.username);
            }
          } 
          else{
           
            QLogger.LogError(this.logSrc,"Auto Login Failed - " + data.getError() + " - " + data.getErrorDetail());
            if(this.isElectronMode && this.sharedData.appInfo.isLoggedOut==false)
            {
              if(this.figureHeadEmail)
              {
                  QLogger.LogInfo(this.logSrc,"Trying logging with Figure Head Credentials");
                  this.validateUser(this.figureHeadEmail,this.figureHeadPassword,true);
              }
              else 
              {
                this.loginTimer = setInterval(() => {
                    this.tryFigureHeadCredentails();
                }, 300000);  
                 
              }
            }
            
          }
        }
      )
  }
  tryFigureHeadCredentails()
  {
    let response : Observable<QPMResponse>;
    response = this.webClient.getFigureHeadCredentials();
    response.subscribe(
      (data:QPMResponse) => {
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Fetched Figurehead credentials Trying to login");
          clearInterval(this.loginTimer);
          let obj = JSON.parse(data.getData());
          this.validateUser(obj.username,obj.password,true);
        }
      });


  }
  getTokenAndCheckPkla(){
    if(this.isElectronMode){
      let response : Observable<QPMResponse>;
      response = this.webClient.getToken();
      response.subscribe(
        (data:QPMResponse) => { 
          let obj = JSON.parse(data.getData());
          this.sharedData.userInfo.vordelToken = obj.access_token as string;
          this.sharedData.userInfo.tokenModifiedTime = obj.hostTimeUTC as string;
          this.checkPkla();
        }
      );
    }
  }
  checkPkla()
  {
    let pklaResponse : Observable<QPMResponse>;
    pklaResponse = this.webClient.checkPkla(this.sharedData.userInfo.username);
    pklaResponse.subscribe(
      (data:QPMResponse) => { 
        if (data.isSuccess()) {
          let obj = JSON.parse(data.getData());
          this.sharedData.licenses.common.itemsAddedToCart=[];//Clear Cart
          this.sharedData.updateCommunicationSubcribers(commType.UpdateCartItems, this.sharedData.licenses.common.itemsAddedToCart);
          this.sharedData.licenses.common.toolUserRoles=[];
          this.sharedData.licenses.common.toolUserRoles=obj?.roles;
          this.sharedData.licenses.common.partyId=null;
          this.sharedData.licenses.common.partyId=obj?.partyId;
          this.sharedData.licenses.common.vordelUserCountryCode=null;
          this.sharedData.licenses.common.vordelUserCountryCode=obj?.country;
          let errorCode = obj.errorCode as number;
          if (((errorCode < 200) || (errorCode > 299)) && (errorCode != -1)){
            QLogger.LogError(this.logSrc,"Error Checking PKLA");
            this.pklaCheckFailed = true;
            this.webClient.logout().subscribe(); // Invalidate the token
          }
          else{
               if(this.isElectronMode){
                this.sharedData.appInfo.isLoggedOut = false;
                this.electron.remote.getCurrentWindow().hide();
                this.utils.runQPMCLICommands(RefreshInvokationType.OnLogin, this.logSrc);
               }
               this.router.navigate(['/main/home']);
          }
            
        }
        else{
          QLogger.LogError(this.logSrc,"Error Checking PKLA");
          this.errorMsg ="Error Fetching ProductKit License Agreement";
        }

    });
  }
  goToHome(){
    this.router.navigate(['/main/home']);
  }

  quitApp(){
    this.electron.remote.app.quit();
  }

  workOffline()
  {
    QLogger.LogInfo(this.logSrc,"Work Offline");

    if(this.isElectronMode){
      QLogger.LogInfo(this.logSrc, "Set user as: " + this.sharedData.userInfo.username);
      this.electron.remote.getCurrentWindow().hide();
      this.sharedData.userInfo.username = "offline";
      this.sharedData.appInfo.workOffline = true;
      this.router.navigate(['/main/tools/find']);
    }

  }
  onFigureHeadLoginCLick()
  {
    QLogger.LogInfo(this.logSrc,"Login using Figurehead Credentials");
    this.validateUser(this.figureHeadEmail, this.figureHeadPassword,true);
  }
  
  
  goToUrl(url: string) {
    if (this.sharedData.appInfo.isElectronMode) {
        const { shell } = window.require('electron');
        shell.openExternal(url);
    }
    else {
        window.open(url, "_blank");
    }
  }
}
