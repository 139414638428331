import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng';
import { Observable, Subscription } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { CveCrMappingRequest, cveCrInfoDetails, cveCrMappingDetails } from 'src/app/models/lime-web-client';
import { QPMResponse } from 'src/app/models/response';
import { crFixDetail, ServiceTaskInfo } from 'src/app/models/software-catalog-client';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';

@Component({
  selector: 'app-cvelookup',
  templateUrl: './cvelookup.component.html',
  styleUrls: ['./cvelookup.component.css']
})
export class CveLookupComponent implements OnInit {
  private logSrc:string = "CveLookup-Component";
  private subscriptionSrc:string = "CveLookup-Component";
  private webClient: WebClientService;
  private visibiltySubcription: Observable<boolean>;
  private softwareCatalogClient: SoftwareCatalogClientService;
  
  currentTab: number;
  softwareTypes: any[];
  isMaxLength: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public app: AppMainComponent, private utils: Utils, private service: DataServiceProducer, public zone: NgZone){
    QLogger.LogInfo(this.logSrc, "CveCrMapping Component Creation");
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "CVELookup Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);
    this.app.setUserInfo();
    this.app.sharedData.resetVisibility();
    this.app.sharedData.setVisibility();
    this.setCrSearchTableColumn();
    this.isMaxLength = false;
    this.loadInitData();
    this.resetAddCrToServiceTaskProcess();
    this.resetFilterValues();
  }

  ngAfterViewInit() {    
    this.visibiltySubcription = this.app.sharedData.subscribeVisibility(this.subscriptionSrc);
    this.visibiltySubcription.subscribe((updated)=>{
      if(updated){
        this.loadInitData();
      }
    });
  }

  loadInitData(){
    this.getSecurityBulletins();
  }
  
  //#region CR Fix Status
  cveLookupCols: any[];
  cveCrMapping: cveCrMappingDetails[] = [];
  securityBulletins: string[] = [];
  securityBulletinsSelectItems: SelectItem[];
  selectedCVEs: string;
  selectedSecurityBulletins: string[] = [];
  loadingResult: boolean;
  loadingSecurityBulletin: boolean;
  errorMessage: string;
  selectedcveCrMappingCrsToAdd: cveCrMappingDetails[] = [];

  setCrSearchTableColumn(){
    this.cveLookupCols = [];
    this.cveLookupCols.push({ field: 'cveNumber', header: 'CVE Number', isFilterable: true });
    this.cveLookupCols.push({ field: 'crId', header: 'Change Request', isFilterable: true });
    this.cveLookupCols.push({ field: 'cwe', header: 'CWE', isFilterable: true });
    this.cveLookupCols.push({ field: 'rating', header: 'rating', isFilterable: true});
    this.cveLookupCols.push({ field: 'attacker', header: 'Attacker', isFilterable: true});
    this.cveLookupCols.push({ field: 'allBulletins', header: 'Bulletin', isFilterable: true});
    this.cveLookupCols.push({ field: 'weaknessType', header: 'Weekness Type', isFilterable: true });
    this.cveLookupCols.push({ field: 'victim', header: 'Victim', isFilterable: true });
    this.cveLookupCols.push({ field: 'area', header: 'Area', isFilterable: true});
    this.cveLookupCols.push({ field: 'subsystem', header: 'Subsystem', isFilterable: true });
    this.cveLookupCols.push({ field: 'functionality', header: 'Functionality'});
  }

  //#region Events
  onChangeCVEList()
  {
    let cveList = this.extractCVEList(this.selectedCVEs).map(Number);
    if (cveList.length > 20) {
        this.isMaxLength = true;
    }
    else {
        this.isMaxLength = false;
    }
  }
  extractCVEList(cveList: string): string[] {
    if (cveList === undefined || cveList === "") {
        return null;
    }
    let cveListUnique: Set<string>;
    cveListUnique = new Set<string>(cveList.match(/[^ ,;]+/g));
    return Array.from(cveListUnique.values());
}
  onSearch(event){
    let crs = this.extractCVEList(this.selectedCVEs);
    if(crs === null || crs?.length < 0) {
      this.errorMessage = "Invalid CVE(s) value";
      return;
    }
    this.getCVECRMapping();
  }
  

  getSecurityBulletins(){
    let response : Observable<QPMResponse>;
    this.errorMessage = "";
    this.loadingSecurityBulletin = true;
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get Security Bulletins - Request");
    }
    this.securityBulletins = [];
    this.securityBulletinsSelectItems = [];
    response = this.webClient.getSecurityBulletins();
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get Security Bulletins - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get Security Bulletins - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.securityBulletins = obj.securityBulletins as string[];
            this.securityBulletinsSelectItems = this.utils.getSelectItemsFromStrings(this.securityBulletins);
          }
        }
        else{
          QLogger.LogError(this.logSrc,  "Get Security Bulletins - Failed");
          QLogger.LogError(this.logSrc,  "Get Security Bulletins - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.errorMessage = data.getError();
        }
        this.loadingSecurityBulletin = false;
      });
  }

  getCVECRMapping()
  {
    let response : Observable<QPMResponse>;
    this.errorMessage = "";
    let request:CveCrMappingRequest;
    request = {
      cveNumbers: this.extractCVEList(this.selectedCVEs),
      securityBulletins: this.selectedSecurityBulletins
    }
    this.loadingResult = true;
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get CVE CR Mapping - Request: "+ JSON.stringify(request));
    }
    this.cveCrMapping = [];
    response = this.webClient.getCveCrMapping(request);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get CVE CR Mapping - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get CVE CR Mapping - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.cveCrMapping = obj.cveCrInfo as cveCrMappingDetails[];
            if(obj.invalidCVEs !== undefined || obj.invalidCVEs !== null){
              let invalidCVEs = obj.invalidCVEs as string[];
              if(invalidCVEs.length > 0) this.errorMessage = "Invalid CVEs: " + invalidCVEs.join(", ");
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc,  "Get CVE CR Mapping - Failed");
          QLogger.LogError(this.logSrc,  "Get CVE CR Mapping - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.errorMessage = data.getError();
        }
        this.loadingResult = false;
      });

  }
  //#endregion
  //#region Functions

  clearAllFilterResult(){
    this.selectedCVEs = undefined;
    this.loadingResult = false;
    this.errorMessage = "";
  }
  //#endregion

  //#region Add CRs to New ST and Existing ST  
  @ViewChild('serviceTaskTable') serviceTaskTable;
  @ViewChild('serviceTaskGlobalFilter') serviceTaskGlobalFilter;

  filterOptionMaster: SelectItem[] = [{label:"All", value: undefined},{label:"Yes", value: true},{label:"No", value: false}];
  filterOptionStatus: SelectItem[] = [{label:"All", value: undefined},{label:"Active", value: "Active"},{label:"Abandoned", value: "Abandoned"}];
  filterOptionReqSource: SelectItem[] = [{label:"QPM", value: "QPM"},{label:"Planner", value: "Planner"},{label:"Salesforce", value: "Salesforce"}];

  filterValues:{
    serviceTaskId: string,
    requestedDate: string,
    baseBuildSpf: string,
    baseBuild: string,
    requestAppId: string,
    latestRelease: string,
    totalReleases: string,
    requester: string,
    requestSource: any[],
    master: boolean,
    customer: string,
    statusString: string
  }
  
  resetFilterValues(){
    this.filterValues = {
      serviceTaskId: '',
      requestedDate: '',
      baseBuildSpf: '',
      baseBuild: '',
      requestAppId: '',
      latestRelease: '',
      totalReleases: '',
      requester: '',
      requestSource: [],
      master: undefined,
      customer: '',
      statusString: undefined
    }
  }

  addCrToServiceTaskProcess:{
    displayFormCVE: boolean;
    cols: any[];
    serviceTasks: ServiceTaskInfo[];
    selectedServiceTask: ServiceTaskInfo;
    errorMessage: string;
    loadingServiceTasks: boolean;
  }

  resetAddCrToServiceTaskProcess(){
    this.addCrToServiceTaskProcess={
      displayFormCVE: false,
      cols: [],
      serviceTasks: [],
      selectedServiceTask: undefined,
      errorMessage: '',
      loadingServiceTasks: false
    }
  }

  cveLookupStartAddCrtoServiceTaskProcess(){
    this.resetFilterValues();
    this.addCrToServiceTaskProcess.displayFormCVE = true;
    this.getAllServiceTasks();
  }

  cveAddCrsToST(){
    this.addCrToServiceTaskProcess.displayFormCVE = false;
    this.app.sharedData.crSearch.foundCrsToAdd = this.selectedcveCrMappingCrsToAdd.map(x => x.crId);
    this.router.navigate(["/main/software/servicetask/triage", this.addCrToServiceTaskProcess.selectedServiceTask.serviceTaskId]);
  }
  cveLookupAddCrsToNewST(){
    this.app.sharedData.crSearch.foundCrsToAdd = this.selectedcveCrMappingCrsToAdd.map(x => x.crId);
    this.router.navigate(["/main/software/servicetask/find"]);
  }

  getAllServiceTasks() {
    let response : Observable<QPMResponse>;

    // Get all service task ID's
    this.addCrToServiceTaskProcess.loadingServiceTasks = true;
    QLogger.LogInfo(this.logSrc, "Get Service Tasks");
    response = this.softwareCatalogClient.getServiceTasks(undefined);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Tasks Response - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Tasks Response - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.addCrToServiceTaskProcess.serviceTasks = [];
            obj.serviceTasks.forEach((task) => {
              //if(task.baseBuild){
                this.addCrToServiceTaskProcess.serviceTasks.push(task as ServiceTaskInfo);
              //}
            });
            this.addCrToServiceTaskProcess.serviceTasks.forEach((task) => {
              if (task.abandoned) task.statusString = "Abandoned";
              else task.statusString = "Active"

              if (task.master) task.masterString = "Yes";
              else task.masterString = "No"

              if (task.companyNames != null) {
                task.companyNameList = task.companyNames.split(/; /g);
                task.companyNameList.sort();
              }
              
              // Clean up requestSource and requestAppId data
              if (task.requestSource.indexOf('planner') > -1) {
                task.requestSource = "Planner";
              }
              else if (task.requestSource.indexOf('salesforce') > -1) {
                task.requestSource = "Salesforce";
              }
              else if (task.requestSource.indexOf('QPM') > -1) {
                task.requestSource = "QPM";
                
              }
              if(task.requestAppId ==null || task.requestAppId =="")
              {
                task.requestAppId = 'N/A';
              }
            });   
            this.errorMessage = "";
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Tasks - Failed");
          QLogger.LogError(this.logSrc, "Get Service Tasks - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.errorMessage = "Failed to get Service Tasks: " + data.getError();
        }
        this.addCrToServiceTaskProcess.loadingServiceTasks = false;
      });
    }
  //#endregion  
  //#region ExportToExcel

  exportToExcel(){
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Qualcomm Inc.';
    const worksheet = workbook.addWorksheet('New Sheet');
    worksheet.columns = [
      { header: 'CVE Number', key: 'cveNumber' },
      { header: 'Change Request', key: 'crId' },
      { header: 'CWE', key: 'cwe' },
      { header: 'Rating', key: 'rating' },
      { header: 'Attacker', key: 'attacker' },
      { header: 'Bulletin', key: 'allBulletins' },
      { header: 'Weekness Type', key: 'weaknessType' },
      { header: 'Victim', key: 'victim' },
      { header: 'Area', key: 'area' },
      { header: 'Subsystem', key: 'subsystem' },
      { header: 'Functionality', key: 'functionality' }
    ];
    this.cveCrMapping.forEach((c)=>{
      worksheet.addRow({
                        cveNumber: c.cveNumber,
                        crId: c.crId,
                        cwe: c.cwe,
                        rating: c.rating,
                        attacker: c.attacker,
                        allBulletins: c.allBulletins,
                        weaknessType: c.weaknessType,
                        victim: c.victim,
                        area: c.area,
                        subsystem: c.subsystem,
                        functionality: c.functionality
                      });
    });
    worksheet.getRow(1).eachCell((cell) => {
      cell.font = { bold: true };
    });
    
    if(this.app.sharedData.appInfo.isElectronMode){
      const fs = window.require('fs');
      workbook.xlsx.writeBuffer().then(async (data) => {
        const { dialog } = window.require('electron').remote;
        var path = window.require('path');
        var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",properties: ['openDirectory'] }); //Select Download Path
        var downloadPath = path.join(downloadPathList[0], "CVE_CR_Mapping_"+ this.utils.getCurrentDateTimeStamp_24() +".xlsx");
        //const buffer = await blob.arrayBuffer();
        fs.writeFile(downloadPath, data,(err) =>this.zone.run(() => {
          if (err || data.length===0)
          {
            this.app.showMessageBox("Download Failed", "CVE information export Failed", "Ok")
          }
          else{
            this.app.showMessageBox("Download Success", "CVE information exported successfully to " + downloadPath, "Ok")
          }
        }));
      });
    }
    else{
      workbook.xlsx.writeBuffer().then(async (data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
        var fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      });
    }
  }
  //#endregion  
  //#endregion
}
