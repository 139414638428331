import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { SoftwareComponent } from './component/software/software.component';
import { ToolsComponent } from './component/tools/tools.component';
import { DetailsComponent } from './component/tools/details/details.component';
import { InstallComponent } from './component/tools/install/install.component';
import { DocumentComponent } from './component/document/document.component';
import { DownloadsComponent } from './component/downloads/downloads.component';
import { STFindComponent } from './component/software/servicetask/find/st.find.component';
import { STTriageComponent } from './component/software/servicetask/triage/st.triage.component';
import { STBuildComponent } from './component/software/servicetask/build/st.build.component';
import { STDistComponent } from './component/software/servicetask/dist/st.dist.component';
import { AccessDeniedComponent } from './component/common/accessdenied/accessdenied.component';
import { STMainComponent } from './component/software/servicetask/main/st.main.component';
import { UserguideComponent } from './component/userguide/userguide.component';
import { V1UserguideComponent } from './component/userguide/v1userguide/v1userguide.component';
import { InternaluserguideComponent } from './component/userguide/internaluserguide/internaluserguide.component';
import { CrsearchComponent } from './component/crsearch/crsearch.component';
import { CveLookupComponent } from './component/cvelookup/cvelookup.component';
import { LicensesComponent } from './component/licenses/licenses.component';
import { LicensesfindComponent } from './component/licenses/licensesfind/licensesfind.component';
import { LicensesseatComponent } from './component/licenses/licensesseat/licensesseat.component';
import { LicensesgroupComponent } from './component/licenses/licensesgroup/licensesgroup.component';
import { SerialnumberComponent } from './component/licenses/serialnumber/serialnumber.component';
import { CheckoutComponent } from './component/licenses/checkout/checkout.component';
import { SuccessComponent } from './component/licenses/success/success.component';
import { EmailsubscriptionComponent } from './component/licenses/emailsubscription/emailsubscription.component';
import { CartComponent } from './component/licenses/cart/cart.component';
import { CloDetailsComponent } from './component/tools/clo-details/clo-details.component';

export const routes: Routes = [
    {path: "", component: LoginComponent},
    {path: "blank", component:HomeComponent},
    {path: "login", component: LoginComponent},
    {path: "servicetask/:id", redirectTo: "/main/software/servicetask/triage/:id", pathMatch: "full"},
    {path: "servicetask/:id/build", redirectTo: "/main/software/servicetask/build/:id", pathMatch: "full"},
    {path: "servicetask/:id/dist", redirectTo: "/main/software/servicetask/distribution/:id", pathMatch: "full"},
    {path: "tools/:id/details", redirectTo: "/main/tools/details/:id", pathMatch: "full"},
    {path: "newservicetask", redirectTo: "main/software/servicetask/find"},
    {path: "crsearch/:id", redirectTo: "/main/crsearch/:id", pathMatch: "full"},
    {path: "licenses", redirectTo: "/main/licenses/find", pathMatch: "full"},
    {path: "subscriptions", redirectTo: "/main/licenses/subscriptions", pathMatch: "full"},
    {path: "cart", redirectTo: "/main/licenses/cart", pathMatch: "full"},
    {path: "checkout", redirectTo: "/main/licenses/checkout", pathMatch: "full"},
    {path: "success", redirectTo: "/main/licenses/success", pathMatch: "full"},
    {path: "licensesgroup/:id/:lg", redirectTo: "/main/licenses/licensesgroup/:id/:lg", pathMatch: "full"},
    {path: "licenses/licensesgroup/:id/:lg", redirectTo: "/main/licenses/licensesgroup/:id/:lg", pathMatch: "full"},
    {
      path: "main", component: AppMainComponent, 
      children :[
        {path: "accessdenied", component: AccessDeniedComponent },
        {path: "", component: HomeComponent},
        {path: "home", component: HomeComponent},
        {path: "software", component: SoftwareComponent},
        {
          path: "source", component: InstallComponent, 
          children :[
            {path: "find", component: ToolsComponent},
            {path: "details/:id", component: DetailsComponent},
          ]
        },
        {path: "software/servicetask/find", component: STFindComponent},
        {
          path: "software/servicetask", component: STMainComponent, 
          children :[
            {path: "triage/:id", component: STTriageComponent},
            {path: "build/:id", component: STBuildComponent},
            {path: "distribution/:id", component: STDistComponent},
          ]
        },
        {
          path: "tools", component: InstallComponent, 
          children :[
            {path: "find", component: ToolsComponent},
            {path: "details/:id", component: DetailsComponent},
            {path: "clodetails/:id", component: CloDetailsComponent},
          ]
        },
        {
          path: "licenses", component: LicensesComponent, 
          children :[
            {path: "find", component: LicensesfindComponent},
            {path: "subscriptions", component: EmailsubscriptionComponent},
            {path: "seats", component: LicensesseatComponent},
            {path: "serialnumber/:id", component: SerialnumberComponent},
            {path: "licensesgroup/:id/:lg", component: LicensesgroupComponent},
            {path: "cart", component: CartComponent},
            {path: "checkout", component: CheckoutComponent},
            {path: "success", component: SuccessComponent}
          ]
        },
        {path: "crsearch/:id", component: CrsearchComponent},
        {path: "crsearch", component: CrsearchComponent},
        {
          path: "cvelookup", component: CveLookupComponent, 
        },
        {path: "document", component: DocumentComponent} ,
        {path: "downloads", component: DownloadsComponent},
        {path: "userguide", component: UserguideComponent},
        {path: "v1userguide", component: V1UserguideComponent},
        {path: "internaluserguide", component: InternaluserguideComponent} 
        
      ]
    }, 
    {path: "**", redirectTo: "main/accessdenied", pathMatch: "full"},
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'});
