import {MenuItem, SelectItem, TreeNode} from 'primeng/primeng';
import {Component, HostListener, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { QPMResponse } from 'src/app/models/response';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { AppMainComponent } from 'src/app/app.main.component';
import { ServiceTaskInfo, ServiceTaskBuilds, ServiceTaskBuildDetail, ServiceTaskBuildImage, ServiceTaskBuildHistory, ServiceTaskBuildCR, ServiceTaskCirrusBuild, ServiceTaskBuildCRDetail, InternalClient,ServiceTaskDistProductComposition, SoftwareProductDistro, ServiceTaskBuildAction, ServiceTaskBuildCRCurrentInfo, Gerrit, ChangeRequest, ServiceTaskBaitBuild, ServiceTaskBaitJob, SoftwareProductRelease,ServiceTaskDistImpactedFiles, ServiceTaskDistProduct, ServiceTaskDistProductDetail, FileInfo, FileStatus, BaitSubTasks, ServiceTaskPassThroughBuild, PassThroughImageBuildInfo, baitLocalFileUploadImageBuild, BaitLocalFileUploadJobDetails, ServiceTaskBaitJobInfo, ServiceTaskCrmBuildInfo, ServiceTaskBaitJobPatchLocation, CirrusComponent, ComponentBranchRequest} from 'src/app/models/software-catalog-client';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { UpdateServiceTaskRequest,TriggerCirrusBuildResponse,BuildCompositionImage, TriggerProductBuildRequest, TriggerProductBuildResponse, MasterTriggerBuildRequest, AutoShipRequest, AutoShipRequestSP, Email, TriggerBaitBuildRequest, TriggerBaitBuildResponse, CrGerritComment, SoftwareProductDistroInfo, ImageBuildDistributionRequest, imageShipDownloadResponse, SoftwareImageDistroInfo, ImageDistributionRequest, AutoPropagationRequest, AutoPropagationResult, ChangeListDetails, FilePropagationResult, FilePathValidationRequest, LocalFileUploadRequest, AutoPropagationCommitResult, LocalFileUploadResponse, PropagationResult, SoftwareImageLatest, OPSHELP, RemoteAutoCommitRequest, RemoteAutoCommitResponse, RemoteAutoCommitResponseResultFile, RemoteAutoCommitResponseResult, LocalFileUploadInitiateRequest, s3FileUploadContinueRequest, LocalFileUploadCompleteRequest, LocalFileUploadSessionStatus, BaitSystemTriggerBuildRequest, UploadSystemType, BaitFileUploadCompleteRequest, BaitFileUploadInitRequest, LocalFileUploadSessionRequest, FilePathSearchRequest, LocalFilesDeleteRequest, LocalFilesDeleteResponse, FilePathSearchRequestForBait, CirrusWorkspaceRootResponse } from 'src/app/models/lime-web-client';
import { Utils } from 'src/app/common/utils';
import { TableData } from 'src/app/models/common';
import { QLogger } from 'src/app/common/logger';
import { Console } from 'console';
import { shipStatusErrorResponse, SoftwareImageSyncResult, TriggerPassThroughSIRequest, UpdatePassThroughRequest, UpdatePassThroughResponse } from 'src/app/models/distribution-client';
import { StringBuilder } from 'typescript-string-operations';
import { stringify } from 'querystring';
import { STMainComponent } from '../main/st.main.component';
import { DistributionClientService } from 'src/app/service/Contract/DistributionClientService';
import { commType } from 'src/app/common/shared-data';
import { FileUploadType, LargeFileUpload, LargeFileUploadConfig } from 'src/app/common/large-file-upload';
import { formatDate, getLocaleDateTimeFormat, PathLocationStrategy } from '@angular/common';
import { TransitiveCompileNgModuleMetadata } from '@angular/compiler';

@Component({
  selector: 'app-st.build',
  templateUrl: './st.build.component.html',
  styleUrls: ['./st.build.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class STBuildComponent implements OnInit {

  @ViewChild('crTable') crTable;
  @ViewChild('crGlobalFilter') crGlobalFilter;
  @ViewChild('gerritTable') gerritTable;
  @ViewChild('gerritGlobalFilter') gerritGlobalFilter;
  @ViewChild('addComponentToBranchtable') addComponentToBranchtable;
  @ViewChild('addComponentToBranchFilter') addComponentToBranchFilter;
  
  private logSrc:string = "ServiceTaskBuild-Component";
  private subscriptionSrc:string = "ServiceTaskBuild-Component";

  private catalogClient: CatalogClientService;
  private distributionClient: DistributionClientService;
  private softwareCatalogClient : SoftwareCatalogClientService;
  private webClient : WebClientService;
  private visibiltySubcription: Observable<boolean>;
  private emailSubcription: Observable<boolean>;
  largeFileUpload : LargeFileUpload;
  UploadSystemType = UploadSystemType;

  productValidationInProgress: boolean;
  productBuildInProgress: boolean;

  productDistros: SoftwareProductDistro[];
  loadingProductDistros: boolean;

  serviceTaskBuilds: ServiceTaskBuilds;
  serviceTaskBuildsMap: Map<string,string>;
  serviceTaskDistProducts: ServiceTaskDistProduct[];
  compositionDetails: BuildCompositionImage[];
  baseBuildComposition: ServiceTaskDistProductComposition[];

  loadingRecentProducts: boolean;
  loadingRecentProductDetails: number;
  loadingRecentProductComposition: number;

  imagesCols: any[];  
  historyOptions: SelectItem[];
  masterServiceTaskImages: any[];
  
  internalClients: InternalClient[];
  internalClientSelected: InternalClient;

  buildModeOptions: SelectItem[] = [{label:"Incremental", value: false},{label:"Full Build", value: true}];

  buildProductPreview: {
    displayPreview: boolean
    tableData: any[]
  };

  viewBuildRequestDetailProcess:{
    displayBuildRequestDetail: boolean,
    build: ServiceTaskCirrusBuild,
    tableData: any[],
    loadingImpactedFiles:boolean,
    impactedFiles: ServiceTaskDistImpactedFiles[];
  }

  viewProductBuildDetail:{
    displayProductBuildDetail: boolean,
    tableData: any[]
  }
  
  buildSiSpProcess:{
    displayModal: boolean,
    displayAutoShipOption:boolean,
    displayForm: boolean,
    displayFormConfirm: boolean,
    displayCustomSoftwareImage: boolean,
    isTriggerSIProcess: boolean,
    isTriggerSPProcess: boolean,
    autoShip: boolean,
    autoDiffShip:boolean,
    skipRecompile: boolean,
    fullBuild: boolean,
    buildAllDistros: boolean,
    includeRunningImages: boolean,
    loadCompositionInProgress: boolean,
    loadRunningCompositionInProgress: boolean,
    triggerSIInProgress: boolean,
    triggerSPInProgress: boolean,
    triggerAutoShipInProgress: boolean,
    compositionTableData: BuildCompositionImage[],
    image: ServiceTaskBuildImage,
    imageChanged: boolean,
    signRequired:boolean,
    distroTypes: SoftwareProductDistro[],
    distroSelected: SoftwareProductDistro[],
    message: string;
    diffBuildSelected:string;
    imageSignedMessages:string[];
    isDuplicateComposition: boolean;
    duplicateProduct: string;

    customize: boolean,
    customizeImage: BuildCompositionImage;
    softwareImageType: SelectItem[],
    errorMessageComposition: string,
    isEmptyBuild: boolean;
    
    isTriggerBaitProcess: boolean,
    displayBaitForm: boolean,
    isGerrit: SelectItem,
    allGerrits: Gerrit[];
    gerrits: Gerrit[];
    gerritSelected: Gerrit[];
    allCrs: ChangeRequest[];
    crs: ChangeRequest[];
    crSelected: ChangeRequest[];
    previousGerritSet: Set<string>;
    previousGerrits: Gerrit[];
    previousCrSet: Set<Number>;
    previousCrs: ChangeRequest[];
    technologySelectItems: SelectItem[],
    technologySelected: string,
    targetSelectItems: SelectItem[],
    targetSelected: string[],
    skipBuild: boolean,
    loadingTechnologies: boolean,
    loadingTargets: boolean,
    triggerBuildInProgress: boolean,
    passthrough:boolean
  };

  triggerPassThroughSiProcess:{
    displayForm: boolean,
    loadingProcess: boolean,
    errorMessage: string,
    successMessage:string;
    selectedPassThroughImage:ServiceTaskBuildImage;
  }

  baitImagesSet: Set<string>;
  baitBuildOption: SelectItem[];

  abortBaitBuildProcess: {
    displayForm: boolean,
    image: ServiceTaskBuildImage,
    baitJob: ServiceTaskBaitBuild,
    message: string
  }
  shipImageProcess: {
    distroTypes: SoftwareProductDistro[],
    distroSelected: SoftwareProductDistro[],
    softwareImageBuild: string,
    displayDistro: boolean,
    loadingDistros:boolean
  };
  downloadImageProcess: {
    distroTypes: SoftwareProductDistro[],
    distroTypesSelectItems: SelectItem[],
    distroSelected: SoftwareProductDistro,
    softwareBuildSelected: SoftwareProductRelease,
    displayDistro: boolean,
    loadingDistros:boolean,
    softwareImageBuild?:string;
  }
  // Static UI
  crCols: any[];
  crSelectCols: any[];
  gerritCols: any[];
  historyCols: any[];
  cirrusComponentCol:any[];
  
  refreshBuildStatusMap: Map<string,boolean>;
  refreshBuildDetails:any;
  impactedFileTableCols: any[];
  constructor(private router: Router, public activatedRoute: ActivatedRoute, public app : AppMainComponent, 
    private utils : Utils, private service:DataServiceProducer, public stMain: STMainComponent) {
    QLogger.LogInfo(this.logSrc, "ServiceTaskBuild Component Creation");
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.distributionClient = service.getServiceInstance(ApiType.DistributionClient) as DistributionClientService;

  }

  ngOnInit(): void {

    QLogger.LogInfo(this.logSrc, "ServiceTaskBuild Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);
    // Static UI Elements
    this.activatedRoute.params.subscribe(params => {
      this.app.sharedData.service.common.selectedServiceTaskID = params['id'];
      this.stMain.activeIndex = 1;

      this.productDistros = [];
      this.buildProductPreview = {
        displayPreview: false,
        tableData: []
      };
      
      this.viewBuildRequestDetailProcess={
        displayBuildRequestDetail: false,
        build: new ServiceTaskCirrusBuild(),
        tableData: [],
        loadingImpactedFiles:false,
        impactedFiles:[]
      }

      this.viewProductBuildDetail={
        displayProductBuildDetail: false,
        tableData: []
      }
      
      this.baitBuildOption = [
        { label: "Gerrits", value: true },
        { label: "CRs", value: false }
      ];
      
      this.resetBuildSiSpProcess();
      this.resetTriggerPassThroughSiProcess();
      this.resetAddComponentToBranchProcess();
      this.resetAbortBaitBuildProcess();
      this.resetEmailProcess();
      
      this.productValidationInProgress = false;
      this.productBuildInProgress = false;
      
      this.app.sharedData.service.common.abandonInProgress = false;

      this.stMain.serviceTasksDetails = new ServiceTaskInfo();
      this.serviceTaskBuilds = new ServiceTaskBuilds();
      this.compositionDetails = [];
      this.baseBuildComposition = [];
      this.refreshBuildDetails = null;
      this.serviceTaskDistProducts = [];
      this.loadingRecentProducts = false;
      this.loadingRecentProductDetails = 0;;
      this.loadingRecentProductComposition = 0;
      this.baitImagesSet = new Set();
      this.imagesCols = [
        { field: 'softwareImage', header: 'Software Image' },
        { field: 'softwareImageBuild', header: 'Software Image Build', style:{'width':'40%'}},
        { field: 'status', header: 'Status', style:{'width':'30%'}},
        { field: 'action', header: 'Action',  style:{'width':'7%'}},
      ];
      this.gerritCols = [
        { field: 'gerritId', header: 'Gerrit', style:{ 'width': '20%' }},
        { field: 'type', header: 'Type', style:{ 'width': '20%' }},
        { field: 'requestedDate', header: 'Requested Date'},
        { field: 'comments', header: 'Comments'},
        { field: 'action', header: 'Action', style:{ 'width': '10%' }}
      ];
      this.cirrusComponentCol = [
        { field: 'name', header: 'Component Name', style:{ 'width': '50%' }},
        { field: 'status', header: 'Status', style:{ 'width': '30%' }},
        { field: '', header: 'Action', style:{ 'width': '20%' }}
      ];
      this.app.sharedData.resetVisibility();
    });
    this.refreshBuildStatusMap = new Map<string, boolean>(); 
    this.historyOptions = [
      { label: "Latest", value: false },
      { label: "All", value: true }
    ];
    this.shipImageProcess = {
      distroTypes: [],
      distroSelected: [],
      softwareImageBuild: "",
      displayDistro: false,
      loadingDistros:false
    };
    this.downloadImageProcess = {
      distroTypes: [],
      distroTypesSelectItems: [],
      displayDistro: false,
      distroSelected: undefined,
      softwareBuildSelected: new SoftwareProductRelease(),
      loadingDistros:false
    };
    
    this.resetLocalFileUploadProcess();
    this.resetLocalFileDeleteProcess();
    this.resetLocalFileUploadProgress();
    this.resetAutoPropogateProcess();
    this.resetRemoteAutoCommitProcess();
    this.resetTriggerSystemBuildProcess();

    this.impactedFileTableCols= [
      { field: 'crNumber', header: 'CR#', style:{'width':'150px'} },
      { field: '', header: 'Files'}
    ];
  }
  
  ngOnDestroy()
  { 
    this.app.sharedData.unsubscribeVisibility(this.subscriptionSrc);
    this.app.sharedData.unsubscribeCommunication(commType.EmailSent, this.subscriptionSrc);
    if(this.refreshBuildDetails)
    clearInterval(this.refreshBuildDetails);
  }

  ngAfterContentInit(){
    QLogger.LogInfo(this.logSrc, "ServiceTaskBuild Component Load Page Data");
    this.visibiltySubcription = this.app.sharedData.subscribeVisibility(this.subscriptionSrc);
    this.visibiltySubcription.subscribe((updated)=>{
      if(updated){
        this.loadInitData();
      }
    });

    this.emailSubcription = this.app.sharedData.subscribeCommunication(commType.EmailSent, this.subscriptionSrc);
    this.emailSubcription.subscribe((updated)=>{
      if(updated){
        this.resetEmailProcess();
      }
    });

    this.loadInitData();
    this.loadServiceTaskDetails();
    /*let isRefreshInProgress:boolean;
    if(!this.stMain.serviceTasksDetails.abandoned)
    {
      this.refreshBuildDetails = setInterval(() => {
      this.serviceTaskBuilds?.softwareImages.forEach((softwareImage) =>
      {
        QLogger.LogInfo(this.logSrc,"Refreshing Image "+softwareImage.baseImageBuild);
        isRefreshInProgress = this.refreshBuildStatusMap.get(softwareImage.baseImageBuild);
        if(!isRefreshInProgress)
        {
          this.refreshBuildStatusMap[softwareImage.baseImageBuild] = true;
          this.loadServiceTaskBuildCRs(softwareImage, true);
          this.loadServiceTaskBuildDetails(softwareImage, true); 
        }
      });}, 120000);
    }*/
      
  }

//#region events
onFilterClear() {
  this.crGlobalFilter.nativeElement.value = '';
  this.crTable.filterGlobal('', 'contains');
}
getDialogStyle()
{
    if(this.buildSiSpProcess.autoShip || this.buildSiSpProcess.isTriggerSPProcess)
      return {"width":"60%"};
    else 
      return {"width":"50%"};
}
onBuildExpand(image: ServiceTaskBuildImage){
    if(image.buildSystem === "BAIT"){
      this.loadServiceTaskBaitBuilds(image, false);
      this.loadServiceTaskBaitBuildCRs(image, false);
      this.loadServiceTaskBaitBuildGerrits(image, false);
    }else if(image.buildSystem ==='PassThrough'){
      this.serviceTaskPassThroughBuilds(image,false);
      this.loadServiceTaskBuildCRs(image, false);
    }
    else{
      this.loadServiceTaskBuildDetails(image, false);
      this.loadServiceTaskBuildCRs(image, false);
    }
}
onBuildDetailRefresh(image: ServiceTaskBuildImage){//cpucp
  let isRefreshInProgress:boolean;
  isRefreshInProgress = this.refreshBuildStatusMap.get(image.baseImageBuild);
  if(!isRefreshInProgress)
  {
    this.refreshBuildStatusMap[image.baseImageBuild] = true; 
      if(image.buildSystem === "BAIT"){
        this.loadServiceTaskBaitBuilds(image, true);
        this.loadServiceTaskBaitBuildCRs(image, true);
        this.loadServiceTaskBaitBuildGerrits(image, true);
      }else if(image.buildSystem ==='PassThrough'){
        this.serviceTaskPassThroughBuilds(image,true);
        this.loadServiceTaskBuildCRs(image, true);
      }
      else{
        this.loadServiceTaskBuildDetails(image, true);
        this.loadServiceTaskBuildCRs(image, true);
      }
  }
}

onInternalClientChange(image: ServiceTaskBuildImage){
  if(image.internalClientSelected.client === image.detail?.internalClient) return;
  this.updateInternalClient(image);
}

onTriggerBuildInit(){
  this.buildSiSpProcess.imageChanged = false;
  this.buildSiSpProcess.autoShip = this.stMain.serviceTasksDetails.baseBuild !== null;
  this.buildSiSpProcess.autoDiffShip =  this.stMain.serviceTasksDetails.baseBuild !== null;
  this.buildSiSpProcess.skipRecompile = true;
  this.buildSiSpProcess.buildAllDistros = true;
  this.buildSiSpProcess.includeRunningImages = false;
  this.buildSiSpProcess.compositionTableData = [];
  this.buildSiSpProcess.distroTypes = this.productDistros;
  this.buildSiSpProcess.distroSelected = [];
  this.buildSiSpProcess.message = "";
  this.buildSiSpProcess.loadCompositionInProgress = false;
  this.buildSiSpProcess.triggerSIInProgress = false;
  this.buildSiSpProcess.triggerSPInProgress = false;
  this.buildSiSpProcess.triggerAutoShipInProgress = false;
  this.buildSiSpProcess.imageSignedMessages = [];
  this.buildSiSpProcess.signRequired = false;
  this.buildSiSpProcess.isDuplicateComposition = false;
  this.buildSiSpProcess.duplicateProduct = "";
}

onTriggerSIClicked(image: ServiceTaskBuildImage){
  if(!this.buildSiSpProcess.displayBaitForm){
    this.resetBuildSiSpProcess();
  }
  this.onTriggerBuildInit();
  this.buildSiSpProcess.image = image;
  this.buildSiSpProcess.isTriggerSIProcess = true;
  this.buildSiSpProcess.fullBuild = image.detail.incrementalBuildSupported !== true?  true: false;
  this.verifyAutoShipComposition();
  let isStandard: boolean = false;
  this.productDistros.forEach((distro) => {
    if(distro.softwareDistro.endsWith("AMSS|Standard|OEM|")){
      this.buildSiSpProcess.distroSelected.push(distro);
      isStandard = true;
    }
  });
  if(!isStandard){
    this.productDistros.forEach((distro) => {
      if(distro.softwareDistro.endsWith("AMSS|OEM|")){
        this.buildSiSpProcess.distroSelected.push(distro);
      }
    });
  }

  this.buildSiSpProcess.displayBaitForm = false;
  this.buildSiSpProcess.displayModal = true;
  this.buildSiSpProcess.displayForm = true;
  this.buildSiSpProcess.displayFormConfirm = false;
  if(this.stMain.serviceTasksDetailsLatestReleases?.shippedRelease===null || this.stMain.availableDiffBuildOptions.length==0)
  this.buildSiSpProcess.diffBuildSelected = this.stMain.serviceTasksDetails.baseBuild;
  else
  this.buildSiSpProcess.diffBuildSelected = this.stMain.availableDiffBuildOptions[0].label;

}

onTriggerSPClicked(){
  this.resetBuildSiSpProcess();
  this.onTriggerBuildInit();
  this.buildSiSpProcess.image = undefined;
  this.buildSiSpProcess.isTriggerSPProcess = true;
  this.buildSiSpProcess.fullBuild = true;
  this.verifyAutoShipComposition();
  this.getRecentProductBuild();
  let isStandard: boolean = false;
  this.productDistros.forEach((distro) => {
    if(distro.softwareDistro.endsWith("AMSS|Standard|OEM|")){
      this.buildSiSpProcess.distroSelected.push(distro);
      isStandard = true;
    }
  });
  if(!isStandard){
    this.productDistros.forEach((distro) => {
      if(distro.softwareDistro.endsWith("AMSS|OEM|")){
        this.buildSiSpProcess.distroSelected.push(distro);
      }
    });
  }
  this.buildSiSpProcess.displayModal = true;
  this.buildSiSpProcess.displayForm = true;
  this.buildSiSpProcess.displayFormConfirm = false;
  if(this.stMain.serviceTasksDetailsLatestReleases?.shippedRelease===null || this.stMain.availableDiffBuildOptions.length==0)
  this.buildSiSpProcess.diffBuildSelected = this.stMain.serviceTasksDetails.baseBuild;
  else
  this.buildSiSpProcess.diffBuildSelected = this.stMain.availableDiffBuildOptions[0].label;

}

onTriggerSiSpSubmit(){
  if(this.buildSiSpProcess.isTriggerSIProcess){
    this.buildImageAutoShip(this.buildSiSpProcess.image);
  }
  else if(this.buildSiSpProcess.isTriggerSPProcess){
    this.autoShipSP();
  }
}

onTriggerSiSpBack(){
  this.buildSiSpProcess.displayForm = true;
  this.buildSiSpProcess.displayAutoShipOption = false;
  this.buildSiSpProcess.displayFormConfirm = false;
}
//#endregion

//#region TriggerPassThroughSI
triggerPassThroughSIClicked(selectedImage:ServiceTaskBuildImage){
  this.resetTriggerPassThroughSiProcess();
  this.triggerPassThroughSiProcess.displayForm=true;
  this.triggerPassThroughSiProcess.loadingProcess=false;
  this.triggerPassThroughSiProcess.errorMessage='';
  this.triggerPassThroughSiProcess.successMessage='';
  this.triggerPassThroughSiProcess.selectedPassThroughImage=selectedImage;
}

onTriggerPassThroughSiSubmit(selectedImage:ServiceTaskBuildImage){
  let response : Observable<QPMResponse>;
  this.triggerPassThroughSiProcess.loadingProcess=true;
  this.triggerPassThroughSiProcess.errorMessage='';
  this.triggerPassThroughSiProcess.successMessage='';
  if(this.webClient === null) {
    return
  }
  let request: TriggerPassThroughSIRequest;
  request = {
    baseImage      :selectedImage.baseImage,
    baseImageBuild :selectedImage.baseImageBuild,
    gitBranch      :selectedImage.gitBranch,
    gitUrl         :selectedImage.gitUrl,
    serviceTaskId  :this.stMain.serviceTasksDetails.serviceTaskId
  }
  if(this.app.sharedData.appInfo.logRequest){
    QLogger.LogInfo(this.logSrc, "Trigger Passthrough Software Image Build : " + JSON.stringify(request) );
  }
  response = this.webClient.triggerPassThroughSI(request);

  response.subscribe(
    (data:QPMResponse) => { 
     
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Trigger Passthrough Software Image Build - Response : " +JSON.stringify(data));
      } 
      if (data.isSuccess()){
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null) {
          QLogger.LogInfo(this.logSrc, "Trigger Passthrough Software Image Build - Success"); 
          if (obj.success) {
            this.triggerPassThroughSiProcess.loadingProcess=false;
            this.triggerPassThroughSiProcess.successMessage="Trigger SI request has been queued successfully";
          }
        }
      }
      else {
        QLogger.LogError(this.logSrc, "Trigger Passthrough Software Image Build - Failed");
        QLogger.LogError(this.logSrc, "Trigger Passthrough Software Image Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        this.triggerPassThroughSiProcess.errorMessage="Trigger SI request failed";
      }
      this.triggerPassThroughSiProcess.loadingProcess=false;
    }
  );

}

//#endregion

  //#region functions
  loadInitData(){
    this.setVisibilityBasedUI();
  }
  setVisibilityBasedUI(){
    if(this.app.sharedData.visibility.serviceTaskBuild.additionalCrInfo){
      this.crCols = [
        { field: 'changeRequestId', header: 'CR #', style:{ 'width': '8%' }, sortable: true},
        { field: 'detail.title', header: 'Title', style:{ 'width': '17%' }, sortable: true},
        { field: 'area', header: 'Area', style:{ 'width': '9%' } },
        { field: 'subsystem', header: 'Sub System' },
        { field: 'functionality', header: 'Functionality' },
        { field: 'currentInfo.status', header: 'Status', sortable: true},
        { field: 'assignee', header: 'Assignee'},
        { field: 'changeRequestDate', header: 'Requested Date', style:{ 'width': '95px' }, sortable: true},
        { field: 'comments', header: 'Comments', style:{ 'width': '12%' }},
        { field: 'action', header: 'Action' , style:{ 'width': '15%' } },
      ];

      this.crSelectCols = [
        { field: 'changeRequestId', header: 'CR #', style:{ 'width': '10%' }, sortable: true},
        { field: 'detail.title', header: 'Title', style:{ 'width': '20%' }, sortable: true},
        { field: 'area', header: 'Area' },
        { field: 'subsystem', header: 'Sub System' },
        { field: 'functionality', header: 'Functionality' },
        { field: 'currentInfo.status', header: 'Status', sortable: true},
        { field: 'assignee', header: 'Assignee'},
        { field: 'changeRequestDate', header: 'Requested Date', style:{ 'width': '95px' }, sortable: true}
      ];
    }
    else{
      this.crCols = [
        { field: 'cr', header: 'CR #', style:{ 'width': '10%' }},
        { field: 'title', header: 'Title', style:{ 'width': '27%' }},
        { field: 'status', header: 'Status', style:{ 'width': '10%' }},
        { field: 'requestedDate', header: 'Requested Date'},
        { field: 'comments', header: 'Comments', style:{ 'width': '14%' }},
        { field: 'action', header: 'Action', style:{ 'width': '15%' }}
      ];
    }

    if(this.app.sharedData.visibility.serviceTaskBuild.additionalHistoryInfo){      
      this.historyCols = [
        { field: 'actionInfo', header: 'Action'},
        //{ field: 'cirrus_build_id', header: 'Cirrus ID' },
        { field: 'date', header: 'Date', style:{ 'width': '20%' }},
        { field: 'user', header: 'User', style:{ 'width': '18%' }},
        { field: 'status', header: 'Status', style:{ 'width': '17%' }},
        { field: 'comments', header: 'Comments', style:{ 'width': '17%' }},
        //{ field: 'userAction', header: '', style:{ 'width': '15%' } }
      ];
    }
    else{
      this.historyCols = [
        { field: 'actionInfo', header: 'Action'},
        //{ field: 'cirrus_build_id', header: 'Cirrus ID' },
        { field: 'date', header: 'Date', style:{ 'width': '20%' }},
        { field: 'status', header: 'Status', style:{ 'width': '17%' }},
        //{ field: 'userAction', header: '', style:{ 'width': '18%' } }
      ];
    }
  }
  loadServiceTaskDetails() {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details");

    if(this.softwareCatalogClient === null || this.app.sharedData.service.common.selectedServiceTaskID === undefined){
      return;
    }
    
    let response : Observable<QPMResponse>;

    // Get service task details
    response = this.softwareCatalogClient.getServiceTaskDetails(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Response : " +JSON.stringify(data));
        } 
        if(data.getCode() === 401){
          this.router.navigate(['/main/accessdenied']);
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.stMain.serviceTasksDetails = obj as ServiceTaskInfo;
            this.stMain.getAvailableBaseBuilds();
            this.stMain.loadAdditionalInfo();
            this.setVisibilityBasedUI();
            this.loadServiceTaskBuilds();
            this.getAvailableDistros(this.stMain.serviceTasksDetails.baseBuild);
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  loadServiceTaskBuilds() {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details");
    this.serviceTaskBuilds = new ServiceTaskBuilds();
    this.baitImagesSet = new Set();
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskBuilds(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
    (data:QPMResponse) => { 
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Response : " +JSON.stringify(data));
      }
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Success");
        
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          this.serviceTaskBuilds = obj as ServiceTaskBuilds;
          this.serviceTaskBuilds.softwareImages.forEach(image =>{
            image.detail = new ServiceTaskBuildDetail();
            image.changeRequests = [];
            image.gerrits = [];
            image.internalClients = [];
            image.baitBuilds = [];
            image.passThroughBuilds=[];
            image.updateInternalClientInProgress = false;
            image.loadingChangeRequests = 0;
            image.loadingChangeRequestsCurrentInfo = 0;
            image.loadingInternalClients = false;
            image.loadingHistory = false;
            image.loadingBaitBuilds = false;
            image.loadingBaitBuildDetails = 0;
            image.loadingPassThroughBuilds=false;
            image.loadingPassThroughBuildDetails=0;
            image.buildHistoryOption = this.historyOptions[0];
            if(image.buildSystem === "BAIT"){
              this.baitImagesSet.add(image.baseImage);
            }
          })
          this.getProductComposition();
          if(this.serviceTaskBuilds.softwareImages.length >= 1){
            this.onBuildExpand(this.serviceTaskBuilds.softwareImages[0]);
          }
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

      }
    });
  }

  loadServiceTaskBuildInternalClients(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.internalClients.length !== 0){
      return;
    }
    image.loadingInternalClients = true;
    QLogger.LogInfo(this.logSrc, "Get Service Task Build "+image.baseImageBuild+" Internal Clients");
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskBuildInternalClients(image.baseImageBuild.toString());
    //this.loadingCrTable = true;
    response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task Build "+image.baseImageBuild+" Internal Clients - Response : " +JSON.stringify(data));
      } 
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task Build "+image.baseImageBuild+" Internal Clients - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          image.internalClients = obj.clients as InternalClient[];
          image.internalClients.forEach((client)=>{
            if(client.client === image.detail.internalClient){
              image.internalClientSelected = client;
            }
          });
          if(image.internalClientSelected === undefined || image.internalClientSelected === null){
            image.internalClients.unshift({
              client: ""
            });
          }
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task Build "+image.baseImageBuild+" Internal Clients - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task Build "+image.baseImageBuild+" Internal Clients - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }
      image.loadingInternalClients = false;
      //this.loadingCrTable = false;
    });
  }

  loadServiceTaskBuildCRs(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.changeRequests.length !== 0){
      return;
    }
    QLogger.LogInfo(this.logSrc, "Get Service Task Build "+image.id+" CRs");
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskBuildCRs(image.servicetaskId.toString(), image.id.toString());
    response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task Build "+image.id+" CRs - Response : " +JSON.stringify(data));
      } 
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task Build "+image.id+" CRs - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          image.changeRequests = [];
          let crsWithDate: Map<number, ChangeRequest> = new Map();
          this.stMain.serviceTasksDetails.changeRequests.forEach(cr=>{
            crsWithDate.set(cr.changeRequest, cr);
          });
          
          let crList:Set<Number> = new Set();
          var crs = obj.crlist as ServiceTaskBuildCR[];
          crs.forEach((cr)=>{
            if(cr.changeRequestId !== null && !crList.has(cr.changeRequestId)){
              if(crsWithDate.has(cr.changeRequestId)){
                cr.changeRequestDate = crsWithDate.get(cr.changeRequestId).requestedDate;
              }
              image.changeRequests.push(cr);
              crList.add(cr.changeRequestId);
              cr.currentInfo = new ServiceTaskBuildCRCurrentInfo();
            }
          });
          image.changeRequests.forEach((cr) => {
            cr.comments = [];
            let request: CrGerritComment = {
              crId: cr.changeRequestId,
              gerritId: undefined,
              softwareImage: image.baseImage
            }
            this.stMain.getComments(request, cr);
            this.loadServiceTaskBuildCRDetail(image, cr);
            this.loadServiceTaskBuildCRCurrentInfo(image, cr);
          });
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task Build "+image.id+" CRs - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task Build "+image.id+" CRs - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }
    });
  }

  loadServiceTaskBuildCRDetail(image: ServiceTaskBuildImage, cr: ServiceTaskBuildCR){
    QLogger.LogInfo(this.logSrc, "Get Service Task CR "+cr.changeRequestId+" Details");
    let response : Observable<QPMResponse>;
    if(this.webClient === null){
      return
    }
    image.loadingChangeRequests++;
    cr.detail = new ServiceTaskBuildCRDetail();
    response = this.webClient.getChangeRequestDetails(cr.changeRequestId);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task CR "+cr.changeRequestId+" Details - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task CR "+cr.changeRequestId+" Details - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            cr.detail = obj;
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task CR "+cr.changeRequestId+" Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task CR "+cr.changeRequestId+" Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.loadingChangeRequests--;
      }
    );
  }

  loadServiceTaskBuildCRCurrentInfo(image: ServiceTaskBuildImage, cr: ServiceTaskBuildCR){
    QLogger.LogInfo(this.logSrc, "Get Service Task Build CR "+cr.changeRequestId+" Current Info");
    let response : Observable<QPMResponse>;
    if(this.webClient === null){
      return
    }
    image.loadingChangeRequestsCurrentInfo++;
    cr.detail = new ServiceTaskBuildCRDetail();
    response = this.softwareCatalogClient.getServiceTaskBuildCRStatus(cr.changeRequestId.toString(), this.stMain.serviceTasksDetails.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task Build CR "+cr.changeRequestId+" Current Info - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task Build CR "+cr.changeRequestId+" Current Info - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            cr.currentInfo = obj as ServiceTaskBuildCRCurrentInfo;
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task Build CR "+cr.changeRequestId+" Current Info - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task Build CR "+cr.changeRequestId+" Current Info - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.loadingChangeRequestsCurrentInfo--;
      }
    );
  }

  loadServiceTaskBuildDetails(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.detail.history.length !== 0){
      return;
    }
    let compositionResponse : Observable<QPMResponse>;
    let signInfoMessageMap:Map<string,string>= new Map<string,string>();
    compositionResponse = this.webClient.verifyProductComposition(this.stMain.serviceTasksDetails.baseBuild, this.stMain.serviceTasksDetails.serviceTaskId.toString());
    compositionResponse.subscribe(
      (data:QPMResponse) => {   
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Product Composition - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()){
            let obj = JSON.parse(data.getData());
            this.compositionDetails = obj;
            this.compositionDetails.forEach(image=>{
                if(image.changed){
                  image.softwareImageChangedBuildsV2.forEach(build=>{
                         if(build.isSigningRequired && build.signingInfoMessage){
                          signInfoMessageMap.set(build.softwareImageBuild,build.signingInfoMessage)
                         }
                      
                  });
                }
            });
        }
        QLogger.LogInfo(this.logSrc, "Get Build Details - Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId);
        image.loadingHistory = true;
        image.detail = new ServiceTaskBuildDetail();
        let response : Observable<QPMResponse>;
        if(image.buildHistoryOption.value){
          response = this.softwareCatalogClient.getServiceTaskBuildDetails(this.app.sharedData.service.common.selectedServiceTaskID, image.cirrusId.toString(), 0);
        }
        else{
          response = this.softwareCatalogClient.getServiceTaskBuildDetails(this.app.sharedData.service.common.selectedServiceTaskID, image.cirrusId.toString(), this.app.sharedData.service.build.loadBuildHistoryTopCount);
        }
        response.subscribe(
        (data:QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Get Build Details - Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId + " Response : " +JSON.stringify(data));
          } 
          if(data.isSuccess()){
            QLogger.LogInfo(this.logSrc, "Get Build Details Success- Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId);
            let obj = JSON.parse(data.getData());
            if(obj !== undefined || obj !== null){
              this.loadServiceTaskBuildInternalClients(image, refresh);
              let histories: ServiceTaskBuildHistory[];
              histories = [];
              image.detail = obj as ServiceTaskBuildDetail;
              //if(this.app.sharedData.visibility.serviceTaskBuild.buildHistory){
                image.detail.builds.forEach((build) => {
                  //Build Info
                  let history: ServiceTaskBuildHistory;
                  let historyBuild: ServiceTaskBuildHistory;
                  let actionBuildCL: ServiceTaskBuildAction = {
                    actionInfo: "CLs : ",
                    actionUrls: [] 
                  };
                  let actionBuildCR: ServiceTaskBuildAction = {
                    actionInfo: "CRs : ",
                    actionUrls: [] 
                  };
                  historyBuild = {
                    actionInfo: "",
                    type: "BUILD",
                    comments:"",
                    status: build.status + (build.failed_reason !== null ? (" - " + build.failed_reason) : ""),
                    build_url: build.build_url,
                    build: build,
                    timestamp: build.build_completed_timestamp !== 0 ? build.build_completed_timestamp : build.build_start_timestamp,
                    date: build.build_completed_timestamp !== 0 ?
                            this.utils.epochToDateString(build.build_completed_timestamp).toString()
                            : this.utils.epochToDateString(build.build_start_timestamp).toString(),
                    actions: []
                  };
                  if(this.app.sharedData.visibility.serviceTaskBuild.buildHistoryIDs && build.build_id !== null && build.build_id !== ""){
                    historyBuild.actionInfo = "Complete: " + build.build_id;
                  }
                  else{
                    historyBuild.actionInfo = "Build Triggered";
                  }
                  histories.push(historyBuild);

                  if(build.build_start_timestamp !== 0){
                    let signMessage=null;
                    if(build.build_id!=null && signInfoMessageMap.has(build.build_id)){
                      signMessage = signInfoMessageMap.get(build.build_id);
                    }
                    history = {
                      actionInfo: "Started" + ((this.app.sharedData.visibility.serviceTaskBuild.buildHistoryIDs && build.build_id !== null && build.build_id !== "") ? " : "+build.build_id : ""),
                      //status: "Complete",
                      user: build.trigger_mode === "OnDemand"?  build.requested_by : "",
                      status: "",
                      comments:signMessage!=null?signMessage:"",
                      timestamp: build.build_start_timestamp,
                      date: this.utils.epochToDateString(build.build_start_timestamp).toString(),
                      actions: []
                    };
                    
                    history.actions.push({
                      actionInfo: "Triggered: " + (build.trigger_mode === undefined || build.trigger_mode === null? "Unknown" : build.trigger_mode),
                      actionUrls: []
                    });
                    history.actions.push({
                      actionInfo: "Build Mode: " + (build.build_mode === undefined || build.build_mode === null? "Unknown" : build.build_mode),
                      actionUrls: []
                    });
                    histories.push(history);
                  }
                  //CL & CR info
                  let CRsSet: Set<string> = new Set();
                  build.changes.forEach((change)=>{
                    history = {
                      actionInfo: "",
                      status: "Complete",
                      comments:"",
                      timestamp: change.submitted_timestamp,
                      user: change.user,
                      date: this.utils.epochToDateString(change.submitted_timestamp).toString(),
                      actions: []
                    };
                    let actionCL: ServiceTaskBuildAction = {
                      actionInfo: "Checkin CL#: ",
                      actionUrls: [{
                        label: change.changelist,
                        link: change.changelist_url
                      }]
                    };
                    history.actions.push(actionCL);
                    actionBuildCL.actionUrls.push(...actionCL.actionUrls); // Build ID row
                    let action: ServiceTaskBuildAction = {
                      actionInfo: "CRs : ",
                      actionUrls: [] 
                    };
                    if(change.change_requests !== undefined && change.change_requests !== null){
                      if(change.change_requests.length > 0){
                        change.change_requests.forEach((cr)=>{
                          action.actionUrls.push({
                            label: cr,
                            link: "https://orbit/cr/" + cr
                          });
                        });
                      }
                    }
                    action.actionUrls.forEach((cr)=>{ // Build ID row
                      if(!CRsSet.has(cr.label)){
                        actionBuildCR.actionUrls.push(cr);
                        CRsSet.add(cr.label);
                      }
                    });
                    if(action.actionUrls.length === 0) action.actionInfo = "CRs : None";
                    history.actions.push(action);
                    //Seed build should be identified by flag instead of spin ".1"
                    // if(!build.build_id?.endsWith(".1")){
                    histories.push(history);
                    // }
                  });
                  if(actionBuildCR.actionUrls.length === 0) actionBuildCR.actionInfo = "CRs : None";
                  // if(!build.build_id?.endsWith(".1")){
                  historyBuild.actions.push(actionBuildCL); // Build ID - Consolidated CLs
                  historyBuild.actions.push(actionBuildCR); // Build ID - Consolidated CRs
                  // }
                });
                image.detail.history = histories;
                image.detail.history = image.detail.history.sort((h1,h2)=> h2.timestamp - h1.timestamp);
              //}
            }
          }
          else{
            QLogger.LogError(this.logSrc, "Get Build Details Failed- Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId);
            QLogger.LogError(this.logSrc, "Get Build Details Failed- Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId +" Error : " + data.getError() + " - " + data.getErrorDetail());
          }
          image.loadingHistory = false;
          //this.refreshBuildStatusMap[image.baseImageBuild] = false;

        });

    });

  }
  getAvailableDistros(softwareProductBaseBuild: string) {
    QLogger.LogInfo(this.logSrc, "Get Available Software Product "+softwareProductBaseBuild+" Distros");
    this.productDistros = [];
    //this.downloadProcess.distroTypes = SoftwareData.availableDistros;
    this.loadingProductDistros = true;
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductBaseBuild);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get Available Software Product "+softwareProductBaseBuild+" Distro - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get Available Software Product "+softwareProductBaseBuild+" Distro - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.productDistros = obj.distros;
          }
        }
        else{
          QLogger.LogError(this.logSrc,  "Get Available Software Product "+softwareProductBaseBuild+" Distro - Failed");
          QLogger.LogError(this.logSrc,  "Get Available Software Product "+softwareProductBaseBuild+" Distro - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingProductDistros = false;
      }
    );
  }

  retryCirrusBuildRequest(image: ServiceTaskBuildImage){
    let serviceTaskID = this.stMain.serviceTasksDetails.serviceTaskId.toString();
    let softwareImage = image.baseImage;
    let softwareImageBuild = image.baseImageBuild;
    QLogger.LogInfo(this.logSrc, "Retry cirrus build, ServiceTaskID: " + serviceTaskID + " ,SoftwareImageBuild: " + softwareImageBuild);
    image.retryCirrusBuildInProgress = true;
    let response : Observable<QPMResponse>;
    response = this.webClient.retryCirrusBuildRequest(serviceTaskID, softwareImage, softwareImageBuild);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Retry cirrus build, ServiceTaskID: " + serviceTaskID + " ,SoftwareImageBuild: " + softwareImageBuild + " - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Retry cirrus build, ServiceTaskID: " + serviceTaskID + " ,SoftwareImageBuild: " + softwareImageBuild + " - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            if(obj.success){
              this.loadServiceTaskBuildDetails(image, true);
              this.app.showMessageBox("Retry Setup Request Initiated", "Retry setup request for " + softwareImage + " initiated successfully", "Ok");
            }
            else{
              this.app.showMessageBox("Retry Setup Request Failed", "Retry setup request for " + softwareImage + " failed. Error: " + obj.message, "Ok");
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Retry cirrus build, ServiceTaskID: " + serviceTaskID + " ,SoftwareImageBuild: " + softwareImageBuild + " - Failed");
          QLogger.LogError(this.logSrc, "Retry cirrus build, ServiceTaskID: " + serviceTaskID + " ,SoftwareImageBuild: " + softwareImageBuild + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.app.showMessageBox("Retry Setup Request Failed", "Retry setup request for  " + softwareImage + " failed. Error: " + data.getError(), "Ok");
        }
        image.retryCirrusBuildInProgress = false;
      }
    );
  }

  recoverCirrusSeedBuildRequest(image: ServiceTaskBuildImage){
    let serviceTaskID = this.stMain.serviceTasksDetails.serviceTaskId.toString();
    let cirrusTaskID = image.cirrusId.toString();
    QLogger.LogInfo(this.logSrc, "Recover cirrus seed build, ServiceTaskID: " + serviceTaskID + ", CirrusTaskID: " + cirrusTaskID);
    image.recoverCirrusSeedBuildInProgress = true;
    let response : Observable<QPMResponse>;
    response = this.webClient.recoverCirrusSeedBuild(cirrusTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Recover cirrus seed build, ServiceTaskID: " + serviceTaskID + ", CirrusTaskID: " + cirrusTaskID + " - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Recover cirrus seed build, ServiceTaskID: " + serviceTaskID + ", CirrusTaskID: " + cirrusTaskID + " - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.loadServiceTaskBuildDetails(image, true);
            this.app.showMessageBox("Retry Setup Request Initiated", "Retry setup request for " + image.baseImage + " initiated. " + obj.combinedMessage, "Ok");
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Recover cirrus seed build, ServiceTaskID: " + serviceTaskID + ", CirrusTaskID: " + cirrusTaskID + " - Failed");
          QLogger.LogError(this.logSrc, "Recover cirrus seed build, ServiceTaskID: " + serviceTaskID + ", CirrusTaskID: " + cirrusTaskID + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.app.showMessageBox("Retry Setup Request Failed", "Retry setup request for  " + image.baseImage + " failed. Error: " + data.getErrorDetail(), "Ok");
        }
        image.recoverCirrusSeedBuildInProgress = false;
      }
    );
  }

  resetBuildSiSpProcess(){
    this.buildSiSpProcess = {
      displayModal: false,
      displayForm: false,
      displayAutoShipOption:false,
      displayFormConfirm: false,
      displayCustomSoftwareImage: false,
      isTriggerSIProcess: false,
      isTriggerSPProcess: false,
      autoShip: false,
      autoDiffShip:false,
      skipRecompile: false,
      fullBuild: false,
      buildAllDistros: false,
      includeRunningImages: false,
      loadCompositionInProgress: false,
      loadRunningCompositionInProgress: false,
      triggerSIInProgress: false,
      triggerSPInProgress: false,
      triggerAutoShipInProgress: false,
      compositionTableData: [],
      image: null,
      imageChanged: false,
      signRequired: false,
      distroTypes: [],
      distroSelected: [],
      message: "",
      diffBuildSelected:undefined,
      imageSignedMessages:[],
      isDuplicateComposition: false,
      duplicateProduct: "",

      customize: false,
      customizeImage: new BuildCompositionImage(),
      softwareImageType: [{label: "Default", value: "default"}, {label: "CRM Builds", value: "CRM"}, {label: "Service Builds", value: "QPMService"}],
      errorMessageComposition: "",
      isEmptyBuild: false,

      isTriggerBaitProcess: false,
      displayBaitForm: false,
      isGerrit: this.baitBuildOption[0],
      allGerrits: [],
      gerrits: [],
      gerritSelected: [],
      allCrs: [],
      crs: [],
      crSelected: [],
      previousGerritSet: new Set<string>(),
      previousGerrits: [],
      previousCrSet: new Set<Number>(),
      previousCrs: [],
      technologySelectItems: [],
      technologySelected: undefined,
      targetSelectItems: [],
      targetSelected: undefined,
      skipBuild: false,
      loadingTechnologies: false,
      loadingTargets: false,
      triggerBuildInProgress: false,
      passthrough:false
    };
  }

  resetTriggerPassThroughSiProcess(){
    this.triggerPassThroughSiProcess={
      displayForm:false,
      errorMessage:'',
      successMessage:'',
      loadingProcess:false,
      selectedPassThroughImage:new ServiceTaskBuildImage()
    }
  }

  updateInternalClient(image: ServiceTaskBuildImage){
    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    let client : string = "";
    if(image.internalClientSelected !== undefined && image.internalClientSelected !== null){
      client = image.internalClientSelected.client;
    }
    if(client === ""){
      return;
    }
    QLogger.LogInfo(this.logSrc, "Update Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client );
    response = this.webClient.updateServiceTaskBuildInternalClient(image.cirrusId.toString(), image.internalClientSelected.client);
    image.updateInternalClientInProgress = true;
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Update Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + " - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Update Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + "- Success");
            this.app.showMessageBox("Update Complete","Internal Client for " + image.baseImageBuild + " updated to " + client,"OK");
            image.detail.internalClient = client;
            this.loadServiceTaskBuildInternalClients(image, true);
          }
        }
        else {
          this.app.showMessageBox("Update Failed","Update Internal Client for "+image.baseImageBuild + " Failed. Error : " + data.getError(),"OK")
          QLogger.LogError(this.logSrc, "Update Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + " - Failed");
          QLogger.LogError(this.logSrc, "Update Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.updateInternalClientInProgress = false;
      }
    );
  }

  onRemoveInternalClientClick(image: ServiceTaskBuildImage){
    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    let client : string = "";
    if(image.internalClientSelected !== undefined && image.internalClientSelected !== null){
      client = image.internalClientSelected.client;
    }
    if(client === ""){
      return;
    }
    QLogger.LogInfo(this.logSrc, "Remove Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client );
    response = this.webClient.removeServiceTaskBuildInternalClient(image.cirrusId.toString());
    image.updateInternalClientInProgress = true;
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Remove Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + " - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Remove Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + "- Success");
            this.app.showMessageBox("Remove Complete","Internal Client "+client +" Successfully Removed from  "+ image.baseImageBuild ,"OK");
            image.detail.internalClient = "";
            image.internalClientSelected=undefined;
            this.loadServiceTaskBuildInternalClients(image, true);
          }
        }
        else {
          this.app.showMessageBox("Remove Failed","Remove Internal Client for "+image.baseImageBuild + " Failed. Error : " + data.getError(),"OK")
          QLogger.LogError(this.logSrc, "Remove Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + " - Failed");
          QLogger.LogError(this.logSrc, "Remove Internal Client for " + image.baseImageBuild + ", CirrusID : " + image.cirrusId + ", Client : " + client + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.updateInternalClientInProgress = false;
      }
    );
  }
  customizeCompositionSI(image: BuildCompositionImage){
    if(image.softwareImageTypeSelected === "default"){ 
      this.onBuildChange(image);
      return;
    };
    this.buildSiSpProcess.customizeImage = image;
    this.buildSiSpProcess.signRequired = false;
    this.buildSiSpProcess.imageSignedMessages =[];
    this.getLatestSIBuilds(image);
  }

  verifyAutoShipComposition() {
    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }
    
    this.buildSiSpProcess.loadCompositionInProgress = true;
    this.buildSiSpProcess.imageChanged = false;
    this.buildSiSpProcess.imageSignedMessages =[];
    QLogger.LogInfo(this.logSrc, "Get Auto Ship Product Composition " + this.stMain.serviceTasksDetails.baseBuild + ", " + this.stMain.serviceTasksDetails.serviceTaskId );
    response = this.webClient.verifyProductComposition(this.stMain.serviceTasksDetails.baseBuild, this.stMain.serviceTasksDetails.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {   
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Auto Ship Product Composition - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Get Auto Ship Product Composition - Success");
            
            this.compositionDetails = obj;

            this.buildSiSpProcess.compositionTableData = [];
            if (this.compositionDetails !== undefined && this.compositionDetails !== null) {
              if(this.buildSiSpProcess.isTriggerSIProcess){
                this.compositionDetails.forEach( image => {

                  if(image.changed){
                    this.buildSiSpProcess.compositionTableData.push(image);
                  }
                  else if(image.softwareImage === this.buildSiSpProcess.image?.baseImage){
                    if(image.softwareImageChangedBuilds==null){
                      image.changed = false;
                    }
                    else{
                      image.changed = true;
                    }
                    this.buildSiSpProcess.compositionTableData.push(image);
                  }
                });
                this.buildSiSpProcess.imageChanged = true;
              }
              else{
                this.buildSiSpProcess.compositionTableData = this.compositionDetails;
                this.buildSiSpProcess.imageChanged = this.compositionDetails.find((i)=> i.changed === true) !== undefined;
              }
              this.buildSiSpProcess.compositionTableData.forEach((image)=>{
                image.baseImageBuild = this.serviceTaskBuildsMap.get(image.softwareImage);
                image.softwareImageTypeSelected = "default";
                if(image.changed){
                  image.newBuildSelectItems= this.utils.getSelectItemsMultiLabel(image.softwareImageChangedBuildsV2, ["softwareImageBuild","isSigningRequired","imageBuildStatus","signingInfoMessage"],'-');
                  if(image.softwareImageChangedBuildsV2.length>0){
                    image.newSoftwareImageBuildInfo = image.newBuildSelectItems[0].value;
                    image.newSoftwareImageBuild = image.newBuildSelectItems[0].value.softwareImageBuild;
                    image.buildStatus= image.newSoftwareImageBuildInfo.imageBuildStatus;
                    if( image.newSoftwareImageBuildInfo.isSigningRequired){
                      this.buildSiSpProcess.signRequired = true;
                    }
                    this.buildSiSpProcess.imageSignedMessages.push(image.newSoftwareImageBuildInfo.signingInfoMessage);
                  }
                  else{
                    image.changed = false;
                  }
                }
              });
              this.buildSiSpProcess.imageChanged = this.buildSiSpProcess.compositionTableData.find((i)=> i.changed === true) !== undefined;
              this.buildSiSpProcess.compositionTableData = this.buildSiSpProcess.compositionTableData.sort((a, b)=>  (+!!a.changed) - (+!!b.changed) || a.softwareImage.toLowerCase().localeCompare(b.softwareImage.toLowerCase()));           
            }
          }
          this.buildSiSpProcess.displayModal = true;
          this.checkDuplicateComposition();
        }
        else {
          QLogger.LogError(this.logSrc, "Get Auto Ship Product Composition - Failed");
          QLogger.LogError(this.logSrc, "Get Auto Ship Product Composition - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.loadCompositionInProgress = false;
      }
    );
  }
    
  buildImageAutoShip(image: ServiceTaskBuildImage){
    if(this.buildSiSpProcess.autoShip){
      this.autoShipSI(image);
    }
    else{
      this.buildImage(image);
    }
  }

  buildImage(image: ServiceTaskBuildImage){

    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    this.buildSiSpProcess.triggerSIInProgress = true;
    this.buildSiSpProcess.message = "";
    if(!this.buildSiSpProcess.displayModal) {
      this.buildSiSpProcess.autoShip = false;
    }
    QLogger.LogInfo(this.logSrc, "Trigger SI Build for " + image.baseImageBuild + ", " + image.cirrusId );
    response = this.webClient.triggerCirrusBuild(image.cirrusId.toString(), this.buildSiSpProcess.fullBuild);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Trigger SI Build - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Trigger SI Build - Success");   

            let cirrusBuildDetails : TriggerCirrusBuildResponse = obj;
            let dialogText = "Build successfully triggered for " + image.baseImageBuild;
            cirrusBuildDetails.messages.forEach(message =>{
              dialogText += ": " + message;
            })
            this.buildSiSpProcess.displayModal = false;
            this.buildSiSpProcess.displayAutoShipOption = false;
            this.app.showMessageBox("Trigger SI Build",dialogText,"OK");
            
            this.onBuildDetailRefresh(image);
          }
        }
        else {
          this.buildSiSpProcess.displayModal = false;
          this.buildSiSpProcess.displayAutoShipOption = false;
          this.app.showMessageBox("Trigger SI Build","Failed to trigger SI build "+image.baseImageBuild + " Error : " + data.getError(),"OK")
          QLogger.LogError(this.logSrc, "Trigger SI Build - Failed");
          QLogger.LogError(this.logSrc, "Trigger SI Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.triggerSIInProgress = false;
      }
    );
  }

  buildProductPreviewShow(){
    this.buildProductPreview.tableData = [];
    this.verifyProductComposition();
  }

  verifyProductComposition() {
    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    this.productValidationInProgress = true;
    QLogger.LogInfo(this.logSrc, "Get Product Composition " + this.stMain.serviceTasksDetails.baseBuild + ", " + this.stMain.serviceTasksDetails.serviceTaskId );
    response = this.webClient.verifyProductComposition(this.stMain.serviceTasksDetails.baseBuild, this.stMain.serviceTasksDetails.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {   
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Product Composition - Response : " +JSON.stringify(data));
        } 
        this.productValidationInProgress = false;
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Get Product Composition - Success");
            this.compositionDetails = obj;
            this.buildProductPreview.tableData = [];
            if (this.compositionDetails !== undefined && this.compositionDetails !== null) {
              this.compositionDetails.forEach( image => {
                this.buildProductPreview.tableData.push({label:image.softwareImage, value: image.newSoftwareImageBuildInfo.softwareImageBuild , changed: image.changed});
              });
            }
            this.buildProductPreview.displayPreview = true;
          }
        }
        else {
          //this.app.showMessageBox("Trigger SI Build","Failed to trigger SI build "+image.baseImageBuild,"OK")
          QLogger.LogError(this.logSrc, "Get Product Composition - Failed");
          QLogger.LogError(this.logSrc, "Get Product Composition - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
      }
    );
  }

  getLatestSIBuilds(image: BuildCompositionImage){

    let imageBuild = image.baseImageBuild;
    let imageBuildType = image.softwareImageTypeSelected;
    QLogger.LogInfo(this.logSrc, "Get Latest Software Image Build : " + imageBuild);
    let response : Observable<QPMResponse>;

    image.loadLatestBuildInProgress = true;    
    this.buildSiSpProcess.errorMessageComposition = "";
    image.softwareImageCustom = [];
    image.softwareImageCustomSelectItem = [];
    image.softwareImageCustomSelected = undefined;
    // Get service task details
    response = this.softwareCatalogClient.getLatestSoftwareImageBuilds(image.softwareImage, imageBuild, imageBuildType);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            image.softwareImageCustom = obj.imageBuilds as SoftwareImageLatest[];
            if(image.softwareImageCustom !== undefined && image.softwareImageCustom !== null && image.softwareImageCustom?.length > 0){
              image.softwareImageCustomSelectItem= this.utils.getSelectItems(image.softwareImageCustom, "imageBuild");
              image.softwareImageCustomSelected = image.softwareImageCustomSelectItem[0].value;
            }
            else{
              this.buildSiSpProcess.errorMessageComposition = "No latest image build found.";
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Failed");
          QLogger.LogError(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          this.buildSiSpProcess.errorMessageComposition = "No latest image build found.";
        }
        image.loadLatestBuildInProgress = false;
        this.onCustomBuildChange(image);
    });
  }

  getProductComposition(){
    QLogger.LogInfo(this.logSrc, "Get "+this.stMain.serviceTasksDetails.baseBuild+" Composition");
    let response : Observable<QPMResponse>;

    // Get service task details
    response = this.softwareCatalogClient.getSoftwareProductBuildComposition(this.stMain.serviceTasksDetails.baseBuild, false);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get "+this.stMain.serviceTasksDetails.baseBuild+" Composition - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get "+this.stMain.serviceTasksDetails.baseBuild+" Composition - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.baseBuildComposition = obj as ServiceTaskDistProductComposition[];  
            this.serviceTaskBuildsMap = new Map<string,string>();
            this.baseBuildComposition.forEach(image =>{
              this.serviceTaskBuildsMap.set(image.softwareImage, image.softwareImageBuild);
            });
            this.displayAvailableImages();
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get "+this.stMain.serviceTasksDetails.baseBuild+" Composition - Failed");
          QLogger.LogError(this.logSrc, "Get "+this.stMain.serviceTasksDetails.baseBuild+" Composition - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
    });
  }

  getRecentProductBuild(){
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products");
    this.serviceTaskDistProducts = [];
    this.loadingRecentProducts = true;
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDistProducts(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Response : " +JSON.stringify(data));
      } 
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          let products = obj as ServiceTaskDistProduct[];
          products.reverse();
          this.serviceTaskDistProducts.push(...products.slice(0,2));
          this.serviceTaskDistProducts.forEach((product) => {
            if(product.errorMessage === "" && product.productNewBuildID !== null) 
            {           
              product.compositionLoaded = false;
              product.releaseInfo = undefined;
              product.composition = [];
              product.loadingComposition = false;
              let software = new SoftwareProductRelease();
              software.softwareProductBuild = product.productNewBuildID;
              this.getRecentProductBuildDetails(product);
            }
          });
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
      }
      this.loadingRecentProducts = false;
    });
  }

  getRecentProductBuildDetails(product: ServiceTaskDistProduct) {
    QLogger.LogInfo(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details");
    this.loadingRecentProductDetails++;
    let response : Observable<QPMResponse>;
    product.loadingDetails = true;
    response = this.softwareCatalogClient.getServiceTaskDistProductDetails(product.productNewBuildID);
    response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Response : " +JSON.stringify(data));
      } 
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          product.detail = obj as ServiceTaskDistProductDetail;
          this.getRecentProductComposition(product);
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Failed");
        QLogger.LogError(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

      }
      product.loadingDetails = false;
      this.loadingRecentProductDetails--;
    });
  }

  getRecentProductComposition(product: ServiceTaskDistProduct){
    product.loadingComposition = true;
    QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition");
    let response : Observable<QPMResponse>;
    this.loadingRecentProductComposition++;
    // Get service task details
    response = this.softwareCatalogClient.getSoftwareProductBuildComposition(product.productNewBuildID, false);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            product.composition = obj as ServiceTaskDistProductComposition[];
            product.compositionSet = new Set(product.composition.map((image)=> image.softwareImageBuild));
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        product.loadingComposition = false;
        this.loadingRecentProductComposition--;
        if(this.loadingRecentProductComposition === 0){
          this.checkDuplicateComposition();
        }
    });
  }

  checkDuplicateComposition(){
    //true - continue loop
    //false - break loop
    if(this.buildSiSpProcess.isTriggerSPProcess){
      this.serviceTaskDistProducts.every((product)=>{
        if(product.detail === undefined || product.compositionSet === undefined) return false;
        if(product.detail.status === "Failed"){
          return true;
        }
        this.buildSiSpProcess.compositionTableData.every((image)=>{
          if(product.compositionSet.has(image.newSoftwareImageBuild)){
            this.buildSiSpProcess.isDuplicateComposition = true;
            return true;
          }
          else{
            this.buildSiSpProcess.isDuplicateComposition = false;
            return false;
          }
        });
        if(this.buildSiSpProcess.isDuplicateComposition === true){
          this.buildSiSpProcess.duplicateProduct = product.productNewBuildID;
          return false;
        }
        else{
          return true;
        }
      });
    }
  }

  displayAvailableImages() {
    this.masterServiceTaskImages = [];
    if (this.baseBuildComposition != undefined && this.baseBuildComposition != null) {
      var index = 0;
      let filterBaseImage: Set<string> = new Set<string>();
      this.serviceTaskBuilds.softwareImages.forEach((stImage)=>{
        var imageNameSplit = stImage.baseImage.split(".");
        imageNameSplit.splice(2, imageNameSplit.length - 2);
        var imageName = imageNameSplit.join(".");
        filterBaseImage.add(imageName);
      });
      if(this.stMain.serviceTasksDetails.createdUsingSI){
        this.stMain.serviceTasksDetails?.serviceTaskImageBuilds.forEach((stImage)=>{
          var imageNameSplit = stImage.softwareImage.split(".");
          imageNameSplit.splice(2, imageNameSplit.length - 2);
          var imageName = imageNameSplit.join(".");
          filterBaseImage.add(imageName);
        });
      }
      this.baseBuildComposition.forEach( image => {
        var imageNameSplit = image.softwareImage.split(".");
        imageNameSplit.splice(2, imageNameSplit.length - 2);
        var imageName = imageNameSplit.join(".");
        // Only show images that are not already included in service task
        if (!filterBaseImage.has(imageName)) {
          this.masterServiceTaskImages.push({
            image:image.softwareImage,
            imageBuild: image.softwareImageBuild,
            integrationSystem: image.integrationSystem,
            status: '',
            userAction: '',
            inProgress: false,
            passthrough: image.passthrough//for CPUCP + add Image
          });
        }
      });
    }
  }


  updateGitDetailsForPassThroughImage(image:any){
    this.stMain.addCustomeBaseImage(image.baseImage, image.baseImageBuild, image.baseImageBuild, image, this.updatePassThroughImage, this, true,true,true);
  }
  updatePassThroughImage(appPtr:any){
    let response : Observable<QPMResponse>;
    let result:UpdatePassThroughResponse;
    if(appPtr.webClient === null) {
      return;
    }
    appPtr.stMain.addCustomBaseImageProcess.displayForm = false;
    appPtr.stMain.addCustomBaseImageProcess.isUpdateForPassThrough=true;
    let rowData = appPtr.stMain.addCustomBaseImageProcess.imageData;
    let gitBranch=appPtr.stMain.addCustomBaseImageProcess.gitBranch?.trim();
    let gitUrl=appPtr.stMain.addCustomBaseImageProcess.gitUrl?.trim();
    let request: UpdatePassThroughRequest;

    request = {
      serviceTaskId : appPtr.stMain.serviceTasksDetails.serviceTaskId,
      softwareImage : rowData.baseImage,
      gitBranch     : gitBranch,
      gitUrl        :gitUrl     
      
    };

    if(appPtr.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(appPtr.logSrc, "Update Passthrough Image Service Task Build: " + JSON.stringify(request) );
    }
    response = appPtr.webClient.updatePassThrough(request);
    response.subscribe(
      (data:QPMResponse) => { 
        rowData.inProgress = false;
        if(appPtr.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(appPtr.logSrc, "Update Passthrough Image Service Task Build - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(appPtr.logSrc, "Update Passthrough Image Service Task Build - Success");  
            result= obj as UpdatePassThroughResponse;
            appPtr.app.showMessageBox("Update Image",result.message,"OK");
          }
          appPtr.loadServiceTaskBuilds();
        }
        else {
          QLogger.LogError(appPtr.logSrc, "Update Passthrough Image Service - Failed");
          QLogger.LogError(appPtr.logSrc, "Update Passthrough Image Service - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          appPtr.app.showMessageBox("Update Image","Failed : "+data.getError(),"OK");
        }
        appPtr.stMain.addCustomBaseImageProcess.isUpdateForPassThrough=false;
      }
    );

  }
  
  addCustomeBaseImage(image: any){
    if(image.passthrough===true){
      this.stMain.addCustomeBaseImage(image.image, image.imageBuild, image.imageBuild, image, this.buildMasterServiceTaskImage, this, false,true,false);
    }else{
      this.stMain.addCustomeBaseImage(image.image, image.imageBuild, image.imageBuild, image, this.buildMasterServiceTaskImage, this, false,false,false);
    }
   
  }

  buildMasterServiceTaskImage(appPtr: any){
    let response : Observable<QPMResponse>;
    if(appPtr.webClient === null) {
      return;
    }

    appPtr.stMain.addCustomBaseImageProcess.displayForm = false;
    let rowData = appPtr.stMain.addCustomBaseImageProcess.imageData;
    let gitBranch=appPtr.stMain.addCustomBaseImageProcess.gitBranch;
    let gitUrl=appPtr.stMain.addCustomBaseImageProcess.gitUrl;
    let request: MasterTriggerBuildRequest;
    request = {
      imageBuild : appPtr.stMain.addCustomBaseImageProcess.softwareImageTypeSelected === "default"? rowData.imageBuild : appPtr.stMain.addCustomBaseImageProcess.softwareImageBuildSelected.imageBuild,
      productBuild : appPtr.stMain.serviceTasksDetails.baseBuild,
      serviceTaskId : appPtr.stMain.serviceTasksDetails.serviceTaskId,
      softwareImage : rowData.image,
      gitBranch     : gitBranch,
      gitUrl        :gitUrl     
      
    };

    if(appPtr.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(appPtr.logSrc, "Trigger Master Service Task Build: " + JSON.stringify(request) );
    }
    rowData.inProgress = true;
    response = appPtr.webClient.masterTriggerBuild(request);
    response.subscribe(
      (data:QPMResponse) => { 
        rowData.inProgress = false;
        if(appPtr.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(appPtr.logSrc, "Trigger Master Service Task Build - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(appPtr.logSrc, "Trigger Master Service Task Build - Success");  
          }
          rowData.status = "Added";
          appPtr.loadServiceTaskBuilds();
        }
        else {
          QLogger.LogError(appPtr.logSrc, "Get Product Composition - Failed");
          QLogger.LogError(appPtr.logSrc, "Get Product Composition - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          rowData.status = data.getError();
        }
      }
    );
  }

  closeProductBuildPreview() {
    this.buildProductPreview.displayPreview = false;
  }

  buildProductAutoShip(){
    if(this.buildSiSpProcess.autoShip){
      this.autoShipSP();
    }
    else{
      this.buildProduct();
    }
  }

  buildProduct(){
    //this.app.showMessageBox("Build Product", "Product Build request sent succesfully", "Ok");

    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    let changedCompositionDetails : BuildCompositionImage[] = [];
    this.compositionDetails.forEach((image)=> {
      if(image.changed || (image.softwareImageTypeSelected !== "default" && this.buildSiSpProcess.customize)){
        changedCompositionDetails.push(image);
      }
    });

    let request: TriggerProductBuildRequest;
    request = {
      images: changedCompositionDetails,
      productBaseBuildID: this.stMain.serviceTasksDetails.baseBuild,
      serviceTaskId: this.stMain.serviceTasksDetails.serviceTaskId,
      distros: this.buildSiSpProcess.buildAllDistros
                ? []
                : this.buildSiSpProcess.distroSelected.map(d => d.softwareDistro),
      skipRecompile: this.buildSiSpProcess.skipRecompile
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Trigger Product Build: " + JSON.stringify(request) );
    }
    this.buildSiSpProcess.triggerSPInProgress = true;
    this.buildSiSpProcess.message = "";
    if(!this.buildSiSpProcess.displayModal) {
      this.buildSiSpProcess.autoShip = false;
    }

    if(this.buildSiSpProcess.buildAllDistros){
      response = this.webClient.triggerProductBuild(request);  
    }
    else{
      response = this.webClient.triggerProductBuildWithDistroList(request);
    }
    
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Trigger Product Build - Response : " +JSON.stringify(data));
        } 
        this.productBuildInProgress = false;
        this.buildProductPreview.displayPreview = false;
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Trigger Product Build - Success");  

            let buildResponse : TriggerProductBuildResponse = obj;

            if (buildResponse !== null && buildResponse !== undefined) {
              let autoShipError: boolean = false;
              if(this.buildSiSpProcess.autoShip){
                if(buildResponse.productNewBuildID === null){
                  autoShipError = true;
                }
                else{
                  this.buildSiSpProcess.message = "SP build place successfully. Build : " + buildResponse.productNewBuildID + ". Auto Ship request in process..";
                  this.autoShipSP();
                }
              }
              
              if(!this.buildSiSpProcess.autoShip || (this.buildSiSpProcess.autoShip && autoShipError)){
                this.buildSiSpProcess.displayModal = false;
                this.buildSiSpProcess.displayAutoShipOption = false;
                this.viewProductBuildDetail.tableData = [];
                this.viewProductBuildDetail.tableData.push({label:'Created At', value: buildResponse.createdAt});
                if (buildResponse.errorCode === 0) {
                  this.viewProductBuildDetail.tableData.push({label:'Build', value: buildResponse.productNewBuildID, link: buildResponse.jobUrl});
                }
                else {
                  this.viewProductBuildDetail.tableData.push({label:'Debug Build Status', value:buildResponse.errorMessage});
                }
                this.viewProductBuildDetail.displayProductBuildDetail = true;
              }            
            }
          }
        }
        else if(data.getCode() !== 504 && data.getCode() !== 500) {
          //this.app.showMessageBox("Trigger SI Build","Failed to trigger SI build "+image.baseImageBuild,"OK")
          this.buildSiSpProcess.displayModal = false;
          this.buildSiSpProcess.displayAutoShipOption = false;
          this.app.showMessageBox("Trigger SP Build","Failed to trigger Software Product build. Error : " + data.getError(),"OK");
          QLogger.LogError(this.logSrc, "Trigger Product Build - Failed");
          QLogger.LogError(this.logSrc, "Trigger Product Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        else{
          QLogger.LogError(this.logSrc, "Trigger Product Build - Failed");
          QLogger.LogError(this.logSrc, "Trigger Product Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.triggerSPInProgress = false;
      }
    );
    this.buildProductPreview.displayPreview = false;
  }

  autoShipSI(image: ServiceTaskBuildImage){

    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    let changedCompositionDetails : BuildCompositionImage[] = [];
    this.compositionDetails.forEach((image)=> {
      if(image.changed || (image.softwareImageTypeSelected !== "default" && this.buildSiSpProcess.customize)){
        changedCompositionDetails.push(image);
      }
    });

    let request: AutoShipRequest;
    request = {
      autoShip: this.buildSiSpProcess.autoShip,
      autoDiffShip:this.buildSiSpProcess.autoDiffShip,
      diffBaseSoftwareProductBuild:this.buildSiSpProcess.diffBuildSelected,
      cirrusBuildId: [],
      cirrusTaskId: image.cirrusId,
      images: changedCompositionDetails,
      distroList: this.buildSiSpProcess.distroSelected.map(d => d.softwareDistro),
      serviceTaskId: image.servicetaskId,
      softwareProductBaseBuild: this.stMain.serviceTasksDetails.baseBuild,
      skipRecompile: this.buildSiSpProcess.skipRecompile,
      fullBuild: this.buildSiSpProcess.fullBuild,
      buildAllDistros:this.buildSiSpProcess.buildAllDistros,
    };

    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Request Product Auto Ship: " + JSON.stringify(request) );
    }

    this.buildSiSpProcess.triggerAutoShipInProgress = true;
    response = this.webClient.triggerAutoShipSIAsync(request);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Request Product Auto Ship - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Request Product Auto Ship - Success");
            if (obj.success) {
              this.buildSiSpProcess.displayModal = false;
              this.buildSiSpProcess.displayAutoShipOption = false;
              this.app.showMessageBox("Auto Ship Request","Auto Ship request has been queued successfully","OK");
            }
          }
        }
        else{
          this.buildSiSpProcess.message = "Auto Ship request has been failed. Error: " + data.getError();
          QLogger.LogError(this.logSrc, "Request Product Auto Ship - Failed");
          QLogger.LogError(this.logSrc, "Request Product Auto Ship - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.triggerAutoShipInProgress = false;
        this.buildSiSpProcess.autoShip = false;
      }
    );
  }

  autoShipSP(){

    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    let changedCompositionDetails : BuildCompositionImage[] = [];
    this.compositionDetails.forEach((image)=> {
      if(image.changed || (image.softwareImageTypeSelected !== "default" && this.buildSiSpProcess.customize)){
        changedCompositionDetails.push(image);
      }
    });

    let request: AutoShipRequestSP;
    request = {
      autoShip: this.buildSiSpProcess.autoShip,
      autoDiffShip:this.buildSiSpProcess.autoDiffShip,
      diffBaseSoftwareProductBuild:this.buildSiSpProcess.diffBuildSelected,
      buildAllDistros: this.buildSiSpProcess.buildAllDistros,
      images: changedCompositionDetails,
      distroList: this.buildSiSpProcess.distroSelected.map(d => d.softwareDistro),
      serviceTaskId: this.stMain.serviceTasksDetails.serviceTaskId,
      softwareProductBaseBuild: this.stMain.serviceTasksDetails.baseBuild,
      skipRecompile: this.buildSiSpProcess.skipRecompile
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Request Product Auto Ship SP: " + JSON.stringify(request) );
    }

    this.buildSiSpProcess.triggerAutoShipInProgress = true;
    this.buildSiSpProcess.message = "";
    response = this.webClient.triggerAutoShipSPAsync(request);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Request Product Auto Ship SP - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Request Product Auto Ship SP - Success");
            if (obj.success) {
              this.buildSiSpProcess.displayModal = false;
              this.buildSiSpProcess.displayAutoShipOption = false;
              if(this.buildSiSpProcess.autoShip){
                this.app.showMessageBox("Auto Ship Request","Auto Ship request has been queued successfully","OK");
                this.stMain.shipBaseSoftwareProduct(this.buildSiSpProcess.distroSelected);
              }
              else{
                this.app.showMessageBox("Trigger SP Request","Trigger SP Request has been queued successfully","OK");
              }
            }
          }
        }
        else{
          if(this.buildSiSpProcess.autoShip){
            this.buildSiSpProcess.message = "Auto Ship request is failed. Error: " + data.getError();
          }
          else{
            this.buildSiSpProcess.message = "Trigger SP request is failed. Error: " + data.getError();
          }
          QLogger.LogError(this.logSrc, "Request Product Auto Ship SP - Failed");
          QLogger.LogError(this.logSrc, "Request Product Auto Ship SP - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.triggerAutoShipInProgress = false;
        this.buildSiSpProcess.autoShip = false;
      }
    );
  }

  closeProductBuildDetails() {
    this.viewProductBuildDetail.displayProductBuildDetail = false;
  }

  showBuildRequestDetail(build: ServiceTaskCirrusBuild){
    this.viewBuildRequestDetailProcess.displayBuildRequestDetail = true;
    this.viewBuildRequestDetailProcess.loadingImpactedFiles = true;
    this.viewBuildRequestDetailProcess.build = build;
    this.viewBuildRequestDetailProcess.tableData = [];
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build ID', value:build.build_id});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build Status', value:build.status});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build Logs', value:build.build_url, link:build.build_url});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build Location', value:build.build_location, path:build.build_location});
    if(build.related_build !== undefined && build.related_build !== null){
      build.related_build.forEach((relatedBuild) => {
        if(relatedBuild.build_type === "Debug"){
          this.viewBuildRequestDetailProcess.tableData.push({label:'Debug Build ID', value:relatedBuild.build_id});
          this.viewBuildRequestDetailProcess.tableData.push({label:'Debug Build Status', value:relatedBuild.status});
          this.viewBuildRequestDetailProcess.tableData.push({label:'Debug Build Logs', value:relatedBuild.build_url, link:relatedBuild.build_url});
          this.viewBuildRequestDetailProcess.tableData.push({label:'Debug Build Location', value:relatedBuild.build_location, path:relatedBuild.build_location});
        }
      });
    }
    this.viewBuildRequestDetailProcess.tableData.push({label:'Start', value: build.build_start_timestamp ===0 ? "" : this.utils.epochToDateString(build.build_start_timestamp)});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Complete', value: build.build_completed_timestamp ===0 ? "" : this.utils.epochToDateString(build.build_completed_timestamp)});
    this.getImpactedFilesForImage(build.build_id);

  }
  getImpactedFilesForImage(softwareBuildId:string){

    let response : Observable<QPMResponse>;
    if(this.softwareCatalogClient === null) {
      this.viewBuildRequestDetailProcess.loadingImpactedFiles = false;
      return
    }
    response = this.softwareCatalogClient.getSoftwareProductBuildImpactedFiles(softwareBuildId,true);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Image "+softwareBuildId+" Impacted Files - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Image "+softwareBuildId+" Impacted Files - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.viewBuildRequestDetailProcess.impactedFiles = obj.changeRequestFiles as ServiceTaskDistImpactedFiles[];
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Image "+softwareBuildId+" Impacted Files - Failed");
          QLogger.LogError(this.logSrc, "Get Image "+softwareBuildId+" Impacted Files - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        this.viewBuildRequestDetailProcess.loadingImpactedFiles = false;
    });
    
  }
  getCRComments(softwareImage:string,cr:ServiceTaskBuildCR){
    let request: CrGerritComment = {
      crId: cr.changeRequestId,
      gerritId: undefined,
      softwareImage: softwareImage
    }
    this.stMain.getComments(request, cr);
  }

  onCustomizeToggle(){
    this.buildSiSpProcess.signRequired = false;
    this.buildSiSpProcess.imageSignedMessages =[];
    this.buildSiSpProcess.isEmptyBuild = false;
    if(!this.buildSiSpProcess.customize){
      this.buildSiSpProcess.compositionTableData.forEach((image)=>{
        if(image.changed){
          if(image.newSoftwareImageBuildInfo?.isSigningRequired)
            this.buildSiSpProcess.signRequired = true;
          this.buildSiSpProcess.imageSignedMessages.push(image.newSoftwareImageBuildInfo.signingInfoMessage);
          image.buildStatus= image.newSoftwareImageBuildInfo.imageBuildStatus;
          if(image.newSoftwareImageBuild === undefined){
            this.buildSiSpProcess.isEmptyBuild = true;
          }
        }
      });
    }
  }

  onBuildChange(image:BuildCompositionImage){

    this.buildSiSpProcess.signRequired = false;
    image.newSoftwareImageBuild = image.newSoftwareImageBuildInfo?.softwareImageBuild;
    this.buildSiSpProcess.imageSignedMessages =[];
    this.buildSiSpProcess.isEmptyBuild = false;
    this.buildSiSpProcess.compositionTableData.forEach((image)=>{
      if(image.changed){
        if(image.newSoftwareImageBuildInfo?.isSigningRequired)
          this.buildSiSpProcess.signRequired = true;
        this.buildSiSpProcess.imageSignedMessages.push(image.newSoftwareImageBuildInfo.signingInfoMessage);
        image.buildStatus= image.newSoftwareImageBuildInfo.imageBuildStatus;
        if(image.newSoftwareImageBuild === undefined){
          this.buildSiSpProcess.isEmptyBuild = true;
        }
      }
    });
    this.checkDuplicateComposition();
  }

  onCustomBuildChange(image:BuildCompositionImage){
    image.newSoftwareImageBuild = image.softwareImageCustomSelected?.imageBuild;
    this.buildSiSpProcess.isEmptyBuild = false;
    this.buildSiSpProcess.compositionTableData.forEach((image)=>{
      if(image.newSoftwareImageBuild === undefined){
        this.buildSiSpProcess.isEmptyBuild = true;
      }
    });
    this.checkDuplicateComposition();
  }

  onShipImageActionClick(build: ServiceTaskCirrusBuild)
  {
    this.shipImageProcess.softwareImageBuild = build.build_id;
    this.getDistrosShipStatusForImage(build.build_id);
    this.shipImageProcess.displayDistro = true;
    
  }
  onDownloadImageActionClick(build: ServiceTaskCirrusBuild)
  {
    if(this.app.downloadQueue.downloadInProgress){
      this.app.showMessageBox("Download", "Download request failed. Currently another download in-progress", "Ok");
      return;
    }
    this.downloadImageProcess.softwareImageBuild = build.build_id;
    this.getDistrosShipStatusForImage(build.build_id);
    this.downloadImageProcess.displayDistro = true;
  }
  
  getDistrosShipStatusForImage(buildId:string)
  {
    this.shipImageProcess.loadingDistros = true;
    this.downloadImageProcess.loadingDistros = true;
    let response : Observable<QPMResponse>;
    this.shipImageProcess.distroTypes = [];
    this.downloadImageProcess.distroTypes = [];
    this.downloadImageProcess.distroTypesSelectItems =[];
    let distroList:SoftwareProductDistroInfo[]=[];
    let request: ImageBuildDistributionRequest;
    this.productDistros.forEach(distro=>{
 
      let distroInfo  = new SoftwareProductDistroInfo() ;
      distroInfo.distroName =  distro.softwareDistro;
      distroInfo.distroId   = Number(distro.softwareDistroId);
      distroInfo.uploadComplete = 0;
      distroInfo.softwareDistroAlternateId = distro.softwareDistroAlternateId;
      distroList.push(distroInfo);
    });
    request = {
      softwareImageBuild : buildId,
      softwareDistroList : distroList
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Request is "+JSON.stringify(request)); 
    }
    response = this.webClient.getImageDistroShipStaus(request);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get Software Image "+buildId+" Distro Status - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get Software Image "+buildId+" Distro Status- Success");
          let shipStatusResp:imageShipDownloadResponse;
          let distrosStatus: Map<string, SoftwareImageDistroInfo> = new Map<string, SoftwareImageDistroInfo>();
          shipStatusResp = JSON.parse(data.getData());
          if(shipStatusResp !== undefined || shipStatusResp !== null){
            shipStatusResp.imageDistroList?.forEach((distro) =>{
                  distrosStatus.set(distro.distroName, distro);
            });
          }
          this.shipImageProcess.distroTypes = [];
          this.downloadImageProcess.distroTypes = [];
          this.productDistros.forEach((distro) => {
            let newDistro: SoftwareProductDistro = new SoftwareProductDistro();
            newDistro.imageUploadStatus=[];
            newDistro.softwareDistro = distro.softwareDistro;
            newDistro.distroType = distro.distroType;
            newDistro.softwareDistroAlternateId = distro.softwareDistroAlternateId;
            newDistro.softwareDistroId = distro.softwareDistroId;
            newDistro.stream = distro.stream;
            newDistro.softwareDistroFolderName = distro.softwareDistroFolderName;
           
            if(distrosStatus.has(distro.softwareDistro)){
                let shippedDistroInfo = distrosStatus.get(distro.softwareDistro)
                newDistro.uploadComplete = shippedDistroInfo.uploadComplete;
                newDistro.imageUploadTrackingId = shippedDistroInfo.imageUploadTrackingId;
                newDistro.disabled = newDistro.uploadComplete === 1 || newDistro.uploadComplete === 0;
                if(newDistro.uploadComplete !=4){
                      if(newDistro.uploadComplete==1) newDistro.status ="In Progress";
                      else if(newDistro.uploadComplete==0) newDistro.status ="Queued";
                      else if(newDistro.uploadComplete==2) newDistro.status ="Success";
                      else if(newDistro.uploadComplete==3) newDistro.status ="Failed";
                      
                      this.downloadImageProcess.distroTypes.push(newDistro);
                    }
                    else
                      this.shipImageProcess.distroTypes.push(newDistro);
                      
                  }
                  else
                    this.shipImageProcess.distroTypes.push(newDistro);
                           
                });
                if(this.shipImageProcess.distroTypes?.length === 1){
                  this.shipImageProcess.distroSelected.push(this.shipImageProcess.distroTypes[0]);
                }
                if(this.downloadImageProcess.distroTypes?.length === 1 && this.downloadImageProcess.distroTypes[0].uploadComplete===2){
                  this.downloadImageProcess.distroSelected = this.downloadImageProcess.distroTypes[0];        
                }
        }
        this.shipImageProcess.loadingDistros = false;
        this.downloadImageProcess.loadingDistros = false;
      });
  }
  onShipImageButtonClick(event){
    this.shipSoftwareImageBuild(this.shipImageProcess.softwareImageBuild, this.shipImageProcess.distroSelected);
  }
  onDownloadImageButtonClick(event){
    this.downloadImageProcess.displayDistro = false;
    let downloadPath:string;
    downloadPath ="";
    let defaultDownloadPath:string;
    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      const { dialog } = window.require('electron').remote;
      response = this.distributionClient.getDefaultDownloadLocation();
      response.subscribe(
        (data: QPMResponse) => {
          defaultDownloadPath = data.getData();;
          var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",defaultPath :defaultDownloadPath,properties: ['openDirectory'] }); //Select Download Path
          if(!downloadPathList)
          {
            downloadPath = "";
          }
          else
          {
            downloadPath = downloadPathList[0];
          }
         this.downloadSoftwareImage(downloadPath);
          
        });
    }
    else{
       this.downloadSoftwareImage(downloadPath);
    }
  }
  downloadSoftwareImage(downloadPath){

    QLogger.LogInfo(this.logSrc, "Downloading Image : " +this.downloadImageProcess.softwareImageBuild  + " Distro : " + this.downloadImageProcess.distroSelected?.softwareDistro);
    let response: Observable<QPMResponse>;
    if (this.distributionClient === null || this.app.downloadQueue.downloadInProgress) {
        let end =this.utils.getCurrentDateTime_24();
        return;
    }        
    this.app.startDownload();
    let request: ImageDistributionRequest;
    let requestedPlatform : Number;
    if(this.app.sharedData.appInfo.isElectronMode)
    requestedPlatform = 0;
    else
    requestedPlatform = 1;
    request = {
        imageBuild:this.downloadImageProcess.softwareImageBuild,
        softwareDistro:this.downloadImageProcess.distroSelected?.softwareDistro,
        softwareDistroID:this.downloadImageProcess.distroSelected.softwareDistroId,
        requestedPlatform: requestedPlatform,
        softwareDistroAlternateId:this.downloadImageProcess.distroSelected.softwareDistroAlternateId,
        imageUploadTrackingId:this.downloadImageProcess.distroSelected.imageUploadTrackingId
    };
    if (this.app.sharedData.appInfo.logRequest) {
        QLogger.LogInfo(this.logSrc,JSON.stringify(request));
    }                                  
    response = this.distributionClient.downloadSoftwareImageBuild(request);
    response.subscribe(
         (data: QPMResponse) => {
            if (this.app.sharedData.appInfo.logResponse) {
                QLogger.LogInfo(this.logSrc, "Download Image Build Success Response: " + JSON.stringify(data));
              }
              if (data.isSuccess()) {
              
                let obj = JSON.parse(data.getData());
                let downloadTrackingId = obj.stardustImageTrackingId;
                this.app.updateDownloadHistory(null,null,this.downloadImageProcess.distroSelected,"In Progress",null,null,this.downloadImageProcess.softwareImageBuild);

                this.app.StartDownload(obj.signedUrl, null,null,this.downloadImageProcess.distroSelected,downloadPath,downloadTrackingId,this.downloadImageProcess.softwareImageBuild);
              }
          });

  }
  shipSoftwareImageBuild(softwareImageBuild:string,distros: SoftwareProductDistro[])
  {
    QLogger.LogInfo(this.logSrc, "Ship Software :" + softwareImageBuild);
    let response: Observable<QPMResponse>;
    let distroList:SoftwareProductDistroInfo[] = [];
    let req:ImageBuildDistributionRequest;
    let requestedPlatform : Number;
    if(this.app.sharedData.appInfo.isElectronMode)
    requestedPlatform = 0;
    else
    requestedPlatform = 1;
    if (this.distributionClient === null) {
      return
    }
    let uniqueDistros = [...new Set(distros)];
    uniqueDistros.forEach(function (distro) 
    {
      let info  = new SoftwareProductDistroInfo();  
      info.distroName =  distro.softwareDistro;
      info.distroId   = Number(distro.softwareDistroId);
      info.uploadComplete = 0;
	  info.softwareDistroAlternateId = distro.softwareDistroAlternateId;
      distroList.push(info);
    });

    req = {
      serviceTaskId : this.stMain.serviceTasksDetails.serviceTaskId,
      softwareImageBuild : softwareImageBuild,
      softwareDistroList : distroList,
      requestedPlatform : requestedPlatform
    };
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Ship Software Request: " + JSON.stringify(req));
    }
    response = this.distributionClient.shipSoftwareImage(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Ship Software Image Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Ship Software Image Success:" + softwareImageBuild);
        }
        else{
          QLogger.LogError(this.logSrc, "Ship Software Image Failed:" + softwareImageBuild);
          QLogger.LogError(this.logSrc, "Ship Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          if(this.app.sharedData.userInfo.info.internal)
          {
            let obj :shipStatusErrorResponse;
            try{
                obj = JSON.parse(data.getErrorDetail());
                this.app.displayShipStatus(softwareImageBuild,"Failure",obj.error.message);
            }
            catch (e) 
            {
              QLogger.LogError(this.logSrc,"Cannot Parse error message from logs");
              this.app.displayShipStatus(softwareImageBuild,"Failure",data.getError());
            }
          }
          else
          this.app.displayShipStatus(softwareImageBuild,"Failure",null);
        }
      }
    );
    this.shipImageProcess.displayDistro = false;
    this.app.showMessageBox("Ship Request", "Ship Request for " + softwareImageBuild + " has been placed", "Ok");
  }
  //#endregion

  //#region App Build
  
  //#region Apps Build Events
  
  onFilterClearGerrit() {
    this.gerritGlobalFilter.nativeElement.value = '';
    this.gerritTable.filterGlobal('', 'contains');
  }
  onTriggerBaitBuildClicked(image: ServiceTaskBuildImage){
    this.resetBuildSiSpProcess();
    this.onTriggerBuildInit();

    this.buildSiSpProcess.displayBaitForm = true;
    this.buildSiSpProcess.isTriggerBaitProcess = true;
    this.buildSiSpProcess.image = image;

    //Get accepted Gerrits
    let gerrits: Gerrit[] = [];
    let gerritSet: Map<string, Gerrit> = new Map();
    this.stMain.serviceTasksDetails.changes.forEach((gerritSI)=>{
      if(gerritSI.softwareImage === image.baseImage){
        gerritSI.gerrits.forEach((gerrit)=>{
          if(gerrit.action === "Accept"){
            gerrits.push(gerrit);
            gerritSet.set(gerrit.gerritId, gerrit);
          }
        });
      }
    });
    this.buildSiSpProcess.gerrits = gerrits;
    this.buildSiSpProcess.allGerrits.push(...gerrits);

    //Get accepted CRs
    let crs: ChangeRequest[] = [];
    this.stMain.serviceTasksDetails.changes.forEach((gerritSI)=>{
      if(gerritSI.softwareImage === image.baseImage){
        gerritSI.changeRequests.forEach((cr)=>{
          if(cr.action === "Accept"){
            crs.push(cr);
          }
        });
      }
    });
    this.buildSiSpProcess.crs = crs;
    this.buildSiSpProcess.allCrs.push(...crs);
        
    //Get previous CRs/Gerrits
    if(image.baitBuilds.length > 0){
      for(let i =0; i < image.baitBuilds.length; i++){
        if(image.baitBuilds[i].baitJob !== undefined && image.baitBuilds[i].baitJob.overall_status === "Complete"){
          //Get Previous Build Gerrits
          gerrits = [];
          let previousGerrits = image.baitBuilds[i].baitJob.gerrits;
          this.buildSiSpProcess.previousGerritSet = new Set<string>(image.baitBuilds[i].baitJob.gerrits);
          //Maintain gerrit order of last complete build
          previousGerrits.forEach((gerritId)=>{
            if(gerritSet.has(gerritId)){
              gerrits.push(gerritSet.get(gerritId));
              this.buildSiSpProcess.previousGerrits.push(gerritSet.get(gerritId));
            }
          });
          this.buildSiSpProcess.gerrits.forEach((gerrit)=>{
            if(!this.buildSiSpProcess.previousGerritSet.has(gerrit.gerritId)){
              gerrits.push(gerrit);
            }
          });
          this.buildSiSpProcess.gerrits = [];
          this.buildSiSpProcess.allGerrits = [];
          this.buildSiSpProcess.gerrits = gerrits;
          this.buildSiSpProcess.allGerrits.push(...gerrits);

          //Get Previous Build CRs
          this.buildSiSpProcess.previousCrSet = new Set<Number>(image.baitBuilds[i].baitJob.change_requests);
          this.buildSiSpProcess.crs.forEach((cr)=>{
            if(this.buildSiSpProcess.previousCrSet.has(cr.changeRequest)){
              this.buildSiSpProcess.previousCrs.push(cr);
            }
          });
          break;
        }
      }
    }
    this.onSelectPreviousGerrits();
    this.onSelectPreviousCrs();
    this.getHlosTechnologies();
    this.getHlosTarget(image.baseImage);
  }

  onBaitBuildNext(){
    if(this.buildSiSpProcess.isTriggerSIProcess){
      this.buildSiSpProcess.displayBaitForm = false;
      this.buildSiSpProcess.displayModal = true;
      this.buildSiSpProcess.displayForm = true;
    }
    else{
      this.onTriggerSIClicked(this.buildSiSpProcess.image);
    }
  }

  onBaitBuildBack(){
    this.buildSiSpProcess.displayBaitForm = true;
    this.buildSiSpProcess.displayModal = false;
    this.buildSiSpProcess.displayForm = false;
  }

  onBaitBuildSubmit(){
    this.buildSiSpProcess.technologySelected = undefined;
    this.buildSiSpProcess.targetSelected = undefined;
  }

  onAbortBaitBuild(image: ServiceTaskBuildImage, baitJob: ServiceTaskBaitBuild){
    this.abortBaitBuildProcess.displayForm = true;
    this.abortBaitBuildProcess.image = image;
    this.abortBaitBuildProcess.baitJob = baitJob;
  }
  
  onSelectPreviousCrs(){    
    let selectedCRs: Set<Number> = new Set<Number>();    
    this.buildSiSpProcess.crSelected.forEach((cr)=>{
      selectedCRs.add(cr.changeRequest);
    });

    this.buildSiSpProcess.crSelected = [];
    let availableCrs: ChangeRequest[] = []
    //Get accepted Gerrits
    this.buildSiSpProcess.crSelected.push(...this.buildSiSpProcess.previousCrs);
    this.buildSiSpProcess.allCrs.forEach((cr)=>{ 
      if(!this.buildSiSpProcess.previousCrSet.has(cr.changeRequest)){
        if(selectedCRs.has(cr.changeRequest)){
          this.buildSiSpProcess.crSelected.push(cr);
        }
        else{
          availableCrs.push(cr);
        }
      }
    });
    this.buildSiSpProcess.crs = availableCrs;
  }  
  onSelectAllCrs(){
    this.buildSiSpProcess.crSelected.push(...this.buildSiSpProcess.crs);
    this.buildSiSpProcess.crs = [];
  }
  onUnselectAllCrs(){
    this.buildSiSpProcess.crs.push(...this.buildSiSpProcess.crSelected);
    this.buildSiSpProcess.crSelected = [];
  }

  onSelectPreviousGerrits(){
    let selectedGerrits: Set<string> = new Set<string>();    
    this.buildSiSpProcess.gerritSelected.forEach((gerrit)=>{
      selectedGerrits.add(gerrit.gerritId);
    });

    this.buildSiSpProcess.gerritSelected = [];
    let availableGerrits: Gerrit[] = []
    //Get accepted Gerrits
    this.buildSiSpProcess.gerritSelected.push(...this.buildSiSpProcess.previousGerrits);
    this.buildSiSpProcess.allGerrits.forEach((gerrit)=>{
      if(!this.buildSiSpProcess.previousGerritSet.has(gerrit.gerritId)){
        if(selectedGerrits.has(gerrit.gerritId)){
          this.buildSiSpProcess.gerritSelected.push(gerrit);
        }
        else{
          availableGerrits.push(gerrit);
        }
      }
    });
    this.buildSiSpProcess.gerrits = availableGerrits;
  }
  onSelectAllGerrits(){
    this.buildSiSpProcess.gerritSelected.push(...this.buildSiSpProcess.gerrits);
    this.buildSiSpProcess.gerrits = [];
  }
  onUnselectAllGerrits(){
    this.buildSiSpProcess.gerrits.push(...this.buildSiSpProcess.gerritSelected);
    this.buildSiSpProcess.gerritSelected = [];
  }
  //#endregion

  resetAbortBaitBuildProcess(){
    this.abortBaitBuildProcess = {
      displayForm: false,
      image: undefined,
      baitJob: undefined,
      message: ""
    }
  }
  
  showCrmBuildDetail(baitJob: ServiceTaskBaitBuild){
    this.viewBuildRequestDetailProcess.displayBuildRequestDetail = true;
    this.viewBuildRequestDetailProcess.tableData = [];
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build ID', value:baitJob.baitJob.crm_build_info.image_build});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build Status', value:baitJob.baitJob.crm_build_info.status});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build Job', value:baitJob.baitJob.crm_build_info.job_link, link:baitJob.baitJob.crm_build_info.job_link});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Build Path', value:baitJob.baitJob.crm_build_info.build_path, path:baitJob.baitJob.crm_build_info.build_path});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Start', value: baitJob.baitJob.crm_build_info.build_start_time});
    this.viewBuildRequestDetailProcess.tableData.push({label:'Complete', value: baitJob.baitJob.crm_build_info.build_completed_time});
  }

  loadServiceTaskBaitBuildCRs(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.changeRequests.length !== 0){
      return;
    }
    image.changeRequests = [];
    this.stMain.serviceTasksDetails.changes.forEach((gerritSI)=>{
      if(gerritSI.softwareImage === image.baseImage){
        gerritSI.changeRequests.forEach((cr)=>{
          if(cr.action === "Accept"){
            var buildCr = new ServiceTaskBuildCR();
            buildCr.changeRequestId = cr.changeRequest;
            buildCr.comments = [];
            buildCr.changeRequestDate ="";
            buildCr.changeRequestDate = cr.requestedDate;
            let request: CrGerritComment = {
              crId: cr.changeRequest,
              gerritId: undefined,
              softwareImage: image.baseImage
            }
            this.stMain.getComments(request, buildCr);
            this.loadServiceTaskBuildCRDetail(image, buildCr);
            this.loadServiceTaskBuildCRCurrentInfo(image, buildCr);
            image.changeRequests.push(buildCr);
          }
        });
      }
    });
  }

  loadServiceTaskBaitBuildGerrits(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.gerrits.length !== 0){
      return;
    }
    image.gerrits = [];
    this.stMain.serviceTasksDetails.changes.forEach((gerritSI)=>{
      if(gerritSI.softwareImage === image.baseImage){
        gerritSI.gerrits.forEach((gerrit)=>{
          if(gerrit.action === "Accept"){
            let request: CrGerritComment = {
              crId: undefined,
              gerritId: gerrit.gerritId,
              softwareImage: image.baseImage
            }
            this.stMain.getComments(request, gerrit);
            image.gerrits.push(gerrit);
          }
        });
      }
    });
  }

  loadServiceTaskBaitBuilds(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.baitBuilds.length !== 0){
      return;
    }
    let serviceTakID = this.stMain.serviceTasksDetails.serviceTaskId;
    let baseImage = image.baseImage;
    QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds");
    let response : Observable<QPMResponse>;
    image.loadingBaitBuilds = true;
    response = this.softwareCatalogClient.getServiceTaskBaitBuilds(serviceTakID.toString(), baseImage);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            image.baitBuilds = obj.image_builds as ServiceTaskBaitBuild[];
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }

        //Collect Local File Upload Bait Build Ids
        this.loadServiceTaskLocalFileUploadBaitBuilds(serviceTakID,baseImage,image)

        image.loadingBaitBuilds = false;
      }
    );
  }

  loadServiceTaskLocalFileUploadBaitBuilds(serviceTakID: number,baseImage:string, image: ServiceTaskBuildImage){
    QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Local File Upload Bait Builds");
    let localFileBaitBuildsresponse : Observable<QPMResponse>;
    image.loadingBaitBuilds = true;
    localFileBaitBuildsresponse = this.softwareCatalogClient.getBaitLocalfileUploadBuilds(serviceTakID.toString(), baseImage);
    localFileBaitBuildsresponse.subscribe(
      (data:QPMResponse)=>{
          if(this.app.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds For Local File Upload - Response : " +JSON.stringify(data));
          }
          if(data.isSuccess()){
              QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds For Local File Upload - Success");
              let obj = JSON.parse(data.getData());
              if(obj!==undefined || obj!==null){
                let baitLocalFileUploadImageBuilds:baitLocalFileUploadImageBuild[];
                baitLocalFileUploadImageBuilds=[];
                baitLocalFileUploadImageBuilds=obj.image_builds as baitLocalFileUploadImageBuild[];
                baitLocalFileUploadImageBuilds?.forEach(build=>{
                  let baitBuild=new ServiceTaskBaitBuild();
                  baitBuild.isLocalfileuploadBuild=true;
                  baitBuild.build_id=build.build_id;
                  baitBuild.request_date=build.request_date;
                  image.baitBuilds.push(baitBuild);
                })
              }
          }else{
            QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds For Local File Upload - Failed");
            QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds For Local File Upload- Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          }
          image.loadingBaitBuilds = false;

          //Sorting and Other PostProcessing of All BuildIDs
          image.baitBuilds.sort((b1,b2)=> b2.request_date.localeCompare(b1.request_date));
          image.baitBuilds.forEach((baitBuild)=>{
            //call based on isLocalfileuploadBuild is true OR false
            if(baitBuild.isLocalfileuploadBuild===true){
              this.loadServiceTaskBaitLocalFileUploadBuildDetails(baitBuild,image)
            }else{
              this.loadServiceTaskBaitBuildDetails(baitBuild, image);
            }
          });
    })

  }
  loadServiceTaskBaitBuildDetails(baitBuild: ServiceTaskBaitBuild, image: ServiceTaskBuildImage){
    let serviceTakID = this.stMain.serviceTasksDetails.serviceTaskId;
    let buildID = baitBuild.build_id;
    QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details");
    let response : Observable<QPMResponse>;
    image.loadingBaitBuildDetails++;
    response = this.softwareCatalogClient.getServiceTaskBaitBuildDetails(serviceTakID.toString(), buildID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            baitBuild.baitJob = obj as ServiceTaskBaitJob;
            //RegEx to extract OSSLink
            const regex = new RegExp('https?:\\/\\/[^\\s].*tar.gz', '')
            let finalOssLink=baitBuild.baitJob.oss?.match(regex);
            if(finalOssLink!==null && finalOssLink?.length>0){
              baitBuild.baitJob.oss=finalOssLink[0];
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.loadingBaitBuildDetails--;
      }
    );
  }

  loadServiceTaskBaitLocalFileUploadBuildDetails(baitBuild: ServiceTaskBaitBuild, image: ServiceTaskBuildImage){
    let serviceTakID = this.stMain.serviceTasksDetails.serviceTaskId;
    let buildID = baitBuild.build_id;
    QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + "  Bait Local File Upload Build Details");
    let response : Observable<QPMResponse>;
    image.loadingBaitBuildDetails++;
    response = this.softwareCatalogClient.getBaitLocalFileUploadBuildDetails(serviceTakID.toString(), buildID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Local File Upload Build Details - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + "  Bait Local File Upload Build Details - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            let baitLocalFileUploadJobDetails=new BaitLocalFileUploadJobDetails();
            baitLocalFileUploadJobDetails = obj as BaitLocalFileUploadJobDetails;
            let filteredcrm_build_info=baitLocalFileUploadJobDetails.crm_build_info.filter(b=>b.image_name.toLocaleLowerCase()===image.baseImage.toLocaleLowerCase());
            baitLocalFileUploadJobDetails.crm_build_info=filteredcrm_build_info;//should not be more than 1

           // conversion of BaitLocalFileUploadJobDetails into ServiceTaskBaitJob without bait_build_info
            let serviceTaskBaitJob = new ServiceTaskBaitJob();

            serviceTaskBaitJob.build_task_id=baitLocalFileUploadJobDetails.build_task_id;
            serviceTaskBaitJob.product_build_id=baitLocalFileUploadJobDetails.product_build_id;
            serviceTaskBaitJob.image_name=baitLocalFileUploadJobDetails.crm_build_info[0]?.image_name;
            serviceTaskBaitJob.user=baitLocalFileUploadJobDetails.submitted_by;
            serviceTaskBaitJob.overall_status=baitLocalFileUploadJobDetails.crm_build_info[0]?.status;//Need to Change In Later Ticket

            let crm_build_info=new ServiceTaskCrmBuildInfo();
            crm_build_info=baitLocalFileUploadJobDetails.crm_build_info[0]
            serviceTaskBaitJob.crm_build_info=crm_build_info;

            baitBuild.baitJob = serviceTaskBaitJob;
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + "  Bait Local File Upload Build Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + "  Bait Local File Upload Build Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.loadingBaitBuildDetails--;
      }
    );
  }

  //#region Load PassthroughBuild Details
  serviceTaskPassThroughBuilds(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.passThroughBuilds.length !== 0){
      return;
    }
    let serviceTakID = this.stMain.serviceTasksDetails.serviceTaskId;
    let baseImage = image.baseImage;
    QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " PassThrough Builds");
    let response : Observable<QPMResponse>;
    image.loadingPassThroughBuilds = true;
    response = this.softwareCatalogClient.getserviceTaskPassThroughBuilds(serviceTakID.toString(), baseImage);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " PassThrough Builds - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " PassThrough Builds - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            image.passThroughBuilds = obj.image_builds as ServiceTaskPassThroughBuild[];
            image.passThroughBuilds.sort((b1,b2)=> b2.request_date.localeCompare(b1.request_date));
            image.passThroughBuilds.forEach((passThroughBuild)=>{
              this.loadServiceTaskPassThroughBuildDetails(passThroughBuild,image);
            });
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " PassThrough Builds - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " PassThrough Builds - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.loadingPassThroughBuilds = false;
      }
    );
  }

  loadServiceTaskPassThroughBuildDetails(passThroughBuild:ServiceTaskPassThroughBuild,image: ServiceTaskBuildImage){
   let buildID = passThroughBuild.image_build;
   let serviceTakID = this.stMain.serviceTasksDetails.serviceTaskId;
   QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " PassThrough BuildID : " + buildID + " PassThrough Build Details");
   let response : Observable<QPMResponse>;
   image.loadingPassThroughBuildDetails++;
   response = this.softwareCatalogClient.getserviceTaskPassThroughBuildDetails(buildID);

   response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + "PassThrough BuildID : " + buildID + " PassThrough Build Details - Response : " +JSON.stringify(data));
      } 
      if (data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + "PassThrough BuildID : " + buildID + " PassThrough Build Details - Success");
        let obj = JSON.parse(data.getData());
        if (obj !== undefined || obj !== null) {
          passThroughBuild.passThroughImageBuildInfo = obj as PassThroughImageBuildInfo;
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + "PassThrough BuildID : " + buildID + " PassThrough Build Details - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + "PassThrough BuildID : " + buildID + " PassThrough Build Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }
      image.loadingPassThroughBuildDetails--;
    }
  );

  }
  //#endregion

  getHlosTechnologies(){
    QLogger.LogInfo(this.logSrc, "Get HLOS Technologies");
    this.buildSiSpProcess.loadingTechnologies = true;
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getHlosTechnologies();
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get HLOS Technologies - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get HLOS Technologies - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            let technologies  = obj.jira_technology_breakdown as string[];
            this.buildSiSpProcess.technologySelectItems = this.utils.getSelectItemsFromStrings(technologies);
            if(this.buildSiSpProcess.technologySelectItems.length > 0){
              this.buildSiSpProcess.technologySelected = this.buildSiSpProcess.technologySelectItems[0].value;
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get HLOS Technologies - Failed");
          QLogger.LogError(this.logSrc, "Get HLOS Technologies - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.loadingTechnologies = false;
    });
  }

  getHlosTarget(softwareImage: string){    
    QLogger.LogInfo(this.logSrc, "Get HLOS Image " + softwareImage + " Targets");
    this.buildSiSpProcess.loadingTargets = true;
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getHlosTargets(softwareImage);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get HLOS Image " + softwareImage + " Targets - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get HLOS Image " + softwareImage + " Targets - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            let targets  = obj.targets as string[];
            this.buildSiSpProcess.targetSelectItems = this.utils.getSelectItemsFromStrings(targets);
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get HLOS Image " + softwareImage + " Targets - Failed");
          QLogger.LogError(this.logSrc, "Get HLOS Image " + softwareImage + " Targets - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.loadingTargets = false;
    });
  }

  triggerBaitBuild(){
    let serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId;
    let image = this.buildSiSpProcess.image.baseImage;
    let request: TriggerBaitBuildRequest;
    request = {
      softwareImage: this.buildSiSpProcess.image.baseImage,
      gerritIds: [],
      changeRequests: [],
      jira_technology_breakdown: this.buildSiSpProcess.technologySelected,
      targets: this.buildSiSpProcess.targetSelected,
      skip_build: this.buildSiSpProcess.skipBuild,
      user: this.app.sharedData.userInfo.username,
      skipRecompile: this.buildSiSpProcess.skipRecompile,
      autoShip: this.buildSiSpProcess.autoShip,
      autoDiffShip: this.buildSiSpProcess.autoDiffShip,
      diffBaseSoftwareProductBuild: this.buildSiSpProcess.diffBuildSelected,
      distroList: this.buildSiSpProcess.distroSelected.map(d => d.softwareDistro),
    };
    request.gerritIds = this.buildSiSpProcess.gerritSelected.map(g => g.gerritId);
    request.changeRequests = this.buildSiSpProcess.crSelected.map(c => c.changeRequest);
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Trigger Service Task: " + serviceTaskId + ", Image: " + image + " Bait Build: " + JSON.stringify(request) );
    }
    this.buildSiSpProcess.triggerBuildInProgress = true;
    this.buildSiSpProcess.message = "";

    let response : Observable<QPMResponse>;
    response = this.webClient.triggerBaitBuild(request, serviceTaskId.toString());  
    
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Trigger Service Task: " + serviceTaskId + ", Image: " + image + " Bait Build - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Trigger Service Task: " + serviceTaskId + ", Image: " + image + " Bait Build - Success");  

            let buildResponse = obj as TriggerBaitBuildResponse;
            if (buildResponse.success) {
              this.loadServiceTaskBaitBuilds(this.buildSiSpProcess.image, true);
              if(this.buildSiSpProcess.autoShip){
                this.app.showMessageBox("Auto Ship Request","Auto Ship request has been queued successfully","OK");
              }
              else{
                this.app.showMessageBox("Trigger Bait Build","Bait build for " + image + " triggered successfully","OK");  
              }        
              this.resetBuildSiSpProcess();
            }
            else{
              if(this.buildSiSpProcess.autoShip){                
                this.buildSiSpProcess.message = "Auto Ship request is failed. Error: " + buildResponse.message;;
              }
              else{
                this.buildSiSpProcess.message = "Failed to trigger Bait build for "+image + ". Error : " + buildResponse.message;;
              }
              QLogger.LogError(this.logSrc, "Trigger Service Task: " + serviceTaskId + ", Image: " + image + " Bait Build - Failed");
              QLogger.LogError(this.logSrc, "Trigger Service Task: " + serviceTaskId + ", Image: " + image + " Bait Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            }
          }
        }
        else{
          this.buildSiSpProcess.message = "Error: " + data.getError();
          if(this.buildSiSpProcess.autoShip){
            this.buildSiSpProcess.message = "Auto Ship request is failed. Error: " + data.getError();
          }
          else{
            this.buildSiSpProcess.message = "Trigger Bait Build is failed. Error: " + data.getError();
          }
          QLogger.LogError(this.logSrc, "Trigger Service Task: " + serviceTaskId + ", Image: " + image + " Bait Build - Failed");
          QLogger.LogError(this.logSrc, "Trigger Service Task: " + serviceTaskId + ", Image: " + image + " Bait Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.buildSiSpProcess.triggerBuildInProgress = false;
      }
    );
  }

  abortBaitBuild(){
    let serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId;
    let image = this.abortBaitBuildProcess.image.baseImage;
    let buildId = this.abortBaitBuildProcess.baitJob.build_id;
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Abort Service Task: " + serviceTaskId + "Build Image: " + image + ", Bait Job: " + buildId);
    }
    this.abortBaitBuildProcess.baitJob.abortInProgress = true;
    this.abortBaitBuildProcess.message = "";

    let response : Observable<QPMResponse>;
    response = this.webClient.abortBaitBuild(serviceTaskId.toString(), buildId);  
    
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Abort Service Task: " + serviceTaskId + "Build Image: " + image + ", Bait Job: " + buildId + " - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Abort Service Task: " + serviceTaskId + "Build Image: " + image + ", Bait Job: " + buildId + " - Success");  

            let abortResponse = obj as TriggerBaitBuildResponse;
            if (abortResponse.success) {
              this.loadServiceTaskBaitBuilds(this.abortBaitBuildProcess.image, true);
              this.resetAbortBaitBuildProcess();
              this.app.showMessageBox("Abort Bait Job","Bait job " + buildId + " aborted successfully","OK");          
            }
            else{
              this.abortBaitBuildProcess.message = abortResponse.message;
              QLogger.LogError(this.logSrc, "Abort Service Task: " + serviceTaskId + "Build Image: " + image + ", Bait Job: " + buildId + " - Failed");
              QLogger.LogError(this.logSrc, "Abort Service Task: " + serviceTaskId + "Build Image: " + image + ", Bait Job: " + buildId + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            }
          }
        }
        else{
          this.abortBaitBuildProcess.message = "Error: " + data.getError();
          QLogger.LogError(this.logSrc, "Abort Service Task: " + serviceTaskId + "Build Image: " + image + ", Bait Job: " + buildId + " - Failed");
          QLogger.LogError(this.logSrc, "Abort Service Task: " + serviceTaskId + "Build Image: " + image + ", Bait Job: " + buildId + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.abortBaitBuildProcess.baitJob.abortInProgress = false;
      }
    );
  }
  //#endregion
  
  //#region Email

  emailProcess: {
    image: ServiceTaskBuildImage;
    displayForm: boolean;
    selectedCRs: ServiceTaskBuildCR[];
  }

  resetEmailProcess(){
    this.emailProcess = {
      image: undefined,
      displayForm: false,
      selectedCRs: []
    }
  }

  selectEmailCRs(image: ServiceTaskBuildImage){
    this.resetEmailProcess();
    this.emailProcess.image = image;
    this.emailProcess.displayForm = true;
    image.changeRequests.forEach((cr) => {
      if(cr.currentInfo?.status === "Open" || (cr.currentInfo?.status === undefined && cr.detail.status === "Open")){
        this.emailProcess.selectedCRs.push(cr);
      }
    });
  }

  emailImageInfo(image: ServiceTaskBuildImage){
    this.app.resetEmailProcess();
    var newline = "<br/>"
    var serviceTaskUrl = this.app.apiUrl.portalUrl + "/#/main/software/servicetask/build/" + image.servicetaskId;
    var subject = "Service Task #" + image.servicetaskId + " " + image.baseImageBuild;
    let mailTo : Set<String> = new Set();
    this.emailProcess.selectedCRs.forEach((cr) => {
      mailTo.add((cr.currentInfo?.assignee === undefined || cr.currentInfo?.assignee === ''  || cr.currentInfo?.assignee === 'stardustsvc')? cr.detail.assigneeUid : cr.currentInfo?.assignee);
    });
    
    let email: Email = new Email();
    email.fromEmailId = this.app.sharedData.userInfo.username;
    email.toEmailIds = Array.from(mailTo.values());
    email.subject = subject;
    var bodyTop = "Hello";
    var note = "Kindly help Port following CRs into P4 path";
    let QPMST="";
    if(this.stMain.serviceTasksDetails?.master==true){
      QPMST='QPM MasterST';
    }else{
      QPMST='QPM ST';
    }
    let bodyTopTableInfo : StringBuilder = new StringBuilder();
    bodyTopTableInfo.Append("<table border='1'>");
    bodyTopTableInfo.Append("<tr>");
    bodyTopTableInfo.Append("<td><b>Cirrus P4 Path<b></td>");
    bodyTopTableInfo.Append("<td>" + (image.detail.perforceDetails?.path !== undefined ? image.detail.perforceDetails?.path : " - " ) + "</td>");
    bodyTopTableInfo.Append("</tr>");
    bodyTopTableInfo.Append("<tr>");
    bodyTopTableInfo.Append("<td><b>"+QPMST+"<b></td>");
    bodyTopTableInfo.Append("<td>" + serviceTaskUrl + "</td>");
    bodyTopTableInfo.Append("</tr>");
    bodyTopTableInfo.Append("</table>");

    var bodyTopInfo =bodyTopTableInfo.ToString()+ newline +   "Cirrus Notes: " + "https://go.qualcomm.com/cirrusdocs";
    var bodyCenter = this.formatEmailBodyCrs(image);

    if(bodyCenter === null){
      this.app.showMessageBox("Email Failed","No Open/Selected CR(s) found.","OK");
      return;
    }

    
    email.body = bodyTopInfo + newline + newline + bodyCenter + newline + newline;

    this.app.startEmailProcess(bodyTop, email, note);
    //window.location.href = "mailto:" + Array.from(mailTo).join(";") + "?subject="+subject+"&body="+ bodyTop + bodyCenter + newline + bodyBottom;
  }

  formatEmailBodyCrs(image: ServiceTaskBuildImage):String{
    let openCRs: boolean = false;
    let bodyCenter : StringBuilder = new StringBuilder();
    bodyCenter.Append("<table border='1'>");
    bodyCenter.Append("<tr>");
    bodyCenter.Append("<th>CR #</th>");
    bodyCenter.Append("<th>Title</th>");
    bodyCenter.Append("<th>Area</th>");
    bodyCenter.Append("<th>Sub System</th>");
    bodyCenter.Append("<th>Functionality</th>");
    bodyCenter.Append("<th>Status</th>");
    bodyCenter.Append("<th>Assignee</th>");
    bodyCenter.Append("</tr>");
    this.emailProcess.selectedCRs.forEach((cr)=>{
      openCRs = true;
      let crUrl="";
      crUrl="https://orbit/cr/"+cr.changeRequestId; 
      bodyCenter.Append("<tr>");
      bodyCenter.Append('<td rowspan=' + cr.detail.participants.length + '><a  target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;" href="'+crUrl+'">'+cr.changeRequestId+'</a></td>');
      bodyCenter.Append("<td rowspan=" + cr.detail.participants.length + ">" + cr.detail.title + "</td>");
      if(cr.detail.participants.length > 0){
        bodyCenter.Append("<td>" + cr.detail.participants[0].area + "</td>");
        bodyCenter.Append("<td>" + cr.detail.participants[0].subsystem + "</td>");
        bodyCenter.Append("<td>" + cr.detail.participants[0].functionality + "</td>");
      }
      bodyCenter.Append("<td rowspan=" + cr.detail.participants.length + ">" + (cr.currentInfo?.status === undefined? cr.detail.status : cr.currentInfo.status) + "</td>");
      bodyCenter.Append("<td rowspan=" + cr.detail.participants.length + ">" + ((cr.currentInfo?.assignee === undefined || cr.currentInfo?.assignee === '' || cr.currentInfo?.assignee === 'stardustsvc')? cr.detail.assigneeUid : cr.currentInfo?.assignee) + "</td>");
      bodyCenter.Append("</tr>");
      if(cr.detail.participants.length > 1){
        cr.detail.participants.slice(1).forEach((participant)=>{
          bodyCenter.Append("<tr>");
          bodyCenter.Append("<td>" + participant.area + "</td>");
          bodyCenter.Append("<td>" + participant.subsystem + "</td>");
          bodyCenter.Append("<td>" + participant.functionality + "</td>");
          bodyCenter.Append("</tr>");
        });
      }
    });
    bodyCenter.Append("</table>");
    
    if(openCRs){
      return bodyCenter.ToString();
    }
    else{      
      return null;
    }
  }
  //#endregion
    
  //#region AutoCRPropogation
  FileStatus = FileStatus;
  
  autoPropogateProcess:{
    autoPropogateImage:ServiceTaskBuildImage;
    changeRequest:ServiceTaskBuildCR;
    showConfirmDialog:boolean;
    showPropogationStatus:boolean;
    showPropogationCommitStatus: boolean;
    tableData:any[];
    tableCommitData:any[];
    result: PropagationResult[];
    failureReasons:string[];
    isTopSecret: boolean;
    autoPropagationInProgress:boolean;
    autoPropagationCommitInProgress:boolean;
    autoPropagationOutcome: string;
    acknowledge: boolean;
    errorMessageCommit: string;
  }

  onAutoPropogateClicked(cr: any,image: ServiceTaskBuildImage)
  {
    if(this.autoPropogateProcess.autoPropagationInProgress){

      QLogger.LogInfo(this.logSrc,"Auto Propagation Request is in Progress");
      return;
    }
    this.autoPropogateProcess.autoPropogateImage = image;
    this.autoPropogateProcess.changeRequest = cr;
    this.autoPropogateProcess.tableData = [];
    this.autoPropogateProcess.tableCommitData = [];
    this.autoPropogateProcess.showPropogationCommitStatus = false;
    this.autoPropogateProcess.failureReasons =[];
    this.autoPropogateProcess.isTopSecret = false;
    this.autoPropogateProcess.autoPropagationInProgress = false;
    this.autoPropogateProcess.autoPropagationCommitInProgress = false;
    this.autoPropogateProcess.autoPropagationOutcome = "";
    this.autoPropogateProcess.acknowledge = false;
    this.autoPropogateProcess.errorMessageCommit = "";
    let response: Observable<QPMResponse>;
    let req:AutoPropagationRequest;
    req ={
      changeRequest       : this.autoPropogateProcess.changeRequest?.changeRequestId,
      serviceTaskId       : this.stMain.serviceTasksDetails.serviceTaskId,
      targetBranch        : this.autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path,
      targetSoftwareImage : this.autoPropogateProcess.autoPropogateImage.baseImage,
      user                :  this.app.sharedData.userInfo.username
    }
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Auto Propagate Result Request: " + JSON.stringify(req));
    }
    response = this.webClient.getAutoPropagationResult(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Auto Propagate Result Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Auto Propagate Result Success ");
          let propagationResult:AutoPropagationResult;
          propagationResult = JSON.parse(data.getData());
          this.autoPropogateProcess.autoPropagationOutcome = propagationResult.outcome;
          if(this.autoPropogateProcess.autoPropagationOutcome === "CommitSuccess"){
            this.app.showMessageBox("Auto Propagation","Commit already done. Change ID: " + propagationResult.committedCl,"OK");
            this.resetAutoPropogateProcess();
            return;
          }

          this.autoPropogateProcess.tableData.push({label:'Status', value: propagationResult.outcome});
          this.autoPropogateProcess.tableData.push({label:'CR#', value: this.autoPropogateProcess.changeRequest?.changeRequestId});
          if(propagationResult.messages!=null && propagationResult.messages?.length>0){
             if(propagationResult.outcome==='Failure'){
              this.autoPropogateProcess.tableData.push({label:'Failure Reason', value: propagationResult.messages});
             }
          }
          this.autoPropogateProcess.result=[];
          propagationResult.propagation_results?.forEach(result =>{
            if(result.failure_reason===null && result.target_change_details?.change_id!==null){
              this.autoPropogateProcess.tableData.push({label:'Shelved CL', value: result.target_change_details?.change_id});
              this.autoPropogateProcess.tableData.push({label:'Swarm URL', value: result.target_change_details?.review_url, link: result.target_change_details?.review_url});
            }
            if(propagationResult.outcome==='Failure' && result.failure_reason!=null){
                this.autoPropogateProcess.result.push(result);
            }
            if(result.is_top_secret){
              this.autoPropogateProcess.isTopSecret = true;
              this.autoPropogateProcess.errorMessageCommit = "Cannot commit Red-IP code directly.";
            }
          });
          this.autoPropogateProcess.showPropogationStatus = true;
        }
        else if(data.getCode() == 504 && data.getCode() == 500) {
          this.app.showMessageBox("Auto Propagation","Cannot Propagate CR "+this.autoPropogateProcess.changeRequest.changeRequestId +" .Please try again after sometime.","OK");
        } 
        else
          this.autoPropogateProcess.showConfirmDialog = true;
        this.autoPropogateProcess.autoPropagationInProgress = false;
      });
   
  }

  resetAutoPropogateProcess(){
    this.autoPropogateProcess ={
      autoPropogateImage :new ServiceTaskBuildImage(),
      changeRequest:new ServiceTaskBuildCR(),
      showConfirmDialog:false,
      showPropogationStatus:false,
      showPropogationCommitStatus: false,
      result: [],
      failureReasons:[],
      tableData:[],
      tableCommitData: [],
      isTopSecret: false,
      autoPropagationInProgress:false,
      autoPropagationCommitInProgress: false,
      autoPropagationOutcome: "",
      acknowledge: false,
      errorMessageCommit: ""
    }
  }

  autoPropogateCR()
  {
    if(this.autoPropogateProcess.autoPropagationInProgress){
      QLogger.LogInfo(this.logSrc,"Auto Propagation Request is in Progress");
      return;
    }
    
    this.autoPropogateProcess.autoPropagationInProgress = true;
    let response: Observable<QPMResponse>;
    let req:AutoPropagationRequest;
    let addCRCommentResponse : Observable<QPMResponse>;
    req ={
      changeRequest       : this.autoPropogateProcess.changeRequest?.changeRequestId,
      serviceTaskId       : this.stMain.serviceTasksDetails.serviceTaskId,
      targetBranch        : this.autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path,
      targetSoftwareImage : this.autoPropogateProcess.autoPropogateImage.baseImage,
      user                :  this.app.sharedData.userInfo.username
    }
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Auto Propagate Request: " + JSON.stringify(req));
    }
    response = this.webClient.autoPropagateCR(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Auto Propagate Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
            let obj = JSON.parse(data.getData());
            if(obj.request_id===undefined){
              this.app.showMessageBox("Auto Propagation","Cannot Propagate CR "+this.autoPropogateProcess.changeRequest.changeRequestId +" to branch "+this.autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path,"OK");
            }
            else{ 
                 //Adding Comment for Auto Propagation and updating UI
                let request: CrGerritComment = new CrGerritComment();
                let cRIdString = ( this.autoPropogateProcess.changeRequest?.changeRequestId as Number).toString();
                request.crId = parseInt(cRIdString);
                request.commentDesc = "Auto Propagation Rquested";
                request.softwareImage = this.autoPropogateProcess.autoPropogateImage.baseImage;
                addCRCommentResponse = this.webClient.addServiceTaskCRGerritComment( this.stMain.serviceTasksDetails.serviceTaskId.toString(), 
                                                                             request );

                addCRCommentResponse.subscribe((data:QPMResponse) => {
                  if(this.app.sharedData.appInfo.logResponse){
                      QLogger.LogInfo(this.logSrc, "Add Auto Propagate CR Comment " + cRIdString + " Response - Response : " +JSON.stringify(data));
                  }   
                  if(data.isSuccess()){
                    QLogger.LogInfo(this.logSrc, "Add Auto Propagate CR Comment " + cRIdString + " Response - Success"); 
                  }
                  this.getCRComments(this.autoPropogateProcess.autoPropogateImage.baseImage,this.autoPropogateProcess.changeRequest);
              });
              this.app.showMessageBox("Auto Propagation","Propagation Request has been placed for  "+this.autoPropogateProcess.changeRequest.changeRequestId +" to branch "+this.autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path,"OK");
            }
        }
        else
          this.app.showMessageBox("Auto Propagation","Cannot Propagate CR "+this.autoPropogateProcess.changeRequest.changeRequestId +" to branch "+this.autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path,"OK");
        this.autoPropogateProcess.autoPropagationInProgress = false;
        this.autoPropogateProcess.showConfirmDialog = false;
      });
  }
  autoPropogateCRCommit()
  {
    if(this.autoPropogateProcess.autoPropagationInProgress){
      QLogger.LogInfo(this.logSrc,"Auto Propagation Request is in Progress");
      return;
    }
    this.autoPropogateProcess.showPropogationCommitStatus = false;
    this.autoPropogateProcess.autoPropagationCommitInProgress = true;
    this.autoPropogateProcess.tableCommitData = [];
    let response: Observable<QPMResponse>;
    let req:AutoPropagationRequest;
    let addCRCommentResponse : Observable<QPMResponse>;
    req ={
      changeRequest       : this.autoPropogateProcess.changeRequest?.changeRequestId,
      serviceTaskId       : this.stMain.serviceTasksDetails.serviceTaskId,
      targetBranch        : this.autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path,
      targetSoftwareImage : this.autoPropogateProcess.autoPropogateImage.baseImage,
      user                :  this.app.sharedData.userInfo.username
    }
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Auto Propagate Request: " + JSON.stringify(req));
    }
    response = this.webClient.autoPropagateCRCommit(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Auto Propagate Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
            let propagationCommitResult: AutoPropagationCommitResult;
            propagationCommitResult = JSON.parse(data.getData()) as AutoPropagationCommitResult;
            if(propagationCommitResult.outcome === "Success"){ 
                 //Adding Comment for Auto Propagation and updating UI
                let request: CrGerritComment = new CrGerritComment();
                let cRIdString = ( this.autoPropogateProcess.changeRequest?.changeRequestId as Number).toString();
                request.crId = parseInt(cRIdString);
                request.commentDesc = "Auto Propagation Commited";
                addCRCommentResponse = this.webClient.addServiceTaskCRGerritComment( this.stMain.serviceTasksDetails.serviceTaskId.toString(), 
                                                                             request );

                addCRCommentResponse.subscribe((data:QPMResponse) => {
                  if(this.app.sharedData.appInfo.logResponse){
                      QLogger.LogInfo(this.logSrc, "Add  Auto Propagate CR Comment " + cRIdString + " Response - Response : " +JSON.stringify(data));
                  }   
                  if(data.isSuccess()){
                    QLogger.LogInfo(this.logSrc, "Add Auto Propagate CR Comment " + cRIdString + " Response - Success"); 
                  }
                  this.getCRComments(this.autoPropogateProcess.autoPropogateImage.baseImage,this.autoPropogateProcess.changeRequest);
              });
              this.app.showMessageBox("Auto Propagation","Propagation Request has been placed for  "+this.autoPropogateProcess.changeRequest.changeRequestId +" to branch "+this.autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path,"OK");
            }
            this.autoPropogateProcess.tableCommitData = [];
            this.autoPropogateProcess.tableCommitData.push({label:'Status', value: propagationCommitResult.outcome});
            this.autoPropogateProcess.tableCommitData.push({label:'CL#', value: propagationCommitResult.result.submitted_change_id});
            this.autoPropogateProcess.tableCommitData.push({label:'Requester', value: propagationCommitResult.result.requested_by});
            this.autoPropogateProcess.tableCommitData.push({label:'Submitted On', value: propagationCommitResult.result.submitted_on});
            if(propagationCommitResult.messages?.length > 0){
              this.autoPropogateProcess.tableCommitData.push({label:'Error/Message', value: propagationCommitResult.result.submitted_on});
              propagationCommitResult.messages.forEach((message)=>{
                this.autoPropogateProcess.tableCommitData.push({label:'', value: message});
              });
            }
            this.autoPropogateProcess.showPropogationCommitStatus = true;
            this.autoPropogateProcess.showPropogationStatus = false;
        }
        else{
          this.autoPropogateProcess.errorMessageCommit = "Error occurred during CR Propagation Commit. " + data.getError();
        }
        this.autoPropogateProcess.autoPropagationCommitInProgress = false;
      });
  }
  //#endregion

  //#region LocalFileDelete
  localFileDeleteProcess:{
    commitMessage:string;
    changeList:string;
    multipleFileMatches:string[];
    files:string[];
    jobs:string[];
    errorMessage: string;
    successMessage:string;
    deleteInProgress: boolean;
    showLocalFileDeleteSuccessDialog:boolean;
    localFilesDeleteResult:LocalFilesDeleteResponse;

  }
  resetLocalFileDeleteProcess(){  
    this.localFileDeleteProcess = {
      commitMessage:'',
      changeList:'',
      multipleFileMatches:[],
      files: [],
      jobs:[],
      errorMessage: '',
      successMessage:'',
      deleteInProgress:false,
      showLocalFileDeleteSuccessDialog:false,
      localFilesDeleteResult:new LocalFilesDeleteResponse()
    }
  }

  localFilesDelete(){
      this.localFileDeleteProcess.deleteInProgress=true;
      let req:LocalFilesDeleteRequest;
      let response: Observable<QPMResponse>;
      let absoluteFilePaths:string[]=[];
      this.localFileDeleteProcess.files.forEach(fPath=>{
        fPath=this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path+'/'+fPath;
        absoluteFilePaths.push(fPath);
      })
      req ={
        description : this.localFileDeleteProcess.commitMessage,
        files       : absoluteFilePaths,
        jobs        : []//Backed will set job array
      }
      if (this.app.sharedData.appInfo.logRequest) {
        QLogger.LogInfo(this.logSrc, "Local Files Remove Request: " + JSON.stringify(req));
      }
      response = this.webClient.localFilesDelete(this.localFileUploadProcess.localUploadImage.cirrusId,req);
      response.subscribe(
        (data: QPMResponse) => {
          if (this.app.sharedData.appInfo.logResponse) {
            QLogger.LogInfo(this.logSrc, "Local Files Remove  Response: " + JSON.stringify(data));
          }
          if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc,"Local Files Remove - Success");
            let obj: any = JSON.parse(data.getData());
            QLogger.LogInfo(this.logSrc,"Local Files Remove Response :: " + JSON.stringify(obj));
            //success
            this.localFileUploadProcess.showLocalFileUploadDialog = false;
            this.localFileDeleteProcess.deleteInProgress=false;
            this.localFileDeleteProcess.showLocalFileDeleteSuccessDialog=true;
            let result = new LocalFilesDeleteResponse();
            result.changelist=null;
            result.files=[];
            this.localFileDeleteProcess.localFilesDeleteResult=obj as LocalFilesDeleteResponse;
        
          }
          else{
            QLogger.LogInfo(this.logSrc,"Local Files Remove  - Failed");
            this.localFileDeleteProcess.deleteInProgress=false;
            this.localFileDeleteProcess.errorMessage="Error : " + data.getError();
          }
        });
  }
  //#region LocalFileUpload
  localFileUploadProcess:{
    uploadSystem: UploadSystemType;
    showLocalFileUploadDialog:boolean;
    showLocalFileSummaryDialog: boolean;
    showLocalFileFinalDialog: boolean;
    tabIndex: number;
    commitMessage:string;
    prevCommitMessage:string;
    files: any[];
    filesInfo: FileInfo[];
	  sessionId: number;
    crNumber:string;
    cr: ServiceTaskBuildCR;
    errorMessage: string;
    localUploadImage:ServiceTaskBuildImage;
    isCRPresent:boolean;
    localFileName:string;
    pathValidationInProgress: number;
    pathInvalid: boolean;
    uploadInProgress: boolean;
    uploadFailed: boolean;
    fileValue: any;
    filesProcessed: number;
    filesUploaded: LocalFileUploadResponse;
    totalFileSizeInByte: number;
    totalFileSizeInMB: number;
    maxFileSizeInByte: number;
    maxFileSizeInMB: number;
    maxNoOfFiles: number;
    showLocalFileFinalDialogScuccess:boolean;
    subscription: Observable<boolean>;
    LocalFilePathMatches?:string[];
  }
  
  localFileUploadProgress:{
    displayProgress: boolean,
    loadingSessions: boolean,
    files: LocalFileUploadSessionStatus[],
    errorMessage: string
  }
  //Bait-LocalFilePath Suggestion :For Existing File
  serachLocalFilePathsForBaitUpload(event,index?:number){
    if(this.localFileUploadProcess.filesInfo[index].LocalRelativeFilePathMatches==undefined && this.localFileUploadProcess.filesInfo[index].loadingRelativeFilePaths===false){
      this.searchLocalFilePathsForBait(event,index);
    }
  }

  searchLocalFilePathsForBait(fileNameQuery:string,fileIndex?:number){
    let req:FilePathSearchRequestForBait;
     let response: Observable<QPMResponse>;
     this.localFileUploadProcess.filesInfo[fileIndex].loadingRelativeFilePaths=true;
   
     req ={
       image             : this.localFileUploadProcess.localUploadImage.baseImage,
       serviceTaskId     : this.localFileUploadProcess.localUploadImage.servicetaskId,
       smPath            : fileNameQuery
     }
     if (this.app.sharedData.appInfo.logRequest) {
       QLogger.LogInfo(this.logSrc, "Local File Path Search Request: " + JSON.stringify(req));
     }
     response = this.webClient.localFilePathSearchForBait(req);
     response.subscribe(
       (data: QPMResponse) => {
         if (this.app.sharedData.appInfo.logResponse) {
           QLogger.LogInfo(this.logSrc, "Local File Path For Bait Search Response: " + JSON.stringify(data));
         }
         if (data.isSuccess()) {
           QLogger.LogInfo(this.logSrc,"Local File Path For Bait Search - Success");
           let obj: any = JSON.parse(data.getData());
           QLogger.LogInfo(this.logSrc,"Local File Path For Bait Search obj Received :: " + JSON.stringify(obj));
            this.localFileUploadProcess.filesInfo[fileIndex].LocalRelativeFilePathMatches=[];
            let fileMatches=obj.paths as string[]; 
            this.localFileUploadProcess.filesInfo[fileIndex].LocalRelativeFilePathMatches=this.utils.getSelectItemsFromStrings(fileMatches);
         }
         else{
           QLogger.LogInfo(this.logSrc,"Local File Path Search - Failed");
           this.localFileUploadProcess.LocalFilePathMatches = ["Error : " + data.getError()];
         }
         this.localFileUploadProcess.filesInfo[fileIndex].loadingRelativeFilePaths=false;
       });

  }
  //Cirrus-Workspace_Root Suggestion :For New File
  resetCirrusRelativePathSection(index:number){
    this.localFileUploadProcess.filesInfo[index].workspace_rootPathMatches=undefined ;
    this.localFileUploadProcess.filesInfo[index].loadingWorkspaceRoot=false;
    this.localFileUploadProcess.filesInfo[index].LocalRelativeFilePathMatches=undefined ;
    this.localFileUploadProcess.filesInfo[index].loadingRelativeFilePaths=false;
    this.localFileUploadProcess.filesInfo[index].extraInputFilePath="";
    this.localFileUploadProcess.filesInfo[index].filePath="";
  }
  searchWorkspaceRootForCirrus(event,index?:number){

    if(this.localFileUploadProcess.filesInfo[index].workspace_rootPathMatches==undefined && this.localFileUploadProcess.filesInfo[index].loadingWorkspaceRoot===false){
      this.getWorkspaceRootForCirrus(event,index);
    } 
  }
  getWorkspaceRootForCirrus(event,fileIndex?:number){
    let response: Observable<QPMResponse>;
    this.localFileUploadProcess.filesInfo[fileIndex].loadingWorkspaceRoot=true;
    let softwareImageBuild=this.localFileUploadProcess.localUploadImage.baseImageBuild;

    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Cirrus Local File - Workspace_Root Search Request for softwareImageBuild : " + softwareImageBuild);
    }
    response = this.webClient.getWorkspaceRootForCirrus(softwareImageBuild);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Cirrus Local File - Workspace_Root Search Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Cirrus Local File - Workspace_Root  Search - Success");
          let obj: any = JSON.parse(data.getData());
          QLogger.LogInfo(this.logSrc,"Cirrus Local File - Workspace_Root  Search obj Received :: " + JSON.stringify(obj));
           this.localFileUploadProcess.filesInfo[fileIndex].workspace_rootPathMatches=[];
           let components=obj.components as CirrusWorkspaceRootResponse[]; 
           let workspace_roots=components?.map(com=>com.workspace_root) as string[];
           this.localFileUploadProcess.filesInfo[fileIndex].workspace_rootPathMatches=this.utils.getSelectItemsFromStrings(workspace_roots);
        }
        else{
          QLogger.LogInfo(this.logSrc,"Cirrus Local File - Workspace_Root  Search - Failed");
          this.localFileUploadProcess.filesInfo[fileIndex].workspace_rootPathMatches=[];
        }
        this.localFileUploadProcess.filesInfo[fileIndex].loadingWorkspaceRoot=false;
      });
  }


  //Cirrus-LocalFilePath Suggestion :For Existing File
  serachLocalFilePathsForCirrusUpload(event,isUploadMode:boolean,index?:number){

    if(this.localFileUploadProcess.filesInfo[index].LocalRelativeFilePathMatches==undefined && this.localFileUploadProcess.filesInfo[index].loadingRelativeFilePaths===false){
      this.searchLocalFilePathsForCirrus(event,isUploadMode,index);
    } 
  }
  serachLocalFilePathsForCirrusDelete(event,isUploadMode:boolean){
    this.searchLocalFilePathsForCirrus(event,isUploadMode);
  }

  searchLocalFilePathsForCirrus(event,isUploadMode:boolean,fileIndex?:number){
     let req:FilePathSearchRequest;
     let response: Observable<QPMResponse>;
     let fileNameQuery='';
     if(isUploadMode){//For Cirrus Local Upload
      fileNameQuery=event;
      this.localFileUploadProcess.filesInfo[fileIndex].loadingRelativeFilePaths=true;
     }else{//For Cirrus Local Delete
      fileNameQuery=event.query;
     }

    if(this.app.sharedData.devFeature.componentBranchDevFR===false){//For componentBranchDevFR is false
      req ={
        branch             : this.localFileUploadProcess.localUploadImage.detail.perforceDetails?.path,
        cirrusTaskId       : this.localFileUploadProcess.localUploadImage.cirrusId,
        filename           : fileNameQuery
      }
      if (this.app.sharedData.appInfo.logRequest) {
        QLogger.LogInfo(this.logSrc, "Local File Path Search Request: " + JSON.stringify(req));
      }
      response = this.webClient.localFilePathSearch(req);
    }else{//For componentBranchDevFR is true
      if (this.app.sharedData.appInfo.logRequest) {
        QLogger.LogInfo(this.logSrc, "Local File Path Search Request for cirrusTaskId : " + this.localFileUploadProcess.localUploadImage.cirrusId + "filename : "+fileNameQuery);
      }
      response = this.webClient.localFilePathSearchV2(this.localFileUploadProcess.localUploadImage.cirrusId,fileNameQuery);
    }

     response.subscribe(
       (data: QPMResponse) => {
         if (this.app.sharedData.appInfo.logResponse) {
           QLogger.LogInfo(this.logSrc, "Local File Path Search Response: " + JSON.stringify(data));
         }
         if (data.isSuccess()) {
           QLogger.LogInfo(this.logSrc,"Local File Path Search - Success");
           let obj: any = JSON.parse(data.getData());
           QLogger.LogInfo(this.logSrc,"Local File Path Search obj Received :: " + JSON.stringify(obj));
           if(isUploadMode){//For Local Upload
            this.localFileUploadProcess.LocalFilePathMatches=[];
            this.localFileUploadProcess.filesInfo[fileIndex].LocalRelativeFilePathMatches=[];
            let fileMatches=obj.matches as string[]; 
            this.localFileUploadProcess.filesInfo[fileIndex].LocalRelativeFilePathMatches=this.utils.getSelectItemsFromStrings(fileMatches);
           }else{//For Local Delete
            this.localFileDeleteProcess.multipleFileMatches=obj.matches as string[];
           }
         }
         else{
           QLogger.LogInfo(this.logSrc,"Local File Path Search - Failed");
           this.localFileUploadProcess.LocalFilePathMatches=[];
           this.localFileUploadProcess.LocalFilePathMatches = ["Error : " + data.getError()];
         }
         this.localFileUploadProcess.filesInfo[fileIndex].loadingRelativeFilePaths=false;
       });
   }

  filesDropped(event): void {
    let files: FileList = event.target.files;
    this.saveFiles(files);
    event.target.value = "";

  }
  @HostListener("dragover", ["$event"]) onDragOver(event: any) {
    event.preventDefault();
    
  }
  @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
    event.preventDefault();
  }
  @HostListener("drop", ["$event"]) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    if (event.dataTransfer.files) {
      let files: FileList = event.dataTransfer.files;
      this.saveFiles(files);
    }
  }

  saveFiles(files: FileList) {
    this.localFileUploadProcess.localFileName="";
    let filesToAddSizeinByte = 0;
    this.localFileUploadProcess.totalFileSizeInByte = 0;
    this.localFileUploadProcess.totalFileSizeInMB = 0;
    Array.from(files).forEach(file=>filesToAddSizeinByte = filesToAddSizeinByte + file.size);
    Array.from(this.localFileUploadProcess.files).forEach(file=>this.localFileUploadProcess.totalFileSizeInByte = this.localFileUploadProcess.totalFileSizeInByte + file.size);
    this.localFileUploadProcess.totalFileSizeInMB=(this.localFileUploadProcess.totalFileSizeInByte/1000000);
    if((filesToAddSizeinByte + this.localFileUploadProcess.totalFileSizeInByte) > this.localFileUploadProcess.maxFileSizeInByte){
      this.localFileUploadProcess.totalFileSizeInMB = Number.parseFloat(this.localFileUploadProcess.totalFileSizeInMB.toFixed(2));
      this.localFileUploadProcess.errorMessage = "Total file size exceeded "+ this.localFileUploadProcess.maxFileSizeInMB +"MB";
    }
    else if(this.localFileUploadProcess.files.length + files.length > this.localFileUploadProcess.maxNoOfFiles){
      this.localFileUploadProcess.errorMessage = "Please input maximum of " + this.localFileUploadProcess.maxNoOfFiles + " file(s) to continue";
      return;
    }
    this.localFileUploadProcess.errorMessage = "";
    this.localFileUploadProcess.files.push(...Array.from(files));
    
    this.localFileUploadProcess.totalFileSizeInByte = this.localFileUploadProcess.totalFileSizeInByte + filesToAddSizeinByte;
    this.localFileUploadProcess.totalFileSizeInMB=(this.localFileUploadProcess.totalFileSizeInByte/1000000);
    for(let i=0; i < files.length; i++){
      this.localFileUploadProcess.filesInfo.push({
        filename: files[i].name,
        filesize: files[i].size,
        loadingRelativeFilePaths:false,
        LocalRelativeFilePathMatches:undefined,
        filePath: "",
        workspaceRootPath:"",
        extraInputFilePath:"",
        loadingWorkspaceRoot:false,
        workspace_rootPathMatches:undefined,
        fullFilePath: "",
        s3Key: "",
        newFile: false,
        status: FileStatus.Init,
        statusString: "",
        validationInProgress: false,
        uploadInProgress: false,
        uploadProgress: 0
      });
    }
    this.onLocalFileUploadInputValidation();
  }
  onRemoveLocalFile(index: number){
    this.localFileUploadProcess.files.splice(index, 1);
    this.localFileUploadProcess.filesInfo.splice(index, 1);
    this.localFileUploadProcess.totalFileSizeInByte = 0;
    Array.from(this.localFileUploadProcess.files).forEach(file=>this.localFileUploadProcess.totalFileSizeInByte = this.localFileUploadProcess.totalFileSizeInByte + file.size);
    this.localFileUploadProcess.totalFileSizeInMB = (this.localFileUploadProcess.totalFileSizeInByte/1000000);
    this.localFileUploadProcess.totalFileSizeInMB = Number.parseFloat(this.localFileUploadProcess.totalFileSizeInMB.toFixed(2));
    this.onLocalFileUploadInputValidation();
  }
  onLocalFileUploadInputValidation(){ 
    this.localFileUploadProcess.errorMessage="";   
    if(this.localFileUploadProcess.totalFileSizeInByte > this.localFileUploadProcess.maxFileSizeInByte){ 
      //this.localFileUploadProcess.errorMessage = "Total file size exceeded "+ this.localFileUploadProcess.maxFileSizeInMB +"MB";
      //return
    };
    this.localFileUploadProcess.errorMessage = "";
    this.localFileUploadProcess.pathInvalid = false;
    if(this.localFileUploadProcess.files.length === 0){
      this.localFileUploadProcess.errorMessage = "Please select file to upload";
      if(this.localFileUploadProcess.errorMessage !== "") return;
    }
    else if(this.localFileUploadProcess.files.length > 0){
      this.localFileUploadProcess.filesInfo.every((f)=>{
        //workspaceRootPath validation for cirrus : New File (disabled for now :componentBranchDevFR flag is false)
        if(this.app.sharedData.devFeature.componentBranchDevFR===true && f.newFile===true && this.localFileUploadProcess.localUploadImage.buildSystem !== 'BAIT' && (f.workspaceRootPath==='' || f.workspaceRootPath===undefined)){
          this.localFileUploadProcess.errorMessage = "Please select root workspace";
          this.localFileUploadProcess.pathInvalid = true;
          return false;
        }
        else if(f.filePath === ''){
          this.localFileUploadProcess.errorMessage = "File path cannot be empty";
          this.localFileUploadProcess.pathInvalid = true;
          return false;
        }
        else{
          return true;
        }
      });
      if(this.localFileUploadProcess.errorMessage !== "") return;
      this.localFileUploadProcess.filesInfo.forEach((f)=>{
        if(!f.filePath.endsWith(f.filename)){
          f.status = FileStatus.FilenameInvalid;
          f.statusString = FileStatus.FilenameInvalid.toString();
          this.localFileUploadProcess.errorMessage = "File path contains wrong filename";
          this.localFileUploadProcess.pathInvalid = true;
        }
      });
      if(this.localFileUploadProcess.errorMessage !== "") return;
      //Validation: Each filePath should be distinct 
      let map = new Map<string,number>();
      this.localFileUploadProcess.filesInfo.forEach((f)=>{
        if(map.has(f.filePath)){
          map.set(f.filePath,map.get(f.filePath)+1)
        }else{
          map.set(f.filePath,1);
        }
        });
        this.localFileUploadProcess.filesInfo.forEach((f)=>{
          if(map.get(f.filePath)>=2){
            f.status = FileStatus.DuplicateFilePath;
          f.statusString = FileStatus.DuplicateFilePath.toString();
          this.localFileUploadProcess.errorMessage = "Duplicate file paths are not allowed";
          this.localFileUploadProcess.pathInvalid = true;
          }else if(map.get(f.filePath)==1){
            f.status = FileStatus.Init;
          }
        })

        if(this.localFileUploadProcess.errorMessage !== "") return;
    }
    if((this.app.sharedData.service.common.master && this.localFileUploadProcess.crNumber=='')||(this.localFileUploadProcess.crNumber!='' && !(/^\d+$/.test(this.localFileUploadProcess.crNumber)))){
      this.localFileUploadProcess.errorMessage = "Please enter valid CR Number";
    }
    else if(this.localFileUploadProcess.commitMessage==""|| this.localFileUploadProcess.commitMessage==this.localFileUploadProcess.prevCommitMessage){
      this.localFileUploadProcess.errorMessage = "Commit Message cannot be empty";
    }
  }
  onLocalFileUploadClicked(image: ServiceTaskBuildImage,cr: ServiceTaskBuildCR, uploadSystem: UploadSystemType){
    this.resetLocalFileUploadProcess();
    this.resetLocalFileUploadProgress();
    this.resetLocalFileDeleteProcess();
    this.localFileUploadProcess.uploadSystem = uploadSystem;
    this.localFileUploadProcess.localUploadImage=image;
    this.localFileUploadProcess.isCRPresent = false;
    this.localFileUploadProcess.crNumber = cr==undefined?"":cr?.changeRequestId.toString();
    if(cr!==undefined)  {
      this.localFileUploadProcess.cr = cr;
      this.localFileUploadProcess.isCRPresent = true;
    }
    switch(uploadSystem){
      case UploadSystemType.CIRRUS:{
                this.localFileUploadProcess.maxFileSizeInByte = 1000000000
                this.localFileUploadProcess.maxFileSizeInMB = 1000;
                this.localFileUploadProcess.maxNoOfFiles = 5;
            }
            break;

      case UploadSystemType.BAIT:{        
                this.localFileUploadProcess.maxFileSizeInByte = 100000000
                this.localFileUploadProcess.maxFileSizeInMB = 100;
                this.localFileUploadProcess.maxNoOfFiles = 1;
            }
            break;
    }
    this.localFileUploadProcess.showLocalFileUploadDialog=true;
  }
  onLocalFileUploadNextClicked(){ 
    // var currentfilesData = this.localFileUploadProcess.filesInfo;
    this.localFileUploadProcess.filesInfo.forEach(fileUploaded => {
      var currentDate = new Date();
      let formattedDate = formatDate(currentDate, 'MMM d, y, h:mm:ss a', 'en-US');
      fileUploaded.requested_date = formattedDate;
      fileUploaded.requester = this.app.sharedData.userInfo.username;
      fileUploaded.completed_date = "";
    });
    this.localFileUploadProcess.errorMessage = '';
    this.localFileUploadProcess.pathInvalid = false;
    this.localFileUploadProcess.pathValidationInProgress = 0;
    this.onLocalFileUploadInputValidation();
    if(this.localFileUploadProcess.errorMessage !== '') return;
    let filesValid = true;
    
    switch(this.localFileUploadProcess.uploadSystem){
      case UploadSystemType.CIRRUS:{                
              this.localFileUploadProcess.filesInfo.forEach((f)=>{
                if(f.status !== FileStatus.PathNew && f.status !== FileStatus.PathValid){
                  f.filePath = f.filePath.replace(/\\/g, "/");
                  f.status = FileStatus.PathValid;
                  f.fullFilePath = this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path+(f.filePath[0]=="/"?"":"/")+ f.filePath;
                  //Removed FilePathValidationCheck
                  //this.localFileUpload(f);
                }
              });
            }
            break;

      case UploadSystemType.BAIT:{                   
              this.localFileUploadProcess.filesInfo.forEach((f)=>{
                if(f.status !== FileStatus.PathNew && f.status !== FileStatus.PathValid){
                  f.status = FileStatus.PathValid;
                  f.fullFilePath = f.filePath;
                }
              });
            }
            break;
    }
    if(filesValid){      
      this.localFileUploadProcess.showLocalFileUploadDialog = false;
      this.localFileUploadProcess.showLocalFileSummaryDialog = true;
    }
  }
  onLocalFileUploadBackClicked(){    
    this.localFileUploadProcess.errorMessage = '';
    this.localFileUploadProcess.showLocalFileSummaryDialog = false;
    this.localFileUploadProcess.showLocalFileUploadDialog = true;
  }

  resetLocalFileUploadProcess(){    
    this.localFileUploadProcess ={
      uploadSystem: undefined,
      showLocalFileUploadDialog:false,
      showLocalFileSummaryDialog: false,
      showLocalFileFinalDialog: false,
      tabIndex: 0,
      commitMessage:"",
      prevCommitMessage:"",
      files: [],
      crNumber:'',
      cr: undefined,
      filesInfo: [],
      sessionId: 0,
      errorMessage:'',
      localUploadImage :new ServiceTaskBuildImage(),
      isCRPresent:false,
      localFileName:'',
      pathValidationInProgress: 0,
      pathInvalid: false,
      uploadInProgress: false,
      uploadFailed: false,
      fileValue: {},
      filesProcessed: 0,
      filesUploaded: undefined,
      totalFileSizeInByte: 0,
      totalFileSizeInMB: 0,
      maxFileSizeInByte: this.app.sharedData.appInfo.isElectronMode? 14000000: 7000000,
      maxFileSizeInMB: this.app.sharedData.appInfo.isElectronMode? 14: 7,
      maxNoOfFiles: 0,
      showLocalFileFinalDialogScuccess:false,
      subscription: undefined
    }
  }

  resetLocalFileUploadProgress(){  
    this.localFileUploadProgress = {
      displayProgress : false,
      loadingSessions: false,
      files: [],
      errorMessage: ""
    }
  }

  localFileUpload(file: FileInfo) { 
    this.localFileUploadProcess.pathValidationInProgress++;
    file.status = FileStatus.PathValidation;
    file.statusString = FileStatus.PathValidation.toString();
    file.validationInProgress = true;
    QLogger.LogInfo(this.logSrc,"Uploading Local File to branch "+this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path);
    file.fullFilePath = this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path+(file.filePath[0]=="/"?"":"/")+ file.filePath;
    let req :FilePathValidationRequest;
    let response: Observable<QPMResponse>;
    req ={
          cirrusTaskId: this.localFileUploadProcess.localUploadImage.cirrusId,
          depot_file: file.fullFilePath,
          enumerate_directories:true
    }
    if (this.app.sharedData.appInfo.logResponse) {
      QLogger.LogInfo(this.logSrc, "File Path Validation Request: " + JSON.stringify(req));
    }
    response = this.webClient.validateFilePath(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "File Path Validation Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"File Path is valid proceeding with Upload");
          file.status = FileStatus.PathValid;
          file.statusString = FileStatus.PathValid.toString();
        }
        else if(file.newFile){
          file.status = FileStatus.PathNew;
          file.statusString = FileStatus.PathNew.toString();
        }
        else{
          file.status = FileStatus.PathInvalid;
          file.statusString = FileStatus.PathInvalid.toString();
          this.localFileUploadProcess.pathInvalid = true;
          this.localFileUploadProcess.errorMessage = "Filename path is not valid. Please check input and try again";
        }
        file.validationInProgress = false;
        this.localFileUploadProcess.pathValidationInProgress--;
        if(this.localFileUploadProcess.pathValidationInProgress === 0
            && !this.localFileUploadProcess.pathInvalid){
              this.localFileUploadProcess.showLocalFileUploadDialog = false;
              this.localFileUploadProcess.showLocalFileSummaryDialog = true;
        }
      }); 
  }



  submitFileInitiate(){
    switch(this.localFileUploadProcess.uploadSystem){
      case UploadSystemType.CIRRUS:{                
              this.submitFileInitiateCirrus();
            }
            break;
      case UploadSystemType.BAIT:{            
              this.submitFileInitiateBait();
            }
            break;
    }     
    
  }  

  submitFileComplete(){        
    switch(this.localFileUploadProcess.uploadSystem){
      case UploadSystemType.CIRRUS:{                
              this.submitFileCompleteCirrus();
            }
            break;

      case UploadSystemType.BAIT:{
              //Enable once backend ready                   
              this.submitFileCompleteBait();
            }
            break;
    }
  }
  
  submitFileInitiateCirrus(){
    QLogger.LogInfo(this.logSrc,"Uploading Local File Initiate to branch "+this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path)
    let req : LocalFileUploadInitiateRequest;
    let response: Observable<QPMResponse>;
    this.localFileUploadProcess.uploadInProgress = true;
    req ={
          cirrusTaskId: this.localFileUploadProcess.localUploadImage.cirrusId,
          filePaths: this.localFileUploadProcess.filesInfo.map(x => x.fullFilePath),
          p4Path: this.localFileUploadProcess.localUploadImage.detail.perforceDetails.path,
          serviceTaskId: this.stMain.serviceTasksDetails.serviceTaskId
    }
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Uploading Local File Initiate - Request: " + JSON.stringify(req));
    }
    response = this.webClient.localFileUploadInitiate(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Uploading Local File Initiate - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Uploading Local File Initiate - Success");
          let obj: any = JSON.parse(data.getData());
          QLogger.LogInfo(this.logSrc,"submitFileInitiateCirrus() obj Received :: " + JSON.stringify(obj));
          this.localFileUploadProcess.sessionId = obj.sessionId as number;
          this.localFileUploadProcess.filesInfo.forEach(f=>{
            f.s3Key = obj.fullKeys[f.fullFilePath];
          });
          //this.s3FileUploadContinue();
          this.largeFileUpload = new LargeFileUpload(this.app, this.webClient);
          let config: LargeFileUploadConfig = {
            skipStart: true,
            uploadType: FileUploadType.Base64 //For bait, change this to Base64Decode once backend is ready and make corresponding API integrations on UI
          };
          this.largeFileUpload.setFileInfo(this.localFileUploadProcess.files, this.localFileUploadProcess.filesInfo, config);
          this.localFileUploadProcess.subscription = this.largeFileUpload.initiateUploadLargeFile();
          this.localFileUploadProcess.subscription.subscribe((uploadResp)=>{
            if(uploadResp === true){
              this.submitFileCompleteCirrus();
            }
          })
          //this.uploadLargeFile();
        }
        else{
          this.localFileUploadProcess.errorMessage = data.getErrorDetail();
          QLogger.LogInfo(this.logSrc,"Uploading Local File Initiate - Error: " + data.getErrorDetail());
        }
    });
  }
  
  submitFileCompleteCirrus(){
    QLogger.LogInfo(this.logSrc,"Uploading Local File Complete to branch "+this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path)
    let req : LocalFileUploadCompleteRequest;
    let response: Observable<QPMResponse>;    
    let jobs:string[]=[];
    let description:string;
    let operatingSystem: string;
    operatingSystem = this.utils.getOSStringFromUserAgent();
    description = this.localFileUploadProcess.commitMessage;
    if(this.localFileUploadProcess.crNumber!='')
    {
      jobs.push("ChangeRequest" + this.localFileUploadProcess.crNumber);
      description = "CR "+this.localFileUploadProcess.crNumber + "\n" +this.localFileUploadProcess.commitMessage+"\n"+"Fixed CR:"+this.localFileUploadProcess.crNumber;
    }
    else{
      jobs.push("CirrusNoCR");
    }
    req ={
      sessionId: this.localFileUploadProcess.sessionId,
      description: description,
      jobs: jobs,
      operatingSystem: operatingSystem
    }
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Uploading Local File Complete - Request: " + JSON.stringify(req));
    }
    response = this.webClient.localFileUploadComplete(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Uploading Local File Complete - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Uploading Local File Complete - Success");
          let msg: string[] = [];
          msg.push("The file upload request placed successfully.");
          msg.push("Note: This request may take several minutes to update the history table. Please check history table later.");
          msg.push("   ");
          msg.push(...this.localFileUploadProcess.filesInfo.map(x => x.fullFilePath));
          this.app.showMessageBoxMultiLine("Local Upload Summary", msg, "Ok");
          this.localFileUploadProcess.uploadInProgress = false;
          this.resetLocalFileUploadProcess();
        }
        else{
          this.localFileUploadProcess.errorMessage = data.getErrorDetail();
          QLogger.LogInfo(this.logSrc,"Uploading Local File Complete - Error: " + data.getErrorDetail());
        }
    });
  }
  
  submitFileInitiateBait(){
    let fileUploadInitiateRequest:BaitFileUploadInitRequest;
    let response: Observable<QPMResponse>;
    fileUploadInitiateRequest = {
      smPath: this.localFileUploadProcess.filesInfo[0].fullFilePath,
      serviceTaskId: this.stMain.serviceTasksDetails.serviceTaskId.toString(),
      image: this.localFileUploadProcess.localUploadImage.baseImage
    }
    QLogger.LogInfo(this.logSrc,"Uploading Bait File Initiate. ServiceTask: " + fileUploadInitiateRequest.serviceTaskId + ", Image: "+ fileUploadInitiateRequest.image + ", smPath: " + fileUploadInitiateRequest.smPath);
    
    this.localFileUploadProcess.uploadInProgress = true;
    response = this.webClient.baitFileUploadInitiate(fileUploadInitiateRequest);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Uploading Bait File Initiate. ServiceTask: " + fileUploadInitiateRequest.serviceTaskId + ", Image: "+ fileUploadInitiateRequest.image + ", smPath: " + fileUploadInitiateRequest.smPath + " - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Uploading Bait File Initiate. ServiceTask: " + fileUploadInitiateRequest.serviceTaskId + ", Image: "+ fileUploadInitiateRequest.image + ", smPath: " + fileUploadInitiateRequest.smPath + " - Success");
          let obj: any = JSON.parse(data.getData());
          this.localFileUploadProcess.sessionId = obj.sessionId as number;
          this.localFileUploadProcess.filesInfo.forEach(f=>{
            f.s3Key = obj.fullKeys[f.fullFilePath];
          });
          //this.s3FileUploadContinue();
          this.largeFileUpload = new LargeFileUpload(this.app, this.webClient);
          let config: LargeFileUploadConfig = {
            skipStart: true,
            uploadType: FileUploadType.Base64Decode // Change to Base64Decode once backend is ready
          };
          this.largeFileUpload.setFileInfo(this.localFileUploadProcess.files, this.localFileUploadProcess.filesInfo, config);
          this.localFileUploadProcess.subscription = this.largeFileUpload.initiateUploadLargeFile();
          this.localFileUploadProcess.subscription.subscribe((uploadResp)=>{
            if(uploadResp === true){
              this.submitFileCompleteBait();
            }
          })
          //this.uploadLargeFile();
        }
        else{
          this.localFileUploadProcess.errorMessage = data.getErrorDetail();
          QLogger.LogInfo(this.logSrc,"Uploading Bait File Initiate. ServiceTask: " + fileUploadInitiateRequest.serviceTaskId + ", Image: "+ fileUploadInitiateRequest.image + ", smPath: " + fileUploadInitiateRequest.smPath + " - Error: " + data.getErrorDetail());
        }
    });
  }
  
  submitFileCompleteBait(){
    QLogger.LogInfo(this.logSrc,"Uploading Bait File Complete to Image: "+ this.localFileUploadProcess.localUploadImage.baseImage)
    let req : BaitFileUploadCompleteRequest;
    let response: Observable<QPMResponse>;
    req ={
      sessionId: this.localFileUploadProcess.sessionId,
      commitMessage: this.localFileUploadProcess.commitMessage
    }
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Uploading Bait File Complete - Request: " + JSON.stringify(req));
    }
    response = this.webClient.baitFileUploadComplete(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Uploading Local Bait Complete - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Uploading Local Bait Complete - Success");
          let msg: string[] = [];
          msg.push("The file upload request placed successfully.");
          msg.push("Note: This request may take several minutes to update the history table. Please check history table later.");
          msg.push("   ");
          msg.push(...this.localFileUploadProcess.filesInfo.map(x => x.fullFilePath));
          this.app.showMessageBoxMultiLine("Local Upload Summary", msg, "Ok");
          this.localFileUploadProcess.uploadInProgress = false;
          this.resetLocalFileUploadProcess();
        }
        else{
          this.localFileUploadProcess.errorMessage = data.getErrorDetail();
          QLogger.LogInfo(this.logSrc,"Uploading Bait File Complete - Error: " + data.getErrorDetail());
        }
    });
  }
  
  submitFile(){
    let appPtr = this;
    this.localFileUploadProcess.fileValue = {};
    this.localFileUploadProcess.filesProcessed = 0;
   //l let multipartfiles: Array<File> = this.localFileUploadProcess.files;
    //this.uploadFileMultipart(multipartfiles)
    this.readFiles(0);
  }

  //Read files one by one recursively to avoid concurrent access of variables
  readFiles(i:number){
    
    let appPtr = this;
    const reader = new FileReader();
    var filename = appPtr.localFileUploadProcess.filesInfo[i].fullFilePath;
    appPtr.localFileUploadProcess.filesInfo[i].status = FileStatus.UploadInProgress;
    appPtr.localFileUploadProcess.filesInfo[i].statusString = FileStatus.UploadInProgress.toString();
    appPtr.localFileUploadProcess.filesInfo[i].uploadProgress = 50;
    reader.readAsDataURL(appPtr.localFileUploadProcess.files[i]);
    reader.onload = function () {
      let result = <string>reader.result;
      let base64String = (result).split(',').pop(); //Removing Header
      appPtr.localFileUploadProcess.fileValue[filename]=base64String;
      appPtr.localFileUploadProcess.filesProcessed++;
      if(appPtr.localFileUploadProcess.filesProcessed === appPtr.localFileUploadProcess.files.length){
        appPtr.localFileUploadProcess.filesInfo[i].status = FileStatus.UploadComplete;
        appPtr.localFileUploadProcess.filesInfo[i].statusString = FileStatus.UploadComplete.toString();
        appPtr.localFileUploadProcess.filesInfo[i].uploadProgress = 100;
        appPtr.localFileUploadProcess.showLocalFileSummaryDialog = false;
        appPtr.uploadFile(appPtr);
        return;
      }
      else{
        appPtr.readFiles(i+1);
      }
    }
    reader.onerror = function (error) {
      appPtr.app.showMessageBox("Local File Upload","Cannot read uploaded file. Please check if file is present in local storage","OK");
    };
  }
  uploadFile(appPtr){
    this.localFileUploadProcess.uploadInProgress = true;
    let req :LocalFileUploadRequest;
    let response: Observable<QPMResponse>;
    let jobs:string[]=[];
    let commitMessage: string = appPtr.localFileUploadProcess.commitMessage;
    if(appPtr.localFileUploadProcess.crNumber!='')
    {
      jobs.push("ChangeRequest" + appPtr.localFileUploadProcess.crNumber);
      commitMessage = "CR "+appPtr.localFileUploadProcess.crNumber + "\n" +appPtr.localFileUploadProcess.commitMessage+"\n"+"Fixed CR:"+appPtr.localFileUploadProcess.crNumber;
    }
    else{
      jobs.push("CirrusNoCR");
    }
    commitMessage = commitMessage + "\n SubmitterID: " + this.app.sharedData.userInfo.username;
    req = {
            cirrusTaskId: appPtr.localFileUploadProcess.localUploadImage.cirrusId,
            description: commitMessage,
            jobs:jobs,
            files_and_content:appPtr.localFileUploadProcess.fileValue
      }
    if (appPtr.app.sharedData.appInfo.logRequest) {
        //QLogger.LogInfo(appPtr.logSrc, "Remote File Upload Request: " + JSON.stringify(req));
    }
    response = appPtr.webClient.localFileUpload(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (appPtr.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(appPtr.logSrc, "Remote File Upload Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(appPtr.logSrc,"Remote File Upload Success");
          this.localFileUploadProcess.filesUploaded = JSON.parse(data.getData()) as LocalFileUploadResponse;
          this.localFileUploadProcess.filesUploaded.files.forEach(uploadedFile => {
            var currentDate = new Date();
            let formattedDate = formatDate(currentDate, 'MMM d, y, h:mm:ss a', 'en-US');
            uploadedFile.completed_date = formattedDate;
            uploadedFile.requester = this.app.sharedData.userInfo.username;
            uploadedFile.requested_date = this.localFileUploadProcess.filesInfo[0].requested_date;
            QLogger.LogInfo(appPtr.logSrc,"Successfully Uploaded :: " + JSON.stringify(uploadedFile));
          });
          appPtr.localFileUploadProcess.showLocalFileSummaryDialog = false;
          appPtr.localFileUploadProcess.showLocalFileFinalDialog = true;
          if(appPtr.localFileUploadProcess.crNumber !== ''){
            if(appPtr.localFileUploadProcess.cr === undefined){
              appPtr.localFileUploadProcess.cr = new ServiceTaskBuildCR();
              appPtr.localFileUploadProcess.cr.changeRequestId =  parseInt(appPtr.localFileUploadProcess.crNumber);
              appPtr.localFileUploadProcess.cr.comments = [];
            }
            let commentRequest: CrGerritComment = new CrGerritComment();
            commentRequest.crId = appPtr.localFileUploadProcess.cr.changeRequestId;
            commentRequest.softwareImage = appPtr.localFileUploadProcess.localUploadImage.baseImage;
            commentRequest.commentDesc = "Local Upload Commited";
            appPtr.stMain.addComment(commentRequest, appPtr.localFileUploadProcess.cr)
          }
        }
        else{
          if(data.getCode()===502 || data.getCode()===504){
            //msg box to show success message
            this.resetRemoteAutoCommitProcess();
            this.localFileUploadProcess.showLocalFileFinalDialogScuccess=true;
           
          }else{
            this.localFileUploadProcess.errorMessage = "Cannot upload local file to branch "+this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path+". Reason : "+ data.getError();
          }
        }
        this.localFileUploadProcess.uploadInProgress = false;
    });
  }

  showLocalFileUploadProgress(path: string){
    let req :LocalFileUploadSessionRequest;
    this.resetLocalFileUploadProgress();
    QLogger.LogInfo(this.logSrc,"Local File Upload Sessions - "+ path);
    this.localFileUploadProgress.displayProgress = true;
    this.localFileUploadProgress.loadingSessions = true;
    this.localFileUploadProgress.files = [];
    let response: Observable<QPMResponse>;
    if (typeof path === 'undefined'){
      path = this.localFileUploadProcess.localUploadImage.baseImage;
    }
    let serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId;
    req = {
      path: path,
      serviceTaskId: serviceTaskId
    }
    response = this.webClient.localFileUploadSessions(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Local File Upload Sessions - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          let obj:any = JSON.parse(data.getData());
          if(obj !== null){
            this.localFileUploadProgress.files = obj.localFileSessionDetailsAndFilePaths as LocalFileUploadSessionStatus[];
            this.localFileUploadProgress.files.reverse();
            QLogger.LogInfo(this.logSrc,"Local File Upload Sessions - Success");
          }
          
        }
        else{
          this.localFileUploadProgress.errorMessage = data.getErrorDetail();
          QLogger.LogInfo(this.logSrc,"Local File Upload Sessions - Error: " + data.getErrorDetail());
        }
        this.localFileUploadProgress.loadingSessions = false;
    });
  }
  
  //#multipart-file-upload
 /* uploadFileMultipart(multipartfiles:File[]){
    this.localFileUploadProcess.uploadInProgress = true;
    let req :FormData=new FormData();
    let response: Observable<QPMResponse>;
    let jobs:string[]=[];
    let commitMessage: string = this.localFileUploadProcess.commitMessage;
    if(this.localFileUploadProcess.crNumber!='')
    {
      jobs.push("ChangeRequest" + this.localFileUploadProcess.crNumber);
      commitMessage = "CR "+this.localFileUploadProcess.crNumber + "\\n" +this.localFileUploadProcess.commitMessage+"\\n"+"Fixed CR:"+this.localFileUploadProcess.crNumber;
    }
    else{
      jobs.push("CirrusNoCR");
    }
    commitMessage = commitMessage + "\\n SubmitterID: " + this.app.sharedData.userInfo.username;
   

    for(let k=0;k<multipartfiles.length;k++){
      var fileName=multipartfiles[k].name;
      var pathWithfilename = this.localFileUploadProcess.filesInfo[k].fullFilePath;
      req.append("files",multipartfiles[k],pathWithfilename);
    }
    req.append("description",commitMessage);
    req.append("jobs",jobs.toString());
    req.append("cirrusTaskId",this.localFileUploadProcess.localUploadImage.cirrusId.toString());


    if (this.app.sharedData.appInfo.logResponse) {
        QLogger.LogInfo(this.logSrc, "Remote File Upload Request: " + JSON.stringify(req));
    }
    response = this.webClient.localFileUpload(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Remote File Upload Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Remote File Upload Success");
          this.localFileUploadProcess.filesUploaded = JSON.parse(data.getData()) as LocalFileUploadResponse;
          this.localFileUploadProcess.showLocalFileSummaryDialog = false;
          this.localFileUploadProcess.showLocalFileFinalDialog = true;
          if(this.localFileUploadProcess.crNumber !== ''){
            if(this.localFileUploadProcess.cr === undefined){
              this.localFileUploadProcess.cr = new ServiceTaskBuildCR();
              this.localFileUploadProcess.cr.changeRequestId =  parseInt(this.localFileUploadProcess.crNumber);
              this.localFileUploadProcess.cr.comments = [];
            }
            let commentRequest: CrGerritComment = new CrGerritComment();
            commentRequest.crId = this.localFileUploadProcess.cr.changeRequestId;
            commentRequest.softwareImage = this.localFileUploadProcess.localUploadImage.baseImage;
            commentRequest.commentDesc = "Local Upload Commited";
            this.stMain.addComment(commentRequest, this.localFileUploadProcess.cr)
          }
        }
        else{
          this.localFileUploadProcess.errorMessage = "Cannot upload local file to branch "+this.localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path+". Reason : "+ data.getError();
        }
        this.localFileUploadProcess.uploadInProgress = false;
    });
  }*/
  //#endregion

  //#region BaitFileUpload

  triggerSystemBuildProcess:{
    displayForm: boolean;
    buildInProgress: boolean;
    image: ServiceTaskBuildImage;
  }

  resetTriggerSystemBuildProcess(){
    this.triggerSystemBuildProcess = {
      displayForm: false,
      buildInProgress: false,
      image: undefined
    }
  }

  loadBaitHlosChanges(image: ServiceTaskBuildImage){
    var serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId.toString();
    var softwareImage = image.baseImage;
    QLogger.LogInfo(this.logSrc,"Get HLOS changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage);
    image.loadHlosChanges = true;
    let response: Observable<QPMResponse>;
    response = this.webClient.baitHlosChanges(serviceTaskId, softwareImage);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Get HLOS changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage + " - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Get HLOS changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage + " - Success");
          let obj:any = JSON.parse(data.getData());
          if(obj !== null){
            //image.hlosChangesCommits = [];
            image.hlosChangesCommitsFiles = [];
            image.hlosChanges = obj.hlos_changes?.subtasks as BaitSubTasks[];
            image.hlosChanges?.forEach((subtask)=>{
              //image.hlosChangesCommits.push(...subtask.commits);
              subtask.commits.forEach((commit) => {
                let commitNode: TreeNode = {};
                commitNode.data = commit;
                commitNode.data["type"] = "COMMIT";
                commitNode.children = [];
                let actionNode: TreeNode;
                let filesNode: TreeNode;
                if(commit.changed_files.new_files?.length > 0){
                  actionNode = {data:{}, children:[]};
                  filesNode = {data:{}, children:[]};
                  actionNode.data["action"] = "New Files(" + commit.changed_files.new_files?.length + ")";
                  actionNode.data["type"] = "ACTION";
                  filesNode.data["files"] = commit.changed_files.new_files;
                  filesNode.data["type"] = "FILES";
                  actionNode.children.push(filesNode);
                  commitNode.children.push(actionNode);
                }
                if(commit.changed_files.edited_files?.length > 0){
                  actionNode = {data:{}, children:[]};
                  filesNode = {data:{}, children:[]};
                  actionNode.data["action"] = "Edited Files(" + commit.changed_files.edited_files?.length + ")";
                  actionNode.data["type"] = "ACTION";
                  filesNode.data["files"] = commit.changed_files.edited_files;
                  filesNode.data["type"] = "FILES";
                  actionNode.children.push(filesNode);
                  commitNode.children.push(actionNode);
                }
                if(commit.changed_files.deleted_files?.length > 0){
                  actionNode = {data:{}, children:[]};
                  filesNode = {data:{}, children:[]};
                  actionNode.data["action"] = "Deleted Files(" + commit.changed_files.deleted_files?.length + ")";
                  actionNode.data["type"] = "ACTION";
                  filesNode.data["files"] = commit.changed_files.deleted_files;
                  filesNode.data["type"] = "FILES";
                  actionNode.children.push(filesNode);
                  commitNode.children.push(actionNode);
                }
                if(commit.changed_files.renamed_files?.length > 0){
                  actionNode = {data:{}, children:[]};
                  filesNode = {data:{}, children:[]};
                  actionNode.data["action"] = "Renames Files(" + commit.changed_files.renamed_files?.length + ")";
                  actionNode.data["type"] = "ACTION";
                  filesNode.data["files"] = commit.changed_files.renamed_files;
                  filesNode.data["type"] = "FILES";
                  actionNode.children.push(filesNode);
                  commitNode.children.push(actionNode);
                }
                //commit.children.push()
                //commit.changed_files.
                image.hlosChangesCommitsFiles.push(commitNode);
              });

            });
          }
        }
        else{
          QLogger.LogInfo(this.logSrc,"Get HLOS changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage + " - Error: " + data.getErrorDetail());
        }
        image.loadHlosChanges = false;
    });
  }
  
  loadBaitSystemChanges(image: ServiceTaskBuildImage){
    var serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId.toString();
    var softwareImage = image.baseImage;
    QLogger.LogInfo(this.logSrc,"Get System changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage);
    image.loadSystemChanges = true;
    let response: Observable<QPMResponse>;
    response = this.webClient.baitSystemChanges(serviceTaskId, softwareImage);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Get System changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage + " - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Get System changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage + " - Success");
          let obj:any = JSON.parse(data.getData());
          if(obj !== null){
            image.systemChangesCommits = [];
            image.systemChanges = obj.system_changes?.subtasks as BaitSubTasks[];
            image.systemChanges?.forEach((subtask)=>{
              image.systemChangesCommits.push(...subtask.commits);
            });
          }
        }
        else{
          QLogger.LogInfo(this.logSrc,"Get System changes ServiceTaskID: " + serviceTaskId + " , Image: " + softwareImage + " - Error: " + data.getErrorDetail());
        }
        image.loadSystemChanges = false;
    });
  }

  triggerBaitSystemBuild(image: ServiceTaskBuildImage){
    var serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId.toString();
    var softwareImage = image.baseImage;
    QLogger.LogInfo(this.logSrc,"Bait system trigger build: " + serviceTaskId + " , Image: " + softwareImage);
    this.triggerSystemBuildProcess.buildInProgress = true;
    let req: BaitSystemTriggerBuildRequest = {
      softwareImage: softwareImage
    }
    let response: Observable<QPMResponse>;
    response = this.webClient.baitSystemTriggerBuild(serviceTaskId, req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Bait system trigger build: " + serviceTaskId + " , Image: " + softwareImage + " - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc,"Bait system trigger build: " + serviceTaskId + " , Image: " + softwareImage + " - Success");
          let buildResp:any = JSON.parse(data.getData());
          if(buildResp.success){
            this.app.showMessageBox("Trigger System Build", "Build request has been place succesfully", "Ok");
          }
          else{
            this.app.showMessageBox("Trigger System Build", "Build request failed. Error: " + buildResp.message, "Ok");
          }
        }
        else{
          QLogger.LogInfo(this.logSrc,"Bait system trigger build: " + serviceTaskId + " , Image: " + softwareImage + " - Error: " + data.getErrorDetail());
        }
        this.resetTriggerSystemBuildProcess();
    });
  }
  //#endregion

  //#region RemoteAutoCommit  
  remoteAutoCommitProcess:{
    displayForm: boolean;
    displayResultForm: boolean;
    image:ServiceTaskBuildImage;
    changeRequest:ServiceTaskBuildCR;
    changeListLabel: any;
    clientSpecOptions: SelectItem[];
    clientSpecType: string,
    predefinedTarget: string,
    customTarget: string,
    isChangeList: boolean;
    shelvedptions: SelectItem[];
    isShelved: boolean,
    commitResult: RemoteAutoCommitResponseResult;
    commitMessage: string;
    commitInProgress:boolean;
    errorMessage: string;
  }

  resetRemoteAutoCommitProcess(){
    this.remoteAutoCommitProcess = {
      displayForm: false,
      displayResultForm: false,
      image: undefined,
      changeRequest: undefined,
      changeListLabel: undefined,
      clientSpecOptions:  [
                            { label: "Predefined", value: "predefined" },
                            { label: "Custom", value: "custom" }
                          ],
      clientSpecType: "predefined",
      predefinedTarget: undefined,
      customTarget: undefined,
      isChangeList: false,
      shelvedptions:  [
                        { label: "No", value: false },
                        { label: "Yes", value: true }
                      ],
      isShelved: false,
      commitResult: undefined,
      commitMessage: "",
      commitInProgress:false,
      errorMessage: "",
    }
  }

  onRemoteAutoCommitClicked(rowData: ServiceTaskBuildCR,image: ServiceTaskBuildImage){
    this.resetRemoteAutoCommitProcess();
    this.remoteAutoCommitProcess.changeRequest = rowData;
    this.remoteAutoCommitProcess.image = image;
    if(rowData !== undefined){
      this.remoteAutoCommitProcess.commitMessage = "CR " + rowData.changeRequestId;
    }
    this.remoteAutoCommitProcess.displayForm = true;    
  }
  remoteAutoCommit(){
    if(this.app.sharedData.devFeature.componentBranchDevFR===false){
      this.remoteAutoCommitV1();
    }else{
      this.remoteAutoCommitV2();
    }
  }
  //For componentBranchDevFR is false
  remoteAutoCommitV1(){
    this.remoteAutoCommitProcess.commitInProgress = true;
    let response: Observable<QPMResponse>;
    let req: RemoteAutoCommitRequest;
    req ={
      cirrusTaskId: this.remoteAutoCommitProcess.image.cirrusId,
      client_spec_type: this.remoteAutoCommitProcess.clientSpecType,
      client_spec_view: this.remoteAutoCommitProcess.clientSpecType === "predefined"? [this.remoteAutoCommitProcess.predefinedTarget] : this.remoteAutoCommitProcess.customTarget?.split("\n"),
      source_changelist: this.remoteAutoCommitProcess.changeListLabel.toString(),
      description: this.remoteAutoCommitProcess.commitMessage,
      target_branch: this.remoteAutoCommitProcess.image.detail.perforceDetails.path,
      jobs: []
    }
    if(this.remoteAutoCommitProcess.changeRequest !== undefined)
    {
      req.jobs.push("ChangeRequest" + this.remoteAutoCommitProcess.changeRequest.changeRequestId);
    }
    else{
      req.jobs.push("CirrusNoCR");
    }

    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Remote Auto Commit - Request: " + JSON.stringify(req));
    }
    response = this.webClient.remoteAutoCommit(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Remote Auto Commit - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          let response = JSON.parse(data.getData()) as RemoteAutoCommitResponse;
          if(response.success){
            this.remoteAutoCommitProcess.commitResult = response.result as RemoteAutoCommitResponseResult;
            this.resetLocalFileUploadProcess();
            this.remoteAutoCommitProcess.displayForm = false;
            this.remoteAutoCommitProcess.displayResultForm = true;
          }
          else if(response.failure_reason !== undefined && response.failure_reason !== ""){
            this.remoteAutoCommitProcess.errorMessage = "Error occurred during Auto Commit. " + response.failure_reason;
            if(response.messages?.length > 0){
              this.remoteAutoCommitProcess.errorMessage = response.messages[0];
            }
          }
        }
        else{
          this.remoteAutoCommitProcess.errorMessage = "Error occurred during Auto Commit. " + data.getError();
        }
        this.remoteAutoCommitProcess.commitInProgress = false;
      });
  }

  //For componentBranchDevFR is true
  remoteAutoCommitV2(){
    this.remoteAutoCommitProcess.commitInProgress = true;
    let response: Observable<QPMResponse>;
    let req: RemoteAutoCommitRequest;
    req ={
      cirrusTaskId: this.remoteAutoCommitProcess.image.cirrusId,
      client_spec_type: this.remoteAutoCommitProcess.clientSpecType,
      client_spec_name:this.remoteAutoCommitProcess.clientSpecType === "predefined"? this.remoteAutoCommitProcess.predefinedTarget : null,
      client_spec_view: this.remoteAutoCommitProcess.clientSpecType === "predefined"? null : this.remoteAutoCommitProcess.customTarget?.split("\n"),
      source_changelist: this.remoteAutoCommitProcess.changeListLabel,
      description: this.remoteAutoCommitProcess.commitMessage,
      requested_on_behalf_of:this.app.sharedData.userInfo.username,
      jobs: []
    }
    if(this.remoteAutoCommitProcess.changeRequest !== undefined)
    {
      req.jobs.push("ChangeRequest" + this.remoteAutoCommitProcess.changeRequest.changeRequestId);
    }
    else{
      req.jobs.push("CirrusNoCR");
    }

    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Remote Auto Commit - Request: " + JSON.stringify(req));
    }
    response = this.webClient.remoteAutoCommitV2(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Remote Auto Commit - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          let obj  = JSON.parse(data.getData()) as  RemoteAutoCommitResponseResult;
          this.remoteAutoCommitProcess.commitResult = obj as  RemoteAutoCommitResponseResult;
          this.resetLocalFileUploadProcess();
          this.remoteAutoCommitProcess.displayForm = false;
          this.remoteAutoCommitProcess.displayResultForm = true;
        }
        else{
          this.remoteAutoCommitProcess.errorMessage = "Error occurred during Auto Commit. " + data.getError();
        }
        this.remoteAutoCommitProcess.commitInProgress = false;
      });
  }
  //#endregion

  //#region OPSHELP Support Ticket Creation
  opsHelpIssueType=OPSHELP.IssueType;
  opsHelpProjectKey=OPSHELP.ProjectKey;
  ciruss=OPSHELP.Ciruss;
  buildInfrastructure=OPSHELP.BuildInfrastructure;


  onOpenOPSHelpSupportTicketDialog(issueType: string,projectKey:string,component:string,buildTableData:any[],failedReason:string) {
      this.app.onOpenSupportTicketDialog(issueType,projectKey,component);
      //Pre-populate the Build fail details
      let tableData=buildTableData; 
      let buildDescription = "Build Description " + "\n"+"\n";
      let buildId="";
      tableData.forEach(data=>{
        if(data.label==='Build ID'){
          buildId=data.value;
        }
        buildDescription+= data.label + " :  " + data.value + "\n";
    
      })
      this.app.supportDialog.description =buildDescription;
      //Subject Format :ST # <STNumber> <Build-ID> Failure- <faliedReason>
      this.app.supportDialog.subject= "ST #"+ this.stMain.serviceTasksDetails?.serviceTaskId+" " +buildId+ "   Failure-"+failedReason;
  
  };

  onOpenCirrusHelpSupportTicketDialog(issueType: string,projectKey:string,component:string,buildTableData:any[],failedReason:string, status:string, softwareImage:string) {
    this.app.onOpenSupportTicketDialog(issueType,projectKey,component);
    //Pre-populate the Build fail details
    let tableData=buildTableData; 
    let buildDescription = 
    "Build Description " + "\n" + 
    "ServiceTask ID : " + this.stMain.serviceTasksDetails?.serviceTaskId + "\n" + 
    "Software Image : " + softwareImage +  "\n" + 
    "Image Detailed Status : " + status +"\n";
    let buildId="";
    tableData.forEach(data=>{
      if(data.label==='Build ID'){
        buildId=data.value;
      }
      buildDescription+= data.label + " :  " + data.value + "\n";
  
    })
    this.app.supportDialog.description =buildDescription;
    //Subject Format :ST # <STNumber> <Build-ID> Failure- <faliedReason>
    this.app.supportDialog.subject= "ST #"+ this.stMain.serviceTasksDetails?.serviceTaskId+" " +buildId+ "   Failure: "+failedReason+ "  ImageStatus: " + status;

};
  //#endregion

  //#region Add Component to Branch
  addComponentToBranchProcess:{
    displayForm: boolean,
    selectedCirrusImage:ServiceTaskBuildImage;
    isComponentAddingInprogress:boolean,
    showAddAllBtn:boolean,
  }
  
  resetAddComponentToBranchProcess(){
    this.addComponentToBranchProcess={
      displayForm:false,
      selectedCirrusImage:new ServiceTaskBuildImage(),
      isComponentAddingInprogress:false,
      showAddAllBtn:false
    }

  }
  onFilterClearComponentBranch() {
    this.addComponentToBranchFilter.nativeElement.value = '';
    this.addComponentToBranchtable.filterGlobal('', 'contains');
  }
  
  showComponentToBranchDetails(selectedImage:ServiceTaskBuildImage){
    this.resetAddComponentToBranchProcess();
    this.addComponentToBranchProcess.displayForm=true;
    this.addComponentToBranchProcess.selectedCirrusImage=selectedImage;
    //backed call to load 
    this.loadServiceTaskBuildDetailsWithComponents(selectedImage,false)
  }

  loadServiceTaskBuildDetailsWithComponents(image: ServiceTaskBuildImage, refresh: boolean){
    if(!refresh && image.detail.components.length !== 0){
      return;
    }
    QLogger.LogInfo(this.logSrc, "Get Build Details - Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId);
    image.loadingBranchComponents = true;
    //image.detail.components =[];
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskBuildDetailsWithComponents(this.app.sharedData.service.common.selectedServiceTaskID, image.cirrusId.toString());
    response.subscribe(
      (data:QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Get Build Details - Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId + " Response : " +JSON.stringify(data));
          } 
          if(data.isSuccess()){
            QLogger.LogInfo(this.logSrc, "Get Build Details Success- Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId);
            let obj = JSON.parse(data.getData());
            if(obj !== undefined || obj !== null){
              image.detail.components = obj?.components as CirrusComponent[];
              let notBranchedComponents=[];
              image.detail.components?.forEach(comp=>{
                comp.addInprogress=false;
                comp.errorMsg="";
                //Extract branch components which are not yet added
                if(comp.status!=='Branched'){
                  notBranchedComponents.push(comp);
                }
              })            
              //If all components are already 'Branched' then no need to show <+ Add All>
              if(notBranchedComponents?.length === 0){
               this.addComponentToBranchProcess.showAddAllBtn=false;
              }else{
                this.addComponentToBranchProcess.showAddAllBtn=true;
              }              

            }
          }
          else{
            QLogger.LogError(this.logSrc, "Get Build Details Failed- Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId);
            QLogger.LogError(this.logSrc, "Get Build Details Failed- Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" CirrusID: "+ image.cirrusId +" Error : " + data.getError() + " - " + data.getErrorDetail());
          }
          image.loadingBranchComponents = false;

        });

  }

  onAddSingleComponentToBranch(cirrusComponent:CirrusComponent){
    QLogger.LogInfo(this.logSrc, "Add Build Component to Branch  - Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID+" Component Base Name: "+ cirrusComponent.base_name);
    let response : Observable<QPMResponse>;
    let request: ComponentBranchRequest;
    request={
      component_base_names:[]
    }
    request.component_base_names.push(cirrusComponent.base_name);
    cirrusComponent.addInprogress=true;
    this.addComponentToBranchProcess.isComponentAddingInprogress=true;

    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Add Build Component to Branch - Service Task Build: " + JSON.stringify(request) );
    }
    response = this.softwareCatalogClient.addBuildComponentToBranch(this.app.sharedData.service.common.selectedServiceTaskID,request);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Add Build Component to Branch- Service Task Build - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            QLogger.LogInfo(this.logSrc, "Add Build Component to Branch - Service Task Build - Success");  
            let result= obj?.combinedMessage as string;
            QLogger.LogInfo(this.logSrc, "Add Build Component to Branch - Service Task Build - Success-combinedMessage:"+result);  
          }
          this.loadServiceTaskBuildDetailsWithComponents(this.addComponentToBranchProcess.selectedCirrusImage,true);
        }
        else {
          QLogger.LogError(this.logSrc, "Add Build Component to Branch- Service Task Build - Failed");
          QLogger.LogError(this.logSrc, "Add Build Component to Branch- Service Task Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          cirrusComponent.errorMsg=data.getError();
        }
        this.addComponentToBranchProcess.isComponentAddingInprogress=false;
        cirrusComponent.addInprogress=false;
      }
    );


  }
  onAddAllComponentToBranch(){
      QLogger.LogInfo(this.logSrc, "Add All Build Component to Branch  - Service Task: "+this.app.sharedData.service.common.selectedServiceTaskID);
      let response : Observable<QPMResponse>;
      let request: ComponentBranchRequest;
      request={
        component_base_names:[]
      }
     
      this.addComponentToBranchProcess.isComponentAddingInprogress=true;
      if(this.app.sharedData.appInfo.logRequest){
        QLogger.LogInfo(this.logSrc, "Add All Build Component to Branch - Service Task Build: " + JSON.stringify(request) );
      }
      response = this.softwareCatalogClient.addBuildComponentToBranch(this.app.sharedData.service.common.selectedServiceTaskID,request);
      response.subscribe(
        (data:QPMResponse) => { 
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Add All Build Component to Branch- Service Task Build - Response : " +JSON.stringify(data));
          } 
          if (data.isSuccess()){
            let obj = JSON.parse(data.getData());
            if(obj !== undefined || obj !== null) {
              QLogger.LogInfo(this.logSrc, "Add All Build Component to Branch - Service Task Build - Success");  
              let result= obj?.combinedMessage as string;
              QLogger.LogInfo(this.logSrc, "Add All Build Component to Branch - Service Task Build - Success-combinedMessage:"+result);  
            }
            this.loadServiceTaskBuildDetailsWithComponents(this.addComponentToBranchProcess.selectedCirrusImage,true);
          }
          else {
            QLogger.LogError(this.logSrc, "Add All Build Component to Branch- Service Task Build - Failed");
            QLogger.LogError(this.logSrc, "Add All Build Component to Branch- Service Task Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            this.app.showMessageBox("Add Component to Branch","Failed : "+data.getError(),"OK");
          }
          this.addComponentToBranchProcess.isComponentAddingInprogress=false;
          this.addComponentToBranchProcess.selectedCirrusImage.detail.components.forEach(comp=>comp.addInprogress=false)
        }
      );
  }
}

