import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import isElectron from 'is-electron';
import { BehaviorSubject } from 'rxjs';
import { authCodeFlowConfig } from '../common/auth-config';
import { Utils } from '../common/utils';
import { OidcTokenResponse } from '../models/lime-web-client';

class WebHttpUrlEncodingCodec {
  encodeKey(k) {
      return encodeURIComponent(k);
  }
  encodeValue(v) {
      return encodeURIComponent(v);
  }
  decodeKey(k) {
      return decodeURIComponent(k);
  }
  decodeValue(v) {
      return decodeURIComponent(v);
  }
}

@Injectable()
export class AuthService {// Used to deteremine if user is logged in
  private isLoggedInSubject$ = new BehaviorSubject<boolean>(false);
  public isLoggedIn$ = this.isLoggedInSubject$.asObservable();

  // Used to keep track of when login process has started / ended
  private isLoginCompleteSubject$ = new BehaviorSubject<boolean>(false);
  public isLoginComplete$ = this.isLoginCompleteSubject$.asObservable();

  // Used to signal that login has already been initiated by oauth
  // and that we have been redirected back to the UI from the login site
  private isLoginInitiatedSubject$ = new BehaviorSubject<boolean>(false);
  public isLoginInitiated$ = this.isLoginInitiatedSubject$.asObservable();

  _storage: any;
  queryString: string;

  isLocalHost = false;//CommonUtility.isLocalHost();
  localuser: string

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    //private userService: UserService,
    private http: HttpClient,
    private utils: Utils,
    private authStorage: OAuthStorage
  ) {
    if(isElectron()){
      return;
    }
    this.isLocalHost = window.location.origin.includes("localhost");
    this._storage = sessionStorage;
    this.queryString = window.location.href?.substring(1);
    const urlParams = new URLSearchParams(window.location.href);
    if(!window.location.href.includes("nonce")){
      var url = window.location.href;
      var urlparts = url.split("#/");
      urlparts.shift();
      if(urlparts !== undefined && urlparts?.length > 0){
        this._storage.setItem('routepath',urlparts[0]);
      }
    }
    this.init();
  }

  init(): void {
    if(this.isLocalHost){
      while(!this.localhostLogin()){
        continue;
      }
      return;
    }

    if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()){
      this.isLoggedInSubject$.next(true);
      this.routeToPage();
    }
    else if (!this.isLocalHost) {
      this.oauthService.loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      .catch(err=>{
        if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()){
          this.isLoggedInSubject$.next(true);
        }
        else{
          this.fetchToken();
        }
      })
      .then(()=>{        
        if (this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken()){
          this.isLoggedInSubject$.next(true);
        }
        else{
          this.fetchToken();
        }
      });
    }
  }

  routeToPage(){
    if(!this.utils.isStringNullOrEmpty(this._storage.getItem('routepath'))){
      var path = this._storage.getItem('routepath');
      this._storage.removeItem('routepath')
      this.router.navigate(["/" + path]);
    }
  }

  async validateLoginToken(): Promise<void> {
    // Try to load and save user profile
    await this.setupAndLoadUserProfile();
  }

  isAuthUrl(): boolean {
    const queryString = window.location.href?.substring(1);
    return queryString?.includes('code') && queryString?.includes('nonce');
  }

  // Login Methods
  async loginRequested(): Promise<void> {
    this.isLoggedInSubject$.next(false);
    this.isLoginCompleteSubject$.next(false);

    // First try to login the user
    this.isLoggedInSubject$.next(await this.loginUser());

    if (this.isLoggedInSubject$.value) {
      this.isLoginInitiatedSubject$.next(false);
      this.isLoginCompleteSubject$.next(true);
    } else {
      this.isLoginInitiatedSubject$.next(false);
      this.isLoginCompleteSubject$.next(true);

    }
  }

  loginUser(): Promise<boolean> | boolean {
    // Choose login flow based on env
    if (!this.isLocalHost) {
      return this.openAuthLogin();
    } else {
      return this.localhostLogin();
    }
  }

  async authorizeUser(): Promise<boolean> {
    /*const url = environment.qdcApi;
    const auth = await this.http.get<{ uuid: string, name: string }>(`${url}users/${this.userService.user()}/authorize`, { headers: this.buildHeaders()}).toPromise().catch(_ => false);

    // If auth is valid it returns an object but we only care about a valid
    // value for now so just return true / false
    if (!auth) {
      return false;
    }*/

    return true;
  }

  async getAuthentication(): Promise<boolean> {
    // Parse query parameters to fetch token with
    const queryString = window.location.href?.substring(1);
    const query: string[] = [];
    queryString.split('&').forEach((q) => {
      query.push(q.substring(q.lastIndexOf('=') + 1))
    })

    //const token = await this.oauthService.fetchTokenUsingGrant('authorization_code', { nonce: query[0], code: query[1], state: query[2] }).catch(_ => false);
    const token = await this.oauthService.getAccessToken(); //this.authStorage.getItem('access_token'); //this.oauthService.getIdToken();

    //  this.authLogout();
    // If token call fails, it will return a false value so just return back to login call
    if (!token) {
      return false;
    }
    if (token) {
      // If user prof call fails, it will return a false value so just return back to login call
      // TODO: Clean up IUserProfile to match new oauth user profile values
      const loadUser: any = await this.oauthService.loadUserProfile().catch(_ => false);
      if (!loadUser) {
        return false;
      }

      if (loadUser) {
        // Set user profile info
        //this.userService.setUser(loadUser.info.sub);
        console.log("Token:" + loadUser)
        return true;
      }
    }

    return false;
  }

  async setupAndLoadUserProfile(): Promise<void> {
    const loadUser: any = await this.oauthService.loadUserProfile().catch(_ => false);
    const token: any = await this.oauthService.getIdToken();
    if (loadUser) {
      // Set user profile info
      //this.userService.setUser(loadUser.info.sub);
      this.isLoggedInSubject$.next(true);
      this.isLoginInitiatedSubject$.next(true);

    }
  }

  async openAuthLogin(): Promise<boolean> {
    if (this.isLoginInitiatedSubject$.value) {
      // Returning from login process, now get the authentication
      // from the url route to complete login process
      return await this.getAuthentication();
    }
    else {
      // Start oauth login process
      this.oauthService.initCodeFlow();
    }

    return Promise.resolve(false);
  }

  localhostLogin(): boolean {
    const user = prompt('Who goes there!?') ?? undefined;
    if (!this.utils.isStringNullOrEmpty(user)) {
      this.localuser = user;
      this.isLoggedInSubject$.next(true);
      return true;
    }

    return false;
  }

  // Logout Methods
  logoutRequested(): void {
    if (!this.isLocalHost) {
      this.authLogout();
    } else {
      this.localhostLogout();
    }
  }

  authLogout(): void {
    //this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout();
    this.resetLogin();
  }

  localhostLogout(): void {
    this.resetLogin();
  }

  resetLogin(): void {
    //this.userService.logoutUser();
    this.isLoggedInSubject$.next(false);
    this.isLoginCompleteSubject$.next(false);
  }

  protected buildHeaders(): HttpHeaders {
    let headers = new HttpHeaders();

    // headers = headers.set('Accept', '*/*');
    // headers = headers.set('Authorization', `Bearer ${this.oauthService.getAccessToken()}`);
    // headers = headers.set('X-QCOM-TracingID', ApigeeConfig.tracingId);
    // headers = headers.set('X-QCOM-AppName', ApigeeConfig.appName);
    // headers = headers.set('X-QCOM-ClientType', ApigeeConfig.clientType);
    // headers = headers.set('X-QCOM-TokenType', ApigeeConfig.tokenType);
    // headers = headers.set('X-QCOM-ClientId', ApigeeConfig.clientId);

    return headers;
  }

  
  fetchToken(){
    const query: string[] = [];
    this.queryString.split('&').forEach((q) => {
      query.push(q.substring(q.lastIndexOf('=') + 1))
    })
    if(!!query[1] && this.queryString.includes("code") && !this.isLoggedInSubject$.value){
      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      let params = new HttpParams({encoder: new WebHttpUrlEncodingCodec})
          .set('grant_type', 'authorization_code')
          .set('code', decodeURIComponent(query[1]))
          .set('redirect_uri', authCodeFlowConfig.redirectUri);
      params = params.set('client_id', authCodeFlowConfig.clientId);
      params = params.set('client_secret', authCodeFlowConfig.dummyClientSecret);
      
      this.http.post(authCodeFlowConfig.tokenEndpoint, params,{headers})
      .subscribe(response => {
        let tokenResponse = response as OidcTokenResponse;
        this.storeAccessTokenResponse(tokenResponse.access_token, tokenResponse.refresh_token, tokenResponse.expires_in, undefined);
        this.oauthService.processIdToken(tokenResponse.id_token, tokenResponse.access_token)
          .then(result => {
            this.storeIdToken(result);
            this.isLoggedInSubject$.next(true);
            this.routeToPage();
            //console.log(this.oauthService.getIdToken());
            //console.log(this.oauthService.getAccessToken());
            //console.log(JSON.stringify(this.oauthService.getIdentityClaims()));
          });
      });
    }
    else{
      this.oauthService.initCodeFlow();
    }
  }

  revokeTokenAndLogout(){
    let accessToken = this.oauthService.getAccessToken();
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    let params = new HttpParams({encoder: new WebHttpUrlEncodingCodec})
      .set('token', accessToken)
      .set('token_type_hint', 'access_token');
    params = params.set('client_id', authCodeFlowConfig.clientId);
    params = params.set('client_secret', authCodeFlowConfig.dummyClientSecret);
    this.http.post(authCodeFlowConfig.revocationEndpoint, params,{headers})
      .subscribe(response => {
        this.oauthService.logOut();
      });
  }
  
  getAccessToken(){
    return (this.oauthService.getAccessToken());
  }
  getIdToken(){
    return (this.oauthService.getIdToken());
  }
  getUsername(){
    return (this.isLocalHost? this.localuser : this.oauthService.getIdentityClaims()['sub'] as string);
  }

  storeAccessTokenResponse(accessToken, refreshToken, expiresIn, grantedScopes) {
    this._storage.setItem('access_token', accessToken);
    if (grantedScopes && !Array.isArray(grantedScopes)) {
        this._storage.setItem('granted_scopes', JSON.stringify(grantedScopes.split('+')));
    }
    else if (grantedScopes && Array.isArray(grantedScopes)) {
        this._storage.setItem('granted_scopes', JSON.stringify(grantedScopes));
    }
    this._storage.setItem('access_token_stored_at', '' + Date.now());
    if (expiresIn) {
        const expiresInMilliSeconds = expiresIn * 1000;
        const now = new Date();
        const expiresAt = now.getTime() + expiresInMilliSeconds;
        this._storage.setItem('expires_at', '' + expiresAt);
    }
    if (refreshToken) {
        this._storage.setItem('refresh_token', refreshToken);
    }
  }
  
  storeIdToken(idToken) {
    this._storage.setItem('id_token', idToken.idToken);
    this._storage.setItem('id_token_claims_obj', idToken.idTokenClaimsJson);
    this._storage.setItem('id_token_expires_at', '' + idToken.idTokenExpiresAt);
    this._storage.setItem('id_token_stored_at', '' + Date.now());
  }
}
