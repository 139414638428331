import { SelectItem } from 'primeng';
import { DownloadInfo, InstalledPackageDetails, QIKPackageProperites } from './catalog-client'
import { Entitlements } from './lime-client';
import { crFixDetail, Gerrit, GerritSISelection, SoftwareImage } from "./software-catalog-client";

export enum UserRoleType{
    ST_Manager,
    Master_ST_Manager,
    ST_Requestor,
    SW_Downloader,
    Super_Admin,
    Beta_User,
    Master_ST_Manager_BAIT,
    ST_CR_Propagation,
    Master_ST_CR_Propagation
}
export enum ToolUserRoleType{
    ADMIN,
    EXTL1ADMIN
}

export class OidcTokenResponse{
    access_token: string;
    id_token: string;
    expires_in: string;
    token_type: string;
    refresh_token: string;
}

export class UserInfo{
    uid: string;
    partyId: number;
    partyNum: number;
    companyName: string;
    fullName: string;
    roles: string[];
    internal: boolean;
}

export class ErrorResponse 
{
    errorId:string
    errorCode:string
    errorMessage:string
    errorDetails:string
}

export class ServiceTaskRequest
{
    customerProjectId: string
    baseBuild: string
    baseBuildSpf?: string
    baseBuildTag?: string
    baseBuildSpfTag? : string
    branch?: string
    buildType?: string
    changeRequests: string[]
    salesforceCustomer?: string
    companies?: Company[]
    createdUsingSI: boolean
    serviceTaskImageBuilds? : SoftwareImage[]
    gerrits?: GerritSISelection[]
    customerUserId?: string
    title?: string
    description?: string
    requestAppId?: string
    requestSource?: string
    softwareProduct?: string
    master: boolean
    autobuild?: boolean;
    isImageFormatAddOn?:boolean;
    caption?:string;
}

export class MemberUpdateRequest{
    accessType: number;
    enabled: boolean;
    uid: string;
}

export class NewServiceTaskInfo{
    serviceTaskId: number
    serviceTaskUrl: string
}

export class CRDetails {
    assignee: string
    assigneeUid: string
    changeRequestNumber: number
    severity: string
    status: string
    title: string
    type: string
    participants: Participant[];
}
export class Participant {
    area: string
    subsystem: string
    functionality: string
    assigneeUid: string
}

export class ChangeRequest {
    action: string;
    changeRequest: number;
}

export class Company {
    companyName: string;
    partyId: number;
    partyNum: number;
}

export class GerritInfo{
    softwareImage: string;
    softwareImageBuild: string;
    gerritIds: string[];
    type: string;
}

export class UpdateServiceTaskRequest {
    abandoned: boolean;
    baseBuild: string;
    baseBuildTag?: string;
    baseBuildSpf?: string;
    baseBranch?: string;
    changeRequests?: ChangeRequest[];
    gerrits?: GerritInfo[];
    companies?: Company[];
    customerUserId?: string;
    requestedDate?: string;
    requester?: string;
    serviceTaskId: number;
    requestAppId: string;
    requestSource: string;
    caption?:string;
    primaryOwner?: string;
    secondaryOwner?: string;
    createdUsingSI?: boolean;
    autobuild?: boolean;
    isImageFormatAddOn?:boolean;
    
}

export class CrGerritComment{
    commentDesc?: string;
    crId: number;
    gerritId: string;
    softwareImage: string;
}

export class TriageDeleteCrRequest{
    crId: Number;
    softwareImage: string;
}

export class TriageDeleteGerritRequest{
    gerritId: string;
    softwareImage: string;
}

export interface TriggerBuildRequest {
    changeRequests?: ChangeRequest[];
    changeRequestAction?: ChangeRequest;
    gerrits?: Gerrit[];
    gerritAction?: Gerrit;
    productBuild: string;
    serviceTaskId: number;
    createdUsingSI: boolean;
    requestAppId: string;
    requestSource: string;
}

export interface ValidateCRRequest {
    baseBuild: string;
    changeRequest: number[];
    serviceTaskId: number;
    createdUsingSI: boolean;
}

export interface TriggerCirrusBuildResponse {
    messages: string[];
    cirrus_build_id: number;
}

export interface TriggerProductBuildRequest {
    images: BuildCompositionImage[];
    productBaseBuildID: string;
    serviceTaskId: number;
    distros: string[];
    skipRecompile: boolean;
}

export class BuildCompositionImage {
    newSoftwareImageBuildInfo: SoftwareImageSigningInfo;
    newSoftwareImageBuild: string;
    oldSoftwareImageBuild: string;
    baseImageBuild: string;
    softwareImage: string;
    softwareImageChangedBuilds: string[];
    softwareImageChangedBuildsV2:SoftwareImageSigningInfo[];
    newBuildSelectItems: SelectItem[];
    changed: boolean;
    customized: boolean;
    softwareImageTypeSelected: string;
    softwareImageCustom: SoftwareImageLatest[];
    softwareImageCustomSelected: SoftwareImageLatest;
    softwareImageCustomSelectItem: SelectItem[];
    isSigningRequired?:boolean;
    buildStatus?:string;
    loadLatestBuildInProgress: boolean;
    }

export class SoftwareImageSigningInfo{

    imageBuildStatus:string;
    isSigningRequired:boolean;
    signingInfoMessage:string;
    softwareImageBuild:string;
}

export class SoftwareImageLatest{
    imageBuild: string;
    buildType: string;
}

export class BuildCompositionResponse {
    images: BuildCompositionImage[] = [];
    constructor() {
        this.images = [];
    }
}

export class DistributionRequest{
    serviceTaskId?: number;
    softwareProductBuild: string;
    softwareDistroList : SoftwareProductDistroInfo[];
    requestedPlatform?:Number
}
export class ImageBuildDistributionRequest{
    serviceTaskId?: number;
    requestedMechanism?:number;
    softwareImageBuild: string;
    softwareDistroList : SoftwareProductDistroInfo[];
    requestedPlatform?:Number
}
export class DiffDistributionRequest{
    baseSoftwareProductBuild: string;
    targetSoftwareProductBuild: string;
    softwareDistroList : SoftwareProductDistroInfo[];
    requestedPlatform?:Number;
    serviceTaskId?:Number
}
export class SoftwareProductDistroInfo{

    distroName:string;
    distroId:number;
    uploadComplete:number;
    softwareDistroAlternateId:string;
}

export class shipDownloadResponse{
    softwareProductBuild: string;
    softwareDistroList : SoftwareProductDistroInfo[];
}
 
export class diffShipDownloadResponse{
    baseSoftwareProductBuild: string;
    targetSoftwareProductBuild: string;
    softwareDistroList : SoftwareProductDistroInfo[];
}
export class TriggerProductBuildResponse {
    createdAt: string;
    errorCode: number;
    errorMessage: string;
    jobUrl: string;
    productBaseBuildID: string;
    productNewBuildID: string;
    requester: string;
    serviceTaskId: number;
}

export class MasterTriggerBuildRequest {
    imageBuild: string;
    productBuild: string;
    serviceTaskId: number;
    softwareImage: string;
    gitBranch: string;
    gitUrl: string;
}

export class TriggerBaitBuildRequest {
    gerritIds: string[];
    changeRequests: Number[];
    jira_technology_breakdown: string;
    skip_build: boolean;
    softwareImage: string;
    targets: string[];
    user: string;
    autoShip: boolean;
    autoDiffShip:boolean;
    diffBaseSoftwareProductBuild:string;
    skipRecompile: boolean;
    distroList: String[]
}
export class TriggerBaitBuildResponse {
    success: boolean;
    message: string;
}

export class AvaliableDiffBuildsResponse{

    baseSoftwareProductBuild:string;
    diffTargetBuildAndDistroList:SoftwareDiffBuildRelease[];
}
export class SoftwareDiffBuildRelease{
    targetSoftwareProductBuild:string;
    distroList:SoftwareProductDistroInfo[];
}
export class AutoShipRequest{
    cirrusBuildId: number[];
    cirrusTaskId: number;
    autoShip: boolean;
    autoDiffShip:boolean;
    diffBaseSoftwareProductBuild:string;
    images: BuildCompositionImage[];
    distroList: string[];
    serviceTaskId: number;
    softwareProductBuild?: string;
    softwareProductBaseBuild?: string;
    skipRecompile: boolean;
    fullBuild: boolean;
    buildAllDistros:boolean;
}
export class AutoShipRequestSP{
    autoShip: boolean;
    autoDiffShip:boolean;
    diffBaseSoftwareProductBuild:string;
    buildAllDistros: boolean;
    images: BuildCompositionImage[];
    distroList: string[];
    serviceTaskId: number;
    softwareProductBaseBuild: string;
    skipRecompile: boolean;
}

export class Email {
    fromEmailId: string;
    toEmailIds: String[];
    ccEmailIds: String[];
    subject: string;
    body: string;
}

export class ImageDistributionRequest{
    imageBuild: string;
    softwareProductBuild?: string;
    softwareDistro : string;
    softwareDistroID:string;
    softwareDistroAlternateId?:string
    requestedPlatform?:Number
    imageUploadTrackingId?:Number
}
export class ImageDiffDistributionRequest{
    baseImageBuild: string;
    baseSoftwareProductBuild: string;
    softwareDistro : string;
    softwareDistroID:string;
    requestedPlatform?:Number
    targetSoftwareProductBuild:string;
}

export class AvaliableDiffImagesResponse{

    baseSoftwareProductBuild:string;
    diffTargetBuildAndDistroListAndImageList:SoftwareImageDiffBuildRelease[];
}
export class SoftwareImageDiffBuildRelease{
    targetSoftwareProductBuild:string;
    distroList:SoftwareImageDistroInfo[];
}
export class SoftwareImageDistroInfo{
    distroName:string;
    distroId:number;
    uploadComplete:number;
    shipRequester?:string;
    softwareDistroAlternateId?:string;
    diffImageBuildDownloadResponses?:AvailableDiffImage[];
    imageUploadStatusesList?:shipStatusForImage[];
    imageUploadTrackingId?:Number;
    jiraUrl?: string;
    jiraStatus?: Number;
    binDdmStatus?: Number;
    
}
export class shipStatusForImage{

    softwareImagebuild:string;
    uploadComplete:Number;
}
export class AvailableDiffImage{
    baseImageBuild:string;
    targetImageBuild:string;
    uploadComplete:number;
}


export class imageShipDownloadResponse{
    softwareProductBuild?: string;
    softwareImageBuild?:string;
    imageDistroList : SoftwareImageDistroInfo[];
}

/* TOOLS */
export class ProductCatalogV3 {
    products : ProductV3[]
    productSuites : ProductSuiteV3[]
    subSuites : ProductSubSuiteV3[]
}
export class ProductV3 {
    isInternal : boolean
    description: string
    displayName: string
    simplifiedDisplayName: string // remove any special characters
    eccn: string
    etag: Number
    ownerTeam: string
    productLogo: string
    logoHTML: string
    productName: string
    productType: string
    productUUID: string
    latestRelease: ReleaseV3
    installedVersion: InstalledPackageDetails
    productEntitlements:Entitlements[]
    productOS:string[]
    productID:string  //Used for filetring in UI
    selectedOS?:string
    productSuites: string[]
    subSuiteUuids: string[]
    productClassification: string
    latestVersion: string
    osDistTypes?:string[];
}
export class ProductSuiteV3 {
    description: string
    displayName: string
    simplifiedDisplayName: string // remove any special characters
    name: string
    id: string
    logo: string
    logoHTML: string
    products: string[]
    subSuiteUuids: string[]
}
export class ProductSubSuiteV3 {
    //creator: string // Not needed
    description: string
    displayName: string
    simplifiedDisplayName: string // remove any special characters
    name: string
    uuid: string
    logo: string
    logoHTML: string
    products: string[]
}
export class ReleasesV3 {
    componentID: string
    releases:ReleaseV3[]
}
export class ReleaseV3 {
    autoUpdateEnabled: boolean
    packageComponentID: number
    releaseBranch: string
    releaseDateUTC: string
    targetOS: string
    updateLevel: string
    version: string
    releaseID: string
    packageDownloadLocation:string
    releaseNotesInfo:DownloadInfo[]
    packageComponentId:string
    packageVersion?:string
    installerInfo:QIKPackageProperites
    fileType?:string;
    installType?:string;
    installerSizeString?:string
    targetArch?:string
}
  
export class ProductDetails {
    etag: number
    description: string
    displayName: string
    id: string
    name: string
    subtitle: string
    logo: string
    logoHTML: string
    productType: string
    features: Feature[]
    packages: PackageDetails[]
}
  
export class PackageDetails {
    componentId: string
    componentName: string
    componentType: string
    etag: string
    files: File[]
    id: string
    installType: string
    isAutoUpdateEnabled: boolean
    isAutoUpdateOptional: boolean
    name: string
    notificationDuration: number
    packageType: string
    releaseBranch: string
    releaseDateUTC: string
    releaseNote: string
    targetArch: string
    targetOS: string
    updateLevel: string
    updateOfflineOnly: boolean
    updateSchedule: string
    version: string
}
export class File {
    filename: string
    filesize: number
    tokenId: string
}
  
export class Feature {
    components: string[]
    description: string
    displayName: string
    featureType: string
    id: string
    name: string
    subtitle: string
}
  
export class ProductSuite {
    etag: number
    description: string
    displayName: string
    id: string
    name: string
    logo: string
    products: string[]
}

export class RadarInfo{
    externalID: String;
    cr: String;
    note: String;
}
export class ServiceTaskCRGerritComment{
    commentId: number;
    creationDateTime: string;
    description: string;
    serviceTaskChangeRequestId: number;
    userId: string;
    softwareImage: string;
}

export class DocumentInfo {
    fileName:string;
    dcnNumber:string;
}

export class JiraTicketRequest {   
    issueType:string;
    summary:string;
    description:string;
    projectKey:string;
    components:string[];
}
export class JiraTicketResponse { 
    id:string;  
    jiraTicket:string;
    jiraTicketUrl:string;
}
export class JiraFile{
    base64File: string;
    fileName: string;
}
export class JiraFilesUploadRequest{
    requestList:JiraFile[];
}

export class CrFixDetailRequest{
    crs: number[];
    softwareItem: string;
    softwareItemType: string;
    serviceTaskId: number;
}
export class CrFixDetailResponse{
    requestId: number
    crFixDetails: crFixDetail[];
}

export class SearchUpdateCrFixDetailRequest{
    changeRequest: number[];
    baseBuild: string;
    createdUsingSI: boolean;
    serviceTaskId: number;
}

export class CveCrMappingRequest{
    cveNumbers: string[];
    securityBulletins: string[];
}
export class cveCrInfoDetails{

    cveCrInfo:cveCrInfoDetails[];
}
export class cveCrMappingDetails{
    cveNumber:string;
    crId: number;
    cwe: string;
    attacker: string
    victim: string;
    weaknessType: string;
    rating:string;
    allBulletins:string;
    area: string;
    subsystem: string;
    functionality: string;
}

export class AutoPropagationRequest{
    changeRequest:number;
    serviceTaskId:number;
    targetBranch:string;
    targetSoftwareImage:string;
    user:string;

}

export class AutoPropagationResult{
    messages:string[];
    outcome:string;
    state : string;
    committedCl: string;
    propagation_results:PropagationResult[];
}

export class PropagationResult{
    completed_on:string;
    failure_reason:string;
    source_type:string;
    started_on:string;
    target_change_id:string;
    source_change_ids:string[];
    files:FilePropagationResult[];
    target_change_details:ChangeListDetails;
    is_top_secret: boolean;
}

export class FilePropagationResult{
    file_name:string;
    operation:string;
    resolved_line:string;
}

export class AutoPropagationCommitResult{
    messages:string[];
    outcome:string;
    result: {
        requested_by: string,
        submitted_change_id: string,
        submitted_on: string
    }
}

export class RemoteAutoCommitRequest{
    cirrusTaskId: number;
    client_spec_view: string[];
    source_changelist: any;
    client_spec_type?: string;
    client_spec_name?:string;
    jobs: string[];
    description: string;
    target_branch?: string;
    requested_on_behalf_of?:string;
}

export class RemoteAutoCommitResponse{
    failure_reason: string;
    messages: string[];
    result: RemoteAutoCommitResponseResult;
    success: boolean;
}

export class RemoteAutoCommitResponseResult{
    files: RemoteAutoCommitResponseResultFile[];
    changelist: number;
    messages?: string[];
}

export class RemoteAutoCommitResponseResultFile{
    revision: string;
    depot_file: string;
    action: string; 
}

export class ChangeListDetails{
    change_id:string;
    review_url:string;
}
export class FilePathSearchRequestForBait {
    image: string;
    serviceTaskId: number;
    smPath: string;
}
export class FilePathSearchRequest {
    branch: string;
    cirrusTaskId: number;
    filename: string;
}
export class CirrusWorkspaceRootResponse {
    name:           string;
    base_name:      string;
    revision:       string;
    workspace_root: string;
}
export class LocalFilesDeleteRequest {
    description: string;
    files: string[];
    jobs:string[];
}
export class LocalFilesDeleteResponse {
    files: RemovedFile[]
    changelist: number
  }
  
  export class RemovedFile {
    revision: string
    depot_file: string
    action: string
  }
  
export class FilePathValidationRequest {
    cirrusTaskId: number;
    depot_file:string;
    enumerate_directories:boolean;
}

export enum UploadSystemType{
    CIRRUS,
    BAIT
}

export class LocalFileUploadRequest {
    cirrusTaskId: number;
    description:string;
    files_and_content:any;
    jobs:string[]
}
export class LocalFileUploadResponse {
    changelist: number;
    files:FileUploaded[];

}
export class FileUploaded{
    action: string;
    depot_file: string;
    revision: string;
    requester?: string;
    requested_date?: string;
    completed_date?: string;
}
export class LocalFileUploadInitiateRequest{
    cirrusTaskId: number;
    filePaths: string[];
    p4Path: string;
    serviceTaskId: number;
}
export class LocalFileUploadCompleteRequest{
    description: string;
    jobs: string[];
    sessionId: number;
    operatingSystem: string;
}
export class BaitFileUploadCompleteRequest{
    commitMessage: string;
    sessionId: number;
}
export class BaitFileUploadInitRequest{
    serviceTaskId: string;
    image: string; 
    smPath: string;
}
export class s3FileUploadContinueRequest{
    fileContent: string;
    key: string;
    partNumber: number;
    path: string;
    decode:boolean;
    // decodeBase64: boolean;
}
export class S3UploadCompleteRequest{
    key:string; 
    path:string; 
}
export class S3FileUploadInfo{
    s3fileinfoId: number;
    fullKey: string;
    uploadId: string;
    path: string;
    expiration: string;
}

export class LocalFileUploadSessionStatus{
    localFileSessionDetails: LocalFileSessionDetails;
    filePaths: string[];
    serviceTaskId: number;
    cirrusTaskId: number;
    sessionId: string;
    sessionStatus: number;
    p4ChangeList: number;
    path: string;
    files: string[];
    s3fileinfo: string[];
    p4Path: string;
    requester: string;
    requested_date: string;
    completed_date: string;
    image: string;
    s3FileUploadInfoEntities: S3FileUploadInfo[];
}
export class LocalFileUploadSessionRequest{
    path: string;
    serviceTaskId: number;
}
export class LocalFileSessionDetails{
    serviceTaskId: number;
    cirrusTaskId: number;
    requester	:	string;
    sessionStatus: number;
    p4ChangeList: number;
    path: string;
    axiomRequestedDate: string;
    axiomCompletedDate: string;
    axiomTaskVerdict: number;
    axiomTaskStatus: number;
    taskId: number;
    attemptTaskId: number;
    retryCount: number;
    uploadRequestedDate: string;
    checkinCompletedDate: string;
    image: string;
    smServiceTaskId	: number;
    sessionId: number;
    s3FileUploadInfoEntities: S3FileUploadInfo[];

}
export class BaitSystemTriggerBuildRequest{
    softwareImage: string;
}


export enum STARDUST{
    IssueType = "Support",
    ProjectKey = "STARDUST"
}
export enum OPSHELP{
    IssueType = "Incident",
    ProjectKey = "OPSHELP",
    BuildInfrastructure="BuildInfrastructure",
    Ciruss="Cirrus"
}

export class LicenseResponseData{
    serialNumber:string;
    id:string;
    displayProductNames:string;
    activeCount:number;
    quantity :number;
    expiryStatus:string;
    creationDate:string;  
    endDate: string
    startDate: string; 
    licenseType:string;     
}

export class LicenseGroup{
    id:string;
    displayProductNames:string; 
    creationDate:string;
    startDate: string;
    endDate: string;
    activeCount?:number;
    quantity ?:number;
}
export class License{
    serialNumber:string;
    isEligibleForRenewal:boolean;
    licenseGroups:LicenseGroup[];
    slNumberAndLicenseGroups?:string;
    activeCount:number;
    quantity :number;
    expiryStatus:string;
    endDate: string
    startDate: string;
    licenseType:string;
}

export class LicenseGroupFeature {
    endd:string;
    featureType:string;
    id:string;
    length:number;
    name:string;
    productName:string;
    productId:string;
    productLogo:string;
    startd:string;
}
export class LicenseGroupFeatureResponse{
    company:string;
    licenseType:string;
    quantity:number;
    activeCount:number;
    featuresDTO:LicenseGroupFeature[];
    creationDate:string;
    creator:string;
    id:string;
    licenseCount:number;
    partyId:number;
    productId:string;
    serialNumber:string;
    status:string;
}

export class LicenseGroupProductCovered{
    imageURL:string;
    productName:string;
}

export class LicenseGroupSeat {
    licenseActivationId: string;
    activatedd: string;
    status: string;
    username: string;
    computerName: string;
    daysUsed: number;
    licenseGroupId: string;
    licenseType: string;
    licenseUserId: number;
    partyId: number;
    stateId: number;
    versionId: number;
}

export class SerialNumberDetail {
    licenseID: string;
    products: string[];
    allProductsName:string;
    serialStatus: string;
    purchaseDate: string;
    startDate: string;
    endDate: string;
}

export class PaymentIntentRequest {
    request: PaymentIntent;
}

export class PaymentIntent {
    paymentIntentId: string;
    amount: number;
    automaticPaymentMethodEnabled: boolean;
    description: string;
    currency: string;
    receiptEmail: string;
    metadata: PaymentMetaData;
    application: string;

}

export class PaymentMetaData{
    userId: string;
    partyId:number;
    serialNumberDetails: SerialNumberModel[]
}

export class SerialNumberModel{
    serialNumber: string;
    itemPrice: number;
    serviceFee: number;
    licenseGroups: LicenseGroup[];
}

export class RequestLine {
    serialNumber: string;
    licenseGroupId: string;
    startDate: string;
    partnerId:number;
}
export class RequestLines {
    requestLine: RequestLine[];
}

export class PriceRequest {
    userName: string;
    requestLines: RequestLines;
}
export class PricingDetailsRequest {
    priceRequest: PriceRequest;
}

export class ResponseLine {
    billToAddress: string;
    customerName: string;
    errorCode: string;
    itemPrice: string;
    itemTaxAmount:string;
    licenseGroupId: string;
    serialNumber: string;
    shipToAddress: string;
    billToAddressV2: BillToAddressV2;
    shipToAddressV2: ShipToAddressV2;
}

export class BillToAddressV2 {
    city: string;
    country: string;
    countryCode: string;
    county: string;
    postalCode: string;
    state: string;
    streetAddress1: string;
}

export class ShipToAddressV2 {
    city: string;
    country: string;
    countryCode: string;
    county: string;
    postalCode: string;
    state: string;
    streetAddress1: string;
}

export class ResponseLines {
    responseLine: ResponseLine[];
}
export class PricingDetailsResponse {
    errorCode: string;
    messageId: string;
    responseLines: ResponseLines;
    userName: string;
}

export class CartItem{
    selectedLicense:License;
    responseLineDetail: ResponseLine;
}

export class LicenseSeatDeactivateRequest {
    item: Item;
    requester: string;
}
export class Item {
    status: string;
}

export class LicenseGroupForNotificationResponse {
    expiryDate: string;
    expiryStatus: string;
    licenseType: string;
    licensegroupUuid: string;
    products: Product[];
    userId: string;
  }
  
  export class Product {
    productName: string;
    productUuid: string;
  }

  export class LicenseGroupForNotification{
    expiryDate: string;
    expiryStatus: string;
    licenseType: string;
    licensegroupUuid: string;
    products: Product[];
    allProducts:string;
    userId: string;
    inProgress:boolean;

  }