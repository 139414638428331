<div id="LicenseFindPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">

  <div class="ui-g-12 card card-w-title" style="height:100%;">
        <!-- Header -->
        <div class="ui-g-12">
            <div class="ui-g-8" style="display:flex; justify-content: flex-start; height: 50px;flex-direction: column-reverse;">
                <p-tabMenu  [model]="licensesLandingMenuItems" [activeItem]="licensesLandingMenuActiveItem"  style="width:100%;"></p-tabMenu>
          </div>
        </div>
       
        <hr>
  
          <!--Search-->
        <div class="ui-g-12">
          <div class="ui-inputgroup" style="margin-top: 20px;">
              <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
              <input id="FeaturesProductsSearch" #licensesGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Search Serial Numbers,License Groups,Tools " (input)="licensesTable?.filter($event.target.value,'slNumberAndLicenseGroups','contains')">
              <i class="ui-icon-cancel" (click)="onLicensesFilterClear()" id="LicenceGroupclearToolGlobalFilter" pTooltip="Clear Search" style="margin:4px 4px 0 0; cursor: pointer;"></i>        
          </div>
        </div>
        <div class="ui-g-12">
           <!--Filter Section-->
           <div class="ui-g-12 ui-lg-3">
            <h3 style="display: inline-block;color:lightgrey">FILTER BY</h3>
            <div class="ui-g">
            <h3 style="display: inline-block;">Status</h3>
            <div class="ui-g-12">
                <p-checkbox id="activeCheckBox" name="statusGroup" value="Active"  [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedStatus, 'expiryStatus', 'in')" [(ngModel)]="selectedStatus"  inputId="active"></p-checkbox>
                <label for="active">Active</label>  
            </div>
            <div class="ui-g-12">
              <p-checkbox id="purchasedCheckBox" name="statusGroup"  value="Purchased" [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedStatus, 'expiryStatus', 'in')" [(ngModel)]="selectedStatus"  inputId="Purchased"></p-checkbox>
              <label for="Purchased">Purchased</label>
          </div>
            <div class="ui-g-12">
                <p-checkbox id="expiringCheckBox" name="statusGroup" value="Expiring" [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedStatus, 'expiryStatus', 'in')"  [(ngModel)]="selectedStatus" inputId="expiring"></p-checkbox>
                <label for="expiring">Expiring</label>
            </div>
            <div class="ui-g-12">   
                <p-checkbox id="expiredCheckBox" name="statusGroup" value="Expired" [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedStatus, 'expiryStatus', 'in')" [(ngModel)]="selectedStatus" inputId="expired"></p-checkbox>
                <label for="expired">Expired</label>
            </div> 
          </div>

          <div class="ui-g">
            <h3 style="display: inline-block;">Type</h3>
            <div class="ui-g-12">
                <p-checkbox id="SITECheckBox" name="statusGroup" value="SITE"  [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedTypes, 'licenseType', 'in')" [(ngModel)]="selectedTypes"  inputId="SITE"></p-checkbox>
                <label for="SITE">SITE</label>  
            </div>
            <div class="ui-g-12">
              <p-checkbox id="PURCHASECheckBox" name="statusGroup"  value="PURCHASE" [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedTypes, 'licenseType', 'in')" [(ngModel)]="selectedTypes"  inputId="PURCHASE"></p-checkbox>
              <label for="PURCHASE">PURCHASE</label>
          </div>
            <div class="ui-g-12">
                <p-checkbox id="DEMOCheckBox" name="statusGroup" value="DEMO" [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedTypes, 'licenseType', 'in')"  [(ngModel)]="selectedTypes" inputId="DEMO"></p-checkbox>
                <label for="DEMO">DEMO</label>
            </div>
            <div class="ui-g-12">   
                <p-checkbox id="FACTORYCheckBox" name="statusGroup" value="FACTORY" [disabled]="this.loadingLicenses" (onChange)="licensesTable.filter(selectedTypes, 'licenseType', 'in')" [(ngModel)]="selectedTypes" inputId="FACTORY"></p-checkbox>
                <label for="FACTORY">FACTORY</label>
            </div> 
          </div>
          </div>
         <!--Table Section-->
         <div class="ui-g-12 ui-lg-9">
             
               <div class="ui-g-12" *ngIf="loadingLicenses">
                 <h3 style="display: inline-block;">Loading Details. Please wait...</h3>
                 <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
               </div>
              
                <div class="ui-g-12">
                  <h2 style="display: inline-block;">Licenses</h2>
                </div>
    
               
            
              <div class="ui-g-12" style="margin:0px;padding:0px;">
                <p-table  #licensesTable [value]="licenses"[scrollable]="true" 
                [loading]="loadingLicenses" sortField="serialNumber" [sortOrder]="-1" [paginator]="true" [rows]="20" [globalFilterFields]="['serialNumber','id','displayProductNames','expiryStatus']">
                <ng-template pTemplate="caption">
                  <div style="text-align: right">
                    <i class="ui-icon-refresh" id="refreshRelease" pTooltip="Refresh" (click)="getAvailableLicensesData()" style="margin:4px 4px 0 0"></i>
                  </div>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                    <th *ngFor="let col of cols" id ="{{col.field}}"  [ngStyle]="col.style">
                      <span>{{col.header}}</span>
                      <!-- <p-sortIcon [field]="col.field"></p-sortIcon> -->
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td headers="serialNumber">
                      <p *ngIf="rowData?.serialNumber!=''" style="word-wrap: break-word"><b><a (click)="app.goToSerialNumberDetailsPage(rowData?.serialNumber,rowData)" style="cursor: pointer; color: blue; display: inline-block;">{{rowData?.serialNumber}}</a></b></p>
                      <p *ngIf="rowData?.serialNumber==''" style="word-wrap: break-word">N/A</p>
                    </td>
                    <td headers="licenseGroups" style="width: 35%;">
                      <span *ngFor="let item of rowData?.licenseGroups">
                        <p style="word-wrap: break-word"><b><a (click)="app.goToLicenseGroupDetailsPage(item?.id,rowData)" style="cursor: pointer; color: blue; display: inline-block;">{{item?.id}}</a></b></p>
                        <p style="word-wrap: break-word"><b style="color: grey;">Tools :</b>{{item?.displayProductNames}}</p><br>
                      </span>
                   
                    </td>
                    <td headers="activeCount">
                      <span *ngFor="let lgitem of rowData?.licenseGroups">
                        <p style="word-wrap: break-word"><b>{{lgitem?.activeCount}} of </b> <b style="color: grey;"> {{lgitem?.quantity}}</b> </p>
                        <p style="word-wrap: break-word"></p><br><br>
                      </span>
                    </td>
                    <td headers="licenseType"><span style="word-wrap: break-word">{{rowData?.licenseType}}</span></td>
                    <td headers="expiryStatus">
                      <span>
                        <p id="expiringStatus" *ngIf="rowData?.expiryStatus==='Expiring'" style="word-wrap: break-word;font-size:0.9em;margin-bottom:2px;"><span class="yellowdot" style="margin-right:5px;"></span><b>Expiring</b></p>
                        <p id="expiringDate" *ngIf="rowData?.expiryStatus==='Expiring'" style="word-wrap: break-word;font-size:0.9em;margin-bottom:2px;color: gray;">On {{rowData?.endDate}}</p>
                      </span>
                    
                    <p id="purchasedStatus"  *ngIf="rowData?.expiryStatus==='Purchased'" style="word-wrap: break-word;font-size:0.9em;margin-bottom:2px;"><span class="greendot" style="margin-right:5px;"></span><b>Purchased</b></p>
                    <p id="activeStatus"   *ngIf="rowData?.expiryStatus==='Active'" style="word-wrap: break-word;font-size:0.9em;margin-bottom:2px;"><span class="greendot" style="margin-right:5px;"></span><b>Active</b></p>
                    <p id="expiredStatus"  *ngIf="rowData?.expiryStatus==='Expired'" style="word-wrap: break-word;font-size:0.9em;margin-bottom:2px;"><span class="reddot" style="margin-right:5px;"></span><b>Expired</b></p>
                    </td>
                    <td headers="renew" style="width: 210px;">
                      <span *ngIf="(app.sharedData.visibility.license.renewWorkflowForUSuser) && (rowData?.serialNumber!='') && (rowData?.expiryStatus==='Expiring' || rowData?.expiryStatus==='Expired')" style="word-wrap: break-word"> 
                        <button style="width: 150px;;align-self: center;vertical-align:center;" id="RenewButton" (click) = "app.openRenewDialogProcess(rowData)" [disabled]="!rowData?.isEligibleForRenewal"  pButton type="button" label="Renew"></button>
                        <i class="pi pi-exclamation-circle" *ngIf="!rowData?.isEligibleForRenewal" pTooltip="Renewal is available when: License expired less than 1 year ago or is due to expire within 90 days" [style]="{'font-size': '1.7em', 'cursor': 'pointer'}"></i>
                      </span>
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td>No data found.</td>
                  </tr>
                </ng-template>
              </p-table>
              </div>
            
          </div>
        </div>
  </div>
</div>