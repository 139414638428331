<div class="card card-w-title">
    <h1 id="softwareImagesHeader">Software Images:</h1>
    <p-accordion *ngIf="app.sharedData.visibility.serviceTaskBuild.addSI && stMain.serviceTasksDetails.abandoned == false">
        <p-accordionTab id="addImagesAccordionTab" header="Add Images" [selected]=false>               
            <p-table id="addImagesTable" #dt *ngIf="app.sharedData.visibility.serviceTaskBuild.addSI"
                [value]="masterServiceTaskImages" [columns]="imagesCols" dataKey="image">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th *ngFor="let col of columns" id="{{col.field}}" [ngStyle]="col.style">
                           {{col.header}} 
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td id="noImageDataFoundMessage" colspan="4">No data found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                        <td id="image">{{rowData.image}}</td>
                        <td id="imageBuild">{{rowData.imageBuild}}</td>
                        <td id="imageStatus">{{rowData.status}}</td>
                        <td>
                            <i class="ui-icon-add" id="addStSoftwareImageCPUCP" pTooltip="Add Image" (click)="addCustomeBaseImage(rowData)"
                            *ngIf="rowData.passthrough === true && rowData.integrationSystem !== 'BAIT' && rowData.inProgress === false && (rowData.status === '' || rowData.status !== 'Added')"></i>
                            <i class="ui-icon-add" id="addStSoftwareImage" pTooltip="Add Image" (click)="addCustomeBaseImage(rowData)"
                            *ngIf="rowData.passthrough === false && rowData.inProgress === false && (rowData.status === '' || rowData.status !== 'Added')"></i>
                            <p-progressSpinner id="addSISpinner" *ngIf="rowData.inProgress == true" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </p-accordionTab>
    </p-accordion>
    <h3 id="noBuildFoundMessage" *ngIf="serviceTaskBuilds.softwareImages.length === 0">No Builds Found</h3>
    <div style="text-align: right; padding-top: 15px;">
        <button *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerSI && serviceTaskBuilds.softwareImages.length !== 0 
                        && stMain.serviceTasksDetails.abandoned == false && !loadingProductDistros
                        && stMain.serviceTasksDetails.baseBuild !== null"
                pButton id="triggerSP&AutoShipButton" type="button" (click)="onTriggerSPClicked()" label="Trigger SP & Auto Ship" style="width:170px">
       </button>
       <!--button *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerSI && serviceTaskBuilds.softwareImages.length !== 0 && stMain.serviceTasksDetails.abandoned == false" pButton type="button"
           (click)="buildProductPreviewShow()" label="Trigger SP Build" style="width:150px">
      </button-->
       <p-progressSpinner *ngIf="productValidationInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
    </div>

    <p-accordion *ngFor="let image of serviceTaskBuilds.softwareImages; let i = index"
        (onOpen)="onBuildExpand(image)" [multiple]="true">
        <p-accordionTab id="addedImagesTab" header={{image.baseImage}} [selected]="i === 0">
            <div>
                  <!-- PassThrough Build Info-->
                  <div class="ui-g" *ngIf="image.buildSystem ==='PassThrough'">
                    <div class="ui-g-9">
                        <div id="baseSIbuild" *ngIf="app.sharedData.visibility.serviceTaskBuild.viewBranch" style="margin-bottom:10px;">
                            <b>Base SI Build : </b>{{image?.baseImageBuild}}
                        </div>
                        <div id="gitBranch" *ngIf="app.sharedData.visibility.serviceTaskBuild.viewBranch" style="margin-bottom:10px;">
                            <b>Git Branch : </b>{{image?.gitBranch}}
                            <span class="ui-inputgroup-addon" *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerProduct"> <!-- && image.passThroughBuilds?.length==0"-->
                                <i id="editGitBranch" class="material-icons" (click)="updateGitDetailsForPassThroughImage(image)" *ngIf="!stMain.addCustomBaseImageProcess.isUpdateForPassThrough" pTooltip="Edit" style="display: inline-block; cursor: pointer;">edit</i>
                                <p-progressSpinner id="UpdategitBranchSpinner" *ngIf="stMain.addCustomBaseImageProcess.isUpdateForPassThrough" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                            </span>
                        </div>
                        <div id="gitUrl" *ngIf="app.sharedData.visibility.serviceTaskBuild.viewBranch" style="margin-bottom:10px;">
                            <b>Git Url : </b>{{image?.gitUrl}}
                            <span class="ui-inputgroup-addon" *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerProduct"> <!-- && image.passThroughBuilds?.length==0"-->
                                <i id="editGitURL" *ngIf="!stMain.addCustomBaseImageProcess.isUpdateForPassThrough" class="material-icons" (click)="updateGitDetailsForPassThroughImage(image)" pTooltip="Edit" style="display: inline-block; cursor: pointer;">edit</i>
                                <p-progressSpinner id="UpdategitSpinner" *ngIf="stMain.addCustomBaseImageProcess.isUpdateForPassThrough" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                            </span>
                        </div>
                         
                    </div>
                    <div class="ui-g-3">
                        <div class="ui-g-12">
                            <i class="ui-icon-refresh"  *ngIf="!(image.loadingPassThroughBuilds || image.loadingPassThroughBuildDetails > 0 || image.loadingChangeRequests > 0 || image.loadingChangeRequestsCurrentInfo > 0 || image.loadingInternalClients)" id="refreshStBuildDetails" pTooltip="Refresh" (click)="onBuildDetailRefresh(image)" style="margin-top: -10px;float: right;"></i>
                            <p-progressSpinner          *ngIf="(image.loadingPassThroughBuilds || image.loadingPassThroughBuildDetails > 0 || image.loadingChangeRequests > 0 || image.loadingChangeRequestsCurrentInfo > 0 || image.loadingInternalClients)" [style]="{width: '20px', height: '20px', margin: '-10px 0 0 0', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                            <!--Disable Local File Upload and Email Temporarly-->
                            <!-- <i class="ui-icon-email" *ngIf="app.sharedData.visibility.serviceTaskBuild.emailCR
                                                            && stMain.serviceTasksDetails.abandoned === false 
                                                            && !(image.loadingChangeRequests > 0 || image.loadingChangeRequestsCurrentInfo > 0)" 
                                                            pTooltip="Email" (click)="selectEmailCRs(image)" style="margin-top: -10px;float: right;"></i> -->
                         
                            <!-- <i class="pi pi-upload" id="stImageFileUpload"  style="margin-top: -10px;float: right;" pTooltip="File Upload" *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskBuild.localFileUpload && image?.detail?.perforceDetails!== null && image.detail.perforceDetails?.path !== undefined" (click)="onLocalFileUploadClicked(image,undefined,UploadSystemType.CIRRUS); onRemoteAutoCommitClicked(undefined,image)" ></i> -->
                        </div>
                        <div class="ui-g-12" style="padding-left: 0;">
                            <!--Disable Auto Ship Temporarly-->
                            <button *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerProduct && stMain.serviceTasksDetails.abandoned == false
                                            && !loadingProductDistros && image.gitBranch !== null && image.gitUrl !== null && image.loadingPassThroughBuildDetails==0"
                                    pButton id="triggerPassThroughSI&AutoShip" type="button" (click)="triggerPassThroughSIClicked(image)" label="Trigger SI"
                                    style="width: 165px;float: right;"></button>
                        </div>
                    </div>
                </div>
                <!-- Bait Build Info-->
                <div class="ui-g"  *ngIf="image.buildSystem === 'BAIT'">
                    <div class="ui-g-9">
                        <div id="smIDStatus">
                            <b>STATUS : </b> {{image.smServiceTaskId?'Active':'Failed'}}
                            <i class="pi pi-info-circle" *ngIf="(image.smServiceTaskId ===null) && stMain.serviceTasksDetails.abandoned === false" id="smidFailed" pTooltip="{{image.errorMessage}}" style="margin-top: -10px;"></i>
                        </div>
                    </div>
                    <div class="ui-g-3">
                        <div class="ui-g-12">
                            <p-progressSpinner *ngIf="image.loadingBaitBuilds || image.loadingBaitBuildDetails > 0 || image.loadHlosChanges || image.loadSystemChanges" [style]="{width: '20px', height: '20px', margin: '-10px 0 0 0', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                            <i id="imageRefreshIcon" *ngIf="!(image.loadingBaitBuilds || image.loadingBaitBuildDetails > 0)" class="ui-icon-refresh" pTooltip="Refresh" (click)="onBuildDetailRefresh(image)" style="margin-top: -10px;float: right;"></i>
                            <i class="ui-icon-email" *ngIf="app.sharedData.visibility.serviceTaskBuild.emailCR
                                                            && stMain.serviceTasksDetails.abandoned === false 
                                                            && !(image.loadingChangeRequests > 0 || image.loadingChangeRequestsCurrentInfo > 0)" 
                                                            pTooltip="Email" (click)="selectEmailCRs(image)" style="margin-top: -10px;float: right;"></i>
                            <i class="pi pi-upload" id="stImageFileUpload"  style="margin-top: -10px;float: right;" pTooltip="File Upload" *ngIf="image.smServiceTaskId!==null  && stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskBuild.localFileUpload" (click)="onLocalFileUploadClicked(image,undefined,UploadSystemType.BAIT); onRemoteAutoCommitClicked(undefined,image)" ></i>
                        </div>
                        <div class="ui-g-12" style="padding-left: 0;">
                            <button pButton id="TriggerBaitBuildButton" type="button" 
                                *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerBaitBuild && 
                                        !image.loadingBaitBuilds && image.loadingBaitBuildDetails == 0"
                                (click)="onTriggerBaitBuildClicked(image)" label="Trigger Bait Build & Auto Ship"
                                style="width: 215px;float: right;"></button>
                        </div>
                    </div>
                </div>
                <!-- Cirrus Build Info-->
                <div class="ui-g" *ngIf="image.buildSystem !== 'BAIT' && image.buildSystem !=='PassThrough'">
                    <div class="ui-g-9">
                        <div *ngIf="app.sharedData.visibility.serviceTaskBuild.viewBranch" style="display: inline-flex;">
                            <div id="stBranch" *ngIf="app.sharedData.visibility.serviceTaskBuild.viewBranch" style="margin-bottom:10px;"><b>P4 PATH : </b>{{image.detail.perforceDetails?.path}}</div>
                            <i   id="AddCompToBranch" *ngIf="app.sharedData.devFeature.componentBranchDevFR===true && app.sharedData.visibility.serviceTaskBuild.viewBranch && image?.detail?.status==='Ready'" class="pi pi-sitemap" pTooltip="Component Branch" (click)="showComponentToBranchDetails(image)" style="font-size: 1.5rem;"></i> 
                        </div>
                        <div id="stStatus" *ngIf="!image.failed">
                            <b>STATUS : </b>{{image.detail.status}}
                            <i class="ui-icon-refresh" *ngIf="image.detail.status === 'SeedBuildFailed' && !image.recoverCirrusSeedBuildInProgress && app.sharedData.visibility.serviceTaskBuild.recoverSeedBuiild && stMain.serviceTasksDetails.abandoned === false" id="recoverCirrusSeedBuildRequest" pTooltip="Recover" (click)="recoverCirrusSeedBuildRequest(image)" style="margin-top: -10px;"></i>
                            <div id="branchingErrors" *ngIf="image.detail.status === 'BranchingFailed' || image.detail.status === 'Error'">
                                <div>Error must be resolved by <b>CirrusHelp</b> team.
                                    <button pButton id="CirrusHelp" [style]="{'width': '170px', 'margin-left': '10px'}" type="button"  (click)="onOpenCirrusHelpSupportTicketDialog(opsHelpIssueType,opsHelpProjectKey,ciruss,viewBuildRequestDetailProcess.tableData,viewBuildRequestDetailProcess.build.failed_reason, image.detail.status, image.baseImage)" label="File CirrusHelp Ticket"></button>
                                </div>
                            </div>
                        </div>
                        <div id="stStatus" *ngIf="image.failed"> 
                            <b>STATUS: </b>Failed
                            <i class="ui-icon-refresh" *ngIf="!image.retryCirrusBuildInProgress && app.sharedData.visibility.serviceTaskBuild.retryCirrus && stMain.serviceTasksDetails.abandoned === false" id="retryCirrusBuildRequest" pTooltip="Retry" (click)="retryCirrusBuildRequest(image)" style="margin-top: -10px;"></i>
                        </div>
                        <div id="stStatusFailReason" *ngIf="image.failed" style="margin-top:10px;"><b>FAILURE REASON : </b>{{image.errorMessage}}</div>
                        <div *ngIf="app.sharedData.visibility.serviceTaskBuild.viewInternalClient" style="margin-top:10px;">
                            <div id="stInternalClient" class="ui-g-2" style="padding: 0;"><b>INTERNAL CLIENT: </b></div>
                            <div class="ui-g-4" *ngIf="!app.sharedData.visibility.serviceTaskBuild.updateInternalClient || stMain.serviceTasksDetails.abandoned == true" style="padding: 0;">
                                {{image.detail?.internalClient}}
                            </div>
                            <div class="ui-g-4" *ngIf="app.sharedData.visibility.serviceTaskBuild.updateInternalClient && stMain.serviceTasksDetails.abandoned == false" style="padding: 0;">
                                <p-dropdown id="stInternalClientOptions" [options]="image.internalClients" [(ngModel)]="image.internalClientSelected" (click)="onInternalClientChange(image)" (keyup.enter)="onInternalClientChange(image)" optionLabel="client"></p-dropdown>
                            </div>
                            <div class="ui-g-1" *ngIf="app.sharedData.visibility.serviceTaskBuild.updateInternalClient && stMain.serviceTasksDetails.abandoned == false && image.internalClientSelected" style="padding: 0;">
                                <i id="removeinternalClientButton"  class="ui-icon-cancel" pTooltip="Remove Internal Client" 
                                (click)="onRemoveInternalClientClick(image)" style="cursor: pointer; font-size: 1.8rem"></i>
                            </div>
                            <div>
                                <p-progressSpinner *ngIf="image.updateInternalClientInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                            </div>
                        </div>
                    </div>
                    <div class="ui-g-3">
                        <div class="ui-g-12">
                            <i class="ui-icon-refresh" *ngIf="!(image.loadingChangeRequests > 0 || image.loadingChangeRequestsCurrentInfo > 0 || image.loadingInternalClients || image.loadingHistory || image.retryCirrusBuildInProgress)" id="refreshStBuildDetails" pTooltip="Refresh" (click)="onBuildDetailRefresh(image)" style="margin-top: -10px;float: right;"></i>
                            <p-progressSpinner *ngIf="image.loadingChangeRequests > 0 || image.loadingChangeRequestsCurrentInfo > 0 || image.loadingInternalClients || image.loadingHistory || image.retryCirrusBuildInProgress || image.recoverCirrusSeedBuildInProgress" [style]="{width: '20px', height: '20px', margin: '-10px 0 0 0', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                            <i class="ui-icon-email" *ngIf="app.sharedData.visibility.serviceTaskBuild.emailCR
                                                            && stMain.serviceTasksDetails.abandoned === false 
                                                            && !(image.loadingChangeRequests > 0 || image.loadingChangeRequestsCurrentInfo > 0)" 
                                                            pTooltip="Email" (click)="selectEmailCRs(image)" style="margin-top: -10px;float: right;"></i>
                            <i class="pi pi-upload" id="stImageFileUpload"  style="margin-top: -10px;float: right;" pTooltip="File Upload" *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskBuild.localFileUpload && image?.detail?.perforceDetails!== null && image.detail.perforceDetails?.path !== undefined" (click)="onLocalFileUploadClicked(image,undefined,UploadSystemType.CIRRUS); onRemoteAutoCommitClicked(undefined,image)" ></i>
                            <!--i class="pi pi-cloud-upload" id="stImageFileUploadProgress"  style="margin-top: -10px;float: right;font-size: 1.5rem;" pTooltip="Upload Progress" *ngIf="stMain.serviceTasksDetails.abandoned == false && image.buildSystem !== 'BAIT' && app.sharedData.visibility.serviceTaskBuild.localFileUpload && image.detail.perforceDetails?.path !== undefined && localFileUploadProgress.uploadInProgress" (click)="showLocalFileUploadProgress()" ></i-->
                        </div>
                        <div class="ui-g-12" style="padding-left: 0;">
                            <!--Disable Auto Ship Temporarly-->
                            <button *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerProduct && stMain.serviceTasksDetails.abandoned == false
                                            && !loadingProductDistros"
                                    pButton id="triggerSI&AutoShip" type="button" (click)="onTriggerSIClicked(image)" label="{{stMain.serviceTasksDetails.baseBuild === null? 'Trigger SI' : 'Trigger SI & Auto Ship'}}"
                                    style="width: 165px;float: right;"></button>

                            <!--button *ngIf="app.sharedData.visibility.serviceTaskBuild.triggerProduct && stMain.serviceTasksDetails.abandoned == false" pButton type="button" (click)="buildImage(image)" label="Trigger SI"
                            style="width: 165px;float: right;"></button-->
                        </div>
                    </div>
                </div>
                <!--System Changes Section: Not in Use-->
                <!-- <div *ngIf="image.buildSystem === 'BAIT'">                    
                    <p-accordion [multiple]="true" (onOpen)="loadBaitSystemChanges(image)">
                        <p-accordionTab header="System Changes" [selected]=false>
                            <button pButton id="baitSystemTriggerBuild" type="button" label="Build" (click)="triggerSystemBuildProcess.displayForm = true; triggerSystemBuildProcess.image = image" style="width: 70px; margin-bottom: 5px; float: right;"></button>
                            <br>
                            <p-table id="changeRequestTable" #crTable [value]="image.systemChangesCommits" [paginator]="true" [rows]="8">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th>Commit</th>
                                        <th>Submitted By</th>
                                        <th>Submitted On</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td id="noDataFoundMsg" colspan="3">No data found.</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowData let-columns="columns">
                                    <tr>
                                        <td>{{rowData.sha}}</td>
                                        <td>{{rowData.author_name}}</td>
                                        <td>{{rowData.committed_date}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </p-accordionTab>
                    </p-accordion>
                </div> -->
                
                <!--Local Upload details will show in HLOS Changes section-->
                <div *ngIf="image.buildSystem === 'BAIT'">  
                    <p-accordion [multiple]="true" (onOpen)="loadBaitHlosChanges(image)">
                        <p-accordionTab header="HLOS Changes" [selected]=false>
                            <button pButton id="baitSystemTriggerBuildForLocalFileUpload" type="button" label="Trigger APPS CRM Build" (click)="triggerSystemBuildProcess.displayForm = true; triggerSystemBuildProcess.image = image" style="width: 215px; margin-bottom: 5px; float: right;"
                                [disabled] = "!(!image.baitBuilds || image.baitBuilds?.length === 0 || 
                                            (!image.baitBuilds[0]?.baitJob?.crm_build_info?.status 
                                            || image.baitBuilds[0]?.baitJob?.crm_build_info?.status.toLowerCase() === 'built' 
                                            || image.baitBuilds[0]?.baitJob?.crm_build_info?.status.toLowerCase() === 'failed') 
                                            && 
                                            (!image.baitBuilds[0]?.baitJob?.bait_build_info?.status 
                                            || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'complete'
                                            || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'retry'
                                            || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'aborted'
                                            || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'overwritten'
                                            || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'failed'))">
                            </button>
                            <span *ngIf="!(!image.baitBuilds || image.baitBuilds?.length === 0 || 
                                        (!image.baitBuilds[0]?.baitJob?.crm_build_info?.status 
                                        || image.baitBuilds[0]?.baitJob?.crm_build_info?.status.toLowerCase() === 'built' 
                                        || image.baitBuilds[0]?.baitJob?.crm_build_info?.status.toLowerCase() === 'failed') 
                                        && 
                                        (!image.baitBuilds[0]?.baitJob?.bait_build_info?.status 
                                        || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'complete'
                                        || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'retry'
                                        || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'aborted'
                                        || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'overwritten'
                                        || image.baitBuilds[0]?.baitJob?.bait_build_info?.status.toLowerCase() === 'failed'))" >
                                <i class="pi pi-info-circle"
                                    (mouseenter)="app.toggleOverlay($event,'Trigger Build disabled because latest BAIT / CRM Job is running.')"
                                    (mouseout)="app.toggleOverlay($event,'')" style="float: right; cursor: pointer; font-size: 1.5rem;">
                                </i>
                            </span>

                            <br>
                            <p-treeTable [value]="image.hlosChangesCommitsFiles"  [paginator]="true" [rows]="8">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="width:30px"></th>
                                        <th>Commit</th>
                                        <th>Submitted By</th>
                                        <th ttSortableColumn="committed_date">
                                            Submitted On
                                            <p-treeTableSortIcon field="committed_date"></p-treeTableSortIcon>
                                        </th>
                                    </tr>
                                </ng-template><ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td id="noDataFoundMsg" colspan="4">No data found.</td>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                                    <tr *ngIf="rowData.type==='COMMIT'" [ttRow]="rowNode">
                                        <td><p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler></td>
                                        <td>
                                            {{rowData.sha}}
                                        </td>
                                        <td>{{rowData.author_name}}</td>
                                        <td>{{rowData.committed_date}}</td>
                                    </tr>
                                    <tr *ngIf="rowData.type==='ACTION'" [ttRow]="rowNode">
                                        <td></td>
                                        <td colspan="3">
                                            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                                            {{rowData.action}}
                                            <!--p-accordion *ngFor="let file of rowData" [multiple]="true">
                                                <p-accordionTab id="addedImagesTab" header={{rowNode.label}}>
                                                </p-accordionTab>
                                            </p-accordion-->
                                        </td>
                                    </tr>
                                    <tr *ngIf="rowData.type==='FILES'">
                                        <td></td>
                                        <td colspan="3">
                                            <span style="margin-left: 50px;" *ngFor="let file of rowData.files">{{file}}<br></span>
                                            
                                            <!--p-accordion *ngFor="let file of rowData" [multiple]="true">
                                                <p-accordionTab id="addedImagesTab" header={{rowNode.label}}>
                                                </p-accordionTab>
                                            </p-accordion-->
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-treeTable>
                        </p-accordionTab>
                    </p-accordion>
                </div>
                <!-- CR Table For All Except PassThrough Type buildSystem-->
                <div *ngIf="app.sharedData.visibility.serviceTaskBuild.additionalCrInfo && image.buildSystem !=='PassThrough'">
                    <h2 id="changeRequestHeader">Change Requests:</h2>
                    <p-table id="changeRequestTable" #crTable [columns]="crCols" [value]="image.changeRequests" [loading]="image.loadingChangeRequests > 0 && image.loadingChangeRequestsCurrentInfo > 0" [paginator]="true" [rows]="8"
                            [globalFilterFields]="['changeRequestId', 'detail.title', 'currentInfo.status', 'currentInfo.assignee','changeRequestDate']">
                        <ng-template pTemplate="caption">
                            <div style="text-align: right">
                                <i id="searchFilterCr" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input #crGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                                  (input)="crTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                                <i class="ui-icon-cancel" id="clearFilterCr" pTooltip="Clear Filter" (click)="onFilterClear()"
                                  style="margin:4px 4px 0 0"></i>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="7">No data found.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData" *ngFor="let participant of rowData.detail?.participants; let i = index">
                                <td id="crLink" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" >
                                    <span *ngIf="app.sharedData.appInfo.isElectronMode">
                                        <a  (click)="app.goToUrl('https://orbit/cr/'+rowData.changeRequestId)" 
                                            style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word">
                                            {{rowData.changeRequestId}}
                                        </a>
                                    </span>
                                    <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                                        <a href="{{'https://orbit/cr/'+rowData.changeRequestId}}" target="_blank"
                                            style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word">
                                            {{rowData.changeRequestId}}
                                        </a>
                                    </span>
                                </td>
                                <td id="crTitle" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                                    {{rowData.detail.title}}
                                </td>
                                <td id="crArea" style="word-wrap: break-word">
                                    <p *ngIf="participant.primary" style="float: left;">
                                        <img src="assets/images/primary.png" style="width:17px; height:17px;" pTooltip="Primary">
                                    </p>
                                    <p style="padding-top: 15px;">
                                        <span>{{participant.area}}</span>
                                    </p>
                                </td>
                                <td id="crSubSystem" style="word-wrap: break-word">{{participant.subsystem}}</td>
                                <td id="crFunctionality" style="word-wrap: break-word">{{participant.functionality}}</td>
                                <td id="crStatus" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                                    {{rowData.currentInfo?.status}}
                                </td>
                                <td id="crAssignee" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                                    {{(rowData.currentInfo?.assignee === '' || rowData.currentInfo?.assignee === 'stardustsvc')? rowData.detail.assigneeUid : rowData.currentInfo?.assignee}}
                                </td>
                                <td id="crRequestedDate" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                                    {{rowData.changeRequestDate}}
                                </td>
                                <td id="crComments" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length">
                                    <span *ngFor="let comment of rowData.comments | slice:0:3" style="width: 100%; display: inline-block; max-height: 45px; overflow:hidden">
                                    <b>{{comment.userId}}</b> : {{comment.description}}
                                    </span>
                                </td>
                                <td id="crActions" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length">
                                    <div class="action-icon" *ngIf="i===0">
                                        <i class="ui-icon-plus" id="stImageAddComents" pTooltip="Add Comment" *ngIf="stMain.serviceTasksDetails.abandoned == false" (click)="stMain.onShowAddCommentDialog(rowData.changeRequestId, image.baseImage, rowData)"></i>
                                    </div>
                                    <div class="action-icon" *ngIf="(rowData.comments !== undefined && rowData.comments?.length > 0 && i===0)">
                                        <i class="pi pi-comments" id="stImageViewAllCRComments" style="font-size: 24px" pTooltip="View All Comments"(click)="stMain.onShowAllCommentsDialog(rowData.changeRequestId, image.baseImage, rowData)"></i>
                                    </div>
                                    <div class="action-icon" *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskBuild.autoPropagateCR && ( rowData.currentInfo?.status==='Open' || rowData.currentInfo?.status==='FixInProgress'||  rowData.currentInfo?.status===undefined)&& i===0 && image.buildSystem !== 'BAIT'">
                                        <i class="pi pi-sign-in" id="stImageAutoPropogate" style="font-size: 24px" pTooltip="Auto Propogate" (click)="onAutoPropogateClicked(rowData,image)" ></i>
                                    </div>
                                    <div class="action-icon" *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskBuild.localFileUpload && ( rowData.currentInfo?.status==='Open' ||  rowData.currentInfo?.status===undefined)&& i===0 && (image.buildSystem === 'BAIT' || (image?.detail?.perforceDetails!== null && image.detail.perforceDetails?.path !== undefined))">
                                        <i class="pi pi-upload" id="stImageFileUpload" style="font-size: 24px" pTooltip="File Upload" (click)="onLocalFileUploadClicked(image,rowData,UploadSystemType.CIRRUS); onRemoteAutoCommitClicked(rowData,image)" ></i>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div *ngIf="!app.sharedData.visibility.serviceTaskBuild.additionalCrInfo && image.buildSystem !=='PassThrough'">
                    <h2 id="changeRequestHeader">Change Requests:</h2>
                    <p-table id="changeRequestTable" #crTable [columns]="crCols" [value]="image.changeRequests" [loading]="image.loadingChangeRequests > 0 && image.loadingChangeRequestsCurrentInfo > 0" [paginator]="true" [rows]="8"
                            [globalFilterFields]="['changeRequestId', 'detail.title', 'currentInfo.status']">
                        <ng-template pTemplate="caption">
                            <div style="text-align: right">
                                <i id="searchFilterCr" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input #crGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                                    (input)="crTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                                <i class="ui-icon-cancel" id="clearFilterCr" pTooltip="Clear Filter" (click)="onFilterClear()"
                                    style="margin:4px 4px 0 0"></i>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="3">No data found.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <td id="crLink">
                                    {{rowData.changeRequestId}}
                                </td>
                                <td id="crTitle">
                                    {{rowData.detail.title}}
                                </td>
                                <td id="crStatus">{{rowData.currentInfo?.status}}</td>
                                <td id="crRequestedDate" style="word-wrap: break-word">
                                    {{rowData.requestedDate}}
                                </td>
                                <td id="crComments">
                                    <span *ngFor="let comment of rowData.comments | slice:0:3" style="width: 100%; display: inline-block; max-height: 45px; overflow:hidden">
                                    <b>{{comment.userId}}</b> : {{comment.description}}
                                    </span>
                                </td>
                                <td id="actionIcon">
                                    <div class="action-icon">
                                        <i class="ui-icon-plus" id="stImageAddComments" pTooltip="Add Comment" *ngIf="stMain.serviceTasksDetails.abandoned == false" (click)="stMain.onShowAddCommentDialog(rowData.changeRequestId, image.baseImage, rowData)"></i>
                                        <i class="pi pi-comments" id="stImageViewAllComments" style="font-size: 24px" pTooltip="View All Comments" *ngIf="(rowData.comments !== undefined && rowData.comments?.length > 0)" (click)="stMain.onShowAllCommentsDialog(rowData.changeRequestId, image.baseImage, rowData)"></i>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <!-- Gerrit Table-->
                <div  *ngIf="image.buildSystem === 'BAIT'">
                    <h2 id="gerritHeader">Gerrits:</h2>
                    <p-table id="gerritTable" #gerritTable [columns]="gerritCols" [value]="image.gerrits" [paginator]="true" [rows]="5"
                            [globalFilterFields]="['gerritId', 'type','requestedDate']">
                        <ng-template pTemplate="caption">
                            <div style="text-align: right">
                                <i id="searchFilterGerrit" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input #gerritGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                                    (input)="gerritTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                                <i class="ui-icon-cancel" id="clearFilterCr" pTooltip="Clear Filter" (click)="onFilterClearGerrit()"
                                    style="margin:4px 4px 0 0"></i>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                    {{col.header}}
                                    <p-sortIcon [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="4">No data found.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData">
                                <td id="gerritLink">
                                    {{rowData.gerritId}}
                                </td>
                                <td id="gerritTitle">
                                    {{rowData.type}}
                                </td>
                                <td id="gerritRequestedDate">
                                    {{rowData.requestedDate}}
                                </td>
                                <td id="gerritComments">
                                    <span *ngFor="let comment of rowData.comments | slice:0:3" style="width: 100%; display: inline-block; max-height: 45px; overflow:hidden">
                                    <b>{{comment.userId}}</b> : {{comment.description}}
                                    </span>
                                </td>
                                <td id="actionIconGerrit">
                                    <div class="action-icon">
                                        <i class="ui-icon-plus" id="stImageAddComments" pTooltip="Add Comment" *ngIf="stMain.serviceTasksDetails.abandoned == false" (click)="stMain.onShowAddCommentDialog(rowData.gerritId, image.baseImage, rowData, true)"></i>
                                        <i class="pi pi-comments" id="stImageViewAllComments" style="font-size: 24px" pTooltip="View All Comments" *ngIf="(rowData.comments !== undefined && rowData.comments?.length > 0)" (click)="stMain.onShowAllCommentsDialog(rowData.gerritId, image.baseImage, rowData, true)"></i>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <!--Bait (Build History) Table-->
                <div *ngIf="image.buildSystem === 'BAIT'">
                    <h2 id="historyTableHeader">Build History:</h2>
                        <p-dataView #baitJobHistory [value]="image.baitBuilds" [paginator]="true" [rows]="4" filterBy="baitJob.overall_status" layout="list" [style]="{'width':'100%'}"
                            emptyMessage="No jobs found. Please click 'Trigger Bait Build' to start a job.">
                            <p-header>
                                <div class="ui-helper-clearfix">
                                    <div class="ui-g">
                                        <div class="ui-g-12 ui-md-6">
                                            <div style="position:relative">
                                                <input type="search" pInputText placeholder="Search by status" (keyup)="baitJobHistory.filter($event.target.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-header>
                            <ng-template let-job pTemplate="listItem">
                                <div class="ui-g" style="padding: 2em;border-bottom: 1px solid #d9d9d9">
                                    <div class="li-job ui-g-10">
                                        <h2 style="font-weight: bold; margin-top: 0;"></h2>
                                        <div class="ui-g-12">                                        
                                            <div class="ui-g-12">
                                                <div style="display: inline-block;" *ngIf="job.isLocalfileuploadBuild!==true">
                                                    <b>Job #</b>
                                                    <span *ngIf="!app.sharedData.visibility.serviceTaskBuild.baitJobInfo || job.baitJob?.bait_build_info?.job_link === null">
                                                        {{job.build_id}}
                                                    </span>
                                                    <span *ngIf="app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTaskBuild.baitJobInfo
                                                                    && job.baitJob?.bait_build_info?.job_link !== null">
                                                        <a (click)="app.goToUrl(job.baitJob?.bait_build_info?.job_link)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                            {{job.build_id}}
                                                        </a>
                                                    </span>
                                                    <span *ngIf="!app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTaskBuild.baitJobInfo
                                                                    && job.baitJob?.bait_build_info?.job_link !== null">
                                                        <a href="{{job.baitJob?.bait_build_info?.job_link}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                            {{job.build_id}}
                                                        </a>
                                                    </span>
                                                </div>
                                                <div style="display: inline-block;" *ngIf="job.isLocalfileuploadBuild===true">
                                                    <b>Job #</b>
                                                    <span *ngIf="!app.sharedData.visibility.serviceTaskBuild.baitJobInfo || job.baitJob?.crm_build_info?.job_link === null">
                                                        {{job.build_id}}
                                                    </span>
                                                    <span *ngIf="app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTaskBuild.baitJobInfo
                                                                    && job.baitJob?.crm_build_info?.job_link !== null">
                                                        <a (click)="app.goToUrl(job.baitJob?.crm_build_info?.job_link)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                            {{job.build_id}}
                                                        </a>
                                                    </span>
                                                    <span *ngIf="!app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTaskBuild.baitJobInfo
                                                                    && job.baitJob?.crm_build_info?.job_link !== null">
                                                        <a href="{{job.baitJob?.crm_build_info?.job_link}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                            {{job.build_id}}
                                                        </a>
                                                    </span>
                                                </div>






                                                <!-- <div class="li-job-status" style="display: inline-block;">
                                                    <div class="li-job-status tile" *ngIf="job.baitJob?.overall_status === 'Complete'" style="background-color: limegreen;">{{job.baitJob?.overall_status}}</div>
                                                    <div class="li-job-status tile" *ngIf="job.baitJob?.overall_status === 'In-Progress' || job.baitJob?.overall_status === 'Started'" style="background-color:chocolate;">{{job.baitJob?.overall_status}}</div>
                                                    <div class="li-job-status tile" *ngIf="job.baitJob?.overall_status === 'Aborted' || job.baitJob?.overall_status === 'Failed'" 
                                                                                            style="background-color:#e53a3a;">{{job.baitJob?.overall_status}}</div>
                                                    <div class="li-job-status tile" 
                                                            *ngIf="!(job.baitJob?.overall_status === 'Complete' 
                                                                    ||job.baitJob?.overall_status === 'In-Progress' || job.baitJob?.overall_status === 'Started' 
                                                                    || job.baitJob?.overall_status === 'Aborted' || job.baitJob?.overall_status === 'Failed')" 
                                                            style="background-color:slategray">{{job.baitJob?.overall_status}}</div>
                                                </div> -->






                                                <!--i class="ui-icon-remove-red-eye" *ngIf="job.baitJob?.overall_status === 'In-Progress'" pTooltip="Show Details" style="margin-right: 10px;"></i-->
                                            </div>
                                        </div>
                                        <div class="ui-g-12" style="margin-top: 2px; margin-bottom: 2px;">
                                            <div class="ui-g-3"><b>CRM Build Status</b></div>
                                            <!-- <div class="ui-g-9">: {{job.baitJob?.crm_build_info?.status}}</div> -->
                                            
                                            <div class="li-job-status" style="display: inline-block;" *ngIf="job.baitJob?.crm_build_info?.status">
                                                <div class="li-job-status tile" *ngIf="job.baitJob?.crm_build_info?.status === 'Complete'" style="background-color: limegreen;">{{job.baitJob?.crm_build_status?.status}}</div>
                                                <div class="li-job-status tile" *ngIf="job.baitJob?.crm_build_info?.status === 'In-Progress' || job.baitJob?.crm_build_info?.status === 'Started'" style="background-color:chocolate;">{{job.baitJob?.crm_build_info?.status}}</div>
                                                <div class="li-job-status tile" *ngIf="job.baitJob?.crm_build_info?.status === 'Aborted' || job.baitJob?.crm_build_info?.status === 'Failed'" 
                                                                                        style="background-color:#e53a3a;">{{job.baitJob?.crm_build_info?.status}}</div>
                                                <div class="li-job-status tile" 
                                                        *ngIf="!(job.baitJob?.crm_build_info?.status === 'Complete' 
                                                                ||job.baitJob?.crm_build_info?.status === 'In-Progress' || job.baitJob?.crm_build_info?.status === 'Started' 
                                                                || job.baitJob?.crm_build_info?.status === 'Aborted' || job.baitJob?.crm_build_info?.status === 'Failed')" 
                                                        style="background-color:slategray">{{job.baitJob?.crm_build_info?.status}}</div>
                                            </div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.baitJob?.crm_build_info?.message">
                                            <div class="ui-g-3"><b>CRM Build Error</b></div>
                                            <div class="ui-g-9"*ngIf="job.baitJob?.crm_build_info?.message">: <span style="color: red;">{{job.baitJob?.crm_build_info?.message}}</span></div>
                                        </div>
                                        <div class="ui-g-12" *ngIf= "job.isLocalfileuploadBuild!==true" style="margin-top: 2px; margin-bottom: 2px;">
                                            <div class="ui-g-3"><b>BAIT Build Status</b></div>
                                            <!-- <div class="ui-g-9">: {{job.baitJob?.bait_build_info?.status}}</div> -->
                                            <div class="li-job-status" style="display: inline-block;" *ngIf="job.baitJob?.bait_build_info?.status">
                                                <div class="li-job-status tile" *ngIf="job.baitJob?.bait_build_info?.status === 'Complete'" style="background-color: limegreen;">{{job.baitJob?.bait_build_info?.status}}</div>
                                                <div class="li-job-status tile" *ngIf="job.baitJob?.bait_build_info?.status === 'In-Progress' || job.baitJob?.bait_build_info?.status === 'Started'" style="background-color:chocolate;">{{job.baitJob?.bait_build_info?.status}}</div>
                                                <div class="li-job-status tile" *ngIf="job.baitJob?.bait_build_info?.status === 'Aborted' || job.baitJob?.bait_build_info?.status === 'Failed'" 
                                                                                        style="background-color:#e53a3a;">{{job.baitJob?.bait_build_info?.status}}</div>
                                                <div class="li-job-status tile" 
                                                        *ngIf="!(job.baitJob?.bait_build_info?.status === 'Complete' 
                                                                ||job.baitJob?.bait_build_info?.status === 'In-Progress' || job.baitJob?.bait_build_info?.status === 'Started' 
                                                                || job.baitJob?.bait_build_info?.status === 'Aborted' || job.baitJob?.bait_build_info?.status === 'Failed')" 
                                                        style="background-color:slategray">{{job.baitJob?.bait_build_info?.status}}</div>
                                            </div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.baitJob?.bait_build_info?.message && job.baitJob?.bait_build_info?.message.toLowerCase() !== 'unknown' &&  job.baitJob?.bait_build_info?.message.toLowerCase() !== 'null' &&  job.baitJob?.bait_build_info?.message !== null">
                                            <div class="ui-g-3"><b>BAIT Build Message</b></div>
                                            <div class="ui-g-9"*ngIf="job.baitJob?.bait_build_info?.message && job.baitJob?.bait_build_info?.message.toLowerCase() !== 'unknown' && job.baitJob?.bait_build_info?.message.toLowerCase() !== 'null' &&  job.baitJob?.bait_build_info?.message !== null">: <span style="color: red;word-wrap: break-word;">{{job.baitJob?.bait_build_info?.message}}</span></div>
                                        </div>
                                        <div class="ui-g-12">
                                            <div class="ui-g-3"><b>Submitted At</b></div>
                                            <div class="ui-g-9"*ngIf="job.isLocalfileuploadBuild!==true">: {{job.baitJob?.bait_build_info.build_start_time}}</div>
                                            <div class="ui-g-9"*ngIf="job.isLocalfileuploadBuild===true">: {{job.baitJob?.crm_build_info.build_start_time}}</div>
                                        </div>
                                        <div class="ui-g-12">
                                            <div class="ui-g-3"><b>Completed At</b></div>
                                            <div class="ui-g-9" *ngIf="job.isLocalfileuploadBuild!==true">: {{job.baitJob?.bait_build_info.build_completed_time}}</div>
                                            <div class="ui-g-9" *ngIf="job.isLocalfileuploadBuild===true">: {{job.baitJob?.crm_build_info.build_completed_time}}</div>
                                        </div>
                                        <div class="ui-g-12">
                                            <div class="ui-g-3"><b>Requested by</b></div>
                                            <div class="ui-g-9">: {{job.baitJob?.user}}</div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.baitJob?.crm_build_info !== null">
                                            <div class="ui-g-3"><b>Linux Build ID</b></div>
                                            <div class="ui-g-9">:                                             
                                                <span *ngIf="!app.sharedData.visibility.serviceTaskBuild.baitJobInfo">
                                                    {{job.baitJob?.crm_build_info?.image_build}}
                                                </span>
                                                <span *ngIf="app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTaskBuild.baitJobInfo">
                                                    <a (click)="app.goToUrl(job.baitJob?.crm_build_info?.job_link)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                        {{job.baitJob?.crm_build_info?.image_build}}
                                                    </a>
                                                </span>
                                                <span *ngIf="!app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTaskBuild.baitJobInfo">
                                                    <a href="{{job.baitJob?.crm_build_info?.job_link}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                        {{job.baitJob?.crm_build_info?.image_build}}
                                                    </a>
                                                </span>
                                                <i class="ui-icon-remove-red-eye" *ngIf="app.sharedData.visibility.serviceTaskBuild.baitJobInfo"
                                                    (click)="showCrmBuildDetail(job)" pTooltip="Show Details" style="margin-right: 10px;"></i>
                                            </div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.isLocalfileuploadBuild!==true">
                                            <div class="ui-g-3"><b>Linux Base Build ID</b></div>
                                            <div class="ui-g-9">: {{job.baitJob?.base_image_build}}</div>
                                        </div>
                                        <!--div class="ui-g-12">
                                            <div class="ui-g-3"><b>Product Base Build ID</b></div>
                                            <div class="ui-g-9">: {{job.baitJob?.product_build_id}}</div>
                                        </div-->
                                        <div class="ui-g-12" *ngIf="job.isLocalfileuploadBuild!==true">
                                            <div class="ui-g-3"><b>CRs</b></div>
                                            <div class="ui-g-9">: 
                                                <span *ngIf="job.baitJob?.change_requests === null || job.baitJob?.change_requests?.length === 0">
                                                    None
                                                </span>
                                                <span *ngIf="job.baitJob?.change_requests?.length > 0">
                                                    {{job.baitJob?.change_requests.join(", ")}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.isLocalfileuploadBuild!==true">
                                            <div class="ui-g-3"><b>Gerrits</b></div>
                                            <div class="ui-g-9">: 
                                                <span *ngIf="job.baitJob?.gerrits === null || job.baitJob?.gerrits?.length === 0">
                                                    None
                                                </span>
                                                <span *ngIf="job.baitJob?.gerrits?.length > 0">
                                                    {{job.baitJob?.gerrits.join(", ")}}
                                                </span>
                                                
                                            </div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.isLocalfileuploadBuild!==true">
                                            <div class="ui-g-3"><b>JIRA</b></div>
                                            <div class="ui-g-9">:                                            
                                                <span *ngIf="job.baitJob?.bait_build_info?.lost_jira === null">
                                                    Unknown
                                                </span>
                                                <span *ngIf="job.baitJob?.bait_build_info?.lost_jira !== null">
                                                   <a *ngIf="app.sharedData.appInfo.isElectronMode" (click)="app.goToUrl('https://jira-dc.qualcomm.com/jira/browse/'+job.baitJob?.bait_build_info?.lost_jira)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">{{job.baitJob?.bait_build_info?.lost_jira}}</a>
                                                   <a *ngIf="!app.sharedData.appInfo.isElectronMode" href="{{'https://jira-dc.qualcomm.com/jira/browse/'+ job.baitJob?.bait_build_info?.lost_jira}}" target="_blank" style="cursor: pointer;text-decoration: underline; color: blue; display: inline-block;">{{job.baitJob?.bait_build_info?.lost_jira}}</a>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.isLocalfileuploadBuild!==true">
                                            <div class="ui-g-3"><b>JIRA status</b></div>
                                            <div class="ui-g-9">:                                                                                     
                                                <span *ngIf="job.baitJob?.bait_build_info?.lost_jira_status === null">
                                                    Unknown
                                                </span>
                                                <span *ngIf="job.baitJob?.bait_build_info?.lost_jira_status !== null">
                                                    {{job.baitJob?.bait_build_info?.lost_jira_status}}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="ui-g-12" *ngIf="job.isLocalfileuploadBuild!==true">
                                            <div class="ui-g-3"><b>OSS Build</b></div>
                                            <div class="ui-g-9">: 
                                                <span *ngIf="job.baitJob?.oss_only === null">
                                                    Unknown
                                                </span>                                                                                   
                                                <span *ngIf="job.baitJob?.oss_only === true">Yes</span>
                                                <span *ngIf="job.baitJob?.oss_only === false">No</span>                                                
                                            </div>
                                        </div>
                                        <div *ngIf="job.isLocalfileuploadBuild!==true" style="margin: 0;padding: 0;">
                                            <div class="ui-g-12" *ngIf="job.baitJob?.bait_build_info?.patch_locations?.proprietary_bin_location !== null && app.sharedData.visibility.serviceTaskBuild.baitJobInfo">
                                                <div class="ui-g-3"><b>Binaries</b></div>
                                                <div class="ui-g-9">
                                                    <span style="cursor: pointer; color: blue; text-decoration: underline"
                                                    *ngIf="job.baitJob?.bait_build_info?.patch_locations?.proprietary_bin_location !== null"
                                                    (click)="app.onOpenFolderClick(job.baitJob?.bait_build_info?.patch_locations?.proprietary_bin_location)">
                                                        :{{job.baitJob?.bait_build_info?.patch_locations?.proprietary_bin_location}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="ui-g-12" *ngIf="job.baitJob?.bait_build_info?.caf_links !== null || job.baitJob?.oss !== null" style="padding-top: .3rem;">
                                                <div class="ui-g-3"><b>OSS Link</b></div>
                                                <div class="ui-g-9">
                                                    <div *ngIf="job.baitJob?.bait_build_info?.caf_links !== null">
                                                        <span *ngIf="app.sharedData.appInfo.isElectronMode">:
                                                            <a (click)="app.goToUrl(job.baitJob?.bait_build_info?.caf_links)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                                :{{job.baitJob?.bait_build_info?.caf_links}}
                                                            </a>
                                                        </span>
                                                        <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                                                            <a href="{{job.baitJob?.bait_build_info?.caf_links}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                                                :{{job.baitJob?.bait_build_info?.caf_links}}
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div *ngIf="job.baitJob?.oss !== null">
                                                        <span *ngIf="app.sharedData.appInfo.isElectronMode">
                                                            <a *ngIf="job.baitJob?.oss?.includes('http')===true" (click)="app.goToUrl(job.baitJob?.oss)" style="cursor: pointer; color: blue; text-decoration: underline">
                                                                :{{job.baitJob?.oss}}
                                                            </a>
                                                            <span *ngIf="job.baitJob?.oss?.includes('http')===false"> :{{job.baitJob?.oss}}</span>
                                                         </span>
                                                         <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                                                            <a *ngIf="job.baitJob?.oss?.includes('http')===true" href="{{job.baitJob?.oss}}" target="_blank" style="cursor: pointer; color: blue; text-decoration: underline">
                                                               :{{job.baitJob?.oss}}
                                                            </a>
                                                            <span *ngIf="job.baitJob?.oss?.includes('http')===false"> :{{job.baitJob?.oss}}</span>
                                                         </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ui-g-2" *ngIf="job.baitJob?.overall_status !== 'Complete' 
                                                            && job.baitJob?.overall_status !== 'Failed'
                                                            && job.baitJob?.overall_status !== 'Aborted'">
                                        <button pButton type="button" label="Abort" (click)="onAbortBaitBuild(image, job)" style="width:100px; float: right;"></button>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dataView>
                </div>
                <!--Cirrus Build History Table-->
                <div *ngIf="image.buildSystem !== 'BAIT' && image.buildSystem !=='PassThrough'">
                    <h2 id="historyTableHeader">History:</h2>
                    <div style="float: right;padding-bottom: 10px;margin-top: -30px;cursor: pointer;">
                        <p-selectButton [options]="historyOptions" [(ngModel)]="image.buildHistoryOption" optionLabel="label" optionValue="value"
                            (onChange)="loadServiceTaskBuildDetails(image,true)" [style]="{'width': '130px'}"></p-selectButton>
                    </div>
                    <p-table #dt *ngIf="image.detail !==undefined" [columns]="historyCols" [loading]="image.loadingHistory"
                        [value]="image.detail.history" [paginator]="true" [rows]="20" dataKey="build_id">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" id="{{col.field}}" [ngStyle]="col.style">
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="4">No data found.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                                <td id="crActionHistory">
                                    {{rowData.actionInfo}}
                                    <div *ngFor="let action of rowData.actions">
                                        <span>{{action.actionInfo}}</span>
                                        <span *ngIf="!app.sharedData.visibility.serviceTask.showCrLink">
                                            <span *ngFor="let url of action.actionUrls" style="word-wrap: break-word; padding-right: 3px;">
                                                {{url.label}} 
                                            </span>
                                        </span>
                                        <span *ngIf="app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                                            <a *ngFor="let url of action.actionUrls" (click)="app.goToUrl(url.link)" style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word; padding-right: 3px;">
                                                {{url.label}} 
                                            </a>
                                        </span>
                                        <span *ngIf="!app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                                            <a *ngFor="let url of action.actionUrls" href="{{url.link}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word; padding-right: 3px;">
                                                {{url.label}} 
                                            </a>
                                        </span>
                                    </div>
                                </td>
                                <!--td><a (click)="app.goToUrl(rowData.build_url)">{{rowData.cirrus_build_id}}</a></td-->
                                <td id="crRActionDateHistroy">{{rowData.date}}</td>
                                <td id="crUserHistory" *ngIf="app.sharedData.visibility.serviceTaskBuild.additionalHistoryInfo">{{rowData.user}}</td>
                                <td id="crStatusHistory">
                                    <div *ngIf="!rowData.status?.includes('Failure')" style="display: inline-block;vertical-align: super;">{{rowData.status}}</div>
                                    <div *ngIf="rowData.status?.includes('Failure')" style="display: inline-block;vertical-align: super; color: red;">{{rowData.status}}</div>
                                    <div class="action-icon">
                                        <i *ngIf="app.sharedData.visibility.serviceTaskBuild.buildHistoryInfo && rowData.type === 'BUILD' && !rowData.status?.includes('Failure')" 
                                        class="ui-icon-remove-red-eye" id="stPreviewBuildReqDetails" pTooltip="Preview" (click)="showBuildRequestDetail(rowData.build)">
                                        </i>
                                        <i *ngIf="app.sharedData.visibility.serviceTaskBuild.buildHistoryInfo && rowData.type === 'BUILD' && rowData.status?.includes('Failure')" 
                                        class="pi pi-exclamation-triangle" id="stPreviewBuildReqDetails" pTooltip="Preview" (click)="showBuildRequestDetail(rowData.build)" style="color: red; font-size: 1.3rem;">
                                        </i>
                                    </div>
                                </td>
                                <td id="crStatusComments" *ngIf="app.sharedData.visibility.serviceTaskBuild.additionalHistoryInfo">{{rowData.comments}}</td>
                                <!--td id="crUserAction">
                                    <div class="action-icon"  *ngIf="app.sharedData.visibility.software.showSoftwareImageDownload">
                                        <i *ngIf="app.sharedData.visibility.serviceTaskDist.shipProduct && rowData.type === 'BUILD' && rowData?.status==='Success'"  
                                        id="softwareBuildShipAction" class="ui-icon-cloud-queue" id="stPreviewBuildReqDetails" pTooltip="Ship Image" (click)="onShipImageActionClick(rowData.build)">
                                        </i>
                                    </div-->
                                    <!--div class="action-icon"  *ngIf="app.sharedData.visibility.software.showSoftwareImageDownload">
                                        <i *ngIf="app.sharedData.visibility.software.download && rowData.type === 'BUILD' && rowData?.status==='Success'" 
                                        id="softwareBuildDownloadAction" class="ui-icon-cloud-download" pTooltip="Download Image" (click)="onDownloadImageActionClick(rowData.build)">
                                       </i>
                                    </div>
                            </td-->
                            </tr>
                        </ng-template>
                    </p-table>
                </div>


                <!--PassThrough build History Table-->
                <div *ngIf="image.buildSystem ==='PassThrough'">
                    <h2 id="PassThroughhistoryTableHeader">Image Build History:</h2>
                        <p-dataView #passThroughJobHistory [loading]="image.loadingPassThroughBuilds || image.loadingPassThroughBuildDetails > 0" [value]="image.passThroughBuilds" [paginator]="true" [rows]="4" filterBy="passThroughImageBuildInfo.status" layout="list" [style]="{'width':'100%'}"
                            emptyMessage="No jobs found. Please click 'Trigger SI' to start a job.">
                            <p-header>
                                <div class="ui-helper-clearfix">
                                    <div class="ui-g">
                                        <div class="ui-g-12 ui-md-6">
                                            <div style="position:relative">
                                                <input type="search" pInputText placeholder="Search by status" (keyup)="passThroughJobHistory.filter($event.target.value)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </p-header>
                            <ng-template let-rowData pTemplate="listItem">
                                <div class="ui-g" style="padding: 2em;border-bottom: 1px solid #d9d9d9">
                                    <div class="li-job ui-g-10">
                                        <h2 style="font-weight: bold; margin-top: 0;"></h2>
                                        <div class="ui-g-12">                                        
                                                <div class="ui-g-3"><b>Status</b></div>
                                                <div class="ui-g-9">:
                                                    <div class="li-job-status" style="display: inline-block;">
                                                        <div class="li-job-status tile" *ngIf="rowData?.passThroughImageBuildInfo?.status === 'Completed'" style="background-color: limegreen;">{{rowData?.passThroughImageBuildInfo?.status }}</div>
                                                        <div class="li-job-status tile" *ngIf="rowData?.passThroughImageBuildInfo?.status === 'In-Progress'|| rowData?.passThroughImageBuildInfo?.status === 'Submitted'" style="background-color:chocolate;">{{rowData?.passThroughImageBuildInfo?.status}}</div>
                                                        <div class="li-job-status tile" *ngIf="rowData?.passThroughImageBuildInfo?.status === 'Incomplete' || rowData?.passThroughImageBuildInfo?.status === 'Failed' || rowData?.passThroughImageBuildInfo?.status === 'Expired'" style="background-color:#e53a3a;">{{rowData?.passThroughImageBuildInfo?.status }} </div>
                                                        <div class="li-job-status tile" *ngIf="rowData?.passThroughImageBuildInfo?.status === 'BuildTest' || rowData?.passThroughImageBuildInfo?.status  === 'Approved'" style="background-color:slategray">{{rowData?.passThroughImageBuildInfo?.status}}</div>
                                                        <i  *ngIf="rowData?.passThroughImageBuildInfo?.status === 'BuildTest'" class="pi pi-exclamation-circle" pTooltip="Build is needed to be signed to be complete and used in triggering SP" [style]="{'font-size': '1.7em', 'cursor': 'pointer'}"></i>
                                                    </div>
                                                </div>

                                                <div class="ui-g-12" *ngIf="rowData?.passThroughImageBuildInfo?.failedReason!=null">
                                                    <div class="ui-g-3"><b>Failed Reason</b></div>
                                                    <div class="ui-g-9">: {{rowData?.passThroughImageBuildInfo?.failedReason}}</div>
                                                </div>
                                        </div>
                                        <!-- <div class="ui-g-12">
                                            <div class="ui-g-3"><b>SoftwareImage Build ID</b></div>
                                            <div class="ui-g-9">: {{rowData?.passThroughImageBuildInfo?.softwareImageBuildId}}</div>
                                        </div> -->
                                        <div class="ui-g-12">
                                            <div class="ui-g-3"><b>SoftwareImage Build</b></div>
                                            <div class="ui-g-9">: {{rowData?.passThroughImageBuildInfo?.softwareImageBuild}}</div>
                                        </div>
                                        <!-- <div class="ui-g-12">
                                            <div class="ui-g-3"><b>Service Type</b></div>
                                            <div class="ui-g-9">: {{rowData?.passThroughImageBuildInfo?.serviceType}}</div>
                                        </div> -->
                                        <div class="ui-g-12">
                                            <div class="ui-g-3"><b>Built by</b></div>
                                            <div class="ui-g-9">: {{rowData?.passThroughImageBuildInfo?.builtBy}}</div>
                                        </div>
                                        <div class="ui-g-12">
                                            <div class="ui-g-3"><b>Built On</b></div>
                                            <div class="ui-g-9">: {{rowData?.passThroughImageBuildInfo?.builtOn}}</div>
                                        </div>

                                     
                                    </div>
                                   
                                </div>
                            </ng-template>
                        </p-dataView>
                </div>



            </div>
        </p-accordionTab>
    </p-accordion>
    <div class="card" *ngIf="triggerSystemBuildProcess.displayForm">
        <p-dialog id="triggerSystemBuildDialog" header="Trigger System Build" [(visible)]="triggerSystemBuildProcess.displayForm" modal="modal"
            showEffect="fade" [style]="{width: '550px'}"  [transitionOptions]="'0ms'">
            <div class="ui-g-12">
                Do you want to submit request to build the system changes for {{triggerSystemBuildProcess.image?.baseImage}}?
            </div>
            <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                    <p-progressSpinner *ngIf="triggerSystemBuildProcess.buildInProgress" [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                    <button pButton id="triggerSystemBuildYes" type="button" [disabled]="triggerSystemBuildProcess.buildInProgress" [style]="{width: '150px'}" (click)="triggerBaitSystemBuild(triggerSystemBuildProcess.image)" label="Submit"></button>
                    <button pButton id="triggerSystemBuildNo" type="button" [disabled]="triggerSystemBuildProcess.buildInProgress" [style]="{width: '150px'}" (click)="triggerSystemBuildProcess.displayForm = false" label="Cancel"></button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
    <div class="card" *ngIf="buildProductPreview.displayPreview">
        <p-dialog header="Product Preview" [(visible)]="buildProductPreview.displayPreview" modal="modal"
            showEffect="fade" [style]="{width: '600px'}">
            <p-table [value]="buildProductPreview.tableData" [style]="{'width': '560px'}"sortField="changed" [sortOrder]="-1">
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th id="stbuildProductImage" pSortableColumn="label" style="width: 150px;">Image<p-sortIcon field="label"></p-sortIcon></th>
                        <th id="stbuildProductVersion" pSortableColumn="value" style="width: 300px;">Build Version<p-sortIcon field="value"></p-sortIcon></th>
                        <th id="stbuildProductChanged" pSortableColumn="changed" style="width: auto;">Changed<p-sortIcon field="changed"></p-sortIcon></th>
                    </tr>
                </ng-template>                            
                <ng-template pTemplate="body" let-rowData>
                    <tr [ngStyle]="{'background-color': rowData.changed  ? 'RoyalBlue' :'white','color': rowData.changed  ? 'white':'black'}">
                        <td >{{rowData.label}}</td>
                        <td >{{rowData.value}}</td>
                        <td >
                            <p *ngIf="rowData.changed">Yes</p>
                            <p *ngIf="rowData.changed == false">No</p>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                    <button pButton id="stProductBuildPreviewCancelButton" type="button" [style]="{width: '150px'}" (click)="closeProductBuildPreview()" label="Cancel"></button>
                    <button pButton id="stbuildProductButton" type="button" [style]="{width: '150px'}" (click)="buildProduct()" label="Build Product"></button>
                    <p-progressSpinner *ngIf="productBuildInProgress" [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                </div>
            </p-footer>
        </p-dialog>
    </div>
    <div class="card" *ngIf="viewBuildRequestDetailProcess.displayBuildRequestDetail">
        <p-dialog id="buildRequestDetailDialogHeader" header="Build Request Detail" [(visible)]="viewBuildRequestDetailProcess.displayBuildRequestDetail" modal="modal" showEffect="fade" [style]="{'width': '800px'}"  [transitionOptions]="'0ms'">
            <div class="ui-g" *ngIf="viewBuildRequestDetailProcess.build.status === 'Failure'">
                <div class="ui-g-12">
                    <b>{{viewBuildRequestDetailProcess.build.status + ' - ' + viewBuildRequestDetailProcess.build.failed_reason}}</b>
                    <span *ngIf="viewBuildRequestDetailProcess.build.failed_reason === 'Compile'"> error must be resolved by development team. Please contact the developer directly.</span>
                    <span *ngIf="viewBuildRequestDetailProcess.build.failed_reason !== 'Compile' && !viewBuildRequestDetailProcess.build?.failed_reason?.toLowerCase().includes('unknown')"> error must be resolved by <b>CRMHelp</b> team.
                        <button pButton id="CRMhelp" [style]="{'width': '160px', 'margin-left': '220px'}" type="button"  (click)="onOpenOPSHelpSupportTicketDialog(opsHelpIssueType,opsHelpProjectKey,buildInfrastructure,viewBuildRequestDetailProcess.tableData,viewBuildRequestDetailProcess.build.failed_reason)" label="File CRMHelp Ticket"></button>
                    </span>
                    <span *ngIf="viewBuildRequestDetailProcess.build?.failed_reason?.toLowerCase().includes('unknown')"> error must be resolved by <b>CirrusHelp</b> team.
                        <button pButton id="CirrusHelp" [style]="{'width': '160px', 'margin-left': '215px'}" type="button"  (click)="onOpenOPSHelpSupportTicketDialog(opsHelpIssueType,opsHelpProjectKey,ciruss,viewBuildRequestDetailProcess.tableData,viewBuildRequestDetailProcess.build.failed_reason)" label="File CirrusHelp Ticket"></button>
                    </span>

                </div>
            </div>
            <p-table id="buildRequestDetailTable" [value]="viewBuildRequestDetailProcess.tableData" [style]="{'width': '750px'}">
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td style="width: 155px;">{{rowData.label}}</td>
                        <td style="width: 2px;">:</td>
                        <td>
                            <span style="cursor: pointer; color: blue; text-decoration: underline" *ngIf="rowData.link!==null && rowData.link!==undefined"
                                    (click)="app.goToUrl(rowData.link)">
                                {{rowData.value}}
                            </span>
                            <span style="cursor: pointer; color: blue; text-decoration: underline" *ngIf="rowData.path!==null && rowData.path!==undefined"
                                    (click)="app.onOpenFolderClick(rowData.path)">
                                {{rowData.value}}
                            </span>
                            <span *ngIf="(rowData.link===null || rowData.link===undefined) && (rowData.path===null || rowData.path===undefined)">
                                {{rowData.value}}
                            </span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>  
            <p-accordion [multiple]="true" >
                <p-accordionTab header="Impacted Files" [selected]=false>
                  <p-progressSpinner *ngIf="viewBuildRequestDetailProcess.loadingImpactedFiles" [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                  <p-table [value]="viewBuildRequestDetailProcess.impactedFiles" [columns]="impactedFileTableCols">
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td colspan="2">No Impacted File(s).</td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                          <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                              {{col.header}}
                              <p-sortIcon *ngIf="col.field !== ''" [field]="col.field"></p-sortIcon>
                          </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData>
                      <tr *ngFor="let file of rowData.files; let i = index">
                        <td *ngIf="i===0" [attr.rowspan]="rowData.files?.length" style="width: 100px; vertical-align: top;">
                          {{rowData.crNumber}}
                        </td>
                        <td>{{file.filePath}}/{{file.fileName}}</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </p-accordionTab>
            </p-accordion>
        </p-dialog>
    </div>
    <div class="card" *ngIf="viewProductBuildDetail.displayProductBuildDetail">
        <p-dialog header="Product Build Details" [(visible)]="viewProductBuildDetail.displayProductBuildDetail" modal="modal" showEffect="fade" [style]="{width: '650px'}">
            <p-table [value]="viewProductBuildDetail.tableData" [style]="{'width': '600px'}">
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                        <td style="width: 140px;">{{rowData.label}}</td>
                        <td>
                            <span style="cursor: pointer; color: blue; text-decoration: underline;" *ngIf="rowData.link!==null && rowData.link!==undefined"
                                    (click)="app.goToUrl(rowData.link)">
                                {{rowData.value}}
                            </span>
                            <span *ngIf="rowData.link===null || rowData.link===undefined" style="width: 140px;">{{rowData.value}}</span>
                        </td>
                    </tr>
                </ng-template>
            </p-table>  
            <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                    <button pButton id="closeProductBuildDetailsButton" [style]="{width: '150px'}" type="button" (click)="closeProductBuildDetails()" label="OK"></button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
    <div class="card" *ngIf="buildSiSpProcess.displayModal|| buildSiSpProcess.displayBaitForm || buildSiSpProcess.displayAutoShipOption || buildSiSpProcess.displayCustomSoftwareImage">
        <p-dialog header="{{stMain.serviceTasksDetails.baseBuild === null ? 'Trigger Bait Build' : 'Trigger Bait Build & Auto Ship'}}"
            [(visible)]="buildSiSpProcess.displayBaitForm" modal="modal" showEffect="fade"  [style]="{width: '600px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                    <b>Image  : </b>{{buildSiSpProcess.image?.baseImage}}
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <span style="display: inline-block;margin-bottom: 20px;vertical-align: middle;padding: 0.5em;padding-left: 0;"><b>Select : </b></span>
                    <p-selectButton id="selectGerritCR" [options]="baitBuildOption" [(ngModel)]="buildSiSpProcess.isGerrit" optionLabel="label" optionValue="value"
                        [style]="{'width': '130px'}"></p-selectButton>
                    <div class="ui-g-12" *ngIf="buildSiSpProcess.isGerrit?.value" style="padding-top: 0;">
                        <div style="display: inline-block; color: blue;">
                            <div (click)="onSelectAllGerrits()" style="margin: 0 5px; display: inline-block; cursor: pointer; text-decoration: underline;">Select All</div>
                            <div (click)="onUnselectAllGerrits()" style="margin: 0 5px; display: inline-block; cursor: pointer; text-decoration: underline;">Unselect All</div>
                        </div>
                        <div *ngIf="buildSiSpProcess.previousGerrits?.length > 0" style="float: right; color: blue; margin-right: 50px;">
                            <div (click)="onSelectPreviousGerrits()" style="margin: 0 5px; display: inline-block; cursor: pointer; text-decoration: underline;">Select Previous</div>
                            <i class="pi pi-info-circle"
                                (mouseenter)="app.toggleOverlay($event,'Automatically Select Gerrits from Latest Completed Build')"
                                (mouseout)="app.toggleOverlay($event,'')" style="float: right; cursor: pointer; font-size: 1rem;">
                            </i>
                        </div>                   
                    </div>     
                    <div class="ui-g-12" *ngIf="!buildSiSpProcess.isGerrit?.value" style="padding-top: 0;">                        
                        <div style="display: inline-block; color: blue;">
                            <div (click)="onSelectAllCrs()" style="margin: 0 5px; display: inline-block; cursor: pointer; text-decoration: underline;">Select All</div>
                            <div (click)="onUnselectAllCrs()" style="margin: 0 5px; display: inline-block; cursor: pointer; text-decoration: underline;">Unselect All</div>
                        </div>
                        <div *ngIf="buildSiSpProcess.previousCrs?.length > 0" style="float: right; color: blue; margin-right: 50px;">
                            <div (click)="onSelectPreviousCrs()" style="margin: 0 5px; display: inline-block; cursor: pointer; text-decoration: underline;">Select Previous</div>
                            <i class="pi pi-info-circle"
                                (mouseenter)="app.toggleOverlay($event,'Automatically Select CRs from Latest Completed Build')"
                                (mouseout)="app.toggleOverlay($event,'')" style="float: right; cursor: pointer; font-size: 1.2rem">
                            </i>
                        </div>                        
                    </div>              
                    <div class="ui-g-12" *ngIf="buildSiSpProcess.isGerrit?.value">
                        <p-pickList sourceHeader="Available" targetHeader="Selected"  dragdrop="true"
                            [showSourceControls] = "false" filterBy="gerritId" 
                            sourceFilterPlaceholder="Search by gerrit" targetFilterPlaceholder="Search by gerrit"
                            [sourceStyle]="{'width': '100%'}" [targetStyle]="{'width': '100%'}"
                            [source]="buildSiSpProcess.gerrits" [target]="buildSiSpProcess.gerritSelected">
                            <ng-template let-gerrit pTemplate="item">
                                <div class="ui-helper-clearfix">
                                    <div style="display:inline-block;">{{gerrit.type}}</div>
                                    <div style="float: right;">{{gerrit.gerritId}}</div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                    <div class="ui-g-12" *ngIf="!buildSiSpProcess.isGerrit?.value">  
                        <p-pickList sourceHeader="Available" targetHeader="Selected"  dragdrop="true"
                            [showSourceControls] = "false" [showTargetControls] = "false" filterBy="changeRequest" 
                            sourceFilterPlaceholder="Search by CR" targetFilterPlaceholder="Search by CR"
                            [sourceStyle]="{'width': '100%'}" [targetStyle]="{'width': '100%'}"
                            [source]="buildSiSpProcess.crs" [target]="buildSiSpProcess.crSelected">
                            <ng-template let-cr pTemplate="item">
                                <div class="ui-helper-clearfix">
                                    <div style="display:inline-block;">{{cr.changeRequest}}</div>
                                </div>
                            </ng-template>
                        </p-pickList>
                    </div>
                </div>
            </div>            
            <div class="ui-g">
                <div class="ui-g-12">
                    <b id="skipBuild">Skip Build                        
                        <i class="pi pi-question-circle" 
                            (mouseenter)="app.toggleOverlay($event,'If you only have OSS changes you can skip the build and the changes will be pushed to the customer- facing OSS repository')"
                            (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                        </i>
                         : 
                    </b>
                    <p-checkbox id="checkToSkipBuild" [binary]="true"
                        [(ngModel)]="buildSiSpProcess.skipBuild">
                    </p-checkbox>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <b>Select Technology : </b>
                    <p-dropdown [options]="buildSiSpProcess.technologySelectItems"
                      [(ngModel)]="buildSiSpProcess.technologySelected" filter="true" [virtualScroll]="true">
                      <ng-template let-item pTemplate="selectedItem">
                        {{item.label}}
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                        {{item.label}}
                      </ng-template>
                    </p-dropdown>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <b>Select Targets : </b>
                    <p-listbox [options]="buildSiSpProcess.targetSelectItems" [(ngModel)]="buildSiSpProcess.targetSelected"
                    [multiple]="true" [checkbox]="true" [filter]="true"
                    [style]="{'overflow-y': 'auto'}" [listStyle]="{'min-height': '150px','max-height': '150px'}">
                    </p-listbox>
                </div>
            </div>
            <p-footer>      
                <div class="ui-g" *ngIf="buildSiSpProcess.message !== ''" >
                    <div class="ui-g-12" class="message message-error">
                    {{buildSiSpProcess.message}}
                    </div>
                </div>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">                                    
                    <p-progressSpinner *ngIf="buildSiSpProcess.loadingTechnologies || buildSiSpProcess.loadingTargets"
                        [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                    </p-progressSpinner>
                    <button id="triggerBaitNextButton" pButton type="button" [style]="{width: '100px'}" label="Next >" (click)="onBaitBuildNext()"
                        [disabled]="(buildSiSpProcess.isGerrit?.value && buildSiSpProcess.gerritSelected?.length === 0)
                                || (!buildSiSpProcess.isGerrit?.value && buildSiSpProcess.crSelected?.length === 0)">
                    </button>
                </div>
            </p-footer>
        </p-dialog>
        <p-dialog id="triggerSP&AutoShipDialogBoxHeader" header="{{buildSiSpProcess.isTriggerBaitProcess?  (stMain.serviceTasksDetails.baseBuild === null ? 'Trigger Bait Build' : 'Trigger Bait Build & Auto Ship'): (buildSiSpProcess.isTriggerSIProcess? (stMain.serviceTasksDetails.baseBuild === null ? 'Trigger SI' : 'Trigger SI & Auto Ship'):'Trigger SP & Auto Ship')}}"
            [(visible)]="buildSiSpProcess.displayModal" modal="modal" showEffect="fade"  [style]="getDialogStyle()">
            <div *ngIf="buildSiSpProcess.displayForm">
                <div class="ui-g-12"  *ngIf="buildSiSpProcess.autoShip || buildSiSpProcess.isTriggerSPProcess || buildSiSpProcess.isTriggerSIProcess">
                     <div class="ui-g">
                         <div style="width: 100%">
                            <b id="compositionHeader">Composition : </b>
                            <p-toggleButton id="customizeComposition" *ngIf="app.sharedData.visibility.serviceTaskBuild.customizeComposition && buildSiSpProcess.isTriggerSPProcess" [(ngModel)]="buildSiSpProcess.customize" (onChange)="onCustomizeToggle()" onLabel="Restore" offLabel="Customize" [style]="{'width':'90px', 'float':'right'}"></p-toggleButton>
                        </div>
                        <div class="ui-g-12">
                            <!--span *ngIf="buildSiSpProcess.isTriggerSPProcess">Running Builds: 
                                <p-checkbox value="0" [binary]="true" [(ngModel)]="buildSiSpProcess.includeRunningImages">
                                </p-checkbox>
                            </span-->
                            <div *ngIf="buildSiSpProcess.isTriggerSIProcess">
                                Latest build of {{buildSiSpProcess.image?.baseImage}} will be included.
                            </div>
                            <div  class="message message-error" *ngIf="buildSiSpProcess.isDuplicateComposition">
                                Warning : Found Product Build {{buildSiSpProcess.duplicateProduct}} with the same composition.
                            </div>
                            <p-table id="buildCompositionTable" *ngIf="!buildSiSpProcess.includeRunningImages" [value]="buildSiSpProcess.compositionTableData"
                                    sortField="changed" [sortOrder]="-1" 
                                    [style]="{'max-height': '200px', 'overflow-y': 'auto'}">
                                <ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th id="softwareImage" pSortableColumn="softwareImage" style="width:110px;">
                                            <b>Image</b>
                                            <p-sortIcon field="softwareImage"></p-sortIcon>
                                        </th>
                                        <th id="buildVersion" style="width: auto;"><b>Build Version</b></th>
                                        <th id="buildEdit" *ngIf="buildSiSpProcess.customize" style="width: 123px;"><b>Build Type</b></th>
                                        <th id="buildEditAction" *ngIf="buildSiSpProcess.customize" style="width: 25px;"></th>
                                        <th id="buildStatus" style="width: 100px;"><b>Build Status</b></th>
                                        <th id="changedBuild" pSortableColumn="changed" style="width:75px;">
                                            <b>Changed</b>
                                            <p-sortIcon field="changed"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <!--ng-template pTemplate="header" let-columns>
                                    <tr>
                                        <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                            <b>{{col.header}}</b>
                                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template-->
                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="4">No data found.</td>
                                    </tr>
                                </ng-template>                            
                                <ng-template pTemplate="body" let-rowData>
                                    <tr>
                                        <td id="swImage" >{{rowData.softwareImage}}</td>
                                        <td id="swImageBuild" >
                                            <!--span *ngIf="buildSiSpProcess.customize && rowData.softwareImageTypeSelected !== 'default'">{{rowData.softwareImageCustomSelected?.imageBuild}}</span-->
                                            <span *ngIf="!rowData.changed && !(buildSiSpProcess.customize && rowData.softwareImageTypeSelected !== 'default')">{{rowData.oldSoftwareImageBuild}}</span>
                                            <p-dropdown id="selectedNewImageBuild" *ngIf="rowData.changed && !(buildSiSpProcess.customize && rowData.softwareImageTypeSelected !== 'default')" [options]="rowData.newBuildSelectItems" [(ngModel)]="rowData.newSoftwareImageBuildInfo" (onChange)="onBuildChange(rowData)" (keyup.enter)="onBuildChange(rowData)" appendTo="body" (style)="{'width':'80%'}">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    {{item.value?.softwareImageBuild}}
                                                  </ng-template>
                                                  <ng-template let-item pTemplate="item">
                                                    {{item.value?.softwareImageBuild}}
                                                  </ng-template>
                                            </p-dropdown>                                                          
                                            <p-dropdown id="selectCustomImageBuild" *ngIf="buildSiSpProcess.customize && rowData.softwareImageTypeSelected !== 'default'" [options]="rowData.softwareImageCustomSelectItem" [(ngModel)]="rowData.softwareImageCustomSelected" (onChange)="onCustomBuildChange(rowData)" [filter]="true" appendTo="body" (style)="{'width':'100%'}">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    {{item.value?.imageBuild}}
                                                </ng-template>
                                                <ng-template let-item pTemplate="item">
                                                    {{item.value?.imageBuild}}
                                                </ng-template>
                                            </p-dropdown>
                                        </td>
                                        <td *ngIf="buildSiSpProcess.customize">                        
                                            <p-dropdown id="selectCustomImageBuildType" [options]="buildSiSpProcess.softwareImageType" [(ngModel)]="rowData.softwareImageTypeSelected" (onChange)="customizeCompositionSI(rowData)" (keyup.enter)="customizeCompositionSI(rowData)" appendTo="body" (style)="{'width':'80%'}">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    {{item.label}}
                                                </ng-template>
                                                <ng-template let-item pTemplate="item">
                                                    {{item.label}}
                                                </ng-template>
                                            </p-dropdown>
                                        </td>
                                        <td *ngIf="buildSiSpProcess.customize">
                                            <div>                  
                                                <i id="selectSIBuildBuild" class="material-icons" *ngIf="rowData.softwareImageTypeSelected !== 'default' && !rowData.loadLatestBuildInProgress" 
                                                    (click)="rowData.softwareImageTypeSelected = 'default';customizeCompositionSI(rowData)"  pTooltip="Restore Default" style="display: inline-block; cursor: pointer;">close</i>
                                                <p-progressSpinner *ngIf="rowData.loadLatestBuildInProgress"
                                                    [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                                                </p-progressSpinner>
                                            </div>
                                        </td>
                                        <td id="buildStatus">{{rowData.buildStatus}}</td>
                                        <td id="changedCheckImageBuild"  >
                                            <p *ngIf="rowData.changed">Yes</p>
                                            <p *ngIf="rowData.changed == false">No</p>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>                        
                            <div *ngIf="!buildSiSpProcess.imageChanged" style="color:red;margin-top: 5px;">
                                Note: No change to Software Image(s).
                            </div>
                            <div *ngIf="buildSiSpProcess.signRequired && buildSiSpProcess.imageSignedMessages.length>0" style="color:red;margin-top: 5px;">
                                Note:
                                <span *ngFor="let line of buildSiSpProcess.imageSignedMessages">
                                    <div style="color:red;margin-top: 5px; min-width: 100%;" *ngIf="line !== '' && line !== undefined && line !== null">
                                        Software Product Build would fail. {{line}}
                                    </div>
                                </span>
                            </div>
                            
                        </div>
                      </div>
                      <div class="ui-g" >
                        <div class="ui-g-12">
                            <b id="skipRecompile">Skip Recompile : </b>
                            <p-checkbox id="checkToSkipRecompile" value="1" [binary]="true"
                                [(ngModel)]="buildSiSpProcess.skipRecompile" [disabled]="true">
                            </p-checkbox>
                        </div>
                       </div>
                    <div class="ui-g" *ngIf="buildSiSpProcess.isTriggerSIProcess && !buildSiSpProcess.isTriggerBaitProcess">
                        <div class="ui-g-12">
                            <b id="buildMode">Build Mode : </b>
                            <p-dropdown id="selectBuildMode"  [options]="buildModeOptions" [(ngModel)]="buildSiSpProcess.fullBuild"
                                [disabled] = "buildSiSpProcess.image?.detail?.incrementalBuildSupported !== true"
                                [style]="{'min-width':'0','width':'150px'}">
                                <ng-template let-item pTemplate="selectedItem">
                                    {{item.label}}
                                  </ng-template>
                                  <ng-template let-item pTemplate="item">
                                    {{item.label}}
                                  </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-12">
                            <b id="selectDsitros">Select Distros : </b>
                            <p-checkbox id="checktoSelectDistros" *ngIf="buildSiSpProcess.autoShip || buildSiSpProcess.isTriggerSPProcess" value="1" [binary]="true"
                                [(ngModel)]="buildSiSpProcess.buildAllDistros">
                            </p-checkbox>
                            <span *ngIf="buildSiSpProcess.autoShip || buildSiSpProcess.isTriggerSPProcess" >All (Build all distro formats)</span>
                            <p-listbox id="softwareDistroTypesForSI&SPTrigger" [options]="buildSiSpProcess.distroTypes" [(ngModel)]="buildSiSpProcess.distroSelected"
                            [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="softwareDistro"
                            [style]="{'overflow-y': 'auto'}" [listStyle]="{'max-height': '150px'}">
                            </p-listbox>
                        </div>
                    </div>
                   </div>
                 </div>
                 <p-footer>
                    <div class="ui-dialog-buttonpane ui-helper-clearfix">             
                        <div class="ui-g" *ngIf="buildSiSpProcess.errorMessageComposition !== ''" >
                            <div class="ui-g-12" class="message message-error">
                                {{buildSiSpProcess.errorMessageComposition}}
                            </div>
                        </div>
                        <p-progressSpinner *ngIf="buildSiSpProcess.loadCompositionInProgress || loadingRecentProductComposition !== 0
                         || loadingRecentProductDetails !== 0 || loadingRecentProducts === true"
                            [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                        </p-progressSpinner>
                        
                        <button pButton id="triggerBaitBackButton" *ngIf="buildSiSpProcess.isTriggerBaitProcess" type="button" [style]="{width: '100px'}" (click)="onBaitBuildBack()" label="< Back">
                        </button>
                      <button pButton id="buildSelectAutoShipOption" type="button" (click)="buildSiSpProcess.displayModal=false;buildSiSpProcess.displayAutoShipOption=true;" label="Next >" [style]="{width: '100px'}" 
                        [disabled]="(buildSiSpProcess.distroSelected.length === 0)
                        || buildSiSpProcess.loadCompositionInProgress || buildSiSpProcess.loadRunningCompositionInProgress
                        || buildSiSpProcess.triggerSPInProgress || buildSiSpProcess.triggerAutoShipInProgress 
                        || (buildSiSpProcess.signRequired && buildSiSpProcess.isTriggerSPProcess)
                        || loadingRecentProductComposition !== 0 || loadingRecentProductDetails !== 0 || loadingRecentProducts === true
                        || buildSiSpProcess.isEmptyBuild">
                       </button>
                    </div>
                  </p-footer>
               </p-dialog>
               <p-dialog  id="triggerAutoShipDialogBoxHeader" header="{{buildSiSpProcess.isTriggerBaitProcess?  (stMain.serviceTasksDetails.baseBuild === null ? 'Trigger Bait Build' : 'Trigger Bait Build & Auto Ship'): (buildSiSpProcess.isTriggerSIProcess? (stMain.serviceTasksDetails.baseBuild === null ? 'Trigger SI' : 'Trigger SI & Auto Ship'):'Trigger SP & Auto Ship')}}"
               [(visible)]="buildSiSpProcess.displayAutoShipOption" modal="modal" showEffect="fade"  [style]="getDialogStyle()">
               <div *ngIf="buildSiSpProcess.displayForm">
               <div class="ui-g-12" *ngIf="buildSiSpProcess.autoShip || buildSiSpProcess.isTriggerSPProcess || buildSiSpProcess.isTriggerSIProcess">
                    <div class="ui-g" *ngIf="stMain.serviceTasksDetails.baseBuild !== null">
                        <div class="ui-g-12">
                            <b id="autoShip">Auto Ship : </b>
                            <p-checkbox id="checkToAutoShip" value="1" [binary]="true"
                                [(ngModel)]="buildSiSpProcess.autoShip">
                            </p-checkbox>
                            <p *ngIf="buildSiSpProcess.isTriggerSIProcess && buildSiSpProcess.autoShip">
                                Select "Auto Ship" to Trigger {{buildSiSpProcess.isTriggerBaitProcess? 'Bait' : 'SI'}}, trigger SP build and Ship selected distros
                            </p>
                            <p *ngIf="buildSiSpProcess.isTriggerSPProcess && buildSiSpProcess.autoShip">
                                Select "Auto Ship" to Trigger SP and Ship selected distros
                            </p>
                        </div>
                        <div class="ui-g-12">
                            <b *ngIf="buildSiSpProcess.autoShip" id="autoShip">Auto Diff Ship : </b>
                            <p-checkbox *ngIf="buildSiSpProcess.autoShip" id="checkToAutoDiffShip" value="1" [binary]="true"
                                [(ngModel)]="buildSiSpProcess.autoDiffShip">
                            </p-checkbox>
                            <p *ngIf="buildSiSpProcess.isTriggerSIProcess && buildSiSpProcess.autoDiffShip && buildSiSpProcess.autoShip">
                                Select "Auto Diff Ship" to Trigger {{buildSiSpProcess.isTriggerBaitProcess? 'Bait' : 'SI'}}, trigger SP and Ship difference between SP and selected Base Build for selected distros.
                            </p>
                            <p *ngIf="buildSiSpProcess.isTriggerSPProcess && buildSiSpProcess.autoDiffShip && buildSiSpProcess.autoShip">
                                Select "Auto Diff Ship" to Trigger SP and Ship difference between SP and selected Base Build for selected distros.
                            </p>
                        </div>
                        <div class="ui-g-12" *ngIf="buildSiSpProcess.autoDiffShip && buildSiSpProcess.autoShip">
                            <div class="ui-g-4">
                                <b id="baseBuild" >Select Base Build : </b>
                            </div>
                            <div class="ui-g-8">
                                <p-dropdown id="stDiffBaseBuildOptions" [options]="stMain.availableDiffBuildOptions"[(ngModel)]="buildSiSpProcess.diffBuildSelected" filter="true" [virtualScroll]="true" itemSize="30" appendTo="body">
                                    <ng-template let-item pTemplate="selectedItem">
                                    {{item.label}}
                                    </ng-template>
                                    <ng-template let-item pTemplate="item">
                                    {{item.label}}
                                    </ng-template>
                              </p-dropdown>
                            </div>
                        </div>
                    </div>
                   
               </div>
            <div *ngIf="buildSiSpProcess.displayFormConfirm">
                No change to Software Image(s). Do you still want to submit the request?
            </div>
            </div>
            <p-footer>      
                <div class="ui-g" *ngIf="buildSiSpProcess.message !== ''" >
                    <div class="ui-g-12" class="message message-error">
                    {{buildSiSpProcess.message}}
                    </div>
                </div>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                        <p-progressSpinner *ngIf="buildSiSpProcess.triggerSIInProgress || buildSiSpProcess.triggerSPInProgress || buildSiSpProcess.loadCompositionInProgress
                                                    || buildSiSpProcess.loadRunningCompositionInProgress || buildSiSpProcess.triggerAutoShipInProgress
                                                    || buildSiSpProcess.triggerBuildInProgress"
                            [style]="{width: '20px', height: '20px', padding: '2px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                        </p-progressSpinner>
                        <button pButton id="triggerSpBackButton" type="button" (click)="buildSiSpProcess.displayModal=true;buildSiSpProcess.displayAutoShipOption=false;"label="< Back"></button>
                        <button pButton id="triggerSI&AutoShipSubmitButton" *ngIf="buildSiSpProcess.isTriggerSIProcess && !buildSiSpProcess.displayFormConfirm && !buildSiSpProcess.isTriggerBaitProcess" type="button" [style]="{width: '100px'}" (click)="onTriggerSiSpSubmit()" label="Submit"
                            [disabled]="(buildSiSpProcess.autoShip && buildSiSpProcess.distroSelected.length === 0) 
                                            || buildSiSpProcess.loadCompositionInProgress || buildSiSpProcess.loadRunningCompositionInProgress
                                            || buildSiSpProcess.triggerSIInProgress || buildSiSpProcess.triggerAutoShipInProgress">
                        </button>
                        <button pButton id="triggerSP&AutoShipSubmitButton" *ngIf="buildSiSpProcess.isTriggerSPProcess && !buildSiSpProcess.displayFormConfirm && !buildSiSpProcess.isTriggerBaitProcess" type="button" [style]="{width: '100px'}" (click)="onTriggerSiSpSubmit()" label="Submit"
                            [disabled]="(buildSiSpProcess.distroSelected.length === 0)
                                            || buildSiSpProcess.loadCompositionInProgress || buildSiSpProcess.loadRunningCompositionInProgress
                                            || buildSiSpProcess.triggerSPInProgress || buildSiSpProcess.triggerAutoShipInProgress || buildSiSpProcess.signRequired">
                        </button>
                        <button pButton id="triggerSiSpBackButton" *ngIf="buildSiSpProcess.displayFormConfirm" type="button" [style]="{width: '100px'}" (click)="onTriggerSiSpBack()" label="< Back">
                        </button>
                        <button pButton id="triggerSiSpConfirmButton" *ngIf="buildSiSpProcess.displayFormConfirm && !buildSiSpProcess.isTriggerBaitProcess" type="button" [style]="{width: '100px'}" (click)="buildSiSpProcess.isTriggerSIProcess? buildImageAutoShip(buildSiSpProcess.image) : autoShipSP()" label="Submit"
                            [disabled]="buildSiSpProcess.triggerSPInProgress || buildSiSpProcess.triggerAutoShipInProgress">
                        </button>
                        <button id="triggerBaitSubmitButton" pButton type="button" [style]="{width: '100px'}" label="Submit" (click)="triggerBaitBuild()"
                            *ngIf="buildSiSpProcess.isTriggerBaitProcess"
                            [disabled]="(buildSiSpProcess.isGerrit?.value && buildSiSpProcess.gerritSelected?.length === 0)
                                    || (!buildSiSpProcess.isGerrit?.value && buildSiSpProcess.crSelected?.length === 0)">
                        </button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
    <!--Abort Bait-->
    <div class="card" *ngIf="abortBaitBuildProcess.displayForm">
        <p-dialog id="abortBaitDialogBox" header="Abort Bait Job" [(visible)]="abortBaitBuildProcess.displayForm" modal="modal" showEffect="fade">
            <div class="ui-g">
                <div id="abortBaitBuildConfirmation" class="ui-g-12">
                    Do you want to abort Bait Job {{abortBaitBuildProcess.baitJob.build_id}} for Image {{abortBaitBuildProcess.image.baseImage}}?
                </div>
            </div>
            <p-footer>
                <div class="ui-g">
                    <div class="ui-g-12" *ngIf="this.abortBaitBuildProcess.message !== ''" class="message message-error">
                      {{abortBaitBuildProcess.message}}
                    </div>
                  </div>
                  <p-progressSpinner *ngIf="abortBaitBuildProcess.baitJob.abortInProgress" 
                                      [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                <button pButton id="abortBaitBuildSubmitButton" [disabled]="abortBaitBuildProcess.baitJob.abortInProgress"  type="button"
                         (click) = "abortBaitBuild()" label="Yes" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
            </p-footer>
        </p-dialog>
    </div>
    <div class="card" *ngIf="shipImageProcess.displayDistro">
        <p-dialog header="Ship Image {{shipImageProcess.softwareImageBuild}}"
          [(visible)]="shipImageProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
          <p-listbox id="distSoftwareDistroTypesforShip" [options]="shipImageProcess.distroTypes" [(ngModel)]="shipImageProcess.distroSelected"
            [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="softwareDistro">
            <p-header>
              Select Distros
            </p-header>
          </p-listbox>
          <p-footer>
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
              <p-progressSpinner *ngIf="shipImageProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
              <button pButton id="distRequestShipButton" type="button" (click)="onShipImageButtonClick($event)" label="Request Ship"
                [disabled]="shipImageProcess.distroSelected?.length === 0" icon="ui-icon-cloud-queue"></button>
            </div>
          </p-footer>
        </p-dialog>
    </div>
    <div class="card" *ngIf="downloadImageProcess.displayDistro">
        <p-dialog header="Download Image {{downloadImageProcess.softwareImageBuild}}"
          [(visible)]="downloadImageProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
          <p-listbox id="buildSoftwareDistroTypesforDownloadImage" [options]="downloadImageProcess.distroTypes" [(ngModel)]="downloadImageProcess.distroSelected"
               [filter]="true" optionLabel="softwareDistro">
            <p-header>
              Select Distros
            </p-header>
          </p-listbox>
          <p-footer>
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
              <p-progressSpinner *ngIf="downloadImageProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
              <button pButton id="distDownloadImageButton" type="button" (click)="onDownloadImageButtonClick($event)" label="Download Image"
                [disabled]="downloadImageProcess.distroSelected == undefined" icon="ui-icon-cloud-queue"></button>
            </div>
          </p-footer>
        </p-dialog>
    </div>
    
    <div class="card" *ngIf="autoPropogateProcess.showConfirmDialog">
    <p-dialog id="autoPropogateDialogBoxHeader" header="Auto Propopgate CR" [(visible)]="autoPropogateProcess.showConfirmDialog" modal="modal"
    showEffect="fade" [style]="{width: '550px'}">
        <div class="ui-g">
            <div class="ui-g-12">
            <p id="autoPropogateConfirmation">Are you sure you want to propagate CR {{autoPropogateProcess.changeRequest?.changeRequestId}} to Branch {{autoPropogateProcess.autoPropogateImage?.detail?.perforceDetails?.path}}?</p>
            </div>
        </div>
        <p-footer>
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <p-progressSpinner *ngIf="autoPropogateProcess.autoPropagationInProgress" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                <button id="autoPropogate" pButton type="button" (click)="autoPropogateCR()" label="Yes" [disabled]="autoPropogateProcess.autoPropagationInProgress"></button>
                <button id="cancelAutoPropogate" pButton type="button" (click)="autoPropogateProcess.showConfirmDialog=false" label="No"></button>
            </div>
        </p-footer>
    </p-dialog>
    </div>
    <div class="card" *ngIf="autoPropogateProcess.showPropogationStatus">
    <p-dialog id="autoPropogateDialogBoxHeader" header="Auto Propopgate CR Status" [(visible)]="autoPropogateProcess.showPropogationStatus" modal="modal"
    showEffect="fade" [style]="{width: '550px'}"  [transitionOptions]="'0ms'">
        <p-table id="buildRequestDetailTable" [value]="autoPropogateProcess.tableData">
            <ng-template pTemplate="body" let-rowData>
                <tr>
                <td style="width: 155px;">{{rowData.label}}</td>
                <td style="width: 2px;">:</td>
                <td>
                    <span style="cursor: pointer; color: blue; text-decoration: underline" *ngIf="rowData.link!==null && rowData.link!==undefined"
                        (click)="app.goToUrl(rowData.link)">
                        {{rowData.value}}
                    </span>
                    <span style="cursor: pointer; color: blue; text-decoration: underline" *ngIf="rowData.path!==null && rowData.path!==undefined"
                        (click)="app.onOpenFolderClick(rowData.path)">
                    {{rowData.value}}
                    </span>
                    <span *ngIf="(rowData.link===null || rowData.link===undefined) && (rowData.path===null || rowData.path===undefined)">
                    {{rowData.value}}
                    </span>
                </td>
            </tr>
        </ng-template>
        </p-table>
        <br>     
        <b *ngIf="autoPropogateProcess.result.length>0">Merge Logs:</b>   
        <p-accordion *ngIf="autoPropogateProcess.result.length>0"[multiple]="true">
            <p-accordionTab *ngFor="let result of autoPropogateProcess.result" [selected]=false>
                <p-header>
                    <div style="display: inline-block;width: 90%;position: absolute;bottom: 0px;">
                        {{result.failure_reason}}
                    </div>
                </p-header>
                <p-table id="buildRequestDetailTable" [value]="result.files">
                    <ng-template pTemplate="body" let-rowData>
                        <tr>
                            <td>{{rowData.file_name}}</td>
                            <td style="width: 10px;">:</td>
                            <td>{{rowData.resolved_line}}</td>
                        </tr>
                    </ng-template>
                </p-table>        
            </p-accordionTab>
        </p-accordion>
        <p-footer *ngIf="autoPropogateProcess.failureReasons.length === 0 && autoPropogateProcess.autoPropagationOutcome === 'Success'">
            <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <div class="ui-g" *ngIf="autoPropogateProcess.errorMessageCommit !== ''">
                    <div class="ui-g-12 message message-error" style="padding: 0;">
                        <p id="autoPropogateCommitError">{{autoPropogateProcess.errorMessageCommit}}</p>
                    </div>
                </div>
                <p-checkbox id="autoPropogateAcknowledge" value="0" [binary]="true" [(ngModel)]="autoPropogateProcess.acknowledge"
                    [disabled]="autoPropogateProcess.isTopSecret" label="I acknowledge that changes have been reviewed" style="float: left;">
                </p-checkbox>
                <p-progressSpinner *ngIf="autoPropogateProcess.showPropogationCommitStatus" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                <button id="autoPropogateCommit" pButton type="button" (click)="autoPropogateCRCommit()" label="Commit" [disabled]="autoPropogateProcess.acknowledge === false || autoPropogateProcess.isTopSecret"></button>
            </div>
        </p-footer>
    </p-dialog>
    </div>  
    <div class="card" *ngIf="autoPropogateProcess.showPropogationCommitStatus">
        <p-dialog id="autoPropogateDialogBoxHeader" header="Auto Propopgate CR Commit Status" [(visible)]="autoPropogateProcess.showPropogationCommitStatus" modal="modal"
        showEffect="fade" [style]="{width: '550px'}"  [transitionOptions]="'0ms'">
            <p-table id="buildCommitRequestDetailTable" [value]="autoPropogateProcess.tableCommitData">
                <ng-template pTemplate="body" let-rowData>
                    <tr>
                    <td style="width: 155px;">{{rowData.label}}</td>
                    <td style="width: 2px;">:</td>
                    <td>
                        <span style="cursor: pointer; color: blue; text-decoration: underline" *ngIf="rowData.link!==null && rowData.link!==undefined"
                            (click)="app.goToUrl(rowData.link)">
                            {{rowData.value}}
                        </span>
                        <span style="cursor: pointer; color: blue; text-decoration: underline" *ngIf="rowData.path!==null && rowData.path!==undefined"
                            (click)="app.onOpenFolderClick(rowData.path)">
                        {{rowData.value}}
                        </span>
                        <span *ngIf="(rowData.link===null || rowData.link===undefined) && (rowData.path===null || rowData.path===undefined)">
                        {{rowData.value}}
                        </span>
                    </td>
                </tr>
            </ng-template>
            </p-table>
        </p-dialog>
    </div>   
    <div class="card" *ngIf="emailProcess.displayForm">
        <p-dialog header="Select CRs"
            [(visible)]="emailProcess.displayForm" modal="modal" showEffect="fade"  [style]="{width: '80%'}">            
            <p-table id="changeRequestSelectTable" #crSelectTable [columns]="crSelectCols" [value]="emailProcess.image.changeRequests"
                [(selection)]="emailProcess.selectedCRs" sortField="currentInfo.status" [sortOrder]="-1"
                [globalFilterFields]="['changeRequestId', 'detail.title', 'currentInfo.status', 'currentInfo.assignee','changeRequestDate']">
                <ng-template pTemplate="caption">
                    <div style="text-align: right">
                        <i id="searchFilterCr" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input #crSelectGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                        (input)="crSelectTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                        <i class="ui-icon-cancel" id="clearFilterCrSelect" pTooltip="Clear Filter" (click)="crSelectGlobalFilter.value = ''; crSelectTable.filterGlobal('', 'contains');"
                        style="margin:4px 4px 0 0"></i>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                    <tr>
                        <th style="width: 40px;"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                        <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                            {{col.header}}
                            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td id="noDataFoundMsg" colspan="6">No data found.</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" *ngFor="let participant of rowData.detail?.participants; let i = index">
                        <td *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length">
                            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                        </td>
                        <td id="crLink" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" >
                            <span *ngIf="app.sharedData.appInfo.isElectronMode">
                                <a  (click)="app.goToUrl('https://orbit/cr/'+rowData.changeRequestId)" 
                                    style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word">
                                    {{rowData.changeRequestId}}
                                </a>
                            </span>
                            <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                                <a href="{{'https://orbit/cr/'+rowData.changeRequestId}}" target="_blank"
                                    style="cursor: pointer; text-decoration: underline; color: blue; word-wrap: break-word">
                                    {{rowData.changeRequestId}}
                                </a>
                            </span>
                        </td>
                        <td id="crTitle" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                            {{rowData.detail.title}}
                        </td>
                        <td id="crArea" style="word-wrap: break-word">{{participant.area}}</td>
                        <td id="crSubSystem" style="word-wrap: break-word">{{participant.subsystem}}</td>
                        <td id="crFunctionality" style="word-wrap: break-word">{{participant.functionality}}</td>
                        <td id="crStatus" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                            {{rowData.currentInfo?.status}}
                        </td>
                        <td id="crAssignee" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                            {{(rowData.currentInfo?.assignee === '' || rowData.currentInfo?.assignee === 'stardustsvc')? rowData.detail.assigneeUid : rowData.currentInfo?.assignee}}
                        </td>
                        <td id="crRequestedDate" *ngIf="i===0" [attr.rowspan]="rowData.detail.participants.length" style="word-wrap: break-word">
                            {{rowData.changeRequestDate}}
                        </td>
                    </tr>
                </ng-template>
            </p-table>
            
            <p-footer>      
                <div class="ui-g" *ngIf="buildSiSpProcess.message !== ''" >
                    <div class="ui-g-12" class="message message-error">
                    {{buildSiSpProcess.message}}
                    </div>
                </div>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                    <button pButton type="button" [style]="{width: '100px'}" label="Next >" (click)="emailImageInfo(emailProcess.image)">
                    </button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
    <div class="card" *ngIf="localFileUploadProcess.showLocalFileUploadDialog
                             || remoteAutoCommitProcess.displayForm
                             || remoteAutoCommitProcess.displayResultForm
                             || localFileUploadProcess.showLocalFileSummaryDialog
                             || localFileUploadProcess.showLocalFileFinalDialog">
        <p-dialog id="fileUploadDialogBoxHeader" header="File Manager" [(visible)]="localFileUploadProcess.showLocalFileUploadDialog" modal="modal"
        showEffect="fade" [style]="{width: '900px'}">
            <p-tabView [(activeIndex)]="localFileUploadProcess.tabIndex">
                <p-tabPanel header="Local Upload" leftIcon="ui-icon-file-upload">
            <div class="ui-g">
                <div class="ui-g-12">
                    <span id="localFileUploadST">Upload file(s) to Service Task {{app.sharedData.service.common.selectedServiceTaskID}}</span>
                    <i class="ui-icon-remove-red-eye" id="viewPreviousUploads" pTooltip="View Previous Uploads" (click)="showLocalFileUploadProgress(localFileUploadProcess.localUploadImage.detail?.perforceDetails?.path)" style="float:right">
                    </i>
                </div>
            </div>
            <div class="col-12 rmpm dropzone" appDrag (files)="filesDropped($event)">
                <div class="text-wrapper">
                    <div class="centered">
                        <span>Drop file to upload,or    
                            <label>click to browse
                            <input type="file" id="file" style="display: none"  (change)="filesDropped($event)" multiple>
                            </label>
                        </span>
                    </div>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <p-table id="filesSelectedTable" #filesSelectedTable [value]="localFileUploadProcess.filesInfo" [rows]="5">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width: 100px;">Filename</th>
                                <th style="width: 40px;">New File</th>
                                <th>Relative File Path</th>
                                <th style="width: 70px;">File Size</th>
                                <th style="width: 100px;">Status</th>
                                <th style="width: 40px;"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="5">No files selected.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                            <tr>
                                <td>
                                    {{rowData.filename}}
                                </td>
                                <td>
                                    <!--Cirrus Local New File Checkbox section-->
                                    <div *ngIf="localFileUploadProcess.localUploadImage.buildSystem !== 'BAIT'">
                                        <p-checkbox id="CirrusnewPath" [binary]="true"
                                        [(ngModel)]="rowData.newFile"  (ngModelChange)="rowData.status = FileStatus.Init;resetCirrusRelativePathSection(i);onLocalFileUploadInputValidation()">
                                        </p-checkbox>
                                    </div>
                                    <!--Bait Local New File Checkbox section-->
                                    <div *ngIf="localFileUploadProcess.localUploadImage.buildSystem === 'BAIT'">
                                        <p-checkbox id="BaitnewPath" [binary]="true"
                                        [(ngModel)]="rowData.newFile"  (ngModelChange)="rowData.status = FileStatus.Init;onLocalFileUploadInputValidation()">
                                        </p-checkbox>
                                    </div>
                                             
                                </td>
                                <td>
                                    <!--Cirrus Local Relative FilePath suggestion section-->
                                    <div *ngIf="localFileUploadProcess.localUploadImage.buildSystem !== 'BAIT'">
                                        
                                        <span *ngIf="rowData.newFile===false">
                                                <span class="ui-g-11">
                                                    <p-dropdown  id="selectfilePathCirrus" [options]="rowData.LocalRelativeFilePathMatches" (click)="serachLocalFilePathsForCirrusUpload(rowData.filename,true,i)" (onChange)="rowData.status = FileStatus.Init;onLocalFileUploadInputValidation()" placeholder="Select Relative File Path"
                                                    [(ngModel)]="rowData.filePath" filter="true" [virtualScroll]="true" itemSize="30">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        {{item.label}}
                                                    </ng-template>
                                                    <ng-template let-item pTemplate="item">
                                                        {{item.label}}
                                                    </ng-template>
                                                    </p-dropdown>
                                                </span>
                                                <span class="ui-g-1">
                                                    <p-progressSpinner *ngIf="rowData.loadingRelativeFilePaths===true" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                                                </span>
                                        </span>
                                        <!--Cirrus New file : Workspace_Root search disabled :componentBranchDevFR flag is false -->
                                        <span *ngIf=" app.sharedData.devFeature.componentBranchDevFR===true && rowData.newFile===true">
                                            <span class="ui-g-6">
                                                <p-dropdown  id="selectworkspaceRootPathCirrus" [options]="rowData.workspace_rootPathMatches" (click)="searchWorkspaceRootForCirrus(rowData,i)" (onChange)="rowData.status = FileStatus.Init;onLocalFileUploadInputValidation()" placeholder="Select Root Workspace"
                                                [(ngModel)]="rowData.workspaceRootPath" filter="true" [virtualScroll]="true" itemSize="30">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    {{item.label}}
                                                </ng-template>
                                                <ng-template let-item pTemplate="item">
                                                    {{item.label}}
                                                </ng-template>
                                                </p-dropdown>
                                            </span>
                                            <span class="ui-g-1">
                                                <p-progressSpinner *ngIf="rowData.loadingWorkspaceRoot===true" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                                            </span>
                                            <span class="ui-g-5">
                                                <input type="text" id="filePathInputCirrus" pInputText [(ngModel)]="rowData.extraInputFilePath"  (ngModelChange)="rowData.status = FileStatus.Init;rowData.filePath=rowData.workspaceRootPath+rowData.extraInputFilePath;onLocalFileUploadInputValidation();" placeholder="Eg: cta_core/cta_diag.c">
                                            </span>
                                            
                                        </span>
                                        <!--Cirrus New file :Existing flow -->
                                        <span *ngIf="rowData.newFile===true">
                                            <input type="text" id="filePathInputCirrus" pInputText [(ngModel)]="rowData.filePath"  (ngModelChange)="rowData.status = FileStatus.Init;onLocalFileUploadInputValidation()" placeholder="Eg: /modem_proc/cta/cta_core/cta_diag.c">
                                        </span> 

                                    </div>

                                    <!--Bait Local Relative FilePath suggestion section-->
                                    <div *ngIf="localFileUploadProcess.localUploadImage.buildSystem === 'BAIT'">
                                        
                                        <span *ngIf="rowData.newFile===false">
                                                <span class="ui-g-11">
                                                    <p-dropdown  id="selectfilePathBait" [options]="rowData.LocalRelativeFilePathMatches" (click)="serachLocalFilePathsForBaitUpload(rowData.filename,i)" (onChange)="rowData.status = FileStatus.Init;onLocalFileUploadInputValidation()" placeholder="Select Relative File Path"
                                                    [(ngModel)]="rowData.filePath" filter="true" [virtualScroll]="true" itemSize="30">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        {{item.label}}
                                                    </ng-template>
                                                    <ng-template let-item pTemplate="item">
                                                        {{item.label}}
                                                    </ng-template>
                                                    </p-dropdown>
                                                </span>
                                                <span class="ui-g-1">
                                                    <p-progressSpinner *ngIf="rowData.loadingRelativeFilePaths===true" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                                                </span>
                                        </span>
                                        <span *ngIf="rowData.newFile===true">
                                            <input  *ngIf="localFileUploadProcess.localUploadImage.buildSystem === 'BAIT'" type="text" id="filePathInputBait" pInputText [(ngModel)]="rowData.filePath"  (ngModelChange)="rowData.status = FileStatus.Init;onLocalFileUploadInputValidation()" placeholder="Eg: /modem_proc/cta/cta_core/cta_diag.c">
                                        </span>   
                                    </div>

                                </td>
                                <td>{{(rowData.filesize/1000000).toFixed(2)}}MB</td>
                                <td>{{rowData.status}}</td>
                                <td id="actionIcon">
                                    <div class="action-icon">
                                        <p-progressSpinner *ngIf="rowData.validationInProgress || rowData.uploadInProgress" 
                                            [style]="{width: '20px', height: '20px'}"
                                            strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                                        </p-progressSpinner>
                                        <i class="pi pi-times" id="removeLocalFile" *ngIf="!rowData.validationInProgress && !rowData.uploadInProgress"  (click)="onRemoveLocalFile(i)" pTooltip="Remove"></i>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-3">
                    <span><b>Change Request: </b></span>
                </div>
                <div class="ui-g-9">
                    <span>
                    <input id="crInput" [(ngModel)]="localFileUploadProcess.crNumber" [disabled] = "localFileUploadProcess.isCRPresent==true"
                        placeholder="Enter CR Number " 
                        pInputText autoResize="autoResize" [style]="{width: '100%'}" (ngModelChange)="onLocalFileUploadInputValidation()">
                    </span>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-3">
                    <span><b>Commit Message: </b></span>
                </div>
                <div class="ui-g-9">
                    <span>
                    <input id="commitMessageInput" [(ngModel)]="localFileUploadProcess.commitMessage" placeholder="Enter commit message"
                    pInputText autoResize="autoResize" [style]="{width: '100%',height: '20px'}"(ngModelChange)="onLocalFileUploadInputValidation()">
                    </span>
                </div>
            </div>
                </p-tabPanel>
                <p-tabPanel header="Remote Upload" *ngIf="localFileUploadProcess.localUploadImage.buildSystem !== 'BAIT'" leftIcon="ui-icon-cloud-upload">
                    <div class="ui-g">
                        <div class="ui-g-12">
                            <span id="localFileUploadST">Auto commit to Service Task {{app.sharedData.service.common.selectedServiceTaskID}}</span>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            <span><b>Commit Message</b></span>
                        </div>
                        <div class="ui-g-1">:</div>
                        <div class="ui-g-8">
                            <input type="text" id="commitMessage" pInputText [(ngModel)]="remoteAutoCommitProcess.commitMessage">
                        </div>
                    </div>
                    <!--div class="ui-g">
                        <div class="ui-g-3">
                            <span><b>Select Change</b></span>
                        </div>
                        <div class="ui-g-1">:</div>
                        <div class="ui-g-8">
                            <p-checkbox id="isChangeList" [(ngModel)]="remoteAutoCommitProcess.isChangeList" binary="true"></p-checkbox>
                        </div>
                    </div-->
                    <div class="ui-g">
                        <div class="ui-g-3">
                            <span><b>Changelist/Label </b></span>
                        </div>
                        <div class="ui-g-1">:</div>
                        <div class="ui-g-8">
                            <input type="number" min="0" placeholder="Eg: 123456" id="changelist" pInputText [(ngModel)]="remoteAutoCommitProcess.changeListLabel">
                        </div>
                    </div>
                    <!--div class="ui-g">
                        <div class="ui-g-3">
                            <span><b>Shelved </b></span>
                        </div>
                        <div class="ui-g-1">:</div>
                        <div class="ui-g-8">
                            <p-selectButton id="isShelved" [options]="remoteAutoCommitProcess.shelvedptions" [(ngModel)]="remoteAutoCommitProcess.isShelved" [style]="{'width':'100px'}"></p-selectButton>
                        </div>
                    </div-->
                    <div class="ui-g">
                        <div class="ui-g-3">
                            <span><b>Client Spec </b></span>
                        </div>
                        <div class="ui-g-1">:</div>
                        <div class="ui-g-8">
                            <p-selectButton id="clientSpecType" [options]="remoteAutoCommitProcess.clientSpecOptions" [(ngModel)]="remoteAutoCommitProcess.clientSpecType" [style]="{'width':'180px'}"></p-selectButton>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-4">
                        </div>
                        <div class="ui-g-8">
                            <input type="text" id="predefinedTarget" *ngIf="remoteAutoCommitProcess.clientSpecType === 'predefined'" pInputText [(ngModel)]="remoteAutoCommitProcess.predefinedTarget" placeholder="Eg: CRM_AOP.HO.2.0-00142-SM8250_E-1">
                            <textarea id="customTarget" *ngIf="remoteAutoCommitProcess.clientSpecType === 'custom'" [(ngModel)]="remoteAutoCommitProcess.customTarget" 
                                pInputTextarea [rows]="5" autoResize="autoResize" [style]="{width: '100%'}">
                            </textarea>
                            <div *ngIf="remoteAutoCommitProcess.clientSpecType === 'custom'" style="padding: 15px;margin-bottom: 18px;border: 1px solid transparent;border-radius: 4px;background-color: #d9edf7;border-color: #bce8f1;color: #31708f;margin-top: 2px;">
                                The workspace must be prefixed with "//CRM_*/" followed by the distributable folder name (DistroPath).
                                <br>
                                Example:
                                <br>
                                <br>
                                //depot/projects/modem_proc/... //CRM_foobar/modem_proc/...
                                <br>
                                //depot/projects/rpm_proc/... //CRM_foobar/rpm_proc/...
                            </div>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Delete Files" *ngIf="localFileUploadProcess.localUploadImage.buildSystem !== 'BAIT'" leftIcon="ui-icon-delete">
                    <div class="ui-g">
                        <div class="ui-g-12">
                            <span id="localFileDeleteST">Delete files from Service Task {{app.sharedData.service.common.selectedServiceTaskID}}</span>
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            <span><b>Commit Message : </b></span>
                        </div>
                        <div class="ui-g-9">
                            <input type="text" id="localFileDeleteProcesscommitMessage" pInputText [(ngModel)]="localFileDeleteProcess.commitMessage">
                        </div>
                    </div>
                    <div class="ui-g">
                        <div class="ui-g-3">
                            <span><b>Select Files : </b></span>
                        </div>
                        <div class="ui-g-9">
                            <span class="ui-fluid">
                                <p-autoComplete [(ngModel)]="localFileDeleteProcess.files" [suggestions]="localFileDeleteProcess.multipleFileMatches" (completeMethod)="serachLocalFilePathsForCirrusDelete($event,false)" styleClass="wid100"
                                 emptyMessage="No Matches Found" [minLength]="2" placeholder="Enter File Name" [multiple]="true" [inputStyle]="{'width':'85%'}">
                                </p-autoComplete>
                            </span>
                        </div>
                    </div>
                </p-tabPanel>

            </p-tabView>
            <p-footer>
                <div *ngIf="localFileUploadProcess.tabIndex === 0" class="ui-dialog-buttonpane ui-helper-clearfix">
                    <div class="ui-g-12 message message-error" *ngIf="localFileUploadProcess.errorMessage !== ''">
                        <span id="localFileUploadErrorMessage">{{localFileUploadProcess.errorMessage}}</span>
                    </div>
                    <div class="ui-g-12">
                        <p-progressSpinner *ngIf="localFileUploadProcess.pathValidationInProgress !== 0"
                            [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                        </p-progressSpinner>
                        <!--utton id="localUploadNext" pButton type="button" (click)="onLocalFileUploadNextClicked()" label="Next >"  [disabled]="((app.sharedData.service.common.master && localFileUploadProcess.crNumber=='') || localFileUploadProcess.pathInvalid || localFileUploadProcess.commitMessage==localFileUploadProcess.prevCommitMessage||localFileUploadProcess.commitMessage==''||localFileUploadProcess.files.length === 0 || localFileUploadProcess.pathValidationInProgress !== 0 || (this.localFileUploadProcess.totalFileSizeInByte > this.localFileUploadProcess.maxFileSizeInByte))"></button-->
                        <button id="localUploadNext" pButton type="button" (click)="onLocalFileUploadNextClicked()" label="Next >"  [disabled]="((app.sharedData.service.common.master && localFileUploadProcess.crNumber=='') || localFileUploadProcess.pathInvalid || localFileUploadProcess.commitMessage==localFileUploadProcess.prevCommitMessage||localFileUploadProcess.commitMessage==''||localFileUploadProcess.files.length === 0 || localFileUploadProcess.pathValidationInProgress !== 0)"></button>
                    </div>
                </div>
                <div *ngIf="localFileUploadProcess.tabIndex === 1" class="ui-dialog-buttonpane ui-helper-clearfix">
                    <div class="ui-g-12 message message-error" *ngIf="remoteAutoCommitProcess.errorMessage !== ''">
                        <span id="remoteAutoCommitErrorMessage">{{remoteAutoCommitProcess.errorMessage}}</span>
                    </div>
                    <div class="ui-g-12">
                        <p-progressSpinner *ngIf="remoteAutoCommitProcess.commitInProgress"
                            [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                        </p-progressSpinner>
                        <button id="localUploadNext" pButton type="button" (click)="remoteAutoCommit()" label="Commit"  [disabled]="remoteAutoCommitProcess.commitInProgress || remoteAutoCommitProcess.changeListLabel == undefined || remoteAutoCommitProcess.changeListLabel == '' || remoteAutoCommitProcess.changeListLabel ==null
                                                                                                                                    || (remoteAutoCommitProcess.clientSpecType === 'predefined' && remoteAutoCommitProcess.predefinedTarget === undefined)
                                                                                                                                    || (remoteAutoCommitProcess.clientSpecType === 'custom' && remoteAutoCommitProcess.customTarget === undefined)">
                        </button>
                    </div>
                </div>
                <div *ngIf="localFileUploadProcess.tabIndex === 2" class="ui-dialog-buttonpane ui-helper-clearfix">
                    <div class="ui-g-12 message message-error" *ngIf="localFileDeleteProcess.errorMessage !== ''">
                        <span id="localFileDeleteProcessErrorMessage">{{localFileDeleteProcess.errorMessage}}</span>
                    </div>
                    <div class="ui-g-12">
                        <p-progressSpinner *ngIf="localFileDeleteProcess.deleteInProgress"
                            [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                        </p-progressSpinner>
                        <button id="localdelete" pButton type="button" (click)="localFilesDelete()" label="Delete"  [disabled]="localFileDeleteProcess.deleteInProgress || localFileDeleteProcess.commitMessage === undefined || localFileDeleteProcess.commitMessage === '' || localFileDeleteProcess.files?.length==0">
                        </button>
                    </div>
                </div>
            </p-footer>
        </p-dialog>
        <p-dialog id="localUploadDialogBoxSummary" header="File Upload Summary" [(visible)]="localFileUploadProcess.showLocalFileSummaryDialog" modal="modal"
        showEffect="fade" [style]="{width: '900px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                    <p-table id="filesToUploadTable" #filesToUploadTable [value]="localFileUploadProcess.filesInfo" [rows]="5">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width: 120px;">Filename</th>
                                <th style="width: 250px;">Path</th>
                                <th style="width: 100px;">Status</th>
                                <th style="width: 100;">Requested By</th>
                                <th style="width: 100;">Requested On</th>
                                <!-- <th style="width: 100;">Completed On</th> -->
                                <th style="width: 40px;"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="4">No files selected.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                            <tr>
                                <td>
                                    {{rowData.filename}}
                                </td>
                                <td>
                                    {{rowData.filePath}}
                                    <p-progressBar *ngIf="rowData.uploadProgress !== 0" [value]="rowData.uploadProgress"  [style]="{'height': '6px'}"></p-progressBar>
                                </td>
                                <td>{{rowData.status}}</td>
                                <td>{{this.app.sharedData.userInfo.username}}</td>
                                <td>{{rowData.requested_date}}</td>
                                <!-- <td>{{rowData.completed_date}}</td> -->
                                <td id="actionIcon">
                                    <div class="action-icon">
                                        <i class="pi pi-times" id="deleteSelectedLocalfile" *ngIf="!rowData.validationInProgress && !rowData.uploadInProgress && rowData.status !== FileStatus.UploadInProgress && rowData.status !== FileStatus.UploadComplete"  (click)="onRemoveLocalFile(i)" pTooltip="Remove"></i>
                                        <p-progressSpinner *ngIf="rowData.status === FileStatus.UploadInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                    <div class="ui-g-12 message message-error" *ngIf="localFileUploadProcess.errorMessage !== ''">
                        <span id="localFileUploadErrorMessage">{{localFileUploadProcess.errorMessage}}</span>
                    </div>
                    <div class="ui-g-12">
                        <p-progressSpinner *ngIf="localFileUploadProcess.uploadInProgress"
                            [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                        </p-progressSpinner>
                        <button id="localUploadBack" pButton type="button" (click)="onLocalFileUploadBackClicked()" label="< Back"  [disabled]="localFileUploadProcess.uploadInProgress"></button>
                        <!--button id="localUploadSubmit" pButton type="button" (click)="submitFile()" label="Submit"  [disabled]="localFileUploadProcess.uploadInProgress || localFileUploadProcess.files.length === 0"></button-->
                        <button id="localUploadSubmit" pButton type="button" (click)="submitFileInitiate()" label="Submit"  [disabled]="localFileUploadProcess.uploadInProgress || localFileUploadProcess.files.length === 0"></button>
                    </div>
                </div>
            </p-footer>
        </p-dialog>
        <p-dialog id="localUploadDialogBoxSummary" header="File Upload Summary" [(visible)]="localFileUploadProcess.showLocalFileFinalDialog" modal="modal"
        showEffect="fade" [style]="{width: '900px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                    Change list number: {{localFileUploadProcess.filesUploaded?.changelist}}
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <p-table id="filesUploadedTable" #filesUploadedTable [value]="localFileUploadProcess.filesUploaded?.files" [rows]="5">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th>File</th>
                                <th style="width: 100px;">Status</th>
                                <th style="width: 100;">Requested By</th>
                                <th style="width: 100;">Requested On</th>
                                <th style="width: 100;">Completed On</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="2">No files selected.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>
                                    {{rowData.depot_file}}
                                </td>
                                <td>
                                    <span *ngIf="rowData.action === 'edit'">Updated</span>
                                    <span *ngIf="rowData.action === 'add'">Added</span>
                                </td>
                                <td>{{rowData.localFileSessionDetails.requester}</td>
                                <td>{{rowData.requested_date}}</td>
                                <td>{{rowData.completed_date}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    Note: This request may take several minutes to update the history table.
                </div>
            </div>
        </p-dialog>

        <p-dialog id="localDeleteDialogBoxSummary" header="File Delete Summary" [(visible)]="localFileDeleteProcess.showLocalFileDeleteSuccessDialog" modal="modal"
        showEffect="fade" [style]="{width: '700px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                    Change list number: {{localFileDeleteProcess.localFilesDeleteResult?.changelist}}
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <p-table id="filesDeletedTable" #filesDeletedTable [value]="localFileDeleteProcess.localFilesDeleteResult?.files" [rows]="5">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th>File</th>
                                <th style="width: 100px;">Revision</th>
                                <th style="width: 100px;">Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDeleteDataFoundMsg" colspan="2">No files selected.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>
                                    {{rowData?.depot_file}}
                                </td>
                                <td>{{rowData?.revision}}</td>
                                <td>
                                    <span *ngIf="rowData?.action === 'delete'">Deleted</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    Note: This request may take several minutes to update the history table.
                </div>
            </div>
        </p-dialog>

        <p-dialog id="localUploadDialogBoxSuccess" header="File Upload" [(visible)]="localFileUploadProcess.showLocalFileFinalDialogScuccess" modal="modal"
        showEffect="fade" [style]="{width: '700px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                    The file upload request placed successfully. Please check the history table for updates.
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    Note: This request may take several minutes to update the history table.
                </div>
            </div>
        </p-dialog>
                
        <p-dialog id="remoteUploadDialogBoxSummary" header="File Upload Summary" [(visible)]="remoteAutoCommitProcess.displayResultForm" modal="modal"
        showEffect="fade" [style]="{width: '700px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                    Change list number: {{remoteAutoCommitProcess.commitResult?.changelist}}
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <p-table id="filesUploadedTable" #filesUploadedTable [value]="remoteAutoCommitProcess.commitResult?.files" [rows]="5">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th>File</th>
                                <th style="width: 100px;">Action</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="2">No files selected.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td>
                                    {{rowData.depot_file}}
                                </td>
                                <td>
                                    {{rowData.action}}
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    Note: This request may take several minutes to update the history table.
                </div>
            </div>
        </p-dialog>
        
    </div>
    <div class="card" *ngIf="localFileUploadProgress.displayProgress">
        <p-dialog id="localUploadDialogBoxProgress" header="Local File Upload Progress" [(visible)]="localFileUploadProgress.displayProgress" modal="modal"
        showEffect="fade" [style]="{width: '865px'}">
            <div class="ui-g">
                <div class="ui-g-12">
                    Note: This request may take several minutes to update the history table. Please check history table later.
                </div>
            </div>
            <div class="ui-g">
                <div class="ui-g-12">
                    <p-table id="filesInProgressTable" #filesInProgressTable [value]="localFileUploadProgress.files" [rows]="10" [loading] = "localFileUploadProgress.loadingSessions" [paginator]="true">
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th style="width: 50px;">Session</th>
                                <th style="width: 300;">FilePaths</th>
                                <th style="width: 110px;">Status</th>
                                <th style="width: 110;">Requested By</th>
                                <th style="width: 120;">Requested On</th>
                                <th style="width: 120;">Completed On</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="3">No files.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                            <tr>
                                <td>
                                    {{rowData.localFileSessionDetails.sessionId}}
                                </td>
                                <td>{{rowData.filePaths}}</td>
                                <td>
                                    <span *ngIf="rowData.localFileSessionDetails.sessionStatus <= 3 ">In Progress</span>
                                    <span *ngIf="rowData.localFileSessionDetails.sessionStatus === 4">Upload Complete</span>
                                    <span *ngIf="rowData.localFileSessionDetails.sessionStatus === 5">Upload Failed</span>
                                </td>
                                <td>{{rowData.localFileSessionDetails.requester}}</td>
                                <td>{{rowData.localFileSessionDetails.uploadRequestedDate}}</td>
                                <td>{{rowData.localFileSessionDetails.checkinCompletedDate}}</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <p-footer>
                <div class="ui-g-12 message message-error" *ngIf="localFileUploadProgress.errorMessage !== ''">
                    <span id="localFileUploadSessionsErrorMessage">{{localFileUploadProgress.errorMessage}}</span>
                </div>
            </p-footer>
        </p-dialog>
    </div>

    <!--Trigger PassThrough SI-->
    <div class="card" *ngIf="triggerPassThroughSiProcess.displayForm">
        <p-dialog header="Trigger SI" [(visible)]="triggerPassThroughSiProcess.displayForm"  modal="modal" showEffect="fade" [style]="{width: '600px'}">
              <div class="ui-g">
                  <div class="ui-g-12" *ngIf="triggerPassThroughSiProcess.successMessage === ''">
                    <h4> Are you sure you want to trigger SI build for {{triggerPassThroughSiProcess.selectedPassThroughImage.baseImage}} ?</h4>
                  </div>
                  <div class="ui-g-12 message" *ngIf="triggerPassThroughSiProcess.successMessage !== ''"  style="padding-top: 0px;">
                    {{triggerPassThroughSiProcess.successMessage}}
                  </div>
              </div>
              <p-footer>
                <div class="ui-g-12 message message-error" *ngIf="triggerPassThroughSiProcess.errorMessage !== ''"  style="padding-top: 0px;">
                    {{triggerPassThroughSiProcess.errorMessage}}
                </div>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                  <p-progressSpinner *ngIf="triggerPassThroughSiProcess.loadingProcess" [style]="{width: '20px', height: '20px', margin: '0 4px 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                  <span *ngIf="triggerPassThroughSiProcess.successMessage===''">
                  <button pButton id="triggerPassThroughSiProcessYesBtn" type="button" (click)="onTriggerPassThroughSiSubmit(triggerPassThroughSiProcess.selectedPassThroughImage)" label="Yes" [disabled]="triggerPassThroughSiProcess.loadingProcess"></button>
                  <button pButton id="triggerPassThroughSiProcessNoBtn" type="button" (click)="resetTriggerPassThroughSiProcess()" label="No" [disabled]="triggerPassThroughSiProcess.loadingProcess"></button>
                  </span>

                  <span *ngIf="triggerPassThroughSiProcess.successMessage!==''">
                  <button  pButton id="triggerPassThroughSiProcessClose" type="button" (click)="resetTriggerPassThroughSiProcess()" label="Close"></button>
                  </span>
                </div>
              </p-footer>
        </p-dialog>
    </div>
    <!--Add Component to Branch-->
    <div class="card" *ngIf="addComponentToBranchProcess.displayForm">
        <p-dialog header="Add Component To Branch" [(visible)]="addComponentToBranchProcess.displayForm"  modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <p-table id="ComponentBranchTable" #addComponentToBranchtable [columns]="cirrusComponentCol" [value]="addComponentToBranchProcess.selectedCirrusImage.detail.components" [paginator]="true" [rows]="10" [loading]="addComponentToBranchProcess.selectedCirrusImage.loadingBranchComponents"
                [globalFilterFields]="['base_name', 'status']">

            <ng-template pTemplate="caption">
                <div class="ui-g">
                  <div class="ui-g-12" style="padding: 0px;">
                    <div style="float: left">
                        <i id="searchFilterComponent" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input #addComponentToBranchFilter class="filter-clear" type="text" id="filterCr" pInputText size="40" placeholder="Filter"
                            (input)="addComponentToBranchtable.filterGlobal($event.target.value, 'contains')" style="width:75%; color: white;">
                        <i class="ui-icon-cancel" id="clearFilterComponent" pTooltip="Clear Filter" (click)="onFilterClearComponentBranch()"
                            style="margin:4px 4px 0 0"></i>
                        <i class="ui-icon-refresh" pTooltip="Refresh" (click)="loadServiceTaskBuildDetailsWithComponents(addComponentToBranchProcess.selectedCirrusImage,true)" style="font-size: 1.7em;margin:4px 4px 0 0;cursor:pointer"></i>
                    </div>
                    <div style="float: right;">  
                        <button pButton pRipple label="Add All" icon="pi pi-plus" class="p-button-text" style="width: auto;" (click)="onAddAllComponentToBranch()" 
                        [disabled]="addComponentToBranchProcess.selectedCirrusImage.loadingBranchComponents ||addComponentToBranchProcess.isComponentAddingInprogress" *ngIf="app.sharedData.userInfo.isMasterServiceTaskManager && addComponentToBranchProcess.showAddAllBtn" ></button>
                    </div>
                  </div>
                </div>
              </ng-template>

              
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                        {{col.header}}
                        <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td id="noDataFoundMsg" colspan="4">No data found.</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData">
                    <td id="componentBaseName">
                        {{rowData.base_name}}
                    </td>
                    <td id="status">
                        {{rowData.status}}
                    </td>                   
                    <td id="Action">

                        <span class="action-icon" *ngIf="rowData.status!=='Branched'">
                            <i class="ui-icon-plus" id="AddComponent" pTooltip="Add Component" *ngIf="rowData.addInprogress === false" (click)="onAddSingleComponentToBranch(rowData)"></i>
                            <p-progressSpinner id="addComponentBranchSpinner" *ngIf="rowData.addInprogress === true" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                        </span>
                    </td>
                </tr>
            </ng-template>
            </p-table>
        </p-dialog>
    </div>

</div>