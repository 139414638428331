import { Injectable } from '@angular/core';
import isElectron from 'is-electron';
import { Observable } from 'rxjs';
import { LimeResponse, RestResponse, QPMResponse, RestErrorResponse } from '../../models/response';
import { WebClientService } from '../Contract/WebClientService';
import { PaymentIntentRequest, ServiceTaskRequest, UpdateServiceTaskRequest, TriggerBuildRequest, ValidateCRRequest,TriggerProductBuildRequest, DistributionRequest,MasterTriggerBuildRequest,DiffDistributionRequest, AutoShipRequest, AutoShipRequestSP, Email, TriggerBaitBuildRequest, TriageDeleteCrRequest, TriageDeleteGerritRequest, CrGerritComment, JiraTicketRequest ,MemberUpdateRequest,ImageBuildDistributionRequest, CrFixDetailRequest, CveCrMappingRequest, AutoPropagationRequest, FilePathValidationRequest, LocalFileUploadRequest, SearchUpdateCrFixDetailRequest, JiraFilesUploadRequest, RemoteAutoCommitRequest, PricingDetailsRequest, LicenseSeatDeactivateRequest, LocalFileUploadInitiateRequest, s3FileUploadContinueRequest, LocalFileUploadCompleteRequest, BaitSystemTriggerBuildRequest, BaitFileUploadCompleteRequest, BaitFileUploadInitRequest, S3UploadCompleteRequest, LocalFileUploadSessionStatus, LocalFileUploadSessionRequest, FilePathSearchRequest, LocalFilesDeleteRequest, FilePathSearchRequestForBait} from 'src/app/models/lime-web-client';
import { RestWebClientService } from '../RestService/rest-web-client.service';
import { ServiceUtils } from '../ServiceUtils';
import { SoftwareImage } from 'src/app/models/software-catalog-client';
import { environment } from 'src/environments/environment';
import { BinDdmJiraTicketInfoRequest, NoticeFileDownloadRequest, NoticeFileUploadCompleteRequest, NoticeFileUploadStartRequest, TriggerPassThroughSIRequest, UpdatePassThroughRequest } from 'src/app/models/distribution-client';

@Injectable()
export class LimeWebClientService implements WebClientService{
  private limeWebClient;
  private catalogClient;
  private limeClient;
  private isProcessorArm64:boolean;
  constructor(private serviceUtils : ServiceUtils, private webClientRestApi: RestWebClientService) {
      if(isElectron()){
        var lime = window.require('bindings')('addon.node');
        this.limeWebClient = new lime.LimeWebClientWrapper();        
        if(environment.production){
          this.limeWebClient.initialize(false);
        }
        else{
          this.limeWebClient.initialize(true);
        }
        this.catalogClient = new lime.CatalogClientWrapper();
        this.limeClient = new lime.LimeClientWrapper();
        this.setIsProcessorArm64();
      }
   }
   private setIsProcessorArm64()
   {
    var response  = this.limeClient.IsProcessorARM64();
    var respModel = JSON.parse(response);
    this.isProcessorArm64 = (respModel.data == "true");
    console.log("isProcessorARM64: " + this.isProcessorArm64);
   }
   /* Generic APIs */
   public getVersion() : Observable<LimeResponse>  {
     var response = this.limeWebClient.getVersion();
     return this.serviceUtils.getObservableLimeReponse(response);
   }
   
   public login(username?:string, password?:string) : Observable<LimeResponse> {
      var response;
      if(username === undefined || password === undefined){
       response = this.limeWebClient.login();
      }
      else{
        response = this.limeWebClient.login(username,password);
      }
      
      return this.serviceUtils.getObservableLimeReponse(response);
    }
  
  public logout() : Observable<LimeResponse>{
    var response = this.limeWebClient.logout();
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  public getToken() : Observable<LimeResponse>{
    var response = this.limeWebClient.getToken();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  
  public refreshToken() : Observable<LimeResponse>{
    var response = this.limeWebClient.refreshToken();
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  public checkMaintenance() :  Observable<RestResponse> {
    return this.webClientRestApi.checkMaintenance();
  }
  
  /* Tools APIs */
  getCatalogV3(username, OS) : Observable<QPMResponse>{
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      response = this.webClientRestApi.getCatalogV3(username, OS);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            this.catalogClient.catalogResponseV3(data.getData()); // TODO if needed
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.catalogClient.getCatalogV3();
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }
  getReleasesV3(productID, username, OS, numOfReleases) : Observable<QPMResponse>{
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      var requestParam = OS;
      if(isElectron && process.platform == "win32" && this.isProcessorArm64)requestParam += "&Arch=arm64";
      response = this.webClientRestApi.getReleasesV3(productID, username, requestParam, numOfReleases);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            //this.catalogClient.releasesV3Response(data.getData()); // TODO if needed
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.catalogClient.getReleasesV3(productID);
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }
  getReleasesV3ByTargetArch(productID, username, OS, numOfReleases, targetArch) : Observable<QPMResponse>{
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      var requestParam = OS + "&Arch=" + targetArch;
      response = this.webClientRestApi.getReleasesV3(productID, username, requestParam, numOfReleases);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            //this.catalogClient.releasesV3Response(data.getData()); // TODO if needed
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.catalogClient.getReleasesV3(productID);
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }
  getReleasesV3ByLinuxOSDistType(productID, username, OS, numOfReleases, OSDistType) : Observable<QPMResponse>{
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      var requestParam = OS + "&OSDistType=" + OSDistType;
      response = this.webClientRestApi.getReleasesV3(productID, username, requestParam, numOfReleases);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            //this.catalogClient.releasesV3Response(data.getData()); // TODO if needed
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.catalogClient.getReleasesV3(productID);
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }
  refreshProduct(productID, OS) : Observable<QPMResponse>{
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      response = this.webClientRestApi.refreshProduct(productID, OS);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            //this.catalogClient.productDetailsResponse(data.getData()); // TODO if needed
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.catalogClient.refreshProduct(productID);
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }

  refreshPackage(packageID, OS) : Observable<QPMResponse>{
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      response = this.webClientRestApi.refreshPackage(packageID, OS);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            //this.catalogClient.productDetailsResponse(data.getData()); // TODO if needed
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.catalogClient.getPackage(packageID);;
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }
  refreshAll() : Observable<LimeResponse>{
    var response = this.limeWebClient.refreshAll();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  activateLicense(productID, licenseID) : Observable<LimeResponse>{
    var response = this.limeWebClient.activateLicense(productID, licenseID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  activateLicenseByProduct(productID) : Observable<LimeResponse>{
    var response = this.limeWebClient.activateLicenseByProduct(productID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  deactivateLicense(licenseID) : Observable<LimeResponse>{
    var response = this.limeWebClient.deactivateLicense(licenseID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  verifyLicenses() : Observable<LimeResponse>{
    var response = this.limeWebClient.verifyLicenses();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  recoverLicenses() : Observable<LimeResponse>{
    var response = this.limeWebClient.recoverLicenses();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  getEntitlementsForProduct(productID) : Observable<LimeResponse>{
    var response = this.limeWebClient.getEntitlementsForProduct(productID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  downloadPackage(packageID) : Observable<LimeResponse>{
    var response = this.limeWebClient.downloadPackage(packageID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  getSignedURL(tokenId): Observable<RestResponse> {
    return this.webClientRestApi.getSignedURL(tokenId);
  }
  getReleaseNotes(tokenId): Observable<RestResponse> {
    return this.webClientRestApi.getReleaseNotes(tokenId);
  }
  public getFigureHeadCredentials() : Observable<LimeResponse>{
    var response = this.limeWebClient.getFigureHeadCredentials();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  getEntitilementsProduct(productId:string,OS:string):Observable<RestResponse> {
    return this.webClientRestApi.getEntitilementsProduct(productId,OS);
  }
  getNetworkStatus():Observable<RestResponse> {
    return this.webClientRestApi.getNetworkStatus();
  }
  public checkPkla(username?:string):Observable<RestResponse> {
    return this.webClientRestApi.checkPkla(username);
  }

  public getPackageListForComponent(componentId:string, os:string) : Observable<RestResponse>{
    return this.webClientRestApi.getPackageListForComponent(componentId, os);
  }
  /* Software APIs */
  refreshSoftwareProducts(): Observable<RestResponse> {
    return this.webClientRestApi.refreshSoftwareProducts();
  }
  refreshSoftwareProductDistros(softwareProductID:string): Observable<RestResponse> {
    return this.webClientRestApi.refreshSoftwareProductDistros(softwareProductID);
  }
  refreshCustomerProjects(): Observable<RestResponse> {
    return this.webClientRestApi.refreshCustomerProjects();
  }
  refreshSoftwareProductBuilds(softwareProductID:string): Observable<RestResponse> {
    return this.webClientRestApi.refreshSoftwareProductBuilds(softwareProductID);
  }
  getCrFixDetails(request: CrFixDetailRequest): Observable<RestResponse>{
    return this.webClientRestApi.getCrFixDetails(request);
  }
  getCrFixDetailsByRequest(requestID: string): Observable<RestResponse>{
    return this.webClientRestApi.getCrFixDetailsByRequest(requestID);
  }
  searchUpdateCrFixDetail(request: SearchUpdateCrFixDetailRequest): Observable<RestResponse>{
    return this.webClientRestApi.searchUpdateCrFixDetail(request);
  }
  createServiceTask(request: ServiceTaskRequest): Observable<RestResponse> {
    return this.webClientRestApi.createServiceTask(request);
  }
  updateServiceTask(serviceTaskID:string, request: UpdateServiceTaskRequest): Observable<RestResponse> {
    return this.webClientRestApi.updateServiceTask(serviceTaskID,request);
  }
  deleteServiceTaskCR(serviceTaskID: string, cr: TriageDeleteCrRequest): Observable<RestResponse> {
    return this.webClientRestApi.deleteServiceTaskCR(serviceTaskID, cr);
  }
  deleteServiceTaskGerrit(serviceTaskID: string, gerrit: TriageDeleteGerritRequest): Observable<RestResponse> {
    return this.webClientRestApi.deleteServiceTaskGerrit(serviceTaskID, gerrit);
  }
  updateServiceTaskBuildInternalClient(cirrusTaskId : string, internalClient : string): Observable<RestResponse>{
    return this.webClientRestApi.updateServiceTaskBuildInternalClient(cirrusTaskId, internalClient);
  }
  removeServiceTaskBuildInternalClient(cirrusTaskId: string): Observable<QPMResponse> {
    return this.webClientRestApi.removeServiceTaskBuildInternalClient(cirrusTaskId);
  }
  refreshServiceTasks(): Observable<RestResponse> {
    return this.webClientRestApi.refreshServiceTasks();
  }
  refreshServiceTaskDetails(serviceTaskID:string): Observable<RestResponse> {
    return  this.webClientRestApi.refreshServiceTaskDetails(serviceTaskID);
  }
  abortBaitBuild(serviceTaskID: string, buildId: string): Observable<RestResponse> {
    return this.webClientRestApi.abortBaitBuild(serviceTaskID, buildId);
  }
  triggerBaitBuild(request: TriggerBaitBuildRequest, serviceTaskID: string): Observable<RestResponse> {
    return this.webClientRestApi.triggerBaitBuild(request, serviceTaskID);
  }
  triggerBuild(request:TriggerBuildRequest): Observable<RestResponse> {
    return this.webClientRestApi.triggerBuild(request);
  }
  triggerCirrusBuild(cirrusID: string, fullBuild: boolean): Observable<RestResponse> {
    return this.webClientRestApi.triggerCirrusBuild(cirrusID, fullBuild);
  }
  triggerProductBuild(request: TriggerProductBuildRequest): Observable<RestResponse> {
    return this.webClientRestApi.triggerProductBuild(request);
  }
  triggerProductBuildWithDistroList(request: TriggerProductBuildRequest): Observable<RestResponse> {
    return this.webClientRestApi.triggerProductBuildWithDistroList(request);
  }
  triggerAutoShip(request: AutoShipRequest): Observable<RestResponse> {
    return this.webClientRestApi.triggerAutoShip(request);
  }
  triggerAutoShipSP(request: AutoShipRequestSP): Observable<RestResponse> {
    return this.webClientRestApi.triggerAutoShipSP(request);
  }
  triggerAutoShipSIAsync(request: AutoShipRequest): Observable<RestResponse> {
    return this.webClientRestApi.triggerAutoShipSIAsync(request);
  }
  triggerAutoShipSPAsync(request: AutoShipRequestSP): Observable<RestResponse> {
    return this.webClientRestApi.triggerAutoShipSPAsync(request);
  }
  retryCirrusBuildRequest(serviceTaskID: string, softwareImage: string, softwareImageBuild: string): Observable<RestResponse> {
    return this.webClientRestApi.retryCirrusBuildRequest(serviceTaskID, softwareImage, softwareImageBuild);
  }  
  recoverCirrusSeedBuild(cirrusTaskID: string): Observable<QPMResponse>{
    return this.webClientRestApi.recoverCirrusSeedBuild(cirrusTaskID);
  }
  verifyProductComposition(productBuild: string, serviceTaskID: string): Observable<RestResponse> {
    return this.webClientRestApi.verifyProductComposition(productBuild, serviceTaskID);
  }
  validateCR(request:ValidateCRRequest): Observable<RestResponse> {
    return this.webClientRestApi.validateCR(request);
  }
  getUserDetails(userID:string): Observable<RestResponse> {
    return this.webClientRestApi.getUserDetails(userID);
  }
  getChangeRequestDetails(crID:number): Observable<RestResponse> {
    return this.webClientRestApi.getChangeRequestDetails(crID);
  }
  getChangeRequestRelated(crID: number): Observable<RestResponse> {
    return this.webClientRestApi.getChangeRequestRelated(crID);
  }
  refreshServiceTaskBuilds(serviceTaskID:string): Observable<RestResponse> {
    return this.webClientRestApi.refreshServiceTaskBuilds(serviceTaskID);
  }
  refreshServiceTaskBuildDetails(serviceTaskID:string, cirrusID:string): Observable<RestResponse> {
   return this.webClientRestApi.refreshServiceTaskBuildDetails(serviceTaskID, cirrusID);
  }
  refreshServiceTaskBuildCRs(serviceTaskID:string, buildRequestID:string): Observable<RestResponse> {
    return  this.webClientRestApi.refreshServiceTaskBuildCRs(serviceTaskID, buildRequestID);
  }
  getAwsTempCredentials(): Observable<RestResponse> {
    return this.webClientRestApi.getAwsTempCredentials();
  }
  getDistrosShipStatus(request: DistributionRequest): Observable<RestResponse> {
    return this.webClientRestApi.getDistrosShipStatus(request);
  }
  masterTriggerBuild(request: MasterTriggerBuildRequest): Observable<RestResponse> {
    return this.webClientRestApi.masterTriggerBuild(request);
  }
  getDiffDistrosShipStatus(request:DiffDistributionRequest): Observable<RestResponse> {
    return this.webClientRestApi.getDiffDistrosShipStatus(request);
  
  }
  getSoftwareProductDiffBuilds(softwareProductBuildID:string) : Observable<RestResponse>{
    return this.webClientRestApi.getSoftwareProductDiffBuilds(softwareProductBuildID);
  }
  sendEmail(request: Email): Observable<RestResponse> {
    return this.webClientRestApi.sendEmail(request);
  }
  getCustomerInfo(orgID: number): Observable<RestResponse> {
    return this.webClientRestApi.getCustomerInfo(orgID);
  }
  getServiceTaskCRGerritComments(serviceTaskID:string, request: CrGerritComment): Observable<RestResponse> {
    return this.webClientRestApi.getServiceTaskCRGerritComments(serviceTaskID, request);
  }
  addServiceTaskCRGerritComment(serviceTaskID:string, request: CrGerritComment): Observable<RestResponse> {
    return this.webClientRestApi.addServiceTaskCRGerritComment(serviceTaskID, request);
  }
  getServiceTaskBaseImage(serviceTaskID: string): Observable<RestResponse> {
    return this.webClientRestApi.getServiceTaskBaseImage(serviceTaskID);
  }
  addServiceTaskBaseImage(serviceTaskID: string, softwareImage: SoftwareImage): Observable<RestResponse> {
    return this.webClientRestApi.addServiceTaskBaseImage(serviceTaskID, softwareImage);
  }
  deleteServiceTaskBaseImage(serviceTaskID: string, softwareImageBuild: string): Observable<RestResponse> {
    return this.webClientRestApi.deleteServiceTaskBaseImage(serviceTaskID, softwareImageBuild);
  }
  validateServiceTaskBaseImage(softwareImageBuild: string): Observable<RestResponse> {
    return this.webClientRestApi.validateServiceTaskBaseImage(softwareImageBuild);
  }
  getServiceTaskMembers(serviceTaskID:string, accessType: number){
    return this.webClientRestApi.getServiceTaskMembers(serviceTaskID, accessType);
  }
  updateServiceTaskMembers(serviceTaskID:string, request: MemberUpdateRequest){    
    return this.webClientRestApi.updateServiceTaskMembers(serviceTaskID, request);
  }
  cirrustaskStatusServiceTaskBaseImage(serviceTaskID: string, softwareImageBuild: string): Observable<RestResponse> {    
    return this.webClientRestApi.cirrustaskStatusServiceTaskBaseImage(serviceTaskID, softwareImageBuild);
  }
  getSoftwareImageDiffBuilds(softwareProductBuildID:string) : Observable<RestResponse>{
    return this.webClientRestApi.getSoftwareImageDiffBuilds(softwareProductBuildID);
  }
  getDistroShipStausForImage(request: DistributionRequest): Observable<RestResponse> {
    return this.webClientRestApi.getDistroShipStausForImage(request);
  }
  createTicket(request:JiraTicketRequest): Observable<RestResponse> {
    return this.webClientRestApi.createTicket(request);
  }
  jiraAttachment(projectKey:string,issueId:string,request:JiraFilesUploadRequest): Observable<RestResponse> {
    return this.webClientRestApi.jiraAttachment(projectKey,issueId,request);
  }
  getImageDistroShipStaus(request: ImageBuildDistributionRequest): Observable<RestResponse> {
    return this.webClientRestApi.getImageDistroShipStaus(request);
  }
  getCveCrMapping(request: CveCrMappingRequest): Observable<RestResponse>{
    return this.webClientRestApi.getCveCrMapping(request);
  }  
  getSecurityBulletins(): Observable<RestResponse>{
    return this.webClientRestApi.getSecurityBulletins();
  }

  autoPropagateCR(autoPropagateRequest:AutoPropagationRequest): Observable<RestResponse> {
    return this.webClientRestApi.autoPropagateCR(autoPropagateRequest);
  }
  getAutoPropagationResult(autoPropagateRequest:AutoPropagationRequest): Observable<RestResponse>{
    return this.webClientRestApi.getAutoPropagationResult(autoPropagateRequest);
  }
  autoPropagateCRCommit(autoPropagateRequest:AutoPropagationRequest): Observable<RestResponse> {
    return this.webClientRestApi.autoPropagateCRCommit(autoPropagateRequest);
  }
  getWorkspaceRootForCirrus(softwareImageBuild: string): Observable<QPMResponse> {
    return this.webClientRestApi.getWorkspaceRootForCirrus(softwareImageBuild);
  }
  localFilePathSearchForBait(request: FilePathSearchRequestForBait): Observable<QPMResponse> {
    return this.webClientRestApi.localFilePathSearchForBait(request);
  }
  localFilePathSearch(request: FilePathSearchRequest): Observable<QPMResponse> {//componentBranchDevFR is false
    return this.webClientRestApi.localFilePathSearch(request);
  }
  localFilePathSearchV2(cirrusTaskId: number, fileName: string): Observable<QPMResponse> {//componentBranchDevFR is true
    return this.webClientRestApi.localFilePathSearchV2(cirrusTaskId,fileName);
  }
  localFilesDelete(cirrusTaskId:number,localFilesDeleteRequest: LocalFilesDeleteRequest): Observable<QPMResponse> {
    return this.webClientRestApi.localFilesDelete(cirrusTaskId,localFilesDeleteRequest);
  }
  validateFilePath(filePathValidationReq:FilePathValidationRequest): Observable<RestResponse>{
    return this.webClientRestApi.validateFilePath(filePathValidationReq);
  }
  localFileUpload(fileUploadRequest:LocalFileUploadRequest): Observable<RestResponse>{
    return this.webClientRestApi.localFileUpload(fileUploadRequest);
  }  
  remoteAutoCommit(remoteAutoCommitRequest:RemoteAutoCommitRequest): Observable<RestResponse>{//componentBranchDevFR is false
    return this.webClientRestApi.remoteAutoCommit(remoteAutoCommitRequest);
  }
  remoteAutoCommitV2(remoteAutoCommitRequest: RemoteAutoCommitRequest): Observable<QPMResponse> {//componentBranchDevFR is true
    return this.webClientRestApi.remoteAutoCommitV2(remoteAutoCommitRequest);
  }
  localFileUploadInitiate(fileUploadInitiateRequest:LocalFileUploadInitiateRequest): Observable<RestResponse>{
    return this.webClientRestApi.localFileUploadInitiate(fileUploadInitiateRequest);
  }  
  localFileUploadComplete(fileUploadCompleteRequest:LocalFileUploadCompleteRequest): Observable<RestResponse>{
    return this.webClientRestApi.localFileUploadComplete(fileUploadCompleteRequest);
  }  
  localFileUploadSessions(localFileUploadSessionsRequest:LocalFileUploadSessionRequest): Observable<RestResponse>{
    return this.webClientRestApi.localFileUploadSessions(localFileUploadSessionsRequest);
  }
  s3FileUploadStart(key:string, path:string): Observable<RestResponse>{ 
    return this.webClientRestApi.s3FileUploadStart(key, path);
  }  
  s3FileUploadContinue(key:string, path:string, partNumber:number, formData:FormData): Observable<RestResponse>{ 
    return this.webClientRestApi.s3FileUploadContinue(key, path, partNumber, formData);
  }  
  s3FileUploadContinueBase64(request:s3FileUploadContinueRequest): Observable<RestResponse>{ 
    return this.webClientRestApi.s3FileUploadContinueBase64(request);
  }  
  s3FileUploadComplete(s3UploadCompleteRequest: S3UploadCompleteRequest): Observable<RestResponse>{ 
    return this.webClientRestApi.s3FileUploadComplete(s3UploadCompleteRequest);
  }
  baitFileUploadInitiate(fileUploadInitiateRequest:BaitFileUploadInitRequest): Observable<RestResponse>{
    return this.webClientRestApi.baitFileUploadInitiate(fileUploadInitiateRequest);
  }
  baitFileUploadComplete(fileUploadCompleteRequest:BaitFileUploadCompleteRequest): Observable<RestResponse>{
    return this.webClientRestApi.baitFileUploadComplete(fileUploadCompleteRequest);
  }
  baitHlosChanges(serviceTaskId: string, softwareImage: string): Observable<RestResponse>{
    return this.webClientRestApi.baitHlosChanges(serviceTaskId, softwareImage);
  }
  baitSystemChanges(serviceTaskId: string, softwareImage: string): Observable<RestResponse>{
    return this.webClientRestApi.baitSystemChanges(serviceTaskId, softwareImage);
  }
  baitSystemTriggerBuild(serviceTaskId: string, request: BaitSystemTriggerBuildRequest): Observable<RestResponse>{
    return this.webClientRestApi.baitSystemTriggerBuild(serviceTaskId, request);
  }
  getDeltaCRs(newBuild:string,oldBuild:string,isImageBuild:boolean):Observable<RestResponse>{
    return this.webClientRestApi.getDeltaCRs(newBuild,oldBuild,isImageBuild);
  }

  binDdmJiraTicketIdSubmission(binDdmJiraTicketInfoRequest: BinDdmJiraTicketInfoRequest): Observable<QPMResponse> {
    return this.webClientRestApi.binDdmJiraTicketIdSubmission(binDdmJiraTicketInfoRequest);
  }
  noticeFileUploadStartSession(noticeFileUploadStartRequest: NoticeFileUploadStartRequest): Observable<QPMResponse> {
    return this.webClientRestApi.noticeFileUploadStartSession(noticeFileUploadStartRequest);
  }
  noticeFileUploadCompleteSession(noticeFileUploadCompleteRequest: NoticeFileUploadCompleteRequest): Observable<QPMResponse> {
    return this.webClientRestApi.noticeFileUploadCompleteSession(noticeFileUploadCompleteRequest);
  }
  noticeFileDownload(noticeFileDownloadRequest: NoticeFileDownloadRequest): Observable<QPMResponse> {
    return this.webClientRestApi.noticeFileDownload(noticeFileDownloadRequest);
  }

  getLicenses(): Observable<QPMResponse> {
    return  this.webClientRestApi.getLicenses();;
  }
  getFeatures(licenseGroupID:string): Observable<QPMResponse> {
    return  this.webClientRestApi.getFeatures(licenseGroupID);
  }
  getDocuments(request): Observable<RestResponse> {    
    return  this.webClientRestApi.getDocuments(request);
  }
  getSeats(licenseGroupID:string): Observable<QPMResponse> {
    return  this.webClientRestApi.getSeats(licenseGroupID);
  }
  getSerialNumberDetails(serialNumber: string): Observable<QPMResponse> {
    return  this.webClientRestApi.getSerialNumberDetails(serialNumber);
  }
  getPricingDetails(pricingDetailsRequest: PricingDetailsRequest): Observable<QPMResponse> {
    return  this.webClientRestApi.getPricingDetails(pricingDetailsRequest);
  }

  buildPaymentIntent(request: PaymentIntentRequest): Observable<QPMResponse> {
    return this.webClientRestApi.buildPaymentIntent(request);
  }

  updatePaymentIntent(request: PaymentIntentRequest): Observable<QPMResponse> {
    return this.webClientRestApi.updatePaymentIntent(request);
  }


  deactivateLicenseSeat(licenceGroupId: string, licenseActivationId: string, licenseSeatDeactivateRequest: LicenseSeatDeactivateRequest): Observable<QPMResponse> {
    return this.webClientRestApi.deactivateLicenseSeat(licenceGroupId,licenseActivationId,licenseSeatDeactivateRequest);
  }
  getAdditionalCRInfo(crId: string, serviceTaskId: string): Observable<QPMResponse> {
    var observable = this.webClientRestApi.getAdditionalCRInfo(crId, serviceTaskId);
    return observable;
  }
  getCurrentlySubscribedLicenseGroup(): Observable<QPMResponse> {
    return this.webClientRestApi.getCurrentlySubscribedLicenseGroup();
  }

  getSubscriptionDetailsByLicenseGroupId(licenceGroupId: string): Observable<QPMResponse> {
    return this.webClientRestApi.getSubscriptionDetailsByLicenseGroupId(licenceGroupId);
  }
  subscribeLicensegroupForEmail(licenceGroupId: string): Observable<QPMResponse> {
    return this.webClientRestApi.subscribeLicensegroupForEmail(licenceGroupId);
  }
  unsubscribeLicenseGroupForEmail(licenceGroupId: string): Observable<QPMResponse> {
    return this.webClientRestApi.unsubscribeLicenseGroupForEmail(licenceGroupId);
  }
  updatePassThrough(request: UpdatePassThroughRequest): Observable<QPMResponse> {
    return this.webClientRestApi.updatePassThrough(request);
  }
  triggerPassThroughSI(request: TriggerPassThroughSIRequest): Observable<QPMResponse> {
    return this.webClientRestApi.triggerPassThroughSI(request);
  }

  //CLO
  getCloReleases(productUUID: string): Observable<QPMResponse> {
    return this.webClientRestApi.getCloReleases(productUUID);
  }
  getManifestFileAndReleaseNote(productUuid: string, version: string): Observable<QPMResponse> {
    return this.webClientRestApi.getManifestFileAndReleaseNote(productUuid,version);
  }
}
