<div class="exception-body notfound-page">
    <div class="exception-type">
        <img src="assets/layout/images/404.svg">
    </div>

    <div class="card exception-panel">
        <i class="material-icons">&#xE001;</i>
        <h1>Page Not Found</h1>
        <div class="exception-detail">The resource you are looking for does not exist.</div>
        <p-button label="Homepage" [routerLink]="['/']" styleClass="pink-btn"></p-button>
    </div>
</div>
