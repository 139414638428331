import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { DocumentInfo } from 'src/app/models/lime-web-client';


@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {
  @ViewChild('versionsTable') versionsTable;
  @ViewChild('versionGlobalFilter') versionGlobalFilter;
  products: DocumentInfo[] = [];
  private logSrc:string = "Documents-Component";
  constructor(private router: Router, private activatedRoute: ActivatedRoute,public app : AppMainComponent) {
  }
  ngOnInit(): void {

    let productDocument = new DocumentInfo();
    productDocument.fileName="Qualcomm Package Manager 3 User Guide";
    productDocument.dcnNumber ="80-PJ922-3B";
    this.products.push(productDocument);
  }
  onFilterClear() {
    this.versionGlobalFilter.nativeElement.value = '';
    this.versionsTable.filterGlobal('', 'contains');
  }

  onDocumentRowSelect(event)
  {
     QLogger.LogInfo(this.logSrc,"Downloading Document  "+event.data.fileName);
     if(!this.app.sharedData.appInfo.isElectronMode)
     {
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.href = 'assets/UserGuide/80-PJ922-3B_AA_QPMv3_UG_Ext.pdf';
        a.download = '80-PJ922-3B_AA_QPMv3_UG_Ext';
        a.click();
        document.body.removeChild(a);
     }
     else
     {
       const fs = window.require('fs');
       var installationRoot ="C:\\Program Files (x86)\\QUALCOMM\\QPM3\\"+this.app.sharedData.appInfo.version+"\\UserGuide";
       if(this.app.sharedData.appInfo.platform!='win32') {
         installationRoot = "/opt/qcom/QPM3/bin/UserGuide";
       }
       if (fs.existsSync(installationRoot)){
         QLogger.LogInfo(this.logSrc,"QPM3 user guide is present in your client at" + installationRoot);
         this.app.onOpenFolderClick(installationRoot);
       }
       else
         this.app.showMessageBox("User Guide","User Guide not present for installed version","Ok");

     }
  }
}