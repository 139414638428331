import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OverlayPanel, SelectItem, TreeNode } from 'primeng/primeng';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { QLogger } from 'src/app/common/logger';
import { SoftwareData } from 'src/app/common/sample-data';
import { Utils } from 'src/app/common/utils';
import { shipStatusErrorResponse, SoftwareDistroLocal, SoftwareProductBuildLocal, SoftwareProductDownload, SoftwareProductLocal, SoftwareProductMetaDataLocal } from 'src/app/models/distribution-client';
import { AvaliableDiffBuildsResponse, CrFixDetailRequest, DiffDistributionRequest, diffShipDownloadResponse, DistributionRequest, shipDownloadResponse, SoftwareDiffBuildRelease, SoftwareProductDistroInfo } from 'src/app/models/lime-web-client';
import { QPMResponse } from 'src/app/models/response';
import { crFixDetail, Customer, CustomerProject, ServiceTaskInfo, SoftwareProduct, SoftwareProductDistro, SoftwareProductFamily, SoftwareProductRelease } from 'src/app/models/software-catalog-client';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { DistributionClientService } from 'src/app/service/Contract/DistributionClientService';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';

@Component({
  selector: 'app-crsearch',
  templateUrl: './crsearch.component.html',
  styleUrls: ['./crsearch.component.css']
})
export class CrsearchComponent implements OnInit {
  private logSrc:string = "CRSearch-Component";
  private subscriptionSrc:string = "CRSearch-Component";

  private catalogClient: CatalogClientService;
  private softwareCatalogClient: SoftwareCatalogClientService;
  private webClient: WebClientService;
  private distributionClient: DistributionClientService;
  
  currentTab: number;
  softwareTypes: any[];
  isMaxLength: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public app: AppMainComponent, private utils: Utils, private service: DataServiceProducer){
    QLogger.LogInfo(this.logSrc, "CRSearch Component Creation");

    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.distributionClient = service.getServiceInstance(ApiType.DistributionClient) as DistributionClientService;
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "CRSearch Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);
    
    this.crSearchRequestID = undefined;
    this.crSearchRequestID = this.activatedRoute.snapshot.paramMap.get('id');

    this.crSearchRequestSoftwareBuild = "";
    this.app.setUserInfo();
    this.app.sharedData.resetVisibility();
    this.app.sharedData.setVisibility();

    this.currentTab = 0;
    this.softwareTypes = [
                          {label: "Product Build", value: "ProductBuild"},
                          {label: "Image Build", value: "ImageBuild"}
                         ];

    this.app.resetCrSearchResultProcess();
    this.resetAddCrToServiceTaskProcess();
    this.resetFilterValues();
    this.setCrSearchTableColumn();
    if(this.crSearchRequestID !== undefined){
      this.getCrFixDetailsByRequest(this.crSearchRequestID);
    }
    this.isMaxLength = false;
  }

  
  //#region CR Fix Status
  crSearchRequestID: string;
  crSearchRequestSoftwareBuild: string;
  crSearchRequestServiceTaskId: string;
  crSearchCols: any[];
  crSearchselectedColumns: any[];
  crList: crFixDetail[] = [];
  selectedSoftwareBuild: string;
  selectedSoftwareType: string = "ProductBuild";
  selectedCrs: string;
  selectedCrsToAdd: crFixDetail[] = [];
  selectedServiceTask: string;
  loadingResult: boolean;
  errorMessage: string;

  setCrSearchTableColumn(){
    this.crSearchCols = [];
    this.crSearchCols.push({ field: 'crId', header: 'Change Request', isFilterable: true });
    this.crSearchCols.push({ field: 'softwareImageBuild', header: 'Software Image Build', isFilterable: true });
    this.crSearchCols.push({ field: 'fixStatus', header: 'Status', isFilterable: true});
    this.crSearchCols.push({ field: 'softwareImageIntegratedBuild', header: 'Integrated Image', isFilterable: true});
    this.crSearchCols.push({ field: 'title', header: 'Title', isFilterable: true });
    this.crSearchCols.push({ field: 'type', header: 'Type', isFilterable: true });
    this.crSearchCols.push({ field: 'area', header: 'Area', isFilterable: true});
    this.crSearchCols.push({ field: 'subsystem', header: 'Subsystem', isFilterable: true });
    this.crSearchCols.push({ field: 'functionality', header: 'Functionality'});

    this.crSearchselectedColumns = [];
    this.crSearchselectedColumns.push(this.crSearchCols[0]);
    this.crSearchselectedColumns.push(this.crSearchCols[1]);

    //this.selectedSoftwareBuild = "Lahaina.LA.1.0.r1-00675-STD.PROD-2.1036.2";
    //this.selectedCrs = "327604, 54765";
    //this.selectedServiceTask = "1901";
  }

  //#region Events
  onSearch(event){
    let crs = this.app.extractCRsList(this.selectedCrs);
    if(crs === null || crs?.length < 0) {
      this.errorMessage = "Invalid CR(s) value";
      return;
    }
    this.getCrFixDetails();
  }
  //#endregion
  //#region Functions

  clearAllFilterResult(){
    this.crSearchRequestID = undefined;
    this.selectedSoftwareBuild = undefined;
    this.selectedSoftwareType = "ProductBuild";
    this.selectedCrs = undefined;
    this.selectedCrsToAdd = [];
    this.loadingResult = false;
    this.errorMessage = "";
    this.app.resetCrSearchResultProcess();
  }
  
  addCrsToNewST(){
    this.app.sharedData.crSearch.foundCrsToAdd = this.selectedCrsToAdd.filter(x => x.fixStatus !== 'Fixed' && x.fixStatus !== 'N/A').map(x => x.crId);
    this.router.navigate(["/main/software/servicetask/find"]);
  }
  
  getCrFixDetails(){
    this.app.resetCrSearchResultProcess();
    this.crList = [];
    this.crSearchRequestID = "";
    this.crSearchRequestSoftwareBuild = "";
    this.crSearchRequestServiceTaskId = "";
    this.errorMessage = "";
    let request: CrFixDetailRequest;
    request = {
      softwareItem: this.selectedSoftwareBuild,
      softwareItemType: this.selectedSoftwareType,
      crs: this.app.extractCRsList(this.selectedCrs).map(Number),
      serviceTaskId: undefined
    }
    this.loadingResult = true;
    
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get CR Fix details - Request: "+ JSON.stringify(request));
    }
    let response : Observable<QPMResponse>;
    response = this.webClient.getCrFixDetails(request);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get CR Fix details - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get CR Fix details - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            if(obj.resultPending){
              this.app.showResultConfirmation(obj.requestId);
            }
            else{
              this.crList = obj.crFixDetails;
              this.errorMessage = obj.errorMessage;
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc,  "Get CR Fix details - Failed");
          QLogger.LogError(this.logSrc,  "Get CR Fix details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.errorMessage = data.getError();
        }
        this.loadingResult = false;
      }
    );
  }

  getCrFixDetailsByRequest(requestID: string){
    if(this.crSearchRequestID===null) return;
    this.app.resetCrSearchResultProcess();
    this.crList = [];
    this.loadingResult = true;
    QLogger.LogInfo(this.logSrc, "Get CR Fix details RequestID: "+ requestID);
    this.errorMessage = "";
    let response : Observable<QPMResponse>;
    response = this.webClient.getCrFixDetailsByRequest(requestID);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get CR Fix details RequestID: "+ requestID + " - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get CR Fix details RequestID: "+ requestID + " - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            if(obj.resultPending){
              this.errorMessage = "Result not available. Please check later.";
            }
            else{
              this.crList = obj.crFixDetails;
              this.crSearchRequestSoftwareBuild = obj.softwareItem;
              this.crSearchRequestServiceTaskId = obj.serviceTaskId;
              this.errorMessage = obj.errorMessage;
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc,  "Get CR Fix details RequestID: "+ requestID + " - Failed");
          QLogger.LogError(this.logSrc,  "Get CR Fix details RequestID: "+ requestID + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.errorMessage = data.getError();
        }
        this.loadingResult = false;
      }
    );
  }
  //#endregion
  //#endregion

  //#region AddToServiceTask
  //FilterOptions
  
  @ViewChild('serviceTaskTable') serviceTaskTable;
  @ViewChild('serviceTaskGlobalFilter') serviceTaskGlobalFilter;

  filterOptionMaster: SelectItem[] = [{label:"All", value: undefined},{label:"Yes", value: true},{label:"No", value: false}];
  filterOptionStatus: SelectItem[] = [{label:"All", value: undefined},{label:"Active", value: "Active"},{label:"Abandoned", value: "Abandoned"}];
  filterOptionReqSource: SelectItem[] = [{label:"QPM", value: "QPM"},{label:"Planner", value: "Planner"},{label:"Salesforce", value: "Salesforce"}];

  filterValues:{
    serviceTaskId: string,
    requestedDate: string,
    baseBuildSpf: string,
    baseBuild: string,
    requestAppId: string,
    latestRelease: string,
    totalReleases: string,
    requester: string,
    requestSource: any[],
    master: boolean,
    customer: string,
    statusString: string
  }

  resetFilterValues(){
    this.filterValues = {
      serviceTaskId: '',
      requestedDate: '',
      baseBuildSpf: '',
      baseBuild: '',
      requestAppId: '',
      latestRelease: '',
      totalReleases: '',
      requester: '',
      requestSource: [],
      master: undefined,
      customer: '',
      statusString: undefined
    }
  }
 
  addCrToServiceTaskProcess:{
    displayForm: boolean;
    cols: any[];
    serviceTasks: ServiceTaskInfo[];
    selectedServiceTask: ServiceTaskInfo;
    errorMessage: string;
    loadingServiceTasks: boolean;
  }

  resetAddCrToServiceTaskProcess(){
    this.addCrToServiceTaskProcess={
      displayForm: false,
      cols: [],
      serviceTasks: [],
      selectedServiceTask: undefined,
      errorMessage: '',
      loadingServiceTasks: false
    }
  }

  startAddCrtoServiceTaskProcess(){
    this.resetFilterValues();
    this.addCrToServiceTaskProcess.displayForm = true;
    this.getAllServiceTasks();
  }
  
  addCrsToST(){
    this.addCrToServiceTaskProcess.displayForm = false;
    this.app.sharedData.crSearch.foundCrsToAdd = this.selectedCrsToAdd.filter(x => x.fixStatus !== 'Fixed' && x.fixStatus !== 'N/A').map(x => x.crId);
    this.router.navigate(["/main/software/servicetask/triage", this.addCrToServiceTaskProcess.selectedServiceTask.serviceTaskId]);
  }

  getAllServiceTasks() {
    let response : Observable<QPMResponse>;

    // Get all service task ID's
    this.addCrToServiceTaskProcess.loadingServiceTasks = true;
    QLogger.LogInfo(this.logSrc, "Get Service Tasks");
    response = this.softwareCatalogClient.getServiceTasks(undefined);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Tasks Response - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Tasks Response - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.addCrToServiceTaskProcess.serviceTasks = [];
            obj.serviceTasks.forEach((task) => {
              //if(task.baseBuild){
                this.addCrToServiceTaskProcess.serviceTasks.push(task as ServiceTaskInfo);
              //}
            });
            this.addCrToServiceTaskProcess.serviceTasks.forEach((task) => {
              if (task.abandoned) task.statusString = "Abandoned";
              else task.statusString = "Active"

              if (task.master) task.masterString = "Yes";
              else task.masterString = "No"

              if (task.companyNames != null) {
                task.companyNameList = task.companyNames.split(/; /g);
                task.companyNameList.sort();
              }
              
              // Clean up requestSource and requestAppId data
              if (task.requestSource.indexOf('planner') > -1) {
                task.requestSource = "Planner";
              }
              else if (task.requestSource.indexOf('salesforce') > -1) {
                task.requestSource = "Salesforce";
              }
              else if (task.requestSource.indexOf('QPM') > -1) {
                task.requestSource = "QPM";
                
              }
              if(task.requestAppId ==null || task.requestAppId =="")
              {
                task.requestAppId = 'N/A';
              }
            });   
            this.errorMessage = "";
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Tasks - Failed");
          QLogger.LogError(this.logSrc, "Get Service Tasks - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.errorMessage = "Failed to get Service Tasks: " + data.getError();
        }
        this.addCrToServiceTaskProcess.loadingServiceTasks = false;
      });
    }
  
  onChangeCRList()
  {
    let crList = this.extractCRsList(this.selectedCrs).map(Number);
    if (crList.length > 50) {
        this.isMaxLength = true;
    }
    else {
        this.isMaxLength = false;
    }
  }
  extractCRsList(crList: string): string[] {
    if (crList === undefined || crList === "") {
        return null;
    }
    let crListUnique: Set<string>;
    crListUnique = new Set<string>(crList.match(/\d+/g));
    return Array.from(crListUnique.values());
  }

  goToServiceTask(serviceTaskId: string){
    this.app.sharedData.service.common.selectedServiceTaskID = serviceTaskId.toString();
    this.router.navigate(['/main/software/servicetask/triage', serviceTaskId.toString()]);
  }
  //#endregion
}
