import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng';
import { Observable } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { QPMResponse } from 'src/app/models/response';
import { ReleaseCR, ServiceTaskInfo } from 'src/app/models/software-catalog-client';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { StringBuilder } from 'typescript-string-operations';

enum filterParameters{
  serviceTaskId = "filterByServiceTaskId",
  baseBuild = "filterByBaseBuild",
  baseBuildSpf = "filterByBaseSpf",
  companyNameList = "filterByCompany",
  latestRelease = "filterByLatestRelease",
  master = "filterByMaster",
  requestAppId = "filterByExternalId",
  requestSource = "filterByRequestSource",
  requestedDate = "filterByRequestedDate",
  requester = "filterByRequester",
  statusString = "filterByStatus",
  totalReleases = "filterByReleases"
}

enum tableProp{  
  currentPage = "pageNumber",
  pageSize = "pageSize",
  sortBy = "sortBy"
}

enum sortParameters{
  serviceTaskId = "serviceTaskId",
  baseBuild = "baseBuild",
  baseBuildSpf = "baseBuildSpf",
  companyNameList = "companyName",
  latestRelease = "latestRelease",
  master = "master",
  requestAppId = "requestAppId",
  requestSource = "requestSource",
  requestedDate = "requestedDate",
  requester = "requester",
  statusString = "abandoned",
  totalReleases = "totalReleases"
}

@Component({
  selector: 'app-st.find',
  templateUrl: './st.find.component.html',
  styleUrls: ['./st.find.component.css']
})
export class STFindComponent implements OnInit {

  private logSrc:string = "ServiceTaskFind-Component";

  private catalogClient: CatalogClientService;
  private softwareCatalogClient : SoftwareCatalogClientService;
  private webClient : WebClientService;

  errorMessage : string;
  loadingServiceTasks: boolean;

  // Static UI elements
  cols: any[];
  serviceTasks: ServiceTaskInfo[]
  @ViewChild('serviceTaskTable') serviceTaskTable;
  @ViewChild('serviceTaskGlobalFilter') serviceTaskGlobalFilter;

  //FilterOptions
  filterOptionMaster: SelectItem[] = [{label:"All", value: undefined},{label:"Yes", value: true},{label:"No", value: false}];
  filterOptionStatus: SelectItem[] = [{label:"All", value: undefined},{label:"Active", value: "false"},{label:"Abandoned", value: "true"}];
  filterOptionReqSource: SelectItem[] = [{label:"QPM", value: "QPM"},{label:"Planner", value: "Planner"},{label:"Salesforce", value: "Salesforce"}];


  filterValues:{
    serviceTaskId: string,
    requestedDate: string,
    baseBuildSpf: string,
    baseBuild: string,
    requestAppId: string,
    latestRelease: string,
    totalReleases: string,
    requester: string,
    requestSource: string[],
    master: boolean,
    customer: string,
    statusString: string
  }

  tableParam: {
    first: number,
    currentPage: number,
    pageSize: number,
    totalRecords: number,
    totalPage: number,
    sortBy: string
  }
  filterQuery: string;
  pageQuery: string;
  sortQuery: string;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, public app : AppMainComponent, private utils : Utils, private service:DataServiceProducer) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
  }

  ngOnInit() {
    QLogger.LogInfo(this.logSrc, "ServiceTaskFinder Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);

    // Static UI Elements
    this.app.setUserInfo();
    this.app.sharedData.service.common.master = false;
    this.app.sharedData.resetVisibility();
    this.app.sharedData.setVisibility();
    this.loadingServiceTasks = false;
    if(!this.app.sharedData.appInfo.workOffline)

    this.errorMessage = "";
    this.resetFilters();
  }
  ngAfterViewInit(){
    this.restoreState();
    this.app.createServiceTaskProcess.caseNumber = undefined;
    this.app.createServiceTaskProcess.baseRelease = undefined;
    this.app.createServiceTaskProcess.prod = "";
    this.app.createServiceTaskProcess.title = "";
    this.app.createServiceTaskProcess.desc = "";
    this.app.createServiceTaskProcess.custid = "";
    this.app.createServiceTaskProcess.cust = "";
    this.app.createServiceTaskProcess.isSalesforce = false;
    this.app.createServiceTaskProcess.baseReleasePreSelected = false;
    this.app.createServiceTaskProcess.companiesPreSelected = false;
    
    if(!this.app.sharedData.appInfo.isElectronMode){
      var caseNumber = this.activatedRoute.snapshot.queryParams['id'];
      if(caseNumber !== undefined && caseNumber !== null && caseNumber !== ""){
        this.app.resetNewServiceTask();
        this.app.createServiceTaskProcess.caseNumber = caseNumber;
        this.app.createServiceTaskProcess.prod = decodeURI(this.activatedRoute.snapshot.queryParams['prod']);
        this.app.createServiceTaskProcess.title = this.activatedRoute.snapshot.queryParams['title'].replace("+"," ");
        this.app.createServiceTaskProcess.desc = this.activatedRoute.snapshot.queryParams['desc'].replace("+"," ");
        this.app.createServiceTaskProcess.custid = this.activatedRoute.snapshot.queryParams['custid'];
        this.app.createServiceTaskProcess.cust = decodeURI(this.activatedRoute.snapshot.queryParams['cust']).replace("+"," ");
          this.app.createServiceTaskProcess.isSalesforce = true;
          this.app.createServiceTaskProcess.companiesPreSelected = true;
          this.app.newServiceTask(undefined, undefined);
      }
    }

    var pageNumber =  this.activatedRoute.snapshot.queryParams['pageNumber'];
    if(pageNumber !== undefined && pageNumber !== null && pageNumber !== ""){
      this.extractFilterQueryParam();
      this.getFilterValues();
      this.getAllServiceTasks(this.getQuery());
    }
    else{
      this.applyFilterSort();
    }
    
    //Add CR through CR Search
    if(this.app.sharedData.crSearch.foundCrsToAdd.length > 0){
      this.onCreateServiceTaskClick();            
    }
  }
  

  onRowSelect(event) {
    if(this.app.viewReleaseCrProcess.displayForm) return;
    this.app.sharedData.service.common.selectedServiceTaskID = event.data.serviceTaskId.toString();
    this.router.navigate(['/main/software/servicetask/triage',this.app.sharedData.service.common.selectedServiceTaskID]);
  }

  onFilterServiceTaskTable(event){
    this.filterQuery = "";
    let q: StringBuilder = new StringBuilder();
    this.getFilterValues();
    this.applyFilterSort();
  }
  
  onSortServiceTaskTable(event){
    let field = event.field;
    let order = event.order === 1 ? "ASC": "DESC";
    this.tableParam.sortBy = field + "," + order;
    this.tableParam.currentPage = 1;
    this.applyFilterSort();
  }


  onCreateServiceTaskClick(){
    this.app.resetNewServiceTask();
    this.app.createServiceTaskProcess.baseReleasePreSelected = false;
    this.app.newServiceTask(undefined, undefined);
    if(this.app.sharedData.crSearch.foundCrsToAdd.length > 0){
      this.app.createServiceTaskProcess.crList = this.app.sharedData.crSearch.foundCrsToAdd.join(", ");
      this.app.sharedData.crSearch.foundCrsToAdd = [];
      this.app.createServiceTaskProcess.expandAdvanceOptions=true;
      this.app.createServiceTaskProcess.addCRs=true;
    }
  }
  // Currently unused
  onPageChange(event){
    this.tableParam.currentPage = (event.first/event.rows) + 1;
    this.applyFilterSort();
  }

  onClearAllFilters(){
    this.resetFilters();
    this.filterQuery = "";
    this.applyFilterSort();
  }

  extractFilterQueryParam(){
    var params = this.activatedRoute.snapshot.queryParams;

    if(params[tableProp.currentPage] !== undefined) this.tableParam.currentPage = parseInt(params[tableProp.currentPage]);
    if(params[tableProp.pageSize] !== undefined) this.tableParam.pageSize = parseInt(params[tableProp.pageSize]);
    if(params[tableProp.sortBy] !== undefined) this.tableParam.sortBy = params[tableProp.sortBy];

    if(params[filterParameters.serviceTaskId] !== undefined) this.filterValues.serviceTaskId = params[filterParameters.serviceTaskId];
    if(params[filterParameters.baseBuild] !== undefined) this.filterValues.baseBuild = params[filterParameters.baseBuild];
    if(params[filterParameters.baseBuildSpf] !== undefined) this.filterValues.baseBuildSpf = params[filterParameters.baseBuildSpf];
    if(params[filterParameters.companyNameList] !== undefined) this.filterValues.customer = params[filterParameters.companyNameList];
    if(params[filterParameters.latestRelease] !== undefined) this.filterValues.latestRelease = params[filterParameters.latestRelease];
    if(params[filterParameters.master] !== undefined) this.filterValues.master = params[filterParameters.master] == "true";
    if(params[filterParameters.requestAppId] !== undefined) this.filterValues.requestAppId = params[filterParameters.requestAppId];
    if(params[filterParameters.requestSource] !== undefined) this.filterValues.requestSource = (params[filterParameters.requestSource] as String).split(",");
    if(params[filterParameters.requestedDate] !== undefined) this.filterValues.requestedDate = params[filterParameters.requestedDate];
    if(params[filterParameters.requester] !== undefined) this.filterValues.requester = params[filterParameters.requester];
    if(params[filterParameters.statusString] !== undefined) this.filterValues.statusString = params[filterParameters.statusString];
    if(params[filterParameters.totalReleases] !== undefined) this.filterValues.totalReleases = params[filterParameters.totalReleases];
  }

  applyFilterSort(){
    var params = {};
    params[tableProp.currentPage] = this.tableParam.currentPage;
    params[tableProp.pageSize] = this.tableParam.pageSize;
    params[tableProp.sortBy] = this.tableParam.sortBy;

    if(this.filterValues.serviceTaskId !== "")  params[filterParameters.serviceTaskId] = this.filterValues.serviceTaskId;
    if(this.filterValues.baseBuild !== "") params[filterParameters.baseBuild] = this.filterValues.baseBuild;
    if(this.filterValues.baseBuildSpf !== "") params[filterParameters.baseBuildSpf] = this.filterValues.baseBuildSpf;
    if(this.filterValues.customer !== "") params[filterParameters.companyNameList] = this.filterValues.customer;
    if(this.filterValues.latestRelease !== "") params[filterParameters.latestRelease] = this.filterValues.latestRelease;
    if(this.filterValues.master !==undefined) params[filterParameters.master] = this.filterValues.master;
    if(this.filterValues.requestAppId !== "") params[filterParameters.requestAppId] = this.filterValues.requestAppId;
    if(this.filterValues.requestSource !== undefined && this.filterValues.requestSource.length !== 0) params[filterParameters.requestSource] = this.filterValues.requestSource;
    if(this.filterValues.requestedDate !== "") params[filterParameters.requestedDate] = this.filterValues.requestedDate;
    if(this.filterValues.requester !== "") params[filterParameters.requester] = this.filterValues.requester;
    if(this.filterValues.statusString !== undefined && this.filterValues.statusString !== "") params[filterParameters.statusString] = this.filterValues.statusString;
    if(this.filterValues.totalReleases !== "") params[filterParameters.totalReleases] = this.filterValues.totalReleases;

    this.getAllServiceTasks(this.getQuery());
    this.router.navigate([], {queryParams:params, relativeTo: this.activatedRoute,replaceUrl:true});
  }
  
  restoreState(){    
    this.filterQuery = this.app.sharedData.service.finder.filterQuery;
    this.pageQuery = this.app.sharedData.service.finder.pageQuery;
    this.sortQuery = this.app.sharedData.service.finder.sortQuery;
    if(this.app.sharedData.service.finder.filterValues !== undefined){
      this.filterValues = this.app.sharedData.service.finder.filterValues;
    }
    if(this.app.sharedData.service.finder.tableParam !== undefined){
      this.tableParam = this.app.sharedData.service.finder.tableParam;
    }
  }

  resetFilters(){
    this.filterValues = {
      serviceTaskId: '',
      requestedDate: '',
      baseBuildSpf: '',
      baseBuild: '',
      requestAppId: '',
      latestRelease: '',
      totalReleases: '',
      requester: '',
      requestSource: [],
      master: undefined,
      customer: '',
      statusString: undefined
    }
    this.tableParam = {
      first : 0,
      currentPage: 1,
      pageSize: 20,
      totalRecords: 0,
      totalPage: 0,
      sortBy: "serviceTaskId,DESC"
    }
  }

  loadMoreServiceTask(event){
    this.tableParam.currentPage = (event.first/event.rows) + 1;
  }

  getFilterValues(){
    let q: StringBuilder = new StringBuilder();
    if(this.filterValues.serviceTaskId !== "") q.Append(filterParameters.serviceTaskId + "=" + this.filterValues.serviceTaskId + "&");
    if(this.filterValues.baseBuild !== "") q.Append(filterParameters.baseBuild + "=" + this.filterValues.baseBuild + "&");
    if(this.filterValues.baseBuildSpf !== "") q.Append(filterParameters.baseBuildSpf + "=" + this.filterValues.baseBuildSpf + "&");
    if(this.filterValues.customer !== "") q.Append(filterParameters.companyNameList + "=" + this.filterValues.customer + "&");
    if(this.filterValues.latestRelease !== "") q.Append(filterParameters.latestRelease + "=" + this.filterValues.latestRelease + "&");
    if(this.filterValues.master !==undefined) q.Append(filterParameters.master + "=" + this.filterValues.master + "&");
    if(this.filterValues.requestAppId !== "") q.Append(filterParameters.requestAppId + "=" + this.filterValues.requestAppId + "&");
    if(this.filterValues.requestSource !== undefined && this.filterValues.requestSource.length !== 0) q.Append(filterParameters.requestSource + "=" + this.filterValues.requestSource.join(",") + "&");
    if(this.filterValues.requestedDate !== "") q.Append(filterParameters.requestedDate + "=" + this.filterValues.requestedDate + "&");
    if(this.filterValues.requester !== "") q.Append(filterParameters.requester + "=" + this.filterValues.requester + "&");
    if(this.filterValues.statusString !== undefined && this.filterValues.statusString !== "") q.Append(filterParameters.statusString + "=" + this.filterValues.statusString + "&");
    if(this.filterValues.totalReleases !== "") q.Append(filterParameters.totalReleases + "=" + this.filterValues.totalReleases + "&");
    this.filterQuery = q.ToString();
  }

  getQuery(){
    this.pageQuery = "pageNumber="+this.tableParam.currentPage+"&pageSize="+this.tableParam.pageSize;
    this.sortQuery = "sortBy="+this.tableParam.sortBy;
    this.app.sharedData.service.finder.filterQuery = this.filterQuery;
    this.app.sharedData.service.finder.pageQuery = this.pageQuery;
    this.app.sharedData.service.finder.sortQuery = this.sortQuery;
    this.app.sharedData.service.finder.filterValues = this.filterValues;
    this.app.sharedData.service.finder.tableParam = this.tableParam;
    return this.filterQuery + this.pageQuery + "&" + this.sortQuery;
  }

  getAllServiceTasks(query: string) {
    let response : Observable<QPMResponse>;

    // Get all service task ID's
    QLogger.LogInfo(this.logSrc, "Get Service Tasks");
    response = this.softwareCatalogClient.getServiceTasks(query);
    this.loadingServiceTasks = true;
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          //QLogger.LogInfo(this.logSrc, "Get Service Tasks Response - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Tasks Response - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.serviceTasks = obj.serviceTasks;    
            this.tableParam.totalRecords = obj.totalItems as number;
            this.tableParam.totalPage = Math.ceil(obj.totalItems/this.tableParam.pageSize);
            this.serviceTaskTable.first = (this.tableParam.currentPage - 1) * this.tableParam.pageSize;
            this.serviceTaskTable.sortField = this.tableParam.sortBy.split(",")[0];
            this.serviceTaskTable.sortOrder = this.tableParam.sortBy.split(",")[1] == "DESC"? -1 : 1;
            this.serviceTasks.forEach((task) => {
              if (task.abandoned) task.statusString = "Abandoned";
              else task.statusString = "Active"

              if (task.master) task.masterString = "Yes";
              else task.masterString = "No"

              if (task.companyNames != null) {
                task.companyNameList = task.companyNames.split(/; /g);
                task.companyNameList.sort();
              }
              
              // Clean up requestSource and requestAppId data
              if (task.requestSource.indexOf('planner') > -1) {
                task.requestSource = "Planner";
              }
              else if (task.requestSource.indexOf('salesforce') > -1) {
                task.requestSource = "Salesforce";
              }
              else if (task.requestSource.indexOf('QPM') > -1) {
                task.requestSource = "QPM";
                
              }
              if(task.requestAppId ==null || task.requestAppId =="")
              {
                task.requestAppId = 'N/A';
              }
            });   
            this.errorMessage = "";
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Tasks - Failed");
          QLogger.LogError(this.logSrc, "Get Service Tasks - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.errorMessage = "Failed to get Service Tasks: " + data.getError();
        }
        this.loadingServiceTasks = false;
      });
  }

  onFilterClear() {
    this.app.sharedData.service.finder.finderFilter = '';
    this.serviceTaskGlobalFilter.nativeElement.value = '';
    this.serviceTaskTable.filterGlobal('', 'contains');
  }


}