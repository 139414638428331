import { Component } from '@angular/core';
import { Router } from '@angular/router';
import isElectron from 'is-electron';
import { QLogger } from './common/logger';
import { QConfig } from './common/qpm-config';
import { SharedData } from './common/shared-data';
import { AuthService } from './service/auth.service';
import { Utils } from './common/utils';
import { LoggingService } from './service/Contract/LoggingService';
import { ApiType, DataServiceProducer } from './service/Factory/DataServiceProducer';
import { LimeClientService } from './service/LimeService/lime-client.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    private logSrc:string = "App-Component";
    title = 'Qualcomm Package Manager 3';
    private loggingClient: LoggingService;
    private limeClient: LimeClientService;
    interval=null; ;
    server = null;
    electron = null;
    constructor(public sharedData: SharedData,private service: DataServiceProducer,private router: Router,private qpmConfig: QConfig, private utils : Utils, private authService: AuthService){
        QLogger.init();
        this.loggingClient = service.getServiceInstance(ApiType.LoggingClient) as LoggingService;
        this.limeClient = service.getServiceInstance(ApiType.Client) as LimeClientService;
        if(isElectron())
        {
            this.interval = null;
            this.electron = window.require('electron');
            this.handleExternalRequest();
            const fs = window.require('fs');
            const os = window.require('os');
            if (fs.existsSync(os.tmpdir()+"/QPMConfig.json"))
            {
              let config = fs.readFileSync(os.tmpdir()+"/QPMConfig.json", 'utf8');
              try{
                let obj  = JSON.parse(config);
                this.sharedData.appInfo.logLevel = obj.logLevel;
              }
              catch(e){
                QLogger.LogInfo(this.logSrc,"Configuration File not in correct format .Defaulting to enabling all logs");
                this.sharedData.appInfo.logLevel = 2;
              }
            }
            this.loggingClient.registerErrorCallback(AppComponent.onError);
            if(this.sharedData.appInfo.logLevel==1)
            this.loggingClient.registerWarningCallback(AppComponent.onInfo);
            else if(this.sharedData.appInfo.logLevel==2){
              this.loggingClient.registerWarningCallback(AppComponent.onInfo);
              this.loggingClient.registerInfoCallback(AppComponent.onInfo);
              
            }
            const remote = window.require('electron').remote
            if(remote.process.argv.length>0)
             this.handleCommandLine(remote.process.argv);
            this.readCommandLineArgs();
        }
        QLogger.LogInfo(this.logSrc,"App Launching v" + this.sharedData.appInfo.version);
    }
    readCommandLineArgs()
    {
      QLogger.LogInfo(this.logSrc,"Waiting for command line args");
      let appObj = this;
      const { ipcRenderer } = window.require("electron")
      ipcRenderer.on("qikParams", function (event, data) {
        QLogger.LogInfo(appObj.logSrc,data);
        appObj.handleCommandLine(data);

      });
    }
    handleCommandLine(commandLine:string[])
    {
      QLogger.LogInfo(this.logSrc,"Handling command Line arguments");
      let qikParam:string;
      commandLine.forEach(arg =>{
          if(arg.toLowerCase().indexOf("qikparams") != -1)
          {
            QLogger.LogInfo(this.logSrc,"Got QIK Params");
            const qikParams = arg.split("=");
            this.sharedData.qikParams.installParams.push(qikParams[1]);
            //collect the userName passed by qikparam
            let installConfig = this.sharedData.qikParams.installParams.shift();
            let installParams = installConfig.split("_");
            let qikparamOS=installParams[2];
            let qikparamTargetArch=installParams[4]
            let qikparamUserName=installParams[5].substring(0, installParams[5].length - 1)//extract userName	
            let obj = this.router;
            if(this.sharedData.userInfo.username!==''  && this.sharedData.userInfo.username===qikparamUserName )
            {
                //Additional Checks for [OS]
                let isOsMatch=this.validateOS(qikparamOS);
                if(isOsMatch===false){
                  this.sharedData.sharedMessage.showMessageBox=true;
                  this.sharedData.sharedMessage.contents=['Cannot launch installation as operating system of selected product and desktop application do not match.',
                                                          'Note : Please try to visit the product details page on the desktop app and start installation from there.'];
                  this.sharedData.sharedMessage.headLine='Installation Failed';
                }else{
                  setTimeout(function () {
                    obj.navigateByUrl('blank').then(() => {
                      obj.navigate(['/main/tools/find']);
                    });
                  }, 5000);
                }
            }
            else{
              QLogger.LogInfo(this.logSrc,"User is not logged in");
              this.sharedData.sharedMessage.showMessageBox=true;
              this.sharedData.sharedMessage.contents=['Cannot launch installation as portal and desktop application user do not match.',
                                                       'Note : Please try to visit the product details page on the desktop app and start installation from there.'];
              this.sharedData.sharedMessage.headLine='Installation Failed';
            }
 
          }
      });
    }
    private static onInfo(message: string): void {
        QLogger.LogInfo("Native-Lib","Info: " +message);
      }
      private static onWarning(message: string): void {
        QLogger.LogInfo("Native-Lib", "Warning: "+message);
      }
    
      private static onError(message: string): void {
        QLogger.LogInfo("Native-Lib", "Error:" +message);
      }

      handleExternalRequest()
      {
        const net = window.require("net");
        const fs = window.require("fs");
        let incomingMsg:String;
        let incomingMsgQ:String[];
        incomingMsgQ=[];
        let socketPath = '\\\\?\\pipe\\QPM-IPC';
        if(process.platform!=='win32')
        {
           socketPath = '/tmp/QPM-IPC';
           if(fs.existsSync(socketPath))
            fs.unlinkSync(socketPath);
        }
        this.server = net.createServer((stream) => {
          QLogger.LogInfo(this.logSrc,"Server: on connection");
        
          stream.on("data", (buffer) => {
            QLogger.LogInfo(this.logSrc,"Got data "+buffer.toString());
            incomingMsgQ.push(buffer.toString());
            stream.write("Got the Message");
          });
        });
        this.server.listen(socketPath, () => {
          QLogger.LogInfo(this.logSrc,"QPM UI Listening for external events");
        });
        this.interval = setInterval(() => {
          incomingMsg = incomingMsgQ.shift();
          if(incomingMsg!=null){

              if (incomingMsg=='login') {
                QLogger.LogInfo(this.logSrc,"User logged in through CLI ");
                if(this.sharedData.userInfo.username==='')
                {
                  this.router.navigateByUrl('blank').then(() => {
                    this.sharedData.appInfo.isLoggedOut = false;
                    this.router.navigate(['']);
                  });
                }
            }
            else if(incomingMsg =='logout')
            {                    
              QLogger.LogInfo(this.logSrc,"User logged out through CLI");
              incomingMsg ="";
              this.sharedData.appInfo.isLoggedOut = true;
              this.sharedData.userInfo.username ='';
              this.router.navigate(['']);
            }
            else if(incomingMsg =='refreshTools')
            {
              QLogger.LogInfo(this.logSrc,"Product Installation Complete refreshing tools Page");
              let win = this.electron.remote.getCurrentWindow();
              let isWindowFocused:Boolean = false;
              isWindowFocused=win.isFocused();
              incomingMsg ="";
              if (isWindowFocused) {
                var lastVisited = this.qpmConfig.getLastVisitedPage();
                if(lastVisited!==null && lastVisited=='/main/tools/find'){
                  this.router.navigateByUrl('blank').then(() => {
                    this.router.navigate(['/main/tools/find']);
                  });
                }
              }
            }

          }
      }, 1000);
    
      }
  //#region Additional checks 
  isARM64Processor():boolean{
        QLogger.LogInfo(this.logSrc,"isProcessorARM64 : Processing Started");
        let isARM64=false;
        let response=this.limeClient.isProcessorARM64();
        response.subscribe(data=>{
            if(data.isSuccess()){
                QLogger.LogInfo(this.logSrc,"isProcessorARM64 : Processing Success");
                let result=JSON.parse(data.getData());
                QLogger.LogInfo(this.logSrc,"isProcessorARM64 Response : "+result);
                isARM64 = result
            }else{
                QLogger.LogInfo(this.logSrc,"isProcessorARM64 : Processing Failed");
            }
        })
        QLogger.LogInfo(this.logSrc,"isProcessorARM64 : "+isARM64);
        return isARM64;
  }
  validateOS(qikparamOS:string):boolean{
    QLogger.LogInfo(this.logSrc,"Collect OS from QIK Params");
    let appOS=this.utils.getOSInfoByUserAgent();
    if(appOS===qikparamOS){
      QLogger.LogInfo(this.logSrc,"OS Matching :"+true);
      return true;
    }else{
      QLogger.LogInfo(this.logSrc,"OS Matching :"+false);
      return false;
    }

  }
  validateTargetArchitecture(qikparamTargetArch:string):boolean{
    QLogger.LogInfo(this.logSrc,"Collect Target Architechture from QIK Params");
    let appTargetArch='';
    this.isARM64Processor()===true?appTargetArch='ARM64': appTargetArch='Any';
    if(appTargetArch===qikparamTargetArch){
      QLogger.LogInfo(this.logSrc,"Target Architechture Matching :"+true);
      return true;
    }else{
      QLogger.LogInfo(this.logSrc,"Target Architechture Matching :"+false);
      return false;
    }
  }
  //#endregion
}
