export class ProductSuite {
  etag: number
  description: string
  displayName: string
  id: string
  name: string
  logo: string
  products: string[]
}

export class InstalledPackageDetails {
  Branch?: string 
  ComponentID?: string
  ComponentName?: string
  ComponentType?: string
  DependsOn?: string
  Description?: string 
  FeatureID?: string
  FeatureName?: string
  GUID?: string
  InstallLocation?: string
  InstallLocationRoot?: string
  InstallationDate?: string 
  Installations?: InstalledVersion[]
  MultipleInstall?: string
  MultipleVersions?: number
  Name?: string
  ProductID?: string
  ProductName?: string
  PublishedDate?: Date
  Publisher?: string
  ReferenceCounter?: number
  RequiredBy?: string
  SpaceReq?: string
  Subtitle?: string
  Version: string
  packageFile?: string
  packageDownloadLocation?: string
  InstalledVersions?: InstalledVersion[]
  logo:string
  UpdateAvailable:boolean
}

export class InstalledVersion {
  version: string
  Version?: string 
  InstallationDate: string
  PublishedDate: string
  SpaceReq: string
  GUID: string
  packageFile: string
  InstallLocation: string
  InstallLocationRoot: string
}

export class PackageDetails{

  etag:string;
  componentId:string;
  componentName:string;
  componentType:string;
  files:FileInfo[];
  id:string;
  installType:string;
  isAutoUpdateEnabled:boolean;
  isAutoUpdateOptional:boolean;
  name:string;
  notificationDuration:number;
  packageType:string;
  productId:string;
  releaseBranch:string;
  releaseDateUTC:string;
  releaseNote:string;
  targetArch:string;
  targetOS:string;
  updateDeadlineUTC:string;
  updateLevel:string;
  updateOfflineOnly:boolean;
  updateSchedule:string;
  version:string;
  isInstalled:boolean;
}

export class FileInfo
{
  filesize:number;
  tokenId:string;
  filename:string;
}
export class ReleaseNotesDetails{

  fileUrlMap:ReleaseNotesInfo;
  status:string;
  success:boolean;
}
export class ReleaseNotesInfo{
   entry:DownloadInfo[];
}

export class DownloadInfo{
  key:string;
  value:string;
}
export class QIKResult{

  returnCode:Number;
  returnMessage:string;
  returnLogs:string[];
  logPath:string;
}

export class QIKPackageProperites{

  errorCode:Number;
  errorMessage:string;
  packageProperties:QIKPackageInfo;
  Dependencies:PackageDependencyInfo[];
  Addons:PackageDependencyInfo[];
}
export class QIKPackageInfo{

  EulafileName:string;
  ReleasefileName:string;
  TargetDirectory:string;
  CustomPathRequired:string;
  EnableMultipleInstall:boolean;
}

export class PackageDependencyInfo{

  AskUser:string;
  ComponentName:string;
  MinVersion:string;
  MaxVersion:string;
  InstallOption:string;
  ShowVersion:string;
  Primary:string;
  EmbedFileName:string;
}

export class CloProductRelease {
  gitUrl: string
  lostReview: string
  productUuid: string
  publishEndTimestamp: string
  publishStartTimestamp: string
  publisherID: string
  serviceAccount: string
  version: string
}
export class ManifestFileAndReleaseNoteInfo {
  manifestFileUrl: string
  releaseNoteFileUrl: string
}

export class ToolDocumentList{
  resources: ToolDocument[];
  docCollectionUrls:string[];
}

export class ToolDocument{
  dcn: string;
  id: string;
  url: string;
  revision: string;
  requiresActivation: boolean;
  requiresAuthorization: boolean;
  contentType: string[];
  isDownloadable: boolean;
  resourceType: string;
  requiresVerification: boolean;
  publishedOn: string;
  resourceSubType: string;
  title: string;
}
