<div class="card card-w-title">

  <h1 id="ossreleasesHeader">Open Source:</h1>
  <h3 id="ossnoReleaseFoundHeader" *ngIf="serviceTaskBuildsForOss.softwareImages?.length === 0">No Releases Found</h3>
  <p-accordion *ngIf="serviceTaskBuildsForOss.softwareImages?.length !== 0"  (onOpen)="OnOssProductExpand()">
      <p-accordionTab id="shippedImage" header="Releases">
          <div class="ui-g">
              <div class="ui-g-11">
                  <div>
                    <p-table id="ossTable" #ossTable [columns]="ossCols" [value]="serviceTaskBuildsForOss.softwareImages" [loading]="serviceTaskBuildsForOss.loadingBaitImagesForOss || serviceTaskBuildsForOss.loadingBaitJobIdsForOss > 0 || serviceTaskBuildsForOss.loadingBaitJobDetailsForOss > 0" [paginator]="true" [rows]="8"
                            [globalFilterFields]="['baseImage']">
                        <ng-template pTemplate="caption">
                            <div style="text-align: right">
                                <i id="searchFilterOss" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input #ossGlobalFilter class="filter-clear" type="text" id="filterOss" pInputText size="50" placeholder="Filter by base image"
                                  (input)="ossTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                                <i class="ui-icon-cancel" id="clearFilterOss" pTooltip="Clear Filter" (click)="onOssFilterClear()"
                                  style="margin:4px 4px 0 0"></i>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="7">No data found.</td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                            <tr [pSelectableRow]="rowData" *ngFor="let ossData of rowData.ossLinkSet; let i = index">
                                <td id="baseImage" *ngIf="i===0" [attr.rowspan]="rowData.ossLinkSet.length" style="word-wrap: break-word">
                                    {{rowData.baseImage}}
                                </td>
                                <td id="ossLink" style="word-wrap: break-word">
                                    <p>
                                        <span *ngIf="app.sharedData.appInfo.isElectronMode">
                                          <a (click)="app.goToUrl(ossData.oss)" style="word-wrap: break-word;cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                              {{ossData.oss}}
                                          </a>
                                       </span>
                                       <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                                          <a href="{{ossData.oss}}" target="_blank" style="word-wrap: break-word;cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                                              {{ossData.oss}}
                                          </a>
                                       </span>
                                    </p>
                                </td>
                                <td id="ossbuildCreatedAt" style="word-wrap: break-word">
                                  <p>
                                      <span>{{ossData.build_completed_time}}</span>
                                  </p>
                              </td>
                                
                            </tr>
                        </ng-template>
                    </p-table>
                 </div>   
              </div>
              <div class="ui-g-1">
                  <div class="ui-g-12">
                    <i id="refreshOssInfo" class="ui-icon-refresh" *ngIf="!(serviceTaskBuildsForOss.loadingBaitImagesForOss || serviceTaskBuildsForOss.loadingBaitJobIdsForOss > 0 || serviceTaskBuildsForOss.loadingBaitJobDetailsForOss > 0)" pTooltip="Refresh" id = "ossProductRefresh" (click)="OnOssProductRefresh()" style="margin-top: -10px;float: right;"></i>
                    <p-progressSpinner id="refreshOssSpinner" *ngIf="serviceTaskBuildsForOss.loadingBaitImagesForOss || serviceTaskBuildsForOss.loadingBaitJobIdsForOss > 0 || serviceTaskBuildsForOss.loadingBaitJobDetailsForOss > 0" [style]="{width: '20px', height: '20px', margin: '-10px 0 0 0', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                  </div>
              </div>
          </div>                        
      </p-accordionTab>
  </p-accordion>






  <h1 id="releasesHeader">Releases:</h1>
  <h3 id="noReleaseFoundHeader" *ngIf="serviceTaskDistProducts?.length === 0">No Releases Found</h3>
  <p-accordion *ngFor="let product of serviceTaskDistProducts; let i = index" 
              (onOpen)="onProductExpand(product,i)"[multiple]="true">
      <p-accordionTab id="shippedImage" header="{{(product.productNewBuildID === null)?'No Build ID - Failed': product.productNewBuildID + (product.detail?.status === null? '' : ' - ' + product.detail?.status)}}" [selected]="i === 0">
          <div class="ui-g">
              <div class="ui-g-9">
                  <div id="customerHeader" style="margin-bottom:10px;"><b>Customer : </b>
                      <span> 
                          <p id="customerName" *ngFor="let company of stMain.serviceTasksDetails.companies" style="margin-left:20px">{{company.companyName}}</p>
                      </span>
                  </div>  
                  <div id="statusHeader" *ngIf="product.productNewBuildID!=null"style="margin-bottom:10px;"><b>Status : </b>
                      {{product.detail?.status}}
                      <div id="failedReasonHeader" *ngIf="product.detail?.status==='Failed'" style="margin-bottom:10px;">
                        <b>Failed Reason : </b>{{product.detail?.failedReason}}
                        <div id="compileErrorMsg" *ngIf="product.detail?.failedReason==='Compile'" style="margin-bottom:10px;">
                          Please contact SP Target/Integration team.
                        </div>
                          <div *ngIf="product.detail?.failedReason!=='Compile'">
                          Error must be resolved by <b>CRMHelp</b> team.  <button pButton id="CRMhelp" [style]="{'width': '160px'}" type="button"  (click)="onOpenCRMHelpSupportTicketDialog(opsHelpIssueType,opsHelpProjectKey,buildInfrastructure,product)" label="File CRMHelp Ticket"></button>
                          </div>
                      </div>
                      <div id="buildTestHeader" *ngIf="product.detail?.status==='BuildTest'" style="margin-bottom:10px;">
                        BuildTest issue must be resolved by <b>CRMHelp</b> team. <button pButton id="CRMhelp" [style]="{'width': '160px'}" type="button"  (click)="onOpenCRMHelpSupportTicketDialog(opsHelpIssueType,opsHelpProjectKey,buildInfrastructure,product)" label="File CRMHelp Ticket"></button>
                      </div>

                    </div>
                  <div id="errorHeader" *ngIf="product.productNewBuildID===null"style="margin-bottom:10px;"><b>Failure Reason : </b>
                      {{product.errorMessage}}
                </div>
                  <div style="margin-bottom:10px;" *ngIf="app.sharedData.visibility.serviceTaskDist.viewJobUrl && product?.productNewBuildID!=null">
                      <b id="buildJobHeader">Build Job : </b>
                      <span id="buildJob" style="cursor: pointer; color: blue; text-decoration: underline;" (click)="app.goToUrl(product.jobUrl)">
                          {{product.jobUrl}}
                      </span>
                  </div>
                  <div id="builtByHeader" *ngIf="app.sharedData.visibility.serviceTaskDist.viewBuiltBy && product.productNewBuildID!=null " style="margin-bottom:10px;">
                    <b>Built By : </b>{{product.detail?.builtBy}}
                  </div>  
                  <div id="builtByHeader" *ngIf="app.sharedData.visibility.serviceTaskDist.viewBuiltBy && product.productNewBuildID===null " style="margin-bottom:10px;">
                    <b>Built By : </b>{{product.requester}}
                  </div>    
                  <div id="builtAtHeader" style="margin-bottom:10px;">
                    <b>Built Date : </b>{{product?.createdAt}}
                  </div>
                  <div id="autoShipHeader" style="margin-bottom:10px;">
                    <b>Auto Ship  : </b>
                    <span *ngIf="product?.autoShip===true">Yes</span>
                    <span *ngIf="product?.autoShip===false">No</span>
                  </div> 
                  <div id="autoDiffShipHeader" style="margin-bottom:10px;">
                    <b>Auto Diff Ship : </b>
                    <span *ngIf="product?.autoDiffShip===true">Yes</span>
                    <span *ngIf="product?.autoDiffShip===false">No</span>
                  </div>   
                  <div id="spfTagHeader" *ngIf="product?.releaseInfo?.spfTag!=undefined && product.productNewBuildID!=null "style="margin-bottom:10px;">
                    <b>Shipped SPF Tag : </b>{{product?.releaseInfo?.spfTag}}
                  </div> 
                  <div id="shippedTagHeader" *ngIf="product?.releaseInfo?.tag!=undefined && product.productNewBuildID!=null" style="margin-bottom:10px;">
                    <b>Shipped Tag : </b>{{product?.releaseInfo?.tag}}
                  </div>                  
                  <div style="margin-bottom:10px;" *ngIf="product.crList?.buildCRs?.length > 0">
                      <b>New CRs : </b>
                      <div *ngIf="app.sharedData.visibility.serviceTaskDist.viewCRs  && product.productNewBuildID!=null" style="display: inline-block;">
                        <span *ngIf="app.sharedData.appInfo.isElectronMode">
                          <a *ngFor="let cr of product.crList?.buildCRs" (click)="app.goToUrl('https://orbit/cr/'+cr)" style="margin-left: 5px; cursor: pointer; color: blue; text-decoration: underline;">
                              {{cr}} 
                          </a>
                        </span>
                        <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                            <a *ngFor="let cr of product.crList?.buildCRs" href="{{'https://orbit/cr/'+cr}}" target="_blank" style="margin-left: 5px; cursor: pointer; color: blue; text-decoration: underline;">
                                {{cr}} 
                            </a>
                            <a *ngIf="!product.showAdditionalInfoNewCRs" (click)="onClickDetailsNewCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Details</a>
                            <a *ngIf="product.showAdditionalInfoNewCRs" (click)="onClickDetailsNewCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Hide Details</a>
                        </span>
                      </div>
                      <div *ngIf="!app.sharedData.visibility.serviceTaskDist.viewCRs && product.productNewBuildID!=null" style="display: inline-block;">
                        <span *ngFor="let cr of product.crList?.buildCRs" style="margin-left: 5px;">
                            {{cr}}
                        </span>
                        <a *ngIf="!product.showAdditionalInfoNewCRs" (click)="onClickDetailsNewCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Details</a>
                        <a *ngIf="product.showAdditionalInfoNewCRs" (click)="onClickDetailsNewCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Hide Details</a>
                      </div>
                  </div>
                  <!--New Table starts here-->  
                  <div style="margin-bottom:10px;">
                      <p-table *ngIf="product.showAdditionalInfoNewCRs" id="additionalNewCRInfoTable" #additionalNewCRInfoTable [columns]="additionalCRColumns" [value]="product.additionalInfoNewCRs"
                      [globalFilterFields]="['changeRequestNumber', 'area', 'subsystem', 'functionality', 'srNumbers']" [paginator]="true" [rows]="8">
                      <!--
                        <ng-template pTemplate="caption">
                            <div style="text-align: right">
                                <i id="searchFilterCr" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                                <input #crSelectGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                                (input)="crSelectTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                                <i class="ui-icon-cancel" id="clearFilterCrSelect" pTooltip="Clear Filter" (click)="crSelectGlobalFilter.value = ''; crSelectTable.filterGlobal('', 'contains');"
                                style="margin:4px 4px 0 0"></i>
                            </div>
                        </ng-template>
                      -->
                      <ng-template pTemplate="caption">
                        <div style="text-align: right">
                            <i id="searchFilterAdditionalCr" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                            <input #AdditionalCrSelectGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                            (input)="additionalNewCRInfoTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                            <i class="ui-icon-cancel" id="clearFilterAdditionalCrSelect" pTooltip="Clear Filter" (click)="AdditionalCrSelectGlobalFilter.value = ''; additionalNewCRInfoTable.filterGlobal('', 'contains');"
                            style="margin:4px 4px 0 0"></i>
                        </div>
                    </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                    {{col.header}}
                                    <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td id="noDataFoundMsg" colspan="6" *ngIf="product.additionalInfoLoadedNewCRs">No data found.</td>
                                <td id="additionalCRTableLoading" *ngIf="!product.additionalInfoLoadedNewCRs" style="width: 100%; display: flex;"><p-progressSpinner style="margin: auto;"></p-progressSpinner></td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-info let-columns="columns">
                            <tr>
                                <td *ngFor="let col of columns">
                                  <div *ngIf="col.field !== 'changeRequestNumber'">{{info[col.field]}}</div>
                                  <div *ngIf="col.field === 'changeRequestNumber'">
                                    <a *ngIf="app.sharedData.userInfo.info.internal" (click)="app.goToUrl('https://orbit/cr/'+info[col.field])" style="margin-left: 5px; cursor: pointer; color: blue; text-decoration: underline;">
                                      {{info[col.field]}} 
                                    </a>
                                    <div *ngIf="!app.sharedData.userInfo.info.internal">
                                      {{info[col.field]}}
                                    </div>
                                  </div>
                                </td>
                            </tr>
                        </ng-template>
                      </p-table>
                  </div>
                  <!--New Table ends here-->
                  <div style="margin-bottom:10px;" *ngIf="product.crList?.consolidatedCRs?.length > 0 && product.productNewBuildID!=null">
                      <b>All CRs : </b>
                      <div *ngIf="app.sharedData.visibility.serviceTaskDist.viewCRs" style="display: inline-block;">
                        <span *ngIf="app.sharedData.appInfo.isElectronMode">
                          <a *ngFor="let cr of product.crList?.consolidatedCRs" (click)="app.goToUrl('https://orbit/cr/'+cr)" style="margin-left: 5px; cursor: pointer; color: blue; text-decoration: underline;">
                              {{cr}} 
                          </a>
                        </span>
                        <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                            <a *ngFor="let cr of product.crList?.consolidatedCRs" href="{{'https://orbit/cr/'+cr}}" target="_blank" style="margin-left: 5px; cursor: pointer; color: blue; text-decoration: underline;">
                                {{cr}} 
                            </a>
                            <a *ngIf="!product.showAdditionalInfoAllCRs" (click)="onClickDetailsAllCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Details</a>
                            <a *ngIf="product.showAdditionalInfoAllCRs" (click)="onClickDetailsAllCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Hide Details</a>
                        </span>
                      </div>
                      <div *ngIf="!app.sharedData.visibility.serviceTaskDist.viewCRs" style="display: inline-block;">
                        <span *ngFor="let cr of product.crList?.consolidatedCRs" style="margin-left: 5px;">
                            {{cr}}
                        </span>
                        <a *ngIf="!product.showAdditionalInfoAllCRs" (click)="onClickDetailsAllCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Details</a>
                        <a *ngIf="product.showAdditionalInfoAllCRs" (click)="onClickDetailsAllCRs(product)" style="margin-left: 5px; cursor: pointer; color: grey; text-decoration: underline;">Hide Details</a>
                      </div>
                  </div>
                  <!--New Table starts here-->  
                  <div style="margin-bottom:10px;">
                    <p-table *ngIf="product.showAdditionalInfoAllCRs" id="additionalAllCRInfoTable" #additionalNewCRInfoTable [columns]="additionalCRColumns" [value]="product.additionalInfoAllCRs"
                    [globalFilterFields]="['changeRequestNumber', 'area', 'subsystem', 'functionality', 'srNumbers']" [paginator]="true" [rows]="8">
                    <!--
                      <ng-template pTemplate="caption">
                          <div style="text-align: right">
                              <i id="searchFilterCr" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                              <input #crSelectGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                              (input)="crSelectTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                              <i class="ui-icon-cancel" id="clearFilterCrSelect" pTooltip="Clear Filter" (click)="crSelectGlobalFilter.value = ''; crSelectTable.filterGlobal('', 'contains');"
                              style="margin:4px 4px 0 0"></i>
                          </div>
                      </ng-template>
                    -->
                    <ng-template pTemplate="caption">
                      <div style="text-align: right">
                          <i id="searchFilterAdditionalCr" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                          <input #AdditionalCrSelectGlobalFilter class="filter-clear" type="text" id="filterCr" pInputText size="50" placeholder="Filter"
                          (input)="additionalNewCRInfoTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                          <i class="ui-icon-cancel" id="clearFilterAdditionalCrSelect" pTooltip="Clear Filter" (click)="AdditionalCrSelectGlobalFilter.value = ''; additionalNewCRInfoTable.filterGlobal('', 'contains');"
                          style="margin:4px 4px 0 0"></i>
                      </div>
                  </ng-template>
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                  {{col.header}}
                                  <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
                              </th>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                          <tr>
                              <td id="noDataFoundMsg" colspan="6" *ngIf="product.additionalInfoLoadedAllCRs">No data found.</td>
                              <td id="additionalCRTableLoading" *ngIf="!product.additionalInfoLoadedAllCRs"><p-progressSpinner style="margin: auto;"></p-progressSpinner></td>
                          </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-info let-columns="columns">
                          <tr>
                            <td *ngFor="let col of columns">
                              <div *ngIf="col.field !== 'changeRequestNumber'">{{info[col.field]}}</div>
                              <div *ngIf="col.field === 'changeRequestNumber'">
                                <a *ngIf="app.sharedData.userInfo.info.internal" (click)="app.goToUrl('https://orbit/cr/'+info[col.field])" style="margin-left: 5px; cursor: pointer; color: blue; text-decoration: underline;">
                                  {{info[col.field]}} 
                                </a>
                                <div *ngIf="!app.sharedData.userInfo.info.internal">
                                  {{info[col.field]}}
                                </div>
                              </div>
                            </td>
                          </tr>
                      </ng-template>
                    </p-table>
                </div>
                <!--New Table ends here-->  
                  <div style="margin-bottom:10px;" *ngIf="product.crList?.buildGerrits?.length > 0 && product.productNewBuildID!=null">
                      <b>Gerrits : </b>
                      <div style="display: inline-block;">
                        <span *ngFor="let gerrit of product.crList?.buildGerrits" style="margin-left: 5px;">
                            {{gerrit}}
                        </span>
                      </div>
                  </div>
                  <div id="shippedDistros" style="margin-bottom:10px;" *ngIf="product.productNewBuildID!=null">
                      <b>Shipped Distros : </b>
                      <p-table id="shippedDistrosTable" [value]=" product.shippedDistroList" [style]="{'width': '830px'}">
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td id="noShippedDistrosMsg" colspan="2">No shipped distros.</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                            <tr>
                                <th id="distShippedDistroName" style="width: 290px"><span style="vertical-align: middle"><b>Distro Name</b></span></th>
                                <th id="distShippedDistroStatus" style="width: 130px"><span style="vertical-align: middle"><b>Status</b></span></th>
                                <th id="distShippedDistroRequester" style="width: 150px" *ngIf="product.isShipComplete === true"><span style="vertical-align: middle"><b>Requester</b></span></th>
                                <th id="distShippedDistroECCN" style="width: 110px"><span style="vertical-align: middle"><b>ECCN</b></span></th>
                                <th id="distShippedDistroRepost" style="width: 100px"*ngIf="product.isShipFailed ===true"><span style="vertical-align: middle"><b>Repost</b></span></th>
                                <th id="distShippedBinDdm" style="width: 140px" ><span style="vertical-align: middle"><b>BIN_DDM Approval</b></span></th>
                            </tr>
                        </ng-template>                            
                        <ng-template pTemplate="body" let-rowData>
                            <tr>
                                <td id="distroName"><span style="word-wrap: break-word">{{rowData.softwareDistro}}</span></td>
                                <td id="distroStatus">
                                  <span>{{rowData.status}}</span>
                                  <i class="pi pi-info-circle ng-star-inserted" *ngIf="rowData.uploadComplete===5" id="showImageStatus" pTooltip="Ship was successful for all modified images, but some non-modified images failed to ship." (click)="onShowImageStatus(rowData)" style="margin-right: 10px;"></i>
                                </td>
                                <td *ngIf="product.isShipComplete === true"><span *ngIf="rowData.uploadComplete===2 || rowData.uploadComplete===5" style="word-wrap: break-word" >{{rowData.shippedBy}}</span></td>
                                <td id="distroECCN"><span style="word-wrap: break-word">{{rowData.eccn}}</span></td>
                                <td id="failedShipAction" *ngIf="product.isShipFailed===true" >
                                  <div class="action-icon" *ngIf="rowData.uploadComplete===3">
                                    <i class="ui-icon-refresh" id="distRetryShip" pTooltip="Retry Ship" style="cursor:pointer" (click)="retryFailedUploads(rowData)"></i>
                                  </div>
                                </td>
                                <td id="distroBINDdm" >
                                  <span  *ngIf="rowData?.binDdmStatus==0"style="word-wrap: break-word ;vertical-align: middle">Not Required</span> 
                                  <span  *ngIf="rowData?.binDdmStatus==1 && stMain.serviceTasksDetails.master===true"  style="word-wrap: break-word ;vertical-align: middle"><i class="material-icons" pTooltip="Approval Required"  [ngClass]="app.sharedData.userInfo.isMasterServiceTaskManager === true ? 'enableIcon' : 'disableIcon'" (click)="openBinDdmApprovalDialog(rowData,product.productNewBuildID,product,i)">verified_user</i> </span> 
                                  <span  *ngIf="rowData?.binDdmStatus==1 && stMain.serviceTasksDetails.master===false" style="word-wrap: break-word ;vertical-align: middle"><i class="material-icons" pTooltip="Approval Required"  [ngClass]="app.sharedData.userInfo.info.internal === true ? 'enableIcon' : 'disableIcon'" (click)="openBinDdmApprovalDialog(rowData,product.productNewBuildID,product,i)">verified_user</i> </span> 
                                </td>
                            </tr>
                        </ng-template>                
                    </p-table>
                  </div>
              </div>
              <div class="ui-g-3">
                  <div class="ui-g-12">
                    <i id="refreshShipInfo" class="ui-icon-refresh" *ngIf="!(product.loadingConsolidatedCRs || product.loadingComposition || product.loadingShippedDistros || product.loadingImpactedFiles  || product.loadingDetails || product.loadingProductReleaseInfo)" pTooltip="Refresh" id = "distProductRefresh" (click)="onProductRefresh(product,i)" style="margin-top: -10px;float: right;"></i>
                    <p-progressSpinner id="refreshShipSpinner" *ngIf="product.loadingConsolidatedCRs || product.loadingComposition || product.loadingShippedDistros || product.loadingImpactedFiles  || product.loadingDetails || product.loadingProductReleaseInfo" [style]="{width: '20px', height: '20px', margin: '-10px 0 0 0', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                    <i id="emailShipInfo" class="ui-icon-email" *ngIf="app.sharedData.visibility.serviceTaskBuild.emailCR && !(product.loadingConsolidatedCRs || product.loadingComposition || product.loadingShippedDistros || product.loadingImpactedFiles|| product.loadingDetails || product.loadingProductReleaseInfo)" id="distEmail" pTooltip="Email" (click)="emailReleaseInfo(product)" style="margin-top: -10px;float: right; cursor: pointer;"></i>
                  </div>
                  <div class="ui-g-12" style="text-align: right;">
                      <button id ="downloadBuildButton" *ngIf="(product.detail?.status === 'Completed' || product.detail?.status === 'Approved') && stMain.serviceTasksDetails.abandoned == false && product.releaseInfo!=undefined && app.sharedData.visibility.software.download" 
                           pButton type="button" label="Download Build" (click)="onDownloadActionClick(product.releaseInfo)" 
                           style="min-width: 55px;">
                      </button>
                  </div>
                  <div class="ui-g-12" style="text-align: right;">
                    <button id ="shipBuildButton" *ngIf="product.compositionLoaded && (product.detail?.status === 'Completed' || product.detail?.status === 'Approved') && app.sharedData.visibility.serviceTaskDist.shipProduct && stMain.serviceTasksDetails.abandoned == false" 
                        pButton id ="distShipBuildBtn" type="button" label="Ship Build" (click)="onShipActionClick(product.productNewBuildID)" 
                        style="min-width: 55px;">
                    </button>
                  </div>
                  <div class="ui-g-12" style="text-align: right;">
                    <button id ="diffDownloadButton" *ngIf="(product.detail?.status === 'Completed' || product.detail?.status === 'Approved') && stMain.serviceTasksDetails.abandoned == false && product.releaseInfo!=undefined && app.sharedData.visibility.software.download" 
                          pButton id ="distDiffDownloadBtn" type="button" label="Diff Download" (click)="onDiffDownloadActionClick(product.releaseInfo)" 
                          style="min-width: 55px;">
                    </button>
                  </div>
                  <div class="ui-g-12" style="text-align: right;">
                    <button id ="distDiffShipButton" *ngIf="product.compositionLoaded && (product.detail?.status === 'Completed' || product.detail?.status === 'Approved') && stMain.serviceTasksDetails.abandoned == false && product.releaseInfo!=undefined" 
                        pButton type="button" label="Request Diff" (click)="onDiffShipActionClick(product.releaseInfo)" 
                        style="min-width: 55px;">
                    </button>
                  </div>
              </div>
          </div>                        
          <div class="ui-g" *ngIf="product.impactedFiles?.length > 0">
            <div class="ui-g-12"> 
              <div style="margin-bottom:10px;">
                  <p-accordion [multiple]="true">
                    <p-accordionTab header="Impacted Files - CRs">
                      <p-table [value]="product.impactedFiles" [columns]="impactedFileTableCols">
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td colspan="2">No Impacted File(s).</td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th *ngFor="let col of columns" id="{{col.field}}" [pSortableColumn]="col.field" [ngStyle]="col.style">
                                  {{col.header}}
                                  <p-sortIcon *ngIf="col.field !== ''" [field]="col.field"></p-sortIcon>
                              </th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData>
                          <tr *ngFor="let file of rowData.files; let i = index">
                            <td *ngIf="i===0" [attr.rowspan]="rowData.files?.length" style="width: 100px; vertical-align: top;">
                              {{rowData.crNumber}}
                            </td>
                            <td>{{file.filePath}}/{{file.fileName}}</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-accordionTab>
                  </p-accordion>
              </div>
            </div>
          </div> 
          <h1 *ngIf="product?.productNewBuildID!=null">Composition:</h1>
          <p-table *ngIf="product?.productNewBuildID!=null" [value]="product.composition" [style]="{'width': '560px'}" [loading]="product.loadingComposition">
              <ng-template pTemplate="header" let-columns>
                  <tr>
                      <th id="distProductImage" style="width: 180px"><b>Image</b></th>
                      <th id="distProductVersion"><b>Version</b></th>
                      <th id="distActions" *ngIf=" (product.detail?.status === 'Completed' || product.detail?.status === 'Approved') && stMain.serviceTasksDetails.abandoned == false && product.releaseInfo!=undefined && app.sharedData.visibility.software.download &&(product.isImageDownloadAvailable==true  || availableDiffImageBuilds?.length > 0)"><b>Actions</b></th>
                  </tr>
              </ng-template>                            
              <ng-template pTemplate="body"let-rowData let-columns="columns">
                  <tr>
                      <td [ngClass]="{'is-build-change': rowData.isBuildChange==true}"><span style="word-wrap: break-word">{{rowData.softwareImage}}</span></td>
                      <td [ngClass]="{'is-build-change': rowData.isBuildChange==true}"><span style="word-wrap: break-word">{{rowData.softwareImageBuild}}</span></td>
                      <td [ngClass]="{'is-build-change': rowData.isBuildChange==true}" style="width: 90px" *ngIf="(product.detail?.status === 'Completed' || product.detail?.status === 'Approved')  && stMain.serviceTasksDetails.abandoned == false && product.releaseInfo!=undefined && app.sharedData.visibility.software.download  &&(product.isImageDownloadAvailable==true  || availableDiffImageBuilds?.length > 0)" >
                        <div class="action-icon" *ngIf="rowData.availableDistros?.length > 0">
                          <i class="ui-icon-cloud-download"  id="distImageDownload" pTooltip="Download Image" style="cursor:pointer" (click)="onDownloadImageActionClick( product.releaseInfo,rowData)"></i>
                        </div>
                        <div class="action-icon" *ngIf="rowData.isDiffAvailable==true">
                          <i class="ui-icon-chrome-reader-mode" *ngIf="rowData.isDiffAvailable==true" id="distImageDiffDownload" pTooltip="Diff Download Image" style="cursor:pointer" (click)="onDiffDownloadImageActionClick( product,rowData)"></i>
                        </div>
                      </td>
                  </tr>
              </ng-template>                
              <ng-template pTemplate="emptymessage">
                  <tr>
                  <td colspan="2">No data found.</td>
                  </tr>
              </ng-template>
          </p-table>
      </p-accordionTab>
  </p-accordion>
</div>
        
        <div class="card" *ngIf="shipProcess.displayDistro">
            <p-dialog header="Ship {{shipProcess.softwareProductBuild}}"
              [(visible)]="shipProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
              <p-listbox id="distSoftwareDistroTypesforShip" [options]="shipProcess.distroTypes" [(ngModel)]="shipProcess.distroSelected"
                [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="softwareDistro">
                <p-header>
                  Select Distros
                </p-header>
              </p-listbox>
              <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                  <p-progressSpinner *ngIf="shipProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                  <button pButton id="distRequestShipButton" type="button" (click)="onShipButtonClick($event)" label="Request Ship"
                    [disabled]="shipProcess.distroSelected?.length === 0" icon="ui-icon-cloud-queue"></button>
                </div>
              </p-footer>
            </p-dialog>
        </div>
        <div class="card" *ngIf="downloadProcess.displayDistro">
          <p-dialog id="DownloadBuild" header="Download {{downloadProcess.softwareBuildSelected.softwareProductBuild}}"
            [(visible)]="downloadProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <p-table id="downloadTable" #downloadTable  [value]="downloadProcess.approvedDistroTypes" selectionMode="multiple" [(selection)]="downloadProcess.distroSelected" sortField="uploadComplete" [sortOrder]="-1"
            [globalFilterFields]="['softwareDistro','status']">
                <ng-template pTemplate="caption">
                  <div style="text-align: right">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input #versionGlobalFilter id="distFilterforDistro" class="filter-clear" type="text" pInputText size="50" placeholder="Distro"
                      (input)="downloadTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="2">No shipped distros.</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th id="distDownloadBuildistro" style="width: 340px"><span style="vertical-align: middle">Distro </span></th>
                        <th id="distDownloadBuildShipStatus" style="width: 130px"><span style="vertical-align: middle">Ship Status</span></th>
                        <th id="distDownloadBuildShipRepost" style="width: 80px" *ngIf="downloadProcess.shipFailed ===true"><span style="vertical-align: middle">Repost</span></th>
                     </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData" [pSelectableRowDisabled]="rowData.uploadComplete!==2 && rowData.uploadComplete!=5">
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareDistro}}</span></td>
                        <td>
                          <span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.status}}</span>
                          <i class="pi pi-info-circle ng-star-inserted" *ngIf="rowData.uploadComplete===5" id="showImageStatus" pTooltip="Ship was successful for all modified images, but some non-modified images failed to ship." (click)="onShowImageStatus(rowData)" style="margin-right: 10px;"></i>
                        </td>
                        <td *ngIf="downloadProcess.shipFailed ===true"> 
                          <div class="action-icon" *ngIf="rowData?.uploadComplete===3">
                            <i class="ui-icon-refresh" id="distRetryShipForDownloadBuild" pTooltip="Retry Ship" style="cursor:pointer" (click)="retryFailedUploads(rowData)"></i>
                          </div>
                        </td>
                    </tr>
                </ng-template>
              </p-table>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <div *ngIf="!app.sharedData.appInfo.isElectronMode">
                  <i class="pi pi-exclamation-triangle" id="warningNoteForDiff"></i><span style="margin-left: 7px;">Please disable browser popup blocker</span>
                </div>
                <p-progressSpinner *ngIf="downloadProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                <button  pButton id="distDownloadButton" type="button" (click)="onDownloadButtonClick($event)" label="Download"
                  [disabled]="downloadProcess.approvedDistroTypes.length===0 || downloadProcess.distroSelected?.length === 0" icon="ui-icon-cloud-download"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="diffShipProcess.displayBuild">
          <p-dialog id="distDiffRequestBuild" header="Diff Request" [(visible)]="diffShipProcess.displayBuild" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
              <div class="ui-g-12">
                <b>Selected Build : </b>
                {{diffShipProcess.softwareBuildSelected.softwareProductBuild}}
              </div>
            </div>
            <p-table id="diffShipTableAll" *ngIf="diffShipProcess.displayAllBaseBuilds" #diffShipTableAll  [value]="diffShipProcess.availableBaseBuilds" selectionMode="single" [(selection)]="diffShipProcess.baseBuildSelected" sortField="shipDate" [sortOrder]="-1"
            [globalFilterFields]="['tag','softwareProductBuild','shipDate']">
                <ng-template pTemplate="caption">
                  <div style="text-align: right">
                    <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                    <input #versionGlobalFilter class="filter-clear" id="enterTargetBuildForFilter" type="text" pInputText size="50" placeholder="Target Build"
                      (input)="diffShipTableAll.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </ng-template>
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th id="diffRequestVersion"><span style="vertical-align: middle">Version</span></th>
                        <th id="diffRequestSoftwareBuild"><span style="vertical-align: middle">Software Build</span></th>
                        <th id="diffRequestReleaseDate"><span style="vertical-align: middle">Release Date</span></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProductBuild}}</span></td>
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.shipDate}}</span></td>
                    </tr>
                </ng-template>
              </p-table>
              <p-table id="diffShipTableFiltered" *ngIf="!diffShipProcess.displayAllBaseBuilds" #diffShipTableFiltered  [value]="diffShipProcess.filteredBaseBuilds" selectionMode="single" [(selection)]="diffShipProcess.baseBuildSelected" sortField="shipDate" [sortOrder]="-1"
              [globalFilterFields]="['tag','softwareProductBuild','shipDate']">
                  <ng-template pTemplate="caption">
                    <div style="text-align: right">
                      <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                      <input #versionGlobalFilter class="filter-clear" id="enterTargetBuildForFilter" type="text" pInputText size="50" placeholder="Target Build"
                        (input)="diffShipTableFiltered.filterGlobal($event.target.value, 'contains')" style="width:auto">
                    </div>
                  </ng-template>
                      <ng-template pTemplate="header" let-columns>
                        <tr>
                          <th id="diffRequestVersion"><span style="vertical-align: middle">Version</span></th>
                          <th id="diffRequestSoftwareBuild"><span style="vertical-align: middle">Software Build</span></th>
                          <th id="diffRequestReleaseDate"><span style="vertical-align: middle">Release Date</span></th>
                      </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                          <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                          <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProductBuild}}</span></td>
                          <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.shipDate}}</span></td>
                      </tr>
                  </ng-template>
                </p-table>
              <p-footer>
                <div align="left">
                  <p-inputSwitch inputId="filterBuilds" [(ngModel)]="diffShipProcess.displayAllBaseBuilds"  (onChange)="onFilterDiffBuilds($event)"></p-inputSwitch>
                  <label for="filterBuilds">
                    Show all available releases 
                  </label>
                </div>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                  <p  style="color: red;text-align: left;" *ngIf="diffShipProcess.displayAllBaseBuilds">
                    NOTE:The diff generation is not guaranteed between unrelated builds
                  </p>
                  <button  pButton id="distDiffShipDistroOptions" type="button" (click)="onSelectDistroButtonClick($event)" label="Select Distro >"
                    [disabled]="diffShipProcess.baseBuildSelected === undefined"></button>
                </div>
              </p-footer>  
          </p-dialog>
        </div>
        <div class="card" *ngIf="diffShipProcess.displayDistro">
          <p-dialog id="diffRequestDistro" header="Diff Request" [(visible)]="diffShipProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
              <div class="ui-g-12">
                <b>Selected Build : </b>
                {{diffShipProcess.softwareBuildSelected.softwareProductBuild}}
              </div>
            </div>
            <p-listbox id="distDiffReqSelectDistros" [options]="diffShipProcess.distroTypes" [(ngModel)]="diffShipProcess.distroSelected"
              [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="softwareDistro">
              <p-header>
                Select Distros
              </p-header>
            </p-listbox>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <p-progressSpinner *ngIf="diffShipProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                <button pButton id="distReqDiffShipBtn" type="button" (click)="onDiffShipButtonClick($event)" label="Request Diff Ship"
                [disabled]="diffShipProcess.distroSelected?.length === 0" icon="ui-icon-cloud-queue"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="diffDownloadProcess.displayBuild">
          <p-dialog id="diffDownloadBuild" header="Diff Download" [(visible)]="diffDownloadProcess.displayBuild" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
              <div class="ui-g-12">
                <b>Selected Build : </b>
                {{diffDownloadProcess.softwareBuildSelected.softwareProductBuild}}
              </div>
            </div>
            <p-table id="diffDownloadTable" #diffDownloadTable [value]="diffDownloadProcess.availableBaseBuilds" selectionMode="single" compareSelectionBy="equals" [(selection)]="diffDownloadProcess.baseBuildSelected" sortField="shipDate" [sortOrder]="-1"
            [globalFilterFields]="['spfTag','tag','softwareProductBuild','distroAvailable.softwareDistro','shipDate','distroAvailable.status']">
            <ng-template pTemplate="caption">
              <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Target Build"
                  (input)="diffDownloadTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="7">No shipped distros.</td>
              </tr>
            </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th id="diffRequestSPFVersion"><span style="vertical-align: middle">SPF Version</span></th>
                  <th id="distDiffDownloadBuildVersion"><span style="vertical-align: middle">Version</span></th>
                  <th id="distDiffDownloadBuild"><span style="vertical-align: middle">Software Build</span></th>
                  <th id="distDiffDownloadAvailableDistros"><span style="vertical-align: middle">Available Distro</span></th>
                  <th id="distDiffDownloadReleaseDate"><span style="vertical-align: middle">Release Date</span></th>
                  <th id="distDiffDownloadShipStatus"><span style="vertical-align: middle">Ship Status</span></th>
                  <th id="distDiffDownloadRepost" *ngIf="diffDownloadProcess.shipFailed ===true ||app.sharedData.visibility.serviceTaskDist.viewRepostButton"><span style="vertical-align: middle">Repost</span></th>
              </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData" [pEditableRow]="rowData" [pSelectableRowDisabled]="rowData.distroAvailable.uploadComplete!==2">
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.spfTag}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProductBuild}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.distroAvailable.softwareDistro}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.shipDate}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.distroAvailable.status}}</span></td>
                      <td *ngIf="diffDownloadProcess.shipFailed ===true"> 
                        <div class="action-icon" *ngIf="rowData?.distroAvailable.uploadComplete===3">
                          <i class="ui-icon-refresh" id="distRefresh" pTooltip="Retry Ship" style="cursor:pointer" (click)="repostDiffShipRequest(rowData)"></i>
                        </div>
                      </td>
                      <td *ngIf="app.sharedData.visibility.serviceTaskDist.viewRepostButton && rowData?.distroAvailable.uploadComplete===2"> 
                        <div class="action-icon" *ngIf="rowData?.distroAvailable.uploadComplete===2">
                          <i class="ui-icon-refresh" id="distReplay" pTooltip="Replay Diff Ship" style="cursor:pointer" (click)="onReplayActionClick(rowData)"></i>
                        </div>
                      </td>
                  </tr>
              </ng-template>
            </p-table>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <div *ngIf="!app.sharedData.appInfo.isElectronMode">
                  <i class="pi pi-exclamation-triangle" id="warningNoteForDiff"></i><span style="margin-left: 7px;">Please disable browser popup blocker</span>
                </div>
                <p style="display: inline-flex;">
                <i  *ngIf="diffDownloadProcess.baseBuildSelected !== undefined" class="pi pi-clone"  tooltipPosition="left" pTooltip="CLI Command" style="cursor: pointer; font-size: 1.5rem; color:black" (click)="openCLIDialogBox()"></i>
                <p-progressSpinner *ngIf="diffDownloadProcess.loadingBaseBuilds" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                <button pButton type="button" id="distDiffDownloadButton" (click)="onDiffDownloadButtonClick($event)" label="Diff Download"
                [disabled]="diffDownloadProcess.baseBuildSelected === undefined"></button>
              </p>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="downloadImageProcess.displayDistro">
          <p-dialog header="Download Image {{downloadImageProcess.softwareImageBuild}}"
            [(visible)]="downloadImageProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <p-listbox id="distSoftwareDistroTypesforDownloadImage" [options]="downloadImageProcess.distroTypes" [(ngModel)]="downloadImageProcess.distroSelected"
                 [filter]="true" optionLabel="softwareDistro">
              <p-header>
                Select Distros
              </p-header>
            </p-listbox>
            <p-footer>
              <div *ngIf="!app.sharedData.appInfo.isElectronMode">
                <i class="pi pi-exclamation-triangle" id="warningNoteForDiffImgDownload"></i><span style="margin-left: 7px;">Please disable browser popup blocker</span>
              </div>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <button pButton id="distDownloadImageButton" type="button" (click)="onDownloadImageButtonClick($event)" label="Download Image"
                  [disabled]="downloadImageProcess.distroSelected == undefined" icon="ui-icon-cloud-queue"></button>
              </div>
            </p-footer>
          </p-dialog>
    </div>
    <div class="card" *ngIf="diffDownloadImageProcess.displayBuild">
      <p-dialog id="diffDownloadImageBuild" header="Diff Download Image" [(visible)]="diffDownloadImageProcess.displayBuild" modal="modal" showEffect="fade" [style]="{width: '600px'}">
        <div class="ui-g">
          <div class="ui-g-12">
            <b>Selected Image Build : </b>
            {{diffDownloadImageProcess.baseSoftwareImageBuild}}
          </div>
        </div>
        <p-table id="diffDownloadImageTable" #diffDownloadImageTable [value]="diffDownloadImageProcess.availableBaseBuilds" selectionMode="single" compareSelectionBy="equals" [(selection)]="diffDownloadImageProcess.baseBuildSelected" sortField="shipDate" [sortOrder]="-1"
        [globalFilterFields]="['spfTag','tag','softwareProductBuild','distroAvailable.softwareDistro','shipDate','distroAvailable.status','imageBuildId']">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Target Build"
              (input)="diffDownloadImageTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">No shipped distros.</td>
          </tr>
        </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th id="diffRequestSPFVersion"><span style="vertical-align: middle">SPF Version</span></th>
              <th id="distDiffDownloadBuildVersion"><span style="vertical-align: middle">Version</span></th>
              <th id="distDiffDownloadBuild"><span style="vertical-align: middle">Software Build</span></th>
              <th id="distDiffDownloadImage"><span style="vertical-align: middle">Image Build</span></th>
              <th id="distDiffDownloadAvailableDistros"><span style="vertical-align: middle">Available Distro</span></th>
              <th id="distDiffDownloadReleaseDate"><span style="vertical-align: middle">Release Date</span></th>
              <th id="distDiffDownloadShipStatus"><span style="vertical-align: middle">Ship Status</span></th>
              
          </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr [pSelectableRow]="rowData" [pEditableRow]="rowData" [pSelectableRowDisabled]="rowData.distroAvailable.uploadComplete!==2">
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.spfTag}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProductBuild}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.imageBuildId}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.distroAvailable.softwareDistro}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.shipDate}}</span></td>
                  <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.distroAvailable.status}}</span></td>
              </tr>
          </ng-template>
        </p-table>
        <p-footer>
          <div *ngIf="!app.sharedData.appInfo.isElectronMode">
            <i class="pi pi-exclamation-triangle" id="warningNoteForDiffImgDownload"></i><span style="margin-left: 7px;">Please disable browser popup blocker</span>
          </div>
          <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <p-progressSpinner *ngIf="diffDownloadImageProcess.loadingBaseBuilds" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            <button pButton type="button" id="distDiffImageDownloadButton" (click)="onDiffDownloadImageButtonClick($event)" label="Diff Download"
            [disabled]="diffDownloadImageProcess.baseBuildSelected === undefined"></button>
          </div>
        </p-footer>
      </p-dialog>
    </div>
    <p-dialog [(visible)]="imageShipStatus.showImageStatus" modal="modal" showEffect="fade" header="Image Status">      
      <p-table  id="imageStatusTable" #imageStatusTable [value]="imageShipStatus.softwareImageShipStatus" [style]="{'width': '600px'}"sortField="uploadComplete"   [globalFilterFields]="['softwareImagebuild']" [rows]="8" [paginator]="true">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
            <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Software Image"
              (input)="imageStatusTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">No Images.</td>
          </tr>
        </ng-template> 
        <ng-template pTemplate="header" let-columns>
           <tr>
             <th id="imageName"><span style="vertical-align: middle">Software Image</span></th>
             <th id="shipStatus"><span style="vertical-align: middle">Status</span></th>
           </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
              <tr>
                <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareImagebuild}}</span></td>
                <td >
                  <p *ngIf="rowData.uploadComplete===0">Queued</p>
                  <p *ngIf="rowData.uploadComplete===1">In Progress</p>
                  <p *ngIf="rowData.uploadComplete===2">Success</p>
                  <p *ngIf="rowData.uploadComplete===3">Failed</p>
                </td>
              </tr>
          </ng-template>
      </p-table>
      <button pButton id="distroHideImageStatus" type="button" (click) = "imageShipStatus.showImageStatus=false" label="OK" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
  </p-dialog>
  <div class="card" *ngIf="diffDownloadProcess.showConfirmDialog">
    <p-dialog id="replayDiffShipDialogBoxHeader" header="Replay Diff Ship" [(visible)]="diffDownloadProcess.showConfirmDialog" modal="modal"
      showEffect="fade" [style]="{width: '550px'}">
      <div class="ui-g">
          <div class="ui-g-12">
          <p id="replayDiffShipConfirmation">Are you sure you want to requeue the Diff request for <b>{{diffDownloadProcess.softwareBuildSelected?.softwareProductBuild}}</b> and <b>{{diffDownloadProcess.baseBuildSelected?.softwareProductBuild}} </b> for Distro <b> {{diffDownloadProcess.baseBuildSelected?.distroAvailable?.softwareDistro}}</b>?</p>
          </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
          <button id="abandonTask" pButton type="button" (click)="replayDiffShipRequest()" label="Yes"></button>
          <button id="cancelAbandonTask" pButton type="button" (click)="diffDownloadProcess.showConfirmDialog=false" label="No"></button>
        </div>
      </p-footer>
    </p-dialog>
  </div>

  <div class="card" *ngIf="cliCommandProcess.display">
    <p-dialog id="cliCommandProcessDialog" header="CLI Command" [(visible)]="cliCommandProcess.display" modal="modal"
      showEffect="fade" [style]="{width: '550px'}">
      <div class="ui-g">
          <div class="ui-g-12" style="background: black;color: wheat;">
          <code>
            <span>
              {{cliCommandProcess.cliDiffDownloadCommand}}
            </span>
          </code> 
          </div>
      </div>
      <p-footer>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <p style="display: inline-flex;">
            <!-- <i class="pi pi-check-circle" *ngIf="cliCommandProcess.isCopiedToClipboard" style=" font-size: 1rem;color: green;">Copied!!</i>  -->
            <i class="pi pi-clone"  tooltipPosition="right" pTooltip="Copy to Clipboard" style="cursor: pointer; font-size: 1.5rem;" [ngStyle]="{'color':cliCommandProcess.isCopiedToClipboard ? 'green': 'black'}" (click)="copyToClipboard(cliCommandProcess.cliDiffDownloadCommand)"></i>
            </p>
        </div>
      </p-footer>
    </p-dialog>
  </div>


<!--BIN_DDM Region-->
  <div class="card" *ngIf="binDdmApprovalProcess.display">
    <p-dialog id="binDdmApprovalProcessDialogBoxHeader" header="BIN DDM Approval Process" [(visible)]="binDdmApprovalProcess.display" modal="modal" showEffect="fade" [style]="{width: '700px'}">
      <p-tabView [(activeIndex)]="binDdmApprovalProcess.tabIndex">
        
        <p-tabPanel header="1. Jira Ticket" leftIcon="pi pi-ticket">
          <div class="ui-g">
            <div class="ui-g-12">
                <span id="jiraTicketIDupload">Upload Jira Ticket ID to Service Task {{app.sharedData.service.common.selectedServiceTaskID}}</span>
            </div>
          </div>
          <div class="ui-g">
              <div class="ui-g-3">
                  <span><b>Jira Ticket ID </b></span>
              </div>
              <div class="ui-g-9" *ngIf="jiraTicketIDUploadProcess.jiraUrl !== null">
                : <span (click)="app.goToUrl(jiraTicketIDUploadProcess?.jiraUrl)" target="_blank" style="cursor: pointer ; color: blue; display: inline-block;"> QUICLOSTEX-{{jiraTicketIDUploadProcess?.jiraTicketID}}</span>
              </div>
              
              <div class="ui-g-9" *ngIf="jiraTicketIDUploadProcess.jiraUrl == null">
                <span class="md-inputfield md-inputfield-fill">
                  : <textarea id="jiraTicketID"  [(ngModel)]="jiraTicketIDUploadProcess.jiraTicketID" placeholder="Eg: QUICLOSTEX-1234" pInputTextarea autoResize="autoResize" [style]="{width: '90%'}">
                  </textarea>
                  <i class="ui-icon-cancel" id="clearSTImageFilter" pTooltip="Clear Filter" (click)="jiraTicketIDUploadProcess.jiraTicketID = undefined;" style="cursor: pointer;"></i>
                </span>
              </div>
          </div>

          <div class="ui-g">
            <div class="ui-g-3">
                <span><b>Jira Ticket Status  </b></span>
            </div>
            <div class="ui-g-3">
              <span *ngIf="jiraTicketIDUploadProcess.jiraTicketStatus==null">: Not Submitted</span>
              <span *ngIf="jiraTicketIDUploadProcess.jiraTicketStatus=='0'"> : Open</span>
              <span *ngIf="jiraTicketIDUploadProcess.jiraTicketStatus=='1'"> : In-Progress</span>
              <span *ngIf="jiraTicketIDUploadProcess.jiraTicketStatus=='2'"> : Closed</span>
           </div>
        </div>

        <div class="ui-g">
          <div class="ui-g-3">
              <span><b>LOST Resolution </b></span>
          </div>
          <div class="ui-g-3">
            <span>: {{jiraTicketIDUploadProcess.lostResolution==null ? 'Not Submitted' : jiraTicketIDUploadProcess.lostResolution}} </span>
         </div>
      </div>
      
        </p-tabPanel>

        <p-tabPanel header="2. Notice File" leftIcon="ui-icon-file-upload" [disabled]="jiraTicketIDUploadProcess.jiraTicketStatus!='2' && binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.lostResolution !=='Fully Approved'" >
        <div class="ui-g">
            <div class="ui-g-9">
                <span id="localFileUploadST" *ngIf="binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus == null || binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus===0 || binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus==1">Upload Notice file to Service Task {{app.sharedData.service.common.selectedServiceTaskID}}</span>   
                <span id="localFileUploadST" *ngIf="binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus==2">Notice file uploaded successfully to Service Task {{app.sharedData.service.common.selectedServiceTaskID}}. No need to upload again.</span>   
            </div>
            <div class="ui-g-3">
              <button pButton id="noticeFiledownloadBtn" type="button" label="Download Notice File" (click)="downloadNoticeFile(binDdmApprovalProcess?.selectedSoftwareProductDistro?.softwareDistro,binDdmApprovalProcess?.softwareProductBuildId)"  [disabled]="binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus == null || binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus===0 || binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus==1 " icon="ui-icon-cloud-download"></button>
            </div>
        </div>
      
        <div class="ui-g">
          <p-blockUI [target]="NoticeFileuploadPanel" [blocked]="binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus==2">
            <i class="pi pi-lock" style="position:absolute;top:37%;left:50%;font-size: 50px;color: black"></i>
         </p-blockUI>
         <p-panel #NoticeFileuploadPanel>
          <div class="ui-g-12" #NoticeFileuploadPanel>

            <div class="noticeFiledropzone" appDrag (files)="noticefileDropped($event)">
                <div class="text-wrapper">
                    <div class="centered">
                        <span>Drop file to upload,or    
                            <label>click to browse
                            <input type="file" id="file" style="display: none"  (change)="noticefileDropped($event)" multiple>
                            </label>
                        </span>
                    </div>
                </div>
            </div>

            <p-table id="noticeFileUploadProcessTable" #filesSelectedTableForNoticeFile [value]="noticeFileUploadProcess.filesInfo" [rows]="4">
              <ng-template pTemplate="header" let-columns>
                  <tr>
                      <th style="width: 200px;">Filename</th>
                      <th style="width: 60px;">File Size</th>
                      <th style="width: 140px;">Status</th>
                      <th style="width: 37px;"></th>
                  </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                  <tr>
                      <td id="noDataFoundMsg" colspan="4">No files selected.</td>
                  </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-i="rowIndex">
                  <tr>
                      <td> {{rowData.filename}}</td>
                      <td>{{(rowData.filesize/1000000).toFixed(2)}}MB</td>
                      <td><p-progressBar *ngIf="noticeFileUploadProcess.fileUploadInprogress" [value]="rowData.uploadProgress"  [style]="{'height': '6px'}"></p-progressBar></td>
                      <td id="actionIcon">
                          <div class="action-icon">
                              <i class="pi pi-times" *ngIf="!noticeFileUploadProcess.fileUploadInprogress" id="removeNoticeFile"  (click)="onRemoveNoticeFile(i)" pTooltip="Remove"></i>
                              <p-progressSpinner     *ngIf="noticeFileUploadProcess.fileUploadInprogress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                          </div>
                      </td>
                  </tr>
              </ng-template>
            </p-table>

          </div>
        </p-panel>
        </div>
        </p-tabPanel>
   
      </p-tabView>



      <p-footer>
        <div *ngIf="binDdmApprovalProcess.tabIndex === 0" class="ui-dialog-buttonpane ui-helper-clearfix">
            <div class="ui-g-12 message message-error" *ngIf="jiraTicketIDUploadProcess.errorMessage !== ''">
                <span id="jiraTicketIDErrorMessage">{{jiraTicketIDUploadProcess.errorMessage}}</span>
            </div>
            <div class="ui-g-12 message" *ngIf="jiraTicketIDUploadProcess.successMessage !== ''">
              <span id="jiraTicketIDErrorMessage">{{jiraTicketIDUploadProcess.successMessage}}</span>
           </div>
            <div class="ui-g-12">
                <p-progressSpinner *ngIf="jiraTicketIDUploadProcess.ticketIdUploadInprogress==true"
                    [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                </p-progressSpinner>
             
                <button id="UploadjiraTicketID" style="margin-right: 13px;" pButton type="button" (click)="uploadJiraTicketID()" label="Submit"  [disabled]="jiraTicketIDUploadProcess.jiraTicketID == '' ||jiraTicketIDUploadProcess.jiraTicketID == undefined || jiraTicketIDUploadProcess.errorMessage !== '' || jiraTicketIDUploadProcess.successMessage!=='' || jiraTicketIDUploadProcess.ticketIdUploadInprogress===true ||jiraTicketIDUploadProcess.jiraUrl!==null"></button>
               
            </div>
        </div>
        <div *ngIf="binDdmApprovalProcess.tabIndex === 1" class="ui-dialog-buttonpane ui-helper-clearfix">
            <div class="ui-g-12 message message-error" *ngIf="noticeFileUploadProcess.errorMessage !== ''">
                <span id="noticeFileUploadProcessErrMsg">{{noticeFileUploadProcess.errorMessage}}</span>
            </div>
            <div class="ui-g-12 message" *ngIf="noticeFileUploadProcess.successMessage !== ''">
              <span id="jiraTicketIDErrorMessage">{{noticeFileUploadProcess.successMessage}}</span>
           </div>
            <div class="ui-g-12">
                <p-progressSpinner *ngIf="noticeFileUploadProcess.fileUploadInprogress"
                    [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                </p-progressSpinner>
                <button id="uploadNoticeFile" pButton type="button" icon="ui-icon-cloud-upload" (click)="uploadNoticeFileInitiate(binDdmApprovalProcess?.selectedSoftwareProductDistro?.softwareDistro,binDdmApprovalProcess?.softwareProductBuildId)" label="Upload"  [disabled]="noticeFileUploadProcess.fileUploadInprogress || noticeFileUploadProcess.files.length === 0 || noticeFileUploadProcess.errorMessage !== ''"></button>
            </div>
        </div>
      </p-footer>



    </p-dialog>
   
  </div>
  <!--BIN_DDM RegionEnd-->