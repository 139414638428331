<div id="successPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">
    <div class="ui-g-12">
      <div class="card card-w-title" style="height:100%;">
  
        <!-- Header : -->
        <div class="ui-g-12">
            <div class="ui-g-8" style="display:flex; justify-content: flex-start; height: 50px;">
                <h2 id="OrderConfirmation" style="align-self: center;padding-left: 10px;">Order Confirmation</h2>
            </div>
        </div>
        <hr>
  
  
        <div class="ui-g-12">
            <div class="ui-g-8" style="justify-content: flex-start; height: 50px;">
                <h2 id="OrderConfirmation" style="align-self: center;padding-left: 10px;">Thanks for your Order!</h2>
                <p id="Confirmation" style="align-self: center;padding-left: 10px;">Please check your email address for a license key, then <a style="cursor: pointer; color: blue;" (click)="goToQpmHomePage()">return to QPM</a> to download your tool purchase. </p>
            </div>
        </div>
     
      </div>
    </div>
  </div>
