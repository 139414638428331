<div id="EmailSubscriptionPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">
    <div class="ui-g-12">
      <div class="card card-w-title" style="height:100%;">
  
        <!-- Header -->
        <div class="ui-g-12">
            <div class="ui-g-12" style="display:flex; justify-content: flex-start; height: 50px;flex-direction: column-reverse;">
                <h2 id="EmailSubscriptionPageHeader"  style="padding-left: 10px; color:black ;margin-bottom:2px;">License Group Email Updates</h2>
            </div> 
        </div>
        <hr>
        <div class="ui-g-12">
            <h4 id="EmailSubscriptionPageDescription"  style="padding-left: 10px; color:black ;margin-top: 4px;">Receive email updates and expiration notices about your license groups that you are subscribed to. Manage your subscribed list below.</h4>
        </div>
  
        <!--Search-->
        <div class="ui-g-12" style="margin-top: -10px;">
            <div class="ui-inputgroup">
                <span class="ui-inputgroup-addon"><i class="pi pi-search" style="margin-top: 1px;"></i></span>
                <input id="EmailSubscriptionSearch" pInputText type="text" placeholder="Search license group id or tool name" #emailsubscriptionGlobalFilter 
                (input)="currentlySubscribedTable.filterGlobal($event.target.value,'contains');availableForSubscriptionTable.filterGlobal($event.target.value,'contains');">
                <i class="ui-icon-cancel" id="EmailSubscriptionGlobalFilter" pTooltip="Clear Search" style="margin:4px 4px 0 0; cursor: pointer;" (click)="onFilterClear()"></i>        
            </div>
        </div>
    
        <!--Tables-->
        <div class="ui-g-12">
            <div class="ui-g-10" *ngIf="loadingcurrentlySubscribedDetails || loadingAvailableSubscriptionDetails">
              <h3 style="display: inline-block;">Loading Details. Please wait...</h3>
              <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
            </div>
  
  
            <div class="ui-g-12">
              <h2 style="margin-top: 10px;">Currently Subscribed</h2>
            </div>
            <div class="ui-g-12" style="margin-bottom: 20px;padding:0px;">
              <p-table  #currentlySubscribedTable  [value]="currentlySubscribedLicenseGroups" [scrollable]="false" scrollHeight="400px"
                      [loading]="loadingcurrentlySubscribedDetails"  [sortOrder]="-1" [paginator]="true" [rows]="15" [globalFilterFields]="['licensegroupUuid','allProducts']">
                      <ng-template pTemplate="caption">
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th *ngFor="let col of emailSubscriptionTableCols" id ="{{col.field}}"  [ngStyle]="col.style">
                            <span>{{col.header}}</span>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                          <td headers="emailSubscriptionTableHeader">
                            <span>
                              <p style="word-wrap: break-word"><b><a (click)="goToLicenseGroupDetailsFromSubscriptionPage(rowData?.licensegroupUuid,rowData)" style="cursor: pointer; color: blue; display: inline-block;">{{rowData?.licensegroupUuid}}</a></b></p>
                              <p style="word-wrap: break-word"><b style="color: grey;">Tools : </b> {{rowData?.allProducts}}</p>
                            </span>
                          </td>
                          <td headers="licenseType"><p style="word-wrap: break-word"><b style="color: grey;">License Type : </b> {{rowData?.licenseType}}</p></td>
                          <td headers="addaction">
                              <i class="ui-icon-minus" id="unStSubscription" *ngIf="rowData.inProgress == false" style="float: right;margin-right: 65px;cursor: pointer;" pTooltip="Unsubscribe" (click)="openSubscriptionProcessDialog(false,false,rowData)"></i>
                              <p-progressSpinner id="addSISpinner" *ngIf="rowData.inProgress == true" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                          </td>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td  colspan="3">No data found.</td>
                        </tr>
                      </ng-template>
              </p-table>
            </div>
  
  
            <div class="ui-g-12">
              <p style="display: flex;justify-content: space-between;">
                <span style="float: left;"> <h2 style=" margin-top: 10px;">Available For Subscription</h2></span> 
                <span style="float: right;"> <a style="cursor: pointer; color: blue;" (click)="openSubscriptionProcessDialog(true,true,null)"><h3 >+ Subscribe To All</h3></a> </span>
              </p>
            </div>

            <div class="ui-g-12" style="margin-bottom: 20px;padding:0px;">
              <p-table  #availableForSubscriptionTable  [value]="availableToSubscribeLicenseGroups" [scrollable]="false" scrollHeight="400px"
                      [loading]="loadingAvailableSubscriptionDetails"  [sortOrder]="-1" [paginator]="true" [rows]="15" [globalFilterFields]="['licensegroupUuid','allProducts']">
                      <ng-template pTemplate="caption">
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th *ngFor="let col of emailSubscriptionTableCols" id ="{{col.field}}"  [ngStyle]="col.style">
                            <span>{{col.header}}</span>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                          <td headers="AvailableEmailSubscriptionTableHeader">
                            <span>
                              <p style="word-wrap: break-word"><b><a (click)="goToLicenseGroupDetailsFromSubscriptionPage(rowData?.licensegroupUuid,rowData)" style="cursor: pointer; color: blue; display: inline-block;">{{rowData?.licensegroupUuid}}</a></b></p>
                              <p style="word-wrap: break-word"><b style="color: grey;">Tools : </b> {{rowData?.allProducts}}</p>
                            </span>
                          </td>
                          <td headers="licenseType"><p style="word-wrap: break-word"><b style="color: grey;">License Type : </b> {{rowData?.licenseType}}</p></td>
                          <td headers="addaction">
                              <i class="ui-icon-add" id="Subscription" *ngIf="rowData.inProgress === false" style="float: right;margin-right: 65px;cursor: pointer;" pTooltip="Subscribe" (click)="openSubscriptionProcessDialog(true,false,rowData)"></i>
                              <p-progressSpinner id="addSISpinner" *ngIf="rowData.inProgress == true" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                             
                          </td>
                          
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td colspan="3">No data found.</td>
                        </tr>
                      </ng-template>
              </p-table>
            </div>

         
        </div>


      <!--Subscribe and Unsubscribe licenseGroup-->
      <div class="card" *ngIf="subscriptionProcess.displayForm">
        <p-dialog header="{{(subscriptionProcess.isSubscriptionProcess? 'Subscribe':'Unsubscribe')}}" [(visible)]="subscriptionProcess.displayForm" modal="modal" showEffect="fade" [style]="{width: '550px'}">
              <div class="ui-g">
                  <div class="ui-g-12" *ngIf="subscriptionProcess.successMessage === ''">
                  <p *ngIf="subscriptionProcess.isSubscriptionProcess"> Are you sure you want to subscribe to receive email updates for expiration notices for selected license group(s) ?</p>
                  <p *ngIf="!subscriptionProcess.isSubscriptionProcess"> Are you sure you want to unsubscribe to receive email updates for expiration notices for selected license group(s) ?</p>
                  </div>
              </div>
              <div class="ui-g-12 message" *ngIf="subscriptionProcess.successMessage !== ''"  style="padding-top: 0px;">
                {{subscriptionProcess.successMessage}}
              </div>
              <p-footer>
                <div class="ui-g-12 message message-error" *ngIf="subscriptionProcess.errorMessage !== ''"  style="padding-top: 0px;">
                  {{subscriptionProcess.errorMessage}}
                </div>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                  <p-progressSpinner *ngIf="subscriptionProcess.loadingProcess" [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                  <span *ngIf="subscriptionProcess.successMessage===''">
                  <button *ngIf="subscriptionProcess.isSubscriptionProcess===true && subscriptionProcess.isMultipleMode===true" pButton id="multipleSubscriptionProcess" type="button" (click)="multipleSubscribeToAll()" label="Yes" [disabled]="subscriptionProcess.loadingProcess"></button>
                  <button *ngIf="subscriptionProcess.isSubscriptionProcess===false && subscriptionProcess.isMultipleMode===true" pButton id="multipleUnSubscriptionProcess" type="button" (click)="multipleUnSubscribeToAll()" label="Yes" [disabled]="subscriptionProcess.loadingProcess"></button>
                  <button *ngIf="subscriptionProcess.isSubscriptionProcess===true && subscriptionProcess.isMultipleMode===false" pButton id="singleSubscriptionProcess" type="button" (click)="subscribeForEmailNotification(subscriptionProcess.selectedLicenseGroupForNotification)" label="Yes" [disabled]="subscriptionProcess.loadingProcess"></button>
                  <button *ngIf="subscriptionProcess.isSubscriptionProcess===false && subscriptionProcess.isMultipleMode===false" pButton id="singleUnSubscriptionProcess" type="button" (click)="unSubscribeForEmailNotification(subscriptionProcess.selectedLicenseGroupForNotification)" label="Yes" [disabled]="subscriptionProcess.loadingProcess"></button>
                  
                  <button pButton id="subscriptionProcessNoBtn" type="button" (click)="resetSubscriptionProcess()" label="No" [disabled]="subscriptionProcess.loadingProcess"></button>
                  </span>
                  

                  <span *ngIf="subscriptionProcess.successMessage!==''">
                  <button  pButton id="singleUnSubscriptionProcessClose" type="button" (click)="resetSubscriptionProcess()" label="Close"></button>
                  </span>
                </div>
              </p-footer>
        </p-dialog>
      </div>



     </div>
    </div>
</div>