import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { String, StringBuilder } from 'typescript-string-operations';
import { QPMResponse, RestResponse } from 'src/app/models/response';
import { ServiceUtils } from '../ServiceUtils';
import { ApiUrls } from '../ApiUrls';
import isElectron from 'is-electron';
import { SoftwareCatalogClientService } from '../Contract/SoftwareCatalogClient';
import { ComponentBranchRequest } from 'src/app/models/software-catalog-client';

@Injectable()
export class RestSoftwareCatalogClientService implements SoftwareCatalogClientService{

  constructor(private serviceUtils : ServiceUtils, private apiUrls : ApiUrls) { 

  }
  getVersion(): Observable<RestResponse> {
    throw new Error("Method not implemented.");
  }
  getCustomersAll(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.customersAll);
    return observable;
  }
  getSoftwareProducts(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.softwareProducts);
    return observable;
  }
  getSoftwareProductsByParty(partyID: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductsByParty, partyID));
    return observable;
  }
  getSoftwareProductFamily(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.softwareProductFamily);
    return observable;
  }
  getSoftwareProductFamilyByParty(partyID: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductFamilyByParty, partyID));
    return observable;
  }
  getSoftwareProductDistros(softwareProductID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductDistros, encodeURI(softwareProductID).replace("+","%2B")));
    return observable;
  }
  getSoftwareProductCustomers(softwareProductBuildID:string,spfTag:string) : Observable<RestResponse>{
    if(spfTag==null){
      var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductCustomers, encodeURI(softwareProductBuildID).replace("+","%2B")));
    }else{
      var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductCustomersWithSpfTag, encodeURI(softwareProductBuildID).replace("+","%2B"),spfTag));
    }
    
    return observable;
  }
  getCustomerProjects(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.softwareProjects);
    return observable;
  }
  getCustomerProjectsByParty(partyNumber: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProjectsByParty, partyNumber));
    return observable;
  }
  getSoftwareProductBuilds(softwareProductID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareBuilds, encodeURI(softwareProductID).replace("+","%2B")));
    return observable;
  }
  getSoftwareProductBuildsByParty(softwareProductID: string, partyID: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareBuildsByParty, encodeURI(softwareProductID).replace("+","%2B"), partyID));
    return observable;
  }  
  getSoftwareProductBuildInfo(softwareProductBuildID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareBuildInfo, encodeURI(softwareProductBuildID).replace("+","%2B")));
    return observable;
  }
  getSoftwareProductLatestComposition(softwareProductID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductLatestComposition, encodeURI(softwareProductID).replace("+","%2B")));
    return observable;
  }
  getSoftwareImagesBuildsLatest(softwareImageBuildID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareImagesBuildsLatest, encodeURI(softwareImageBuildID).replace("+","%2B")));
    return observable;
  }
  getServiceTasks(query: string = undefined): Observable<RestResponse> {
    let amp = new RegExp(/&/,"g");
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.serviceTasks + (query === undefined? '': '?'+(isElectron()? query : encodeURI(query).replace(amp,"%26"))));
    return observable;
  }
  getServiceTaskDetails(serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDetails, serviceTaskID));
    return observable;
  }
  getServiceTaskDetailsLatestReleases(serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDetailsLatestRelease, serviceTaskID));
    return observable;
  }
  getServiceTaskDetailsHistoryCRs(serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDetailsHistoryCRs, serviceTaskID));
    return observable;
  }
  getServiceTaskDetailsReleaseCRs(serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDetailsReleaseCRs, serviceTaskID));
    return observable;
  }
  getServiceTaskDetailsBuildReleases(softwareProductBuildID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDetailsBuildRelease, encodeURI(softwareProductBuildID).replace("+","%2B")));
    return observable;
  }
  deleteSoftwareProductCustomers(serviceTaskID:string, partyId:string) : Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.deleteServiceTaskCustomer, serviceTaskID, partyId), null);
    return observable;
  }
  
  getServiceTaskBuilds(serviceTaskID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuilds, serviceTaskID));
    return observable;
  }

  getServiceTaskBuildInternalClients(softwareImageBuild:string): Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildInternalClients, encodeURI(softwareImageBuild).replace("+","%2B")));
    return observable;
  }
  
  getServiceTaskBuildDetails(serviceTaskID:string, cirrusID:string, noOfBuilds:number): Observable<RestResponse> {
    var observable;
    if(noOfBuilds === 0){
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildDetails, serviceTaskID, cirrusID));
    }
    else{
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildDetails + "?noOfBuilds=" + noOfBuilds, serviceTaskID, cirrusID));
    }
    return observable;
  }
  getServiceTaskBuildDetailsWithComponents(serviceTaskID:string, cirrusID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildDetailsWithComponents, serviceTaskID, cirrusID));
    return observable;
  }
  addBuildComponentToBranch(serviceTaskID:string,request:ComponentBranchRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.addBuildComponentToBranch, serviceTaskID), request);
    return observable;
  }
  getServiceTaskBuildCRs(serviceTaskID:string, buildRequestID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildCRs, buildRequestID));
    return observable;
  }
  getServiceTaskBuildCRStatus(cr:string, serviceTaskID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildCRStatus, cr, serviceTaskID));
    return observable;
  }
  getServiceTaskDistProducts(serviceTaskID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDistProducts, serviceTaskID));
    return observable;
  }
  getServiceTaskDistProductDetails(softwareProductBuild:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDistProductDetails, softwareProductBuild));
    return observable;
  }
  getSoftwareProductBuildComposition(softwareProductBuildID:string, HLOSonly:boolean) : Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductBuildComposition, softwareProductBuildID) + (HLOSonly ? "?HLOSOnly=true" : ""));
    return observable;
  }
  getSoftwareProductBuildConsolidatedCR(softwareProductBuildID:string) : Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.consolidatedProductCRs, softwareProductBuildID));
    return observable;
  }
  getSoftwareProductBuildImpactedFiles(softwareProductBuildID:string,isImageBuild:boolean) : Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.changeListImpactedFiles, softwareProductBuildID,isImageBuild));
    return observable;
  }
  getSoftwareProductBuildBaitImpactedFiles(softwareProductBuildID:string) : Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.baitImpactedFiles, softwareProductBuildID));
    return observable;
  }
  getSoftwareProductBuildDistros(softwareProductBuildID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductBuildDistros, encodeURI(softwareProductBuildID).replace("+","%2B")));
    return observable;
  }
  getHlosTechnologies(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.hlosTechnology);
    return observable;
  }
  getHlosTargets(softwareImage: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.hlosTarget, softwareImage));
    return observable;
  }
  getServiceTaskBaitBuilds(serviceTaskID:string, image:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBaitBuilds, serviceTaskID, image));
    return observable;
  }
  getServiceTaskBaitBuildDetails(serviceTaskID:string, buildID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBaitBuildDetails, serviceTaskID, buildID));
    return observable;
  }
  getSoftwareProductByImageBuild(imageBuild:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.spByImageBuild, imageBuild));
    return observable;
  }
  getSoftwareProductByImageBuildAndPartyId(imageBuild:string, partyID: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.spByImageBuildAndPartyID, encodeURI(imageBuild).replace("+","%2B"), partyID));
    return observable;
  }

  getLatestSoftwareImageBuilds(image:string, imageBuild:string, imageBuildType: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareImageBuildLatest, image, imageBuild) + "?buildType=" + imageBuildType);
    return observable;
  }
  getserviceTaskPassThroughBuilds(serviceTaskID: string, image: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskPassThroughBuilds, serviceTaskID, image));
    return observable;
  }

  getserviceTaskPassThroughBuildDetails(imageBuildID: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskPassThroughBuildDetails, imageBuildID));
    return observable;
  }
  getBaitLocalfileUploadBuilds(serviceTaskID: string, image: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.baitLocalfileUploadBuilds, serviceTaskID, image));
    return observable;
  }
  getBaitLocalFileUploadBuildDetails(serviceTaskID: string, buildID: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.baitLocalFileUploadBuildDetails, serviceTaskID, buildID));
    return observable;
  }
}
