import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-v1',
  templateUrl: './v1userguide.component.html',
  styleUrls: ['./v1userguide.component.css']
})
export class V1UserguideComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
}
