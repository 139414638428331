<div class="ui-g ui-fluid" style="min-height: calc(80vh);">
  <div class="ui-g-12">
    <div class="ui-g-12 ui-lg-3">
      <!-- Left Side -->
      <div class="card card-w-title">
        <h1>Software
          <i class="ui-icon-refresh" *ngIf="!(loadingProject || loadingProductFamily || loadingProduct || loadingReleases || loadingCustomers)" id="refreshSoftwareFinder" pTooltip="Refresh" (click)="onLeftPaneRefresh()" style="margin-top: -10px;float: right;"></i>
          <p-progressSpinner *ngIf="loadingProject || loadingProductFamily || loadingProduct || loadingReleases || loadingCustomers" [style]="{width: '20px', height: '20px', margin: '-10px 0 0 0', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
        </h1>
        <p-tabView>
          <p-tabPanel header="Finder" leftIcon="ui-icon-assignment">
            <div class="ui-g">
              <div class="ui-g-12"><b>Project:</b></div>
              <div class="ui-g-12" *ngIf="this.app.sharedData.visibility.software.showCustomerFilter" style="margin-top: -10px;">
                <p-dropdown id="selectCustomer" [options]="customersSelectItems" (click)="onCustomerChange()" (keyup.enter)="onCustomerChange()" placeholder="Select Customer"
                  [(ngModel)]="selectedCustomer" filter="true" [virtualScroll]="true" itemSize="30">
                  <ng-template let-item pTemplate="selectedItem">
                    {{item.label}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{item.label}}
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="ui-g-12">
                <p-dropdown id="selectProject" [options]="projectsSelectItems" (click)="onProjectChange()" (keyup.enter)="onProjectChange()" placeholder="Select Project"
                  [(ngModel)]="selectedProject" filter="true" [virtualScroll]="true" itemSize="30">
                  <ng-template let-item pTemplate="selectedItem">
                    {{item.label}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{item.label}}
                  </ng-template>
                </p-dropdown>
                <i class="pi pi-exclamation-circle" *ngIf="this.app.sharedData.visibility.software.showCustomerFilter" pTooltip="Please select customer to see project list." [style]="{'font-size': '1.7em', 'cursor': 'pointer', 'float': 'right', 'margin-top': '-25px', 'margin-right': '-22px'}"></i>
              </div>
              <div class="ui-g-12" style="margin-top: 15px;"><b>Software Product Family (SPF):</b></div>
              <div class="ui-g-12" style="margin-top: -10px;">
                <p-dropdown id="selectSPF" [options]="productFamilySelectItems" (click)="onProductFamilyChange()" (keyup.enter)="onProductFamilyChange()" placeholder="Select SPF"
                  [(ngModel)]="selectedProductFamily" filter="true" [virtualScroll]="true" itemSize="30">
                  <ng-template let-item pTemplate="selectedItem">
                    {{item.label}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{item.label}}
                  </ng-template>
                </p-dropdown>
              </div>
              <div class="ui-g-12" style="margin-top: 15px;"><b>Software Product (SP):</b></div>
              <div class="ui-g-12">
                <p-dropdown id="selectSP" [options]="productsSelectItems" (click)="onProductChange()" (keyup.enter)="onProductChange()" placeholder="Select SP"
                  [(ngModel)]="selectedProduct" filter="true" [virtualScroll]="true" itemSize="30">
                  <ng-template let-item pTemplate="selectedItem">
                    {{item.label}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{item.label}}
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel *ngIf="app.sharedData.appInfo.isElectronMode" header="Workspace" leftIcon="ui-icon-assignment-turned-in">
            <div class="downloaded-tree">
              <div style="height: 95%;">
                <h3 *ngIf="downloadedProductsTreeNodes.length === 0">No software downloaded</h3>
                <p-tree *ngIf="downloadedProductsTreeNodes.length !== 0" [value]="downloadedProductsTreeNodes"
                  (onNodeSelect)="onTreeNodeSelect($event)" selectionMode="single" [filter]="true"
                  [style]="{width: '100%'}"></p-tree>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>

    <div class="ui-g-12 ui-lg-9">
      <!-- Right Side -->
      <div class="card card-w-title">
        <h3 *ngIf="selectedProductName === ''" style="color: #EE3124; margin-bottom: 5px;">
          No product selected. Select a item from Software Finder/Workspace.
        </h3>

        <h2>Available Releases: </h2>
        <h3 *ngIf="selectedCustomerName !== '' || selectedProjectName !== '' || selectedProductFamilyName !== '' || selectedProductName !== ''" style="margin-bottom: 0px;">Current Filters: </h3>
        <span id="selectedCustomer" *ngIf="selectedCustomerName !== ''" style="display: block;">
          <b>Customer</b> : {{selectedCustomerName}}
          <i class="ui-icon-cancel" pTooltip="Clear" (click)="onCustomerClear()"></i>
        </span>
        <span id="selectedProject" *ngIf="selectedProjectName !== ''" style="display: block;">
          <b>Project</b> : {{selectedProjectName}}
          <i class="ui-icon-cancel" id="selectedProjectClear" pTooltip="Clear" (click)="onProjectClear()"></i>
        </span>
        <span id="selectedSPF" *ngIf="selectedProductFamilyName !== ''" style="display: block;">
          <b>SPF</b> : {{selectedProductFamilyName}}
          <i class="ui-icon-cancel" id="selectedProductFamilyClear"  pTooltip="Clear" (click)="onProductFamilyClear()"></i>
        </span>
        <span id="selectedSP" *ngIf="selectedProductName !== ''" style="display: block;">
          <b>SP</b> : {{selectedProductName}}
          <i class="ui-icon-cancel" id="selectedProductClear" pTooltip="Clear" (click)="onProductClear()"></i>
        </span>
        <div>
          <p-table  #versionsTable [value]="versions"[scrollable]="true" scrollHeight="380px"
            [loading]="loadingReleases" sortField="shipDate" [sortOrder]="-1" [paginator]="true" [rows]="10" [globalFilterFields]="['spfTag','tag','softwareProductBuild','branch','shipDate','distroDownloadsNames']">
            <ng-template pTemplate="caption">
              <div style="text-align: right">
                <i class="pi pi-search" id="softwareSearch" style="margin:4px 4px 0 0"></i>
                <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Filter"
                  (input)="versionsTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
                <i class="ui-icon-cancel" id="clearSoftwareSearchFilter" pTooltip="Clear Filter" (click)="onFilterClear()"
                  style="margin:4px 4px 0 0"></i>
                <i class="ui-icon-refresh" id="refreshRelease" pTooltip="Refresh" (click)="getAvailableReleaseData(selectedProductName)" style="margin:4px 4px 0 0"></i>
              </div>
            </ng-template>
            <ng-template pTemplate="header">
              <tr>
                <th *ngFor="let col of cols" id ="{{col.field}}" [pSortableColumn]="col.field"  [ngStyle]="col.style">
                  <span>{{col.header}}</span>
                  <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-columns="columns">
              <tr>
                <td headers="spfTag" *ngIf="selectedProduct.softwareProductFamily">
                  <span *ngIf="rowData.serviceTaskId === undefined || rowData.serviceTaskId === null || rowData.serviceTaskId === 0" 
                    style="vertical-align: middle; word-wrap: break-word">
                    {{rowData.spfTag}}
                  </span>
                  <span *ngIf="!(rowData.serviceTaskId === undefined || rowData.serviceTaskId === null || rowData.serviceTaskId === 0)"
                     (click)="app.goToServiceTask(rowData.serviceTaskId)" style="vertical-align: middle; cursor: pointer; color: blue; text-decoration: underline;word-wrap: break-word">
                    {{rowData.spfTag}}
                  </span>
                </td>
                <td headers="tag">
                  <span *ngIf="rowData.serviceTaskId === undefined || rowData.serviceTaskId === null || rowData.serviceTaskId === 0">
                    {{rowData.tag}}
                  </span>
                  <span *ngIf="!(rowData.serviceTaskId === undefined || rowData.serviceTaskId === null || rowData.serviceTaskId === 0)"
                     (click)="app.goToServiceTask(rowData.serviceTaskId)" style="vertical-align: middle; cursor: pointer; color: blue; text-decoration: underline;">
                    {{rowData.tag}}
                  </span>
                </td>
                <td headers="softwareProductBuild"><span style="vertical-align: middle ; word-wrap: break-word">{{rowData.softwareProductBuild}}</span></td>
                <td headers="branch"><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.branch}}</span></td>
                <td headers="shipDate"><span style="vertical-align: middle; word-wrap: break-word">{{rowData.shipDate}}</span></td>
                <td headers="distroDownloadsDisplay" *ngIf="app.sharedData.appInfo.isElectronMode">
                  <span style="vertical-align: middle ; word-wrap: break-word" (mouseenter)="onHoverDistro($event,rowData,distroOverlay)"
                    (mouseout)="onHoverDistro($event,rowData,distroOverlay)">
                    {{rowData.distroDownloadsDisplay}}
                  </span>
                </td>
                <td headers="action" style="width: 210px;">
                  <div class="action-icon" *ngIf="app.sharedData.appInfo.isElectronMode">
                    <i class="ui-icon-folder" id="openSoftwareAction" pTooltip="Open" (click)="onOpenFolderClick(rowData)"
                      *ngIf="rowData.isBuild && rowData.isDownloaded"></i>
                  </div>
                  <div class="action-icon" *ngIf="app.sharedData.appInfo.isElectronMode">
                    <i class="ui-icon-delete-forever" id="deleteSoftwareAction" pTooltip="Delete" *ngIf="rowData.isBuild && rowData.isDownloaded"
                      (click)="onDeleteActionClick(rowData)"></i>
                  </div>
                  <div class="action-icon" *ngIf="app.sharedData.visibility.software.download">
                    <i id="softwareBuildDownloadAction" class="ui-icon-cloud-download" pTooltip="Download"
                      (click)="onDownloadActionClick(rowData)"
                      *ngIf="rowData.isBuild"></i>
                  </div>
                  <div class="action-icon">
                    <i class="ui-icon-cloud-queue" id="requestSoftwareBuildShipAction" pTooltip="Request"
                      (click)="onShipActionClick(rowData)"
                      *ngIf="rowData.isBuild"></i>
                  </div>

                  <div class="action-icon" *ngIf="app.sharedData.visibility.software.download">
                    <i id="softwareDiffDownloadAction" class="ui-icon-chrome-reader-mode" pTooltip="Diff Download"
                      (click)="onDiffDownloadActionClick(rowData)"
                      *ngIf="rowData.isBuild"></i>
                  </div>
                  <div class="action-icon">
                    <i class="ui-icon-compare" id="softwareDiffShipAction" pTooltip="Request Diff"
                      (click)="onDiffShipActionClick(rowData)"
                      *ngIf="rowData.isBuild"></i>
                  </div>
<!--              <div class="action-icon">
                    <i class="ui-icon-remove-red-eye" pTooltip="Preview" (click)="dryRun(rowData)"
                      *ngIf="rowData.isBuild"></i>
                  </div-->
                  <div class="action-icon" *ngIf="app.sharedData.visibility.software.requestServiceTask">
                    <i class="ui-icon-add" id="requestServiceBuildAction" pTooltip="Create Service Task" (click)="newServiceTask(rowData)"
                      *ngIf="rowData.isBuild"></i>
                  </div>
                  <div class="action-icon">
                    <i class="ui-icon-search" id="crSearchAction" pTooltip="Search CRs" (click)="startFindCrStatusProcess(rowData, 'ProductBuild')"
                      *ngIf="rowData.isBuild"></i>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td>No data found.</td>
              </tr>
            </ng-template>
          </p-table>
          <p-overlayPanel #distroOverlay>
            <span style="vertical-align: middle">
              <p *ngFor="let distro of hoveredDistros">{{distro.distro}}</p>
            </span>
          </p-overlayPanel>
        </div>
        <div class="card" *ngIf="shipProcess.displayDistro">
          <p-dialog header="Ship {{shipProcess.softwareBuildSelected.softwareProductBuild}}"
            [(visible)]="shipProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <p-listbox id="softwareDistroTypes" [options]="shipProcess.distroTypes" [(ngModel)]="shipProcess.distroSelected"
              [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="softwareDistro">
              <p-header>
                Select Distros
              </p-header>
            </p-listbox>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <p-progressSpinner *ngIf="shipProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                <button pButton id="requestShipButton" type="button" (click)="onShipButtonClick($event)" label="Request Ship"
                [disabled]="shipProcess.distroSelected.length === 0" icon="ui-icon-cloud-queue"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="downloadProcess.displayDistro">
          <p-dialog id="DownloadBuild" header="Download {{downloadProcess.softwareBuildSelected.softwareProductBuild}}"
            [(visible)]="downloadProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <p-table id="downloadTable" #downloadTable  [value]="downloadProcess.distroTypes" selectionMode="multiple" [(selection)]="downloadProcess.distroSelected" sortField="uploadComplete" [sortOrder]="-1"
            [globalFilterFields]="['softwareDistro','status']">
                <ng-template pTemplate="caption">
                  <div style="text-align: right">
                    <i class="pi pi-search"  id="searchBuildToDownload" style="margin:4px 4px 0 0"></i>
                    <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Distro"
                      (input)="downloadTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="2">No shipped distros.</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th style="width: 340px"><span style="vertical-align: middle">Distro </span></th>
                        <th style="width: 120px"><span style="vertical-align: middle">Ship Status</span></th>
                        <th style="width: 80px" *ngIf="downloadProcess.shipFailed ===true"><span style="vertical-align: middle">Repost</span></th>
                     </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData" [pSelectableRowDisabled]="rowData.uploadComplete!==2 && rowData.uploadComplete!==5">
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareDistro}}</span></td>
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.status}}</span></td>
                        <td *ngIf="downloadProcess.shipFailed ===true"> 
                          <div class="action-icon" *ngIf="rowData?.uploadComplete===3">
                            <i class="ui-icon-refresh" id="retryShip" pTooltip="Retry Ship" style="cursor:pointer" (click)="retryFailedUploads(rowData)"></i>
                          </div>
                        </td>
                    </tr>
                </ng-template>
              </p-table>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <p-progressSpinner *ngIf="downloadProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                <button pButton id="downloadBuildButton" type="button" (click)="onDownloadButtonClick($event)" label="Download"
                  [disabled]="downloadProcess.distroSelected.length === 0" icon="ui-icon-cloud-download"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="diffShipProcess.displayBuild">
          <p-dialog header="Diff Request" [(visible)]="diffShipProcess.displayBuild" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
              <div class="ui-g-12">
                <b>Selected Build : </b>
                {{diffShipProcess.softwareBuildSelected.softwareProductBuild}}
              </div>
            </div>
            <p-table id="diffShipTable" #diffShipTable  [value]="diffShipProcess.availableBaseBuilds" selectionMode="single" [(selection)]="diffShipProcess.baseBuildSelected" sortField="shipDate" [sortOrder]="-1"
            [globalFilterFields]="['tag','softwareProductBuild','shipDate']">
                <ng-template pTemplate="caption">
                  <div style="text-align: right">
                    <i class="pi pi-search" id="searchForDiffRequest" style="margin:4px 4px 0 0"></i>
                    <input #versionGlobalFilter class="filter-clear" id="targetBuildDiffRequest" type="text" pInputText size="50" placeholder="Target Build"
                      (input)="diffShipTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
                  </div>
                </ng-template>
                    <ng-template pTemplate="header" let-columns>
                      <tr>
                        <th id="buildVersionForDiffReq"><span style="vertical-align: middle">Version</span></th>
                        <th id="softwareBuildDiffReq"><span style="vertical-align: middle">Software Build</span></th>
                        <th id="softwareReleaseDateDiffReq"><span style="vertical-align: middle">Release Date</span></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProductBuild}}</span></td>
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.shipDate}}</span></td>
                    </tr>
                </ng-template>
              </p-table>
              <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                  <button pButton id="selectDistroForDiffReqButton" type="button" (click)="onSelectDistroButtonClick($event)" label="Select Distro >"
                    [disabled]="diffShipProcess.baseBuildSelected === undefined"></button>
                </div>
              </p-footer>  
          </p-dialog>
        </div>
        <div class="card" *ngIf="diffShipProcess.displayDistro">
          <p-dialog header="Diff Request" [(visible)]="diffShipProcess.displayDistro" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
              <div class="ui-g-12">
                <b>Selected Build : </b>
                {{diffShipProcess.softwareBuildSelected.softwareProductBuild}}
              </div>
            </div>
            <p-listbox id="distroTypesForDiffReq" [options]="diffShipProcess.distroTypes" [(ngModel)]="diffShipProcess.distroSelected"
              [multiple]="true" [checkbox]="true" [filter]="true" optionLabel="softwareDistro">
              <p-header>
                Select Distros
              </p-header>
            </p-listbox>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <p-progressSpinner *ngIf="diffShipProcess.loadingDistros" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                <button pButton id="requestDiffShipButton" type="button" (click)="onDiffShipButtonClick($event)" label="Request Diff Ship"
                [disabled]="diffShipProcess.distroSelected.length === 0" icon="ui-icon-cloud-queue"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="diffDownloadProcess.displayBuild">
          <p-dialog id="diffDownloadBuild" header="Diff Download" [(visible)]="diffDownloadProcess.displayBuild" modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <div class="ui-g">
              <div class="ui-g-12">
                <b>Selected Build : </b>
                {{diffDownloadProcess.softwareBuildSelected.softwareProductBuild}}
              </div>
            </div>
            <p-table id="diffDownloadTable" #diffDownloadTable [value]="diffDownloadProcess.availableBaseBuilds" selectionMode="single" compareSelectionBy="equals" [(selection)]="diffDownloadProcess.baseBuildSelected" sortField="shipDate" [sortOrder]="-1"
            [globalFilterFields]="['tag','softwareProductBuild','distroAvailable.softwareDistro','shipDate','distroAvailable.status']">
            <ng-template pTemplate="caption">
              <div style="text-align: right">
                <i class="pi pi-search" id="searchBuildForDiffDownload" style="margin:4px 4px 0 0"></i>
                <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Target Build"
                  (input)="diffDownloadTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </div>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No shipped distros.</td>
              </tr>
            </ng-template>
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th id="versionForDiffDownload"><span style="vertical-align: middle">Version</span></th>
                  <th id="softwareBuildForDiffDownload"><span style="vertical-align: middle">Software Build</span></th>
                  <th id="distorsForDiffDownload"><span style="vertical-align: middle">Available Distro</span></th>
                  <th id="releaseDateForDiffDownload"><span style="vertical-align: middle">Release Date</span></th>
                  <th id="shipStatusForDiffDownload" ><span style="vertical-align: middle">Ship Status</span></th>
                  <th  *ngIf="diffDownloadProcess.shipFailed ===true"><span style="vertical-align: middle">Repost</span></th>
              </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr [pSelectableRow]="rowData" [pEditableRow]="rowData" [pSelectableRowDisabled]="rowData.distroAvailable.uploadComplete!==2">
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.tag}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareProductBuild}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.distroAvailable.softwareDistro}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.shipDate}}</span></td>
                      <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.distroAvailable.status}}</span></td>
                      <td *ngIf="diffDownloadProcess.shipFailed ===true"> 
                        <div class="action-icon" *ngIf="rowData?.distroAvailable.uploadComplete===3">
                          <i class="ui-icon-refresh" id="retryShipforDiffDownload" pTooltip="Retry Ship" style="cursor:pointer" (click)="repostDiffShipRequest(rowData)"></i>
                        </div>
                      </td>
                  </tr>
              </ng-template>
            </p-table>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <p-progressSpinner *ngIf="diffDownloadProcess.loadingBaseBuilds" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                <button pButton id="diffDownLoadButton" type="button" (click)="onDiffDownloadButtonClick($event)" label="Diff Download"
                [disabled]="diffDownloadProcess.baseBuildSelected === undefined"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
        <div class="card" *ngIf="deleteProcess.displayDistro">
          <p-dialog header="Delete {{deleteProcess.softwareBuildSelected.softwareProductBuild}}" [(visible)]="deleteProcess.displayDistro"
            modal="modal" showEffect="fade" [style]="{width: '600px'}">
            <p-listbox id="distrosTypesforDelete" [options]="deleteProcess.distroTypes" [(ngModel)]="deleteProcess.distroSelected"
              [multiple]="false" [checkbox]="true" [filter]="true" optionLabel="distro">
              <p-header>
                Select Distros
              </p-header>
            </p-listbox>
            <p-footer>
              <div class="ui-dialog-buttonpane ui-helper-clearfix">
                <button pButton id="deleteButton" type="button" (click)="onDeleteButtonClick($event)" label="Delete"
                [disabled]="deleteProcess.distroSelected === undefined" icon="ui-icon-delete-forever"></button>
              </div>
            </p-footer>
          </p-dialog>
        </div>
      </div>
    </div>
  </div>
</div>