import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMainComponent } from './app.main.component';
import { QPMResponse } from './models/response';
import { UserProfileService } from './service/CommonService/user-profile.service';
import { DataServiceProducer, ApiType } from './service/Factory/DataServiceProducer';
import { Router } from '@angular/router';

@Component({
    selector: 'app-inline-profile',
    template: `
        <div class="profile" [ngClass]="{'profile-expanded':active}">
            <a href="#" (click)="onClick($event)">
                <img class="profile-image" src="assets/images/QPM60.png" />
                <!--span class="profile-name">Jane Williams</span-->
                <i class="material-icons" style="display: inline-block;">keyboard_arrow_down</i>
            </a>
        </div>

        <ul class="ultima-menu profile-menu" [@menu]="active ? 'visible' : 'hidden'">
            <!--li role="menuitem">
                <a href="#" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
                    <i class="material-icons">person</i>
                    <span>Profile</span>
                </a>
            </li>
            <li role="menuitem">
                <a href="#" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
                    <i class="material-icons">security</i>
                    <span>Privacy</span>
                </a>
            </li>
            <li role="menuitem">
                <a href="#" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
                    <i class="material-icons">settings_application</i>
                    <span>Settings</span>
                </a>
            </li-->
            <li role="menuitem">
                <a  (click)="app.onLogout($event)" class="ripplelink" [attr.tabindex]="!active ? '-1' : null">
                    <i class="material-icons" *ngIf="app.sharedData.appInfo.workOffline==true"  pTooltip="Login" style="cursor:pointer">power_settings_new</i>
                    <i class="material-icons" *ngIf="app.sharedData.appInfo.workOffline==false"  pTooltip="Logout" style="cursor:pointer">power_settings_new</i>
                    <span>Logout</span>
                </a>
            </li>
        </ul>
    `,
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineProfileComponent {

    private webClient;
    active: boolean;
    pic : string;
    constructor(public app: AppMainComponent, private router: Router, private userProfileService : UserProfileService,
        private service:DataServiceProducer){
        this.webClient = service.getServiceInstance(ApiType.WebClient);

        //Get user pic
        /*let response : Observable<QPMResponse>;
        response = this.userProfileService.getUserPic();
        if(response !== null){
        response.subscribe(
            (data:QPMResponse) => { 
                //this.version = (data.isSuccess())? data.getData() : data.getError();
                if(data.isSuccess()){
                    this.pic = data.getData();
                    console.log("pic : " + this.pic);
                }
            }
        )
        }*/
        
    }

    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }
}
