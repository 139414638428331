<div class="card card-w-title">
  <h1 id="requestTriageHeader">Request Triage:</h1>
  <div style="text-align: right">      
    <i *ngIf="stMain.serviceTasksDetails.abandoned == false" class="ui-icon-refresh" id="refreshRequestTriage" pTooltip="Refresh" (click)="loadServiceTaskDetails(true)" style="cursor: pointer;"></i>
  </div>
  <p-table id="triageTable" #triageTable [columns]="cols" [value]="validationSteps" selectionMode="none" style="width:auto">
      <ng-template pTemplate="header" let-columns>
          <tr>
              <th id="triageStep" style="width: 150px">Step</th>
              <th id="triageChangeReqId" style="width: 180px">Change Request / Base Image</th>
              <th id="triageValidationStatus">Validation Status</th>
              <th id="traigeValidationDetails">Validation Details</th>
              <th style="width: 90px;"></th>
              <th style="width: 30px;"></th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
          <tr>
              <td>
                <div style="margin-left: 15px;">{{rowData.step?.step}}</div>
              </td>
              <td id="crValidationProgress" colspan="5" *ngIf="rowData.dataType === 'VALIDATECR'">
                <div *ngFor="let cr of rowData.validateCRs" style="display: inline-block; margin-bottom: 6px;">
                  <span id="invalidCrs" *ngIf="cr.status !== 'Valid' || !cr.hasSoftwareImage" style="background-color: gainsboro;border-radius: 15px;padding: 9px;">
                    <i class="ui-icon-remove-red-eye" id="triageShowCRDetails" pTooltip="CR Details" (click)="showCRDetails(cr.changeRequest)" style="margin-right: 10px;"></i>
                    <span id="addedCrLink" *ngIf="!app.sharedData.visibility.serviceTask.showCrLink">
                      {{cr?.changeRequest}}
                    </span>
                    <span *ngIf="app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                      <a id="addedCrLink" (click)="app.goToUrl('https://orbit/cr/'+cr?.changeRequest)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                        {{cr?.changeRequest}}
                      </a>
                    </span>
                    <span *ngIf="!app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                        <a id="addedCrLink" href="{{'https://orbit/cr/'+cr?.changeRequest}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                          {{cr?.changeRequest}}
                        </a>
                    </span>
                    <p-progressSpinner id="triageRunValidationSpinner" *ngIf="cr?.status == 'In Progress' || cr?.actionInProgress || cr?.deleteInProgress"
                         [style]="{width: '20px', height: '20px'}"
                        strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                    <i class="ui-icon-refresh" id="triageRunValidationAgain" pTooltip="Run validation again" 
                          *ngIf="(cr?.status === 'Invalid' || !cr?.hasSoftwareImage)
                            && cr?.action !== 'Accept'
                            && !cr?.validationInProgress
                            && app.sharedData.visibility.serviceTaskTriage.triggerValidation 
                            && stMain.serviceTasksDetails.abandoned == false" (click)="validateCR(cr)" 
                          style="text-align: right; margin: 5px;">
                    </i>
                    <i id="crInfo" class="pi pi-info-circle" *ngIf="(cr?.status === 'Invalid' || !cr?.hasSoftwareImage) && cr?.validationMsg"  
                          (mouseenter)="app.toggleOverlay($event, cr?.validationMsg + '. ' +cr?.details)"
                          (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                    </i>
                    <i class="pi pi-times" id="triageRemoveCR" pTooltip="Remove CR" *ngIf="stMain.serviceTasksDetails.abandoned == false && (cr?.status === 'Invalid' || !cr?.hasSoftwareImage) && cr?.action !== 'Accept' && !cr?.actionInProgress" (click)="onCRActionDelete(cr, true)" style="cursor: pointer"></i>
                  </span>
                </div>
              </td>
              <td *ngIf="rowData.dataType !== 'VALIDATECR'">
                <div *ngIf="rowData.dataType === 'IMAGE'">
                    {{rowData.build?.softwareImageBuild}}
                </div>
              </td>
              <td id="CRValidationStatus" *ngIf="rowData.dataType !== 'VALIDATECR'">
                <span *ngIf="rowData.dataType === 'IMAGE'">{{rowData.build?.status}}</span>
                <i class="ui-icon-refresh" id="triageRunValidationAgain" pTooltip="Run validation again" 
                  *ngIf="(rowData.dataType === 'IMAGE' && rowData.build?.status === 'Invalid')
                        && app.sharedData.visibility.serviceTaskTriage.triggerValidation 
                        && stMain.serviceTasksDetails.abandoned == false" (click)="validateBaseImages(rowData.build)" 
                  style="text-align: right; margin: 5px;"></i>
              </td>
              <td id="crValidationDetails" *ngIf="rowData.dataType !== 'VALIDATECR'">                
                <span *ngIf="rowData.dataType === 'STEP'">{{rowData.step?.statusDetails}}</span>
                <span *ngIf="rowData.dataType === 'IMAGE'">{{rowData.build?.validationMsg}}</span>
                <i class="pi pi-info-circle" *ngIf="rowData.build?.status === 'Invalid' && rowData.build?.details"  
                    (mouseenter)="app.toggleOverlay($event,rowData.build?.details)"
                    (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                </i>
              </td>
              <td  id="crCommentsAction" *ngIf="rowData.dataType !== 'VALIDATECR'">
                <div class="action-icon" *ngIf="rowData.dataType === 'IMAGE' && app.sharedData.visibility.serviceTaskTriage.actionsImage">
                  <i class="pi pi-times" id="triageRemoveImage" pTooltip="Remove Image" *ngIf="stMain.serviceTasksDetails.abandoned == false && rowData.build?.status === 'Invalid'" (click)="onImageActionDelete(rowData.build)" style="cursor: pointer"></i>
                </div>
              </td>
              <td *ngIf="rowData.dataType !== 'VALIDATECR'">
                  <p-progressSpinner *ngIf="rowData.build?.status == 'In Progress' || rowData.build?.deleteInProgress" 
                        [style]="{width: '20px', height: '20px'}"
                        strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                  </p-progressSpinner>
              </td>
          </tr>
      </ng-template>
  </p-table>
  <button *ngIf="stMain.serviceTasksDetails.abandoned == false && stMain.serviceTasksDetails.changeRequests?.length < 1000" pButton id="searchCRButton" type="button" (click)="startFindCrStatusProcess()" label="Search CR(s)" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
  <button *ngIf="stMain.serviceTasksDetails.abandoned == false && stMain.serviceTasksDetails.changeRequests?.length < 1000" pButton id="triageToAddCRButton" type="button"  (click) = "showAddCrRequest()" label="Add CR(s)" [style]="{'width': '150px', 'margin-top': '10px', 'margin-bottom': '20px', 'margin-left': '10px'}"></button>
  <button *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskTriage.searchUpdateCR" pButton id="searchUpdateCR" type="button" (click) = "onClickSearchAndUpdate()" label="Update CR(s)" pTooltip="Find pending CRs in the base build. If the CR fix present in the base build, update the status to Already Fixed." [style]="{'width': '150px', 'margin-top': '20px', 'margin-bottom': '20px', 'margin-left': '10px'}"></button>
  <button *ngIf="stMain.serviceTasksDetails.abandoned == false && stMain.serviceTasksDetails.createdUsingSI && app.sharedData.visibility.serviceTaskTriage.addImage" pButton id="triageAddImageButton" type="button" (click) = "onImageActionAdd()" label="Add Image(s)" [style]="{'width': '150px', 'margin-top': '10px', 'margin-bottom': '20px', 'margin-left': '10px'}"></button>
  <button *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskTriage.addGerrit" pButton  id="triageAddGerritButton" type="button" (click) = "onGerritActionAdd()" label="Add Gerrit(s)" [style]="{'width': '150px', 'margin-top': '10px', 'margin-bottom': '20px', 'margin-left': '10px'}"></button>
  
  
  <p-accordion *ngFor="let image of stMain.serviceTasksDetails.changes; let i = index" (onOpen)="onImageExpand(image)" [multiple]="true">
    <p-accordionTab header={{image.softwareImage}}>
      <div class="ui-g">
        <div class="ui-g-12">
            <span>
              <b>Base SI Build: </b>
              <span>{{image.softwareImageBuild}}</span>
              <i class="pi pi-exclamation-circle" *ngIf="app.sharedData.visibility.serviceTaskTriage.updateBaseImageBuild && stMain.serviceTasksDetails.abandoned == false && image.isBranched" pTooltip="Branched" [style]="{'font-size': '1.7em', 'cursor': 'pointer', 'margin-top': '-25px', 'margin-right': '-22px'}"></i>
              <i class="pi pi-exclamation-circle" *ngIf="app.sharedData.visibility.serviceTaskTriage.updateBaseImageBuild && stMain.serviceTasksDetails.abandoned == false && image.isAcceptPending" pTooltip="Processing CR/Gerrit" [style]="{'font-size': '1.7em', 'cursor': 'pointer', 'margin-top': '-25px', 'margin-right': '-22px'}"></i>
              <i id="editBaseBuild" class="material-icons" 
                *ngIf="app.sharedData.visibility.serviceTaskTriage.updateBaseImageBuild && stMain.serviceTasksDetails.abandoned == false && !image.isBranched && !image.isAcceptPending"
                (click)="addCustomBaseImage(image)" pTooltip="Edit base SI build before branching" style="display: inline-block; cursor: pointer;">edit</i>
            </span>
        </div>
      </div>
      <div class="ui-g" *ngIf="image.changeRequests?.length > 0">
          <div class="ui-g-9" style="margin-top: -20;">
          <h3>Change Requests:</h3>
          </div>
          <div class="ui-g-3" style="margin-top: -10;">
          <p-dropdown id="triageImageCRActionOptions" *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskTriage.actions" 
                      [options]="bulkCrActionOptions" [(ngModel)]="image.selectedCROptions" 
                      [disabled] = "(updateCRActionProcess.updateCRActionInProgress && updateCRActionProcess.showConfirmDialog)
                                    || image.selectedCRsMap === undefined || image.selectedCRsMap?.size === 0"
                      (click)="image.isCrUpdateAction = true; onBulkCrActionChange($event, image)"
                      (keyup.enter)="image.isCrUpdateAction = true; onBulkCrActionChange($event, image)"
                      placeholder="Accept/Reject Selected CRs" [panelStyle]="{'width':'200px'}">
          </p-dropdown>
          </div>
          
        <div class="ui-g-12" style="margin-top: -15px;">
        <p-table id="triageCrsTable" #triageCrsTable [value]="image.changeRequests" [rowHover]="true"
          [paginator]="true" [rowsPerPageOptions]="[7,10,25,50]" [rows]="7" (onPage)="image.isCrsSelected = true; onTablePageChange(triageCrsTable, image)"
          style="width:auto" [globalFilterFields]="['changeRequest', 'requestedDate', 'status', 'action']">
          <ng-template pTemplate="caption">
            <div style="text-align: right">
              <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
              <input #triageCrGlobalFilter class="filter-clear" type="text" id="filterCR" pInputText size="50" placeholder="Filter"
                (input)="triageCrsTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
              <i class="ui-icon-cancel" id="clearFilterST" pTooltip="Clear Filter" (click)="triageCrGlobalFilter.value = ''; triageCrsTable.filterGlobal('', 'contains');"
                style="margin:4px 4px 0 0"></i>
              <i class="ui-icon-refresh" id="refreshCRs" *ngIf="image.loadingCrCurrentInfo === 0 && !image.loadingDetails" pTooltip="Refresh"
                  (click)="updateSICrGerrits(image)" style="margin:4px 4px 0 0">
              </i>
              <p-progressSpinner *ngIf="image.loadingCrCurrentInfo !== 0 || image.loadingDetails" 
                  [style]="{width: '20px', height: '20px'}"
                  strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
              </p-progressSpinner>
            </div>
          </ng-template>
          <ng-template pTemplate="header" let-columns>
            <tr>
                <th style="width: 30px">
                  <p-checkbox *ngIf="app.sharedData.visibility.serviceTaskTriage.actions" value="0"
                      (onChange) = "image.isCrsSelected = true; onBulkImageCheckboxToggle($event, triageCrsTable, image)"
                      [binary]="true" [(ngModel)]="image.isAllCrSelected">
                  </p-checkbox>
                  <!--p-checkbox style="margin-left: 5px;margin-top: -11px;" value="0" [binary]="true"[(ngModel)]="rowData.isSelectAll" (click)="changeCRState($event,rowData)"></p-checkbox-->
                </th>
                <th id="triageChangeReqId" pSortableColumn="changeRequest" style="width: 110px">Change Request <p-sortIcon field="changeRequest"></p-sortIcon></th>
                <th id="triageRequestedDate" pSortableColumn="requestedDate" style="width: 95px">Requested Date <p-sortIcon field="requestedDate"></p-sortIcon></th>
                <th id="triageValidationStatus" pSortableColumn="status">Validation Status <p-sortIcon field="status"></p-sortIcon></th>
                <th id="triageAction" pSortableColumn="action" style="width: 140px">Action <p-sortIcon field="action"></p-sortIcon></th>
                <th id="triageValidationStatus" pSortableColumn="currentInfo.status">Status <p-sortIcon field="currentInfo.status"></p-sortIcon></th>
                <th id="traigeValidationDetails">Details</th>
                <th id="triageComments" style="width: 180px">Comments</th>
                <th style="width: 90px;"></th>
                <th style="width: 40px;"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData>
            <tr class="ui-selectable-row">
              <td>                
                <p-checkbox *ngIf="rowData.valid && app.sharedData.visibility.serviceTaskTriage.actions"
                    (onChange)="image.isCrsSelected = true; onImageCheckboxToggle($event, rowData, image)"
                    value="0" [binary]="true" [(ngModel)]="rowData.isSelected">
                </p-checkbox>
                <!--p-checkbox value="0"style="margin-left: 17px;margin-top: -11px;"  [binary]="true"[(ngModel)]="rowData.isSelected"></p-checkbox-->
              </td>
              <td>
                <div>
                  <i class="ui-icon-remove-red-eye" id="triageShowCRDetails" pTooltip="CR Details" (click)="showCRDetails(rowData.changeRequest)" style="margin-right: 10px;"></i>     
                  <span *ngIf="!app.sharedData.visibility.serviceTask.showCrLink">
                    {{rowData.changeRequest}}
                  </span>
                  <span *ngIf="app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                    <a (click)="app.goToUrl('https://orbit/cr/'+rowData.changeRequest)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                      {{rowData.changeRequest}}
                    </a>
                  </span>
                  <span *ngIf="!app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                      <a id="addedCrToImageLink" href="{{'https://orbit/cr/'+rowData.changeRequest}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                        {{rowData.changeRequest}}
                      </a>
                  </span>
                </div>
              </td>
              <td id="CRRequestedDate">
                <span>{{rowData.requestedDate}}</span>
              </td>
              <td id="CRValidationStatus">
                <span>{{rowData.status}}</span>
                <i class="ui-icon-refresh" id="triageRunValidationAgain" pTooltip="Run validation again" 
                  *ngIf="rowData.status === 'Invalid'
                        && app.sharedData.visibility.serviceTaskTriage.triggerValidation 
                        && stMain.serviceTasksDetails.abandoned == false" (click)="validateCR(rowData)" 
                  style="text-align: right; margin: 5px;"></i>
              </td>
              <td>
                  <p-dropdown id="triageCRActionOptions" *ngIf="stMain.serviceTasksDetails.abandoned == false
                                    && rowData.action == 'Pending'
                                    && rowData.status == 'Valid' && app.sharedData.visibility.serviceTaskTriage.actions 
                                    && !rowData.deleteInProgress" 
                              [options]="crActionOptions" [(ngModel)]="rowData.selectedOptions" [disabled] = "rowData.acceptStatus === 1 || rowData.actionInProgress || rowData.deleteInProgress" (onClick)="onCRActionChange($event, rowData, image)" (keyup.enter)="onCRActionChange($event, rowData, image)"
                              [style]="{'width':'95px', 'min-width':'0px'}"></p-dropdown>
                  <p-dropdown  id="triageCRShortActionOptions" *ngIf="stMain.serviceTasksDetails.abandoned == false 
                                    && rowData.action !== 'Pending'
                                    && rowData.status == 'Valid' && app.sharedData.visibility.serviceTaskTriage.actions 
                                    && !rowData.deleteInProgress" 
                              [options]="crShortActionOptions" [(ngModel)]="rowData.selectedOptions" [disabled] = "rowData.acceptStatus === 1 || rowData.actionInProgress || rowData.deleteInProgress" (onClick)="onCRActionChange($event, rowData, image)" (keyup.enter)="onCRActionChange($event, rowData, image)"
                              [style]="{'width':'95px', 'min-width':'0px'}"></p-dropdown>
                  <i class="pi pi-info-circle" *ngIf="rowData.acceptStatus === 1"
                      (mouseenter)="app.toggleOverlay($event,'Processing accept request')"
                      (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                  </i>
                  <i class="pi pi-info-circle" *ngIf="rowData.acceptStatus === 3"
                      (mouseenter)="app.toggleOverlay($event,'Accept failed. '+ image?.errorMessage + ' ' + '- Please file support ticket through help menu.')"
                      (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                  </i>
                  <!--p-dropdown *ngIf="rowData.dataType === 'BULKACTION'&& stMain.serviceTasksDetails.abandoned == false" id="triageBulkCRActionOptions" 
                              [options]="bulkCrActionOptions" [(ngModel)]="rowData.selectedOptions" [disabled] = "this.updateCRActionProcess.updateCRActionInProgress" (onClick)="onBulkCRActionChange($event, rowData)" (keyup.enter)="onBulkCRActionChange($event, rowData)"></p-dropdown-->
                  <div class="action-icon" *ngIf="stMain.serviceTasksDetails.abandoned == false &&  rowData.action =='Accept' && rowData.buildTriggered==false && rowData.actionInProgress==false">
                    <i class="ui-icon-refresh" id="triageRetriggerBuild" pTooltip="Retrigger Build" style="cursor:pointer" (click)="triggerBuild(rowData)"></i>
                  </div>
              </td>
              <td>
               {{(rowData.action === "AlreadyFixed")? "Present" : (rowData.currentInfo === undefined)?"Unknown" : rowData.currentInfo?.status}}              
              </td>
              <td id="crValidationDetails">
                <span>{{rowData.validationMsg}}</span>
                <i class="pi pi-info-circle" *ngIf="rowData.status === 'Invalid' && rowData.details"  
                    (mouseenter)="app.toggleOverlay($event,rowData.validationMsg + '. ' + rowData.details)"
                    (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                </i>
              </td>
              <td id="crComments">
                <span id="addedCrComment" *ngFor="let comment of rowData.comments | slice:0:3" style="width: 100%; display: inline-block; max-height: 45px; overflow:hidden">
                  <b>{{comment.userId}}</b> : {{comment.description}}
                </span>
              </td>
              <td  id="crCommentsAction">
                <div class="action-icon">
                  <i class="ui-icon-plus" id="triageaddCRComment" pTooltip="Add Comment" *ngIf="stMain.serviceTasksDetails.abandoned == false" (click)="stMain.onShowAddCommentDialog(rowData.changeRequest, rowData.softwareImage, rowData)"></i>
                  <i class="pi pi-comments" style="font-size: 24px" id="triageViewAllCRComments" pTooltip="View All Comments" *ngIf="(rowData.comments !== undefined && rowData.comments.length > 0)" (click)="stMain.onShowAllCommentsDialog(rowData.changeRequest, rowData.softwareImage, rowData)"></i>
                  <span *ngIf="(rowData.comments == undefined || rowData.comments.length == 0)" style="padding-right: 24px;"></span>
                  <i class="pi pi-times" id="triageRemoveCR" pTooltip="Remove CR" *ngIf="stMain.serviceTasksDetails.abandoned == false && rowData.selectedOptions == 0 && !rowData.actionInProgress" (click)="onCRActionDelete(rowData, false)" style="cursor: pointer"></i>
                </div>
              </td>
              <td>
                  <p-progressSpinner *ngIf="rowData.status == 'In Progress' || rowData.actionInProgress || rowData.deleteInProgress"
                        [style]="{width: '20px', height: '20px'}"
                        strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
              </td>
            </tr>
          </ng-template>
        </p-table>
        </div>
      </div>
      <div class="ui-g" *ngIf="image.gerrits?.length > 0">
          <div class="ui-g-9" style="margin-top: -20;">
          <h3>Gerrits:</h3>
          </div>
          
          <div class="ui-g-3" style="margin-top: -10;">
            <p-dropdown id="triageImageGerritActionOptions"  *ngIf="stMain.serviceTasksDetails.abandoned == false && app.sharedData.visibility.serviceTaskTriage.actions"
                        [options]="bulkCrActionOptions" [(ngModel)]="image.selectedGerritOptions" 
                        [disabled] = "updateCRActionProcess.updateCRActionInProgress && updateCRActionProcess.showConfirmDialog
                                      || image.selectedGerritsMap === undefined || image.selectedGerritsMap?.size === 0"
                        (click)="image.isCrUpdateAction = false; onBulkCrActionChange($event, image)" 
                        (keyup.enter)="image.isCrUpdateAction = false; onBulkCrActionChange($event, image)"
                        placeholder="Accept/Reject Selected Gerrits" [style]="{'width':'140px'}">
            </p-dropdown>
          </div>
        
        <div class="ui-g-12" style="margin-top: -15px;">
          <p-table id="triageGerritsTable" #triageGerritsTable [columns]="cols" [value]="image.gerrits" [rowHover]="true"
            [paginator]="true" [rowsPerPageOptions]="[7,10,25,50]" [rows]="7" (onPage)="image.isCrsSelected = false; onTablePageChange(triageGerritsTable, image)"
            style="width:auto" [globalFilterFields]="['gerritId', 'type', 'requestedDate', 'action']">
            <ng-template pTemplate="caption">
              <div style="text-align: right">
                <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                <input #triageGerritGlobalFilter class="filter-clear" type="text" id="filterGerrit" pInputText size="50" placeholder="Filter"
                  (input)="triageGerritsTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
                <i class="ui-icon-cancel" id="clearFilterST" pTooltip="Clear Filter" (click)="triageGerritGlobalFilter.value = ''; triageGerritsTable.filterGlobal('', 'contains');"
                  style="margin:4px 4px 0 0"></i>
                <i class="ui-icon-refresh" id="refreshGerrits" pTooltip="Refresh" (click)="updateSICrGerrits(image)" style="margin:4px 4px 0 0"></i>
              </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
              <tr>
                  <th style="width: 30px">
                    <p-checkbox *ngIf="app.sharedData.visibility.serviceTaskTriage.actions" value="0"
                        (onChange) = "image.isCrsSelected = false; onBulkImageCheckboxToggle($event, triageGerritsTable, image)"
                        [binary]="true" [(ngModel)]="image.isAllGerritSelected">
                    </p-checkbox>
                  </th>
                  <th id="triageGerritId" pSortableColumn="gerritId" style="width: 130px">Gerrit <p-sortIcon field="gerritId"></p-sortIcon></th>
                  <th id="triageGerritRequestedDate" pSortableColumn="requestedDate" style="width: 95px">Requested Date <p-sortIcon field="requestedDate"></p-sortIcon></th>
                  <th id="triageGerritAction" pSortableColumn="action" style="width: 140px">Action <p-sortIcon field="action"></p-sortIcon></th>
                  <th id="triageGerritValidationDetails">Details</th>
                  <th id="triageGerritComments" style="width: 180px">Comments</th>
                  <th style="width: 90px;"></th>
                  <th style="width: 40px;"></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData>
              <tr class="ui-selectable-row">
                <td>
                  <p-checkbox *ngIf="rowData.valid && app.sharedData.visibility.serviceTaskTriage.actions"
                      (onChange)="image.isCrsSelected = false; onImageCheckboxToggle($event, rowData, image)"
                      value="0" [binary]="true" [(ngModel)]="rowData.isSelected">
                  </p-checkbox>
                </td>
                <td>
                  <div>
                    <!--i class="ui-icon-remove-red-eye" id="triageShowCRDetails" pTooltip="Gerrit Details" style="margin-right: 10px;"></i-->
                    {{rowData.gerritId}}{{rowData.type !== null ? ' - ' + rowData.type: ''}}
                    <!--span *ngIf="!app.sharedData.visibility.serviceTask.showCrLink">
                      {{rowData.gerrit?.gerritId}}
                    </span>
                    <span *ngIf="app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                      <a (click)="app.goToUrl('https://orbit/cr/'+rowData.gerrit?.gerritId)" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                        {{rowData.gerrit?.gerritId}}
                      </a>
                    </span>
                    <span *ngIf="!app.sharedData.appInfo.isElectronMode && app.sharedData.visibility.serviceTask.showCrLink">
                        <a href="{{'https://orbit/cr/'+rowData.gerrit?.gerritId}}" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                          {{rowData.gerrit?.gerritId}}
                        </a>
                    </span-->
                  </div>
                </td>
                <td id="gerritRequestedDate">
                  <span>{{rowData.requestedDate}}</span>
                </td>
                <td id="gerritAction">
                    <p-dropdown *ngIf="stMain.serviceTasksDetails.abandoned == false && rowData.selectedOptions == 0  && rowData.status == 'Valid' && app.sharedData.visibility.serviceTaskTriage.actionsGerrit" id="triageCRActionOptionsGerrit" [options]="crActionOptions" [(ngModel)]="rowData.selectedOptions" [disabled] = "rowData.acceptStatus === 1 || rowData.actionInProgress || rowData.deleteInProgress"  (onChange)="onGerritActionChange($event, rowData, image)" [style]="{'width':'95px', 'min-width':'0px'}"></p-dropdown>
                    <p-dropdown *ngIf="stMain.serviceTasksDetails.abandoned == false && rowData.selectedOptions != 0  && rowData.status == 'Valid' && app.sharedData.visibility.serviceTaskTriage.actionsGerrit" id="triageCRShortActionOptions" [options]="crShortActionOptions" [(ngModel)]="rowData.selectedOptions" [disabled] = "rowData.acceptStatus === 1 || rowData.actionInProgress || rowData.deleteInProgress" (onChange)="onGerritActionChange($event, rowData, image)" [style]="{'width':'95px', 'min-width':'0px'}"></p-dropdown>
                    <i class="pi pi-info-circle" *ngIf="rowData.acceptStatus === 1"
                        (mouseenter)="app.toggleOverlay($event,'Processing accept request')"
                        (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                    </i>
                    <i class="pi pi-info-circle" *ngIf="rowData.acceptStatus === 3"
                        (mouseenter)="app.toggleOverlay($event,'Accept failed. '+ image?.errorMessage + ' ' + '- Please file support ticket through help menu.')"
                        (mouseout)="app.toggleOverlay($event,'')" style="cursor: pointer; font-size: 1.2rem">
                    </i>
                </td>
                <td id="gerritValidationDetails">
                  <span>{{rowData.validationMsg}}</span>
                </td>
                <td id="gerritComments">
                  <span *ngFor="let comment of rowData.comments | slice:0:3" style="width: 100%; display: inline-block; max-height: 45px; overflow:hidden">
                    <b>{{comment.userId}}</b> : {{comment.description}}
                  </span>
                </td>
                <td  id="crCommentsAction">
                  <div class="action-icon" *ngIf="app.sharedData.visibility.serviceTaskTriage.actionsGerrit">
                    <i class="ui-icon-plus" id="triageaddGerritComment" pTooltip="Add Comment" *ngIf="stMain.serviceTasksDetails.abandoned == false" (click)="stMain.onShowAddCommentDialog(rowData.gerritId, rowData.softwareImage, rowData, true)"></i>
                    <i class="pi pi-comments" style="font-size: 24px" id="triageViewAllGerritComments" pTooltip="View All Comments" *ngIf="(rowData.comments !== undefined && rowData.comments.length > 0)" (click)="stMain.onShowAllCommentsDialog(rowData.gerritId, rowData.softwareImage, rowData, true)"></i>
                    <span *ngIf="(rowData.comments == undefined || rowData.comments.length == 0)" style="padding-right: 24px;"></span>
                    <i class="pi pi-times" id="triageRemoveGerrit" pTooltip="Remove Gerrit" *ngIf="stMain.serviceTasksDetails.abandoned == false && rowData.selectedOptions == 0" (click)="onGerritActionDelete(rowData)" style="cursor: pointer"></i>
                  </div> 
                </td>
                <td>
                  <p-progressSpinner *ngIf="rowData.actionInProgress || rowData.deleteInProgress" 
                          [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                  </p-progressSpinner>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-accordionTab>
  </p-accordion>

  <!--Add Base Image-->
  <p-dialog id="addBaseImageDialogBox" header="Add Base Image" [(visible)]="addBaseImageProcess.displayForm" modal="modal" showEffect="fade" [style]="{width: '600px'}">
    <div class="ui-g">
      <div class="ui-g-12" style="height: 200px;">
        <div class="ui-g-12">
          <p-dropdown id="selectSIOption" [options]="addBaseImageProcess.availableImages" (click)="getLatestImageBuilds()"
            (keyup.enter)="getLatestImageBuilds()" placeholder="Select SI"
            [(ngModel)]="addBaseImageProcess.selectedImage" filter="true" [virtualScroll]="true" [style]="{width: '100%'}">
            <ng-template let-item pTemplate="selectedItem">
              {{item.label}}
            </ng-template>
            <ng-template let-item pTemplate="item">
              {{item.label}}
            </ng-template>
          </p-dropdown>
        </div>
        <div class="ui-g-12">
          <p-dropdown id="selectSIBuildOptions" [options]="addBaseImageProcess.availableImageBuilds" placeholder="Select SI Build"
            [(ngModel)]="addBaseImageProcess.selectedImageBuild" filter="true" [virtualScroll]="true" [style]="{width: '100%'}">
            <ng-template let-item pTemplate="selectedItem">
              {{item.label}}
            </ng-template>
            <ng-template let-item pTemplate="item">
              {{item.label}}
            </ng-template>
          </p-dropdown>
        </div>
      </div>
    </div>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" *ngIf="this.addBaseImageProcess.errorMessage !== ''" class="message message-error">
          {{addBaseImageProcess.errorMessage}}
        </div>
      </div>
      <p-progressSpinner *ngIf="addBaseImageProcess.addImageInProgress || addBaseImageProcess.loadingImageBuilds || addBaseImageProcess.loadingImages" 
                          [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
      <button pButton id="triageAddBaseImagesButton" type="button" [disabled]="addBaseImageProcess.selectedImageBuild === undefined" (click) = "addBaseImages(addBaseImageProcess.selectedImageBuild)" label="Add Image" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
    </p-footer>
  </p-dialog>

  <!--Delete CR/Image/Gerrit-->
  <p-dialog id="deleteDialogBox" header="Delete {{deleteProcess.item}}" [(visible)]="deleteProcess.displayDeleteForm" modal="modal" showEffect="fade">
    <div class="ui-g">
      <div id="deleteCRConfirmation" class="ui-g-12" *ngIf="deleteProcess.cr !== undefined">
        Do you want to delete CR {{deleteProcess.cr?.changeRequest}}?
      </div>
      <div id="deleteBaseImageConfirmation" class="ui-g-12" *ngIf="deleteProcess.image !== undefined">
        Do you want to delete Base Image {{deleteProcess.image?.softwareImageBuild}}?
      </div>
      <div id="deleteGerritConfirmation" class="ui-g-12" *ngIf="deleteProcess.gerrit !== undefined">
        Do you want to delete Gerrit {{deleteProcess.gerrit?.gerritId}}?
      </div>
    </div>
    <p-footer>
      <button pButton id="triageDeleteCRSubmitButton" type="button" *ngIf="deleteProcess.cr !== undefined" (click) = "deleteCR(deleteProcess.cr)" label="Yes" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
      <button pButton id="triageDeleteBaseImageButton" type="button" *ngIf="deleteProcess.image !== undefined" (click) = "deleteBaseImage(deleteProcess.image)" label="Yes" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
      <button pButton id="triageDeleteGerritButton" type="button" *ngIf="deleteProcess.gerrit !== undefined" (click) = "deleteGerrit(deleteProcess.gerrit)" label="Yes" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
      <button pButton id="triageDeleteCancelButton" type="button" (click) = "deleteProcess.displayDeleteForm = false" label="No" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
    </p-footer>
  </p-dialog>

  <!--Message Box-->
  <p-dialog [(visible)]="dialog.displayCRMessageBox" modal="modal" showEffect="fade">
      <p-header>
          {{dialog.header}}
      </p-header>
      <p-table id="displayCRMessageTable" [value]="crDetailRows" [style]="{'width': '600px'}">
          <ng-template pTemplate="body" let-rowData>
              <tr>
                  <td [style]="{'width': '200px'}">{{rowData.name}}</td>
                  <td>{{rowData.value}}</td>
              </tr>
          </ng-template>
      </p-table>
      <button pButton id="triageHideCRButton" type="button" (click) = "hideCRMessageBox()" label="{{dialog.ok}}" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
  </p-dialog>

  <!--Add CR-->
  <div class="card" *ngIf="addCrProcess.displayAddCrRequest 
                            && (addCrProcess.displayAddCrForm || addCrProcess.displaySummary)">
      <p-dialog id="addCRDialogHeader" header="Add CRs" [(visible)]="addCrProcess.displayAddCrForm" modal="modal"
        showEffect="fade" [style]="{width: '600px'}">
        <div class="ui-g">
          <div class="ui-g-12">
            <label *ngIf="!this.addCrProcess.isRelatedCRs" id="addCRBoxLabel" for="crlistTextArea">Enter CR Number to add to service task:</label>
            <label *ngIf="this.addCrProcess.isRelatedCRs" id="dependentCRLabel" for="crlistTextArea">Found following dependent CRs:</label>
            <textarea id="triageEnterCRList"  [(ngModel)]="addCrProcess.crList" (ngModelChange)="addCrRequestValidation()" [cols]="30" [rows]="3" pInputTextarea autoResize="autoResize" [style]="{'margin-top': '10px'}"></textarea>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-12" id="addCrErrorMessage" *ngIf="addCrProcess.errorMessage !== ''" class="message message-error">
            {{addCrProcess.errorMessage}}
          </div>
          <div class="ui-g-12" id="addCrDuplicateMessage" *ngIf="addCrProcess.duplicateMessage !== ''" class="message message-error" style="white-space: pre-line">
            Warning: CR(s) already present. Please continue to enable different SIs.
            <br/>{{addCrProcess.duplicateMessage}}
          </div>
        </div>
        <p-footer>
          <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <button pButton id="triageAddNextCRReqButton" type="button" (click)="addCrRequestNext()" label="Next >" [style]="{'width': '150px', 'margin-right' : '20px'}"></button>
            <!--button pButton type="button" (click) = "addCR()" label="Add" [style]="{'width': '150px', 'margin-right' : '20px'}"></button-->
            <!--button pButton type="button" (click) = "hideAddCRMessageBox()" label="Cancel" [style]="{'width': '150px', 'margin-left': '20px'}"></button-->
          </div>
        </p-footer>
      </p-dialog>
      <p-dialog id="addCRSummary" header="Add CRs Summary" [(visible)]="addCrProcess.displaySummary" modal="modal"
        showEffect="fade" [style]="{width: '600px'}">
        <div class="ui-g">
          <div class="ui-g-12">
            CR Validations:
            <p-table id="crValidationTable" [columns]="addCrProcess.crCols" [value]="addCrProcess.crLists"  [style]="{'width': '250px'}">
              <ng-template pTemplate="header" let-columns>
                <tr>
                  <th *ngFor="let col of columns" id="{{col.field}}">
                    {{col.header}}
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="emptymessage">
                <tr>
                  <td colspan="2">No CR numbers entered.</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                  <td>{{rowData.cr}}</td>
                  <td>{{rowData.valid}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        <div class="ui-g">
          <div class="ui-g-12" *ngIf="addCrProcess.errorMessageResponse !== ''" class="message message-error">
            {{addCrProcess.errorMessageResponse}}
          </div>
        </div>
        <p-footer>
          <div class="ui-dialog-buttonpane ui-helper-clearfix">
            <p-progressSpinner *ngIf="addCrProcess.addCrInProgress" [style]="{width: '20px', height: '20px'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
            <button pButton id="triageAddCRBackButton" type="button" (click)="addCrRequestBack()" label="Back"></button>
            <button pButton id="triageAddCRButton" type="button" (click)="addCR()" label="Add CRs"></button>
          </div>
        </p-footer>
      </p-dialog>
  </div>
  <p-dialog [(visible)]="updateCRActionProcess.showConfirmDialog" modal="modal" showEffect="fade" header="Update CR/Gerrit Status" closable="false">      
    <p>Are you sure you want to <b>{{crAction}}</b> below Change Request(s) and Gerrit(s) ?</p>
    <p-table  id="CRStatusTable" #CRStatusTable [value]=" updateCRActionProcess.crSelected" [style]="{'width': '500px'}" [globalFilterFields]="['data.changeRequest','data.gerritId']">
      <ng-template pTemplate="caption">
        <div style="text-align: right">
          <i class="pi pi-search" style="margin:5px 5px 0 0"></i>
          <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="CR(s)/Gerrit(s)"
            (input)="CRStatusTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
        </div>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="2">No CRs/Gerrits selected.</td>
        </tr>
      </ng-template> 
      <ng-template pTemplate="header" let-columns>
         <tr>
           <th id="CRNumber"><span style="vertical-align: middle">Change Request / Gerrit</span></th>
           <th id="shipStatus"><span style="vertical-align: middle">Image</span></th>
         </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
              <td *ngIf="rowData.changeRequest!=undefined"><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.changeRequest}}</span></td>
              <td *ngIf="rowData.changeRequest!=undefined"><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareImage}}</span></td>
              <td *ngIf="rowData.gerritId!=undefined"><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.gerritId}}</span></td>
              <td *ngIf="rowData.gerritId!=undefined"><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.softwareImage}}</span></td>
            </tr>
        </ng-template>
    </p-table>
    <div class="ui-g" *ngIf="updateCRActionProcess.action===2">
      <div class="ui-g-12">
        <label *ngIf="updateCRActionProcess.action===2" for="crlistTextArea">Enter the reason the Change Request(s)/Gerrit(s) are being rejected</label>
      </div>
      <div class="ui-g-12">
        <label>Comment:</label>
        <textarea id="crCommentText"[(ngModel)]="updateCRActionProcess.commentToAdd" [cols]="20" pInputTextarea autoResize="autoResize" [style]="{'margin-top': '10px'}"  (ngModelChange)="commentValidation()"></textarea>
      </div>
    </div>
    <p *ngIf="updateCRActionProcess.action===2 && updateCRActionProcess.commentToAdd?.length > 0 &&  256 > updateCRActionProcess.commentToAdd?.length  "style="color: blue;">{{updateCRActionProcess.commentToAdd?.length}}/255</p>
    <p *ngIf="updateCRActionProcess.action===2 && app.sharedData.userInfo.info.internal" style="color: #F7921E;">Warning: All Comments entered are visible to external customers.</p>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" *ngIf="this.updateCRActionProcess.errorMessage !== ''" class="message message-error">
          {{updateCRActionProcess.errorMessage}}
        </div>
      </div>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">
        <button pButton id="triageHideCRConfirm" [disabled]="updateCRActionProcess.crSelected?.length==0 || this.updateCRActionProcess.errorMessage !== '' || (this.updateCRActionProcess.commentToAdd === '' && updateCRActionProcess.action===2 )" type="button" (click) = "onSelectedCRActionChange()" label="Yes" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
        <button pButton id="triageHideCRConfirm" type="button" (click) = "onCancelButtonClick()" label="No" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
      </div>
    </p-footer>    
  </p-dialog>
  <div *ngIf="searchUpdateCRProcess.showSelectDialog || searchUpdateCRProcess.showConfirmDialog">
    <p-dialog [(visible)]="searchUpdateCRProcess.showSelectDialog" modal="modal" showEffect="fade" header="Search & Update CR Status" [style]="{'width': '500px'}">      
      <p>The selected CR(s) are in the <b>Pending</b> state. Click <b>Submit</b> to update all selected CR(s) to the <b>Already Fixed</b> state.</p>
      <p>Note: This request may take several minutes to update the status.</p>
      <p-table  id="CRSearchSelectTable" #CRSearchSelectTable [value]=" searchUpdateCRProcess.crPending" [(selection)]=" searchUpdateCRProcess.crSelected" [globalFilterFields]="['changeRequest']">
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin:5px 5px 0 0"></i>
            <input #crSearchStatusGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Search CR(s)"
              (input)="CRSearchSelectTable.filterGlobal($event.target.value, 'contains')" style="width:auto; color: white;">
          </div>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="2">No CRs selected.</td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width:30px;"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
            <th>
              <span>CR#</span>
              <p-sortIcon field="changeRequest"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
              <td style="width:30px;"><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
              <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.changeRequest}}</span></td>
            </tr>
        </ng-template>
      </p-table>
      <p-footer>
        <div class="ui-g">
          <div class="ui-g-12" *ngIf="this.updateCRActionProcess.errorMessage !== ''" class="message message-error">
            {{searchUpdateCRProcess.errorMessage}}
          </div>
        </div>
        <div class="ui-dialog-buttonpane ui-helper-clearfix">          
          <p-progressSpinner *ngIf="searchUpdateCRProcess.searchUpdateCRInProgress" 
              [style]="{width: '20px', height: '20px'}"
              strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
          </p-progressSpinner>
          <button pButton id="searchUpdateCRSubmit" [disabled]="searchUpdateCRProcess.crSelected?.length==0 || searchUpdateCRProcess.searchUpdateCRInProgress" type="button" (click) = "searchAndUpdate()" label="Submit" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
        </div>
      </p-footer>    
    </p-dialog>
  </div>
  
  <!--Add Related CRs-->
  <p-dialog [(visible)]="addRelatedCRProcess.displayForm" modal="modal" showEffect="fade" header="Related CRs Found" closable="false">
    <p-table  id="CRStatusTable" #CRStatusTable [value]=" addRelatedCRProcess.relatedCRs" [(selection)]="addRelatedCRProcess.relatedCRsSelected" [style]="{'width': '500px'}">
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="4">No related CRs Found.</td>
        </tr>
      </ng-template> 
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3em"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
          <th id="currentCR"><span style="vertical-align: middle">Current CR</span></th>
          <th id="relaredCR"><span style="vertical-align: middle">CR to Add</span></th>
          <th id="relationship"><span style="vertical-align: middle">Reason</span></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData>
          <tr>
            <td><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
            <td><span style="vertical-align: middle">{{rowData.currentCR}}</span></td>
            <td><span style="vertical-align: middle">{{rowData.id}}</span></td>
            <td><span style="vertical-align: middle">{{rowData.relationship}}</span></td>
          </tr>
      </ng-template>
    </p-table>
    <p-footer>
      <div class="ui-g">
        <div class="ui-g-12" *ngIf="this.addRelatedCRProcess.errorMessage !== ''" class="message message-error">
          {{addRelatedCRProcess.errorMessage}}
        </div>
      </div>
      <div class="ui-dialog-buttonpane ui-helper-clearfix">          
        <p-progressSpinner *ngIf="addRelatedCRProcess.inProgress" 
            [style]="{width: '20px', height: '20px'}"
            strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
        </p-progressSpinner>
        <button pButton id="addRelatedCRsButton" [disabled]="addRelatedCRProcess.inProgress || addRelatedCRProcess.relatedCRsSelected?.length === 0" type="button" (click) = "addRelatedCRs()" label="Add Selected CRs" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
      </div>
    </p-footer>    
  </p-dialog>
</div>