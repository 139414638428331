import {Component} from '@angular/core';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
    constructor(public app : AppMainComponent){

    }
    date = new Date();
    year = this.date.getFullYear().toString();
}
