import { Injectable } from '@angular/core';
import isElectron from 'is-electron';
import { Observable } from 'rxjs';
import { LimeResponse, QPMResponse, RestResponse } from 'src/app/models/response';
import { DistributionClientService } from '../Contract/DistributionClientService';
import { ServiceUtils } from '../ServiceUtils';
import { DistributionRequest ,DiffDistributionRequest, ImageDistributionRequest, ImageDiffDistributionRequest, ImageBuildDistributionRequest} from 'src/app/models/lime-web-client';
import { SoftwareProductMetaDeta,DownloadStatusRequest, DownloadImageStatusRequest} from 'src/app/models/distribution-client';
import { RestDistributionClientService } from '../RestService/rest-distribution-client.service';
@Injectable()
export class LimeDistributionClientService implements DistributionClientService{
  private distributionClient;
  constructor(private serviceUtils : ServiceUtils, private distributionClientRestApi: RestDistributionClientService) {
    if(isElectron()){
      var lime = window.require('bindings')('addon.node');
      this.distributionClient = new lime.DistributionClientWrapper();
    }
  }

  getVersion(): Observable<LimeResponse> {
    var response = this.distributionClient.getVersion();
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  requestDownloadSoftwareImage(request : DistributionRequest): Observable<QPMResponse> {
  return this.distributionClientRestApi.requestDownloadSoftwareImage(request);
  }
  processDownloadSoftwareImage(softwareProductID : string, product : string,branch : string, tag : string, distroType : string,distrofolderName:string, downloadLocation:string,response : string): Observable<QPMResponse> 
  {
    var limeresponse = this.distributionClient.requestDownloadSoftwareImage(softwareProductID, product, branch, tag, distroType,distrofolderName,downloadLocation,"Type0",response);
    return this.serviceUtils.getObservableLimeReponse(limeresponse);
  }
  requestDryRunSoftwareImage(request : DistributionRequest): Observable<QPMResponse> {
    
    return this.distributionClientRestApi.requestDryRunSoftwareImage(request);
  }

  shipSoftwareProduct(request:DistributionRequest): Observable<RestResponse> {
    return this.distributionClientRestApi.shipSoftwareProduct(request);
  }

  getDownloadedSoftware(): Observable<QPMResponse> {
    var response = this.distributionClient.getDownloadedSoftware();
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  generateProductMetadata(buildId : string, distroType : string, distrofolderName:string,syncResultJson : SoftwareProductMetaDeta): Observable<QPMResponse> {
    var response = this.distributionClient.generateProductMetadata(buildId, distroType, distrofolderName,JSON.stringify(syncResultJson));
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  deleteProductMetadata(productId: string,buildId : string, distroType : string, distrofolderName:string): Observable<QPMResponse> {
    var response = this.distributionClient.deleteMetaData(productId,buildId, distroType, distrofolderName);
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  getDownloadedSoftwareBuildDetail(buildId : string, distroType : string): Observable<QPMResponse> {
     var response = this.distributionClient.getDownloadedSoftwareBuildDetail(buildId, distroType);
     return this.serviceUtils.getObservableLimeReponse(response);
   }
   diffShipSoftwareProduct(request:DiffDistributionRequest): Observable<RestResponse> {
    return this.distributionClientRestApi.diffShipSoftwareProduct(request);
  }

  diffDownloadSoftwareProduct(request:DiffDistributionRequest): Observable<RestResponse> {
    return this.distributionClientRestApi.diffDownloadSoftwareProduct(request);
  }
  processDiffDownloadSoftwareImage(baseSoftwareProductID : string, targetSoftwareProductID:string,downloadLocation:string,product : string,branch : string, tag : string, distroType : string,distrofolderName:string, response : string):Observable<QPMResponse> 
  {
    var limeresponse = this.distributionClient.requestDiffDownloadSoftwareImage(baseSoftwareProductID,targetSoftwareProductID,downloadLocation,product, branch, tag, distroType,distrofolderName,response);
    return this.serviceUtils.getObservableLimeReponse(limeresponse);
  }

  getDefaultDownloadLocation(): Observable<LimeResponse> {
    var response = this.distributionClient.getDefaultDownloadLocation();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  getDefaultMetaDownloadLocation(): Observable<LimeResponse> {
    var response = this.distributionClient.getDefaultMetaDownloadLocation();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  saveDownloadHistory(downloadHistory:string): Observable<LimeResponse> {
    var response = this.distributionClient.saveDownloadHistory(downloadHistory);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  getDownloadedHistoryDetails(): Observable<LimeResponse> {
    var response = this.distributionClient.getDownloadedHistoryDetails();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  updateDownloadStatus(request:DownloadStatusRequest): Observable<QPMResponse> {
    return this.distributionClientRestApi.updateDownloadStatus(request);
  }
  updateDiffDownloadStatus(request:DownloadStatusRequest): Observable<QPMResponse> {
    return this.distributionClientRestApi.updateDiffDownloadStatus(request);
  }
  downloadSoftwareImage(request:ImageDistributionRequest):Observable<QPMResponse>{
    return this.distributionClientRestApi.downloadSoftwareImage(request);
  }
  updateImageDownloadStatus(request:DownloadImageStatusRequest): Observable<QPMResponse> {
    return this.distributionClientRestApi.updateImageDownloadStatus(request);
  }
  diffDownloadSoftwareImage(request:ImageDiffDistributionRequest): Observable<RestResponse> {
    return this.distributionClientRestApi.diffDownloadSoftwareImage(request);
  }
  updateDiffImageDownloadStatus(request:DownloadImageStatusRequest): Observable<QPMResponse> {
    return this.distributionClientRestApi.updateDiffImageDownloadStatus(request);
  }
  shipSoftwareImage(request:ImageBuildDistributionRequest):Observable<QPMResponse> {
    return this.distributionClientRestApi.shipSoftwareImage(request);
  }
  downloadSoftwareImageBuild(request:ImageDistributionRequest):Observable<QPMResponse>{
    return this.distributionClientRestApi.downloadSoftwareImageBuild(request);
  }
  replayDiffShipSoftwareProduct(request:DiffDistributionRequest): Observable<RestResponse> {
    return this.distributionClientRestApi.replayDiffShipSoftwareProduct(request);
  }
}
