import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { Utils } from 'src/app/common/utils';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { CartItem, License } from 'src/app/models/lime-web-client';
import { QLogger } from 'src/app/common/logger';

import { QPMResponse, RestResponse } from 'src/app/models/response';
import { PaymentIntent, PaymentIntentRequest, PaymentMetaData, SerialNumberModel, LicenseGroup } from 'src/app/models/lime-web-client';

import { StripeService, StripePaymentElementComponent } from 'ngx-stripe';
import {
  StripeElementsOptions,
  StripeElements,
  StripePaymentElement,
  StripePaymentElementOptions
} from '@stripe/stripe-js';
import { commType } from 'src/app/common/shared-data';
import { OverlayPanel } from 'primeng/primeng';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  private logSrc: string = "Checkout-Component";
  private subscriptionSrc:string = "Checkout-Component";
  private cartItemsSubcription: Observable<CartItem[]>;

  private catalogClient: CatalogClientService;
  private webClient: WebClientService;
  private limeClient: ClientService;
  private telematicsClient: TelematicsClientService;

  private stripeElements: StripeElements;
  private paymentElement: StripePaymentElement;
  private paymentElementOptions: StripePaymentElementOptions;

  private paymentIntentRequest: PaymentIntentRequest;

  @ViewChild(StripePaymentElementComponent)
  paymentElementComponent: StripePaymentElementComponent;

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  itemsAddedToCart:CartItem[];

  informationProcess:{
    customerEmailId:string;
    shippingAddressSet:Set<string>;
    billingInformationSet:Set<string>;
  }
  orderSummaryProcess:{
    itemsAddedToCart:CartItem[];
    subtotal:any;
    shipping:string;
    total:any;
}
selectedBillingAddress:string;
selectedShippingAddress:string;
checkedStripeConcent:boolean;
paymentInProgress:boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private location: Location,
    public app: AppMainComponent, private utils: Utils,
    private service: DataServiceProducer, private http: HttpClient,
    public zone: NgZone,
    private stripeService: StripeService) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
  }


  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "Checkout-Component Initialization");
    this.checkedStripeConcent=false;
    this.paymentInProgress=false;
    this.initSubscribesForCartItems();
    this.resetOrderSummaryProcess();
    this.resetInformationProcess();
    this.itemsAddedToCart= this.app.sharedData.licenses.common.itemsAddedToCart;
    //set OrderSummery
    this.orderSummaryProcess.itemsAddedToCart=this.app.sharedData.licenses.common.itemsAddedToCart;
    this.orderSummaryProcess.shipping="Electronic delivery";
    this.orderSummaryProcess.itemsAddedToCart.forEach((item)=>{
      item.responseLineDetail.itemPrice=parseFloat(item.responseLineDetail.itemPrice).toFixed(2);
      item.responseLineDetail.itemTaxAmount=parseFloat(item.responseLineDetail.itemTaxAmount).toFixed(2);
      this.orderSummaryProcess.subtotal=this.orderSummaryProcess.subtotal + parseFloat(item.responseLineDetail.itemPrice)+parseFloat(item.responseLineDetail.itemTaxAmount)
    })
    this.orderSummaryProcess.subtotal=(this.orderSummaryProcess.subtotal.toFixed(2));
    this.orderSummaryProcess.total=this.orderSummaryProcess.subtotal;
    //set Informations
    if(this.app.sharedData.userInfo.username.includes('@')){
      this.informationProcess.customerEmailId=this.app.sharedData.userInfo.username;
    }else{
      this.informationProcess.customerEmailId=this.app.sharedData.userInfo.username+'@qti.qualcomm.com';
    }
    this.app.sharedData.licenses.common.itemsAddedToCart.forEach(item=>{
      this.informationProcess.shippingAddressSet.add(item.responseLineDetail.shipToAddress);
      this.informationProcess.billingInformationSet.add(item.responseLineDetail.billToAddress);
    })
    this.selectedBillingAddress=[...this.informationProcess.billingInformationSet][0];
    this.selectedShippingAddress=[...this.informationProcess.shippingAddressSet][0];
    
    this.initializePaymentElement();
  }



  resetOrderSummaryProcess() {
    this.orderSummaryProcess = {
        itemsAddedToCart:[],
        subtotal:0,
        shipping:'',
        total:0
    }
  }
  resetInformationProcess(){
    this.informationProcess={
      customerEmailId:'',
      billingInformationSet:new Set<string>(),
      shippingAddressSet:new Set<string>()
  
    }
  }

  ngAfterViewInit() {
          
  }
  initSubscribesForCartItems(){
    this.cartItemsSubcription = this.app.sharedData.subscribeCommunication(commType.UpdateCartItems, this.subscriptionSrc);
    this.cartItemsSubcription.subscribe((cartItems: any)=> {
      if(cartItems === undefined) return;

    this.orderSummaryProcess.itemsAddedToCart = cartItems as CartItem[];
    this.resetOrderSummaryProcess();
    this.resetInformationProcess();
    this.itemsAddedToCart= cartItems as CartItem[];

    //set OrderSummery
    this.orderSummaryProcess.itemsAddedToCart=cartItems as CartItem[];;
    this.orderSummaryProcess.shipping="Electronic delivery";
    this.orderSummaryProcess.itemsAddedToCart.forEach((item)=>{
      item.responseLineDetail.itemPrice=parseFloat(item.responseLineDetail.itemPrice).toFixed(2);
      item.responseLineDetail.itemTaxAmount=parseFloat(item.responseLineDetail.itemTaxAmount).toFixed(2);
      this.orderSummaryProcess.subtotal=this.orderSummaryProcess.subtotal + parseFloat(item.responseLineDetail.itemPrice)+parseFloat(item.responseLineDetail.itemTaxAmount)
    })
    this.orderSummaryProcess.subtotal=(this.orderSummaryProcess.subtotal.toFixed(2));
    this.orderSummaryProcess.total=this.orderSummaryProcess.subtotal;
    //set Informations
    if(this.app.sharedData.userInfo.username.includes('@')){
      this.informationProcess.customerEmailId=this.app.sharedData.userInfo.username;
    }else{
      this.informationProcess.customerEmailId=this.app.sharedData.userInfo.username+'@qti.qualcomm.com';
    }
    this.itemsAddedToCart.forEach(item=>{
      this.informationProcess.shippingAddressSet.add(item.responseLineDetail.shipToAddress);
      this.informationProcess.billingInformationSet.add(item.responseLineDetail.billToAddress);
    })
    this.selectedBillingAddress=[...this.informationProcess.billingInformationSet][0];
    this.selectedShippingAddress=[...this.informationProcess.shippingAddressSet][0];
    
    });
  }

  ngOnDestroy(): void {
    QLogger.LogInfo(this.logSrc, "Checkout-Component Destroy");
    this.paymentInProgress=false;  
    this.app.sharedData.unsubscribeCommunication(commType.UpdateCartItems, this.subscriptionSrc);
  }

  initializePaymentElement() {
    this.paymentIntentRequest = this.buildPaymentIntentRequest();
    this.buildPaymentIntent();
  }

  //TODO populate dynamically based on other components in the page
  buildPaymentIntentRequest(): PaymentIntentRequest {
    let serialNumberDetails: SerialNumberModel[] = [];

    this.app.sharedData.licenses.common.itemsAddedToCart.forEach(item=>{
      let serialNumber: SerialNumberModel;
      serialNumber = {
        serialNumber: item.selectedLicense.serialNumber,
        itemPrice: Number(parseFloat(item.responseLineDetail.itemPrice).toFixed(2))*100,//itemPrice*100
        serviceFee: Number(parseFloat(item.responseLineDetail.itemTaxAmount).toFixed(2))*100,//itemTaxAmount*100
        licenseGroups: item.selectedLicense.licenseGroups
      }
      serialNumberDetails.push(serialNumber);
    })

    let metdata: PaymentMetaData;
    metdata = {
      userId: this.informationProcess.customerEmailId,
      partyId:this.app.sharedData.licenses.common.partyId,
      serialNumberDetails: serialNumberDetails//dynamic
    }
    let paymentIntent: PaymentIntent;
    paymentIntent = {
      paymentIntentId: null,
      amount: (this.orderSummaryProcess?.total*100),
      automaticPaymentMethodEnabled: true,
      description: "Maintenance Fee for Software Tool Renewals",
      currency: "USD",
      receiptEmail: this.informationProcess.customerEmailId,
      application: "QPM",
      metadata: metdata //dynamic
    }

    return { request: paymentIntent }
  }

  buildPaymentIntent() {
    let response: Observable<QPMResponse>;
    let clientSecret = this.app.sharedData.licenses.common.clientSecret;
    if(clientSecret == undefined){
      console.log("client secret not found, triggerring build payment intent");
      
      response = this.webClient.buildPaymentIntent(this.paymentIntentRequest);
      response.subscribe(
        (data: QPMResponse) => {

          if (data.isSuccess()) {
            let obj = JSON.parse(data.getData());

            this.elementsOptions.clientSecret = obj.clientSecret;
            this.app.sharedData.licenses.common.clientSecret = obj.clientSecret;
            this.populatePaymentElement(this.elementsOptions);

          } else {
            console.log("Error in buildPaymentIntent");
          }
        }
      );
    }
    else {
      console.log("Re-using client secret, triggerring update payment intent");

      let paymentIntentId = clientSecret.substring(0, clientSecret.indexOf('_secret')); //extract paymentIntentId from client secret eg "pi_3LvLfgDZs6aaNSfl0hwocyat_secret_y6L2PhuEVEKQOM9icBHH70muY" -> "pi_3LvLfgDZs6aaNSfl0hwocyat"
      this.paymentIntentRequest.request.paymentIntentId = paymentIntentId;
      response = this.webClient.updatePaymentIntent(this.paymentIntentRequest);
      response.subscribe(
        (data: QPMResponse) => {

          if (data.isSuccess()) {

            this.elementsOptions.clientSecret = this.app.sharedData.licenses.common.clientSecret;
            this.populatePaymentElement(this.elementsOptions);
            
          } else {
            console.log("Error in buildPaymentIntent");
          }
        }
      );
    }
  }

  populatePaymentElement(elementOptions){
    //take country code from first element as country code remains same even for different serial numbers for a customer
    let countryCode = this.app.sharedData.licenses.common.itemsAddedToCart[0].responseLineDetail.billToAddressV2.countryCode;

    this.stripeService.elements(elementOptions)
      .subscribe(elements => {
        this.stripeElements = elements;
        if (!this.paymentElement) { // Only mount the element the first time
          this.paymentElementOptions = {
            defaultValues: {
              billingDetails: {
                address: {
                  country: countryCode //set country code from billing address
                }
              }
            },
            fields: {
              billingDetails: {
                address: {
                  country: 'never' //hide country dropdwon
                }
              }
            },
            /*By default payment method is 'card' and card includes credi card, applePay, googlePay. 
            Setting applePay, googlePay to never as only credit card option is supported payment method. 
            This is optional as these 2 payment methods are disabled at account level but good to have as a 2nd layer check just in case someone enables it on account sometime in future*/
            wallets: {
              applePay : 'never',
              googlePay: 'never'
            }
          };
          this.paymentElement = this.stripeElements.create('payment', this.paymentElementOptions);
          this.paymentElement.mount('#payment-element');
        }
      });
  }

  pay() {
    //TODO: add a conditional check here to see if form details are valid.
    //take country code from first element as country code remains same even for different serial numbers for a customer
    //Disable Pay Now button for  paymentInProgress
    this.paymentInProgress=true;
    let countryCode = this.app.sharedData.licenses.common.itemsAddedToCart[0].responseLineDetail.billToAddressV2.countryCode;
    this.stripeService.confirmPayment({
      elements: this.stripeElements,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            name: this.informationProcess.customerEmailId,
            email: this.informationProcess.customerEmailId,
            address: {
              line1: this.selectedBillingAddress,
              postal_code: '',
              city: '',
              country: countryCode ////send country code during confirm payment explicitly as country field is set to never
            }
          }
        }
      },
      redirect: 'if_required'
    }).subscribe(result => {
      console.log('Result', result);
      if (result.error) {
        this.app.showMessageBox("Payment Failed", 'Error : '+result.error.message, "Ok");
      } else {
        // The payment has been processed!
        if (result.paymentIntent.status === 'succeeded') {
          this.app.sharedData.licenses.common.clientSecret = undefined;
          this.app.sharedData.licenses.common.itemsAddedToCart=[];//Clear Cart
          this.app.sharedData.updateCommunicationSubcribers(commType.UpdateCartItems, this.app.sharedData.licenses.common.itemsAddedToCart);
          this.router.navigate(['/main/licenses/success']);
        }
      }
      this.paymentInProgress=false;  
    });
  }


  onHoverBillingAddress(event, billingAddressInfoPanel: OverlayPanel) {
    billingAddressInfoPanel.toggle(event);
  }
  onHoverShippingAddress(event, shippingAddressInfoPanel: OverlayPanel) {
    shippingAddressInfoPanel.toggle(event);
  }

}
