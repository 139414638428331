import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import {Location} from '@angular/common'; 
import { License, SerialNumberDetail } from 'src/app/models/lime-web-client';
import { Observable } from 'rxjs';
import { QPMResponse } from 'src/app/models/response';

@Component({
  selector: 'app-serialnumber',
  templateUrl: './serialnumber.component.html',
  styleUrls: ['./serialnumber.component.css']
})
export class SerialnumberComponent implements OnInit {

  private logSrc:string = "SerialNumber-Component";

  private catalogClient: CatalogClientService;
  private webClient : WebClientService;
  private limeClient : ClientService;
  private telematicsClient: TelematicsClientService;
  
  selectedLicense:License=new License();
  //#Region For SerialNumbers Table
  @ViewChild('currentSerialNumbersTable') currentSerialNumbersTable;
  @ViewChild('upcomingSerialNumbersTable') upcomingSerialNumbersTable;
  @ViewChild('expiredSerialNumbersTable') expiredSerialNumbersTable;
  @ViewChild('licenseSerialNumbersGlobalFilter') licenseSerialNumbersGlobalFilter;
  loadingSerialNumberDetails:boolean;
  serialNumberTableCols: any[];
  serialNumberDetails:SerialNumberDetail[];
  expiringOrActiveSerialNumberDetails:SerialNumberDetail[];
  purchasedSerialNumberDetails:SerialNumberDetail[];
  expiredSerialNumberDetails:SerialNumberDetail[];
  //#region 

  constructor(private router: Router, private activatedRoute: ActivatedRoute,private location: Location,
    public app : AppMainComponent, private utils : Utils, 
    private service: DataServiceProducer, private http : HttpClient,
    public zone : NgZone) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
  }
  

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "SerialNumber Component Initialization");

    // If User try to access direct link to this License SerialNumber-Page
    if (this.app.sharedData.licenses.common.selectedLicense == undefined) {
      this.activatedRoute.params.subscribe(params => {
        this.app.sharedData.licenses.common.serialNumber = params['id'];
        this.app.sharedData.licenses.common.licensesGroupID = '';
        this.app.sharedData.licenses.common.directLinkForSerialNumber=true;
        this.app.sharedData.licenses.common.directLinkForLicensesGroup=false;
        this.router.navigate(['/main/licenses/find']);
      });
      return;
    }

    this.selectedLicense=this.app.sharedData.licenses.common.selectedLicense;
    this.activatedRoute.params.subscribe(params => {
      this.app.sharedData.licenses.common.serialNumber = params['id'];
      this.app.sharedData.licenses.common.licensesGroupID = '';


      this.loadingSerialNumberDetails=false;
      this.serialNumberTableCols = [];
      this.serialNumberDetails = [];
      this.expiringOrActiveSerialNumberDetails=[];
      this.purchasedSerialNumberDetails=[];
      this.expiredSerialNumberDetails=[];
      this.setSerialNumberTableColumn();
      this.getSerialNumberDetails(this.app.sharedData.licenses.common.serialNumber);
    })

    
 
    
  }

  setSerialNumberTableColumn(){
    this.serialNumberTableCols = [];
    this.serialNumberTableCols.push({ field: 'allProductsName', header: 'License Group ID', isFilterable: true });
    this.serialNumberTableCols.push({ field: 'serialStatus', header: 'Status', isFilterable: false });
    this.serialNumberTableCols.push({ field: 'purchaseDate', header: 'Purchase Date', isFilterable: false});
    this.serialNumberTableCols.push({ field: 'startDate', header: 'Start Date', isFilterable: false});
    this.serialNumberTableCols.push({ field: 'endDate', header: 'End Date', isFilterable: false});

  }

  ngAfterViewInit(){

  }

  onFilterClear()
  {
    this.licenseSerialNumbersGlobalFilter.nativeElement.value = '';
    this.currentSerialNumbersTable.filterGlobal('', 'contains');
    this.upcomingSerialNumbersTable.filterGlobal('', 'contains');
    this.expiredSerialNumbersTable.filterGlobal('', 'contains');
  }

  goBackToLicenseFind() {
    QLogger.LogInfo(this.logSrc,"Back to Licenses Find Page");
    this.location.back();
  }

  ngOnDestroy(): void {
    QLogger.LogInfo(this.logSrc, "SerialNumber Component Destroy"); 
  }


  getSerialNumberDetails(serialNumber:string){
    QLogger.LogInfo(this.logSrc, "Get SerialNumber-Details")
    this.loadingSerialNumberDetails=true;
    let response: Observable<QPMResponse>;
    response = this.webClient.getSerialNumberDetails(serialNumber);

    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available   SerialNumber-Details : " +JSON.stringify(data));
        } 
        if(data.getCode() === 400){
          this.router.navigate(['/main/accessdenied']);
        }
        this.serialNumberDetails = [];
        this.expiringOrActiveSerialNumberDetails=[];
        this.purchasedSerialNumberDetails=[];
        this.expiredSerialNumberDetails=[];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available  SerialNumber-Details  - Success");
          let obj = JSON.parse(data.getData());

          if (obj !== undefined || obj !== null) {
            if(obj.errorCode === 404){
              this.router.navigate(['/main/accessdenied']);
            }
            this.serialNumberDetails=obj as SerialNumberDetail[];
            this.serialNumberDetails?.forEach(slNo=>{
              slNo.allProductsName=slNo.products.toString();
              slNo.purchaseDate=this.utils.convertDatetimestampIntoMMDDYYYY(slNo.purchaseDate);
              slNo.startDate=this.utils.convertDatetimestampIntoMMDDYYYY(slNo.startDate);
              slNo.endDate=this.utils.convertDatetimestampIntoMMDDYYYY(slNo.endDate);
            });
            this.expiringOrActiveSerialNumberDetails=this.serialNumberDetails?.filter(slNo=>slNo.serialStatus==='Expiring' || slNo.serialStatus==='Active');
            this.purchasedSerialNumberDetails=this.serialNumberDetails?.filter(slNo=>slNo.serialStatus==='Purchased');
            this.expiredSerialNumberDetails=this.serialNumberDetails?.filter(slNo=>slNo.serialStatus==='Expired');

          }
          

        }
        else{
            QLogger.LogError(this.logSrc, "Get Available SerialNumber-Details - Failed");
            QLogger.LogError(this.logSrc, "Get Available SerialNumber-Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingSerialNumberDetails = false;
    });
  }






}
