import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { QPMResponse } from 'src/app/models/response';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { License, LicenseGroupFeature, LicenseGroupFeatureResponse, LicenseGroupForNotification, LicenseGroupProductCovered, LicenseGroupSeat, LicenseSeatDeactivateRequest } from 'src/app/models/lime-web-client';
import { MenuItem } from 'primeng/api/menuitem';
import { Observable } from 'rxjs';
import {Location} from '@angular/common'; 

@Component({
  selector: 'app-licensesgroup',
  templateUrl: './licensesgroup.component.html',
  styleUrls: ['./licensesgroup.component.css']
})
export class LicensesgroupComponent implements OnInit {

  private logSrc:string = "LicensesGroup-Component";

  private catalogClient: CatalogClientService;
  private webClient : WebClientService;
  private limeClient : ClientService;
  private telematicsClient: TelematicsClientService;

  
  licensesGroupLandingMenuItems: MenuItem[];
  licensesGroupLandingMenuActiveItem: MenuItem;
  ShowFeatureTableSection:boolean;
  showEmailIconForSubscription:boolean;

  //#Region For Feature Table
  @ViewChild('licenseFeaturesTable') licenseFeaturesTable;
  @ViewChild('licenseFeaturesGlobalFilter') licenseFeaturesGlobalFilter;
  loadingLicenseFeatures: boolean;
  cols: any[];
  licenseFeaturesResponse: LicenseGroupFeatureResponse=new LicenseGroupFeatureResponse();
  licenseGroupFeatures:LicenseGroupFeature[]=[];
  licenseGroupFeaturesMap:Map<string,string[]>=new Map();
  productsCovered:LicenseGroupProductCovered[]=[];

  details: {
    serialNumber?:string,
    company?: string,
    licenseType? : string,
    quantity?: number,
    activeCount?: number,
    Stardate ?: string,
    Enddate? : string
  }
  licensesGroupIDName:string;
  selectedLicense:License=new License();
  //#Region For Seats Table
    SeatsTableCols=[];
    @ViewChild('licenseSeatsTable') licenseSeatsTable;
    @ViewChild('licenseSeatsGlobalFilter') licenseSeatsGlobalFilter;
    loadingLicenseSeats: boolean;
    licenseGroupSeats: LicenseGroupSeat[];
    selectedLicenseGroupSeatsToDeactivate:LicenseGroupSeat[];


    licenseSeatDeactivateProcess:{
      displayForm: boolean,
      loadingDeactivateProcess: boolean,
      errorMessage: string,
      successMessage:string;
      selectedSingleLicenseGroupSeat:LicenseGroupSeat;
      deactivateSingle:boolean;
    }

    //#Region for EmailsubscriptionProcess
    emailSubscriptionProcess:{
      isSubscriptionProcess:boolean;
      isMultipleMode:boolean;
      displayForm: boolean,
      loadingProcess: boolean,
      errorMessage: string,
      successMessage:string;
      selectedLicenseGroupForNotification:LicenseGroupForNotification;
    }

  constructor(private router: Router, private activatedRoute: ActivatedRoute,private location: Location, 
    public app : AppMainComponent, private utils : Utils, 
    private service: DataServiceProducer, private http : HttpClient,
    public zone : NgZone) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
  }
  

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "LicensesGroup Component Initialization");

    // If User try to access direct link to this LicenseGroupPage
    if (this.app.sharedData.licenses.common.selectedLicense == undefined) {
          this.activatedRoute.params.subscribe(params => {
            this.app.sharedData.licenses.common.serialNumber = params['id'];
            this.app.sharedData.licenses.common.licensesGroupID = params['lg'];
            this.app.sharedData.licenses.common.directLinkForLicensesGroup=true;
            this.app.sharedData.licenses.common.directLinkForSerialNumber=false;
            this.router.navigate(['/main/licenses/find']);
          });
          return;
    }
    
    this.selectedLicense=this.app.sharedData.licenses.common.selectedLicense;
    this.activatedRoute.params.subscribe(params => {
      this.app.sharedData.licenses.common.serialNumber = params['id'];
      this.app.sharedData.licenses.common.licensesGroupID = params['lg'];
      this.licensesGroupLandingMenuItems = [
        {label: 'Features' , command: (event: any) => {this.viewFeatureSection(event);}},
        {label: 'Seats', command: (event: any) => {this.viewSeatsSection(event);}}
      ];
      this.licensesGroupLandingMenuActiveItem = this.licensesGroupLandingMenuItems[0];
      this.ShowFeatureTableSection=true;
      this.showEmailIconForSubscription=false;


      this.loadingLicenseFeatures=false;
      this.cols = [];
      this.licenseFeaturesResponse = new LicenseGroupFeatureResponse();
      this.licenseGroupFeatures=[];
      this.productsCovered=[];
      this.detailsResetProcess();
      this.setLicenseFeaturesTableColumn();
  
      this.loadingLicenseSeats=false;
      this.SeatsTableCols=[];
      this.licenseGroupSeats = [];
      this.selectedLicenseGroupSeatsToDeactivate=[];
      this.setLicenseSeatsTableColumn();
  
      this.getAvailableLicenseFeaturesData();
      this.getAvailableLicenseGroupSeatsData();
      this.resetLicenseSeatDeactivateProcess();
      this.resetEmailSubscriptionProcess();
      this.getSubscriptionDetailsByLicenseGroupId();
    })

  
  }

  setLicenseFeaturesTableColumn(){
    this.cols = [];
    this.cols.push({ field: 'productName', header: 'Tool', isFilterable: true });
    this.cols.push({ field: 'name', header: 'Feature', isFilterable: true });
  }
  setLicenseSeatsTableColumn(){
    this.SeatsTableCols = [];
    this.SeatsTableCols.push({ field: 'licenseActivationId', header: 'Activation ID', style:{'width': '30%'}, isFilterable: true });
    this.SeatsTableCols.push({ field: 'activatedd', header: 'Activation Date', isFilterable: true });
    this.SeatsTableCols.push({ field: 'status', header: 'Status', isFilterable: true });
    this.SeatsTableCols.push({ field: 'username', header: 'Username', isFilterable: true});
    this.SeatsTableCols.push({ field: 'computerName', header: 'Computer Name', isFilterable: true});
   // this.SeatsTableCols.push({ field: 'actions', header: '', style:{'width': '210px'}});

  }

  detailsResetProcess(){
    this.details = {
      serialNumber:null,
      activeCount:null,
      quantity:null,
      company:'',
      licenseType:'',
      Stardate:'',
      Enddate:''
     };
  }

  viewFeatureSection(event){
    this.licensesGroupLandingMenuActiveItem = this.licensesGroupLandingMenuItems[0];
    this.ShowFeatureTableSection=true;
  }
  viewSeatsSection(event){
    this.licensesGroupLandingMenuActiveItem = this.licensesGroupLandingMenuItems[1];
    this.ShowFeatureTableSection=false;
  }


  ngAfterViewInit(){

  }
  ngOnDestroy(): void {
    QLogger.LogInfo(this.logSrc, "LicensesGroup Component Destroy"); 
  }


  
  getAvailableLicenseFeaturesData(){
    QLogger.LogInfo(this.logSrc, "Get Licenses-Features ");
    this.loadingLicenseFeatures = true;
    let response: Observable<QPMResponse>;
    response = this.webClient.getFeatures(this.app.sharedData.licenses.common.licensesGroupID);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available   Licenses-Features : " +JSON.stringify(data));
        } 
        if(data.getCode() === 400){
          this.router.navigate(['/main/accessdenied']);
        }
        this.licenseFeaturesResponse = new LicenseGroupFeatureResponse();
        this.licenseGroupFeatures=[];
        this.detailsResetProcess();
        this.productsCovered=[];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available  Licenses-Features  - Success");
          let obj = JSON.parse(data.getData());

          if (obj !== undefined || obj !== null) {
            if(obj.errorCode === 400){
              this.router.navigate(['/main/accessdenied']);
            }
            this.licenseFeaturesResponse=obj as LicenseGroupFeatureResponse;
            this.licenseGroupFeatures=this.licenseFeaturesResponse?.featuresDTO;
            //Process licenseGroupFeaturesMap
            this.licenseGroupFeatures?.forEach(item=>{
              if(this.licenseGroupFeaturesMap.has(item.productName)===false){
                let featureArr:any=[];
                featureArr.push({name:item.name})
                this.licenseGroupFeaturesMap.set(item.productName,featureArr);
              }else{
                let existingFeatureArr:any=this.licenseGroupFeaturesMap.get(item.productName);
                existingFeatureArr.push({name:item.name});
                this.licenseGroupFeaturesMap.set(item.productName,existingFeatureArr);
              }
            }) 

            this.details.serialNumber=this.app.sharedData.licenses.common.serialNumber;
            this.details.company=this.licenseFeaturesResponse.company;
            this.details.licenseType=this.licenseFeaturesResponse.licenseType;
            this.details.activeCount=this.licenseFeaturesResponse.activeCount;
            this.details.quantity=this.licenseFeaturesResponse.quantity;
            
            this.details.Stardate=this.utils.convertDatetimestampIntoMMDDYYYY(this.licenseFeaturesResponse.featuresDTO[0]?.startd);
            this.details.Enddate=this.utils.convertDatetimestampIntoMMDDYYYY(this.licenseFeaturesResponse.featuresDTO[0]?.endd);

            //ProductTools logo with Name
            let productsCoveredResponse=[];
            this.licenseGroupFeatures?.forEach(feature=>{
              let productCovered:LicenseGroupProductCovered=new LicenseGroupProductCovered();
              productCovered.imageURL=feature?.productLogo;
              productCovered.productName=feature?.productName;
              productsCoveredResponse.push(productCovered);
            })
            let productsCoveredSet=new Set<LicenseGroupProductCovered>();
            this.productsCovered= productsCoveredResponse?.filter(obj => !productsCoveredSet.has(obj['productName']) && productsCoveredSet.add(obj['productName']));
            this.loadingLicenseFeatures = false;
          }
          

        }
        else{
            QLogger.LogError(this.logSrc, "Get Available Licenses-Features - Failed");
            QLogger.LogError(this.logSrc, "Get Available Licenses-Features - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingLicenseFeatures = false;
    });

  }

  getAvailableLicenseGroupSeatsData(){
    QLogger.LogInfo(this.logSrc, "Get Licenses-Seats ");
    this.loadingLicenseSeats = true;
    let response: Observable<QPMResponse>;
    response = this.webClient.getSeats(this.app.sharedData.licenses.common.licensesGroupID);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available   Licenses-Seats : " +JSON.stringify(data));
        } 
        if(data.getCode() === 400){
          this.router.navigate(['/main/accessdenied']);
        }
        this.licenseGroupSeats=[];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available  Licenses-Seats  - Success");
          let obj = JSON.parse(data.getData());

          if (obj !== undefined || obj !== null) {
            if(obj.errorCode === 400){
              this.router.navigate(['/main/accessdenied']);
            }
            this.licenseGroupSeats=obj as LicenseGroupSeat[];
            this.licenseGroupSeats?.forEach(seat=>seat.activatedd=this.utils.convertDatetimestampIntoMMDDYYYY(seat.activatedd))
          }
        }
        else{
            this.licenseGroupSeats=[];
            QLogger.LogError(this.logSrc, "Get Available Licenses-Seats - Failed");
            QLogger.LogError(this.logSrc, "Get Available Licenses-Seats - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingLicenseSeats = false;
    });

  }

  onFilterClear()
  {
    this.licenseFeaturesGlobalFilter.nativeElement.value = '';
    this.licenseFeaturesTable.filterGlobal('', 'contains');
  }

  goBackToPreviousVisitedPage() {
    QLogger.LogInfo(this.logSrc,"Back To Previous Visited Page");  
    this.location.back();
  }

//#region Deactivate
  resetLicenseSeatDeactivateProcess(){
    this.licenseSeatDeactivateProcess={
      displayForm:false,
      loadingDeactivateProcess:false,
      errorMessage:'',
      successMessage:'',
      selectedSingleLicenseGroupSeat:null,
      deactivateSingle:false
    }
  }
 
  openLicenseSeatDeactivationDialog(deactivateSingle:boolean,selectedSingleLicenseGroupSeat?:LicenseGroupSeat){
    this.resetLicenseSeatDeactivateProcess();
    this.licenseSeatDeactivateProcess.displayForm=true;
    this.licenseSeatDeactivateProcess.errorMessage='';
    this.licenseSeatDeactivateProcess.successMessage='';
    this.licenseSeatDeactivateProcess.loadingDeactivateProcess=false;
    this.licenseSeatDeactivateProcess.selectedSingleLicenseGroupSeat=selectedSingleLicenseGroupSeat;
    this.licenseSeatDeactivateProcess.deactivateSingle=deactivateSingle;
  }
 
  deactivateSingleLicenseSeats(selectedLicenseGroupSeat:LicenseGroupSeat){
    QLogger.LogInfo(this.logSrc, "Deactivate Single Licenses-Seat");
    this.licenseSeatDeactivateProcess.loadingDeactivateProcess = true;
    let response: Observable<QPMResponse>;
    let request=new LicenseSeatDeactivateRequest();
    request.item={status:'D'};
    request.requester=this.app.sharedData.userInfo.username;
 
    response = this.webClient.deactivateLicenseSeat(selectedLicenseGroupSeat.licenseGroupId,selectedLicenseGroupSeat.licenseActivationId,request);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Single Licenses-Seat successfully Deactivated : " +JSON.stringify(data));
        } 
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Single Licenses-Seat Deactivation - Success");
          let obj = JSON.parse(data.getData());

          if (obj !== undefined || obj !== null) {
           this.licenseSeatDeactivateProcess.successMessage='';
           this.licenseSeatDeactivateProcess.errorMessage='';
           this.licenseSeatDeactivateProcess.loadingDeactivateProcess = false;
           //this.licenseSeatDeactivateProcess.successMessage='License with ActivationID: '+obj.licenseActivationId+ ' Deactivated Succesfully';
           this.licenseSeatDeactivateProcess.successMessage='Selected license(s) deactivated succesfully';
           this.getAvailableLicenseGroupSeatsData();
           
          }
        }
        else{
            this.licenseSeatDeactivateProcess.successMessage='';
            this.licenseSeatDeactivateProcess.errorMessage='';
            this.licenseSeatDeactivateProcess.loadingDeactivateProcess = false;
            this.licenseSeatDeactivateProcess.errorMessage='Error : '+data.getError() ;
            QLogger.LogError(this.logSrc, "Single Licenses-Seat Deactivation  - Failed");
            QLogger.LogError(this.logSrc, "Single Licenses-Seat Deactivation  - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.licenseSeatDeactivateProcess.loadingDeactivateProcess = false;
    });
  }

  deactivateMultipleLicenseSeats(){
    this.selectedLicenseGroupSeatsToDeactivate?.forEach(licenseSeat=>{
      this.deactivateSingleLicenseSeats(licenseSeat);
    })
    this.clearDeactivate();
  }

  clearDeactivate(){
    this.selectedLicenseGroupSeatsToDeactivate=[];
  }
 //#endregion


 //#region Email subscription and Unsubscription Process
 resetEmailSubscriptionProcess(){
  this.emailSubscriptionProcess={
    isSubscriptionProcess:undefined,
    isMultipleMode:undefined,
    displayForm:false,
    loadingProcess:false,
    errorMessage:'',
    successMessage:'',
    selectedLicenseGroupForNotification:null
  }
}

openEmailSubscriptionProcessDialog(isSubscriptionProcess:boolean,isMultipleMode:boolean,selectedLicenseGroupForNotification?:LicenseGroupForNotification){
  this.resetEmailSubscriptionProcess();
  this.emailSubscriptionProcess.displayForm=true;
  this.emailSubscriptionProcess.errorMessage='';
  this.emailSubscriptionProcess.successMessage='';
  this.emailSubscriptionProcess.loadingProcess=false;
  this.emailSubscriptionProcess.selectedLicenseGroupForNotification=selectedLicenseGroupForNotification;
  this.emailSubscriptionProcess.isSubscriptionProcess=isSubscriptionProcess;
  this.emailSubscriptionProcess.isMultipleMode=isMultipleMode;
}

subscribeEmailNotification(licenceGroupId:string){
  QLogger.LogInfo(this.logSrc, "Subscribe Single LicensesGroup-For Email Notification");
  this.emailSubscriptionProcess.loadingProcess = true;
  let response: Observable<QPMResponse>;

  response = this.webClient.subscribeLicensegroupForEmail(licenceGroupId);
  response.subscribe(
    (data: QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully Subscribed : " +JSON.stringify(data));
      } 
      if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully Subscribed - Success");
        let obj = JSON.parse(data.getData());

        if (obj !== undefined || obj !== null) {
         this.emailSubscriptionProcess.successMessage='';
         this.emailSubscriptionProcess.errorMessage='';
         this.emailSubscriptionProcess.loadingProcess = false;
         //this.emailSubscriptionProcess.successMessage=obj.errorDetails;
         this.emailSubscriptionProcess.successMessage='Successfully subscribed to receive email updates for expiration notices for selected license group(s).'
         this.getSubscriptionDetailsByLicenseGroupId();
         
        }
      }
      else{
          this.emailSubscriptionProcess.successMessage='';
          this.emailSubscriptionProcess.errorMessage='';
          this.emailSubscriptionProcess.loadingProcess = false;
          this.emailSubscriptionProcess.errorMessage='Error : '+data.getError() ;
          QLogger.LogError(this.logSrc, "Single LicensesGroup  Subscription   - Failed");
          QLogger.LogError(this.logSrc, "Single LicensesGroup  Subscription   - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }
      this.emailSubscriptionProcess.loadingProcess = false;
  });
  

}

unSubscribeEmailNotification(licenceGroupId:string){
  QLogger.LogInfo(this.logSrc, "UnSubscribe Single LicensesGroup-For Email Notification");
  this.emailSubscriptionProcess.loadingProcess = true;
  let response: Observable<QPMResponse>;

  response = this.webClient.unsubscribeLicenseGroupForEmail(licenceGroupId);
  response.subscribe(
    (data: QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully UnSubscribed : " +JSON.stringify(data));
      } 
      if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully UnSubscribed - Success");
        let obj = JSON.parse(data.getData());

        if (obj !== undefined || obj !== null) {
         this.emailSubscriptionProcess.successMessage='';
         this.emailSubscriptionProcess.errorMessage='';
         this.emailSubscriptionProcess.loadingProcess = false;
         //this.emailSubscriptionProcess.successMessage=obj.errorDetails;
         this.emailSubscriptionProcess.successMessage='Successfully unsubscribed to receive email updates for expiration notices for selected license group(s).'
         this.getSubscriptionDetailsByLicenseGroupId();
         
        }
      }
      else{
          this.emailSubscriptionProcess.successMessage='';
          this.emailSubscriptionProcess.errorMessage='';
          this.emailSubscriptionProcess.loadingProcess = false;
          this.emailSubscriptionProcess.errorMessage='Error : '+data.getError() ;
          QLogger.LogError(this.logSrc, "Single LicensesGroup  UnSubscription   - Failed");
          QLogger.LogError(this.logSrc, "Single LicensesGroup  UnSubscription   - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }
      this.emailSubscriptionProcess.loadingProcess = false;
  });
  

}


getSubscriptionDetailsByLicenseGroupId(){
 QLogger.LogInfo(this.logSrc, "Get Email Subscription Details By LicenseGroupId");
 this.showEmailIconForSubscription=false;
 this.emailSubscriptionProcess.loadingProcess = true;
 let response: Observable<QPMResponse>;
 response = this.webClient.getSubscriptionDetailsByLicenseGroupId(this.app.sharedData.licenses.common.licensesGroupID);

 response.subscribe(
  (data: QPMResponse) => {
    if(this.app.sharedData.appInfo.logResponse){
      QLogger.LogInfo(this.logSrc, "Get Email Subscription Details By LicenseGroupId : " +JSON.stringify(data));
    } 
    let products = [];
    if (data.isSuccess()) {
      QLogger.LogInfo(this.logSrc, "Get Currently Subscribed LicenseGroup-Details  - Success");
      let obj = JSON.parse(data.getData());

      if (obj !== undefined || obj !== null) {
        products = [];
        products=obj.products;
        if(products?.length==0 && this.selectedLicense?.licenseType==='FACTORY' && this.selectedLicense?.expiryStatus!=='Expired'){
          //showEmailIcon
          this.showEmailIconForSubscription=true;
          this.emailSubscriptionProcess.loadingProcess = false;
        }else{
          //hide emailIcon
          this.showEmailIconForSubscription=false;
          this.emailSubscriptionProcess.loadingProcess = false;
        }
      }
    }
    else{
        QLogger.LogError(this.logSrc, "Get Currently Subscribed LicenseGroup-Details - Failed");
        QLogger.LogError(this.logSrc, "Get Currently Subscribed LicenseGroup-Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
         //hide emailIcon
         this.showEmailIconForSubscription=false;
         this.emailSubscriptionProcess.loadingProcess = false;
    }
    this.emailSubscriptionProcess.loadingProcess = false;
    
});
}
//#endregion


}
