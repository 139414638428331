export const environment = {
  production: false,
  developer: false,
  productID: "3097f479-8993-11ea-9571-06074a401742",
  featureID: "31042640-8993-11ea-9571-06074a401742",
  baseWebAppUrl: "https://qpmstg.qualcomm.com",
  domain: 'corpawswsgwtestx.qualcomm.com',
  baseUrlSoftwareVordel: "https://corpawswsgwtestx.qualcomm.com/stardust",
  baseUrlSoftware: "http://vpce-07e5623b3e5d4049d-i3dr4ycr.vpce-svc-0bcc500f9e8fbd9e5.us-west-2.vpce.amazonaws.com",//"http://starduststaging-env.eba-p2mvva5e.us-west-2.elasticbeanstalk.com",
  baseUrlSoftwareOnPrem: "http://10.47.209.33:8082",
  portalUrl: "https://qpmstg.qualcomm.com",
  baseUrlToolsVordel: "https://corpawswsgwtestx.qualcomm.com/lime-staging/",
  baseUrlTools: "http://vpce-0fe0dfe8af74ec3de-hivp5e1s.vpce-svc-03df9bbd976d9fe12.us-west-2.vpce.amazonaws.com/webapi",//"http://ridl.lime-stage.aws.qualcomm.com/webapi",
  baseUrlUGDBVordel: "https://corpawswsgwtestx.qualcomm.com/ugdb",
  baseUrlUGDB: "http://vpce-0e11704bda21e2247-6vpe1lfg.vpce-svc-0c294d8ab35f736ce.us-west-2.vpce.amazonaws.com/",// "http://ugdbservicestaging-env.eba-2brad5xq.us-west-2.elasticbeanstalk.com/",
  baseUrlPaasVordel: "https://corpawswsgwtestx.qualcomm.com/paas/",
  baseUrlPaas: "http://vpce-01a783b6e47db69c0-nf8geiam.vpce-svc-0c171966a30f2f495.us-west-2.vpce.amazonaws.com/webapi",// "http://Paymentsservice-env.eba-7xymfss9.us-west-2.elasticbeanstalk.com/webapi",
  stripePublishableKey: "pk_test_51KhHyQDZs6aaNSflbDUzda3gwcU0HUuWJyzHhEKvRPYmIZxmXBvUakgC4Ll0tpp6YVQARgI3NoDfa1P8yL6tnN6x00IQ13K2l7", //prod account test mode
  //stripePublishableKey: "pk_test_51KiRk0J9HSJXUod0U6jXnQ7EQaa1fvBj7byEBxQIZGFAsf4lQ33tYSgKXK3CmMxOZeLUitUaRTFhx47HuDdJIck600OJEcyDST" //dev account test mode
  authConfig: {
    issuer: 'https://openid-tst.qualcomm.com/affwebservices/CASSO/oidc/QPM3Dev',
    responseType: 'code',
    redirectUri: window.location.origin + '/#/',
    loginUrl: 'https://openid-tst.qualcomm.com/affwebservices/CASSO/oidc/QPM3Dev/authorize',
    tokenEndpoint: 'https://openid-tst.qualcomm.com/affwebservices/CASSO/oidc/QPM3Dev/token',
    userinfoEndpoint: 'https://openid-tst.qualcomm.com/affwebservices/CASSO/oidc/QPM3Dev/userinfo',
    revocationEndpoint: 'https://openid-tst.qualcomm.com/affwebservices/CASSO/oidc/QPM3Dev/revoke',
    clientId: '00037e5b-9f74-1392-b0f0-90550a310000',
    dummyClientSecret: "gnpfDWdPbCTzFDXz/LCHykHwq7RqAsnFMl+iF36sJV0=",
  
    // Hostname could be localhost or localhost.com when hosting locally
    //silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    useSilentRefresh: true,
    logoutUrl: "https://openid-tst.qualcomm.com/affwebservices/redirectjsp/logout.jsp?redirect=https://ext.qclogintest.qualcomm.com/logoff_blank.html?returnURL=" + window.location.origin,
    scope: 'openid, profile',
    disablePKCE: true
  }
};
