export  class QPMCLICommands{
    public static readonly  catalogRefresh:string = "--catalog-refresh";
    public static readonly  chipsetCatalogRefresh:string = "--chipset-catalog-refresh";
    public static readonly  licenseRecover:string = "--license-recover";
    public static readonly  licenseVerify:string = "--license-verify";
    public static readonly  catalogRefreshWithAutoUpgrade:string = QPMCLICommands.catalogRefresh + " --upgrade --auto";
    public static readonly  licenseRefresh:string = "--license-refresh";
};
export var QPMCLICmdsToInvokeOnRefresh:string[] = [QPMCLICommands.chipsetCatalogRefresh, QPMCLICommands.licenseRecover, QPMCLICommands.licenseVerify, QPMCLICommands.catalogRefreshWithAutoUpgrade];
export var QPMCLICmdsToInvokeOnLogin:string[] = [QPMCLICommands.chipsetCatalogRefresh, QPMCLICommands.licenseRecover, QPMCLICommands.licenseVerify, QPMCLICommands.catalogRefreshWithAutoUpgrade, QPMCLICommands.licenseRefresh];
export var QPMCLICommandsToInvokePeriodically:string[] = [QPMCLICommands.catalogRefreshWithAutoUpgrade];
export class toolsConstants{
    public static readonly CatalogExpiryInterval_ms:number = 30*60*1000;// 30 minutes;
    public static readonly ProductReleasesInfoExpiryInterval_ms:number = 10*60*1000; //5 minutes
}
export enum ProductClassification{
    Executable = 'Executable',
    CloOnly = 'Clo-only',
    CloExec = 'Clo-exec',
}
