import { Injectable } from '@angular/core';
import isElectron from 'is-electron';
import { platform } from 'os';
import { ConfigProperties } from './config-properties';
@Injectable()
export class QConfig {
    private static qpmConfig: any;
    lastVisitedPage = "lastVisitedPage";

    constructor( private configProp: ConfigProperties){
        if(isElectron() && QConfig.qpmConfig === undefined){
            const store = window.require('electron-store');
            QConfig.qpmConfig = new store();
        }
    }

    //#region Functions
    setLastVisitedPage(path: string){
        if(QConfig.qpmConfig !== undefined){
            QConfig.qpmConfig.set(this.configProp.lastVisitedPage, path);
        }
    }
    getLastVisitedPage(): string{
        if(QConfig.qpmConfig !== undefined){
            if(QConfig.qpmConfig.has(this.configProp.lastVisitedPage)){
                return QConfig.qpmConfig.get(this.configProp.lastVisitedPage);
            }
        }
        return null;
    }
    getQPM3LogFolder():string{
        if(isElectron()){
            if(process.platform == 'win32')
                return (process.env.TEMP||process.env.TMP)+"\\QualcommPackageManager3\\logs\\";
            else if(process.platform == 'linux')
                return "/var/tmp/qcom/QualcommPackageManager3/logs/";
        }
        throw new Error("Platform not supported for getting log folder")
    }
    //#endregion
}