import { Injectable } from '@angular/core';
import isElectron from 'is-electron';
import { SharedData } from './shared-data';
import {QConfig} from './qpm-config';
import {Buffer} from 'buffer';

@Injectable()
export class QLogger{
    private static logger;
    private static sharedData;
    private static browserLogger: String[];
    private static browserLogLimit:number = 5 * 1024 * 1024;
    private static browserLogSize:number = 0;

    static init(){
       
        if(isElectron() && QLogger.logger === undefined){
            QLogger.logger = window.require('electron-log');
            QLogger.logger.transports.file.maxSize = 10485760;
            QLogger.sharedData= new SharedData();
            if (QLogger.sharedData.appInfo.platform == 'win32' || QLogger.sharedData.appInfo.platform == 'linux'){ 
                let qpm3LogFolder:string = QConfig.prototype.getQPM3LogFolder();
                QLogger.logger.transports.file.resolvePath =  () =>qpm3LogFolder + this.getFileName();
            }       
        }
        else if(!isElectron()){
            QLogger.browserLogger = [];
        }
       
    }

    static LogInfo(src: string, data: string){
        if(QLogger.logger !== undefined){
            QLogger.logger.transports.file.fileName = this.getFileName();
            QLogger.logger.info(src + " : " + data);
        }
        else{
            console.log(src + " : " + data);
        }

        QLogger.LogBrowser(src, data);
    }
    static LogWarn(src: string, data: string){
        if(QLogger.logger !== undefined){
            QLogger.logger.transports.file.fileName = this.getFileName();
            QLogger.logger.warn(src + " : " + data);
        }
        else{
            console.log(src + " : " + data);
        }

        QLogger.LogBrowser(src, data);
    }
    static LogError(src: string, data: string){
        if(QLogger.logger !== undefined){
            QLogger.logger.transports.file.fileName = this.getFileName();
            QLogger.logger.error(src + " : " + data);
        }
        else{
            console.log(src + " : " + data);
        }

        QLogger.LogBrowser(src, data);
    }

    private static LogBrowser(src: string, data: string){
        if(QLogger.browserLogger !== undefined){
            let logStr = src + " : " + data;
            let size = Buffer.from(logStr).length;
            QLogger.browserLogger.push(src + " : " + data);
            QLogger.browserLogSize += size;
            if(QLogger.browserLogSize > QLogger.browserLogLimit){
                let trimSize = QLogger.browserLogSize - QLogger.browserLogLimit;
                while(trimSize > 0 && QLogger.browserLogger.length > 0){
                    let oldLogSize = Buffer.from(QLogger.browserLogger[0]).length;
                    trimSize -= oldLogSize;
                    QLogger.browserLogSize -= oldLogSize;
                    QLogger.browserLogger.splice(0,1);
                }
            }
        }
    }

    static GetBrowserLogs(): String[]{
        if(QLogger.browserLogger !== undefined){
            return QLogger.browserLogger;
        }
        return [];
    }

    private static getFileName(){
        let d = new Date();
        let year = d.getFullYear().toString();
        let month = (d.getMonth()+1).toString().length == 1 ? "0"+(d.getMonth()+1).toString() : (d.getMonth()+1).toString();
        let date = d.getDate().toString().length == 1 ? "0"+d.getDate().toString() : d.getDate().toString();
        return year + "-" + month + "-" + date+".log";
    }
}