import { Component, OnInit} from '@angular/core';
import { AppMainComponent } from 'src/app/app.main.component';
import { DownloadHistoryLocal } from 'src/app/models/distribution-client';
import { SoftwareProductDistro, SoftwareProductRelease } from 'src/app/models/software-catalog-client';
@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.css']
})
export class DownloadsComponent implements OnInit {
  cols: any[];
  constructor(public app: AppMainComponent){

  }

  ngOnInit(): void {
  this.cols = [];
  this.cols.push({ field: 'tag', header: 'Version', isFilterable: true });
  this.cols.push({ field: 'softwareBuildId', header: 'Software Build', isFilterable: true });
  this.cols.push({ field: 'distro.distro', header: 'Distro Name', isFilterable: true});

  }

  retryDownload(downloadHistory:DownloadHistoryLocal)
  {
      if(downloadHistory.targetSoftwareBuild==null)
      {
          if(downloadHistory.softwareImageBuild==null)
          this.app.queueDownload(downloadHistory.baseSoftwareBuild,downloadHistory.distro,downloadHistory.downloadLocation);
          else
          this.app.queueImageDownload(downloadHistory.baseSoftwareBuild,downloadHistory.softwareImageBuild,downloadHistory.distro,downloadHistory.downloadLocation);
      }
      else
      {  
        if(downloadHistory.softwareImageBuild==null)
          this.app.queueDiffDownload(downloadHistory.targetSoftwareBuild,downloadHistory.baseSoftwareBuild,downloadHistory.distro,downloadHistory.downloadLocation);
        else
          this.app.queueDiffImageDownload(downloadHistory.targetSoftwareBuild,downloadHistory.baseSoftwareBuild,downloadHistory.softwareImageBuild,downloadHistory.distro,downloadHistory.downloadLocation);
      }
  }

  cancelDownload(downloadHistory:DownloadHistoryLocal)
  {
      this.app.downloadQueue.softwareQueue.forEach(software =>{

          if(software.softwareProductBuild.softwareProductBuild == downloadHistory.baseSoftwareBuild.softwareProductBuild && software.distro.softwareDistro==downloadHistory.distro.softwareDistro)
          { 
            if((downloadHistory.softwareImageBuild!=null && software.softwareImageBuild!=null && downloadHistory.softwareImageBuild==software.softwareImageBuild)||(downloadHistory.softwareImageBuild==null && software.softwareImageBuild==null))
            {
                if(downloadHistory.tag==software.softwareProductBuild.tag || (downloadHistory.tag==software.softwareProductBaseBuild?.tag+"_"+software.softwareProductBuild?.tag))
                {
                    this.app.downloadQueue.softwareQueue.splice(this.app.downloadQueue.softwareQueue.indexOf(software),1);
                    this.app.updateDownloadHistory(downloadHistory.targetSoftwareBuild,downloadHistory.baseSoftwareBuild,downloadHistory.distro,"Cancelled",null,null,downloadHistory.softwareImageBuild)
                }
            }

          }
      });
  }
}

