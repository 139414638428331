<div id="ToolsDetailPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">

      <div class="ui-g-12 card card-w-title" style="height:100%;">
  
        <!-- Header : Online mode-->
        <div class="ui-g-12">
          <div class="ui-g-7" style="display:flex; justify-content: flex-start; height: 80px;">
            <a (click)="goBackToPreviousVisitedPage()" style="align-self: center;"><img src="assets/images/back_button.png"/></a>
            <div style="grid-column: 1;grid-row:1;">
                <h3 id="InstallationStatus"  style="padding-left: 10px; color:grey ;margin-bottom:2px;">Licenses Group ID:</h3>
                <h2 style=" padding-left: 10px; color: black;"><b>{{this.app.sharedData.licenses.common.licensesGroupID}}</b></h2>
              </div>
          </div>
    
           
          <!--For License Status -->
          <div class="ui-g-4"  style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Active'">
              <p id="ActiveStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="greendot" style="margin-right:5px;"></span><b>License Active</b></p>
              <!-- <p style="color: gray;margin-top: 3px;">To purchase new licenses, please visit <br> <a style="cursor: pointer; color: blue;" href="https://cp.qti.qualcomm.com/" target="_blank">Qualcomm Sales Center</a> </p> -->
            </div>
            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Purchased'">
              <p id="PurchasedStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="greendot" style="margin-right:5px;"></span><b>License Purchased</b></p>
            </div>

            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Expired'">
              <p id="ExpiredStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="reddot" style="margin-right:5px;"></span><b>License Expired</b></p>
            </div>

            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Expiring'">
              <p id="ExpiringStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="yellowdot" style="margin-right:5px;"></span><b>License Expiring</b></p>
              <p style="color: gray;margin-top: 3px;">on  <span style="color: gray;">{{selectedLicense?.endDate}}</span></p>
            </div>
            <div *ngIf="(app.sharedData.visibility.license.renewWorkflowForUSuser) && (selectedLicense?.serialNumber!='') && (selectedLicense?.expiryStatus==='Expiring' || selectedLicense?.expiryStatus==='Expired')" style="grid-column: 3;grid-row: 1;width: auto;align-self: center;vertical-align:center;margin-top: -15px;"> 
              <i class="pi pi-exclamation-circle" *ngIf="!selectedLicense?.isEligibleForRenewal" pTooltip="Renewal is available when: License expired less than 1 year ago or is due to expire within 90 days" [style]="{'font-size': '1.7em', 'cursor': 'pointer'}"></i>
              <button style="width: 150px;;align-self: center;vertical-align:center;" id="RenewButton" (click) = "app.openRenewDialogProcess(selectedLicense)"  [disabled]="!selectedLicense?.isEligibleForRenewal"  pButton type="button" label="Renew"></button>
              <p style="color: gray;" *ngIf="selectedLicense?.licenseType==='PURCHASE'">To purchase new licenses, please visit <br> <a style="cursor: pointer; color: blue;" href="https://cp.qti.qualcomm.com/" target="_blank">Qualcomm Sales Center</a> </p>
            </div>

            <div *ngIf="selectedLicense?.expiryStatus==='Active' && selectedLicense?.licenseType==='PURCHASE'" style="grid-column: 3;grid-row: 1;width: auto;align-self: center;vertical-align:center;margin-top: -15px;"> 
              <p style="color: gray;">To purchase new licenses, please visit <br> <a style="cursor: pointer; color: blue;" href="https://cp.qti.qualcomm.com/" target="_blank">Qualcomm Sales Center</a> </p>
            </div>

        </div>

          <div class="ui-g-1"  style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
           <div style="grid-column: 1;grid-row:1;text-align: center">
            <i class="ui-icon-email" *ngIf="showEmailIconForSubscription" pTooltip="Subscribe"  style="margin-top: 10px;font-size: 2.2em;" (click)="openEmailSubscriptionProcessDialog(true,false,null)"></i>
           </div>
          </div>

        </div>
    
        <hr>
        <div class="ui-g-12">
          <div class="ui-inputgroup" style="margin-top: 20px;">
              <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
              <input id="FeaturesProductsSearch" pInputText type="text" placeholder="Search features , tools" #licenseFeaturesGlobalFilter (input)="licenseFeaturesTable.filterGlobal($event.target.value,'contains')">
              <i class="ui-icon-cancel" id="LicenceGroupFeatureclearToolGlobalFilter" pTooltip="Clear Search" style="margin:4px 4px 0 0; cursor: pointer;" (click)="onFilterClear()"></i>        
          </div>
        </div>
  
        <div class="ui-g-12">

          <div class="ui-g-9">
            <div class="ui-g-12">
                  <p-tabMenu  [model]="licensesGroupLandingMenuItems" [activeItem]="licensesGroupLandingMenuActiveItem"  style="width:100%;"></p-tabMenu>
            </div>

           
            <hr>
          
            <!-- Feature Section -->
            <div class="ui-g-12" style="margin:0px;padding:0px;" *ngIf="ShowFeatureTableSection" >
                <p-table  #licenseFeaturesTable [value]="licenseGroupFeatures"[scrollable]="false" scrollHeight="380px"
                        [loading]="loadingLicenseFeatures" sortField="productName" [sortOrder]="-1" [paginator]="true" [rows]="10" [globalFilterFields]="['name','productName']">
                        <ng-template pTemplate="caption">
                        </ng-template>

                        <ng-template pTemplate="header" style="margin-right:0px">
                          <tr>
                            <th *ngFor="let col of cols" id ="{{col.field}}" [pSortableColumn]="col.field"  [ngStyle]="col.style">
                              <span>{{col.header}}</span>
                              <p-sortIcon [field]="col.field"></p-sortIcon>
                            </th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns">
                          <tr> 
                            <td headers="productName"><span style="word-wrap: break-word">{{rowData.productName}}</span></td>
                            <td headers="name"><span style="word-wrap: break-word">{{rowData.name}}</span></td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td>No data found.</td>
                          </tr>
                        </ng-template>
                </p-table>
            </div> 
            <!-- Seat Section -->
            <div class="ui-g-12" style="margin:0px;padding:0px;" *ngIf="!ShowFeatureTableSection">
              <p-table  #licenseSeatsTable [value]="licenseGroupSeats"[scrollable]="false" scrollHeight="380px"
                      [loading]="loadingLicenseSeats" sortField="activatedd" [sortOrder]="-1" [paginator]="true" [rows]="10" [globalFilterFields]="['licenseActivationId','activatedd','status','username','computerName']">
                      <ng-template pTemplate="caption">
                      </ng-template>
                      <ng-template pTemplate="header">
                        <tr>
                          <th *ngFor="let col of SeatsTableCols" id ="{{col.field}}" [pSortableColumn]="col.field"  [ngStyle]="col.style">
                            <span>{{col.header}}</span>
                          </th>
                          <th style="width: 210px;" *ngIf="app.sharedData.userInfo.isADMINorEXTL1ADMIN">
                            <div>
                              <i class="ui-icon-refresh" id="refreshCheckboxs" pTooltip="Refresh " (click)="clearDeactivate()" style="margin:4px 10px 0 0"></i>
                              <button style="width: auto;align-self: center;vertical-align:center;" id="deactivateSelectedButton" (click)="openLicenseSeatDeactivationDialog(false,null)" [disabled]="(selectedLicenseGroupSeatsToDeactivate.length==0) "  pButton type="button" label="Deactivate Selected"></button>
                            </div>
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="body" let-rowData let-columns="columns">
                        <tr>
                          <td headers="licenseActivationId"style="width: 30%;" ><span style="word-wrap: break-word;">{{rowData.licenseActivationId}}</span></td>
                          <td headers="activatedd"><span style="word-wrap: break-word">{{rowData.activatedd}}</span></td>
                          <td headers="status"><span style="word-wrap: break-word">{{rowData.status}}</span></td>
                          <td headers="username"><span style="word-wrap: break-word">{{rowData.username}}</span></td>
                          <td headers="computerName"><span style="word-wrap: break-word">{{rowData.computerName}}</span></td>
        
                          <td headers="action" style="width: 210px;" *ngIf="app.sharedData.userInfo.isADMINorEXTL1ADMIN">
                            <div *ngIf="rowData.status=='A' && rowData.licenseType!=='FACTORY'">
                              <!-- <button style="width: auto;align-self: center;vertical-align:center;" id="deactivateButton" (click)="openLicenseSeatDeactivationDialog(true,rowData)"  pButton type="button" label="Deactivate"></button> -->
                              <p-checkbox [style]="{float: 'left'}" name="selectedLicenseGroup" value="rowData" [value]="rowData" [(ngModel)]="selectedLicenseGroupSeatsToDeactivate" [inputId]="rowData.licenseActivationId"></p-checkbox>
                            </div>
                          </td>
                        
                        </tr>
                      </ng-template>
                      <ng-template pTemplate="emptymessage">
                        <tr>
                          <td>No data found.</td>
                        </tr>
                      </ng-template>
              </p-table>
            </div>
          </div>
  
          <div class="ui-g-3">
            <div class="ui-g-12">
            <h3 style="display:inline-block">Tools Covered</h3>
            <hr>
            <div class="ui-g-12" style="margin:0px;padding:0px;display:flex; align-items:center;" *ngFor="let tool of productsCovered">
                <!-- <img  style="vertical-align:middle; padding-right:30px;height: 30px" [attr.alt]="tool?.imageURL" src="{{tool?.imageURL}}" onerror="this.src='assets/images/DefaultBlueQ48.png'"> -->
                <p>{{tool?.productName}}</p>
            </div>
            </div>


            <div class="ui-g-12">
              <h3 style="display:inline-block">Details</h3>
              <hr>
              <p style="color: gray;">Serial Number: <span style="color: black;">{{details?.serialNumber}}</span></p>
              <p style="color: gray;">Company: <span style="color: black;">{{details?.company}}</span></p>
              <p style="color: gray;">Type: <span style="color: black">{{details?.licenseType}}</span></p>
              <p style="color: gray;">Total Seats: <span style="color: black;">{{details?.quantity}}</span></p>
              <p style="color: gray;">Active Seats: <span style="color: black">{{details?.activeCount}}</span></p>
              <p style="color: gray;">Start Date: <span style="color: black;">{{details?.Stardate}}</span></p>
              <p style="color: gray;">End Date: <span style="color: black">{{details?.Enddate}}</span></p>
            </div>

          </div>

        </div>
     



      <!--Deactivate license(s)-->
      <div class="card" *ngIf="licenseSeatDeactivateProcess.displayForm">
        <p-dialog header="Deactivate license(s)" [(visible)]="licenseSeatDeactivateProcess.displayForm" modal="modal" showEffect="fade" [style]="{width: '550px'}">
              <div class="ui-g" *ngIf="licenseSeatDeactivateProcess.successMessage===''">
                  <div class="ui-g-12">
                  <p> Are you sure you want to deactivate selected license(s) ?</p>
                  </div>
              </div>
              <div class="ui-g-12 message message-error" *ngIf="licenseSeatDeactivateProcess.errorMessage !== ''"  style="padding-top: 0px;">
                {{licenseSeatDeactivateProcess.errorMessage}}
              </div>
              <div class="ui-g-12 message" *ngIf="licenseSeatDeactivateProcess.successMessage !== ''"  style="padding-top: 0px;">
                {{licenseSeatDeactivateProcess.successMessage}}
              </div>
              <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                  <span  *ngIf="licenseSeatDeactivateProcess.successMessage===''">
                  <p-progressSpinner *ngIf="licenseSeatDeactivateProcess.loadingDeactivateProcess" [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
                  <!-- <button *ngIf="licenseSeatDeactivateProcess.deactivateSingle===true" pButton id="deactivateSingleLicenseSeatsYesBtn" type="button" (click)="deactivateSingleLicenseSeats(licenseSeatDeactivateProcess?.selectedSingleLicenseGroupSeat)" label="Yes" [disabled]="licenseSeatDeactivateProcess.loadingDeactivateProcess"></button> -->
                  <button *ngIf="licenseSeatDeactivateProcess.deactivateSingle===false" pButton id="deactivateMultipleLicenseSeatsYesBtn" type="button" (click)="deactivateMultipleLicenseSeats()" label="Yes" [disabled]="licenseSeatDeactivateProcess.loadingDeactivateProcess"></button>
                  <button pButton id="licenseSeatDeactivateProcessNoBtn" type="button" (click)="licenseSeatDeactivateProcess.displayForm=false" label="No" [disabled]="licenseSeatDeactivateProcess.loadingDeactivateProcess"></button>
                 </span>
                  <span  *ngIf="licenseSeatDeactivateProcess.successMessage!==''">
                    <button  pButton id="licenseSeatDeactivateProcessClose" type="button" (click)="licenseSeatDeactivateProcess.displayForm=false" label="Close" ></button>
                  </span>
                </div>
              </p-footer>
        </p-dialog>
      </div>

      <!--Subscribe and Unsubscribe Email Notification for licenseGroup-->
      <div class="card" *ngIf="emailSubscriptionProcess.displayForm">
        <p-dialog header="{{(emailSubscriptionProcess.isSubscriptionProcess? 'Subscribe':'Unsubscribe')}}" [(visible)]="emailSubscriptionProcess.displayForm" modal="modal" showEffect="fade" [style]="{width: '550px'}">
              <div class="ui-g">
                  <div class="ui-g-12" *ngIf="emailSubscriptionProcess.successMessage === ''">
                  <p *ngIf="emailSubscriptionProcess.isSubscriptionProcess"> Are you sure you want to subscribe to receive email updates for expiration notices for selected license group(s) ?</p>
                  <p *ngIf="!emailSubscriptionProcess.isSubscriptionProcess"> Are you sure you want to unsubscribe to receive email updates for expiration notices for selected license group(s)?</p>
                  </div>
              </div>
              <div class="ui-g-12 message message-error" *ngIf="emailSubscriptionProcess.errorMessage !== ''"  style="padding-top: 0px;">
                {{emailSubscriptionProcess.errorMessage}}
              </div>
              <div class="ui-g-12 message" *ngIf="emailSubscriptionProcess.successMessage !== ''"  style="padding-top: 0px;">
                {{emailSubscriptionProcess.successMessage}}
              </div>
              <p-footer>
                <div class="ui-dialog-buttonpane ui-helper-clearfix">
                  <p-progressSpinner *ngIf="emailSubscriptionProcess.loadingProcess" [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>

                  <span *ngIf="emailSubscriptionProcess.successMessage===''">
                  <button *ngIf="emailSubscriptionProcess.isSubscriptionProcess===true" pButton id="singleSubscriptionProcess" type="button" (click)="subscribeEmailNotification(app.sharedData.licenses.common.licensesGroupID)" label="Yes" [disabled]="emailSubscriptionProcess.loadingProcess"></button>
                  <button *ngIf="emailSubscriptionProcess.isSubscriptionProcess===false" pButton id="singleUnSubscriptionProcess" type="button" (click)="unSubscribeEmailNotification(app.sharedData.licenses.common.licensesGroupID)" label="Yes" [disabled]="emailSubscriptionProcess.loadingProcess"></button>
                  <button pButton id="subscriptionProcessNoBtn" type="button" (click)="resetEmailSubscriptionProcess()" label="No" [disabled]="emailSubscriptionProcess.loadingProcess"></button>
                  </span>
                  <span *ngIf="emailSubscriptionProcess.successMessage!==''">
                  <button  pButton id="emailSubscriptionProcessClose" type="button" (click)="resetEmailSubscriptionProcess()" label="Close" ></button>
                  </span>
                </div>
              </p-footer>
        </p-dialog>
      </div>
      


      </div>

  </div>
