import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QPMResponse } from 'src/app/models/response';
import { LimeResponse, RestResponse } from '../../models/response';
import isElectron from 'is-electron';
import { TelematicsClientService } from '../Contract/TelematicsClientService';
import { ServiceUtils } from '../ServiceUtils';

@Injectable()
export class TelematicsService implements TelematicsClientService{
  private telematicsClient;
  constructor(private serviceUtils : ServiceUtils) { 
    if(isElectron()){
      var lime = window.require('bindings')('addon.node');
      this.telematicsClient = new lime.TelematicsClientWrapper();
    }
  }
  
  public getVersion() : Observable<LimeResponse>  {
    var response = this.telematicsClient.getVersion();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  
  public trackEvent(eventID:string, telematicsJson:string) : Observable<LimeResponse>{
    console.log("Sending Telematics Event");
    var response = this.telematicsClient.trackEvent(eventID, telematicsJson);
    return this.serviceUtils.getObservableLimeReponse(response);
  }

}