import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QPMResponse } from 'src/app/models/response';
import { LimeResponse, RestResponse } from '../../models/response';
import isElectron from 'is-electron';
import { ClientService } from '../Contract/ClientService';
import { ServiceUtils } from '../ServiceUtils';

@Injectable()
export class LimeClientService implements ClientService{
  private limeClient;
  constructor(private serviceUtils : ServiceUtils) { 
    if(isElectron()){
      var lime = window.require('bindings')('addon.node');
      this.limeClient = new lime.LimeClientWrapper();
    }
  }
  
  public getVersion() : Observable<LimeResponse>  {
    var response = this.limeClient.getVersion();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  
  public checkLicense(productID:string, featureID:string) : Observable<LimeResponse>{
    var response = this.limeClient.checkLicense(productID, featureID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  public getLicensesByProduct(productID:string) : Observable<LimeResponse>{
    var response = this.limeClient.getLicensesByProduct(productID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  public getLicenseInfo(activationID:string) : Observable<LimeResponse>{
    var response = this.limeClient.getLicenseInfo(activationID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  public getActiveLicenseExpiryDate(productID:string, featureID:string) : Observable<LimeResponse>{
    var response = this.limeClient.getLicenseInfo(productID, featureID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }

  public getDaysRemaining(productID:string, featureID:string) : Observable<LimeResponse>{
    var response = this.limeClient.getLicenseInfo(productID, featureID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getLockState() : Observable<LimeResponse>  {
    var response = this.limeClient.getLockState();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public isProcessorARM64() : Observable<LimeResponse>  {
    var response = this.limeClient.IsProcessorARM64();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
}