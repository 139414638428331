<app-details *ngIf="app.sharedData.service.tools.loadFromTools && app.sharedData.service.tools.showDetails"></app-details>
<app-clo-details *ngIf="app.sharedData.service.tools.loadFromTools && app.sharedData.service.tools.showCloDetails"></app-clo-details>
<div id="ToolsDetailPage" [ngStyle]="{'display': app.sharedData.service.tools.loadFromTools? 'none':'flex'}" class="ui-g ui-fluid" style="min-height: calc(80vh);">
  <div class="ui-g-12">
    <div class="ui-g-12 ui-lg-3">
      <div class="card card-w-title">
        <h1 *ngIf="!showingSoftwareSource" style="display: inline-block;">Tools {{utils.getOSInfoByUserAgent()!=='Windows'?'(Beta)':''}}</h1>
        <h1 *ngIf="showingSoftwareSource" style="display: inline-block;">Software Source</h1>

        <div class="ui-inputgroup">
          <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
          <input id="ToolsSearch" pInputText type="text" placeholder="Search Tools" [disabled]="this.loadingTools" #toolsGlobalFilter (input)="toolsTable.filterGlobal($event.target.value?.trim(),'contains')">
          <i class="ui-icon-cancel" id="clearToolGlobalFilter" pTooltip="Clear Search" (click)="onFilterClear()"
          style="margin:4px 4px 0 0; cursor: pointer;"></i>        
        </div>
      </div>
      <div class="card card-w-title" *ngIf="!app.sharedData.appInfo.isElectronMode|| app.sharedData.visibility.tools.viewVisibiltyCheckbox">
        <h3 style="display: inline-block;color:lightgrey">FILTER BY</h3>
          <div class="ui-g" *ngIf="!app.sharedData.appInfo.isElectronMode">
            <h3 style="display: inline-block;">System OS</h3>
            <div class="ui-g-12">
                <p-checkbox id="windowsCheckBox" name="osGroup" value="Windows" [disabled]="this.loadingTools" (onChange)="onFilterByOs($event)" [(ngModel)]="this.app.sharedData.service.tools.filteredOSs" inputId="win"></p-checkbox>
                <label for="win">Windows</label>
            </div>
            <div class="ui-g-12">
                <p-checkbox id="linuxCheckBox" name="osGroup" value="Linux" [disabled]="this.loadingTools" (onChange)="onFilterByOs($event)" [(ngModel)]="this.app.sharedData.service.tools.filteredOSs" inputId="lnx"></p-checkbox>
                <label for="lnx">Linux</label>
            </div>
            <!-- Only show MacOS option for internal users and select customer -->
            <div class="ui-g-12" *ngIf="app.sharedData.visibility.tools.viewMacOSVisibilityCheckbox">   
                <p-checkbox id="macCheckBox" name="osGroup" value="MacOS" [disabled]="this.loadingTools" (onChange)="onFilterByOs($event)" [(ngModel)]="this.app.sharedData.service.tools.filteredOSs" inputId="mac"></p-checkbox>
                <label for="mac">MacOS</label>
            </div>
          </div>
          <div class="ui-g" *ngIf="app.sharedData.visibility.tools.viewVisibiltyCheckbox">
            <h3  style="display: inline-block;">Audience</h3>
            <div class="ui-g-12">
                <p-checkbox id="internalCheckBox" name="visibilityGroup" value="Internal" [disabled]="this.loadingTools" (onChange)="onFilterByVisibilty($event)" [(ngModel)]="this.app.sharedData.service.tools.filteredVisibility" inputId="int"></p-checkbox>
                <label for="int">Internal</label>
            </div>
            <div class="ui-g-12">
                <p-checkbox id="externalCheckBox" name="visibilityGroup" value="External" [disabled]="this.loadingTools" (onChange)="onFilterByVisibilty($event)" [(ngModel)]="this.app.sharedData.service.tools.filteredVisibility" inputId="ext"></p-checkbox>
                <label for="ext">External</label>
            </div>
          </div>
      </div>
    </div>
    <div class="ui-g-12 ui-lg-9">
      <div class="card card-w-title">  
        <div class="ui-g">
          <div class="ui-g-9">
            <div *ngIf="loadingTools">
              <h3 style="display: inline-block;">Loading Details. Please wait...</h3>
              <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
            </div>
          </div>
          <div class="ui-g-12">
            <div class="ui-g-10">
              <p-tabMenu #landingMenuItems [model]="toolsLandingMenuItems" [activeItem]="toolsLandingMenuActiveItem" (click)="onToolsLandingMenuChanged()"></p-tabMenu>
            </div>

            <div class="ui-g-2" style="text-align: right;vertical-align: top;" *ngIf="!loadingTools">                    
              <i  class="pi pi-ellipsis-v" pTooltip="Actions" (click)="moreActionMenu.toggle($event)" style="width: auto;align-self: center;vertical-align:center;font-size: 30px;"></i>
              <p-tieredMenu #moreActionMenu [model]="moreActionMenuItems" [popup]="true"></p-tieredMenu>
            </div>

            <div class="ui-g-12" style="margin:0px;padding:0px;">
              <p-treeTable id="toolsTable" *ngIf="app.sharedData.appInfo.workOffline==false" #toolsTable [value]="toolsRows" [paginator]="true" [rows]="20" 
                selectionMode="single" dataKey="displayName" sortField="displayName" [sortOrder]="1" (onFilter)="onFilterToolsTable($event)" (onNodeExpand)="onNodeExpand($event)" (onNodeSelect)="onToolRowSelect($event)"
                [globalFilterFields]="['key']">
                <ng-template pTemplate="caption">
                  <div *ngIf="loadingTools">
                    <div *ngFor="let line of errorMessage"  style="text-align: left">
                      {{line}}
                    </div>
                  </div>
                  <div style="text-align: right">
                    <i id="RefreshTools" class="ui-icon-refresh" pTooltip="Refresh" (click)="onRefreshButtonClick()" style="margin:4px 4px 0 0"></i>
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width: 12%;"></th>
                    <th style="word-break: break-all;padding-left: 42px;" id="toolDisplayName" ttSortableColumn="displayName">Name<p-treeTableSortIcon field="displayName"></p-treeTableSortIcon></th>
                    <th style="width: 15%;word-break: break-all;" id="productOS">OS</th>
                    <th style="width: 15%;word-break: break-all;" id="toolLatestRelease" *ngIf="app.sharedData.appInfo.isElectronMode">Latest Release</th>
                    <th style="width: 15%;word-break: break-all;" id="toolPublishedDate" *ngIf="app.sharedData.appInfo.isElectronMode">Published Date</th>
                    <th style="width: 15%;word-break: break-all;" id="toolInstalledVersion" *ngIf="app.sharedData.appInfo.isElectronMode">Installed Version</th>
                  </tr>
                </ng-template>

                <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                  <tr [ttRow]="rowNode" [ttSelectableRow]="rowNode">
                      <td>
                        <div style="display:flex; align-items:center;">
                          <p-treeTableToggler style="padding-right: 10px;" [rowNode]="rowNode"></p-treeTableToggler>
                          <img *ngIf="rowData.rowType=='Suite'"    style="vertical-align:middle; height: 24px; padding-right:30px;" [attr.alt]="rowData.displayName" src="{{rowData.suiteInfo?.logoHTML}}" onerror="this.src='assets/images/DefaultBlueQ48.png'">
                          <img *ngIf="rowData.rowType=='Subsuite'" style="vertical-align:middle; padding-left:20px; height: 24px; padding-right:30px;" [attr.alt]="rowData.displayName" src="{{rowData.subsuiteInfo?.logoHTML}}" onerror="this.src='assets/images/DefaultBlueQ48.png'">
                          <img *ngIf="rowData.rowType=='Product'"  style="vertical-align:middle; padding-left:20px; height: 24px; padding-right:30px;" [attr.alt]="rowData.displayName" src="{{rowData.productInfo?.logoHTML}}" onerror="this.src='assets/images/DefaultBlueQ48.png'">
                        </div>
                      </td>
                      <td>
                        <div style="display:flex; flex-direction: column-reverse;word-break: break-all; padding-left: 34px;">
                          {{rowData.displayName}}
                        </div>
                      </td>
                      <td>
                        <div *ngFor="let os of rowData.productInfo?.productOS" style="display:flex; align-items:center;word-break: break-all;">
                          {{os}}
                        </div>
                      </td>
                      <td *ngIf="app.sharedData.appInfo.isElectronMode">
                        <div style="display:flex; align-items:center;word-break: break-all;"> 
                          {{rowData.productInfo?.latestVersion}}
                        </div>
                      </td>
                      <!--td *ngIf="!app.sharedData.appInfo.isElectronMode && rowData.rowType=='Product'">{{rowData.productInfo?.latestRelease?.version}} [{{rowData.productInfo?.latestRelease?.targetOS}}]</td-->
                      <!--td *ngIf="!app.sharedData.appInfo.isElectronMode && rowData.rowType!='Product'"></td-->
                      <td *ngIf="app.sharedData.appInfo.isElectronMode">
                        <div style="display:flex; align-items:center;word-break: break-all;">
                          {{rowData.productInfo?.latestRelease?.releaseDateUTC}}
                        </div>
                      </td>
                      <td *ngIf="app.sharedData.appInfo.isElectronMode && rowData.productInfo?.installedVersion?.InstalledVersions == undefined">
                        <div style="display:flex; align-items:center;word-break: break-all;">
                          {{rowData.productInfo?.installedVersion?.Version}}
                        </div>
                      </td>
                      <td *ngIf="app.sharedData.appInfo.isElectronMode && rowData.productInfo?.installedVersion?.InstalledVersions?.length > 0" style="height: auto;">
                        <div style="display:flex; align-items:center;word-break: break-all;">
                          <span *ngFor="let version of rowData.productInfo?.installedVersion.InstalledVersions">
                            {{version.version}}
                          </span>
                        </div>
                      </td>
                  </tr>
                </ng-template>
              </p-treeTable>
          
              <p-table id="OfflineToolsTable" *ngIf="app.sharedData.appInfo.workOffline && app.sharedData.appInfo.isElectronMode" #toolsTable [value]="installedProducts" [paginator]="true" [rows]="20" dataKey="ProductName" sortField="name" [sortOrder]="-1" reorderableColumns="true" 
                selectionMode="single" (onRowSelect)="onInstalledToolRowSelect($event)"
                [globalFilterFields]="['ProductName','Name','Description','Version','InstallationDate']">

                  <ng-template pTemplate="caption">
                    <div *ngIf="!loadingTools">
                      <div *ngFor="let line of errorMessage" style="text-align: left">
                        {{line}}
                      </div>
                    </div>
                    <div style="text-align: right">
                      <i class="ui-icon-refresh" pTooltip="Refresh" (click)="refreshAllTools()" style="margin:4px 4px 0 0"></i>
                    </div>
                  </ng-template> 
                  <ng-template pTemplate="header" let-columns>
                      <tr>
                          <th style="width: 10%;"></th>
                          <th id="toolOfflineProductName" style="width: 45%;" pSortableColumn="ProductName">Name<p-sortIcon field="ProductName"></p-sortIcon></th>
                          <th id="toolOfflineProductOS" style="width: 15%;" pSortableColumn="OS">OS</th>
                          <th id="toolOfflineInstallationDate" style="width: 15%;" pSortableColumn="InstallationDate">Installation Date</th>
                          <th id="toolOfflineInstallVersion" style="width: 15%;" pSortableColumn="Version" *ngIf="app.sharedData.appInfo.isElectronMode">Installed Version</th>
                      </tr> 
                  </ng-template>
                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                          <td>
                            <div style="display:flex; align-items:center;">
                              <img [attr.alt]="rowData.ProductName" src="{{rowData.logo}}" style="padding-left:20px;" onerror="this.src='assets/images/DefaultBlueQ48.png'">
                            </div>
                          </td>
                          <td>
                            <div style="display:flex; align-items:center;">
                              {{rowData.ProductName}}
                            </div>
                          </td>
                          <td>
                            <div style="display:flex; align-items:center;">
                              {{this.app.sharedData.service.tools.selectedOSs}}
                            </div>
                          </td>
                          <td>
                            <div style="display:flex; align-items:center;">
                              {{rowData.InstallationDate}}
                            </div>
                          </td>
                          <td *ngIf="app.sharedData.appInfo.isElectronMode">
                            <div style="display:flex; align-items:center;">
                              {{rowData.Version}}
                            </div>
                          </td>
                      </tr>
                    </ng-template>
              </p-table>
            </div>
        </div>
      </div>
      <div>
        <p-dialog [(visible)]="showAddLicenseDialog" [style]="{width: '500px'}">
          <p-header>
              Add License
          </p-header>
          <div>                    
            <div  class="ui-dialog-buttonpane ui-helper-clearfix" style="display:flex; flex-direction: row; justify-content: right; align-items: right">
              <input id="LicenseGroupID" pInputText type="text" [(ngModel)]="uiLicenseGroupID" placeholder="Enter License Group ID" style="width: 300px;">
              <button id="ActivateLicenseGroupButton" pButton type="button" (click)="onActivateGroupButtonClicked($event)" label="Add" style="width: 100px;margin-left:10px;"></button>
              <button id="CancelAddLicenseButton" pButton type="button" (click)="onCancelAddLicenseClicked()" label="Cancel" style="width: 100px;margin-left:10px;"></button>
            </div>
            <p>{{licenseErrorMessage}}
              <i *ngIf="licenseErrorMessage != ''" class="ui-icon-cancel" pTooltip="Clear Message" (click)="onClearLicenseError()" style="margin:4px 4px 0 0"></i>
            </p>
          </div>
          <p-footer>

          </p-footer>
        </p-dialog>
      </div>

      <!-- Dialogs for PRODUCT-BUNDLE -->
      <div class="card" *ngIf="productsBundleProcess.displayProductBundlerDialog">
     
        <p-dialog  id="displayCategoryAppDialogHeader" header="Create Product Bundle" [(visible)]="productsBundleProcess.displayProductBundlerDialog" modal="modal" showEffect="fade" [style]="{width: '850px'}">
          <!--Top Sub-Menu-->
          <h4>Add products to create a bundle.</h4><br>
          <div class="ui-g">
            <div style="margin-bottom: 10px;" class="ui-g-12">
              <div class="ui-g-2">
                <b>System OS :</b>
                <p-dropdown id="selectOS" [options]="productsBundleProcess.OSoptionsSelectItems" (onChange)="onOSChange()" placeholder="Select OS"
                [(ngModel)]="productsBundleProcess.selectedOS" filter="true" [virtualScroll]="true" itemSize="10" [style]="{width: '95%'}" [panelStyle] = "{width:'80px'}" appendTo="body">
                <ng-template let-item pTemplate="selectedItem">
                    {{item.label}}
                </ng-template>
                <ng-template let-item pTemplate="item">
                    {{item.label}}
                </ng-template>
            </p-dropdown>
              </div>
              <div class="ui-g-4">
                <b>Product :</b>
                  <p-dropdown *ngIf="!loadingProductListbyOS" id="selectProduct" [options]="productsBundleProcess.productSelectItems" (onChange)="onProductChange()" placeholder="Select Product"
                      [(ngModel)]="productsBundleProcess.selectedProduct" filter="true" [virtualScroll]="true" itemSize="30" [style]="{width: '95%'}" [panelStyle] = "{width:'250px'}" appendTo="body">
                      <ng-template let-item pTemplate="selectedItem">
                          {{item.label}}
                      </ng-template>
                      <ng-template let-item pTemplate="item">
                          {{item.label}}
                      </ng-template>
                  </p-dropdown>
                  <div *ngIf="loadingProductListbyOS">
                    <span style="display: inline-block;">Loading Details. Please wait...</span>
                    <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                  </div>
              </div>
              <div class="ui-g-4">
                <b>Version :</b>
                  <p-dropdown *ngIf="!loadingSelectedProductDetails" id="VersionDropdwn" [options]="productsBundleProcess.versionsSelectItems" placeholder="Select Version"
                  [(ngModel)]="this.productsBundleProcess.selectedVersion" filter="true" [virtualScroll]="true" itemSize="30" [style]="{width: '95%'}" [panelStyle] = "{width:'250px'}" appendTo="body">
                  <ng-template let-item pTemplate="selectedItem">
                      {{item.label}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                      {{item.label}}
                  </ng-template>
                 </p-dropdown>
                 <div *ngIf="loadingSelectedProductDetails">
                  <span style="display: inline-block;">Loading Details. Please wait...</span>
                  <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                </div>
              </div>
              <div class="ui-g-2">
                <button pButton pRipple label="Add" icon="pi pi-plus" class="p-button-text" (click)="addProduct()"[disabled]="productsBundleProcess.selectedOS==undefined||productsBundleProcess.selectedProduct==undefined"></button>
              </div>
            </div>
          </div>
          <!--Table for Products Bundle-->
          <p-table id="ProductsBundleTable" #productsBundleTable  [value]="productsBundleProcess.productsWithVersionDetails" [paginator]="true" [rows]="5"  [globalFilterFields]="['productName','version']" sortField="productName" [sortOrder]="1">
              <ng-template pTemplate="caption">
                <div class="ui-g">
                  <div class="ui-g-12" style="padding: 0px;">
                    <div style="float: left">
                      <i class="pi pi-search"  id="searchProduct" style="margin:4px 4px 0 0"></i>
                      <input #versionGlobalFilter class="filter-clear" type="text" pInputText size="40" placeholder="Search Product Name, Version"
                      (input)="productsBundleTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
                      <i class="ui-icon-cancel" id="clearCrSearchFilter" pTooltip="Clear Filter" (click)="versionGlobalFilter.value=''; productsBundleTable.filterGlobal('', 'contains')"
                          style="margin:4px 4px 0 0;cursor:pointer"></i>
                    </div>
                    <div style="float: right;">
                      <span>   
                        <label><i class="ui-icon-file-upload" id="importJson" pTooltip="Import"  style="font-size: 1.8em; margin:4px 4px 0 0;cursor:pointer"></i>
                        <input type="file" id="file" style="display: none"  (change)="productBundlefileUpload($event)" multiple>
                        </label>
                      </span>
                      <i class="ui-icon-file-download" id="exportToJson" pTooltip="Export"  style="font-size: 1.8em; margin:4px 4px 0 0;cursor:pointer" (click)="productBundlefileExport()"></i>
                      <i class="ui-icon-refresh" pTooltip="Clear" (click)="clearProductBundle()" style="font-size: 1.8em;margin:4px 4px 0 0;cursor:pointer"></i>
                    </div>
                  </div>
                </div>
              </ng-template>
  
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td colspan="3">No data found</td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="header">
                  <tr>
                      <th pSortableColumn="productName">Product Name <p-sortIcon field="productName"></p-sortIcon></th>
                      <th pSortableColumn="version">Version <p-sortIcon field="version"></p-sortIcon></th>
                      <th style="width: 240px"><span style="vertical-align: middle">Action</span></th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                    <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.productName}}</span></td>
                        <td><span style="vertical-align: middle  ; word-wrap: break-word">{{rowData.version}}</span></td>
                        <td>
                          <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning" (click)="deleteProductFromBundle(rowData)"></button>
                        </td>
                    </tr>
                </ng-template>
          </p-table>

          <p-footer>
            <div class="ui-g">
              <div class="ui-g-12" *ngIf="this.productsBundleProcess.errorMsg !== ''" class="message message-error">
                {{productsBundleProcess.errorMsg}}
              </div>
              <div class="ui-g-12" *ngIf="this.productsBundleProcess.successMsg !== ''">
                {{productsBundleProcess.successMsg}}
              </div>
            </div>
          </p-footer>

        </p-dialog>

      </div>
    </div>
</div>