import { SelectItem, TreeNode } from 'primeng';
import { SoftwareDistroInfo, SoftwareDistroLocal } from './distribution-client';
import { SoftwareProductDistroInfo, ServiceTaskCRGerritComment, shipStatusForImage, S3FileUploadInfo } from './lime-web-client'
export class SoftwareProduct {
    softwareProduct: string;
    softwareProductFamily: boolean;
    regularSoftwareProduct: string;
    sourceSoftwareProduct: string;
    active?: boolean;
    enabledForServiceTaskInInactiveStatus?: boolean;
}

export class Customer {
    country: string;
    isActive: true;
    isInternal: true;
    isPKLA2Signed: true;
    isSuspended: true;
    name: string;
    partyId: 0;
    partyNum: 0;
    partyType: string;
}
export class SoftwareProductFamily{
    softwareProductFamily: string;
    softwareProducts: string[]
}
export class CustomerProject {
    customerProjectId: string;
    customerProject: string;
    softwareProduct: string;
    softwareProductFamily: string;
}
export class SoftwareProductRelease {
    softwareProduct: string;
    softwareProductBuild: string;
    imageBuildId?:string;
    serviceTaskId: number;
    branch: string;
    tag: string;
    spf: string;
    spfTag: string;
    buildType: string;
    shipDate: string;
    isBuild: boolean;
    isDownloaded?: boolean;
    distroDownloads?: SoftwareDistroLocal[];
    distroDownloadsNames?: string;
    distroDownloadsDisplay?: string;
    distroAvailable?:SoftwareProductDistro;
}

export class SoftwareImage{
    softwareImage: string;
    softwareImageBuild: string;
    status?: string;
    validationMsg?: string;
    valid?: boolean;
    details?: string;
    message?: string;
    deleteInProgress?: boolean;
}

export class SoftwareImageInfo{
    builtOn: string;
    location: string;
    packRecompileStatus: string;
    revision: string;
    s3Location: string
    site: string;
    softwareImage: string;
    softwareImageBuild: string;
    softwareImageId: string;
    status: string;
    submissionDate: string;
    variant: string;
}

export class MasterSTSISelection{
    imageSelected: SoftwareImage;
    imageBuilds: SelectItem[]
    imageBuildSelected: SoftwareImage;
}

export class GerritSISelection{
    softwareImage: string;
    softwareImageBuild: string;
    type: string;
    gerrits: string;
    gerritIds: string[];
    crs: string;
    crIds: string[];
}

export class SoftwareProductDistro{
    softwareDistro?: string;
    distroType?: string;
    stream?: string;
    softwareDistroAlternateId?: string;
    softwareDistroId: string;
    uploadComplete?: number;
    softwareDistroFolderName?:string;
    disabled?: boolean;
    status?:string;
    imageUploadStatus?:shipStatusForImage[];
    shippedBy?:string
    imageUploadTrackingId?:Number;
    eccn?: string;
    solutionContext?: string;
    isActive?: string;
    binDdmStatus?: Number;
    binDdmRecordSummary?:BinDdmRecordSummary;

}
export class BinDdmRecordSummary {
    noticeFileUploadStatus: number
    jiraStatus: string;
    noticeFileUrl: string;
    lostResolution: string;
    jiraUrl: string;
  }
  
export class SoftwareImageDistro{
    targetImageBuildId:string;
    distro:SoftwareProductDistro;
}
export class SoftwareDownloadProcessQueueItem{
    softwareProductBuild: SoftwareProductRelease;
    distro: SoftwareProductDistro;
    softwareProductBaseBuild?: SoftwareProductRelease;
    softwareDownloadPath?:string;
    softwareImageBuild?:string;
    
}

export class SoftwareShipProcessQueueItem{
    softwareProductBuild: string;
    targetSoftwareProductBuild?:string;
    distro: SoftwareProductDistroInfo[];
}
export class InternalClient{
    client: string;
    build_command?: string;
    is_external?: boolean;
}

export class parentServiceTask {
  serviceTasksDetails: ServiceTaskInfo;
  serviceTasksDetailsLatestReleases : ServiceTaskInfoLatestReleases;
  loadingServiceTaskDetails: boolean;
}

export class ServiceTaskInfo {
    serviceTaskId: number;
    softwareProduct: string;
    baseBuild: string;
    baseBuildTag: string;
    baseBuildSpf: string;
    baseBuildSpfTag: string;
    customerUserId: string;
    requestAppId: string;
    requestSource: string;
    requestedDate: string;
    requester: string;
    changeRequests: ChangeRequest[];
    releaseCRs?: ReleaseCR[];
    historyCRs?: HistroyCR[];
    companies?: Company[];
    createdUsingSI: boolean;
    serviceTaskImageBuilds? : SoftwareImage[];
    isGerrit: boolean;
    changes: GerritSI[];
    companyNames: string;
    companyNameList: string[];
    abandoned: boolean;
    abandonedByUid: string;
    statusString: string;
    master: boolean;
    masterString: string;
    caption:string;
    updatedCaption:string;
    primaryOwner: string;
    secondaryOwner: string;
    updatedPrimaryOwner: string;
    updatedRequestedAppid?:string;
    updatedSecondaryOwner: string;
    watchers: Watcher[];
    isWatcher: boolean;
    hasParent: boolean;
    parentServiceTaskID: number;
    parentServiceTaskLatestRelease: ServiceTaskInfoLatestReleases;
    latestRelease: string;
    totalReleases: number;
    isMaster: boolean;
    displayConfirm:boolean;
    autobuild:boolean;
    isImageFormatAddOn:boolean;
    constructor() {
        this.changeRequests = [];
        this.companies = [];
        this.serviceTaskImageBuilds = [];
        this.master = false;
        this.companyNameList = [];
        this.displayConfirm = false;
    }
};

export class Watcher{
    fullName: string;
    uid: string;
}

export class ServiceTaskInfoLatestReleases {
    allShippedCRs: number[];
    baseBuild: string;
    externalId: string;
    latestShippedCRs: number[];
    serviceTaskId: number;
    shippedBuildTag: string;
    shippedRelease: string;
    shippedReleases: number;
    shippedSpfTag: string;
    includedInReleaseCount: number;
    includedInReleaseLatestCount: number;
    constructor() {
        this.allShippedCRs = [];
        this.latestShippedCRs = [];
    }
};

export class ChangeRequest {
    changeRequest: number;
    softwareImage: string;
    softwareImageBuild: string;
    hasSoftwareImage: boolean;
    action: string;
    actionPrevious?: string;
    validationMsg: string;
    details: string;
    valid: boolean;
    status: string;
    statusDetails: string;
    acceptStatus: number;
    selectedOptions: number;
    actionInProgress: boolean;
    deleteInProgress: boolean;
    isSelected: boolean;
    includedInRelease: boolean;
    includedInReleaseLatest: boolean;
    currentInfo: ServiceTaskBuildCRCurrentInfo;
    comments: ServiceTaskCRGerritComment[];
    buildTriggered:boolean;
    validationInProgress: boolean;
    requestedDate:string;
    constructor() {
        this.buildTriggered = true
    }
}

export class ReleaseCR{
    buildCRs: number[];
    productBuild: string;
    spinNumber: number;
    buildRequestedDate: string;
}

export class HistroyCR{
    serviceTaskId: string;
    productBuild: string;
    spinNumber: number;
    consolidatedCRs: number[];
}

export class RelatedChangeRequest {
    areaName: string;
    comment: string;
    functionalityName: string;
    id: number;
    relationship: string;
    subsystemName: string;
    title: string;
    currentCR: number;
}

export class Gerrit{
    gerritId: string;
    type: string;
    softwareImage?: string;
    softwareImageBuild?: string;
    action: string;
    actionPrevious?: string;
    valid: boolean;
    status: string;
    statusDetails: string;
    acceptStatus: number;
    validationMsg: string;
    selectedOptions: number;
    actionInProgress: boolean;
    deleteInProgress: boolean;
    isSelected: boolean;
    buildTriggered: boolean;
    comments: ServiceTaskCRGerritComment[];
    requestedDate:string;
}

export class GerritSI{
    softwareImage: string;
    softwareImageBuild: string;
    errorMessage?:string='';
    originalSoftwareImageBuild: string;
    latestSoftwareImageBuild: string[];
    latestSoftwareImageBuildSelectItem: SelectItem[];
    latestSoftwareImageBuildSelected: string;
    type: string;
    gerritList: string;
    gerrits: Gerrit[];
    crList: string;
    changeRequests: ChangeRequest[];
    selectedCROptions: number;
    selectedGerritOptions: number;
    selectedCRsMap: Map<number, ChangeRequest>;
    selectedGerritsMap: Map<string, ChangeRequest>;
    isAllCrSelected: boolean;
    isAllGerritSelected: boolean;
    isCrsSelected: boolean;
    isCrUpdateAction: boolean;
    isBranched: boolean;
    isAcceptPending: boolean;
    imageAction: string;
    loadingDetails: boolean;
    loadingCrCurrentInfo: number;
    updateBaseBuildInProgress: boolean;
}

export class Company {
    partyId: number;
    partyNum: number;
    companyName: string;
}

export class ServiceTaskBuilds {
    serviceTaskId: number;
    softwareImages: ServiceTaskBuildImage[] = [];
    constructor() {
        this.softwareImages = [];
    }
}
export class ServiceTaskBuildImage {
    baseImageBuild: string;
    baseImage: string;
    cirrusId: number;
    createdAt: string;
    errorMessage: string;
    failed: boolean;
    id: number;
    servicetaskId: number;
    smServiceTaskId: string;
    changeRequestId: null;
    gerritId: string;
    buildSystem: string;
    gitBranch: string;
    gitUrl: string;
    passthrough:boolean;
    lastModifiedDate: string;
    changeRequests: ServiceTaskBuildCR[];
    gerrits: Gerrit[];
    internalClients: InternalClient[];
    internalClientSelected: InternalClient;
    updateInternalClientInProgress : boolean;
    loadingBaitBuilds: boolean;
    loadingBaitBuildDetails: number;
    loadingChangeRequests: number;
    loadingChangeRequestsCurrentInfo: number;
    loadingInternalClients: boolean;
    loadingHistory: boolean;
    loadingBranchComponents:boolean;
    retryCirrusBuildInProgress: boolean;
    recoverCirrusSeedBuildInProgress: boolean;
    buildHistoryOption: SelectItem;
    baitBuilds: ServiceTaskBaitBuild[];
    passThroughBuilds:ServiceTaskPassThroughBuild[];
    loadingPassThroughBuilds: boolean;
    loadingPassThroughBuildDetails:number;
    hlosChanges: BaitSubTasks[];
    hlosChangesCommits: BaitSubTasksCommits[];
    hlosChangesCommitsFiles: TreeNode[];
    loadHlosChanges: boolean;
    systemChanges: BaitSubTasks[];
    systemChangesCommits: BaitSubTasksCommits[];
    loadSystemChanges: boolean;
    detail: ServiceTaskBuildDetail = new ServiceTaskBuildDetail();
    constructor() {
        this.detail = new ServiceTaskBuildDetail();
        this.baitBuilds = [];
        this.passThroughBuilds=[];
        this.changeRequests = [];
        this.gerrits = [];
        this.internalClients = [];
    }
}

export class BaitSubTasks{
    id: number;
    image: string;
    subtask_type: string;
    cr: number;
    commits: BaitSubTasksCommits[];
}

export class BaitSubTasksCommits{
    message: string;
    committed_date: string;
    sha: string;
    author_name: string;
    author_email: string;
    changed_files: BaitSubTasksCommitsFiles;
}

export class BaitSubTasksCommitsFiles{
    deleted_files: string[];
    edited_files: string[];
    new_files: string[];
    renamed_files: string[];
}

export class ServiceTaskBuildDetail {
    imageBuild: string;
    status: string;
    cirrusTaskId: number;
    perforceDetails: ServiceTaskPerforceDetail = new ServiceTaskPerforceDetail();
    internalClient?: string;
    history: ServiceTaskBuildHistory[];
    builds: ServiceTaskCirrusBuild[];
    incrementalBuildSupported: boolean;
    components:CirrusComponent[];
    constructor() {
        this.perforceDetails = new ServiceTaskPerforceDetail();
        this.history = [];
        this.builds = [];
        this.components=[];
    }
}
export class ServiceTaskPerforceDetail {
    path: string;
    vcsInstance: string;
}
export class ServiceTaskBuildCR {
    buildRequestId: number;
    changeRequestId: number;
    id: number;
    state: string;
    detail: ServiceTaskBuildCRDetail;
    currentInfo: ServiceTaskBuildCRCurrentInfo;
    comments: ServiceTaskCRGerritComment[];
    changeRequestDate?:string
}
export class ServiceTaskBuildCRDetail {
    assigneeUid: string;
    changeRequestNumber: number;
    severity: string;
    status: string;
    state: string;
    title: string;
    type: string;
    area: string;
    subsystem: string;
    functionality: string;
    participants: ServiceTaskBuildCRParticipant[] = [];
}
export class ServiceTaskBuildCRCurrentInfo{
    assignee: string;
    buildDate: string;
    integrationBuild: string;
    reportedBuild: string;
    status: string;
}
export class ServiceTaskBuildCRParticipant{
    area: string;
    assigneeUid: string;
    functionality: string;
    subsystem: string;
    primary: boolean;
}
export class ServiceTaskBuildHistory {
    buildId?: string;
    actionInfo: string;
    status: string;
    comments:string;
    timestamp : number;
    date?: string;
    user?: string;
    build_url?: string;
    build?: ServiceTaskCirrusBuild;
    type?: string;
    actions: ServiceTaskBuildAction[];
}
export class ServiceTaskBuildAction{
    actionInfo: string;
    actionUrls: ServiceTaskBuildActionUrls[];
}
export class ServiceTaskBuildActionUrls{
    label: string;
    link: string;
}
export class ServiceTaskCirrusBuild {

    build_completed_timestamp: number;
    build_id: string;
    build_location: string;
    build_start_timestamp: number;
    build_type: string;
    build_url: string;
    changes: ServiceTaskCirrusBuildChange[];
    related_build: ServiceTaskCirrusBuildRelated[];
    cirrus_build_id: number;
    cirrus_job_url: string;
    debug_build_id: string;
    debug_build_location: string;
    debug_build_status: string;
    debug_build_url: string;
    failed_reason: string;
    status: string;
    requested_by: string;
    trigger_mode: string;
    build_mode: string;
    constructor(){
        related_build: [];
    }
}
export class ServiceTaskCirrusBuildChange{
    change_requests: string[];
    changelist: string;
    submitted_timestamp: number;
    changelist_url: string;
    user: string;
}
export class ServiceTaskCirrusBuildRelated{
    build_completed_timestamp: number;
    build_id: string;
    build_location: string;
    build_mode: string;
    build_start_timestamp: number;
    build_type: string;
    build_url: string;
    cirrus_build_id: number;
    cirrus_job_url: string;
    crm_build_status: string;
    failed_reason: string;
    status: string;
    trigger_mode: string;
}

export class baitLocalFileUploadImageBuild {
    build_id: string
    image_build: string
    request_date: string
  }
export class ServiceTaskBuildsForOss {
    serviceTaskId: number;
    softwareImages: ServiceTaskBaitImageForOss[] = [];
    loadingBaitImagesForOss:boolean;
    loadingBaitJobIdsForOss: number;
    loadingBaitJobDetailsForOss:number;
    constructor() {
        this.softwareImages = [];
        this.loadingBaitImagesForOss=false;
        this.loadingBaitJobIdsForOss=0;
        this.loadingBaitJobDetailsForOss=0;
    }
}
export class ServiceTaskBaitImageForOss{
    id: number;
    servicetaskId: number;
    cirrusId: number;
    smServiceTaskId: string;
    changeRequestId: null;
    gerritId: string;
    baseImageBuild: string;
    baseImage: string;//extract baseImage
    buildSystem: string;
    buildUser:string;
    errorMessage: string;
    createdAt: string;
    lastModifiedDate: string;
    gitBranch: string;
    gitUrl: string;
    failed: boolean;
    
    baitBuilds: ServiceTaskBaitBuildForOss[];
    ossLinkSet:OssLinkDetail[];
    constructor() {
        this.baitBuilds = [];
        this.ossLinkSet=[];
    }
}
export class OssLinkDetail{
    oss:string;
    build_completed_time:string;
}
export class ServiceTaskBaitBuildForOss{
    build_id: string; //Extract build_id
    request_date: string;
    baitJob: ServiceTaskBaitJob;
    constructor(){
        this.baitJob = new ServiceTaskBaitJob();
    }
}
export class ServiceTaskBaitBuild{
    isLocalfileuploadBuild:boolean=false;
    build_id: string;
    request_date: string;
    abortInProgress: boolean;
    baitJob: ServiceTaskBaitJob;
    constructor(){
        this.baitJob = new ServiceTaskBaitJob();
    }
}

export class ServiceTaskBaitJob{
    build_task_id: string;
	product_build_id: string;
	image_name: string;
	base_image_build: string;
	gerrits: string[];
	change_requests: Number[];
	user: string;
	overall_status: string;
	oss_only: boolean;
    oss:string;
    message:string;
	crm_build_info: ServiceTaskCrmBuildInfo;
    bait_build_info: ServiceTaskBaitJobInfo;
    constructor(){
        this.crm_build_info=new ServiceTaskCrmBuildInfo();
        this.bait_build_info = new ServiceTaskBaitJobInfo();
    }
}

export class BaitLocalFileUploadJobDetails {
    build_status_text: string;
    build_task_id: string;
    is_quick_ship: boolean;
    message: string;
    product_build_id: string;
    request_id: string;
    requested_at: string;
    service_build_id: string;
    submitted_at: string;
    submitted_by: string;
    crm_build_info: ServiceTaskCrmBuildInfo[];
  }

export class ServiceTaskPassThroughBuild {
    build_id: string;
    image_build?: string;
    request_date: string;
    passThroughImageBuildInfo:PassThroughImageBuildInfo;
    constructor(){
        this.passThroughImageBuildInfo = new PassThroughImageBuildInfo();
    }
  }
  export class PassThroughImageBuildInfo {
    softwareImageBuildId: number;
    softwareImageBuild: string;
    status: string;
    builtBy: string;
    builtOn: string;
    failedReason: string;
    serviceType: string;
  }

export class ServiceTaskCrmBuildInfo{
    image_family?: string;
    image_name?: string;
	image_build: string;
	job_link: string;
	status: string;
	message: string;
	build_start_time: string;
	build_completed_time: string;
	build_path: string;
}

export class ServiceTaskBaitJobInfo{
    job_link: string;
	status: string;
    message:string;
	build_start_time: string;
	build_completed_time: string;
	lost_jira: string;
	lost_jira_status: string;
	caf_links: string;
	previous_caf_links: string;
	patch_locations: ServiceTaskBaitJobPatchLocation;
    constructor(){
        this.patch_locations = new ServiceTaskBaitJobPatchLocation();
    }
}

export class ServiceTaskBaitJobPatchLocation{
    oss_patch_location: string;
    proprietary_src_location: string;
    proprietary_bin_location: string;
}

/*export class ServiceTaskCirrusBuildLocation{
    artifacts: ServiceTaskCirrusBuildLocationArtifact[];
    location: string;
    provider: string;
}
export class ServiceTaskCirrusBuildLocationArtifact{
    expiresOn: string;
    lastModifiedOn: string;
    path: string;
    size: number;
    type: string;
}*/
export class DistroShipStatus{

    distroInfo:SoftwareProductDistroInfo;
    softwareProductBuild?:string;
}
export class ServiceTaskDistProduct{
    detail: ServiceTaskDistProductDetail;
    releaseInfo: SoftwareProductRelease;
    requester: string;
    servicetaskId: number;
    productBaseBuildID: string;
    productNewBuildID: string;
    jobUrl: string;
    errorMessage: string;
    errorCode: number;
    createdAt: string;
    completed:boolean;
    crList: ServiceTaskDistProductCRs;
    consolidatedCRList: number[];
    shippedDistroList: SoftwareProductDistro[];
    availableDistroList:SoftwareProductDistro[];

    compositionLoaded: boolean;
    composition: ServiceTaskDistProductComposition[] = [];
    compositionSet: Set<String> = new Set();
    previousCompositionSet: Set<String> = new Set();
    impactedFiles: ServiceTaskDistImpactedFiles[] = [];
    loadingConsolidatedCRs: boolean;
    loadingShippedDistros: boolean;
    loadingComposition: boolean;
    loadingDetails: boolean;
    loadingImpactedFiles: boolean;
    loadingProductReleaseInfo: boolean;
    showImpactedFiles: boolean;
    isShipFailed:boolean;
    isShipComplete:boolean;
    isImageDownloadAvailable:boolean;
    showAdditionalInfoNewCRs: boolean = false;
    additionalInfoNewCRs: AdditionalCRInfo[];
    additionalInfoLoadedNewCRs: boolean = false;
    showAdditionalInfoAllCRs: boolean = false;
    additionalInfoAllCRs: AdditionalCRInfo[];
    additionalInfoLoadedAllCRs: boolean = false;
}
export class ServiceTaskDistProductDetail{
    builtBy: string;
    engineeringLabel: string;
    serviceType: string;
    softwareProductBuildId: number;
    status: string;
    failedReason?:string;
}
export class ServiceTaskDistProductComposition{
    builtOn: string;
    location: string;
    packRecompileStatus: string;
    revision: string;
    s3Location: string;
    site: string;
    softwareImage: string;
    softwareImageBuild: string;
    softwareImageId: string;
    status: string;
    submissionDate: string;
    variant: string;
    isDiffAvailable?:boolean;
    isBuildChange?:boolean;
    availableDiffBuilds?:SoftwareProductRelease[];
    availableDistros?:SoftwareProductDistro[];
    integrationSystem: string;
    passthrough:boolean;
}

export class ServiceTaskDistProductCRs{
    buildCRs: number[];
    consolidatedCRs: number[];
    buildGerrits: string[];
}
export class ServiceTaskDistImpactedFiles{
    crNumber?: number;
    gerritId?: string;
    files: ServiceTaskDistCLFiles[];
}
export class ServiceTaskDistCLFiles{
    filePath: string;
    fileName: string;
}
export class CustomerInfo{
    active: boolean;
    customerName: string;
    internal: boolean;
    partyId: number;
    partyNumber: number;
    suspended: boolean;
}

export class crFixDetail{
    crId: number;
    softwareImageBuild: string;
    fixStatus: string
    title: string;
    type: string;
    area: string;
    subsystem: string;
    functionality: string;
    softwareImageIntegratedBuild: string;
    buildType: string;
    source: string;
}

export class deltaCRInfo{
    crId:number
}

export class FileInfo{
    filename: string;
    filesize: number;
    filePath: string;
    fullFilePath: string;
    s3Key?: string;
    newFile: boolean;
    status: FileStatus; 
    statusString: string;
    validationInProgress: boolean;
    uploadInProgress: boolean;
    uploadProgress: number;
    requester?: string;
    image?: string;
    s3FileUploadInfoEntities?: S3FileUploadInfo[];
    files?: string[];
    s3fileinfo?: string[];
    requested_date?: string;
    completed_date?: string;
    loadingRelativeFilePaths?:boolean;
    LocalRelativeFilePathMatches?:SelectItem[];
    workspaceRootPath?:string;
    extraInputFilePath?:string;
    loadingWorkspaceRoot?:boolean;
    workspace_rootPathMatches?:SelectItem[];
}

export class CustomerRecords {
    companyName: string;
    partyId: number;
    srNumber: number;
}

export class AdditionalCRInfo {
    area: string;
    changeDescription: string;
    changeRequestNumber: number;
    customerRecords: Array<CustomerRecords>;
    functionality: string;
    problemDescription: string;
    problemScenario: string;
    serviceTaskId: number;
    subsystem: string;
    srNumbers?: string;
}

export enum FileStatus{
    Init = "",
    PathValidation = "Validation In Progress",
    PathValid = "Valid",
    FilenameInvalid = "InValid Filename",
    PathInvalid = "InValid",
    PathNew = "New File",
    UploadInProgress = "Upload In Progress",
    UploadComplete = "Upload Successful",
    UploadFailed = "Upload Failed",
    DuplicateFilePath = "Duplicate Path",
    DuplicateFile='Duplicate File',
    FileSizeExcced='Filesize Exceeded'
}
export class CirrusComponent{
    name:      string;
    base_name: string;
    status:    string;
    addInprogress:boolean;
    errorMsg:string;
}
export class ComponentBranchRequest {
    component_base_names: string[]
}
  
