import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import isElectron from 'is-electron';
import { Observable } from 'rxjs';
import { SharedData } from '../common/shared-data';
import { LimeResponse, LimeResponseModel, QPMResponse, RestResponse } from '../models/response';
import { ApiUrls } from './ApiUrls';
import { QLogger } from '../common/logger';
import { AuthService } from './auth.service';
import { Utils } from '../common/utils';

@Injectable()
export class ServiceUtils{
    private logSrc:string = "ServiceUtil";
    private httpOptions;
    private httpHeaders: HttpHeaders;
    private limeWebClient;
    constructor(private apiUrls : ApiUrls, private router: Router, private httpClient: HttpClient, private sharedData: SharedData, private authService: AuthService, private util: Utils){
        if(isElectron()){
            var lime = window.require('bindings')('addon.node');
            this.limeWebClient = new lime.LimeWebClientWrapper();
        }
    }

    private setHeaders(){
        if(isElectron()){
                this.httpHeaders = new HttpHeaders({ 
                  'Access-Control-Allow-Origin' : '*',
                  'Authorization' : "Bearer "+this.sharedData.userInfo.vordelToken
                })
        }
        else if(!this.util.isStringNullOrEmpty(this.authService.getIdToken())) {
                this.httpHeaders = new HttpHeaders({ 
                  'Access-Control-Allow-Origin' : '*',
                  'id_token': this.authService.getIdToken(),
                  'idtoken': this.authService.getIdToken(),
                  'CloudFront-Viewer-City-Code':(this.sharedData.userInfo.cityCode!=undefined || this.sharedData.userInfo.cityCode!=null)?this.sharedData.userInfo.cityCode:"",
                  'CloudFront-Viewer-Country':(this.sharedData.userInfo.countryCode!=undefined ||this.sharedData.userInfo.countryCode!=null )?this.sharedData.userInfo.countryCode:"",
                  'CloudFront-Viewer-Country-Region':(this.sharedData.userInfo.regionCode!=undefined || this.sharedData.userInfo.regionCode!=null)?this.sharedData.userInfo.regionCode:"",
                })            
        }
        else{
                this.httpHeaders = new HttpHeaders({ 
                  'Access-Control-Allow-Origin' : '*',
                  'vordelUser' :  this.sharedData.userInfo.username,
                  'vordel_user' : this.sharedData.userInfo.username,
                  'CloudFront-Viewer-City-Code':(this.sharedData.userInfo.cityCode!=undefined || this.sharedData.userInfo.cityCode!=null)?this.sharedData.userInfo.cityCode:"",
                  'CloudFront-Viewer-Country':(this.sharedData.userInfo.countryCode!=undefined ||this.sharedData.userInfo.countryCode!=null )?this.sharedData.userInfo.countryCode:"",
                  'CloudFront-Viewer-Country-Region':(this.sharedData.userInfo.regionCode!=undefined || this.sharedData.userInfo.regionCode!=null)?this.sharedData.userInfo.regionCode:"",
                })            
        }
        
        this.httpOptions = {headers: this.httpHeaders};

    }

    //Use for multipart/form-data contentType
    private setHeadersForFormData(filetype: boolean = false){
        let headers: HttpHeaders;
        if(isElectron()){
                this.httpHeaders = new HttpHeaders({ 
                  'Access-Control-Allow-Origin' : '*',
                  'Authorization' : "Bearer "+this.sharedData.userInfo.vordelToken
                });
        }
        else if(!this.util.isStringNullOrEmpty(this.authService.getIdToken())) {
                this.httpHeaders = new HttpHeaders({ 
                  'Access-Control-Allow-Origin' : '*',
                  'id_token': this.authService.getIdToken(),
                  'idtoken': this.authService.getIdToken(),
                  'CloudFront-Viewer-City-Code':(this.sharedData.userInfo.cityCode!=undefined || this.sharedData.userInfo.cityCode!=null)?this.sharedData.userInfo.cityCode:"",
                  'CloudFront-Viewer-Country':(this.sharedData.userInfo.countryCode!=undefined ||this.sharedData.userInfo.countryCode!=null )?this.sharedData.userInfo.countryCode:"",
                  'CloudFront-Viewer-Country-Region':(this.sharedData.userInfo.regionCode!=undefined || this.sharedData.userInfo.regionCode!=null)?this.sharedData.userInfo.regionCode:"",
                })            
        }
        else{
                this.httpHeaders =  new HttpHeaders({ 
                  'Access-Control-Allow-Origin' : '*',
                  'vordelUser' : this.sharedData.userInfo.username,
                  'vordel_user' : this.sharedData.userInfo.username,
                  'CloudFront-Viewer-City-Code':(this.sharedData.userInfo.cityCode!=undefined || this.sharedData.userInfo.cityCode!=null)?this.sharedData.userInfo.cityCode:"",
                  'CloudFront-Viewer-Country':(this.sharedData.userInfo.countryCode!=undefined ||this.sharedData.userInfo.countryCode!=null )?this.sharedData.userInfo.countryCode:"",
                  'CloudFront-Viewer-Country-Region':(this.sharedData.userInfo.regionCode!=undefined || this.sharedData.userInfo.regionCode!=null)?this.sharedData.userInfo.regionCode:"",
                });
              
        }
        if(filetype){
            this.httpHeaders.append("Content-Type", "multipart/form-data");
        }
        this.httpOptions = {header: this.httpHeaders}

    }

    getObservableLimeReponse(response:string) : Observable<LimeResponse>{
        if(response === undefined || response === ""){
            return null;
        }
        var respModel : LimeResponseModel;
        if(response.includes("DefaultKeyChain")){
            response = response.replace("DefaultKeyChain", null);
        }
        respModel = JSON.parse(response);
        var limeResp = new LimeResponse(respModel.returnCode, respModel.data, respModel.error);
        var observable = new Observable<LimeResponse>(
            observer => {
                observer.next(limeResp); 
                observer.complete();}
        );
        return observable;
    }

    getObservableRestResponseDirect(url : string) : Observable<RestResponse>{
        this.setHeaders();
        let headers= new HttpHeaders({ 
            'Access-Control-Allow-Origin' : '*',
            'vordelUser' : this.sharedData.userInfo.username,
            'vordel_user' : this.sharedData.userInfo.username,
            'Authorization' : "Bearer "+this.sharedData.userInfo.vordelToken
        })
        var observable = new Observable<RestResponse>(
            observer => {
                this.httpClient.get(url, {headers: headers, observe: "response"})
                .subscribe(
                    res=> {
                        //res.body["countryCode"]=res.headers.get('x-country-code');
                        //res.body["cityCode"]=res.headers.get('x-city-code');
                        //res.body["regionCode"]=res.headers.get('x-region-code');
                        let resp = new RestResponse(200, JSON.stringify(res.body), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        let resp = new RestResponse(error.status, "", error);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }
    getObservableRestResponseText(url : string) : Observable<RestResponse>{
        this.setHeaders();
        this.httpOptions = {
            headers: this.httpHeaders,
            responseType: 'text'
        };
        var observable = new Observable<RestResponse>(
            observer => {
                url = this.sharedData.appInfo.isElectronMode ? url : this.apiUrls.httpGetURL + url;
                this.httpClient.get(url, this.httpOptions)
                .subscribe(
                    data=> {
                        console.log(data);
                        let resp = new RestResponse(200, JSON.stringify(data), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        if(this.isInvalidToken(error) && this.sharedData.appInfo.isElectronMode){
                            this.refreshToken();
                            this.setHeaders();
                            this.httpClient.get(url, this.httpOptions)
                            .subscribe(
                                data=> {
                                    let resp = new RestResponse(200, JSON.stringify(data), null);
                                    observer.next(resp); 
                                    observer.complete();
                                },
                                error => {
                                    if(this.isInvalidToken(error)){
                                        this.logout();
                                    }
                                    console.log(error);
                                    let resp = new RestResponse(error.status, "", error);
                                    observer.next(resp); 
                                    observer.complete();
                                }
                            );
                        }
                        else{
                            let resp = new RestResponse(error.status, "", error);
                            observer.next(resp); 
                            observer.complete();
                        }
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }
    getObservableRestResponse(url : string) : Observable<RestResponse>{
        this.setHeaders();
        var observable = new Observable<RestResponse>(
            observer => {
                url = this.sharedData.appInfo.isElectronMode ? url : this.apiUrls.httpGetURL + url;
                this.httpClient.get(url, this.httpOptions)
                .subscribe(
                    data=> {
                        let resp = new RestResponse(200, JSON.stringify(data), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        if(this.isInvalidToken(error) && this.sharedData.appInfo.isElectronMode){
                            this.refreshToken();
                            this.setHeaders();
                            this.httpClient.get(url, this.httpOptions)
                            .subscribe(
                                data=> {
                                    let resp = new RestResponse(200, JSON.stringify(data), null);
                                    observer.next(resp); 
                                    observer.complete();
                                },
                                error => {
                                    if(this.isInvalidToken(error)){
                                        this.logout();
                                    }
                                    let resp = new RestResponse(error.status, "", error);
                                    observer.next(resp); 
                                    observer.complete();
                                }
                            );
                        }
                        if(this.isInvalidToken(error) && !this.sharedData.appInfo.isElectronMode){
                            window.location.reload();
                        }
                        else{
                            let resp = new RestResponse(error.status, "", error);
                            observer.next(resp); 
                            observer.complete();
                        }
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }

    deleteObservableRestResponse(url : string, body: any = null) : Observable<RestResponse>{
        this.setHeaders();
        if(body !== null){
            this.httpOptions['body'] = body;
        }
        var observable = new Observable<RestResponse>(
            observer => {
                url = this.sharedData.appInfo.isElectronMode ? url : this.apiUrls.httpDeleteURL + url;
                this.httpClient.delete(url, this.httpOptions)
                .subscribe(
                    data=> {
                        let resp = new RestResponse(200, JSON.stringify(data), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        if(this.isInvalidToken(error) && this.sharedData.appInfo.isElectronMode){
                            this.refreshToken();
                            this.setHeaders();
                            this.httpClient.get(url, this.httpOptions)
                            .subscribe(
                                data=> {
                                    let resp = new RestResponse(200, JSON.stringify(data), null);
                                    observer.next(resp); 
                                    observer.complete();
                                },
                                error => {
                                    if(this.isInvalidToken(error)){
                                        this.logout();
                                    }
                                    let resp = new RestResponse(error.status, "", error);
                                    observer.next(resp); 
                                    observer.complete();
                                }
                            );
                        }
                        else{
                            let resp = new RestResponse(error.status, "", error);
                            observer.next(resp); 
                            observer.complete();
                        }
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }

    putObservableRestResponse(url : string, body: any) : Observable<RestResponse>{
        this.setHeaders();
        var observable = new Observable<RestResponse>(
            observer => {
                url = this.sharedData.appInfo.isElectronMode ? url : this.apiUrls.httpPutURL + url;
                this.httpClient.put(url, body, this.httpOptions)
                .subscribe(
                    data=> {
                        let resp = new RestResponse(200, JSON.stringify(data), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        if(this.isInvalidToken(error) && this.sharedData.appInfo.isElectronMode){
                            this.refreshToken();
                            this.setHeaders();
                            this.httpClient.put(url, body, this.httpOptions)
                            .subscribe(
                                data=> {
                                    let resp = new RestResponse(200, JSON.stringify(data), null);
                                    observer.next(resp); 
                                    observer.complete();
                                },
                                error => {
                                    if(this.isInvalidToken(error)){
                                        this.logout();
                                    }
                                    let resp = new RestResponse(error.status, "", error);
                                    observer.next(resp); 
                                    observer.complete();
                                }
                            );
                        }
                        else{
                            let resp = new RestResponse(error.status, "", error);
                            observer.next(resp); 
                            observer.complete();
                        }
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }

    putFileObservableRestResponse(url : string, formData: FormData) : Observable<RestResponse>{
        this.httpOptions = {
            headers: new HttpHeaders({ 
              'Access-Control-Allow-Origin' : '*',
              'vordelUser' : this.sharedData.userInfo.username,
              'vordel_user' : this.sharedData.userInfo.username,
              'Authorization' : "Bearer "+this.sharedData.userInfo.vordelToken,
              'content-type': 'multipart/form-data'
            })
          };
        var observable = new Observable<RestResponse>(
            observer => {
                url = this.sharedData.appInfo.isElectronMode ? url : this.apiUrls.httpPostFileURL + url;
                this.httpClient.put(url, formData, this.httpOptions)
                .subscribe(
                    data=> {
                        let resp = new RestResponse(200, JSON.stringify(data), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        if(this.isInvalidToken(error) && this.sharedData.appInfo.isElectronMode){
                            this.refreshToken();
                            this.setHeaders();
                            this.httpClient.put(url, formData, this.httpOptions)
                            .subscribe(
                                data=> {
                                    let resp = new RestResponse(200, JSON.stringify(data), null);
                                    observer.next(resp); 
                                    observer.complete();
                                },
                                error => {
                                    if(this.isInvalidToken(error)){
                                        this.logout();
                                    }
                                    let resp = new RestResponse(error.status, "", error);
                                    observer.next(resp); 
                                    observer.complete();
                                }
                            );
                        }
                        else{
                            let resp = new RestResponse(error.status, "", error);
                            observer.next(resp); 
                            observer.complete();
                        }
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }

    postObservableRestResponse(url : string, body: any) : Observable<RestResponse>{
        this.setHeaders();
        var observable = new Observable<RestResponse>(
            observer => {
                url = this.sharedData.appInfo.isElectronMode ? url : this.apiUrls.httpPostURL + url;
                this.httpClient.post(url, body, this.httpOptions)
                .subscribe(
                    data=> {
                        let resp = new RestResponse(200, JSON.stringify(data), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        if(this.isInvalidToken(error) && this.sharedData.appInfo.isElectronMode){
                            this.refreshToken();
                            this.setHeaders();
                            this.httpClient.post(url, body, this.httpOptions)
                            .subscribe(
                                data=> {
                                    let resp = new RestResponse(200, JSON.stringify(data), null);
                                    observer.next(resp); 
                                    observer.complete();
                                },
                                error => {
                                    if(this.isInvalidToken(error)){
                                        this.logout();
                                    }
                                    let resp = new RestResponse(error.status, "", error);
                                    observer.next(resp); 
                                    observer.complete();
                                }
                            );
                        }
                        else{
                            let resp = new RestResponse(error.status, "", error);
                            observer.next(resp); 
                            observer.complete();
                        }
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }

    //Use to support multipart/form-data
    postFileObservableRestResponse(url : string, body: any) : Observable<RestResponse>{
        this.setHeadersForFormData(true);
        var observable = new Observable<RestResponse>(
            observer => {
                url = this.sharedData.appInfo.isElectronMode ? url : this.apiUrls.httpPostFileURL + url;//Making direct call to backend
                this.httpClient.post(url, body, this.httpOptions)
                .subscribe(
                    data=> {
                        alert(JSON.stringify(data))
                        let resp = new RestResponse(200, JSON.stringify(data), null);
                        observer.next(resp); 
                        observer.complete();
                        //return resp;
                    },
                    error => {
                        if(this.isInvalidToken(error) && this.sharedData.appInfo.isElectronMode){
                            this.refreshToken();
                            this.setHeaders();
                            this.httpClient.post(url, body, this.httpOptions)
                            .subscribe(
                                data=> {
                                    let resp = new RestResponse(200, JSON.stringify(data), null);
                                    observer.next(resp); 
                                    observer.complete();
                                },
                                error => {
                                    if(this.isInvalidToken(error)){
                                        this.logout();
                                    }
                                    let resp = new RestResponse(error.status, "", error);
                                    observer.next(resp); 
                                    observer.complete();
                                }
                            );
                        }
                        else{
                            let resp = new RestResponse(error.status, "", error);
                            observer.next(resp); 
                            observer.complete();
                        }
                        //return resp;
                    }
                );
            }
        );
        return observable;
    }

    isInvalidToken(error):boolean{
        let errorStr:String = JSON.stringify(error);
        if(errorStr !== undefined && errorStr !== ""){
            if((errorStr.includes("Token") && errorStr.includes("Invalid"))
                || errorStr.includes("Access Denied")){
                    return true;
            }
            else if(!this.sharedData.appInfo.isElectronMode &&error.status != undefined && error.statusText != undefined
                    && error.status != null && error.statusText != null){                
                return (error.status === 0 && error.statusText === "Unknown Error");
            }
        }
        return false;
    }

    /* Check Network Connection using ping */
    getNetworkConnectionStatus():boolean
    {   
       /* const { spawnSync } = window.require('child_process');
        const cmd = spawnSync('nslookup', ['-timeout=1 -retry=1 ',this.apiUrls.domain], { timeout: 3000, shell: true });
        console.log(cmd);
        if(cmd.error) return false; */
        //NOTE : Ping not allowed in some region. Alway get data from backend
        return true
    }

    async refreshToken(){
        QLogger.LogInfo(this.logSrc, "Refresh Token - Start");
        if(this.sharedData.appInfo.isElectronMode){
            QLogger.LogInfo(this.logSrc, "Checking existing token for validity");
            let refreshResponse= this.limeWebClient.getToken();
            this.getObservableLimeReponse(refreshResponse)
            .subscribe(
                
                (data: QPMResponse) => {
                if (data.isSuccess()) {
                        let obj = JSON.parse(data.getData());
                        let newTokenModifiedTime = obj.hostTimeUTC as string;
                        let  newTokenDate     = new Date(newTokenModifiedTime);
                        let  currentTokenDate = new Date(this.sharedData.userInfo.tokenModifiedTime);
                        if(newTokenDate.getTime() >currentTokenDate.getTime())
                        {
                            QLogger.LogInfo(this.logSrc,"Got New Token from Token File");
                            this.sharedData.userInfo.vordelToken = obj.access_token as string; 
                        }
                        else
                        {
                            let response = this.limeWebClient.refreshToken();
                            this.getObservableLimeReponse(response)
                            .subscribe(
                                (data: QPMResponse) => {
                                    if (data.isSuccess()) {
                                        QLogger.LogInfo(this.logSrc, "Refresh Token - Success");
                                        let refreshResponse= this.limeWebClient.getToken();
                                        this.getObservableLimeReponse(refreshResponse)
                                        .subscribe(
                                            (data: QPMResponse) => {
                                            if (data.isSuccess()) {
                                                    let obj = JSON.parse(data.getData());
                                                    this.sharedData.userInfo.vordelToken = obj.access_token as string; 
                                                    this.sharedData.userInfo.tokenModifiedTime = obj.hostTimeUTC as string;
                                                }
                                            }
                                        );
                                    }
                                    else{
                                        if(this.sharedData.appInfo.logResponse){
                                            QLogger.LogError(this.logSrc, "Refresh Token - Response : " +JSON.stringify(data));
                                        }
                                    }
                                }
                            );
                            
                        }
                    }
                    else
                    {
                        QLogger.LogInfo(this.logSrc,"No Token User already logged out");
                        this.sharedData.appInfo.isLoggedOut = true;
                        this.sharedData.userInfo.username ="";
                        this.sharedData.appInfo.workOffline = false;
                        this.router.navigate(['']);
                    }
                }
            );
        }
        else{

        }
    }

    logout(){
        QLogger.LogInfo(this.logSrc, "Auto Logout - Start");
        if(this.sharedData.appInfo.isElectronMode){
            if(this.sharedData.appInfo.workOffline)
            {
                this.sharedData.appInfo.workOffline = false;
                this.router.navigate(['']);
            }
            else
            {
                let response = this.limeWebClient.logout();
                this.getObservableLimeReponse(response)
                .subscribe(
                    (data: QPMResponse) => {
                        if(this.sharedData.appInfo.logResponse){
                            QLogger.LogInfo(this.logSrc, "Auto Logout - Response : " +JSON.stringify(data));
                        }
                        let errorStr = (data.isSuccess()) ? data.getData() : data.getError();
                        // Success? 
                        if (errorStr == "") {
                            QLogger.LogInfo(this.logSrc, "Auto Logout - Success");
                            this.sharedData.appInfo.isLoggedOut = true;
                            this.sharedData.userInfo.username ='';
                            this.router.navigate(['']);
                        }
                    }
                );   
            }
        }
    }
}