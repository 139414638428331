import { SoftwareProductLocal, SoftwareDistroLocal, SoftwareProductBuildLocal } from "../models/distribution-client";
import { TreeNode } from 'primeng';

export class SoftwareData {
    /*static downloadedSoftwares: SoftwareProductLocal[] = [
        {
            "productId": "Saipan.LA.1.1",
            "type":"product",
            "softwareProductBuilds": [
                {
                    "type":"build",
                    "downloadLocation": "c:/folder/file",
                    "productBuildId": "Saipan.LA.1.1.00056.PROD-1",
                    "productId": "",
                    "tag": "r00054.02.1",
                    "softwareDistros": [
                        {
                            "type":"distro",
                            "createdTime": "06/22/2019",
                            "modifiedTime": "06/22/2019",
                            "downloadLocation": "c:/folder/file",
                            "productBuildId": "",
                            "productId": "",
                            "distro": "Saipan_HLOS_Dev",
                            "tag": "",
                            "isSuccessfulDownload": true
                        },
                        {
                            "type":"distro",
                            "createdTime": "06/22/2019",
                            "modifiedTime": "06/22/2019",
                            "downloadLocation": "c:/folder/file",
                            "productBuildId": "",
                            "productId": "",
                            "distro": "Saipan_Test_Device",
                            "tag": "",
                            "isSuccessfulDownload": true
                        }
                    ]
                },
                {
                    "type":"build",
                    "downloadLocation": "c:/folder/file",
                    "productBuildId": "SM8150.LA.1.0.00059.PROD-1",
                    "productId": "",
                    "tag": "r00012.1",
                    "softwareDistros": [
                        {
                            "type":"distro",
                            "createdTime": "06/22/2019",
                            "modifiedTime": "06/22/2019",
                            "downloadLocation": "c:/folder/file",
                            "productBuildId": "",
                            "productId": "",
                            "distro": "Saipan_HLOS_Dev",
                            "tag": "",
                            "isSuccessfulDownload": true
                        },
                        {
                            "type":"distro",
                            "createdTime": "06/22/2019",
                            "modifiedTime": "06/22/2019",
                            "downloadLocation": "c:/folder/file",
                            "productBuildId": "",
                            "productId": "",
                            "distro": "Saipan_Test_Device",
                            "tag": "",
                            "isSuccessfulDownload": true
                        }
                    ]
                }
            ]
        },
        {
            "productId": "SM8250.LA.1.0",
            "type":"product",
            "softwareProductBuilds": [
                {
                    "type":"build",
                    "downloadLocation": "c:/folder/file",
                    "productBuildId": "SM8250.LA.1.0.00056.PROD-1",
                    "productId": "",
                    "tag": "r00039.1.182077.21",
                    "softwareDistros": [
                        {
                            "type":"distro",
                            "createdTime": "06/22/2019",
                            "modifiedTime": "06/22/2019",
                            "downloadLocation": "c:/folder/file",
                            "productBuildId": "",
                            "productId": "",
                            "distro": "SS_8250_HLOS_Dev",
                            "tag": "",
                            "isSuccessfulDownload": true
                        },
                        {
                            "type":"distro",
                            "createdTime": "06/22/2019",
                            "modifiedTime": "06/22/2019",
                            "downloadLocation": "c:/folder/file",
                            "productBuildId": "",
                            "productId": "",
                            "distro": "SS_8250_Test_Device",
                            "tag": "",
                            "isSuccessfulDownload": true
                        },
                        {
                            "type":"distro",
                            "createdTime": "06/22/2019",
                            "modifiedTime": "06/22/2019",
                            "downloadLocation": "c:/folder/file",
                            "productBuildId": "",
                            "productId": "",
                            "distro": "SS_8250_CAM_SDK",
                            "tag": "",
                            "isSuccessfulDownload": true
                        }
                    ]
                }
            ]
        }
    ];*/
    static downloadedBuilds: Map<string,SoftwareProductBuildLocal>;

    static availableDistros = [
        { distroName: "HLOS Dev" },
        { distroName: "Test Device" },
        { distroName: "HLOS Dev CAM-Core" },
        { distroName: "Test Device CAM-SDK" }
    ];

    static availableReleases = [
        { tag: 'r00031.1.182017.31', shipDate: '10-10-2019', isBuild: true, isShipped: false, isDownloaded: false, softwareProductBuild: "Build1" },
        { tag: 'r00031.1.182017.29', shipDate: '11-14-2019', isBuild: true, isShipped: true, isDownloaded: true, softwareProductBuild: "Build2" },
        { tag: 'r00031.1', shipDate: '12-01-2019', isBuild: true, isShipped: false, isDownloaded: false, softwareProductBuild: "Build3" },
        { tag: 'r00015.2.202020.10', shipDate: '01-20-2020', isBuild: true, isShipped: true, isDownloaded: true, softwareProductBuild: "Build4" },
        { tag: 'r00015.2.202020.5', shipDate: '01-20-2020', isBuild: true, isShipped: false, isDownloaded: false, softwareProductBuild: "Build5" },
        { tag: 'r00015.2', shipDate: '01-20-2020', isBuild: true, isShipped: true, isDownloaded: true, softwareProductBuild: "Build6" },
        { tag: 'r00016.1.2012131.15', shipDate: '03-10-2020', isBuild: true, isShipped: false, isDownloaded: false, softwareProductBuild: "Build7" },
        { tag: 'r00041.1.182017.31', shipDate: '10-10-2019', isBuild: true, isShipped: true, isDownloaded: true, softwareProductBuild: "Build8" },
        { tag: 'r00041.1.182017.29', shipDate: '11-14-2019', isBuild: true, isShipped: false, isDownloaded: false, softwareProductBuild: "Build9" },
        { tag: 'r00081.1', shipDate: '12-01-2019', isBuild: true, isShipped: true, isDownloaded: true },
        { tag: 'r00025.2.202020.10', shipDate: '01-20-2020', isBuild: true, isShipped: false, isDownloaded: false },
        { tag: 'r00025.2.202020.5', shipDate: '01-20-2020', isBuild: true, isShipped: true, isDownloaded: true },
        { tag: 'r00025.2', shipDate: '01-20-2020', isBuild: true, isShipped: false, isDownloaded: false },
        { tag: 'r00061.1.182017.29', shipDate: '11-14-2019', isBuild: true, isShipped: true, isDownloaded: true },
        { tag: 'r00061.1', shipDate: '12-01-2019', isBuild: true, isShipped: false, isDownloaded: false },
        { tag: 'r00065.2.202020.10', shipDate: '01-20-2020', isBuild: true, isShipped: true, isDownloaded: true },
        { tag: 'r00065.2.202020.5', shipDate: '01-20-2020', isBuild: true, isShipped: false, isDownloaded: false },
        { tag: 'r00065.2', shipDate: '01-20-2020', isBuild: true, isShipped: true, isDownloaded: true }
    ];

    static getDownloadedProducts() {
        let downloadedProducts = [];//SoftwareData.downloadedSoftwares;
        SoftwareData.downloadedBuilds = new Map<string, SoftwareProductBuildLocal>();
        let treeNodes: TreeNode[];
        treeNodes = [];
        downloadedProducts.forEach(product => {
            let productNode: TreeNode;
              productNode = {
                label: product.productId,
                key:  product.productId,
                data: product,
                expandedIcon: "pi pi-folder-open",
                collapsedIcon: "pi pi-folder",
                children: []
              };
              product.softwareProductBuilds.forEach(build => {
                build.productId = product.productId;
                let buildNode: TreeNode = {
                  label: build.tag,
                  key: build.productId+ build.tag,
                  data: build,
                  expandedIcon: "pi pi-folder-open",
                  collapsedIcon: "pi pi-folder",
                  children: []
                };
                build.softwareDistros.forEach(distro => {
                  distro.productId = product.productId;
                  distro.productBuildId = build.productBuildId;
                  distro.tag = build.tag;
                  buildNode.children.push({
                    label: distro.distro,
                    key: build.productId + build.tag + distro.distro,
                    data: distro,
                    icon: "pi pi-file"
                  })
                });
                //if(this.app.isElectronMode){
                    SoftwareData.downloadedBuilds.set(build.tag, build);
                //}
                productNode.children.push(buildNode);
            });
            treeNodes.push(productNode);
        })
        return treeNodes;
    }
    static getAvailableReleaseData(): any[] {

        let data = SoftwareData.availableReleases;
        let groupedData = new Map();
        let shipped: boolean;
        shipped = false;
        data.forEach(function (build) {
              build.isBuild = true;
              build.isShipped = shipped;
              //if(this.app.isElectronMode){
                if(SoftwareData.downloadedBuilds.has(build.tag)){
                  build.isDownloaded = true;
                }
                //}
              shipped = !shipped;
            let tagSplit = build.tag.split(".");
            tagSplit.pop();
            let tag = tagSplit.join(".");
            if (groupedData.has(tag)) {
                groupedData.get(tag).children.push({ data: build });
            }
            else {
                let serviceBuild = { data: { tag: tag }, children: [] };
                serviceBuild.children.push({ data: build });
                groupedData.set(tag, serviceBuild);
            }
        });

        let finalData = [];
        for (let value of groupedData.values()) {
            if (value.children.length == 1) {
                finalData.push(value.children[0]);
            }
            else {
                finalData.push(value);
            }
        }
        return finalData;
    }

}