<div id="SerialNumberDetailPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">
   
      <div class="ui-g-12 card card-w-title" style="height:100%;">
  
        <!-- Header : Online mode-->
        <div class="ui-g-12">
          <div class="ui-g-8" style="display:flex; justify-content: flex-start; height: 80px;">
            <a (click)="goBackToLicenseFind()" style="align-self: center;"><img src="assets/images/back_button.png"/></a>
            <div style="grid-column: 1;grid-row:1;">
                <h3 id="serialNumberStatus"  style="padding-left: 10px; color:grey ;margin-bottom:2px;">Serial Number:</h3>
                <h2 style=" padding-left: 10px; color: black;"><b>{{this.app.sharedData.licenses.common.serialNumber}}</b></h2>
              </div>
          </div>
    
           
          <!--For License Status -->
          <div class="ui-g-4"  style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Active'">
              <p id="ActiveStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="greendot" style="margin-right:5px;"></span><b>License Active</b></p>
            </div>
            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Purchased'">
              <p id="PurchasedStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="greendot" style="margin-right:5px;"></span><b>License Purchased</b></p>
            </div>

            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Expired'">
              <p id="ExpiredStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="reddot" style="margin-right:5px;"></span><b>License Expired</b></p>
            </div>

            <div style="grid-column: 1;grid-row:1;text-align: center" *ngIf="selectedLicense?.expiryStatus==='Expiring'">
              <p id="ExpiringStatus"  style="font-size:0.9em;margin-bottom:2px;"><span class="yellowdot" style="margin-right:5px;"></span><b>License Expiring</b></p>
              <p style="color: gray;margin-top: 3px;">on  <span style="color: gray;">{{selectedLicense?.endDate}}</span></p>
            </div>
            <div *ngIf="(app.sharedData.visibility.license.renewWorkflowForUSuser) && (selectedLicense?.serialNumber!='') && (selectedLicense?.expiryStatus==='Expiring' || selectedLicense?.expiryStatus==='Expired')" style="grid-column: 3;grid-row: 1;width: auto;align-self: center;vertical-align:center;margin-top: -15px;"> 
              <i class="pi pi-exclamation-circle" *ngIf="!selectedLicense?.isEligibleForRenewal" pTooltip="Renewal is available when: License expired less than 1 year ago or is due to expire within 90 days" [style]="{'font-size': '1.7em', 'cursor': 'pointer'}"></i>
              <button style="width: 150px;;align-self: center;vertical-align:center;" id="RenewButton" (click) = "app.openRenewDialogProcess(selectedLicense)"  [disabled]="!selectedLicense?.isEligibleForRenewal"  pButton type="button" label="Renew"></button>
              <p style="color: gray;" *ngIf="selectedLicense?.licenseType==='PURCHASE'">To purchase new licenses, please visit <br> <a style="cursor: pointer; color: blue;" href="https://cp.qti.qualcomm.com/" target="_blank">Qualcomm Sales Center</a> </p>
            </div>

            <div *ngIf="selectedLicense?.expiryStatus==='Active' && selectedLicense?.licenseType==='PURCHASE'" style="grid-column: 3;grid-row: 1;width: auto;align-self: center;vertical-align:center;margin-top: -15px;"> 
              <p style="color: gray;">To purchase new licenses, please visit <br> <a style="cursor: pointer; color: blue;" href="https://cp.qti.qualcomm.com/" target="_blank">Qualcomm Sales Center</a> </p>
            </div>

        </div>

        </div>
    
        <hr>
        <div class="ui-g-12">
          <div class="ui-inputgroup" style="margin-top: 20px;">
              <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
              <input id="ProductsSearch" pInputText type="text" placeholder="Search  tools" #licenseSerialNumbersGlobalFilter 
              (input)="currentSerialNumbersTable.filterGlobal($event.target.value,'contains');upcomingSerialNumbersTable.filterGlobal($event.target.value,'contains');expiredSerialNumbersTable.filterGlobal($event.target.value,'contains');">
              <i class="ui-icon-cancel" id="LicenceSerialNumbersGlobalFilter" pTooltip="Clear Search" style="margin:4px 4px 0 0; cursor: pointer;" (click)="onFilterClear()"></i>        
          </div>
        </div>
  
        <div class="ui-g-12">
          <div class="ui-g-10" *ngIf="loadingSerialNumberDetails">
            <h3 style="display: inline-block;">Loading Details. Please wait...</h3>
            <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
          </div>


          <div class="ui-g-12">
            <h2 style="margin-top: 10px;">Current License Groups</h2>
          </div>
          <div class="ui-g-12" style="margin-bottom: 20px;padding:0px;">
            <p-table  #currentSerialNumbersTable  [value]="expiringOrActiveSerialNumberDetails" [scrollable]="false" scrollHeight="400px"
                    [loading]="loadingSerialNumberDetails" sortField="startDate" [sortOrder]="-1" [paginator]="true" [rows]="5" [globalFilterFields]="['allProductsName']">
                    <ng-template pTemplate="caption">
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th *ngFor="let col of serialNumberTableCols" id ="{{col.field}}"  [ngStyle]="col.style">
                          <span>{{col.header}}</span>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr>
                        <td headers="allProductsName">
                          <span>
                            <p style="word-wrap: break-word;color: blue; display: inline-block;">{{rowData?.licenseID}}</p>
                            <p style="word-wrap: break-word"><b style="color: grey;">Tools : </b> {{rowData?.allProductsName}}</p>
                          </span>
                        </td>
                        <td headers="serialStatus"><span style="word-wrap: break-word">{{rowData?.serialStatus}}</span></td>
                        <td headers="purchaseDate"><span style="word-wrap: break-word">{{rowData?.purchaseDate}}</span></td>
                        <td headers="startDate"><span style="word-wrap: break-word">{{rowData?.startDate}}</span></td>
                        <td headers="endDate"><span style="word-wrap: break-word">{{rowData?.endDate}}</span></td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td>No data found.</td>
                      </tr>
                    </ng-template>
            </p-table>
          </div>


          <div class="ui-g-12">
            <h2 style=" margin-top: 10px;">Upcoming License Groups</h2>
          </div>
          <div class="ui-g-12" style="margin-bottom: 20px;padding:0px;">
            <p-table  #upcomingSerialNumbersTable [value]="purchasedSerialNumberDetails" [scrollable]="false" scrollHeight="400px"
                    [loading]="loadingSerialNumberDetails" sortField="startDate" [sortOrder]="-1" [paginator]="true" [rows]="5" [globalFilterFields]="['allProductsName']">
                    <ng-template pTemplate="caption">
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th *ngFor="let col of serialNumberTableCols" id ="{{col.field}}"  [ngStyle]="col.style">
                          <span>{{col.header}}</span>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr>
                        <td headers="allProductsName">
                          <span>
                            <p style="word-wrap: break-word;color: blue; display: inline-block;">{{rowData?.licenseID}}</p>
                            <p style="word-wrap: break-word"><b style="color: grey;">Tools : </b> {{rowData?.allProductsName}}</p>
                          </span>
                        </td>
                        <td headers="serialStatus"><span style="word-wrap: break-word">{{rowData.serialStatus}}</span></td>
                        <td headers="purchaseDate"><span style="word-wrap: break-word">{{rowData.purchaseDate}}</span></td>
                        <td headers="startDate"><span style="word-wrap: break-word">{{rowData.startDate}}</span></td>
                        <td headers="endDate"><span style="word-wrap: break-word">{{rowData.endDate}}</span></td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td>No data found.</td>
                      </tr>
                    </ng-template>
            </p-table>
          </div>


          <div class="ui-g-12">
            <h2 style=" margin-top: 10px;">Expired License Groups</h2>
          </div>
          <div class="ui-g-12" style="padding:0px;margin-bottom: 20px;">
            <p-table  #expiredSerialNumbersTable [value]="expiredSerialNumberDetails"[scrollable]="false" scrollHeight="400px"
                    [loading]="loadingSerialNumberDetails" sortField="startDate" [sortOrder]="-1" [paginator]="true" [rows]="5" [globalFilterFields]="['allProductsName']">
                    <ng-template pTemplate="caption">
                    </ng-template>
                    <ng-template pTemplate="header">
                      <tr>
                        <th *ngFor="let col of serialNumberTableCols" id ="{{col.field}}"   [ngStyle]="col.style">
                          <span>{{col.header}}</span>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr>
                        <td headers="allProductsName">
                          <span>
                            <p style="word-wrap: break-word;color: blue; display: inline-block;">{{rowData?.licenseID}}</p>
                            <p style="word-wrap: break-word"><b style="color: grey;">Tools : </b> {{rowData?.allProductsName}}</p>
                          </span>
                        </td>
                        <td headers="serialStatus"><span style="word-wrap: break-word">{{rowData.serialStatus}}</span></td>
                        <td headers="purchaseDate"><span style="word-wrap: break-word">{{rowData.purchaseDate}}</span></td>
                        <td headers="startDate"><span style="word-wrap: break-word">{{rowData.startDate}}</span></td>
                        <td headers="endDate"><span style="word-wrap: break-word">{{rowData.endDate}}</span></td>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                      <tr>
                        <td>No data found.</td>
                      </tr>
                    </ng-template>
            </p-table>
          </div>


       
        </div>
     
      </div>

  </div>
