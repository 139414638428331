<div id="ToolsDetailPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">
    <div class="ui-g-12">
      <div class="card card-w-title" style="height:100%;">
  
        <!-- Header : Online mode-->
        <div class="ui-g-12" *ngIf="!app.sharedData.appInfo.workOffline">
          <div class="ui-g-6" style="display:flex; justify-content: flex-start; height: 80px;">
            <a (click)="goBack()" style="align-self: center;"><img src="assets/images/back_button.png"/></a>
            <img id="ProductIcon" [attr.alt]="selectedProductDetails?.displayName" src={{selectedProductDetails?.logoHTML}} style="width:48px;height:48px;align-self: center;" onerror="this.src='assets/images/DefaultBlueQ48.png'">
            <h2 id="ProductDisplayName" style="align-self: center;padding-left: 10px;">{{selectedProductDetails?.displayName}}</h2>
          </div>

            <!--Go To CLO-Details-->
          <div class="ui-g-2" *ngIf="this.app.sharedData.service.tools.selectedTool.productClassification==='Clo-exec'" style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
            <div style="grid-column: 1;grid-row:1;">
              <button *ngIf="this.app.sharedData.service.tools.selectedTool.productClassification==='Clo-exec'" type="button" pButton id="CLOButton" label="Show CLO Details" [style]="{'width': '150px','margin-top': '10px'}" (click)="goToCLODetails()"></button>
            </div>
          </div>

          <!--For Installed and update-->
          <div class="ui-g-4" *ngIf="app.sharedData.appInfo.isElectronMode && selectedProductInstallDetails !== undefined" style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
            <div *ngIf="!isMultipleInstall" style="grid-column: 1;grid-row:1;">
              <p id="InstallationStatus" *ngIf="selectedProductInstallDetails?.UpdateAvailable" style="font-size:0.9em;margin-bottom:2px;"><span class="yellowdot" style="margin-right:5px;"></span><b>Update Available</b></p>
              <p id="InstallationStatus" *ngIf="!selectedProductInstallDetails?.UpdateAvailable" style="font-size:0.9em;margin-bottom:2px;"><span class="greendot" style="margin-right:5px;"></span><b>Installed</b></p>
              <p id="InstalledVersion" style="text-align:left;vertical-align:center;color:gray;font-size:0.85em;margin-top:2px;margin-bottom:2px;">{{selectedProductInstallDetails.Version}} installed on {{selectedProductInstallDetails.InstallationDate}}</p>
              <!-- <a href="{{selectedProductInstallDetails.InstallLocation}}\" style="color:blue;font-size:0.85em;margin-top:0px;margin-bottom:2px;">{{selectedProductInstallDetails.InstallLocation}}</a> -->
            </div>
            <button *ngIf="!isMultipleInstall" style="grid-column: 2;grid-row: 1;width: auto;align-self: center;vertical-align:center; margin-left: -45px;" id="openButton" pButton type="button" label="Open folder" (click)="this.installer.OpenFileInFileManager(this.selectedProductInstallDetails.InstallLocation)" ></button> 
            <div *ngIf="!isMultipleInstall" style="grid-column: 3;grid-row: 1;width: auto;align-self: center;vertical-align:center;">
              <i  class="pi pi-ellipsis-v" pTooltip="Actions" (click)="menuForInstalled.toggle($event)" style="width: auto;align-self: center;vertical-align:center;font-size: 30px;"></i>
              <p-tieredMenu #menuForInstalled [model]="menuItemsForInstalledTools" [popup]="true"></p-tieredMenu>
            </div>
            <!--
            <p id="InstallationStatus" *ngIf="selectedProductInstallDetails === undefined || (selectedProductInstallDetails != undefined && selectedProductInstallDetails?.Version != selectedVersion.version)" style="color:gray;">Status: <span class="bluedot"></span><span style="color:black;">Available</span></p>
            <p id="InstallationStatus" *ngIf="selectedProductInstallDetails != undefined && selectedProductInstallDetails?.Version === selectedVersion.version" style="color:gray;">Status: <span class="blackdot"></span><span style="color: black;">Installed</span></p>
            -->
          </div>
          <!--For Available Only-->
          <div class="ui-g-4" *ngIf="app.sharedData.appInfo.isElectronMode && selectedProductInstallDetails == undefined" style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
              <div style="grid-column: 1;grid-row:1;">
                <p id="InstallationStatus" *ngIf="selectedProductInstallDetails === undefined || (selectedProductInstallDetails != undefined && selectedProductInstallDetails?.Version != selectedVersion.version)" style="font-size:0.9em;"><span class="bluedot" style="margin-right:5px;"></span><b>Available</b></p>
                <p style="color: gray; margin-top: -12px;">Version: <span style="color: gray;">{{selectedVersion?.version}}</span></p>
              </div>
          </div>
        </div>
        <!-- Header : Offline mode-->
        <div class="ui-g-12" *ngIf="app.sharedData.appInfo.workOffline">
          <div class="ui-g-8" style="display:flex; justify-content: flex-start; height: 80px;">
            <a (click)="goBack()" style="align-self: center;"><img src="assets/images/back_button.png"/></a>
            <img id="ProductIcon" [attr.alt]="selectedProductDetails?.displayName" src={{selectedProductInstallDetails?.logo}} style="width:48px;height:48px;align-self: center;" onerror="this.src='assets/images/DefaultBlueQ48.png'">
            <h2 id="ProductDisplayName" style="align-self: center;padding-left: 10px;">{{selectedProductInstallDetails?.ProductName}}</h2>
          </div>
          <div class="ui-g-4" style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
            <div style="grid-column: 1;grid-row: 1;">
              <p id="InstalledVersion" *ngIf="selectedProductInstallDetails !== undefined;" style="text-align:center;vertical-align:center;color: gray;"><b>v{{selectedProductInstallDetails.Version}} installed</b><br><a href="{{selectedProductInstallDetails.InstallLocation}}\" style="color: blue;">{{selectedProductInstallDetails.InstallLocation}}</a></p>
            </div>
            <button style="grid-column: 2;grid-row: 1;width: auto;align-self: center;vertical-align:center;" id="UninstallButton" *ngIf="app.sharedData.appInfo.isElectronMode && selectedProductInstallDetails !== undefined " pButton type="button" label="Uninstall" (click)="onUninstallButtonClickedSingle()"></button>
          </div>
        </div>
        <!--hr style="background: linear-gradient(to top, white 20%, #A7A9A9); height:25px;border:0;margin-left:-14px;margin-right:-14px;border-top: white;"-->
        <hr>
  
        <div class="ui-g-12" style="padding-top: 50px;">
          <!-- Left column-->
          <div class="ui-g-8" style="padding-right: 50px;">
              <div *ngIf="loadingSelectedProductDetails">
                <h3 style="display: inline-block;">Loading Details. Please wait...</h3>
                <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
              </div>
              <div *ngIf="!app.sharedData.appInfo.workOffline">
                  <p style="color:gray;font-size:1.0em;">{{selectedProductDetails?.description}}</p>
                  <p id="OwnerTeam" *ngIf="app.sharedData.visibility.tools.viewOwnerTeam" style="color: gray;">Tool Owner: <a href="mailto:{{selectedProduct?.ownerTeam}}?subject={{selectedProductDetails?.displayName}} query" style="color:blue;">{{selectedProduct?.ownerTeam}}</a></p>      
              </div>
              <div *ngIf="app.sharedData.appInfo.workOffline">
                  <p style="color:gray;font-size:1.0em;">{{selectedProductInstallDetails?.Description}}</p>
              </div>

              <p-tabView>
                <p-tabPanel header="License Information" *ngIf="app.sharedData.appInfo.isElectronMode">
                  <div  *ngIf="selectedVersion != undefined && app.sharedData.appInfo.isElectronMode" style="padding-top: 20px;">
                    <div *ngIf="selectedProductDetails?.productType == 'Freemium' || selectedVersion?.installType == 'ExtractOnly'"
                    style="padding-top: .5em; padding-bottom: .5em; border-top: 1px solid grey; border-bottom: 1px solid grey;">
                      <span style="font-size: 12px">
                        No Licenses Required
                      </span>
                    </div>
                    <p-treeTable id="licenseTable" #licenseTable [value]="selectedProductLicensesRows" dataKey="licenseGroupID" (onNodeExpand)="onNodeExpand($event)"
                    *ngIf="selectedProductDetails?.productType != 'Freemium' && selectedVersion?.installType != 'ExtractOnly'">  
                      <ng-template pTemplate="header" let-columns>
                          <tr>
                              <th style="width: 10px;"></th>
                              <th id="toolLicenseGroup">License Group</th>
                              <th id="toolExpirationDate" style="width:150px;">Expiration</th>
                              <th *ngIf="!app.sharedData.appInfo.workOffline" style="width:150px;">Activated</th>
                          </tr> 
                      </ng-template>
                      <!--No licenses found when selectedProductLicensesRows?.length==0 -->
                      <ng-template pTemplate="emptymessage">
                        <tr>
                            <td id="NolicensesFoundMsg" colspan="4" *ngIf="selectedProductDetails?.productType != 'Freemium' && selectedVersion?.installType != 'ExtractOnly'">No licenses found</td>
                            <td id="NolicensesRequiredMsg" colspan="4" *ngIf="selectedProductDetails?.productType == 'Freemium' || selectedVersion?.installType == 'ExtractOnly'">No licenses required</td>
                        </tr>
                    </ng-template>
                      <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                        <!--Typical License-->
                          <tr [ttRow]="rowNode" *ngIf="rowData.licenseGroupID != undefined && selectedProductLicensesRows?.length != 0 && selectedProductDetails?.productType !== 'Freemium' && selectedVersion?.installType != 'ExtractOnly' && (rowData.licenseDetails.status == 'A' || rowData.licenseDetails.status == 'D')">
                              <td>
                                <p-treeTableToggler style="padding-right: 10px;" [rowNode]="rowNode"></p-treeTableToggler>
                              </td>
                              <td>{{rowData.licenseGroupID}}</td>
                              <td >{{rowData.licenseDetails.expirationDate}}</td>
                              <td *ngIf="!app.sharedData.appInfo.workOffline">
                                <div>
                                  <p-inputSwitch [(ngModel)]="rowData.licenseStatus" (onChange)=onLicenseStatusChanged(rowData)></p-inputSwitch>
                                </div>
                              </td>
                          </tr>
                          <!--Placeholder License Extract or Freemium-->
                          <tr [ttRow]="rowNode" *ngIf="(selectedProductDetails?.productType === 'Freemium' || selectedVersion?.installType === 'ExtractOnly')">
                            <td>
                            </td>
                            <td *ngIf="selectedProductDetails?.productType == 'Freemium' || selectedVersion?.installType == 'ExtractOnly'">No licenses required</td>
                            <td></td>
                            <td></td>
                            <td *ngIf="!app.sharedData.appInfo.workOffline"></td>
                          </tr>
                          <!--License Details Row-->
                          <tr class="license-info" [ttRow]="rowNode" *ngIf="rowData.licenseGroupID == undefined && selectedProductLicensesRows?.length != 0 && selectedProductDetails?.productType !== 'Freemium' && selectedVersion?.installType != 'ExtractOnly'">
                            <td>
                              <p-treeTableToggler style="padding-right: 10px;" [rowNode]="rowNode"></p-treeTableToggler>
                            </td>
                            <td license-info>
                              <div *ngIf="rowData.licenseDetails.status == 'A'">
                                <tr style="column-span: all;">
                                  <th style="width:25%;text-align:right;color:gray;">
                                    <p>Activated By:</p>
                                    <p>Activation Date:</p>
                                    <p>Last Verification:</p>
                                    <p>Activation ID:</p>
                                    <p>License Group ID:</p>
                                    <p>Products:</p>
                                    <p>Feature Details:</p>
                                  </th>
                                  <th style="width:75%;text-align:left;">
                                    <p>{{rowData.licenseDetails.activationKey.userId}}</p>
                                    <p>{{rowData.licenseDetails.activationKey.activatedd}}</p>
                                    <p>{{rowData.licenseDetails.serverTimeUTC}}</p>
                                    <p>{{rowData.licenseDetails.activationKey.licenseActivationId}}</p>
                                    <p>{{rowData.licenseDetails.activationKey.licenseGroupId}}</p>
                                    <p>{{rowData.productString}}</p>
                                    <p>{{rowData.featureString}}</p>
                                  </th>
                                </tr>
                              </div>
                              <div *ngIf="rowData.licenseDetails.status !== 'A'">
                                <tr style="column-span: all;">
                                  <th style="width:25%;text-align:right;color:gray;">
                                    <p>License Group ID:</p>
                                    <p>Products:</p>
                                    <p>Feature Details:</p>
                                  </th>
                                  <th style="width:75%;text-align:left;">
                                    <p>{{rowData.licenseDetails.activationKey.licenseGroupId}}</p>
                                    <p>{{rowData.productString}}</p>
                                    <p>{{rowData.featureString}}</p>
                                  </th>
                                </tr>
                              </div>
                            </td>
                            <td></td>
                            <td *ngIf="!app.sharedData.appInfo.workOffline"></td>
                          </tr>
                      </ng-template>
                    </p-treeTable>
        
                    <div *ngIf="!app.sharedData.appInfo.workOffline && this.selectedVersion?.installType != 'ExtractOnly' && selectedProductDetails?.productType != 'Freemium'" style="margin-top: 10px;">                    
                        <a *ngIf="!showAddLicense && this.selectedVersion?.installType != 'ExtractOnly' && selectedProductDetails?.productType != 'Freemium'" (click)="onAddLicenseClicked()" style="cursor: pointer"><h4 style="color: blue">+ Add License</h4></a>
                        <div *ngIf="showAddLicense" class="ui-dialog-buttonpane ui-helper-clearfix" style="display:flex; flex-direction: row; justify-content: right; align-items: right">
                          <input id="LicenseGroupID" pInputText type="text" [(ngModel)]="uiLicenseGroupID" placeholder="Enter License Group ID" style="width: 300px;">
                          <button id="ActivateLicenseGroupButton" pButton type="button" (click)="onActivateGroupButtonClicked($event)" label="Add" style="width: 100px;margin-left:10px;"></button>
                          <button id="CancelAddLicenseButton" pButton type="button" (click)="onCancelAddLicenseClicked()" label="Cancel" style="width: 100px;margin-left:10px;"></button>
                        </div>
                        <p>{{licenseErrorMessage}}
                          <i *ngIf="licenseErrorMessage != ''" class="ui-icon-cancel" pTooltip="Clear Error" (click)="onClearLicenseError()" style="margin:4px 4px 0 0"></i>
                      </p>
                    </div>
                  </div>
                </p-tabPanel>
                <p-tabPanel header="Documentation">
                    <div class="ui-g">
                      <div class="ui-g-12" *ngIf="documentList === undefined || (documentList?.docCollectionUrls === undefined && documentList?.resources === undefined) || (documentList?.resources?.length === 0 && documentList?.docCollectionUrls?.length === 0)">
                        No Documents found
                      </div>
                      <div class="ui-g-12" *ngIf="documentList?.resources?.length !== 0 || documentList?.docCollectionUrls?.length !== 0">
                        <div *ngFor="let doc of documentList?.resources">
                          <div *ngIf="doc.url !== 'NA'">
                            <p style="display: flex;justify-content: space-between;">
                              <span style="color: blue;font-weight: bold; font-size: small; float: left;">
                                <a *ngIf="this.app.sharedData.appInfo.isElectronMode" (click)="app.goToUrl(doc.url)" style="cursor: pointer; color: blue; display: inline-block;">
                                  {{doc.title}}
                                </a>
                                <a *ngIf="!this.app.sharedData.appInfo.isElectronMode" href="{{doc.url}}" target="_blank" style="cursor: pointer; color: blue; display: inline-block;">
                                  {{doc.title}}
                                </a>
                              </span> 
                              <span style="color: gray;font-size: smaller;float: right;">{{doc.publishedOn}} </span>
                            </p>
                            <p style="display: flex;">
                              <span style="color: gray;font-size: smaller;">Rev {{doc.revision}}</span> 
                              <span style="color: gray;font-size: smaller;margin-left: 30px;">{{doc.dcn}}</span>
                              <span style="color: gray; font-size: smaller;margin-left: 30px;">{{doc.contentType[0]}}</span>    
                            </p>
                            <hr>
                          </div>
                        </div>                          
                        <div *ngIf="documentList !== undefined && documentList?.docCollectionUrls !== undefined && documentList?.docCollectionUrls?.length !== 0">
                          <span style="color: blue;font-weight: bold; font-size: small; float: left;">
                            <a *ngIf="this.app.sharedData.appInfo.isElectronMode" (click)="app.goToUrl(documentList?.docCollectionUrls[0])" style="cursor: pointer; color: blue; display: inline-block;">
                              More..
                            </a>
                            <a *ngIf="!this.app.sharedData.appInfo.isElectronMode" href="{{documentList?.docCollectionUrls[0]}}" target="_blank" style="cursor: pointer; color: blue; display: inline-block;">
                              More..
                            </a>
                          </span> 
                        </div>
                      </div>
                    </div>
                </p-tabPanel>
              </p-tabView>
          </div>

          <!-- Right column-->
          <div class="ui-g-4" *ngIf="!app.sharedData.appInfo.workOffline">
            <div style="display: grid;grid-template-columns: 1fr 1fr 1fr; grid-template-rows: 40px;grid-gap: 10px;">

              <div style="grid-column: 1;grid-row: 1;">	
                <p-dropdown id="OSDropdown"  appendTo="body" [options]="osOptions" (onChange)="onOSChange()" (keyup.enter)="onOSChange()" [(ngModel)]="selectedOSId">	
                  <ng-template let-item pTemplate="selectedItem">	
                    {{item.label}}	
                  </ng-template>	
                  <ng-template let-item pTemplate="item">	
                    {{item.label}}	
                  </ng-template>	
                </p-dropdown>	
              </div>
              <div *ngIf="packageVersions.length > 0" style="grid-column: 2;grid-row: 1;">
                <p-dropdown  id="VersionDropdown" appendTo="body" [options]="packageVersions" filter="true" [virtualScroll]="true" itemSize="30" (onChange)="onVersionChange()" [(ngModel)]="selectedVersion">
                  <ng-template let-item pTemplate="selectedItem">
                    {{item.label}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{item.label}}
                  </ng-template>
                </p-dropdown>
              </div>
              <div *ngIf="packageVersions.length == 0" style="grid-column: 2;grid-row: 1;">
                <span>No packages available</span>
              </div>
              <div *ngIf="packageVersions.length > 0" style="grid-column:3; grid-row:1;">
                <p-splitButton id="installSplitButton" *ngIf="installActionOptions?.length > 1" #installSplitButton label="Install" icon="ui-icon-download" (onClick)="onInstallButtonClicked()" [model]="installActionOptions" [disabled]="installer.isInstallInProgress || platformMismatch"></p-splitButton>
                <button id="installButton" *ngIf="installActionOptions?.length === 1" pButton type="button" label="{{installActionOptions[0].label}}" (click)="installActionOptions[0].command()" style="width:120px"></button>
                <p-progressSpinner *ngIf="!app.sharedData.appInfo.isElectronMode && installer.isInstallInProgress==true" [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
              </div>
            </div>

            <div *ngIf="selectedVersion != undefined && packageVersions.length > 0">
              <h3 style="padding-top:10px;">About this version:</h3>
              <hr>
              <p style="color: gray;">Version: <span style="color: black;">{{selectedVersion.version}}</span></p>
              <p style="color: gray;">Operating System: <span style="color: black">{{getSelectedOSLabel()}}</span></p>
              <p *ngIf="selectedVersion != undefined" style="color: gray;">Release Date:  <span id="SelectedVersionReleaseDate" style="color: black;">{{selectedVersion.releaseDateUTC}}</span></p>
              <p *ngIf="selectedVersion != undefined && selectedVersion.installerSizeString != undefined" style="color: gray;">Installer Size:  <span id="SelectedVersionInstallerSize" style="color: black;">{{selectedVersion.installerSizeString}}</span></p>
              <p *ngIf="selectedVersion != undefined && selectedVersion?.releaseNotesInfo?.length > 0" (click)="downloadReleaseNotes(true)" 
                style="cursor: pointer;color: blue;">Download Release Notes {{selectedVersion.version}}</p>
            </div>
            <div *ngIf="isMultipleInstall">
              <h3 style="float:left">Installed versions:</h3>
              <div  style="float:right; margin-top: 10px;">
                <i  class="pi pi-ellipsis-v" pTooltip="Actions" (click)="menuForInstalled.toggle($event)" style="width: auto;align-self: center;vertical-align:center;font-size: 30px;"></i>
                <p-tieredMenu #menuForInstalled [model]="menuItemsForInstalledTools" [popup]="true"></p-tieredMenu>
              </div>
              <div style="clear:both;"></div>
              <hr style="padding-top:0px; margin-top:0px">
              <ul style="list-style: none;padding:0">
                <li *ngFor="let installedVersion of selectedProductInstallDetails.InstalledVersions; index as i; first as isFirst">
                  <h4 style="padding-bottom:0px; margin-bottom:0px">Version {{installedVersion.version}}</h4>
                  <p style=" color:gray;margin:0  ;padding-top:5px">Installed at <span  style="color:black;"><button class="btn-file-open" (click)="OpenFileInFileManager(installedVersion.InstallLocation)">{{installedVersion.InstallLocation}} </button></span></p>
                </li>
              </ul>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>