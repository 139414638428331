import { HttpClient } from '@angular/common/http';
import { templateJitUrl } from '@angular/compiler';
import { Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api/menuitem';
import { Observable } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { LicenseGroup, License, LicenseResponseData } from 'src/app/models/lime-web-client';
import { QPMResponse } from 'src/app/models/response';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';

@Component({
  selector: 'app-licensesfind',
  templateUrl: './licensesfind.component.html',
  styleUrls: ['./licensesfind.component.css']
})
export class LicensesfindComponent implements OnInit {
  private logSrc:string = "LicensesFind-Component";

  private catalogClient: CatalogClientService;
  private webClient : WebClientService;
  private limeClient : ClientService;
  private telematicsClient: TelematicsClientService;

  licensesLandingMenuItems: MenuItem[];
  licensesLandingMenuActiveItem: MenuItem;

  @ViewChild('licensesTable') licensesTable;
  @ViewChild('licensesGlobalFilter') licensesGlobalFilter;

  // For progress spinners
  loadingLicenses: boolean;
  cols: any[];
  licenses: License[];
  licenseResponse:LicenseResponseData[];
  licenseSerialNumberMap:Map<string,License>=new Map<string, License>();

  selectedStatus: string[] = [];
  selectedTypes: string[] = [];

  constructor(private router: Router, private activatedRoute: ActivatedRoute, 
    public app : AppMainComponent, private utils : Utils, 
    private service: DataServiceProducer, private http : HttpClient,
    public zone : NgZone) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "LicensesFind Component Initialization");
    this.licensesLandingMenuItems = [
      {label: 'Licenses' ,routerLink: ['/main/licenses/find']},
      // {label: 'Seats', routerLink: ['/main/licenses/seats']} disable for Now
    ];
    this.licensesLandingMenuActiveItem = this.licensesLandingMenuItems[0];
    this.loadingLicenses=false;
    this.cols = [];
    this.licenses = [];
    this.setLicensesTableColumn();
    this.selectedStatus=[];
    this.selectedTypes=[];
  }

  ngAfterViewInit(){

    this.getAvailableLicensesData();
  }
  ngOnDestroy(): void {
    QLogger.LogInfo(this.logSrc, "LicensesFind Component Destroy"); 
  }

  setLicensesTableColumn(){
    this.cols = [];
    this.cols.push({ field: 'serialNumber', header: 'Serial Number', isFilterable: true });
    this.cols.push({ field: 'id', header: 'License Groups', style:{'width':'35%'}, isFilterable: true});
    this.cols.push({ field: 'activeCount', header: 'Active Seats', isFilterable: true});
    this.cols.push({ field: 'licenseType', header: 'Type', isFilterable: true});
    this.cols.push({ field: 'expiryStatus', header: 'Status', isFilterable: true});
    this.cols.push({ field: 'renew', header: "", style:{'width': '210px'}});
  }

  onLicensesFilterClear() {
    this.licensesGlobalFilter.nativeElement.value = '';
    this.licensesTable?.filter('','slNumberAndLicenseGroups','contains');
  }
  
  getAvailableLicensesData(){
    QLogger.LogInfo(this.logSrc, "Get All Licenses  ");
    this.loadingLicenses = true;
    let response: Observable<QPMResponse>;
    let freeLicenses:License[];
    response = this.webClient.getLicenses();
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available  Licenses : " +JSON.stringify(data));
        } 
        if(data.getCode() === 403){
          this.router.navigate(['/main/accessdenied']);
        }
        this.licenseResponse = [];
        this.licenses=[];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Licenses  - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.licenseResponse=obj as LicenseResponseData[];
         
            freeLicenses =this.licenseResponse.filter(license=>license.serialNumber==='').map((x)=>{
              let toolNames = x.displayProductNames.split(" ").join("");  
              return {serialNumber:x.serialNumber,activeCount:x.activeCount,quantity:x.quantity,expiryStatus:x.expiryStatus,startDate:this.utils.convertDatetimestampIntoMMDDYYYY(x.startDate),endDate:this.utils.convertDatetimestampIntoMMDDYYYY(x.endDate),licenseType:x.licenseType,licenseGroups:[{id:x.id,displayProductNames:toolNames,startDate:x.startDate,endDate:x.endDate,creationDate:x.creationDate,activeCount:x.activeCount,quantity:x.quantity}],slNumberAndLicenseGroups:'NA'+','+'N/A'+','+x.id+','+toolNames}
            }) as License[];

            this.licenses=[...freeLicenses];

            //mapping serialNumber--> LicenseResponseData
            let mapSerialNumberToLicenseResponseData:Map<string,LicenseResponseData[]>=new Map<string,LicenseResponseData[]>();
            this.licenseResponse.forEach(licenseResponseData=>{
              if(licenseResponseData.serialNumber!=""){
                if(mapSerialNumberToLicenseResponseData.has(licenseResponseData.serialNumber)){
                  let tempLicenseResponsedata:LicenseResponseData[]=[];
                  tempLicenseResponsedata=mapSerialNumberToLicenseResponseData.get(licenseResponseData.serialNumber);
                  tempLicenseResponsedata.push(licenseResponseData);
                  mapSerialNumberToLicenseResponseData.set(licenseResponseData.serialNumber,tempLicenseResponsedata);
                }else{
                  let tempLicenseResponsedata:LicenseResponseData[]=[];
                  tempLicenseResponsedata.push(licenseResponseData);
                  mapSerialNumberToLicenseResponseData.set(licenseResponseData.serialNumber,tempLicenseResponsedata);
                }
              }
            })
            //mapping serialNumber--> Latest StartDate
            let mapSerialNumberToLatestStartDate:Map<string,string>=new Map<string,string>();
            this.licenseResponse.forEach(licenseResponseData=>{
              if(licenseResponseData.serialNumber!=""){
                if(mapSerialNumberToLatestStartDate.has(licenseResponseData.serialNumber)){
                  let tempStartDate=mapSerialNumberToLatestStartDate.get(licenseResponseData.serialNumber);
                  //Date-Comparision to determine Latest StartDate
                  let existingDate=new Date(tempStartDate);
                  let runningDate=new Date(licenseResponseData.startDate);

                  if(existingDate.valueOf()<runningDate.valueOf()){
                    mapSerialNumberToLatestStartDate.set(licenseResponseData.serialNumber,licenseResponseData.startDate);
                  }


                }else{
                  mapSerialNumberToLatestStartDate.set(licenseResponseData.serialNumber,licenseResponseData.startDate);
                }
              }
            
            })
            //Filter and take Only with Latest StartDate
            mapSerialNumberToLatestStartDate.forEach((v,k)=>{
              if(mapSerialNumberToLicenseResponseData.has(k)){
              let LicenseResponseDataArr=  mapSerialNumberToLicenseResponseData.get(k);
              let filteredLicenseResponseDataArr= LicenseResponseDataArr.filter((x)=>{
                let existingDate=new Date(x.startDate);
                let runningDate=new Date(v);
                if(existingDate.valueOf()===runningDate.valueOf()){
                  return true;
                }else{
                  return false;
                }
              });
              mapSerialNumberToLicenseResponseData.set(k,filteredLicenseResponseDataArr);
              }
            })
           
            mapSerialNumberToLicenseResponseData.forEach((licenseResponseDataArr,serialNumber)=>{
              let tempLicense:License=new License();
              tempLicense.serialNumber=serialNumber;
              tempLicense.licenseGroups=[];
              
              licenseResponseDataArr.forEach(data=>{
                tempLicense.activeCount=data.activeCount;
                tempLicense.expiryStatus=data.expiryStatus;
                tempLicense.quantity=data.quantity;
                tempLicense.licenseType=data.licenseType;

                let licenseGroup:LicenseGroup=new LicenseGroup();
                licenseGroup.id=data.id;
                let displayToolNames = data.displayProductNames.split(" ").join("");  
                licenseGroup.displayProductNames=displayToolNames;
                licenseGroup.creationDate=data.creationDate;
                licenseGroup.startDate=data.startDate;
                licenseGroup.endDate=data.endDate;
                licenseGroup.activeCount=data.activeCount;
                licenseGroup.quantity=data.quantity;
                tempLicense.licenseGroups.push(licenseGroup);
              })
              tempLicense.startDate=this.utils.convertDatetimestampIntoMMDDYYYY(tempLicense?.licenseGroups[0]?.startDate);
              tempLicense.endDate=this.utils.convertDatetimestampIntoMMDDYYYY(tempLicense?.licenseGroups[0]?.endDate);
              let currentDateTime=this.utils.getCurrentDateTime_24();
              if(this.utils.calculateDiffYears(currentDateTime.toString(),tempLicense?.licenseGroups[0]?.endDate) >1 ||this.utils.calculateDiffDays(tempLicense?.licenseGroups[0]?.endDate,currentDateTime.toString())>90){
                tempLicense.isEligibleForRenewal=false;
              }else{
                tempLicense.isEligibleForRenewal=true;
              }

              let valueArrayNeedToFilter = tempLicense.licenseGroups.map(lg=>{
                let displayToolsName =  lg.displayProductNames.split(" ").join("");  
                return lg.id+','+displayToolsName
               });
               valueArrayNeedToFilter.push(tempLicense.serialNumber)
               tempLicense.slNumberAndLicenseGroups=valueArrayNeedToFilter.toString();

              this.licenses.push(tempLicense);
            }) 
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Available Licenses - Failed");
          QLogger.LogError(this.logSrc, "Get Available Licenses - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingLicenses = false;

        if (this.licenses.length>0 && this.app.sharedData.licenses.common.directLinkForLicensesGroup ===true) {
          this.app.sharedData.licenses.common.selectedLicense=undefined;
         
          if(this.app.sharedData.licenses.common.serialNumber.toLocaleUpperCase()==='NA'){

            for(let license of freeLicenses){
              if(license.licenseGroups[0].id===this.app.sharedData.licenses.common.licensesGroupID){
                this.app.sharedData.licenses.common.selectedLicense=license;
                break;
              }
            }
          
          }else{
            this.app.sharedData.licenses.common.selectedLicense = this.licenses?.find(license => license.serialNumber === this.app.sharedData.licenses.common.serialNumber);
          }

          if (this.app.sharedData.licenses.common.selectedLicense != undefined) {
              this.router.navigate(['/main/licenses/licensesgroup',this.app.sharedData.licenses.common.serialNumber, this.app.sharedData.licenses.common.licensesGroupID]);
          }
          this.app.sharedData.licenses.common.directLinkForLicensesGroup = false;
        }

        if (this.licenses.length>0 && this.app.sharedData.licenses.common.directLinkForSerialNumber ===true) {
          this.app.sharedData.licenses.common.selectedLicense=undefined;
          this.app.sharedData.licenses.common.selectedLicense = this.licenses?.find(license => license.serialNumber === this.app.sharedData.licenses.common.serialNumber);

          if (this.app.sharedData.licenses.common.selectedLicense != undefined) {
            this.router.navigate(['/main/licenses/serialnumber',this.app.sharedData.licenses.common.serialNumber]);
          }
          this.app.sharedData.licenses.common.directLinkForSerialNumber = false;
        }

      });

  }
  

    
  

}
