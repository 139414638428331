import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import {Location} from '@angular/common'; 
import { Observable } from 'rxjs';
import { QPMResponse } from 'src/app/models/response';
import { License, LicenseGroup, LicenseGroupForNotification, LicenseGroupForNotificationResponse, LicenseResponseData } from 'src/app/models/lime-web-client';

@Component({
  selector: 'app-emailsubscription',
  templateUrl: './emailsubscription.component.html',
  styleUrls: ['./emailsubscription.component.css']
})
export class EmailsubscriptionComponent implements OnInit {
  private logSrc:string = "Emailsubscription-Component";
  private subscriptionSrc:string = "Emailsubscription-Component";
  private visibiltySubcription: Observable<boolean>;

  private catalogClient: CatalogClientService;
  private webClient : WebClientService;
  private limeClient : ClientService;
  private telematicsClient: TelematicsClientService;

  //#Region For Emailsubscription Table
  @ViewChild('currentlySubscribedTable') currentlySubscribedTable;
  @ViewChild('availableForSubscriptionTable') availableForSubscriptionTable;
  @ViewChild('emailsubscriptionGlobalFilter') emailsubscriptionGlobalFilter;
  loadingcurrentlySubscribedDetails:boolean;
  loadingAvailableSubscriptionDetails:boolean;
  emailSubscriptionTableCols: any[];
  currentlySubscribedLicenseGroups:LicenseGroupForNotification[];
  currentlySubscribedLicenseGroupsSet:Set<string>;
  availableToSubscribeLicenseGroups:LicenseGroupForNotification[];
  factoryTypeLicenseResponse:LicenseResponseData[];

  
  subscriptionProcess:{
    isSubscriptionProcess:boolean;
    isMultipleMode:boolean;
    displayForm: boolean,
    loadingProcess: boolean,
    errorMessage: string,
    successMessage:string;
    selectedLicenseGroupForNotification:LicenseGroupForNotification;
  }

  constructor(private router: Router, private activatedRoute: ActivatedRoute,private location: Location,
    public app : AppMainComponent, private utils : Utils, 
    private service: DataServiceProducer, private http : HttpClient,
    public zone : NgZone) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "EmailSubscription Component Initialization");
    this.loadingcurrentlySubscribedDetails=false;
    this.loadingAvailableSubscriptionDetails=false;
    this.emailSubscriptionTableCols=[];
    this.currentlySubscribedLicenseGroups=[];
    this.currentlySubscribedLicenseGroupsSet= new Set<string>();
    this.availableToSubscribeLicenseGroups=[];
    this.factoryTypeLicenseResponse=[];
    this.setEmailSubscriptionTableCols();
    this.resetSubscriptionProcess();
  }
  
  ngOnDestroy()
  { 
    this.app.sharedData.unsubscribeVisibility(this.subscriptionSrc);
  }

  ngAfterContentInit(){
    QLogger.LogInfo(this.logSrc, "EmailSubscription Component Load Page Data");
    this.visibiltySubcription = this.app.sharedData.subscribeVisibility(this.subscriptionSrc);
    this.visibiltySubcription.subscribe((updated)=>{
      if(updated){
        this.loadInitData();
      }
    });
    this.loadInitData();
  }


  setEmailSubscriptionTableCols(){
    this.emailSubscriptionTableCols = [];
    this.emailSubscriptionTableCols.push({ field: 'licensegroupUuid', header: '', isFilterable: true });
    this.emailSubscriptionTableCols.push({ field: 'allProducts', header: '', isFilterable: false });
    this.emailSubscriptionTableCols.push({ field: 'licenseType', header: '', isFilterable: false});
  }


  onFilterClear()
  {
    this.emailsubscriptionGlobalFilter.nativeElement.value = '';
    this.currentlySubscribedTable.filterGlobal('', 'contains');
    this.availableForSubscriptionTable.filterGlobal('', 'contains');
  }

  loadInitData(){    
    this.getCurrentlySubscribedLicenseGroupDetails();
  }

  getCurrentlySubscribedLicenseGroupDetails(){
    QLogger.LogInfo(this.logSrc, "Get Currently Subscribed LicenseGroup-Details")
    this.loadingcurrentlySubscribedDetails=true;
    let response: Observable<QPMResponse>;
    response = this.webClient.getCurrentlySubscribedLicenseGroup();

    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Currently Subscribed LicenseGroup-Details : " +JSON.stringify(data));
        } 
        // if(data.getCode() === 403){
        //   this.router.navigate(['/main/accessdenied']);
        // }
        this.currentlySubscribedLicenseGroups = [];
        this.currentlySubscribedLicenseGroupsSet= new Set<string>();
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Currently Subscribed LicenseGroup-Details  - Success");
          let obj = JSON.parse(data.getData());

          if (obj !== undefined || obj !== null) {
            this.currentlySubscribedLicenseGroups = [];
            this.currentlySubscribedLicenseGroupsSet= new Set<string>();
            let licenseGroupForNotificationResponses=obj as LicenseGroupForNotificationResponse[];
            licenseGroupForNotificationResponses?.forEach(lgroup=>{
             let subscribedLicenseGroup = new LicenseGroupForNotification();
             subscribedLicenseGroup.userId=lgroup.userId;
             subscribedLicenseGroup.licensegroupUuid=lgroup.licensegroupUuid;
             subscribedLicenseGroup.licenseType=lgroup.licenseType;
             subscribedLicenseGroup.expiryStatus=lgroup.expiryStatus;
             subscribedLicenseGroup.expiryDate=this.utils.convertDatetimestampIntoMMDDYYYY(lgroup.expiryDate);
             subscribedLicenseGroup.products=lgroup.products;
            
             let productNamesArr=lgroup.products.map(p=>p.productName);
             subscribedLicenseGroup.allProducts=productNamesArr.toString();
             subscribedLicenseGroup.inProgress=false;

             this.currentlySubscribedLicenseGroups.push(subscribedLicenseGroup);
             this.currentlySubscribedLicenseGroupsSet.add(lgroup.licensegroupUuid);
            });

            this.getAvailableForSubscriptionDetails();
           
          }
          
        }
        else{
            QLogger.LogError(this.logSrc, "Get Currently Subscribed LicenseGroup-Details - Failed");
            QLogger.LogError(this.logSrc, "Get Currently Subscribed LicenseGroup-Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingcurrentlySubscribedDetails = false;
    });

  }

  getAvailableForSubscriptionDetails(){
    QLogger.LogInfo(this.logSrc, "Get Available LicenseGroup-Details For Subscription")
    this.loadingAvailableSubscriptionDetails=true;
    let response: Observable<QPMResponse>;
    response = this.webClient.getLicenses();
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available LicenseGroup-Details For Subscription : " +JSON.stringify(data));
        } 
        // if(data.getCode() === 403){
        //   this.router.navigate(['/main/accessdenied']);
        // }
        this.factoryTypeLicenseResponse = [];
        this.availableToSubscribeLicenseGroups=[];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available LicenseGroup-Details For Subscription  - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            let licenseResponse=obj as LicenseResponseData[];
            let today=new Date();
            this.availableToSubscribeLicenseGroups=[];
            this.factoryTypeLicenseResponse=licenseResponse?.filter(license=>(license.licenseType==='FACTORY' && license.expiryStatus!=='Expired'));

            this.factoryTypeLicenseResponse?.forEach(license=>{
            if(this.currentlySubscribedLicenseGroupsSet.has(license.id)===false){
              let subscribedLicenseGroup = new LicenseGroupForNotification();
              subscribedLicenseGroup.userId=this.app.sharedData.userInfo.username;
              subscribedLicenseGroup.licensegroupUuid=license.id;
              subscribedLicenseGroup.licenseType=license.licenseType;
              subscribedLicenseGroup.expiryStatus=license.expiryStatus;
              subscribedLicenseGroup.expiryDate=this.utils.convertDatetimestampIntoMMDDYYYY(license.endDate);
              subscribedLicenseGroup.products=license.displayProductNames.split(',')?.map(p=>{
                return  {productName:p,productUuid:''}
              });

              let displayToolNames = license.displayProductNames.split(" ").join("");  
              subscribedLicenseGroup.allProducts=displayToolNames;
              subscribedLicenseGroup.inProgress=false;
 
              this.availableToSubscribeLicenseGroups.push(subscribedLicenseGroup);
            }
          });


        
          }
        }
        else{
          this.loadingAvailableSubscriptionDetails=false;
          QLogger.LogError(this.logSrc, "Get Available LicenseGroup-Details For Subscription - Failed");
          QLogger.LogError(this.logSrc, "Get Available LicenseGroup-Details For Subscription - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingAvailableSubscriptionDetails=false;

    });

  }

  //#region subscriptionProcess
  resetSubscriptionProcess(){
    this.subscriptionProcess={
      isSubscriptionProcess:undefined,
      isMultipleMode:undefined,
      displayForm:false,
      loadingProcess:false,
      errorMessage:'',
      successMessage:'',
      selectedLicenseGroupForNotification:null
    }
  }

  openSubscriptionProcessDialog(isSubscriptionProcess:boolean,isMultipleMode:boolean,selectedLicenseGroupForNotification?:LicenseGroupForNotification){
    if(isMultipleMode){
      if(this.availableToSubscribeLicenseGroups === undefined || this.availableToSubscribeLicenseGroups?.length === 0 || this.availableToSubscribeLicenseGroups === null){
        this.app.showMessageBox("Subscription", "Error: No licence group(s) available to subscribe.", "Ok");
        return
      }
    }
    this.resetSubscriptionProcess();
    this.subscriptionProcess.displayForm=true;
    this.subscriptionProcess.errorMessage='';
    this.subscriptionProcess.successMessage='';
    this.subscriptionProcess.loadingProcess=false;
    this.subscriptionProcess.selectedLicenseGroupForNotification=selectedLicenseGroupForNotification;
    this.subscriptionProcess.isSubscriptionProcess=isSubscriptionProcess;
    this.subscriptionProcess.isMultipleMode=isMultipleMode;
  }
 
  subscribeForEmailNotification(licenseGroupForNotification:LicenseGroupForNotification){
    QLogger.LogInfo(this.logSrc, "Subscribe Single LicensesGroup-For Email Notification");
    this.subscriptionProcess.loadingProcess = true;
    let response: Observable<QPMResponse>;
    let licenceGroupId=licenseGroupForNotification.licensegroupUuid;

    response = this.webClient.subscribeLicensegroupForEmail(licenceGroupId);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully Subscribed : " +JSON.stringify(data));
        } 
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully Subscribed - Success");
          let obj = JSON.parse(data.getData());

          if (obj !== undefined || obj !== null) {
           this.subscriptionProcess.successMessage='';
           this.subscriptionProcess.errorMessage='';
           this.subscriptionProcess.loadingProcess = false;
           //this.subscriptionProcess.successMessage=obj.errorDetails;
           this.subscriptionProcess.successMessage='Successfully subscribed to receive email updates for expiration notices for selected license group(s).'
           this.getCurrentlySubscribedLicenseGroupDetails();
           
          }
        }
        else{
            this.subscriptionProcess.successMessage='';
            this.subscriptionProcess.errorMessage='';
            this.subscriptionProcess.loadingProcess = false;
            this.subscriptionProcess.errorMessage='Error : '+data.getError() ;
            QLogger.LogError(this.logSrc, "Single LicensesGroup  Subscription   - Failed");
            QLogger.LogError(this.logSrc, "Single LicensesGroup  Subscription   - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.subscriptionProcess.loadingProcess = false;
    });
    

  }

  multipleSubscribeToAll(){
    this.availableToSubscribeLicenseGroups?.forEach(lGroupForNotification=>{
      this.subscribeForEmailNotification(lGroupForNotification);
    })

  }

  //#endregion

  //#region UnsubscriptionProcess
  unSubscribeForEmailNotification(licenseGroupForNotification:LicenseGroupForNotification){
    QLogger.LogInfo(this.logSrc, "UnSubscribe Single LicensesGroup-For Email Notification");
    this.subscriptionProcess.loadingProcess = true;
    let response: Observable<QPMResponse>;
    let licenceGroupId=licenseGroupForNotification.licensegroupUuid;

    response = this.webClient.unsubscribeLicenseGroupForEmail(licenceGroupId);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully UnSubscribed : " +JSON.stringify(data));
        } 
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Single LicensesGroup successfully UnSubscribed - Success");
          let obj = JSON.parse(data.getData());

          if (obj !== undefined || obj !== null) {
           this.subscriptionProcess.successMessage='';
           this.subscriptionProcess.errorMessage='';
           this.subscriptionProcess.loadingProcess = false;
           //this.subscriptionProcess.successMessage=obj.errorDetails
           this.subscriptionProcess.successMessage='Successfully unsubscribed to receive email updates for expiration notices for selected license group(s).'
           this.getCurrentlySubscribedLicenseGroupDetails();
           
          }
        }
        else{
            this.subscriptionProcess.successMessage='';
            this.subscriptionProcess.errorMessage='';
            this.subscriptionProcess.loadingProcess = false;
            this.subscriptionProcess.errorMessage='Error : '+data.getError() ;
            QLogger.LogError(this.logSrc, "Single LicensesGroup  UnSubscription   - Failed");
            QLogger.LogError(this.logSrc, "Single LicensesGroup  UnSubscription   - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.subscriptionProcess.loadingProcess = false;
    });
    

  }

  multipleUnSubscribeToAll(){
    //If required in future
  }
  //#endregion

  goToLicenseGroupDetailsFromSubscriptionPage(licenceGroupId:string,licenseGroupForNotification:LicenseGroupForNotification){
    let license = new License();
    license.serialNumber='';
    license.licenseType=licenseGroupForNotification.licenseType;
    license.expiryStatus=licenseGroupForNotification.expiryStatus;
    license.isEligibleForRenewal=false;
    license.slNumberAndLicenseGroups='NA'+','+'N/A'+','+licenseGroupForNotification.licensegroupUuid+','+licenseGroupForNotification.allProducts;
    //Default values
    license.activeCount=0;
    license.quantity=0;
    license.startDate='';
    license.endDate=licenseGroupForNotification.expiryDate;
    license.licenseGroups=[];
    //licenseGroup Details
    let licenseGroup = new LicenseGroup();
    licenseGroup.id=licenseGroupForNotification.licensegroupUuid;
    licenseGroup.displayProductNames=licenseGroupForNotification.allProducts;
    licenseGroup.creationDate='';
    licenseGroup.startDate='';
    licenseGroup.endDate=licenseGroupForNotification.expiryDate;
    license.licenseGroups.push(licenseGroup);
    QLogger.LogInfo(this.logSrc,"Redirection from Email Subscription page to License Group Details Page");
    QLogger.LogInfo(this.logSrc,"Selected Serial Number: " + license.serialNumber!=''?license.serialNumber:'NA'+ " |  Selected license Group: " + licenceGroupId);//Need to handle empty SL-Number
    this.app.goToLicenseGroupDetailsPage(licenceGroupId,license);

  }
 

}
