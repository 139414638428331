import { Injectable } from '@angular/core';

 export interface QPMResponse
{
    isSuccess():boolean;
    getCode():number;
    getData():string;
    getError():string;
    getErrorDetail():string;
}

export class LimeResponseModel
{
    returnCode:number;
    data:string;
    error:LimeErrorResponse;
}

export class LimeResponse implements QPMResponse
{
    returnCode:number;
    data:string;
    error:LimeErrorResponse;
    
    constructor(returnCode:number, data:string, error:LimeErrorResponse){
        this.returnCode = returnCode;
        this.data = data;
        this.error = error;
        if(this.error !== undefined && this.error !== null){
            try{
                this.error.errorDetailServer = (this.error.errorDetails !== undefined && this.error.errorDetails !== null
                                                && this.error.errorDetails !== "") ? JSON.parse(this.error.errorDetails) : undefined;
            }
            catch(error){}
        }
    }
    
    isSuccess(){
        if(this.returnCode == 0){
            return true;
        }
        return false;
    }
    getCode(){
        return this.returnCode;
    }
    getData(){
        return (this.data !== undefined && this.data !== null) ? this.data : "";
    }
    getError(){
        if((this.error !== undefined && this.error !== null)){
            if(this.error.errorDetailServer !== undefined && this.error.errorDetailServer !== null){
                return (this.error.errorDetailServer.errorMessage !== undefined 
                    && this.error.errorDetailServer.errorMessage !== null)?
                    this.error.errorDetailServer.errorMessage : this.error.errorDetailServer.errorMsg;
                
            }
            else{
                return this.error.errorMessage;
            }
        }
        return "";
    }
    getErrorDetail(){
        if((this.error !== undefined && this.error !== null)){
            if(this.error.errorDetailServer !== undefined && this.error.errorDetailServer !== null){
                return (this.error.errorDetailServer.errorDetails !== undefined 
                    && this.error.errorDetailServer.errorDetails !== null)?
                    this.error.errorDetailServer.errorDetails : this.error.errorDetailServer.errorDescription;
                
            }
            else if(this.error.errorDetails !== undefined && this.error.errorDetails !== null){
                return this.error.errorDetails;                
            }
            else{
                return this.error.errorMessage;
            }
        }
        return "";
    }
}

export class LimeErrorResponse 
{
    errorId: string;
    errorCode: string;
    errorMessage: string;
    errorDetails: string;
    errorDetailServer: LimeServerErrorResponse;
}
export class LimeServerErrorResponse
{
    errorCode: number;
    errorMessage: string;
    errorDetails: string;

    //Vordel structure
    error: string;
	errorDescription: string;
	errorMsg: string;
}

export class RestResponse implements QPMResponse
{
    returnCode:number;
    data:string;
    error:RestErrorResponse;
    constructor(returnCode:number, data:string, error:RestErrorResponse){
        this.returnCode = returnCode;
        this.data = data;
        this.error = error;
    }

    isSuccess(){
        if(this.returnCode == 200){
            return true;
        }
        return false;
    }
    getCode(){
        return this.returnCode;
    }
    getData(){
        return (this.data !== undefined && this.data !== null) ? this.data : "";
    }
    getError(){
        if(this.error !== undefined && this.error !== null){
            if(this.error.error !== undefined && this.error.error !== null){
                if(this.error.error.errorMessage !== undefined && this.error.error.errorMessage !== null){
                    return this.error.error.errorMessage;
                }
                else if(this.error.error.error !== undefined && this.error.error.error !== null){
                    return this.error.error.error;
                }
                else if(this.error.error.message !== undefined && this.error.error.message !== null){
                    return this.error.error.error;
                }
                else{
                    return this.error.statusText;
                }
            }
            else{
                return this.error.message;
            }
        }
        else{
            return "";
        }
    }
    getErrorDetail(){
        if(this.error !== undefined && this.error !== null){
            return (this.error.error !== undefined && this.error.error !== null 
                    && this.error.error.errorDetails !== undefined && this.error.error.errorDetails !== null) 
                ? this.error.error.errorDetails : JSON.stringify(this.error);
        }
        else{
            return "";
        }
    }
}

export class RestErrorResponse 
{
    error: RestServerErrorResponse;
    errorId:string;
    errorCode:string;
    errorMessage:string;
    errorDetails:string;
    message:string;
    statusText: string;
}

export class RestServerErrorResponse 
{
    errorId:string;
    errorCode:string;
    errorMessage:string;
    errorDetails:string;
    status: number;
    error: string;
    message: string;
}