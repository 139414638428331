import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';


@Component({
  selector: 'app-licensesseat',
  templateUrl: './licensesseat.component.html',
  styleUrls: ['./licensesseat.component.css']
})
export class LicensesseatComponent implements OnInit {

  private logSrc:string = "Licenses-Seat-Component";

  private catalogClient: CatalogClientService;
  private webClient : WebClientService;
  private limeClient : ClientService;
  private telematicsClient: TelematicsClientService;

  seatsLandingMenuItems: MenuItem[];
  seatsLandingMenuActiveItem: MenuItem;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, 
    public app : AppMainComponent, private utils : Utils, 
    private service: DataServiceProducer, private http : HttpClient,
    public zone : NgZone) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "Licenses-Seat Component Initialization");
    this.seatsLandingMenuItems = [
      {label: 'Licenses' ,routerLink: ['/main/licenses/find']},
      {label: 'Seats', routerLink: ['/main/licenses/seats']}
    ];
    this.seatsLandingMenuActiveItem = this.seatsLandingMenuItems[1];
  }

  ngAfterViewInit(){

  }
  ngOnDestroy(): void {
    QLogger.LogInfo(this.logSrc, "Licenses-Seat Component Destroy"); 
  }
}
