import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { String, StringBuilder } from 'typescript-string-operations';
import { QPMResponse, RestResponse } from 'src/app/models/response';
import { ServiceUtils } from '../ServiceUtils';
import { WebClientService } from '../Contract/WebClientService';
import { ApiUrls } from '../ApiUrls';
import { PaymentIntentRequest, ServiceTaskRequest, UpdateServiceTaskRequest, TriggerBuildRequest, ValidateCRRequest, TriggerProductBuildRequest, DistributionRequest,MasterTriggerBuildRequest,DiffDistributionRequest, AutoShipRequest, AutoShipRequestSP, Email, TriggerBaitBuildRequest, TriageDeleteCrRequest, TriageDeleteGerritRequest, CrGerritComment, JiraTicketRequest ,MemberUpdateRequest,ImageBuildDistributionRequest, CrFixDetailRequest, CveCrMappingRequest, AutoPropagationRequest, FilePathValidationRequest, LocalFileUploadRequest, SearchUpdateCrFixDetailRequest,JiraFilesUploadRequest, RemoteAutoCommitRequest, PricingDetailsRequest, LicenseSeatDeactivateRequest, LocalFileUploadInitiateRequest, s3FileUploadContinueRequest, LocalFileUploadCompleteRequest, BaitSystemTriggerBuildRequest, BaitFileUploadCompleteRequest, BaitFileUploadInitRequest, S3UploadCompleteRequest, LocalFileUploadSessionStatus, LocalFileUploadSessionRequest, FilePathSearchRequest, LocalFilesDeleteRequest, FilePathSearchRequestForBait} from 'src/app/models/lime-web-client';
import { SoftwareImage } from 'src/app/models/software-catalog-client';
import isElectron from 'is-electron';
import { BinDdmJiraTicketInfoRequest, NoticeFileDownloadRequest, NoticeFileUploadCompleteRequest, NoticeFileUploadStartRequest, TriggerPassThroughSIRequest, UpdatePassThroughRequest } from 'src/app/models/distribution-client';

@Injectable()
export class RestWebClientService implements WebClientService{
  constructor(private serviceUtils : ServiceUtils, private apiUrls : ApiUrls) {
  }

  /* Generic APIs */
  public getVersion() :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.login);
    return observable;
  }

  public login(username?:string, password?:string) :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponseDirect(this.apiUrls.login);
    return observable;
  }

  public logout() :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.logout);
    return observable;
  }

  public checkPkla(username?:string):Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.checkPkla,username));
    return observable;
  }
  public getToken() :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.logout);
    return observable;
  }
  
  public refreshToken() :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.logout);
    return observable;
  }

  public checkMaintenance() :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.checkMaintanence);
    return observable;
  }

  /* Tools APIs */
  getCatalogV3(username, OS) :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsCatalog, username, OS));
    return observable;
  }
  getReleasesV3(productID, username, OS, numOfReleases) :  Observable<RestResponse> {
    var observable;
    if(numOfReleases === 0){
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsAllReleases, productID, username,OS));
    }
    else{
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsReleases,productID, username,OS,numOfReleases));
    }
    return observable;
  }
  getReleasesV3ByTargetArch(productID, username, OS, numOfReleases, targetArch) : Observable<RestResponse>{
    var observable;
    var requestParam = OS + "&Arch=" + targetArch;
    if(numOfReleases === 0){
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsAllReleases, productID, username,requestParam));
    }
    else{
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsReleases,productID, username,requestParam,numOfReleases));
    }
    return observable;

  }
  getReleasesV3ByLinuxOSDistType(productID, username, OS, numOfReleases, OSDistType) : Observable<RestResponse>{
    var observable;
    var requestParam = OS + "&OSDistType=" + OSDistType;
    if(numOfReleases === 0){
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsAllReleases, productID, username,requestParam));
    }
    else{
      observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsReleases,productID, username,requestParam,numOfReleases));
    }
    return observable;

  }
  refreshProduct(productID, OS) :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsProduct, productID, OS));
    return observable;
  }
  refreshPackage(packageID, OS) :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsPackage, packageID, OS));
    return observable;
  }
  getEntitlementsForProduct(productID) :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsEntitlements, productID));
    return observable;
  }
  refreshAll() :  Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.softwareProductDistros);
    return observable;
  }

  activateLicense(productID, licenseID) : Observable<RestResponse>{
    return null;
  }
  activateLicenseByProduct(productID) : Observable<RestResponse>{
    return null;
  }
  deactivateLicense(licenseID) : Observable<RestResponse>{
    return null;
  }
  verifyLicenses() : Observable<RestResponse>{
    return null;
  }
  recoverLicenses() : Observable<RestResponse>{
    return null;
  }
  downloadPackage(packageID) : Observable<RestResponse>{
    return null;
  }
  getSignedURL(tokenUrl): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponseText(String.Format(this.apiUrls.toolsSignedUrl, tokenUrl));
    return observable;
  }
  getReleaseNotes(tokenUrl): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsSignedUrl, tokenUrl));
    return observable;
  }
  public getFigureHeadCredentials() : Observable<RestResponse>{
     return null
  }
  getEntitilementsProduct(productId:string,OS:string):Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.toolsEntitlemets, productId,OS));
    return observable;
  }
  getNetworkStatus():Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.baseUrlTools));
    return observable;
  }

  getPackageListForComponent(componentId: string, os:string) {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.compPackageList, componentId, os));
    return observable;
  }
  /* Software APIs */
  refreshSoftwareProducts(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.softwareProducts);
    return observable;
  }
  refreshSoftwareProductDistros(softwareProductID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductDistros, softwareProductID));
    return observable;
  }
  refreshCustomerProjects(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.softwareProjects);
    return observable;
  }
  refreshSoftwareProductBuilds(softwareProductID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProducts, softwareProductID));
    return observable;
  }
  
  getCrFixDetails(request: CrFixDetailRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.softwareBuildCrFixDetails, request);
    return observable;
  }  
  getCrFixDetailsByRequest(requestID: string): Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareBuildCrFixDetailsByRequest, requestID));
    return observable;
  }
  searchUpdateCrFixDetail(request: SearchUpdateCrFixDetailRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.softwareBuildCrFixUpdate, request);
    return observable;
  }
  
  createServiceTask(request: ServiceTaskRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.createServiceTask, request);
    return observable;
  }
  updateServiceTask(serviceTaskID: string, request: UpdateServiceTaskRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.updateServiceTask, serviceTaskID), request);
    return observable;
  }
  deleteServiceTaskCR(serviceTaskID: string, cr: TriageDeleteCrRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.deleteServiceTaskCR, serviceTaskID), cr);
    return observable;
  }
  deleteServiceTaskGerrit(serviceTaskID: string, gerrit: TriageDeleteGerritRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.deleteServiceTaskGerrit, serviceTaskID), gerrit);
    return observable;
  }
  updateServiceTaskBuildInternalClient(cirrusTaskId : string, internalClient : string): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.updateServiceTaskBuildInternalClient, cirrusTaskId, encodeURI(internalClient).replace("+","%2B")), null);
    return observable;
  }
  removeServiceTaskBuildInternalClient(cirrusTaskId : string): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.removeServiceTaskBuildInternalClient, cirrusTaskId), null);
    return observable;
  }
  refreshServiceTasks(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.serviceTasks);
    return observable;
  }
  refreshServiceTaskDetails(serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskDetails, serviceTaskID));
    return observable;
  }
  abortBaitBuild(serviceTaskID: string, buildId: string): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.abortBaitBuild, serviceTaskID, buildId), null);
    return observable;
  }
  triggerBaitBuild(request: TriggerBaitBuildRequest, serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.triggerBaitBuild, serviceTaskID), request);
    return observable;
  }
  triggerBuild(request: TriggerBuildRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerBuild, request);
    return observable;
  }
  triggerCirrusBuild(cirrusID: string, fullBuild: boolean): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.triggerCirrusBuild, cirrusID) + "?fullBuild=" + (fullBuild? "true": "false"));
    return observable;
  }
  triggerProductBuild(request: TriggerProductBuildRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerProductBuild, request);
    return observable;
  }
  triggerProductBuildWithDistroList(request: TriggerProductBuildRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerProductBuildWithDistroList, request);
    return observable;
  }
  triggerAutoShip(request: AutoShipRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerAutoShip, request);
    return observable;
  }
  triggerAutoShipSP(request: AutoShipRequestSP): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerAutoShipSP, request);
    return observable;
  }
  triggerAutoShipSIAsync(request: AutoShipRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerAutoShipSIAsync, request);
    return observable;
  }
  triggerAutoShipSPAsync(request: AutoShipRequestSP): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerAutoShipSPAsync, request);
    return observable;
  }
  retryCirrusBuildRequest(serviceTaskID: string, softwareImage: string, softwareImageBuild: string){
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.retryCirrusBuildRequest, serviceTaskID, softwareImage, encodeURI(softwareImageBuild).replace("+","%2B")), null);
    return observable;
  }  
  recoverCirrusSeedBuild(cirrusTaskID: string): Observable<QPMResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.recoverCirrusSeedBuild, cirrusTaskID), null);
    return observable;
  }
  verifyProductComposition(productBuild: string, serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.verifyProductComposition, productBuild, serviceTaskID));
    return observable;
  }
  validateCR(request: ValidateCRRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.validateServiceTask, request);
    return observable;
  }
  getUserDetails(userID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.userDetails, userID));
    return observable;
  }
  getChangeRequestDetails(crID: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.crDetails, crID));
    return observable;
  }
  getChangeRequestRelated(crID: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.crRelated, crID));
    return observable;
  }
  
  refreshServiceTaskBuilds(serviceTaskID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuilds, serviceTaskID));
    return observable;
  }
  refreshServiceTaskBuildDetails(serviceTaskID:string, cirrusID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildDetails, serviceTaskID, cirrusID));
    return observable;
  }
  refreshServiceTaskBuildCRs(serviceTaskID:string, buildRequestID:string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBuildCRs, buildRequestID));
    return observable;
  }
  getAwsTempCredentials(): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.tempCredentials));
    return observable;
  }
  getDistrosShipStatus(request:DistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.shipStatus,request);
    return observable;
  }
  masterTriggerBuild(request:MasterTriggerBuildRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.masterTriggerBuild,request);
    return observable;
}
  getDiffDistrosShipStatus(request:DiffDistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.diifShipStatus,request);
    return observable;
  }
  getSoftwareProductDiffBuilds(softwareProductBuildID:string) : Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareProductDiffBuilds, encodeURI(softwareProductBuildID).replace("+","%2B")));
    return observable;
  }  
  sendEmail(request: Email): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.sendEmail, request);
    return observable;
  }
  getCustomerInfo(orgID: number): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.customerInfo, orgID));
    return observable;
  }
  getServiceTaskCRGerritComments(serviceTaskID: string, request: CrGerritComment): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.serviceTaskGetCRComments, serviceTaskID), request);
    return observable;
  }
  addServiceTaskCRGerritComment(serviceTaskID: string, request: CrGerritComment): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.serviceTaskAddCRComment, serviceTaskID), request);
    return observable;
  }
  getServiceTaskBaseImage(serviceTaskID: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBaseImages, serviceTaskID));
    return observable;
  }
  addServiceTaskBaseImage(serviceTaskID: string, softwareImage: SoftwareImage): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.serviceTaskBaseImages, serviceTaskID), softwareImage);
    return observable;
  }
  deleteServiceTaskBaseImage(serviceTaskID: string, softwareImageBuild: string): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.serviceTaskBaseImagesDelete, serviceTaskID, encodeURI(softwareImageBuild).replace("+","%2B")), null);
    return observable;
  }
  validateServiceTaskBaseImage(softwareImageBuild: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBaseImagesValidate, encodeURI(softwareImageBuild).replace("+","%2B")));
    return observable;
  }
  getServiceTaskMembers(serviceTaskID:string, accessType: number){
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskMembers, serviceTaskID) + "?accessType=" + accessType);
    return observable;
  }
  updateServiceTaskMembers(serviceTaskID:string, request: MemberUpdateRequest){
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.serviceTaskMembersUpdate, serviceTaskID), request);
    return observable;
  }
  cirrustaskStatusServiceTaskBaseImage(serviceTaskID: string, softwareImageBuild: string): Observable<RestResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serviceTaskBaseImagesCirrusStatus, serviceTaskID, encodeURI(softwareImageBuild).replace("+","%2B")));
    return observable;
  }
  getSoftwareImageDiffBuilds(softwareProductBuildID:string) : Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.softwareImageDiffBuilds, encodeURI(softwareProductBuildID).replace("+","%2B")));
    return observable;
  }  
  getDistroShipStausForImage(request:DistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.shipStatusForImage,request);
    return observable;
  }
  createTicket(request:JiraTicketRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.createTicket, request);
    return observable;
  }
  jiraAttachment(projectKey:string,issueId:string,request:JiraFilesUploadRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse( String.Format(this.apiUrls.jiraAttachment, projectKey,issueId) , request);
    return observable;
  }
  getImageDistroShipStaus(request:ImageBuildDistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.imageShipStatus,request);
    return observable;
  }
  getCveCrMapping(request: CveCrMappingRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.softwareBuildCveCrMapping, request);
    return observable;
  }  
  getSecurityBulletins(): Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.softwareBuildSecurityBulletins);
    return observable;
  }
  autoPropagateCR(autoPropagateRequest:AutoPropagationRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.crAutoPropagate, autoPropagateRequest);
    return observable;
  }
  getAutoPropagationResult(autoPropagateRequest:AutoPropagationRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.crAutoPropagateResult, autoPropagateRequest);
    return observable;
  }
  autoPropagateCRCommit(autoPropagateRequest:AutoPropagationRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.crAutoPropagateCommit, autoPropagateRequest);
    return observable;
  }
  getWorkspaceRootForCirrus(softwareImageBuild: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.workspaceRootForCirrus, encodeURI(softwareImageBuild).replace("+","%2B")));
    return observable;
 }
  localFilePathSearchForBait(request: FilePathSearchRequestForBait): Observable<QPMResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.localFilePathSearchForBait, request);
    return observable;
  }
  localFilePathSearch(request: FilePathSearchRequest): Observable<QPMResponse> {//componentBranchDevFR is false
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.localFilePathSearch, request);
    return observable;
  }
  localFilePathSearchV2(cirrusTaskId: number, fileName: string): Observable<QPMResponse> {//componentBranchDevFR is true
      var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.localFilePathSearch, cirrusTaskId,fileName),null);
      return observable;
  }
  localFilesDelete(cirrusTaskId:number,localFilesDeleteRequest: LocalFilesDeleteRequest): Observable<QPMResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.localFilesRemove, cirrusTaskId), localFilesDeleteRequest);
    return observable; 
  }
  validateFilePath(filePathValidationReq:FilePathValidationRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.localFileUploadValidation, filePathValidationReq);
    return observable;
  }

  localFileUpload(fileUploadRequest:LocalFileUploadRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.localFileUpload, fileUploadRequest);
    return observable;
  }
  
  localFileUploadInitiate(fileUploadInitiateRequest:LocalFileUploadInitiateRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.localFileUploadInitiate, fileUploadInitiateRequest);
    return observable;
  }
  
  localFileUploadComplete(fileUploadCompleteRequest:LocalFileUploadCompleteRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.localFileUploadComplete, fileUploadCompleteRequest);
    return observable;
  }
  
  localFileUploadSessions(localFileUploadSessionsRequest:LocalFileUploadSessionRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.localFileUploadSessions, localFileUploadSessionsRequest);
    return observable;
  }
  
  s3FileUploadStart(key:string, path:string): Observable<RestResponse>{ 
    let amp = new RegExp(/&/,"g");
    var query = "key="+key + "&path="+path;
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.s3UploadStart + '?'+(isElectron()? query : encodeURI(query).replace(amp,"%26")));
    return observable;
  }
  
  s3FileUploadContinue(key:string, path:string, partNumber:number, formData:FormData): Observable<RestResponse>{ 
    let amp = new RegExp(/&/,"g");
    var query = "key="+key+ "&path="+path+ "&partNumber="+partNumber;
    var observable = this.serviceUtils.postFileObservableRestResponse(this.apiUrls.s3UploadContinue + '?'+(isElectron()? query : encodeURI(query).replace(amp,"%26")), formData);
    //var observable = this.serviceUtils.postFileObservableRestResponse(this.apiUrls.s3UploadContinue + '?'+(isElectron()? query : encodeURI(query)), formData);
    return observable;
  }
  
  s3FileUploadContinueBase64(request:s3FileUploadContinueRequest): Observable<RestResponse>{ 
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.s3UploadContinue64, request);
    return observable;
  }
  
  s3FileUploadComplete(s3UploadCompleteRequest: S3UploadCompleteRequest): Observable<RestResponse>{ 
    // let amp = new RegExp(/&/,"g");
    // var query = "key="+key + "&path="+path;
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.s3UploadCompletev2, s3UploadCompleteRequest);
    return observable;
  }

  baitFileUploadInitiate(fileUploadInitiateRequest:BaitFileUploadInitRequest): Observable<RestResponse>{
    // let amp = new RegExp(/&/,"g");
    // var query = "serviceTaskId="+serviceTaskId + "&image="+image + "&smPath="+smPath;
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.baitFileUploadInitiate, fileUploadInitiateRequest);
    return observable;
  }
  
  baitFileUploadComplete(fileUploadCompleteRequest:BaitFileUploadCompleteRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.baitFileUploadComplete, fileUploadCompleteRequest);
    return observable;
  }

  baitHlosChanges(serviceTaskId: string, softwareImage: string): Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.baitHlosChanges, serviceTaskId, softwareImage));
    return observable;
  }

  baitSystemChanges(serviceTaskId: string, softwareImage: string): Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.baitSystemChanges, serviceTaskId, softwareImage));
    return observable;
  }

  baitSystemTriggerBuild(serviceTaskId: string, request: BaitSystemTriggerBuildRequest): Observable<RestResponse>{
    var observable = this.serviceUtils.postObservableRestResponse(String.Format(this.apiUrls.baitSystemTriggerBuild, serviceTaskId), request);
    return observable;
  }

  remoteAutoCommit(remoteAutoCommitRequest:RemoteAutoCommitRequest): Observable<RestResponse>{//componentBranchDevFR is false
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.remoteAutoCommit, remoteAutoCommitRequest);
    return observable;
  }
  remoteAutoCommitV2(remoteAutoCommitRequest: RemoteAutoCommitRequest): Observable<QPMResponse> {//componentBranchDevFR is true
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.remoteAutoCommitV2, remoteAutoCommitRequest);
    return observable;
  }

  getDeltaCRs(newBuild:string,oldBuild:string,isImageBuild:boolean):Observable<RestResponse>{
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.deltaCRs, newBuild,oldBuild,isImageBuild));
    return observable;
  }

  binDdmJiraTicketIdSubmission(binDdmJiraTicketInfoRequest: BinDdmJiraTicketInfoRequest): Observable<QPMResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.binDdmJiraTicketIdSubmit,binDdmJiraTicketInfoRequest );
    return observable;
  }
  noticeFileUploadStartSession(noticeFileUploadStartRequest: NoticeFileUploadStartRequest): Observable<QPMResponse> {
    // let amp = new RegExp(/&/,"g");
    // var query = "softwareDistro="+softwareDistro + "&softwareProductBuildId="+softwareProductBuildId;
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.noticeFileUploadStartSessionV2, noticeFileUploadStartRequest);
    return observable;
  }

  noticeFileUploadCompleteSession(noticeFileUploadCompleteRequest: NoticeFileUploadCompleteRequest): Observable<QPMResponse> {
    // let amp = new RegExp(/&/,"g");
    // var query = "sessionId="+sessionId + "&softwareDistro="+softwareDistro+"&softwareProductBuildId="+softwareProductBuildId;
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.noticeFileUploadCompleteSessionv2, noticeFileUploadCompleteRequest);
    return observable;
  }
  noticeFileDownload(noticeFileDownloadRequest: NoticeFileDownloadRequest): Observable<QPMResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.noticeFileDownload,noticeFileDownloadRequest );
    return observable;
  }
  getLicenses(): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.licenses);
    return observable;
  }
  getFeatures(licenseGroupID:string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.features,licenseGroupID));
    return observable;
  }
  getDocuments(request): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.documentList, request);
    return observable;
  }
  getSeats(licenseGroupID:string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.seatsDetails,licenseGroupID));
    return observable;
  }
  getSerialNumberDetails(serialNumber: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.serialNumberDetails,serialNumber));
    return observable;
  }
  getPricingDetails(pricingDetailsRequest: PricingDetailsRequest): Observable<QPMResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.pricingDetails, pricingDetailsRequest);
    return observable;
  }

  buildPaymentIntent(request: PaymentIntentRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.buildPaymentIntent, request);
    return observable;
  }

  updatePaymentIntent(request: PaymentIntentRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.updatePaymentIntent, request);
    return observable;
  }

  deactivateLicenseSeat(licenceGroupId: string, licenseActivationId: string, licenseSeatDeactivateRequest: LicenseSeatDeactivateRequest): Observable<QPMResponse> {
    var observable = this.serviceUtils.putObservableRestResponse(String.Format(this.apiUrls.deactivateLicenseSeat, licenceGroupId, licenseActivationId),licenseSeatDeactivateRequest);
    return observable;
  }

  getAdditionalCRInfo(crId: string, serviceTaskId: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.additionalCRInfo, crId, serviceTaskId));
    return observable;
  }
  
  getCurrentlySubscribedLicenseGroup(): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(this.apiUrls.currentSubscriptionDetails);
    return observable;
  }

  getSubscriptionDetailsByLicenseGroupId(licenceGroupId:string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.subscriptionDetailsByLicenseGroupId, licenceGroupId));
    return observable;
    
  }

  subscribeLicensegroupForEmail(licenceGroupId: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.putObservableRestResponse(String.Format(this.apiUrls.subscriptionDetailsByLicenseGroupId, licenceGroupId),null);
    return observable;
  }

  unsubscribeLicenseGroupForEmail(licenceGroupId: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.deleteObservableRestResponse(String.Format(this.apiUrls.subscriptionDetailsByLicenseGroupId, licenceGroupId),null);
    return observable;
  }

  updatePassThrough(request: UpdatePassThroughRequest): Observable<QPMResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.updatePassThrough, request);
    return observable;
  }
  triggerPassThroughSI(request: TriggerPassThroughSIRequest): Observable<QPMResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.triggerPassThroughSI, request);
    return observable;
  }

  //CLO
  getCloReleases(productUUID: string): Observable<QPMResponse> {
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.cloReleases, productUUID));
    return observable;
  }
  getManifestFileAndReleaseNote(productUuid: string, version: string): Observable<QPMResponse> {
    var requestParam = productUuid + "&version="+version;
    var observable = this.serviceUtils.getObservableRestResponse(String.Format(this.apiUrls.manifestFileAndReleaseNote, requestParam));
    return observable; 
  }
}