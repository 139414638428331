import { Location } from '@angular/common';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, isDevMode, NgZone, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { app } from 'electron';
import isElectron from 'is-electron';
import { CookieService } from 'ngx-cookie-service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DownloadHistoryLocal, DownloadImageStatusRequest, DownloadStatusRequest, SoftwareDiffDownloadResponse, SoftwareDownloadResponse, SoftwareImageDownload, SoftwareImageDownloadCommand, SoftwareImageSyncResult, SoftwareProductDownload, SoftwareProductMetaDeta } from 'src/app/models/distribution-client';
import { Company, DiffDistributionRequest, diffShipDownloadResponse, DistributionRequest, Email, ImageDiffDistributionRequest, ImageDistributionRequest, NewServiceTaskInfo, RadarInfo, ServiceTaskRequest, shipDownloadResponse, SoftwareDiffBuildRelease, SoftwareProductDistroInfo, UpdateServiceTaskRequest, UserRoleType, JiraTicketRequest, JiraTicketResponse, CrFixDetailRequest, JiraFile, JiraFilesUploadRequest, License, CartItem, PricingDetailsRequest, PriceRequest, RequestLine, PricingDetailsResponse, RequestLines, ToolUserRoleType } from 'src/app/models/lime-web-client';
import { environment } from 'src/environments/environment';
import { StringBuilder } from 'typescript-string-operations';
import { MenuService } from './app.menu.service';
import { QLogger } from './common/logger';
import { QConfig } from './common/qpm-config';
import { commType, SharedData } from './common/shared-data';
import { Utils } from './common/utils';
import { QPMResponse } from './models/response';
import { crFixDetail, Customer, CustomerInfo, CustomerProject, FileInfo, FileStatus, GerritSI, GerritSISelection, MasterSTSISelection, ReleaseCR, ServiceTaskInfo, SoftwareDownloadProcessQueueItem, SoftwareImage, SoftwareImageInfo, SoftwareProduct, SoftwareProductDistro, SoftwareProductFamily, SoftwareProductRelease, SoftwareShipProcessQueueItem } from './models/software-catalog-client';
import { ApiUrls } from './service/ApiUrls';
import { AuthService } from './service/auth.service';
import { DistributionClientService } from './service/Contract/DistributionClientService';
import { SoftwareCatalogClientService } from './service/Contract/SoftwareCatalogClient';
import { WebClientService } from './service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from './service/Factory/DataServiceProducer';
import { LimeClientService } from './service/LimeService/lime-client.service';

enum MenuOrientation {
    STATIC,
    OVERLAY,
    SLIM,
    HORIZONTAL
}

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    styleUrls: ['./app.main.component.css']
})
export class AppMainComponent implements AfterViewInit, OnDestroy, OnInit {

    private logSrc: string = "AppMain-Component";

    @ViewChild('commonOverlay') commonOverlay;
    @ViewChild('loggerFrame', { static: false }) loggerFrame: ElementRef;
    @ViewChild('companiesListBox') companiesListBox;

    private loggerContainer: any;
    private webClient: WebClientService;
    private limeClient: LimeClientService;
    private distributionClient: DistributionClientService;
    private softwareCatalogClient: SoftwareCatalogClientService;
    private worker_process;
    private diff_worker_process;

    electron = null;
    findInPage = null;
    ipcRenderer = null;

    layoutCompact = true;

    layoutMode: MenuOrientation = MenuOrientation.SLIM;

    darkMenu = true;

    profileMode = 'inline';

    rotateMenuButton: boolean;

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    rightPanelActive: boolean;

    rightPanelClick: boolean;

    layoutContainer: HTMLDivElement;

    menuClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    menuHoverActive: boolean;

    configActive: boolean;

    configClick: boolean;

    rippleInitListener: any;

    rippleMouseDownListener: any;

    overlayBody: string;

    errorMessage: string;

    supportDialog: {
        showSupportDialog: boolean;
        ticketTypes: SelectItem[];
        selectedTicketType: string;
        subject: string;
        description: string;
        successful: boolean;
        ticket: string;
        ticket_url: string;
        error: string;
        issueType :string;
        projectKey :string;
        component:string;
        files: any[];
        filesInfo: FileInfo[];
        jiraFileValues: JiraFile[];
        filesProcessed: number;
        fileErrorMessage: string;
        fileSuccessMessage:string;
        totalFileSizeInByte: number;
        totalFileSizeInMB: number;
        maxFileSizeInByte: number;
        maxFileSizeInMB: number;
        id:string;
    }
    


    maintanenceMessage: Message[];
    errorMessages: Message[];
    checkMaintenanceTimer: any;

    loggerPanel: {
        terminalActive: boolean;
        terminalClick: boolean;
        terminalVisible: boolean;
        logMessages: string[];
    }
    dialog: {
        displayMessageBox: boolean,
        header: string,
        content: string[],
        ok: string
    }
    aboutDialog: {
        displayAboutBox: boolean,
        versionStr: string
    }

    createServiceTaskProcess: {
        newServiceTask: NewServiceTaskInfo,
        displayCreateRequest: boolean,
        displayCreateForm: boolean,
        displaySummary: boolean,
        displayResponse: boolean,
        baseProject: string
        baseSPF: SoftwareProductFamily,
        baseRelease: SoftwareProductRelease,
        baseReleasePreSelected: boolean,
        addSoftwareImage: boolean,
        baseSI: MasterSTSISelection[],
        addGerrit: boolean,
        addCustomers:boolean,
        addCRs:boolean,
        autobuild:boolean,
        isImageFormatAddOn:boolean,
        caption?:string,
        expandAdvanceOptions:boolean,
        gerritSIs: GerritSISelection[],
        masterService: boolean,
        crList: string,
        crLists: any[],
        crCols: any[],
        companies: Company[],
        companiesPreSelected: boolean,
        loadCustomerInProgress: boolean,
        loadCustomerInfoInProgress: boolean,
        creatingServiceTaskInProgress: boolean,
        selectedCompanies: Company[],
        errorMessage: string,
        errorMessageResponse: string,

        caseNumber?: string,
        prod?: string,
        title?: string,
        desc?: string,
        custid?: string,
        partyid?: number,
        cust?: string,
        isSalesforce: boolean
    };

    downloadQueue: {
        softwareQueue: SoftwareDownloadProcessQueueItem[],
        downloadInProgress: boolean,
        showProgress:boolean,
        startTimeStamp: number,
        endTimeStamp: number,
        setCredentialFailed: boolean,
        suceessfullDownloadCount:number
    }


    shipStatusQueue:{
        softwareQueue: SoftwareShipProcessQueueItem[],
        shipStatusCheckInProgress: boolean
    }
    
    diffShipStatusQueue:{
        softwareQueue: SoftwareShipProcessQueueItem[],
        shipStatusCheckInProgress: boolean
    }
    downloadStatus: {
        baseSoftwareBuild:string;
        distroName:string;
        targetSoftwareBuild:string
        totalDownloadSize:number;
        totalDownloadSizeString:string;
        downloadedSize:number;
        downloadedSizeString:string;
        isFailed:boolean;
        downloadProgress:number;
        fileName:string;
        downloadSpeed:number;
        downloadSpeedString:string;
    }
    shipStatusTimer:any;
    diffShipStatusTimer:any;
    downloadHistory: DownloadHistoryLocal[];
    buildSyncResultList :SoftwareProductMetaDeta;
    updateExternalIdProcess:
    {
        updateExternalIdError:string;
        updateExternalId:boolean;
        updateExternalIdInProgress:boolean;

    }

    viewReleaseCrProcess: {
        displayForm: boolean;
        serviceTaskID: string;
        releaseCRs: ReleaseCR[];
    }

    viewAllCrProcess: {
        displayForm: boolean;
        serviceTaskID: string;
        CRs: number[];
    }
    searchReleaseByImageProcess:{
        selectedProductRelease:SoftwareProductRelease;
        softwareProductReleases:SoftwareProductRelease[];
        loadingAvailableRelease:boolean;
        imageBuild:string;
        displaySearchByImageDialog:boolean;
        displaySearchResults:boolean;

    }

    qpmLogLocation:string;
    createJIRAInProgress: boolean;
    pklaCheckFailed:boolean;
    timeoutId: NodeJS.Timeout;
    
    constructor(private router: Router, public sharedData: SharedData, private utils: Utils, private qpmConfig: QConfig,
                private service: DataServiceProducer, public renderer2: Renderer2, public zone: NgZone, 
                private messageService: MessageService, private menuService: MenuService,
                private cookieService: CookieService, public apiUrl: ApiUrls,private http: HttpClient,public location: Location,
                private authService:AuthService) {
        QLogger.LogInfo(this.logSrc, "AppMain Creation");
        this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
        this.limeClient = service.getServiceInstance(ApiType.Client) as LimeClientService;
    }

    ngOnInit() {
        QLogger.LogInfo(this.logSrc, "AppMain Initialization");

        this.errorMessage = '';
        this.maintanenceMessage = [];
        this.errorMessages = [];
        this.checkMaintenanceTimer = null;
        this.dialog = {
            displayMessageBox: false,
            header: '',
            content: [],
            ok: ''
        }
        this.aboutDialog = {
            displayAboutBox: false,
            versionStr: ""
        }

        this.loggerPanel = {
            terminalActive: false,
            terminalClick: false,
            terminalVisible: false,
            logMessages: []
        };

        this.createServiceTaskProcess = {
            newServiceTask: new NewServiceTaskInfo(),
            displayCreateRequest: false,
            displayCreateForm: false,
            displaySummary: false,
            displayResponse: false,
            masterService: false,
            crList: "",
            crLists: [],
            baseProject: undefined,
            baseSPF: undefined,
            baseRelease: new SoftwareProductRelease(),
            baseReleasePreSelected: true,
            addSoftwareImage: false,
            baseSI: [],
            addGerrit: false,
            addCustomers:false,
            addCRs:false,
            autobuild:true,
            isImageFormatAddOn:false,
            caption:'',
            expandAdvanceOptions:false,
            gerritSIs: [],
            crCols: [
            { field: 'cr', header: 'CR #' },
            { field: 'validation', header: 'Valid' }
            ],
            companies: [],
            companiesPreSelected:false,
            loadCustomerInProgress: false,
            loadCustomerInfoInProgress: false,
            creatingServiceTaskInProgress: false,
            selectedCompanies: [],
            errorMessage: "",
            errorMessageResponse: "",
            isSalesforce: false
        }

        this.downloadQueue = {
            softwareQueue: [],
            downloadInProgress: false,
            showProgress: false,
            startTimeStamp: 0,
            endTimeStamp: 0,
            suceessfullDownloadCount:0,
            setCredentialFailed: false
        };

        this.shipStatusQueue = {
            softwareQueue: [],
            shipStatusCheckInProgress:false
        };
        this.diffShipStatusQueue = {
            softwareQueue: [],
            shipStatusCheckInProgress:false
        };
        this.downloadStatus = {
            totalDownloadSize : 0,
            downloadedSize : 0,
            downloadSpeedString:"",
            isFailed:false,
            baseSoftwareBuild:"",
            distroName:"",
            targetSoftwareBuild:"",
            downloadProgress :0,
            downloadedSizeString:"",
            fileName:"",
            downloadSpeed:0,
            totalDownloadSizeString:""
        };
        this.updateExternalIdProcess ={
            updateExternalIdError:"",
            updateExternalId:false,
            updateExternalIdInProgress:false
        }
        this.viewReleaseCrProcess = {
            displayForm: false,
            serviceTaskID: undefined,
            releaseCRs: []
        };
        this.viewAllCrProcess = {
            displayForm: false,
            serviceTaskID: undefined,
            CRs: []
        };
        this.searchReleaseByImageProcess={
            selectedProductRelease:undefined,
            softwareProductReleases:[],
            loadingAvailableRelease:false,
            imageBuild:undefined,
            displaySearchByImageDialog:false,
            displaySearchResults:false
        }
        this.updateSTCaptionProcessReset();
        this.updateOwnerProcessReset();
        this.resetAddGerritProcess();
        this.resetEmailProcess();
        this.initiateFindCrStatus();
        this.resetRenewDialogProcess();
        

        this.supportDialog = {
            showSupportDialog: false,
            ticketTypes: [
                {label: 'Bug', value: 'Bug'},
                {label: 'New Feature', value: 'New Feature'},
                {label: 'Question', value: 'Question'},
                {label: 'Other', value: 'Other'}],
            selectedTicketType: "Bug",
            subject: "",
            description: "",
            successful: false,
            ticket: "",
            ticket_url: "",
            error: "",
            issueType:"",
            projectKey:"",
            component:"",
            id:"",
            files: [],
            filesInfo: [],
            jiraFileValues: [],
            filesProcessed: 0,
            fileErrorMessage: '',
            fileSuccessMessage:'',
            totalFileSizeInByte: 0,
            totalFileSizeInMB: 0,
            maxFileSizeInByte: 10000000,
            maxFileSizeInMB: 10,
        }        

        this.zone.runOutsideAngular(() => { this.bindRipple(); });

        if (isElectron()) {
            this.sharedData.appInfo.isElectronMode = true;
            this.sharedData.appInfo.platform = process.platform;
            this.electron = window.require('electron');
            let Find = window.require('electron-find').FindInPage;
            this.findInPage = new Find(this.electron.remote.getCurrentWebContents());
            this.ipcRenderer= window.require('electron').ipcRenderer;
            this.ipcRenderer.on('on-find', (e, args) => {
                this.findInPage.openFindWindow()
            })
            this.setUserInfo();

            //Check & navigate to last visited  page
            var lastVisited = this.qpmConfig.getLastVisitedPage();
            //Store last visited page
            this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event) => 
            {
                this.qpmConfig.setLastVisitedPage(event['url']);
                console.log(event['url']);
            });
            //Navigate last visited page
            if(lastVisited !== null){
                QLogger.LogInfo(this.logSrc,"Last Visited: " + lastVisited);

                this.router.navigate([lastVisited]);
            }
        }
        else{
            this.authService.isLoggedIn$.subscribe(
                (isValid: boolean) => {
                    if (isValid) {
                        QLogger.LogInfo(this.logSrc,"Get OIDC UID Successful");
                        this.sharedData.userInfo.username = this.authService.getUsername();
                        this.checkPkla();
                        let response : Observable<QPMResponse>;
                        response = this.webClient.login();
                        response.subscribe(
                            (data:QPMResponse) => {
                                if (data.isSuccess()) {
                                    QLogger.LogInfo(this.logSrc,"Get SiteMinder UID Successful");
                                    //this.sharedData.userInfo.username = JSON.parse(data.getData()).uid;
                                    this.sharedData.userInfo.countryCode = JSON.parse(data.getData()).countryCode;
                                    this.sharedData.userInfo.cityCode = JSON.parse(data.getData()).cityCode;
                                    this.sharedData.userInfo.regionCode = JSON.parse(data.getData()).regionCode;
                                    this.checkPkla();
                                    this.authService.routeToPage();
                                } 
                                else{
                                    QLogger.LogError(this.logSrc,"Get SiteMinder UID Failed - " + data.getError() + " - " + data.getErrorDetail());
                                    if(this.sharedData.appInfo.logResponse){
                                        QLogger.LogError(this.logSrc,  "Get SiteMinder UID Failed - Response : " +JSON.stringify(data));
                                    }
                                }
                            }
                        );
                    } 
                    else{
                        QLogger.LogError(this.logSrc,"Get OIDC UID Failed");
                    }
                }
            )
            /* QLogger.LogInfo(this.logSrc,"Get SiteMinder UID");
            let response : Observable<QPMResponse>;
            response = this.webClient.login();
            response.subscribe(
                (data:QPMResponse) => {
                    if (data.isSuccess()) {
                        QLogger.LogInfo(this.logSrc,"Get SiteMinder UID Successful");
                        //this.sharedData.userInfo.username = JSON.parse(data.getData()).uid;
                        this.sharedData.userInfo.countryCode = JSON.parse(data.getData()).countryCode;
                        this.sharedData.userInfo.cityCode = JSON.parse(data.getData()).cityCode;
                        this.sharedData.userInfo.regionCode = JSON.parse(data.getData()).regionCode;
                        this.checkPkla();

                    } 
                    else{
                        QLogger.LogError(this.logSrc,"Get SiteMinder UID Failed - " + data.getError() + " - " + data.getErrorDetail());
                        if(this.sharedData.appInfo.logResponse){
                            QLogger.LogError(this.logSrc,  "Get SiteMinder UID Failed - Response : " +JSON.stringify(data));
                        }
                    }
                }
            ) */
        }
        this.initElectronWindow();
        if(!this.sharedData.appInfo.workOffline)
        this.checkMaintenance();
        this.distributionClient = this.service.getServiceInstance(ApiType.DistributionClient) as DistributionClientService;
        this.softwareCatalogClient = this.service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
        this.shipStatusTimer = null;
        this.diffShipStatusTimer = null;
        this.buildSyncResultList = new SoftwareProductMetaDeta();
        this.buildSyncResultList.SyncResultJson = [];
        if(this.sharedData.appInfo.isElectronMode)
        this.getDownloadHistory();
        else
        this.downloadHistory =[];
        this.qpmLogLocation = undefined;
        if(isDevMode()){
            this.sharedData.enableDevFeature();
        }
        this.createJIRAInProgress = false;
        this.pklaCheckFailed = false;
    }



    ngAfterViewInit() {
        this.loggerPanel.logMessages = [];
        this.loggerContainer = this.loggerFrame.nativeElement as HTMLDivElement;
    }

    ngAfterContentInit(){
        if(this.sharedData.appInfo.isElectronMode && !this.sharedData.appInfo.workOffline ){
            this.checkMaintenanceTimer = setInterval(() => {
                this.checkMaintenance();
            }, 3600000);
        }
    }

    bindRipple() {
        this.rippleInitListener = this.init.bind(this);
        document.addEventListener('DOMContentLoaded', this.rippleInitListener);
    }

    init() {
        this.rippleMouseDownListener = this.rippleMouseDown.bind(this);
        document.addEventListener('mousedown', this.rippleMouseDownListener, false);
    }

    rippleMouseDown(e) {
        const parentNode = 'parentNode';
        for (let target = e.target; target && target !== this; target = target[parentNode]) {
            if (!this.isVisible(target)) {
                continue;
            }

            // Element.matches() -> https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
            if (this.selectorMatches(target, '.ripplelink, .ui-button, .ui-listbox-item, .ui-multiselect-item, .ui-fieldset-toggler')) {
                const element = target;
                this.rippleEffect(element, e);
                break;
            }
        }
    }

    selectorMatches(el, selector) {
        const matches = 'matches';
        const webkitMatchesSelector = 'webkitMatchesSelector';
        const mozMatchesSelector = 'mozMatchesSelector';
        const msMatchesSelector = 'msMatchesSelector';
        const p = Element.prototype;
        const f = p[matches] || p[webkitMatchesSelector] || p[mozMatchesSelector] || p[msMatchesSelector] || function (s) {
            return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
        };
        return f.call(el, selector);
    }

    isVisible(el) {
        return !!(el.offsetWidth || el.offsetHeight);
    }

    rippleEffect(element, e) {
        if (element.querySelector('.ink') === null) {
            const inkEl = document.createElement('span');
            this.addClass(inkEl, 'ink');

            if (this.hasClass(element, 'ripplelink') && element.querySelector('span')) {
                element.querySelector('span').insertAdjacentHTML('afterend', '<span class=\'ink\'></span>');
            } else {
                element.appendChild(inkEl);
            }
        }

        const ink = element.querySelector('.ink');
        this.removeClass(ink, 'ripple-animate');

        if (!ink.offsetHeight && !ink.offsetWidth) {
            const d = Math.max(element.offsetWidth, element.offsetHeight);
            ink.style.height = d + 'px';
            ink.style.width = d + 'px';
        }

        const x = e.pageX - this.getOffset(element).left - (ink.offsetWidth / 2);
        const y = e.pageY - this.getOffset(element).top - (ink.offsetHeight / 2);

        ink.style.top = y + 'px';
        ink.style.left = x + 'px';
        ink.style.pointerEvents = 'none';
        this.addClass(ink, 'ripple-animate');
    }
    hasClass(element, className) {
        if (element.classList) {
            return element.classList.contains(className);
        } else {
            return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
        }
    }

    addClass(element, className) {
        if (element.classList) {
            element.classList.add(className);
        } else {
            element.className += ' ' + className;
        }
    }

    removeClass(element, className) {
        if (element.classList) {
            element.classList.remove(className);
        } else {
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    getOffset(el) {
        const rect = el.getBoundingClientRect();

        return {
            top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
            left: rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0),
        };
    }

    unbindRipple() {
        if (this.rippleInitListener) {
            document.removeEventListener('DOMContentLoaded', this.rippleInitListener);
        }
        if (this.rippleMouseDownListener) {
            document.removeEventListener('mousedown', this.rippleMouseDownListener);
        }
    }

    onLayoutClick() {
        if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.menuService.reset();
            }

            if (this.overlayMenuActive || this.staticMenuMobileActive) {
                this.hideOverlayMenu();
            }

            this.menuHoverActive = false;
        }

        if (!this.rightPanelClick) {
            this.rightPanelActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        if (this.loggerPanel.terminalActive && !this.loggerPanel.terminalClick) {
            this.loggerPanel.terminalActive = false;
        }

        this.loggerPanel.terminalClick = false;
        this.configClick = false;
        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        } else {
            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
            }
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        this.hideOverlayMenu();

        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }

        event.preventDefault();
    }

    /*onTopbarSubItemClick(event) {
        event.preventDefault();
    }*/
    onCreateServiceTask() {

        this.router.navigate(['/main/software']);
    }
    onFindServiceTask() {
        this.router.navigate(['/main/software/servicetask/find']);
    }
    onOpenUserGuide() {
        this.router.navigate(['/main/userguide']);
    }
    onOpenEmailSubscriptionsPage() {
        this.router.navigate(['/main/licenses/subscriptions']);
    }
    onOpenInternalUserGuide() {
        this.router.navigate(['/main/internaluserguide']);
    }
    onOpenSupportTicketDialog(issueType: string,projectKey:string,component:string) {
        this.supportDialog.selectedTicketType = this.supportDialog.ticketTypes[0].value;
        this.supportDialog.description = "";
        this.supportDialog.subject = "";
        this.supportDialog.successful = false;
        this.supportDialog.ticket = "";
        this.supportDialog.ticket_url = "";
        this.supportDialog.error = "";
        this.supportDialog.showSupportDialog = true;
        this.supportDialog.issueType=issueType;
        this.supportDialog.projectKey=projectKey;
        this.supportDialog.component=component;
        this.supportDialog.files=[];
        this.supportDialog.filesInfo=[];
        this.supportDialog.fileErrorMessage='';
        this.supportDialog.fileSuccessMessage='';
        this.supportDialog.totalFileSizeInByte=0;
        this.supportDialog.totalFileSizeInMB=0;
        this.supportDialog.maxFileSizeInByte=7000000;
        this.supportDialog.maxFileSizeInMB=7;
        this.createJIRAInProgress=false;
    };

    //File Drag&Drop and Other process
    filesDroppedForJira(event): void {
            let files: FileList = event.target.files;
            this.saveFilesForJira(files);
        }
    @HostListener("dragover", ["$event"]) onDragOver(event: any) {
            event.preventDefault();
            
        }
    @HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
            event.preventDefault();
        }
    @HostListener("drop", ["$event"]) onDrop(event: any) {
            event.preventDefault();
            event.stopPropagation();
            if (event.dataTransfer.files) {
              let files: FileList = event.dataTransfer.files;
              this.saveFilesForJira(files);
            }
        }
    
    saveFilesForJira(files: FileList) {
            this.supportDialog.fileErrorMessage="";
            this.supportDialog.files.push(...Array.from(files));
           
     
            for(let i=0; i < files.length; i++){
              this.supportDialog.filesInfo.push({
                filename: files[i].name,
                filesize: files[i].size,
                filePath: "",
                fullFilePath: "",
                newFile: false,
                status: FileStatus.Init,
                statusString: "",
                validationInProgress: false,
                uploadInProgress: false,
                uploadProgress: 0
              });
            }
            this.validateForJiraFileUpload();
            
        }
    
    onRemoveLocalFileForJira(index: number){
            this.supportDialog.files.splice(index, 1);
            this.supportDialog.filesInfo.splice(index, 1);
            this.supportDialog.fileErrorMessage="";
            this.validateForJiraFileUpload();
            
        }
    
     
    validateForJiraFileUpload(){
            this.supportDialog.fileErrorMessage="";
            let sumFileSizeExcced=0;
            this.supportDialog.filesInfo.forEach((f)=>{
                sumFileSizeExcced=sumFileSizeExcced+f.filesize;
                if(sumFileSizeExcced>this.supportDialog.maxFileSizeInByte){
                        f.status=FileStatus.FileSizeExcced;
                        f.statusString=FileStatus.FileSizeExcced.toString();
                }
            })
    
            
            this.supportDialog.totalFileSizeInByte = 0;
            this.supportDialog.totalFileSizeInMB = 0;
            Array.from(this.supportDialog.files).forEach(file=>this.supportDialog.totalFileSizeInByte = this.supportDialog.totalFileSizeInByte + file.size);
            this.supportDialog.totalFileSizeInMB=(this.supportDialog.totalFileSizeInByte/1000000);
    
            if((this.supportDialog.totalFileSizeInByte) > this.supportDialog.maxFileSizeInByte){
              this.supportDialog.totalFileSizeInMB = Number.parseFloat(this.supportDialog.totalFileSizeInMB.toFixed(2));
              this.supportDialog.fileErrorMessage = "Total file size exceeded "+ this.supportDialog.maxFileSizeInMB +"MB";
              return;
            }
    
          //Validation: Each filename should be distinct 
           
          let map = new Map<string,number>();
          this.supportDialog.filesInfo.forEach((f)=>{
            if(map.has(f.filename)){
              map.set(f.filename,map.get(f.filename)+1)
            }else{
              map.set(f.filename,1);
            }
            });
            this.supportDialog.filesInfo.forEach((f)=>{
              if(map.get(f.filename)>=2){
                f.status = FileStatus.DuplicateFile;
              f.statusString = FileStatus.DuplicateFile.toString();
              this.supportDialog.fileErrorMessage = "Duplicate files are not allowed";
              return;
              }else if(map.get(f.filename)==1){
                f.status = FileStatus.Init;
              }
            })
        }
      

    readJiraFiles(i:number){
        let appPtr = this;
        const reader = new FileReader();
        var filename = appPtr.supportDialog.filesInfo[i].filename;
        reader.readAsDataURL(appPtr.supportDialog.files[i]);
        reader.onload = function () {
            let result = <string>reader.result;
            let base64String = (result).split(',').pop(); //Removing Header
            let jiraFile=new JiraFile();
            jiraFile.fileName=filename;
            jiraFile.base64File=base64String;
            appPtr.supportDialog.jiraFileValues.push(jiraFile);
           appPtr.supportDialog.filesProcessed++;
          if(appPtr.supportDialog.filesProcessed === appPtr.supportDialog.files.length){
            appPtr.attatchFileWithSupportTicket()
            return;
          }
          else{
            appPtr.readJiraFiles(i+1);
          }
        }
        reader.onerror = function (error) {
          appPtr.showMessageBox("Jira File Attatchments","Cannot read uploaded file. Please check if file is present in local storage","OK");
        };
      }

      attatchFileWithSupportTicket(){
        QLogger.LogInfo(this.logSrc,"Starting jira file upload");
        this.supportDialog.fileSuccessMessage="File upload in progress...";
        let jirafileUploadRequest =new JiraFilesUploadRequest();

        jirafileUploadRequest.requestList=this.supportDialog.jiraFileValues;

        QLogger.LogInfo(this.logSrc,  "Create Ticket with Attatchment - Request Payload : " +JSON.stringify(jirafileUploadRequest));
        let JiraAttachmentresponse : Observable<QPMResponse>;
        JiraAttachmentresponse = this.webClient.jiraAttachment(this.supportDialog.projectKey,this.supportDialog.id,jirafileUploadRequest);
        JiraAttachmentresponse.subscribe(
        (data:QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc,  "Create Ticket with Attatchment - Response : " +JSON.stringify(data));
            }
            if(data.isSuccess()){
                QLogger.LogInfo(this.logSrc, "Create Ticket with Attatchment - Success");
                let obj = JSON.parse(data.getData()); 
                this.supportDialog.fileSuccessMessage="File attachments completed successfully"
                this.createJIRAInProgress=false;

            }else{
                this.supportDialog.fileSuccessMessage="";
                this.supportDialog.fileErrorMessage="File attatchments failed : "+data.getError();
                this.createJIRAInProgress=false;
            }

        })

      }
    closeSupportTicketDialog() {
        this.supportDialog.showSupportDialog = false;
    }
    createSupportTicket(issueType: string,projectKey:string,component:string) {
        let request = new JiraTicketRequest();
        request.issueType = issueType;
        request.projectKey = projectKey;
        request.components=[];
        request.components.push(component);
        request.summary = this.supportDialog.subject;
        this.createJIRAInProgress = true;

        let fullDescription = "QPM3 Generated Support Ticket" + "\n";
        fullDescription += "Reporter:       " + this.sharedData.userInfo.username + "\n";
        fullDescription += "Ticket Type:    " + this.supportDialog.selectedTicketType + "\n";
        fullDescription += "QPM3 Version:   " + this.sharedData.appInfo.version + "\n";
        if (this.sharedData.appInfo.isElectronMode) {
            fullDescription += "Current URL:   " + this.qpmConfig.getLastVisitedPage() + "\n\n";
        }
        else {
            fullDescription += "Current URL:   " + window.location.href + "\n\n";
        }
        fullDescription += "User entered description: \n\n"
        fullDescription += this.supportDialog.description;

        request.description = fullDescription;

        let response : Observable<QPMResponse>;
        response = this.webClient.createTicket(request);
        response.subscribe(
          (data:QPMResponse) => { 
            this.createJIRAInProgress = false;
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc,  "Create Ticket - Response : " +JSON.stringify(data));
            }
            if(data.isSuccess()){
                QLogger.LogInfo(this.logSrc, "Create Ticket - Success");
                let obj = JSON.parse(data.getData());
                if(obj !== undefined || obj !== null){
                    let response: JiraTicketResponse;
                    response = obj;

                    // Show the response
                    this.supportDialog.successful = true;
                    this.supportDialog.ticket = response.jiraTicket;
                    this.supportDialog.ticket_url = response.jiraTicketUrl;
                    if(this.supportDialog.files.length!=0){
                        this.supportDialog.id=response.id;
                        this.supportDialog.projectKey=request.projectKey
    
                        //make the call for reading & uploading of files
                        QLogger.LogInfo(this.logSrc,"Starting jira file upload");
                        this.supportDialog.jiraFileValues = [];
                        this.supportDialog.filesProcessed = 0;
                        this.createJIRAInProgress=true;
                        this.readJiraFiles(0);

                    }
    
                }
            }
            else {
                this.supportDialog.successful = false;
                this.createJIRAInProgress=false;
                this.supportDialog.error = "Failed to create support ticket: " + data.getError();
            }
        });


    }
    onShowQPMInfo() {
        // TO DO: Open ticket modal
        let versionStr =  "Version " + this.sharedData.appInfo.version;
        if (!environment.production) {
            versionStr += "(STAG)";
        }
        this.showAboutBox(versionStr);
    }
    onRightPanelButtonClick(event) {
        this.rightPanelClick = true;
        this.rightPanelActive = !this.rightPanelActive;
        event.preventDefault();
    }

    onRightPanelClick() {
        this.rightPanelClick = true;
    }

    onConfigClick(event) {
        this.configClick = true;
    }

    hideOverlayMenu() {
        this.rotateMenuButton = false;
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    isStatic() {
        return this.layoutMode === MenuOrientation.STATIC;
    }

    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }

    isSlim() {
        return this.layoutMode === MenuOrientation.SLIM;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    changeToHorizontalMenu() {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    }

    changeToSlimMenu() {
        this.layoutMode = MenuOrientation.SLIM;
    }

    ngOnDestroy() {
        if(this.shipStatusTimer)
        clearInterval(this.shipStatusTimer);
        if(this.diffShipStatusTimer)
        clearInterval(this.diffShipStatusTimer);
        this.downloadHistory?.forEach(build =>{

            if(build.status==="In Progress")
            {
                let end =this.utils.getCurrentDateTime_24();
                build.status ='Failed';
                build.downloadDate = end.toString();
            }
        });
        if(this.downloadHistory)
        {
            let HistoryString = JSON.stringify(this.downloadHistory);
            this.distributionClient?.saveDownloadHistory(HistoryString); // Updating History
        }

        if(this.checkMaintenanceTimer){
            clearInterval(this.checkMaintenanceTimer);
        }
        this.unbindRipple();
    }

    onErrorClose(event) {
        this.errorMessage = '';
    }

    onClickClose(event) {
        if (this.sharedData.appInfo.isElectronMode) {
            event.preventDefault();
            this.electron.remote.getCurrentWindow().hide();
        }
    }
    onClickMax(event) {
        if (this.sharedData.appInfo.isElectronMode) {
            event.preventDefault();
            let win = this.electron.remote.getCurrentWindow();
            if (win.isMaximized()) {
                win.unmaximize();
                win.center();
            }
            else {
                win.maximize();
            }
        }
    }
    onClickMin(event) {
        if (this.sharedData.appInfo.isElectronMode) {
            event.preventDefault();
            this.electron.remote.getCurrentWindow().minimize();
        }
    }
    isQPMLocked(){
        let isLocked = false;
        try{
        let limeResponse = this.limeClient.getLockState();
            limeResponse.subscribe(
            (data:QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc, "Got lock state  - Response : " +JSON.stringify(data));
                }
                if(data.isSuccess()){
    
                let obj = JSON.parse(data.getData());
                if(obj.state==1)
                {
                    QLogger.LogInfo(this.logSrc,"QIK process is locked");
                    this.showMessageBox("Logout", "QPM is locked by another process, please wait for QPM to be unlocked" , "Ok");
                    isLocked = true;
                }
                }
                else
                QLogger.LogInfo(this.logSrc,"Unable to Get the lock Status, Default to Unlocked");

            });
        }
        catch(e){
            QLogger.LogError(this.logSrc, "Exception while checking lock status: " + e);
        }
        return isLocked;

    }
    onLogout(event) {
        if(this.sharedData.appInfo.isElectronMode){
           
            if(this.isQPMLocked()) return;
        }

        this.sharedData.resetUserRoles();
        this.sharedData.resetVisibility();
        if(this.sharedData.appInfo.isElectronMode){
            if(this.sharedData.appInfo.workOffline)
            {
                if (this.sharedData.appInfo.isElectronMode) {
                    this.electron.remote.getCurrentWindow().hide();
                }
                this.sharedData.appInfo.workOffline = false;
                this.router.navigate(['']);
            }
            let response: Observable<QPMResponse>;
            response = this.webClient.logout();
            response.subscribe(
                (data: QPMResponse) => {
                    let errorStr = (data.isSuccess()) ? data.getData() : data.getError();
                    // Success? 
                    if (errorStr == "") {
                        if (this.sharedData.appInfo.isElectronMode) {
                            this.electron.remote.getCurrentWindow().hide();
                        }
                        this.sharedData.appInfo.isLoggedOut = true;
                        this.sharedData.userInfo.username ='';
                        this.sharedData.service.tools.isCatalogInitialized = false;
                        this.router.navigate(['']);
                    }
                    if (!this.sharedData.appInfo.isElectronMode) {
                        this.router.navigate(['']);
                    }
                }
            );
        }
        else{
            this.authService.revokeTokenAndLogout();
            //window.location.href= this.apiUrl.baseUrl + '/logout.html';
        }
    }


    onTerminalClick(event) {
        this.loggerPanel.terminalClick = true;
    }
    onTerminalButtonClick(event) {
        this.loggerPanel.terminalActive = !this.loggerPanel.terminalActive;
        event.preventDefault();
    }
    onTerminalCloseClick(event) {
        this.loggerPanel.terminalActive = false;
        event.preventDefault();
    }

    //#region Functions
    initElectronWindow() {
        if (this.sharedData.appInfo.isElectronMode) {
            if(this.sharedData.appInfo.platform=='win32')
               this.electron.remote.getCurrentWindow().setSize(1080, 700);
            else
               this.electron.remote.getCurrentWindow().setSize(1920, 1080);
            this.electron.remote.getCurrentWindow().center();
            this.electron.remote.getCurrentWindow().maximize();//setSize(1080,700);
            this.electron.remote.getCurrentWindow().show();
        }
    }

    setUserInfo() {
        QLogger.LogInfo(this.logSrc, "Get User Info");
        if(this.sharedData.appInfo.workOffline) return;
        let response: Observable<QPMResponse>;
        response = this.webClient.getUserDetails(this.sharedData.userInfo.username);
        response.subscribe(
            (data: QPMResponse) => {
                if (this.sharedData.appInfo.logResponse) {
                    //Removing User Info from Logs
                    //QLogger.LogInfo(this.logSrc, "Get User Info  - Response : " + JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get User Info - Successful");
                    let obj = JSON.parse(data.getData());
                    if (obj !== undefined && obj !== null) {
                        this.sharedData.resetUserRoles();
                        this.sharedData.userInfo.info = obj;
                        this.sharedData.userInfo.partyId=this.sharedData.userInfo.info.partyId;
                        this.sharedData.userInfo.isDeveloper = !environment.production && environment.developer;
                        this.sharedData.userInfo.info.internal = this.sharedData.userInfo.info.internal ? true
                            : !environment.production && environment.developer;
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.ST_Manager])) {
                            this.sharedData.userInfo.isServiceTaskManager = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.Master_ST_Manager])) {
                            this.sharedData.userInfo.isMasterServiceTaskManager = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.ST_Requestor])) {
                            this.sharedData.userInfo.isRequestor = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.SW_Downloader])) {
                            this.sharedData.userInfo.isDownloader = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.Super_Admin])) {
                            this.sharedData.userInfo.isSuperAdmin = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.Beta_User])) {
                            this.sharedData.userInfo.isBetaUser = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.ST_CR_Propagation])) {
                            this.sharedData.userInfo.isStCrPropagation = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.Master_ST_CR_Propagation])) {
                            this.sharedData.userInfo.isMasterStCrPropagation = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.userInfo.info.roles.includes(UserRoleType[UserRoleType.Master_ST_Manager_BAIT])) {
                            this.sharedData.userInfo.isMasterServiceTaskManagerBait = true;
                        }
                        if (this.sharedData.userInfo.isDeveloper
                            || this.sharedData.licenses.common.toolUserRoles.includes(ToolUserRoleType[ToolUserRoleType.ADMIN])
                            || this.sharedData.licenses.common.toolUserRoles.includes(ToolUserRoleType[ToolUserRoleType.EXTL1ADMIN])) {
                            this.sharedData.userInfo.isADMINorEXTL1ADMIN = true;
                        }

                        /*this.sharedData.userInfo.isDeveloper = false;
                        this.sharedData.userInfo.info.internal = false;
                        this.sharedData.userInfo.isRequestor = false;
                        this.sharedData.userInfo.isServiceTaskManager = false;
                        this.sharedData.userInfo.isMasterServiceTaskManager = false;
                        this.sharedData.userInfo.isDownloader = false;*/
                        if(this.sharedData.userInfo.isBetaUser){
                            this.sharedData.enableBetaFeature();
                        }
                        this.sharedData.resetVisibility();
                        this.sharedData.setVisibility();
                        this.sharedData.updateVisibilitySubcribers();
                        this.sharedData.updateLoginSubcribers();
                    }
                }
                else {
                    QLogger.LogError(this.logSrc, "Get User Info Failed - " + data.getError() + " - " + data.getErrorDetail());
                }
            }
        );
    }

    checkMaintenance(){
        this.maintanenceMessage = [];
        if(this.sharedData.appInfo.logRequest){
            QLogger.LogInfo(this.logSrc, "Check for Planned Maintanence");
        }
      
        let response : Observable<QPMResponse>;
        response = this.webClient.checkMaintenance();
        response.subscribe(
          (data:QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Check for Planned Maintanence - Response : " +JSON.stringify(data));
            }   
            if(data.isSuccess()){
              QLogger.LogInfo(this.logSrc, "Check for Planned Maintanence - Success"); 
              let obj = JSON.parse(data.getData());
              if (obj !== undefined && obj !== null) {
                if (obj.message !== undefined && obj.message !== null && obj.message !== "") {
                    this.maintanenceMessage.push({severity:'info', detail:obj.message});
                }
              }
            }
            else {
              QLogger.LogError(this.logSrc, "Check for Planned Maintanence - Failed");
              QLogger.LogError(this.logSrc, "Check for Planned Maintanence - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
            }
          });
    }
    onCheckNetworkConnectionStatus(){
            
        
        let response: Observable<QPMResponse>;
        response = this.webClient.getNetworkStatus();
        response.subscribe(data=>{
            this.errorMessages=[];
            let responseCode = data.getCode();
            if(responseCode==0|| responseCode==500)
               this.errorMessages.push({severity:'error', summary:'Network Error',detail:"Qualcomm Package Manager is unable to connect to the server. Please check network connection."});
            else
               this.errorMessages.push({severity:'success', detail:"Connection to server was successful."});
        });
    }
    onDownloadLogs(){
        let logs = QLogger.GetBrowserLogs();
        const blob = new Blob([logs.join('\n')],{type: 'text/plain'});
        const url= window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = "QPM3Log.log";
        link.click();
    }
    executeCmd(command) {
        var parts = command.split(/\s+/g);
        const { spawnSync } = window.require('child_process');
        const cmd = spawnSync(parts[0], parts.slice(1), {shell: true });
        console.log(cmd);
        return cmd;
    }

    scrollToBottom(): void {
        this.loggerContainer.scroll({
            top: this.loggerContainer.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    goToUrl(url: string) {
        if (this.sharedData.appInfo.isElectronMode) {
            const { shell } = window.require('electron');
            shell.openExternal(url);
        }
        else {
            window.open(url, "_blank");
        }
    }
    onOpenFolderClick(path: string) {
        const openExplorer = window.require('open-file-explorer');
        openExplorer(path, error => {
            if (error) {
            }
            else {
                //Do Something
            }
        });
    }

    showAboutBox(versionStr: string) {
        this.aboutDialog.displayAboutBox = true;
        this.aboutDialog.versionStr = versionStr;
    }
    hideAboutBox() {
        this.aboutDialog.displayAboutBox = false;
    }

    showMessageBox(header, content, ok) {
        this.dialog.displayMessageBox = true;
        this.dialog.header = header;
        this.dialog.content = [];
        this.dialog.content.push(content);
        this.dialog.ok = ok;
    }
    showMessageBoxMultiLine(header, content: string[], ok) {
        this.dialog.displayMessageBox = true;
        this.dialog.header = header;
        this.dialog.content = [];
        this.dialog.content = content;
        this.dialog.ok = ok;
    }

    hideMessageBox() {
        this.dialog.displayMessageBox = false;
        this.dialog.header = '';
        this.dialog.content = [];
        this.dialog.ok = '';
    }

    terminalShow() {
        this.loggerPanel.terminalVisible = true;
        this.loggerPanel.terminalActive = true;
        this.loggerPanel.terminalClick = true;
    }
    terminalHide() {
        this.loggerPanel.terminalVisible = false;
        this.loggerPanel.terminalActive = false;
        this.loggerPanel.terminalClick = false;
    }
    logTerminalMessage(msg: string) {
        if (this.loggerPanel.logMessages.length > 1000) {
            this.loggerPanel.logMessages.splice(0, 1);
        }
        this.loggerPanel.logMessages.push(this.utils.getCurrentDateTime_24() + " > " + msg);
        this.scrollToBottom();
    }
    cleanTerminalMessage() {
        this.loggerPanel.logMessages = [];
    }

    toggleOverlay(event, body: string) {
        this.overlayBody = body;
        this.commonOverlay.toggle(event);
    }

    popError(message: string) {
        this.errorMessage = message;
    }
    showNetworkError() {
        this.errorMessages =[]
        this.errorMessages.push({severity:'error', summary:'Network Error',detail:"Qualcomm Package Manager cannot connect to network. Please check your internet connection"});
        this.checkNetworkError();
    }

    checkNetworkError() {
        let response: Observable<QPMResponse>;
            response = this.webClient.getNetworkStatus();
            response.subscribe(
            data=>{
                let responseCode = data.getCode();
                if(responseCode==0 || responseCode==500){
                    this.timeoutId = setTimeout(() => {
                        this.checkNetworkError();
                    }, 20000);
                }else{
                    clearTimeout(this.timeoutId);
                    this.errorMessage = '';
                    this.errorMessages=[];
                    return;
                }
            }
        );
    }
    //#endregion

    //#region Download/Ship
    startDownload() {
        this.downloadQueue.downloadInProgress = true;
        this.downloadQueue.startTimeStamp = Date.now();
        this.downloadQueue.endTimeStamp = 0;
        this.downloadStatus.totalDownloadSize = 0;
        this.downloadStatus.downloadedSize = 0;
        this.downloadStatus.isFailed = false;
        this.downloadStatus.downloadProgress = 0;
    }
    showNotification(heading:string,content:string)
    {
        this.messageService.add({key: 'notifications',severity:'info', closable: true,
        summary: heading, detail:content,life:60000});
    }
    completeDownload(softwareProductBaseBuild:SoftwareProductRelease,softwareProductTargetBuild:SoftwareProductRelease,distro:SoftwareProductDistro,downloadLocation:string,softwareImageBuild=null){
        this.downloadQueue.endTimeStamp = Date.now();
        let start = this.utils.millisecondToDateString(this.downloadQueue.startTimeStamp);
        let end = this.utils.millisecondToDateString(this.downloadQueue.endTimeStamp);
        let completeTime = this.utils.getCurrentDateTime_24();
        let timeTaken = (this.downloadQueue.endTimeStamp - this.downloadQueue.startTimeStamp) / (1000 * 60);
        let message: string[] = [];
        if (softwareProductBaseBuild== null && softwareProductTargetBuild==null) {
            if(softwareImageBuild!=null)
            message.push("Image Build  : " + softwareImageBuild);

        }
        else if (softwareProductBaseBuild== null) {
            message.push("Build  : " + softwareProductTargetBuild.softwareProductBuild);
            if(softwareImageBuild!=null)
            message.push("Image Build  : " + softwareImageBuild);
        }
        else {
            message.push("Base Build  : " + softwareProductBaseBuild.softwareProductBuild);
            message.push("Target Build  : " + softwareProductTargetBuild.softwareProductBuild);
            if(softwareProductTargetBuild.imageBuildId)
            {
                message.push("Target Image Build  : " + softwareProductTargetBuild.imageBuildId);
                message.push("Base Image Build  : " + softwareImageBuild);
            }
        }
        if(!this.downloadStatus.isFailed)
        {
            message.push("Status  : " + "Success");
        }
        else
        {
            message.push("Status  : " + "Failure"); 
        }
        message.push("Distro : " + distro.softwareDistro);
        message.push("Start  : " + start);
        message.push("End    : " + end);
        message.push("Total Time : " + timeTaken.toFixed(2) + " mins");
        this.showMessageBoxMultiLine("Download Complete", message, "Ok");
        this.updateDownloadHistory(softwareProductBaseBuild,softwareProductTargetBuild,distro,this.downloadStatus.isFailed?"Failed":"Success",completeTime.toString(),downloadLocation,softwareImageBuild);
        this.cleanDownload();
    }

    displayShipStatus(softwareBuild:string,shipStatus:string,errorMessage:string)
    {
        let message: string[] = [];
        message.push("Software Build : "+softwareBuild);
        message.push("Status  : " + shipStatus);
        if(shipStatus==='Failure')
        message.push("Failure Reason  : " + errorMessage);
        this.showMessageBoxMultiLine("Ship Request", message, "Ok");

    }
    cleanDownload() {
        this.downloadQueue.downloadInProgress = false;
        this.downloadQueue.showProgress = false;
        this.downloadQueue.startTimeStamp = 0;
        this.downloadQueue.endTimeStamp = 0;
        this.downloadStatus.totalDownloadSize = 0;
        this.downloadStatus.downloadedSize =0;
        this.downloadStatus.isFailed = false;
        this.downloadStatus.downloadProgress = 0;
        this.buildSyncResultList.SyncResultJson =[];
    }
    queueDownload(softwareProductBuild: SoftwareProductRelease, distro: SoftwareProductDistro,downloadLocation:string) {
        this.downloadQueue.softwareQueue.push({
            softwareProductBuild: softwareProductBuild,
            distro: distro,
            softwareDownloadPath: downloadLocation,
            softwareImageBuild:null
        });
        this.updateDownloadHistory(null,softwareProductBuild,distro,"Queued",null,null);
        if (!this.downloadQueue.downloadInProgress) {
            let software = this.downloadQueue.softwareQueue.shift();
            this.updateDownloadHistory(null,software.softwareProductBuild,software.distro,"In Progress",null,null);
            this.downloadSoftwareDistro(software.softwareProductBuild, software.distro,software.softwareDownloadPath);
        }
    }

    queueShipStatusRequest(softwareProductBuild: string, distroList: SoftwareProductDistroInfo[]){
        QLogger.LogInfo(this.logSrc,"Queuing Ship Status Request");
        this.shipStatusQueue.softwareQueue.push({
            softwareProductBuild : softwareProductBuild,
            distro: distroList
        });
        if(!this.shipStatusQueue.shipStatusCheckInProgress){
            this.shipStatusQueue.shipStatusCheckInProgress = true ;
            this.shipStatusTimer = setInterval(() => {
                this.updateShipStatus();
            }, 600000);  
        }
    }
    updateShipStatus()
    {
        let software = this.shipStatusQueue.softwareQueue.shift();
        let request: DistributionRequest;
        let response: Observable<QPMResponse>;
        let newDistroList:SoftwareProductDistroInfo[]=[];
        request = {
            softwareProductBuild : software?.softwareProductBuild,
            softwareDistroList : software?.distro
          };
          if(this.sharedData.appInfo.logRequest){
              QLogger.LogInfo(this.logSrc, "Ship Status Request : "+JSON.stringify(request));
          }
          response = this.webClient.getDistrosShipStatus(request);
          response.subscribe(
          (data: QPMResponse) => {
            if (data.isSuccess()) 
            {
                let shipStatusResp:shipDownloadResponse;
                shipStatusResp = JSON.parse(data.getData());
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Ship Status Response : "+JSON.stringify(shipStatusResp));
                }
                let distros: SoftwareProductDistroInfo[] = shipStatusResp.softwareDistroList;
                distros.forEach((distro) => {
                        if(distro.uploadComplete==0 || distro.uploadComplete==1)
                        {
                            newDistroList.push(distro);
                        }
                        else if(distro.uploadComplete==2)
                        {
                            this.showNotification("Upload Successfull","Ship successfull for " + software.softwareProductBuild + " Distro : "+ distro.distroName);                                
                        }
                        else
                        {
                            this.showNotification("Upload Fail","Ship failed for " + software.softwareProductBuild + " Distro : "+ distro.distroName);                                
                        }
                    });
                if(newDistroList.length!=0)
                {
                    this.shipStatusQueue.softwareQueue.push({
                        softwareProductBuild : software.softwareProductBuild,
                        distro: newDistroList
                    });
                }
            }
            else
            {
                QLogger.LogInfo(this.logSrc,"Cannot fetch Ship Status");
                this.shipStatusQueue.softwareQueue.push(software);
            }
            if(this.shipStatusQueue.softwareQueue.length==0) {
                this.terminalHide();
                clearInterval(this.shipStatusTimer);
                this.shipStatusQueue.shipStatusCheckInProgress = false;
                return;
            }
        });
    }
    queueDiffShipStatusRequest(softwareProductBaseBuild: string, softwareProductTargetBuild:string,distroList: SoftwareProductDistroInfo[]){
        QLogger.LogInfo(this.logSrc,"Queuing Diff Ship Status Request");
        this.diffShipStatusQueue.softwareQueue.push({
            softwareProductBuild : softwareProductBaseBuild,
            targetSoftwareProductBuild : softwareProductTargetBuild,
            distro: distroList
        });
        if(!this.diffShipStatusQueue.shipStatusCheckInProgress){
            this.diffShipStatusQueue.shipStatusCheckInProgress = true ;
            this.diffShipStatusTimer = setInterval(() => {
                this.updateDiffShipStatus();
            }, 300000);  
        }
    }
    updateDiffShipStatus()
    {
        let software = this.diffShipStatusQueue.softwareQueue.shift();
        let request: DiffDistributionRequest;
        let response: Observable<QPMResponse>;
        let newDistroList:SoftwareProductDistroInfo[]=[];
        request = {
            baseSoftwareProductBuild : software?.softwareProductBuild,
            targetSoftwareProductBuild :software?.targetSoftwareProductBuild,
            softwareDistroList : software?.distro
          };
          if(this.sharedData.appInfo.logRequest){
              QLogger.LogInfo(this.logSrc, "Diff Ship Status Request : "+JSON.stringify(request));
          }
          response = this.webClient.getDiffDistrosShipStatus(request);
          response.subscribe(
          (data: QPMResponse) => {
            if (data.isSuccess()) 
            {
                let shipStatusResp:diffShipDownloadResponse;
                shipStatusResp = JSON.parse(data.getData());
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Diff Ship Status Response : "+JSON.stringify(shipStatusResp));
                }
                let distros: SoftwareProductDistroInfo[] = shipStatusResp.softwareDistroList;
                distros.forEach((distro) => {
                        if(distro.uploadComplete==0 || distro.uploadComplete==1)
                        {
                            newDistroList.push(distro);
                        }
                        else if(distro.uploadComplete==2)
                        {
                            this.showNotification("Upload Successfull"," Difference between Software : " +software?.softwareProductBuild +" and "+software?.targetSoftwareProductBuild + " Distro "+distro.distroName+" shipped successfully");
                                
                        }
                        else
                        {
                            this.showNotification("Upload Failed"," Difference between Software : " +software?.softwareProductBuild +" and "+software?.targetSoftwareProductBuild + " Distro "+distro.distroName+" cannot be shipped");
                        }
                    });
                if(newDistroList.length!=0)
                {
                    this.diffShipStatusQueue.softwareQueue.push({
                        softwareProductBuild : software?.softwareProductBuild,
                        targetSoftwareProductBuild :software?.targetSoftwareProductBuild,
                        distro: newDistroList
                    });
                }
            }
            else
            {
                QLogger.LogInfo(this.logSrc,"Cannot fetch Diff Ship Status");
                this.diffShipStatusQueue.softwareQueue.push(software);
            }
            if(this.diffShipStatusQueue.softwareQueue.length==0) {
                this.terminalHide();
                clearInterval(this.diffShipStatusTimer);
                this.diffShipStatusQueue.shipStatusCheckInProgress = false;
                return;
            }
        });
    }
    queueDiffDownload(softwareProductBaseBuild: SoftwareProductRelease, softwareProductTargetBuild: SoftwareProductRelease, distro: SoftwareProductDistro, downloadPath: string) {
        this.downloadQueue.softwareQueue.push({
            softwareProductBuild: softwareProductTargetBuild,
            distro: distro,
            softwareProductBaseBuild: softwareProductBaseBuild,
            softwareDownloadPath: downloadPath,
            softwareImageBuild:null
        });
        if (!this.downloadQueue.downloadInProgress) {
            let software = this.downloadQueue.softwareQueue.shift();
            this.updateDownloadHistory(software.softwareProductBaseBuild,software.softwareProductBuild,distro,"In Progress",null,null);
            this.diffDownloadSoftwareDistro(software.softwareProductBaseBuild, software.softwareProductBuild, software.distro, software.softwareDownloadPath);
        }
    }
  downloadSoftwareDistro(softwareProductBuild: SoftwareProductRelease, distro: SoftwareProductDistro,downloadPath:string) {
        QLogger.LogInfo(this.logSrc, "Download Software : " + softwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
        let response: Observable<QPMResponse>;
        let limeResponse: Observable<QPMResponse>;
        if (this.distributionClient === null || this.downloadQueue.downloadInProgress) {
            let end =this.utils.getCurrentDateTime_24();
            this.updateDownloadHistory(null,softwareProductBuild,distro,"Failed",end.toString(),downloadPath);
            if (this.downloadQueue.softwareQueue.length !== 0) {
                let software = this.downloadQueue.softwareQueue.shift();
                this.updateDownloadHistory(null,software.softwareProductBuild,software.distro,"In Progress",null,null);
                this.downloadSoftwareDistro(software.softwareProductBuild, software.distro,software.softwareDownloadPath);
            }
            return;
        }
        this.startDownload();
        let request: DistributionRequest;
        let distroList: SoftwareProductDistroInfo[] = [];
        let distroInfo = new SoftwareProductDistroInfo();
        let requestedPlatform : Number;
        if(this.sharedData.appInfo.isElectronMode)
        requestedPlatform = 0;
        else
        requestedPlatform = 1;
        let softwareDownloadResponse = new SoftwareProductDownload();
        distroInfo.distroName = distro.softwareDistro;
        distroInfo.distroId = Number(distro.softwareDistroId);
        distroInfo.uploadComplete = distro.uploadComplete;
        distroList.push(distroInfo);
        request = {
            softwareProductBuild: softwareProductBuild.softwareProductBuild,
            softwareDistroList: distroList,
            requestedPlatform: requestedPlatform
        };
        if (this.sharedData.appInfo.logRequest) {
            QLogger.LogInfo(this.logSrc, "Download Request : " + JSON.stringify(request));
        }

        response = this.distributionClient.requestDownloadSoftwareImage(request);
        response.subscribe(
            (data: QPMResponse) => {
                if (this.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Download Response : " + JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    let downloadResponse:SoftwareDownloadResponse ;
                    downloadResponse = JSON.parse(data.getData());
                    if(this.sharedData.appInfo.isElectronMode)
                    {
                        limeResponse = this.distributionClient.processDownloadSoftwareImage(softwareProductBuild.softwareProductBuild,
                            softwareProductBuild.softwareProduct,
                            softwareProductBuild.branch,
                            softwareProductBuild.tag,
                            distro.softwareDistro,
                            distro.softwareDistroFolderName,
                            downloadPath,
                            data.getData());
                        limeResponse.subscribe((data: QPMResponse) => {
                            if (this.sharedData.appInfo.logResponse) {
                                QLogger.LogInfo(this.logSrc, "Download Native Response : " + JSON.stringify(data));
                            }
                            if (data.isSuccess()) {
                                this.downloadQueue.setCredentialFailed = false;
                                softwareDownloadResponse = JSON.parse(data.getData());
                                softwareDownloadResponse.aboutHtmlSignedUrl = downloadResponse.distroDownloadResponse[0].aboutHtmlSignedUrl;
                                this.startDownloadSession(softwareDownloadResponse,softwareProductBuild, distro,downloadResponse.distroDownloadResponse[0]?.trackingId,downloadPath);
                                this.logTerminalMessage("Download for " + softwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro + " Started at : " + this.utils.getCurrentDateTime());
                            }
                            else
                            {
                                QLogger.LogError(this.logSrc, "Download Software Failed: " + softwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                                let end =this.utils.getCurrentDateTime_24();
                                let downloadCompleteStatus = new DownloadStatusRequest();
                                downloadCompleteStatus.trackingId = downloadResponse.distroDownloadResponse[0]?.trackingId;
                                downloadCompleteStatus.isDownloadSuccessfull = false;
                                this.distributionClient.updateDownloadStatus(downloadCompleteStatus).subscribe();
                                this.updateDownloadHistory(null,softwareProductBuild,distro,"Failed",end.toString(),null);
                                this.cleanDownload();
                                if (this.downloadQueue.softwareQueue.length !== 0) {
                                    let software = this.downloadQueue.softwareQueue.shift();
                                    this.updateDownloadHistory(null,software.softwareProductBuild,software.distro,"In Progress",null,null);
                                    this.downloadSoftwareDistro(software.softwareProductBuild, software.distro,software.softwareDownloadPath);
                                }
    
                            }
                        });
                    }
                    else
                    {
                        downloadResponse.distroDownloadResponse.forEach(distroDownloadInfo => {
                            let downloadCompleteStatus = new DownloadStatusRequest();
                            downloadCompleteStatus.trackingId = distroDownloadInfo.trackingId;
                            if(distroDownloadInfo.aboutHtmlSignedUrl!=undefined)
                                window.open(distroDownloadInfo.aboutHtmlSignedUrl, '_blank');
                            distroDownloadInfo.imageBuildDownloadResponse.forEach(image =>{
                                let fileName = image.signedUrl?.split('/')?.pop()?.split('?')[0];
                                QLogger.LogError(this.logSrc,"Downloading " +image.imageBuildId +" using URL "+image.signedUrl);
                                var popUpWin = window.open(image.signedUrl, '_blank');
                                
                                if(!popUpWin || popUpWin.closed || typeof popUpWin.closed=='undefined') 
                                { 
                                    this.showMessageBox("Popup Blocker Detection", "Please disable browser popup blocker", "Ok");
                                }
             

                            });
                            downloadCompleteStatus.isDownloadSuccessfull = true;
                            this.distributionClient.updateDownloadStatus(downloadCompleteStatus).subscribe();
                            this.cleanDownload();

                        });
                    }

                }
                else {
                    QLogger.LogError(this.logSrc, "Download Software Failed: " + softwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                    this.showMessageBox("Download", "Download failed for " + softwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro, "Ok");
                    let end =this.utils.getCurrentDateTime_24();
                    this.updateDownloadHistory(null,softwareProductBuild,distro,"Failed",end.toString(),downloadPath);
                    this.cleanDownload();
                    if (this.downloadQueue.softwareQueue.length !== 0) {
                        let software = this.downloadQueue.softwareQueue.shift();
                        this.updateDownloadHistory(null,software.softwareProductBuild,software.distro,"In Progress",null,null);
                        this.downloadSoftwareDistro(software.softwareProductBuild, software.distro,software.softwareDownloadPath);
                    }
                }
            });
    }
    downloadMetaBuild(softwareProductBuild: SoftwareProductRelease, distro: SoftwareProductDistro,downloadId:Number,imageInfo:SoftwareImageDownload,localFolderLocation:string,imageBuildCount:Number,downloadPath:string)
    {
        let sofwaterDownloadLocation = downloadPath;
       
        let downloadCompleteStatus = new DownloadStatusRequest();
        downloadCompleteStatus.trackingId = downloadId;
        try
        {
          let fileName = imageInfo.downloadUrl?.split('/')?.pop()?.split('?')[0];
          if(this.sharedData.appInfo.isElectronMode)
          {
            let headers:HttpHeaders;
            headers = new HttpHeaders({ 'Access-Control-Allow-Origin' : '*'});
            this.http.get(imageInfo.downloadUrl, {headers: headers,
                'responseType': 'arraybuffer','reportProgress':true,observe: "events"}).subscribe((event: HttpEvent<any>) => {
                switch (event.type) 
                {
                    case HttpEventType.Response:
                    {
                        let buildResult = new SoftwareImageSyncResult();

                        buildResult.softwareImageBuild = imageInfo.imageBuildId;
                        buildResult.isSuccessfullDownload = "true";
                        if(event.status!=200)
                        {
                            QLogger.LogError(this.logSrc,"Cannot Downloaded "+softwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                            this.downloadStatus.isFailed = true;
                            buildResult.isSuccessfullDownload = "false";
                            this.logTerminalMessage("Download Failed for "+imageInfo.imageBuildId);
                        }
                        else
                        { 
                            const buffer = Buffer.from(event.body); 
                            const fs = window.require('fs');          
                            fs.writeFile(imageInfo.downloadCommand.cwd+"/"+fileName, buffer,(err) =>this.zone.run(() => {
                                if (err || buffer.length===0)
                                {
                                    QLogger.LogError(this.logSrc,"Cannot Downloaded "+softwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                                    this.downloadStatus.isFailed = true;
                                    buildResult.isSuccessfullDownload = "false";
                                    QLogger.LogError(this.logSrc,"Download Failed for "+imageInfo.imageBuildId +"Cannot write to storage");
                                    this.logTerminalMessage("Download Failed for "+imageInfo.imageBuildId +"Cannot write to storage");
                                }
                                else
                                {
                                    QLogger.LogError(this.logSrc,"Download Success for "+imageInfo.imageBuildId);
                                    this.logTerminalMessage("Download Success for "+imageInfo.imageBuildId );
                                }

                            }));             
                        
                        
                       }
                       this.buildSyncResultList.SyncResultJson.push(buildResult);
                       if(this.buildSyncResultList.SyncResultJson.length==imageBuildCount)
                       {
                        this.completeDownload(null, softwareProductBuild, distro,sofwaterDownloadLocation);
                        downloadCompleteStatus.isDownloadSuccessfull = !this.downloadStatus.isFailed;
                        this.distributionClient.generateProductMetadata(softwareProductBuild.softwareProductBuild, distro.softwareDistro, distro.softwareDistroFolderName, this.buildSyncResultList);
                        this.distributionClient.updateDownloadStatus(downloadCompleteStatus).subscribe();
                        this.terminalHide();
                       }
                      break;
                    }
                }
            },
            error =>{
                this.markDownloadFailed(imageInfo);
                if(this.buildSyncResultList.SyncResultJson.length==imageBuildCount)
                {
                 this.completeDownload(null, softwareProductBuild, distro,sofwaterDownloadLocation);
                 downloadCompleteStatus.isDownloadSuccessfull = !this.downloadStatus.isFailed;
                 this.distributionClient.generateProductMetadata(softwareProductBuild.softwareProductBuild, distro.softwareDistro, distro.softwareDistroFolderName, this.buildSyncResultList);
                 this.distributionClient.updateDownloadStatus(downloadCompleteStatus).subscribe();
                 this.terminalHide();
                }
            });
          }
        }
        catch(e)
        {
            this.markDownloadFailed(imageInfo);
            if(this.buildSyncResultList.SyncResultJson.length==imageBuildCount)
            {
             this.completeDownload(null, softwareProductBuild, distro,sofwaterDownloadLocation);
             downloadCompleteStatus.isDownloadSuccessfull = !this.downloadStatus.isFailed;
             this.distributionClient.generateProductMetadata(softwareProductBuild.softwareProductBuild, distro.softwareDistro, distro.softwareDistroFolderName, this.buildSyncResultList);
             this.distributionClient.updateDownloadStatus(downloadCompleteStatus).subscribe();
             this.terminalHide();
            }
        }
    }
    markDownloadFailed(imageInfo:SoftwareImageDownload)
    {
        let buildResult = new SoftwareImageSyncResult();
        buildResult.softwareImageBuild = imageInfo.imageBuildId;
        QLogger.LogError(this.logSrc,"Cannot Downloaded "+imageInfo.imageBuildId );
        this.downloadStatus.isFailed = true;
        buildResult.isSuccessfullDownload = "false";
        this.logTerminalMessage("Download Failed for "+imageInfo.imageBuildId);
        this.buildSyncResultList.SyncResultJson.push(buildResult);

    }
    startDownloadSession(sofwareDownloadResponse: SoftwareProductDownload,targetBuildId: SoftwareProductRelease, distro: SoftwareProductDistro, downloadId:Number,downloadPath:string) {
        QLogger.LogInfo(this.logSrc,"Starting Download Using Signed URL");
        this.terminalShow();
        this.startDownload();
        let aboutHtml = new SoftwareImageDownload();
        aboutHtml.downloadUrl = sofwareDownloadResponse.aboutHtmlSignedUrl;
        aboutHtml.imageBuildId = "about.html";
        aboutHtml.downloadCommand = new SoftwareImageDownloadCommand();
        aboutHtml.downloadCommand.cwd = sofwareDownloadResponse.localFolderLocation;
        this.downloadMetaBuild(targetBuildId,distro,downloadId,aboutHtml,sofwareDownloadResponse.localFolderLocation,sofwareDownloadResponse.imageBuilds.length + 1,downloadPath);
        sofwareDownloadResponse.imageBuilds.forEach(image => {
                this.logTerminalMessage("Start Download for "+image.imageBuildId);
                this.downloadMetaBuild(targetBuildId,distro,downloadId,image,sofwareDownloadResponse.localFolderLocation,sofwareDownloadResponse.imageBuilds.length + 1,downloadPath);
        });

    }

    diffDownloadSoftwareDistro(softwareProductBaseBuild: SoftwareProductRelease, softwareProductTargetBuild: SoftwareProductRelease, distro: SoftwareProductDistro, downloadPath: string) {
        QLogger.LogInfo(this.logSrc, "Download difference between Software : " + softwareProductTargetBuild.softwareProductBuild + " and " + softwareProductBaseBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
        let response: Observable<QPMResponse>;
        if (this.distributionClient === null || this.downloadQueue.downloadInProgress) {
            let end =this.utils.getCurrentDateTime_24();
            this.updateDownloadHistory(softwareProductBaseBuild,softwareProductTargetBuild,distro,"Failed",end.toString(),null); 
            return;
        }        
        this.startDownload();
        let request: DiffDistributionRequest;
        let distroList: SoftwareProductDistroInfo[] = [];
        let distroInfo = new SoftwareProductDistroInfo();
        let requestedPlatform : Number;
        if(this.sharedData.appInfo.isElectronMode)
        requestedPlatform = 0;
        else
        requestedPlatform = 1;
        distroInfo.distroName = distro.softwareDistro;
        distroInfo.distroId = Number(distro.softwareDistroId);
        distroInfo.uploadComplete = distro.uploadComplete;
        distroList.push(distroInfo);
        request = {
            baseSoftwareProductBuild: softwareProductBaseBuild.softwareProductBuild,
            targetSoftwareProductBuild: softwareProductTargetBuild.softwareProductBuild,
            softwareDistroList: distroList,
            requestedPlatform:requestedPlatform
        };
        if (this.sharedData.appInfo.logRequest) {
            QLogger.LogInfo(this.logSrc, "Download Request : " + JSON.stringify(request));
        }

        response = this.distributionClient.diffDownloadSoftwareProduct(request);
        response.subscribe(
            (data: QPMResponse) => {
                if (this.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Download Response : " + JSON.stringify(data));
                }
                if (data.isSuccess()) 
                {
                    let downloadResponse:SoftwareDiffDownloadResponse;
                    downloadResponse = JSON.parse(data.getData());
                    downloadResponse?.distroDownloadResponse.forEach((distroInfo) =>{
                    let diffDownloadTrackingID = distroInfo.trackingId;
                    if(distroInfo.uploadComplete===2){
                        if(distroInfo.spSignedUrl!=null){
                            this.StartDownload(distroInfo.spSignedUrl,softwareProductBaseBuild, softwareProductTargetBuild, distro,downloadPath,diffDownloadTrackingID,null);
                        }
                        else{
                            let successImageCount = 0;
                            distroInfo.imageBuildDownloadResponse.forEach(imageDownload=>{
                                  
                                  if(imageDownload.signedUrl!=undefined)
                                    successImageCount = successImageCount +1;

                            });
                            if(successImageCount!=distroInfo.imageBuildDownloadResponse.length){
                                let end =this.utils.getCurrentDateTime_24();
                                this.updateDownloadHistory(softwareProductBaseBuild,softwareProductTargetBuild,distro,"Failed",end.toString(),null); 
                                this.showMessageBox("Download", "Difference between Software : " + softwareProductTargetBuild.softwareProductBuild + " and " + softwareProductBaseBuild.softwareProductBuild + " Distro : " + distro.softwareDistro+" not available for Download.", "Ok");
                                this.cleanDownload();   
                                return;
                            }
                            if(this.sharedData.appInfo.isElectronMode){
                                this.terminalShow();
                                QLogger.LogInfo(this.logSrc,"Downloading diff");
                                this.logTerminalMessage("Started Downloading difference between Software : " + softwareProductTargetBuild.softwareProductBuild + " and " + softwareProductBaseBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                                this.StartDiffDownload(distroInfo.aboutHtmlSignedUrl,softwareProductBaseBuild, softwareProductTargetBuild, distro,downloadPath,diffDownloadTrackingID,distroInfo.imageBuildDownloadResponse.length+1,"about.html");
                                distroInfo.imageBuildDownloadResponse.forEach(imageDownload=>{
                                   this.logTerminalMessage("Started Downloading difference for image : "+imageDownload.imageBuildId+ " Distro : " + distro.softwareDistro);
                                    this.StartDiffDownload(imageDownload.signedUrl,softwareProductBaseBuild, softwareProductTargetBuild, distro,downloadPath,diffDownloadTrackingID,distroInfo.imageBuildDownloadResponse.length+1,imageDownload.imageBuildId);

                                });

                            }
                            else
                            {
                                let downloadCompleteStatus = new DownloadStatusRequest();
                                downloadCompleteStatus.trackingId =diffDownloadTrackingID;
                                //Downloading HTML File 
                                if(distroInfo.aboutHtmlSignedUrl!=undefined)
                                    window.open(distroInfo.aboutHtmlSignedUrl, '_blank');
                                distroInfo.imageBuildDownloadResponse.forEach(imageDownload=>{
                                    QLogger.LogError(this.logSrc,"Downloading Difference for  " +imageDownload.imageBuildId +" using URL "+imageDownload.signedUrl);
                                    var ImgDownloadPopUpWin = window.open(imageDownload.signedUrl, '_blank');
                                    if(!ImgDownloadPopUpWin || ImgDownloadPopUpWin.closed || typeof ImgDownloadPopUpWin.closed=='undefined') 
                                    { 
                                        this.showMessageBox("Popup Blocker Detection", "Please disable browser popup blocker", "Ok");
                                    }
                                
                                });
                                downloadCompleteStatus.isDownloadSuccessfull = true;
                                this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
                                this.cleanDownload(); 
                            }
                          }
                       }
                       else{
                        let end =this.utils.getCurrentDateTime_24();
                        this.updateDownloadHistory(softwareProductBaseBuild,softwareProductTargetBuild,distro,"Failed",end.toString(),null); 
                        this.showMessageBox("Download", "Difference between Software : " + softwareProductTargetBuild.softwareProductBuild + " and " + softwareProductBaseBuild.softwareProductBuild + " Distro : " + distro.softwareDistro+" not available for Download.", "Ok");
                        this.cleanDownload();      
                       }
                    });

                }
                else {
                    QLogger.LogError(this.logSrc, "Cannot Download difference between Software : " + softwareProductTargetBuild.softwareProductBuild + " and " + softwareProductBaseBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                    let end =this.utils.getCurrentDateTime_24();
                    this.updateDownloadHistory(softwareProductBaseBuild,softwareProductTargetBuild,distro,"Failed",end.toString(),null); 
                    this.showMessageBox("Download", "Cannot Download difference between Software : " + softwareProductTargetBuild.softwareProductBuild + " and " + softwareProductBaseBuild.softwareProductBuild + " Distro : " + distro.softwareDistro, "Ok");
                    this.cleanDownload();
                }
            });
    }
    StartDiffDownload(signedUrl: string, baseSoftwareProductBuild: SoftwareProductRelease, targetSoftwareProductBuild: SoftwareProductRelease, distro: SoftwareProductDistro,downloadPath:string,diffDownloadTrackingId:Number,imageBuildCount:Number,buildId:string)
    {

        let downloadCompleteStatus = new DownloadStatusRequest();
        downloadCompleteStatus.trackingId = diffDownloadTrackingId;
        try
        {
            let fileName = signedUrl?.split('/')?.pop()?.split('?')[0];
            this.downloadStatus.baseSoftwareBuild = baseSoftwareProductBuild.softwareProductBuild;
            this.downloadStatus.targetSoftwareBuild = targetSoftwareProductBuild.softwareProductBuild;
            this.downloadStatus.fileName = fileName;
            this.downloadStatus.distroName = distro.softwareDistro;
            let headers:HttpHeaders;
            headers = new HttpHeaders({ 'Access-Control-Allow-Origin' : '*'});
            this.http.get(signedUrl, {headers: headers,
                'responseType': 'arraybuffer','reportProgress':true,observe: "events"}).subscribe((event: HttpEvent<any>) => {
                switch (event.type) 
                {
                    case HttpEventType.Response:
                    {
                        
                        if(event.status!=200)
                        {
                            QLogger.LogError(this.logSrc,"Download Failed - Cannot Write File to Storage "+buildId);
                            this.downloadStatus.isFailed = true;
                            this.logTerminalMessage("Download Failed for "+buildId );
                        }
                        else
                        { 
                            const buffer = Buffer.from(event.body); 
                            const fs = window.require('fs');      
                            let downloadLocation = null;
                            downloadLocation = downloadPath+"/"+fileName;
                            fs.writeFile(downloadLocation, buffer,(err) =>this.zone.run(() => {
                                if (err || buffer.length===0)
                                {
                                    QLogger.LogError(this.logSrc,"Cannot Downloaded Difference between "+targetSoftwareProductBuild.softwareProductBuild + " and " + baseSoftwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                                    this.downloadStatus.isFailed = true;
                                    this.logTerminalMessage("Download Failed for "+buildId );
                                }
                                else
                                   this.logTerminalMessage("Download Success for "+buildId );
                            }));    
                      }
                      this.downloadQueue.suceessfullDownloadCount = this.downloadQueue.suceessfullDownloadCount+1;
                      if(this.downloadQueue.suceessfullDownloadCount===imageBuildCount){
                        downloadCompleteStatus.isDownloadSuccessfull = !this.downloadStatus.isFailed;
                        this.completeDownload(baseSoftwareProductBuild, targetSoftwareProductBuild, distro,downloadPath,null);
                        this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
                        this.terminalHide();
                      }
                     
                      break;
                    }
                }
            },
            error =>{
                QLogger.LogError(this.logSrc,"Error During Download for "+buildId);
                this.downloadStatus.isFailed = true;
                this.downloadQueue.suceessfullDownloadCount = this.downloadQueue.suceessfullDownloadCount+1;
                if(this.downloadQueue.suceessfullDownloadCount===imageBuildCount){
                  downloadCompleteStatus.isDownloadSuccessfull = !this.downloadStatus.isFailed;
                  this.completeDownload(baseSoftwareProductBuild, targetSoftwareProductBuild, distro,downloadPath,null);
                  this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
                  this.cleanDownload(); 
                  this.terminalHide();
                }
                
            });
          
        }
        catch(e)
        {
          QLogger.LogError(this.logSrc,"Failed to diff download Excpetion occoured "+buildId);
          if(this.downloadQueue.suceessfullDownloadCount===imageBuildCount){
            downloadCompleteStatus.isDownloadSuccessfull = !this.downloadStatus.isFailed;
            this.completeDownload(baseSoftwareProductBuild, targetSoftwareProductBuild, distro,downloadPath,null);
            this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
            this.cleanDownload(); 
            this.terminalHide();
          }
        }
    }
    StartDownload(signedUrl: string, baseSoftwareProductBuild: SoftwareProductRelease, targetSoftwareProductBuild: SoftwareProductRelease, distro: SoftwareProductDistro,downloadPath:string,diffDownloadTrackingId:Number,softwareImageBuild:string)
    {
        let downloadCompleteStatus = new DownloadStatusRequest();
        downloadCompleteStatus.trackingId = diffDownloadTrackingId;
        let imageDownloadCompleteStatus = new DownloadImageStatusRequest();
        if(undefined!=targetSoftwareProductBuild?.imageBuildId)
        imageDownloadCompleteStatus.stardustDiffImgTrackingId = diffDownloadTrackingId;
        else
        imageDownloadCompleteStatus.stardustImgTrackingId = diffDownloadTrackingId;
        try
        {
          let fileName = signedUrl?.split('/')?.pop()?.split('?')[0];
          if(this.sharedData.appInfo.isElectronMode)
          {
            if(softwareImageBuild==null)
            {
                this.downloadStatus.baseSoftwareBuild = baseSoftwareProductBuild.softwareProductBuild;
                this.downloadStatus.targetSoftwareBuild = targetSoftwareProductBuild.softwareProductBuild;
                this.downloadStatus.fileName = fileName;
            }
            else 
            {
                  if(targetSoftwareProductBuild?.imageBuildId)
                  this.downloadStatus.fileName = softwareImageBuild+"_"+targetSoftwareProductBuild.imageBuildId;
                  else
                  this.downloadStatus.fileName = softwareImageBuild;
            }
           
            this.downloadStatus.distroName = distro.softwareDistro;
            
            this.downloadQueue.showProgress = true;
            let headers:HttpHeaders;
            headers = new HttpHeaders({ 'Access-Control-Allow-Origin' : '*'});
            this.http.get(signedUrl, {headers: headers,
                'responseType': 'arraybuffer','reportProgress':true,observe: "events"}).subscribe((event: HttpEvent<any>) => {
                switch (event.type) 
                {
                    case HttpEventType.DownloadProgress:
                    {
                        this.zone.run(() => {
                            this.downloadStatus.totalDownloadSize  = event.total;
                            this.downloadStatus.downloadedSize = event.loaded;
                            this.downloadStatus.downloadedSizeString = this.utils.bytesToSizeString(this.downloadStatus.downloadedSize);
                            this.downloadStatus.downloadProgress = (this.downloadStatus.downloadedSize *100)/this.downloadStatus.totalDownloadSize;
                            this.downloadStatus.totalDownloadSize = Math.round((this.downloadStatus.totalDownloadSize)*100)/100;
                            this.downloadStatus.totalDownloadSizeString = this.utils.bytesToSizeString(this.downloadStatus.totalDownloadSize);
                            this.downloadStatus.downloadedSize =  Math.round((this.downloadStatus.downloadedSize)*100)/100;
                            this.downloadStatus.downloadedSizeString = this.utils.bytesToSizeString(this.downloadStatus.downloadedSize);
                            let end = Date.now();
                            let timeTaken = (end - this.downloadQueue.startTimeStamp) / (1000);
                            this.downloadStatus.downloadSpeed = Math.round((this.downloadStatus.downloadedSize/(timeTaken))*100)/100;
                            this.downloadStatus.downloadSpeedString = this.utils.bytesToSizeString(this.downloadStatus.downloadSpeed);
                        });
                        break;
                    }
                    case HttpEventType.Response:
                    {
                        
                        if(event.status!=200)
                        {
                            QLogger.LogError(this.logSrc,"Download Failed - Cannot Write File to Storage");
                            this.downloadStatus.isFailed = true;
                            downloadCompleteStatus.isDownloadSuccessfull = false;
                            imageDownloadCompleteStatus.isImageDownloadSuccessfull = false;
                            if(softwareImageBuild==null)
                            this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
                            else 
                            {
                                if(undefined!=targetSoftwareProductBuild?.imageBuildId)
                                this.distributionClient.updateDiffImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                                else
                                this.distributionClient.updateImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                            }
                            
                            this.completeDownload(baseSoftwareProductBuild, targetSoftwareProductBuild, distro,downloadPath,softwareImageBuild);
                        }
                        else
                        { 
                            const buffer = Buffer.from(event.body); 
                            const fs = window.require('fs');      
                            let downloadLocation = null;
                            if(softwareImageBuild==null)
                            downloadLocation = downloadPath+"/"+fileName;
                            else
                            {
                                downloadLocation = downloadPath+"/"+distro.softwareDistroFolderName+fileName;
                            }
                            fs.writeFile(downloadLocation, buffer,(err) =>this.zone.run(() => {
                                if (err || buffer.length===0)
                                {
                                    if(softwareImageBuild==null)
                                    QLogger.LogError(this.logSrc,"Cannot Downloaded Difference between "+targetSoftwareProductBuild.softwareProductBuild + " and " + baseSoftwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                                    else
                                    QLogger.LogError(this.logSrc, "Cannot Download  Software Image: " + softwareImageBuild + " Distro : " + distro.softwareDistro);
                                    this.downloadStatus.isFailed = true;
                                }
                                else
                                {
                                    this.downloadStatus.isFailed = false;
                                }

                                downloadCompleteStatus.isDownloadSuccessfull = !this.downloadStatus.isFailed;
                                imageDownloadCompleteStatus.isImageDownloadSuccessfull = !this.downloadStatus.isFailed;
                                this.completeDownload(baseSoftwareProductBuild, targetSoftwareProductBuild, distro,downloadPath,softwareImageBuild);
                                if(softwareImageBuild==null)
                                this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
                                else 
                                {
                                    if(undefined!=targetSoftwareProductBuild?.imageBuildId)
                                    this.distributionClient.updateDiffImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                                    else
                                    this.distributionClient.updateImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                                }

                            }));    
                      }
                      this.downloadQueue.showProgress = false;
                      break;
                    }
                }
            },
            error =>{
                QLogger.LogError(this.logSrc,"Error During Download ");
                let end = this.utils.millisecondToDateString(Date.now());
                this.updateDownloadHistory(baseSoftwareProductBuild,targetSoftwareProductBuild,distro,"Failed",end.toString(),null,softwareImageBuild);
                downloadCompleteStatus.isDownloadSuccessfull = false;
                imageDownloadCompleteStatus.isImageDownloadSuccessfull = false;
                if(softwareImageBuild==null)
                {
                    QLogger.LogError(this.logSrc,"Cannot Downloaded Difference between "+targetSoftwareProductBuild.softwareProductBuild + " and " + baseSoftwareProductBuild.softwareProductBuild + " Distro : " + distro.softwareDistro);
                  this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
                }
                else 
                {
                    if(undefined!=targetSoftwareProductBuild?.imageBuildId)
                    {
                        this.distributionClient.updateDiffImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                        this.showMessageBox("Download", "Cannot Download Difference between Software Image: " + softwareImageBuild + "and " +baseSoftwareProductBuild.imageBuildId + " Distro : " + distro.softwareDistro, "Ok");
                    }
                    else
                    {
                        this.showMessageBox("Download", "Cannot Download  Software Image: " + softwareImageBuild + " Distro : " + distro.softwareDistro, "Ok");
                        this.distributionClient.updateImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                    }
                }
                this.cleanDownload(); 
            });
          }
          else
          {
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.href = signedUrl;
            a.download = fileName;
            a.click();
            document.body.removeChild(a);
            downloadCompleteStatus.isDownloadSuccessfull = true;
            imageDownloadCompleteStatus.isImageDownloadSuccessfull = true;
            if(softwareImageBuild==null)
            this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
            else 
            {
                if(undefined!=targetSoftwareProductBuild?.imageBuildId)
                this.distributionClient.updateDiffImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                else
                this.distributionClient.updateImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
            } 
            this.cleanDownload();
          }
        }
        catch(e)
        {
          this.terminalHide();
          let end = this.utils.millisecondToDateString(Date.now());
          this.updateDownloadHistory(baseSoftwareProductBuild,targetSoftwareProductBuild,distro,"Failed",end.toString(),null,softwareImageBuild);
         
          downloadCompleteStatus.isDownloadSuccessfull = false;
          imageDownloadCompleteStatus.isImageDownloadSuccessfull = false;
          if(softwareImageBuild==null)
          {
            this.showMessageBox("Download", "Cannot Download difference between Software : " + baseSoftwareProductBuild + " and " + targetSoftwareProductBuild + " Distro : " + distro.softwareDistro, "Ok");
            this.distributionClient.updateDiffDownloadStatus(downloadCompleteStatus).subscribe();
          }
          else 
          {
            if(undefined!=targetSoftwareProductBuild?.imageBuildId)
            {
                this.distributionClient.updateDiffImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
                this.showMessageBox("Download", "Cannot Download Difference between Software Image: " + softwareImageBuild + "and " +baseSoftwareProductBuild.imageBuildId + " Distro : " + distro.softwareDistro, "Ok");
            }
            else
            {
                this.showMessageBox("Download", "Cannot Download  Software Image: " + softwareImageBuild + " Distro : " + distro.softwareDistro, "Ok");
                this.distributionClient.updateImageDownloadStatus(imageDownloadCompleteStatus).subscribe();
            }
          }
          this.cleanDownload(); 
        }
    }
    
    diffShipSoftwareProductBuild(softwareProductBaseBuild: SoftwareProductRelease,softwareProductTargetBuild: SoftwareProductRelease, distros: SoftwareProductDistro[],serviceTakID:Number) {
        QLogger.LogInfo(this.logSrc, "Ship difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild);
        let response: Observable<QPMResponse>;
        let distroList:SoftwareProductDistroInfo[] = [];
        let req:DiffDistributionRequest;
        let requestedPlatform : Number;
        if (this.distributionClient === null) {
          return
        }
        if(this.sharedData.appInfo.isElectronMode)
        requestedPlatform = 0;
        else
        requestedPlatform = 1;
        distros.forEach(function (distro) 
        {
          let info  = new SoftwareProductDistroInfo();  
          info.distroName =  distro.softwareDistro;
          info.distroId   = Number(distro.softwareDistroId);
          info.uploadComplete = 0;
          info.softwareDistroAlternateId = distro.softwareDistroAlternateId
          distroList.push(info);
        });
    
        req = {
          baseSoftwareProductBuild : softwareProductBaseBuild.softwareProductBuild,
          targetSoftwareProductBuild: softwareProductTargetBuild.softwareProductBuild,
          softwareDistroList : distroList,
          requestedPlatform: requestedPlatform,
          serviceTaskId: serviceTakID
        };
        if(this.sharedData.appInfo.logRequest){
          QLogger.LogInfo(this.logSrc, "Diff Ship Software Success Request: " + JSON.stringify(req));
        }
        response = this.distributionClient.diffShipSoftwareProduct(req);
        response.subscribe(
          (data: QPMResponse) => {
            if (this.sharedData.appInfo.logResponse) {
              QLogger.LogInfo(this.logSrc, "Diff Ship Software Success Response: " + JSON.stringify(data));
            }
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Diff Ship Software Success:" + softwareProductTargetBuild.softwareProductBuild);
              this.queueDiffShipStatusRequest(softwareProductBaseBuild.softwareProductBuild,softwareProductTargetBuild.softwareProductBuild,distroList);
            }
            else {
              QLogger.LogError(this.logSrc, "Diff Ship Software Failed:" + softwareProductTargetBuild.softwareProductBuild);
              QLogger.LogError(this.logSrc, "Diff Ship Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
              this.showMessageBox("Diff Ship Request","Ship difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild +" is failed. " + data.getError(), "Ok");
            }
          }
        );
        this.showMessageBox("Diff Ship Request", "Ship Request for difference between  " + softwareProductTargetBuild.softwareProductBuild + " and "+ softwareProductBaseBuild.softwareProductBuild + " has been placed", "Ok");
    }

    diffDownloadSoftwareDistros(softwareProductBaseBuild: SoftwareProductRelease, softwareProductTargetBuild: SoftwareProductRelease,distro: SoftwareProductDistro,downloadPath:string) {
        QLogger.LogInfo(this.logSrc, "Download difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild);// + " Distro : " + "AMSS Standard OEM");
        this.queueDiffDownload(softwareProductBaseBuild, softwareProductTargetBuild,distro,downloadPath);
        this.logTerminalMessage("Download difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild + "  Queued " +"at " + this.utils.getCurrentDateTime());
    }

    getAvailableDistrosForDownload(softwareBaseBuild:string,availableDiffBuilds:SoftwareDiffBuildRelease[],entitledDistroList:SoftwareProductDistro[]):SoftwareProductDistro[]
    {
        let availableDistroMap: Map<string, SoftwareProductDistroInfo> = new Map<string, SoftwareProductDistroInfo>();
        let avaiableSoftwareDistro:SoftwareProductDistro[];
        avaiableSoftwareDistro =[];
        availableDiffBuilds?.forEach((availableDiffBuild)=> {
          if(availableDiffBuild.targetSoftwareProductBuild == softwareBaseBuild)
          {
              availableDiffBuild.distroList.forEach((distro)=>{
      
                availableDistroMap.set(distro.distroName,distro);
              });
          }
        });
        
        entitledDistroList.forEach((distro) => {
          let newDistro: SoftwareProductDistro = new SoftwareProductDistro();
          newDistro.softwareDistro = distro.softwareDistro;
          newDistro.distroType = distro.distroType;
          newDistro.softwareDistroAlternateId = distro.softwareDistroAlternateId;
          newDistro.softwareDistroId = distro.softwareDistroId;
          newDistro.stream = distro.stream;
          newDistro.softwareDistroFolderName = distro.softwareDistroFolderName;
          newDistro.binDdmStatus=distro.binDdmStatus;//required for BIN_DDM Approved distro check to  Download diff SP
          newDistro.binDdmRecordSummary=distro.binDdmRecordSummary;//required for BIN_DDM Approved distro check to  Download diff SP
          if(availableDistroMap.has(distro.softwareDistro)){
            newDistro.uploadComplete = availableDistroMap.get(distro.softwareDistro).uploadComplete;
            if(newDistro.uploadComplete != 4 ){
              if(newDistro.uploadComplete==0) newDistro.status ="Queued";
              if(newDistro.uploadComplete==1) newDistro.status ="In Progress";
              if(newDistro.uploadComplete==2) newDistro.status ="Success";
              if(newDistro.uploadComplete==3)
              {
                newDistro.status ="Failed";
                
              }
              avaiableSoftwareDistro.push(newDistro);
            }
          }
        });     
        return avaiableSoftwareDistro;
    }
      
    downloadSoftwareImageBuild(softwareProductBuild: SoftwareProductRelease, softwareImageBuild: string,distro: SoftwareProductDistro,downloadPath:string) {
        QLogger.LogInfo(this.logSrc, "Downloading Image : " +softwareImageBuild  + " Distro : " + distro.softwareDistro);
        this.queueImageDownload(softwareProductBuild, softwareImageBuild,distro,downloadPath);
        this.logTerminalMessage("Downloading Image : " +softwareImageBuild  + " Distro : " + distro.softwareDistro + "  Queued " +"at " + this.utils.getCurrentDateTime());
    }

    queueImageDownload(softwareProductBuild: SoftwareProductRelease, softwareImageId:string,distro: SoftwareProductDistro,downloadPath:string) {
        this.downloadQueue.softwareQueue.push({
            softwareProductBuild: softwareProductBuild,
            distro: distro,
            softwareDownloadPath:downloadPath,
            softwareImageBuild:softwareImageId
        });
        this.updateDownloadHistory(null,softwareProductBuild,distro,"Queued",null,null,softwareImageId);
        if (!this.downloadQueue.downloadInProgress) {
            let software = this.downloadQueue.softwareQueue.shift();
            this.updateDownloadHistory(null,software.softwareProductBuild,software.distro,"In Progress",null,null,softwareImageId);
            this.downloadSoftwareImage(software.softwareProductBuild, software.distro,software.softwareImageBuild,software.softwareDownloadPath);
        }
    }
    
    downloadSoftwareImage(softwareProductBuild:SoftwareProductRelease,distro:SoftwareProductDistro,softwareImageBuild:string,softwareDownloadPath:string)
    {
        QLogger.LogInfo(this.logSrc, "Downloading Image : " +softwareImageBuild  + " Distro : " + distro.softwareDistro+" for product "+softwareProductBuild.softwareProductBuild);

        let response: Observable<QPMResponse>;
        if (this.distributionClient === null || this.downloadQueue.downloadInProgress) {
            let end =this.utils.getCurrentDateTime_24();
            this.updateDownloadHistory(null,softwareProductBuild,distro,"Failed",end.toString(),softwareDownloadPath,softwareImageBuild); 
            return;
        }        
        this.startDownload();
        let request: ImageDistributionRequest;
        let requestedPlatform : Number;
        if(this.sharedData.appInfo.isElectronMode)
        requestedPlatform = 0;
        else
        requestedPlatform = 1;
        request = {
            imageBuild:softwareImageBuild,
            softwareDistro:distro.softwareDistro,
            softwareDistroID:distro.softwareDistroId,
            softwareProductBuild:softwareProductBuild.softwareProductBuild,
            requestedPlatform: requestedPlatform,
            softwareDistroAlternateId:distro.softwareDistroAlternateId
        };
        QLogger.LogInfo(this.logSrc,JSON.stringify(request));
        response = this.distributionClient.downloadSoftwareImage(request);
        response.subscribe(
             (data: QPMResponse) => {
                if (this.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Download Image Success Response: " + JSON.stringify(data));
                  }
                  if (data.isSuccess()) 
                  {
                     
                      let obj = JSON.parse(data.getData());
                      let downloadTrackingId = obj.stardustImageTrackingId;
                      this.StartDownload(obj.signedUrl, null,softwareProductBuild,distro,softwareDownloadPath,downloadTrackingId,softwareImageBuild);
                      this.logTerminalMessage("Started Downloading  Software Image : " + softwareImageBuild + " Distro : " + distro.softwareDistro);
  
                  }
                  else {
                      QLogger.LogError(this.logSrc, "Cannot Download  Software Image: " + softwareImageBuild + " Distro : " + distro.softwareDistro);
                      let end =this.utils.getCurrentDateTime_24();
                      this.updateDownloadHistory(null,softwareProductBuild,distro,"Failed",end.toString(),softwareDownloadPath,softwareImageBuild); 
                      this.showMessageBox("Download", "Cannot Download  Software Image: " + softwareImageBuild + " Distro : " + distro.softwareDistro, "Ok");
                      this.cleanDownload();
                  }
                
        });
    }

    diffDownloadSoftwareImageBuild(softwareProductBaseBuild: SoftwareProductRelease, softwareProductTargetBuild: SoftwareProductRelease,softwareImageBuild:string,distro: SoftwareProductDistro,downloadPath:string) {
        QLogger.LogInfo(this.logSrc, "Downloading Difference between Image : " +softwareImageBuild  + "and "+ softwareProductTargetBuild.imageBuildId+ " Distro : " + distro.softwareDistro );
        this.queueDiffImageDownload(softwareProductBaseBuild,softwareProductTargetBuild,softwareImageBuild,distro,downloadPath);
        this.logTerminalMessage("Downloading Difference between Image : " +softwareImageBuild  + "and "+ softwareProductTargetBuild.imageBuildId+ " Distro : " + distro.softwareDistro + "  Queued " +"at " + this.utils.getCurrentDateTime());
    }
    
    queueDiffImageDownload(softwareProductBaseBuild: SoftwareProductRelease, softwareProductTargetBuild: SoftwareProductRelease,softwareImageBuild:string,distro: SoftwareProductDistro,downloadPath:string) 
    {
        this.downloadQueue.softwareQueue.push({
            softwareProductBuild: softwareProductTargetBuild,
            distro: distro,
            softwareProductBaseBuild: softwareProductBaseBuild,
            softwareDownloadPath: downloadPath,
            softwareImageBuild:softwareImageBuild
        });

        if (!this.downloadQueue.downloadInProgress) {
            let software = this.downloadQueue.softwareQueue.shift();
            this.updateDownloadHistory(software.softwareProductBaseBuild,software.softwareProductBuild,distro,"In Progress",null,software.softwareImageBuild);
            this.diffImageDownloadSoftwareDistro(software.softwareProductBaseBuild, software.softwareProductBuild, software.softwareImageBuild,software.distro, software.softwareDownloadPath);
        }
    }
    
    diffImageDownloadSoftwareDistro(softwareProductBaseBuild: SoftwareProductRelease, softwareProductTargetBuild: SoftwareProductRelease, softwareImageBuild:string,distro: SoftwareProductDistro, downloadPath: string)
    {
        QLogger.LogInfo(this.logSrc, "Downloading Difference between Image : " +softwareImageBuild  + " and "+ softwareProductTargetBuild.imageBuildId +" Distro : " + distro.softwareDistro+" for product "+softwareProductBaseBuild.softwareProductBuild);

        let response: Observable<QPMResponse>;
        if (this.distributionClient === null || this.downloadQueue.downloadInProgress) {
            let end =this.utils.getCurrentDateTime_24();
            this.updateDownloadHistory(softwareProductBaseBuild,softwareProductTargetBuild,distro,"Failed",end.toString(),downloadPath,softwareImageBuild);  
            return;
        }        
        this.startDownload();
        let request: ImageDiffDistributionRequest;
        let requestedPlatform : Number;
        if(this.sharedData.appInfo.isElectronMode)
        requestedPlatform = 0;
        else
        requestedPlatform = 1;
        request = {
            baseImageBuild:softwareImageBuild,
            softwareDistro:distro.softwareDistro,
            softwareDistroID:distro.softwareDistroId,
            targetSoftwareProductBuild:softwareProductTargetBuild.softwareProductBuild,
            baseSoftwareProductBuild:softwareProductBaseBuild.softwareProductBuild,
            requestedPlatform: requestedPlatform
        };
        QLogger.LogInfo(this.logSrc,JSON.stringify(request));
        response = this.distributionClient.diffDownloadSoftwareImage(request);
        response.subscribe(
             (data: QPMResponse) => {
                if (this.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Download Image Success Response: " + JSON.stringify(data));
                  }
                  if (data.isSuccess()) 
                  {
                     
                      let obj = JSON.parse(data.getData());
                      let downloadTrackingId = obj.stardustDiffImageTrackingId;
                      this.StartDownload(obj.signedUrl, softwareProductBaseBuild,softwareProductTargetBuild,distro,downloadPath,downloadTrackingId,softwareImageBuild);
                      this.logTerminalMessage("Started Downloading  Diff Between Software Images : " + softwareImageBuild + " and "+ softwareProductTargetBuild.imageBuildId+" Distro : " + distro.softwareDistro);
  
                  }
                  else {
                      QLogger.LogError(this.logSrc, "Cannot Download  Software Image: " + softwareImageBuild + " Distro : " + distro.softwareDistro);
                      let end =this.utils.getCurrentDateTime_24();
                      this.updateDownloadHistory(softwareProductBaseBuild,softwareProductTargetBuild,distro,"Failed",end.toString(),downloadPath,softwareImageBuild);  
                      this.showMessageBox("Download", "Cannot Download  Difference between Software Image: " + softwareImageBuild + " and "+ softwareProductTargetBuild.imageBuildId+" Distro : " + distro.softwareDistro, "Ok");
                      this.cleanDownload();
                  }
                
        });
    }
    //#endregion

    //#region ServiceTask Variable/Events/Functions
    abandonServiceTask(serviceTasksDetails: ServiceTaskInfo) {
        serviceTasksDetails.displayConfirm = false;
        if (serviceTasksDetails === undefined || serviceTasksDetails.serviceTaskId === undefined) {
            return;
        }

        QLogger.LogInfo(this.logSrc, "Abandoning Service Task: " + serviceTasksDetails.serviceTaskId);
        this.sharedData.service.common.abandonInProgress = true;
        let request: UpdateServiceTaskRequest;
        request = {
            abandoned: true,
            baseBuild: serviceTasksDetails.baseBuild,
            changeRequests: null,
            companies: [],
            customerUserId: serviceTasksDetails.customerUserId,
            requestedDate: serviceTasksDetails.requestedDate,
            requester: serviceTasksDetails.requester,
            serviceTaskId: serviceTasksDetails.serviceTaskId,
            requestAppId: serviceTasksDetails.requestAppId,
            requestSource: serviceTasksDetails.requestSource
        };
        if (this.sharedData.appInfo.logRequest) {
            QLogger.LogInfo(this.logSrc, "Abandoning Service Task Request: " + JSON.stringify(request));
        }
        let response: Observable<QPMResponse>;
        response = this.webClient.updateServiceTask(serviceTasksDetails.serviceTaskId.toString(), request);
        response.subscribe(
            (data: QPMResponse) => {
                if (this.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Abandoning Service Task: " + serviceTasksDetails.serviceTaskId + " - Response : " + JSON.stringify(data));
                }
                this.sharedData.service.common.abandonInProgress = false;
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Abandoning Service Task: " + serviceTasksDetails.serviceTaskId + " - Success");
                    this.router.navigate(['/main/software/servicetask/find']);
                }
                else {
                    QLogger.LogError(this.logSrc, "Abandoning Service Task: " + serviceTasksDetails.serviceTaskId + " - Failed");
                    QLogger.LogError(this.logSrc, "Abandoning Service Task: " + serviceTasksDetails.serviceTaskId + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
                }
            }
        );

    }
    
    getAvailbleCompanies(softwareProductBuild: string,spfTag:string, process: any){
        QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProductBuild +" Customers");
        process.companies = [];
        process.loadCustomerInProgress = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareProductCustomers(softwareProductBuild,spfTag);
        response.subscribe(
        (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProductBuild +" Customers - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
                QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProductBuild +" Customers - Success");
                let obj = JSON.parse(data.getData());
                if (obj !== undefined || obj !== null) {
                    let companies =  obj.entitledCompanies as Company[];
                    if(companies !== undefined){
                        let companiesMap : Set<string> = new Set<string>();
                        if(process.serviceTasksDetails && process.serviceTasksDetails !== undefined){
                            process.serviceTasksDetails.companies.forEach((company)=>{
                                companiesMap.add(company.companyName);
                            });
                        }
                        process.companies = [];
                        companies.forEach((company)=>{
                            if(!companiesMap.has(company.companyName)){
                                process.companies.push(company);
                            }
                            if(this.selectedCustomer !== undefined){
                                if(this.selectedCustomer.name === company.companyName){
                                    this.createServiceTaskProcess.selectedCompanies = [];
                                    this.createServiceTaskProcess.selectedCompanies.push(company);
                                }
                            }
                        });

                    }
                }
            }
            else{
                QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProductBuild +" Customers - Failed");
                QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProductBuild +" Customers - Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            process.loadCustomerInProgress = false;
        });
    }
    
    viewReleaseCRs(serviceTaskID: string, releaseCRs: ReleaseCR[] = null){
        this.viewReleaseCrProcess.displayForm = true;
        this.viewReleaseCrProcess.serviceTaskID = serviceTaskID;
        if(releaseCRs !== null){
            if(releaseCRs.length > 0){
                this.viewReleaseCrProcess.releaseCRs = releaseCRs;
                return;
            }
        }
        QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTaskID + " Release CRs");

        if(this.softwareCatalogClient === null || serviceTaskID === "0" || serviceTaskID === ""){
            return;
        }
        
        let response : Observable<QPMResponse>;
        response = this.softwareCatalogClient.getServiceTaskDetailsReleaseCRs(serviceTaskID);
        response.subscribe(
        (data:QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTaskID + " Release CRs - Response : " +JSON.stringify(data));
            }
            if(data.isSuccess()){
                QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTaskID + " Release CRs - Success");
                
                let obj = JSON.parse(data.getData());
                let buildIdSplit: string[];
                if(obj !== undefined || obj !== null) {
                    let crs: ReleaseCR[] = obj.productBuilds as ReleaseCR[];
                    crs.forEach((cr)=>{
                        buildIdSplit = cr.productBuild.split(".");
                        cr.spinNumber = Number.parseInt(buildIdSplit[buildIdSplit.length - 1]);
                    });
                    this.viewReleaseCrProcess.releaseCRs = crs;
                }
            }
            else {
                QLogger.LogError(this.logSrc, "Get Service Task " + serviceTaskID + " Release CRs - Failed");
                QLogger.LogError(this.logSrc, "Get Service Task " + serviceTaskID + " Release CRs - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
            }
        });
    }

    viewAllCRs(serviceTaskID: string, CRs: number[] = null){
        this.viewAllCrProcess.displayForm = true;
        if(CRs !== null){
            if(CRs.length > 0){
                this.viewAllCrProcess.serviceTaskID = serviceTaskID;
                this.viewAllCrProcess.CRs = CRs;
                return;
            }
        }
    }
    //#endregion

    //#region STCaption
    updateSTCaptionProcess: {
        updateSTCaption:boolean;
        updateSTCaptionInProgress: boolean,
        updateSTCaptionError: string,
    }
    updateSTCaptionProcessReset(){
        this.updateSTCaptionProcess = {
            updateSTCaption:false,
            updateSTCaptionInProgress: false,
            updateSTCaptionError: ""
        }

    }
    updateSTCaptionStart(){
        this.updateSTCaptionProcess.updateSTCaption = true;
    }
    updateSTCaptionCancel(serviceTasksDetails: ServiceTaskInfo){
        this.updateSTCaptionProcess.updateSTCaption = false;
        this.updateSTCaptionProcess.updateSTCaptionError = "";
        serviceTasksDetails.updatedCaption = serviceTasksDetails.caption;
    }

    updateSTCaption(serviceTasksDetails: ServiceTaskInfo){
        if(serviceTasksDetails.caption === serviceTasksDetails.updatedCaption){
            this.updateSTCaptionProcess.updateSTCaptionError = "Please enter different subject line";
            return;
        }
        let request: UpdateServiceTaskRequest;
        request = {
            abandoned: false,
            baseBuild: serviceTasksDetails.baseBuild,
            serviceTaskId: serviceTasksDetails.serviceTaskId,
            requestAppId: serviceTasksDetails.requestAppId,
            requestSource: serviceTasksDetails.requestSource,
            caption: serviceTasksDetails.updatedCaption,
        };
        let response: Observable<QPMResponse>;
        if(this.sharedData.appInfo.logRequest){
            QLogger.LogInfo(this.logSrc, "Update Service Task " + serviceTasksDetails.serviceTaskId + " Owners request : " + JSON.stringify(request));
        }
        this.updateSTCaptionProcess.updateSTCaptionInProgress=true;
        this.updateSTCaptionProcess.updateSTCaptionError='';

        response = this.webClient.updateServiceTask(serviceTasksDetails.serviceTaskId.toString(), request);
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Update Service Task Caption Response - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Update Service Task Caption Response - Success");
                    let obj = JSON.parse(data.getData());
                    if(obj !== undefined || obj !== null){
                        let serviceTasksDetailsUpdated = obj as ServiceTaskInfo;
                        serviceTasksDetails.caption = serviceTasksDetailsUpdated.caption;
                        serviceTasksDetails.updatedCaption = serviceTasksDetailsUpdated.caption;
                        this.updateSTCaptionProcess.updateSTCaptionInProgress = false; 
                    }
                }
                else {
                    QLogger.LogError(this.logSrc, "Update Service Task " + serviceTasksDetails.serviceTaskId + "  - Failed");
                    QLogger.LogError(this.logSrc, "Update Service Task " + serviceTasksDetails.serviceTaskId + "   - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
                    this.updateSTCaptionProcess.updateSTCaptionError = data.getError();  
                    this.updateSTCaptionProcess.updateSTCaptionInProgress=false;
                }
        });

    }
    //#endregion




    

    //#region Add/Update Owners
    updateOwnerProcess: {
        updatePrimaryOwner: boolean,
        updateSecondaryOwner: boolean,
        updatePrimaryOwnerInProgress: boolean,
        updateSecondaryOwnerInProgress: boolean,
        updatePrimaryOwnerError: string,
        updateSecondaryOwnerError: string,
    }

    updateOwnerProcessReset(){
        this.updateOwnerProcess = {
            updatePrimaryOwner: false,
            updateSecondaryOwner: false,
            updatePrimaryOwnerInProgress: false,
            updateSecondaryOwnerInProgress: false,
            updatePrimaryOwnerError: "",
            updateSecondaryOwnerError: "",
        }
    }

    updatePrimaryOwnerStart(){
        this.updateOwnerProcess.updatePrimaryOwner = true;
    }
    updatePrimaryOwnerCancel(serviceTasksDetails: ServiceTaskInfo){
        this.updateOwnerProcess.updatePrimaryOwner = false;
        this.updateOwnerProcess.updatePrimaryOwnerError = "";
        serviceTasksDetails.updatedPrimaryOwner = serviceTasksDetails.primaryOwner;
    }
    updateExternalIdstart(){
        this.updateExternalIdProcess.updateExternalId = true;
    }
    updateExternalIdCancel(serviceTasksDetails: ServiceTaskInfo){
        this.updateExternalIdProcess.updateExternalId = false;
        this.updateExternalIdProcess.updateExternalIdError = "";
        serviceTasksDetails.updatedRequestedAppid = serviceTasksDetails.requestAppId;
    }
    updateExternalId(serviceTasksDetails: ServiceTaskInfo){
        if(serviceTasksDetails.requestAppId === serviceTasksDetails.updatedRequestedAppid){
            this.updateExternalIdProcess.updateExternalIdError = "Please enter different External ID";
            return;
        }
        if(serviceTasksDetails.requestSource!=='QPM')
        {
            this.updateExternalIdProcess.updateExternalIdError = "Cannot update External ID for Non QPM service task";
            return;
        }
        let request: UpdateServiceTaskRequest;
        request = {
            abandoned: false,
            baseBuild: serviceTasksDetails.baseBuild,
            serviceTaskId: serviceTasksDetails.serviceTaskId,
            requestAppId: serviceTasksDetails.updatedRequestedAppid,
            requestSource: serviceTasksDetails.requestSource
        };
        this.updateServiceTaskOwners(serviceTasksDetails, request, true,false);
    }
    
    updateSecondaryOwnerStart(){
        this.updateOwnerProcess.updateSecondaryOwner = true;
    }
    updateSecondaryOwnerCancel(serviceTasksDetails: ServiceTaskInfo){
        this.updateOwnerProcess.updateSecondaryOwner = false;
        this.updateOwnerProcess.updateSecondaryOwnerError = "";
        serviceTasksDetails.updatedSecondaryOwner = serviceTasksDetails.secondaryOwner;
    }

    updateServiceTaskOwnersPrimary(serviceTasksDetails: ServiceTaskInfo){
        if(serviceTasksDetails.primaryOwner === serviceTasksDetails.updatedPrimaryOwner){
            this.updateOwnerProcess.updatePrimaryOwnerError = "Please enter different user";
            return;
        }
        let request: UpdateServiceTaskRequest;
        request = {
            abandoned: false,
            baseBuild: serviceTasksDetails.baseBuild,
            serviceTaskId: serviceTasksDetails.serviceTaskId,
            requestAppId: serviceTasksDetails.requestAppId,
            requestSource: serviceTasksDetails.requestSource,
            primaryOwner: serviceTasksDetails.updatedPrimaryOwner,
        };
        this.updateServiceTaskOwners(serviceTasksDetails, request, false,true);
    }
    
    updateServiceTaskOwnersSecondary(serviceTasksDetails: ServiceTaskInfo){
        if(serviceTasksDetails.secondaryOwner === serviceTasksDetails.updatedSecondaryOwner){
            this.updateOwnerProcess.updateSecondaryOwnerError = "Please enter different user";
            return;
        }
        let request: UpdateServiceTaskRequest;
        request = {
            abandoned: false,
            baseBuild: serviceTasksDetails.baseBuild,
            serviceTaskId: serviceTasksDetails.serviceTaskId,
            requestAppId: serviceTasksDetails.requestAppId,
            requestSource: serviceTasksDetails.requestSource,
            secondaryOwner: serviceTasksDetails.updatedSecondaryOwner,
        };
        this.updateServiceTaskOwners(serviceTasksDetails, request, false,false);
    }
    
    updateServiceTaskOwners(serviceTasksDetails: ServiceTaskInfo, request: UpdateServiceTaskRequest, isExternalIdUpdate:boolean,isPrimary: boolean){
        let response: Observable<QPMResponse>;
        if(this.sharedData.appInfo.logRequest){
            QLogger.LogInfo(this.logSrc, "Update Service Task " + serviceTasksDetails.serviceTaskId + " Owners request : " + JSON.stringify(request));
        }
        if(!isExternalIdUpdate)
        {
            if(isPrimary){
                this.updateOwnerProcess.updatePrimaryOwnerInProgress = true;
                this.updateOwnerProcess.updatePrimaryOwnerError = "";
            }
             if(!isPrimary){
                this.updateOwnerProcess.updateSecondaryOwnerInProgress = true;
                this.updateOwnerProcess.updateSecondaryOwnerError = "";
            }
        }
        else
        {
            this.updateExternalIdProcess.updateExternalIdError="";
            this.updateExternalIdProcess.updateExternalIdInProgress = true;
        }
        response = this.webClient.updateServiceTask(serviceTasksDetails.serviceTaskId.toString(), request);
        response.subscribe(
        (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc, "Update Service Task Owners Response - Response : " +JSON.stringify(data));
            }
            
            if (data.isSuccess()) {
                QLogger.LogInfo(this.logSrc, "Update Service Task Owners Response - Success");
                let obj = JSON.parse(data.getData());
                if(obj !== undefined || obj !== null){
                    let serviceTasksDetailsUpdated = obj as ServiceTaskInfo;
                    if(!isExternalIdUpdate)
                    {
                        if(isPrimary){
                            serviceTasksDetails.primaryOwner = serviceTasksDetailsUpdated.primaryOwner;
                            serviceTasksDetails.updatedPrimaryOwner = serviceTasksDetailsUpdated.primaryOwner;
                            this.updateOwnerProcess.updatePrimaryOwner = false;
                        }
                        if(!isPrimary){
                            serviceTasksDetails.secondaryOwner = serviceTasksDetailsUpdated.secondaryOwner;
                            serviceTasksDetails.updatedSecondaryOwner = serviceTasksDetailsUpdated.secondaryOwner;
                            this.updateOwnerProcess.updateSecondaryOwner = false;
                        }
                    }
                    else
                    {
                        this.updateExternalIdProcess.updateExternalId = false;
                        serviceTasksDetails.updatedRequestedAppid = serviceTasksDetailsUpdated.requestAppId;
                        serviceTasksDetails.requestAppId = serviceTasksDetailsUpdated.requestAppId;
                    }
                }
            }
            else {
                QLogger.LogError(this.logSrc, "Update Service Task " + serviceTasksDetails.serviceTaskId + "  - Failed");
                QLogger.LogError(this.logSrc, "Update Service Task " + serviceTasksDetails.serviceTaskId + "   - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
                if(!isExternalIdUpdate)
                {
                    if(isPrimary){
                        this.updateOwnerProcess.updatePrimaryOwnerError = data.getError();
                    }
                    if(!isPrimary){
                        this.updateOwnerProcess.updateSecondaryOwnerError = data.getError();
                    }
                }
                else
                {
                    this.updateExternalIdProcess.updateExternalIdError = data.getError();
                }
            }
            if(!isExternalIdUpdate)
            {
                if(isPrimary){
                    this.updateOwnerProcess.updatePrimaryOwnerInProgress = false;
                }
                if(!isPrimary){
                    this.updateOwnerProcess.updateSecondaryOwnerInProgress = false;
                }
            }
            else
            {
                this.updateExternalIdProcess.updateExternalIdInProgress = false;
            }
        });
    }
    updateDownloadHistory(softwareProductBaseBuild: SoftwareProductRelease,softwareProductBuild: SoftwareProductRelease,distro:SoftwareProductDistro,status:string,downloadTime:string,downloadLocation:string,softwareImageBuild=null)
    {
        let downloadedProduct:DownloadHistoryLocal = new DownloadHistoryLocal();
        this.downloadHistory?.forEach(build =>{
                   if(softwareProductBuild==null){

                           if(build.softwareImageBuild!=null && build.softwareImageBuild==softwareImageBuild && build.baseSoftwareBuild==null){
                                downloadedProduct.requestedDate = build.requestedDate;
                                this.downloadHistory.splice(this.downloadHistory.indexOf(build),1);
                           }
                   } 
                   else if(build.baseSoftwareBuild!=null && build.baseSoftwareBuild?.softwareProductBuild===softwareProductBuild?.softwareProductBuild &&  build.distro?.softwareDistro==distro.softwareDistro )
                   {
                        if((softwareImageBuild!=null && build.softwareImageBuild!=null && softwareImageBuild==build.softwareImageBuild)||(softwareImageBuild==null && build.softwareImageBuild==null))
                        {
                            if(build.tag==softwareProductBuild?.tag || (build.tag==softwareProductBaseBuild?.tag+"_"+softwareProductBuild?.tag))
                            {
                                downloadedProduct.requestedDate = build.requestedDate;
                                this.downloadHistory.splice(this.downloadHistory.indexOf(build),1);
                            }
                        }
                        
                   }
         });
         
         let currentTime = this.utils.getCurrentDateTime_24(); 
         if(softwareProductBuild)
         {
             downloadedProduct.baseSoftwareBuild = softwareProductBuild;
             downloadedProduct.softwareProduct = softwareProductBuild.softwareProduct;
             downloadedProduct.tag = softwareProductBuild.tag;
         }
         downloadedProduct.distro = distro;
         downloadedProduct.status = status;
         downloadedProduct.downloadLocation = downloadLocation;
         downloadedProduct.downloadDate = downloadTime;
         downloadedProduct.softwareImageBuild = softwareImageBuild
         if(status==='Queued')
            downloadedProduct.requestedDate = currentTime.toString();
         if(softwareProductBaseBuild)
         {
            downloadedProduct.tag = softwareProductBaseBuild.tag+"_"+softwareProductBuild?.tag;
            downloadedProduct.targetSoftwareBuild = softwareProductBaseBuild;
            if(status==='In Progress')
            downloadedProduct.requestedDate = currentTime.toString();
            downloadedProduct.targetSoftwareImageBuild = softwareProductBuild?.imageBuildId;
         }
         else
            downloadedProduct.targetSoftwareBuild = null;
         this.downloadHistory?.push(downloadedProduct);
         let HistoryString = JSON.stringify(this.downloadHistory);
         this.distributionClient?.saveDownloadHistory(HistoryString); // Updating History
    }
   

    getDownloadHistory()
    {
        let response: Observable<QPMResponse>;
        if(this.sharedData.appInfo.logRequest){
            QLogger.LogInfo(this.logSrc, "Getting Download History");
        }

        response = this.distributionClient.getDownloadedHistoryDetails(); 
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Update Service Task Owners Response - Response : " +JSON.stringify(data));
                }
                
                if (data.isSuccess()) {
                
                    this.downloadHistory = JSON.parse(data.getData());
                }
                else{
                    this.downloadHistory =[];
                }

            });
    }
    //#endregion

    //#region CreateServiceTask  
    customers: Customer[];
    customersSelectItems: SelectItem[];
    selectedCustomer: Customer;
    selectedCustomerName: string;
    loadingCustomers: boolean;

    projects: CustomerProject[];
    projectsSelectItems: SelectItem[];
    selectedProject: CustomerProject;
    selectedProjectName: string;
    projectsMap: Map<String, CustomerProject>;  
    projectsProductsMap: Map<String, String[]>;  
    spProducProjectsMap: Map<string, string[]>;
    projectsSpfMap: Map<String, string[]>;  
    spfProjectsMap: Map<string, string[]>;
    loadingProject: boolean;

    productFamily: SoftwareProductFamily[];
    productFamilySelectItems: SelectItem[];
    selectedProductFamily: SoftwareProductFamily;
    selectedProductFamilyName: string;
    productFamilyMap: Map<string, SoftwareProductFamily>;
    productFamilySpMap: Map<string, string[]>;
    spProductFamilyMap: Map<string, string[]>;
    loadingProductFamily: boolean;

    products: SoftwareProduct[];
    productsSelectItems: SelectItem[];
    selectedProduct: SoftwareProduct;
    selectedProductName: string;
    productsMap: Map<string, SoftwareProduct>;
    productsRegularMap: Map<string, SoftwareProduct>;
    loadingProduct: boolean;

    releases: SoftwareProductRelease[];
    releasesSelectItems: SelectItem[];
    selectedRelease: SoftwareProductRelease;
    loadingReleases: boolean;

    images: SoftwareImageInfo[];
    imageSelectItems: SelectItem[];
    imageSelected: SoftwareImageInfo;
    imageSelectedName: string;
    imagesMapUnfiltered: Map<string, SoftwareImageInfo>;
    imagesMap: Map<string, SoftwareImageInfo>;
    loadingImages: boolean;

    imageBuilds: SoftwareImageInfo[];
    imageBuildSelectItems: SelectItem[];
    imageBuildSelected: SoftwareImageInfo;
    loadingImageBuilds: boolean;

    
    imagesHlos: SoftwareImageInfo[];
    imageHlosSelectItems: SelectItem[];
    imageHlosSelected: SoftwareImageInfo;
    loadingHlosImages: boolean;
    imagesHlosGerritList: string;

    resetNewServiceTask(){
        this.createServiceTaskProcess.masterService = false;
        this.createServiceTaskProcess.crList = "";
        this.createServiceTaskProcess.crLists = [];
        this.createServiceTaskProcess.errorMessage = "";
        this.createServiceTaskProcess.errorMessageResponse = "";
        this.createServiceTaskProcess.displayCreateRequest = false;
        this.createServiceTaskProcess.displayCreateForm = false;
        this.createServiceTaskProcess.newServiceTask = new NewServiceTaskInfo;
        this.createServiceTaskProcess.companies = [];
        this.createServiceTaskProcess.selectedCompanies = [];
        this.createServiceTaskProcess.loadCustomerInProgress = false;
        this.createServiceTaskProcess.loadCustomerInfoInProgress = false;

        this.createServiceTaskProcess.caseNumber = undefined;
        this.createServiceTaskProcess.baseProject = undefined;
        this.createServiceTaskProcess.baseSPF = undefined;
        this.createServiceTaskProcess.baseRelease = undefined;
        this.createServiceTaskProcess.addSoftwareImage = false;
        this.createServiceTaskProcess.baseSI = [];
        this.createServiceTaskProcess.addGerrit = false;
        this.createServiceTaskProcess.addCRs = false;
        this.createServiceTaskProcess.expandAdvanceOptions=false;
        this.createServiceTaskProcess.addCustomers = false;
        this.createServiceTaskProcess.gerritSIs = [];
        this.createServiceTaskProcess.prod = "";
        this.createServiceTaskProcess.title = "";
        this.createServiceTaskProcess.isSalesforce = false;
        this.createServiceTaskProcess.baseReleasePreSelected = false;
        this.createServiceTaskProcess.companiesPreSelected = false;
        this.createServiceTaskProcess.partyid = 0;
        this.createServiceTaskProcess.autobuild=true;
        this.createServiceTaskProcess.isImageFormatAddOn=false;
        this.createServiceTaskProcess.caption="";
        
        this.projectsMap = new Map<String, CustomerProject>(); 
        this.projectsProductsMap = new Map<string, String[]>();
        this.spProducProjectsMap = new Map<string, string[]>();
        this.projectsSpfMap = new Map<string, string[]>();
        this.spfProjectsMap = new Map<string, string[]>();
        this.productFamilySpMap = new Map<string, string[]>();
        this.spProductFamilyMap = new Map<string, string[]>();
        this.releases=[];
        this.releasesSelectItems=[];
        this.onCustomerClear();
        this.onProjectClear();
        this.onProductFamilyClear();
        this.onProductClear();
        this.onReleaseClear();
        this.clearAllTableResults();
    }

    clearAllTableResults(){
        this.onClearAllFilters()
        this.searchReleaseByImageProcess.softwareProductReleases=[];
        this.searchReleaseByImageProcess.imageBuild=undefined; 
        this.searchReleaseByImageProcess.selectedProductRelease=undefined;
        this.createServiceTaskProcess.baseRelease = undefined;
     }
     onClearAllFilters(){
         this.createServiceTaskProcess.errorMessage="";
         this.selectedCustomer=undefined;
         this.onCustomerClear();
         this.onProjectClear();
         this.onProductFamilyClear();
         this.onSPandSPBuildClear();
     }
     onSPandSPBuildClear(){
        this.onProductClear();
        this.onReleaseClear();
        this.selectedProduct=undefined;
        this.releases=[];
        this.releasesSelectItems=[];
        this.selectedRelease=undefined;
        this.createServiceTaskProcess.baseRelease = undefined;
        this.createServiceTaskProcess.selectedCompanies = [];
        this.createServiceTaskProcess.companies = [];
     }

    onCustomerClear() {
      this.selectedCustomer = undefined;
      this.selectedCustomerName = '';
      this.onProjectClear();
      this.onProductFamilyClear();
      this.onProductClear();
      this.projects = [];
      this.projectsSelectItems = [];
      this.getAvailableProductFamilyByParty(this.sharedData.userInfo.info.partyId);
      this.getAvailableProductsByParty(this.sharedData.userInfo.info.partyId);
    }
    
    onCustomerChange() {
        if(this.selectedCustomerName && (!this.selectedCustomer ||this.selectedCustomerName==this.selectedCustomer?.name)) return;
        if(this.selectedCustomer === undefined) return;
        this.selectedCustomerName = this.selectedCustomer.name;
        this.onProjectClear();
        this.onProductFamilyClear();
        this.onProductClear();
        this.getAvailableProjects(this.selectedCustomer.partyNum);
        this.getAvailableProductFamilyByParty(this.selectedCustomer.partyId);
        this.getAvailableProductsByParty(this.selectedCustomer.partyId);
    }
    
    onProjectClear() {
        this.selectedProject = undefined;
        this.selectedProjectName = '';
        this.filterProductFamily();
        this.filterProduct();
    }
    
    onProjectChange() {
        if(this.selectedProjectName && (!this.selectedProject ||this.selectedProjectName==this.selectedProject?.customerProject)) return;
        if(this.selectedProject === undefined) return;
        this.selectedProjectName = this.selectedProject.customerProject;
        this.filterProductFamily();
        this.filterProduct();
    }

    onProductFamilyClear() {
        this.selectedProductFamily = undefined;
        this.selectedProductFamilyName = '';
        this.createServiceTaskProcess.baseSPF = undefined;
        this.filterProduct();
    }

    onProductFamilyChange() {
        this.createServiceTaskProcess.baseSPF = this.selectedProductFamily;
        if(this.selectedProductFamilyName && (!this.selectedProductFamily ||this.selectedProductFamilyName==this.selectedProductFamily?.softwareProductFamily)) return;
        if(this.selectedProductFamily === undefined) return;
        this.selectedProductFamilyName = this.selectedProductFamily.softwareProductFamily;
        this.filterProduct();
    }

    onProductClear() {
        this.selectedProduct = undefined;
        this.releases = [];
        this.selectedProductName = '';
        this.filterProject();
        this.filterProductFamily();
    }

    onProductChange() {
        if(this.selectedProduct === undefined) return;
        if(this.selectedProductName && (!this.selectedProduct ||this.selectedProductName==this.selectedProduct?.softwareProduct)) return;
        
        this.selectedProductName = this.selectedProduct.softwareProduct;
        this.createServiceTaskProcess.prod = this.selectedProduct.softwareProduct;
        
        this.filterProject();
        this.filterProductFamily();
        
        if(this.createServiceTaskProcess.isSalesforce){            
            this.getAvailableReleasesByParty(this.selectedProduct.softwareProduct, this.createServiceTaskProcess.partyid);
        }
        else{
            
            let partyId: number = this.selectedCustomer === undefined ? 
                                    this.sharedData.userInfo.info.partyId : this.selectedCustomer.partyId;
            this.getAvailableReleasesByParty(this.selectedProduct.softwareProduct, partyId);
            if(!this.createServiceTaskProcess.baseReleasePreSelected){
                this.createServiceTaskProcess.baseSI = [];
                this.getAvailableImages(this.selectedProduct.softwareProduct);
            }
        }
    }

    onReleaseChange(){
        this.createServiceTaskProcess.baseRelease = this.selectedRelease;
        if(this.createServiceTaskProcess.baseRelease === undefined) return;
        if(!this.createServiceTaskProcess.isSalesforce){
            this.loadCustomers(this.createServiceTaskProcess.baseRelease);
        }
    }

    onAddSIGerrit(){
        this.onGerritActionAdd(this.createServiceTaskProcess.baseRelease.softwareProductBuild,
                                 this.createServiceTaskProcess.gerritSIs.map(g => g.softwareImage));
    }

    addSI(){
        let newImage : MasterSTSISelection = new MasterSTSISelection();
        var imageNameSplit = this.imageSelected.softwareImage.split(".");
        imageNameSplit.splice(2, imageNameSplit.length - 2);
        var imageName = imageNameSplit.join(".");
        newImage.imageSelected = {softwareImage: this.imageSelected.softwareImage, softwareImageBuild: this.imageSelected.softwareImageBuild};
        newImage.imageBuildSelected = {softwareImage: this.imageBuildSelected.softwareImage, softwareImageBuild: this.imageBuildSelected.softwareImageBuild};
        this.createServiceTaskProcess.baseSI.push(newImage);
        this.imagesMap.delete(imageName);
        this.imageSelectItems = this.utils.getSelectItems(Array.from(this.imagesMap.values()) as SoftwareImage[], "softwareImage");
        this.imageSelected = undefined;
        this.imageBuildSelected = undefined;
        this.imageBuildSelectItems = [];
    }
    removeSI(image : MasterSTSISelection, index: number){
        var imageNameSplit = image.imageSelected.softwareImage.split(".");
        imageNameSplit.splice(2, imageNameSplit.length - 2);
        var imageName = imageNameSplit.join(".");
        this.imagesMap.set(imageName, this.imagesMapUnfiltered.get(imageName));
        this.imageSelectItems = this.utils.getSelectItems(Array.from(this.imagesMap.values()).sort((x1,x2)=> (x1.softwareImage.localeCompare(x2.softwareImage) <= -1) ? -1:1) as SoftwareImage[], "softwareImage");
        this.createServiceTaskProcess.baseSI.splice(index, 1);
    }

    removeSIGerrit(image : GerritSISelection, index: number){
        this.createServiceTaskProcess.gerritSIs.splice(index, 1);
    }

    
    filterProject(){
        this.projectsSelectItems = [];
        if(this.selectedProduct === undefined){
            this.projectsSelectItems = this.utils.getSelectItems(this.projects, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
            return;
        }

        let projectsFiltered: CustomerProject[] = [];
        let spProductProjectsFilter: Set<String> = new Set();

        if(this.spProducProjectsMap.has(this.selectedProduct.softwareProduct)){
          this.spProducProjectsMap.get(this.selectedProduct.softwareProduct)
          .forEach((project) => {
            spProductProjectsFilter.add(project);
          });
        }
    
        if(spProductProjectsFilter.size === 0){
          if(this.spProducProjectsMap.has(this.selectedProduct.regularSoftwareProduct)){
            this.spProducProjectsMap.get(this.selectedProduct.regularSoftwareProduct)
            .forEach((project) => {
              spProductProjectsFilter.add(project);
            });
          }
        }

        let selectedProject: string = this.selectedProjectName;
        this.selectedProject = undefined;
        this.selectedProjectName = '';
        this.projectsMap.forEach((value, key) => {
        if(this.selectedProduct === undefined || spProductProjectsFilter.has(key))
        {
            projectsFiltered.push(value);      
            if(selectedProject === key){
            this.selectedProject = value;
            this.selectedProjectName = key.toString();
            }
        }
        });

        this.projectsSelectItems = this.utils.getSelectItems(projectsFiltered, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    }
  
    filterProductFamily(){  
        this.productFamilySelectItems = [];
        if(this.selectedProject === undefined && this.selectedProduct === undefined){
            this.productFamilySelectItems = this.utils.getSelectItems(this.productFamily, "softwareProductFamily").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
            return;
        }

        let productsFamilyFiltered: SoftwareProductFamily[] = [];
        let projectsSpfFilter: Set<string> = new Set();
        let spProductFamilyFilter: Set<string> = new Set();
        if(this.selectedProject !== undefined){
            if(this.projectsSpfMap.has(this.selectedProject.customerProject)){
                this.projectsSpfMap.get(this.selectedProject.customerProject)
                .forEach((spf) => {
                projectsSpfFilter.add(spf);
                });
            }
        }

        if(this.selectedProduct !== undefined){
            if(this.spProductFamilyMap.has(this.selectedProduct.softwareProduct.toLowerCase())){
                this.spProductFamilyMap.get(this.selectedProduct.softwareProduct.toLowerCase())
                .forEach((family) => {
                spProductFamilyFilter.add(family);
                });
            }
        }
        
        let selectedFamily: string = this.selectedProductFamilyName;
        this.selectedProductFamily = undefined;
        this.selectedProductFamilyName = '';
        this.productFamilyMap.forEach((value, key) => {
        if((this.selectedProject === undefined || projectsSpfFilter.has(key)) 
            && (this.selectedProduct === undefined || spProductFamilyFilter.has(key)))
        {
            productsFamilyFiltered.push(value);      
            if(selectedFamily === key){
            this.selectedProductFamily = value;
            this.selectedProductFamilyName = key;
            }
        }
        });
        this.productFamilySelectItems = this.utils.getSelectItems(productsFamilyFiltered, "softwareProductFamily").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    }

    filterProduct(){
        this.productsSelectItems = [];
        if(this.selectedProject === undefined && this.selectedProductFamily === undefined){
            this.productsSelectItems = this.utils.getSelectItems(this.products, "softwareProduct").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
            return;
        }

        let productsFiltered: SoftwareProduct[] = [];
        let projectsProductsFilter: Set<string> = new Set();
        let productFamilySpFilter: Set<string> = new Set();
        if(this.selectedProject !== undefined){
            if(this.projectsProductsMap.has(this.selectedProject.customerProject)){
                this.projectsProductsMap.get(this.selectedProject.customerProject).forEach((product)=>{
                projectsProductsFilter.add(product.toLowerCase());
                });
            }
        }
        if(this.selectedProductFamily !== undefined){
            if(this.productFamilySpMap.has(this.selectedProductFamily.softwareProductFamily)){
                this.productFamilySpMap.get(this.selectedProductFamily.softwareProductFamily).forEach((family) => {
                productFamilySpFilter.add(family.toLowerCase());
                });
            }
        }

        
        this.productsMap.forEach((value, key) => {
            if((this.selectedProject === undefined || projectsProductsFilter.has(key) || projectsProductsFilter.has(value.regularSoftwareProduct.toLowerCase())) 
                && (this.selectedProductFamily === undefined || productFamilySpFilter.has(key))){
                productsFiltered.push(value);
            }
        });
        /*this.productsRegularMap.forEach((value, key) => {
            if((this.selectedProject === undefined || projectsProductsFilter.has(key)) 
                && (this.selectedProductFamily === undefined || productFamilySpFilter.has(key))){
                    productsFiltered.push(value);
            }
        });*/
        this.productsSelectItems = this.utils.getSelectItems(productsFiltered, "softwareProduct").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
    }
    
    loadCustomers(softwareProductBuild: SoftwareProductRelease){
        if(this.sharedData.visibility.software.addCustomer){
            if(this.companiesListBox !== undefined){
              this.companiesListBox._filterValue = null;
            }
            this.getAvailbleCompanies(softwareProductBuild.softwareProductBuild, softwareProductBuild.spfTag, this.createServiceTaskProcess);
        }
    }

    getCustomerInfo(orgID: number) {
        QLogger.LogInfo(this.logSrc, "Get Customer Info");
        this.createServiceTaskProcess.loadCustomerInfoInProgress = true;
        let response: Observable<QPMResponse>;
        response = this.webClient.getCustomerInfo(orgID);
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Get Customer Info - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get Customer Info - Success");
                    let obj = JSON.parse(data.getData()) as CustomerInfo;
                    if (obj !== undefined || obj !== null) {
                        if(this.createServiceTaskProcess.isSalesforce){
                            this.createServiceTaskProcess.partyid = obj.partyId;
                            this.getAvailableProjects(obj.partyNumber);
                            this.getAvailableProductFamilyByParty(obj.partyId);
                            this.getAvailableProductsByParty(obj.partyId);
                            if(this.createServiceTaskProcess.isSalesforce){
                                this.createServiceTaskProcess.selectedCompanies = [];
                                this.createServiceTaskProcess.selectedCompanies.push({
                                    companyName: obj.customerName,
                                    partyId: obj.partyId,
                                    partyNum: obj.partyNumber
                                });
                                this.createServiceTaskProcess.errorMessage = "";
                            }
                        }
                    }
                }
                else{
                    this.createServiceTaskProcess.errorMessage = "Customer not found. " + data.getError();
                    QLogger.LogError(this.logSrc, "Get Customer Info - Failed");
                    QLogger.LogError(this.logSrc, "Get Customer Info - Error : " + data.getError() + " - " + data.getErrorDetail());
                }
                
                if(this.createServiceTaskProcess.companiesPreSelected && this.createServiceTaskProcess.selectedCompanies.length === 0){
                    this.createServiceTaskProcess.errorMessage = "Customer " + this.createServiceTaskProcess.cust + " not entitled.";
                }

                this.createServiceTaskProcess.loadCustomerInfoInProgress = false;
            }
        );
    }

    
    getAvailbleCustomers(){
        if(!this.sharedData.visibility.software.showCustomerFilter){
            return;
        }
        QLogger.LogInfo(this.logSrc, "Get Available Customers");
        this.customers = [];
        this.selectedCustomer = undefined;
        this.loadingCustomers = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getCustomersAll();
        response.subscribe(
        (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Get Available Customers - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
                QLogger.LogInfo(this.logSrc, "Get Available Customers - Success");
                let obj = JSON.parse(data.getData());
                if (obj !== undefined || obj !== null) {
                    this.customers =  obj as Customer[];
                    this.customersSelectItems = this.utils.getSelectItems(this.customers, "name").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
                }
            }
            else{
                QLogger.LogError(this.logSrc, "Get Available Customers - Failed");
                QLogger.LogError(this.logSrc, "Get Available Customers - Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.loadingCustomers = false;
        });
    }

    getAvailableProjects(partyId: number) {
        QLogger.LogInfo(this.logSrc, "Get Available Software Projects, PartyId: " + partyId);
        this.projects = [];
        this.projectsSelectItems = []
        this.projectsMap = new Map<String, CustomerProject>(); 
        this.projectsProductsMap = new Map<string, String[]>();
        this.spProducProjectsMap = new Map<string, string[]>();
        this.loadingProject = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getCustomerProjectsByParty(partyId);
        response.subscribe(
          (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Get Available Software Projects, PartyId: " + partyId + " - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Get Available Software Projects, PartyId: " + partyId + " - Success");
              
              let obj = JSON.parse(data.getData());
              if (obj !== undefined || obj !== null) {
                this.projects = obj.customerProjects;
                this.projectsSelectItems = this.utils.getSelectItems(this.projects, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
                this.projectsMap = new Map<String, CustomerProject>(); 
                this.projectsProductsMap = new Map<string, String[]>();
                this.spProducProjectsMap = new Map<string, string[]>();
                this.projects.forEach((project) => {
                  this.projectsMap.set(project.customerProject, project);
    
                  if(this.projectsProductsMap.has(project.customerProject)){
                    this.projectsProductsMap.get(project.customerProject).push(project.softwareProduct);
                  }
                  else{
                    this.projectsProductsMap.set(project.customerProject,[]);
                    this.projectsProductsMap.get(project.customerProject).push(project.softwareProduct);
                  }
    
                  if(this.spProducProjectsMap.has(project.softwareProduct)){
                    this.spProducProjectsMap.get(project.softwareProduct).push(project.customerProject);
                  }
                  else{
                    this.spProducProjectsMap.set(project.softwareProduct,[]);
                    this.spProducProjectsMap.get(project.softwareProduct).push(project.customerProject);
                  }
    
                  if(project.softwareProductFamily !== null){
                    if(this.projectsSpfMap.has(project.customerProject)){
                      this.projectsSpfMap.get(project.customerProject).push(project.softwareProductFamily);
                    }
                    else{
                      this.projectsSpfMap.set(project.customerProject,[]);
                      this.projectsSpfMap.get(project.customerProject).push(project.softwareProductFamily);
                    }
    
                    if(this.spfProjectsMap.has(project.softwareProductFamily)){
                      this.spfProjectsMap.get(project.softwareProductFamily).push(project.customerProject);
                    }
                    else{
                      this.spfProjectsMap.set(project.softwareProductFamily,[]);
                      this.spfProjectsMap.get(project.softwareProductFamily).push(project.customerProject);
                    }
                  }
                });
    
                let projectsFiltered: CustomerProject[] = [];
                if(this.selectedProduct !== undefined && this.selectedProduct !== null){
                  this.projectsSelectItems = [];
                  let projects: string[];
                  if(this.spProducProjectsMap.has(this.selectedProduct.softwareProduct)){
                    projects = this.spProducProjectsMap.get(this.selectedProduct.softwareProduct);
                  }
                  else if(this.spProducProjectsMap.has(this.selectedProduct.regularSoftwareProduct)){
                    projects = this.spProducProjectsMap.get(this.selectedProduct.regularSoftwareProduct);
                  }
                  if(projects != undefined){
                    let selectedProject: string = this.selectedProjectName;
                    this.selectedProject = undefined;
                    this.selectedProjectName = '';
                    projects.forEach((project) => {
                      if(this.projectsMap.has(project)){
                        projectsFiltered.push(this.projectsMap.get(project));
                      }
                      if(selectedProject === project){
                        this.selectedProject = this.projectsMap.get(project);
                        this.selectedProjectName = project;
                      }
                    });
                    this.projectsSelectItems = this.utils.getSelectItems(projectsFiltered, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
                  }
                }
              }
            }
            else{
              QLogger.LogError(this.logSrc, "Get Available Software Projects, PartyId: " + partyId + " - Failed");
              QLogger.LogError(this.logSrc, "Get Available Software Projects, PartyId: " + partyId + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.loadingProject = false;
          });
    }

    getAvailableProductFamily() {
        QLogger.LogInfo(this.logSrc, "Get Available Software Product Family");
        this.loadingProductFamily = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareProductFamily();
        response.subscribe(
          (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Get Available Software Product Family - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Get Available Software Product Family - Success");
              let obj = JSON.parse(data.getData());
              if (obj !== undefined || obj !== null) {
                this.productFamily = obj.spfMappings;
                this.productFamilySelectItems = this.utils.getSelectItems(this.productFamily, "softwareProductFamily").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
                this.productFamilyMap = new Map<string, SoftwareProductFamily>();
                this.productFamilySpMap = new Map<string, string[]>();
                this.spProductFamilyMap = new Map<string, string[]>();
                this.productFamily.forEach((family) => {
                  this.productFamilyMap.set(family.softwareProductFamily, family);
                  this.productFamilySpMap.set(family.softwareProductFamily, family.softwareProducts);
                  family.softwareProducts.forEach((softwareProduct)=>{
                    if(this.spProductFamilyMap.has(softwareProduct.toLowerCase())){
                      this.spProductFamilyMap.get(softwareProduct.toLowerCase()).push(family.softwareProductFamily);
                    }
                    else{
                      this.spProductFamilyMap.set(softwareProduct.toLowerCase(),[]);
                      this.spProductFamilyMap.get(softwareProduct.toLowerCase()).push(family.softwareProductFamily);
                    }
                  });
                });
              }
            }
            else{
              QLogger.LogError(this.logSrc, "Get Available Software Product Family - Failed");
              QLogger.LogError(this.logSrc, "Get Available Software Product Family - Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.loadingProductFamily = false;
          });
    }

    getAvailableProductFamilyByParty(partyID: number) {
        QLogger.LogInfo(this.logSrc, "Get Available Software Product Family by Party");
        this.loadingProductFamily = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareProductFamilyByParty(partyID);
        response.subscribe(
          (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Get Available Software Product Family by Party - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Get Available Software Product Family by Party - Success");
              let obj = JSON.parse(data.getData());
              if (obj !== undefined || obj !== null) {
                this.productFamily = obj.spfMappings;
                this.productFamilySelectItems = this.utils.getSelectItems(this.productFamily, "softwareProductFamily").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
                this.productFamilyMap = new Map<string, SoftwareProductFamily>();
                this.productFamilySpMap = new Map<string, string[]>();
                this.spProductFamilyMap = new Map<string, string[]>();
                this.productFamily.forEach((family) => {
                  this.productFamilyMap.set(family.softwareProductFamily, family);
                  this.productFamilySpMap.set(family.softwareProductFamily, family.softwareProducts);
                  family.softwareProducts.forEach((softwareProduct)=>{
                    if(this.spProductFamilyMap.has(softwareProduct.toLowerCase())){
                      this.spProductFamilyMap.get(softwareProduct.toLowerCase()).push(family.softwareProductFamily);
                    }
                    else{
                      this.spProductFamilyMap.set(softwareProduct.toLowerCase(),[]);
                      this.spProductFamilyMap.get(softwareProduct.toLowerCase()).push(family.softwareProductFamily);
                    }
                  });
                });
              }
            }
            else{
              QLogger.LogError(this.logSrc, "Get Available Software Product Family by Party - Failed");
              QLogger.LogError(this.logSrc, "Get Available Software Product Family by Party - Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.loadingProductFamily = false;
          });
    }
    
    getAvailableProducts() {
        QLogger.LogInfo(this.logSrc, "Get Available Software Products");
        this.loadingProduct = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareProducts();
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Get Available Software Products - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get Available Software Products - Success");
                    let obj = JSON.parse(data.getData());
                    if (obj !== undefined || obj !== null) {
                        this.products = obj.softwareProducts;
                        this.productsSelectItems = this.utils.getSelectItems(this.products, "softwareProduct");
                        this.productsMap = new Map<string, SoftwareProduct>();
                        this.productsRegularMap = new Map<string, SoftwareProduct>();
                        this.products.forEach((product) => {
                            this.productsMap.set(product.softwareProduct.toLowerCase(), product);
                            this.productsRegularMap.set((product.regularSoftwareProduct).toLowerCase(), product);
                        });
                        this.productsSelectItems.forEach((product)=>{
                            if(product.label === this.createServiceTaskProcess.prod){
                                this.selectedProduct = product.value;
                                let partyId: number = this.selectedCustomer === undefined ? 
                                    this.sharedData.userInfo.info.partyId : this.selectedCustomer.partyId;
                                this.getAvailableReleasesByParty(this.selectedProduct.softwareProduct, partyId);
                            }
                        });
                    }
                }
                else{
                    QLogger.LogError(this.logSrc, "Get Available Software Products - Failed");
                    QLogger.LogError(this.logSrc, "Get Available Software Products - Error : " + data.getError() + " - " + data.getErrorDetail());
                }
                this.loadingProduct = false;
            }
        );
    }

    getAvailableProductsByParty(partyID: number) {
        QLogger.LogInfo(this.logSrc, "Get Available Software Products by Party");
        this.createServiceTaskProcess.errorMessage="";
        this.loadingProduct = true;
        this.productsSelectItems = [];
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareProductsByParty(partyID);
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Get Available Software Products by Party - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get Available Software Products by Party - Success");
                    let obj = JSON.parse(data.getData());
                    if (obj !== undefined || obj !== null) {
                        this.products = obj.softwareProducts;
                        this.productsSelectItems = this.utils.getSelectItems(this.products, "softwareProduct");
                        this.productsMap = new Map<string, SoftwareProduct>();
                        this.productsRegularMap = new Map<string, SoftwareProduct>();
                        this.products.forEach((product) => {
                            this.productsMap.set(product.softwareProduct.toLowerCase(), product);
                            this.productsRegularMap.set((product.regularSoftwareProduct).toLowerCase(), product);
                        });
                        this.productsSelectItems.forEach((product)=>{
                            if(product.label === this.createServiceTaskProcess.prod){
                                this.selectedProduct = product.value;
                                this.getAvailableReleasesByParty(this.selectedProduct.softwareProduct, partyID);
                            }
                        });
                    }
                }
                else{
                    QLogger.LogError(this.logSrc, "Get Available Software Products by Party - Failed");
                    QLogger.LogError(this.logSrc, "Get Available Software Products by Party - Error : " + data.getError() + " - " + data.getErrorDetail());
                }

                if(this.productsSelectItems.length === 0){
                    this.createServiceTaskProcess.errorMessage = "No entitlements found for selected customer";
                }
                this.loadingProduct = false;
            }
        );
    }

    getAvailableReleases(softwareProduct: string) {
        QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds");
        this.loadingReleases = true;
        let response: Observable<QPMResponse>;
        //this.versions = SoftwareData.getAvailableReleaseData();
    
        this.releases = [];                
        this.releasesSelectItems = [];
        response = this.softwareCatalogClient.getSoftwareProductBuilds(softwareProduct);
        response.subscribe(
          (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Response : " +JSON.stringify(data));
            }
            this.releases = [];
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Success");
              let obj = JSON.parse(data.getData());
              if (obj !== undefined || obj !== null) {
                this.releases = obj.softwareProductReleases;                
                this.releasesSelectItems = this.utils.getSelectItemsMultiLabel(this.releases, ["spfTag", "tag"], "-");
              }
            }
            else{
              QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Failed");
              QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.loadingReleases = false;
          }
        );
    }

    getAvailableReleasesByParty(softwareProduct: string, partyID: number) {
        QLogger.LogInfo(this.logSrc, "Get Available Software Product by Party " + softwareProduct + " Builds");
        this.loadingReleases = true;
        this.releases = [];
        this.releasesSelectItems = [];
        let response: Observable<QPMResponse>;
        //this.versions = SoftwareData.getAvailableReleaseData();
    
        response = this.softwareCatalogClient.getSoftwareProductBuildsByParty(softwareProduct, partyID);
        response.subscribe(
          (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Get Available Software Product by Party " + softwareProduct + " Builds - Response : " +JSON.stringify(data));
            }
            this.releases = [];
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Get Available Software Product by Party " + softwareProduct + " Builds - Success");
              let obj = JSON.parse(data.getData());
              if (obj !== undefined || obj !== null) {
                this.releases = obj.softwareProductReleases;                
                this.releasesSelectItems = this.utils.getSelectItemsMultiLabel(this.releases, ["spfTag", "tag"], "-");
                this.selectedRelease = undefined;
                this.createServiceTaskProcess.baseRelease = undefined;
              }
            }
            else{
              QLogger.LogError(this.logSrc, "Get Available Software Product by Party " + softwareProduct + " Builds - Failed");
              QLogger.LogError(this.logSrc, "Get Available Software Product by Party " + softwareProduct + " Builds - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            if(this.releasesSelectItems.length === 0){
                this.createServiceTaskProcess.errorMessage = "Party not entitle to any releases";
            }
            this.loadingReleases = false;
          }
        );
    }

    getAvailableImages(softwareProduct: string){     
        if(!this.sharedData.visibility.software.addBaseImage){
            return;
        }
        QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition");
        this.imageSelected = undefined;
        this.images = [];
        this.imageSelectItems = [];
        this.loadingImages = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareProductLatestComposition(softwareProduct);
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Success");
                    let obj = JSON.parse(data.getData());
                    if (obj !== undefined || obj !== null) {
                        this.images = obj as SoftwareImageInfo[];
                        this.imageSelectItems = this.utils.getSelectItems(this.images, "softwareImage");
                        this.imagesMap = new Map<string, SoftwareImageInfo>();
                        this.imagesMapUnfiltered = new Map<string, SoftwareImageInfo>();
                        this.images.forEach((image) => {
                            var imageNameSplit = image.softwareImage.split(".");
                            imageNameSplit.splice(2, imageNameSplit.length - 2);
                            var imageName = imageNameSplit.join(".");
                            this.imagesMapUnfiltered.set(imageName, image);
                            this.imagesMap.set(imageName, image);
                        });
                    }
                }
                else{
                    QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Failed");
                    QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Error : " + data.getError() + " - " + data.getErrorDetail());
                }
                this.loadingImages = false;
            }
        );
    }

    getLatestImageBuilds(){
        if(this.imageSelected === undefined || this.imageSelectedName === this.imageSelected.softwareImage){
            return;
        }
        this.imageSelectedName = this.imageSelected.softwareImage;
        this.imageBuildSelected = undefined;
        this.imageBuilds = [];
        this.imageBuildSelectItems = [];
        let softwareImageBuild: string = this.imageSelected.softwareImageBuild;
        QLogger.LogInfo(this.logSrc, "Get Latest Software Images after " + softwareImageBuild);
        this.loadingImageBuilds = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareImagesBuildsLatest(softwareImageBuild);
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Success");
                    let obj = JSON.parse(data.getData());
                    if (obj !== undefined || obj !== null) {
                        this.imageBuilds = obj as SoftwareImageInfo[];
                        this.imageBuildSelectItems = this.utils.getSelectItems(this.imageBuilds, "softwareImageBuild");
                    }
                }
                else{
                    QLogger.LogError(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Failed");
                    QLogger.LogError(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Error : " + data.getError() + " - " + data.getErrorDetail());
                }
                this.loadingImageBuilds = false;
            }
        );
    }

    onReleaseClear(){
        this.selectedRelease = undefined;
    }
    newServiceTask(spf: SoftwareProductFamily, softwareProductBuild: SoftwareProductRelease) {
        this.createServiceTaskProcess.baseSPF = spf;
        this.createServiceTaskProcess.baseRelease = softwareProductBuild;
        this.createServiceTaskProcess.displayCreateRequest = true;
        this.createServiceTaskProcess.displayCreateForm = true;
        this.createServiceTaskProcess.baseSI = [];
        this.createServiceTaskProcess.gerritSIs = [];
        if (this.sharedData.appInfo.isElectronMode) {
            this.createServiceTaskProcess.isSalesforce = false;
           // this.createServiceTaskProcess.caseNumber = undefined;
            this.createServiceTaskProcess.prod = "";
            this.createServiceTaskProcess.title = "";
            this.createServiceTaskProcess.desc = "";
            this.createServiceTaskProcess.custid = "";
            this.createServiceTaskProcess.cust = "";
        }

        if (!this.createServiceTaskProcess.baseReleasePreSelected) {
            if(this.createServiceTaskProcess.isSalesforce){
                this.getCustomerInfo(Number.parseInt(this.createServiceTaskProcess.custid));
            }
            else{
                if(this.sharedData.visibility.software.showCustomerFilter){
                    this.getAvailbleCustomers();
                    this.projectsSelectItems = [];
                }
                else{
                    this.getAvailableProjects(this.sharedData.userInfo.info.partyNum);
                }

                this.getAvailableProductFamilyByParty(this.sharedData.userInfo.info.partyId);
                this.getAvailableProductsByParty(this.sharedData.userInfo.info.partyId);
            }
        }

        if (this.createServiceTaskProcess.baseRelease !== undefined) {
            this.loadCustomers(softwareProductBuild);
        }
    }
    newServiceTaskNext() {
        if (this.createServiceTaskValidation()) {
            this.createServiceTaskProcess.crLists = [];
            let crs = this.extractCRsList(this.createServiceTaskProcess.crList);
            if (crs !== null) {
                crs.forEach((cr) => {
                    this.createServiceTaskProcess.crLists.push({
                        cr: cr,
                        valid: "Yes"
                    });
                });
            }
            this.createServiceTaskProcess.displaySummary = true;
            this.createServiceTaskProcess.displayCreateForm = false;
        }
    }
    newServiceTaskBack() {
        this.createServiceTaskProcess.displaySummary = false;
        this.createServiceTaskProcess.errorMessageResponse = "";
        this.createServiceTaskProcess.displayCreateForm = true;
    }

    createServiceTaskValidation(): boolean {
        this.createServiceTaskProcess.errorMessage = "";
        let isCrEmpty: boolean;
        let isExternalIdEmpty : boolean;
        isCrEmpty = this.createServiceTaskProcess.crList === undefined || this.createServiceTaskProcess.crList === "";
        isExternalIdEmpty = this.createServiceTaskProcess.caseNumber===undefined || this.createServiceTaskProcess.caseNumber === ""
        if (!isCrEmpty) {
            if (!this.createServiceTaskProcess.crList.match(/\d+/g)) {
                this.createServiceTaskProcess.errorMessage = "Invalid CR values. Please enter in correct format";
            }
            else {
                let crList = this.extractCRsList(this.createServiceTaskProcess.crList);
                if (crList.length > 50) {
                    this.createServiceTaskProcess.errorMessage = "Number of CR(s) exceed maximum permissible limit of 50 . Please reduce number of CR(s)"
                }
            }
        }
        if(!this.createServiceTaskProcess.isSalesforce && !isExternalIdEmpty)
        {
            if (!this.createServiceTaskProcess.caseNumber.match('[A-Za-z]_[0-9]')) {
                this.createServiceTaskProcess.errorMessage = "Invalid External ID Format . Please enter in correct format";
            }
            else if((this.createServiceTaskProcess.caseNumber.match(','))||(this.createServiceTaskProcess.caseNumber.match(' ')))
            {
                this.createServiceTaskProcess.errorMessage = "Cannot enter more than 1 ID ";
            }
        }
        if (this.createServiceTaskProcess.errorMessage !== "") {
            return false;
        }
        return true;
    }

    extractCRsList(crList: string): string[] {
        if (crList === undefined || crList === "") {
            return null;
        }
        let crListUnique: Set<string>;
        crListUnique = new Set<string>(crList.match(/\d+/g));
        return Array.from(crListUnique.values());
    }
    extractGerritsList(gerrits: string): string[] {
        if (gerrits === undefined || gerrits === "") {
            return null;
        }
        let gerritListUnique: Set<string>;
        gerritListUnique = new Set<string>(gerrits.match(/([0-9]+\/[0-9]+)|([0-9]+)/g));
        return Array.from(gerritListUnique.values());
    }

    createServiceTask() {
        QLogger.LogInfo(this.logSrc, "Create Service Task");
        this.createServiceTaskProcess.displayCreateRequest = true;
        this.createServiceTaskProcess.displaySummary = true;
        this.createServiceTaskProcess.errorMessageResponse = "";
        let request: ServiceTaskRequest;
        let crs = this.extractCRsList(this.createServiceTaskProcess.crList);
        request = {
            master: this.createServiceTaskProcess.masterService,
            customerProjectId: undefined,
            baseBuild: undefined,
            baseBuildSpf: undefined,
            baseBuildTag: undefined,
            createdUsingSI: false,
            branch: this.createServiceTaskProcess.baseRelease.branch,
            buildType: this.createServiceTaskProcess.baseRelease.buildType,
            autobuild: this.createServiceTaskProcess.autobuild,
            isImageFormatAddOn:   this.createServiceTaskProcess.isImageFormatAddOn,
            caption : this.createServiceTaskProcess.caption ? this.createServiceTaskProcess.caption : "",
            changeRequests: crs !== null ? crs : [],
            salesforceCustomer: this.createServiceTaskProcess.cust,
            companies: this.createServiceTaskProcess.selectedCompanies,
            customerUserId: this.sharedData.userInfo.username,
            title: this.createServiceTaskProcess.title,
            description: this.createServiceTaskProcess.desc,
            requestAppId: this.createServiceTaskProcess.caseNumber ? this.createServiceTaskProcess.caseNumber : "",
            requestSource: this.createServiceTaskProcess.isSalesforce ? "salesforce" : "QPM",
            softwareProduct: this.createServiceTaskProcess.baseRelease.softwareProduct
        };
        if(this.createServiceTaskProcess.addSoftwareImage){
            request.serviceTaskImageBuilds = Array.from(this.createServiceTaskProcess.baseSI.map(x => x.imageBuildSelected)) as SoftwareImage[];
            request.createdUsingSI = true;
        }
        else{            
            request.customerProjectId = this.createServiceTaskProcess.baseProject;
            request.baseBuild = this.createServiceTaskProcess.baseRelease.softwareProductBuild;
            request.baseBuildSpf = this.createServiceTaskProcess.baseSPF !== undefined? this.createServiceTaskProcess.baseSPF.softwareProductFamily: this.createServiceTaskProcess.baseRelease.spf;
            request.baseBuildTag = this.createServiceTaskProcess.baseRelease.tag;
            request.baseBuildSpfTag = this.createServiceTaskProcess.baseRelease.spfTag;
        }
        if(this.createServiceTaskProcess.addGerrit){
            request.gerrits = this.createServiceTaskProcess.gerritSIs;
        }

        if (this.sharedData.appInfo.logRequest) {
            QLogger.LogInfo(this.logSrc, "Create Service Task Request : " + JSON.stringify(request));
        }

        this.createServiceTaskProcess.creatingServiceTaskInProgress = true;
        let response: Observable<QPMResponse>;
        response = this.webClient.createServiceTask(request);
        response.subscribe(
            (data: QPMResponse) => {
                if (this.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Create Service Task Request Success. TaskID: " + this.createServiceTaskProcess.newServiceTask.serviceTaskId + " - Response : " + JSON.stringify(data));
                }
                //this.version = (data.isSuccess())? data.getData() : data.getError();
                if (data.isSuccess()) {
                    this.createServiceTaskProcess.newServiceTask = JSON.parse(data.getData());
                    this.createServiceTaskProcess.displayCreateForm = false;
                    this.createServiceTaskProcess.displaySummary = false;
                    this.createServiceTaskProcess.crList = "";
                    this.createServiceTaskProcess.crLists = [];
                    this.createServiceTaskProcess.masterService = false;
                    this.createServiceTaskProcess.displayResponse = true;
                    QLogger.LogInfo(this.logSrc, "Create Service Task Request Success. TaskID: " + this.createServiceTaskProcess.newServiceTask.serviceTaskId);

                    //this.app.showMessageBox("New Service Task", "New Service Task created for " + this.createServiceTaskProcess.baseRelease.softwareProductBuild + ". TaskID : " + serviceTaskInfo.serviceTaskId, "Ok");
                }
                else {
                    QLogger.LogError(this.logSrc, "Create Service Task Request - Failed");
                    QLogger.LogError(this.logSrc, "Create Service Task Request - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
                    this.createServiceTaskProcess.errorMessageResponse = "New Service Task creation failed. " + data.getError();
                    //this.app.showMessageBox("New Service Task", "New Service Task creation failed. " + data.getError(), "Ok");
                }
                //this.createServiceTaskProcess.displayCreateRequest = false;
                this.createServiceTaskProcess.creatingServiceTaskInProgress = false;
            }
        );
    }

    goToServiceTask(serviceTaskId: number) {
        this.createServiceTaskProcess.displayResponse = false;
        this.createServiceTaskProcess.companiesPreSelected = false;
        this.sharedData.service.common.selectedServiceTaskID = serviceTaskId.toString();
        this.router.navigate(['/main/software/servicetask/triage', this.sharedData.service.common.selectedServiceTaskID]);
    }
    //#endregion

    //#region Gerrits    
    addGerritProcess: {
        displayForm: boolean,
        displayFormSummary: boolean,
        serviceTasksDetails: ServiceTaskInfo,
        baseSpBuild: string,
        avoidSIs: string[],
        availableImages: SelectItem[],
        selectedImage: SoftwareImage,
        availableTypes: SelectItem[],
        selectedType: string,
        imagesHlosGerritList: string,
        imagesHlosGerritSummary: string,
        imagesHlosCrList: string,
        addGerritInProgress: boolean,
        isCreateST: boolean,
        loadingImages: boolean,
        errorMessage:string,
        duplicateMessage:string
    }
    
    addGerritSubscriber: BehaviorSubject<ServiceTaskInfo>;
    newAddedGerritSI: string;   

    //#region Events
    onGerritActionAdd(baseSpBuild: string, avoidSIs: string[]){
        this.resetAddGerritProcess();
        this.addGerritProcess.baseSpBuild = baseSpBuild;
        this.addGerritProcess.selectedType = this.addGerritProcess.availableTypes[0].value;
        this.addGerritProcess.avoidSIs = avoidSIs;
        this.addGerritProcess.isCreateST = true;
        this.getAvailableHlosImages();
        this.addGerritProcess.displayForm = true;
    }
    onGerritActionAddToST(baseSpBuild: string, avoidSIs: string[], serviceTasksDetails: ServiceTaskInfo): Observable<ServiceTaskInfo>{
        this.resetAddGerritProcess();
        this.addGerritProcess.serviceTasksDetails = serviceTasksDetails;
        this.addGerritProcess.baseSpBuild = baseSpBuild;
        this.addGerritProcess.selectedType = this.addGerritProcess.availableTypes[0].value;
        this.addGerritProcess.avoidSIs = avoidSIs;
        this.addGerritProcess.isCreateST = false;
        this.getAvailableHlosImages();
        this.addGerritProcess.displayForm = true;
        this.addGerritSubscriber = new BehaviorSubject<ServiceTaskInfo>(null);
        return this.addGerritSubscriber.asObservable();
    }

    onGerritActionNext(){
        this.addGerritProcess.errorMessage = '';
        let gerritList = this.extractGerritsList(this.addGerritProcess.imagesHlosGerritList);
        if (gerritList !== null) {
            this.addGerritProcess.imagesHlosGerritSummary = gerritList.join(", ");
        }
        else{
            this.addGerritProcess.errorMessage = "Invalid Gerrit values. Please enter in correct format";
            return;
        }
        this.addGerritProcess.displayForm = false;
        this.addGerritProcess.displayFormSummary = true;
    }

    onGerritActionBack(){
        this.addGerritProcess.displayForm = true;
        this.addGerritProcess.displayFormSummary = false;
    }

    onGerritActionSubmit(){
        let gerritList = this.extractGerritsList(this.addGerritProcess.imagesHlosGerritList);
        if (gerritList === null) {
            this.addGerritProcess.errorMessage = "Invalid Gerrit values. Please enter in correct format";
            return;
        }
        let crList = this.extractCRsList(this.addGerritProcess.imagesHlosCrList);
        if(this.addGerritProcess.isCreateST){
            this.createServiceTaskProcess.gerritSIs.push({
                softwareImage: this.addGerritProcess.selectedImage.softwareImage,
                softwareImageBuild: this.addGerritProcess.selectedImage.softwareImageBuild,
                type: this.addGerritProcess.selectedType,
                gerrits: gerritList.join(", "),
                gerritIds: gerritList,
                crs: this.addGerritProcess.imagesHlosCrList,
                crIds: crList
            });
            this.resetAddGerritProcess();
        }
        else{
            this.addGerrit();
        }
    }
    //#endregion

    resetAddGerritProcess(){
        this.addGerritProcess = {
            displayForm: false,
            displayFormSummary: false,
            serviceTasksDetails: undefined,
            baseSpBuild: undefined,
            avoidSIs: [],
            availableImages: [],
            selectedImage: undefined,
            availableTypes:[{label:"Fix", value:"Fix"},
                            {label:"Customization", value:"Customization"},
                            {label:"Debug", value:"Debug"}],
            selectedType: undefined,
            imagesHlosGerritList: undefined,
            imagesHlosGerritSummary: '',
            imagesHlosCrList: undefined,
            addGerritInProgress: false,
            isCreateST: false,
            loadingImages: false,
            errorMessage: "",
            duplicateMessage: ""
        }
    }

    getAvailableHlosImages(){     
        if(!this.sharedData.visibility.software.addBaseImage){
            return;
        }
        let softwareProductBuild: string = this.addGerritProcess.baseSpBuild;
        QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProductBuild + " HLOS Image Composition");
        this.addGerritProcess.loadingImages = true;
        let response: Observable<QPMResponse>;
        response = this.softwareCatalogClient.getSoftwareProductBuildComposition(softwareProductBuild, true);
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProductBuild + " HLOS Image Composition - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProductBuild + " HLOS Image Composition - Success");
                    let obj = JSON.parse(data.getData());
                    if (obj !== undefined || obj !== null) {
                        let images: SoftwareImage[] = obj as SoftwareImage[];
                        let imagesSet: Set<string> = new Set<string>(this.addGerritProcess.avoidSIs);
                        let imagesFiltered: SoftwareImage[] = [];
                        images.forEach((image)=>{
                            if(!imagesSet.has(image.softwareImage)){
                                imagesFiltered.push(image);
                            }
                        });
                        if(imagesFiltered.length > 0) {
                            this.addGerritProcess.selectedImage = imagesFiltered[0];
                        }
                        this.addGerritProcess.availableImages = this.utils.getSelectItems(imagesFiltered, "softwareImage");
                    }
                }
                else{
                    QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProductBuild + " HLOS Image Composition - Failed");
                    QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProductBuild + " HLOS Image Composition - Error : " + data.getError() + " - " + data.getErrorDetail());
                }
                this.addGerritProcess.loadingImages = false;
            }
        );
    }
    
    validateGerrit(){
        if(this.addGerritProcess.serviceTasksDetails === undefined) return;
        this.addGerritProcess.duplicateMessage = "";
        let gerritList = this.extractGerritsList(this.addGerritProcess.imagesHlosGerritList);
        let gerritListUnique : Set<string>;
        gerritListUnique = new Set<string>(gerritList);
        let duplicateGerrits: string[] = [];
        this.addGerritProcess.serviceTasksDetails.changes.forEach((image)=>{
            if(this.addGerritProcess.selectedImage?.softwareImage === image.softwareImage){
                image.gerrits.forEach((gerrit)=>{
                    if(gerritListUnique.has(gerrit.gerritId)){
                        duplicateGerrits.push(gerrit.gerritId);
                    }
                });
            }
        });
        if(duplicateGerrits.length > 0){
            this.addGerritProcess.duplicateMessage = "Duplicate Gerrits: " + duplicateGerrits.join(", ");
        }
    }

    addGerrit(){
        this.addGerritProcess.errorMessage = "";
        let serviceTaskId = this.addGerritProcess.serviceTasksDetails.serviceTaskId;
        let gerritList = this.extractGerritsList(this.addGerritProcess.imagesHlosGerritList);
        let crList = this.extractCRsList(this.addGerritProcess.imagesHlosCrList);
        this.addGerritProcess.addGerritInProgress = true;
        let request: UpdateServiceTaskRequest;
        request = {
            abandoned: false,
            baseBuild: this.addGerritProcess.serviceTasksDetails.baseBuild,
            gerrits: [{
                        softwareImage: this.addGerritProcess.selectedImage.softwareImage,
                        softwareImageBuild: this.addGerritProcess.selectedImage.softwareImageBuild,
                        gerritIds: gerritList,
                        type: this.addGerritProcess.selectedType
                    }],
            serviceTaskId: serviceTaskId,
            requestAppId: this.addGerritProcess.serviceTasksDetails.requestAppId,
            requestSource: this.addGerritProcess.serviceTasksDetails.requestSource
        };

        let response: Observable<QPMResponse>;
        if(this.sharedData.appInfo.logRequest){
            QLogger.LogInfo(this.logSrc, "Update Service Task " + serviceTaskId + " add gerrit request : " + JSON.stringify(request));
        }
        response = this.webClient.updateServiceTask(serviceTaskId.toString(), request);
        response.subscribe(
            (data: QPMResponse) => {
                if(this.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc, "Update Service Task " + serviceTaskId + " add gerrit Response - Response : " +JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Update Service Task " + serviceTaskId + " add gerrit Response - Success");
                    this.newAddedGerritSI = this.addGerritProcess.selectedImage.softwareImage;
                    this.resetAddGerritProcess();
                    this.addGerritSubscriber.next(JSON.parse(data.getData()) as ServiceTaskInfo);
                }
                else {
                    QLogger.LogError(this.logSrc, "Update Service Task " + serviceTaskId + " add gerrit - Failed");
                    QLogger.LogError(this.logSrc, "Update Service Task " + serviceTaskId + " add gerrit - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
                    this.addGerritProcess.errorMessage = "Update failed. Error: " + data.getError();
                }
                this.addGerritProcess.addGerritInProgress = false;
            });
            //this.hideAddCRMessageBox();
    }
    //#endregion

    //#region CRSearch
    crSearchCols: any[];
    softwareTypes: any[];
    findCrStatusProcess:{
        displayForm: boolean;
        serviceTaskID: number,
        crList: crFixDetail[];
        selectedCrsToAdd: crFixDetail[];
        selectedSoftwareType: string;
        selectedSoftwareBuild: string;
        selectedCrs: string;
        selectedServiceTask: string;
        searchInProgress: boolean;
        errorMessage: string;
        isMaxLength: boolean;
    }
    
    crSearchResultProcess: {
        displayFormResponse: boolean,
        crSearchRequestID: string,
        requestUrl: string
    }
    
    initiateFindCrStatus(){
        this.resetFindCrStatusProcess();
        this.resetCrSearchResultProcess();
        this.setCrSearchTableColumn();
    }

    resetFindCrStatusProcess(){
        this.findCrStatusProcess = {
            displayForm: false,
            serviceTaskID: undefined,
            crList: [],
            selectedCrsToAdd: [],
            selectedSoftwareType: undefined,
            selectedSoftwareBuild: '',
            selectedCrs: '',
            selectedServiceTask: '',
            searchInProgress: false,
            errorMessage: '',
            isMaxLength: false
        }

        this.softwareTypes = [
            {label: "Product Build", value: "ProductBuild"},
            /*{label: "Image Build", value: "ImageBuild"}*/
        ];

        /*this.findCrStatusProcess.crList.push({
            crId: 2982440,
            softwareImageBuild: "MPSS.HI.4.3-01344.2-LC_ALL_PACK-1",
            fixStatus: "DefectFound",
            title: "Clone1_MAV30 : B511 Port B FBRX skip via RFC implementation",
            type: "Bug",
            area: "Modem",
            subsystem: "RFA",
            functionality: "RFA_RFCARD",
            softwareImageIntegratedBuild: "",
            buildType: "",
            source: ""
          });
          this.findCrStatusProcess.crList.push({
            crId: 2982442,
            softwareImageBuild: "MPSS.HI.4.3-01344.2-LC_ALL_PACK-1",
            fixStatus: "DefectFound",
            title: "[ASDiv][HI4.3] B8 uses dedicated XSW but cannot switch to CFG1 in B8/PCC+B3_N1 ENDC if NR is prioritized",
            type: "Bug",
            area: "Modem",
            subsystem: "MCS",
            functionality: "MCS_TRM",
            softwareImageIntegratedBuild: "",
            buildType: "",
            source: ""
          });*/
    }
    
    resetCrSearchResultProcess(){
        this.crSearchResultProcess = {
            displayFormResponse: false,
            crSearchRequestID: undefined,
            requestUrl: this.sharedData.appInfo.baseWebAppUrl+'/#/crsearch/'
        }
    }

    setCrSearchTableColumn(){
        this.crSearchCols = [];
        this.crSearchCols.push({ field: 'crId', header: 'Change Request', isFilterable: true });
    this.crSearchCols.push({ field: 'softwareImageBuild', header: 'Software Image Build', isFilterable: true });
    this.crSearchCols.push({ field: 'fixStatus', header: 'Status', isFilterable: true});
    this.crSearchCols.push({ field: 'softwareImageIntegratedBuild', header: 'Integrated Image', isFilterable: true});
    this.crSearchCols.push({ field: 'title', header: 'Title', isFilterable: true });
    this.crSearchCols.push({ field: 'type', header: 'Type', isFilterable: true });
    this.crSearchCols.push({ field: 'area', header: 'Area', isFilterable: true});
    this.crSearchCols.push({ field: 'subsystem', header: 'Subsystem', isFilterable: true });
    this.crSearchCols.push({ field: 'functionality', header: 'Functionality'});
    }

    startFindCrStatusProcess(softwareBuild: string, softwareType: string, serviceTaskID: number){
        this.resetFindCrStatusProcess();
        this.resetCrSearchResultProcess();
        this.findCrStatusProcess.displayForm = true;
        this.findCrStatusProcess.serviceTaskID = serviceTaskID;
        this.findCrStatusProcess.selectedSoftwareBuild = softwareBuild;
        this.findCrStatusProcess.selectedSoftwareType = softwareType;
        //this.findCrStatusProcess.selectedCrs = "327604, 54765";
        this.findCrStatusProcess.isMaxLength = false;
    }    

    showResultConfirmation(requestID: string){
        this.crSearchResultProcess.displayFormResponse = true;
        this.crSearchResultProcess.crSearchRequestID = requestID;
    }
      
    addCrsToST(){
        this.findCrStatusProcess.displayForm = false;
        this.sharedData.updateCommunicationSubcribers(commType.CrSearch, this.findCrStatusProcess.selectedCrsToAdd.filter(x => x.fixStatus !== 'Fixed' && x.fixStatus !== 'N/A').map(x => x.crId).join(", "));
    }
    
    getCrFixDetails(){
        this.resetCrSearchResultProcess();
        this.findCrStatusProcess.crList = [];
        this.errorMessage = "";
        let request: CrFixDetailRequest;
        request = {
          softwareItem: this.findCrStatusProcess.selectedSoftwareBuild,
          softwareItemType: this.findCrStatusProcess.selectedSoftwareType,
          crs: this.extractCRsList(this.findCrStatusProcess.selectedCrs).map(Number),
          serviceTaskId: this.findCrStatusProcess.serviceTaskID
        }
        this.findCrStatusProcess.searchInProgress = true;
        if(this.sharedData.appInfo.logRequest){
            QLogger.LogInfo(this.logSrc, "Get CR Fix details - Request: "+ JSON.stringify(request));
        }
        let response : Observable<QPMResponse>;
        response = this.webClient.getCrFixDetails(request);
        response.subscribe(
          (data:QPMResponse) => { 
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc,  "Get CR Fix details - Response : " +JSON.stringify(data));
            }
            if(data.isSuccess()){
              QLogger.LogInfo(this.logSrc,  "Get CR Fix details - Success");
              
              let obj = JSON.parse(data.getData());
              if(obj !== undefined || obj !== null){
                if(obj.resultPending){
                    this.findCrStatusProcess.displayForm = false;
                    this.showResultConfirmation(obj.requestId);
                }
                else{
                  this.findCrStatusProcess.crList = obj.crFixDetails;
                }
              }
            }
            else{
              QLogger.LogError(this.logSrc,  "Get CR Fix details - Failed");
              QLogger.LogError(this.logSrc,  "Get CR Fix details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.findCrStatusProcess.searchInProgress = false;
          }
        );
    }
    //#endregion
    
    //#region Email
    emailProcess: {
        displayForm: boolean,
        bodyTop: string,
        ccEmailIds: string,
        toEmailIds: string,
        email: Email,
        editTo: boolean,
        note: string,
        sendingEmail: boolean,
        errorMessage: string,
        editRadar: boolean,
        radarInfo: RadarInfo[]
    }

    resetEmailProcess(){
        this.emailProcess= {
            displayForm: false,
            bodyTop: "",
            ccEmailIds: "",
            toEmailIds: "",
            email: new Email(),
            editTo: false,
            note: "",
            sendingEmail: false,
            errorMessage: "",
            editRadar: false,
            radarInfo: []
        };
    }

    startEmailProcess(bodyTop: string, email: Email, note: string){
        this.emailProcess.bodyTop = bodyTop;
        this.emailProcess.email.fromEmailId = email.fromEmailId;
        this.emailProcess.email.toEmailIds = email.toEmailIds;
        this.emailProcess.email.ccEmailIds = email.ccEmailIds;
        this.emailProcess.email.subject = email.subject;
        this.emailProcess.email.body = email.body;
        this.emailProcess.note = note;
        this.emailProcess.displayForm = true;
        this.emailProcess.ccEmailIds = "";
        this.emailProcess.editTo = true;
        this.emailProcess.toEmailIds = email.toEmailIds.join('; ');
        this.emailProcess.editRadar = false;
        this.emailProcess.radarInfo = [];
    }

    startEmailProcessRelease(bodyTop: string, email: Email, note: string, crs: number[], externalID: String){
        this.emailProcess.bodyTop = bodyTop;
        this.emailProcess.email.fromEmailId = email.fromEmailId;
        this.emailProcess.email.toEmailIds = email.toEmailIds;
        this.emailProcess.email.ccEmailIds = email.ccEmailIds;
        this.emailProcess.email.subject = email.subject;
        this.emailProcess.email.body = email.body;
        this.emailProcess.note = note;
        this.emailProcess.displayForm = true;
        this.emailProcess.ccEmailIds = "";
        this.emailProcess.editTo = true;
        this.emailProcess.toEmailIds = email.toEmailIds.join(';');
        
        this.emailProcess.editRadar = true;
        this.emailProcess.radarInfo = [];

        if(externalID === undefined){
            crs.forEach((cr)=>{
                this.emailProcess.radarInfo.push({externalID: "", cr: cr.toString(), note: ""});
            });
        }
        else{
            this.emailProcess.radarInfo.push({externalID: externalID, cr: crs.join(", "), note: ""});
        }
    }

    sendEmail(){
        let response : Observable<QPMResponse>;
        if(this.webClient === null) {
            return;
        }

        if(this.emailProcess.editRadar){
            let bodyRadar : StringBuilder = new StringBuilder();
            bodyRadar.Append("<table border='1'>");
            bodyRadar.Append("<tr>");
            bodyRadar.Append("<th>External ID</th>");
            bodyRadar.Append("<th>CR Number</th>");
            bodyRadar.Append("<th>Note</th>");
            bodyRadar.Append("</tr>");

            this.emailProcess.radarInfo.forEach((info)=>{
            bodyRadar.Append("<tr>");
            bodyRadar.Append("<td>" + info.externalID + "</td>");
            bodyRadar.Append("<td>" + info.cr + "</td>");
            bodyRadar.Append("<td>" + info.note + "</td>");
            bodyRadar.Append("</tr>");
            });
            bodyRadar.Append("</table>");
            this.emailProcess.email.body = this.emailProcess.email.body + "<br/><br/>" + bodyRadar.ToString();
        }
        QLogger.LogInfo(this.logSrc, "Sending Email. Sub:  " + this.emailProcess.email.subject);
        let request: Email;
        request = {
            fromEmailId: this.emailProcess.email.fromEmailId,
            toEmailIds: this.emailProcess.editTo? this.emailProcess.toEmailIds.match(/[^\r\n,; ]+/g) : this.emailProcess.email.toEmailIds,
            ccEmailIds: this.emailProcess.ccEmailIds.match(/[^\r\n,; ]+/g),
            subject: this.emailProcess.email.subject,
            body: this.emailProcess.bodyTop + "<br/><br/>" + this.emailProcess.note + "<br/><br/>" + this.emailProcess.email.body,
        };
        if(this.sharedData.appInfo.logRequest){
            QLogger.LogInfo(this.logSrc, "Sending Email. Request: "+ JSON.stringify(request) );
        }

        this.emailProcess.sendingEmail = true;
        response = this.webClient.sendEmail(request);
        response.subscribe(
        (data:QPMResponse) => { 
            if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc, "Sending Email. Sub:  " + this.emailProcess.email.subject + " - Response : " +JSON.stringify(data));
            } 
            if (data.isSuccess()){
                let obj = JSON.parse(data.getData());
                if(obj !== undefined || obj !== null) {
                    QLogger.LogInfo(this.logSrc, "Sending Email. Sub:  " + this.emailProcess.email.subject + " - Success");
                    if (obj.success) {
                        this.emailProcess.displayForm = false;
                        this.resetEmailProcess();
                        this.showMessageBox("Send Email","Email has been sent successfully","OK");
                        this.sharedData.updateCommunicationSubcribers(commType.EmailSent, true);
                    }
                }
            }
            else{
                this.emailProcess.errorMessage = "Failed to send the email. Error: " + data.getError();
                QLogger.LogError(this.logSrc, "Sending Email. Sub:  " + this.emailProcess.email.subject + " - Failed");
                QLogger.LogError(this.logSrc, "Sending Email. Sub:  " + this.emailProcess.email.subject + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.emailProcess.sendingEmail = false;
        });
    }
    searchReleaseInfoByImage()
    {
        QLogger.LogInfo(this.logSrc,"Search Releases for image build "+this.searchReleaseByImageProcess.imageBuild);
        this.searchReleaseByImageProcess.loadingAvailableRelease = true;
        this.searchReleaseByImageProcess.softwareProductReleases =[];
        let partyID: number;
        let response : Observable<QPMResponse>;
        if(this.softwareCatalogClient === null || this.searchReleaseByImageProcess.imageBuild==undefined) {
            return;
        }
        if(this.selectedCustomer === undefined || this.selectedCustomer === null){
            partyID =  this.sharedData.userInfo.info.partyId;
        }
        else{      
            partyID = this.selectedCustomer.partyId;
        }
        response = this.softwareCatalogClient.getSoftwareProductByImageBuildAndPartyId(this.searchReleaseByImageProcess?.imageBuild, partyID)
        response.subscribe(
        (data:QPMResponse) => { 
            if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc, "Software Products for Image Build "+ this.searchReleaseByImageProcess?.imageBuild+ " - Response : " +JSON.stringify(data));
            }
            if(data.isSuccess()){
                QLogger.LogInfo(this.logSrc, "Software Products for Image Build "+ this.searchReleaseByImageProcess?.imageBuild +" Success");
                let obj = JSON.parse(data.getData());
                if (obj !== undefined || obj !== null) {
                
                    this.searchReleaseByImageProcess.softwareProductReleases = obj.softwareProductReleases;
                    if(this.searchReleaseByImageProcess.softwareProductReleases?.length==1){
                        this.searchReleaseByImageProcess.selectedProductRelease = this.searchReleaseByImageProcess.softwareProductReleases[0];
                    }
                }
                else
                QLogger.LogInfo(this.logSrc, "Cannot Find Software Products for Image Build "+ this.searchReleaseByImageProcess?.imageBuild);

            }
            else{
                QLogger.LogInfo(this.logSrc, "Software Products for Image Build "+ this.searchReleaseByImageProcess?.imageBuild +" Error "+data.getError());
            }
            
            this.searchReleaseByImageProcess.displaySearchResults = true;
            this.searchReleaseByImageProcess.loadingAvailableRelease = false;
        }); 
        
    }
    onSelectButtonClick()
    {
        if(this.searchReleaseByImageProcess.selectedProductRelease==undefined) return;
        this.selectedProductFamily = undefined;
        this.selectedProduct = undefined;
        this.selectedRelease = undefined;
        this.searchReleaseByImageProcess.displaySearchByImageDialog = false;
        //this.searchReleaseByImageProcess.displaySearchResults = false;
        if(this.searchReleaseByImageProcess.selectedProductRelease.spf!=null){
            this.productFamily.forEach((family) => {
                if(family.softwareProductFamily == this.searchReleaseByImageProcess.selectedProductRelease.spf){
                     this.selectedProductFamily = family;
                     this.createServiceTaskProcess.baseSPF = this.selectedProductFamily;
                } 
            });
            if(this.selectedProductFamily==undefined) {
                QLogger.LogError(this.logSrc,"Product Family "+this.searchReleaseByImageProcess?.selectedProductRelease?.spf+" not found in catalog ");
                this.showMessageBox("Search Release from Image Build","Cannot find selected Software Release.", "Ok");
                return;
            }
        }
        let productsFiltered: SoftwareProduct[] = [];
        if(this.selectedProductFamily==undefined)
        {
            this.productsSelectItems = this.utils.getSelectItems(this.products, "softwareProduct");
            if(this.productsMap.has(this.searchReleaseByImageProcess.selectedProductRelease.softwareProduct.toLowerCase())){
            
                this.selectedProduct = this.productsMap.get(this.searchReleaseByImageProcess.selectedProductRelease.softwareProduct.toLowerCase());
                this.selectedProductName = this.selectedProduct.softwareProduct;
                this.createServiceTaskProcess.prod = this.selectedProduct.softwareProduct;
            }
        }
        else{
            if(this.productFamilySpMap.has(this.selectedProductFamily.softwareProductFamily)){
                this.productFamilySpMap.get(this.selectedProductFamily.softwareProductFamily)
                .forEach((product) => {
                  if(this.productsMap.has(product.toLowerCase())&& product == this.searchReleaseByImageProcess.selectedProductRelease.softwareProduct){
                    productsFiltered.push(this.productsMap.get(product.toLowerCase()));
                  }
                });
              }
              this.productsSelectItems = this.utils.getSelectItems(productsFiltered, "softwareProduct");
              if(this.productsSelectItems.length > 0){
                this.selectedProduct = this.productsSelectItems[0].value;
                this.selectedProductName = this.selectedProduct.softwareProduct;
                this.createServiceTaskProcess.prod = this.selectedProduct.softwareProduct;
              }
        }
        
        if(this.selectedProduct==undefined)
        {
            QLogger.LogError(this.logSrc,"Product  "+this.searchReleaseByImageProcess?.selectedProductRelease?.softwareProduct+" not found in catalog ");
            this.showMessageBox("Search Release from Image Build","Cannot find selected Software Product.", "Ok");
            return;
        }
        let response: Observable<QPMResponse>;
        this.loadingReleases = true;
        response = this.softwareCatalogClient.getSoftwareProductBuilds(this.searchReleaseByImageProcess.selectedProductRelease.softwareProduct);
        response.subscribe(
          (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Get Available Software Product " + this.searchReleaseByImageProcess.selectedProductRelease.softwareProduct + " Builds - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Get Available Software Product " + this.searchReleaseByImageProcess.selectedProductRelease.softwareProduct + " Builds - Success");
              let obj = JSON.parse(data.getData());
              if (obj !== undefined || obj !== null) {
                this.releases =  obj.softwareProductReleases;
                this.releasesSelectItems = this.utils.getSelectItemsMultiLabel(this.releases, ["spfTag", "tag"], "-");
                this.releases?.forEach(release=>{
                       if(release.tag == this.searchReleaseByImageProcess.selectedProductRelease?.tag && (release.spfTag == undefined || release.spfTag == this.searchReleaseByImageProcess.selectedProductRelease?.spfTag )){
                          this.selectedRelease = release;
                          this.onReleaseChange();
                       }
                });
              }
            }
            this.loadingReleases = false;
           
        });

    }
  checkPkla()
  {
    let pklaResponse : Observable<QPMResponse>;
    let errorCode = -1;
    pklaResponse = this.webClient.checkPkla(this.sharedData.userInfo.username);
    pklaResponse.subscribe(
      (data:QPMResponse) => { 
        if (data.isSuccess()) {
            this.pklaCheckFailed = false;
            QLogger.LogInfo(this.logSrc, "User has signed PKLA.Set user as: " + this.sharedData.userInfo.username);
            let obj = JSON.parse(data.getData());
            this.sharedData.licenses.common.itemsAddedToCart=[];//Clear Cart
            this.sharedData.updateCommunicationSubcribers(commType.UpdateCartItems, this.sharedData.licenses.common.itemsAddedToCart);
            this.sharedData.licenses.common.toolUserRoles=[];
            this.sharedData.licenses.common.toolUserRoles=obj?.roles;
            this.sharedData.licenses.common.partyId=null;
            this.sharedData.licenses.common.partyId=obj?.partyId;
            this.sharedData.licenses.common.vordelUserCountryCode=null;
            this.sharedData.licenses.common.vordelUserCountryCode=obj?.country;
            this.setUserInfo();            
        }
        else{
            let obj = JSON.parse(data.getData());
            errorCode = obj.errorCode as number;
            if (((errorCode < 200) || (errorCode > 299)) && (errorCode != -1)){
                QLogger.LogError(this.logSrc,"Error Checking PKLA");
                this.pklaCheckFailed = true;
            }
            else{
                QLogger.LogError(this.logSrc,"Error Fetching PKLA for user "+this.sharedData.userInfo.username);
                this.onLogout(null);
            }
        }

    });
  }
  acceptPkla()
  {
    if (!isElectron()) {
        window.open("https://www.qualcomm.com/agreements", "_blank");
        this.onLogout(null);
    }
  }
  onChangeCRList()
  {
    let crList = this.extractCRsList(this.findCrStatusProcess.selectedCrs).map(Number);
    if (crList.length > 20) {
        this.findCrStatusProcess.isMaxLength = true;
    }
    else {
        this.findCrStatusProcess.isMaxLength = false;
    }
  }
    //#endregion

    //#region LicenseModule Process Objects
    renewDialogProcess:{
        showRenewDialog: boolean;
        fetchingPricingDetailsInprogress:boolean;
        selectedLicense:License;
        errorMessageForRenew:string;
    }

    //#region LicenseModule Functions
    resetRenewDialogProcess(){
        this.renewDialogProcess = {
            showRenewDialog: false,
            fetchingPricingDetailsInprogress:false,
            selectedLicense:new License(),
            errorMessageForRenew:''
        }
    }

    openRenewDialogProcess(selectedLicense:License){
        this.resetRenewDialogProcess();
        this.renewDialogProcess.showRenewDialog=true;
        this.renewDialogProcess.selectedLicense=selectedLicense;
    }


    AddLicenseToCartWithPricingDetails(){
        if(this.renewDialogProcess.selectedLicense!=undefined){
            if ((this.sharedData.licenses.common.itemsAddedToCart.filter(item => item.selectedLicense.serialNumber === this.renewDialogProcess.selectedLicense.serialNumber).length == 0)) {
                //call getPriceingDetails
                let requestLineArray:RequestLine[]=[];
                this.renewDialogProcess.selectedLicense.licenseGroups.forEach(licenseGroup=>{
                    let requestLine= new RequestLine();
                    requestLine.serialNumber=this.renewDialogProcess.selectedLicense.serialNumber;
                    requestLine.licenseGroupId=licenseGroup.id;
                    requestLine.startDate=licenseGroup.startDate;
                    requestLine.partnerId=this.sharedData.licenses.common.partyId;
                    
                    requestLineArray.push(requestLine);
                })

                let requestLines=new RequestLines();
                requestLines.requestLine=requestLineArray;
                
                let priceRequest= new PriceRequest();
                priceRequest.userName=this.sharedData.userInfo.username;
                priceRequest.requestLines=requestLines;
                let request = new PricingDetailsRequest();
                request.priceRequest=priceRequest;

                this.renewDialogProcess.fetchingPricingDetailsInprogress = true;
                QLogger.LogInfo(this.logSrc, "Get Pricing Details - Request: "+ JSON.stringify(request));
                let response : Observable<QPMResponse>;
                response = this.webClient.getPricingDetails(request);
               
                response.subscribe(
                    (data:QPMResponse) => { 
                      if(this.sharedData.appInfo.logResponse){
                        QLogger.LogInfo(this.logSrc,  "Pricing Details - Response : " +JSON.stringify(data));
                      }
                      if(data.isSuccess()){
                          QLogger.LogInfo(this.logSrc, "Pricing Details - Success Response : "+JSON.stringify(data));
                          let obj = JSON.parse(data.getData());
                          let errorCode = obj.errorCode;
                          let errorMessage=obj.errorMessage;
                          //Error case
                          if(errorCode !== undefined || errorCode !== null){
                            this.renewDialogProcess.fetchingPricingDetailsInprogress = false;
                            QLogger.LogInfo(this.logSrc,  "Failed to load Pricing Details:  " + errorMessage);
                            this.renewDialogProcess.errorMessageForRenew = "Error : " + errorMessage;
                          }
                           //Success case
                         if(obj !== undefined || obj !== null && (errorCode==undefined || errorCode==null)){
                               
                                this.renewDialogProcess.fetchingPricingDetailsInprogress = false;
                                let response: PricingDetailsResponse;
                                response = obj;
                                let cartItem=new CartItem();
                                cartItem.selectedLicense=this.renewDialogProcess.selectedLicense;
                                cartItem.responseLineDetail=response.responseLines.responseLine[0];//Same for all and Used For Price and Address
                                this.sharedData.licenses.common.itemsAddedToCart.unshift(cartItem);
                                this.sharedData.updateCommunicationSubcribers(commType.UpdateCartItems, this.sharedData.licenses.common.itemsAddedToCart);
                                this.resetRenewDialogProcess();
                                QLogger.LogInfo(this.logSrc, "Redirecting To Cart-Page");
                                this.router.navigate(['/main/licenses/cart']);     
                            }

                        }else{
                            //Network and other generic Error case (Unknown Error) 
                            this.renewDialogProcess.fetchingPricingDetailsInprogress = false;
                            QLogger.LogInfo(this.logSrc,  "Failed to load Pricing Details:  " + data.getError()+ " - " + data.getErrorDetail());
                            this.renewDialogProcess.errorMessageForRenew = "Failed to load Pricing Details: " + data.getError();
                        }
                    
                    });

            }else{
                this.renewDialogProcess.errorMessageForRenew="Already exists in cart for renewal"
            }
            
        }
    }
    goToLicenseGroupDetailsPage(licenseGroupID:string,rowData:License){
        QLogger.LogInfo(this.logSrc,"Selected Serial Number: " + rowData.serialNumber!=''?rowData.serialNumber:'NA'+ " |  Selected license Group: " + licenseGroupID);//Need to handle empty SL-Number
        this.sharedData.licenses.common.licensesGroupID=licenseGroupID;
        this.sharedData.licenses.common.serialNumber=rowData.serialNumber!=''?rowData.serialNumber:'NA';
        this.sharedData.licenses.common.selectedLicense=rowData;
        this.router.navigate(['/main/licenses/licensesgroup',rowData.serialNumber!=''?rowData.serialNumber:'NA', this.sharedData.licenses.common.licensesGroupID]);
      }
    
    
      goToSerialNumberDetailsPage(serialNumber:string,rowData:License){
        QLogger.LogInfo(this.logSrc,"Selected Serial Number: " + rowData.serialNumber);
        this.sharedData.licenses.common.licensesGroupID=rowData.serialNumber;
        this.sharedData.licenses.common.serialNumber=rowData.serialNumber;
        this.sharedData.licenses.common.selectedLicense=rowData;
        this.router.navigate(['/main/licenses/serialnumber',this.sharedData.licenses.common.serialNumber]);
    
      }
    //#endregion
}
