<router-outlet></router-outlet>
<p-dialog id="InstallDetailsDialog" [(visible)]="showInstallProgress" modal="modal" showEffect="fade"
  [style]="{width: '1078px', height: '715px'}" (onHide)="OnInstallDialogClose()">
  <div class="ui-g-11" style="margin-left: 20px;margin-right: 20px;">
    <!-- HEADER-->
    <div>
      <h2 id="ProductDisplayName" style="margin-top: 2px; margin-bottom: 5px;">{{selectedProduct?.displayName}}</h2>
      <p id="Version" style="color: gray;margin-top: 5px;margin-bottom: 2px;">Version {{selectedVersion?.version}} for {{selectedOS}}</p>
      <p id="OwnerTeam" *ngIf="app.sharedData.visibility.tools.viewOwnerTeam" style="color: gray;margin-top: 2px;">
        Tool Owner: <a href="mailto:{{selectedProduct?.ownerTeam}}?subject={{selectedProduct?.displayName}} query"
          style="color:blue; margin-top: 2px;">{{selectedProduct?.ownerTeam}}</a></p>
    </div>

    <!--MIDDLE CONTENT : EULA-->
    <div *ngIf="showInstallProgress==true && showEula==true" style="margin-top:40px;">
      <h3>Please review the terms below. You must accept to proceed.</h3>
      <hr>
      <div *ngFor="let line of eulaString">
        {{line}}
        <br><br>
      </div>
      <div class="ui-g-12" style="margin-top: 40px;">
        <button pButton id="customizeInstall" type="button" (click)="onAcceptEulaClicked()" label="I Accept" style="margin-left: -5px;"></button>
        <button pButton id="cancelInstall" type="button" (click)="cleanInstall()" label="Cancel" style="margin-left: 15px;"></button>
      </div>
    </div>

    <!--MIDDLE CONTENT : Dependency Download (this should go away?)-->
    <div *ngIf="showInstallProgress==true && (showInstallStatus==true)"
      class="ui-g-12">
      <h3>{{installationStatus}}</h3>
      <hr>
      <div class="ui-g-12">
        <p-progressBar *ngIf="installationStatus!='Finished'" mode="indeterminate"></p-progressBar>
      </div>
      <div class="ui-g-12">
        <div *ngFor="let line of installProgress">
          {{line}}
          <br><br>
        </div>
      </div>
      <hr>
      <div class="ui-g-12">
        <button pButton id="installComplete" type="button" (click)="cleanInstall()" label="Close"></button>
      </div>
    </div>

    <!--MIDDLE CONTENT : Download Progress-->
    <div *ngIf="showInstallProgress==true && showDownloadProgress==true">
      <div style="word-break: break-all;">
        <b>Downloading:</b> {{app.downloadStatus.fileName}}
      </div>
      <hr>
      <div class="ui-g-12">
        <h5 *ngIf="app.downloadStatus.totalDownloadSize!=0">Downloaded {{app.downloadStatus.downloadedSizeString}} of {{app.downloadStatus.totalDownloadSizeString}} at {{app.downloadStatus.downloadSpeedString}}/s</h5>
        <p-progressBar mode="determinate" [value]="app.downloadStatus.downloadProgress"></p-progressBar>
      </div>
      <hr>
    </div>

    <div *ngIf="showInstallProgress==true && showConfig==true" style="margin-top:40px;height: 350px;">
      <div *ngIf="configUIBindings.showCustomPathSelector==true">
        <h3>Install Option: Select Custom Install Location</h3>
        <hr>

        <div class="dataBox" style="text-align:left;background-color:blue;">

          <div class="ui-g-12" style="margin-left:0px;padding-left: 0px;">
            <input type="radio" id="defaultLocRadio" [(ngModel)]="configUIBindings.customPathScreen.radioSelected"
              (change)="onPathRadioChange('defaultLocRadio')" value="defaultLocRadio" name="pathSelector">
            <label for="defaultLocRadio">Default Location: </label>
          </div>
          <div class="ui-g-12" style="margin-left: 15px;">
            <label>{{configUIBindings.customPathScreen.defaultLocation}}</label>
          </div>


          <div class="ui-g-12" style="margin-top:30px;padding-left: 0px;">
            <input type="radio" id="customLocRadio" [(ngModel)]="configUIBindings.customPathScreen.radioSelected"
              (change)="onPathRadioChange('customLocRadio')" value="customLocRadio" name="pathSelector">
             
            <label for="customLocRadio">Custom Location: </label>
          </div>
          <div class="ui-g-12">
            <input class="ui-g-8" id="InstallDirectoryInput" type="text" pInputText
              [(ngModel)]="configUIBindings.customPathScreen.customLocation"
              value="{{configUIBindings.customPathScreen.customLocation}}"
              [disabled]="configUIBindings.customPathScreen.isDefaultSelected"
              style="margin-left: 15px;">
              <button class="ui-g-2" pButton icon="pi pi-folder-open" iconPos="center" id="customizeInstallBtn"
              type="button" (click)="getfolder()" [disabled]="configUIBindings.customPathScreen.isDefaultSelected">
            </button>

        </div>
        <div *ngIf="configUIBindings.showDependenciesSelector==true">
          <h3>Install Option:</h3>
          <h4> Select Dependencies</h4>
          <p-table [value]="configUIBindings.dependenciesSelectorScreen.components" [scrollable]="true"
            scrollHeight="275px">
            <ng-template pTemplate="header">
              <tr>
                <th>Component Name</th>
                <th>Versions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-component>
              <tr>
                <td>{{component.componentName}}</td>
                <td>
                  <p-dropdown [options]="component.versions" [(ngModel)]="component.selectedVersion" optionLabel="value" optionValue="id"></p-dropdown>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div *ngIf="configUIBindings.showAddOnsSelector==true">
          <h3>Install Option:</h3>
          <h4> Select Addons</h4>
          <p-table [value]="configUIBindings.addOnsSelectorScreen.components" [scrollable]="true" scrollHeight="275px">
            <ng-template pTemplate="header">
              <tr>
                <th>Component Name</th>
                <th>Install</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-component>
              <tr>
                <td>{{component.componentName}}</td>
                <td>
                  <p-inputSwitch [(ngModel)]="component.checked"></p-inputSwitch>
                </td>
              </tr>
            </ng-template>
          </p-table>
         
        </div>

      </div>
      </div>
      <div *ngIf="configUIBindings.showPrimaryInstallPathSelector==true">
        <h3>Install Option: Select Installation Directory</h3>
        <hr>
        <div class="dataBox" style="text-align:left;">
          <div class="ui-g-12" *ngFor='let installations of configUIBindings.primaryComponentInstallSelectorScreen.primaryInstallations;let i = index'>                    
            <input type="radio" [checked]="configUIBindings.primaryComponentInstallSelectorScreen.selectedInstallationIndex==i"(change)='onPrimaryInstallPathRadioChange(i)' > 
             v<b>{{installations.version}}</b> installed at <i>{{installations.InstallLocation}}</i> 
          </div> 
        </div>
      </div>
      <div *ngIf="configUIBindings.showDependenciesSelector==true">
        <h3>Install Option: Select Dependencies</h3>

        <p-table [value]="configUIBindings.dependenciesSelectorScreen.components" [scrollable]="true" scrollHeight="275px">
          <ng-template pTemplate="header">
            <tr>
              <th style="font-weight:bold;">Component Name</th>
              <th style="font-weight:bold;">Versions</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-component>
            <tr>
              <td>{{component.componentName}}</td>
              <td>
                <p-dropdown [options]="component.versions" [(ngModel)]="component.selectedVersion" optionLabel="value" optionValue="id"></p-dropdown>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div *ngIf="configUIBindings.showAddOnsSelector==true">
        <h3>Install Option: Select Addons</h3>

        <p-table [value]="configUIBindings.addOnsSelectorScreen.components" [scrollable]="true" scrollHeight="275px">
          <ng-template pTemplate="header">
            <tr>
              <th style="font-weight:bold;">Component Name</th>
              <th style="font-weight:bold;">Install</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-component>
            <tr>
              <td>{{component.componentName}}</td>
              <td>
                <p-inputSwitch [(ngModel)]="component.checked"></p-inputSwitch>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="ui-g-12" *ngIf="!showSpinner">
        <button class="configNavBtn" (click)="ConfigPrevClicked()" pButton type="button"
            [disabled]="!configUIBindings.enablePrev" label="Previous" style="margin-left: -5px;"></button>
        <button class="configNavBtn" (click)="ConfigNextClicked()" pButton type="button"
            [disabled]="!configUIBindings.enableNext" label="Next" style="margin-left: 15px;"></button>
      </div>
    </div>
  </div>
  <div class="progress-spinner" *ngIf="showSpinner">
    <h3>Loading package settings... Please wait.</h3>
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div *ngIf="showInstallProgress==true && showSummary==true">
    <div class="ui-g-10" style="text-align:left; margin-left: 20px;">
      <h3 id="summaryHeader">{{actionSummary.action}} Summary</h3>
      <hr>
      <div class="summaryDetails" style="text-align:left; max-height: 350px; margin-top: 20px; overflow-y: auto">
        <div *ngIf="actionSummary.showActionLocation">
          <p>
            {{actionSummary.actionFileLocationString}} <button  class="btn-file-open" (click)="OpenFileInFileManager(actionSummary.actionLocation)" >{{actionSummary.actionLocation}} </button>
          </p>
        </div>
        <p>
            {{actionSummary.action}} {{actionSummary.successString}}
        </p>
        <div *ngFor="let line of actionSummary.summary">
          {{line}}
          <br><br>
        </div>
      </div>
      <hr>
      <div  *ngIf="actionSummary.showLogLocation" style="text-align:left; margin-top: 20px;">
        <p>
          <button class="btn-file-open" (click)="OpenFileInFileManager(actionSummary.logLocation)">Open Log Folder</button>
        </p>
      </div>
    </div>
    <div class="ui-g-10" style="text-align:left; margin-left: 20px;">
      <div class="ui-g-3"> <button class="closeButton" (click)="OnInstallDialogClose()" pButton type="button"
          label="Close"></button></div>
    </div>
  </div>


</p-dialog>
<div *ngIf="showAppLaunchSelector">
  <p-dialog [(visible)]="showAppLaunchSelector" [style]="{width: '500px'}" (visibleChange)="OnDeepLinkSelectorClose()">
    <div style="display: 'block';margin-top: 0px; margin-bottom: 40px;">
    <p>To install {{deepLinkInstallerInfo.productName}} you need the Qualcomm Package Manager 3 [QPM3] Desktop Application. Do you already have it installed?</p>
    </div>

      <button (click)="OnLaunchQPMClick()" pButton type="button" label="Yes, launch QPM3" style=" float: right;margin-right: 10px; margin-left: 10px;">
      </button>
      <button (click)="OnDownloadQPMClick()" pButton type="button" label="No, install QPM3" style="float: right;margin-right: 10px; margin-left: 10px;"></button>
  </p-dialog>
</div>

