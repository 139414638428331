<div class="ui-g" style="min-height: calc(80vh);">
    <div class="ui-g-12">
        <div class="card card-w-title">
          
          <div class="ui-g">
              <div class="ui-g-12">
                <h1 id="serviceTaskFinderHeader" style="display: inline-block;">Service Task Finder</h1>
                <p *ngIf="app.sharedData.appInfo.workOffline">Not available in offline mode</p>
                <button *ngIf="app.sharedData.visibility.software.requestServiceTask && app.sharedData.appInfo.workOffline==false"pButton id="createServiceTaskButton" type="button" (click)="onCreateServiceTaskClick()" label="Create Service Task"
                  style="float: right;margin-top: 15px;"></button>
              </div>
          </div>
          <div class="ui-g" *ngIf="app.sharedData.appInfo.workOffline==false">
            <div class="ui-g-9">
              <div *ngIf="loadingServiceTasks">
                <h3 style="display: inline-block;">Loading Details. Please wait...</h3>
                <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
              </div>
            </div>
            <div class="ui-g-12">
              <p-table id ="serviceTaskTable" #serviceTaskTable [columns]="cols" [value]="serviceTasks" dataKey="serviceTaskId"  
                [paginator]="true" [(first)]="tableParam.first" [rows]="tableParam.pageSize" [totalRecords]="tableParam.totalRecords"
                sortField="serviceTaskId" [sortOrder]="-1" reorderableColumns="true" 
                selectionMode="single" (onRowSelect)="onRowSelect($event)" (onFilter)="onFilterServiceTaskTable($event)" (onSort)="onSortServiceTaskTable($event)"
                (onPage)="onPageChange($event)" [lazy]="true" (onLazyLoad)="loadMoreServiceTask($event)"
                [globalFilterFields]="['serviceTaskId','requestedDate','baseBuildSpf','baseBuild','requestSource','requestAppId', 'latestRelease', 'requester', 'companyNameList', 'statusString', 'masterString']">     
                  <ng-template pTemplate="caption">
                    <div *ngIf="errorMessage !== ''" style="text-align: left;">
                      <p>{{errorMessage}}</p>
                    </div>           
                    <div style="text-align: right; height: 25px;">
                      <i class="ui-icon-cancel" id="clearFilterST" pTooltip="Clear All Filters" (click)="onClearAllFilters()"
                        style="margin:4px 4px 0 0"></i>
                      <i class="ui-icon-refresh" id="refreshST" *ngIf="!loadingServiceTasks" pTooltip="Refresh" (click)="getAllServiceTasks(getQuery())" ></i>
                      <p-progressSpinner id="refreshShipSpinner" *ngIf="loadingServiceTasks" 
                        [style]="{width: '20px', height: '20px', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s">
                      </p-progressSpinner>
                    </div>
                  </ng-template>
    
                  <ng-template pTemplate="header" let-columns>
                      <tr>
                          <th id="stFinderserviceTaskId" pSortableColumn="serviceTaskId" style="width: 100px;">
                            Service Task ID 
                            <p-sortIcon field="serviceTaskId">
                            </p-sortIcon>
                          </th>
                          <th id="stFinderRequestedDate" pSortableColumn="requestedDate" style="width: 110px;">Requested Date <p-sortIcon field="requestedDate"></p-sortIcon></th>
                          <th id="stFinderBaseBuildSPF" pSortableColumn="baseBuildSpf">Base Build SPF<p-sortIcon field="baseBuildSpf"></p-sortIcon></th>
                          <th id="stFinderBaseBuild" pSortableColumn="baseBuild">Base Build <p-sortIcon field="baseBuild"></p-sortIcon></th>
                          <th id="stFinderRequestSource" pSortableColumn="requestSource">Request Source<p-sortIcon field="requestSource"></p-sortIcon></th>
                          <th id="stFinderExternalId" pSortableColumn="requestAppId">External ID<p-sortIcon field="requestAppId"></p-sortIcon></th>
                          <th id="stFinderLatestRelease" pSortableColumn="latestRelease">Latest<p-sortIcon field="latestRelease"></p-sortIcon></th>
                          <th id="stFinderTotalReleases" pSortableColumn="totalReleases">Releases<p-sortIcon field="totalRelease"></p-sortIcon></th>
                          <th  id="stFinderRequesterId" pSortableColumn="requester" style="width: 120px;">Requester <p-sortIcon field="requester" ></p-sortIcon></th>
                          <th id="stFinderIsMaster" pSortableColumn="is_master">Master <p-sortIcon field="is_master" ></p-sortIcon></th>
                          <th pSortableColumn="companyNames" *ngIf="app.sharedData.visibility.serviceTaskFinder.customerColumn" style="width: 230px;">Customer(s) <p-sortIcon field="companyNames"></p-sortIcon></th>
                          <th id="stFinderStatus" pSortableColumn="is_abandoned" style="width: 100px;">Status <p-sortIcon field="is_abandoned"></p-sortIcon></th>
                      </tr>
                      <tr>
                        <th style="width: 100px;">
                          <div class="ui-inputgroup">
                            <i class="pi pi-filter" style="font-size: 1.2em; margin-left: -10px; margin-top: 5px;"></i>
                            <span class="md-inputfield">
                                <input #filterServiceTaskId pInputText type="text" placeholder="ST#" [(ngModel)]="filterValues.serviceTaskId"
                                    (input)="serviceTaskTable.filter($event.target.value, 'serviceTaskId', 'equals')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.serviceTaskId !== ''" style="min-width: 0;">
                              <i #clearFilterServiceTaskId class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.serviceTaskId = ''; serviceTaskTable.filter('', 'serviceTaskId', 'equals')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 110px;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterRequestedDate pInputText type="text" placeholder="yyyy-mm-dd" [(ngModel)]="filterValues.requestedDate"
                                    (input)="serviceTaskTable.filter($event.target.value, 'requestedDate', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requestedDate !== ''" style="min-width: 0;">
                              <i #clearFilterRequestedDate class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.requestedDate = ''; serviceTaskTable.filter('', 'requestedDate', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterBaseBuildSpf pInputText type="text" placeholder="SPF" [(ngModel)]="filterValues.baseBuildSpf"
                                    (input)="serviceTaskTable.filter($event.target.value, 'baseBuildSpf', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.baseBuildSpf !== ''" style="min-width: 0;">
                              <i #clearFilterBaseBuildSpf class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.baseBuildSpf = ''; serviceTaskTable.filter('', 'baseBuildSpf', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterBaseBuild pInputText type="text" placeholder="Build" [(ngModel)]="filterValues.baseBuild"
                                    (input)="serviceTaskTable.filter($event.target.value, 'baseBuild', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.baseBuild !== ''" style="min-width: 0;">
                              <i #clearFilterBaseBuild class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.baseBuild = ''; serviceTaskTable.filter('', 'baseBuild', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield" style="width: 85%; display: block;">
                                <p-multiSelect  #filterRequestSource [options]="filterOptionReqSource" [(ngModel)]="filterValues.requestSource"
                                    defaultLabel="All" [style]="{'min-width':'0','width':'100%'}"
                                    (onChange)="serviceTaskTable.filter($event.value, 'requestSource', 'in')" >
                                </p-multiSelect >
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requestSource?.length > 0 " style="min-width: 0; border: 0">
                              <i #clearFilterRequestSource class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer"
                                  (click)="filterValues.requestSource = []; serviceTaskTable.filter([], 'requestSource', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterRequestAppId pInputText type="text" placeholder="External ID" [(ngModel)]="filterValues.requestAppId"
                                    (input)="serviceTaskTable.filter($event.target.value, 'requestAppId', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requestAppId !== ''" style="min-width: 0;">
                              <i #clearFilterRequestAppId class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.requestAppId = ''; serviceTaskTable.filter('', 'requestAppId', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterLatestRelease pInputText type="text" placeholder="Build ID" [(ngModel)]="filterValues.latestRelease"
                                    (input)="serviceTaskTable.filter($event.target.value, 'latestRelease', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.latestRelease !== ''" style="min-width: 0;">
                              <i #clearFilterLatestRelease class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.latestRelease = ''; serviceTaskTable.filter('', 'latestRelease', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterTotalReleases pInputText type="text" placeholder="Count" [(ngModel)]="filterValues.totalReleases"
                                    (input)="serviceTaskTable.filter($event.target.value, 'totalReleases', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.totalReleases !== ''" style="min-width: 0;">
                              <i #clearFilterTotalReleases class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.totalReleases = ''; serviceTaskTable.filter('', 'totalReleases', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 120px;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterRequester pInputText type="text" placeholder="Requester ID" [(ngModel)]="filterValues.requester"
                                    (input)="serviceTaskTable.filter($event.target.value, 'requester', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requester !== ''" style="min-width: 0;">
                              <i #clearFilterRequester class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.requester = ''; serviceTaskTable.filter('', 'requester', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield" style="width: 85%; display: block;">
                                <p-dropdown #filterMaster [options]="filterOptionMaster" [(ngModel)]="filterValues.master" [style]="{'min-width':'0','width':'100%'}"
                                    (onChange)="serviceTaskTable.filter($event.value, 'master', 'equals')" >
                                </p-dropdown>
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.master !== undefined" style="min-width: 0; border: 0">
                              <i #clearFilterMaster class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer"
                                  (click)="filterValues.master = undefined; serviceTaskTable.filter(undefined, 'master', 'equals')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 230px;" *ngIf="app.sharedData.visibility.serviceTaskFinder.customerColumn">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterCustomer pInputText type="text" placeholder="Customer" [(ngModel)]="filterValues.customer"
                                    (input)="serviceTaskTable.filter($event.target.value, 'companyNameList', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.customer !== ''" style="min-width: 0;">
                              <i #clearFilterCustomer class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.customer = ''; serviceTaskTable.filter('', 'companyNameList', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 100px;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield" style="width: 85%; display: block;">
                                <p-dropdown #filterStatusString [options]="filterOptionStatus" [(ngModel)]="filterValues.statusString" [style]="{'min-width':'0','width':'100%'}"
                                    (onChange)="serviceTaskTable.filter($event.value, 'statusString', 'equals')" >
                                </p-dropdown>
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.statusString !== undefined" style="min-width: 0; border: 0">
                              <i #clearFilterStatusString class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer"
                                  (click)="filterValues.statusString = undefined; serviceTaskTable.filter(undefined, 'statusString', 'equals')">
                              </i>
                            </span>
                          </div>
                        </th>
                      </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr [pSelectableRow]="rowData" [pEditableRow]="rowData">
                          <td id="serviceTaskIdValue">{{rowData.serviceTaskId}}</td>
                          <td id="serviceTaskRequestDateValue">{{rowData.requestedDate}}</td>
                          <td id="serviceTaskBaseBuildSpfValue">{{rowData.baseBuildSpf}}</td>
                          <td id="serviceTaskBaseBuildValue">{{rowData.baseBuild}}</td>
                          <td id="serviceTaskRequestSourceValue">{{rowData.requestSource}}</td>
                          <td id="serviceTaskRequestAppIdValue">{{rowData.requestAppId}}</td>
                          <td id="serviceTaskLatestReleaseValue">
                            <div *ngIf="rowData.latestRelease !== null && rowData.latestRelease !== undefined">
                              {{rowData.latestRelease}}
                              <i class="ui-icon-remove-red-eye" pTooltip="CR Details" (click)="app.viewReleaseCRs(rowData.serviceTaskId, rowData.releaseCRs)"></i>
                            </div>
                          </td>
                          <td id="serviceTaskTotalReleaseValue">{{rowData.totalReleases}}</td>
                          <td id="serviceTaskRequesterValue">{{rowData.requester}}</td>
                          <td id="serviceTaskMasterValue" >{{rowData.master? "Yes" : "No"}}</td>
                          <td id="serviceTaskCustomerValue" *ngIf="app.sharedData.visibility.serviceTaskFinder.customerColumn">
                            <span *ngFor="let cust of rowData.companyNameList" style="width: 100%; display: inline-block">
                              {{cust}}
                            </span>
                          </td>
                          <td id="serviceTaskStatusValue">{{rowData.statusString}}</td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>
          </div>
      </div>
    </div>
</div>