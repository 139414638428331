import { QPMResponse } from 'src/app/models/response';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { InstalledPackageDetails, InstalledVersion } from 'src/app/models/catalog-client';
import { QLogger } from 'src/app/common/logger';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { Component } from '@fullcalendar/core';
import { Product, ProductCatalogV3, ProductV3, ReleasesV3, ReleaseV3 } from 'src/app/models/lime-web-client';
import { selectableOSs } from 'src/app/common/shared-data';
import { Injectable } from '@angular/core';
import { TTContextMenuRow } from 'primeng';
import { AppMainComponent } from 'src/app/app.main.component';
import { toolsConstants } from '../toolsConstants';
import * as e from 'cors';


export class CatalogByOs{
    os:selectableOSs;
    latestCatalog:ProductCatalogV3;
    lastUpdated:Date;
    constructor(os:selectableOSs, latestCatalog:ProductCatalogV3, lastUpdated:Date){
        this.os = os;
        this.latestCatalog= latestCatalog;
        this.lastUpdated = lastUpdated;
    }
}
export class ReleasesV3ByOS{
    os:selectableOSs;
    releases:ReleaseV3[];
    productId:string;
    lastUpdated:Date;
    constructor(os: selectableOSs, releases:ReleaseV3[] , productId:string, lastUpdated:Date){
        this.os = os;
        this.releases = releases;
        this.productId = productId;
        this.lastUpdated = lastUpdated;
    }
}
export class CatalogUtils{
    private logSrc:string = "CatalogUtils";
    private latestCatalogs:Map<selectableOSs, CatalogByOs>;
    private productReleasesMap:Map<string, Map<selectableOSs,ReleasesV3ByOS>>// key of the outer map would be productid
    
    private username:string;
    constructor( public app : AppMainComponent, private webClient:WebClientService){
        this.latestCatalogs = null;
        this.username = this.app.sharedData.userInfo.username;

    }
    private isCatalogStale(catalog:CatalogByOs){
        return(new Date().getTime() - catalog.lastUpdated.getTime() > toolsConstants.CatalogExpiryInterval_ms);
    }
    private isProductV3ReleaseInfoStale(releasesV3: ReleasesV3ByOS){
        if(releasesV3 == null) return true;
        return(new Date().getTime() - releasesV3.lastUpdated.getTime() > toolsConstants.ProductReleasesInfoExpiryInterval_ms);
    }
    private doesCatalogNeedToBeFetched(os:selectableOSs ):boolean{
        if(this.app.sharedData.userInfo.username != this.username) return true;
        if(!this.latestCatalogs?.has(os)) return true;
        if(this.isCatalogStale(this.latestCatalogs.get(os))) return true;

        return false;
    }
    private doesProductV3ReleasesNeedToBeFetched(os:selectableOSs, productId:string){
        if(this.app.sharedData.userInfo.username != this.username) return true;
        if(!this.productReleasesMap?.get(productId)?.has(os)) return true;
        if(this.isProductV3ReleaseInfoStale(this.productReleasesMap.get(productId).get(os))) return true;
        return false;
    }
    getAllProductsByOS(os:selectableOSs):Observable<ProductV3[]>{
        if(!this.doesCatalogNeedToBeFetched( os))of(this.latestCatalogs.get(os).latestCatalog.products);
        var observable$ = new Observable<ProductV3[]>(
            observer => {
                this.FetchCatalogAndSave(os)
                .subscribe(
                    (productCatalogV3:ProductCatalogV3)=>{
                        observer.next(this.latestCatalogs.get(os).latestCatalog.products);
                        observer.complete()  
                    },
                    error=>{
                        QLogger.LogError(this.logSrc, `Unhandled error: ${error}`);
                        observer.error(error);
                        observer.complete();
                    }
                )
                
            });
        return observable$;
    }
    updateLocalCatalog(os:selectableOSs, productCatalogV3:ProductCatalogV3){
        let catalogToUpdate:CatalogByOs;
        if(this.latestCatalogs == null) this.latestCatalogs = new Map();
        if(this.latestCatalogs.has(os)){
            catalogToUpdate = this.latestCatalogs.get(os);
            catalogToUpdate.os = os;
            catalogToUpdate.latestCatalog = productCatalogV3;
            catalogToUpdate.lastUpdated = new Date();

        }
        else{
            catalogToUpdate = new CatalogByOs(os, productCatalogV3, new Date())
        }
        this.latestCatalogs.set(os, catalogToUpdate);
    }
    updateLocalProductReleases(os:selectableOSs, productId:string, releases: ReleaseV3[]){
        let releasesToOSMapToUpdate:Map<selectableOSs,ReleasesV3ByOS>
        let releasesV3ToUpdate:ReleasesV3ByOS; 
        if(this.productReleasesMap == null) this.productReleasesMap = new Map();
        if(this.productReleasesMap.has(productId)){
            let releasesToOSMap = this.productReleasesMap.get(productId);
            if(releasesToOSMap.has(os)){
                releasesV3ToUpdate = releasesToOSMap.get(os);
                releasesV3ToUpdate.releases = releases;
                releasesV3ToUpdate.os = os;
                releasesV3ToUpdate.productId = productId;
                releasesV3ToUpdate.lastUpdated = new Date();
            }
            else{
                releasesV3ToUpdate = new ReleasesV3ByOS(os, releases, productId, new Date())
            }
            releasesToOSMapToUpdate.set(os, releasesV3ToUpdate);
        }
        else{
            releasesV3ToUpdate = new ReleasesV3ByOS(os, releases, productId, new Date());
            releasesToOSMapToUpdate = new Map();
            releasesToOSMapToUpdate.set(os, releasesV3ToUpdate);
        }
        this.productReleasesMap.set(productId, releasesToOSMapToUpdate);
    }
    FetchCatalogAndSave(os:selectableOSs):Observable<ProductCatalogV3>{
        this.username = this.app.sharedData.userInfo.username;
        var observable$ = new Observable<ProductCatalogV3>(
            observer => {
                this.webClient.getCatalogV3(this.username, os)
                .subscribe(
                    (data:QPMResponse)=>{
                        if(data.isSuccess()){
                            QLogger.LogInfo(this.logSrc, "Fetched catalog successfully");
                            let obj = JSON.parse(data.getData());
                            if(obj !== undefined || obj !== null) {
                              let productCatalog:ProductCatalogV3 ;
                              productCatalog=obj;
                              this.updateLocalCatalog(os, productCatalog);
                              observer.next(productCatalog);
                              observer.complete()
                            }
                            else{
                                QLogger.LogInfo(this.logSrc, `Empty response or couldn't parse catalog response. Catlog response: ${data.getData()}`);
                                observer.error("Empty response or Couldn't parse catalog response");
                                observer.complete();
                            }
                        }
                        else{
                            QLogger.LogInfo(this.logSrc, `Catalog refresh failed. ErrorCode: ${data.getCode()}, Error: ${data.getError()}, ErrorDetails: ${data.getErrorDetail()}`);
                            observer.error(`Catalog refresh failed with Error: ${data.getError()}`);
                            observer.complete();
                        }
                    },
                    error=>{
                        QLogger.LogError(this.logSrc, `Unhandled error: ${error}`);
                        observer.error(error);
                        observer.complete();
                    }
                )
                
            });
        return observable$;

    }
    getAllReleaseVersionsByProductId(productId:string, os:selectableOSs):Observable<ReleaseV3[]>{
        if(!this.doesProductV3ReleasesNeedToBeFetched(os, productId))return of(this.productReleasesMap.get(productId).get(os).releases);
        this.username = this.app.sharedData.userInfo.username;
        var observable$ = new Observable<ReleaseV3[]>(
            observer => {
                this.webClient.getReleasesV3(productId, this.username, os, 0)
                .subscribe(
                    (data:QPMResponse)=>{
                        if(data.isSuccess()){
                            let obj = JSON.parse(data.getData());
                            if(obj !== undefined || obj !== null) {
                              let releasesV3:ReleasesV3 ;
                              releasesV3=obj;
                              this.updateLocalProductReleases(os, productId, releasesV3.releases);
                              observer.next(releasesV3.releases);
                              observer.complete()
                            }
                            else{
                                QLogger.LogInfo(this.logSrc, `Empty response or couldn't parse product releases response for ${productId}. Catlog response: ${data.getData()}`);
                                observer.error("Empty response or Couldn't parse product releases response");
                                observer.complete();
                            }
                        }
                        else{
                            QLogger.LogInfo(this.logSrc, `Fetching product releases failed. ErrorCode: ${data.getCode()}, Error: ${data.getError()}, ErrorDetails: ${data.getErrorDetail()}`);
                            observer.error(`Product releases fetch failed with Error: ${data.getError()}`);
                            observer.complete();
                        }
                    },
                    error=>{
                        QLogger.LogError(this.logSrc, `Unhandled error: ${error}`);
                        observer.error(error);
                        observer.complete();
                    }
                )
                
            });
        return observable$;
    }
  
}