import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ViewChildren, QueryList, NgZone } from '@angular/core';
import { SelectItem, MenuItem, TreeNode } from 'primeng/primeng';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { QPMResponse } from 'src/app/models/response';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { AppMainComponent } from 'src/app/app.main.component';
import { Gerrit, GerritSI, RelatedChangeRequest, ServiceTaskBuildCRCurrentInfo, ServiceTaskBuildImage, ServiceTaskBuilds, ServiceTaskInfo, SoftwareImage } from 'src/app/models/software-catalog-client';
import { CRDetails, TriggerBuildRequest, ValidateCRRequest, TriageDeleteCrRequest, TriageDeleteGerritRequest, CrGerritComment, SearchUpdateCrFixDetailRequest } from 'src/app/models/lime-web-client';
import { ChangeRequest } from 'src/app/models/software-catalog-client';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { UpdateServiceTaskRequest } from 'src/app/models/lime-web-client';
import { Utils } from 'src/app/common/utils';
import { QLogger } from 'src/app/common/logger';
import { STMainComponent } from '../main/st.main.component';
import { commType, SharedData } from 'src/app/common/shared-data';

@Component({
  selector: 'app-st.triage',
  templateUrl: './st.triage.component.html',
  styleUrls: ['./st.triage.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class STTriageComponent implements OnInit {

  private logSrc:string = "ServiceTaskTriage-Component";
  private subscriptionSrc:string = "ServiceTaskTriage-Component";

  private catalogClient: CatalogClientService;
  private softwareCatalogClient : SoftwareCatalogClientService;
  private webClient : WebClientService;
  private crSearchSubcription: Observable<any>;

  @ViewChild('triageTable') triageTable;
  @ViewChild('triageGlobalFilter') triageGlobalFilter;

  filterTriage: string;
  toggleTriageSIs: boolean;
  crDetails: CRDetails[];
  crDetail: CRDetails;
  crToAdd : string;
  crDetailRows : any[];
  crErrorMsg : string;
  validationNeeded : boolean;
  disableCrActions: boolean;

  crActionOptions: SelectItem[];
  crShortActionOptions: SelectItem[];
  bulkCrActionOptions: SelectItem[];

  crDetailColumns: any[];
  crDetailsRows: any[];

  baseImages: SoftwareImage[];
  baseImagesMap: Set<string>;
  cols: any[];

  baseImagesBranchedMap: Set<string>;
  
  validationCrCount: number;
  validationImageCount: number;
  validationStep: {
    dataType: string,
    selectedCROptions?: number,
    validateCRs?; ChangeRequest: number,
    cr?: ChangeRequest,
    imageAction?:string,
    gerrit?: Gerrit,
    build?: SoftwareImage,
    step?: {
      step: string,
      status: string,
      statusDetails: string,
      softwareImage?:string
    }
  }[];
  validationSteps: any[];
  crAction :string;
  dialog : {
    displayCRMessageBox : boolean,
    header : string,
    ok : string
  }
  
  addCrProcess: {
    displayAddCrRequest : boolean,
    displayAddCrForm : boolean,
    displaySummary: boolean,
    addCrInProgress: boolean,
    isRelatedCRs: boolean,
    crList: string,
    crLists: any[],
    crCols: any[],
    duplicateMessage: string,
    errorMessage: string,
    errorMessageResponse: string
  };

  deleteProcess: {
    displayDeleteForm: boolean,
    serviceTaskID: number,
    item: string,
    invalid: boolean,
    cr?: ChangeRequest,
    image?: SoftwareImage,
    gerrit?: Gerrit
  }

  addCommentProcess: {
    displayAddComment: boolean,
    displayViewAllComments: boolean,
    rowData: any,
    displayRejectConfirmation: boolean,
    commentToAdd: string,
    errorMessage:string
  }

  addBaseImageProcess: {
    displayForm: boolean,
    availableImages: SelectItem[],
    selectedImage: SoftwareImage,
    availableImageBuilds: SelectItem[],
    selectedImageBuild: SoftwareImage,
    addImageInProgress: boolean,
    loadingImages: boolean,
    loadingImageBuilds: boolean,
    errorMessage:string,
  }
  

  updateCRActionProcess:{

      action:any;
      crSelected:any[];
      image: GerritSI;
      isCR: boolean;
      showConfirmDialog:boolean;
      updateCRActionInProgress:boolean;
      commentToAdd:string;
      errorMessage:string;
  }
  
  searchUpdateCRProcess:{
    crPending:ChangeRequest[];
    crSelected:ChangeRequest[];
    showSelectDialog: boolean;
    showConfirmDialog:boolean;
    searchUpdateCRInProgress:boolean;
    errorMessage:string;
  }
  
  constructor(private ngZone: NgZone,private sharedData: SharedData, private router: Router, private activatedRoute: ActivatedRoute, 
              public app : AppMainComponent, private utils : Utils, private service:DataServiceProducer,
              public stMain: STMainComponent) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;

    // SelectItem API with label-value pairs
    this.crActionOptions = [
      {label:'Pending', value: 0},
      {label:'Accept', value: 1},
      {label:'Reject', value: 2},
      {label:'AlreadyFixed', value: 3}
    ];
    this.bulkCrActionOptions = [
      {label:'', value: 0},
      {label:'Accept Selected', value: 1},
      {label:'Reject Selected', value: 2},
    ];
    this.crShortActionOptions = [
      {label:'Accept', value: 1},
      {label:'Reject', value: 2},
      {label:'AlreadyFixed', value: 3}
    ];
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "ServiceTaskTriage Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);
    this.activatedRoute.params.subscribe(params => {
      this.app.sharedData.service.common.selectedServiceTaskID = params['id'];
      
      // Static UI Elements
      this.toggleTriageSIs = true;
      this.stMain.activeIndex = 0;
      this.stMain.serviceTasksDetails = new ServiceTaskInfo();
      this.baseImages = [];
      this.baseImagesMap = new Set<string>();
      this.crDetail = new CRDetails();
      this.disableCrActions = false;
      this.baseImagesBranchedMap = new Set<string>();

      this.crToAdd = "";
      this.crErrorMsg = "";
      this.validationNeeded = false;

      this.validationCrCount = 0;
      this.validationImageCount = 0;

      this.dialog = {
        displayCRMessageBox : false,
        header : '',
        ok : ''
      }

      this.addCrProcess = {
        displayAddCrRequest: false,
        displayAddCrForm: false,
        displaySummary: false,
        addCrInProgress: false,
        isRelatedCRs: false,
        crList: '',
        crLists: [],
        crCols: [
          { field: 'cr', header: 'CR #' },
          { field: 'validation', header: 'Valid' }
        ],
        duplicateMessage: '',
        errorMessage: '',
        errorMessageResponse: ''
      };

      this.deleteProcess = {
        displayDeleteForm: false,
        serviceTaskID: 0,
        item: "",
        invalid: false,
        cr: undefined,
        image: undefined,
        gerrit: undefined
      }

      this.addCommentProcess = {
        displayAddComment: false,
        displayViewAllComments: false,
        rowData: undefined,
        displayRejectConfirmation: false,
        commentToAdd: "",
        errorMessage: ""
      }

      this.cols = [
        { field: 'step', header: 'Step' },
        { field: 'crID', header: 'CR ID' },
        { field: 'details', header: '' },
        { field: 'status', header: 'Status' },
        { field: 'action', header: 'Action' }
      ];

      this.crDetailColumns = [
        { field: 'key', header: '' },
        { field: 'value', header: '' }
      ];
      this.updateCRActionProcess = {
        action:undefined,
        crSelected :[],
        image: undefined,
        isCR: false,
        showConfirmDialog:false,
        updateCRActionInProgress:false,
        commentToAdd:"",
        errorMessage: ""
      }

      this.resetSearchUpdateCRProcess();
      this.crAction ="Pending";
      this.app.sharedData.service.common.abandonInProgress = false;
      this.app.sharedData.resetVisibility();
      this.loadServiceTaskDetails(true);
      this.resetaddBaseImageProcess();
      this.resetAddRelatedCRProcess();
      this.initSubscribes();
    });
    //this.loadServiceTaskTriageDetails();
  }

  ngOnDestroy() {
    this.app.sharedData.unsubscribeCommunication(commType.CrSearch, this.subscriptionSrc);
  }

  initSubscribes(){
    this.crSearchSubcription = this.app.sharedData.subscribeCommunication(commType.CrSearch, this.subscriptionSrc);
    this.crSearchSubcription.subscribe((crs: any)=> {
      if(crs === undefined) return;
      this.showAddCrRequest();
      this.addCrProcess.crList = crs as string;
    });
  }

  onImageExpand(image: GerritSI){
    this.loadImageCrsCurrentInfo(image, false);
  }

  getRowStyle(rowData:any)
  {
      if(rowData.dataType=='IMAGESTEP')
         return {"background-color":"lightgrey"};
      else 
        return {"background-color":"white"};
  }
  loadServiceTaskDetails(loadTriageInfo: boolean) {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details");

    if(this.softwareCatalogClient === null || this.app.sharedData.service.common.selectedServiceTaskID === undefined){
      return;
    }
    
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetails(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Response : " +JSON.stringify(data));
        } 
        if(data.getCode() === 401){
          this.router.navigate(['/main/accessdenied']);
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            this.stMain.serviceTasksDetails = obj as ServiceTaskInfo;
            this.stMain.loadAdditionalInfo();
            if(this.stMain.serviceTasksDetails.createdUsingSI){
              this.getBaseImages(this.stMain.serviceTasksDetails.serviceTaskId.toString());
            }
            else if(loadTriageInfo){
              this.getAllComments();
              this.loadServiceTaskTriageDetails();
              this.loadServiceTaskBuilds();
            }

            //Add CR through CR Search
            if(this.app.sharedData.crSearch.foundCrsToAdd.length > 0){
              this.showAddCrRequest();
              this.addCrProcess.crList = this.app.sharedData.crSearch.foundCrsToAdd.join(", ");
              this.app.sharedData.crSearch.foundCrsToAdd = [];
            }
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
    });
  }
  
  loadServiceTaskBuilds() {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details");
    let serviceTaskBuilds = new ServiceTaskBuilds();
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskBuilds(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
    (data:QPMResponse) => { 
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Response : " +JSON.stringify(data));
      }
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Success");
        
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          serviceTaskBuilds = obj as ServiceTaskBuilds;
          serviceTaskBuilds.softwareImages.forEach(image =>{
            this.baseImagesBranchedMap.add(image.baseImage.toLowerCase());
          })
          this.loadServiceTaskTriageDetails();
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

      }
    });
  }

  loadServiceTaskTriageDetails() {
    this.validationSteps = [];
    if(this.baseImages.length > 0){
      this.validationSteps.push({dataType: 'STEP', step: {step: 'Base build validation', status: 'In Progress', statusDetails: ''}});
    }
    else{
      this.validationSteps.push({dataType: 'STEP', step: {step: 'Base build validation', status: 'N/A', statusDetails: ''}});
    }

    if(this.baseImages.length > 0){
      for (var i = 0; i < this.baseImages.length; i++) {
        this.cirrusTaskStatusBaseImages(this.baseImages[i]);
        this.validationSteps.push({data:{dataType: 'IMAGE', build: this.baseImages[i]}});
      }
    }

    this.validationNeeded = false;
    let crIDs = this.stMain.serviceTasksDetails.changeRequests;
    this.validationSteps.push({dataType: 'VALIDATECR', validateCRs: crIDs, step: {step: 'CR Validation'}});
    for (var i = 0, len = crIDs.length; i < len; i++) {
      crIDs[i].details = crIDs[i].details === undefined? "": crIDs[i].details;
      crIDs[i].status = crIDs[i].valid == true ? "Valid" : "Invalid";
      crIDs[i].statusDetails = crIDs[i].valid == true ? "" : crIDs[i].validationMsg;
      crIDs[i].validationMsg = crIDs[i].statusDetails;
    }

    let images = this.stMain.serviceTasksDetails.changes;
    let crWithImage : Set<Number> = new Set();
    for (var i = 0, lenGerritSIs = images.length; i < lenGerritSIs; i++) {
      let image = images[i]  as GerritSI;
      if(image.gerrits.length <= 0 && image.changeRequests.length <= 0 ){
        continue;
      }
      
      //Process CRs
      image.isBranched = false;
      if(this.baseImagesBranchedMap.has(image.softwareImage.toLowerCase())){
        image.isBranched = true;
      }
      image.isAcceptPending = false;
      let CRs = image.changeRequests;
      for (var j = 0, len = CRs.length; j < len; j++) {
        let actionIndex = this.crActionOptions.find(opt => opt.label == CRs[j].action);
        CRs[j].details =  CRs[j].details === undefined? "":  CRs[j].details;
        CRs[j].status = CRs[j].valid == true ? "Valid" : "Invalid";
        CRs[j].statusDetails = CRs[j].valid == true ? "" : CRs[j].validationMsg;
        CRs[j].validationMsg = CRs[j].statusDetails;
        CRs[j].softwareImage = image.softwareImage;
        CRs[j].softwareImageBuild = image.softwareImageBuild;
        CRs[j].selectedOptions = actionIndex.value;
        crWithImage.add(CRs[j].changeRequest);
      }
      if(!image.isBranched){
        image.isAcceptPending = CRs.filter(x => x.acceptStatus === 1).length === 1;
      }
      
      //Process Gerrits
      let gerrits = image.gerrits;
      for(var j = 0, lenGerrits = gerrits.length; j < lenGerrits; j++){
        gerrits[j].valid = true;//mock up
        let status = "In Progress";
        let statusDetails = "";
        if (gerrits[j].valid === undefined || gerrits[j].valid === null) {
          this.validationNeeded = true;
        }
        else {
          status = gerrits[j].valid == true ? "Valid" : "Invalid";
          statusDetails = gerrits[j].valid == true ? "" : gerrits[j].validationMsg;
          gerrits[j].validationMsg = statusDetails;
        }
        let actionIndex = this.crActionOptions.find(opt => opt.label == gerrits[j].action);
        gerrits[j].status = status;
        gerrits[j].statusDetails = statusDetails;
        gerrits[j].softwareImage = image.softwareImage;
        gerrits[j].softwareImageBuild = image.softwareImageBuild;
        gerrits[j].selectedOptions = actionIndex.value;
      }      
    }

    for (var i = 0, len = crIDs.length; i < len; i++) {
      if(crWithImage.has(crIDs[i].changeRequest)){
        crIDs[i].hasSoftwareImage = true;
      }
      else{
        crIDs[i].hasSoftwareImage = false;
      }
    }

    if (this.validationNeeded) {
      this.validateGerrit();
    }
  }

  loadRelatedCRs(cr: number) {
    QLogger.LogInfo(this.logSrc, "Get CR " + cr + " Dependent CRs");

    if(this.webClient === null){
      return;
    }
    
    let response : Observable<QPMResponse>;
    this.disableCrActions = true;
    this.addRelatedCRProcess.loadInProgress++;
    response = this.webClient.getChangeRequestRelated(cr);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get CR " + cr + " Dependent CRs - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get CR " + cr + " Dependent CRs - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            let relatedCRs: RelatedChangeRequest[] = obj.relatedChangeRequests;
            relatedCRs.forEach(c => {
              if(!this.addRelatedCRProcess.relatedCRsSet.has(c.id)){
                c.currentCR = cr;
                this.addRelatedCRProcess.relatedCRs.push(c);
                this.addRelatedCRProcess.relatedCRsSet.add(c.id);
              }
            });   
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get CR " + cr + " Dependent CRs - Failed");
          QLogger.LogError(this.logSrc, "Get CR " + cr + " Dependent CRs - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        this.addRelatedCRProcess.loadInProgress--;
        this.disableCrActions = false;
        if(this.addRelatedCRProcess.loadInProgress === 0){
          this.selectRegressionCRs();
        }
    });
  }

  validateCRs(revalidateAll: boolean) {
    let allCRs = this.stMain.serviceTasksDetails.changeRequests;
    this.stMain.serviceTasksDetails.changeRequests.forEach((cr) => {
      if(revalidateAll || cr.valid !== true) {
        // Skip valid CRs
        this.validateCR(cr);
      }
    });
  }
  
  validateCR( crDetails: ChangeRequest ) {
    let request: ValidateCRRequest;
    request = {
      baseBuild: this.stMain.serviceTasksDetails.baseBuild,
      changeRequest: [],
      serviceTaskId: this.stMain.serviceTasksDetails.serviceTaskId,
      createdUsingSI: this.stMain.serviceTasksDetails.createdUsingSI
    };
    request.changeRequest.push(crDetails.changeRequest);
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Validate CR Request : " +JSON.stringify(request));
    }

    let validateResponseData;
    let validateResponse : Observable<QPMResponse>;
    crDetails.status = "In Progress";
    crDetails.validationMsg = "";   
    crDetails.validationInProgress = true;
    this.validationCrCount++;
    validateResponse = this.webClient.validateCR(request);
    validateResponse.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Validate CR Response - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Validate CR Response - Success"); 
          validateResponseData = JSON.parse(data.getData()).changeRequestValidationStatusList[0];
          crDetails.valid = validateResponseData.valid;
          crDetails.validationMsg = validateResponseData.valid === true ? "" : validateResponseData.message;
          crDetails.status = validateResponseData.valid === true ? "Valid" : "Invalid";
          crDetails.details = validateResponseData.details;
        }
        else {
          QLogger.LogError(this.logSrc, "Validate CR "+crDetails.changeRequest+" - Failed");
          QLogger.LogError(this.logSrc, "Validate CR "+crDetails.changeRequest+" - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          crDetails.valid = false;
          crDetails.validationMsg = data.getError();
          crDetails.status = "Invalid";
        }
        this.validationCrCount--;
        if(this.validationCrCount === 0){
          this.loadServiceTaskDetails(true);
        }
        crDetails.validationInProgress = false;
      });
  }

  loadImageCrsCurrentInfo(image: GerritSI, refresh: boolean){
    image.loadingCrCurrentInfo = 0;
    image.changeRequests.forEach((cr)=>{
      this.loadCrCurrentInfo(image, cr, refresh);
    })
    //call for image-build error-message
    this.loadServiceTaskBuildsForErrorDetails(image);
  }

  loadCrCurrentInfo(image: GerritSI, cr: ChangeRequest, refresh: boolean){
    if(cr.currentInfo !== undefined && !refresh) return;
    QLogger.LogInfo(this.logSrc, "Get Service Task Build CR "+ crID +" Current Info");
    var crID = cr.changeRequest;
    let response : Observable<QPMResponse>;
    if(this.webClient === null){
      return
    }
    image.loadingCrCurrentInfo++;
    response = this.softwareCatalogClient.getServiceTaskBuildCRStatus(crID.toString(), this.stMain.serviceTasksDetails.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task Build CR "+ crID +" Current Info - Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task Build CR "+ crID +" Current Info - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            cr.currentInfo = obj as ServiceTaskBuildCRCurrentInfo;
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task Build CR "+ crID +" Current Info - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task Build CR "+ crID +" Current Info - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        image.loadingCrCurrentInfo--;
      }
    );
  }

  getAllComments() {
    this.stMain.serviceTasksDetails?.changes?.forEach((image) => {
      image.changeRequests.forEach((cr)=>{
        let request: CrGerritComment = {
          crId: cr.changeRequest,
          gerritId: undefined,
          softwareImage: image.softwareImage
        }
        this.stMain.getComments(request, cr);
      });
      image.gerrits.forEach((gerrit)=>{
        let request: CrGerritComment = {
          crId: 0,
          gerritId: gerrit.gerritId,
          softwareImage: image.softwareImage
        }
        this.stMain.getComments(request, gerrit);
      });
    });
  }

  getSICrGerritComments(image: GerritSI) {
    image.changeRequests.forEach((cr)=>{
        let request: CrGerritComment = {
          crId: cr.changeRequest,
          gerritId: undefined,
          softwareImage: image.softwareImage
        }
        this.stMain.getComments(request, cr);
    });
    image.gerrits.forEach((gerrit)=>{
        let request: CrGerritComment = {
          crId: 0,
          gerritId: gerrit.gerritId,
          softwareImage: image.softwareImage
        }
        this.stMain.getComments(request, gerrit);
    });
  }

  showCRDetails(crID : string) {
    this.crDetailRows = [];
    let response : Observable<QPMResponse>;
    if(this.webClient === null){
      return
    }
    QLogger.LogInfo(this.logSrc, "Get Change Request Details "+crID+" Details");
    response = this.webClient.getChangeRequestDetails(Number(crID));
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Change Request Details Response - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Change Request Details Response - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.crDetail = obj;

            // CR Details visible to all users
            this.crDetailRows.push({name: 'CR Number', value: this.crDetail.changeRequestNumber});
            this.crDetailRows.push({name: 'Title', value: this.crDetail.title});
            this.crDetailRows.push({name: 'Status', value: this.crDetail.status});

            if (this.app.sharedData.visibility.serviceTaskTriage.fullCRDetails) {
              this.crDetailRows.push({name: 'Type', value: this.crDetail.type});
              this.crDetailRows.push({name: 'Severity', value: this.crDetail.severity});
              this.crDetailRows.push({name: 'Assignee', value: this.crDetail.assigneeUid});

              this.crDetail.participants.forEach( participant => {
                this.crDetailRows.push({name: '', value: ''}); 
                this.crDetailRows.push({name: 'Area', value: participant.area});
                this.crDetailRows.push({name: 'Subsystem', value: participant.subsystem});
                this.crDetailRows.push({name: 'Functionality', value: participant.functionality});   
                this.crDetailRows.push({name: 'Assignee', value: participant.assigneeUid});             
              });
            }
            this.showCRMessageBox( "Change Request Details", 
                                  "Ok");
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Change Request "+crID+" Details - Failed");
          QLogger.LogError(this.logSrc, "Get Change Request "+crID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      }
      
    );
  }
  
  showAddCrRequest(){
    this.addCrProcess.displayAddCrRequest = true;
    this.addCrProcess.displayAddCrForm = true;
    this.addCrProcess.displaySummary = false;
    this.addCrProcess.addCrInProgress = false;
    this.addCrProcess.isRelatedCRs = false;
    this.addCrProcess.crList = "";
    this.addCrProcess.crLists = [];
    this.addCrProcess.duplicateMessage = "";
    this.addCrProcess.errorMessage = "";
    this.addCrProcess.errorMessageResponse = "";
  }

  hideAddCrRequest(){
    this.addCrProcess.displayAddCrRequest = false;
    this.addCrProcess.displayAddCrForm = false;
    this.addCrProcess.displaySummary = false;
    this.addCrProcess.addCrInProgress = false;
    this.addCrProcess.isRelatedCRs = false;
    this.addCrProcess.crList = "";
    this.addCrProcess.crLists = [];
    this.addCrProcess.duplicateMessage = "";
    this.addCrProcess.errorMessage = "";
    this.addCrProcess.errorMessageResponse = "";
  }

  addCrRequestNext() {
    if(this.addCrRequestValidation()){    
      this.addCrProcess.crLists = [];
      let crs = this.extractCRsList();
      if(crs !== null){
        crs.forEach((cr) => {
          this.addCrProcess.crLists.push({
            cr: cr,
            valid:"Yes"
          });
        });
      }
      this.addCrProcess.displaySummary = true;
      this.addCrProcess.displayAddCrForm = false;
    }
  }
  addCrRequestBack() {
    this.addCrProcess.displaySummary = false;
    this.addCrProcess.errorMessageResponse = "";
    this.addCrProcess.displayAddCrForm = true;
  }

  addCrRequestValidation():boolean{
    this.addCrProcess.errorMessage = "";
    this.addCrProcess.duplicateMessage = "";
    let isCrEmpty:boolean;
    let crs = this.extractCRsList();
    if(crs === null){
      if(this.addCrProcess.errorMessage === ""){
        this.addCrProcess.errorMessage = "Invalid CR(s) values. Please enter correct CR(s)";
      }
      return false;
    }
    let crListLen = crs.length + this.stMain.serviceTasksDetails?.changeRequests?.length;
    if(crListLen>1000)
    this.addCrProcess.errorMessage = "Number of CR(s) exceed maximum permissible limit of 1000 . Please reduce number of CR(s)";
    if(this.addCrProcess.errorMessage !== ""){
      return false;
    }
    return true;
  }

  extractCRsList(): string[]{
    if(this.addCrProcess.crList === undefined || this.addCrProcess.crList === ""){
      return null;
    }
    let crList = this.addCrProcess.crList.match(/\d+/g);
    let crListImageMap : Map<string, string[]> = new Map<string, string[]>();
    let crListUnique : Set<string>;
    crListUnique = new Set<string>(crList);
    this.addCrProcess.duplicateMessage = "";
    this.stMain.serviceTasksDetails.changes.forEach((image)=>{
      image.changeRequests.forEach((cr)=>{
        if(crListImageMap.has(cr.changeRequest.toString())){
          crListImageMap.get(cr.changeRequest.toString()).push(image.softwareImage);
        }
        else{
          crListImageMap.set(cr.changeRequest.toString(), [image.softwareImage]);
        }
      });
    });
    this.stMain.serviceTasksDetails.changeRequests.forEach((cr)=>{
      if(!cr.valid && crListUnique.has(cr.changeRequest.toString()) && !crListImageMap.has(cr.changeRequest.toString())){
        this.addCrProcess.duplicateMessage += cr.changeRequest + "\n";
      }
    });
    crListUnique.forEach((cr)=>{
      if(crListImageMap.has(cr)){
        this.addCrProcess.duplicateMessage += cr + " : " + crListImageMap.get(cr).join(", ") + "\n";
      }
    });
    return crListUnique.size > 0 ? Array.from(crListUnique.values()) : null;
  }

  addCR(){
    this.addCrProcess.errorMessageResponse = "";

    if(this.addCrRequestValidation()){   
      this.addCrProcess.addCrInProgress = true; 
      this.addCrProcess.errorMessageResponse = "Adding CR(s). Please wait..";
      let request: UpdateServiceTaskRequest;

      let tempServiceTaskCRs = [];
      let crs = this.extractCRsList();
      if(crs === null)
      {
        return;
      }
      crs.forEach((cr) => {
        tempServiceTaskCRs.push({changeRequest: Number(cr), action:'Pending'});
      });
      
      request = {
        abandoned: false,
        baseBuild: this.stMain.serviceTasksDetails.baseBuild,
        changeRequests: tempServiceTaskCRs,
        // Only pass details that are required or changed
        //companies: this.stMain.serviceTasksDetails.companies,
        //customerUserId: this.stMain.serviceTasksDetails.customerUserId,
        //requestedDate: this.stMain.serviceTasksDetails.requestedDate,
        //requester: this.stMain.serviceTasksDetails.requester,
        serviceTaskId: this.stMain.serviceTasksDetails.serviceTaskId,
        requestAppId: this.stMain.serviceTasksDetails.requestAppId,
        requestSource: this.stMain.serviceTasksDetails.requestSource
      };

      let response: Observable<QPMResponse>;
      if(this.app.sharedData.appInfo.logRequest){
        QLogger.LogInfo(this.logSrc, "Update Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " request : " + JSON.stringify(request));
      }
      response = this.webClient.updateServiceTask(this.stMain.serviceTasksDetails.serviceTaskId.toString(), request);
      response.subscribe(
        (data: QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Update Service Task Response - Response : " +JSON.stringify(data));
          }
          this.addCrProcess.addCrInProgress = false;
          this.addCrProcess.errorMessageResponse = "";
          if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc, "Update Service Task Response - Success");
            this.stMain.serviceTasksDetails = JSON.parse(data.getData());
            this.hideAddCrRequest();
            //For refresh after CR Adding
            this.loadServiceTaskDetails(true);
          }
          else {
            QLogger.LogError(this.logSrc, "Update Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " - Failed");
            QLogger.LogError(this.logSrc, "Update Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
            
            if (data.getError() === undefined || data.getError() === null) {
              QLogger.LogInfo(this.logSrc, "Update Service Task Response - Timeout : " + data.getCode());
              this.addCrProcess.errorMessageResponse = "Request has been sent to add " + this.crToAdd;
              this.crToAdd = "";
              this.getAllComments();
              this.loadServiceTaskTriageDetails();
            }
            else {
              this.addCrProcess.errorMessageResponse = "Failed to add CR(s) " + this.crToAdd + " "+ data.getError();
            }
          }
        });
        //this.hideAddCRMessageBox();
    }
  }

  showCRMessageBox(header, ok){
    this.dialog.displayCRMessageBox = true;
    this.dialog.header = header;
    this.dialog.ok = ok;
  }

  hideCRMessageBox(){
    this.dialog.displayCRMessageBox = false;
    this.dialog.header = '';
    this.dialog.ok = '';
  }
  commentValidation()
  {
    let commentLen = this.updateCRActionProcess.commentToAdd.length;
    if(commentLen>255)
    {
     this.updateCRActionProcess.errorMessage = "Comment cannot be more than 255 characters long";
    }
    else
    this.updateCRActionProcess.errorMessage = "";
  }

  onBulkImageCheckboxToggle(event, table:any, image: GerritSI){
    let first = table.first;
    let last = first + table.rows;
    let values = (table.filteredValue !== null && table.filteredValue !== undefined) ? table.filteredValue : table.value;
    while(first < last && first < table.totalRecords){
      if(image.isCrsSelected){
        if(image.selectedCRsMap === undefined) image.selectedCRsMap = new Map();
        if(event && values[first].valid){
          values[first].isSelected = true;
          image.selectedCRsMap.set(values[first].changeRequest, values[first]);
        }
        else{
          values[first].isSelected = false;
          image.selectedCRsMap.delete(values[first].changeRequest);
        }
      }
      else{        
        if(image.selectedGerritsMap === undefined) image.selectedGerritsMap = new Map();
        if(event && values[first].valid && values[first].action !== 'Accept'){
          values[first].isSelected = true;
          image.selectedGerritsMap.set(values[first].gerritId, values[first]);
        }
        else{
          values[first].isSelected = false;
          image.selectedGerritsMap.delete(values[first].gerritId);
        }
      }
      first++;
    }
  }
  onImageCheckboxToggle(event, rowData: any, image: GerritSI){
    if(image.isCrsSelected){
      if(image.selectedCRsMap === undefined) image.selectedCRsMap = new Map();
      event? image.selectedCRsMap.set(rowData.changeRequest, rowData) : image.selectedCRsMap.delete(rowData.changeRequest);
    }
    else{
      if(image.selectedGerritsMap === undefined) image.selectedGerritsMap = new Map();
      event? image.selectedGerritsMap.set(rowData.gerritId, rowData) : image.selectedGerritsMap.delete(rowData.gerritId);
    }
  }
  onTablePageChange(table:any, image: GerritSI){
    let first = table.first;
    let last = first + table.rows;
    let values = (table.filteredValue !== null && table.filteredValue !== undefined) ? table.filteredValue : table.value;
    if(image.isCrsSelected){
      image.isAllCrSelected = false;
    }
    else{
      image.isAllGerritSelected = false;
    }
    while(first < last && first < table.totalRecords){      
      if(values[first].isSelected){
        if(image.isCrsSelected){
          image.isAllCrSelected = true;
        }
        else{
          image.isAllGerritSelected = true;
        }
        return;
      }
      first++;
    }
  }
  onBulkCrActionChange(event, image: GerritSI){
  
    if((image.selectedCROptions === 0 || image.selectedCROptions === undefined) 
        && (image.selectedGerritOptions === 0 || image.selectedGerritOptions === undefined)) return;
    let option: number;
    if(image.isCrUpdateAction){
      option = image.selectedCROptions;
      image.selectedCROptions = undefined;
    }
    else{
      option = image.selectedGerritOptions;
      image.selectedGerritOptions = undefined;
    }
    image.imageAction = this.crActionOptions[option].label;
    this.updateCRActionProcess.image = image;
    this.updateCRActionProcess.isCR = image.isCrUpdateAction;
    this.updateCRActionProcess.updateCRActionInProgress = true;
    this.updateCRActionProcess.action = option;
    this.updateCRActionProcess.crSelected =[];
    this.crAction =this.crActionOptions[option].label;
    if(this.updateCRActionProcess.isCR){
      this.updateCRActionProcess.crSelected.push(...image.selectedCRsMap.values()); 
    }
    else{
      this.updateCRActionProcess.crSelected.push(...image.selectedGerritsMap.values());       
    }
      
    if(this.updateCRActionProcess.crSelected?.length==0){
      image.selectedCROptions = undefined;
      image.selectedGerritOptions = undefined;
    }
    if(this.updateCRActionProcess.crSelected?.length>0){
      this.updateCRActionProcess.showConfirmDialog = true;
    }
    else{
      this.app.showMessageBox("Update CR(s) Status","No CR(s) and Gerrit(s) selected.","OK");
    }
  }
  onSelectedCRActionChange()
  {
    let action = this.crActionOptions[this.updateCRActionProcess.action].label;
    let currentCRs: number[] = [];
    this.updateCRActionProcess.showConfirmDialog=false;
    this.stMain.updateCRList =[];
    this.stMain.updateGerritList =[];
    this.resetAddRelatedCRProcess();
    this.updateCRActionProcess.crSelected.forEach(row=>{
        if(this.updateCRActionProcess.isCR && row.action !=action)
        {
            row.actionPrevious = row.action;
            row.actionInProgress = true;
            row.action  =  action;
            row.selectedOptions =  this.updateCRActionProcess.action;
            if(action === "Accept"){
               QLogger.LogInfo(this.logSrc,"Loading related CR for "+row.changeRequest);
               currentCRs.push(row.changeRequest);
            }
            else if (action === "Reject"){
              let request: CrGerritComment = new CrGerritComment();
              let cRIdString = (row.changeRequest as Number).toString();
              request.crId = parseInt(cRIdString);
              request.softwareImage = row.softwareImage;
              request.commentDesc = this.updateCRActionProcess.commentToAdd;
              this.stMain.addComment(request,row);
            }
            this.stMain.updateCRList.push(row);
        }
        else if(!this.updateCRActionProcess.isCR && row.action!='Accept' && row.action !=action)
        {
          row.actionPrevious = row.action;
          row.actionInProgress = true;
          row.action  =  action;
          row.selectedOptions =  this.updateCRActionProcess.action;
          this.stMain.updateGerritList.push(row);
        } 
    });
    if(this.stMain.updateCRList.length>0){
      this.stMain.TriggerBuild(this.stMain.updateCRList.shift());
    }
    if(this.updateCRActionProcess.isCR && currentCRs.length > 0){
      this.showAddRelatedCRs(currentCRs, this.updateCRActionProcess.image);
      //this.loadRelatedCRs(this.addRelatedCRProcess.currentCRs.shift());
    }
    if(this.stMain.updateGerritList.length>0){
      this.stMain.TriggerBuildWithGerrit(this.stMain.updateGerritList.shift());
    }
    this.cleanCRActions();
  }
  onCancelButtonClick()
  {
    this.updateCRActionProcess.crSelected.forEach(row=>{
    
      if(this.updateCRActionProcess.isCR && row.action!='Accept')
      {
          row.action  =  row.actionPrevious;
          row.selectedOptions =  this.updateCRActionProcess.action;
          row.isSelected = false;
      }
      else if(!this.updateCRActionProcess.isCR && row.action!='Accept')
      {
        row.action  =  row.actionPrevious;
      } 
    });
    this.cleanCRActions();
  }
  cleanCRActions()
  {   
    this.updateCRActionProcess.action='Pending';
    this.updateCRActionProcess.isCR=false;
    this.updateCRActionProcess.commentToAdd=undefined;
    this.updateCRActionProcess.crSelected=[];
    this.updateCRActionProcess.updateCRActionInProgress=false;
    this.updateCRActionProcess.showConfirmDialog=false;

  }
  onCRActionChange(event, rowData: any, image: GerritSI) {
    // Find corrasponding CR in model
    if(rowData.action === this.crActionOptions[rowData.selectedOptions].label) return;
    rowData.actionPrevious = rowData.action;
    this.stMain.updateCRList =[];
    let action = this.crActionOptions[rowData.selectedOptions].label;
    rowData.actionInProgress = true;
    rowData.action  =  action;
    rowData.isSelected = false;
    // If Accept load dependent CRs
    if(action === "Accept"){
      this.resetAddRelatedCRProcess();
      this.showAddRelatedCRs([rowData.changeRequest], image);
    }
    else if (action === "Reject"){
      this.stMain.addCommentProcess.rowData = rowData;
      this.stMain.addCommentProcess.id = (rowData.changeRequest as Number).toString();
      this.stMain.addCommentProcess.isGerrit = false;
      this.stMain.addCommentProcess.cr = rowData;
      this.stMain.addCommentProcess.softwareImage = rowData.softwareImage;
      this.stMain.addCommentProcess.displayAddComment = true;
      this.stMain.addCommentProcess.displayRejectConfirmation = true;
    }
    
    if(action === "Accept" || action === "AlreadyFixed"){
      this.stMain.updateCRList.push(rowData);
      this.stMain.TriggerBuild(this.stMain.updateCRList.shift());
      if(!image.isBranched){
        image.isAcceptPending = true;
      }
    }
    //call for image-build error-message
    this.loadServiceTaskBuildsForErrorDetails(image);
    
  }

  loadServiceTaskBuildsForErrorDetails(gerritSI: GerritSI) {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" For Build Error Details");
    let serviceTaskBuilds = new ServiceTaskBuilds();
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskBuilds(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
    (data:QPMResponse) => { 
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" For Build Error Details - Response : " +JSON.stringify(data));
      }
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" For Build Error Details - Success");
        
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          serviceTaskBuilds = obj as ServiceTaskBuilds;
          //extract that single image
          let filteredSI:ServiceTaskBuildImage[]=[];
          filteredSI=serviceTaskBuilds.softwareImages?.filter(image =>image.baseImage===gerritSI.softwareImage)
          if(filteredSI?.length>0){
            gerritSI.errorMessage=filteredSI[0]?.errorMessage
          }else{
            gerritSI.errorMessage="";
          }
          this.loadServiceTaskTriageDetails();
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" For Build Error Details - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" For Build Error Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

      }
    });
  }
  triggerBuild(rowData)
  {
    this.stMain.updateCRList =[];
    this.stMain.updateCRList.push(rowData);
    this.stMain.TriggerBuild(this.stMain.updateCRList.shift());
  }
  onCRActionDelete(crDetail: ChangeRequest, invalid: boolean){
    this.deleteProcess.item = "CR";
    this.deleteProcess.cr = crDetail;
    this.deleteProcess.invalid = invalid;
    this.deleteProcess.image = undefined;
    this.deleteProcess.gerrit = undefined;
    this.deleteProcess.displayDeleteForm = true;
  }

  deleteCR(crDetail: ChangeRequest){
    this.deleteProcess.displayDeleteForm = false;
    this.deleteProcess.cr = undefined;

    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    crDetail.deleteInProgress = true;

    let request : TriageDeleteCrRequest;
    request = {crId: crDetail.changeRequest,
               softwareImage: crDetail.softwareImage};
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , CR: " +  crDetail.changeRequest + ", Image: " + crDetail.softwareImage);
    }
    response = this.webClient.deleteServiceTaskCR(this.stMain.serviceTasksDetails.serviceTaskId.toString(), request);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , CR: " +  crDetail.changeRequest + ", Image: " + crDetail.softwareImage + " - Success Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , CR: " +  crDetail.changeRequest + ", Image: " + crDetail.softwareImage + " - Success");
          
          let index: number = -1;
          let i: number = -1;
          if(this.deleteProcess.invalid){
            this.stMain.serviceTasksDetails.changeRequests.forEach((cr)=>{
              i++;
              if(cr.changeRequest === crDetail.changeRequest){
                index = i;
              }
            });
            if(index !== -1){
              this.stMain.serviceTasksDetails.changeRequests.splice(index, 1);
            }
          }
          else{            
            let deleteImageIndex: number = -1;
            this.stMain.serviceTasksDetails.changes.forEach((image, imageIndex)=>{
              if(image.softwareImage === crDetail.softwareImage){
                let index: number = -1;
                for(var i = 0; i < image.changeRequests.length; i++){
                  if(image.changeRequests[i].changeRequest === crDetail.changeRequest){
                    index = i;
                  }
                }
                if(index != -1){
                  image.changeRequests.splice(index, 1);
                }
                if(image.changeRequests?.length === 0){
                  deleteImageIndex = imageIndex;
                }
              }
            });
            
            if(deleteImageIndex != -1){
              this.stMain.serviceTasksDetails.changes.splice(deleteImageIndex, 1);
            }

            this.loadServiceTaskTriageDetails();
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , CR: " +  crDetail.changeRequest + ", Image: " + crDetail.softwareImage + " - Failed");
          QLogger.LogError(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , CR: " +  crDetail.changeRequest + ", Image: " + crDetail.softwareImage + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.app.showMessageBox("Delete CR Status","Failed to delete the CR "+crDetail.changeRequest,"OK");
        }
        crDetail.deleteInProgress = false;
      }
    );
  }

  updateSICrGerrits(image: GerritSI) {
    let serviceTaskID = this.app.sharedData.service.common.selectedServiceTaskID;
    let imageName = image.softwareImage;
    QLogger.LogInfo(this.logSrc, "Update Service Task "+ serviceTaskID + ", Image: "+ imageName + " CR/Gerrits Details");
    
    image.loadingDetails = true;
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetails(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Update Service Task "+ serviceTaskID + ", Image: "+ imageName + " CR/Gerrits Details - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Update Service Task "+ serviceTaskID + ", Image: "+ imageName + " CR/Gerrits Details - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            let serviceTasks = obj as ServiceTaskInfo;
            serviceTasks.changes.forEach((imageNew)=>{
              if(imageNew.softwareImage === image.softwareImage){
                image.changeRequests = imageNew.changeRequests;
                image.gerrits = imageNew.gerrits;
                image.selectedCROptions = undefined;
                image.selectedGerritOptions = undefined;
                image.selectedCRsMap = new Map();
                image.selectedGerritsMap = new Map();
                image.isAllCrSelected = false;
                image.isAllGerritSelected = false;
                image.isCrsSelected = false;
                image.isCrUpdateAction = false;
                image.isAllGerritSelected = false;
                this.loadServiceTaskTriageDetails();
                this.getSICrGerritComments(image);
                this.loadImageCrsCurrentInfo(image, true);
                //call for image-build error-message
                this.loadServiceTaskBuildsForErrorDetails(image);
              }
            });
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Update Service Task "+ serviceTaskID + ", Image: "+ imageName + " CR/Gerrits Details - Failed");
          QLogger.LogError(this.logSrc, "Update Service Task "+ serviceTaskID + ", Image: "+ imageName + " CR/Gerrits Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        image.loadingDetails = false;
    });
  }

  //#region AddRelatedCrs
  addRelatedCRProcess:{
    displayForm: boolean;
    currentCRs: number[];
    relatedCRs: RelatedChangeRequest[];
    relatedCRsSet: Set<number>;
    relatedCRsSelected: RelatedChangeRequest[];
    loadInProgress: number;
    inProgress: boolean;
    errorMessage:string;    
  }
  resetAddRelatedCRProcess(){
    this.addRelatedCRProcess = {
      displayForm: false,
      currentCRs: [],
      relatedCRs: [],
      relatedCRsSet: new Set(),
      relatedCRsSelected: [],
      loadInProgress: 0,
      inProgress: false,
      errorMessage: ""
    }
  }
  showAddRelatedCRs(currentCRs: number[], image: GerritSI){
    this.resetAddRelatedCRProcess();
    this.addRelatedCRProcess.relatedCRsSet = new Set();
    image.changeRequests.forEach(c => {this.addRelatedCRProcess.relatedCRsSet.add(c.changeRequest)});
    this.addRelatedCRProcess.currentCRs = currentCRs;
    this.addRelatedCRProcess.currentCRs.forEach((cr)=>{
      this.loadRelatedCRs(cr);
    });
  }
  selectRegressionCRs(){
    if(this.addRelatedCRProcess.relatedCRs?.length > 0){
      this.addRelatedCRProcess.relatedCRsSelected.push(...this.addRelatedCRProcess.relatedCRs);
    }
    if(this.addRelatedCRProcess.relatedCRs?.length > 0){
      this.addRelatedCRProcess.displayForm = true;
    }
  }
  addRelatedCRs(){
    let request: UpdateServiceTaskRequest;
    let tempServiceTaskCRs = [];
    this.addRelatedCRProcess.relatedCRsSelected.forEach((cr) => {
      tempServiceTaskCRs.push({changeRequest: cr.id, action:'Pending'});
    });
    
    request = {
      abandoned: false,
      baseBuild: this.stMain.serviceTasksDetails.baseBuild,
      changeRequests: tempServiceTaskCRs,
      serviceTaskId: this.stMain.serviceTasksDetails.serviceTaskId,
      requestAppId: this.stMain.serviceTasksDetails.requestAppId,
      requestSource: this.stMain.serviceTasksDetails.requestSource
    };

    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Add related CRs " + this.stMain.serviceTasksDetails.serviceTaskId + " request : " + JSON.stringify(request));
    }
    this.addRelatedCRProcess.inProgress = true;
    response = this.webClient.updateServiceTask(this.stMain.serviceTasksDetails.serviceTaskId.toString(), request);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Add related CRs - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Add related CRs - Success");
          this.stMain.serviceTasksDetails = JSON.parse(data.getData());
          this.loadServiceTaskDetails(true);
          this.resetAddRelatedCRProcess();
        }
        else {
          QLogger.LogError(this.logSrc, "Add related CRs " + this.stMain.serviceTasksDetails.serviceTaskId + " - Failed");
          QLogger.LogError(this.logSrc, "Add related CRs " + this.stMain.serviceTasksDetails.serviceTaskId + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          this.addRelatedCRProcess.errorMessage = "Failed to add CR(s). " + data.getError();
        }
        this.addRelatedCRProcess.inProgress = false;
      });
  }
  //#endregion

  //#region Gerrits
  subscriberObservable: Observable<ServiceTaskInfo>;
  //#region Events

  onGerritActionChange(event, rowData: any, image: GerritSI) {
    // Find corrasponding CR in model
    if(rowData.action === this.crActionOptions[rowData.selectedOptions].label) return;
    rowData.actionPrevious = rowData.action;
    let action = this.crActionOptions[rowData.selectedOptions].label;
    rowData.actionInProgress = true;
    rowData.action  =  action;
    rowData.isSelected = false;
    this.stMain.updateGerritList =[];
    this.stMain.updateGerritList.push(rowData);
    this.stMain.TriggerBuildWithGerrit(this.stMain.updateGerritList.shift());
    if(!image.isBranched){
      image.isAcceptPending;
    }
    //call for image-build error-message
    this.loadServiceTaskBuildsForErrorDetails(image);
  }

  onGerritActionAdd(){
    this.subscriberObservable = this.app.onGerritActionAddToST(this.stMain.serviceTasksDetails.baseBuild, [], this.stMain.serviceTasksDetails);
    this.subscriberObservable.subscribe((updatedServiceTask) =>{
      if(updatedServiceTask !== null){
        this.stMain.serviceTasksDetails.changes = updatedServiceTask.changes;
        this.postAddGerrit();
      }
    });
  }

  onGerritActionDelete(gerrit: Gerrit){
    this.deleteProcess.item = "Gerrit";
    this.deleteProcess.cr = undefined;
    this.deleteProcess.image = undefined;
    this.deleteProcess.gerrit = gerrit;
    this.deleteProcess.displayDeleteForm = true;
  }
  //#endregion

  validateGerrit(){

  }

  postAddGerrit(){
    //For refresh after gerrit Adding
    this.loadServiceTaskDetails(true);
  }

  deleteGerrit(gerrit: Gerrit){
    this.deleteProcess.displayDeleteForm = false;
    this.deleteProcess.cr = undefined;

    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    let serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId;
    let gerritId = gerrit.gerritId;
    let image = gerrit.softwareImage;
    let request : TriageDeleteGerritRequest;
    request = {gerritId: gerritId,
               softwareImage: image};

    gerrit.deleteInProgress = true;

    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Delete Service Task: " + serviceTaskId + " , Gerrit: " +  gerritId + ", Image: " + image);
    }
    response = this.webClient.deleteServiceTaskGerrit(serviceTaskId.toString(), request);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Delete Service Task: " + serviceTaskId + " , Gerrit: " +  gerritId + ", Image: " + image + " - Success Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Delete Service Task: " + serviceTaskId + " , Gerrit: " +  gerritId + ", Image: " + image + " - Success");
          let deleteImageIndex: number = -1;
          this.stMain.serviceTasksDetails.changes.forEach((gerritSI, imageIndex)=>{
            if(gerritSI.softwareImage === image){
              let index: number = -1;
              for(var i = 0; i < gerritSI.gerrits.length; i++){
                if(gerritSI.gerrits[i].gerritId === gerritId){
                  index = i;
                }
              }
              if(index != -1){
                gerritSI.gerrits.splice(index, 1);
              }
              if(gerritSI.gerrits?.length === 0){
                deleteImageIndex = imageIndex;
              }
            }
          });
            
          if(deleteImageIndex != -1){
            this.stMain.serviceTasksDetails.changes.splice(deleteImageIndex, 1);
          }

          this.loadServiceTaskTriageDetails();         
        }
        else {
          QLogger.LogError(this.logSrc, "Delete Service Task: " + serviceTaskId + " , Gerrit: " +  gerritId + ", Image: " + image + " - Failed");
          QLogger.LogError(this.logSrc, "Delete Service Task: " + serviceTaskId + " , Gerrit: " +  gerritId + ", Image: " + image + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.app.showMessageBox("Delete Gerrit Status","Failed to delete the Gerrit "+gerrit.gerritId,"OK");
        }
        gerrit.deleteInProgress = false;
      }
    );
  }
  //#endregion

  //#region Images

  resetaddBaseImageProcess(){
    this.addBaseImageProcess = {
      displayForm: false,
      availableImages: [],
      selectedImage: undefined,
      availableImageBuilds: [],
      selectedImageBuild: undefined,
      addImageInProgress: false,
      loadingImages: false,
      loadingImageBuilds: false,
      errorMessage: "",
    }
  }
  //#region Events
  onImageActionAdd(){
    this.resetaddBaseImageProcess();
    this.getAvailableImages();
    this.addBaseImageProcess.displayForm = true;
  }
  onImageActionDelete(image: SoftwareImage){
    this.deleteProcess.item = "Base Image";
    this.deleteProcess.cr = undefined;
    this.deleteProcess.image = image;
    this.deleteProcess.gerrit = undefined;
    this.deleteProcess.displayDeleteForm = true;
  }
  //#endregion
  
  getAvailableImages(){     
    if(!this.sharedData.visibility.software.addBaseImage){
        return;
    }
    let softwareProduct: string = this.stMain.serviceTasksDetails.softwareProduct;
    QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition");
    this.addBaseImageProcess.loadingImages = true;
    let response: Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareProductLatestComposition(softwareProduct);
    response.subscribe(
        (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
                QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Success");
                let obj = JSON.parse(data.getData());
                if (obj !== undefined || obj !== null) {
                  let images: SoftwareImage[] = obj as SoftwareImage[];
                  let imagesFiltered: SoftwareImage[] = [];
                  images.forEach((image)=>{
                    var imageNameSplit = image.softwareImage.split(".");
                    imageNameSplit.splice(2, imageNameSplit.length - 2);
                    var imageName = imageNameSplit.join(".");
                    if(!this.baseImagesMap.has(imageName)){
                      imagesFiltered.push(image);
                    }
                  });
                  this.addBaseImageProcess.availableImages = this.utils.getSelectItems(imagesFiltered, "softwareImage");
                }
            }
            else{
                QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Failed");
                QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Image Composition - Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.addBaseImageProcess.loadingImages = false;
        }
    );
  }

  getLatestImageBuilds(){
    if(this.addBaseImageProcess.selectedImage === undefined){
        return;
    }
    this.addBaseImageProcess.availableImageBuilds = [];
    let softwareImageBuild: string = this.addBaseImageProcess.selectedImage.softwareImageBuild;
    QLogger.LogInfo(this.logSrc, "Get Latest Software Images after " + softwareImageBuild);
    this.addBaseImageProcess.loadingImageBuilds = true;
    let response: Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareImagesBuildsLatest(softwareImageBuild);
    response.subscribe(
        (data: QPMResponse) => {
            if(this.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Response : " +JSON.stringify(data));
            }
            if (data.isSuccess()) {
                QLogger.LogInfo(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Success");
                let obj = JSON.parse(data.getData());
                if (obj !== undefined || obj !== null) {
                    this.addBaseImageProcess.availableImageBuilds = this.utils.getSelectItems(obj as SoftwareImage[], "softwareImageBuild");
                }
            }
            else{
                QLogger.LogError(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Failed");
                QLogger.LogError(this.logSrc, "Get Latest Software Images after " + softwareImageBuild + " - Error : " + data.getError() + " - " + data.getErrorDetail());
            }
            this.addBaseImageProcess.loadingImageBuilds = false;
        }
    );
  }

  cirrusTaskStatusBaseImages(image: SoftwareImage){
    let serviceTaskID = this.stMain.serviceTasksDetails.serviceTaskId.toString();
    
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTaskID + "Base Build Image : " + image.softwareImageBuild + " Cirrus Task Status");
    }

    let responseData;
    let response : Observable<QPMResponse>;
    image.status = "In Progress";
    image.validationMsg = "";
    if(this.validationImageCount === 0){
      this.validationSteps[0] = {dataType: 'STEP', step: {step: 'Base build validation', status: 'In Progress', statusDetails: ''}};
    }
    this.validationImageCount++;
    response = this.webClient.cirrustaskStatusServiceTaskBaseImage(serviceTaskID, image.softwareImageBuild);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTaskID + "Base Build Image : " + image.softwareImageBuild + " Cirrus Task Status - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTaskID + "Base Build Image : " + image.softwareImageBuild + " Cirrus Task Status - Success"); 
          responseData = JSON.parse(data.getData());
          if(!responseData.cirrusTaskCreated){
            this.validateBaseImages(image);
          }
          else{
            image.status = "Built";
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTaskID + "Base Build Image : " + image.softwareImageBuild + " Cirrus Task Status - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTaskID + "Base Build Image : " + image.softwareImageBuild + " Cirrus Task Status - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          image.valid = false;
          image.validationMsg = data.getError();
          image.status = "Unknown";
        }
        this.validationImageCount--;
        if(this.validationImageCount === 0){
            this.validationSteps[0] = {dataType: 'STEP', step: {step: 'Base build validation', status: 'Complete', statusDetails: ''}};
        }
      }
    );
  }

  validateBaseImages(image: SoftwareImage){
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Validate Base Build : " + image.softwareImageBuild);
    }

    let validateResponseData;
    let validateResponse : Observable<QPMResponse>;
    image.status = "In Progress";
    image.validationMsg = "";
    if(this.validationImageCount === 0){
      this.validationSteps[0] = {dataType: 'STEP', step: {step: 'Base build validation', status: 'In Progress', statusDetails: ''}};
    }
    this.validationImageCount++;
    validateResponse = this.webClient.validateServiceTaskBaseImage(image.softwareImageBuild);
    validateResponse.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Validate Base Build : " + image.softwareImageBuild + " - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Validate Base Build : " + image.softwareImageBuild + "  - Success"); 
          validateResponseData = JSON.parse(data.getData());

          image.valid = validateResponseData.valid;
          image.validationMsg = validateResponseData.valid === true ? "" : validateResponseData.message;
          image.status = validateResponseData.valid === true ? "Valid" : "Invalid";
          image.details = validateResponseData.details;
        }
        else {
          QLogger.LogError(this.logSrc, "Validate Base Build : " + image.softwareImageBuild + " - Failed");
          QLogger.LogError(this.logSrc, "Validate Base Build : " + image.softwareImageBuild + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          image.valid = false;
          image.validationMsg = data.getError();
          image.status = "Invalid";
        }
        this.validationImageCount--;
        if(this.validationImageCount === 0){
          this.validationSteps[0] = {dataType: 'STEP', step: {step: 'Base build validation', status: 'Complete', statusDetails: ''}};
        }
      }
    );
  }

  getBaseImages(serviceTaskID: string){
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Images");
    }

    let response : Observable<QPMResponse>;
    response = this.webClient.getServiceTaskBaseImage( this.stMain.serviceTasksDetails.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Images - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Images - Success"); 
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.baseImages = obj.imageBuilds as SoftwareImage[];
            this.baseImagesMap = new Set<string>();
            this.baseImages.forEach((image)=>{
              var imageNameSplit = image.softwareImage.split(".");
              imageNameSplit.splice(2, imageNameSplit.length - 2);
              var imageName = imageNameSplit.join(".");
              this.baseImagesMap.add(imageName);
            });
            this.getAllComments();
            this.loadServiceTaskTriageDetails();
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Images - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Images - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  addBaseImages(image: SoftwareImage){
      this.addBaseImageProcess.addImageInProgress = true; 
      this.addBaseImageProcess.errorMessage = "";
      let request = image;

      let response: Observable<QPMResponse>;
      if(this.app.sharedData.appInfo.logRequest){
        QLogger.LogInfo(this.logSrc, "Add Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Image : " + image.softwareImageBuild);
      }
      response = this.webClient.addServiceTaskBaseImage(this.stMain.serviceTasksDetails.serviceTaskId.toString(), request);
      response.subscribe(
        (data: QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Add Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Image : " + image.softwareImageBuild);
          }
          if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc, "Add Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Image : " + image.softwareImageBuild + " Response - Success");
            this.loadServiceTaskDetails(false);
            this.addBaseImageProcess.displayForm = false;
          }
          else {
            QLogger.LogError(this.logSrc, "Add Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Image : " + image.softwareImageBuild + " - Failed");
            QLogger.LogError(this.logSrc, "Add Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Image : " + image.softwareImageBuild + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
            this.addBaseImageProcess.errorMessage = "Add Service Task " + this.stMain.serviceTasksDetails.serviceTaskId + " Base Image : " + image.softwareImageBuild + " "+ data.getError();
          }
          this.addBaseImageProcess.addImageInProgress = false;
        }
      );
  }

  deleteBaseImage(image: SoftwareImage){
    this.deleteProcess.displayDeleteForm = false;
    this.deleteProcess.image = undefined;

    let response : Observable<QPMResponse>;
    if(this.webClient === null) {
      return
    }

    image.deleteInProgress = true;

    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , Base Image: " +  image.softwareImageBuild);
    }
    response = this.webClient.deleteServiceTaskBaseImage(this.stMain.serviceTasksDetails.serviceTaskId.toString(), image.softwareImageBuild);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , Base Image: " +  image.softwareImageBuild + " - Success Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , Base Image: " +  image.softwareImageBuild + " - Success");
          let index: number = -1;
          let i: number = -1;
          
          //Remove corresponding Image from Table
          this.validationSteps.forEach((row)=>{
            i++;
            if(row.dataType === 'IMAGE' && row.build?.softwareImageBuild === image.softwareImageBuild){
              index = i;
            }
          });
          if(index !== -1){
            this.validationSteps.splice(index, 1);
          }

          //Remove Image from cache
          index = -1;
          i = -1;
          this.baseImages.forEach((baseImage)=>{
            i++;
            if(baseImage.softwareImageBuild === image.softwareImageBuild){
              index = i;
            }
          });
          if(index !== -1){
            this.baseImages.splice(index, 1);
            this.stMain.serviceTasksDetails.serviceTaskImageBuilds.splice(index, 1);
          }
          var imageNameSplit = image.softwareImage.split(".");
          imageNameSplit.splice(2, imageNameSplit.length - 2);
          var imageName = imageNameSplit.join(".");
          this.baseImagesMap.delete(imageName);
          //this.stMain.loadServiceTaskDetails();
          if(this.baseImages.length === 0){
            this.validationSteps[0].step.status = "N/A";
          }
          //this.validateCRs(true);
        }
        else {
          QLogger.LogError(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , Base Image: " +  image.softwareImageBuild + " - Failed");
          QLogger.LogError(this.logSrc, "Delete Service Task: " + this.stMain.serviceTasksDetails.serviceTaskId + " , Base Image: " +  image.softwareImageBuild + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.app.showMessageBox("Delete Base Image Status","Failed to delete the Image "+image.softwareImageBuild,"OK");
        }
        image.deleteInProgress = false;
      }
    );
  }
//#endregion

  //#region CrSearch
  onClickSearchAndUpdate(){
    this.resetSearchUpdateCRProcess();
    let crsMap: Map<number, ChangeRequest> = new Map();
    this.stMain.serviceTasksDetails.changes.forEach((change)=>{
      change.changeRequests.forEach((cr)=>{
        if(cr.action === "Pending"){
          crsMap.set(cr.changeRequest, cr)
        }
      });
    });
    this.searchUpdateCRProcess.crPending.push(...crsMap.values());
    this.searchUpdateCRProcess.crSelected = this.searchUpdateCRProcess.crPending;
    //this.searchUpdateCRProcess.crSelected.push(...image.selectedCRsMap.values());
    this.searchUpdateCRProcess.showSelectDialog = true;
    this.searchUpdateCRProcess.showConfirmDialog = false;
  } 

  resetSearchUpdateCRProcess(){
    this.searchUpdateCRProcess={
      crPending: [],
      crSelected: [],
      showSelectDialog: false,
      showConfirmDialog: false,
      searchUpdateCRInProgress: false,
      errorMessage: ""
    }
  }

  searchUpdateCRNext(){
    this.searchUpdateCRProcess.showSelectDialog = false;
    this.searchUpdateCRProcess.showConfirmDialog = true;
  }
  searchUpdateCRBack(){
    this.searchUpdateCRProcess.showSelectDialog = true;
    this.searchUpdateCRProcess.showConfirmDialog = false;
  }
  
  startFindCrStatusProcess(){
    this.app.startFindCrStatusProcess(this.stMain.serviceTasksDetails.baseBuild, 'ProductBuild', this.stMain.serviceTasksDetails.serviceTaskId)
  }

  searchAndUpdate(){
    let serviceTaskId = this.stMain.serviceTasksDetails.serviceTaskId;
    let createdUsingSI = this.stMain.serviceTasksDetails.createdUsingSI;
    let baseBuild = this.stMain.serviceTasksDetails.baseBuild;
    let request : SearchUpdateCrFixDetailRequest;
    request = {
                serviceTaskId: serviceTaskId,
                baseBuild: baseBuild,
                createdUsingSI: createdUsingSI,
                changeRequest: this.searchUpdateCRProcess.crSelected.map((cr)=>cr.changeRequest)
              };

    this.searchUpdateCRProcess.searchUpdateCRInProgress = true;

    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Search & Update CR fix. ServiceTaskId: " + serviceTaskId + " , BaseBuild: " +  baseBuild + ", Request: " + JSON.stringify(request));
    }
    let response : Observable<QPMResponse>;
    response = this.webClient.searchUpdateCrFixDetail(request);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Search & Update CR fix. ServiceTaskId: " + serviceTaskId + " , BaseBuild: " +  baseBuild + " - Success Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Search & Update CR fix. ServiceTaskId: " + serviceTaskId + " , BaseBuild: " +  baseBuild + " - Success");
          var obj = JSON.parse(data.getData());
          if(obj.success === true){
            this.app.showMessageBox("Update CR Status","Requested submitted successfully. CR Status will be changed to Already Fixed if the CR is present in the build. Please use the link from notification e-mail to see the results or refresh this page later to view the updated status.","OK");
            this.resetSearchUpdateCRProcess();
          }
          else{
            this.searchUpdateCRProcess.errorMessage = "CR update request failed. Error: " + obj.message;
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Search & Update CR fix. ServiceTaskId: " + serviceTaskId + " , BaseBuild: " +  baseBuild + " - Failed");
          QLogger.LogError(this.logSrc, "Search & Update CR fix. ServiceTaskId: " + serviceTaskId + " , BaseBuild: " +  baseBuild + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          this.app.showMessageBox("Search & Update CR Status","Search & Update request failed. Error: " + data.getError(),"OK");
        }
        this.searchUpdateCRProcess.searchUpdateCRInProgress = false;
      }
    );
  } 
  //#endregion

  //#region UpdateLatestBaseBuild
  addCustomBaseImage(image: GerritSI){
    this.stMain.addCustomeBaseImage(image.softwareImage, image.softwareImageBuild, image.originalSoftwareImageBuild, image, this.updateSoftwareImageBaseBuild, this, true,false,false);
  }

  updateSoftwareImageBaseBuild(appPtr: any){
    appPtr.stMain.addCustomBaseImageProcess.displayForm = false;
    if(appPtr.stMain.addCustomBaseImageProcess.softwareImageTypeSelected !== "default"){
      let selectedSoftwareImageBuild = appPtr.stMain.addCustomBaseImageProcess.softwareImageBuildSelected.imageBuild as string;
      appPtr.stMain.addCustomBaseImageProcess.imageData.softwareImageBuild = selectedSoftwareImageBuild;
      let image = appPtr.stMain.addCustomBaseImageProcess.imageData as GerritSI;
      image.changeRequests.forEach((cr)=>{cr.softwareImageBuild = selectedSoftwareImageBuild});
      image.gerrits.forEach((g)=>{g.softwareImageBuild = selectedSoftwareImageBuild});
    }
  }
  //#endregion
}