import { HttpClient } from '@angular/common/http';
import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.css']
})
export class SuccessComponent implements OnInit {
  private logSrc: string = "Success-Component";


  private catalogClient: CatalogClientService;
  private webClient: WebClientService;
  private limeClient: ClientService;
  private telematicsClient: TelematicsClientService;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public app: AppMainComponent, private utils: Utils,
    private service: DataServiceProducer, private http: HttpClient,
    public zone: NgZone) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;

  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "Success-Component Initialization");
  }

  ngAfterViewInit() {


  }
  ngOnDestroy(): void {
    QLogger.LogInfo(this.logSrc, "Success-Component Destroy");
  }


  goToQpmHomePage(){
    this.router.navigate(['/main/licenses/find']);
  }

}
