import { Injectable } from '@angular/core';
import isElectron from 'is-electron';
import { LoggingService } from '../Contract/LoggingService';
import { ServiceUtils } from '../ServiceUtils';

@Injectable()
export class LimeLoggingService implements LoggingService{
  private loggingClient;
  constructor(private serviceUtils : ServiceUtils) {
    if(isElectron()){
      var lime = window.require('bindings')('addon.node');
      this.loggingClient = new lime.LimeLoggingWrapper();
    }
  }
  public registerErrorCallback(errorCallback:Function) : void
  {
    this.loggingClient.registerErrorCallback(errorCallback);
  }
  public registerWarningCallback(errorCallback:Function) : void
  {
    this.loggingClient.registerWarningCallback(errorCallback);
  }
  public registerInfoCallback(errorCallback:Function) : void
  {
    this.loggingClient.registerInfoCallback(errorCallback);
  }  
}
