import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';

@Component({
    selector: 'app-menu',
    template: `
		<ul class="ultima-menu ultima-main-menu clearfix">
			<li app-menuitem *ngFor="let item of model; let i = index;" id ="{{item.id}}" [item]="item" [index]="i" [root]="true"></li>
		</ul>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppMainComponent) {}

    ngOnInit() {
        this.enableMenuByVisibility();
        this.setupMenu();
    }

    enableMenuByVisibility(){
        var visibilitySubscribe = this.app.sharedData.subscribeVisibility("MenuComponent");
        visibilitySubscribe.subscribe((updated)=>{
            if(updated){                        
                this.setupMenu();
            }
        });
    }

    setupMenu(){        
        this.model =[];
        this.model.push({label: 'Home',id: 'Home', icon: "home", routerLink: ['home']});
        if(!this.app.sharedData.appInfo.workOffline)
        {
            this.model.push({label: 'Software', id: 'SoftwarePage' ,image: 'assets/images/leftNav_SW.png',
                             items:[
                                {label: 'Software Builds', routerLink: ['software']},
                                {label: 'Software Source', routerLink: ['source/find']},
                             ]});
        }
        this.model.push({label: 'Service Tasks',id: 'ServiceTaskPage', image: "assets/images/leftNav_task.png", routerLink: ['software/servicetask/find']});
        this.model.push({label: 'Tools', id: 'ToolsPage',image: 'assets/images/leftNav_tools.png', routerLink: ['tools/find']});
        this.model.push({label: 'Search', id: 'CrSearchPage',image: 'assets/images/leftNav_crsearch.png',
                        items:[
                        {label: 'CR Search', routerLink: ['crsearch']},
                        {label: 'CVE Lookup',  routerLink: ['cvelookup']},
                        ]}); 

        this.model.push({label: 'Licenses',id: 'LicensesPage', image: "assets/images/leftNav_licenses.png", routerLink: ['licenses/find']});

        this.model.push({label: 'Documents', id: 'DocumentsPage',image: 'assets/images/leftNav_doc.png', routerLink: ['document']});        
        if(this.app.sharedData.appInfo.isElectronMode)
        {
            this.model.push({label: 'Downloads',  id:'DownloadsPage',icon: 'history', routerLink: ['downloads']});
        }
    }
}
