<div class="exception-body accessdenied-page">
    <div class="exception-type">
        <img src="assets/layout/images/401.svg">
    </div>

    <div class="card exception-panel">
        <i class="material-icons">&#xE32A;</i>
        <h1>Access Denied</h1>
        <div class="exception-detail">You are not authorized to access this resource.</div>
        <p-button label="Homepage" [routerLink]="['/']" styleClass="pink-btn"></p-button>
    </div>
</div>
