import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy, DatePipe} from '@angular/common';
import {AppRoutes} from './app.routes';

// PrimeNG Components for demos
import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SpinnerModule} from 'primeng/spinner';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { OAuthModule } from 'angular-oauth2-oidc';
import {BlockUIModule} from 'primeng/blockui';

//Module Services
import {TerminalService} from 'primeng/terminal';
import {MessageService} from 'primeng/api';
import {CookieService} from 'ngx-cookie-service';
import {OAuthService} from 'angular-oauth2-oidc';
import {AuthConfig} from 'angular-oauth2-oidc';


// Application Components
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppWizardComponent} from './pages/app.wizard.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppInlineProfileComponent} from './app.profile.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppConfigComponent} from './app.config.component';
import {AppRightpanelComponent} from './app.rightpanel.component';
import {AppTopbarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';





// Application services
import {BreadcrumbService} from './breadcrumb.service';
import {MenuService} from './app.menu.service';

import {AccessDeniedComponent} from './component/common/accessdenied/accessdenied.component';

import { SoftwareComponent } from './component/software/software.component';
import { ToolsComponent } from './component/tools/tools.component';
import { DocumentComponent } from './component/document/document.component';
import { HeaderComponent } from './component/header/header.component';
import { LoginComponent } from './component/login/login.component';
import { HomeComponent } from './component/home/home.component';
import { STFindComponent } from "./component/software/servicetask/find/st.find.component";
import { STTriageComponent } from './component/software/servicetask/triage/st.triage.component';
import { STBuildComponent } from './component/software/servicetask/build/st.build.component';
import { STDistComponent } from './component/software/servicetask/dist/st.dist.component';

import {Utils} from './common/utils';
import { ServiceUtils } from './service/ServiceUtils';
import { DataServiceProducer } from './service/Factory/DataServiceProducer';
import { LimeService } from './service/Factory/LimeService';
import { RestService } from './service/Factory/RestService';
import { LimeClientService } from './service/LimeService/lime-client.service';
import { LimeWebClientService } from './service/LimeService/lime-web-client.service';
import { RestWebClientService } from './service/RestService/rest-web-client.service';
import { LimeCatalogClientService } from './service/LimeService/lime-catalog-client.service';
import { LimeSoftwareCatalogClientService } from './service/LimeService/lime-software-catalog-client.service';
import { RestSoftwareCatalogClientService } from './service/RestService/rest-software-catalog-client.service';
import { LimeDistributionClientService } from './service/LimeService/lime-distribution-client.service';
import { ApiUrls } from './service/ApiUrls';
import { ConfigProperties} from './common/config-properties';
import { QConfig } from './common/qpm-config';
import { UserProfileService } from './service/CommonService/user-profile.service';
import { SharedData } from './common/shared-data';
import { QLogger } from './common/logger';
import { DownloadsComponent } from './component/downloads/downloads.component';
import { STMainComponent } from './component/software/servicetask/main/st.main.component';
import { LimeLoggingService } from './service/LimeService/lime-logging.service';
import { UserguideComponent } from './component/userguide/userguide.component';
import { V1UserguideComponent } from './component/userguide/v1userguide/v1userguide.component';
import { InternaluserguideComponent } from './component/userguide/internaluserguide/internaluserguide.component';
import { TelematicsService } from './service/LimeService/telematics.service';
import { DetailsComponent } from './component/tools/details/details.component';
import { InstallComponent } from './component/tools/install/install.component';
import { CrsearchComponent } from './component/crsearch/crsearch.component';
import { CveLookupComponent } from './component/cvelookup/cvelookup.component';
import { LicensesComponent } from './component/licenses/licenses.component';
import { LicensesgroupComponent } from './component/licenses/licensesgroup/licensesgroup.component';
import { LicensesfindComponent } from './component/licenses/licensesfind/licensesfind.component';
import { LicensesseatComponent } from './component/licenses/licensesseat/licensesseat.component';
import { SerialnumberComponent } from './component/licenses/serialnumber/serialnumber.component';
import { CheckoutComponent } from './component/licenses/checkout/checkout.component';
import { NgxStripeModule } from 'ngx-stripe';
import { SuccessComponent } from './component/licenses/success/success.component';
import { environment } from 'src/environments/environment';
import { EmailsubscriptionComponent } from './component/licenses/emailsubscription/emailsubscription.component';
import { CartComponent } from './component/licenses/cart/cart.component';
import { CloDetailsComponent } from './component/tools/clo-details/clo-details.component';
import { authCodeFlowConfig } from './common/auth-config';
import { AuthService } from './service/auth.service';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        BlockUIModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        RatingModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        BrowserModule,
        FormsModule,
        CheckboxModule,
        NgxStripeModule.forRoot(environment.stripePublishableKey),
        OAuthModule.forRoot()
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineProfileComponent,
        AppTopbarComponent,
        AppFooterComponent,
        AppRightpanelComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppHelpComponent,
        AppInvoiceComponent,
        AppWizardComponent,
        AccessDeniedComponent,
        SoftwareComponent,
        ToolsComponent,
        DocumentComponent,
        DownloadsComponent,
        HeaderComponent,
        LoginComponent,
        HomeComponent,
        STFindComponent,
        STTriageComponent,
        STBuildComponent,
        STDistComponent,
        STMainComponent,
        UserguideComponent,
        V1UserguideComponent,
        InternaluserguideComponent,
        DetailsComponent,
        InstallComponent,
        CrsearchComponent,
        CveLookupComponent,
        LicensesComponent,
        LicensesgroupComponent,
        LicensesfindComponent,
        LicensesseatComponent,
        SerialnumberComponent,
        CheckoutComponent,
        SuccessComponent,
        EmailsubscriptionComponent,
        CartComponent,
        CloDetailsComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        DatePipe,
        QLogger,
        CookieService,
        MessageService, TerminalService, BreadcrumbService, MenuService,
        UserProfileService,
        DataServiceProducer,
        LimeService,
        RestService,
        LimeClientService,
        LimeWebClientService,
        LimeCatalogClientService,
        LimeSoftwareCatalogClientService,
        LimeDistributionClientService,
        LimeLoggingService,
        TelematicsService,
        RestWebClientService,
        RestSoftwareCatalogClientService,
        ApiUrls,
        ConfigProperties,
        QConfig,
        ServiceUtils,
        Utils,
        SharedData,
        OAuthService,
        AuthService,
        { provide: AuthConfig, useValue: authCodeFlowConfig}
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
