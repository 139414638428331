import { MenuItem, SelectItem } from 'primeng/primeng';
import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { QPMResponse } from 'src/app/models/response';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { AppMainComponent } from 'src/app/app.main.component';
import { ServiceTaskInfo, ServiceTaskDistProduct, SoftwareProductDistro, SoftwareProductRelease, ServiceTaskDistProductCRs, ServiceTaskDistProductDetail, ServiceTaskDistImpactedFiles, SoftwareImageDistro, ServiceTaskDistProductComposition, FileStatus, FileInfo, AdditionalCRInfo, CustomerRecords, ServiceTaskBuildsForOss, ServiceTaskBaitImageForOss, ServiceTaskBaitBuildForOss, ServiceTaskBaitJob, OssLinkDetail } from 'src/app/models/software-catalog-client';
import { UpdateServiceTaskRequest, SoftwareProductDistroInfo, DistributionRequest, shipDownloadResponse,DiffDistributionRequest,diffShipDownloadResponse, Email, AvaliableDiffBuildsResponse, SoftwareDiffBuildRelease, AvaliableDiffImagesResponse, SoftwareImageDiffBuildRelease, SoftwareImageDistroInfo, imageShipDownloadResponse, shipStatusForImage, OPSHELP } from 'src/app/models/lime-web-client';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { Utils } from 'src/app/common/utils';
import { QLogger } from 'src/app/common/logger';
import { DistributionClientService } from 'src/app/service/Contract/DistributionClientService';
import { StringBuilder } from 'typescript-string-operations';
import { ProductSuite } from 'src/app/models/catalog-client';
import { STMainComponent } from '../main/st.main.component';
import { resolve } from 'dns';
import { BinDdmJiraTicketInfoRequest, BinDdmJiraTicketInfoResponse, NoticeFileDownloadRequest, NoticeFileUploadCompleteRequest, NoticeFileUploadStartRequest, shipStatusErrorResponse } from 'src/app/models/distribution-client';
import { FileUploadType, LargeFileUpload, LargeFileUploadConfig } from 'src/app/common/large-file-upload';
@Component({
  selector: 'app-st.dist',
  templateUrl: './st.dist.component.html',
  styleUrls: ['./st.dist.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class STDistComponent implements OnInit {

  private logSrc:string = "ServiceTaskDist-Component";

  private catalogClient: CatalogClientService;
  private softwareCatalogClient: SoftwareCatalogClientService;
  private webClient: WebClientService;
  private distributionClient: DistributionClientService;
  largeFileUpload : LargeFileUpload;
  

  impactedFileTableCols: any[];
  impactedFileGerritTableCols: any[];

  serviceTaskDistProducts : ServiceTaskDistProduct[];
  productDistros: SoftwareProductDistro[];
  
  shipProcess: {
    distroTypes: SoftwareProductDistro[],
    distroSelected: SoftwareProductDistro[],
    softwareProductBuild: string,
    displayDistro: boolean,
    loadingDistros:boolean
  };
  
  downloadProcess: {
    distroTypes: SoftwareProductDistro[],
    approvedDistroTypes:SoftwareProductDistro[]
    distroTypesSelectItems: SelectItem[],
    distroSelected: SoftwareProductDistro[],
    softwareBuildSelected: SoftwareProductRelease,
    displayDistro: boolean,
    setCredentialFailed:boolean,
    loadingDistros:boolean,
    shipFailed:boolean;
    softwareProductBuild?:string;
  }
  
  diffShipProcess: {
    distroTypes: SoftwareProductDistro[],
    distroSelected: SoftwareProductDistro[],
    softwareBuildSelected: SoftwareProductRelease,
    baseBuildSelected: SoftwareProductRelease,
    displayDistro: boolean,
    displayBuild:boolean,
    availableBaseBuilds:SoftwareProductRelease[],
    filteredBaseBuilds:SoftwareProductRelease[],
    loadingDistros: boolean,
    displayAllBaseBuilds:boolean

  };
  diffDownloadProcess: {
    softwareBuildSelected: SoftwareProductRelease,
    baseBuildSelected: SoftwareProductRelease,
    setCredentialFailed:boolean,
    displayBuild:boolean;
    availableBaseBuilds:SoftwareProductRelease[],
    loadingBaseBuilds: boolean,
    shipFailed:boolean;
    updateDiffBuildSubscription:Subscription ;
    updateAvailableDistrosSubscription:Subscription;
    showConfirmDialog:boolean;
  };
  shipProductPreview: {
    displayPreview: boolean
  };
  downloadImageProcess: {
    distroTypes: SoftwareProductDistro[],
    distroSelected: SoftwareProductDistro,
    softwareBuildSelected: SoftwareProductRelease,
    displayDistro: boolean,
    softwareImageBuild:string;
  }
  diffDownloadImageProcess: {
    softwareBuildSelected: SoftwareProductRelease,
    baseBuildSelected: SoftwareProductRelease,
    displayBuild: boolean,
    baseSoftwareImageBuild:string,
    availableBaseBuilds:SoftwareProductRelease[],
    targetSoftwareImageBuild:string,
    loadingBaseBuilds: boolean,
    shipFailed:boolean
  }
  cliCommandProcess:{
    display:boolean,
    cliDiffDownloadCommand:string,
    isCopiedToClipboard:boolean,
  }
  //#region BIN_DDM ProcessObject
  binDdmApprovalProcess:{
    display:boolean,
    tabIndex: number,
    selectedSoftwareProductDistro:SoftwareProductDistro,
    softwareProductBuildId:string,
    product:ServiceTaskDistProduct,
    index:number
  }
  jiraTicketIDUploadProcess:{
    jiraTicketID:string,
    errorMessage:string;
    successMessage:string;
    ticketIdUploadInprogress:boolean;
    jiraTicketStatus:string;
    lostResolution:string;
    jiraUrl:string;
  }
  noticeFileUploadProcess:{
    files: any[];
    filesInfo: FileInfo[];
    fileErrorMessage: string;
    fileSuccessMessage:string;
    totalFileSizeInByte: number;
    totalFileSizeInMB: number;
    maxFileSizeInByte: number;
    maxFileSizeInMB: number;
    fileUploadInprogress:boolean;
    errorMessage:string;
    successMessage:string;
    maxChunkSizeInByte: number;
    sessionId:number;
    subscription: Observable<boolean>;
    
  }
  //#endRegion BIN_DDM ProcessObject


  //#region OSS ProcessObject
  serviceTaskBuildsForOss:ServiceTaskBuildsForOss;
  @ViewChild('ossTable') ossTable;
  @ViewChild('ossGlobalFilter') ossGlobalFilter;
  ossCols: any[];
  
  setOSSTableUIHeader(){
    this.ossCols = [
      { field: 'baseImage', header: 'Base Image', style:{ 'width': '25%' }, sortable: true},
      { field: 'oss', header: 'OSS Link', style:{ 'width': '55%' } },
      { field: 'build_completed_time', header: 'Completed At', style:{ 'width': '20%' } }
    ];
  }

  onOssFilterClear() {
  this.ossGlobalFilter.nativeElement.value = '';
  this.ossTable.filterGlobal('', 'contains');
  }
  //#endregion OSS ProcessObject

  imageShipStatus:{

    softwareImageShipStatus:shipStatusForImage[];
    showImageStatus:boolean;
  }
  availableDiffBuilds:SoftwareDiffBuildRelease[];
  availableDiffImageBuilds:SoftwareImageDiffBuildRelease[];

  additionalCRColumns: Array<any> = [
    { field: 'changeRequestNumber', header: 'CR No.', style: {'width': '10%'}, isFilterable: true},
    { field: 'srNumbers', header: 'Case No.', style: {'width': '10%'}, isFilterable: true},
    { field: 'area', header: 'Area', style: {'width': '10%'}, isFilterable: true},
    { field: 'subsystem', header: 'System', style: {'width': '10%'}, isFilterable: true},
    { field: 'functionality', header: 'Functionality', style: {'width': '10%'}, isFilterable: true},
    { field: 'problemDescription', header: 'Problem Description'},
    { field: 'problemScenario', header: 'Problem Scenario'},
    { field: 'changeDescription', header: 'Change Description'}
    ];

  constructor(private router: Router, public activatedRoute: ActivatedRoute, public app: AppMainComponent, 
              private utils: Utils, private service: DataServiceProducer, public stMain: STMainComponent) {
    QLogger.LogInfo(this.logSrc, "ServiceTaskDist Component Creation");
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.distributionClient = service.getServiceInstance(ApiType.DistributionClient) as DistributionClientService;
  }

  ngOnInit(): void {

    QLogger.LogInfo(this.logSrc, "ServiceTaskDist Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);

    this.activatedRoute.params.subscribe(params => {
      this.app.sharedData.service.common.selectedServiceTaskID = params['id'];
      
      // Static UI Elements
      this.setOSSTableUIHeader();
      this.stMain.activeIndex = 2;
      this.impactedFileTableCols= [
        { field: 'crNumber', header: 'CR#', style:{'width':'150px'} },
        { field: '', header: 'Files'}
      ];
      this.impactedFileGerritTableCols= [
        { field: 'gerritId', header: 'Gerrit#', style:{'width':'150px'} },
        { field: '', header: 'Files'}
      ];
      this.serviceTaskDistProducts = [];
      this.productDistros = [];
      
      this.availableDiffImageBuilds =[];
      this.shipProcess = {
        distroTypes: [],
        distroSelected: [],
        softwareProductBuild: "",
        displayDistro: false,
        loadingDistros:false
      };
      this.downloadProcess = {
        distroTypes: [],
        approvedDistroTypes:[],
        distroTypesSelectItems: [],
        displayDistro: false,
        distroSelected: [],
        softwareBuildSelected: new SoftwareProductRelease(),
        setCredentialFailed:false,
        loadingDistros:false,
        shipFailed:false
      };
      this.diffShipProcess = {
        distroTypes: [],
        displayDistro: false,
        distroSelected: [],
        softwareBuildSelected: new SoftwareProductRelease(),
        availableBaseBuilds:[],
        filteredBaseBuilds:[],
        baseBuildSelected:new SoftwareProductRelease(),
        displayBuild:false,
        loadingDistros: false,
        displayAllBaseBuilds:false
  
      }

      this.diffDownloadProcess = {
        softwareBuildSelected: new SoftwareProductRelease(),
        setCredentialFailed:false,
        availableBaseBuilds:[],
        displayBuild:false,
        baseBuildSelected:new SoftwareProductRelease(),
        loadingBaseBuilds: false,
        shipFailed:false,
        updateDiffBuildSubscription : undefined,
        updateAvailableDistrosSubscription:undefined,
        showConfirmDialog:false
  
      }
      this.downloadImageProcess = {
        distroTypes: [],
        displayDistro: false,
        distroSelected:undefined,
        softwareBuildSelected: new SoftwareProductRelease(),
        softwareImageBuild:""
      };
      this.diffDownloadImageProcess = {
        displayBuild: false,
        softwareBuildSelected: new SoftwareProductRelease(),
        baseBuildSelected:new SoftwareProductRelease(),
        baseSoftwareImageBuild:"",
        targetSoftwareImageBuild:"",
        availableBaseBuilds:[],
        loadingBaseBuilds: false,
        shipFailed:false
      };
      this.cliCommandProcess={
        display:false,
        cliDiffDownloadCommand:'',
        isCopiedToClipboard:false,
      }
      this.resetBinDdmApprovalProcess();
      this.resetJiraTicketIDUploadProcess();
      this.resetNoticeFileUploadProcess();
      this.shipProductPreview = {
        displayPreview: false
      };
      this.imageShipStatus = {
        softwareImageShipStatus:[],
        showImageStatus:false
      };
      this.app.sharedData.service.common.abandonInProgress = false;
      this.app.sharedData.resetVisibility();
      this.loadServiceTaskBaitBuildsForOSS();
      this.loadServiceTaskDetails();
    });

  }

  //#region events
  previousProductComposition:ServiceTaskDistProductComposition[]=[];
  onProductExpand(product: ServiceTaskDistProduct,index:number){
    if(product.productNewBuildID===null) return
    this.previousProductComposition=[];
    let software = new SoftwareProductRelease();
    software.softwareProductBuild = product.productNewBuildID;
    this.getDistrosShipStatus(software, product);
    this.loadServiceTaskBuildDetails(product, false);
    this.loadProductReleaseInfo(product, false);
    this.loadProductConsolidatedCRs(product, false);
    this.loadProductComposition(product, false,index);
    
    this.loadProductImpactedFiles(product, false);
    
  }

  onProductRefresh(product: ServiceTaskDistProduct,index:number){
    if(product.productNewBuildID===null) return
    this.previousProductComposition=[];
    let software = new SoftwareProductRelease();
    software.softwareProductBuild = product.productNewBuildID;
    this.getDistrosShipStatus(software, product);
    this.loadServiceTaskBuildDetails(product, true);
    this.loadProductReleaseInfo(product, true);    
    this.loadProductConsolidatedCRs(product, true);
    this.loadProductComposition(product, true,index);
    this.loadProductImpactedFiles(product, true);   
  }

  onShipActionClick(softwareProductBuild: string){
    let software = new SoftwareProductRelease();
    software.softwareProductBuild = softwareProductBuild;
    this.getDistrosShipStatus(software);
    this.shipProcess.softwareProductBuild = softwareProductBuild;
    this.shipProcess.displayDistro = true;
  }
  onShipButtonClick(event){
    this.shipSoftwareProductBuild(this.shipProcess.softwareProductBuild, this.shipProcess.distroSelected);
  }

  onDownloadActionClick(softwareReleaseInfo:SoftwareProductRelease) {
    if(this.app.downloadQueue.downloadInProgress){
      this.app.showMessageBox("Download", "Download request failed. Currently another download in-progress", "Ok");
      return;
    }
    this.downloadProcess.softwareBuildSelected = softwareReleaseInfo;
    this.getDistrosShipStatus(softwareReleaseInfo);
    this.downloadProcess.displayDistro = true;
  }

  onDownloadButtonClick(event) {
    this.downloadProcess.displayDistro = false;
    let defaultDownloadPath:string;
    let downloadPath:string;
    downloadPath ="";
    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      const { dialog } = window.require('electron').remote;
      response = this.distributionClient.getDefaultDownloadLocation();
      response.subscribe(
        (data: QPMResponse) => {
          defaultDownloadPath = data.getData();;
          var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",defaultPath :defaultDownloadPath,properties: ['openDirectory'] }); //Select Download Path
          if(!downloadPathList)
          {
            downloadPath = "";
          }
          else
          {
            downloadPath = downloadPathList[0];
          }
          this.downloadSoftwareDistros(this.downloadProcess.softwareBuildSelected, this.downloadProcess.distroSelected,downloadPath);
        });
    }
    else
    {
        this.downloadSoftwareDistros(this.downloadProcess.softwareBuildSelected, this.downloadProcess.distroSelected,"");
    }
    
  }
  //#endregion
  onDiffShipActionClick(softwareProductBuild: SoftwareProductRelease) {
    this.diffShipProcess.softwareBuildSelected = softwareProductBuild;
    this.diffShipProcess.availableBaseBuilds = [];
    this.diffShipProcess.filteredBaseBuilds =[];
    this.diffShipProcess.baseBuildSelected = undefined;
    let  baseBuildShipDate     = new Date(softwareProductBuild.shipDate);
    let availableBuildsMap: Map<string,SoftwareProductRelease> = new Map<string, SoftwareProductRelease>();

    this.stMain.softwareProductReleases?.forEach((availableBuild) =>{
      availableBuildsMap.set(availableBuild.softwareProductBuild,availableBuild);
    
    });
    this.serviceTaskDistProducts?.forEach((product)=>{
      if(product.productNewBuildID!=softwareProductBuild.softwareProductBuild)
      {
          if(availableBuildsMap.has(product.productNewBuildID)){

            let availableBuild =availableBuildsMap.get(product.productNewBuildID);
            let  targetBuildShipDate     = new Date(availableBuild.shipDate);
            if(baseBuildShipDate.getTime() >targetBuildShipDate.getTime())
            {
              this.diffShipProcess.filteredBaseBuilds.push(availableBuild);
            }
          }
      }
      
    });
    if(availableBuildsMap.has(this.stMain.serviceTasksDetails.baseBuild)){

      let availableBuild =availableBuildsMap.get(this.stMain.serviceTasksDetails.baseBuild);
      let  targetBuildShipDate     = new Date(availableBuild.shipDate);
      if(baseBuildShipDate.getTime() >targetBuildShipDate.getTime())
      {
        this.diffShipProcess.filteredBaseBuilds.push(availableBuild);
      }

    }
    let cutOffDate = new Date(); 
    cutOffDate.setFullYear(cutOffDate.getFullYear() - 1); // Remove expired builds older than 1 year
    this.stMain.softwareProductReleases?.forEach((availableBuild) =>{

      if(availableBuild.softwareProductBuild!=softwareProductBuild.softwareProductBuild)
      { 
        let  targetBuildShipDate     = new Date(availableBuild.shipDate);
        if(baseBuildShipDate.getTime() > targetBuildShipDate.getTime() && targetBuildShipDate.getTime() > cutOffDate.getTime())
        {
          this.diffShipProcess.availableBaseBuilds.push(availableBuild);
        }
      }
    });
    if(!this.diffShipProcess.displayAllBaseBuilds){
      if(this.diffShipProcess.filteredBaseBuilds?.length === 1){
        this.diffShipProcess.baseBuildSelected = this.diffShipProcess.filteredBaseBuilds[0];
      }
    }
    else{
      if(this.diffShipProcess.availableBaseBuilds?.length === 1){
        this.diffShipProcess.baseBuildSelected = this.diffShipProcess.availableBaseBuilds[0];
      }
    }
    this.diffShipProcess.displayBuild = true;
  }
  onFilterDiffBuilds(event)
  {
      if(!this.diffShipProcess.displayAllBaseBuilds){

        if(this.diffShipProcess.filteredBaseBuilds?.length === 1){
          this.diffShipProcess.baseBuildSelected = this.diffShipProcess.filteredBaseBuilds[0];
        }
      }
      else
      {
        if(this.diffShipProcess.availableBaseBuilds?.length === 1){
          this.diffShipProcess.baseBuildSelected = this.diffShipProcess.availableBaseBuilds[0];
        }
      }
  }
  onSelectDistroButtonClick(event){
    this.diffShipProcess.distroSelected = [];
    this.getDiffDistrosShipStatus(this.diffShipProcess.softwareBuildSelected,this.diffShipProcess.baseBuildSelected);
    this.diffShipProcess.displayBuild = false;
    this.diffShipProcess.displayDistro = true;

  }

  onDiffShipButtonClick(event){
    this.diffShipProcess.distroSelected = [...new Set(this.diffShipProcess.distroSelected)];
    this.app.diffShipSoftwareProductBuild(this.diffShipProcess.softwareBuildSelected, this.diffShipProcess.baseBuildSelected,this.diffShipProcess.distroSelected, this.stMain.serviceTasksDetails.serviceTaskId);
    this.diffShipProcess.distroSelected = [];
    this.diffShipProcess.displayDistro = false;
  }

  repostDiffShipRequest(softwareProductBuild: SoftwareProductRelease)
  {
    QLogger.LogInfo(this.logSrc,"Reposting Diff SHip request for "+this.diffDownloadProcess.softwareBuildSelected.softwareProductBuild+" and "+softwareProductBuild.softwareProductBuild+" for distro "+softwareProductBuild.distroAvailable.softwareDistro);
    this.diffDownloadProcess.displayBuild = false;
    let distroList:SoftwareProductDistro[] = [];
    distroList.push(softwareProductBuild.distroAvailable);
    this.app.diffShipSoftwareProductBuild(softwareProductBuild,this.diffDownloadProcess.softwareBuildSelected,distroList, this.stMain.serviceTasksDetails.serviceTaskId);
    this.diffShipProcess.distroSelected = [];
    this.diffShipProcess.displayDistro = false;

  }

  //#region CLI-command Snippet 
  openCLIDialogBox(){
    this.cliCommandProcess.cliDiffDownloadCommand='';
    this.cliCommandProcess.isCopiedToClipboard=false;
    this.cliCommandProcess.display=true;
    let cliCommand="qpm3-cli diff-build-download -tb "+this.diffDownloadProcess.softwareBuildSelected.softwareProductBuild+" -bb " +this.diffDownloadProcess.baseBuildSelected?.softwareProductBuild+' -dn "'+this.diffDownloadProcess.baseBuildSelected?.distroAvailable?.softwareDistro+'" -in all';
    this.cliCommandProcess.cliDiffDownloadCommand=cliCommand;
  }
  copyToClipboard(text: string){
    this.cliCommandProcess.isCopiedToClipboard=false;
   const cb=navigator.clipboard;
   cb.writeText(text).then(()=>{
    this.cliCommandProcess.isCopiedToClipboard=true;
   })
  }
  //#region end
  onDiffDownloadActionClick(softwareProductBuild: SoftwareProductRelease) {
    if(this.app.downloadQueue.downloadInProgress){
      this.app.showMessageBox("Download", "Download request failed. Currently another download in-progress", "Ok");
      return;
    }
    this.diffDownloadProcess.availableBaseBuilds = [];
    this.diffDownloadProcess.baseBuildSelected = undefined;
    this.diffDownloadProcess.loadingBaseBuilds = false;
    this.updateAvailableDeltaBuilds(softwareProductBuild)
    this.diffDownloadProcess.softwareBuildSelected = softwareProductBuild;
    this.diffDownloadProcess.displayBuild = true;
  }
  onDiffDownloadButtonClick(event){
    this.diffDownloadProcess.displayBuild = false;
    let downloadPath:string;
    downloadPath ="";
    let defaultDownloadPath:string;
    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      const { dialog } = window.require('electron').remote;
      response = this.distributionClient.getDefaultDownloadLocation();
      response.subscribe(
        (data: QPMResponse) => {
          defaultDownloadPath = data.getData();;
          var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",defaultPath :defaultDownloadPath,properties: ['openDirectory'] }); //Select Download Path
          if(!downloadPathList)
          {
            downloadPath = "";
          }
          else
          {
            downloadPath = downloadPathList[0];
          }
          this.app.diffDownloadSoftwareDistros(this.diffDownloadProcess.softwareBuildSelected, this.diffDownloadProcess.baseBuildSelected,this.diffDownloadProcess.baseBuildSelected.distroAvailable,downloadPath);
        });
    }
    else
    {
        this.app.diffDownloadSoftwareDistros(this.diffDownloadProcess.softwareBuildSelected, this.diffDownloadProcess.baseBuildSelected,this.diffDownloadProcess.baseBuildSelected.distroAvailable,"");
    }

  }
  onDownloadImageActionClick(softwareReleaseInfo:SoftwareProductRelease,imageBuild:ServiceTaskDistProductComposition)
  {
    if(this.app.downloadQueue.downloadInProgress){
      this.app.showMessageBox("Download", "Download request failed. Currently another download in-progress", "Ok");
      return;
    }
   
    let approvedDistrosForImageDownload=imageBuild.availableDistros.filter(distro=>(distro.binDdmStatus==0)||(distro.binDdmStatus==1 && distro.binDdmRecordSummary.jiraStatus=='2' && distro.binDdmRecordSummary.noticeFileUploadStatus==2));
    this.downloadImageProcess.distroTypes = approvedDistrosForImageDownload;
    if(approvedDistrosForImageDownload?.length==1){
      this.downloadImageProcess.distroSelected = approvedDistrosForImageDownload[0]; 
    }
    this.downloadImageProcess.softwareImageBuild = imageBuild.softwareImageBuild;
    this.downloadImageProcess.softwareBuildSelected = softwareReleaseInfo;
    this.downloadImageProcess.displayDistro = true;
  }
  onDownloadImageButtonClick(event)
  {
    this.downloadImageProcess.displayDistro = false;
    let downloadPath:string;
    downloadPath ="";
    let defaultDownloadPath:string;
    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      const { dialog } = window.require('electron').remote;
      response = this.distributionClient.getDefaultDownloadLocation();
      response.subscribe(
        (data: QPMResponse) => {
          defaultDownloadPath = data.getData();;
          var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",defaultPath :defaultDownloadPath,properties: ['openDirectory'] }); //Select Download Path
          if(!downloadPathList)
          {
            downloadPath = "";
          }
          else
          {
            downloadPath = downloadPathList[0];
          }
          this.app.downloadSoftwareImageBuild(this.downloadImageProcess.softwareBuildSelected,this.downloadImageProcess.softwareImageBuild, this.downloadImageProcess.distroSelected,downloadPath);
          
        });
    }
    else
    {
    
        this.app.downloadSoftwareImageBuild(this.downloadImageProcess.softwareBuildSelected,this.downloadImageProcess.softwareImageBuild, this.downloadImageProcess.distroSelected,downloadPath);
    }
  }
  onDiffDownloadImageActionClick(product: ServiceTaskDistProduct,image:ServiceTaskDistProductComposition)
  {
    if(this.app.downloadQueue.downloadInProgress){
      this.app.showMessageBox("Download", "Download request failed. Currently another download in-progress", "Ok");
      return;
    }
    this.diffDownloadImageProcess.baseSoftwareImageBuild = image.softwareImageBuild;
    this.diffDownloadImageProcess.softwareBuildSelected = product.releaseInfo;
    this.updateAvailableDeltaImageBuilds(product,image);
    this.diffDownloadImageProcess.displayBuild = true;
  }
  onDiffDownloadImageButtonClick(event)
  {
    this.diffDownloadImageProcess.displayBuild = false;
    let downloadPath:string;
    downloadPath ="";
    let defaultDownloadPath:string;
    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      const { dialog } = window.require('electron').remote;
      response = this.distributionClient.getDefaultDownloadLocation();
      response.subscribe(
        (data: QPMResponse) => {
          defaultDownloadPath = data.getData();;
          var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",defaultPath :defaultDownloadPath,properties: ['openDirectory'] }); //Select Download Path
          if(!downloadPathList)
          {
            downloadPath = "";
          }
          else
          {
            downloadPath = downloadPathList[0];
          }
          this.app.diffDownloadSoftwareImageBuild(this.diffDownloadImageProcess.softwareBuildSelected, this.diffDownloadImageProcess.baseBuildSelected,this.diffDownloadImageProcess.baseSoftwareImageBuild,this.diffDownloadImageProcess.baseBuildSelected.distroAvailable,downloadPath);
        });
    }
    else
    {
        this.app.diffDownloadSoftwareImageBuild(this.diffDownloadImageProcess.softwareBuildSelected, this.diffDownloadImageProcess.baseBuildSelected,this.diffDownloadImageProcess.baseSoftwareImageBuild,this.diffDownloadImageProcess.baseBuildSelected.distroAvailable,"");
    }
  }
  loadServiceTaskDetails() {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details");

    if(this.softwareCatalogClient === null || this.app.sharedData.service.common.selectedServiceTaskID === undefined){
      return;
    }

    let response: Observable<QPMResponse>;

    // Get service task details
    response = this.softwareCatalogClient.getServiceTaskDetails(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Response : " +JSON.stringify(data));
        } 
        if(data.getCode() === 401){
          this.router.navigate(['/main/accessdenied']);
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.stMain.serviceTasksDetails = obj as ServiceTaskInfo;
            this.stMain.loadAdditionalInfo();
            this.getAvailableDistros(this.stMain.serviceTasksDetails?.softwareProduct);
            this.stMain.getAvailableBaseBuilds();
            this.loadServiceTaskBuilds();
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  loadServiceTaskBuilds() {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products");
    this.serviceTaskDistProducts = [];
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDistProducts(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Response : " +JSON.stringify(data));
      } 
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          let products = obj as ServiceTaskDistProduct[];
          products.reverse();
          products.forEach((product)=>{
              this.serviceTaskDistProducts.push(product);
          });
          this.serviceTaskDistProducts.forEach((product) => {
            if(product.errorMessage === "" && product.productNewBuildID !== null) 
            {           
              product.compositionLoaded = false;
              product.crList = new ServiceTaskDistProductCRs();
              product.releaseInfo = undefined;
              product.crList.buildCRs = [];
              product.crList.consolidatedCRs = [];
              product.crList.buildGerrits = [];
              product.consolidatedCRList = [];
              product.composition = [];
              product.impactedFiles = [];
              product.shippedDistroList = [];
              product.availableDistroList =[];
              product.loadingConsolidatedCRs = false;
              product.loadingComposition = false;
              product.loadingShippedDistros = false;
              product.loadingImpactedFiles = false;
              product.showImpactedFiles = false;
              product.loadingProductReleaseInfo = false;
              this.loadServiceTaskBuildDetails(product, true);

            }

          });
          if(this.serviceTaskDistProducts?.length >= 1){
            if(this.serviceTaskDistProducts[0].errorMessage === "" && this.serviceTaskDistProducts[0].productNewBuildID !== null) {
              let software = new SoftwareProductRelease();
              software.softwareProductBuild = this.serviceTaskDistProducts[0].productNewBuildID;
              this.getDistrosShipStatus(software, this.serviceTaskDistProducts[0]);
              this.getAvailableDistros(this.stMain.serviceTasksDetails?.softwareProduct,this.serviceTaskDistProducts[0]);
              this.loadProductReleaseInfo(this.serviceTaskDistProducts[0], true);
              this.loadProductConsolidatedCRs(this.serviceTaskDistProducts[0], true);
              this.loadProductComposition(this.serviceTaskDistProducts[0], true,0);
              this.loadProductImpactedFiles(this.serviceTaskDistProducts[0], true);
            }
          }
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Products - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

      }
    });
  }
  
  loadServiceTaskBuildDetails(product: ServiceTaskDistProduct, refresh: boolean) {
    if(!refresh && product.detail !== undefined){
      return;
    }
    QLogger.LogInfo(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details");
    let response : Observable<QPMResponse>;
    product.loadingDetails = true;
    response = this.softwareCatalogClient.getServiceTaskDistProductDetails(product.productNewBuildID);
    response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Response : " +JSON.stringify(data));
      } 
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          product.detail = obj as ServiceTaskDistProductDetail;
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Failed");
        QLogger.LogError(this.logSrc, "Get Tiberium Software Product "+product.productNewBuildID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

      }
      product.loadingDetails = false;
    });
  }
  loadProductConsolidatedCRs(product: ServiceTaskDistProduct, refresh: boolean){
    if(!refresh && product.crList.buildCRs?.length !== 0 && product.crList.consolidatedCRs?.length !== 0){
      return;
    }

    product.loadingConsolidatedCRs = true;
    QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Consolidated CRs");
    let response : Observable<QPMResponse>;

    // Get service task details
    response = this.softwareCatalogClient.getSoftwareProductBuildConsolidatedCR(product.productNewBuildID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Consolidated CRs - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Consolidated CRs - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            product.crList = new ServiceTaskDistProductCRs();
            product.crList.buildCRs = obj.buildCRs;
            product.crList.consolidatedCRs = obj.consolidatedCRs;
            product.crList.buildGerrits = obj.buildGerrits;
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Consolidated CRs - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Consolidated CRs - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        product.loadingConsolidatedCRs = false;
    });
  }
  loadProductComposition(product: ServiceTaskDistProduct, refresh: boolean,index:number){
    if(!refresh && product.composition?.length !== 0){
      return;
    }
    product.loadingComposition = true;
    QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition");
    let response : Observable<QPMResponse>;

    // Get service task details
    response = this.softwareCatalogClient.getSoftwareProductBuildComposition(product.productNewBuildID, false);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            product.composition = obj;
            this.loadPreviousComposition(product,index);
            this.getAvailableDeltaImageBuilds(product);
            this.getDistroShipStausForImage(product);
            
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Composition - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        product.loadingComposition = false;
    });
  }

  
  loadPreviousComposition(product: ServiceTaskDistProduct,index:number){
    if(this.serviceTaskDistProducts.length==0){
      return;
    }
     var previousProductNewBuildID='';
    if(this.serviceTaskDistProducts.length==1 || index+1==this.serviceTaskDistProducts.length ){
      previousProductNewBuildID=product.productBaseBuildID; //taking ProductBaseBuild Id
    }else{
      previousProductNewBuildID=this.serviceTaskDistProducts[index+1].productNewBuildID;  //taking Previous ProductBuild Id
    }
    product.loadingComposition = true;
    QLogger.LogInfo(this.logSrc, "Get Service Task Product "+previousProductNewBuildID+" Composition");
    let response : Observable<QPMResponse>;

  // Get service task details
  response = this.softwareCatalogClient.getSoftwareProductBuildComposition(previousProductNewBuildID, false);
  response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task Product "+previousProductNewBuildID+" Composition - Response : " +JSON.stringify(data));
      } 
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task Product "+previousProductNewBuildID+" Composition - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
          this.previousProductComposition=obj;
          product.previousCompositionSet = new Set(this.previousProductComposition.map((image)=> image.softwareImageBuild));
          product.composition.forEach(compo=>{
            compo.isBuildChange=false;
            if(product.previousCompositionSet.has(compo.softwareImageBuild)==false){
              compo.isBuildChange=true;

            }
          })

        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task Product "+previousProductNewBuildID+" Composition - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task Product "+previousProductNewBuildID+" Composition - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
      }
      product.loadingComposition = false;
  });


}

 loadProductImpactedFiles(product: ServiceTaskDistProduct, refresh: boolean){
      if(!refresh && product.impactedFiles?.length !== 0){
        return;
      }    
      product.loadingImpactedFiles = true;
      QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Impacted Files");
      let response : Observable<QPMResponse>;

      response = this.softwareCatalogClient.getSoftwareProductBuildImpactedFiles(product.productNewBuildID,false);
      response.subscribe(
        (data:QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Impacted Files - Response : " +JSON.stringify(data));
          } 
          if(data.isSuccess()){
            QLogger.LogInfo(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Impacted Files - Success");
            let obj = JSON.parse(data.getData());
            if(obj !== undefined || obj !== null){
              product.impactedFiles = obj.changeRequestFiles as ServiceTaskDistImpactedFiles[];
            }
          }
          else{
            QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Impacted Files - Failed");
            QLogger.LogError(this.logSrc, "Get Service Task Product "+product.productNewBuildID+" Impacted Files - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          }
          product.loadingImpactedFiles = false;
      });
  }

  loadProductReleaseInfo(product: ServiceTaskDistProduct, refresh: boolean){    
    if(!refresh && product.releaseInfo !== undefined){
      return;
    } 
    product.loadingProductReleaseInfo = true;
    QLogger.LogInfo(this.logSrc, "Get Service Task Product Build "+product.productNewBuildID+" Info");
    let response : Observable<QPMResponse>;

    response = this.softwareCatalogClient.getSoftwareProductBuildInfo(product.productNewBuildID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product Build "+product.productNewBuildID+" Info - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product Build "+product.productNewBuildID+" Info - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            product.releaseInfo = obj as SoftwareProductRelease;
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Service Task Product Build "+product.productNewBuildID+" Info - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task Product Build "+product.productNewBuildID+" Info - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        product.loadingProductReleaseInfo = false;
    });
  }

  shipSoftwareProductBuild(softwareProductBuild: string, distros: SoftwareProductDistro[]) {
    QLogger.LogInfo(this.logSrc, "Ship Software :" + softwareProductBuild);
    let response: Observable<QPMResponse>;
    let distroList:SoftwareProductDistroInfo[] = [];
    let req:DistributionRequest;
    let requestedPlatform : Number;
    if(this.app.sharedData.appInfo.isElectronMode)
    requestedPlatform = 0;
    else
    requestedPlatform = 1;
    if (this.distributionClient === null) {
      return
    }
    let uniqueDistros = [...new Set(distros)];
    uniqueDistros.forEach(function (distro) 
    {
      let info  = new SoftwareProductDistroInfo();  
      info.distroName =  distro.softwareDistro;
      info.distroId   = Number(distro.softwareDistroId);
      info.uploadComplete = 0;
	  info.softwareDistroAlternateId = distro.softwareDistroAlternateId;
      distroList.push(info);
    });

    req = {
      serviceTaskId : this.stMain.serviceTasksDetails.serviceTaskId,
      softwareProductBuild : softwareProductBuild,
      softwareDistroList : distroList,
      requestedPlatform : requestedPlatform
    };
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Ship Software Request: " + JSON.stringify(req));
    }
    response = this.distributionClient.shipSoftwareProduct(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Ship Software Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Ship Software Success:" + softwareProductBuild);
          this.app.queueShipStatusRequest(softwareProductBuild,distroList);
        }
        else{
          QLogger.LogError(this.logSrc, "Ship Software Failed:" + softwareProductBuild);
          QLogger.LogError(this.logSrc, "Ship Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          if(this.app.sharedData.userInfo.info.internal)
          {
            let obj :shipStatusErrorResponse;
            try{
                obj = JSON.parse(data.getErrorDetail());
                let msg = obj.error.errorMessage;
                if(obj.error.exceptionList?.length > 0){
                  msg = msg + ". " + obj.error.exceptionList?.map(x => x.errorMessage).join(". ");
                }
                this.app.displayShipStatus(softwareProductBuild,"Failure", msg);
            }
            catch (e) 
            {
              QLogger.LogError(this.logSrc,"Cannot Parse error message from logs");
              this.app.displayShipStatus(softwareProductBuild,"Failure",data.getError());
            }
          }
          else
          this.app.displayShipStatus(softwareProductBuild,"Failure",null);
        }
      }
    );
    this.shipProcess.displayDistro = false;
    this.app.showMessageBox("Ship Request", "Ship Request for " + softwareProductBuild + " has been placed", "Ok");
  }
  
  downloadSoftwareDistros(softwareProductBuild: SoftwareProductRelease, distros: SoftwareProductDistro[],downloadLocation:string) {
    QLogger.LogInfo(this.logSrc, "Download Software : " +softwareProductBuild.softwareProductBuild);// + " Distro : " + "AMSS Standard OEM");
    this.app.downloadQueue.softwareQueue=[];
    distros.forEach((distro) => {
      this.app.queueDownload(softwareProductBuild, distro,downloadLocation);
    });
    this.downloadProcess.distroSelected = [];
    this.app.logTerminalMessage("Download for " + softwareProductBuild.softwareProductBuild + " Queued " +"at " + this.utils.getCurrentDateTime());
    //this.app.showMessageBox("Download", "Download for " + softwareProductBuild.softwareProductBuild + "Queued", "Ok");
  }

  getAvailableDistros(softwareProduct: string, product?: ServiceTaskDistProduct) {
    QLogger.LogInfo(this.logSrc, "Get Available Software Product "+softwareProduct+" Distros");
    this.productDistros = [];
    //this.downloadProcess.distroTypes = SoftwareData.availableDistros;
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareProductDistros(softwareProduct);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.productDistros = obj.distros;
            if(product !== undefined){
              let software = new SoftwareProductRelease();
              software.softwareProductBuild = product.productNewBuildID;
              this.getDistrosShipStatus(software,product)
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Failed");
          QLogger.LogError(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }
  
  getDiffDistrosShipStatus(softwareProductBaseBuild: SoftwareProductRelease,softwareProductTargetBuild: SoftwareProductRelease) {
    QLogger.LogInfo(this.logSrc, "Get Available Software Product Distros for comparison between  "+softwareProductTargetBuild.softwareProductBuild+ " and "+ softwareProductBaseBuild.softwareProductBuild );
    let response : Observable<QPMResponse>;
    this.diffShipProcess.distroTypes = [];    
    let distroList:SoftwareProductDistroInfo[]=[];
    let availableBaseDistroList:SoftwareProductDistro[] =[];
    let availableTargetDistroList:SoftwareProductDistro[] =[];
    let availableDistroList:SoftwareProductDistro[]=[];
    this.productDistros?.forEach(function (distro) 
    {
      let distroInfo  = new SoftwareProductDistroInfo() ;
      distroInfo.distroName =  distro.softwareDistro;
      distroInfo.distroId   = Number(distro.softwareDistroId);
      distroInfo.uploadComplete = 0;
	  distroInfo.softwareDistroAlternateId = distro.softwareDistroAlternateId;
      distroList.push(distroInfo);
    });
    let request: DiffDistributionRequest;
    this.diffShipProcess.loadingDistros = true;
    response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductBaseBuild.softwareProductBuild);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get Software Product Build "+softwareProductBaseBuild.softwareProductBuild+" Distro Status - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null)
          {
            availableBaseDistroList = obj.distros;
            response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductTargetBuild.softwareProductBuild);
            response.subscribe(  (data:QPMResponse) => { 
                if(data.isSuccess()){
                  let obj = JSON.parse(data.getData());
                  if(obj !== undefined || obj !== null)
                  {
                    availableTargetDistroList = obj.distros;
                    availableDistroList = availableTargetDistroList?.filter( a => true === availableBaseDistroList.some( b => a.softwareDistro === b.softwareDistro ));
                    availableDistroList?.forEach(function (distro) 
                    {
                      let distroInfo  = new SoftwareProductDistroInfo() ;
                      distroInfo.distroName =  distro.softwareDistro;
                      distroInfo.distroId   = Number(distro.softwareDistroId);
                      distroInfo.uploadComplete = 0;
                      distroInfo.softwareDistroAlternateId =distro.softwareDistroAlternateId;
                      distroList.push(distroInfo);
                    });    
                    
                    request = {
                      baseSoftwareProductBuild : softwareProductBaseBuild.softwareProductBuild,
                      targetSoftwareProductBuild: softwareProductTargetBuild.softwareProductBuild,
                      softwareDistroList : distroList
                    };
                    if(this.app.sharedData.appInfo.logRequest){
                      QLogger.LogInfo(this.logSrc, "Request is "+JSON.stringify(request)); 
                    }
                    
                    response = this.webClient.getDiffDistrosShipStatus(request);
                    response.subscribe(
                      (data:QPMResponse) => { 
                        if(this.app.sharedData.appInfo.logResponse){
                          QLogger.LogInfo(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status - Response : " +JSON.stringify(data));
                        }
                        if(data.isSuccess()){
                          QLogger.LogInfo(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status- Success");
                          let shipStatusResp:diffShipDownloadResponse;
                          let distrosStatus: Map<string, SoftwareProductDistroInfo> = new Map<string, SoftwareProductDistroInfo>();
                          shipStatusResp = JSON.parse(data.getData());
                          if(shipStatusResp !== undefined || shipStatusResp !== null){
                            let distros: SoftwareProductDistroInfo[] = shipStatusResp.softwareDistroList;
                            this.diffShipProcess.distroTypes = [];
                            distros.forEach((distro) => {
                              distrosStatus.set(distro.distroName, distro);
                            });
                            availableDistroList?.forEach((distro) => {
                              let newDistro: SoftwareProductDistro = new SoftwareProductDistro();
                              newDistro.softwareDistro = distro.softwareDistro;
                              newDistro.distroType = distro.distroType;
                              newDistro.softwareDistroAlternateId = distro.softwareDistroAlternateId;
                              newDistro.softwareDistroId = distro.softwareDistroId;
                              newDistro.stream = distro.stream;
                              newDistro.softwareDistroFolderName = distro.softwareDistroFolderName;
                              if(distrosStatus.has(distro.softwareDistro)){
                                newDistro.uploadComplete = distrosStatus.get(distro.softwareDistro).uploadComplete;
                                newDistro.disabled = newDistro.uploadComplete === 1 || newDistro.uploadComplete === 0;
                                if(newDistro.uploadComplete == 4 || newDistro.uploadComplete == 3){
                
                                  this.diffShipProcess.distroTypes.push(newDistro);
                                }
                              }
                              else{
                                this.diffShipProcess.distroTypes.push(newDistro);
                              }
                            });
                
                          }
                        }
                        else{
                          QLogger.LogError(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status - Failed");
                          QLogger.LogError(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
                        }
                        this.diffShipProcess.loadingDistros = false;
                      });
                  }
                }
                else
                this.diffShipProcess.loadingDistros = false;
            });
          }
        }
        else
        this.diffShipProcess.loadingDistros = false;
      });
  }

  emailReleaseInfo(product: ServiceTaskDistProduct){
    this.app.resetEmailProcess();
    var newline = "<br/>"
    var subject = "";

    var externalID = (this.stMain.serviceTasksDetails.requestSource.toLowerCase() === "salesforce" 
                      || this.stMain.serviceTasksDetails.requestSource.toLowerCase() === "planner")
                      ?  this.stMain.serviceTasksDetails.requestAppId : undefined;

    if(externalID === undefined){
      subject = "Release " + product.releaseInfo?.tag;
    }
    else{
      subject = this.stMain.serviceTasksDetails.requestSource + ": " + this.stMain.serviceTasksDetails.requestAppId
                 + " Release " + product.releaseInfo?.tag;
    }
    let email: Email = new Email();
    email.fromEmailId = this.app.sharedData.userInfo.username;
    email.toEmailIds = [];
    email.toEmailIds.push(this.app.sharedData.userInfo.username);
    email.subject = subject;
    var bodyTop = "Hi";
    var note = "Following Factory Master ST  has been shipped.";
    var bodyCenter =  /*this.formatEmailBodyCrs(product) + newline + newline + */"Build ID: " + product.releaseInfo?.softwareProductBuild + newline + " Release ID: " + product.releaseInfo?.tag;
    
    email.body = bodyCenter

    this.app.startEmailProcessRelease(bodyTop, email, note, product.crList.buildCRs, externalID);
    //window.location.href = "mailto:" + Array.from(mailTo).join(";") + "?subject="+subject+"&body="+ bodyTop + bodyCenter + newline + bodyBottom;
  }

  formatEmailBodyCrs(product: ServiceTaskDistProduct):String{
    let openCRs: boolean = false;
    let bodyCenter : StringBuilder = new StringBuilder();
    bodyCenter.Append("<table border='1'>");
    bodyCenter.Append("<tr>");
    bodyCenter.Append("<th>CR Number</th>");
    bodyCenter.Append("</tr>");

    product.crList.buildCRs.forEach((cr)=>{
      bodyCenter.Append("<tr>");
      bodyCenter.Append("<td>" + cr + "</td>");
      bodyCenter.Append("</tr>");
    });
    bodyCenter.Append("</table>");
    return bodyCenter.ToString();
  }
  //#endregion

  retryFailedUploads(softwareDistroStatus:SoftwareProductDistro)
  {
    QLogger.LogInfo(this.logSrc, "Retrying to Ship Software :" + this.downloadProcess.softwareProductBuild +" for distro "+softwareDistroStatus.softwareDistro);
    let softwareDistroInfoList:SoftwareProductDistro[];
    softwareDistroInfoList =[];
    softwareDistroInfoList.push(softwareDistroStatus);
    this.downloadProcess.displayDistro = false;
    this.downloadProcess.shipFailed = false;
    softwareDistroStatus.uploadComplete =0;
    this.shipSoftwareProductBuild(this.downloadProcess.softwareProductBuild,softwareDistroInfoList); 
  }

  

  updateAvailableDeltaBuilds(softwareProductBuild: SoftwareProductRelease)
  {
    QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild.softwareProductBuild );
    let response: Observable<QPMResponse>;
    this.diffDownloadProcess.loadingBaseBuilds = true;
    this.diffDownloadProcess.availableBaseBuilds=[];
    this.diffDownloadProcess.baseBuildSelected = undefined;
    let availableBuilds = new BehaviorSubject<SoftwareProductRelease[]>(this.diffDownloadProcess.availableBaseBuilds);
    this.diffDownloadProcess.shipFailed = false;
    let entitledDistroList:SoftwareProductDistro[] =[];
    let  baseBuildShipDate     = new Date(softwareProductBuild.shipDate);
    response = this.webClient.getSoftwareProductDiffBuilds(softwareProductBuild.softwareProductBuild);
    this.diffDownloadProcess.updateAvailableDistrosSubscription?.unsubscribe();
    this.diffDownloadProcess.updateDiffBuildSubscription?.unsubscribe();
    this.diffDownloadProcess.updateDiffBuildSubscription= response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild.softwareProductBuild + " - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild.softwareProductBuild + " - Success");
          let availabeDiffBuildResponse:AvaliableDiffBuildsResponse;
          availabeDiffBuildResponse = JSON.parse(data.getData());
          response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductBuild.softwareProductBuild);
          this.diffDownloadProcess.updateAvailableDistrosSubscription = response.subscribe(
            (data:QPMResponse) => { 
              if(this.app.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc,  "Get Software Product Build "+softwareProductBuild.softwareProductBuild +" Distro Status - Response : " +JSON.stringify(data));
              }
              if(data.isSuccess()){
                let obj = JSON.parse(data.getData());
                if(obj !== undefined || obj !== null)
                {
                  entitledDistroList = obj.distros;
                  if (availabeDiffBuildResponse !== undefined || availabeDiffBuildResponse !== null) {
                    this.availableDiffBuilds = availabeDiffBuildResponse.diffTargetBuildAndDistroList
                    this.availableDiffBuilds?.forEach((availableDiffBuild)=> {
                      let availableDistros :SoftwareProductDistro[];
                      availableDistros =this.app.getAvailableDistrosForDownload(availableDiffBuild.targetSoftwareProductBuild,this.availableDiffBuilds,entitledDistroList);
                      this.stMain.softwareProductReleases?.forEach((availableBuild) => {
                             if(availableBuild.softwareProductBuild == availableDiffBuild.targetSoftwareProductBuild)
                             {
                                let  targetBuildShipDate     = new Date(availableBuild.shipDate);
                                if(baseBuildShipDate.getTime() > targetBuildShipDate.getTime()) 
                                {
                                  availableDistros.forEach((availableDistro)=>
                                  {
                                    let downloadableDiffBuild: SoftwareProductRelease = new SoftwareProductRelease();
                                    downloadableDiffBuild.softwareProduct = availableBuild.softwareProduct;
                                    downloadableDiffBuild.softwareProductBuild = availableBuild.softwareProductBuild;
                                    downloadableDiffBuild.shipDate = availableBuild.shipDate;
                                    downloadableDiffBuild.tag = availableBuild.tag;
                                    downloadableDiffBuild.spfTag = availableBuild.spfTag
                                    downloadableDiffBuild.branch = availableBuild.branch;  
                                    downloadableDiffBuild.distroAvailable = availableDistro;
                                    if(availableDistro.status=="Failed")
                                      this.diffDownloadProcess.shipFailed = true;
                                    this.diffDownloadProcess.availableBaseBuilds = [...this.diffDownloadProcess.availableBaseBuilds,downloadableDiffBuild];
                                  });
                                }
                             }
                        });
                    });

                    let availableBaseBuildsWithApprovedDistro=this.diffDownloadProcess.availableBaseBuilds.filter(build=>((build.distroAvailable.binDdmStatus==0)||(build.distroAvailable.binDdmStatus==1 && build.distroAvailable.binDdmRecordSummary.jiraStatus=='2' && build.distroAvailable.binDdmRecordSummary.noticeFileUploadStatus==2)))
                    this.diffDownloadProcess.availableBaseBuilds=availableBaseBuildsWithApprovedDistro;
        
                    if(this.diffDownloadProcess.availableBaseBuilds?.length === 1 && this.diffDownloadProcess.availableBaseBuilds[0].distroAvailable.uploadComplete===2){
                        this.diffDownloadProcess.baseBuildSelected = this.diffDownloadProcess.availableBaseBuilds[0]; 
                    }
                  }
                  availableBuilds.next( this.diffDownloadProcess.availableBaseBuilds);
                  this.diffDownloadProcess.loadingBaseBuilds = false;
                }
              }
        
            });
            
      }
      else{

        QLogger.LogError(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild.softwareProductBuild + "  - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }

  });
 }

 updateAvailableDeltaImageBuilds(product: ServiceTaskDistProduct,imageBuild:ServiceTaskDistProductComposition)
 {
   QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + product.releaseInfo.softwareProductBuild +" and software image "+imageBuild.softwareImageBuild);
   this.diffDownloadImageProcess.availableBaseBuilds=[];
   let availableBuilds = new BehaviorSubject<SoftwareProductRelease[]>(this.diffDownloadImageProcess.availableBaseBuilds);
   this.diffDownloadImageProcess.loadingBaseBuilds = true;

   
   let approvedAvailablebUilds=imageBuild.availableDiffBuilds.filter(build=>((build.distroAvailable.binDdmStatus==0)||(build.distroAvailable.binDdmStatus==1 && build.distroAvailable.binDdmRecordSummary.jiraStatus=='2' && build.distroAvailable.binDdmRecordSummary.noticeFileUploadStatus==2)));
   this.diffDownloadImageProcess.availableBaseBuilds=approvedAvailablebUilds;
   availableBuilds.next(this.diffDownloadImageProcess.availableBaseBuilds);
   if(this.diffDownloadImageProcess.availableBaseBuilds?.length==1){
    this.diffDownloadImageProcess.baseBuildSelected=this.diffDownloadImageProcess.availableBaseBuilds[0];
   }
   this.diffDownloadImageProcess.loadingBaseBuilds = false;   
}
getAvailableDistrosForImage(softwareImageBuild:string,availableDiffImageDistros:SoftwareImageDistroInfo[],entitledDistroList:SoftwareProductDistro[]):SoftwareImageDistro[]
{
  let availableDistroMap: Map<string, SoftwareImageDistroInfo> = new Map<string, SoftwareImageDistroInfo>();
  let avaiableSoftwareDistro:SoftwareImageDistro[];
  avaiableSoftwareDistro =[];
  availableDiffImageDistros.forEach((distro)=>{
          availableDistroMap.set(distro.distroName,distro);
  });
  entitledDistroList.forEach((distro) => {
    let newDistro: SoftwareImageDistro = new SoftwareImageDistro();
    newDistro.distro = new SoftwareProductDistro();
    newDistro.distro.softwareDistro = distro.softwareDistro;
    newDistro.distro.distroType = distro.distroType;
    newDistro.distro.softwareDistroAlternateId = distro.softwareDistroAlternateId;
    newDistro.distro.softwareDistroId = distro.softwareDistroId;
    newDistro.distro.stream = distro.stream;
    newDistro.distro.softwareDistroFolderName = distro.softwareDistroFolderName;
    newDistro.distro.binDdmStatus=distro.binDdmStatus;//required for BIN_DDM Approved distro check to  Download diff Image
    newDistro.distro.binDdmRecordSummary=distro.binDdmRecordSummary;//required for BIN_DDM Approved distro check to  Download diff Image
    if(availableDistroMap.has(distro.softwareDistro)){
      let distroInfo:SoftwareImageDistroInfo = availableDistroMap.get(distro.softwareDistro);
      distroInfo.diffImageBuildDownloadResponses.forEach(build=>{

             if(build.baseImageBuild==softwareImageBuild || build.targetImageBuild==softwareImageBuild)
             {
              newDistro.distro.uploadComplete = build.uploadComplete;
              if(build.baseImageBuild==softwareImageBuild)
              newDistro.targetImageBuildId = build.targetImageBuild;
              else
              newDistro.targetImageBuildId = build.baseImageBuild;
              if(newDistro.distro.uploadComplete != 4 ){
                if(newDistro.distro.uploadComplete==0) newDistro.distro.status ="Queued";
                if(newDistro.distro.uploadComplete==1) newDistro.distro.status ="In Progress";
                if(newDistro.distro.uploadComplete==2) newDistro.distro.status ="Success";
                if(newDistro.distro.uploadComplete==3)
                {
                  newDistro.distro.status ="Failed";
                  
                }
                avaiableSoftwareDistro.push(newDistro);
              }
             }
      });
    }
  });     
  return avaiableSoftwareDistro;
}
getAvailableDeltaImageBuilds(product?: ServiceTaskDistProduct)
{
  let response: Observable<QPMResponse>;
  let  baseBuildShipDate     = undefined;
  if(product.releaseInfo==undefined)
  {
    
    response = this.softwareCatalogClient.getSoftwareProductBuildInfo(product.productNewBuildID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product Build "+product.productNewBuildID+" Info - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task Product Build "+product.productNewBuildID+" Info - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            
            product.releaseInfo = obj as SoftwareProductRelease;
            this.updateAvailableDeltasForImage(product)
          }
        }
      });
   }
   else
     this.updateAvailableDeltasForImage(product);
     product.compositionLoaded = true; 
   }
   updateAvailableDeltasForImage(product?: ServiceTaskDistProduct)
   {
      let response: Observable<QPMResponse>;
      let baseBuildShipDate     = new Date(product.releaseInfo?.shipDate);
      response = this.webClient.getSoftwareImageDiffBuilds(product.productNewBuildID);
      response.subscribe(
        (data: QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + product.productNewBuildID + " - Response : " +JSON.stringify(data));
          }
          if (data.isSuccess()) {
          
            QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + product.productNewBuildID + " - Success");
            let availabeDiffBuildResponse:AvaliableDiffImagesResponse;
            availabeDiffBuildResponse = JSON.parse(data.getData());
            if (availabeDiffBuildResponse !== undefined || availabeDiffBuildResponse !== null) {
              this.availableDiffImageBuilds = availabeDiffBuildResponse.diffTargetBuildAndDistroListAndImageList;
              product.composition?.forEach((image) =>{
                  image.isDiffAvailable = false;
                  image.availableDiffBuilds=[];
                  this.availableDiffImageBuilds?.forEach((availableDiffBuild)=> {
                   let availableDistros :SoftwareImageDistro[];
    
                   availableDistros =this.getAvailableDistrosForImage(image.softwareImageBuild,availableDiffBuild.distroList,product.availableDistroList);
                   this.stMain.softwareProductReleases?.forEach((availableBuild) => {
                    if(availableBuild.softwareProductBuild == availableDiffBuild.targetSoftwareProductBuild)
                    {
                      let  targetBuildShipDate     = new Date(availableBuild.shipDate);
                      if(baseBuildShipDate.getTime() > targetBuildShipDate.getTime()) 
                      {
                          availableDistros.forEach((availableDistro)=>
                          {
                            let downloadableDiffBuild: SoftwareProductRelease = new SoftwareProductRelease();
                            downloadableDiffBuild.softwareProduct = availableBuild.softwareProduct;
                            downloadableDiffBuild.softwareProductBuild = availableBuild.softwareProductBuild;
                            downloadableDiffBuild.shipDate = availableBuild.shipDate;
                            downloadableDiffBuild.tag = availableBuild.tag;
                            downloadableDiffBuild.spfTag = availableBuild.spfTag
                            downloadableDiffBuild.branch = availableBuild.branch;  
                            downloadableDiffBuild.distroAvailable = availableDistro.distro;
                            downloadableDiffBuild.imageBuildId    = availableDistro.targetImageBuildId;
                            image.availableDiffBuilds.push(downloadableDiffBuild);
                          });
                      }
                    }
                  });
                   if(image.availableDiffBuilds?.length>0)
                   {
                     image.isDiffAvailable = true;
                   }
                });
              });
            }
          }
        
          });
    }
    getDistroShipStausForImage(product?: ServiceTaskDistProduct)
    {
      QLogger.LogInfo(this.logSrc, "Get Available Software Product "+product.productNewBuildID+" Image Distros");
      let response: Observable<QPMResponse>;  
      let distroList:SoftwareProductDistroInfo[]=[];
      let request: DistributionRequest;
      product.isImageDownloadAvailable=false;
      let availableDistroList:SoftwareProductDistro[] =[];
      let distrosStatus: Map<string, SoftwareProductDistro> = new Map<string, SoftwareProductDistro>();
      response = this.softwareCatalogClient.getSoftwareProductBuildDistros(product.productNewBuildID);
      response.subscribe(
          (data:QPMResponse) => { 
            if(this.app.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc,  "Get Software Product Build "+product.productNewBuildID+" Available Distro  - Response : " +JSON.stringify(data));
            }
            if(data.isSuccess()){
              let obj = JSON.parse(data.getData());
              if(obj !== undefined || obj !== null)
              {
                availableDistroList = obj.distros;
                availableDistroList?.forEach(function (distro) 
                {
                  let distroInfo  = new SoftwareProductDistroInfo() ;
                  distroInfo.distroName =  distro.softwareDistro;
                  distroInfo.distroId   = Number(distro.softwareDistroId);
                  distroInfo.uploadComplete = 0;
                  distroInfo.softwareDistroAlternateId = distro.softwareDistroAlternateId;
                  distroList.push(distroInfo);
                  distrosStatus.set(distro.softwareDistro,distro);
                });
                product.composition.forEach((image)=>{
                    image.availableDistros=[];
                });
                request = {
                  softwareProductBuild : product.productNewBuildID,
                  softwareDistroList : distroList
                };
                if(this.app.sharedData.appInfo.logRequest){
                  QLogger.LogInfo(this.logSrc, "Image Ship Status Request is "+JSON.stringify(request)); 
                }

                response = this.webClient.getDistroShipStausForImage(request);
                response.subscribe(
                  (data: QPMResponse) => {
                    if(this.app.sharedData.appInfo.logResponse){
                      QLogger.LogInfo(this.logSrc, "Image Ship Status for Software Product " + product.productNewBuildID + " - Response : " +JSON.stringify(data));
                    }
                    if(data.isSuccess())
                    {
                      QLogger.LogInfo(this.logSrc,  "Get Software Product "+product.productNewBuildID+" Distro Status- Success");
                      let shipStatusResp:imageShipDownloadResponse;
                    
                      shipStatusResp = JSON.parse(data.getData());

                      let imageDistrosStatus: Map<string, SoftwareProductDistro[]> = new Map<string, SoftwareProductDistro[]>();
                      shipStatusResp.imageDistroList?.forEach((distro) =>
                      {
                        let softwareDistro:SoftwareProductDistro;
                        softwareDistro = distrosStatus.get(distro.distroName);
                        distro.imageUploadStatusesList?.forEach((imageStatus)=>{
                          if(imageStatus.uploadComplete==2)
                          {
                            if(imageDistrosStatus.has(imageStatus.softwareImagebuild)){
                              imageDistrosStatus.get(imageStatus.softwareImagebuild).push(softwareDistro);
                            }
                            else{
                                let availableDistros:SoftwareProductDistro[]=[];
                                availableDistros.push(softwareDistro);
                                imageDistrosStatus.set(imageStatus.softwareImagebuild,availableDistros);
                            }
                          }
                        
                        });
                        product.composition?.forEach((image)=>{
                        image.availableDistros =[];
                          if(imageDistrosStatus.has(image.softwareImageBuild))
                          {
                              product.isImageDownloadAvailable = true;
                              image.availableDistros = imageDistrosStatus.get(image.softwareImageBuild);
                          }
                        });
                      });
                    
                    }
                   })
                }
              }
          });
    }
    getDistrosShipStatus(softwareProductBuild: SoftwareProductRelease, product?: ServiceTaskDistProduct)
    {
      QLogger.LogInfo(this.logSrc, "Get Distro Ship Status for Software Product "+softwareProductBuild.softwareProductBuild);
      if(product !== undefined){
        product.loadingShippedDistros = true;
        product.isShipComplete = false;
      }
      let isShipSuccess = false;
      this.shipProcess.loadingDistros = true;
      this.downloadProcess.loadingDistros = true;
      this.downloadProcess.shipFailed = false;
      let response : Observable<QPMResponse>;
      this.shipProcess.distroTypes = [];
      this.downloadProcess.distroTypes = [];
      this.downloadProcess.approvedDistroTypes=[];
      this.downloadProcess.distroTypesSelectItems =[];
      let distroList:SoftwareProductDistroInfo[]=[];
      let availableDistroList:SoftwareProductDistro[] =[];
      let request: DistributionRequest;
      response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductBuild.softwareProductBuild);
      response.subscribe(
        (data:QPMResponse) => { 
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc,  " Available Software Product Distros for build "+softwareProductBuild.softwareProductBuild+"- Response : " +JSON.stringify(data));
          }
          if(data.isSuccess()){
            let obj = JSON.parse(data.getData());
            if(obj !== undefined || obj !== null)
            {
              availableDistroList = obj.distros;
              if(product!=undefined){
                product.availableDistroList = availableDistroList;
                product.isShipFailed = false;
              }
              availableDistroList?.forEach(function (distro) 
              {
                let distroInfo  = new SoftwareProductDistroInfo() ;
                distroInfo.distroName =  distro.softwareDistro;
                distroInfo.distroId   = Number(distro.softwareDistroId);
                distroInfo.uploadComplete = 0;
                distroInfo.softwareDistroAlternateId = distro.softwareDistroAlternateId;
                distroList.push(distroInfo);
              });    
              request = {
                softwareProductBuild : softwareProductBuild.softwareProductBuild,
                softwareDistroList : distroList
              };
              if(this.app.sharedData.appInfo.logRequest){
                QLogger.LogInfo(this.logSrc, "Request is "+JSON.stringify(request)); 
              }
              response = this.webClient.getDistroShipStausForImage(request);
              response.subscribe(
                (data:QPMResponse) => { 
                  if(this.app.sharedData.appInfo.logResponse){
                    QLogger.LogInfo(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status - Response : " +JSON.stringify(data));
                  }
                  if(data.isSuccess()){
                    QLogger.LogInfo(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status- Success");
                    let shipStatusResp:imageShipDownloadResponse;
                    let distrosStatus: Map<string, SoftwareImageDistroInfo> = new Map<string, SoftwareImageDistroInfo>();
                    shipStatusResp = JSON.parse(data.getData());
                    if(shipStatusResp !== undefined || shipStatusResp !== null){
                      shipStatusResp.imageDistroList?.forEach((distro) =>
                          {
                            distrosStatus.set(distro.distroName, distro);
                          });
                          this.shipProcess.distroTypes = [];
                          this.downloadProcess.distroTypes = [];
                          availableDistroList.forEach((distro) => {
                          let newDistro: SoftwareProductDistro = new SoftwareProductDistro();
                          newDistro.imageUploadStatus=[];
                          newDistro.softwareDistro = distro.softwareDistro;
                          newDistro.distroType = distro.distroType;
                          newDistro.softwareDistroAlternateId = distro.softwareDistroAlternateId;
                          newDistro.softwareDistroId = distro.softwareDistroId;
                          newDistro.eccn=distro.eccn;
                          newDistro.binDdmStatus=distro?.binDdmStatus;
                          newDistro.binDdmRecordSummary=distro?.binDdmRecordSummary;
                          newDistro.stream = distro.stream;
                          newDistro.softwareDistroFolderName = distro.softwareDistroFolderName;
                          if(distrosStatus.has(distro.softwareDistro)){
                              let shippedDistroInfo = distrosStatus.get(distro.softwareDistro)
                              newDistro.uploadComplete = shippedDistroInfo.uploadComplete;
                              newDistro.shippedBy =  shippedDistroInfo.shipRequester;
                              newDistro.disabled = newDistro.uploadComplete === 1 || newDistro.uploadComplete === 0;
                              if(newDistro.uploadComplete !=4){
                                    if(newDistro.uploadComplete==1) newDistro.status ="In Progress";
                                    else if(newDistro.uploadComplete==0) newDistro.status ="Queued";
                                    else if(newDistro.uploadComplete==2) {
                                        newDistro.status ="Success";
                                        isShipSuccess = true;  
                                    }
                                    else if(newDistro.uploadComplete==5) { 
                                        newDistro.status ="Success*";
                                        isShipSuccess = true;
                                        let imageDistro =distrosStatus.get(distro.softwareDistro)
                                        newDistro.imageUploadStatus = imageDistro.imageUploadStatusesList;
                                    }    
                                    else if(newDistro.uploadComplete==3){
                                      newDistro.status ="Failed";
                                      this.downloadProcess.shipFailed = true;
                                    }
                                    this.downloadProcess.distroTypes.push(newDistro);
                                  }
                                  else
                                    this.shipProcess.distroTypes.push(newDistro);
                                    
                                }
                                else
                                  this.shipProcess.distroTypes.push(newDistro);
                                         
                              });
                              if(this.shipProcess.distroTypes?.length === 1){
                                this.shipProcess.distroSelected.push(this.shipProcess.distroTypes[0]);
                              }
                              if(this.downloadProcess.distroTypes?.length === 1 && this.downloadProcess.distroTypes[0].uploadComplete===2){
                                  this.downloadProcess.distroSelected.push(this.downloadProcess.distroTypes[0]);        
                              }
                              if(product!=undefined)
                              {
                                  product.shippedDistroList =[];
                                  product.shippedDistroList = this.downloadProcess.distroTypes;
                                  product.isShipFailed =  this.downloadProcess.shipFailed;
                                  product.isShipComplete = isShipSuccess;
                                  product.loadingShippedDistros = false;
                              }else{
                                let approvedDistros=this.downloadProcess.distroTypes.filter(distro=>((distro.binDdmStatus==0)||(distro.binDdmStatus==1 && distro.binDdmRecordSummary.jiraStatus=='2' && distro.binDdmRecordSummary.noticeFileUploadStatus==2)));
                                this.downloadProcess.approvedDistroTypes=[];
                                this.downloadProcess.approvedDistroTypes=approvedDistros;
                                if(this.downloadProcess.approvedDistroTypes?.length === 1){
                                  this.downloadProcess.distroSelected=[];
                                  this.downloadProcess.distroSelected.push(this.downloadProcess.distroTypes[0]);        
                                }
                                if(this.downloadProcess.approvedDistroTypes?.length === 0){
                                  this.downloadProcess.distroSelected=[];
                                } 
                              }
                    }
                  }
                  else{
                    QLogger.LogError(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status - Failed");
                    QLogger.LogError(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
                  }
                  this.shipProcess.loadingDistros = false;
                  this.downloadProcess.loadingDistros = false;
                  this.downloadProcess.softwareProductBuild = softwareProductBuild.softwareProductBuild;
            });
          }
          else{
            QLogger.LogError(this.logSrc,  "No distros available for  "+softwareProductBuild.softwareProductBuild);
            this.shipProcess.loadingDistros = false;
            this.downloadProcess.loadingDistros = false;
            if(product!=undefined)
              product.loadingShippedDistros = false;
            
          }
            
        }
        else
        {
          QLogger.LogError(this.logSrc,  "Cannot fetch distros available for "+softwareProductBuild.softwareProductBuild);
          this.shipProcess.loadingDistros = false;
          this.downloadProcess.loadingDistros = false;
          if(product!=undefined)
            product.loadingShippedDistros = false;
        }
      });
    }
    onShowImageStatus(distro:SoftwareProductDistro)
    {
      this.imageShipStatus.softwareImageShipStatus = distro.imageUploadStatus;
      this.imageShipStatus.showImageStatus = true;
    }
    replayDiffShipRequest()
    {
      QLogger.LogInfo(this.logSrc,"Replaying Diff SHip request for "+this.diffDownloadProcess.softwareBuildSelected.softwareProductBuild+" and "+this.diffDownloadProcess.baseBuildSelected.softwareProductBuild+" for distro "+this.diffDownloadProcess.baseBuildSelected.distroAvailable.softwareDistro);
      this.diffDownloadProcess.showConfirmDialog= false;
      let distroList:SoftwareProductDistro[] = [];
      distroList.push(this.diffDownloadProcess.baseBuildSelected.distroAvailable);
      this.replayDiffShipSoftwareProductBuild(this.diffDownloadProcess.baseBuildSelected,this.diffDownloadProcess.softwareBuildSelected,distroList, this.stMain.serviceTasksDetails.serviceTaskId);
  
    }
    replayDiffShipSoftwareProductBuild(softwareProductBaseBuild: SoftwareProductRelease,softwareProductTargetBuild: SoftwareProductRelease, distros: SoftwareProductDistro[],serviceTakID:Number) {
      QLogger.LogInfo(this.logSrc, "Replay : Ship difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild);
      let response: Observable<QPMResponse>;
      let distroList:SoftwareProductDistroInfo[] = [];
      let req:DiffDistributionRequest;
      let requestedPlatform : Number;
      if (this.distributionClient === null) {
        return
      }
      if(this.app.sharedData.appInfo.isElectronMode)
      requestedPlatform = 0;
      else
      requestedPlatform = 1;
      distros.forEach(function (distro) 
      {
        let info  = new SoftwareProductDistroInfo();  
        info.distroName =  distro.softwareDistro;
        info.distroId   = Number(distro.softwareDistroId);
        info.uploadComplete = distro.uploadComplete;
        info.softwareDistroAlternateId = distro.softwareDistroAlternateId
        distroList.push(info);
      });
  
      req = {
        baseSoftwareProductBuild : softwareProductBaseBuild.softwareProductBuild,
        targetSoftwareProductBuild: softwareProductTargetBuild.softwareProductBuild,
        softwareDistroList : distroList,
        requestedPlatform: requestedPlatform,
        serviceTaskId: serviceTakID
      };
      if(this.app.sharedData.appInfo.logRequest){
        QLogger.LogInfo(this.logSrc, "Replay:Diff Ship Software Success Request: " + JSON.stringify(req));
      }
      response = this.distributionClient.replayDiffShipSoftwareProduct(req);
      response.subscribe(
        (data: QPMResponse) => {
          if (this.app.sharedData.appInfo.logResponse) {
            QLogger.LogInfo(this.logSrc, "Replay:Diff Ship Software Success Response: " + JSON.stringify(data));
          }
          if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc, "Replay:Diff Ship Software Success:" + softwareProductTargetBuild.softwareProductBuild);
            this.app.queueDiffShipStatusRequest(softwareProductBaseBuild.softwareProductBuild,softwareProductTargetBuild.softwareProductBuild,distroList);
          }
          else {
            QLogger.LogError(this.logSrc, "Replay:Diff Ship Software Failed:" + softwareProductTargetBuild.softwareProductBuild);
            QLogger.LogError(this.logSrc, "Replay:Diff Ship Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
            this.app.showMessageBox("Diff Ship Request","Ship difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild +" is failed. " + data.getError(), "Ok");
          }
        }
      );
      this.app.showMessageBox("Diff Ship Request", "Ship Request for differnece between  " + softwareProductTargetBuild.softwareProductBuild + " and "+ softwareProductBaseBuild.softwareProductBuild + " has been placed", "Ok");
  }
  onReplayActionClick(softwareProductBuild: SoftwareProductRelease){

    this.diffDownloadProcess.showConfirmDialog= true;
    this.diffDownloadProcess.baseBuildSelected = softwareProductBuild;
    this.diffDownloadProcess.displayBuild = false;
  }

    //#region OPSHELP Support Ticket Creation
    opsHelpIssueType=OPSHELP.IssueType;
    opsHelpProjectKey=OPSHELP.ProjectKey;
    buildInfrastructure=OPSHELP.BuildInfrastructure;
  
  
    onOpenCRMHelpSupportTicketDialog(issueType: string,projectKey:string,component:string,distProduct:ServiceTaskDistProduct) {
        this.app.onOpenSupportTicketDialog(issueType,projectKey,component);
        //Pre-populate the Build fail details
        let buildDescription = "Release Description " + "\n"+"\n";
        let buildId="";
        buildId=distProduct?.productNewBuildID;
        buildDescription+=  "Build ID    " + " : "  + distProduct?.productNewBuildID +"\n"+
                            "Build Job  " + " : "  + distProduct?.jobUrl +"\n"+
                            "Built Date " + " : "  + distProduct?.createdAt+"\n"+
                            "Status      " + " : "  + distProduct.detail?.status+"\n"+
                            "Failed Reason" + ": "  + distProduct?.detail?.failedReason + "\n";
        this.app.supportDialog.description =buildDescription;
        //Subject Format :ST # <STNumber> <Build-ID> Failure- <faliedReason>
        this.app.supportDialog.subject= "ST #"+ this.stMain.serviceTasksDetails?.serviceTaskId+" " +buildId+ "   FailedReason-"+distProduct?.detail?.failedReason ;
    };

    onClickDetailsNewCRs(product: ServiceTaskDistProduct) {
      if(product.showAdditionalInfoNewCRs) {
        product.showAdditionalInfoNewCRs = false;
        return;
      }
      else {
        product.showAdditionalInfoNewCRs = true;
      }
      let responses: Array<Promise<any>> = [];
      for(let i = 0; i < product.crList.buildCRs.length; i++) {
        responses.push(new Promise ((resolve, reject) => {
          let response = this.webClient.getAdditionalCRInfo(product.crList.buildCRs[i].toString(), product.servicetaskId.toString());
          response.subscribe((data: QPMResponse) => {
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Additional CR Info Success: " + JSON.stringify(data));
              resolve(JSON.parse(data.getData()) as AdditionalCRInfo);
            }
            else {
              QLogger.LogError(this.logSrc, "Additional CR Info Error: " + data.getError() + " - " + data.getErrorDetail());
              resolve(null);
            }
          })
        }))
      }
      let finalPromise = Promise.all(responses).then((values: Array<AdditionalCRInfo>) => {
        product.additionalInfoLoadedNewCRs = true;
        var filtered = values.filter(function(value, index, arr) {
          return value != null;
        });
        for(let i = 0; i < filtered.length; i++) {
          let srString = ""
          for(let j = 0; j < filtered[i].customerRecords.length; j++) {
            srString = srString + filtered[i].customerRecords[j].srNumber + '\n';
          }
          filtered[i].srNumbers = srString;
        }
        product.additionalInfoNewCRs = filtered;
        return;
      })

      return finalPromise;
    }

    onClickDetailsAllCRs(product: ServiceTaskDistProduct) {
      if(product.showAdditionalInfoAllCRs) {
        product.showAdditionalInfoAllCRs = false;
        return;
      }
      else {
        product.showAdditionalInfoAllCRs = true;
      }
      let responses: Array<Promise<any>> = [];
      for(let i = 0; i < product.crList.consolidatedCRs.length; i++) {
        responses.push(new Promise ((resolve, reject) => {
          let response = this.webClient.getAdditionalCRInfo(product.crList.consolidatedCRs[i].toString(), product.servicetaskId.toString());
          response.subscribe((data: QPMResponse) => {
            if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Additional CR Info Success: " + JSON.stringify(data));
              resolve(JSON.parse(data.getData()) as AdditionalCRInfo);
            }
            else {
              QLogger.LogError(this.logSrc, "Additional CR Info Error: " + data.getError() + " - " + data.getErrorDetail());
              resolve(null);
            }
          })
        }))
      }
      let finalPromise = Promise.all(responses).then((values: Array<AdditionalCRInfo>) => {
        product.additionalInfoLoadedAllCRs = true;
        var filtered = values.filter(function(value, index, arr) {
          return value != null;
        });
        for(let i = 0; i < filtered.length; i++) {
          let srString = ""
          for(let j = 0; j < filtered[i].customerRecords.length; j++) {
            srString = srString + filtered[i].customerRecords[j].srNumber + '\n';
          }
          filtered[i].srNumbers = srString;
        }
        product.additionalInfoAllCRs = filtered;
        return;
      })

      return finalPromise;
    }
 
//#region BIN_DDM
resetBinDdmApprovalProcess(){
  this.binDdmApprovalProcess={
    display:false,
    tabIndex:0,
    selectedSoftwareProductDistro:null,
    softwareProductBuildId:'',
    product:new ServiceTaskDistProduct(),
    index:0
  }
}
resetJiraTicketIDUploadProcess(){
  this.jiraTicketIDUploadProcess={
    jiraTicketID:undefined,
    errorMessage:'',
    successMessage:'',
    ticketIdUploadInprogress:false,
    jiraTicketStatus:null,
    lostResolution:null,
    jiraUrl:null
  }
}
resetNoticeFileUploadProcess(){    
  this.noticeFileUploadProcess ={
    files:[],
    filesInfo:[],
    fileErrorMessage:'',
    fileSuccessMessage:'',
    fileUploadInprogress:false,
    errorMessage:'',
    successMessage:'',
    sessionId: 0,

    totalFileSizeInByte: 0,
    totalFileSizeInMB: 0,
    maxChunkSizeInByte: 5200000,
    maxFileSizeInByte: this.app.sharedData.appInfo.isElectronMode? 900000000: 900000000,
    maxFileSizeInMB: this.app.sharedData.appInfo.isElectronMode? 900: 900,

    subscription: undefined
  }
}

openBinDdmApprovalDialog(softwareProductDistro:SoftwareProductDistro,productBuildID:string,product: ServiceTaskDistProduct,index:number){
  this.resetBinDdmApprovalProcess();
  this.resetJiraTicketIDUploadProcess();
  this.resetNoticeFileUploadProcess();
  this.binDdmApprovalProcess.display=true;
  this.binDdmApprovalProcess.tabIndex=0;
  this.binDdmApprovalProcess.selectedSoftwareProductDistro=softwareProductDistro;//softwareDistro
  this.binDdmApprovalProcess.softwareProductBuildId=productBuildID;

  this.binDdmApprovalProcess.product=product;
  this.binDdmApprovalProcess.index=index;

  if(softwareProductDistro?.binDdmRecordSummary?.jiraUrl!=='' || softwareProductDistro?.binDdmRecordSummary?.jiraUrl!==null || softwareProductDistro?.binDdmRecordSummary?.jiraUrl!==undefined){
    this.jiraTicketIDUploadProcess.jiraUrl=softwareProductDistro?.binDdmRecordSummary?.jiraUrl;
    this.jiraTicketIDUploadProcess.jiraTicketStatus=softwareProductDistro?.binDdmRecordSummary?.jiraStatus;
    this.jiraTicketIDUploadProcess.jiraTicketID=softwareProductDistro?.binDdmRecordSummary?.jiraUrl.split('-')[2];
    this.jiraTicketIDUploadProcess.lostResolution=softwareProductDistro?.binDdmRecordSummary?.lostResolution;
   
  }else{
    this.resetJiraTicketIDUploadProcess();
  }
 
}

 //#region NoticeFileUpload
 noticefileDropped(event): void {
  let files: FileList = event.target.files;
  this.saveNoticeFiles(files);
  event.target.value = "";

}
@HostListener("dragover", ["$event"]) onDragOver(event: any) {
  event.preventDefault();
  
}
@HostListener("dragleave", ["$event"]) onDragLeave(event: any) {
  event.preventDefault();
}
@HostListener("drop", ["$event"]) onDrop(event: any) {
  event.preventDefault();
  event.stopPropagation();
  if (event.dataTransfer.files) {
    let files: FileList = event.dataTransfer.files;
    this.saveNoticeFiles(files);
  }
}

saveNoticeFiles(files: FileList) {
  let filesToAddSizeinByte = 0;
  this.noticeFileUploadProcess.totalFileSizeInByte = 0;
  this.noticeFileUploadProcess.totalFileSizeInMB = 0;
  Array.from(files).forEach(file=>filesToAddSizeinByte = filesToAddSizeinByte + file.size);
  Array.from(this.noticeFileUploadProcess.files).forEach(file=>this.noticeFileUploadProcess.totalFileSizeInByte = this.noticeFileUploadProcess.totalFileSizeInByte + file.size);
  this.noticeFileUploadProcess.totalFileSizeInMB=(this.noticeFileUploadProcess.totalFileSizeInByte/1000000);
  if((filesToAddSizeinByte + this.noticeFileUploadProcess.totalFileSizeInByte) > this.noticeFileUploadProcess.maxFileSizeInByte){
    this.noticeFileUploadProcess.totalFileSizeInMB = Number.parseFloat(this.noticeFileUploadProcess.totalFileSizeInMB.toFixed(2));
    this.noticeFileUploadProcess.errorMessage = "Total file size exceeded "+ this.noticeFileUploadProcess.maxFileSizeInMB +"MB";
  }
  else if(this.noticeFileUploadProcess.files.length + files.length > 1){
    this.noticeFileUploadProcess.errorMessage = "Please input maximum of 1 file to continue";
    return;
  }
  this.noticeFileUploadProcess.errorMessage = "";
  this.noticeFileUploadProcess.files.push(...Array.from(files));
  
  this.noticeFileUploadProcess.totalFileSizeInByte = this.noticeFileUploadProcess.totalFileSizeInByte + filesToAddSizeinByte;
  this.noticeFileUploadProcess.totalFileSizeInMB=(this.noticeFileUploadProcess.totalFileSizeInByte/1000000);
  for(let i=0; i < files.length; i++){
    this.noticeFileUploadProcess.filesInfo.push({
      filename: files[i].name,
      filesize: files[i].size,
      filePath: "",
      fullFilePath: "",
      s3Key: "",
      newFile: false,
      status: FileStatus.Init,
      statusString: "",
      validationInProgress: false,
      uploadInProgress: false,
      uploadProgress: 0
    });
  }
  this.onLocalFileUploadInputValidation();
}



onRemoveNoticeFile(index: number){
  this.noticeFileUploadProcess.files.splice(index, 1);
  this.noticeFileUploadProcess.filesInfo.splice(index, 1);
  this.noticeFileUploadProcess.totalFileSizeInByte = 0;
  Array.from(this.noticeFileUploadProcess.files).forEach(file=>this.noticeFileUploadProcess.totalFileSizeInByte = this.noticeFileUploadProcess.totalFileSizeInByte + file.size);
  this.noticeFileUploadProcess.totalFileSizeInMB = (this.noticeFileUploadProcess.totalFileSizeInByte/1000000);
  this.noticeFileUploadProcess.totalFileSizeInMB = Number.parseFloat(this.noticeFileUploadProcess.totalFileSizeInMB.toFixed(2));
  this.onLocalFileUploadInputValidation();

}

onLocalFileUploadInputValidation(){ 
  this.noticeFileUploadProcess.errorMessage="";   
  if(this.noticeFileUploadProcess.totalFileSizeInByte > this.noticeFileUploadProcess.maxFileSizeInByte){ 
    this.noticeFileUploadProcess.errorMessage = "Total file size exceeded "+ this.noticeFileUploadProcess.maxFileSizeInMB +"MB";
    return
  };
  this.noticeFileUploadProcess.errorMessage = "";
  if(this.noticeFileUploadProcess.files.length === 0){
    this.noticeFileUploadProcess.errorMessage = "Please select notice file to upload";
    this.noticeFileUploadProcess.filesInfo
    if(this.noticeFileUploadProcess.errorMessage !== "") return;
  }
 
}


uploadNoticeFileInitiate(softwareDistro:string,softwareProductBuildId:string){
  QLogger.LogInfo(this.logSrc,"Uploading Notice File Initiate for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  let req: NoticeFileUploadStartRequest;
  let response: Observable<QPMResponse>;
  this.noticeFileUploadProcess.fileUploadInprogress = true;
  req = {
    softwareDistro: softwareDistro, 
    softwareProductBuildId: softwareProductBuildId
  }
  if (this.app.sharedData.appInfo.logRequest) {
    QLogger.LogInfo(this.logSrc,"Uploading Notice File Initiate for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  }
  response = this.webClient.noticeFileUploadStartSession(req);
  response.subscribe(
    (data: QPMResponse) => {
      if (this.app.sharedData.appInfo.logResponse) {
        QLogger.LogInfo(this.logSrc, "Uploading Notice File Initiate - Response: " + JSON.stringify(data));
      }
      if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc,"Uploading Notice File Initiate - Success");
        let obj: any = JSON.parse(data.getData());
       
        this.noticeFileUploadProcess.sessionId = obj.sessionId as number;
        this.noticeFileUploadProcess.filesInfo.forEach(f=>{
          f.s3Key = obj.sessionKey as string;
          f.fullFilePath=obj.sessionKey as string;//key and path(which is not tracking) is same for NoticeFile
        });
        let config: LargeFileUploadConfig = {
          skipStart: false,
          uploadType: FileUploadType.Base64Decode //For bait, change this to Base64Decode once backend is ready and make corresponding API integrations on UI
        };

        this.largeFileUpload = new LargeFileUpload(this.app, this.webClient);
        this.largeFileUpload.setFileInfo(this.noticeFileUploadProcess.files, this.noticeFileUploadProcess.filesInfo, config);
        this.noticeFileUploadProcess.subscription = this.largeFileUpload.initiateUploadLargeFile();
        this.noticeFileUploadProcess.subscription.subscribe((uploadResp)=>{
          if(uploadResp === true){
            this.submitNoticeFileComplete(this.noticeFileUploadProcess.sessionId,softwareDistro,softwareProductBuildId);
          }
        })
      }
      else{
        this.noticeFileUploadProcess.errorMessage = data.getErrorDetail();
        QLogger.LogInfo(this.logSrc,"Uploading Notice File Initiate - Error: " + data.getErrorDetail());
      }
  });
}


submitNoticeFileComplete(sessionId: number, softwareDistro: string, softwareProductBuildId: string){
  QLogger.LogInfo(this.logSrc,"Uploading Notice File Complete for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  let req: NoticeFileUploadCompleteRequest;
  req = {
    sessionId: sessionId,
    softwareDistro: softwareDistro,
    softwareProductBuildId: softwareProductBuildId,
  }
  let response: Observable<QPMResponse>;
  if (this.app.sharedData.appInfo.logRequest) {
    QLogger.LogInfo(this.logSrc, "Uploading Notice File Complete for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  }
  response = this.webClient.noticeFileUploadCompleteSession(req);

  response.subscribe(
    (data: QPMResponse) => {
      if (this.app.sharedData.appInfo.logResponse) {
        QLogger.LogInfo(this.logSrc, "Uploading Notice File Complete - Response: " + JSON.stringify(data));
      }
      if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc,"Uploading Notice File Complete - Success");
        this.noticeFileUploadProcess.successMessage=this.noticeFileUploadProcess.filesInfo.map(x => x.filename)+" uploaded successfully";
        //this.app.showMessageBoxMultiLine("Notice File Upload Success", this.noticeFileUploadProcess.filesInfo.map(x => x.filename+" uploaded successfully"), "Ok");
        this.noticeFileUploadProcess.fileUploadInprogress = false;
        this.resetNoticeFileUploadProcess();
        this.getNoticeFileUploadStatus(softwareDistro, softwareProductBuildId);
        this.onProductRefresh(this.binDdmApprovalProcess.product,this.binDdmApprovalProcess.index)   //call that refresh

      }
      else{
        this.noticeFileUploadProcess.errorMessage = data.getErrorDetail();
        QLogger.LogInfo(this.logSrc,"Uploading Notice File Complete - Error: " + data.getErrorDetail());
      }
  });

}

getNoticeFileUploadStatus(softwareDistro:string,softwareProductBuildId:string){
  QLogger.LogInfo(this.logSrc,"Get NoticeFile Status  for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  let response: Observable<QPMResponse>; 
  let request:NoticeFileDownloadRequest; 
  request ={
    softwareDistro: softwareDistro,
    softwareProductBuild: softwareProductBuildId
  }

  if (this.app.sharedData.appInfo.logRequest) {
    QLogger.LogInfo(this.logSrc, "Get NoticeFile Status  for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  }  

  response = this.webClient.noticeFileDownload(request)
  response.subscribe(
    (data: QPMResponse) => {
      if (this.app.sharedData.appInfo.logResponse) {
        QLogger.LogInfo(this.logSrc, "Get NoticeFile Status  - Response: " + JSON.stringify(data));
      }
      if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc,"Get NoticeFile Status  - Success");
        let obj: any = JSON.parse(data.getData());
       
        this.binDdmApprovalProcess.selectedSoftwareProductDistro.binDdmRecordSummary.noticeFileUploadStatus=obj.noticeFileUploadStatus as number;
      
      }
      else{
        this.noticeFileUploadProcess.errorMessage = data.getErrorDetail();
        QLogger.LogInfo(this.logSrc,"Get NoticeFile Status failed - Error: " + data.getErrorDetail());
      }
  });

}

downloadNoticeFile(softwareDistro:string,softwareProductBuildId:string){
  QLogger.LogInfo(this.logSrc,"Downloading Notice File  for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  let response: Observable<QPMResponse>; 
  let request:NoticeFileDownloadRequest; 
  request ={
    softwareDistro: softwareDistro,
    softwareProductBuild: softwareProductBuildId
  }

  if (this.app.sharedData.appInfo.logRequest) {
    QLogger.LogInfo(this.logSrc, "Downloading Notice File  for softwareDistro:"+softwareDistro+ " softwareProductBuildId: "+softwareProductBuildId);
  }  

  response = this.webClient.noticeFileDownload(request)
  response.subscribe(
    (data: QPMResponse) => {
      if (this.app.sharedData.appInfo.logResponse) {
        QLogger.LogInfo(this.logSrc, "Downloading Notice File Initiate - Response: " + JSON.stringify(data));
      }
      if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc,"Downloading Notice File - Success");
        let obj: any = JSON.parse(data.getData());
        let noticeFileUrl= obj.noticeFileUrl as string;

        if(noticeFileUrl!=undefined){
          if(this.app.sharedData.appInfo.isElectronMode){
            this.app.goToUrl(noticeFileUrl);
          }else{
            let NoticeFilepopUpWin=window.open(noticeFileUrl, '_blank');
            if(!NoticeFilepopUpWin || NoticeFilepopUpWin.closed || typeof NoticeFilepopUpWin.closed=='undefined'){ 
              this.app.showMessageBox("Popup Blocker Detection", "Please disable browser popup blocker", "Ok");
            }
          }
      
        }else{
          this.app.showMessageBoxMultiLine("Notice File Download Failed", ['Notice file download failed for URL'+noticeFileUrl], "Ok");
        }

      }
      else{
        this.noticeFileUploadProcess.errorMessage = data.getErrorDetail();
        QLogger.LogInfo(this.logSrc,"Downloading Notice File failed - Error: " + data.getErrorDetail());
      }
  });

}
uploadJiraTicketID(){
  QLogger.LogInfo(this.logSrc,"Upload JiraTicketID Request is in Progress");
  this.jiraTicketIDUploadProcess.ticketIdUploadInprogress = true;
  this.jiraTicketIDUploadProcess.successMessage='';
  this.jiraTicketIDUploadProcess.errorMessage='';
  let response: Observable<QPMResponse>;
  let req:BinDdmJiraTicketInfoRequest;
  req ={
    jiraIssueId          : this.jiraTicketIDUploadProcess?.jiraTicketID.split('-')[1],
    jiraProjectKey       : 'QUICLOSTEX',
    softwareDistro       : this.binDdmApprovalProcess?.selectedSoftwareProductDistro?.softwareDistro,
    softwareProductBuild : this.binDdmApprovalProcess?.softwareProductBuildId
  }

  if (this.app.sharedData.appInfo.logRequest) {
    QLogger.LogInfo(this.logSrc, "Upload JiraTicketID Request: " + JSON.stringify(req));
  }

  response = this.webClient.binDdmJiraTicketIdSubmission(req);
  response.subscribe(
    (data: QPMResponse) => {
      if (this.app.sharedData.appInfo.logResponse) {
        QLogger.LogInfo(this.logSrc, "Upload JiraTicketID Response: " + JSON.stringify(data));
      }
      if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc,  "Upload JiraTicketID for Distro: "+req.softwareDistro+" and SoftwareProductBuildID :"+req.softwareProductBuild +"- Success");
        
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){

          let jiraTicketInfoResponse = obj as BinDdmJiraTicketInfoResponse;

          this.jiraTicketIDUploadProcess.successMessage='Jira TicketID Submitted Successfully !';
          if(jiraTicketInfoResponse?.jiraUrl!=='' ||jiraTicketInfoResponse?.jiraUrl!==null || jiraTicketInfoResponse?.jiraUrl!==undefined){
            this.jiraTicketIDUploadProcess.jiraTicketStatus=jiraTicketInfoResponse?.jiraStatus.toString();
            this.jiraTicketIDUploadProcess.jiraUrl=jiraTicketInfoResponse?.jiraUrl;
            this.jiraTicketIDUploadProcess.jiraTicketID=jiraTicketInfoResponse?.jiraUrl.split('-')[2];
            this.jiraTicketIDUploadProcess.lostResolution=jiraTicketInfoResponse?.lostResolution;
            this.onProductRefresh(this.binDdmApprovalProcess.product,this.binDdmApprovalProcess.index)   //call that refresh
          }
       
        }
      }
      else{
        QLogger.LogError(this.logSrc,  "Upload JiraTicketID for Distro: "+req.softwareDistro+" and SoftwareProductBuildID :"+req.softwareProductBuild +" - Failed");
        QLogger.LogError(this.logSrc,  "Upload JiraTicketID for Distro: "+req.softwareDistro+" and SoftwareProductBuildID :"+req.softwareProductBuild +"- Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        this.jiraTicketIDUploadProcess.errorMessage = "Error : " + data.getError();
      }
      this.jiraTicketIDUploadProcess.ticketIdUploadInprogress = false;
    });

}
//#endregion BIN_DDM


//#region OSS
loadServiceTaskBaitBuildsForOSS() {
  QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details");
  this.serviceTaskBuildsForOss = new ServiceTaskBuildsForOss();

  let response : Observable<QPMResponse>;
  this.serviceTaskBuildsForOss.loadingBaitImagesForOss = true;
  response = this.softwareCatalogClient.getServiceTaskBuilds(this.app.sharedData.service.common.selectedServiceTaskID);
  response.subscribe(
  (data:QPMResponse) => { 
    if(this.app.sharedData.appInfo.logResponse){
      QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Response : " +JSON.stringify(data));
    }
    if(data.isSuccess()){
      QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Success");
      
      let obj = JSON.parse(data.getData());
      if(obj !== undefined || obj !== null){
        let serviceTaskallBuilds = obj as ServiceTaskBuildsForOss;
        this.serviceTaskBuildsForOss.serviceTaskId=serviceTaskallBuilds.serviceTaskId;
        this.serviceTaskBuildsForOss.softwareImages= serviceTaskallBuilds.softwareImages?.filter(image=>image.buildSystem==='BAIT');
        this.serviceTaskBuildsForOss.softwareImages?.forEach(image=>{
          image.baitBuilds=[];
          image.ossLinkSet=[];
        })
 
      }
    }
    else{
      QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Failed");
      QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Build Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

    }
    this.serviceTaskBuildsForOss.loadingBaitImagesForOss = false;
  });
}

//lazy loading : Only onexpand do backed call
OnOssProductExpand(){
  this.serviceTaskBuildsForOss.softwareImages?.forEach(image=>{
    this.loadServiceTaskBaitBuildsForOss(image,false);
  })
}
OnOssProductRefresh(){
  this.serviceTaskBuildsForOss.softwareImages?.forEach(image=>{
    this.loadServiceTaskBaitBuildsForOss(image,true);
  })
}

loadServiceTaskBaitBuildsForOss(image: ServiceTaskBaitImageForOss, refresh: boolean){//CLO2
  if(!refresh && image.baitBuilds.length !== 0){
    return;
  }
  let serviceTakID = this.stMain.serviceTasksDetails.serviceTaskId;
  let baseImage = image.baseImage;
  QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds");
  let response : Observable<QPMResponse>;
  this.serviceTaskBuildsForOss.loadingBaitJobIdsForOss++;
  response = this.softwareCatalogClient.getServiceTaskBaitBuilds(serviceTakID.toString(), baseImage);
  response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Response : " +JSON.stringify(data));
      } 
      if (data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Success");
        let obj = JSON.parse(data.getData());
        if (obj !== undefined || obj !== null) {
          image.baitBuilds = obj.image_builds as ServiceTaskBaitBuildForOss[];
        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " Image : " + baseImage + " Bait Builds - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }

      this.serviceTaskBuildsForOss.loadingBaitJobIdsForOss--;
       //Sorting BuildIDs/jobIds
       image.baitBuilds.sort((b1,b2)=> b2.request_date.localeCompare(b1.request_date));
       image.baitBuilds.forEach((baitBuild)=>{
           this.loadServiceTaskBaitBuildDetailsForOss(baitBuild, image);
       });
      
    }
  );
}

loadServiceTaskBaitBuildDetailsForOss(baitBuild: ServiceTaskBaitBuildForOss, image: ServiceTaskBaitImageForOss){
  let serviceTakID = this.stMain.serviceTasksDetails.serviceTaskId;
  let buildID = baitBuild.build_id;
  QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details");
  let response : Observable<QPMResponse>;
  this.serviceTaskBuildsForOss.loadingBaitJobDetailsForOss++;
  response = this.softwareCatalogClient.getServiceTaskBaitBuildDetails(serviceTakID.toString(), buildID);
  response.subscribe(
    (data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Response : " +JSON.stringify(data));
      } 
      if (data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Success");
        let obj = JSON.parse(data.getData());
        if (obj !== undefined || obj !== null) {
          baitBuild.baitJob = obj as ServiceTaskBaitJob;
          //add to OssLinkset if bait build status === 'Complete' and not exist in ossLinkSet"
          if(baitBuild.baitJob.oss!= null && baitBuild.baitJob?.bait_build_info?.status==='Complete'){
      
            let ossLinkDetail= new OssLinkDetail();
            //RegEx to extract OSSLink
            const regex = new RegExp('https?:\\/\\/[^\\s].*tar.gz', '')
            let finalOssLink=baitBuild.baitJob.oss?.match(regex);
            if(finalOssLink!==null && finalOssLink?.length>0){
              ossLinkDetail.oss=finalOssLink[0];
            }
            ossLinkDetail.build_completed_time=baitBuild.baitJob.bait_build_info.build_completed_time;
            if(image.ossLinkSet.findIndex(item => item.oss ===ossLinkDetail.oss )===-1){
              image.ossLinkSet.push(ossLinkDetail);
            }
          }

        }
      }
      else{
        QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Failed");
        QLogger.LogError(this.logSrc, "Get Service Task : " + serviceTakID + " BuildID : " + buildID + " Bait Build Details - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }
      this.serviceTaskBuildsForOss.loadingBaitJobDetailsForOss--;
    }
  );
}

//#endregion OSS
}
