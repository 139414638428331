import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FileInfo, InstalledPackageDetails,PackageDetails, ReleaseNotesDetails, ReleaseNotesInfo, InstalledVersion, QIKResult, QIKPackageProperites, PackageDependencyInfo, ProductSuite } from '../../models/catalog-client';
import { ProductDetails, ProductV3, ProductSuiteV3, ProductCatalogV3,ReleaseV3, ReleasesV3, ProductSubSuiteV3} from '../../models/lime-web-client';
import { ActivationKey, Entitlements, Feature, LicenseDetails, ProductBundleConfiguration, ProductWithVersion } from '../../models/lime-client';
import { DataServiceProducer, ApiType } from 'src/app/service/Factory/DataServiceProducer';
import { BehaviorSubject, combineLatest, forkJoin, interval, Observable, of, Subscription } from 'rxjs';
import { SelectItem, MenuItem,Message } from 'primeng/primeng';
import { TreeNode } from 'primeng/api';
import { QPMResponse } from 'src/app/models/response';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { InstallComponent } from 'src/app/component/tools/install/install.component';
import { QLogger } from 'src/app/common/logger';
import { commType, RefreshInvokationType, selectableOSs } from 'src/app/common/shared-data';
import { Utils } from 'src/app/common/utils';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import isElectron from 'is-electron';
import {Buffer} from 'buffer';
import * as S3 from 'aws-sdk/clients/s3';
import { ProductClassification, QPMCLICmdsToInvokeOnRefresh, QPMCLICommandsToInvokePeriodically } from './toolsConstants';

import { map } from 'rxjs/operators';
import { CatalogUtils } from './tools-utils/catalogUtils';
enum InstallActions {
  Install ="Install",
  DownloadOnly="Download",
  Refresh="Refresh",
  Uninstall="Uninstall",
  Extract="Extract"
};

enum ToolsViewTypes  {
  All,
  Installed,
  Favorites,
  Upgrades,
  Licenses 
}

export enum RowType {
  Suite = 'Suite',
  SubSuite = 'Subsuite',
  Product = 'Product',
}

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.css']
})

export class ToolsComponent implements OnInit {
  private logSrc:string = "ToolsFind-Component";
  private subscriptionSrc:string = "ToolsFind-Component";
  subscription: Subscription;
  private toolRefreshSubscription: Observable<boolean>;

  private catalogClient: CatalogClientService;
  private webClient : WebClientService;
  private limeClient : ClientService;
  private telematicsClient: TelematicsClientService
  private catalogUtils:CatalogUtils;
  private ipcRenderer;
  public periodicRefreshSubscription:Subscription
  // S3 Bucket for tools icons	
  iconS3 : any = undefined;

  showingSoftwareSource : boolean;
  errorMessage : string[];

  // Static UI elements
  @ViewChild('toolsTable') toolsTable;
  @ViewChild('toolsGlobalFilter') toolsGlobalFilter;
  @ViewChild('landingMenuItems') landingMenu: MenuItem[];

  // All products
  productCatalog: ProductCatalogV3;
  installedProducts:InstalledPackageDetails[];
  osSupported:string[]=[];
  filteredProducts: ProductV3[] = [];
  toolsRows: TreeNode[];
  filteredDependencies:PackageDependencyInfo[] =[];
  filteredAddOns:PackageDependencyInfo[]=[];
  osOptions: SelectItem[];
  selectedOS: string;

  // For progress spinners
  loadingTools: boolean;
  //PRODUCT-BUNDLE
  moreActionMenuItems : MenuItem[];
  loadingProductListbyOS:boolean;
  loadingSelectedProductDetails:boolean;
  productsBundleProcess:{
    displayProductBundlerDialog: boolean;
    successMsg:string;
    errorMsg:string;
    OSoptions:string[];
    OSoptionsSelectItems: SelectItem[];
    selectedOS:string;
    productV3List: ProductV3[];
    productSelectItems: SelectItem[];
    selectedProduct: ProductV3;
    versionList:ReleaseV3[];
    versionsSelectItems: SelectItem[];
    selectedVersion:string;
    productsWithVersionDetails:ProductWithVersion[];
  }

  ToolsViewTypes = ToolsViewTypes;
  toolsLandingMenuItems: MenuItem[];
  toolsLandingMenuActiveItem: MenuItem;
  productCatalogOsMap:Map<string,ProductCatalogV3>;
  productCatalogETagMap:Map<string,Number>;
  productCatalogETagProductMap:Map<string,ProductV3>;

  showAddLicenseDialog: boolean;
  uiLicenseGroupID: string;
  licenseErrorMessage : string;

  private static toolsIconCredentials:{
    accessKeyId:string;
    secretAccessKey:string;
    sessionToken:string;
    expiration:string;

  }
  toolsIconRequestCount:number;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, 
    public app : AppMainComponent, public utils : Utils, 
    private service: DataServiceProducer, private http : HttpClient,
    public zone : NgZone, public installer : InstallComponent) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
    this.catalogUtils=new CatalogUtils(this.app,this.webClient);
    if(isElectron()){
      this.ipcRenderer = window.require('electron').ipcRenderer;
      
    }
  }
  
  ngOnDestroy() {
    this.app.sharedData.service.tools.loadFromTools = false;
    this.app.sharedData.service.tools.loadFromDetails = false;
    this.app.sharedData.service.tools.showDetails = false;
    this.app.sharedData.service.tools.showCloDetails = false;
    this.app.sharedData.unsubscribeCommunication(commType.RefreshTools, this.subscriptionSrc);
    if(this.periodicRefreshSubscription){
      this.periodicRefreshSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    if (this.router.url.includes("source")) {
      QLogger.LogInfo(this.logSrc, "Source Component Initialization");
      this.showingSoftwareSource = true;
    }
    else {
      QLogger.LogInfo(this.logSrc, "Tools Component Initialization");
      this.showingSoftwareSource = false;
    }

    // Static UI Elements
    this.app.setUserInfo();
    this.app.sharedData.service.common.master = false;
    this.app.sharedData.resetVisibility();
    this.app.sharedData.setVisibility();
    this.showAddLicenseDialog = false;
    this.uiLicenseGroupID = "";
    this.licenseErrorMessage = "";
    
    ToolsComponent.toolsIconCredentials ={
      accessKeyId:"",
      secretAccessKey:"",
      sessionToken:"",
      expiration:""
    }
    this.osOptions = [];
    //PRODUCT-BUNDLE
    this.setMoreActionMenuItems();
    this.resetProductsBundleProcess();
    this.productCatalogOsMap = new Map<string, ProductCatalogV3>();

    if (this.app.sharedData.service.tools.selectedOSs.length === 0) {
      if(this.app.sharedData.appInfo.isElectronMode)
      {
        let osString =  this.getTheRunningOSString();
        if(osString){
          this.app.sharedData.service.tools.selectedOSs.push(osString);
        }
      }
      else
      {
        if((navigator.userAgent).indexOf("Windows")!=-1)
        this.app.sharedData.service.tools.selectedOSs.push('Windows');
        else if((navigator.userAgent).indexOf("Linux")!=-1 || (navigator.userAgent).indexOf("X11")!=-1)
        this.app.sharedData.service.tools.selectedOSs.push('Linux');
        else if((navigator.userAgent).indexOf("Mac")!=-1)
        this.app.sharedData.service.tools.selectedOSs.push('MacOS');
        this.app.sharedData.service.tools.filteredOSs =[...this.app.sharedData.service.tools.selectedOSs];
      }
    }
    this.osSupported = [...this.app.sharedData.service.tools.selectedOSs];
    this.errorMessage = [];

    if (this.app.sharedData.appInfo.isElectronMode && !this.app.sharedData.appInfo.workOffline) {
      this.toolsLandingMenuItems = [
        {label: 'All'},
        {label: 'Installed'},
        {label: 'Updates Available'}
      ];
    }
    else {
      this.toolsLandingMenuItems = [
        {label: 'All'}
      ]
    }

    // Installed
    if (this.app.sharedData.service.tools.shownTools === ToolsViewTypes.Installed) {
      this.toolsLandingMenuActiveItem = this.toolsLandingMenuItems[1];
    }
    // Upgrades
    else if (this.app.sharedData.service.tools.shownTools === ToolsViewTypes.Upgrades) {
      this.toolsLandingMenuActiveItem = this.toolsLandingMenuItems[2];
    }
    else {
      this.toolsLandingMenuActiveItem = this.toolsLandingMenuItems[0];
    }

    this.toolsIconRequestCount = 0;

    // Spinners
    this.loadingTools = false;
    this.productCatalog = new ProductCatalogV3();
    if (this.app.sharedData.service.tools === undefined) {
      this.app.sharedData.service.tools.shownTools = ToolsViewTypes.All;
    }
 
    // Only update catalog if needed
    if (!this.app.sharedData.service.tools.isCatalogInitialized) {

      this.installedProducts =[];
      this.setAwsCredentialsAndFetchCatalog(this.osSupported.shift());
      this.productCatalogETagMap = new Map<string, Number>();
      this.productCatalogETagProductMap = new Map<string,ProductV3>();
      this.app.sharedData.service.tools.productCatalogETagMap = this.productCatalogETagMap;
      this.app.sharedData.service.tools.productCatalogETagProductMap = this.productCatalogETagProductMap;

    }
    else {
      this.productCatalogOsMap = this.app.sharedData.service.tools.productCatalogOsMap;
      this.productCatalogETagMap = this.app.sharedData.service.tools.productCatalogETagMap;
      this.productCatalogETagProductMap = this.app.sharedData.service.tools.productCatalogETagProductMap;
      this.filterCatalog();
      this.organizeProducts();
    }
  }
  //#region Products-Bundler
  //PRODUCT-BUNDLE
  setMoreActionMenuItems(){
    this.moreActionMenuItems = [];
    if(this.app.sharedData.appInfo.isElectronMode){
      this.moreActionMenuItems = [
        {label: 'Create Product Bundle',style:{'width':'auto','text-align': 'start','font-size': '12.4px'},icon: 'pi pi-briefcase',command: () => {this.launchProductsBundler()}},
        {separator: true,},
        {label: 'Add License',style:{'width':'auto','text-align': 'start','font-size': '12.4px'},icon: 'pi pi-plus',command: () => {this.onAddLicenseClicked()}}
      ];
    }else{
      this.moreActionMenuItems = [
        {label: 'Create Product Bundle',style:{'width':'auto','text-align': 'start','font-size': '12.4px'},icon: 'pi pi-briefcase',command: () => {this.launchProductsBundler()}}
      ];
    } 
  }
  resetProductsBundleProcess(){
    this.productsBundleProcess={
      displayProductBundlerDialog: false,
      errorMsg:'',
      successMsg:'',
      OSoptions:[],
      OSoptionsSelectItems:[],
      selectedOS:'',
      productV3List:[],
      productSelectItems:[],
      selectedProduct:undefined,
      versionList:[],
      versionsSelectItems:[],
      selectedVersion:'',
      productsWithVersionDetails:[

      ],
    
    }
  }

  launchProductsBundler(){
    this.resetProductsBundleProcess();
    this.productsBundleProcess.displayProductBundlerDialog=true;
    //this.productsBundleProcess.OSoptions=['Windows'];
    this.productsBundleProcess.OSoptions=['Windows'];
    this.productsBundleProcess.OSoptionsSelectItems=this.utils.getSelectItemsFromStrings(this.productsBundleProcess.OSoptions);
    this.productsBundleProcess.selectedOS='Windows';
    this.getProductV3ListByOS(this.productsBundleProcess.selectedOS);
    this.productsBundleProcess.versionList=[];
    this.productsBundleProcess.versionsSelectItems=[];
  }

  clearProductBundle(){
    this.productsBundleProcess.productsWithVersionDetails=[]
    this.productsBundleProcess.successMsg="";
    this.productsBundleProcess.errorMsg="";

  }
  getProductV3ListByOS(selectedOS:string){
   QLogger.LogInfo(this.logSrc, "Get ProductV3 List For OS : " + this.productsBundleProcess.selectedOS);
   this.productsBundleProcess.productV3List=[];
   this.productsBundleProcess.productSelectItems=[];
   if(this.productsBundleProcess.selectedOS === undefined) return;
   this.loadingProductListbyOS=true;
   let productsV3Response=this.catalogUtils.getAllProductsByOS(selectedOS);
   productsV3Response.subscribe(
    (productV3List)=>{
    QLogger.LogInfo(this.logSrc, "Get ProductV3 List Response - Response : " +JSON.stringify(productV3List));
    let productList=productV3List;
    //Sort by product displayName in alphabetical order
    this.productsBundleProcess.productV3List=productList.sort((productA,productB)=>productA.displayName.toLocaleLowerCase().localeCompare(productB.displayName.toLocaleLowerCase()));
    this.productsBundleProcess.productSelectItems=this.utils.getSelectItems(this.productsBundleProcess.productV3List,'displayName');
    this.loadingProductListbyOS=false;
    },
   (error)=>{
    QLogger.LogError(this.logSrc, "Get ProductV3 List For OS - Failed");
    QLogger.LogError(this.logSrc, `Get ProductV3 List For OS failed. Error: ${error}`);
    this.productsBundleProcess.successMsg="";
      this.productsBundleProcess.errorMsg="";
    this.productsBundleProcess.errorMsg="Failed to loaded products";
    this.loadingProductListbyOS=false;
   })

  }

  getReleaseVersionsByProductId(selectedOS:string,selectedProductV3:ProductV3){
    this.productsBundleProcess.versionList=[];
    this.productsBundleProcess.versionsSelectItems=[];
    let releaseV3Response : Observable<ReleaseV3[]>;
    this.loadingSelectedProductDetails = true;
    QLogger.LogInfo(this.logSrc, "Product Details : " + selectedProductV3.productUUID);
    QLogger.LogInfo(this.logSrc, "Get All Releases For : " + selectedProductV3.displayName);
    QLogger.LogInfo(this.logSrc, "selected os is: " + selectedOS);    
    // Get All Release versions for this productUUID using catalog v3
    releaseV3Response = this.catalogUtils.getAllReleaseVersionsByProductId(selectedProductV3.productUUID,selectedOS);
    releaseV3Response.subscribe((data:ReleaseV3[])=>{
      this.productsBundleProcess.versionList=data;
      this.productsBundleProcess.versionList.sort((releaseA, releaseB) => (Utils.prototype.semVerGte(releaseA.version, releaseB.version) ? -1 : 1))
      this.productsBundleProcess.versionList.forEach((thisPackage,index) => {
        if(thisPackage?.version != "0.0.0.0") {
          let versionLabel = thisPackage.releaseBranch == "Production" ? thisPackage.version : thisPackage.version + " " + thisPackage.releaseBranch;
          this.productsBundleProcess.versionsSelectItems.push({
          label: "v" + versionLabel,
          value: thisPackage?.version,
           disabled: false
          });
        }
      })
      this.loadingSelectedProductDetails = false; 
    },
    (error)=>{
      QLogger.LogError(this.logSrc, "Get AllReleaseVersions By ProductId - Failed");
      QLogger.LogError(this.logSrc, `Get AllReleaseVersions By ProductId Failed. Error: ${error}`);
      this.productsBundleProcess.errorMsg="Failed to loaded releases";
      this.loadingProductListbyOS=false;
      this.loadingSelectedProductDetails = false; 
    })   
  }
  onOSChange(){
    if(this.productsBundleProcess.selectedOS === undefined) return;
    this.productsBundleProcess.successMsg="";
    this.productsBundleProcess.errorMsg="";
    this.getProductV3ListByOS(this.productsBundleProcess.selectedOS);
    this.productsBundleProcess.versionList=[];
    this.productsBundleProcess.versionsSelectItems=[];
  }
  onProductChange() {
    if(this.productsBundleProcess.selectedOS === undefined || this.productsBundleProcess.selectedProduct===undefined) return;
    this.productsBundleProcess.successMsg="";
    this.productsBundleProcess.errorMsg="";
    this.getReleaseVersionsByProductId(this.productsBundleProcess.selectedOS,this.productsBundleProcess.selectedProduct)
  }
  addProduct(){
    this.productsBundleProcess.successMsg="";
    this.productsBundleProcess.errorMsg="";
    let product=new ProductWithVersion();
    product.productUUID=this.productsBundleProcess.selectedProduct.productUUID;
    product.productName=this.productsBundleProcess.selectedProduct.displayName;
    if(this.productsBundleProcess.selectedVersion){
      product.version=this.productsBundleProcess.selectedVersion;
    }else{
      product.version='Latest'
    }
    let index = this.productsBundleProcess.productsWithVersionDetails.findIndex(prod => prod.productUUID === product.productUUID);
    if(index===-1){
      this.productsBundleProcess.productsWithVersionDetails.unshift(product);
      this.productsBundleProcess.selectedProduct=undefined;
      this.productsBundleProcess.selectedVersion=undefined;
      this.productsBundleProcess.versionList=[];
      this.productsBundleProcess.versionsSelectItems=[];
    }else{
      this.productsBundleProcess.errorMsg=this.productsBundleProcess.selectedProduct.displayName +" "+" Already added to bundle" 

    } 

  }

  deleteProductFromBundle(product:ProductWithVersion){
    let index=this.productsBundleProcess.productsWithVersionDetails.indexOf(product)
    this.productsBundleProcess.productsWithVersionDetails.splice(index,1);
  }

  productBundlefileUpload(event): void {
    let selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, "UTF-8");
    fileReader.onload = (event) => {
      let result = <string>fileReader.result;
      console.log(JSON.parse(result));
      try {
        let importedProductBundleConfig= new ProductBundleConfiguration();
        importedProductBundleConfig=JSON.parse(result);
        this.productsBundleProcess.selectedOS=importedProductBundleConfig.os;
        this.productsBundleProcess.productsWithVersionDetails=importedProductBundleConfig.productList;
        
      } catch (error) {
        this.productsBundleProcess.successMsg="";
        this.productsBundleProcess.errorMsg="";
        this.productsBundleProcess.errorMsg="Failed to import file" 
      }
     }
    fileReader.onerror = (error) => {
      QLogger.LogInfo(this.logSrc,"Product bundle file import failed :"+error);
      this.productsBundleProcess.successMsg="";
      this.productsBundleProcess.errorMsg="";
      this.productsBundleProcess.errorMsg="Failed to import file" 
    }

  }
  productBundlefileExport(): void {
    let productBundleConfig=new ProductBundleConfiguration();
    productBundleConfig.os=this.productsBundleProcess.selectedOS;
    productBundleConfig.creator=this.app.sharedData.userInfo.username;
    productBundleConfig.timestamp=this.utils.getCurrentDateTime();
    productBundleConfig.productList=this.productsBundleProcess.productsWithVersionDetails;
    let exportName='PRODUCT_BUNDLE_'+this.utils.getCurrentDateTimeStamp_24();

    if(this.app.sharedData.appInfo.isElectronMode){
      const fs = window.require('fs');
      const { dialog } = window.require('electron').remote;
      var path = window.require('path');
      var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",properties: ['openDirectory'] }); //Select Download Location
      var downloadPath = path.join(downloadPathList[0], exportName +".json");
      let dataStr = JSON.stringify(productBundleConfig);
      fs.writeFile(downloadPath, dataStr,(err) =>this.zone.run(() => {
          if (err || dataStr.length===0){
            this.app.showMessageBox("Download Failed", "Product Bundle Export Failed", "Ok")
          }
          else{
            this.app.showMessageBoxMultiLine("Download Success", ["Product Bundle Exported Successfully", "Path:"+downloadPath], "Ok")
          }
      }))

    }else{
      let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(productBundleConfig));
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     dataStr);
      downloadAnchorNode.setAttribute("download", exportName + ".json");
      document.body.appendChild(downloadAnchorNode); // required for old browser
      downloadAnchorNode.click();
      downloadAnchorNode.remove(); 
      this.productsBundleProcess.successMsg="";
      this.productsBundleProcess.errorMsg="";
      this.productsBundleProcess.errorMsg="Product Bundle Exported Successfully" 
      
    }    

  }

  //#endregion
  getTheRunningOSString():string{
    var osPlatform = process.platform;
    if(osPlatform === "win32")
    return 'Windows';
    if(osPlatform==='linux')
    return 'Linux';
    if(osPlatform=='darwin')
    return 'MacOS';
    return undefined;
  }
  ngAfterViewInit(){
    this.initSubscribes();

    // Re-initialize the tools filter
    if (this.toolsTable !== undefined) {
      this.toolsGlobalFilter.nativeElement.value = this.app.sharedData.service.tools.toolsFilter;
      this.toolsTable?.filterGlobal(this.app.sharedData.service.tools.toolsFilter, 'contains');
    }
  }

  initSubscribes(){
    this.toolRefreshSubscription = this.app.sharedData.subscribeCommunication(commType.RefreshTools, this.subscriptionSrc);
    this.toolRefreshSubscription.subscribe((selectedProduct)=> {
      if (selectedProduct === undefined) return;

      QLogger.LogInfo(this.logSrc,"Install Complete");
      this.getAllTools(this.osSupported.shift());
    });

    //Refresh catalog every 2 hours
    if(this.app.sharedData.appInfo.isElectronMode){
      this.periodicRefreshSubscription = interval(7200000)
      .subscribe(() => {      
        QLogger.LogInfo(this.logSrc,"Refresh Tools in interval");
        this.refreshAllTools();
        this.utils.runQPMCLICommands(RefreshInvokationType.Periodic, this.logSrc);
      });
    }
  }

  private static getS3Bucket(): any {	
    return new S3(	
      {	
        accessKeyId: ToolsComponent.toolsIconCredentials.accessKeyId,	
        secretAccessKey: ToolsComponent.toolsIconCredentials.secretAccessKey,	
        sessionToken : ToolsComponent.toolsIconCredentials.sessionToken,
        region: 'us-west-2'	
      }	
    );	
  }	

  getImage(key: string) {	
    // Make sure this was initialized
    if (this.iconS3 === undefined) {	
      this.iconS3 = ToolsComponent.getS3Bucket();	
    } 	

    const data = this.iconS3.getObject({	
      Bucket: 'qpm-icons',	
      Key: key	
    }).promise();	

    return data;	
  }	

  encode(data) {	
    let buf = Buffer.from(data);	
    let base64 = buf.toString('base64');	
    return base64;	
  }
  onFilterClear()
  {
    this.toolsGlobalFilter.nativeElement.value = '';
    this.toolsTable?.filterGlobal('', 'contains');
  }

  onFilterByOs(event)
  {
    this.loadingTools = true;
    this.osSupported = [];
    this.errorMessage = [];
    this.app.sharedData.service.tools.filteredOSs.forEach(Os=>{
          if(!this.productCatalogOsMap.has(Os))
            this.osSupported.push(Os);
    });
    if(this.app.sharedData.service.tools.filteredOSs.length == 0){
      var selectableOsList = [selectableOSs.WINDOWS, selectableOSs.LINUX, selectableOSs.MACOS];
      selectableOsList.forEach(os => {
        if(!this.productCatalogOsMap.has(os)){
            this.osSupported.push(os);
        }
      });
    }
    
    if(this.osSupported.length>0)
    {
      this.osSupported.forEach(os => {
        this.setAwsCredentialsAndFetchCatalog(this.osSupported.shift());
      });
    } 
    else{
      this.filterCatalog();
      this.organizeProducts();
    }
    this.loadingTools = false;
  }

  onFilterByVisibilty(event)
  {
    this.filterCatalog();
    this.organizeProducts();
  }

  filterProducts(product,Os)
  {
    let productInfo:ProductV3;
    let productDetails = JSON.parse(JSON.stringify(product)); 
    if(product.latestRelease?.version != '0.0.0.0') {
      productInfo = this.productCatalog.products?.find(prod => prod.displayName === product.displayName )
      if (undefined == productInfo) {
        productDetails.productOS =[];
          productDetails.productOS.push(Os);
          productDetails.selectedOS = Os;
          this.productCatalog.products.push(productDetails);       
      }
      else
      {
        productInfo.productOS.push(Os);
        let  currentReleaseDate     = new Date(productInfo.latestRelease?.releaseDateUTC);
        let  newReleaseDate         = new Date(productDetails.latestRelease?.releaseDateUTC);
        if(newReleaseDate.getTime()>currentReleaseDate.getTime())
        {
          productInfo.selectedOS = Os;
          productInfo.latestRelease = JSON.parse(JSON.stringify(productDetails.latestRelease));
        }
      }
    }
  }

  /* All Product View and Filters */
  onFilterToolsTable(event){
    if (event.filters.global != undefined) {
      this.app.sharedData.service.tools.toolsFilter = event.filters.global.value;
    }
    else {
      this.app.sharedData.service.tools.toolsFilter = '';
    }
  }

  onNodeExpand(event) {
    const node = event.node;
    node.children.sort((p1,p2) => p1.data?.displayName.localeCompare(p2.data?.displayName));
  }
  
  onFilterToolsClear() {
    this.app.sharedData.service.tools.toolsFilter = '';
    this.toolsGlobalFilter.nativeElement.value = '';
    this.toolsTable?.filterGlobal('', 'contains');
  }
  onRefreshButtonClick(){
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      this.utils.runQPMCLICommands(RefreshInvokationType.OnRefreshButtonClick, this.logSrc);
    }
    this.refreshAllTools();
    this.toolsTable.filterGlobal(this.toolsGlobalFilter.nativeElement.value?.trim(),'contains')

  }
  //Method Description : For Portal It refreshes for all based on selected OS, but For App it only refreshes the tools supported by the OS of the machine
  refreshAllTools()
  {
    if(this.app.sharedData.appInfo.isElectronMode){
      this.osSupported = [...this.app.sharedData.service.tools.selectedOSs];
      this.errorMessage = [];
      this.setAwsCredentialsAndFetchCatalog(this.osSupported.shift());
      
    }else{
      var osCatalogToRefresh = [];
      if(this.app.sharedData.service.tools.filteredOSs.length == 0)
      {
        osCatalogToRefresh.push(selectableOSs.WINDOWS);
        osCatalogToRefresh.push(selectableOSs.LINUX);
        osCatalogToRefresh.push(selectableOSs.MACOS);
      }
      else
      {
        this.app.sharedData.service.tools.filteredOSs.forEach(os => {
          osCatalogToRefresh.push(os);
        });
      }
      this.errorMessage = [];
      osCatalogToRefresh.forEach(os => {
        this.setAwsCredentialsAndFetchCatalog(os);
      });
    }
  }

  setAwsCredentialsAndFetchCatalog(Os:string)
  {
    if(this.app.sharedData.appInfo.workOffline)
      this.getAllTools(Os);
    else
    {
      let credResponse : Observable<QPMResponse>;
      QLogger.LogInfo(this.logSrc, "Fetching Credentials for icons");
      this.toolsIconRequestCount = 0;
      credResponse = this.webClient.getAwsTempCredentials();
      credResponse.subscribe(
        (cred:QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            //QLogger.LogInfo(this.logSrc, "Temp Cred Response - Response : " +JSON.stringify(cred));
          } 
          if(cred.isSuccess()){
            ToolsComponent.toolsIconCredentials = JSON.parse(cred.getData());
            this.iconS3 = ToolsComponent.getS3Bucket();
         }
         this.getAllTools(Os);
        });
    }
      
  }
  getAllTools(Os:string) {
    let response : Observable<QPMResponse>;
    this.loadingTools = true;
    this.productCatalog.products =[];

    // Get all installed tools - Offline mode
    if(this.app.sharedData.appInfo.workOffline)
    {
      QLogger.LogInfo(this.logSrc, "Getting installed tools");
      response = this.catalogClient.getInstalledTools();
      response.subscribe(
        (data:QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Refresh Catalog Response - Response : " +JSON.stringify(data));
          }
          if(data.isSuccess())
          {
             let installedPackageDetails:InstalledPackageDetails[];
             installedPackageDetails = [];
             this.installedProducts =[];
             installedPackageDetails = JSON.parse(data.getData())
             {
              installedPackageDetails.forEach((component) =>
              {
                if(component.ComponentType=="Core")
                {
                  const fs = window.require('fs');
                  const path = window.require('path');
                 
                  let logoLocation = 'assets/images/tool_icons/' + component.ProductID + ".png";
                  logoLocation = path.join(__dirname,logoLocation);
                  QLogger.LogInfo(this.logSrc,"Checking  "+logoLocation);
                  if (fs.existsSync(logoLocation))
                  {
                    component.logo = 'assets/images/tool_icons/' + component.ProductID + ".png";
                  }
                  else
                  component.logo = 'assets/images/DefaultBlueQ48.png';
                  this.installedProducts.push(component)
                }
              });
              this.installedProducts.sort((p1,p2) => p1.Name.localeCompare(p2.Name));
             }
          } 
          this.loadingTools = false;
      });
    }
    else
    {
      QLogger.LogInfo(this.logSrc, "Get all tools for "+Os);
      if(Os==null) {
        QLogger.LogInfo(this.logSrc,"No OS Selected");
        this.loadingTools = false;
        return;
      }
      response = this.webClient.getCatalogV3(this.app.sharedData.userInfo.username, Os);
      response.subscribe(
        (data:QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Refresh Catalog Response - Response : " +JSON.stringify(data));
          } 
          if(data.isSuccess()){
            QLogger.LogInfo(this.logSrc, "Refresh Catalog Response - Success");
            let obj = JSON.parse(data.getData());
            if(obj !== undefined || obj !== null) {
              let productCatalog:ProductCatalogV3 ;
              productCatalog=obj;

              // For each Suite
              productCatalog.productSuites?.forEach(suite => {
                // Save simplified name
                suite.simplifiedDisplayName = suite.displayName.replace(/[^\w\s]/gi, '')

                // Set default icon
                suite.logoHTML = 'assets/images/DefaultBlueQ48.png';

                // Get custom icon for this suite
                if (suite.logo !== undefined && suite.logo !== '') {
                  this.toolsIconRequestCount = this.toolsIconRequestCount+1;
                  this.getImage(suite.logo).then((img) => {
                    suite.logoHTML="data:image/jpeg;base64," + this.encode(img.Body);
                    this.toolsIconRequestCount = this.toolsIconRequestCount -1;
                    if(this.toolsIconRequestCount==0)
                    { 
                      this.filterCatalog();
                      this.organizeProducts();
                    }
                  }).catch(e => {
                      this.toolsIconRequestCount = this.toolsIconRequestCount -1;
                      if(this.toolsIconRequestCount==0)
                      { 
                        this.filterCatalog();
                        this.organizeProducts();
                      }
                      QLogger.LogError(this.logSrc, "Failed to get image: " + suite.logo)});
                  }               
              });

              productCatalog.subSuites?.forEach(subSuite => {
                // Save simplified name
                subSuite.simplifiedDisplayName = subSuite.displayName.replace(/[^\w\s]/gi, '')

                // Set default icon
                subSuite.logoHTML = 'assets/images/DefaultBlueQ48.png';

                // Get icon for this suite
                if (subSuite.logo !== undefined && subSuite.logo !== '') {
                  this.toolsIconRequestCount = this.toolsIconRequestCount+1;
                  this.getImage(subSuite.logo).then((img) => {
                    subSuite.logoHTML="data:image/jpeg;base64," + this.encode(img.Body);
                    this.toolsIconRequestCount = this.toolsIconRequestCount-1;
                    if(this.toolsIconRequestCount==0)
                    { 
                      this.filterCatalog();
                      this.organizeProducts();
                    }
                  }).catch(e => 
                    {
                        QLogger.LogError(this.logSrc, "Failed to get image: " + subSuite.logo);
                        this.toolsIconRequestCount = this.toolsIconRequestCount-1;
                        if(this.toolsIconRequestCount==0)
                        { 
                          this.filterCatalog();
                          this.organizeProducts();
                        }
                    });
                }              
              });

              // For each Product...
              productCatalog.products?.forEach((product, index) => 
              {
                // Save simplified name
                product.simplifiedDisplayName = product.displayName.replace(/[^\w\s]/gi, '')

                // Set default icon
                product.logoHTML = 'assets/images/DefaultBlueQ48.png';
                
                // Get icon for this product
                if (product.productLogo !== undefined && product.productLogo !== '') {
                  this.toolsIconRequestCount = this.toolsIconRequestCount+1;
                  this.getImage(product.productLogo).then((img) => {
                    product.logoHTML="data:image/jpeg;base64," + this.encode(img.Body);
                    this.toolsIconRequestCount = this.toolsIconRequestCount-1;
                    if(this.toolsIconRequestCount==0)
                    { 
                      this.filterCatalog();
                      this.organizeProducts();
                    }
                  }).catch(e => 
                     {
                        QLogger.LogError(this.logSrc, "Failed to get image: " + product.productLogo);
                        this.toolsIconRequestCount = this.toolsIconRequestCount-1;
                        if(this.toolsIconRequestCount==0)
                        { 
                          this.filterCatalog();
                          this.organizeProducts();
                        }
                     });
                }
              })
              this.productCatalogOsMap.set(Os, productCatalog);
            }
          }
          else {
            QLogger.LogError(this.logSrc, "Refresh Catalog Details - Failed");
            QLogger.LogError(this.logSrc, "Refresh Catalog Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
            let errorCode = data.getCode();
            this.errorMessage.push( "Failed to get catalog for " +  Os+" : "+data.getError())
            if(0===errorCode || 500==errorCode)
            {
              this.app.showNetworkError();
            }
          }

          let nextOs =  this.osSupported.shift();
          if(nextOs==null)
          {
            QLogger.LogInfo(this.logSrc,"Combining All Fetched Catalog");
            this.filterCatalog();
            this.organizeProducts();
            this.app.sharedData.service.tools.productCatalogOsMap = this.productCatalogOsMap;
            this.app.sharedData.service.tools.isCatalogInitialized = true;

            if (this.app.sharedData.appInfo.isElectronMode) { 
              this.getAllToolsVersions(Os);
            }
            else {
              this.loadingTools = false;
              if (this.app.sharedData.service.tools.triggerDirectLink !== '') {
                this.app.sharedData.service.tools.productCatalog = this.productCatalog;
                this.app.sharedData.service.tools.productCatalogOsMap = this.productCatalogOsMap;
                this.app.sharedData.service.tools.selectedTool = this.filteredProducts.find(prod => prod.productName == this.app.sharedData.service.tools.triggerDirectLink);
                if (this.app.sharedData.service.tools.selectedTool != undefined) {
                  if (this.showingSoftwareSource) {
                    this.router.navigate(['/main/source/details', this.app.sharedData.service.tools.triggerDirectLink]);
                  }
                  else {
                    if(this.app.sharedData.service.tools.selectedTool.productClassification===ProductClassification.CloOnly){
                      //ClO-Only Workflow
                      this.router.navigate(['/main/tools/clodetails',this.app.sharedData.service.tools.triggerDirectLink]);
                    }else{
                      this.router.navigate(['/main/tools/details', this.app.sharedData.service.tools.triggerDirectLink]);
                    }
                    
                  }
                }
                this.app.sharedData.service.tools.triggerDirectLink = '';
              }
            }
          }
          else{
            this.getAllTools(nextOs);
          }
          this.loadingTools = false;
      });
    }
  }
  getIdToInstalledVersionMap():Map<string, InstalledPackageDetails>{
    if(!this.app.sharedData.appInfo.isElectronMode)return;
    let productIdToInstalledVersionMap = new Map();
    let response = this.catalogClient.getInstalledTools();
    response.subscribe(//blocking
      (data:QPMResponse) => {
        if(data.isSuccess()) {
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            for(let elem of obj){
              if(elem.ProductID && elem.Version){
                productIdToInstalledVersionMap.set(elem.ProductID, elem);
              }
            }
            
          }
        } 
        else{
          QLogger.LogError(this.logSrc, `Error while getting installation information of tools. Error Code: ${data?.getCode()}, Error : ${data?.getError()}, ErrorDetails : ${data?.getErrorDetail()} `);
        }
      });
      return productIdToInstalledVersionMap;

  }
  
  getAllToolsVersions(Os:string) {
    if (!this.app.sharedData.appInfo.isElectronMode || this.app.sharedData.service.tools.selectedOSs.length != 1) {
      return;
    }
    let prodIdToInstalledVersionMap:Map<string, InstalledPackageDetails> = this.getIdToInstalledVersionMap();

    if(this.productCatalogOsMap.has(Os)) {
      let localCatalog = this.productCatalogOsMap.get(Os);
      let obsList:Observable<{index:number; data:QPMResponse}>[] = new Array();
      localCatalog?.products?.forEach((product, index) =>  
      {
        // No need to fetch versions for products that are not visible
        if ((!this.showingSoftwareSource && product.productClassification === "Source-code")||
            (this.showingSoftwareSource && product.productClassification !== "Source-code")) {
          return;
        }

        let installedVersion = prodIdToInstalledVersionMap.has(product.productUUID)?prodIdToInstalledVersionMap.get(product.productUUID):undefined;
        this.updateLocalCatalogWithInstallInfo(localCatalog, index, installedVersion);

        //Check whether eTag outdated, if not retain releases info
        if(this.productCatalogETagMap.get(product.productUUID) === product.etag){
          let productPrevious = this.productCatalogETagProductMap.get(product.productUUID);
          if(productPrevious !== undefined){
            localCatalog.products[index].latestRelease = productPrevious.latestRelease;
            return;
          }
        }

        localCatalog.products[index].latestRelease = undefined;
        let response = this.webClient.getReleasesV3(product.productUUID, this.app.sharedData.userInfo.username, Os, 1).pipe(map(data => ({index: index, data: data})));
        obsList.push(response);
      });

      const observable = forkJoin((obsList));
      observable.subscribe((responses) => {
        for (let response of responses){
          let index = response.index;
          let data = response.data;
          if(data.isSuccess()){
            let obj =  JSON.parse(data.getData());
            if(this.hasAValidRelease(obj)) {
              this.updateLocalCatalogWithLatestReleaseInfo(localCatalog, index, obj);
            }
            else{
              localCatalog.products[index].latestRelease = undefined;
            }
            this.productCatalogETagProductMap.set(localCatalog.products[index].productUUID, localCatalog.products[index]);
          }
          else{
            QLogger.LogError(this.logSrc, `Error while getting latest release for product:${localCatalog.products[index].productName}. ${this.getObserVableErrorResponseString(data)}`);
            let errorCode = data.getCode();
              if(0===errorCode || 500==errorCode){
                this.app.showNetworkError();
              }
          }
        }
        let validProductsList:ProductV3[] = new Array();
        for(let i = 0; i < localCatalog.products.length; i++){
          if(localCatalog.products[i].latestRelease)
          {
            validProductsList.push(localCatalog.products[i]);
          }
        }
        localCatalog.products = validProductsList;
        this.filterCatalog();
        this.organizeProducts(); 
        this.loadingTools = false;
        });
   
    }
  }
  updateLocalCatalogWithInstallInfo(localCatalog:ProductCatalogV3, index:number, installedVersion:InstalledPackageDetails)
  {
    localCatalog.products[index].installedVersion = installedVersion;
  }
  updateLocalCatalogWithLatestReleaseInfo(localCatalog:ProductCatalogV3, index:number, latestRelease:ReleasesV3)
  {
    localCatalog.products[index].latestRelease = latestRelease.releases[0];

    // Parse date only
    let test = localCatalog.products[index].latestRelease?.releaseDateUTC;
    let latestVersion = localCatalog.products[index].latestRelease?.version;
    localCatalog.products[index].latestRelease.releaseDateUTC = test.substring(0, test.indexOf(' '));
  }
  getObserVableErrorResponseString(response:QPMResponse)
  {
    return `Error Code: ${response?.getCode()}, Error: ${response?.getError()}, ErrorDetails: ${response?.getErrorDetail()}`;
  }
  hasAValidRelease(obj)
  {
     return (obj !== undefined && obj !== null && obj.releases !== undefined && obj.releases.length > 0);

  }
  filterCatalog()
  {
    this.loadingTools = true;
    this.productCatalog.products = [];
    this.productCatalog.productSuites = [];
    this.productCatalog.subSuites = [];
    this.filteredProducts = [];
    let showInternal:boolean = true;
    let showExternal:boolean = true;
    let filteredOS:string[]=[...this.app.sharedData.service.tools.selectedOSs];
    if(this.app.sharedData.service.tools.filteredVisibility.length==1)
    {
      if(this.app.sharedData.service.tools.filteredVisibility[0]=='Internal')
      {
          showInternal = true;
          showExternal = false;
      }
      else
      {
          showInternal = false;
          showExternal = true;
      }
    }
    if(this.app.sharedData.service.tools.filteredOSs.length>0){
      filteredOS =[...this.app.sharedData.service.tools.filteredOSs];
    }
    if(this.app.sharedData.service.tools.filteredOSs.length==0){
      filteredOS=[];
      filteredOS.push(selectableOSs.WINDOWS);
      filteredOS.push(selectableOSs.LINUX);
      filteredOS.push(selectableOSs.MACOS);
    }
    filteredOS.forEach(Os =>
    {
        let localCatalog:ProductCatalogV3;
        let suiteInfo:ProductSuiteV3;
        let subsuiteInfo:ProductSubSuiteV3;
        if(this.productCatalogOsMap.has(Os))
        localCatalog = this.productCatalogOsMap.get(Os);
        localCatalog?.products?.forEach(product=>
        {
          if(showInternal && !showExternal)
          {
              if(product.isInternal) {
                this.filterProducts(product,Os);
              }  
          }
          else if(showExternal && !showInternal)
          {
              if(!product.isInternal){
                this.filterProducts(product,Os);
              }
          }
          else
              this.filterProducts(product,Os);
        });
        localCatalog?.productSuites?.forEach(suite=> {
          let suiteDetails = JSON.parse(JSON.stringify(suite));
          suiteInfo = this.productCatalog.productSuites?.find(thisSuite => thisSuite.id === suite.id )
          if (undefined == suiteInfo) {
            suiteDetails.productOS = [];
            suiteDetails.productOS.push(Os);
            suiteDetails.selectedOS = Os;
            this.productCatalog.productSuites.push(suiteDetails);
          }
        });
        localCatalog?.subSuites?.forEach(subsuite=> {
          let subsuiteDetails = JSON.parse(JSON.stringify(subsuite));
          subsuiteInfo = this.productCatalog.subSuites?.find(thisSuite => thisSuite.uuid === subsuite.uuid )
          if (undefined == subsuiteInfo) {
            subsuiteDetails.productOS = [];
            subsuiteDetails.productOS.push(Os);
            subsuiteDetails.selectedOS = Os;
            this.productCatalog.subSuites.push(subsuiteDetails);
          }
        });

    });

    //this.loadingTools = false;
    if(this.app.sharedData.service.tools.shownTools==ToolsViewTypes.All)
      this.filteredProducts = this.productCatalog.products;
    else if(this.app.sharedData.service.tools.shownTools == ToolsViewTypes.Installed)
      this.filteredProducts = this.productCatalog.products.filter(prod => prod.installedVersion?.Version !== undefined || prod.installedVersion?.InstalledVersions?.length > 0);
    else 
      this.filteredProducts = this.productCatalog.products.filter(prod => (prod.installedVersion?.Version != undefined && prod.installedVersion?.Version !== prod.latestRelease?.version) || (prod.installedVersion?.InstalledVersions?.length > 0 && prod.installedVersion?.InstalledVersions?.find( ver => 
        {
          if(this.app.sharedData.appInfo.platform == 'win32')
            ver.version == prod.latestRelease?.version
          else
            ver.Version == prod.latestRelease?.version
        }) == undefined));
    
    this.readConfigAndInstall();
    this.loadingTools = false;
  }

  readConfigAndInstall()
  {
    if(this.app.sharedData.qikParams.installParams.length>0)
    {
      if(!this.installer.isInstallInProgress){

        try{

          let installConfig = this.app.sharedData.qikParams.installParams.shift();
          //installConfig = "QPM3.3.0.39.3.Windows-AnyCPU_TestProductA_Windows_0.0.0.64-Alpha1+rc26.exe"; // JB: To be removed
          if(installConfig.indexOf('_')==-1) return;
          var installParams = installConfig.split("_");

          let version;
          if(installParams[3].indexOf('(')==-1)
          version = ( installParams[3]).substr(0,(installParams[3]).lastIndexOf('.'));
          else
            version = ( installParams[3]).substr(0,(installParams[3]).lastIndexOf('('));

          let downloadProductInfo ={	
            selectedOS:installParams[2],	
            version:version,	
            productName: installParams[1]	
          };

          QLogger.LogInfo(this.logSrc,"Installing Product "+ downloadProductInfo.productName +" for "+downloadProductInfo.selectedOS+" Version "+downloadProductInfo.version);
          this.productCatalog.products.forEach(product =>{

            if(product.productName==downloadProductInfo.productName) {
                this.selectedOS = downloadProductInfo.selectedOS;
                this.selectedOS = product.selectedOS;
                this.osOptions = [{
                  label: this.selectedOS, value: this.selectedOS, disabled: false
                }];
                product.productOS.forEach(Os =>{
                  if(Os!==this.selectedOS)
                  {
                    this.osOptions.push({
                    label: Os, value: Os, disabled: false
                    });
                  }
                });

                this.app.sharedData.service.tools.productCatalog = this.productCatalog;
                this.app.sharedData.service.tools.productCatalogOsMap = this.productCatalogOsMap;
                this.app.sharedData.service.tools.productCatalogETagMap = this.productCatalogETagMap;
                this.app.sharedData.service.tools.productCatalogETagProductMap = this.productCatalogETagProductMap;
                this.app.sharedData.service.tools.selectedTool = product;
                this.app.sharedData.service.tools.triggerDeepLinkInstall = true;
                this.app.sharedData.service.tools.downloadProductInfo = downloadProductInfo;
                if (this.showingSoftwareSource) {
                  this.router.navigate(['/main/source/details',product.productName]);
                }
                else  {
                  this.router.navigate(['/main/tools/details',product.productName]);
                }
                return;
            }
          });

          QLogger.LogInfo(this.logSrc,"User is not entitled to product "+installParams[1]);
        }
        catch(e)
        {
          QLogger.LogInfo(this.logSrc, "Failed to find package to install");
        }
      }
      else
      { 
        this.app.showMessageBox("Install", "Another Install is in progress", "Ok");
      }  
    }
  }
  
  organizeProducts() {
    this.loadingTools = true;
    this.toolsRows = [];
    let skey: string;
    let sskey: string;
    let pkey: string;
    this.productCatalog.productSuites?.forEach((suite) => {

      let suiteRow : TreeNode;
      skey = suite.displayName + suite.name + suite.simplifiedDisplayName + suite.description;
      suiteRow = {data:{rowType: RowType.Suite, displayName: suite.displayName, suiteInfo: suite, key: skey}, children:[]};

      // Find products (in filteredProducts) that are within this suite, add rows
      let productsInSuite = this.filteredProducts.filter(product => product.productSuites.find(productSuite => productSuite == suite.id));
      !this.app.sharedData.appInfo.isElectronMode && productsInSuite ? productsInSuite = this.filterMultipleOS(productsInSuite) : null;
        productsInSuite?.forEach( prod => {   
          pkey = prod.displayName + prod.productName + prod.simplifiedDisplayName + prod.description + skey;
          if (this.showingSoftwareSource && prod.productClassification === "Source-code") {
            suiteRow.children.push({data:{rowType: RowType.Product, displayName:prod.displayName, productInfo: prod, key: pkey}, children:[]});
          }
          else if (!this.showingSoftwareSource && prod.productClassification !== "Source-code") {
            suiteRow.children.push({data:{rowType: RowType.Product, displayName:prod.displayName, productInfo: prod, key: pkey}, children:[]});
          }     
        });
      
        // Find subsuites that are within this suite
        let subsuitesInSuite = this.productCatalog.subSuites.filter(subsuite => suite.subSuiteUuids.find(uuid => uuid == subsuite.uuid));
        if (subsuitesInSuite.length !== 0) {
          subsuitesInSuite?.forEach( subsuite => {
            let subSuiteRow : TreeNode;
            sskey = subsuite.displayName + subsuite.name + subsuite.simplifiedDisplayName + subsuite.description + skey;
            subSuiteRow = {data:{rowType: RowType.SubSuite, displayName: subsuite.displayName, subsuiteInfo: subsuite, key: sskey}, children:[]};
            let productsInSubsite = this.filteredProducts.filter(product => product.subSuiteUuids.find(subSuiteID => subSuiteID == subsuite.uuid));
            !this.app.sharedData.appInfo.isElectronMode && productsInSubsite ? productsInSubsite = this.filterMultipleOS(productsInSubsite) : null;
            productsInSubsite?.forEach( product => {
              pkey = product.displayName + product.productName + product.simplifiedDisplayName + product.description + sskey;
              if (this.showingSoftwareSource && product.productClassification === "Source-code") {
                subSuiteRow.children.push({data:{rowType: RowType.Product, displayName:product.displayName, productInfo: product, key: pkey}, children:[]});
              }
              else if (!this.showingSoftwareSource && product.productClassification !== "Source-code") {
                subSuiteRow.children.push({data:{rowType: RowType.Product, displayName:product.displayName, productInfo: product, key: pkey}, children:[]});
              }   
            });
            subSuiteRow.children?.sort((p1,p2) => p1.data?.displayName.localeCompare(p2.data?.displayName));
            suiteRow.children?.sort((p1,p2) => p1.data?.displayName.localeCompare(p2.data?.displayName));

            if(subSuiteRow.children?.length !== 0){
              suiteRow.children.push(subSuiteRow);
            }
          });
        }
        if(suiteRow.children?.length !== 0){
          this.toolsRows.push(suiteRow);
        }
      
    })
    !this.app.sharedData.appInfo.isElectronMode && this.filteredProducts ? this.filteredProducts = this.filterMultipleOS(this.filteredProducts) : null;
    this.filteredProducts?.forEach((product) => {
      // Add row for any solo product
      pkey = product.displayName + product.productName + product.simplifiedDisplayName + product.description;
      if (this.showingSoftwareSource && product.productSuites.length == 0 && product.subSuiteUuids.length == 0 && product.productClassification === "Source-code") {
        this.toolsRows.push({data:{rowType: RowType.Product, displayName:product.displayName, productInfo: product, key: pkey}, children:[]});
      }
      else if (product.productSuites?.length == 0 && product.subSuiteUuids.length == 0 && !this.showingSoftwareSource && product.productClassification !== "Source-code") {
        this.toolsRows.push({data:{rowType: RowType.Product, displayName:product.displayName, productInfo: product, key: pkey}, children:[]});
      }   
    });
    this.toolsRows.sort((p1,p2) => p1.data?.displayName.localeCompare(p2.data?.displayName));
    this.loadingTools = false;
  }

  filterMultipleOS(products: ProductV3[]) {
    let filteredOSProducts : ProductV3[] = [];
    let checkBoxValues = this.app.sharedData.service.tools.filteredOSs; //string[]
    if(checkBoxValues == null){
      return products;
    }
    checkBoxValues = checkBoxValues.sort();
      products.forEach(product => {
        let productOSvalues = product.productOS;
        productOSvalues = productOSvalues.sort();
        if(this.utils.arraysEqual(productOSvalues, checkBoxValues)){
          filteredOSProducts.push(product);
        }
      });
    return filteredOSProducts;
  }

  onToolRowSelect(event) {
    let rowNode = event.node as TreeNode;
    let rowData = rowNode.data;
    this.app.sharedData.service.tools.showCloDetails = false;
    this.app.sharedData.service.tools.showDetails = false;
    this.app.sharedData.service.tools.loadFromDetails = false;
    if (rowData.rowType === 'Product') {
      QLogger.LogInfo(this.logSrc, "Selected product: " + rowData.displayName);

      this.app.sharedData.service.tools.productCatalog = this.productCatalog;
      this.app.sharedData.service.tools.productCatalogOsMap = this.productCatalogOsMap;
      this.app.sharedData.service.tools.productCatalogETagMap = this.productCatalogETagMap;
      this.app.sharedData.service.tools.productCatalogETagProductMap = this.productCatalogETagProductMap;      
      this.app.sharedData.service.tools.selectedTool = rowData.productInfo;
      if(this.app.sharedData.service.tools.selectedTool.productClassification===ProductClassification.CloOnly){
        //ClO-Only Workflow
        this.app.location.replaceState('/main/tools/clodetails/' + rowData.productInfo.productName);
        this.app.sharedData.service.tools.showCloDetails = true;
      }else{
        if (this.showingSoftwareSource && rowData.productInfo.productName) {
          this.app.location.replaceState('/main/source/details/' + rowData.productInfo.productName);
        }
        else  {
          this.app.location.replaceState('/main/tools/details/' + rowData.productInfo.productName);
        }
        this.app.sharedData.service.tools.showDetails = true;
      }
      this.app.sharedData.service.tools.loadFromTools = true;
    }
  }

  onInstalledToolRowSelect(event)
  {
    QLogger.LogInfo(this.logSrc,"Selected product: " + event.data.Name);
    this.app.sharedData.service.tools.selectedProductInstallDetails = event.data;
    
    this.app.sharedData.service.tools.selectedProductInstallDetails = this.app.sharedData.service.tools.selectedProductInstallDetails;

    if (this.showingSoftwareSource) {
      this.router.navigate(['/main/source/details',this.app.sharedData.service.tools.selectedProductInstallDetails?.ProductName]);
    }
    else  {
      this.router.navigate(['/main/tools/details',this.app.sharedData.service.tools.selectedProductInstallDetails?.ProductName]);
    }
  }

  onToolsLandingMenuChanged(){
    this.toolsLandingMenuActiveItem = this.landingMenu['activeItem'];
    // All
    if (this.toolsLandingMenuActiveItem.label === 'All') {
      this.app.sharedData.service.tools.shownTools = ToolsViewTypes.All;

    }
    // Installed
    else if (this.toolsLandingMenuActiveItem.label === 'Installed') {
      this.app.sharedData.service.tools.shownTools = ToolsViewTypes.Installed;
    }
    // Upgrades
    else if (this.toolsLandingMenuActiveItem.label === 'Updates Available') {
      this.app.sharedData.service.tools.shownTools = ToolsViewTypes.Upgrades;
    }
    this.filterCatalog();
    this.organizeProducts();
    this.toolsTable?.filterGlobal(this.app.sharedData.service.tools.toolsFilter, 'contains');
  }

  onAddLicenseClicked() {
    this.showAddLicenseDialog = true;
    this.uiLicenseGroupID = "";
  }
  
  onActivateGroupButtonClicked(event) {
    this.uiLicenseGroupID.replace(/\s/g, "");
    this.ActivateLicense(this.uiLicenseGroupID);
    this.uiLicenseGroupID = "";
  }

  onCancelAddLicenseClicked() {
    this.showAddLicenseDialog = false;
  }

  ActivateLicense( licenseGroupID ) {
    let response : Observable<QPMResponse>;

    // Get all licenses for this product
    QLogger.LogInfo(this.logSrc, "Activate License Group : " + licenseGroupID);
    response = this.webClient.activateLicense("", licenseGroupID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Activate License Group Response - Response : " + JSON.stringify(data));
        } 
        if(data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Activate License Group Response - Success");
          this.licenseErrorMessage = "Successfully added license";
        }
        else {
          QLogger.LogError(this.logSrc, "Activate License Group - Failed");
          QLogger.LogError(this.logSrc, "Activate License Group - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.licenseErrorMessage = "Failed to activate license group: " + data.getError();
        }
      });
  }

  onClearLicenseError() {
    this.licenseErrorMessage = "";
  }

}

