import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import isElectron from 'is-electron';
import { Observable, Subscription } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { commType } from 'src/app/common/shared-data';
import { Utils } from 'src/app/common/utils';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { InstallComponent } from '../install/install.component';
import {Location} from '@angular/common'; 
import { ProductDetails, ProductV3, ReleaseV3 } from 'src/app/models/lime-web-client';
import { SelectItem, MenuItem,Message } from 'primeng/primeng';
import { QPMResponse } from 'src/app/models/response';
import { CloProductRelease, ManifestFileAndReleaseNoteInfo, ReleaseNotesDetails } from 'src/app/models/catalog-client';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-clo-details',
  templateUrl: './clo-details.component.html',
  styleUrls: ['./clo-details.component.css']
})
export class CloDetailsComponent implements OnInit {
  private logSrc:string = "CloDetails-Component";
  private subscriptionSrc:string = "CloDetails-Component";
  subscription: Subscription;
  private toolRefreshSubscription: Observable<boolean>;
  private toolRefreshLicenseSubscription: Observable<boolean>;

  private catalogClient: CatalogClientService;
  private webClient : WebClientService;
  private limeClient : ClientService;
  private telematicsClient: TelematicsClientService;
  private ipcRenderer;

  selectedProduct: ProductV3;//Other prodyct Details
  selectedCLOProductReleases: CloProductRelease[];//Load all Releases
  releaseVersions: SelectItem[];//dropdown
  selectedCloProductRelease:CloProductRelease;//single Release Info
  loadingSelectedCLOProductDetails: boolean;

  selectedVersion: ReleaseV3;//selection Of Dropdown

  constructor(private router : Router, private activatedRoute : ActivatedRoute,private location: Location,private http : HttpClient,
    public app : AppMainComponent, private utils : Utils, 
    private service : DataServiceProducer,
    public zone : NgZone, public installer : InstallComponent) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
   
    if(isElectron()){
      this.ipcRenderer = window.require('electron').ipcRenderer;
    }
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "CloDetails Component Initialization");
    this.app.setUserInfo();
    this.app.sharedData.service.common.master = false;
    this.app.sharedData.resetVisibility();
    this.app.sharedData.setVisibility();
    this.resetSelectedCLOReleaseInfoProcess();
    //Is it direct link to this CLO-page?
    if (this.app.sharedData.service.tools.selectedTool.productUUID === undefined) {
      this.activatedRoute.params.subscribe(params => {
        let toolName = params['id'];
        this.app.sharedData.service.tools.triggerDirectLink = toolName;
        if (this.router.url.includes("source")) {
          this.router.navigate(['/main/source/find']);
        }
        else {
          this.router.navigate(['/main/tools/find']);
        }
        
      });
      return;
    }
    
    this.selectedProduct = this.app.sharedData.service.tools.selectedTool;
    if (this.selectedProduct.logoHTML === undefined || this.selectedProduct.logoHTML === '') {
      this.selectedProduct.logoHTML = 'assets/images/DefaultBlueQ48.png';
    } 
    this.selectedCLOProductReleases = [];
    this.releaseVersions=[];
    this.selectedCloProductRelease=new CloProductRelease();
    this.getCLOReleases(this.selectedProduct.productUUID);
  }

  //GET CLO Releases
  getCLOReleases(productUUID:string){
    let response : Observable<QPMResponse>;
    this.releaseVersions = [];
    this.loadingSelectedCLOProductDetails = true;
    this.resetSelectedCLOReleaseInfoProcess();
   
    QLogger.LogInfo(this.logSrc, "Get CLO Product Releases : " + this.selectedProduct.productUUID);
    response = this.webClient.getCloReleases(this.selectedProduct.productUUID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get CLO Product Releases - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get CLO Product Releases - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined && obj !== null) {
            this.selectedCLOProductReleases = obj?.cloProductRelease as CloProductRelease[];
            this.selectedCLOProductReleases?.sort((releaseA, releaseB) => (Utils.prototype.semVerGte(releaseA.version, releaseB.version) ? -1 : 1))
            this.selectedCLOProductReleases?.forEach((thisPackage,index) => {
              if(thisPackage?.version != "0.0.0.0") {
                let versionLabel = thisPackage.version
                this.releaseVersions.push({
                  label: "v" + versionLabel,
                  value: thisPackage,
                  disabled: false
                });
              }
            });
            if(this.selectedCLOProductReleases?.length > 0) {
              this.selectedCloProductRelease = this.selectedCLOProductReleases[0];
              this.selectedCLOReleaseInfoProcess.cloProductRelease=this.selectedCloProductRelease;
              this.selectedCLOReleaseInfoProcess.version=this.selectedCloProductRelease?.version;
              let gitUrlArr=JSON.parse(this.selectedCloProductRelease?.gitUrl);
              this.selectedCLOReleaseInfoProcess.gitUrl=gitUrlArr[0];
              this.selectedCLOReleaseInfoProcess.publishEndDate=this.utils.convertDatetimestampIntoYYYYMMDD(this.selectedCloProductRelease?.publishEndTimestamp);
              
            }else{
              this.selectedCloProductRelease=undefined;
            }
            
            //call for ManifestFile And ReleaseNote
            if(this.selectedCloProductRelease!=undefined)
            {
              this.getManifestFileAndReleaseNote(this.selectedCloProductRelease.productUuid,this.selectedCloProductRelease.version);
            }else{
              QLogger.LogInfo(this.logSrc,"Version "+this.app.sharedData.service.tools.downloadProductInfo.version+" not valid for "+this.selectedProduct.displayName);
            }
            this.loadingSelectedCLOProductDetails = false;
          }
          this.loadingSelectedCLOProductDetails = false;
        }
        else {
          QLogger.LogError(this.logSrc, "Get Latest Version - Failed");
          QLogger.LogError(this.logSrc, "Get Latest Version - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          let errorCode = data.getCode();
          if(0===errorCode || 500==errorCode){
            this.app.showNetworkError();
          }
          this.loadingSelectedCLOProductDetails = false;
        }
        this.loadingSelectedCLOProductDetails = false;
    });
  }

  //GET Manifestfile and Release Note
  getManifestFileAndReleaseNote(productUuid:string,version:string){
    let response : Observable<QPMResponse>;
    this.loadingSelectedCLOProductDetails = true;
   
    QLogger.LogInfo(this.logSrc, "Get Manifest File and Release Note Info For ProductID: " + productUuid +" Version : "+version);
    response = this.webClient.getManifestFileAndReleaseNote(productUuid,version);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Manifest File and Release Note Info - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Manifest File and Release Note Info - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined && obj !== null) {
            this.selectedCLOReleaseInfoProcess.manifestFileAndReleaseNoteInfo = obj;
            this.selectedCLOReleaseInfoProcess.manifestFileUrl=this.selectedCLOReleaseInfoProcess?.manifestFileAndReleaseNoteInfo.manifestFileUrl;
            this.selectedCLOReleaseInfoProcess.releaseNoteFileUrl=this.selectedCLOReleaseInfoProcess?.manifestFileAndReleaseNoteInfo.releaseNoteFileUrl;
            this.loadingSelectedCLOProductDetails = false;
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Latest Version - Failed");
          QLogger.LogError(this.logSrc, "Get Latest Version - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          let errorCode = data.getCode();
          if(0===errorCode || 500==errorCode){
               this.app.showNetworkError();
          }
          this.loadingSelectedCLOProductDetails = false;
        }
        this.loadingSelectedCLOProductDetails = false;
    });

  }


  ngOnDestroy() {
    QLogger.LogInfo(this.logSrc, "CloDetails Component Destroy"); 
    this.app.sharedData.unsubscribeCommunication(commType.RefreshTools, this.subscriptionSrc);
    this.app.sharedData.unsubscribeCommunication(commType.RefreshLicenses, this.subscriptionSrc);
  }

  goBack() {
    QLogger.LogInfo(this.logSrc,"Back From CloDetails Page");
    if(this.app.sharedData.service.tools.loadFromDetails){       
      if (this.router.url.includes("source")) {
        this.app.location.replaceState('/main/source/details/' + this.selectedProduct.productName);
      }
      else {
        this.app.location.replaceState('/main/tools/details/' + this.selectedProduct.productName);
      }
      this.app.sharedData.service.tools.showCloDetails = false;
      this.app.sharedData.service.tools.showDetails = true;
      this.app.sharedData.service.tools.loadFromDetails = false;
    }
    else if(this.app.sharedData.service.tools.loadFromTools){ 
      if (this.router.url.includes("source")) {
        this.app.location.replaceState('/main/source/find');
      }
      else {
        this.app.location.replaceState('/main/tools/find');
      }
      this.app.sharedData.service.tools.loadFromTools = false;
    }
    else{
      QLogger.LogInfo(this.logSrc,"Back To Previous Visited Page");  
      this.location.back();
    }
  }
  goToToolDetails(){
    QLogger.LogInfo(this.logSrc,"Go To Tools-Details Page For : "+this.app.sharedData.service.tools.selectedTool.productName);
    if(this.app.sharedData.service.tools.loadFromDetails){     
      this.goBack();
    }
    else{
      this.router.navigate(['/main/tools/details',this.app.sharedData.service.tools.selectedTool.productName]);
    }

  }

  onVersionChange(){
    this.resetSelectedCLOReleaseInfoProcess();                
    this.selectedCLOReleaseInfoProcess.cloProductRelease=this.selectedCloProductRelease;
    this.selectedCLOReleaseInfoProcess.version=this.selectedCloProductRelease?.version;
    let gitUrlArr=JSON.parse(this.selectedCloProductRelease?.gitUrl);
    this.selectedCLOReleaseInfoProcess.gitUrl=gitUrlArr[0];
    this.selectedCLOReleaseInfoProcess.publishEndDate=this.utils.convertDatetimestampIntoYYYYMMDD(this.selectedCloProductRelease?.publishEndTimestamp);
    this.getManifestFileAndReleaseNote(this.selectedCloProductRelease.productUuid,this.selectedCloProductRelease.version);

  }

   //#region CLOReleaseInformationProcess
   selectedCLOReleaseInfoProcess:{
    cloProductRelease:CloProductRelease;
    manifestFileAndReleaseNoteInfo:ManifestFileAndReleaseNoteInfo;
    gitUrl:string;
    version:string;
    manifestFileUrl: string;
    releaseNoteFileUrl: string;
    publishEndDate:string;
    isCopied:boolean;
  }
  resetSelectedCLOReleaseInfoProcess(){
    this.selectedCLOReleaseInfoProcess ={
      cloProductRelease:undefined,
      manifestFileAndReleaseNoteInfo:undefined,
      gitUrl:'',
      manifestFileUrl:'',
      releaseNoteFileUrl:'',
      version:'',
      publishEndDate:'',
      isCopied:false
    }
  }
  copyCLOCommand(text: string){
    this.selectedCLOReleaseInfoProcess.isCopied=true;
    const cb=navigator.clipboard;
     cb.writeText(text).then(()=>{
      this.selectedCLOReleaseInfoProcess.isCopied=true;
    })
    setTimeout(() => {
      this.selectedCLOReleaseInfoProcess.isCopied=false;
    }, 2000); 
  }


  downloadFiles(s3FileUrl:string){
    QLogger.LogInfo(this.logSrc,"Downloading File  :"+"Started");
    if(s3FileUrl!=undefined){
      if(this.app.sharedData.appInfo.isElectronMode){
        this.app.goToUrl(s3FileUrl);
      }else{
        let fileDownloadWin=window.open(s3FileUrl, '_blank');
        if(!fileDownloadWin || fileDownloadWin.closed || typeof fileDownloadWin.closed=='undefined'){ 
          this.app.showMessageBox("Popup Blocker Detection", "Please disable browser popup blocker", "Ok");
        }
      }
  
    }else{
      this.app.showMessageBoxMultiLine("File Download Failed", ['File download failed for URL'+s3FileUrl], "Ok");
    }
    
  }

}
