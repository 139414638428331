import { Component, OnInit } from '@angular/core';
import { environment } from './../../../../environments/environment';

@Component({
  selector: 'app-internaluserguide',
  templateUrl: './internaluserguide.component.html',
  styleUrls: ['./internaluserguide.component.css']
})
export class InternaluserguideComponent implements OnInit {
  environment;
  constructor() {
    this.environment = environment;
   }

  ngOnInit(): void {
  }

}
