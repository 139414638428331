import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from 'src/app/app.main.component';

@Component({
  selector: 'app-userguide',
  templateUrl: './userguide.component.html',
  styleUrls: ['./userguide.component.css']
})
export class UserguideComponent implements OnInit {

  constructor(public app : AppMainComponent) { }

  ngOnInit(): void {
  }

}
