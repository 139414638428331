import { Injectable } from '@angular/core';
import { ServiceUtils } from '../ServiceUtils';
import { ApiUrls } from '../ApiUrls';
import { RestResponse } from 'src/app/models/response';
import { Observable } from 'rxjs';
import { DistributionRequest ,DiffDistributionRequest, ImageDistributionRequest, ImageDiffDistributionRequest, ImageBuildDistributionRequest} from 'src/app/models/lime-web-client';
import { SoftwareProductMetaDeta,DownloadStatusRequest, DownloadImageStatusRequest } from 'src/app/models/distribution-client';

@Injectable({
  providedIn: 'root'
})
export class RestDistributionClientService {

  constructor(private serviceUtils : ServiceUtils, private apiUrls : ApiUrls) { 
  }

  getVersion(): Observable<RestResponse> {
    return null;
  }

  requestDownloadSoftwareImage(request : DistributionRequest): Observable<RestResponse> {
    
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.downloadSoftwareProduct, request);
    return observable;
  } 
  processDownloadSoftwareImage(softwareProductID : string, product : string,branch : string, tag : string, distroType : string,distrofolderName:string,downloadLocation:string, response : string): Observable<RestResponse> 
  {
    return null;
  }

  requestDryRunSoftwareImage(request : DistributionRequest): Observable<RestResponse> {
                               
     var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.downloadSoftwareProduct, request);
     return observable;
  }

  shipSoftwareProduct(request:DistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.shipSoftwareProduct, request);
    return observable;
  }

  getDownloadedSoftware(): Observable<RestResponse> {
    return null;
  }

  generateProductMetadata(buildId : string, distroType : string, distrofolderName:string,syncResultJson : SoftwareProductMetaDeta): Observable<RestResponse> {
    return null;
  }
  deleteProductMetadata(productId: string,buildId : string, distroType : string, distrofolderName:string): Observable<RestResponse> {
     return null;
   }
   getDownloadedSoftwareBuildDetail(buildId : string, distroType : string): Observable<RestResponse> {
    return null;
  }

  diffShipSoftwareProduct(request:DiffDistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.diffShipSoftwareProduct, request);
    return observable;
  }
  diffDownloadSoftwareProduct(request:DiffDistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.diffDownloadSoftwareProduct, request);
    return observable;
  }
  processDiffDownloadSoftwareImage(baseSoftwareProductID : string, targetSoftwareProductID:string,downloadLocation:string,product : string,branch : string, tag : string, distroType : string,distrofolderName:string, response : string):Observable<RestResponse> 
  {
      return null;
  }

  getDefaultDownloadLocation(): Observable<RestResponse> {
      return null;
  }
  getDefaultMetaDownloadLocation(): Observable<RestResponse> {
      return null;
  }
  saveDownloadHistory(): Observable<RestResponse> {
    return null;
  }
  getDownloadedHistoryDetails(): Observable<RestResponse> {
    return null;
  }
  updateDownloadStatus(request:DownloadStatusRequest): Observable<RestResponse> 
  {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.updateDownloadStatus, request);
    return observable;
  }
  updateDiffDownloadStatus(request:DownloadStatusRequest): Observable<RestResponse> 
  {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.updateDiffDownloadStatus, request);
    return observable;
  }

  downloadSoftwareImage(request:ImageDistributionRequest): Observable<RestResponse> 
  {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.downloadSoftwareImage, request);
    return observable;
  }
  updateImageDownloadStatus(request:DownloadImageStatusRequest): Observable<RestResponse> 
  {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.updateImageDownloadStatus, request);
    return observable;
  }
  diffDownloadSoftwareImage(request:ImageDiffDistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.diffDownloadSoftwareImage, request);
    return observable;
  }
  updateDiffImageDownloadStatus(request:DownloadImageStatusRequest): Observable<RestResponse> 
  {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.updateDiffImageDownloadStatus, request);
    return observable;
  }
  shipSoftwareImage(request:ImageBuildDistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.shipSoftwareImage, request);
    return observable;
  }
  downloadSoftwareImageBuild(request:ImageDistributionRequest): Observable<RestResponse> 
  {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.downloadSoftwareImageBuild, request);
    return observable;
  }
  replayDiffShipSoftwareProduct(request:DiffDistributionRequest): Observable<RestResponse> {
    var observable = this.serviceUtils.postObservableRestResponse(this.apiUrls.replayDiffShipSoftwareProduct, request);
    return observable;
  }

}
