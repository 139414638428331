<nav class="navbar navbar-expand-md bg-dark navbar-dark fixed-top">
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLink="/main/software">Software</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/main/tools">Tools</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/main/document">Document</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="logout()" >Logout</a>
        </li>
      </ul>
    </div>
  </nav>