<div class="topbar clearfix">
    <div class="topbar-left">
        <div class="logo">Qualcomm Package Manager</div>
    </div>

    <div class="topbar-right">
        <a id="menu-button" href="#" (click)="app.onMenuButtonClick($event)">
            <i></i>
        </a>

        <span *ngIf="app.sharedData.appInfo.isElectronMode">
            <a id="rightpanel-menu-button" href="" (click)="app.onClickClose($event)" style="margin-top: 6px; margin-right: 2px;">
                <i class="ui-icon-close" style="margin-top: 5px; width: 1em;"></i>
            </a>
            <a id="rightpanel-menu-button" *ngIf="app.sharedData.appInfo.platform=='win32'" href="" (click)="app.onClickMax($event)" style="margin-top: 6px;">
                <i class="ui-icon-crop-square" style="margin-top: 5px;"></i>
            </a>
            <a id="rightpanel-menu-button" href="" *ngIf="app.sharedData.appInfo.platform=='win32'" (click)="app.onClickMin($event)" style="margin-top: 6px;">
                <i class="ui-icon-minus" style="margin-top: 5px;"></i>
            </a>
        </span>

        <!--a id="rightpanel-menu-button" href="#" (click)="app.onRightPanelButtonClick($event)">
            <i class="material-icons">more_vert</i>
        </a-->

        <a id="topbar-menu-button" href="#" (click)="app.onTopbarMenuButtonClick($event)">
            <i class="material-icons">menu</i>
        </a>

        <ul class="topbar-items animated fadeInDown" >
            <li #helpOptions [ngClass]="{'active-top-menu':app.activeTopbarItem === helpOptions}" style="width:auto;">
                <a style="cursor: pointer;" (click)="app.onTopbarItemClick($event,helpOptions)">
                    <i class="topbar-icon material-icons" pTooltip="Help">help_outline</i>
                    <span class="topbar-item-name">More Options</span>
                </a>
                <ul class="ultima-menu animated fadeInDown" style="width:auto;">
                    <li role="menuitem" [style]="{'width':'auto','display':'block','float': 'none'}">
                        <a (click)="app.onOpenUserGuide()">
                            <i class="material-icons">description</i>
                            <span>User Guide</span>
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="app.sharedData.visibility.main.showInternalUserguide"  [style]="{'width':'auto','display':'block','float': 'none','white-space': 'nowrap'}">
                        <a (click)="app.onOpenInternalUserGuide()">
                            <i class="material-icons">verified_user</i>
                            <span>Internal QC User Guide</span>
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="app.sharedData.visibility.main.showInternalHelpJiraCreation" [style]="{'width':'auto','display':'block','float': 'none','white-space': 'nowrap'}">
                        <a (click)="app.onOpenSupportTicketDialog(stardustIssueType,stardustProjectKey,null)" >
                            <i class="material-icons">feedback</i>
                            <span>Create support ticket</span>
                        </a>
                    </li>
                    <li role="menuitem" [style]="{'width':'auto','display':'block','float': 'none','white-space': 'nowrap'}" >
                        <a (click)="app.onCheckNetworkConnectionStatus()">
                            <i class="material-icons">network_check</i>
                            <span>Check Network Connection Status</span>
                        </a>
                    </li>
                    <li role="menuitem" *ngIf="!app.sharedData.appInfo.isElectronMode" [style]="{'width':'auto','display':'block','float': 'none','white-space': 'nowrap'}" >
                        <a (click)="app.onDownloadLogs()">
                            <i class="material-icons">insert_drive_file</i>
                            <span>Download Logs</span>
                        </a>
                    </li>
                    <li role="menuitem" [style]="{'width':'auto','display':'block','float': 'none','white-space': 'nowrap'}">
                        <a (click)="app.onShowQPMInfo()">
                            <i class="material-icons">info</i>
                            <span>About Qualcomm Package Manager</span>
                        </a>
                    </li>
                </ul>
            </li>

            <li  style="width:auto;" *ngIf="(app.sharedData.visibility.license.renewWorkflowForUSuser)">
                <a style="cursor: pointer; color: white;font-size: 1.8em;"  (click)="gotoCartPage()">
                    <i class="ui-icon-shopping-cart" pTooltip="Cart"></i>
                    <span *ngIf="app.sharedData.licenses.common.itemsAddedToCart?.length>0" class="topbar-badge animated rubberBand">{{app.sharedData.licenses.common.itemsAddedToCart?.length}}</span>
                </a>
            </li>

            <li #profile class="profile-item" [ngClass]="{'active-top-menu':app.activeTopbarItem === profile}">
                <a (click)="app.onTopbarItemClick($event,profile)">
                    <img class="profile-image" src="assets/layout/images/avatar_wh.png"/>
                </a>

                <ul class="ultima-menu animated fadeInDown" style="width:auto;">
                    <li role="menuitem" [style]="{'width':'auto','display':'block','float': 'none','white-space': 'nowrap'}">
                        <a (click)="app.onOpenEmailSubscriptionsPage()">
                            <i class="material-icons">email</i>
                            <span>Subscriptions</span>
                        </a>
                    </li>
                   
                </ul>

                <!--ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem"><span>{{this.app.sharedData.userInfo.info.fullName}}</span></li>
                    <li role="menuitem"><span>{{this.app.sharedData.userInfo.info.companyName}}</span></li>
                    <li *ngFor="let role of this.app.sharedData.userInfo.info.roles" role="menuitem"><span>{{role}}</span></li>
                </ul-->
            </li>
            <li #profile_name class="profile-item" >
                <h3 class="username" style="font-weight: normal;">{{this.app.sharedData.userInfo.username}}</h3>

                <!--span class="topbar-item-name">Jane Williams</span-->

                <!--ul class="ultima-menu animated fadeInDown"-->
                    <!--li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">person</i>
                            <span>Profile</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">security</i>
                            <span>Privacy</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">settings_applications</i>
                            <span>Settings</span>
                        </a>
                    </li-->
                    <!--li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">power_settings_new</i>
                            <span>Logout</span>
                        </a>
                    </li-->
                <!--/ul-->
            </li>
            <!--li #messages [ngClass]="{'active-top-menu':app.activeTopbarItem === messages}">
                <a href="#" (click)="app.onTopbarItemClick($event,messages)">
                    <i class="topbar-icon material-icons animated swing">message</i>
                    <span class="topbar-badge animated rubberBand">5</span>
                    <span class="topbar-item-name">Messages</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar1.png" width="35"/>
                            <span>Give me a call</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar2.png" width="35"/>
                            <span>Sales reports attached</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar3.png" width="35"/>
                            <span>About your invoice</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar2.png" width="35"/>
                            <span>Meeting today at 10pm</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                            <img src="assets/layout/images/avatar4.png" width="35"/>
                            <span>Out of office</span>
                        </a>
                    </li>
                </ul>
            </li-->
            <!--li #notifications [ngClass]="{'active-top-menu':app.activeTopbarItem === notifications}">
                <a href="#" (click)="app.onTopbarItemClick($event,notifications)">
                    <i class="topbar-icon material-icons">timer</i>
                    <span class="topbar-badge animated rubberBand">4</span>
                    <span class="topbar-item-name">Notifications</span>
                </a>
                <ul class="ultima-menu animated fadeInDown">
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">bug_report</i>
                            <span>Pending tasks</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">event</i>
                            <span>Meeting today at 3pm</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">file_download</i>
                            <span>Download documents</span>
                        </a>
                    </li>
                    <li role="menuitem">
                        <a href="#" (click)="app.onTopbarSubItemClick($event)">
                            <i class="material-icons">flight</i>
                            <span>Book flight</span>
                        </a>
                    </li>
                </ul>
            </li-->
            <!--li #search class="search-item" [ngClass]="{'active-top-menu':app.activeTopbarItem === search}"
                (click)="app.onTopbarItemClick($event,search)">
                <span class="md-inputfield">
                    <input type="text" pInputText>
                    <label>Search</label>
                    <i class="topbar-icon material-icons">search</i>
                </span>
            </li-->
        </ul>
        <div class="topbar-right-behind"></div>
    </div>
</div>