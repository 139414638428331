import { SoftwareProductDistro, SoftwareProductRelease } from './software-catalog-client';

export class SoftwareProductDownload {
    SoftwareProductBuild?: string;
    baseSoftwareProductBuildId?:string;
    targetSoftwareProductBuildId?:string
    imageBuilds : SoftwareImageDownload[];
    localFolderLocation : string;
    aboutHtmlSignedUrl?:string;
}
export class SoftwareImageDownload {
    imageBuildId: string;
    downloadUrl: string;
    distro: string;
    localFolderName: string;
    downloadCommand: SoftwareImageDownloadCommand;
    dryRunCommand: SoftwareImageDownloadCommand;
}
export class SoftwareImageDownloadCommand{
    cwd: string;
    cmds:syncCommand[];
}
export class syncCommand{
    cmd: string;
}

export class SoftwareProductLocal {
    type = "product";
    product: string;
    productbuilds: SoftwareProductBuildLocal[];
    regularSoftwareProduct: string;
    sourceSoftwareProduct: string;
}
export class SoftwareProductBuildLocal {
    type = "build";
    downloadLocation: string;
    productBuildId: string;
    product: string;
    tag: string;
    distros: SoftwareDistroLocal[];
}
export class SoftwareDistroLocal {
    type = "distro";
    createdTime: string;
    modifiedTime: string;
    downloadLocation: string;
    product: string;
    productBuildId: string;
    distro: string;
    tag: string;
    isSuccessfulDownload: boolean;
}
export class SoftwareImageLocal {
    createdTime: string;
    modifiedTime: string;
    downloadLocation: string;
    productBuildId: string;
    imageBuildId: string;
    folderType:string;
    isSuccessfulDownload: boolean;
}
export class SoftwareProductMetaDeta{
    SyncResultJson : SoftwareImageSyncResult[];
}
export class SoftwareImageSyncResult
{
    softwareImageBuild :  string;
    isSuccessfullDownload: string;
}

export class SoftwareProductMetaDataLocal
{
    createdTime: string;
    modifiedTime: string;
    downloadLocation: string;
    productBuildId?:string;
    product:string;
    branch:string;
    tag:string;
    distro:string;
    distrofolderName:string;
    imageBuildsMeta:SoftwareImageLocal[]

}

export class SoftwareDiffDownloadResponse{
    baseSoftwareProductBuildId?:string;
    targetSoftwareProductBuildId?:string
    distroDownloadResponse:SoftwareDistroInfo[];

}
export class SoftwareDistroInfo{

    distroName:string;
    distroId:string;
    uploadComplete:Number;
    aboutHtmlSignedUrl?:string;
    spSignedUrl?:string;
    trackingId?:Number;
    imageBuildDownloadResponse:ImageDownloadResponse[];
    
}
export class ImageDownloadResponse{

    downloadUrl: string;
    imageBuildId: string;
    imageDistroFolderName: string;
    localFolder: string;
    signedUrl?:string;
}

export class DownloadHistoryLocal {
    baseSoftwareBuild?:SoftwareProductRelease;
    targetSoftwareBuild?:SoftwareProductRelease;
    softwareProduct?:string;
    tag:string;
    distro:SoftwareProductDistro;
    status:string;
    downloadDate:string;
    downloadLocation:string;
    requestedDate:string;
    softwareImageBuild?:string;
    targetSoftwareImageBuild?:string;
}

export class SoftwareDownloadResponse{
    softwareProductBuild?:string;
    distroDownloadResponse:SoftwareDistroInfo[];
}

export class DownloadStatusRequest{
    isDownloadSuccessfull:boolean;
    trackingId?:Number;
}

export class DownloadImageStatusRequest{
    isImageDownloadSuccessfull:boolean;
    stardustImgTrackingId?:Number;
    stardustDiffImgTrackingId?:Number;
}

export class shipStatusErrorResponse{
    headers:any;
    status:Number;
    statusText:string;
    url:string;
    ok:boolean;
    name:string;
    message:string;
    error?:ErrorDetails;
}

export class ErrorDetails{
    errorCode:Number;
    errorMessage:string;
    message?:string;
    exceptionList?: ExceptionDetails[];
}
export class ExceptionDetails{
    errorCode:Number;
    errorMessage:string;
    errorDetails: string;
}

export class BinDdmJiraTicketInfoRequest{
    jiraIssueId: string
    jiraProjectKey: string
    softwareDistro: string
    softwareProductBuild: string 
}

export class BinDdmJiraTicketInfoResponse {
    jiraStatus: number
    lostResolution: string
    jiraUrl: string
  }
  export class NoticeFileUploadStartRequest{
    softwareDistro: string; 
    softwareProductBuildId: string;
  }
  export class NoticeFileUploadCompleteRequest{
    sessionId: number;
    softwareDistro: string;
    softwareProductBuildId: string;
  }
  export class NoticeFileDownloadRequest{
   softwareDistro: string
   softwareProductBuild: string 
  }

  export class UpdatePassThroughRequest {
    gitBranch: string;
    gitUrl: string;
    serviceTaskId: number;
    softwareImage: string;
  }
  export class UpdatePassThroughResponse {
    success: boolean;
    message: string;
    error: string;
  }
  export class TriggerPassThroughSIRequest {
    baseImage: string;
    baseImageBuild: string;
    gitBranch: string;
    gitUrl: string;
    serviceTaskId: number;
  }
  