import { Injectable } from '@angular/core';
import {environment} from './../../environments/environment';
import isElectron from 'is-electron';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ApiUrls {
  portalUrl = environment.portalUrl;
  baseUrl =  isElectron()? '' : window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port : '');
  httpGetURL = '/qpm-api/get?endpoint=';
  httpDeleteURL = '/qpm-api/delete?endpoint=';
  httpPutURL = '/qpm-api/put?endpoint=';
  httpPostFileURL = '/qpm-api/postFile?endpoint=';
  httpPostURL = '/qpm-api/post?endpoint=';

  domain = environment.domain;
  //baseUrlSoftware = isElectron()? environment.baseUrlSoftwareVordel : "";//"https://corpawswsgwtestx.qualcomm.com/stardust";
  //baseUrlSoftware = environment.baseUrlSoftwareVordel;
  baseUrlSoftware = isElectron()? environment.baseUrlSoftwareVordel : environment.baseUrlSoftware;
  baseUrlSoftwareOnPrem = environment.baseUrlSoftwareOnPrem;//"http://10.47.209.33:8081";
  login = this.baseUrl+'/api/uid';
  logout = this.baseUrl + 'logout';
  userPic = "https://people.qualcomm.com/Photos?format=square&uid={0}";

  checkMaintanence = this.baseUrlSoftware + "/status/planned_downtime";
  //Software Catalog
  customersAll = this.baseUrlSoftware + "/customers";
  softwareProducts = this.baseUrlSoftware + "/catalog/software/softwareproducts";
  softwareProductsByParty = this.baseUrlSoftware + "/catalog/software/softwareproducts/{0}";
  softwareProductFamily = this.baseUrlSoftware + "/catalog/software/spf_mapping";
  softwareProductFamilyByParty = this.baseUrlSoftware + "/catalog/software/spf_mapping/{0}";
  softwareProductDistros = this.baseUrlSoftware + "/distros/{0}";
  softwareProductCustomers = this.baseUrlSoftware + "/catalog/software/customers/{0}";
  softwareProductCustomersWithSpfTag = this.baseUrlSoftware + "/catalog/software/customers/{0}?spfTag={1}";
  deleteServiceTaskCustomer = this.baseUrlSoftware + "/servicetasks/deletecustomer/{0}/{1}";
  customerInfo = this.baseUrlSoftware + "/customers/orgid/{0}";
  softwareProjects = this.baseUrlSoftware + "/catalog/software/customerprojects";
  softwareProjectsByParty = this.baseUrlSoftware + "/catalog/software/customerprojects/{0}";
  //softwareBranches = this.baseUrlSoftware + "/catalog/software/branches";
  softwareBuilds = this.baseUrlSoftware + "/catalog/software/softwareproductreleases/{0}";
  softwareBuildsByParty = this.baseUrlSoftware + "/catalog/software/softwareproductreleases/{0}/{1}";
  softwareBuildInfo = this.baseUrlSoftware + "/catalog/software/softwareproductbuildrelease/{0}";
  softwareBuildCrFixDetails = this.baseUrlSoftware + "/crsearch/crfixdetails";
  softwareBuildCrFixDetailsByRequest = this.baseUrlSoftware + "/crsearch/crfixdetails/{0}";
  softwareBuildCrFixUpdate = this.baseUrlSoftware + "/servicetasks/cr_status_check";
  softwareProductLatestComposition = this.baseUrlSoftware + "/catalog/software/latestreleasecomp/{0}";
  softwareImagesBuildsLatest = this.baseUrlSoftware + "/builds/latestsoftwareimagebuilds/{0}";
  createServiceTask = this.baseUrlSoftware + "/servicetasks/create";
  updateServiceTask = this.baseUrlSoftware + "/servicetasks/update/{0}";
  deleteServiceTaskCR = this.baseUrlSoftware + "/servicetasks/{0}/deletechangerequest";
  deleteServiceTaskGerrit = this.baseUrlSoftware + "/servicetasks/{0}/deletegerrit/";
  updateServiceTaskBuildInternalClient = this.baseUrlSoftware + "/servicetasks/internal_clients/{0}/{1}";
  removeServiceTaskBuildInternalClient = this.baseUrlSoftware + "/servicetasks/internal_clients/remove/{0}";
  serviceTasks = this.baseUrlSoftware + "/servicetasks/details/all";
  serviceTaskDetails = this.baseUrlSoftware + "/servicetasks/details/{0}";
  serviceTaskMembers = this.baseUrlSoftware + "/servicetasks/{0}/members";
  serviceTaskMembersUpdate = this.baseUrlSoftware + "/servicetasks/{0}/members/update";
  serviceTaskDetailsLatestRelease = this.baseUrlSoftware + "/servicetasks/latest_release_info/{0}";
  serviceTaskDetailsHistoryCRs = this.baseUrlSoftware + "/changerequests/history_crs/{0}";
  serviceTaskDetailsReleaseCRs = this.baseUrlSoftware + "/changerequests/build_crs/{0}";
  serviceTaskGetCRComments = this.baseUrlSoftware + "/servicetasks/{0}/comment";
  serviceTaskAddCRComment = this.baseUrlSoftware + "/servicetasks/{0}/comment/add";
  serviceTaskBaseImages = this.baseUrlSoftware + "/servicetasks/request_details/imagebuilds/{0}";
  serviceTaskBaseImagesDelete = this.baseUrlSoftware + "/servicetasks/request_details/imagebuilds/{0}/{1}";
  serviceTaskBaseImagesValidate = this.baseUrlSoftware + "/servicetasks/validate/is_serviceable/{0}";
  serviceTaskBaseImagesCirrusStatus = this.baseUrlSoftware + "/servicetasks/is_cirrustask_created/{0}/{1}";
  serviceTaskDetailsBuildRelease = this.baseUrlSoftware + "/servicetasks/build_release_info/{0}";
  crDetails = this.baseUrlSoftware + "/changerequests/{0}";
  crRelated = this.baseUrlSoftware + "/changerequests/{0}/relatedchangerequests";
  deltaCRs = this.baseUrlSoftware+"/changerequests/delta_crs/{0}/{1}?isImageBuild={2}"; //newBuild, oldBuild, isImageBuild
  serviceTaskBuildCRStatus = this.baseUrlSoftware + "/changerequests/{0}/servicetasks/{1}";
  serviceTaskBuilds = this.baseUrlSoftware + "/servicetasks/{0}/builddetails/v2";
  serviceTaskBuildInternalClients = this.baseUrlSoftware + "/servicetasks/internal_clients/{0}";
  serviceTaskBuildDetails = this.baseUrlSoftware + "/servicetasks/builddetails/{0}/{1}";
  serviceTaskBuildDetailsWithComponents = this.baseUrlSoftware + "/servicetasks/builddetails/{0}/{1}?includeComponents=true";
  addBuildComponentToBranch = this.baseUrlSoftware + "/servicetasks/build_components/add/{0}";
  serviceTaskBuildCRs = this.baseUrlSoftware + "/servicetasks/builddetails/crview/{0}";
  serviceTaskDistProducts = this.baseUrlSoftware + "/softwareproduct/builds/{0}";
  serviceTaskDistProductDetails = this.baseUrlSoftware + "/softwareproduct/tiberium_build_info/{0}";
  serviceTaskBaitBuilds = this.baseUrlSoftware + "/servicetasks/{0}/image_builds/{1}";
  serviceTaskBaitBuildDetails = this.baseUrlSoftware + "/servicetasks/{0}/bait_build_details/{1}";
  baitLocalfileUploadBuilds=this.baseUrlSoftware+"/servicetasks/{0}/image_builds/{1}?isLocalFileUploaded=true";//STNumber,imageId
  baitLocalFileUploadBuildDetails=this.baseUrlSoftware+"/servicetasks/{0}/sm_build_details/{1}";//STNumber,BuildId
  serviceTaskPassThroughBuilds=this.baseUrlSoftware + "/servicetasks/{0}/image_builds/{1}";
  serviceTaskPassThroughBuildDetails=this.baseUrlSoftware + "/softwareimage/tiberium_build_info/{0}";
  softwareImageBuildLatest =  this.baseUrlSoftware + "/imagebuilds/all/{0}/{1}";
  abortBaitBuild = this.baseUrlSoftware + "/servicetasks/{0}/abort_bait_build/{1}";
  triggerBaitBuild = this.baseUrlSoftware + "/servicetasks/{0}/trigger_bait_build";
  triggerBuild = this.baseUrlSoftware + "/servicetasks/update/cr_gerrit_action";
  triggerCirrusBuild = this.baseUrlSoftware + "/servicetasks/buildimage/{0}";
  triggerProductBuild = this.baseUrlSoftware + "/softwareproduct/build";
  triggerProductBuildWithDistroList = this.baseUrlSoftware + "/softwareproduct/build_with_selected_deliverables";
  triggerAutoShip = this.baseUrlSoftware + "/softwareproduct/auto_ship";
  triggerAutoShipSP = this.baseUrlSoftware + "/softwareproduct/auto_ship_sp";
  triggerAutoShipSIAsync = this.baseUrlSoftware + "/servicetasks/async/build_and_ship_si";
  triggerAutoShipSPAsync = this.baseUrlSoftware + "/softwareproduct/async/build_and_ship_sp";
  retryCirrusBuildRequest = this.baseUrlSoftware + "/servicetasks/retry/cirrus/{0}/{1}/{2}";
  recoverCirrusSeedBuild = this.baseUrlSoftware + "/servicetasks/recover_service_image/{0}";
  verifyProductComposition = this.baseUrlSoftware + "/softwareproduct/verify_composition/{0}/{1}";
  validateServiceTask = this.baseUrlSoftware + "/servicetasks/validate_crs";
  userDetails = this.baseUrlSoftware + "/users/{0}";
  shipStatus = this.baseUrlSoftware + "/download/isShip/softwareProduct/";
  shipSoftwareProduct = this.baseUrlSoftware + "/ship/softwareProduct/";
  softwareProductBuildComposition = this.baseUrlSoftware + "/productbuilds/composition/{0}";
  downloadSoftwareProduct = this.baseUrlSoftware + "/download/softwareProduct/";
  masterTriggerBuild = this.baseUrlSoftware + "/servicetasks/master/triggerbuild";
  diifShipStatus = this.baseUrlSoftware + "/diffDownload/isDiffShip/softwareProduct/";
  diffShipSoftwareProduct = this.baseUrlSoftware + "/diffShip/softwareProduct/";
  diffDownloadSoftwareProduct = this.baseUrlSoftware + "/diffDownload/softwareProduct/";
  softwareProductDiffBuilds =  this.baseUrlSoftware + "/diffDownload/getAvailableDiffDownload/{0}"; 
  consolidatedProductCRs =  this.baseUrlSoftware + "/changerequests/consolidated_build_crs/{0}";
  changeListImpactedFiles =  this.baseUrlSoftware + "/changerequests/impacted_files_cr/{0}?isImageBuild={1}";
  baitImpactedFiles =  this.baseUrlSoftware + "/changerequests/bait_impacted_files/{0}";
  sendEmail = this.baseUrlSoftware + "/email/send";
  softwareProductBuildDistros = this.baseUrlSoftware + "/distros/distros_bysoftwareimagebuild/{0}";
  shippedDistros = this.baseUrlSoftware +"/download/shipStatus/softwareProduct/{0}";
  updateDownloadStatus = this.baseUrlSoftware +"/download/UpdateSoftwareDownloadInfoDetails/";
  updateDiffDownloadStatus = this.baseUrlSoftware +"/diffDownload/UpdateDiffDownloadInfoDetails/";
  shipStatusForImage = this.baseUrlSoftware + "/imageDownload/isShip/ImageBuild/";
  imageShipStatus    = this.baseUrlSoftware + "/imageDownload/BuildPage/isShip/ImageBuild/";
  downloadSoftwareImage    = this.baseUrlSoftware +"/imageDownload/ImageBuild/";
  updateImageDownloadStatus = this.baseUrlSoftware +"/imageDownload/UpdateImageDownloadTrackingDetails/";
  softwareImageDiffBuilds   = this.baseUrlSoftware +"/diffImageDownload/getAvailableDiffImageDownload/{0}";
  diffDownloadSoftwareImage = this.baseUrlSoftware + "/diffImageDownload/diffImageBuild/";
  updateDiffImageDownloadStatus = this.baseUrlSoftware +"/diffImageDownload/UpdateDiffImageDownloadTrackingDetails/";
  hlosTechnology = this.baseUrlSoftware + "/bait/jira_technology_breakdown";
  hlosTarget = this.baseUrlSoftware + "/bait/targets/{0}";
  spByImageBuild = this.baseUrlSoftware +"/catalog/software/softwareproductbuildreleases/byimagebuild/{0}";
  shipSoftwareImage = this.baseUrlSoftware +"/imageShip/softwareImage/";
  spByImageBuildAndPartyID = this.baseUrlSoftware +"/catalog/software/softwareproductbuildreleases/byimagebuild/{0}/{1}";
  downloadSoftwareImageBuild = this.baseUrlSoftware+"/imageDownload/BuildPage/ImageBuild/";
  softwareBuildCveCrMapping = this.baseUrlSoftware + "/changerequests/cve";
  softwareBuildSecurityBulletins = this.baseUrlSoftware + "/changerequests/bulletins";
  replayDiffShipSoftwareProduct = this.baseUrlSoftware + "/diffShip/softwareProduct/replay/";
  crAutoPropagate = this.baseUrlSoftware + "/cr-propagation/submit/";
  crAutoPropagateCommit = this.baseUrlSoftware + "/cr-propagation/commit/";
  crAutoPropagateResult = this.baseUrlSoftware + "/cr-propagation/result/";
  localFileUploadValidation = this.baseUrlSoftware + "/cr-propagation/fileAndPathValidity/";
  localFileUpload = this.baseUrlSoftware + "/cr-propagation/fileCheckIn/";
  remoteAutoCommit= this.baseUrlSoftware + "/cr-propagation/fileCheckIn/changelist";//componentBranchDevFR is false
  remoteAutoCommitV2= this.baseUrlSoftware + "/cr-propagation/remote_upload";//Cirrus Remote Upload :componentBranchDevFR is true
  additionalCRInfo = this.baseUrlSoftware + "/changerequests/additional_cr_info/{0}/servicetask/{1}/";
  binDdmJiraTicketIdSubmit=this.baseUrlSoftware + "/binDdm/softwareProduct/";
  noticeFileUploadStartSession=this.baseUrlSoftware + "/binDdm/notice_file/upload/start_session/";
  noticeFileUploadStartSessionV2=this.baseUrlSoftware + "/binDdm/notice_file/upload/start_session/v2/";
  noticeFileUploadCompleteSession=this.baseUrlSoftware + "/binDdm/notice_file/upload/complete_session/";
  noticeFileUploadCompleteSessionv2=this.baseUrlSoftware + "/binDdm/notice_file/upload/complete_session/v2/";
  noticeFileDownload=this.baseUrlSoftware+"/binDdm/notice_file/download/";
  //PassThroughImages
  updatePassThrough=this.baseUrlSoftware + "/servicetasks/update/passthrough";
  triggerPassThroughSI=this.baseUrlSoftware + "/softwareimage/build";


  dev = "http://10.110.84.254:8081";
  workspaceRootForCirrus=this.baseUrlSoftware+"/servicetasks/build_components/{0}";
  localFilePathSearchForBait=this.baseUrlSoftware+"/localfileupload/search/sm";
  localFilePathSearch=this.baseUrlSoftware+"/localfileupload/search/";//componentBranchDevFR is false
  localFilePathSearchV2=this.baseUrlSoftware+"/localfileupload/search/{0}/files/{1}/";//cirrusTaskId,fileName : componentBranchDevFR is true
  localFilesRemove=this.baseUrlSoftware+"/localfileupload/remove_files?cirrusTaskId={0}";
  localFileUploadInitiate = this.baseUrlSoftware + "/localfileupload/session/initiate";
  localFileUploadComplete = this.baseUrlSoftware + "/localfileupload/session/complete";
  s3UploadStart =  this.baseUrlSoftware + "/api/s3/file/start";
  s3UploadContinue =  this.baseUrlSoftware + "/api/s3/file/continue";
  s3UploadContinue64 =  this.baseUrlSoftware + "/api/s3/file/continueBase64";
  s3UploadComplete =  this.baseUrlSoftware + "/api/s3/file/complete";
  s3UploadCompletev2 =  this.baseUrlSoftware + "/api/s3/file/complete/v2";
  localFileUploadSessions =  this.baseUrlSoftware + "/localfileupload/session/status";

  baitFileUploadInitiate = this.baseUrlSoftware + "/localfileupload/session/sm/initiate";
  baitFileUploadComplete = this.baseUrlSoftware + "/localfileupload/session/sm/complete";
  baitHlosChanges = this.baseUrlSoftware + "/servicetasks/{0}/hlos_changes/{1}";
  baitSystemChanges = this.baseUrlSoftware + "/servicetasks/{0}/system_changes/{1}";
  baitSystemTriggerBuild = this.baseUrlSoftware + "/servicetasks/{0}/trigger_sm_build";

  // UGDB
  baseUrlUGDB = isElectron()? environment.baseUrlUGDBVordel : environment.baseUrlUGDB;
  createTicket = this.baseUrlUGDB + "/jira/create";  
  jiraAttachment = this.baseUrlUGDB + "/jira/attachment/v2/{0}/{1}"


  // Tools catalog
  baseUrlTools = isElectron()? environment.baseUrlToolsVordel : environment.baseUrlTools;
  toolsCatalog = this.baseUrlTools + "/catalog/v3/products/{0}?OS={1}"; // username, OS
  toolsReleases = this.baseUrlTools + "/catalog/v3/products/{0}/releases/{1}?OS={2}&noOfReleases={3}";  // ProductUUID, username, OS + targetArch, count
  toolsAllReleases = this.baseUrlTools + "/catalog/v3/products/{0}/releases/{1}?OS={2}";
  toolsProduct = this.baseUrlTools + "/catalog/v2/products/{0}?OS={1}"; // productID, OS
  toolsPackage = this.baseUrlTools + "/catalog/v2/packages/{0}?OS={1}"; // packageID, OS
  toolsEntitlements = this.baseUrlTools + "/products/{0}/entitlements"; // productID
  toolsSignedUrl    = this.baseUrlTools +"/download/{0}";
  toolsEntitlemets  = this.baseUrlTools +"//products/{0}/entitlements?OS={1}";
  checkPkla         = this.baseUrlTools+"/user_profile/{0}"
  tempCredentials     = this.baseUrlTools +"/download/icons/cred";
  compPackageList   = this.baseUrlTools + "/catalog/v2/components/{0}/packages?OS={1}"; // component id, os

  //CLO
  cloReleases=this.baseUrlTools +"/clo/product/{0}/releases"; //ProductUUID
  manifestFileAndReleaseNote=this.baseUrlTools+"/clo/files?productUuid={0}";

  //Licenses
  licenses=this.baseUrlTools +"/ext/licensegroups";
  features=this.baseUrlTools+"/ext/licensegroups/{0}/complete";
  seatsDetails=this.baseUrlTools+"/ext/licensegroups/{0}/licenses";
  serialNumberDetails=this.baseUrlTools+"/ext/serial/{0}";
  pricingDetails=this.baseUrlTools+"/payments/pricingDetails";
  deactivateLicenseSeat=this.baseUrlTools+"/ext/licensegroups/{0}/licenses/{1}"; // licensegroupid ,licenseid

  //Documents
  documentList = this.baseUrlTools +"/interceptor/search";

  //Email Subscriptions For Licensegroup
  currentSubscriptionDetails=this.baseUrlTools+"/expiryNotifications";
  subscriptionDetailsByLicenseGroupId=this.baseUrlTools+"/expiryNotifications?licensegroupUuid={0}";//licenseGroupID
  subscribeLicensegroupForEmail=this.baseUrlTools+"/expiryNotifications?licensegroupUuid={0}";//licenseGroupID
  unsubscribeLicenseGroupForEmail=this.baseUrlTools+"/expiryNotifications?licensegroupUuid={0}";//licenseGroupID

  //Payments microservice
  baseUrlPaaS = isElectron()? environment.baseUrlPaasVordel : environment.baseUrlPaas;
  buildPaymentIntent = this.baseUrlPaaS + "/paymentIntent/build";
  updatePaymentIntent = this.baseUrlPaaS + "/paymentIntent/update";

}
