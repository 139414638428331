import * as e from "cors";
import { BehaviorSubject, Observable } from "rxjs";
import { AppMainComponent } from "../app.main.component";
import { s3FileUploadContinueRequest, S3UploadCompleteRequest } from "../models/lime-web-client";
import { QPMResponse } from "../models/response";
import { FileInfo, FileStatus } from "../models/software-catalog-client";
import { WebClientService } from "../service/Contract/WebClientService";
import { QLogger } from "./logger";

export enum FileUploadType{
    FormData,
    Base64,
    Base64Decode
}

export class LargeFileUploadConfig{
    skipStart: boolean;
    uploadType: FileUploadType;
}

export class LargeFileUpload{
    private logSrc:string = "LargeFileUpload";
    private subject: BehaviorSubject<boolean>;
    private config: LargeFileUploadConfig;

    largeFileUploadProcess:{
        files: any[];
        filesInfo: FileInfo[];
        maxChunkSizeInByte: 5200000;
        errorMessage: string;
    }

    constructor(public app: AppMainComponent, private webClient:WebClientService){
        this.largeFileUploadProcess = {
            files: [],
            filesInfo: [],
            maxChunkSizeInByte: 5200000,
            errorMessage: ""
        }
    }

    setFileInfo(files: any[], filesInfo: FileInfo[], config: LargeFileUploadConfig){
        this.largeFileUploadProcess.files = files;
        this.largeFileUploadProcess.filesInfo = filesInfo;
        this.config = config;
    }

    initiateUploadLargeFile(): Observable<boolean>{
        //this.s3FileUploadStart(0);
        this.subject = new BehaviorSubject<boolean>(undefined);
        if(this.config.skipStart){
            this.uploadFileChunks(0, 1);
        }
        else{
            this.s3FileUploadStart(0);
        }
        return this.subject.asObservable();
    }
    
    uploadFileChunks(fileNumber: number, partNumber: number){
        let fileInfo = this.largeFileUploadProcess.filesInfo[fileNumber];
        let file = this.largeFileUploadProcess.files[fileNumber];
        let maxChunkSize = 5900000;
        let currentChunkStartByte = (partNumber - 1) * maxChunkSize;
        let currentChunkFinalByte = 0;
        if(currentChunkStartByte >= file.size){
            this.s3FileUploadComplete(fileNumber);
            //this.s3FileUploadComplete(fileNumber);
            return;
        }

        const remainingBytes = file.size - currentChunkStartByte;
        if (remainingBytes < maxChunkSize) {
        // if the remaining chunk is smaller than the chunk size we defined
            currentChunkFinalByte = currentChunkStartByte + remainingBytes;
        }
        else {
            // keep chunking
            currentChunkFinalByte = currentChunkStartByte + maxChunkSize;
        }
        let chunk : Blob = file.slice(currentChunkStartByte, currentChunkFinalByte);
        const reader = new FileReader();
        let appPtr = this;
        
        switch(this.config.uploadType){
            case FileUploadType.FormData: {
                this.s3FileUploadContinue(chunk, fileNumber, partNumber);
                break;
            }
            case FileUploadType.Base64: {
                reader.readAsDataURL(chunk);
                reader.onload = function () {
                    let result = <string>reader.result;
                    let base64String = (result).split(',').pop(); //Removing Header
                    //QLogger.LogInfo(appPtr.logSrc, "Part " + partNumber + ": " + base64String);  
                    appPtr.s3FileUploadContinueBase64(base64String, fileNumber, partNumber,false);
                }
                break;
            }
            case FileUploadType.Base64Decode: {
                //Once backend is ready and make corresponding API integrations similar to function s3FileUploadContinueBase64(). Reuse for BAIT & BinDDM
                reader.readAsDataURL(chunk);
                reader.onload = function () {
                    let result = <string>reader.result;
                    let base64String = (result).split(',').pop(); //Removing Header
                    //TODO - convert to nonBase64String if backend not converting
                    let nonBase64String = base64String // convert this
                    //QLogger.LogInfo(appPtr.logSrc, "Part " + partNumber + ": " + base64String); 
                    //appPtr.s3FileUploadContinueBase64Decode(base64String, fileNumber, partNumber);
                    appPtr.s3FileUploadContinueBase64(base64String, fileNumber, partNumber,true);//Can use for NoticeFile and BAIT upload
                }
                break;
            }
            
        }
    }
    
    s3FileUploadStart(fileNumber: number){
    let response: Observable<QPMResponse>;
    let fileinfo = this.largeFileUploadProcess.filesInfo[fileNumber];
    QLogger.LogInfo(this.logSrc,"Uploading File Start to "+ fileinfo.fullFilePath);
    response = this.webClient.s3FileUploadStart(fileinfo.s3Key, fileinfo.fullFilePath);
    response.subscribe(
        (data: QPMResponse) => {
            if (this.app.sharedData.appInfo.logResponse) {
                QLogger.LogInfo(this.logSrc, "Uploading File Start - Response: " + JSON.stringify(data));
            }
            if (data.isSuccess()) {
                fileinfo.status = FileStatus.UploadInProgress;
                this.uploadFileChunks(fileNumber, 1);
                QLogger.LogInfo(this.logSrc,"Uploading File Start - Success" + JSON.parse(data.getData()));
            }
            else{
                this.largeFileUploadProcess.errorMessage = data.getErrorDetail();
                QLogger.LogInfo(this.logSrc,"Uploading File Start - Error: " + data.getErrorDetail());
            }
        });
    }

    s3FileUploadContinueBase64(base64Chunk: string, fileNumber: number, partNumber: number,decode:boolean){
        let req : s3FileUploadContinueRequest;
        let response: Observable<QPMResponse>;
        let fileinfo = this.largeFileUploadProcess.filesInfo[fileNumber];
        fileinfo.status = FileStatus.UploadInProgress;
        QLogger.LogInfo(this.logSrc,"Uploading File ContinueBase64 to "+ fileinfo.fullFilePath);
        req ={
                fileContent: base64Chunk,
                key: fileinfo.s3Key,
                partNumber: partNumber,
                path: fileinfo.fullFilePath,// this.localFileUploadProcess.localUploadImage.detail.perforceDetails.path
                decode:decode
        }
        if (this.app.sharedData.appInfo.logRequest) {
            //QLogger.LogInfo(this.logSrc, "Uploading File ContinueBase64 - Request: " + JSON.stringify(req));
        }
        response = this.webClient.s3FileUploadContinueBase64(req);
        response.subscribe(
            (data: QPMResponse) => {
                if (this.app.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Uploading File ContinueBase64 - Response: " + JSON.stringify(data));
                }
                if (data.isSuccess()) {
                    if(fileinfo.filesize <= partNumber * this.largeFileUploadProcess.maxChunkSizeInByte){
                        fileinfo.uploadProgress = 100;    
                    }
                    else{
                        fileinfo.uploadProgress = ((partNumber * this.largeFileUploadProcess.maxChunkSizeInByte)/fileinfo.filesize) * 100;
                    }
                    QLogger.LogInfo(this.logSrc,"Uploading File ContinueBase64 - Success " + data.getData());
                    this.uploadFileChunks(fileNumber, partNumber+1);
                }
                else{
                    this.largeFileUploadProcess.errorMessage = data.getErrorDetail();
                    QLogger.LogInfo(this.logSrc,"Uploading File ContinueBase64 - Error: " + data.getErrorDetail());
                }
            });
    }


    s3FileUploadContinue(chunk: Blob, fileNumber: number, partNumber: number){
    let response: Observable<QPMResponse>;
    let fileinfo = this.largeFileUploadProcess.filesInfo[fileNumber];
    QLogger.LogInfo(this.logSrc,"Uploading File Continue to "+ fileinfo.fullFilePath);
    const formData = new FormData();
    formData.append('file',chunk);
    response = this.webClient.s3FileUploadContinue(fileinfo.s3Key, fileinfo.fullFilePath, 1, formData);
    response.subscribe(
        (data: QPMResponse) => {
            if (this.app.sharedData.appInfo.logResponse) {
                QLogger.LogInfo(this.logSrc, "Uploading File Continue - Response: " + JSON.stringify(data));
            }
            if (data.isSuccess()) {                
                if(fileinfo.filesize <= partNumber * this.largeFileUploadProcess.maxChunkSizeInByte){
                    fileinfo.uploadProgress = 100;    
                }
                else{
                    fileinfo.uploadProgress = ((partNumber * this.largeFileUploadProcess.maxChunkSizeInByte)/fileinfo.filesize) * 100;
                }
                QLogger.LogInfo(this.logSrc,"Uploading File Continue - Success" + data.getData());
                this.uploadFileChunks(fileNumber, partNumber+1);
            }
            else{
                this.largeFileUploadProcess.errorMessage = data.getErrorDetail();
                QLogger.LogInfo(this.logSrc,"Uploading File Continue - Error: " + data.getErrorDetail());
            }
        });
    }

    s3FileUploadComplete(fileNumber: number){
    let req : S3UploadCompleteRequest;
    let response: Observable<QPMResponse>;
    let fileinfo = this.largeFileUploadProcess.filesInfo[fileNumber];
    QLogger.LogInfo(this.logSrc,"Uploading File Complete to "+ fileinfo.fullFilePath);
    req ={
        key: fileinfo.s3Key,
        path: fileinfo.fullFilePath
    }
    response = this.webClient.s3FileUploadComplete(req);
    response.subscribe(
        (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
            QLogger.LogInfo(this.logSrc, "Uploading File Complete - Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
            fileinfo.status = FileStatus.UploadComplete;
            QLogger.LogInfo(this.logSrc,"Uploading File Complete - Success" + data.getData());
            if(fileNumber === this.largeFileUploadProcess.filesInfo.length - 1){
                this.subject.next(true);
            }
            else{    
                if(this.config.skipStart){
                    this.uploadFileChunks(fileNumber+1, 1);
                }
                else{
                    this.s3FileUploadStart(fileNumber+1);
                }
            }
        }
        else{
            this.largeFileUploadProcess.errorMessage = data.getErrorDetail();
            QLogger.LogInfo(this.logSrc,"Uploading Local File Complete - Error: " + data.getErrorDetail());
        }
    });
    }
}