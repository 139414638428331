import {Component} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { environment } from 'src/environments/environment';
import { STARDUST } from './models/lime-web-client';
import { QLogger } from './common/logger';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl : './app.topbar.component.html'
})
export class AppTopbarComponent {
    private logSrc: string = "AppTopbar-Component";
    environment;
    constructor(private router: Router,public app: AppMainComponent) { 
        this.environment = environment;
    }

    stardustIssueType=STARDUST.IssueType;
    stardustProjectKey=STARDUST.ProjectKey;

    gotoCartPage(){
        QLogger.LogInfo(this.logSrc, "Redirecting To Cart-Page");
        this.router.navigate(['/main/licenses/cart']);
    }
}
