<div id="licensesseatPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">
    <div class="ui-g-12">
      <div class="card card-w-title" style="height:100%;">
  
        <!-- Header -->
        <div class="ui-g-12">
          <div class="ui-g-8" style="display:flex; justify-content: flex-start; height: 50px;flex-direction: column-reverse;">
            <p-tabMenu  [model]="seatsLandingMenuItems" [activeItem]="seatsLandingMenuActiveItem"  style="width:100%;"></p-tabMenu>
          </div>
        </div>
        <!--hr style="background: linear-gradient(to top, white 20%, #A7A9A9); height:25px;border:0;margin-left:-14px;margin-right:-14px;border-top: white;"-->
        <hr>
  
        <div class="ui-g-12">
          <div class="ui-inputgroup" style="margin-top: 20px;">
              <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
              <input id="FeaturesProductsSearch" pInputText type="text" placeholder="Search licence groups, tools, activation IDs, username, or computer name">
              <i class="ui-icon-cancel" id="LicenceGroupclearToolGlobalFilter" pTooltip="Clear Search" style="margin:4px 4px 0 0; cursor: pointer;"></i>        
          </div>
        </div>
        
      </div>
    </div>
  </div>
