<div id="CheckoutPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">

      <div class="ui-g-12 card card-w-title" style="height:100%;">
  
        <!-- Header : -->
        <div class="ui-g-12">
            <div class="ui-g-8" style="display:flex; justify-content: flex-start; height: 50px;">
                <h2 id="Checkout" style="align-self: center;padding-left: 10px;">Checkout</h2>
              </div>
        </div>
        <hr>
  
  
        <div class="ui-g-12" style="margin-top: 10px;">

            <div class="ui-g-8">
            
              <!-- Shipping Information -->
              <div class="ui-g-12" style="margin:0px;padding:0px;">
                <h2 style="display:inline-block">Shipping Information</h2>

                <div class="ui-g-12" style="margin-left: -7px;">
                  <h2 style="display:inline-block">Customer</h2>
 
                  <div id="email" style="margin:0px;padding:0px;margin-left: 5px;">
                    <span style="display:inline; color: gray;">{{informationProcess.customerEmailId}}</span>
                  </div> 
                </div>

                <div class="ui-g-12" style="margin-left: -7px;">
                  <h2 style="display:inline-block">Shipping Address</h2>  <i class="pi pi-exclamation-circle"  (mouseenter)="onHoverShippingAddress($event,shippingAddressInfoPanel)" [style]="{'font-size': '1.7em', 'cursor': 'pointer'}"></i>
                  
                 <p-overlayPanel #shippingAddressInfoPanel  [showCloseIcon]="true">
                  <span style="vertical-align: middle">
                    <p>To update shipping address, please visit <a style="cursor: pointer; color: blue;" href="https://cp.qti.qualcomm.com/" target="_blank">Qualcomm Sales Center</a></p>
                  </span>
                 </p-overlayPanel>

                
                 <div *ngIf="itemsAddedToCart?.length >1">
                    <div id="shippingAddrsses" style="margin:0px;padding:0px;" *ngFor="let item of itemsAddedToCart;let i = index">
                      <div class="p-col-12"> <span>{{item.responseLineDetail.shipToAddress}}</span><span> (Serial Number : <a (click)="app.goToSerialNumberDetailsPage(item?.selectedLicense?.serialNumber,item?.selectedLicense)" style="cursor: pointer; color: blue; display: inline-block;">{{item?.selectedLicense?.serialNumber}}</a>)</span></div>
                    </div>
                 </div>
                    
                    <div id="shippingAddrss" style="margin:0px;padding:0px;" *ngIf="itemsAddedToCart?.length==1">
                      <div class="p-col-12"> <span>{{itemsAddedToCart[0]?.responseLineDetail.shipToAddress}}</span></div>
                    </div>
                </div>
              </div>

               <!-- Billing Address -->
              <div class="ui-g-12" style="margin:0px;padding:0px;">
                  <h2 style="display:inline-block">Billing Address</h2> <i class="pi pi-exclamation-circle" (mouseenter)="onHoverBillingAddress($event,billingAddressInfoPanel)" [style]="{'font-size': '1.7em', 'cursor': 'pointer'}"></i>
                   
                  <p-overlayPanel #billingAddressInfoPanel  [showCloseIcon]="true">
                    <span style="vertical-align: middle">
                      <p>To update billing address, please send an email to <br> salesops.testtools with updated address information</p>
                    </span>
                  </p-overlayPanel>

                  <div *ngIf="itemsAddedToCart?.length >1">
                    <div id="Billingaddresses" style="margin:0px;padding:0px;" *ngFor="let item  of itemsAddedToCart;let i = index">
                      <div class="p-col-12"> <span>{{item.responseLineDetail.billToAddress}}</span><span> (Serial Number : <a (click)="app.goToSerialNumberDetailsPage(item?.selectedLicense?.serialNumber,item?.selectedLicense)" style="cursor: pointer; color: blue; display: inline-block;">{{item?.selectedLicense?.serialNumber}}</a>)</span></div>
                    </div>
                  </div>
                    
                  <div id="Billingaddress" style="margin:0px;padding:0px;" *ngIf="itemsAddedToCart?.length==1">
                    <div class="p-col-12"> <span>{{itemsAddedToCart[0]?.responseLineDetail.billToAddress}}</span></div>
                  </div>
              </div>
              
              

                  <!-- Payment Information -->
               <div class="ui-g-12" style="margin:0px;padding:0px;">
                <h2 style="display:inline-block">Payment Information</h2>

                <div class="field-checkbox" style="margin-top: 10px;margin-bottom: 25px;">
                  <p-checkbox [(ngModel)]="checkedStripeConcent" [binary]="true" inputId="binary"></p-checkbox>
                  <label for="binary"> I consent to Stripe, Inc. processing my payment information according to its 
                    <span *ngIf="app.sharedData.appInfo.isElectronMode">
                      <a  (click)="app.goToUrl('https://stripe.com/privacy')" style="cursor: pointer;  color: blue;">Privacy Policy .</a>
                   </span>
                   <span *ngIf="!app.sharedData.appInfo.isElectronMode">
                      <a href="https://stripe.com/privacy" target="_blank" style="cursor: pointer; color: blue;">Privacy Policy .</a>
                   </span>
                </label>
               </div>

                <form id="payment-form">
                  <div id="payment-element">
                    <!--Stripe.js injects the Payment Element-->
                  </div>
                  
                </form>
              </div>
              <button id="submit" (click)="pay()" [disabled]="paymentInProgress===true||checkedStripeConcent===false || orderSummaryProcess?.total==0 || orderSummaryProcess?.total>30000">
                <div class="spinner hidden" id="spinner"></div>
                <span id="button-text">Pay now</span>
              </button>
             
            </div>
    
            <div class="ui-g-4">
            
              <div class="ui-g-12">
                <h2 style="display:inline-block">Order Summary</h2>
                <hr>
                <div class="ui-g">
                    <div class="ui-g" *ngFor="let item of orderSummaryProcess?.itemsAddedToCart">

                      <div class="ui-g-12">
                        <div class="ui-g-7">
                          <div id="serialNumberHeader">
                            <p style=" color: gray;font-weight: bold; font-size: small;">SERIAL NUMBER RENEWAL</p> 
                          </div> 
                          <div id="serialNumber">
                            <p><a (click)="app.goToSerialNumberDetailsPage(item?.selectedLicense?.serialNumber,item?.selectedLicense)" style="cursor: pointer; color: blue; display: inline-block;">{{item?.selectedLicense?.serialNumber}}</a></p>
                          </div> 
                        </div>
                        <div class="ui-g-5">
                          <div id="price">
                            <p><span style="color: grey;">1 x ${{item?.responseLineDetail?.itemPrice}} :</span> <span> ${{item?.responseLineDetail?.itemPrice}}</span></p>
                          </div>
                          <div id="salestax">
                            <p><span style="color: grey;">Sales Tax :</span> <span> ${{item?.responseLineDetail?.itemTaxAmount}}</span></p>
                          </div>
                        </div>
                      </div>
                        
                      <div class="ui-g-12"  *ngFor="let licenseGroup of item?.selectedLicense?.licenseGroups">
                        <div class="ui-g-12">
                          <span style="color: grey;">License Group :</span>  <span><a (click)="app.goToLicenseGroupDetailsPage(licenseGroup?.id,item.selectedLicense)" style="cursor: pointer; color: blue; display: inline-block;">{{licenseGroup?.id}}</a></span>
                        </div>
                        <div class="ui-g-12">
                          <span style="color: grey;">Tools :</span>  <span> {{licenseGroup?.displayProductNames}}</span>
                        </div>
                      </div>

                    </div>

                    <div class="ui-g-12" style="margin:0px;padding:0px;">
                      <hr>
                      <div style="margin:0px;padding:0px;">
                          <p style="margin-left: 220px;"> <span style="color: grey;">Subtotal (US Dollars) :</span> ${{orderSummaryProcess?.subtotal}}</p>
                          <p style="margin-left: 220px;"> <span style="color: grey;">Shipping :</span>  {{orderSummaryProcess?.shipping}}</p> 
                      </div>
                    </div>
                    <div class="ui-g-12" style="margin:0px;padding:0px;">
                      <hr>
                      <div style="margin:0px;padding:0px;">
                        <p style="margin-left: 220px;"> <span style="color: grey;">Total (US Dollars) :</span> ${{orderSummaryProcess?.subtotal}}</p>
                     </div>

                    </div>
                </div>
              </div>
  
            
            </div>
  
          </div>
     
      </div>

  </div>
