import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';

import { CartItem, License, LicenseGroupFeature, LicenseGroupFeatureResponse, LicenseGroupForNotification, LicenseGroupProductCovered, LicenseGroupSeat, LicenseSeatDeactivateRequest } from 'src/app/models/lime-web-client';
import {Location} from '@angular/common'; 
import { commType } from 'src/app/common/shared-data';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  private logSrc:string = "Cart-Component";

  constructor(private router: Router, private activatedRoute: ActivatedRoute,private location: Location, 
    public app : AppMainComponent, private utils : Utils) {

  }
  

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "Cart Component Initialization");
    this.resetCartDetailsProcess();
    this.prepareCartDetails(this.app.sharedData.licenses.common.itemsAddedToCart);
  }


  cartDetailsProcess:{
    cartProcessingInprogress: boolean;
    itemsAddedToCart:CartItem[];
    totalPrice:number;
  }

  resetCartDetailsProcess(){
    this.cartDetailsProcess = {
        cartProcessingInprogress:false,
        itemsAddedToCart:[],
        totalPrice:0
    }
  }

  prepareCartDetails(sharedDataCartItems:CartItem[]){
  this.resetCartDetailsProcess();
  this.cartDetailsProcess.cartProcessingInprogress=true;
  this.cartDetailsProcess.itemsAddedToCart=sharedDataCartItems;
  this.cartDetailsProcess.totalPrice=0;
  sharedDataCartItems.forEach((item)=>{
      this.cartDetailsProcess.totalPrice=this.cartDetailsProcess.totalPrice + parseFloat(item.responseLineDetail.itemPrice)
     
  })
  this.cartDetailsProcess.totalPrice=Number(this.cartDetailsProcess.totalPrice.toFixed(2));
  this.cartDetailsProcess.cartProcessingInprogress=false;
  }


  removeItemFromCart(removeCartItem:CartItem){
    if((this.app.sharedData.licenses.common.itemsAddedToCart.filter(item => item.selectedLicense.serialNumber === removeCartItem.selectedLicense.serialNumber).length == 1)){
     let remainingLicensesForCart=this.app.sharedData.licenses.common.itemsAddedToCart.filter(item => item.selectedLicense.serialNumber!=removeCartItem.selectedLicense.serialNumber);
     this.app.sharedData.licenses.common.itemsAddedToCart=remainingLicensesForCart;
     this.cartDetailsProcess.itemsAddedToCart=this.app.sharedData.licenses.common.itemsAddedToCart;
     this.cartDetailsProcess.totalPrice=0;
     this.cartDetailsProcess.itemsAddedToCart.forEach((item)=>{
      this.cartDetailsProcess.totalPrice=this.cartDetailsProcess.totalPrice + parseFloat(item.responseLineDetail.itemPrice) 
     })
     this.cartDetailsProcess.totalPrice=Number(this.cartDetailsProcess.totalPrice.toFixed(2));

      this.app.sharedData.updateCommunicationSubcribers(commType.UpdateCartItems, this.app.sharedData.licenses.common.itemsAddedToCart);  
    }
  }


  goBackToPreviousVisitedPageFromCart() {
    QLogger.LogInfo(this.logSrc,"Back To Previous Visited Page");  
    this.location.back();
  }

  gotoCheckoutPage(){
    this.app.sharedData.licenses.common.itemsAddedToCart=this.cartDetailsProcess.itemsAddedToCart;
    this.resetCartDetailsProcess();
    this.app.resetRenewDialogProcess();
    this.router.navigate(['/main/licenses/checkout']);
  }

  goToLicenseGroupDetailsPage(licenseGroupID:string,rowData:License){
    QLogger.LogInfo(this.logSrc,"Redirection From Cart-Page to LicenseGroupDetails-Page");  
    this.app.goToLicenseGroupDetailsPage(licenseGroupID,rowData);
  }


  goToSerialNumberDetailsPage(serialNumber:string,rowData:License){
    QLogger.LogInfo(this.logSrc,"Redirection From Cart-Page to SerialNumberDetails-Page");
    this.app.goToSerialNumberDetailsPage(serialNumber,rowData);
  }
}
