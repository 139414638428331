export class LicenseDetails {
    activationKey: ActivationKey
    serverTimeUTC: Date
    status: string
    expirationDate: String
    products: string[]
}

export class ActivationKey {
    activatedd: string
    computerKey: string
    daysUsed: number
    features: Feature[]
    licenseActivationId: string
    licenseGroupId: string
    partnerId: string
    status: string
    userId: string
    versionId: number
}

export class Feature {
    endd: string
    featureType: string
    id: string
    length: number
    name: string
    productId: string
    productName: string
    startd: string
    daysLeft?: number
}

export class Entitlements{
    features:Feature[];
    id:string;
    licenseType:string;
}
//PRODUCT-BUNDLE
export class ProductWithVersion{
    productUUID:string;
    productName:string;
    version:string;
}
export class ProductBundleConfiguration{
    creator:string;
    os:string;
    timestamp:String;
    productList:ProductWithVersion[];
}