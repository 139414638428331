<div id="CloDetailPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">
    <div class="ui-g-12">
      <div class="card card-w-title" style="height:100%;">
  
        <!-- Header : Online mode-->
        <div class="ui-g-12">
          <div class="ui-g-6" style="display:flex; justify-content: flex-start; height: 80px;">
            <a (click)="goBack()" style="align-self: center;"><img src="assets/images/back_button.png"/></a>
            <img id="ProductIcon" [attr.alt]="selectedProduct?.displayName" src={{selectedProduct?.logoHTML}} style="width:48px;height:48px;align-self: center;" onerror="this.src='assets/images/DefaultBlueQ48.png'">
            <h2 id="ProductDisplayName" style="align-self: center;padding-left: 10px;">{{selectedProduct?.displayName}}</h2>
          </div>
    
          <!--Go To Exe-Details-->
          <div class="ui-g-2" *ngIf="this.app.sharedData.service.tools.selectedTool.productClassification==='Clo-exec'" style="display: grid;grid-template-columns: 2fr 1fr; grid-column-gap:10px;height:80px;">
              <div style="grid-column: 1;grid-row:1;">
                <button *ngIf="this.app.sharedData.service.tools.selectedTool.productClassification==='Clo-exec'"  type="button" pButton id="ExecutableButton" label="Show Exe Details" [style]="{'width': '150px','margin-top': '10px'}" (click)="goToToolDetails()"></button>
              </div>
          </div>

        </div>
    
 
        <hr>
  
        <div class="ui-g-12" style="padding-top: 50px;">
          <!-- Left column-->
          <div class="ui-g-8" style="padding-right: 50px;">
              <div *ngIf="loadingSelectedCLOProductDetails">
                <h3 style="display: inline-block;">Loading Details. Please wait...</h3>
                <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
              </div>
              <div>
                  <p style="color:gray;font-size:1.0em;">{{selectedProduct?.description}}</p>
                  <p id="OwnerTeam" *ngIf="app.sharedData.visibility.tools.viewOwnerTeam" style="color: gray;">Tool Owner: <a href="mailto:{{selectedProduct?.ownerTeam}}?subject={{selectedProduct?.displayName}} query" style="color:blue;">{{selectedProduct?.ownerTeam}}</a></p>      
              </div>
              


              <!--CLO Info Section-->
              <div style="padding-top: 20px;">
                <h3> CLO Release Information :</h3>
               
                <div style="margin-top: 10px;"  *ngIf="loadingSelectedCLOProductDetails==false && releaseVersions?.length > 0">                    
                      <div class="ui-g-12 copyCLO-link">
                        <input type="text" class="copyCLO-link-input" [(ngModel)]="selectedCLOReleaseInfoProcess.gitUrl" readonly>
                        <button type="button" class="copyCLO-link-button">
                          <span *ngIf="selectedCLOReleaseInfoProcess.isCopied===false" class="material-icons"(click)="copyCLOCommand(selectedCLOReleaseInfoProcess?.gitUrl)">content_copy</span>
                          <span *ngIf="selectedCLOReleaseInfoProcess.isCopied===true" class="material-icons">done</span>
                        </button>
                      </div>
                </div>
                <div style="margin-top: 10px;"  *ngIf="releaseVersions?.length == 0">                    
                  <div>
                    <span>No release information available</span>
                  </div>
                </div>
              </div>

          </div>

          <!-- Right column-->
          <div class="ui-g-4">
            <div style="display: grid;grid-template-columns: 1fr 1fr 1fr; grid-template-rows: 40px;grid-gap: 10px;">
              <div *ngIf="releaseVersions?.length > 0" style="grid-column: 1;grid-row: 1;">
                <p-dropdown style="width:100px;" id="VersionDropdown" appendTo="body" [options]="releaseVersions" filter="true" [virtualScroll]="true" itemSize="30" (onChange)="onVersionChange()" [(ngModel)]="selectedCloProductRelease">
                  <ng-template let-item pTemplate="selectedItem">
                    {{item.label}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{item.label}}
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
            <div *ngIf="releaseVersions?.length == 0">
              <span>No packages available</span>
            </div>

            <div *ngIf="releaseVersions.length > 0">
              <h3 style="padding-top:10px;">About this version:</h3>
              <hr>
              <p  style="color: gray;">Version: <span style="color: black;">{{selectedCLOReleaseInfoProcess?.version}}</span></p>
              <p  style="color: gray;">Release Date:  <span id="SelectedVersionReleaseDate" style="color: black;">{{selectedCLOReleaseInfoProcess?.publishEndDate}}</span></p>
              <p  (click)="downloadFiles(selectedCLOReleaseInfoProcess?.releaseNoteFileUrl)" 
                style="cursor: pointer;color: blue;">Download Release Note {{selectedCLOReleaseInfoProcess?.version}}</p>
              <p   (click)="downloadFiles(selectedCLOReleaseInfoProcess?.manifestFileUrl)" 
                  style="cursor: pointer;color: blue;">Download Manifest File {{selectedCLOReleaseInfoProcess?.version}}</p>
            </div>
    
            </div>
        </div>
      </div>
    </div>
  </div>