import { Injectable } from '@angular/core';
import { DataServiceFactory } from './DataServiceFactory';
import { ClientService } from '../Contract/ClientService';
import { WebClientService } from '../Contract/WebClientService';
import { CatalogClientService } from '../Contract/CatalogClientService';
import { SoftwareCatalogClientService } from '../Contract/SoftwareCatalogClient';
import { DistributionClientService } from '../Contract/DistributionClientService';

import { LimeWebClientService } from '../LimeService/lime-web-client.service';
import { LimeCatalogClientService } from '../LimeService/lime-catalog-client.service';
import { LimeSoftwareCatalogClientService } from '../LimeService/lime-software-catalog-client.service';
import { LimeDistributionClientService } from '../LimeService/lime-distribution-client.service';
import { LimeClientService } from '../LimeService/lime-client.service';
import { LimeLoggingService } from '../LimeService/lime-logging.service';

import { LoggingService } from '../Contract/LoggingService';
import { TelematicsService } from '../LimeService/telematics.service';
import { TelematicsClientService } from '../Contract/TelematicsClientService';
@Injectable()
export class LimeService implements DataServiceFactory{
    constructor(private limeWebClientService : LimeWebClientService, 
        private limeCatalogClientService : LimeCatalogClientService,
        private limeSoftwareCatalogClientService : LimeSoftwareCatalogClientService,
        private distributionClientService : LimeDistributionClientService,
        private limeClientService : LimeClientService,
        private limeLoggingService:LimeLoggingService,
        private telematicsClientService:TelematicsService){
    }

    public createClientService() : ClientService {
        return this.limeClientService;
    }

    public createWebClientService() : WebClientService {
        return this.limeWebClientService;
    }

    public createCatalogClientService() : CatalogClientService{
        return this.limeCatalogClientService;
    }

    public createSoftwareCatalogClientService() : SoftwareCatalogClientService{
        return this.limeSoftwareCatalogClientService;
    }

    public createDistributionClientService(): DistributionClientService {
        return this.distributionClientService;
    }   
    
    public createLoggingService(): LoggingService {
        return this.limeLoggingService;
    }    
    public createTelematicsClientService(): TelematicsClientService {
        return this.telematicsClientService;
    }    
}