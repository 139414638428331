  <div class="login-body" *ngIf="isElectronMode">
    <div class="card login-panel ui-fluid">      
        <a id="rightpanel-menu-button" *ngIf="isElectronMode" (click)="quitApp()" style="float: right; cursor: pointer;">
            <i class="ui-icon-close" style="font-size:18px"></i>
        </a>
        <div class="ui-g" (keyup.enter)="validateUser(email, password,false)">
            <div class="ui-g-12">
                <div>
                    <img class="login-logo" src="assets/images/qc_logo_flt_rgb_blk_pos.png">
                </div>
                <div>
                    <img class="login-shield" src="assets/images/icon-shield-person.png">
                    <div class="qcid">Qualcomm ID</div>
                </div>
                
            </div>
            <div class="ui-g-12">
                <span class="md-inputfield">
                    <input type="text"  id="emailtoLogin" pInputText [(ngModel)]="email" >
                    <label>Email/Username</label>
                </span>
            </div>
            <div class="ui-g-12">
                <span class="md-inputfield">
                    <input type="password" id="passwordtoLogin" pInputText [(ngModel)]="password" >
                    <label>Password</label>
                </span>
                <p *ngIf="pklaCheckFailed">Product Kit License Agreement is not signed.To sign License Agreement<a href="https://www.qualcomm.com/agreements">Click Here</a></p>
                <label class="errorMsg" [(ngModel)]="errorMsg">{{errorMsg}}</label>                
            </div>
            <div  #blockContextMenu  class="ui-g-12">
                <button pButton id="validateUserButton" label="Submit" style="width: 100%"  (click)="this.retryInterval = 0.25; validateUser(email, password,false);"></button>
                <!--button pButton id="login" label="Login" style="width: 100%"  (click)="this.authService.loginRequested()"></button>
                <button pButton id="logout" label="Logout" style="width: 100%"  (click)="this.authService.logoutRequested()"></button-->
                <button *ngIf="figureHeadEmail!=undefined" pButton id="figureHeadCredentialsButton" label="Figure Head Login"  style="width: 100%"  (click)="this.retryInterval = 0.25; onFigureHeadLoginCLick();"></button>
            </div>
            <p-contextMenu appendTo="body" 
            [target]="blockContextMenu" [style]="{'white-space':'nowrap', 'width': 'auto'}"
            [model]="items">
           </p-contextMenu>
            <div class="ui-g-12" style="text-align:left">
                <p><a *ngIf="sharedData.appInfo.isElectronMode" (click)="goToUrl('https://myaccount.qualcomm.com/forgotPassword')" style="cursor: pointer; color: blue;">Reset your password</a></p>
                <p *ngIf="!sharedData.appInfo.isElectronMode"><a href="https://myaccount.qualcomm.com/forgotPassword" target="_blank" style="cursor: pointer; color: blue;">Reset your password</a></p>
                <p>Don't have a Qualcomm ID? <a *ngIf="sharedData.appInfo.isElectronMode" (click)="goToUrl('https://myaccount.qualcomm.com/register?target=https://createpoint.qualcomm.com/data-services/login')" style="cursor: pointer; color: blue;">Sign up here.</a></p>
                <p *ngIf="!sharedData.appInfo.isElectronMode">Don't have a Qualcomm ID? <a href="https://myaccount.qualcomm.com/register?target=https://createpoint.qualcomm.com/data-services/login" target="_blank" style="cursor: pointer; color: blue;">Sign up here.</a></p>
            </div>
            <div class="ui-g-12">
                <p class="legal">© 2020 Qualcomm Technologies, Inc. and/or its affiliated companies</p>
            </div>
        </div>
    </div>
</div>
<div class="splash-screen" *ngIf="!isElectronMode">
    <div class="splash-loader-container">
        <svg class="splash-loader" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
           <circle class="splash-path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
    </div>
</div>

