import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, SelectItem } from 'primeng';
import { Observable } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { Company, CRDetails, CrGerritComment, DistributionRequest, imageShipDownloadResponse, MemberUpdateRequest, ServiceTaskCRGerritComment, SoftwareImageDistroInfo, SoftwareImageLatest, SoftwareProductDistroInfo, TriggerBuildRequest, UpdateServiceTaskRequest } from 'src/app/models/lime-web-client';
import { QPMResponse } from 'src/app/models/response';
import { ChangeRequest, deltaCRInfo, Gerrit, GerritSI, HistroyCR, parentServiceTask, ReleaseCR, ServiceTaskDistProduct, ServiceTaskInfo, ServiceTaskInfoLatestReleases, SoftwareImage, SoftwareProductDistro, SoftwareProductRelease, Watcher } from 'src/app/models/software-catalog-client';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { DistributionClientService } from 'src/app/service/Contract/DistributionClientService';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';

@Component({
  selector: 'app-st.main',
  templateUrl: './st.main.component.html',
  styleUrls: ['./st.main.component.css']
})
export class STMainComponent implements OnInit {

  private logSrc:string = "ServiceTaskTriage-Component";

  private catalogClient: CatalogClientService;
  private softwareCatalogClient : SoftwareCatalogClientService;
  private webClient : WebClientService;
  private distributionClient: DistributionClientService;
  @ViewChild('parentStPanel') parentStPanel: ElementRef;

  serviceTasksDetails: ServiceTaskInfo;
  serviceTasksDetailsLatestReleases : ServiceTaskInfoLatestReleases;
  softwareProductReleases: SoftwareProductRelease[];

  crDetails: CRDetails[];
  crDetail: CRDetails;
  crToAdd : string;
  crDetailRows : any[];
  crErrorMsg : string;
  validationNeeded : boolean;
  disableCrActions: boolean;
  failedCRList:String[];
  failedGerritList:String[];
  crActionOptions: SelectItem[];
  crShortActionOptions: SelectItem[];
  availableDiffBuildOptions:SelectItem[];

  stepsItems: MenuItem[];
  activeIndex: number = 0;
  
  crDetailColumns: any[];
  crDetailsRows: any[];

  cols: any[];
  validationSteps: any[];

  dialog : {
    displayCRMessageBox : boolean,
    header : string,
    ok : string
  }

  updateBaseBuildProcess:{
    displayForm: boolean,
    releases: SoftwareProductRelease[],
    releasesSelectItems: SelectItem[],
    selectedRelease: SoftwareProductRelease,
    loadingReleases: boolean,
    updatingRelease: boolean,
    errorMessage: string
  }
  
  addCrProcess: {
    displayAddCrRequest : boolean,
    displayAddCrForm : boolean,
    displaySummary: boolean,
    addCrInProgress: boolean,
    isRelatedCRs: boolean,
    crList: string,
    crLists: any[],
    crCols: any[],
    errorMessage: string,
    errorMessageResponse: string
  };

  deleteCRProcess: {
    displayDeleteCRForm: boolean,
    serviceTaskID: number,
    cr: ChangeRequest
  }

  updateCRList:any[];
  updateGerritList:any[];
  //#For dropdown CR_Delta dialogbox
  softwareTypes: any[];
  constructor(private ngZone: NgZone,private router: Router, private activatedRoute: ActivatedRoute, 
              public app : AppMainComponent, private utils : Utils, private service:DataServiceProducer) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.distributionClient = service.getServiceInstance(ApiType.DistributionClient) as DistributionClientService;
    // SelectItem API with label-value pairs
    this.crActionOptions = [
      {label:'Pending', value: 0},
      {label:'Accept', value: 1},
      {label:'Reject', value: 2},
      {label:'AlreadyFixed', value: 3}
    ];
    this.crShortActionOptions = [
      {label:'Accept', value: 1},
      {label:'Reject', value: 2},
      {label:'AlreadyFixed', value: 3}
    ];
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "ServiceTaskMain Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);
    this.activatedRoute.params.subscribe(params => {
      this.app.sharedData.service.common.selectedServiceTaskID = params['id'];
      
      // Static UI Elements
      this.stepsItems = [
        {label: 'Triage',
          command: (event: any) => {
              this.activeIndex = 0;
              this.router.navigate(['/main/software/servicetask/triage', this.app.sharedData.service.common.selectedServiceTaskID]);}},
        {label: 'Build',
          command: (event: any) => {
              this.activeIndex = 1;
              this.router.navigate(['/main/software/servicetask/build', this.app.sharedData.service.common.selectedServiceTaskID]);}},
        {label: 'Distribution',
          command: (event: any) => {
              this.activeIndex = 2;
              this.router.navigate(['/main/software/servicetask/distribution', this.app.sharedData.service.common.selectedServiceTaskID]);}}      
      ];
      this.serviceTasksDetails = new ServiceTaskInfo();
      this.crDetail = new CRDetails();
      
      this.dialog = {
        displayCRMessageBox : false,
        header : '',
        ok : ''
      }
      this.app.sharedData.service.common.abandonInProgress = false;
      this.availableDiffBuildOptions =[];
      this.app.sharedData.resetVisibility();
      this.loadServiceTaskDetails();      
      this.addDeleteCustomerProcessReset();
      this.resetAddCommentProcess();
      this.resetUpdateBaseBuildProcess();
      this.resetUpdateWatcherProcess();
      this.resetAddCustomBaseImageProcess();
      this.resetupdateSWAddonsProcess();
    });

    this.resetRelatedServiceTaskPanel();
    this.updateCRList =[];
    this.updateGerritList =[]
    this.failedCRList = [];
    this.failedGerritList =[];
    //this.loadServiceTaskTriageDetails();
    this.softwareTypes = [
      {label: "Product Build", value: false},
      {label: "Image Build", value: true}
     ];
    this.resetCRdeltaProcess();
  }

  loadServiceTaskDetails() {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details");

    if(this.softwareCatalogClient === null || this.app.sharedData.service.common.selectedServiceTaskID === "0"
    || this.app.sharedData.service.common.selectedServiceTaskID === "" || this.app.sharedData.service.common.selectedServiceTaskID === undefined){
      return;
    }
    
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetails(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Response : " +JSON.stringify(data));
        } 
        if(data.getCode() === 401){
          this.router.navigate(['/main/accessdenied']);
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            this.serviceTasksDetails = obj as ServiceTaskInfo;
            this.loadAdditionalInfo();
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
    });
  }

  loadAdditionalInfo(){
    this.serviceTasksDetails.updatedCaption=this.serviceTasksDetails.caption;
    this.serviceTasksDetails.updatedPrimaryOwner = this.serviceTasksDetails.primaryOwner;
    this.serviceTasksDetails.updatedSecondaryOwner = this.serviceTasksDetails.secondaryOwner;
    this.serviceTasksDetails.hasParent = false;
    this.app.sharedData.service.common.master = this.serviceTasksDetails.master;
    this.app.sharedData.service.common.isOwner = false;
    if(this.app.sharedData.userInfo.username.toLocaleLowerCase() === this.serviceTasksDetails.requester.toLocaleLowerCase()){
      this.app.sharedData.service.common.isOwner = true;
    }
    this.app.sharedData.setVisibility();

    this.serviceTasksDetails.changes = this.serviceTasksDetails.changes as GerritSI[];
                       
    // Clean up requestSource and requestAppId data
    if (this.serviceTasksDetails.requestSource.indexOf('planner') > -1) {
      this.serviceTasksDetails.requestSource = "Planner";
    }
    else if (this.serviceTasksDetails.requestSource.indexOf('salesforce') > -1) {
      this.serviceTasksDetails.requestSource = "Salesforce";
    }
    else if (this.serviceTasksDetails.requestSource.indexOf('QPM') > -1) {
      this.serviceTasksDetails.requestSource = "QPM";
    }

    if(this.serviceTasksDetails.requestAppId ==null){
      this.serviceTasksDetails.requestAppId = '';
    }
    this.serviceTasksDetails.updatedRequestedAppid = this.serviceTasksDetails.requestAppId;

    this.loadWatchers();
    this.loadServiceTaskDetailsLatestReleases();
    this.loadReleaseCRs(this.serviceTasksDetails);
    this.loadHistoryCRs(this.serviceTasksDetails);
    this.loadParentSTDetailsLatestReleases(this.serviceTasksDetails);
    if(this.serviceTasksDetails.createdUsingSI){
      this.loadBaseImages();
      if(this.serviceTasksDetails.baseBuild === null){
        this.getAvailableReleases(this.serviceTasksDetails.softwareProduct);
      }
    }
  }

  loadBaseImages(){
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Images");
    }

    let response : Observable<QPMResponse>;
    response = this.webClient.getServiceTaskBaseImage( this.serviceTasksDetails.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Images - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Images - Success"); 
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.serviceTasksDetails.serviceTaskImageBuilds = obj.imageBuilds as SoftwareImage[];
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Images - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Images - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  loadReleaseCRs(serviceTask: ServiceTaskInfo){
    QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " Release CRs");

    if(this.softwareCatalogClient === null || serviceTask.serviceTaskId === 0){
      return;
    }
    
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetailsReleaseCRs(serviceTask.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " Release CRs - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " Release CRs - Success");
          
          let obj = JSON.parse(data.getData());
          let buildIdSplit: string[];
          if(obj !== undefined || obj !== null) {
            let crs: ReleaseCR[] = obj.productBuilds as ReleaseCR[];
            crs.forEach((cr)=>{
              buildIdSplit = cr.productBuild.split(".");
              cr.spinNumber = Number.parseInt(buildIdSplit[buildIdSplit.length - 1]);
            });
            serviceTask.releaseCRs = crs;
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " Release CRs - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " Release CRs - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
    });
  }
  
  loadHistoryCRs(serviceTask: ServiceTaskInfo){    
    QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " History CRs");

    if(this.softwareCatalogClient === null || serviceTask.serviceTaskId === 0){
      return;
    }
    
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetailsHistoryCRs(serviceTask.serviceTaskId.toString());
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " History CRs - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " History CRs - Success");
          
          let obj = JSON.parse(data.getData());
          let buildIdSplit: string[];
          if(obj !== undefined || obj !== null) {
            let crs: HistroyCR[] = obj.serviceTaskHistory as HistroyCR[];
            crs.forEach((cr)=>{
              if(cr.productBuild !== null){
                buildIdSplit = cr.productBuild.split(".");
                cr.spinNumber = Number.parseInt(buildIdSplit[buildIdSplit.length - 1]);
              }
            });
            serviceTask.historyCRs = crs;
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " History CRs - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task " + serviceTask.serviceTaskId + " History CRs - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
    });
  }

  loadServiceTaskDetailsLatestReleases() {
    QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+"Latest Release Details");

    if(this.softwareCatalogClient === null || this.app.sharedData.service.common.selectedServiceTaskID === "0"
    || this.app.sharedData.service.common.selectedServiceTaskID === ""){
      return;
    }
    
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetailsLatestReleases(this.app.sharedData.service.common.selectedServiceTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+"Latest Release Details - Response : " +JSON.stringify(data));
        } 
        if(data.getCode() === 401){
          this.router.navigate(['/main/accessdenied']);
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+"Latest Release Details - Success");
          
          let obj = JSON.parse(data.getData()) as ServiceTaskInfoLatestReleases;
          if(obj !== undefined || obj !== null) {
            this.serviceTasksDetailsLatestReleases = obj;
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+"Latest Release Details - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task "+this.app.sharedData.service.common.selectedServiceTaskID+"Latest Release Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
    });
  }
 
  TriggerBuild(rowData: ChangeRequest){
    
    if(this.webClient === null||rowData==null) {
      return
    }
    let crDetail = rowData;
    let response : Observable<QPMResponse>;
    // Handle single CR change at a time, but format into array
    let action = this.crActionOptions[rowData.selectedOptions].label;
    let cr = Object.assign({}, crDetail);
    cr.action = action;
    
    let request: TriggerBuildRequest;
    request = {
      productBuild: this.serviceTasksDetails.baseBuild,
      changeRequestAction: cr,
      serviceTaskId: this.serviceTasksDetails.serviceTaskId,
      createdUsingSI: this.serviceTasksDetails.createdUsingSI,
      requestAppId: this.serviceTasksDetails.requestAppId,
      requestSource: this.serviceTasksDetails.requestSource
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Trigger Build Request: " + JSON.stringify(request));
    }
    response = this.webClient.triggerBuild(request);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Trigger Build - Success Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){

          QLogger.LogInfo(this.logSrc, "Trigger Build - Success");
          crDetail.action = action;
          crDetail.actionInProgress = false;
          crDetail.buildTriggered = true;
          if(action === "Accept") crDetail.acceptStatus = 1;
          let request: CrGerritComment = {
            crId: crDetail.changeRequest,
            gerritId: undefined,
            softwareImage: crDetail.softwareImage
          }
          this.getComments(request, crDetail);
        }
        else {
          
          this.failedCRList.push((crDetail.changeRequest).toString());
          QLogger.LogError(this.logSrc, "Trigger Build - Failed");
          QLogger.LogError(this.logSrc, "Trigger Build - Failed Error : " + data.getError() + " - " + data.getErrorDetail());

          if (data.getError() === undefined || data.getError() === null) {
            QLogger.LogInfo(this.logSrc, "Trigger Build Response - Timeout : " + data.getCode());
          }
          else {   
            crDetail.action = rowData.actionPrevious;
            rowData.selectedOptions = crDetail.action === "Pending"? 0 : 2;
           

          }
          crDetail.actionInProgress = false; 
          crDetail.buildTriggered = false;    
        }
        if(this.updateCRList.length>0)
        {
           this.TriggerBuild(this.updateCRList.shift());
        }
        else
        {
            if(this.failedCRList?.length>0)
            {
              let crString = this.failedCRList?.join(',')
              this.app.showMessageBox("Update CR(s) Status","Status update of CR(s) "+crString+" has Failed.","OK");
              this.failedCRList =[];
            }
        }
        //this.loadServiceTaskDetails();
      }
    );
  }

  
  TriggerBuildWithGerrit(rowData: Gerrit){
    if(this.webClient === null||rowData==null) {
      return
    }
    let gerritDetail = rowData;
    let response : Observable<QPMResponse>;

    
    // Handle single Gerrit change at a time, but format into array
    let action = this.crActionOptions[rowData.selectedOptions].label;
    let gerrit = Object.assign({}, gerritDetail);
    gerrit.action = action;
    let request: TriggerBuildRequest;
    request = {
      productBuild: this.serviceTasksDetails.baseBuild,
      gerritAction: gerrit,
      serviceTaskId: this.serviceTasksDetails.serviceTaskId,
      createdUsingSI: this.serviceTasksDetails.createdUsingSI,
      requestAppId: this.serviceTasksDetails.requestAppId,
      requestSource: this.serviceTasksDetails.requestSource
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Trigger Build Request with Gerrits: " + JSON.stringify(request));
    }
    response = this.webClient.triggerBuild(request);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Trigger Build with Gerrits - Success Response : " +JSON.stringify(data));
        } 
        if (data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Trigger Build with Gerrits - Success");
          gerritDetail.action = action;
          gerritDetail.actionInProgress = false;     
          gerritDetail.buildTriggered = true;
          if(action === "Accept") gerritDetail.acceptStatus = 1;
          let request: CrGerritComment = {
            crId: 0,
            gerritId: gerritDetail.gerritId,
            softwareImage: gerritDetail.softwareImage
          }
          this.getComments(request, gerritDetail);
        }
        else {
          this.failedGerritList.push((gerritDetail.gerritId).toString());
          QLogger.LogError(this.logSrc, "Trigger Build with Gerrits - Failed");
          QLogger.LogError(this.logSrc, "Trigger Build with Gerrits - Failed Error : " + data.getError() + " - " + data.getErrorDetail());

          if (data.getError() === undefined || data.getError() === null) {
            QLogger.LogInfo(this.logSrc, "Trigger Build Response with Gerrits - Timeout : " + data.getCode());
          }
          else {   
            gerritDetail.action = rowData.actionPrevious;
            rowData.selectedOptions = gerritDetail.action === "Pending"? 0 : 2;
          }
          gerritDetail.actionInProgress = false; 
          gerritDetail.buildTriggered = false;    
        }
        if(this.updateGerritList.length>0)
        {
          this.TriggerBuildWithGerrit(this.updateGerritList.shift());
        }
        else
        {
             if(this.failedGerritList.length>0)
             {
              let gerritString = this.failedGerritList?.join(',')
              this.app.showMessageBox("Update Gerrit(s) Status","Status update of Gerrit(s) "+gerritString+" has Failed. " + data.getError(),"OK")
              this.failedGerritList =[];
             }
        }
      }
    );
  }

  //#region UpdateWatchers
  updateWatcherProcess:{
    displayForm: boolean,
    userId: string,
    updateInProgress: boolean,
    loadMembersInProgress: boolean,
    errorMessage: string
  }

  resetUpdateWatcherProcess(){
    this.updateWatcherProcess = {
      displayForm: false,
      userId: '',
      updateInProgress: false,
      loadMembersInProgress: false,
      errorMessage: ''
    }
  }

  onUpdateWatcherClick(){
    this.resetUpdateWatcherProcess();
    this.serviceTasksDetails.watchers = [];
    this.loadWatchers();
    this.updateWatcherProcess.displayForm = true;
  }

  addWatcher(uid: string){
    let request: MemberUpdateRequest = {
      accessType: 1,
      enabled: true,
      uid: uid
    }
    this.updateWatcher(this.serviceTasksDetails.serviceTaskId.toString(), request);
  }

  deleteWatcher(uid: string){
    let request: MemberUpdateRequest = {
      accessType: 1,
      enabled: false,
      uid: uid
    }
    this.updateWatcher(this.serviceTasksDetails.serviceTaskId.toString(), request);
  }

  updateWatcher(serviceTaskId: string, request: MemberUpdateRequest){
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Service Task " + serviceTaskId + " Watchers Update - Request: " + JSON.stringify(request));
    }

    this.updateWatcherProcess.updateInProgress = true;
    this.updateWatcherProcess.errorMessage = "";
    let response : Observable<QPMResponse>;
    response = this.webClient.updateServiceTaskMembers(serviceTaskId.toString(), request);
    response.subscribe((data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Service Task " + serviceTaskId + " Watchers Update - Response : " +JSON.stringify(data));
      }   
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Service Task " + serviceTaskId + " Watchers Update - Success"); 
        let obj = JSON.parse(data.getData());
        if (obj !== undefined || obj !== null) {
          if(obj.success){
            this.loadWatchers();
            this.updateWatcherProcess.userId = "";
          }
          else{
            this.updateWatcherProcess.errorMessage = "Error: " + obj.message;
          }
        }
      }
      else {
        QLogger.LogError(this.logSrc, "Service Task " + serviceTaskId + " Watchers Update - Failed");
        QLogger.LogError(this.logSrc, "Service Task " + serviceTaskId + " Watchers Update - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        this.updateWatcherProcess.errorMessage = "Error: " + data.getErrorDetail();
      }
      this.updateWatcherProcess.updateInProgress = false;
    });
  }

  loadWatchers(){
    let serviceTaskId = this.serviceTasksDetails.serviceTaskId;
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Service Task " + serviceTaskId + " Watchers");
    }

    this.updateWatcherProcess.loadMembersInProgress = true;
    this.updateWatcherProcess.errorMessage = "";
    let response : Observable<QPMResponse>;
    response = this.webClient.getServiceTaskMembers(serviceTaskId.toString(), 1);
    response.subscribe((data:QPMResponse) => {
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Service Task " + serviceTaskId + " Watchers - Response : " +JSON.stringify(data));
      }   
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Service Task " + serviceTaskId + " Watchers - Success"); 
        let obj = JSON.parse(data.getData());
        if (obj !== undefined || obj !== null) {
          this.serviceTasksDetails.watchers = obj.members as Watcher[];
          this.serviceTasksDetails.isWatcher = false;
          if(this.serviceTasksDetails.watchers.find((w)=>w.uid === this.app.sharedData.userInfo.username) !== undefined){
            this.serviceTasksDetails.isWatcher = true;
          }
        }
      }
      else {
        QLogger.LogError(this.logSrc, "Service Task " + serviceTaskId + " Watchers - Failed");
        QLogger.LogError(this.logSrc, "Service Task " + serviceTaskId + " Watchers - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        this.updateWatcherProcess.errorMessage = "Error: " + data.getErrorDetail();
      }
      this.updateWatcherProcess.loadMembersInProgress = false;
    });
  }
  //#endregion


  //#region UpdateBaseBuild
  resetUpdateBaseBuildProcess(){
    this.updateBaseBuildProcess = {
      displayForm: false,
      releases: [],
      releasesSelectItems: [],
      selectedRelease: undefined,
      loadingReleases: false,
      updatingRelease: false,
      errorMessage: ""
    };
  }

  onBaseBuildUpdateAction(){
    this.updateBaseBuildProcess.displayForm = true;
  }

  getAvailableReleases(softwareProduct: string) {
    QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds");
    this.updateBaseBuildProcess.loadingReleases = true;
    let response: Observable<QPMResponse>;
    //this.versions = SoftwareData.getAvailableReleaseData();

    response = this.softwareCatalogClient.getSoftwareProductBuilds(softwareProduct);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Response : " +JSON.stringify(data));
        }
        this.updateBaseBuildProcess.releases = [];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.updateBaseBuildProcess.releases = obj.softwareProductReleases;
            this.updateBaseBuildProcess.releases = this.updateBaseBuildProcess.releases.sort((r1,r2)=> r2.shipDate.localeCompare(r1.shipDate));
            this.updateBaseBuildProcess.releasesSelectItems = [];
            this.updateBaseBuildProcess.releasesSelectItems.push({
              label: "",
              value: {spfTag: "SPF Tag", softwareProductBuild: "Software Product Build"},
              disabled: true
            });
            this.updateBaseBuildProcess.releasesSelectItems.push(...this.utils.getSelectItemsMultiLabel(this.updateBaseBuildProcess.releases, ["spfTag", "softwareProductBuild"], "-"));
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Failed");
          QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
          
        }
        this.updateBaseBuildProcess.loadingReleases = false;
      }
    );
  }

  updateServiceTaskBaseBuild(){
      this.updateBaseBuildProcess.errorMessage = "";
      this.updateBaseBuildProcess.displayForm = false;
      this.updateBaseBuildProcess.updatingRelease = true;
      let request: UpdateServiceTaskRequest;
      request = {
          abandoned: false,
          baseBuild: this.updateBaseBuildProcess.selectedRelease.softwareProductBuild,
          baseBuildTag: this.updateBaseBuildProcess.selectedRelease.tag,
          baseBranch: this.updateBaseBuildProcess.selectedRelease.branch,
          baseBuildSpf: this.updateBaseBuildProcess.selectedRelease.spf,
          createdUsingSI: this.serviceTasksDetails.createdUsingSI,
          serviceTaskId: this.serviceTasksDetails.serviceTaskId,
          requestAppId: this.serviceTasksDetails.requestAppId,
          requestSource: this.serviceTasksDetails.requestSource
      };

      let response: Observable<QPMResponse>;
      if(this.app.sharedData.appInfo.logRequest){
          QLogger.LogInfo(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Build " + this.updateBaseBuildProcess.selectedRelease.softwareProductBuild + " request : " + JSON.stringify(request));
      }
      response = this.webClient.updateServiceTask(this.serviceTasksDetails.serviceTaskId.toString(), request);
      response.subscribe(
      (data: QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Build " + this.updateBaseBuildProcess.selectedRelease.softwareProductBuild + " - Response : " +JSON.stringify(data));
          }
          if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Build " + this.updateBaseBuildProcess.selectedRelease.softwareProductBuild + " Response - Success");
              let obj = JSON.parse(data.getData());
              if(obj !== undefined || obj !== null){
                  this.loadServiceTaskDetails();
                  this.resetUpdateBaseBuildProcess();
              }
          }
          else {
              QLogger.LogError(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Build " + this.updateBaseBuildProcess.selectedRelease.softwareProductBuild + " - Failed");
              QLogger.LogError(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " Base Build " + this.updateBaseBuildProcess.selectedRelease.softwareProductBuild + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
              this.updateBaseBuildProcess.errorMessage = "Error : " + data.getError();
          }
          this.updateBaseBuildProcess.updatingRelease = false;
      });
  }
  //#endregion

  //#region Add/Update Customers
  addDeleteCustomerProcess: {
    displayForm: boolean,
    header: string,
    serviceTasksDetails: ServiceTaskInfo,
    companies: Company[],
    selectedCompanies: Company[],
    errorMessage: string,
    addProcess: boolean,
    deleteProcess: boolean,
    loadCustomerInProgress: boolean,
    addCustomerInProgress: boolean,
    deleteCustomerInProgress: number
  }

  addDeleteCustomerProcessReset(){
      this.addDeleteCustomerProcess = {
          displayForm: false,
          header: "",
          serviceTasksDetails: undefined,
          companies: [],
          selectedCompanies: [],
          errorMessage: '',
          addProcess: false,
          deleteProcess: false,
          loadCustomerInProgress: false,
          addCustomerInProgress: false,
          deleteCustomerInProgress: 0
      }
  }

  onAddCustomerClick(serviceTasksDetails:ServiceTaskInfo){
      this.addDeleteCustomerProcessReset();
      this.addDeleteCustomerProcess.addProcess = true;
      this.addDeleteCustomerProcess.header = "Add Customers";
      this.app.getAvailbleCompanies(serviceTasksDetails.baseBuild,serviceTasksDetails.baseBuildSpfTag, this.addDeleteCustomerProcess);
      this.addDeleteCustomerProcess.serviceTasksDetails = serviceTasksDetails;
      this.addDeleteCustomerProcess.displayForm = true;
  }


  onDeleteCustomerClick(serviceTasksDetails:ServiceTaskInfo){
      this.addDeleteCustomerProcessReset();
      this.addDeleteCustomerProcess.deleteProcess = true;
      this.addDeleteCustomerProcess.header = "Delete Customers";
      //this.getAvailbleCompanies(serviceTasksDetails.baseBuild, this.addDeleteCustomerProcess);
      this.addDeleteCustomerProcess.serviceTasksDetails = serviceTasksDetails;
      this.addDeleteCustomerProcess.companies = serviceTasksDetails.companies?.filter(company=>company.companyName!=='Qualcomm');
      this.addDeleteCustomerProcess.displayForm = true;
  }

  
  onaddDeleteServiceTaskCustomer(){
      if(this.addDeleteCustomerProcess.addProcess){
          this.addServiceTaskCustomer();
      }
      else if(this.addDeleteCustomerProcess.deleteProcess){
          this.addDeleteCustomerProcess.selectedCompanies.forEach((company)=>{
              this.deleteServiceTaskCustomer(company);
          });
      }
  }

  addServiceTaskCustomer(){
      this.addDeleteCustomerProcess.errorMessage = "";
      this.addDeleteCustomerProcess.addCustomerInProgress = true;
      let request: UpdateServiceTaskRequest;
      request = {
          abandoned: false,
          baseBuild: this.addDeleteCustomerProcess.serviceTasksDetails.baseBuild,
          companies: this.addDeleteCustomerProcess.selectedCompanies,
          serviceTaskId: this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId,
          requestAppId: this.addDeleteCustomerProcess.serviceTasksDetails.requestAppId,
          requestSource: this.addDeleteCustomerProcess.serviceTasksDetails.requestSource
      };

      let response: Observable<QPMResponse>;
      if(this.app.sharedData.appInfo.logRequest){
          QLogger.LogInfo(this.logSrc, "Update Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customers request : " + JSON.stringify(request));
      }
      response = this.webClient.updateServiceTask(this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId.toString(), request);
      response.subscribe(
      (data: QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Update Service Task Customers Response - Response : " +JSON.stringify(data));
          }
          
          if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Update Service Task Customers Response - Success");
              let obj = JSON.parse(data.getData());
              if(obj !== undefined || obj !== null){
                  this.addDeleteCustomerProcess.serviceTasksDetails.companies = obj.companies as Company[];
                  this.addDeleteCustomerProcessReset();
              }
          }
          else {
              QLogger.LogError(this.logSrc, "Update Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customers - Failed");
              QLogger.LogError(this.logSrc, "Update Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customers - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
              this.addDeleteCustomerProcess.errorMessage = "Failed to add Customer(s). Error : " + data.getError();
          }
          this.addDeleteCustomerProcess.addCustomerInProgress = false;
      });
  }

  deleteServiceTaskCustomer(company: Company){
      this.addDeleteCustomerProcess.errorMessage = "";
      this.addDeleteCustomerProcess.deleteCustomerInProgress++;
      
      let response: Observable<QPMResponse>;
      if(this.app.sharedData.appInfo.logRequest){
          QLogger.LogInfo(this.logSrc, "Delete Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customer : " + company.partyId + " " + company.companyName);
      }
      response = this.softwareCatalogClient.deleteSoftwareProductCustomers(this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId.toString(),  company.partyId.toString());
      response.subscribe(
      (data: QPMResponse) => {
          if(this.app.sharedData.appInfo.logResponse){
              QLogger.LogInfo(this.logSrc, "Delete Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customer : " + company.partyId + " " + company.companyName + " Response: " +JSON.stringify(data));
          }
          
          if (data.isSuccess()) {
              QLogger.LogInfo(this.logSrc, "Delete Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customer : " + company.partyId + " " + company.companyName + " Response - Success");
              let obj = JSON.parse(data.getData());
              if(obj !== undefined || obj !== null){
                  if(obj.success === true && this.addDeleteCustomerProcess.deleteCustomerInProgress === 1){
                      this.addDeleteCustomerProcessReset();
                      this.loadServiceTaskDetails();
                  }
              }
          }
          else {
              QLogger.LogError(this.logSrc, "Delete Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customer : " + company.partyId + " " + company.companyName + " - Failed");
              QLogger.LogError(this.logSrc, "Delete Service Task " + this.addDeleteCustomerProcess.serviceTasksDetails.serviceTaskId + " Customer : " + company.partyId + " " + company.companyName + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
              this.addDeleteCustomerProcess.errorMessage = "Failed to delete Customer(s). Error : " + data.getError();
          }
          this.addDeleteCustomerProcess.deleteCustomerInProgress--;
      });
  }

  //#endregion
  
  //#region CR Comments
  addCommentProcess: {
    displayAddComment: boolean,
    displayViewAllComments: boolean,
    rowData: any,
    id: string,
    isGerrit: boolean,
    softwareImage: string,
    cr: any,
    displayRejectConfirmation: boolean,
    commentToAdd: string,
    errorMessage:string
  }

  resetAddCommentProcess(){
    this.addCommentProcess = {
      displayAddComment: false,
      displayViewAllComments: false,
      rowData: undefined,
      id: undefined,
      isGerrit: false,
      softwareImage: undefined,
      cr: undefined,
      displayRejectConfirmation: false,
      commentToAdd: "",
      errorMessage: ""
    }
  }

  onShowAddCommentDialog(id: string, image: string, cr: any, isGerrit:boolean = false) {
    this.addCommentProcess.id = id;
    this.addCommentProcess.isGerrit = isGerrit;
    this.addCommentProcess.softwareImage = image;
    this.addCommentProcess.cr = cr;
    this.addCommentProcess.displayAddComment = true;
    this.addCommentProcess.displayViewAllComments = false;
    this.addCommentProcess.displayRejectConfirmation = false;
  }

  onShowAllCommentsDialog(id: string, image: string, cr: any, isGerrit:boolean = false) {
    this.addCommentProcess.id = id;
    this.addCommentProcess.isGerrit = isGerrit;
    this.addCommentProcess.softwareImage = image;
    this.addCommentProcess.cr = cr;
    this.addCommentProcess.displayAddComment = false;
    this.addCommentProcess.displayViewAllComments = true;
    this.addCommentProcess.displayRejectConfirmation = false;
  }

  onCloseAllCommentsDialog() {
    this.addCommentProcess.id = undefined;
    this.addCommentProcess.isGerrit = false;
    this.addCommentProcess.softwareImage = undefined;
    this.addCommentProcess.cr = undefined;
    this.addCommentProcess.displayViewAllComments = false;
  }

  onAddComment(){
    let request: CrGerritComment = new CrGerritComment();
    if(this.addCommentProcess.isGerrit){
      request.gerritId = this.addCommentProcess.id;
    }
    else{
      request.crId = parseInt(this.addCommentProcess.id);
    }
    request.softwareImage = this.addCommentProcess.softwareImage;
    request.commentDesc = this.addCommentProcess.commentToAdd;
    this.addComment(request, this.addCommentProcess.cr);

    if (this.addCommentProcess.displayRejectConfirmation == true) {
      this.updateCRList =[];
      this.updateCRList.push(this.addCommentProcess.rowData);
      this.TriggerBuild(this.updateCRList.shift());
    }

    // Reset structure
    this.addCommentProcess.displayAddComment = false;
    this.addCommentProcess.commentToAdd = "";
    this.addCommentProcess.displayRejectConfirmation = false;
    this.addCommentProcess.id = undefined;
    this.addCommentProcess.cr = undefined;
  }

  getComments(request: CrGerritComment, cr: any) {
    let id: string;
    let type: string;
    if(request.crId !== 0 && request.crId !== undefined && request.crId !== null){
      id = request.crId.toString();
      type = "CR";
    }
    else{
      id = request.gerritId;
      type = "Gerrit";
    }
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Service Task " + this.serviceTasksDetails.serviceTaskId + 
                                   ": Get " + type + " " + id +  " Comments - Request: " + JSON.stringify(request));
    }

    let getCRDetailsResponse : Observable<QPMResponse>;
    getCRDetailsResponse = this.webClient.getServiceTaskCRGerritComments( this.serviceTasksDetails.serviceTaskId.toString(), request);
    getCRDetailsResponse.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get " + type + " Comments "+ id +"Response - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get " + type + " Comments "+ id +"Response - Success"); 
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            let commentsTemp : ServiceTaskCRGerritComment[] = obj.serviceTaskCRComments;
            // Sort: newest on top
            cr.comments = commentsTemp.sort((h1,h2) => h1.creationDateTime > h2.creationDateTime ? -1:1); 
            //this.triageTable.reset();    
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get " + type + " Comments "+ id +" - Failed");
          QLogger.LogError(this.logSrc, "Get " + type + " Comments "+ id +" - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  addComment(request: CrGerritComment, cr: any) {
    let id: string;
    let type: string;
    if(request.crId !== 0 && request.crId !== undefined && request.crId !== null){
      id = request.crId.toString();
      type = "CR";
    }
    else{
      id = request.gerritId;
      type = "Gerrit";
    }

    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Service Task " + this.serviceTasksDetails.serviceTaskId + 
                                   ": Add " + type + " " + id +  " Comments - Request: " + JSON.stringify(request));
    }

    let addCRDetailResponse : Observable<QPMResponse>;
    addCRDetailResponse = this.webClient.addServiceTaskCRGerritComment( this.serviceTasksDetails.serviceTaskId.toString(), 
                                                                   request );
    addCRDetailResponse.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Add " + type + " Comment " + id + " Response - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Add " + type + " Comment " + id + " Response - Success"); 
          this.getComments(request, cr);
        }
        else {
          QLogger.LogError(this.logSrc, "Add " + type + " Comment " + id + " - Failed");
          QLogger.LogError(this.logSrc, "Add " + type + " Comment " + id + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }
  commentValidation()
  {
     let commentLen = this.addCommentProcess.commentToAdd.length;
     if(commentLen>255)
     {
      this.addCommentProcess.errorMessage = "Comment cannot be more than 255 characters long";
     }
     else
     this.addCommentProcess.errorMessage = "";
  }
  //#endregion


  //#region ParentST
  
  relatedServiceTaskPanel: {
    displayForm: boolean;
    parentServiceTask: parentServiceTask[];
  }

  resetRelatedServiceTaskPanel(){
    this.relatedServiceTaskPanel = {
      displayForm : false,
      parentServiceTask : []
    }
  }

  goToServiceTask(serviceTaskId: number){
    this.resetRelatedServiceTaskPanel();
    this.app.goToServiceTask(serviceTaskId);
  }

  showParent(){
    this.relatedServiceTaskPanel.displayForm = true;
    this.relatedServiceTaskPanel.parentServiceTask = [];
    this.loadParent(this.serviceTasksDetails);
  }

  loadParent(childServiceTask: ServiceTaskInfo){
    let parentServiceTask: parentServiceTask;
    parentServiceTask = {
      serviceTasksDetails: undefined,
      serviceTasksDetailsLatestReleases: childServiceTask.parentServiceTaskLatestRelease,
      loadingServiceTaskDetails: false
    };
    this.relatedServiceTaskPanel.parentServiceTask.push(parentServiceTask);
    this.loadParentSTDetails(parentServiceTask);
  }

  loadParentSTDetails(serviceTask: parentServiceTask) {
    var serviceTaskID = serviceTask.serviceTasksDetailsLatestReleases.serviceTaskId.toString();
    QLogger.LogInfo(this.logSrc, "Get Parent Service Task "+ serviceTaskID +" Details");

    if(this.softwareCatalogClient === null || serviceTaskID === "0"){
      return;
    }
    
    serviceTask.loadingServiceTaskDetails = true;
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetails(serviceTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Parent Service Task "+serviceTaskID+" Details - Response : " +JSON.stringify(data));
        } 
        /*if(data.getCode() === 401){
          this.router.navigate(['/main/accessdenied']);
        }*/
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Parent Service Task "+serviceTaskID+" Details - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null) {
            serviceTask.serviceTasksDetails = obj as ServiceTaskInfo;
            serviceTask.serviceTasksDetails.updatedCaption=serviceTask.serviceTasksDetails.caption;
            serviceTask.serviceTasksDetails.updatedPrimaryOwner = serviceTask.serviceTasksDetails.primaryOwner;
            serviceTask.serviceTasksDetails.updatedSecondaryOwner = serviceTask.serviceTasksDetails.secondaryOwner;
            //this.app.sharedData.setVisibility();
                       
            // Clean up requestSource and requestAppId data
            if (serviceTask.serviceTasksDetails.requestSource.indexOf('planner') > -1) {
              serviceTask.serviceTasksDetails.requestSource = "Planner";
            }
            else if (serviceTask.serviceTasksDetails.requestSource.indexOf('salesforce') > -1) {
              serviceTask.serviceTasksDetails.requestSource = "Salesforce";
            }
            else if (serviceTask.serviceTasksDetails.requestSource.indexOf('QPM') > -1) {
              serviceTask.serviceTasksDetails.requestSource = "QPM";
            }
            if(this.serviceTasksDetails.requestAppId ==null)
            {
              this.serviceTasksDetails.requestAppId = '';
            }
            this.serviceTasksDetails.updatedRequestedAppid = this.serviceTasksDetails.requestAppId;
            
            this.loadParentSTDetailsLatestReleases(serviceTask.serviceTasksDetails);
            this.loadHistoryCRs(serviceTask.serviceTasksDetails);
            this.loadReleaseCRs(serviceTask.serviceTasksDetails);
            if(serviceTask.serviceTasksDetails.createdUsingSI){
              this.loadParentBaseImages(serviceTask);
            }
            this.parentStPanel.nativeElement.scrollLeft = this.parentStPanel.nativeElement.scrollWidth;
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Parent Service Task "+serviceTaskID+" Details - Failed");
          QLogger.LogError(this.logSrc, "Get Parent Service Task "+serviceTaskID+" Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }

        serviceTask.loadingServiceTaskDetails = false;
    });
  }
  
  loadParentBaseImages(serviceTask: parentServiceTask){
    var serviceTaskID = serviceTask.serviceTasksDetailsLatestReleases.serviceTaskId.toString();
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get Parent Service Task " + serviceTaskID + " Base Images");
    }

    let response : Observable<QPMResponse>;
    response = this.webClient.getServiceTaskBaseImage( serviceTaskID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTaskID + " Base Images - Response : " +JSON.stringify(data));
        }   
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Service Task " + serviceTaskID + " Base Images - Success"); 
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            serviceTask.serviceTasksDetails.serviceTaskImageBuilds = obj.imageBuilds as SoftwareImage[];
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Service Task " + serviceTaskID + " Base Images - Failed");
          QLogger.LogError(this.logSrc, "Get Service Task " + serviceTaskID + " Base Images - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  loadParentSTDetailsLatestReleases(serviceTask: ServiceTaskInfo) {
    var baseBuildID = serviceTask.baseBuild;
    QLogger.LogInfo(this.logSrc, "Get Parent Service Task "+baseBuildID+" Latest Release Details");

    if(this.softwareCatalogClient === null){
      return;
    }
    
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getServiceTaskDetailsBuildReleases(baseBuildID);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Parent Service Task "+baseBuildID+" Latest Release Details - Response : " +JSON.stringify(data));
        } 
        /*if(data.getCode() === 401){
          this.router.navigate(['/main/accessdenied']);
        }*/
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Parent Service Task "+baseBuildID+" Latest Release Details - Success");
          
          let obj = JSON.parse(data.getData()) as ServiceTaskInfoLatestReleases;
          if(obj !== undefined || obj !== null) {
            serviceTask.parentServiceTaskLatestRelease = obj;
            serviceTask.hasParent = true;
            serviceTask.parentServiceTaskID = serviceTask.parentServiceTaskLatestRelease.serviceTaskId;
          }
        }
        else {          
          serviceTask.hasParent = false;
          QLogger.LogError(this.logSrc, "Get Parent Service Task "+baseBuildID+" Latest Release Details - Failed");
          QLogger.LogError(this.logSrc, "Get Parent Service Task "+baseBuildID+" Latest Release Details - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        }
        //parentServiceTask.loadingLatestReleases = false;
    });
  }
  shipBaseSoftwareProduct(distros:SoftwareProductDistro[])
  {
    QLogger.LogInfo(this.logSrc, "Ship Software :" + this.serviceTasksDetails.baseBuild);
    let response: Observable<QPMResponse>;
    let shipResponse: Observable<QPMResponse>;
    let distroList:SoftwareProductDistroInfo[] = [];
    let req:DistributionRequest;
    let requestedPlatform : Number;
    if(this.app.sharedData.appInfo.isElectronMode)
    requestedPlatform = 0;
    else
    requestedPlatform = 1;
    if (this.distributionClient === null) {
      return
    }
    distros.forEach(function (distro) 
    {
      let info  = new SoftwareProductDistroInfo();  
      info.distroName =  distro.softwareDistro;
      info.distroId   = Number(distro.softwareDistroId);
      info.uploadComplete = 0;
	    info.softwareDistroAlternateId = distro.softwareDistroAlternateId;
      distroList.push(info);
    });

    req = {
      softwareProductBuild : this.serviceTasksDetails.baseBuild,
      softwareDistroList : distroList
    };
    if (this.app.sharedData.appInfo.logRequest) {
      QLogger.LogInfo(this.logSrc, "Ship Software Status Request: " + JSON.stringify(req));
    }
    response = this.webClient.getDistroShipStausForImage(req);
    response.subscribe(
      (data: QPMResponse) => {
       
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Ship Software Status Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          let shipStatusResp:imageShipDownloadResponse;
          let distrosStatus: Map<string, SoftwareImageDistroInfo> = new Map<string, SoftwareImageDistroInfo>();
          shipStatusResp = JSON.parse(data.getData());
          if(shipStatusResp !== undefined && shipStatusResp !== null && !this.serviceTasksDetails.hasParent){
            let distroShipStatus: SoftwareImageDistroInfo[] = shipStatusResp.imageDistroList;
            distroShipStatus.forEach((distro) => {
              if(distro.uploadComplete==4 || distro.uploadComplete==3)
              distrosStatus.set(distro.distroName, distro);
            });
            distroList.forEach(function (distro) 
            {
              if(!distrosStatus.has(distro.distroName)){
                distroList.splice(distroList.indexOf(distro),1);
              }
            });
            req = {
              softwareProductBuild : this.serviceTasksDetails.baseBuild,
              softwareDistroList : distroList,
              requestedPlatform: requestedPlatform
            };
            shipResponse = this.distributionClient.shipSoftwareProduct(req);
            shipResponse.subscribe(
              (data: QPMResponse) => {
                if (this.app.sharedData.appInfo.logResponse) {
                  QLogger.LogInfo(this.logSrc, "Ship Software Response: " + JSON.stringify(data));
                }
                if (data.isSuccess()) {
                  QLogger.LogInfo(this.logSrc, "Ship Software Success:" + this.serviceTasksDetails.baseBuild);
                }
                else{
                  QLogger.LogError(this.logSrc, "Ship Software Failed:" + this.serviceTasksDetails.baseBuild);
                  QLogger.LogError(this.logSrc, "Ship Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
                }
              }
            );   
          }
        }
        else
        {
          QLogger.LogInfo(this.logSrc,"Cannot fetch Ship Status for "+this.serviceTasksDetails.baseBuild);
        }

      });
  }
  getAvailableBaseBuilds()
  {
    let response: Observable<QPMResponse>;
    this.availableDiffBuildOptions =[];
    response = this.softwareCatalogClient.getSoftwareProductBuilds(this.serviceTasksDetails.softwareProduct);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available Software Product " + this.serviceTasksDetails.softwareProduct + " Builds - Response : " +JSON.stringify(data));
        }
        this.softwareProductReleases = [];
        let availableDiffBuilds:string[] =[];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Software Product " + this.serviceTasksDetails.softwareProduct + " Builds - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.softwareProductReleases = obj.softwareProductReleases;
            response = this.softwareCatalogClient.getServiceTaskDistProducts(this.serviceTasksDetails.serviceTaskId.toString());
            response.subscribe(
              (data:QPMResponse) => {
                if(this.app.sharedData.appInfo.logResponse){
                  QLogger.LogInfo(this.logSrc, "Get Service Task "+this.serviceTasksDetails.serviceTaskId.toString()+" Products - Response : " +JSON.stringify(data));
                } 
                if(data.isSuccess()){
                  QLogger.LogInfo(this.logSrc, "Get Service Task "+this.serviceTasksDetails.serviceTaskId.toString()+" Products - Success");
                  let obj = JSON.parse(data.getData());
                  if(obj !== undefined || obj !== null){
                    let products = obj as ServiceTaskDistProduct[];
                    products.reverse();
                    products.forEach((product)=>{
                      if(product.errorMessage === "" && product.productNewBuildID !== null && product.completed==true){
                        availableDiffBuilds.push(product.productNewBuildID);
                      }
                    });
                  }
                }
                this.softwareProductReleases?.forEach(release=>{
                  if(release.softwareProductBuild.includes(this.serviceTasksDetails.baseBuild)){
                      if(!availableDiffBuilds.includes(release.softwareProductBuild)){
                        availableDiffBuilds.push(release.softwareProductBuild);
                      }
                      
                  }
               });
               this.availableDiffBuildOptions = this.utils.getSelectItemsFromStrings(availableDiffBuilds);
              });

          }
        }
      });
  }
  //#regin CR_Delta start---
  deltaCRsProcess:{
    deltaCRsdisplayForm: boolean,
    loadResultsInProgress: boolean,
    errorMessage: string,
    isImageBuild:boolean,
    newBuild:string,
    oldBuild:string
  }
  resetCRdeltaProcess(){
    this.deltaCRsProcess = {
      deltaCRsdisplayForm: false,
      loadResultsInProgress: false,
      errorMessage: '',
      isImageBuild:false,
      newBuild:undefined,
      oldBuild:undefined
    }
  }
  deltaCRsList:deltaCRInfo[]=[];
  clearNewBuildInput(){
    this.deltaCRsProcess.newBuild=undefined;
    this.deltaCRsList=[];
  }
  clearOldBuildInput(){
    this.deltaCRsProcess.oldBuild=undefined;
    this.deltaCRsList=[];
  }
  showDeltaCRsDialog(){
    this.resetCRdeltaProcess();
    this.deltaCRsProcess.deltaCRsdisplayForm = true;
    this.deltaCRsList=[];
  }
  onDeltaCRsSearch(event){
    this.deltaCRsProcess.loadResultsInProgress=true;
    this.deltaCRsProcess.errorMessage = '';
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Request for CR Delta : NewBuild Item :" + this.deltaCRsProcess.newBuild + " "  +"OldBuild Item: "+this.deltaCRsProcess.oldBuild+ + " " +"isImageBuild:"+this.deltaCRsProcess.isImageBuild);
    }
    let response: Observable<QPMResponse>;
    response = this.webClient.getDeltaCRs(this.deltaCRsProcess.newBuild.trim(),this.deltaCRsProcess.oldBuild.trim(),this.deltaCRsProcess.isImageBuild);
    response.subscribe((data:QPMResponse)=>{
      if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get CR Delta - Response : " +JSON.stringify(data));
      }
      if(data.isSuccess()){
        QLogger.LogInfo(this.logSrc, "Get CR Delta between  NewBuild Item :" + this.deltaCRsProcess.newBuild + " "  +"OldBuild Item: "+this.deltaCRsProcess.oldBuild+ + " " +"and isImageBuild:"+this.deltaCRsProcess.isImageBuild+" - Success");
        let obj = JSON.parse(data.getData());
        if (obj !== undefined || obj !== null) {
          this.deltaCRsList=[];
          this.deltaCRsList=obj.map((data) => ({ crId: data}));
          this.deltaCRsProcess.loadResultsInProgress=false;
          this.deltaCRsProcess.errorMessage = '';
        }
      }
      else {
        QLogger.LogError(this.logSrc, "Getting CR Delta between New software build :" + this.deltaCRsProcess.newBuild + " Old software build : " + this.deltaCRsProcess.oldBuild + " " + " - Failed");
        QLogger.LogError(this.logSrc, "Getting CR Delta between New software build :" + this.deltaCRsProcess.newBuild + " Old software build : " + this.deltaCRsProcess.oldBuild + " " +" - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        this.deltaCRsProcess.errorMessage = "Failed to get CR Delta. Error : "+ data.getError();
        this.deltaCRsList=[];
        this.deltaCRsProcess.loadResultsInProgress=false;
      }
    })
  }
  //#regin CR_Delta End---
  //#endregion

  //#region LatestSIBuild
  
  addCustomBaseImageProcess:{
    displayForm: boolean;
    callback: any;
    appPtr: any;
    imageData: any;
    originalSoftwareImageBuild: string;
    baseSoftwareImage: string;
    baseSoftwareImageBuild: string;
    softwareImageBuild: SoftwareImageLatest[];
    softwareImageBuildSelectItem: SelectItem[];
    softwareImageBuildSelected: SoftwareImageLatest;
    softwareImageType: [{label: "Default", value: "default"}, {label: "CRM Builds", value: "CRM"}, {label: "Service Builds", value: "QPMService"}],
    softwareImageTypeSelected: string;
    isUpdate: boolean,
    addImageInProgress: boolean;
    loadingBuild: boolean;
    error: string;
    isPassThroughBuildSystem:boolean;
    isUpdateForPassThrough:boolean;
    gitBranch: string;
    gitUrl: string;
  }

  
  resetAddCustomBaseImageProcess(){
    this.addCustomBaseImageProcess = {
      displayForm: false,
      callback: undefined,
      appPtr: undefined,
      imageData: undefined,
      originalSoftwareImageBuild: "",
      baseSoftwareImage: "",
      baseSoftwareImageBuild: "",
      softwareImageBuild: [],
      softwareImageBuildSelectItem: [],
      softwareImageBuildSelected: undefined,
      softwareImageType: [{label: "Default", value: "default"}, {label: "CRM Builds", value: "CRM"}, {label: "Service Builds", value: "QPMService"}],
      softwareImageTypeSelected: "default",
      isUpdate: false,
      addImageInProgress: false,
      loadingBuild: false,
      error: "",
      isPassThroughBuildSystem:false,
      isUpdateForPassThrough:false,
      gitBranch:'',
      gitUrl:''
    }
  }
  //on open dialogbox
  addCustomeBaseImage(baseImage: string, baseImageBuild: string, originalImageBuild: string, data: any, callback: any, appPtr: any, isUpdate: boolean,isPassThroughBuildSystem:boolean,isUpdateForPassThrough:boolean){
    this.resetAddCustomBaseImageProcess();
    this.addCustomBaseImageProcess.originalSoftwareImageBuild = originalImageBuild;
    this.addCustomBaseImageProcess.baseSoftwareImage = baseImage;
    this.addCustomBaseImageProcess.baseSoftwareImageBuild = baseImageBuild;
    this.addCustomBaseImageProcess.imageData = data;
    this.addCustomBaseImageProcess.callback = callback;
    this.addCustomBaseImageProcess.appPtr = appPtr;
    this.addCustomBaseImageProcess.isUpdate = isUpdate;
    this.addCustomBaseImageProcess.displayForm = true;
    this.addCustomBaseImageProcess.isPassThroughBuildSystem=isPassThroughBuildSystem;
    this.addCustomBaseImageProcess.isUpdateForPassThrough=isUpdateForPassThrough;
    this.addCustomBaseImageProcess.gitBranch=data?.gitBranch;
    //Set Default GIT URL for CPUCP/PassThroughBuildSystem <Add Image>
    if(isPassThroughBuildSystem ===true){
      if(isUpdateForPassThrough===true){
        this.addCustomBaseImageProcess.gitUrl=data?.gitUrl;
      }else{
        this.addCustomBaseImageProcess.gitUrl="git@github.qualcomm.com:cpussfirmware/cpucp.git";
      }
    }
  
  }

  addCustomeBaseImageCallback(){
    if(this.addCustomBaseImageProcess.callback !== undefined){
      this.addCustomBaseImageProcess.callback(this.addCustomBaseImageProcess.appPtr);
    }
  }

  getLatestSIBuilds(){
    let image = this.addCustomBaseImageProcess.baseSoftwareImage;
    let imageBuild = this.addCustomBaseImageProcess.baseSoftwareImageBuild;
    let imageBuildType = this.addCustomBaseImageProcess.softwareImageTypeSelected;
    QLogger.LogInfo(this.logSrc, "Get Latest Software Image Build : " + imageBuild);
    let response : Observable<QPMResponse>;
    this.addCustomBaseImageProcess.softwareImageBuild = [];
    this.addCustomBaseImageProcess.softwareImageBuildSelectItem = [];
    this.addCustomBaseImageProcess.softwareImageBuildSelected = undefined;
    if(this.addCustomBaseImageProcess.softwareImageTypeSelected === "default"){
      this.addCustomBaseImageProcess.softwareImageBuildSelected = undefined;
      return;
    }
    this.addCustomBaseImageProcess.loadingBuild = true;
    // Get service task details
    response = this.softwareCatalogClient.getLatestSoftwareImageBuilds(image, imageBuild, imageBuildType);
    response.subscribe(
      (data:QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Response : " +JSON.stringify(data));
        } 
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Success");
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            let softwareImageBuilds = obj.imageBuilds as SoftwareImageLatest[];
            this.addCustomBaseImageProcess.softwareImageBuild = softwareImageBuilds;
            if(softwareImageBuilds !== undefined && softwareImageBuilds !== null && softwareImageBuilds?.length > 0){
              this.addCustomBaseImageProcess.softwareImageBuildSelectItem = this.utils.getSelectItems(softwareImageBuilds, "imageBuild");
              this.addCustomBaseImageProcess.softwareImageBuildSelected = undefined;
            }
            else{
              this.addCustomBaseImageProcess.error = "No latest image build found.";
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Failed");
          QLogger.LogError(this.logSrc, "Get Latest Software Image Build : " + imageBuild + " Type: " + imageBuildType + " - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          this.addCustomBaseImageProcess.error = "No latest image build found.";
        }
        this.addCustomBaseImageProcess.loadingBuild = false;
    });
  }

  onClickAutobuild(previousAutobuild: boolean){

    let request: UpdateServiceTaskRequest;
    let newAutobuild = true;
    if(previousAutobuild) {
      newAutobuild = false;
    }
    request = {
        abandoned: false,
        baseBuild: this.serviceTasksDetails.baseBuild,
        serviceTaskId: this.serviceTasksDetails.serviceTaskId,
        requestAppId: this.serviceTasksDetails.requestAppId,
        requestSource: this.serviceTasksDetails.requestSource,
        autobuild: newAutobuild
    };

    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.logRequest){
        QLogger.LogInfo(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " Autobuild request : " + JSON.stringify(request));
    }
    response = this.webClient.updateServiceTask(this.serviceTasksDetails.serviceTaskId.toString(), request);
    response.subscribe(
    (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
            QLogger.LogInfo(this.logSrc, "Update Service Task Autobuild Response - Response : " +JSON.stringify(data));
        }
        
        if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc, "Update Service Task Autobuild Response - Success");
            let obj = JSON.parse(data.getData());
            if(obj !== undefined || obj !== null){
                this.serviceTasksDetails.autobuild = obj.autobuild as boolean;
            }
        }
        else {
            QLogger.LogError(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " autobuild - Failed");
            QLogger.LogError(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " autobuild - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
            this.addDeleteCustomerProcess.errorMessage = "Failed to update autobuild. Error : " + data.getError();
        }
    });
}

  //#region UpdateSoftwareFormatAddons
   updateSWAddonsProcess:{
    imageAddonsInprogress: boolean,
    errorMessage: string
  }

  resetupdateSWAddonsProcess(){
    this.updateSWAddonsProcess = {
      imageAddonsInprogress: false,
      errorMessage: ''
    }
  }
 onClickSoftwareFormatAddons(previousSoftwareFormatAddons: boolean){
  let request: UpdateServiceTaskRequest;
  this.resetupdateSWAddonsProcess();
  let newSoftwareFormatAddons = true;
  if(previousSoftwareFormatAddons) {
    newSoftwareFormatAddons = false;
  }
  request = {
    abandoned: false,
    baseBuild: this.serviceTasksDetails.baseBuild,
    serviceTaskId: this.serviceTasksDetails.serviceTaskId,
    requestAppId: this.serviceTasksDetails.requestAppId,
    requestSource: this.serviceTasksDetails.requestSource,
    isImageFormatAddOn: newSoftwareFormatAddons
  };

  let response: Observable<QPMResponse>;
  if(this.app.sharedData.appInfo.logRequest){
    QLogger.LogInfo(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " SoftwareFormatAddons request : " + JSON.stringify(request));
  }
  this.updateSWAddonsProcess.imageAddonsInprogress=true;
  this.updateSWAddonsProcess.errorMessage="";
  response = this.webClient.updateServiceTask(this.serviceTasksDetails.serviceTaskId.toString(), request);
  response.subscribe(
  (data: QPMResponse) => {
    if(this.app.sharedData.appInfo.logResponse){
       QLogger.LogInfo(this.logSrc, "Update Service Task SoftwareFormatAddons Response - Response : " +JSON.stringify(data));
    }    
    if (data.isSuccess()) {
        QLogger.LogInfo(this.logSrc, "Update Service Task SoftwareFormatAddons Response - Success");
        let obj = JSON.parse(data.getData());
        if(obj !== undefined || obj !== null){
            this.serviceTasksDetails.isImageFormatAddOn = obj.isImageFormatAddOn as boolean;
        }
    }
    else {
        QLogger.LogError(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " SoftwareFormatAddons - Failed");
        QLogger.LogError(this.logSrc, "Update Service Task " + this.serviceTasksDetails.serviceTaskId + " SoftwareFormatAddons - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
        this.updateSWAddonsProcess.errorMessage = "Failed to update SoftwareFormatAddons. Error : " + data.getError();
    }
    this.updateSWAddonsProcess.imageAddonsInprogress=false;
  });

}
  //#endregion
}