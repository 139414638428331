import { QPMResponse } from 'src/app/models/response';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';
import { InstalledPackageDetails, InstalledVersion } from 'src/app/models/catalog-client';
import { QLogger } from 'src/app/common/logger';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Component } from '@fullcalendar/core';

export class version {
    id:number;
    value:string;
  }
export class component {
    public checked: boolean
    public componentName: string;
    public versions: version[];
    public selectedVersion:version;
    public minVersion:string;
    public maxVersion:string;
  }
export class ConfigData{
    path:string;
    addons:component[];
    deps:component[];

}
export class toolsUtils{
    private catalogClient: CatalogClientService;
    private webClient : WebClientService;
    private limeClient : ClientService;
    private telematicsClient: TelematicsClientService;
    private logSrc:string;
    constructor(service:DataServiceProducer){
        this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
        this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
        this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
        this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
        this.logSrc = "ToolsUtils";
    }
    getInstalledComponentDetailsByComponentId(componentId:string):InstalledPackageDetails{
        let response : Observable<QPMResponse>;
        let installationDetails: InstalledPackageDetails = undefined;
        response = this.catalogClient.getInstalledComponent(componentId);
        response.subscribe(
            (data:QPMResponse) => {
                if(data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get install details Response - Success");
                    let obj = JSON.parse(data.getData());
                    if(obj !== undefined || obj !== null) {
                        installationDetails = obj;
                        // Handle case of multiple installations
                        if(obj.InstalledVersions || obj.Installations) {//Windows uses 'InstalledVersions', others use 'Installations'
                            let temp : InstalledVersion[] = obj.Installations?obj.Installations:JSON.parse(obj.InstalledVersions);
                            installationDetails.InstalledVersions = temp;
                            for(let index = 0; index < temp.length; index++){
                              if(temp[index].Version)// Windows has 'version' while others have 'Version'
                                installationDetails.InstalledVersions[index].version =temp[index].Version
                            }     
                        }
                    }
                }
            }
        );
        return installationDetails;
    }
    getInstalledComponentDetailsByComponentName(componentName:string):InstalledPackageDetails{
        let installationDetails: InstalledPackageDetails = undefined;
        let compId:string = this.getcomponentIdFromName(componentName);
        if(compId != undefined) return this.getInstalledComponentDetailsByComponentId(compId);
        return undefined;
    }
    getcomponentIdFromName(componentName: string): string {
        let qpmResp: QPMResponse = undefined;
        let response = this.catalogClient.getComponentIdByName(componentName);
        response.subscribe(
          (data: QPMResponse) => {
            qpmResp = data;
            if (!data.isSuccess()) {
              QLogger.LogError(this.logSrc, "Could not get component id for component : " + componentName + "\t. Received:" + JSON.stringify(data));
            }
          });
        if(qpmResp != undefined && qpmResp.isSuccess()) return qpmResp.getData();
        return undefined;
      }
}