<div class="footer">
    <div class="card clearfix">
        <div style="font-size: 11px;">
            <span style="margin-right: 5px;">
                <a *ngIf="app.sharedData.appInfo.isElectronMode" (click)="app.goToUrl('http://www.qualcomm.com/site/terms-of-use')" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Terms of Use 
                </a>
                <a *ngIf="!app.sharedData.appInfo.isElectronMode" href="http://www.qualcomm.com/site/terms-of-use" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Terms of Use
                </a>
            </span>|
            <span style="margin-right: 5px;">
                <a *ngIf="app.sharedData.appInfo.isElectronMode" (click)="app.goToUrl('http://www.qualcomm.com/privacy-policy')" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Privacy
                </a>
                <a *ngIf="!app.sharedData.appInfo.isElectronMode" href="http://www.qualcomm.com/privacy-policy" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Privacy
                </a>
            </span>|
            <span style="margin-right: 5px;">
                <a *ngIf="app.sharedData.appInfo.isElectronMode" (click)="app.goToUrl('http://www.qualcomm.com/cookie-policy')" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Cookie Policy
                </a>
                <a *ngIf="!app.sharedData.appInfo.isElectronMode" href="http://www.qualcomm.com/cookie-policy" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Cookie Policy
                </a>
            </span>|
            <span style="margin-right: 5px;">
                <a *ngIf="app.sharedData.appInfo.isElectronMode" (click)="app.goToUrl('https://www.qualcomm.com/contact/support')" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Contact Us
                </a>
                <a *ngIf="!app.sharedData.appInfo.isElectronMode" href="https://www.qualcomm.com/contact/support" target="_blank" style="cursor: pointer; text-decoration: underline; color: blue; display: inline-block;">
                    Contact Us
                </a>
            </span>
        </div>
        <div style="color: rgb(187, 192, 200);font-size: 11px;">
            <p style="line-height: 15px; margin: 10px 0px;">
                <span class="material-icons ui-icon-copyright" style="font-size: 1em;"></span>
                2020 - {{year}} Qualcomm Technologies, Inc. and/or its affiliated companies.
            </p>
            <p style="line-height: 15px; margin: 0px 0px 10px;">
                References to "Qualcomm" may mean Qualcomm Incorporated, or subsidiaries or business units within the Qualcomm corporate structure, as applicable.
            </p>
            <p style="line-height: 15px; margin: 0px 0px 10px;">
                Qualcomm Incorporated includes Qualcomm's licensing business, QTL, and the vast majority of its patent portfolio. Qualcomm Technologies, Inc., a wholly-owned subsidiary of Qualcomm Incorporated, operates, along with its subsidiaries, substantially all of Qualcomm's engineering, research and development functions, and substantially all of its products and services businesses. Qualcomm products referenced on this page are products of Qualcomm Technologies, Inc. and/or its subsidiaries.
            </p>
            <p style="line-height: 15px; margin: 0px 0px 10px;">
                Materials that are as of a specific date, including but not limited to press releases, presentations, blog posts and webcasts, may have been superseded by subsequent events or disclosures.
            </p>
            <p style="line-height: 15px; margin: 0px 0px 10px;">
                Nothing in these materials is an offer to sell any of the components or devices referenced herein.
            </p>
        </div>
    </div>
</div>