import { Injectable } from '@angular/core';
import isElectron from 'is-electron';
import { Observable } from 'rxjs';
import { LimeResponse } from 'src/app/models/response';
import { CatalogClientService } from '../Contract/CatalogClientService';
import { ServiceUtils } from '../ServiceUtils';

@Injectable()
export class LimeCatalogClientService implements CatalogClientService{
  private catalogClient;
  constructor(private serviceUtils : ServiceUtils) {
    if(isElectron()){
      var lime = window.require('bindings')('addon.node');
      this.catalogClient = new lime.CatalogClientWrapper();
    }
  }

  public getVersion() : Observable<LimeResponse> {
    var response = this.catalogClient.getVersion();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getProducts() : Observable<LimeResponse>{
    var response = this.catalogClient.getProducts();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getProductSuites() : Observable<LimeResponse>{
    var response = this.catalogClient.getProductSuites();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getProduct(productID : string) : Observable<LimeResponse>{
    var response = this.catalogClient.getProduct(productID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getPackage(packageID : string) : Observable<LimeResponse>{
    var response = this.catalogClient.getPackage(packageID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getComponent(componentID : string) : Observable<LimeResponse>{
    var response = this.catalogClient.getComponent(componentID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getLatestVersion(productID : string) : Observable<LimeResponse>{
    var response = this.catalogClient.getLatestVersion(productID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getInstalledComponent(componentID : string) : Observable<LimeResponse>{
    var response = this.catalogClient.getInstalledComponent(componentID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getInstalledTools() : Observable<LimeResponse>{
    var response = this.catalogClient.getInstalledTools();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getInstalledComponentVersion(componentID : string) : Observable<LimeResponse>{
    var response = this.catalogClient.getInstalledComponentVersion(componentID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getComponentIdByName(componentName:string): Observable<LimeResponse>{
    var response = this.catalogClient.getComponentIdByName(componentName);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  public getPackageListForComponentV3(componentID:string): Observable<LimeResponse>{
    var response = this.catalogClient.getPackageListForComponentV3(componentID);
    return this.serviceUtils.getObservableLimeReponse(response);
  }
}
