<div id="CartPage" class="ui-g ui-fluid" style="min-height: calc(80vh);">

    <div class="ui-g-12 card card-w-title" style="height:100%;">

      <!-- Header : -->
      <div class="ui-g-12">
            <div class="ui-g-8" style="display:flex; justify-content: flex-start; height: 50px;">
                <a (click)="goBackToPreviousVisitedPageFromCart()" style="align-self: center;"><img src="assets/images/back_button.png"/></a>
                <div style="grid-column: 1;grid-row:1;">
                    <h2 id="CartPageid" style="align-self: center;padding-left: 10px;">Cart ({{cartDetailsProcess.itemsAddedToCart?.length}}) </h2>
                </div>
            </div>
       </div>
      <hr>

        <div class="ui-g-12" style="margin-top: 10px;">

            <div class="ui-g-12">
            
                <div class="ui-g-12" *ngIf="cartDetailsProcess.cartProcessingInprogress">
                    <h3 style="display: inline-block;">Loading Cart Details. Please wait...</h3>
                    <p-progressSpinner [style]="{width: '20px', height: '20px', margin: '0 5px 0 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>  
                </div>
                <!--Cart Details-->
                <div class="ui-g-12" *ngIf="!cartDetailsProcess.cartProcessingInprogress">
                    <div class="ui-g">
                        <div class="ui-g-12" *ngFor="let item of cartDetailsProcess.itemsAddedToCart">
                            <div class="ui-g-9">
                              <span style="color: gray;font-weight: bold; font-size: small; float: left;">SERIAL NUMBER RENEWAL</span> 
                              <br><span><a (click)="goToSerialNumberDetailsPage(item?.selectedLicense?.serialNumber,item?.selectedLicense)" style="cursor: pointer; color: blue; display: inline-block;">{{item?.selectedLicense?.serialNumber}}</a></span>
                              
                            </div>
                            <div class="ui-g-3">
                              <span  style="float: right;"> ${{item?.responseLineDetail?.itemPrice}}</span>
                            </div>
  
                          <div class="ui-g-12"  *ngFor="let licenseGroup of item?.selectedLicense?.licenseGroups">
                            <div class="ui-g-12">
                              <span style="color: grey;">License Group :</span>  <span> <a (click)="goToLicenseGroupDetailsPage(licenseGroup?.id,item.selectedLicense)" style="cursor: pointer; color: blue; display: inline-block;">{{licenseGroup?.id}}</a></span>
                            </div>
                            <div class="ui-g-12">
                              <span style="color: grey;">Tools :</span>  <span> {{licenseGroup?.displayProductNames}}</span>
                            </div>
                          </div>
                       
                          <div class="ui-g-9">
                          </div>
                          <div class="ui-g-3">
                            <span style="font-size: small;float: right; color: blue;cursor: pointer" (click)="removeItemFromCart(item)">Remove</span>
                          </div>
                          <div class="ui-g-12">
                            <hr>
                          </div>
  
                        </div>
                     </div>

                    <p-footer>
                        <div class="ui-dialog-buttonpane ui-helper-clearfix">
                          <div class="ui-g">
                            <div class="ui-g-12 message message-error" *ngIf="cartDetailsProcess.totalPrice>30000">
                              <span style="float: left;" id="errorMessageForCardLimit">Credit card purchases limited to $30,000.00</span>
                            </div>
                          </div>
                          <div class="ui-g-12">
                           <span style="float: right;" id="total" *ngIf="cartDetailsProcess.itemsAddedToCart?.length!=0"> Total (before tax) : ${{cartDetailsProcess.totalPrice}}</span>
                          </div>
                          <button pButton type="button" style="width: 140px;float: right;margin: 15px;" [disabled]="cartDetailsProcess.itemsAddedToCart==undefined || cartDetailsProcess.itemsAddedToCart?.length==0 || cartDetailsProcess.totalPrice>30000" (click)="gotoCheckoutPage()" label="Checkout >"></button>
                        </div>
                    </p-footer>
                </div>
                <!--Carts Details End-->
            </div>




        </div>
   
    </div>

</div>
