import { Injectable } from '@angular/core';
import isElectron from 'is-electron';
import { Observable } from 'rxjs';
import { LimeResponse, RestResponse, QPMResponse, RestErrorResponse } from '../../models/response';
import { SoftwareCatalogClientService } from '../Contract/SoftwareCatalogClient';
import { RestSoftwareCatalogClientService } from '../RestService/rest-software-catalog-client.service';
import { ServiceUtils } from '../ServiceUtils';
import { SharedData } from 'src/app/common/shared-data';
import { ComponentBranchRequest } from 'src/app/models/software-catalog-client';

@Injectable()
export class LimeSoftwareCatalogClientService implements SoftwareCatalogClientService {
  private softwareCatalogClient;
  private limeWebClient;
  constructor(private serviceUtils : ServiceUtils, private sharedData : SharedData, private softwareCatalogRestApi: RestSoftwareCatalogClientService) {
    if(isElectron()){
      var lime = window.require('bindings')('addon.node');
      this.softwareCatalogClient = new lime.SoftwareCatalogClientWrapper();
      this.limeWebClient = new lime.LimeWebClientWrapper();
    }
  }
  
  getVersion(): Observable<LimeResponse> {
    var response = this.softwareCatalogClient.getVersion();
    return this.serviceUtils.getObservableLimeReponse(response);
  }
  
  getCustomersAll(): Observable<RestResponse> {
    var observable = this.softwareCatalogRestApi.getCustomersAll();
    return observable;
  }

  getSoftwareProducts(): Observable<QPMResponse> {
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      response = this.softwareCatalogRestApi.getSoftwareProducts();
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            this.softwareCatalogClient.softwareCatalogResponse(data.getData());
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.softwareCatalogClient.getSoftwareProducts();
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }

  }
  getSoftwareProductsByParty(partyID: number): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductsByParty(partyID);
  }  
  getSoftwareProductFamily(): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductFamily();;
  }
  getSoftwareProductFamilyByParty(partyID: number): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductFamilyByParty(partyID);;
  }
  getSoftwareProductDistros(softwareProductID:string): Observable<QPMResponse> {
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      response = this.softwareCatalogRestApi.getSoftwareProductDistros(softwareProductID);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            this.softwareCatalogClient.distroDetailsResponse(softwareProductID,data.getData());
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.softwareCatalogClient.getSoftwareProductDistros(softwareProductID);
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }
  
  getSoftwareProductCustomers(softwareProductBuildID:string,spfTag:string) : Observable<RestResponse>{
    return this.softwareCatalogRestApi.getSoftwareProductCustomers(softwareProductBuildID,spfTag);
  }

  getCustomerProjects(): Observable<QPMResponse> {

    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      response = this.softwareCatalogRestApi.getCustomerProjects();
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            this.softwareCatalogClient.customerCatalogResponse(data.getData());
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.softwareCatalogClient.getCustomerProjects();
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }  
  getCustomerProjectsByParty(partyNumber: number): Observable<RestResponse> {
    let response: Observable<RestResponse>;
    response = this.softwareCatalogRestApi.getCustomerProjectsByParty(partyNumber);
    response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            this.softwareCatalogClient.customerCatalogResponse(data.getData());
          }
    });
    return response;
  }

  getSoftwareProductBuilds(softwareProductID:string): Observable<QPMResponse> {
    let response: Observable<RestResponse>;
    if(this.serviceUtils.getNetworkConnectionStatus()){
      response = this.softwareCatalogRestApi.getSoftwareProductBuilds(softwareProductID);
      response.subscribe(
        (data: RestResponse) => {
          if (data.isSuccess()) {
            this.softwareCatalogClient.softwareProductResponse(data.getData(),softwareProductID);
          }
        });
      return response;
    }
    else
    {
      var limeresponse = this.softwareCatalogClient.getSoftwareProductBuilds(softwareProductID);
      return this.serviceUtils.getObservableLimeReponse(limeresponse);
    }
  }
  getSoftwareProductBuildsByParty(softwareProductID: string, partyID: number): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductBuildsByParty(softwareProductID, partyID);
  }  
  getSoftwareProductBuildInfo(softwareProductBuildID: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductBuildInfo(softwareProductBuildID);
  }  
  getSoftwareProductLatestComposition(softwareProductID: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductLatestComposition(softwareProductID);
  }
  getSoftwareImagesBuildsLatest(softwareImageBuildID: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareImagesBuildsLatest(softwareImageBuildID);
  }
  getServiceTasks(query: string = undefined): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTasks(query);
  }
  getServiceTaskDetails(serviceTaskID:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskDetails(serviceTaskID);
  }
  getServiceTaskDetailsLatestReleases(serviceTaskID: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskDetailsLatestReleases(serviceTaskID);
  }
  getServiceTaskDetailsHistoryCRs(serviceTaskID: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskDetailsHistoryCRs(serviceTaskID);
  }
  getServiceTaskDetailsReleaseCRs(serviceTaskID: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskDetailsReleaseCRs(serviceTaskID);
  }
  getServiceTaskDetailsBuildReleases(softwareProductBuildID:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskDetailsBuildReleases(softwareProductBuildID);
  }
  deleteSoftwareProductCustomers(serviceTaskID:string, partyId:string) : Observable<RestResponse>{
    return this.softwareCatalogRestApi.deleteSoftwareProductCustomers(serviceTaskID, partyId);
  }
  getServiceTaskBuilds(serviceTaskID:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskBuilds(serviceTaskID);
  }
  getServiceTaskBuildInternalClients(softwareImageBuild:string): Observable<RestResponse>{
    return this.softwareCatalogRestApi.getServiceTaskBuildInternalClients(softwareImageBuild);
  }
  getServiceTaskBuildDetails(serviceTaskID:string, cirrusID:string, noOfBuilds:number): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskBuildDetails(serviceTaskID, cirrusID, noOfBuilds);
  }
  getServiceTaskBuildDetailsWithComponents(serviceTaskID: string, cirrusID: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskBuildDetailsWithComponents(serviceTaskID, cirrusID);
  }
  addBuildComponentToBranch(serviceTaskID:string,request:ComponentBranchRequest): Observable<RestResponse>{
    return this.softwareCatalogRestApi.addBuildComponentToBranch(serviceTaskID, request);
  }
  getServiceTaskBuildCRs(serviceTaskID:string, buildRequestID:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskBuildCRs(serviceTaskID, buildRequestID);
  }
  getServiceTaskBuildCRStatus(cr:string, serviceTaskID:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskBuildCRStatus(cr, serviceTaskID);
  }
  getServiceTaskDistProducts(serviceTaskID:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskDistProducts(serviceTaskID);
  }
  getServiceTaskDistProductDetails(softwareProductBuild:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskDistProductDetails(softwareProductBuild);
  }
  getSoftwareProductBuildComposition(softwareProductBuildID:string, HLOSonly:boolean) : Observable<RestResponse>{
    return this.softwareCatalogRestApi.getSoftwareProductBuildComposition(softwareProductBuildID, HLOSonly);
  }
  getSoftwareProductBuildConsolidatedCR(softwareProductBuildID:string) : Observable<RestResponse>{
    return this.softwareCatalogRestApi.getSoftwareProductBuildConsolidatedCR(softwareProductBuildID);
  }
  getSoftwareProductBuildDistros(softwareProductBuildID:string) : Observable<RestResponse>{
     return this.softwareCatalogRestApi.getSoftwareProductBuildDistros(softwareProductBuildID);
  }
  getSoftwareProductBuildImpactedFiles(softwareProductBuildID:string,isImageBuild:boolean) : Observable<RestResponse>{
    return this.softwareCatalogRestApi.getSoftwareProductBuildImpactedFiles(softwareProductBuildID,isImageBuild);
  }
  getSoftwareProductBuildBaitImpactedFiles(softwareProductBuildID:string) : Observable<RestResponse>{
    return this.softwareCatalogRestApi.getSoftwareProductBuildBaitImpactedFiles(softwareProductBuildID);
  }
  getHlosTechnologies(): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getHlosTechnologies();
  }
  getHlosTargets(softwareImage: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getHlosTargets(softwareImage);
  }
  getServiceTaskBaitBuilds(serviceTaskID:string, image:string): Observable<RestResponse> {    
    return this.softwareCatalogRestApi.getServiceTaskBaitBuilds(serviceTaskID, image);
  }
  getServiceTaskBaitBuildDetails(serviceTaskID:string, buildID:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getServiceTaskBaitBuildDetails(serviceTaskID, buildID);
  }
  getSoftwareProductByImageBuild(imageBuild:string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductByImageBuild(imageBuild);
  }
  getSoftwareProductByImageBuildAndPartyId(imageBuild:string, partyID: number): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getSoftwareProductByImageBuildAndPartyId(imageBuild, partyID);
  }  
  getLatestSoftwareImageBuilds(image:string, imageBuild:string, imageBuildType: string): Observable<RestResponse> {
    return this.softwareCatalogRestApi.getLatestSoftwareImageBuilds(image, imageBuild,  imageBuildType);
  }
  getserviceTaskPassThroughBuilds(serviceTaskID: string, image: string): Observable<QPMResponse> {
    return this.softwareCatalogRestApi.getserviceTaskPassThroughBuilds(serviceTaskID, image);
  }
  getserviceTaskPassThroughBuildDetails(imageBuildID: string): Observable<QPMResponse> {
    return this.softwareCatalogRestApi.getserviceTaskPassThroughBuildDetails(imageBuildID);
  }
  getBaitLocalfileUploadBuilds(serviceTaskID: string, image: string): Observable<QPMResponse> {
    return this.softwareCatalogRestApi.getBaitLocalfileUploadBuilds(serviceTaskID, image);
  }
  getBaitLocalFileUploadBuildDetails(serviceTaskID: string, buildID: string): Observable<QPMResponse> {
    return this.softwareCatalogRestApi.getBaitLocalFileUploadBuildDetails(serviceTaskID, buildID);
  }

}
