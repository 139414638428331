import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { FileInfo, InstalledPackageDetails, PackageDetails, ReleaseNotesDetails, ReleaseNotesInfo, InstalledVersion, QIKResult, QIKPackageProperites, PackageDependencyInfo, ToolDocumentList } from '../../../models/catalog-client';
import { ProductDetails, ProductV3, ProductSuiteV3, ProductCatalogV3,ReleaseV3, ReleasesV3, ProductSubSuiteV3} from '../../../models/lime-web-client';
import { ActivationKey, Entitlements, Feature, LicenseDetails } from '../../../models/lime-client';
import { DataServiceProducer, ApiType } from 'src/app/service/Factory/DataServiceProducer';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { SelectItem, MenuItem, Message } from 'primeng/primeng';
import { TreeNode } from 'primeng/api';
import { QPMResponse } from 'src/app/models/response';
import { ClientService } from 'src/app/service/Contract/ClientService';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { TelematicsClientService } from 'src/app/service/Contract/TelematicsClientService';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMainComponent } from 'src/app/app.main.component';
import { InstallComponent } from 'src/app/component/tools/install/install.component';
import { commType, OS, OSDistType, OSOption, OSsSupportingOtherArchs, selectableArchs, selectableOSs, TargetArch } from 'src/app/common/shared-data';
import { QLogger } from 'src/app/common/logger';
import { Utils } from 'src/app/common/utils';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import isElectron from 'is-electron';
import { OpsItemEventSummaries } from 'aws-sdk/clients/ssm';
import { Debugger } from 'electron';

enum InstallActions {
  Install = "Install",
  DownloadOnly = "Download",
  Refresh = "Refresh",
  Uninstall = "Uninstall",
  Extract = "Extract",
  DownloadZipOnly="DownloadZipOnly",
};

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {
  private logSrc: string = "ToolsDetails-Component";
  private subscriptionSrc: string = "ToolsDetails-Component";
  subscription: Subscription;
  private toolRefreshSubscription: Observable<boolean>;
  private toolRefreshLicenseSubscription: Observable<boolean>;

  private catalogClient: CatalogClientService;
  private webClient: WebClientService;
  private limeClient: ClientService;
  private telematicsClient: TelematicsClientService;
  private ipcRenderer;
  isMultipleInstall: boolean;
  errorMessage: string[];
  productErrorMessage: string;
  licenseErrorMessage: string;

  // Static UI elements
  selectedProduct: ProductV3;
  selectedProductDetails: ProductDetails;
  selectedProductInstallDetails: InstalledPackageDetails;
  selectedProductReleases: ReleasesV3;
  selectedProductLicensesRows: TreeNode[];
  packageVersions: SelectItem[];
  selectedVersion: ReleaseV3;
  osOptions: SelectItem[];
  selectedOSId: number;
  installActionOptions: MenuItem[];
  uiLicenseGroupID: string;
  userSelection: string;

  showLicenseDetails: boolean;
  showLicenseTable: boolean;
  selectedLicenseDetails: LicenseDetails;
  showAddLicense: boolean;

  showInstalledToolLicenses: boolean;
  loadingSelectedProductDetails: boolean;

  installSplitButtonItems: MenuItem[];

  // Show dialogs
  showInstallStatus: boolean;

  platformMismatch: boolean;

  telematicsInfo: {

    productID: string;
    productName: string;
    eventStatus: boolean;
    Duration: Number;
    productVer: string;
  }
  //private osOptions:OSOption[];
  menuItemsForInstalledTools: MenuItem[];
  refreshOption: any;
  openFolderOption: any;
  separator: any;
  uninstallOption: any;
  optionsForNormalInstallTypes: any;
  optionsForMultipleInstallTypes: any;

  @ViewChild('licenseTable') licenseTable;
  @ViewChild('menuItems') menu: MenuItem[];
  @ViewChild('installSplitButton') installSplitButton;
  @ViewChild('installButton') installButton;

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    public app: AppMainComponent, private utils: Utils,
    private service: DataServiceProducer, private http: HttpClient,
    public zone: NgZone, public installer: InstallComponent) {
    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.limeClient = service.getServiceInstance(ApiType.Client) as ClientService;
    this.telematicsClient = service.getServiceInstance(ApiType.TelematicsClient) as TelematicsClientService;
    this.refreshOption = {
      label: 'Refresh', command: () => { this.onRefreshButtonClickedSingle() }
    };
    this.openFolderOption = {
      label: 'Open folder', command: () => { this.installer.OpenFileInFileManager(this.selectedProductInstallDetails.InstallLocation) }
    };
    this.separator = {
      separator: true,
    };
    this.uninstallOption = {
      label: 'Uninstall', command: () => { this.onUninstallButtonClickedSingle() }
    };
    this.optionsForNormalInstallTypes = [this.refreshOption, this.separator, this.openFolderOption, this.separator, this.uninstallOption];
    this.optionsForMultipleInstallTypes = [this.uninstallOption];
    if (isElectron()) {
      this.ipcRenderer = window.require('electron').ipcRenderer;
    }
  }

  ngOnDestroy() {
    this.app.sharedData.unsubscribeCommunication(commType.RefreshTools, this.subscriptionSrc);
    this.app.sharedData.unsubscribeCommunication(commType.RefreshLicenses, this.subscriptionSrc);
  }

  ngOnInit(): void {

    // Static UI Elements
    this.app.setUserInfo();
    this.app.sharedData.service.common.master = false;
    this.app.sharedData.resetVisibility();
    this.app.sharedData.setVisibility();
    this.isMultipleInstall = false;
    // Was main page ever initialized or was this direct link to this page?
    if (this.app.sharedData.service.tools.selectedTool.productUUID === undefined) {
      this.activatedRoute.params.subscribe(params => {
        let toolName = params['id'];
        this.app.sharedData.service.tools.triggerDirectLink = toolName;
        if (this.router.url.includes("source")) {
          this.router.navigate(['/main/source/find']);
        }
        else {
          this.router.navigate(['/main/tools/find']);
        }

      });
      return;
    }
    this.platformMismatch = false;
    this.telematicsInfo = {
      productName: "",
      eventStatus: false,
      productID: "",
      Duration: 0,
      productVer: ""
    };

    QLogger.LogInfo(this.logSrc, "ToolsDetails Component Initialization");
    this.osOptions = [];

    this.showLicenseDetails = false;
    if (this.app.sharedData.appInfo.isElectronMode)
      this.showLicenseTable = true;
    else
      this.showLicenseTable = false;
    this.showInstalledToolLicenses = false;

    this.showAddLicense = false;

    this.packageVersions = [];

    // Recover licenses
    this.errorMessage = [];
    this.productErrorMessage = "";
    this.licenseErrorMessage = "";

    this.uiLicenseGroupID = "";

    this.selectedProduct = this.app.sharedData.service.tools.selectedTool;
    let osOpt: OSOption = this.utils.addOSOption(this.selectedProduct.selectedOS, "","");
    this.selectedOSId = osOpt.id;
    this.selectedProductDetails = new ProductDetails();

    if (!this.app.sharedData.appInfo.workOffline) {
      let triggerInstall = this.app.sharedData.service.tools.triggerDeepLinkInstall;
      this.getSelectedProductDetails(triggerInstall);
      this.app.sharedData.service.tools.triggerDeepLinkInstall = false;
    }
    else {
      this.selectedProductInstallDetails = this.app.sharedData.service.tools.selectedProductInstallDetails;
      this.getLicensesForSelectedProduct(this.selectedProductInstallDetails.ProductID);
      this.loadingSelectedProductDetails = false;
    }
    console.log("os label: " + osOpt.label + "os id: " + osOpt.id)
    this.osOptions = [{
      label: osOpt.label, value: osOpt.id, disabled: false
    }];


    //if multiple os checkbox selected then Add remaining into this.osOptions[which is dropdown in UI]
    this.selectedProduct.productOS.forEach(Os => {
      QLogger.LogInfo(this.logSrc, "Product Os: " + Os);
      if (Os !== this.utils.getOSoptionObjectById(this.selectedOSId).os && this.osOptions.find(os => os.label == Os) == undefined) {
        console.log("adding os option - Product Os: " + Os);
        let osOpt: OSOption = this.utils.addOSOption(Os, "");
        this.osOptions.push({
          label: osOpt.label, value: osOpt.id, disabled: false
        });
      }
    });

    this.selectedProduct.productOS.forEach(os=>{
      if(os==selectableOSs.LINUX){
        this.selectedProduct.osDistTypes?.forEach(osDistType=>{
          if(osDistType!==OSDistType.Debian){
            console.log("adding Linux os option - Product Os: " + 'Linux');
            let osOpt: OSOption = this.utils.addOSOption('Linux', "",osDistType);
            this.osOptions.push({
              label: osOpt.label, value: osOpt.id, disabled: false
            });
          }
        })
      }
    })

    if (this.utils.isPortal())// On Portal -> if one of the selected OS is Windows-> 
    //check if the product has arm64 release->
    // if it does add Windows on ARM64 in OS dropdown
    {
      console.log("isportal");
      OSsSupportingOtherArchs.forEach(osString => {
        if (this.osOptions.find(os => os.label.toLowerCase() === osString.toLowerCase())) {
          this.tryAddOptionsinOSDropDown(OS[osString], TargetArch.ARM64)
        }
      });
    }
    this.menuItemsForInstalledTools = [
      {
        label: 'Refresh', command: () => { this.onRefreshButtonClickedSingle() }
      },
      {
        separator: true,
      },
      {
        label: 'Open folder', command: () => {
          this.installer.OpenFileInFileManager(this.selectedProductInstallDetails.InstallLocation)
        }
      },
      {
        separator: true,
      },
      {
        label: 'Uninstall', command: () => { this.onUninstallButtonClickedSingle() }
      },
    ];
    this.setTopOptionsDropdown();
    this.setQPMDID();
    this.documentList = new ToolDocumentList();
  }

  ngAfterViewInit() {
    this.initSubscribes();
  }


  initSubscribes() {
    this.toolRefreshSubscription = this.app.sharedData.subscribeCommunication(commType.RefreshTools, this.subscriptionSrc);
    this.toolRefreshSubscription.subscribe((selectedProduct) => {
      if (selectedProduct === undefined) return;

      QLogger.LogInfo(this.logSrc, "Install Complete");
      this.getInstalledComponent(false);
    });

    this.toolRefreshLicenseSubscription = this.app.sharedData.subscribeCommunication(commType.RefreshLicenses, this.subscriptionSrc);
    this.toolRefreshLicenseSubscription.subscribe((selectedProduct) => {
      if (selectedProduct === undefined) return;

      QLogger.LogInfo(this.logSrc, "Install Complete");
      this.getLocalLicenses(this.selectedProductDetails.id);
    });
  }

  goBack() {
    QLogger.LogInfo(this.logSrc, "Back to tools list");
    if(this.app.sharedData.service.tools.loadFromTools){ 
      if (this.router.url.includes("source")) {
        this.app.location.replaceState('/main/source/find');
      }
      else {
        this.app.location.replaceState('/main/tools/find');
      }
      this.app.sharedData.service.tools.loadFromTools = false;
    }
    else if (this.router.url.includes("source")) {
      this.router.navigate(['/main/source/find']);
    }
    else {
      this.router.navigate(['/main/tools/find']);
    }
  }

  /* Single Product View */
  getSelectedProductDetails(installProduct: boolean) {
    let response: Observable<QPMResponse>;
    this.selectedProductDetails = undefined;
    this.selectedVersion = undefined;
    this.packageVersions = [];
    this.loadingSelectedProductDetails = true;
    this.productErrorMessage = "";
    this.uiLicenseGroupID = "";
    this.licenseErrorMessage = "";

    if (!installProduct) {
      this.installer.isInstallInProgress = false;
    }

    QLogger.LogInfo(this.logSrc, "Refresh Product Details : " + this.selectedProduct.productUUID);
    let selectedOsOptionObject = this.utils.getOSoptionObjectById(this.selectedOSId);
    let os = selectedOsOptionObject.os;
    response = this.webClient.refreshProduct(this.selectedProduct.productUUID, os);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Refresh Product Details Response - Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Refresh Product Details Response - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.selectedProductDetails = obj;
            if (this.selectedProduct.logoHTML !== undefined && this.selectedProduct.logoHTML !== '') {
              this.selectedProductDetails.logoHTML = this.selectedProduct.logoHTML;
            } else {
              this.selectedProductDetails.logoHTML = 'assets/images/DefaultBlueQ48.png';
            }
            this.productErrorMessage = "";

            // Get latest version for this product using catalog v3
            QLogger.LogInfo(this.logSrc, "Get Newest Releases : " + this.selectedProductDetails.displayName);
            QLogger.LogInfo(this.logSrc, "selected os is: " + this.selectedOSId);
            if(selectedOsOptionObject.os===selectableOSs.LINUX){
              let osDist= selectedOsOptionObject.osDistType===""?OSDistType.Debian:selectedOsOptionObject.osDistType;
              QLogger.LogInfo(this.logSrc, "Get  Releases for Product with Linux Distribution :"+osDist);
              response = this.webClient.getReleasesV3ByLinuxOSDistType(this.selectedProductDetails.id, this.app.sharedData.userInfo.username, selectedOsOptionObject.os, 0, osDist);
            }else{
              if (selectedOsOptionObject.targetArch != selectableArchs.Any) {
                response = this.webClient.getReleasesV3ByTargetArch(this.selectedProductDetails.id, this.app.sharedData.userInfo.username, selectedOsOptionObject.os, 0, selectedOsOptionObject.targetArch);
              }
              else {
                response = this.webClient.getReleasesV3(this.selectedProductDetails.id, this.app.sharedData.userInfo.username, os, 0);
              }
            }
            response.subscribe(
              (data: QPMResponse) => {
                if (this.app.sharedData.appInfo.logResponse) {
                  QLogger.LogInfo(this.logSrc, "Get Newest Releases Response - Response : " + JSON.stringify(data));
                }
                if (data.isSuccess()) {
                  QLogger.LogInfo(this.logSrc, "Get Newest Releases Response - Success");
                  let obj = JSON.parse(data.getData());
                  if (obj !== undefined && obj !== null) {
                    this.selectedProductReleases = obj;
                    this.selectedProductReleases.releases.sort((releaseA, releaseB) => (Utils.prototype.semVerGte(releaseA.version, releaseB.version) ? -1 : 1))
                    this.selectedProductReleases.releases.forEach((thisPackage, index) => {
                      if (thisPackage?.version != "0.0.0.0") {
                        let versionLabel = thisPackage.releaseBranch == "Production" ? thisPackage.version : thisPackage.version + " " + thisPackage.releaseBranch;
                        this.packageVersions.push({
                          label: "v" + versionLabel,
                          value: thisPackage,
                          disabled: false
                        });
                      }
                    });
                    /*if (this.packageVersions.length == 0) {
                      this.packageVersions.push({
                        label: "No packages available",
                        value: null,
                        disabled: true
                      });
                    }*/
                    if (installProduct) {
                      this.selectedProductReleases.releases.forEach((thisPackage, index) => {
                        if (thisPackage.version == this.app.sharedData.service.tools.downloadProductInfo.version && thisPackage.targetOS == this.app.sharedData.service.tools.downloadProductInfo.selectedOS) {
                          this.selectedVersion = thisPackage;
                        }
                      });
                    }
                    else {
                      if (this.selectedProductReleases.releases.length > 0) {
                        this.selectedVersion = this.selectedProductReleases.releases[0];
                      }
                    }
                    if (this.selectedVersion != undefined) {
                      let test = this.selectedVersion.releaseDateUTC;
                      this.selectedVersion.releaseDateUTC = test.substring(0, test.indexOf(' '));
                      this.getPackageInfo();
                      this.selectedProductInstallDetails = undefined;
                    }
                    else {
                      if (installProduct) {
                        QLogger.LogInfo(this.logSrc, "Version " + this.app.sharedData.service.tools.downloadProductInfo.version + " not valid for " + this.selectedProduct.displayName);
                        return;
                      }

                    }

                    // Get Installed version 
                    if (this.app.sharedData.appInfo.isElectronMode && this.selectedProductReleases.componentID !== undefined) {
                      this.getInstalledComponent(installProduct);
                    }

                    if (this.app.sharedData.appInfo.isElectronMode && this.osOptions.length > 0 &&
                      this.selectedProductReleases.releases.length > 0 &&
                      this.selectedProductReleases.releases[0]?.targetArch.toLowerCase() == selectableArchs.ARM64.toLowerCase()) {

                      let osOptionObj = this.utils.updateOSOption(selectedOsOptionObject.id, selectableArchs.ARM64);
                      this.osOptions.find(x => x.value == selectedOsOptionObject.id).label = osOptionObj?.label;
                    }
                  }
                }
                else {
                  QLogger.LogError(this.logSrc, "Get Latest Version - Failed");
                  QLogger.LogError(this.logSrc, "Get Latest Version - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

                  this.productErrorMessage = "Failed to get available versions" + data.getError();
                  let errorCode = data.getCode();
                  if (0 === errorCode || 500 == errorCode) {
                    this.app.showNetworkError();
                  }
                }
                this.loadingSelectedProductDetails = false;
              });
            if(this.app.sharedData.appInfo.isElectronMode){
              this.getLicensesForSelectedProduct(this.selectedProductDetails.id);
            }
            this.getDocuments();
          }
        }
        else {
          //this.showProductDetails = false;
          if (installProduct)
            QLogger.LogError(this.logSrc, "Cannot fetch product details");

          let errorCode = data.getCode();
          if (0 === errorCode || 500 == errorCode) {
            this.app.showNetworkError();
          }
        }
      });
  }

  getInstalledComponent(installProduct: boolean) {

    if (!this.app.sharedData.appInfo.isElectronMode || this.selectedProductReleases.componentID === undefined) return;

    QLogger.LogInfo(this.logSrc, "Get Installed version details : " + this.selectedProductDetails?.displayName);
    let response: Observable<QPMResponse>;
    response = this.catalogClient.getInstalledComponent(this.selectedProductReleases.componentID);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Get install details Response - Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get install details Response - Success");
          let obj = JSON.parse(data.getData());
          console.log("parsed install details");
          if (obj !== undefined || obj !== null) {
            this.selectedProductInstallDetails = obj;

            if (this.selectedProductInstallDetails.InstallationDate != undefined) {
              this.selectedProductInstallDetails.InstallationDate = this.selectedProductInstallDetails.InstallationDate.substring(0, this.selectedProductInstallDetails.InstallationDate.indexOf(' '));
            }
            let installedVersions: InstalledVersion[];
            if(obj.InstalledVersions){ //the key is 'InstalledVersions' on Windows and 'Installations' on others
              installedVersions = JSON.parse(obj.InstalledVersions)
            }
            else if(obj.Installations){
              installedVersions = obj.Installations;
            }
            // Handle case of multiple installations
            if (installedVersions !== undefined && installedVersions !== null) {
              let temp: InstalledVersion[] = installedVersions;
              this.selectedProductInstallDetails.InstalledVersions = temp;

              for(let index = 0; index < installedVersions.length; index++){
                if(installedVersions[index].Version)// Windows has 'version' while others have 'Version'
                  this.selectedProductInstallDetails.InstalledVersions[index].version =installedVersions[index].Version
              }
              if(this.selectedProductInstallDetails.InstalledVersions)
                this.isMultipleInstall = true;
              this.setTopOptionsDropdown();
            }

            // Determine if latest version is installed
            this.selectedProductInstallDetails.UpdateAvailable = true;
            let index = this.selectedProductReleases.releases.findIndex(release => release.version == this.selectedProductInstallDetails.Version);
            if (index == 0) {
              QLogger.LogInfo(this.logSrc, "Latest version ");
              this.selectedProductInstallDetails.UpdateAvailable = false;
            }
            this.setInstallButtonOptions();
          }
        }
        else {
          this.selectedProductInstallDetails = undefined;
          this.setInstallButtonOptions();
        }

        if (installProduct) {
          if (this.selectedVersion.installType == 'Install')
            this.installer.GetPackageToInstall(InstallActions.Install,
              this.selectedProduct,
              this.selectedVersion,
              this.selectedProductDetails,
              this.utils.getOSoptionObjectById(this.selectedOSId));
          else
            this.installer.GetPackageToInstall(InstallActions.Extract,
              this.selectedProduct,
              this.selectedVersion,
              this.selectedProductDetails,
              this.utils.getOSoptionObjectById(this.selectedOSId));

        }
      });
  }

  getLicensesForSelectedProduct(productId: string) {
    // Get all licenses for this product
    let response: Observable<QPMResponse>;
    if (this.app.sharedData.appInfo.workOffline == false) {
      QLogger.LogInfo(this.logSrc, "Get License Details : " + this.selectedProductDetails?.displayName);
      this.selectedProduct.productEntitlements = [];
      response = this.webClient.getEntitilementsProduct(this.selectedProduct.productUUID, this.utils.getOSoptionObjectById(this.selectedOSId).os);
      response.subscribe(
        (data: QPMResponse) => {
          if (this.app.sharedData.appInfo.logResponse) {
            QLogger.LogInfo(this.logSrc, "Get User Entitlements Response - Response : " + JSON.stringify(data));
          }
          if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc, "Get User Entitlements Response - Success");
            this.selectedProduct.productEntitlements = JSON.parse(data.getData());
          }
          else {
            let errorCode = data.getCode();
            if (0 === errorCode || 500 == errorCode) {
              this.app.showNetworkError();
            }
          }
          this.getLocalLicenses(productId);
        });
    }
    else {
      //Get Offline Licenses 
      QLogger.LogInfo(this.logSrc, "Get License Details : " + this.selectedProductInstallDetails.ProductName);
      this.getLocalLicenses(productId);
    }
  }

  getLocalLicenses(productId: string) {
    // Get license IDs for this product
    let response: Observable<QPMResponse>;
    this.selectedProductLicensesRows = [];
    this.selectedProduct?.productEntitlements?.forEach(entitlement => {
      QLogger.LogInfo(this.logSrc, "License Group ID " + entitlement.id);
      let licenseDetail = new LicenseDetails();
      licenseDetail.activationKey = new ActivationKey();
      licenseDetail.activationKey.licenseGroupId = entitlement.id;
      licenseDetail.activationKey.features = entitlement.features;
      licenseDetail.expirationDate = "Not Activated"
      licenseDetail.status = "D";
      licenseDetail.products = [];
      licenseDetail.activationKey.features.forEach((feature) => {
        if (undefined == licenseDetail.products.find(name => name == feature.productName)) {
          licenseDetail.products.push(feature.productName);
        }
      });
      let productString: String = licenseDetail.products.toString();
      productString.replace(",", ", ");
      let featureString: String = "";
      licenseDetail.activationKey.features.forEach((feature, index) => {
        featureString += feature.name;
        if (index < licenseDetail.activationKey.features.length - 1) featureString += ", ";
      });

      this.selectedProductLicensesRows.push({ data: { licenseGroupID: licenseDetail.activationKey.licenseGroupId, licenseDetails: licenseDetail, licenseStatus: licenseDetail.status == 'A' ? true : false }, children: [{ data: { licenseDetails: licenseDetail, productString: productString, featureString: featureString } }] });
    });
    response = this.limeClient.getLicensesByProduct(productId);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Get Licenses By Product Response - Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Licenses By Product Response - Success");
          var obj = data.getData();
          if (obj !== undefined || obj !== null) {
            obj = obj.replace('[', '').replace(']', '');
            let licenseIDs: string[];

            if (obj.length > 1) {
              licenseIDs = obj.split(',');
            }

            // Get d etails for each license
            licenseIDs?.forEach(licenseID => {
              //this.loadingSelectedProductLicenses = true;
              response = this.limeClient.getLicenseInfo(licenseID.toString());
              response.subscribe(
                (data: QPMResponse) => {
                  if (this.app.sharedData.appInfo.logResponse) {
                    QLogger.LogInfo(this.logSrc, "Get Licenses By Product Response - Response : " + JSON.stringify(data));
                  }
                  if (data.isSuccess()) {
                    QLogger.LogInfo(this.logSrc, "Get Licenses By Product Response - Success");
                    let obj = JSON.parse(data.getData());
                    if (obj !== undefined || obj !== null) {
                      let licenseDetail: LicenseDetails = obj;
                      licenseDetail.products = [];

                      // Calculate expiration date
                      if (licenseDetail.status == 'A') {

                        let smallestDaysLeft = Number.MAX_VALUE;
                        let expiredCount = 0;

                        licenseDetail.activationKey.features.forEach((feature) => {
                          feature.daysLeft = this.getDaysLeft(feature, licenseDetail.activationKey.daysUsed, licenseDetail.activationKey.activatedd);
                          if (feature.daysLeft == 0) expiredCount++;
                          smallestDaysLeft = smallestDaysLeft > feature.daysLeft ? feature.daysLeft : smallestDaysLeft;

                          if (undefined == licenseDetail.products.find(name => name == feature.productName)) {
                            licenseDetail.products.push(feature.productName);
                          }
                        });

                        if (expiredCount > 0) licenseDetail.expirationDate = "Expired";
                        else {
                          let endDate = this.utils.millisecondPlusDays(Date.now(), smallestDaysLeft)
                          licenseDetail.expirationDate = this.utils.millisecondToShortDateString(endDate);
                        }

                      }
                      else {
                        licenseDetail.activationKey.features.forEach((feature) => {
                          if (undefined == licenseDetail.products.find(name => name == feature.productName)) {
                            licenseDetail.products.push(feature.productName);
                          }
                        });
                        licenseDetail.expirationDate = "Deactivated"
                      }
                      this.selectedProductLicensesRows.forEach((license, index) => {
                        if (license.data.licenseDetails.activationKey.licenseGroupId == licenseDetail.activationKey.licenseGroupId) {
                          this.selectedProductLicensesRows.splice(index, 1);
                        }
                      });
                      // Push license details
                      let productString: String = licenseDetail.products.toString();
                      productString.replace(",", ", ");
                      let featureString: String = "";
                      licenseDetail.activationKey.features.forEach((feature, index) => {
                        featureString += feature.name;
                        if (index < licenseDetail.activationKey.features.length - 1) featureString += ", ";
                      });
                      this.selectedProductLicensesRows.push({ data: { licenseGroupID: licenseDetail.activationKey.licenseGroupId, licenseDetails: licenseDetail, licenseStatus: licenseDetail.status == 'A' ? true : false }, children: [{ data: { licenseDetails: licenseDetail, productString: productString, featureString: featureString } }] });

                    }
                  }
                  else {
                    QLogger.LogError(this.logSrc, "Get Licenses By Product - Failed");
                    QLogger.LogError(this.logSrc, "Get Licenses By Product - Failed Error: " + data.getError() + " - " + data.getErrorDetail());
                  }
                  this.licenseTable?.reset();
                });
            });
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Licenses By Product - Failed");
          QLogger.LogError(this.logSrc, "Get Licenses By Product - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.productErrorMessage = "Failed to get Licenses By Product: " + data.getError();
        }

        this.licenseTable?.reset();

      });

  }
  onNodeExpand(event) {
    const node = event.node;
  }
  getDaysLeft(feature: Feature, daysUsed: number, activationDate: string) {

    let end = Date.parse(feature.endd);
    let start = Date.parse(feature.startd);

    let remainingTime = (feature.length - daysUsed) - this.utils.daysBetweenMillisecondTimestamps(Date.now(), Date.parse(activationDate));

    let daysRemaining = 0;
    if (start > Date.now() || end < Date.now()) {
      daysRemaining = 0;
    }
    else {
      daysRemaining = this.utils.daysBetweenMillisecondTimestamps(end, Date.now());
    }

    let finalDaysLeft = 0;
    if (remainingTime < daysRemaining) {
      finalDaysLeft = Math.ceil(remainingTime);
    }
    else {
      finalDaysLeft = Math.ceil(daysRemaining);
    }

    return finalDaysLeft < 0 ? 0 : finalDaysLeft;
  }

  onClearError() {
    this.productErrorMessage = "";
  }

  onClearLicenseError() {
    this.licenseErrorMessage = "";
  }

  onLicenseStatusChanged(event) {
    if (event.licenseDetails.status == 'A') {
      this.onDeactivateActionClick(event);
    }
    else {
      this.onActivateActionClick(event);
    }
  }

  onActivateActionClick(event) {
    console.log("Activate license: " + event.licenseDetails.activationKey.licenseGroupId);

    this.ActivateLicense(event.licenseDetails.activationKey.licenseGroupId);
  }

  onDeactivateActionClick(event) {
    console.log("Deactivate license: " + event.licenseDetails.activationKey.licenseActivationId);

    let response: Observable<QPMResponse>;

    // Deactivate licenses
    QLogger.LogInfo(this.logSrc, "Deactivate License : " + event.licenseDetails.activationKey.licenseActivationId);
    response = this.webClient.deactivateLicense(event.licenseDetails.activationKey.licenseActivationId);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Deactivate License Response - Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Deactivate License Response - Success");

          // Refresh license for this product
          this.getLicensesForSelectedProduct(this.selectedProductDetails.id)
          this.licenseErrorMessage = "";
        }
        else {
          QLogger.LogError(this.logSrc, "Deactivate License - Failed");
          QLogger.LogError(this.logSrc, "Deactivate License - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.licenseErrorMessage = "Failed to Deactivate Licenses: " + data.getError();
        }
      });
  }

  onAddLicenseClicked() {
    this.showAddLicense = true;
  }

  onCancelAddLicenseClicked() {
    this.showAddLicense = false;
  }

  onFilterLicenseTable(event) {
    this.app.sharedData.service.tools.licenseFilter = event.filters.global.value;
  }

  onVersionChange() {
    if (this.selectedVersion === undefined) return;
    this.getPackageInfo();
  }

  setInstallButtonOptions() {
    this.installActionOptions = [];

    // Portal options
    if (!this.app.sharedData.appInfo.isElectronMode) {
      if (this.selectedProductDetails.productType == "Freemium" || this.selectedVersion?.installType == 'ExtractOnly' ||
        this.selectedProduct.productUUID == this.app.sharedData.service.tools.qpm3ProductId) {
        this.installActionOptions.push({ label: "Download", command: () => { this.onDownloadOnlyButtonClicked() } });
        if (this.installSplitButton) this.installSplitButton.label = "Download";
      }else if(this.selectedVersion?.installType==='Zip'){//ZipType: Launch QPM3 same as other cases
        this.installActionOptions.push({ label: "Download", command: () => { this.onDownloadOnlyButtonClicked() } });
        if (this.installSplitButton) this.installSplitButton.label = "Download";
      }
      else { // type == Install
        if (Utils.prototype.getOSEnumFromUserAgent() == Utils.prototype.getOSEnumFromSelectedOSString(this.utils.getOSoptionObjectById(this.selectedOSId).os as string)
          && !Utils.prototype.isMobileOrTablet()) {
          this.installActionOptions.push({ label: "Install", command: () => { this.onInstallButtonClicked() } });
          this.platformMismatch = false;
        }
        else {
          this.platformMismatch = true;
        }
        if (this.installSplitButton) this.installSplitButton.label = "Install";
      }
    }
    // Desktop app options
    else {
      if (this.selectedVersion?.installType == 'ExtractOnly') {
        this.installActionOptions.push({ label: "Extract", command: () => { this.onExtractButtonClicked() } });
        if (this.installSplitButton) this.installSplitButton.label = "Extract";
      }else if(this.selectedVersion?.installType==='Zip'){//ZipType:DownloadOnly
        this.installActionOptions.push({ label: "Download", command: () => { this.onDownloadOnlyButtonClicked() } });
        if (this.installSplitButton) this.installSplitButton.label = "Download";
      }
      else { // type == Install
        let isInstalled: Boolean = this.selectedProductInstallDetails !== undefined;
        let isMultiInstall: Boolean = (this.selectedProductInstallDetails?.MultipleInstall != undefined) && (this.selectedProductInstallDetails.MultipleInstall == "1");
        let isInstalledVersionSameAsSelectedVersion = this.selectedProductInstallDetails?.Version == this.selectedVersion?.version
        if (isInstalled && !isMultiInstall && isInstalledVersionSameAsSelectedVersion) {
          this.installActionOptions.push({ label: "Refresh", command: () => { this.onRefreshButtonClicked() } });
          if (this.installSplitButton) this.installSplitButton.label = "Refresh";
        }
        else {
          this.installActionOptions.push({ label: "Install", command: () => { this.onInstallButtonClicked() } });
          if (this.installSplitButton) this.installSplitButton.label = "Install";
        }
      }
    }
  }

  onOSChange() {
    if (this.selectedOSId === undefined) return;
    this.packageVersions = [];
    this.getAvailableReleasesForProduct();
    if (!this.app.sharedData.appInfo.isElectronMode) {
      this.setInstallButtonOptions();
    }
  }

  onInstallButtonClicked() {
    // Add handling in case where default "Install" button was clicked instead of Download from Portal
    if (!this.app.sharedData.appInfo.isElectronMode &&
      (this.selectedProduct.productUUID == this.app.sharedData.service.tools.qpm3ProductId ||
        this.selectedProduct.productType == "Freemium" ||
        this.selectedVersion?.installType == "ExtractOnly")) {
      this.onDownloadOnlyButtonClicked();
      return;
    }
    else if (this.installSplitButton?.label == "Refresh" || this.installButton?.label == "Refresh") {
      this.onRefreshButtonClicked();
      return;
    }
    else if (this.installSplitButton?.label == "Extract" || this.installButton?.label == "Extract") {
      this.onExtractButtonClicked();
      return;
    }

    console.log("User selected installation of " + this.selectedVersion.version);

    if (!this.app.sharedData.appInfo.isElectronMode) {
      this.installer.downloadQPMV3(this.selectedProduct,
        this.selectedVersion,
        this.utils.getOSoptionObjectById(this.selectedOSId),
        this.selectedProductInstallDetails,
        this.selectedProductDetails);
    }
    else {

      this.installer.GetPackageToInstall(InstallActions.Install,
        this.selectedProduct,
        this.selectedVersion,
        this.selectedProductDetails,
        this.utils.getOSoptionObjectById(this.selectedOSId));
    }
  }
  onExtractButtonClicked() {

    if (!this.app.sharedData.appInfo.isElectronMode) {
      this.installer.downloadQPMV3(this.selectedProduct,
        this.selectedVersion,
        this.utils.getOSoptionObjectById(this.selectedOSId),
        this.selectedProductInstallDetails,
        this.selectedProductDetails);
    }
    else {
      this.installer.GetPackageToInstall(InstallActions.Extract,
        this.selectedProduct,
        this.selectedVersion,
        this.selectedProductDetails,
        this.utils.getOSoptionObjectById(this.selectedOSId));
    }
  }

  onRefreshButtonClicked() {
    console.log("User selected refresh of " + this.selectedVersion.version);

    this.installer.GetPackageToInstall(InstallActions.Refresh,
      this.selectedProduct,
      this.selectedVersion,
      this.selectedProductDetails,
      this.utils.getOSoptionObjectById(this.selectedOSId));

  }

  onRefreshButtonClickedSingle() {
    console.log("User selected refresh of " + this.selectedProductInstallDetails.Version);
    QLogger.LogInfo(this.logSrc, "User selected refresh of " + this.selectedProductInstallDetails.Version);
    let installedVersion: ReleaseV3 = this.getInstalledVersionReleaseV3();
    if (installedVersion != null) {
      this.installer.GetPackageToInstall(InstallActions.Refresh,
        this.selectedProduct,
        installedVersion,
        this.selectedProductDetails,
        this.utils.getOSoptionObjectById(this.selectedOSId));

    }

  }

  getInstalledVersionReleaseV3(): ReleaseV3 {
    QLogger.LogInfo(this.logSrc, "Installed Version: " + this.selectedProductInstallDetails.Version);
    for (let release of this.selectedProductReleases.releases) {
      if (release.version === this.selectedProductInstallDetails.Version) {
        QLogger.LogInfo(this.logSrc, "ReleaseV3 Installed Info: " + release.installerInfo);
        return release
      }
    }
    QLogger.LogInfo(this.logSrc, "ReleaseV3 Installed Info: " + null);
    return null;
  }

  onUninstallButtonClickedSingle() {
    if (this.isMultipleInstall) {
      if (this.app.sharedData.appInfo.platform == 'win32')
        this.selectedProductInstallDetails.Version = this.selectedProductInstallDetails.InstalledVersions[0].version;
      else
        this.selectedProductInstallDetails.Version = this.selectedProductInstallDetails.InstalledVersions[0].Version;

      this.selectedProductInstallDetails.packageFile = this.selectedProductInstallDetails.InstalledVersions[0].packageFile;
      this.selectedProductInstallDetails.packageDownloadLocation = this.selectedProductInstallDetails.InstalledVersions[0].packageFile;
    }

    console.log("User selected uninstall of " + this.selectedProductInstallDetails.Version);
    this.installer.GetPackageToUninstall(this.selectedProduct, this.selectedProductInstallDetails, this.selectedProductReleases, this.utils.getOSoptionObjectById(this.selectedOSId), undefined);
  }

  onUninstallButtonClicked(installedVersion) {
    console.log("User selected uninstall of " + installedVersion.version);
    this.installer.GetPackageToUninstall(this.selectedProduct, this.selectedProductInstallDetails, this.selectedProductReleases, this.utils.getOSoptionObjectById(this.selectedOSId), installedVersion);
  }

  onDownloadOnlyButtonClicked() {
    this.installer.GetPackageToInstall(InstallActions.DownloadOnly,
      this.selectedProduct,
      this.selectedVersion,
      this.selectedProductDetails,
      this.utils.getOSoptionObjectById(this.selectedOSId));
  }
 

  onShowLicenseDetails(rowData) {
    this.showLicenseDetails = true;
    this.selectedLicenseDetails = rowData;
  }

  onActivateGroupButtonClicked(event) {
    this.uiLicenseGroupID.replace(/\s/g, "");
    this.ActivateLicense(this.uiLicenseGroupID);
    this.uiLicenseGroupID = "";
  }

  checkLicenseIdAndActivate(licenseGroupID) {

    QLogger.LogInfo(this.logSrc, "Activate License Group After Check for : " + licenseGroupID + " for product " + this.selectedProduct.productUUID);
    let isValidLicense = false;
    this.selectedProduct.productEntitlements.forEach((entitlement) => {

      QLogger.LogInfo(this.logSrc, entitlement.id);
      if (entitlement.id == licenseGroupID) {
        this.ActivateLicense(licenseGroupID);
        isValidLicense = true;
      }
    });
    if (!isValidLicense) {
      this.licenseErrorMessage = "User " + this.app.sharedData.userInfo.username + " is not entitled to license group " + licenseGroupID;
    }
  }
  ActivateLicense(licenseGroupID) {
    let response: Observable<QPMResponse>;

    // Get all licenses for this product
    QLogger.LogInfo(this.logSrc, "Activate License Group : " + licenseGroupID);
    response = this.webClient.activateLicense("", licenseGroupID);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Activate License Group Response - Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Activate License Group Response - Success");
          this.licenseErrorMessage = "";
          //this.getLocalLicenses(this.selectedProduct.productUUID);
        }
        else {
          QLogger.LogError(this.logSrc, "Activate License Group - Failed");
          QLogger.LogError(this.logSrc, "Activate License Group - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.licenseErrorMessage = "Failed to Activate License Group: " + data.getError();
        }
        this.getLicensesForSelectedProduct(this.selectedProductDetails.id);
      });
  }

  getPackageInfo() {
    QLogger.LogInfo(this.logSrc, "Getting Release Notes");
    //this.showDownloadButton = false;
    let response: Observable<QPMResponse>;
    let packageDetails: PackageDetails;
    let releaseNotesDetails: ReleaseNotesDetails;
    this.selectedVersion.releaseNotesInfo = [];
    response = this.webClient.refreshPackage(this.selectedVersion.releaseID, this.selectedVersion.targetOS);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Refresh Package Response - Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          packageDetails = JSON.parse(data.getData());
          this.selectedVersion.packageComponentId = packageDetails.componentId;
          this.selectedVersion.installType = packageDetails.installType;
          let sizeValue = packageDetails.files?.find(file => file.filename.endsWith(".qik") || file.filename.endsWith(".exe"))?.filesize as number;
          this.selectedVersion.installerSizeString = this.utils.bytesToSizeString(sizeValue);
          response = this.webClient.getReleaseNotes("/releasenotes/" + packageDetails.componentId + "/" + this.selectedVersion.version + "/" + this.selectedVersion.targetOS);
          response.subscribe(
            (downloadData: QPMResponse) => {
              if (this.app.sharedData.appInfo.logResponse) {
                QLogger.LogInfo(this.logSrc, "Signed URL - Response : " + JSON.stringify(downloadData));
              }
              if (downloadData.isSuccess()) {
                QLogger.LogInfo(this.logSrc, "Release Notes Response Success ");
                releaseNotesDetails = JSON.parse(downloadData.getData());
                if (releaseNotesDetails !== undefined || releaseNotesDetails !== null) {
                  this.selectedVersion.releaseNotesInfo = releaseNotesDetails.fileUrlMap?.entry;
                }
              }
              else {
                let errorCode = downloadData.getCode();
                if (0 === errorCode || 500 == errorCode) {
                  this.app.showNetworkError();
                }
              }
            });
          /*if(this.selectedOS!='Windows')
          {
            packageDetails.files.forEach((file) =>{
              let fileExtension = file.filename.substr(file.filename.lastIndexOf('.'));
              if((fileExtension=='.deb' && this.selectedOS=='Linux')||(fileExtension=='.pkg' && this.selectedOS=='MacOS'))
              {
                QLogger.LogInfo(this.logSrc,"Can Download Selected Package from QPM");
                this.showDownloadButton = true;
              }
            });
          }
          else
          this.showDownloadButton = true;*/

          // Set button options
          this.setInstallButtonOptions();
        }
        else {
          let errorCode = data.getCode();
          if (0 === errorCode || 500 == errorCode) {
            this.app.showNetworkError();
          }
        }
      });
  }

  downloadReleaseNotes(isCDN: boolean) {
    let downloadUrl: string;
    try {
      this.selectedVersion.releaseNotesInfo.forEach(file => {

        downloadUrl = file.value;
        if (this.app.sharedData.appInfo.isElectronMode) {
          const fs = window.require('fs');
          const os = window.require('os');

          // Does file already exist locally?
          if (fs.existsSync(os.tmpdir() + "/" + file.key)) {
            this.app.showMessageBox("Download", file.key + " already exists at  " + os.tmpdir(), "Ok");

          }
          // File doesn't already exist, requires download
          else {
            QLogger.LogInfo(this.logSrc, os.tmpdir() + "/" + file.key + " does not exist. Triggering download");
            let headers: HttpHeaders;
            headers = new HttpHeaders({
              'Access-Control-Allow-Origin': '*',
              'Authorization': "Bearer " + this.app.sharedData.userInfo.vordelToken
            });
            this.http.get(downloadUrl, {
              headers: headers,
              'responseType': 'arraybuffer', 'reportProgress': true, observe: "events"
            }).subscribe((event: HttpEvent<any>) => {
              switch (event.type) {
                case HttpEventType.Response:
                  {

                    if (event.status != 200) {
                      QLogger.LogError(this.logSrc, "Download Failed - Cannot Write File to Storage");
                    }
                    else {
                      const buffer = Buffer.from(event.body);
                      fs.writeFile(os.tmpdir() + "/" + file.key, buffer, (err) => this.zone.run(() => {
                        if (err || buffer.length === 0) {
                          QLogger.LogError(this.logSrc, "Cannot Downloaded  " + file.key);
                        }
                        else {
                          QLogger.LogInfo(this.logSrc, "Successfully Downloaded  " + file.key);
                          this.app.showMessageBox("Download", file.key + " downloaded successfully to " + os.tmpdir(), "Ok");

                        }
                      }));
                    }
                    break;
                  }
              }
            },
              error => {
                QLogger.LogError(this.logSrc, "Error During Download from URL " + downloadUrl);
                if (isCDN) {
                  let response: Observable<QPMResponse>;
                  let releaseNotesDetails: ReleaseNotesDetails;
                  response = this.webClient.getReleaseNotes("/releasenotes/direct/" + this.selectedVersion.packageComponentId + "/" + this.selectedVersion.version + "/" + this.selectedVersion.targetOS);
                  response.subscribe(
                    (downloadData: QPMResponse) => {
                      if (this.app.sharedData.appInfo.logResponse) {
                        QLogger.LogInfo(this.logSrc, "Release Notes URL - Response : " + JSON.stringify(downloadData));
                      }
                      if (downloadData.isSuccess()) {
                        QLogger.LogInfo(this.logSrc, "Release Notes Response Success ");
                        releaseNotesDetails = JSON.parse(downloadData.getData());
                        if (releaseNotesDetails !== undefined || releaseNotesDetails !== null) {
                          this.selectedVersion.releaseNotesInfo = releaseNotesDetails.fileUrlMap.entry;
                        }
                        this.downloadReleaseNotes(false);
                      }
                      else {
                        this.app.showMessageBox("Download", "Cannot Download : " + file.key, "Ok");
                        let errorCode = downloadData.getCode();
                        if (0 === errorCode || 500 == errorCode) {
                          this.app.showNetworkError();
                        }
                      }
                    });
                }
                else {
                  this.app.showMessageBox("Download", "Cannot Download : " + file.key, "Ok");
                }

              });
          }
        }
        else {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = downloadUrl;
          a.download = file.key;
          a.click();
          document.body.removeChild(a);
        }
      });
    }
    catch (e) {
      this.app.showMessageBox("Download", "Cannot Download Release Notes", "Ok");
    }
  }

  getAvailableReleasesForProduct() {
    let response: Observable<QPMResponse>;
    let osOption: OSOption = this.utils.getOSoptionObjectById(Number(this.selectedOSId));
    QLogger.LogInfo(this.logSrc, "Get Releases for product : " + this.selectedProductDetails.displayName + " for OS " + osOption.os + " targetArch: " + osOption.targetArch);
    if(osOption.os===selectableOSs.LINUX){
      let osDist= osOption.osDistType===""?OSDistType.Debian:osOption.osDistType;
      QLogger.LogInfo(this.logSrc, "Get  Releases for Product with Linux Distribution :"+osDist);
      response = this.webClient.getReleasesV3ByLinuxOSDistType(this.selectedProductDetails.id, this.app.sharedData.userInfo.username, osOption.os, 0, osDist);
    }else{
      if (osOption.targetArch != selectableArchs.Any) {
        response = this.webClient.getReleasesV3ByTargetArch(this.selectedProductDetails.id, this.app.sharedData.userInfo.username, osOption.os, 0, osOption.targetArch);
      }
      else {
        response = this.webClient.getReleasesV3(this.selectedProductDetails.id, this.app.sharedData.userInfo.username, osOption.os, 0);
      }
    }


    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Get Newest Releases Response - Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Newest Releases Response - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined && obj !== null) {
            this.selectedProductReleases = obj;
            this.selectedProductReleases.releases.sort((releaseA, releaseB) => (Utils.prototype.semVerGte(releaseA.version, releaseB.version) ? -1 : 1))
            this.selectedProductReleases.releases.forEach((thisPackage, index) => {
              if (thisPackage?.version != "0.0.0.0") {
                let versionLabel = thisPackage.releaseBranch == "Production" ? thisPackage.version : thisPackage.version + " " + thisPackage.releaseBranch;
                this.packageVersions.push({
                  label: "Version " + versionLabel,
                  value: thisPackage,
                  disabled: false
                });
              }
            });
            /*if (this.packageVersions.length == 0) {
              this.packageVersions.push({
                label: "No packages available",
                value: null,
                disabled: true
              })
            }*/

            if (this.selectedProductReleases.releases.length > 0) {
              this.selectedVersion = this.selectedProductReleases.releases[0];
              let test = this.selectedVersion.releaseDateUTC;
              this.selectedVersion.releaseDateUTC = test.substring(0, test.indexOf(' '));
              this.getPackageInfo();
            }

            this.selectedProductInstallDetails = undefined;

            // Get Installed version 
            if (this.app.sharedData.appInfo.isElectronMode && this.selectedProductReleases.componentID !== undefined) {
              this.getInstalledComponent(false);
            }
          }
        }
        else {
          QLogger.LogError(this.logSrc, "Get Latest Version - Failed");
          QLogger.LogError(this.logSrc, "Get Latest Version - Failed Error: " + data.getError() + " - " + data.getErrorDetail());

          this.productErrorMessage = "Failed to get available versions" + data.getError();
          let errorCode = data.getCode();
          if (0 === errorCode || 500 == errorCode) {
            this.app.showNetworkError();
          }
        }
      });
  }
  tryAddOptionsinOSDropDown(os: OS, arch: TargetArch) {
    try {
      let oSToAdd: string = OS[os];
      let targetArchToAdd: selectableArchs = this.utils.getTargetArchStringFromEnum(arch);
      let response: Observable<QPMResponse>;
      response = this.webClient.getReleasesV3ByTargetArch(this.selectedProduct.productUUID,
        this.app.sharedData.userInfo.username, oSToAdd, 0,
        targetArchToAdd);
      response.subscribe(
        (data: QPMResponse) => {
          if (this.app.sharedData.appInfo.logResponse) {
            QLogger.LogInfo(this.logSrc, "Get Newest Releases Response for " + targetArchToAdd + " - Response : " + JSON.stringify(data));
          }
          if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc, "Get Newest Releases Response for " + targetArchToAdd + " - Success : ");
            let obj = JSON.parse(data.getData());
            if (obj !== undefined && obj !== null) {
              let releases: ReleasesV3 = obj
              if (releases.releases.length > 0 && releases.releases[0].targetArch.toLowerCase() === (targetArchToAdd as string).toLowerCase()) {
                let osOption = this.utils.addOSOption(oSToAdd, targetArchToAdd,"")
                this.osOptions.push({
                  label: osOption.label, value: osOption.id, disabled: false
                });
              }
            }
          }
        });
    }
    catch (ex) {
      QLogger.LogError(this.logSrc, "Exception while getting releaes by target arch" + ex);
    }

  }
  getSelectedOSLabel() {
    return this.utils.getOSoptionObjectById(this.selectedOSId).label;
  }
  OpenFileInFileManager(filePath: string) {
    this.ipcRenderer.invoke('open-file-in-filemanager', [filePath]);
  }
  setTopOptionsDropdown() {
    if (this.isMultipleInstall) {
      this.menuItemsForInstalledTools = this.optionsForMultipleInstallTypes;
    }
    else {
      this.menuItemsForInstalledTools = this.optionsForNormalInstallTypes;
    }
  }

  goToCLODetails() {
    QLogger.LogInfo(this.logSrc, "Go To  CLO-Details Page For : " + this.app.sharedData.service.tools.selectedTool.productName);
    if(this.app.sharedData.service.tools.loadFromTools){      
      this.app.sharedData.service.tools.loadFromDetails = true;
      this.app.sharedData.service.tools.showCloDetails = true;
      this.app.sharedData.service.tools.showDetails = false;
      if (this.router.url.includes("source")) {
        this.app.location.replaceState('/main/source/clodetails/' + this.app.sharedData.service.tools.selectedTool.productName);
      }
      else {
        this.app.location.replaceState('/main/tools/clodetails/' + this.app.sharedData.service.tools.selectedTool.productName);
      }
    }
    else{
      this.router.navigate(['/main/tools/clodetails', this.app.sharedData.service.tools.selectedTool.productName]);
    }
  }

  //#region ToolsDoc
  documentList: ToolDocumentList = new ToolDocumentList();
  QPMDID: Map<string, string>

  setQPMDID(){
    this.QPMDID = new Map();
    this.QPMDID.set("Qualcomm® eXtensible Diagnostic Monitor 5","252098");
    this.QPMDID.set("Qualcomm Audio Calibration Tool v7.4","252133");
    this.QPMDID.set("Qualcomm® Commercial Analysis Toolkit","48375");
    this.QPMDID.set("Qualcomm® Package Manager 3","252131");
    this.QPMDID.set("Qualcomm® Package Manager","149478");
    this.QPMDID.set("Qualcomm® Product Configuration Assistant Tool","164108");
    
  }

  getDocumentRequest(toolID: string): any{
    let request = {
      facetFields: [
        "resourceSubType"
      ],
      filterFields: [
          {
            field: "featuredForToolIds",
            values: [
                toolID
            ]
          }
      ],
      sourceApplication: "QPM",
      includeChunks: true,
      resourceFields: [
          "title",
          "url",
          "publishedOn",
          "resourceSubType",
          "resourceType",
          "contentType",
          "dcn",
          "revision"
      ],
      searchText: "",
      sortFields: [
        {
          field: "score",
          order: "desc"
        }
      ],
      start: 0,
      rows: 10
    };
    return request;
  }

  getDocuments(){

    if(!this.QPMDID.has(this.selectedProductDetails?.displayName)){
      return;
    }

    let toolID = this.QPMDID.get(this.selectedProductDetails?.displayName);
    let response : Observable<QPMResponse>;
    //this.documentList = new ToolDocumentList;
    let request = this.getDocumentRequest(toolID);
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Get Tools document - Request : " +JSON.stringify(request));
    }
    response = this.webClient.getDocuments(request);
    response.subscribe(
      (data:QPMResponse)=>{
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Tools document - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Tools document - Success");
          this.documentList = JSON.parse(data.getData()) as ToolDocumentList;
          this.documentList.resources = this.documentList.resources?.filter(x => x.url !== 'NA').slice(0,3);
        }
        else
        {          
          QLogger.LogInfo(this.logSrc, "Get Tools document - Failed");
          QLogger.LogInfo(this.logSrc, "Get Tools document - Error : " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  //#endregion
}

