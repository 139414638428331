import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import isElectron from 'is-electron';
import { AppMainComponent } from 'src/app/app.main.component';
import { SharedData } from 'src/app/common/shared-data';
import { Utils } from 'src/app/common/utils';
console.log(isElectron());

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, public sharedData: SharedData,public utils : Utils) { 
    if(isElectron()){
      const electron = window.require('electron');
    }
  }

  ngOnInit(): void {
    if(isElectron())
    {
      if(this.sharedData.qikParams.installParams.length>0)
       this.router.navigate(['/main/tools/find']);
    }
  }

  onOpenSoftware() {
    this.router.navigate(['/main/software']);
  }

  onOpenST() {
    this.router.navigate(['/main/software/servicetask/find']);
  }

  onOpenTools() {
    this.router.navigate(['/main/tools/find']);
  }

  onOpenDocuments() {
    this.router.navigate(['/main/document']);
  }
}
