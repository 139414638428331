import { Injectable } from '@angular/core';
import { DataServiceFactory } from './DataServiceFactory';
import { WebClientService } from '../Contract/WebClientService';
import { CatalogClientService } from '../Contract/CatalogClientService';
import { SoftwareCatalogClientService } from '../Contract/SoftwareCatalogClient';
import { RestWebClientService } from '../RestService/rest-web-client.service';
import { RestSoftwareCatalogClientService } from '../RestService/rest-software-catalog-client.service';
import { DistributionClientService } from '../Contract/DistributionClientService';
import { ClientService } from '../Contract/ClientService';
import { RestDistributionClientService } from '../RestService/rest-distribution-client.service';
import { LoggingService } from '../Contract/LoggingService';
import { TelematicsClientService } from '../Contract/TelematicsClientService';
@Injectable()
export class RestService implements DataServiceFactory{
    constructor(private restWebClientService : RestWebClientService, 
                private restSoftwareCatalogService : RestSoftwareCatalogClientService,
                private restDistributionClientService : RestDistributionClientService){}

    public createClientService(): ClientService {
        return null;
    }

    public createWebClientService(): WebClientService {
        return this.restWebClientService;//new RestWebClient();
    }

    public createCatalogClientService() : CatalogClientService{
        return null;
    }
    
    public createSoftwareCatalogClientService(): SoftwareCatalogClientService {
        return this.restSoftwareCatalogService;
    }
    
    public createDistributionClientService(): DistributionClientService {
        return this.restDistributionClientService;
    }

    public createLoggingService(): LoggingService {
        return null;
    }    
    public createTelematicsClientService(): TelematicsClientService {
        return null;
    }    
}