<div class="ui-g ui-fluid" style="min-height: calc(80vh);">
    <div class="ui-g-12">
      <div class="ui-g-12 ui-lg-3">
        <!-- Left Side -->
        <div class="card card-w-title">
          <h1>CVE Lookup
            <p-progressSpinner *ngIf="loadingResult || loadingSecurityBulletin" [style]="{width: '20px', height: '20px', margin: '-10px 0 0 0', float: 'right'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
          </h1>
          <p-tabView>
            <p-tabPanel header="CVE CR Mapping" leftIcon="ui-icon-assignment">
              <div class="ui-g">
                <div class="ui-g-12">
                  <span class="md-inputfield">
                    <input id="cveNumberInput" pInputText type="text" [(ngModel)]="selectedCVEs" placeholder="Enter CVEs" style="width: 90%;" (input)="onChangeCVEList()" >
                    <i class="ui-icon-cancel" id="clearCrs" (click)="selectedCVEs = undefined" pTooltip="Clear" style="float: right;"></i>
                  </span>
                </div>
                <div class="ui-g-12">
                  <span class="md-inputfield">
                    <p-multiSelect id="securityBulletinList" [options]="securityBulletinsSelectItems" [(ngModel)]="selectedSecurityBulletins" [filter]="true"  defaultLabel="Select Security Bulletins" [style]="{'width':'90%'}"></p-multiSelect>
                    <i class="ui-icon-cancel" id="clearSecurityBulletin" (click)="selectedSecurityBulletins = []" pTooltip="Clear" style="float: right;"></i>
                  </span>
                </div>
                <div class="ui-g-9" *ngIf="isMaxLength">
                  <b style="color:red">Maximum is 20 CVEs</b>
                </div>
                <div class="ui-g-12">
                  <button pButton id="searchCrDetails" type="button" (click)="onSearch($event)" label="Search"
                    [disabled]="selectedCVEs === undefined || isMaxLength" style="float: right;width: 120px;">
                  </button>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
  
      <div class="ui-g-12 ui-lg-9">
        <!-- Right Side -->
        <div class="card card-w-title">
          <div class="ui-g">
            <div class="ui-g-12" style="padding: 0px;">
              <div style="float: left;">
                <span *ngIf="errorMessage !== ''" class="message message-error">
                  {{errorMessage}}
                </span>
              </div>
              <div style="float: right; padding-bottom: 5px;">
                <button pButton id="createSTButton" type="button" (click)="cveLookupAddCrsToNewST()" [disabled]="selectedcveCrMappingCrsToAdd?.length === 0" label="New Service Task" [style]="{'width': '150px', 'margin-top': '20px'}"></button>
                <button pButton id="addToSTButton" type="button" (click)="cveLookupStartAddCrtoServiceTaskProcess()" [disabled]="selectedcveCrMappingCrsToAdd?.length === 0" label="Add to Service Task" [style]="{'width': '180px', 'margin-top': '20px', 'margin-left': '10px'}"></button>
              </div>
            </div>
          <div class="ui-g">
            <div class="ui-g-12" style="padding: 0px;">
            <p-table #cveCrMappingTable [value]="cveCrMapping" [columns]="cveLookupCols" [scrollable]="true" scrollHeight="380px"
                  [(selection)]="selectedcveCrMappingCrsToAdd" selectionMode="multiple" 
                  [globalFilterFields]="['cveNumber','crId','cwe','rating','attacker','allBulletins','area','subsystem','functionality','weaknessType','victim']">
                <ng-template pTemplate="caption">
                  <div class="ui-g">
                    <div class="ui-g-12" style="padding: 0px;">
                      <div style="float: left;">
                        <i class="pi pi-file-excel" id="exportToExcel" pTooltip="Export" (click)="exportToExcel()" style="font-size: 1.7em; margin:4px 4px 0 0"></i>
                      </div>
                      <div style="float: right">
                        <i class="pi pi-search" style="margin:4px 4px 0 0"></i>
                        <input #cveCrMappingGlobalFilter class="filter-clear" type="text" pInputText size="50" placeholder="Filter"
                          (input)="cveCrMappingTable.filterGlobal($event.target.value, 'contains')" style="width: auto;">
                        <i class="ui-icon-cancel" id="clearCrSearchFilter" pTooltip="Clear Filter" (click)="cveCrMappingGlobalFilter.value=''; cveCrMappingTable.filterGlobal('', 'contains')"
                          style="margin:4px 4px 0 0"></i>
                        <!--i class="ui-icon-refresh" id="refreshRelease" pTooltip="Refresh" style="margin:4px 4px 0 0"></i-->
                      </div>
                    </div>
                  </div>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <tr>
                    <th style="width:30px;"><p-tableHeaderCheckbox></p-tableHeaderCheckbox></th>
                    <th *ngFor="let col of columns" id ="{{col.field}}" [pSortableColumn]="col.field"  [ngStyle]="col.style">
                      <span>{{col.header}}</span>
                      <p-sortIcon [field]="col.field"></p-sortIcon>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td style="width:30px;"><p-tableCheckbox [value]="rowData"></p-tableCheckbox></td>
                    <td *ngFor="let col of columns">
                        {{rowData[col.field]}}
                    </td>
                  </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                  <tr>
                    <td>No data found.</td>
                  </tr>
                </ng-template>
            </p-table>
            </div>
          </div>
        </div>        
      </div>
      
      <p-dialog header="Select ServiceTask" [(visible)]="addCrToServiceTaskProcess.displayFormCVE" modal="modal" showEffect="fade" [style]="{width: '80%'}">
        <div class="ui-g">
          <div class="ui-g-12">
            <p-table id ="serviceTaskTable" #serviceTaskTable [value]="addCrToServiceTaskProcess.serviceTasks" [paginator]="true" [rows]="20" dataKey="serviceTaskId" sortField="serviceTaskId" [sortOrder]="-1" reorderableColumns="true" 
                selectionMode="single" [(selection)] = "addCrToServiceTaskProcess.selectedServiceTask"
                [globalFilterFields]="['serviceTaskId','requestedDate','baseBuildSpf','baseBuild','requestSource','requestAppId', 'latestRelease', 'requester', 'companyNameList', 'statusString', 'masterString']">   
                  <ng-template pTemplate="caption">
                    <div *ngIf="errorMessage != ''" style="text-align: left">
                      <p>{{errorMessage}}</p>
                    </div>
                    <div style="text-align: right">
                      <i *ngIf="errorMessage == ''" id="searchFilterST" class="pi pi-search" style="margin:4px 4px 0 0"></i>
                      <input id="serviceTaskGlobalFilter" #serviceTaskGlobalFilter class="filter-clear" *ngIf="errorMessage == ''" type="text" id="filterSt" pInputText size="50" placeholder="Global Filter"
                        (input)="serviceTaskTable.filterGlobal($event.target.value, 'contains')" style="width:auto">
                      <i *ngIf="errorMessage == ''" class="ui-icon-cancel" id="clearFilterST" pTooltip="Clear Filter" (click)="serviceTaskGlobalFilter.value = ''; serviceTaskTable.filterGlobal('', 'contains');"
                        style="margin:4px 4px 0 0"></i>
                      <i class="ui-icon-refresh" id="refreshST" pTooltip="Refresh" (click)="getAllServiceTasks()" style="margin:4px 4px 0 0"></i>
                    </div>
                  </ng-template>
    

                  <ng-template pTemplate="header" let-columns>
                      <tr>
                          <th id="stFinderserviceTaskId" pSortableColumn="serviceTaskId" style="width: 70px;">
                            Service Task ID 
                            <p-sortIcon field="serviceTaskId">
                            </p-sortIcon>
                          </th>
                          <th id="stFinderRequestedDate" pSortableColumn="requestedDate" style="width: 110px;">Requested Date <p-sortIcon field="requestedDate"></p-sortIcon></th>
                          <th id="stFinderBaseBuildSPF" pSortableColumn="baseBuildSpf">Base Build SPF<p-sortIcon field="baseBuildSpf"></p-sortIcon></th>
                          <th id="stFinderBaseBuild" pSortableColumn="baseBuild">Base Build <p-sortIcon field="baseBuild"></p-sortIcon></th>
                          <th id="stFinderRequestSource" pSortableColumn="requestSource">Request Source<p-sortIcon field="requestSource"></p-sortIcon></th>
                          <th id="stFinderExternalId" pSortableColumn="requestAppId">External ID<p-sortIcon field="requestAppId"></p-sortIcon></th>
                          <th id="stFinderLatestRelease" pSortableColumn="latestRelease">Latest<p-sortIcon field="latestRelease"></p-sortIcon></th>
                          <th id="stFinderTotalReleases" pSortableColumn="totalReleases">Releases<p-sortIcon field="totalRelease"></p-sortIcon></th>
                          <th  id="stFinderRequesterId" pSortableColumn="requester" style="width: 120px;">Requester <p-sortIcon field="requester" ></p-sortIcon></th>
                          <th id="stFinderIsMaster" pSortableColumn="masterString">Master <p-sortIcon field="masterString" ></p-sortIcon></th>
                          <th pSortableColumn="companyNameList" *ngIf="app.sharedData.visibility.serviceTaskFinder.customerColumn" style="width: 230px;">Customer(s) <p-sortIcon field="companyNameList"></p-sortIcon></th>
                          <th id="stFinderStatus" pSortableColumn="statusString" style="width: 100px;">Status <p-sortIcon field="statusString"></p-sortIcon></th>
                      </tr>
                      <tr>
                        <th style="width: 70px;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterServiceTaskId pInputText type="text" placeholder="ST#" [(ngModel)]="filterValues.serviceTaskId"
                                    (input)="serviceTaskTable.filter($event.target.value, 'serviceTaskId', 'equals')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.serviceTaskId !== ''" style="min-width: 0;">
                              <i #clearFilterServiceTaskId class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.serviceTaskId = ''; serviceTaskTable.filter('', 'serviceTaskId', 'equals')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 110px;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterRequestedDate pInputText type="text" placeholder="yyyy-mm-dd" [(ngModel)]="filterValues.requestedDate"
                                    (input)="serviceTaskTable.filter($event.target.value, 'requestedDate', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requestedDate !== ''" style="min-width: 0;">
                              <i #clearFilterRequestedDate class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.requestedDate = ''; serviceTaskTable.filter('', 'requestedDate', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterBaseBuildSpf pInputText type="text" placeholder="SPF" [(ngModel)]="filterValues.baseBuildSpf"
                                    (input)="serviceTaskTable.filter($event.target.value, 'baseBuildSpf', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.baseBuildSpf !== ''" style="min-width: 0;">
                              <i #clearFilterBaseBuildSpf class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.baseBuildSpf = ''; serviceTaskTable.filter('', 'baseBuildSpf', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterBaseBuild pInputText type="text" placeholder="Build" [(ngModel)]="filterValues.baseBuild"
                                    (input)="serviceTaskTable.filter($event.target.value, 'baseBuild', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.baseBuild !== ''" style="min-width: 0;">
                              <i #clearFilterBaseBuild class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.baseBuild = ''; serviceTaskTable.filter('', 'baseBuild', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield" style="width: 85%; display: block;">
                                <p-multiSelect  #filterRequestSource [options]="filterOptionReqSource" [(ngModel)]="filterValues.requestSource"
                                    defaultLabel="All" [style]="{'min-width':'0','width':'100%'}"
                                    (onChange)="serviceTaskTable.filter($event.value, 'requestSource', 'in')" >
                                </p-multiSelect >
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requestSource?.length > 0 " style="min-width: 0; border: 0">
                              <i #clearFilterRequestSource class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer"
                                  (click)="filterValues.requestSource = []; serviceTaskTable.filter([], 'requestSource', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterRequestAppId pInputText type="text" placeholder="External ID" [(ngModel)]="filterValues.requestAppId"
                                    (input)="serviceTaskTable.filter($event.target.value, 'requestAppId', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requestAppId !== ''" style="min-width: 0;">
                              <i #clearFilterRequestAppId class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.requestAppId = ''; serviceTaskTable.filter('', 'requestAppId', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterLatestRelease pInputText type="text" placeholder="Build ID" [(ngModel)]="filterValues.latestRelease"
                                    (input)="serviceTaskTable.filter($event.target.value, 'latestRelease', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.latestRelease !== ''" style="min-width: 0;">
                              <i #clearFilterLatestRelease class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.latestRelease = ''; serviceTaskTable.filter('', 'latestRelease', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterTotalReleases pInputText type="text" placeholder="Count" [(ngModel)]="filterValues.totalReleases"
                                    (input)="serviceTaskTable.filter($event.target.value, 'totalReleases', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.totalReleases !== ''" style="min-width: 0;">
                              <i #clearFilterTotalReleases class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.totalReleases = ''; serviceTaskTable.filter('', 'totalReleases', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 120px;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterRequester pInputText type="text" placeholder="Requester ID" [(ngModel)]="filterValues.requester"
                                    (input)="serviceTaskTable.filter($event.target.value, 'requester', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.requester !== ''" style="min-width: 0;">
                              <i #clearFilterRequester class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.requester = ''; serviceTaskTable.filter('', 'requester', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th>
                          <div class="ui-inputgroup">
                            <span class="md-inputfield" style="width: 85%; display: block;">
                                <p-dropdown #filterMaster [options]="filterOptionMaster" [(ngModel)]="filterValues.master" [style]="{'min-width':'0','width':'100%'}"
                                    (onChange)="serviceTaskTable.filter($event.value, 'master', 'equals')" >
                                </p-dropdown>
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.master !== undefined" style="min-width: 0; border: 0">
                              <i #clearFilterMaster class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer"
                                  (click)="filterValues.master = undefined; serviceTaskTable.filter(undefined, 'master', 'equals')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 230px;" *ngIf="app.sharedData.visibility.serviceTaskFinder.customerColumn">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield">
                                <input #filterCustomer pInputText type="text" placeholder="Customer" [(ngModel)]="filterValues.customer"
                                    (input)="serviceTaskTable.filter($event.target.value, 'companyNameList', 'contains')" style="width: 100%;">
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.customer !== ''" style="min-width: 0;">
                              <i #clearFilterCustomer class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer;"
                                  (click)="filterValues.customer = ''; serviceTaskTable.filter('', 'companyNameList', 'contains')">
                              </i>
                            </span>
                          </div>
                        </th>
                        <th style="width: 100px;">
                          <div class="ui-inputgroup">
                            <span class="md-inputfield" style="width: 85%; display: block;">
                                <p-dropdown #filterStatusString [options]="filterOptionStatus" [(ngModel)]="filterValues.statusString" [style]="{'min-width':'0','width':'100%'}"
                                    (onChange)="serviceTaskTable.filter($event.value, 'statusString', 'equals')" >
                                </p-dropdown>
                            </span>
                            <span class="ui-inputgroup-addon" *ngIf="filterValues.statusString !== undefined" style="min-width: 0; border: 0">
                              <i #clearFilterStatusString class="ui-icon-cancel" pTooltip="Clear Filter" style="cursor: pointer"
                                  (click)="filterValues.statusString = undefined; serviceTaskTable.filter(undefined, 'statusString', 'equals')">
                              </i>
                            </span>
                          </div>
                        </th>
                      </tr>
                  </ng-template>

                  <ng-template pTemplate="body" let-rowData let-columns="columns">
                      <tr [pSelectableRow]="rowData">
                          <td id="serviceTaskIdValue">{{rowData.serviceTaskId}}</td>
                          <td id="serviceTaskRequestDateValue">{{rowData.requestedDate}}</td>
                          <td id="serviceTaskBaseBuildSpfValue">{{rowData.baseBuildSpf}}</td>
                          <td id="serviceTaskBaseBuildValue">{{rowData.baseBuild}}</td>
                          <td id="serviceTaskRequestSourceValue">{{rowData.requestSource}}</td>
                          <td id="serviceTaskRequestAppIdValue">{{rowData.requestAppId}}</td>
                          <td id="serviceTaskLatestReleaseValue">
                            <div *ngIf="rowData.latestRelease !== null && rowData.latestRelease !== undefined">
                              {{rowData.latestRelease}}
                              <i class="ui-icon-remove-red-eye" pTooltip="CR Details" (click)="app.viewReleaseCRs(rowData.serviceTaskId, rowData.releaseCRs)"></i>
                            </div>
                          </td>
                          <td id="serviceTaskTotalReleaseValue">{{rowData.totalReleases}}</td>
                          <td id="serviceTaskRequesterValue">{{rowData.requester}}</td>
                          <td id="serviceTaskMasterValue" >{{rowData.master? "Yes" : "No"}}</td>
                          <td id="serviceTaskCustomerValue" *ngIf="app.sharedData.visibility.serviceTaskFinder.customerColumn">
                            <span *ngFor="let cust of rowData.companyNameList" style="width: 100%; display: inline-block">
                              {{cust}}
                            </span>
                          </td>
                          <td id="serviceTaskStatusValue">{{rowData.statusString}}</td>
                      </tr>
                  </ng-template>
              </p-table>
          </div>
        </div>        
        <p-footer>
          <div class="ui-g">
            <div class="ui-g-12" *ngIf="addCrToServiceTaskProcess.errorMessage !== ''" class="message message-error">
              {{addCrToServiceTaskProcess.errorMessage}}
            </div>
          </div>
          <p-progressSpinner *ngIf="addCrToServiceTaskProcess.loadingServiceTasks" 
                              [style]="{width: '20px', height: '20px', margin: '0 0 2px 0'}" strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"></p-progressSpinner>
          <button pButton id="addCrToServiceTaskButton" type="button"
              (click) = "cveAddCrsToST()"
              [disabled] = "addCrToServiceTaskProcess.loadingServiceTasks || addCrToServiceTaskProcess.selectedServiceTask === undefined"
              label="Add CR(s)" [style]="{'width': '150px', 'margin-top': '20px'}">
          </button>
        </p-footer>
      </p-dialog>

    </div>
  </div>