import { Injectable } from '@angular/core'
import { SoftwareProduct, ServiceTaskInfo,  } from '../models/software-catalog-client';
import { ProductDetails, ProductV3, ProductSuiteV3, ProductCatalogV3,ReleaseV3, ReleasesV3, ProductSubSuiteV3, License, CartItem} from '../models/lime-web-client';
import { UserInfo } from '../models/lime-web-client';
import { BehaviorSubject, Observable } from 'rxjs';
import { InstalledPackageDetails } from '../models/catalog-client';
import isElectron from 'is-electron';
import { environment } from 'src/environments/environment';
import { integer } from 'aws-sdk/clients/cloudfront';

export enum commType {
    CrSearch = "CrSearch",
    RefreshTools = "RefreshTools",
    RefreshLicenses = "RefreshLicenses",
    EmailSent = "EmailSent",
    UpdateCartItems="UpdateCartItems"
}
export enum OS {
    Linux,
    Mac,
    Windows,
    NotSupported
}
export enum TargetArch{
    Any,
    ARM64
}
export class OSOption{
     id: number;
     os: selectableOSs;
     targetArch: selectableArchs;
     label: string;
     osDistType?:string;
}
export enum OSDistType {
    Debian = "Debian"
}
export class selectableOSs
{
    public static readonly WINDOWS:string = "Windows";
    public static readonly LINUX:string = "Linux";
    public static readonly MACOS:string  = "MacOS";
}
export class selectableArchs
{
    public static readonly Any:string = "Any";
    public static readonly ARM64:string = "ARM64";
}
export enum RefreshInvokationType{
    OnLogin,
    OnRefreshButtonClick,
    Periodic
}
export const OSsSupportingOtherArchs:string[] = [OS[OS.Windows]];
@Injectable()
export class SharedData {

    public appInfo : {
        version: string,
        isElectronMode: boolean,
        baseAppUrl: string,
        baseWebAppUrl: string,
        logRequest: boolean,
        logResponse: boolean,
        workOffline:boolean,
        isLoggedOut:boolean,
        platform:string,
        logLevel:Number
    }

    public visibility : {
        createServivceTask:{
            createMasterST:boolean,
            subjectLine:boolean
        },
        software:{
            showCustomerFilter: boolean,
            requestServiceTask: boolean,
            addCustomer: boolean,
            addBaseImage: boolean,
            addGerrit: boolean,
            download: boolean,
            showSoftwareImageDownload:boolean
        },
        serviceTask:{
            abandonTask: boolean,
            addCustomer: boolean,
            crDelta:boolean,
            updateOwner: boolean,
            updateExternalId: boolean,
            updateBaseBuild: boolean,
            updateWatcher: boolean,
            updateAutobuild: boolean,
            showCrLink: boolean,
            commentWarning: boolean
        }, 
        serviceTaskFinder:{
            customerColumn: boolean,
            autobuild: boolean
        },        
        serviceTaskTriage: {
            fullCRDetails: boolean,
            actions: boolean,
            actionsImage: boolean,
            actionsGerrit: boolean,
            searchUpdateCR: boolean
            addCR: boolean,
            addImage: boolean,
            addGerrit: boolean,
            triggerValidation: boolean,
            updateBaseImageBuild: boolean
        },
        serviceTaskBuild: {
            triggerSI: boolean,
            triggerBaitBuild: boolean,
            triggerProduct: boolean,
            viewBranch: boolean,
            viewInternalClient: boolean,
            updateInternalClient: boolean,
            addSI: boolean,
            additionalCrInfo: boolean,
            additionalHistoryInfo: boolean,
            buildHistoryIDs: boolean,
            buildHistory: boolean,
            buildHistoryInfo: boolean,
            retryCirrus: boolean,
            recoverSeedBuiild: boolean,
            emailCR: boolean,
            baitJobInfo: boolean,
            autoPropagateCR:boolean,
            localFileUpload:boolean,
            customizeComposition: boolean
        },
        serviceTaskDist:{
            viewJobUrl: boolean,
            viewBuiltBy: boolean,
            viewCRs: boolean,
            viewShippedDistros: boolean,
            shipProduct: boolean,
            impactedFiles: boolean,
            viewRepostButton:boolean
        },
        tools:{
            viewOwnerTeam: boolean,
            viewVisibiltyCheckbox: boolean,
            viewMacOSVisibilityCheckbox: boolean
        },
        main:{
            showInternalHelpJiraCreation: boolean,
            showInternalUserguide: boolean
        },
        license:{
            renewWorkflowForUSuser:boolean
        }
    }

    public userInfo : {
        username: string,
        partyId:number,
        role: string,
        vordelToken: string,
        isDeveloper: boolean,
        isRequestor: boolean,
        isServiceTaskManager: boolean,
        isMasterServiceTaskManager: boolean,
        isMasterServiceTaskManagerBait: boolean,
        isDownloader: boolean,
        isSuperAdmin: boolean,
        isBetaUser: boolean,
        isStCrPropagation: boolean,
        isMasterStCrPropagation: boolean,
        info: UserInfo,
        tokenModifiedTime:string,
        countryCode:string,
        cityCode:string,
        regionCode:string,
        isADMINorEXTL1ADMIN:boolean
    }

    public devFeature : {
        //flags for dev feature
        showSoftwareImageDownload:boolean,
        allowEOL: boolean
        componentBranchDevFR:boolean
    }

    public betaFeature : {
        //flags for dev feature
    }

    public software : {
        selectedProduct : SoftwareProduct,
        seletcedProject : string
    }

    public service : {
        common : {
            selectedServiceTaskID : string,
            serviceTasksDetails: ServiceTaskInfo,
            master: boolean,
            isOwner: boolean,
            abandonInProgress:boolean,
        },
        finder : {
            finderFilter : string,
            filterQuery : string,
            pageQuery : string,
            sortQuery : string,
            filterValues: any,
            tableParam: any
        },
        triage : {
        },
        build : {
            loadBuildHistoryTopCount: number
        },
        distribution : {
        },
        tools : {
            qpm3ProductId : string,
            toolsFilter : string,
            shownTools : any,
            filteredVisibility : string[],
            filteredOSs : string[],
            selectedOSs : string[],
            licenseFilter : string,
            isCatalogInitialized : boolean,
            selectedTool : ProductV3,
            selectedProductInstallDetails : InstalledPackageDetails,
            productCatalog: ProductCatalogV3,
            productCatalogOsMap : Map<string,ProductCatalogV3>,
            productCatalogETagMap: Map<string,Number>,
            productCatalogETagProductMap: Map<string,ProductV3>,
            triggerDeepLinkInstall : boolean,
            triggerDirectLink : string,
            downloadProductInfo : any,
            loadFromTools: boolean,
            loadFromDetails: boolean,
            showCloDetails: boolean,
            showDetails: boolean
        }
    }
    public licenses:{
        common:{
            serialNumber:string,
            licensesGroupID:string,
            selectedLicense:License,
            directLinkForLicensesGroup:boolean,
            directLinkForSerialNumber:boolean,
            itemsAddedToCart:CartItem[],
            clientSecret:string
            toolUserRoles:string[];
            partyId:number;
            vordelUserCountryCode:string;
        }

    }

    public crSearch: {
        foundCrsToAdd: number[];
    }

    public qikParams :{

        installParams:string[];
    }
    public sharedMessage:{
        showMessageBox:boolean;
        headLine:string;
        contents:string[]
    }
    constructor(){

        this.appInfo = {
            version: "3.0.75.1",
            isElectronMode: false,
            baseAppUrl: isElectron()? '' : window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port : ''),
            baseWebAppUrl: environment.baseWebAppUrl,
            logRequest: true,
            logResponse: true,
            workOffline:false,
            isLoggedOut:false,
            platform: isElectron()?process.platform: 'win32',
            logLevel:0
        }
                
        this.userInfo = {
            username: '',
            partyId:null,
            role: '',
            vordelToken: '',
            isDeveloper: false,
            isRequestor: false,
            isServiceTaskManager: false,
            isMasterServiceTaskManager: false,
            isMasterServiceTaskManagerBait:false,
            isDownloader: false,
            isSuperAdmin: false,
            isBetaUser: false,
            isStCrPropagation: false,
            isMasterStCrPropagation: false,
            info: new UserInfo(),
            tokenModifiedTime:'',
            countryCode:'',
            cityCode:'',
            regionCode:'',
            isADMINorEXTL1ADMIN:false
        }

        this.devFeature = {
            showSoftwareImageDownload:false,
            allowEOL: false,
            componentBranchDevFR:false
        }
        this.betaFeature = {
        }
        this.qikParams ={
            installParams:[]
        }
        this.sharedMessage={
            showMessageBox:false,
            headLine:"",
            contents:[]
        }
        this.resetVisibility();
        this.resetData();
    }

    //#region Functions
    resetUserRoles(){
        this.userInfo.isDeveloper = false;
        this.userInfo.isServiceTaskManager = false;
        this.userInfo.isMasterServiceTaskManager = false;
        this.userInfo.isMasterServiceTaskManagerBait = false;
        this.userInfo.isRequestor = false;
        this.userInfo.isSuperAdmin = false;
        this.userInfo.isBetaUser = false;
        this.userInfo.info = {
            uid: '',
            partyId: 0,
            partyNum: 0,
            companyName: '',
            fullName: '',
            roles: [],
            internal: false
        };
        this.userInfo.isADMINorEXTL1ADMIN=false;
    }

    resetData(){
        this.software = {
            selectedProduct : null,
            seletcedProject : ''
        };

        this.service = {
            common : {
                selectedServiceTaskID : '',
                serviceTasksDetails: new ServiceTaskInfo(),
                master: false,
                isOwner: false,
                abandonInProgress:false,
            },
            finder : {
                finderFilter : '',
                filterQuery : '',
                pageQuery : '',
                sortQuery : '',
                filterValues : undefined,
                tableParam: undefined
            },
            triage : {
            },
            build :{
                loadBuildHistoryTopCount: 10
            },
            distribution :{
            },
            tools : {
                qpm3ProductId : "3097f479-8993-11ea-9571-06074a401742",
                toolsFilter : '',
                shownTools : '',
                filteredVisibility : [],
                filteredOSs : [],
                selectedOSs : [],
                licenseFilter : '',
                isCatalogInitialized : false,
                selectedTool : new ProductV3(),
                selectedProductInstallDetails : new InstalledPackageDetails(),
                productCatalog : new ProductCatalogV3(),
                productCatalogOsMap : new Map<string,ProductCatalogV3>(),
                productCatalogETagMap : new Map<string,Number>(),
                productCatalogETagProductMap: new Map<string,ProductV3>(),
                triggerDeepLinkInstall : false,
                triggerDirectLink : '',
                downloadProductInfo : [],
                loadFromTools: false,
                loadFromDetails: false,
                showCloDetails: false,
                showDetails: false
            }
        }
        this.licenses = {
            common : {
                serialNumber:'',
                licensesGroupID:'',
                selectedLicense:undefined,
                directLinkForLicensesGroup:false,
                directLinkForSerialNumber:false,
                itemsAddedToCart:[],
                clientSecret:undefined,
                toolUserRoles:[],
                partyId:null,
                vordelUserCountryCode:null
            }
        }

        this.crSearch = {
            foundCrsToAdd: []
        }
    }
    
    resetVisibility(){
        this.visibility = {
            createServivceTask:{
                createMasterST:false,
                subjectLine:false
            },
            software:{
                showCustomerFilter: false,
                requestServiceTask: false,
                addCustomer: false,
                addBaseImage: false,
                addGerrit: false,
                download: false,
                showSoftwareImageDownload:false
            },
            serviceTask:{
                abandonTask: false,
                addCustomer: false,
                crDelta:false,
                updateOwner: false,
                updateExternalId: false,
                updateBaseBuild: false,
                updateWatcher: false,
                showCrLink: false,
                commentWarning: false,
                updateAutobuild: false
                
            }, 
            serviceTaskFinder:{
                customerColumn: false,
                autobuild: false
            }, 
            serviceTaskTriage: {
                fullCRDetails: false,
                actions: false,
                actionsImage: false,
                actionsGerrit: false,
                searchUpdateCR: false,
                addCR: false,
                addImage: false,
                addGerrit: false,
                triggerValidation: false,
                updateBaseImageBuild: false
            },
            serviceTaskBuild: {
                triggerSI: false,
                triggerBaitBuild: false,
                triggerProduct: false,
                viewBranch: false,
                viewInternalClient: false,
                updateInternalClient: false,
                addSI: false,
                additionalCrInfo: false,
                additionalHistoryInfo: false,
                buildHistoryIDs: false,
                buildHistory: false,
                buildHistoryInfo: false,
                retryCirrus: false,
                recoverSeedBuiild: false,
                emailCR: false,
                baitJobInfo: false,
                autoPropagateCR:false,
                localFileUpload:false,
                customizeComposition: false
            },
            serviceTaskDist:{
                viewJobUrl:  false,
                viewBuiltBy: false,
                viewCRs:  false,
                viewShippedDistros: false,
                shipProduct: false,
                impactedFiles: false,
                viewRepostButton:false
            },
            tools:{
                viewOwnerTeam: false,
                viewVisibiltyCheckbox: false,
                viewMacOSVisibilityCheckbox: false
            },
            main:{
                showInternalHelpJiraCreation: false,
                showInternalUserguide: false
            },
            license:{
                renewWorkflowForUSuser:false
            }
        }
    }    

    setVisibility(){
        if(this.userInfo.info.internal){
            //Software
            this.visibility.software.showCustomerFilter = true;
            this.visibility.software.requestServiceTask = true;
            this.visibility.software.addCustomer = true;
            this.visibility.software.addBaseImage = true;
            this.visibility.software.addGerrit = true;
            this.visibility.software.showSoftwareImageDownload = this.devFeature.showSoftwareImageDownload;
            //ST
            this.visibility.serviceTask.showCrLink = true;
            this.visibility.serviceTask.updateWatcher = true;
            this.visibility.serviceTask.commentWarning = true;
            this.visibility.serviceTask.crDelta=true;
            // ST Triage
            this.visibility.serviceTaskTriage.fullCRDetails = true;
            this.visibility.serviceTaskTriage.triggerValidation = true;  
            // ST Finder
            this.visibility.serviceTaskFinder.customerColumn = true;
            this.visibility.serviceTaskFinder.autobuild = true;
            // ST Build
            this.visibility.serviceTaskBuild.viewBranch = true;
            this.visibility.serviceTaskBuild.viewInternalClient = true;
            this.visibility.serviceTaskBuild.additionalCrInfo = true;
            this.visibility.serviceTaskBuild.additionalHistoryInfo = true;
            this.visibility.serviceTaskBuild.buildHistoryIDs = true;
            this.visibility.serviceTaskBuild.buildHistory = true;
            this.visibility.serviceTaskBuild.buildHistoryInfo = true;
            this.visibility.serviceTaskBuild.emailCR = true;
            this.visibility.serviceTaskBuild.baitJobInfo = true;
            this.visibility.serviceTaskBuild.addSI = true;
            this.visibility.serviceTaskBuild.localFileUpload = true;
            //ST Dist
            this.visibility.serviceTaskDist.viewJobUrl = true;
            this.visibility.serviceTaskDist.viewBuiltBy = true;
            this.visibility.serviceTaskDist.viewCRs = true;
            this.visibility.serviceTaskDist.viewShippedDistros = true;
            this.visibility.serviceTaskDist.impactedFiles = true;
            this.visibility.serviceTaskDist.viewRepostButton = true;
            //Tools
            this.visibility.tools.viewOwnerTeam = true;
            this.visibility.tools.viewVisibiltyCheckbox = true;
            this.visibility.tools.viewMacOSVisibilityCheckbox = true;
            //Main
            this.visibility.main.showInternalHelpJiraCreation = true;
            this.visibility.main.showInternalUserguide = true;
        }
        if((this.userInfo.info.internal && !this.service.common.master)
            || (this.userInfo.info.internal && this.service.common.master && this.userInfo.isMasterServiceTaskManager)){
            //ST
            this.visibility.serviceTask.abandonTask = true;
            this.visibility.serviceTask.addCustomer = true;
            this.visibility.serviceTask.updateOwner = true;
            this.visibility.serviceTask.updateExternalId = true;
            this.visibility.serviceTask.updateBaseBuild = true;
            this.visibility.serviceTask.updateAutobuild = true;
            // ST Triage
            this.visibility.serviceTaskTriage.actions = true;
            this.visibility.serviceTaskTriage.actionsImage = true;
            this.visibility.serviceTaskTriage.actionsGerrit = true;
            this.visibility.serviceTaskTriage.searchUpdateCR = true;
            this.visibility.serviceTaskTriage.addCR = true;
            this.visibility.serviceTaskTriage.addImage = true;
            this.visibility.serviceTaskTriage.addGerrit = true;
            // ST Build
            this.visibility.serviceTaskBuild.triggerSI = true;
            this.visibility.serviceTaskBuild.triggerProduct = true;
            this.visibility.serviceTaskBuild.updateInternalClient = true;
            this.visibility.serviceTaskBuild.retryCirrus = true;
            this.visibility.serviceTaskBuild.recoverSeedBuiild = true;
            //this.visibility.serviceTaskBuild.autoPropagateCR = true;
            //ST Dist
            this.visibility.serviceTaskDist.viewJobUrl = true;
            this.visibility.serviceTaskDist.viewBuiltBy = true;  
            this.visibility.serviceTaskDist.viewCRs = true;
            this.visibility.serviceTaskDist.shipProduct = true;
            this.visibility.serviceTaskDist.viewShippedDistros = true;
        }
        if((this.userInfo.info.internal && !this.service.common.master)
            || (this.userInfo.info.internal && this.service.common.master && this.userInfo.isMasterServiceTaskManagerBait))
        {
            this.visibility.serviceTaskBuild.triggerBaitBuild = true;
            //ST Triage
            this.visibility.serviceTaskTriage.actionsGerrit = true;
        }
        if((this.userInfo.isStCrPropagation && !this.service.common.master)
            || (this.userInfo.isMasterStCrPropagation && this.service.common.master)){
            // ST Build
            this.visibility.serviceTaskBuild.autoPropagateCR = true;
        }
        if(this.userInfo.isMasterServiceTaskManager){
            this.visibility.serviceTaskTriage.updateBaseImageBuild = true;
            this.visibility.serviceTaskBuild.customizeComposition = true;
            this.visibility.createServivceTask.createMasterST=true;
            this.visibility.createServivceTask.subjectLine=true;
        }
        if(this.userInfo.isDownloader){
            //Software
            this.visibility.software.download = true;
        }
        if(this.userInfo.isRequestor){
            //Software
            this.visibility.software.requestServiceTask = true;
            this.visibility.serviceTaskTriage.addCR = true;
            this.visibility.serviceTaskTriage.addGerrit = true;
        }
        if(this.service.common.isOwner){
            //ST
            this.visibility.serviceTask.abandonTask = true;
        }

        // Tools MacOS checkbox is only available to select customer
        if (this.userInfo.info.partyId === 118305)
        {
            this.visibility.tools.viewMacOSVisibilityCheckbox = true;
        }
        if(this.userInfo.isSuperAdmin){

        }
        /*Note : For Desktop App CountryCode comes from Vordel.
                 For Web Portal  CountryCode comes from Siteminder.*/
        if(this.appInfo.isElectronMode){
            if(!environment.production || this.licenses.common.vordelUserCountryCode==='US'){
                this.visibility.license.renewWorkflowForUSuser=true;
            }
        }else{
            if(!environment.production || this.userInfo.countryCode==='US'){
                this.visibility.license.renewWorkflowForUSuser=true;
            }
        }
        this.updateVisibilitySubcribers();
    }

    enableDevFeature(){
        //Control devFeature visibilty here
        this.devFeature.showSoftwareImageDownload = true;
        this.devFeature.allowEOL = true;
        this.devFeature.componentBranchDevFR=false;//disabled for stage and prod both
    }
    enableBetaFeature(){
        //Control betaFeature visibilty here
    }
    //#endregion

    //#region Subcribers
    public visibilitySubscribers: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();
    
    subscribeVisibility(component: string): Observable<boolean>{
        if(this.visibilitySubscribers.has(component)){
            return this.visibilitySubscribers.get(component).asObservable();
        }

        var observable = new BehaviorSubject<boolean>(false);
        this.visibilitySubscribers.set(component, observable);
        return observable.asObservable();
    }

    unsubscribeVisibility(component: string){
        this.visibilitySubscribers.delete(component);
    }

    updateVisibilitySubcribers(){
        this.visibilitySubscribers.forEach((s)=>{
            s.next(true);
        });
    }

    
    public loginSubscribers: Map<string, BehaviorSubject<boolean>> = new Map<string, BehaviorSubject<boolean>>();
    
    subscribeLogin(component: string): Observable<boolean>{
        if(this.loginSubscribers.has(component)){
            return this.loginSubscribers.get(component).asObservable();
        }

        var observable = new BehaviorSubject<boolean>(false);
        this.loginSubscribers.set(component, observable);
        return observable.asObservable();
    }

    unsubscribeLogin(component: string){
        this.loginSubscribers.delete(component);
    }

    updateLoginSubcribers(){
        this.loginSubscribers.forEach((s)=>{
            s.next(true);
        });
    }
    
    public communicationSubscribers: Map<string, Map<string, BehaviorSubject<any>>> = new Map<string,Map<string, BehaviorSubject<any>>>();
    
    subscribeCommunication(key: string, component: string): Observable<any>{
        if(this.communicationSubscribers.has(key)){
            if(this.communicationSubscribers.get(key).has(component)){
                return this.communicationSubscribers.get(key).get(component).asObservable();
            }
        }
        else{
            this.communicationSubscribers.set(key, new Map<string, BehaviorSubject<any>>());
        }

        var observable = new BehaviorSubject<any>(undefined);
        this.communicationSubscribers.get(key).set(component, observable);
        return observable.asObservable();
    }

    unsubscribeCommunication(key: string, component: string){
        if(this.communicationSubscribers.has(key)){
            if(this.communicationSubscribers.get(key).has(component)){
                this.communicationSubscribers.get(key).delete(component);
            }
        }
    }

    updateCommunicationSubcribers(key: string, data: any){
        if(this.communicationSubscribers.has(key)){
            this.communicationSubscribers.get(key).forEach((s)=>{
                s.next(data);
            });
        }
    }
    //#endregion
  }