<div class="ui-g" style="min-height: calc(80vh);">
    <div class="card ui-g-12">
        <div class="ui-g-3" (click)="onOpenSoftware()" style="padding:100px 25px 150px 25px; cursor: pointer;">
            <img src="assets/images/leftNav_SW_big_blue.png" style="width:32px; height:32px;">
            <h1>Software</h1> 
            <h3 style="color: #85898B;font-weight: normal;">Enbles users to request or download software, request or download software diffs, manage downloaded software within a workspace and request a new service task.</h3>           
        </div>

        <div class="ui-g-3" (click)="onOpenST()" style="padding:100px 25px 150px 25px; cursor: pointer;">
            <img src="assets/images/leftNav_task_big_blue.png" style="width:32px; height:32px;">
            <h1>Service Tasks</h1>
            <h3 style="color: #85898B;font-weight: normal;">Service tasks are initiated to create a customized software distribution. Allows users to request a new service task, and find or manage existing service task.</h3>           
        </div> 

        <div class="ui-g-3" (click)="onOpenTools()" style="padding:100px 25px 150px 25px; cursor: pointer;">
            <img src="assets/images/leftNav_tool_big_blue.png" style="width:32px; height:32px;">
            <h1>Tools</h1>
            <h3 style="color: #85898B;font-weight: normal;">{{utils.getOSInfoByUserAgent()!=='Windows'?'[Beta]':''}} Discover the tools available from Qualcomm. Enables user to manage installation, upgrade and licensing of tools.</h3>           
        </div>

        <div class="ui-g-3" (click)="onOpenDocuments()" style="padding:100px 25px 150px 25px; cursor: pointer;">
            <img src="assets/images/leftNav_doc_big_blue.png" style="width:32px; height:32px;">
            <h1>Documents</h1>
            <h3 style="color: #85898B;font-weight: normal;">[Coming soon] Click here to access documents.</h3>   
        </div>

    </div>
</div>