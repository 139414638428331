import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { OverlayPanel, SelectItem, TreeNode } from 'primeng/primeng';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppMainComponent } from 'src/app/app.main.component';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { QLogger } from 'src/app/common/logger';
import { SoftwareData } from 'src/app/common/sample-data';
import { commType } from 'src/app/common/shared-data';
import { Utils } from 'src/app/common/utils';
import { shipStatusErrorResponse, SoftwareDistroLocal, SoftwareProductBuildLocal, SoftwareProductDownload, SoftwareProductLocal, SoftwareProductMetaDataLocal } from 'src/app/models/distribution-client';
import { AvaliableDiffBuildsResponse, DiffDistributionRequest, diffShipDownloadResponse, DistributionRequest, imageShipDownloadResponse, shipDownloadResponse, SoftwareDiffBuildRelease, SoftwareImageDistroInfo, SoftwareProductDistroInfo } from 'src/app/models/lime-web-client';
import { QPMResponse } from 'src/app/models/response';
import { Customer, CustomerProject, SoftwareProduct, SoftwareProductDistro, SoftwareProductFamily, SoftwareProductRelease } from 'src/app/models/software-catalog-client';
import { CatalogClientService } from 'src/app/service/Contract/CatalogClientService';
import { DistributionClientService } from 'src/app/service/Contract/DistributionClientService';
import { SoftwareCatalogClientService } from 'src/app/service/Contract/SoftwareCatalogClient';
import { WebClientService } from 'src/app/service/Contract/WebClientService';
import { ApiType, DataServiceProducer } from 'src/app/service/Factory/DataServiceProducer';

@Component({
  selector: 'app-software',
  templateUrl: './software.component.html',
  styleUrls: ['./software.component.css']
})
export class SoftwareComponent implements OnInit {

  private logSrc:string = "Software-Component";
  private subscriptionSrc:string = "Software-Component";

  @ViewChild('versionsTable') versionsTable;
  @ViewChild('versionGlobalFilter') versionGlobalFilter;

  //private myObservable;
  private catalogClient: CatalogClientService;
  private softwareCatalogClient: SoftwareCatalogClientService;
  private webClient: WebClientService;
  private distributionClient: DistributionClientService;
  subscription: Subscription;
  private worker: any;
  private visibiltySubcription: Observable<boolean>;  
  private crSearchSubcription: Observable<boolean>;
  //value: boolean;

  customers: Customer[];
  customersSelectItems: SelectItem[];
  selectedCustomer: Customer;
  loadingCustomers: boolean;

  products: SoftwareProduct[];
  productsSelectItems: SelectItem[];
  selectedProduct: SoftwareProduct;
  productsMap: Map<string, SoftwareProduct>;
  productsRegularMap: Map<string, SoftwareProduct>;
  loadingProduct: boolean;

  productFamily: SoftwareProductFamily[];
  productFamilySelectItems: SelectItem[];
  selectedProductFamily: SoftwareProductFamily;
  productFamilyMap: Map<string, SoftwareProductFamily>;
  productFamilySpMap: Map<string, string[]>;
  spProductFamilyMap: Map<string, string[]>;
  loadingProductFamily: boolean;

  productDistros: SoftwareProductDistro[];

  projects: CustomerProject[];
  projectsSelectItems: SelectItem[];
  selectedProject: CustomerProject;
  projectsMap: Map<String, CustomerProject>;  
  projectsProductsMap: Map<String, String[]>;  
  spProducProjectsMap: Map<string, string[]>;
  projectsSpfMap: Map<String, string[]>;  
  spfProjectsMap: Map<string, string[]>;
  loadingProject: boolean;

  softwareProductReleases: SoftwareProductRelease[];
  branchesSelectedItems: SelectItem[];
  selectedBranch: SoftwareProductRelease;
  loadingReleases: boolean;

  downloadedProducts: SoftwareProductLocal[];
  downloadedBuilds: Map<String, SoftwareProductBuildLocal>;
  downloadedProductsTreeNodes: TreeNode[];


  selectedCustomerName: string;
  selectedProductName: string;
  selectedProductFamilyName: string;
  selectedProjectName: string;
  selectedBranchName: string;
  hoveredDistros: SoftwareDistroLocal[];

  cols: any[];
  versions: any[];
  downloadedSoftware: any[];
  version: string;

  shipProcess: {
    distroTypes: SoftwareProductDistro[],
    distroSelected: SoftwareProductDistro[],
    softwareBuildSelected: SoftwareProductRelease,
    displayDistro: boolean;
    loadingDistros:boolean;
  };
  downloadProcess: {
    distroTypes: SoftwareProductDistro[],
    distroTypesSelectItems: SelectItem[],
    distroSelected: SoftwareProductDistro[],
    softwareBuildSelected: SoftwareProductRelease,
    displayDistro: boolean,
    setCredentialFailed:boolean,
    loadingDistros:boolean,
    shipFailed:boolean;
  };
  diffDownloadProcess: {
    softwareBuildSelected: SoftwareProductRelease,
    baseBuildSelected: SoftwareProductRelease,
    setCredentialFailed:boolean,
    displayBuild:boolean;
    availableBaseBuilds:SoftwareProductRelease[],
    loadingBaseBuilds: boolean,
    shipFailed:boolean;
  };
  diffShipProcess: {
    distroTypes: SoftwareProductDistro[],
    distroSelected: SoftwareProductDistro[],
    softwareBuildSelected: SoftwareProductRelease,
    baseBuildSelected: SoftwareProductRelease,
    displayDistro: boolean,
    displayBuild:boolean,
    availableBaseBuilds:SoftwareProductRelease[],
    loadingDistros: boolean

  };
  deleteProcess: {
    distroTypes: any[],
    distroSelected: SoftwareDistroLocal,
    softwareBuildSelected: SoftwareProductRelease,
    displayDistro: boolean,
  };
  
  displayPreview: boolean;

  dryRunBuilds: SoftwareProductDownload;
  worker_process;
  downloadWorker:any;
  crSearchSoftwareBuild: SoftwareProductRelease;

  availableDiffBuilds:SoftwareDiffBuildRelease[];
  constructor(private router: Router, public app: AppMainComponent, private utils: Utils,
               private service: DataServiceProducer, private breadcrumbService: BreadcrumbService,public zone: NgZone) {

    QLogger.LogInfo(this.logSrc, "Software Component Creation");

    this.breadcrumbService.setItems([
      { label: 'Software/Available', routerLink: ['/main/software'] }
    ]);

    this.catalogClient = service.getServiceInstance(ApiType.CatalogClient) as CatalogClientService;
    this.softwareCatalogClient = service.getServiceInstance(ApiType.SoftwareCatalogClient) as SoftwareCatalogClientService;
    this.webClient = service.getServiceInstance(ApiType.WebClient) as WebClientService;
    this.distributionClient = service.getServiceInstance(ApiType.DistributionClient) as DistributionClientService;
  }

  ngOnDestroy() {
    this.app.sharedData.unsubscribeVisibility(this.subscriptionSrc);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.app.sharedData.unsubscribeCommunication(commType.CrSearch, this.subscriptionSrc);
  }

  ngOnInit(): void {
    QLogger.LogInfo(this.logSrc, "Software Component Initialization");
    QLogger.LogInfo(this.logSrc,"App Launched v" + this.app.sharedData.appInfo.version);
    this.shipProcess = {
      distroTypes: [],
      distroSelected: [],
      softwareBuildSelected: new SoftwareProductRelease(),
      displayDistro: false,
      loadingDistros:false
    };
    this.downloadProcess = {
      distroTypes: [],
      distroTypesSelectItems: [],
      displayDistro: false,
      distroSelected: [],
      softwareBuildSelected: new SoftwareProductRelease(),
      setCredentialFailed:false,
      loadingDistros: false,
      shipFailed:false

    }
    this.diffDownloadProcess = {
      softwareBuildSelected: new SoftwareProductRelease(),
      setCredentialFailed:false,
      availableBaseBuilds:[],
      displayBuild:false,
      baseBuildSelected:new SoftwareProductRelease(),
      loadingBaseBuilds: false,
      shipFailed:false

    }
    this.diffShipProcess = {
      distroTypes: [],
      displayDistro: false,
      distroSelected: [],
      softwareBuildSelected: new SoftwareProductRelease(),
      availableBaseBuilds:[],
      baseBuildSelected:new SoftwareProductRelease(),
      displayBuild:false,
      loadingDistros: false

    }
    this.deleteProcess = {
      distroTypes: [],
      displayDistro: false,
      distroSelected: new SoftwareDistroLocal(),
      softwareBuildSelected: new SoftwareProductRelease()
    }

    this.selectedProductName = '';

    this.selectedProductFamilyName = '';
    //this.productsSelectItems = [];
    this.selectedCustomerName = '';
    this.selectedProjectName = '';
    this.downloadedProductsTreeNodes = [];

    this.projectsMap = new Map<String, CustomerProject>(); 
    this.projectsProductsMap = new Map<string, String[]>();
    this.spProducProjectsMap = new Map<string, string[]>();
    this.projectsSpfMap = new Map<string, string[]>();
    this.spfProjectsMap = new Map<string, string[]>();

    //this.app.terminalVisible = false;

    this.cols = [];
    this.setReleaseTableColumn(false);
    if(this.app.sharedData.appInfo.isElectronMode){
      this.getDownloadedProducts();
    }
  }

  ngAfterViewInit() {
    this.visibiltySubcription = this.app.sharedData.subscribeVisibility(this.subscriptionSrc);
    this.visibiltySubcription.subscribe((updated)=>{
      if(updated){
        this.loadInitData();
      }
    });
    this.loadInitData();
    this.restoreState();
    this.initSubscribes();     
  }

  setReleaseTableColumn(spf: boolean){
    this.cols = [];
    if(spf){
      this.cols.push({ field: 'spfTag', header: 'SPF Tag', isFilterable: true });
    }
    this.cols.push({ field: 'tag', header: 'SP Tag', isFilterable: true });
    this.cols.push({ field: 'softwareProductBuild', header: 'SP Build', isFilterable: true });
    this.cols.push({ field: 'branch', header: 'Branch', isFilterable: true});
    this.cols.push({ field: 'shipDate', header: 'Release Date', isFilterable: true });
    if (this.app.sharedData.appInfo.isElectronMode) {
      this.cols.push({ field: 'distroDownloadsNames', header: 'Downloaded Distros', isFilterable: true });
    }
    this.cols.push({ field: 'action', header: 'Action', style:{'width': '210px'}});
  }
  //#region Events
  onCustomerChange() {
    if(this.selectedCustomerName && (!this.selectedCustomer ||this.selectedCustomerName==this.selectedCustomer?.name)) return;
    if(this.selectedCustomer === undefined) return;
    this.selectedCustomerName = this.selectedCustomer.name;
    this.onProjectClear();
    this.onProductFamilyClear();
    this.onProductClear();
    this.getAvailableProjects(this.selectedCustomer.partyNum);
    this.getAvailableProductFamily();
    this.getAvailableProducts();
  }

  onCustomerClear() {
    this.selectedCustomer = undefined;
    this.selectedCustomerName = '';
    this.onProjectClear();
    this.onProductFamilyClear();
    this.onProductClear();
    this.projects = [];
    this.projectsSelectItems = [];
    this.getAvailableProductFamily();
    this.getAvailableProducts();
  }

  onProductChange() {
    if(this.selectedProductName && (!this.selectedProduct ||this.selectedProductName==this.selectedProduct?.softwareProduct)) return;
    if(this.selectedProduct === undefined) return;
    this.selectedProductName = this.selectedProduct.softwareProduct;
    this.app.sharedData.software.selectedProduct = this.selectedProduct;
    this.downloadProcess.distroSelected = [];
    this.filterProject();
    this.filterProductFamily();
    this.getAvailableDistros(this.selectedProduct.softwareProduct);
    this.getAvailableReleaseData(this.selectedProduct.softwareProduct);
    this.setReleaseTableColumn(this.selectedProduct.softwareProductFamily);
  }

  onProductClear() {
    this.selectedProduct = undefined;
    this.selectedProductName = '';
    this.app.sharedData.software.selectedProduct = null;
    this.softwareProductReleases = [];
    this.versions = [];
    this.downloadProcess.distroTypes = [];
    this.filterProject();
    this.filterProductFamily();
  }

  onProjectChange() {
   if(this.selectedProjectName && (!this.selectedProject ||this.selectedProjectName==this.selectedProject?.customerProject)) return;
    if(this.selectedProject === undefined) return;
    this.selectedProjectName = this.selectedProject.customerProject;
    this.filterProductFamily();
    this.filterProduct();
  }
  
  onProjectClear() {
    this.selectedProject = undefined;
    this.selectedProjectName = '';
    this.filterProductFamily();
    this.filterProduct();
  }
  
  onProductFamilyChange() {
    if(this.selectedProductFamilyName && (!this.selectedProductFamily ||this.selectedProductFamilyName==this.selectedProductFamily?.softwareProductFamily)) return;
    if(this.selectedProductFamily === undefined) return;
    this.selectedProductFamilyName = this.selectedProductFamily.softwareProductFamily;
    this.filterProduct();
  }
  
  onProductFamilyClear() {
    this.selectedProductFamily = undefined;
    this.selectedProductFamilyName = '';
    this.filterProduct();
  }

  onOpenFolderClick(softwareProductBuild: SoftwareProductRelease) {
    if(softwareProductBuild.distroDownloads.length > 0){
      let availableSoftwareProductMetadata: SoftwareProductMetaDataLocal;
      let response : Observable<QPMResponse>;
      response = this.distributionClient.getDownloadedSoftwareBuildDetail(softwareProductBuild.softwareProductBuild, softwareProductBuild.distroDownloads[0].distro.split('|').join('_'));
      response.subscribe(
        (data: QPMResponse) => {
          if (this.app.sharedData.appInfo.logResponse) {
            QLogger.LogInfo(this.logSrc, "Get Downloaded Software Details Response: " + JSON.stringify(data));
          }
          if (data.isSuccess()){
            QLogger.LogInfo(this.logSrc, "Get Downloaded Software Details Success:" + softwareProductBuild.softwareProductBuild);
            availableSoftwareProductMetadata = JSON.parse(data.getData());
            if(availableSoftwareProductMetadata !== undefined || availableSoftwareProductMetadata !== null){
              let delimeter ="";
              if(this.app.sharedData.appInfo.platform=='win32')
              delimeter= "\\";
              else
              delimeter="/";
              let pathSplit = availableSoftwareProductMetadata.downloadLocation.split(delimeter);
              pathSplit.pop();
              this.app.onOpenFolderClick(pathSplit.join(delimeter));
            }
          }
        }
      );
    }
  }

  onShipActionClick(softwareProductBuild: SoftwareProductRelease) {
    this.shipProcess.distroSelected = [];
    this.getDistrosShipStatus(softwareProductBuild);
    this.shipProcess.softwareBuildSelected = softwareProductBuild;
    this.shipProcess.displayDistro = true;
    //this.shipProcess.distroTypes = this.productDistros;
  }

  onShipButtonClick(event) {
    this.shipSoftwareProductBuild(this.shipProcess.softwareBuildSelected, this.shipProcess.distroSelected);
  }

  onDownloadActionClick(softwareProductBuild: SoftwareProductRelease) {
    if(this.app.downloadQueue.downloadInProgress){
      this.app.showMessageBox("Download", "Download request failed. Currently another download in-progress", "Ok");
      return;
    }
    this.downloadProcess.distroSelected = [];
    this.getDistrosShipStatus(softwareProductBuild);
    this.downloadProcess.softwareBuildSelected = softwareProductBuild;
    this.downloadProcess.displayDistro = true;
  }

  onDownloadButtonClick(event) {
    this.downloadProcess.displayDistro = false;
    let response: Observable<QPMResponse>;
    let defaultDownloadPath:string;
    let downloadPath:string;
    downloadPath ="";
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      const { dialog } = window.require('electron').remote;
      response = this.distributionClient.getDefaultMetaDownloadLocation();
      response.subscribe(
        (data: QPMResponse) => {
          defaultDownloadPath = data.getData();;
          var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",defaultPath :defaultDownloadPath,properties: ['openDirectory'] }); //Select Download Path
          if(!downloadPathList)
          {
            downloadPath = "";
          }
          else
          {
            downloadPath = downloadPathList[0];
          }
          this.downloadSoftwareDistros(this.downloadProcess.softwareBuildSelected, this.downloadProcess.distroSelected,downloadPath);
        });
    }
    else
    {
         this.downloadSoftwareDistros(this.downloadProcess.softwareBuildSelected, this.downloadProcess.distroSelected,"");
    }
   
  }
  retryFailedUploads(distro: SoftwareProductDistro){
    QLogger.LogInfo(this.logSrc,"Reposting Ship request for "+distro.softwareDistro +" and build "+this.downloadProcess.softwareBuildSelected.softwareProductBuild);
    this.downloadProcess.displayDistro = false;
    let distroList:SoftwareProductDistro[] = [];
    distroList.push(distro);
    this.shipSoftwareProductBuild(this.downloadProcess.softwareBuildSelected,distroList);
  }
  repostDiffShipRequest(softwareProductBuild: SoftwareProductRelease)
  {
    QLogger.LogInfo(this.logSrc,"Reposting Diff SHip request for "+this.diffDownloadProcess.softwareBuildSelected.softwareProductBuild+" and "+softwareProductBuild.softwareProductBuild+" for distro "+softwareProductBuild.distroAvailable.softwareDistro);
    this.diffDownloadProcess.displayBuild = false;
    let distroList:SoftwareProductDistro[] = [];
    distroList.push(softwareProductBuild.distroAvailable);
    this.app.diffShipSoftwareProductBuild(softwareProductBuild,this.diffDownloadProcess.softwareBuildSelected,distroList,0);
    this.diffShipProcess.distroSelected = [];
    this.diffShipProcess.displayDistro = false;

  }
  onDiffDownloadActionClick(softwareProductBuild: SoftwareProductRelease) {
    if(this.app.downloadQueue.downloadInProgress){
      this.app.showMessageBox("Download", "Download request failed. Currently another download in-progress", "Ok");
      return;
    }
    this.diffDownloadProcess.availableBaseBuilds = [];
    this.diffDownloadProcess.baseBuildSelected = undefined;
    this.diffDownloadProcess.loadingBaseBuilds = false;
    this.updateAvailableDeltaBuilds(softwareProductBuild)
    this.diffDownloadProcess.softwareBuildSelected = softwareProductBuild;
    this.diffDownloadProcess.displayBuild = true;
  }
  onDiffDownloadButtonClick(event){
    this.diffDownloadProcess.displayBuild = false;
    let downloadPath:string;
    downloadPath ="";
    let defaultDownloadPath:string;
    let response: Observable<QPMResponse>;
    if(this.app.sharedData.appInfo.isElectronMode)
    {
      const { dialog } = window.require('electron').remote;
      response = this.distributionClient.getDefaultDownloadLocation();
      response.subscribe(
        (data: QPMResponse) => {
          defaultDownloadPath = data.getData();;
          var downloadPathList = dialog.showOpenDialogSync({ title:"Select Download Location",defaultPath :defaultDownloadPath,properties: ['openDirectory'] }); //Select Download Path
          if(!downloadPathList)
          {
            downloadPath = "";
          }
          else
          {
            downloadPath = downloadPathList[0];
          }
          this.app.diffDownloadSoftwareDistros(this.diffDownloadProcess.softwareBuildSelected, this.diffDownloadProcess.baseBuildSelected,this.diffDownloadProcess.baseBuildSelected.distroAvailable,downloadPath);
        });
    }
    else
    {
        this.app.diffDownloadSoftwareDistros(this.diffDownloadProcess.softwareBuildSelected, this.diffDownloadProcess.baseBuildSelected,this.diffDownloadProcess.baseBuildSelected.distroAvailable,"");
    }

  }

  onDiffShipActionClick(softwareProductBuild: SoftwareProductRelease) {
    this.diffShipProcess.softwareBuildSelected = softwareProductBuild;
    this.diffShipProcess.availableBaseBuilds = [];
    this.diffShipProcess.baseBuildSelected = undefined;
    let  baseBuildShipDate     = new Date(softwareProductBuild.shipDate);
    this.softwareProductReleases.forEach((availableBuild) =>{

      if(availableBuild.softwareProductBuild!=softwareProductBuild.softwareProductBuild)
      { 
        let  targetBuildShipDate     = new Date(availableBuild.shipDate);
        if(baseBuildShipDate.getTime() >targetBuildShipDate.getTime())
        {
          this.diffShipProcess.availableBaseBuilds.push(availableBuild);
        }
      }
    }); 
    if(this.diffShipProcess.availableBaseBuilds.length === 1){
      this.diffShipProcess.baseBuildSelected = this.diffShipProcess.availableBaseBuilds[0];
    }
    this.diffShipProcess.displayBuild = true;
  }
  onSelectDistroButtonClick(event){
    this.diffShipProcess.distroSelected = [];
    this.getDiffDistrosShipStatus(this.diffShipProcess.softwareBuildSelected,this.diffShipProcess.baseBuildSelected);
    this.diffShipProcess.displayBuild = false;
    this.diffShipProcess.displayDistro = true;

  }

  onDiffShipButtonClick(event){
    if(this.diffShipProcess.softwareBuildSelected.serviceTaskId === undefined || this.diffShipProcess.softwareBuildSelected.serviceTaskId === null)
       this.diffShipProcess.softwareBuildSelected.serviceTaskId=0 
    this.app.diffShipSoftwareProductBuild(this.diffShipProcess.softwareBuildSelected, this.diffShipProcess.baseBuildSelected,this.diffShipProcess.distroSelected, this.diffShipProcess.softwareBuildSelected.serviceTaskId);
    this.diffShipProcess.distroSelected = [];
    this.diffShipProcess.displayDistro = false;
  }
  onDeleteActionClick(softwareProductBuild: SoftwareProductRelease) {
    this.deleteProcess.softwareBuildSelected = softwareProductBuild;
    this.deleteProcess.distroTypes = softwareProductBuild.distroDownloads;
    this.deleteProcess.distroSelected = undefined;
    if(this.deleteProcess.distroTypes.length===1)
    {
      this.deleteProcess.distroSelected = this.deleteProcess.distroTypes[0];
    }
    this.deleteProcess.displayDistro = true;
  }
  onDeleteButtonClick(event) {
    this.deleteProcess.displayDistro = false;
    this.deleteSoftwareDistros(this.deleteProcess.softwareBuildSelected,this.deleteProcess.distroSelected);


  }

  onTreeNodeSelect(event) {
    if ((this.selectedProduct !== undefined && this.selectedProduct.softwareProduct !== event.node.data.productId)
      || this.selectedProduct === undefined) {
      this.selectedProduct = {
        softwareProduct: event.node.data.product,
        softwareProductFamily: event.node.data.product,
        regularSoftwareProduct: event.node.data.regularSoftwareProduct,
        sourceSoftwareProduct: event.node.data.sourceSoftwareProduct,
      };
      this.onProductChange();
    }

    if (event.node.data.type === 'product') {
      this.versionGlobalFilter.nativeElement.value = '';
      this.versionsTable.filterGlobal('', 'contains');
    }
    if (event.node.data.type === 'build') {
      let tagSplit = event.node.data.tag.split(".");
      tagSplit.pop();
      let tag = tagSplit.join(".");
      this.versionGlobalFilter.nativeElement.value = tag;
      this.versionsTable.filterGlobal(tag, 'contains');
    }
    if (event.node.data.type === 'distro') {
      this.versionGlobalFilter.nativeElement.value = event.node.data.distro;
      this.versionsTable.filterGlobal(event.node.data.distro, 'contains');
    }
  }

  onFilterClear() {
    this.versionGlobalFilter.nativeElement.value = '';
    this.versionsTable.filterGlobal('', 'contains');
  }
  onHoverDistro(event, build: SoftwareProductRelease, distroOverlay: OverlayPanel) {
    this.hoveredDistros = build.distroDownloads;
    distroOverlay.toggle(event);
  }

  onLeftPaneRefresh(){     
    this.selectedCustomer = undefined;
    this.selectedProject = undefined;
    this.selectedProductFamily = undefined;
    this.selectedProduct = undefined;
    
    this.selectedCustomerName = '';
    this.selectedProjectName = '';
    this.selectedProductFamilyName = '';
    this.selectedProductName = '';
    
    this.softwareProductReleases = [];
    this.versions = [];
    this.loadInitData();
    if(this.app.sharedData.appInfo.isElectronMode){
      this.getDownloadedProducts();
    }
    //this.restoreState();
  }
  //#endregion

  //#region Functions
  initSubscribes(){
    this.crSearchSubcription = this.app.sharedData.subscribeCommunication(commType.CrSearch, this.subscriptionSrc);
    this.crSearchSubcription.subscribe((crs: any)=> {
      if(crs === undefined) return;
      this.newServiceTask(this.crSearchSoftwareBuild);
      this.app.createServiceTaskProcess.crList = crs as string;
    });
  }
  
  loadInitData(){
    if(this.app.sharedData.visibility.software.showCustomerFilter){
      this.getAvailbleCustomers();
    }
    else{
      this.getAvailableProjects(this.app.sharedData.userInfo.info.partyNum);
    }  
    this.getAvailableProductFamily();    
    this.getAvailableProducts();
  }

  restoreState() {
    QLogger.LogInfo(this.logSrc, "Restore state");
    if (this.app.sharedData.software.selectedProduct !== null) {
      this.selectedProduct = this.app.sharedData.software.selectedProduct;
      this.selectedProductName = this.app.sharedData.software.selectedProduct.softwareProduct;
      this.getAvailableDistros(this.selectedProduct.softwareProduct);
      this.getAvailableReleaseData(this.selectedProductName);
      this.setReleaseTableColumn(this.selectedProduct.softwareProductFamily);
    }
  }
  
  executeCmdBlocking(cwd:string,command,cmdtimeout){
    var parts = command.split(/\s+/g);
    const { spawnSync } = window.require('child_process');
    const cmd = spawnSync(parts[0], parts.slice(1), { timeout: cmdtimeout, shell: true, cwd: cwd });
    return cmd;
  }
  executeCmdCwd(cwd: string, command) {
    var parts = command.split(/\s+/g);
    const { spawn } = window.require('child_process');
    const cmd = spawn(parts[0], parts.slice(1), { cwd: cwd });

    cmd.stdout.on('data', (data) => {
      this.app.logTerminalMessage(data);
    });

    cmd.stderr.on('data', (data) => {
      this.app.logTerminalMessage(data);
    });
    cmd.stdout.on('end', (data) => {
      this.app.logTerminalMessage(data);
    });
    cmd.on('exit', (code, signal) => {
      this.app.logTerminalMessage('child process exited with ' + 'code ${code} and signal ${signal}');
    });
  }

  shipSoftwareProductBuild(softwareProductBuild: SoftwareProductRelease, distros: SoftwareProductDistro[]) {
    QLogger.LogInfo(this.logSrc, "Ship Software :" + softwareProductBuild.softwareProductBuild);
    let response: Observable<QPMResponse>;
    let distroList:SoftwareProductDistroInfo[] = [];
    let req:DistributionRequest;
    let requestedPlatform:Number;
    if (this.distributionClient === null) {
      return
    }
    distros.forEach(function (distro) 
    {
      let info  = new SoftwareProductDistroInfo();  
      info.distroName =  distro.softwareDistro;
      info.distroId   = Number(distro.softwareDistroId);
      info.uploadComplete = 0;
	  info.softwareDistroAlternateId = distro.softwareDistroAlternateId;
      distroList.push(info);
    });
    if(this.app.sharedData.appInfo.isElectronMode)
    requestedPlatform = 0;
    else
    requestedPlatform = 1;
    if(softwareProductBuild.serviceTaskId === undefined || softwareProductBuild.serviceTaskId === null)
       softwareProductBuild.serviceTaskId=0 
    req = {
      serviceTaskId:softwareProductBuild.serviceTaskId,
      softwareProductBuild : softwareProductBuild.softwareProductBuild,
      softwareDistroList : distroList,
      requestedPlatform : requestedPlatform
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Ship Software Success Request: " + JSON.stringify(req));
    }
    response = this.distributionClient.shipSoftwareProduct(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Ship Software Success Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Ship Software Success:" + softwareProductBuild.softwareProductBuild);
          this.app.queueShipStatusRequest(softwareProductBuild.softwareProductBuild,distroList);
        }
        else{
          QLogger.LogError(this.logSrc, "Ship Software Failed:" + softwareProductBuild.softwareProductBuild);
          QLogger.LogError(this.logSrc, "Ship Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          if(this.app.sharedData.userInfo.info.internal)
          {
            let obj :shipStatusErrorResponse;
            try{
                obj = JSON.parse(data.getErrorDetail());
                let msg = obj.error.errorMessage;
                if(obj.error.exceptionList?.length > 0){
                  msg = msg + ". " + obj.error.exceptionList?.map(x => x.errorMessage).join(". ");
                }
                this.app.displayShipStatus(softwareProductBuild.softwareProductBuild,"Failure",msg);
            }
            catch (e) 
            {
              QLogger.LogError(this.logSrc,"Cannot Parse error message from logs");
              this.app.displayShipStatus(softwareProductBuild.softwareProductBuild,"Failure",data.getError());
            }
          }
          else
          this.app.displayShipStatus(softwareProductBuild.softwareProductBuild,"Failure",null);
        }
      }
    );
    this.shipProcess.distroSelected = [];
    this.shipProcess.displayDistro = false;
    this.app.showMessageBox("Ship Request", "Ship Request for " + softwareProductBuild.softwareProductBuild + " has been placed", "Ok");
  }

  downloadSoftwareDistros(softwareProductBuild: SoftwareProductRelease, distros: SoftwareProductDistro[],downloadLocation:string) {
    QLogger.LogInfo(this.logSrc, "Download Software : " +softwareProductBuild.softwareProductBuild);// + " Distro : " + "AMSS Standard OEM");
    distros.forEach((distro) => {
      this.app.queueDownload(softwareProductBuild, distro,downloadLocation);
    });
    this.downloadProcess.distroSelected = [];
    this.app.logTerminalMessage("Download for " + softwareProductBuild.softwareProductBuild + " Queued " +"at " + this.utils.getCurrentDateTime());
    //this.app.showMessageBox("Download", "Download for " + softwareProductBuild.softwareProductBuild + "Queued", "Ok");
  }

  dryRun(softwareProductBuild: string) {
    QLogger.LogInfo(this.logSrc, "Preview Download Software : " + softwareProductBuild + " Distro : " + "AMSS Standard OEM");
    let response: Observable<QPMResponse>;
    if (this.distributionClient === null) {
      return;
    }
    //this.getAwsCredentials();
    if(true==this.downloadProcess.setCredentialFailed)
    {
      QLogger.LogError(this.logSrc, "Peview Download Software Failed: " +this.downloadProcess.softwareBuildSelected.softwareProductBuild + " Distro : " + "AMSS Standard OEM");
      this.app.showMessageBox("Download", "Download Preview for " + this.downloadProcess.softwareBuildSelected.softwareProductBuild + " failed", "Ok");
      return ; 
    } 
    return;
    let request: DistributionRequest;
    let distroList:SoftwareProductDistroInfo[]=[];
    let distroInfo  = new SoftwareProductDistroInfo() ;
    
    request = {
      softwareProductBuild : softwareProductBuild,
      softwareDistroList : distroList
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Preview Download Software : " + softwareProductBuild + " Distro : " + "AMSS Standard OEM ,"+ " Request : " + JSON.stringify(request));
    }

   response = this.distributionClient.requestDownloadSoftwareImage(request); 
    response.subscribe(
      (data: QPMResponse) => {    
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Preview Download Software : " + softwareProductBuild + " Distro : " + "AMSS Standard OEM ,"+ " Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          //this.showMessageBox("Download", "Download started for "+softwareProductBuild, "Ok");
          this.dryRunBuilds = JSON.parse(data.getData());
          this.displayPreview = true;
          this.dryRunBuilds.imageBuilds.forEach(image => {
            image.dryRunCommand.cmds.forEach(command => {
              this.executeCmdCwd(image.dryRunCommand.cwd, command.cmd + " --profile qpmv3");
            });
          });
        }
        else {
          QLogger.LogError(this.logSrc, "Preview Download Software Failed: " + this.downloadProcess.softwareBuildSelected.softwareProductBuild + " Distro : " + "AMSS Standard OEM");
          QLogger.LogError(this.logSrc, "Preview Download Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          this.app.logTerminalMessage("Download Preview for  " + this.downloadProcess.softwareBuildSelected.softwareProductBuild + " failed.");
        }
      }
    );
  }
  deleteSoftwareDistros(softwareProductBuild: SoftwareProductRelease, deleteDistro: SoftwareDistroLocal)
  {
    let availableDistros: Map<string, SoftwareProductDistro> = new Map<string, SoftwareProductDistro>();
    let deleteDistroList: SoftwareProductDistro[] =[];
    this.productDistros?.forEach((distro) => {
      availableDistros.set(distro.softwareDistro, distro);
    });
    if(availableDistros.has(deleteDistro.distro))
    {
          let newDistro = availableDistros.get(deleteDistro.distro);
          let response: Observable<QPMResponse>;
          let path:string;
          let availableSoftwareProductMetadata: SoftwareProductMetaDataLocal;
          response = this.distributionClient.getDownloadedSoftwareBuildDetail(softwareProductBuild.softwareProductBuild,newDistro.softwareDistroFolderName);
          response.subscribe(
            (data: QPMResponse) => {
              if (this.app.sharedData.appInfo.logResponse) {
                QLogger.LogInfo(this.logSrc, "Get Downloaded Software Details Response: " + JSON.stringify(data));
              }
              if (data.isSuccess())
              {
                QLogger.LogInfo(this.logSrc, "Get Downloaded Software Details Success:" + softwareProductBuild.softwareProductBuild);
                availableSoftwareProductMetadata = JSON.parse(data.getData());
                if(availableSoftwareProductMetadata !== undefined || availableSoftwareProductMetadata !== null)
                {
                  path = availableSoftwareProductMetadata.downloadLocation;
                  if(this.app.sharedData.appInfo.logRequest){
                    QLogger.LogInfo(this.logSrc, "Deleting : " + path);
                  }
                  this.removeFolder(path,softwareProductBuild,newDistro);
                  this.deleteProcess.distroTypes.splice(this.deleteProcess.distroTypes.indexOf(deleteDistro),1);
                }
              }
        });
    }
  }
  removeFolder(path:string,softwareProductBuild:SoftwareProductRelease,distro:SoftwareProductDistro)
  {
    const fs = window.require('fs'); 
    fs.rmdir(path, { recursive: true }, (error) => { 
      if (error) 
        QLogger.LogInfo(this.logSrc,'Removing downloaded distro failed for product ' + softwareProductBuild.softwareProductBuild +' and distro '+ distro.softwareDistro);
      else
      {
        this.distributionClient.deleteProductMetadata(softwareProductBuild.softwareProduct,softwareProductBuild.softwareProductBuild, distro.softwareDistro, distro.softwareDistroFolderName); // TO DO 
        this.app.updateDownloadHistory(null,softwareProductBuild,distro,"Deleted",null,null);
        this.zone.run(() => {
        this.app.showMessageBox("Delete Build", softwareProductBuild.softwareProductBuild +" is deleted from workspace", "Ok");
        });

      }
    }); 
  }
  
  getVersion() {
    let response: Observable<QPMResponse>;
    if (this.catalogClient === undefined || this.catalogClient === null) {
      return
    }
    response = this.catalogClient.getVersion();
    response.subscribe(
      (data: QPMResponse) => {
        //this.version = (data.isSuccess())? data.getData() : data.getError();
        if (data.isSuccess()) {
          this.version = data.getData();
        }
      }
    );
  }

  newServiceTask(softwareProductBuild: SoftwareProductRelease){
    if(!this.app.sharedData.devFeature.allowEOL && this.selectedProduct.active === false && this.selectedProduct.enabledForServiceTaskInInactiveStatus === false){
      this.app.showMessageBox("End of Life", this.selectedProduct.softwareProduct + " is EOL. Unable to create service task with inactive SP.", "Ok");
      return;
    }
    this.app.resetNewServiceTask();
    this.app.createServiceTaskProcess.baseReleasePreSelected = true;
    if(this.selectedProject !== undefined){
      this.app.createServiceTaskProcess.baseProject = this.selectedProject.customerProjectId;
    }
    this.app.newServiceTask(this.selectedProductFamily, softwareProductBuild);
  }

  startFindCrStatusProcess(softwareProductBuild: SoftwareProductRelease, type: string){
    this.crSearchSoftwareBuild = softwareProductBuild;
    this.app.startFindCrStatusProcess(softwareProductBuild.softwareProductBuild, 'ProductBuild', undefined)
  }

  getDistrosShipStatus(softwareProductBuild: SoftwareProductRelease) {
    QLogger.LogInfo(this.logSrc, "Get Distro Ship Status for Software Product "+softwareProductBuild.softwareProductBuild);
    let isShipSuccess = false;
    this.shipProcess.loadingDistros = true;
    this.downloadProcess.loadingDistros = true;
    this.downloadProcess.shipFailed = false;
    let response : Observable<QPMResponse>;
    this.shipProcess.distroTypes = [];
    this.downloadProcess.distroTypes = [];
    this.downloadProcess.distroTypesSelectItems =[];
    let distroList:SoftwareProductDistroInfo[]=[];
    let availableDistroList:SoftwareProductDistro[] =[];
    let request: DistributionRequest;
    response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductBuild.softwareProductBuild);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  " Available Software Product Distros for build "+softwareProductBuild.softwareProductBuild+"- Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null)
          {
            availableDistroList = obj.distros;
            availableDistroList?.forEach(function (distro) 
            {
              let distroInfo  = new SoftwareProductDistroInfo() ;
              distroInfo.distroName =  distro.softwareDistro;
              distroInfo.distroId   = Number(distro.softwareDistroId);
              distroInfo.uploadComplete = 0;
              distroInfo.softwareDistroAlternateId = distro.softwareDistroAlternateId;
              distroList.push(distroInfo);
            });    
            request = {
              softwareProductBuild : softwareProductBuild.softwareProductBuild,
              softwareDistroList : distroList
            };
            if(this.app.sharedData.appInfo.logRequest){
              QLogger.LogInfo(this.logSrc, "Request is "+JSON.stringify(request)); 
            }
            response = this.webClient.getDistroShipStausForImage(request);
            response.subscribe(
              (data:QPMResponse) => { 
                if(this.app.sharedData.appInfo.logResponse){
                  QLogger.LogInfo(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status - Response : " +JSON.stringify(data));
                }
                if(data.isSuccess()){
                  QLogger.LogInfo(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status- Success");
                  let shipStatusResp:imageShipDownloadResponse;
                  let distrosStatus: Map<string, SoftwareImageDistroInfo> = new Map<string, SoftwareImageDistroInfo>();
                  shipStatusResp = JSON.parse(data.getData());
                  if(shipStatusResp !== undefined || shipStatusResp !== null){
                    shipStatusResp.imageDistroList?.forEach((distro) =>
                        {
                          distrosStatus.set(distro.distroName, distro);
                        });
                        this.shipProcess.distroTypes = [];
                        this.downloadProcess.distroTypes = [];
                        availableDistroList.forEach((distro) => {
                        let newDistro: SoftwareProductDistro = new SoftwareProductDistro();
                        newDistro.imageUploadStatus=[];
                        newDistro.softwareDistro = distro.softwareDistro;
                        newDistro.distroType = distro.distroType;
                        newDistro.softwareDistroAlternateId = distro.softwareDistroAlternateId;
                        newDistro.softwareDistroId = distro.softwareDistroId;
                        newDistro.stream = distro.stream;
                        newDistro.softwareDistroFolderName = distro.softwareDistroFolderName;
                        if(distrosStatus.has(distro.softwareDistro)){
                            let shippedDistroInfo = distrosStatus.get(distro.softwareDistro)
                            newDistro.uploadComplete = shippedDistroInfo.uploadComplete;
                            newDistro.shippedBy =  shippedDistroInfo.shipRequester;
                            newDistro.disabled = newDistro.uploadComplete === 1 || newDistro.uploadComplete === 0;
                            if(newDistro.uploadComplete !=4){
                                  if(newDistro.uploadComplete==1) newDistro.status ="In Progress";
                                  else if(newDistro.uploadComplete==0) newDistro.status ="Queued";
                                  else if(newDistro.uploadComplete==2) {
                                      newDistro.status ="Success";
                                      isShipSuccess = true;  
                                  }
                                  else if(newDistro.uploadComplete==5) { 
                                      newDistro.status ="Success*";
                                      isShipSuccess = true;
                                      let imageDistro =distrosStatus.get(distro.softwareDistro)
                                      newDistro.imageUploadStatus = imageDistro.imageUploadStatusesList;
                                  }    
                                  else if(newDistro.uploadComplete==3){
                                    newDistro.status ="Failed";
                                    this.downloadProcess.shipFailed = true;
                                  }
                                  this.downloadProcess.distroTypes.push(newDistro);
                                }
                                else
                                  this.shipProcess.distroTypes.push(newDistro);
                                  
                              }
                              else
                                this.shipProcess.distroTypes.push(newDistro);
                                       
                            });
                            if(this.shipProcess.distroTypes?.length === 1){
                              this.shipProcess.distroSelected.push(this.shipProcess.distroTypes[0]);
                            }
                            if(this.downloadProcess.distroTypes?.length === 1 && this.downloadProcess.distroTypes[0].uploadComplete===2){
                              this.downloadProcess.distroSelected.push(this.downloadProcess.distroTypes[0]);        
                            }
                  }
                }
                else{
                  QLogger.LogError(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status - Failed");
                  QLogger.LogError(this.logSrc,  "Get Software Product "+softwareProductBuild.softwareProductBuild+" Distro Status - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
                }
                this.shipProcess.loadingDistros = false;
                this.downloadProcess.loadingDistros = false;
          });
        }
        else{
          QLogger.LogError(this.logSrc,  "No distros available for  "+softwareProductBuild.softwareProductBuild);
          this.shipProcess.loadingDistros = false;
          this.downloadProcess.loadingDistros = false;
          
        }
          
      }
      else
      {
        QLogger.LogError(this.logSrc,  "Cannot fetch distros available for "+softwareProductBuild.softwareProductBuild);
        this.shipProcess.loadingDistros = false;
        this.downloadProcess.loadingDistros = false;
      }
    });
  }
  getDiffDistrosShipStatus(softwareProductBaseBuild: SoftwareProductRelease,softwareProductTargetBuild: SoftwareProductRelease) {
    QLogger.LogInfo(this.logSrc, "Get Available Software Product Distros for comparison between  "+softwareProductTargetBuild.softwareProductBuild+ " and "+ softwareProductBaseBuild.softwareProductBuild );
    let response : Observable<QPMResponse>;
    this.diffShipProcess.distroTypes = [];    
    let distroList:SoftwareProductDistroInfo[]=[];
    let availableBaseDistroList:SoftwareProductDistro[] =[];
    let availableTargetDistroList:SoftwareProductDistro[] =[];
    let availableDistroList:SoftwareProductDistro[]=[];
    let request: DiffDistributionRequest;
    this.diffShipProcess.loadingDistros = true;
    response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductBaseBuild.softwareProductBuild);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get Software Product Build "+softwareProductBaseBuild.softwareProductBuild+" Distro Status - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null)
          {
            availableBaseDistroList = obj.distros;
            response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductTargetBuild.softwareProductBuild);
            response.subscribe(  (data:QPMResponse) => { 
                if(data.isSuccess()){
                  let obj = JSON.parse(data.getData());
                  if(obj !== undefined || obj !== null)
                  {
                    availableTargetDistroList = obj.distros;
                    availableDistroList = availableTargetDistroList?.filter( a => true === availableBaseDistroList.some( b => a.softwareDistro === b.softwareDistro ));
                    availableDistroList?.forEach(function (distro) 
                    {
                      let distroInfo  = new SoftwareProductDistroInfo() ;
                      distroInfo.distroName =  distro.softwareDistro;
                      distroInfo.distroId   = Number(distro.softwareDistroId);
                      distroInfo.uploadComplete = 0;
					  distroInfo.softwareDistroAlternateId = distro.softwareDistroAlternateId;
                      distroList.push(distroInfo);
                    });    
                    
                    request = {
                      baseSoftwareProductBuild : softwareProductBaseBuild.softwareProductBuild,
                      targetSoftwareProductBuild: softwareProductTargetBuild.softwareProductBuild,
                      softwareDistroList : distroList
                    };
                    if(this.app.sharedData.appInfo.logRequest){
                      QLogger.LogInfo(this.logSrc, "Request is "+JSON.stringify(request)); 
                    }
                    
                    response = this.webClient.getDiffDistrosShipStatus(request);
                    response.subscribe(
                      (data:QPMResponse) => { 
                        if(this.app.sharedData.appInfo.logResponse){
                          QLogger.LogInfo(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status - Response : " +JSON.stringify(data));
                        }
                        if(data.isSuccess()){
                          QLogger.LogInfo(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status- Success");
                          let shipStatusResp:diffShipDownloadResponse;
                          let distrosStatus: Map<string, SoftwareProductDistroInfo> = new Map<string, SoftwareProductDistroInfo>();
                          shipStatusResp = JSON.parse(data.getData());
                          if(shipStatusResp !== undefined || shipStatusResp !== null){
                            let distros: SoftwareProductDistroInfo[] = shipStatusResp.softwareDistroList;
                            this.diffShipProcess.distroTypes = [];
                            distros.forEach((distro) => {
                              distrosStatus.set(distro.distroName, distro);
                            });
                            availableDistroList?.forEach((distro) => {
                              let newDistro: SoftwareProductDistro = new SoftwareProductDistro();
                              newDistro.softwareDistro = distro.softwareDistro;
                              newDistro.distroType = distro.distroType;
                              newDistro.softwareDistroAlternateId = distro.softwareDistroAlternateId;
                              newDistro.softwareDistroId = distro.softwareDistroId;
                              newDistro.stream = distro.stream;
                              newDistro.softwareDistroFolderName = distro.softwareDistroFolderName;
                              if(distrosStatus.has(distro.softwareDistro)){
                                newDistro.uploadComplete = distrosStatus.get(distro.softwareDistro).uploadComplete;
                                newDistro.disabled = newDistro.uploadComplete === 1 ||  newDistro.uploadComplete === 0;
                                if(newDistro.uploadComplete == 4 ||  newDistro.uploadComplete == 3){
                                  this.diffShipProcess.distroTypes.push(newDistro);
                                }
                              }
                              else{
                                this.diffShipProcess.distroTypes.push(newDistro);
                              }
                            });
                
                          }
                        }
                        else{
                          QLogger.LogError(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status - Failed");
                          QLogger.LogError(this.logSrc,  "Get Diff Software Product "+softwareProductTargetBuild.softwareProductBuild+" Distro Status - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
                        }
                        this.diffShipProcess.loadingDistros = false;
                      });
                  }
                }
                else
                this.diffShipProcess.loadingDistros = false;
            });
          }
        }
        else
        this.diffShipProcess.loadingDistros = false;
      });
  }
  diffShipSoftwareProductBuild(softwareProductBaseBuild: SoftwareProductRelease,softwareProductTargetBuild: SoftwareProductRelease, distros: SoftwareProductDistro[]) {
    QLogger.LogInfo(this.logSrc, "Ship difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild);
    let response: Observable<QPMResponse>;
    let distroList:SoftwareProductDistroInfo[] = [];
    let req:DiffDistributionRequest;
    let requestedPlatform : Number;
    if (this.distributionClient === null) {
      return
    }
    distros.forEach(function (distro) 
    {
      let info  = new SoftwareProductDistroInfo();  
      info.distroName =  distro.softwareDistro;
      info.distroId   = Number(distro.softwareDistroId);
      info.uploadComplete = 0;
	  info.softwareDistroAlternateId = distro.softwareDistroAlternateId;
      distroList.push(info);
    });
    if(this.app.sharedData.appInfo.isElectronMode)
    requestedPlatform = 0;
    else
    requestedPlatform = 1;
    req = {
      baseSoftwareProductBuild : softwareProductBaseBuild.softwareProductBuild,
      targetSoftwareProductBuild: softwareProductTargetBuild.softwareProductBuild,
      softwareDistroList : distroList,
      requestedPlatform: requestedPlatform,
      serviceTaskId:0
    };
    if(this.app.sharedData.appInfo.logRequest){
      QLogger.LogInfo(this.logSrc, "Diff Ship Software Success Request: " + JSON.stringify(req));
    }
    response = this.distributionClient.diffShipSoftwareProduct(req);
    response.subscribe(
      (data: QPMResponse) => {
        if (this.app.sharedData.appInfo.logResponse) {
          QLogger.LogInfo(this.logSrc, "Diff Ship Software Success Response: " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Diff Ship Software Success:" + softwareProductTargetBuild.softwareProductBuild);
          this.app.queueDiffShipStatusRequest(softwareProductBaseBuild.softwareProductBuild,softwareProductTargetBuild.softwareProductBuild,distroList);
        }
        else {
          QLogger.LogError(this.logSrc, "Diff Ship Software Failed:" + softwareProductTargetBuild.softwareProductBuild);
          QLogger.LogError(this.logSrc, "Diff Ship Software Failed Error: " + data.getError() + " - " + data.getErrorDetail());
          this.app.showMessageBox("Diff Ship Request","Ship difference between Software : " +softwareProductTargetBuild.softwareProductBuild +" and "+softwareProductBaseBuild.softwareProductBuild +" is failed. " + data.getError(), "Ok");
        }
      }
    );

    this.app.showMessageBox("Diff Ship Request", "Ship Request for differnece between  " + softwareProductTargetBuild.softwareProductBuild + " and "+ softwareProductBaseBuild.softwareProductBuild + " has been placed", "Ok");
  }

  filterProject(){
    this.projectsSelectItems = [];
    if(this.selectedProduct === undefined){
      this.projectsSelectItems = this.utils.getSelectItems(this.projects, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
      return;
    }

    let projectsFiltered: CustomerProject[] = [];
    let spProductProjectsFilter: Set<String> = new Set();
    
    if(this.spProducProjectsMap.has(this.selectedProduct.softwareProduct)){
      this.spProducProjectsMap.get(this.selectedProduct.softwareProduct)
      .forEach((project) => {
        spProductProjectsFilter.add(project);
      });
    }

    if(spProductProjectsFilter.size === 0){
      if(this.spProducProjectsMap.has(this.selectedProduct.regularSoftwareProduct)){
        this.spProducProjectsMap.get(this.selectedProduct.regularSoftwareProduct)
        .forEach((project) => {
          spProductProjectsFilter.add(project);
        });
      }
    }

    let selectedProject: string = this.selectedProjectName;
    this.selectedProject = undefined;
    this.selectedProjectName = '';
    this.projectsMap.forEach((value, key) => {
      if(this.selectedProduct === undefined || spProductProjectsFilter.has(key))
      {
        projectsFiltered.push(value);      
        if(selectedProject === key){
          this.selectedProject = value;
          this.selectedProjectName = key.toString();
        }
      }
    });

    this.projectsSelectItems = this.utils.getSelectItems(projectsFiltered, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
  }
  
  filterProductFamily(){  
    this.productFamilySelectItems = [];
    if(this.selectedProject === undefined && this.selectedProduct === undefined){
      this.productFamilySelectItems = this.utils.getSelectItems(this.productFamily, "softwareProductFamily").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
      return;
    }

    let productsFamilyFiltered: SoftwareProductFamily[] = [];
    let projectsSpfFilter: Set<string> = new Set();
    let spProductFamilyFilter: Set<string> = new Set();
    if(this.selectedProject !== undefined){
      if(this.projectsSpfMap.has(this.selectedProject.customerProject)){
        this.projectsSpfMap.get(this.selectedProject.customerProject)
        .forEach((spf) => {
          projectsSpfFilter.add(spf);
        });
      }
    }

    if(this.selectedProduct !== undefined){      
      if(this.spProductFamilyMap.has(this.selectedProduct.softwareProduct.toLowerCase())){
        this.spProductFamilyMap.get(this.selectedProduct.softwareProduct.toLowerCase())
        .forEach((family) => {
          spProductFamilyFilter.add(family);
        });
      }
    }
    
    let selectedFamily: string = this.selectedProductFamilyName;
    this.selectedProductFamily = undefined;
    this.selectedProductFamilyName = '';
    this.productFamilyMap.forEach((value, key) => {
      if((this.selectedProject === undefined || projectsSpfFilter.has(key)) 
          && (this.selectedProduct === undefined || spProductFamilyFilter.has(key)))
      {
        productsFamilyFiltered.push(value);      
        if(selectedFamily === key){
          this.selectedProductFamily = value;
          this.selectedProductFamilyName = key;
        }
      }
    });
    this.productFamilySelectItems = this.utils.getSelectItems(productsFamilyFiltered, "softwareProductFamily").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
  }

  filterProduct(){
    this.productsSelectItems = [];
    if(this.selectedProject === undefined && this.selectedProductFamily === undefined){
      this.productsSelectItems = this.utils.getSelectItems(this.products, "softwareProduct").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
      return;
    }

    let productsFiltered: SoftwareProduct[] = [];
    let projectsProductsFilter: Set<string> = new Set();
    let productFamilySpFilter: Set<string> = new Set();
    if(this.selectedProject !== undefined){
      if(this.projectsProductsMap.has(this.selectedProject.customerProject)){
        this.projectsProductsMap.get(this.selectedProject.customerProject).forEach((product)=>{
          projectsProductsFilter.add(product.toLowerCase());
        });
      }
    }
    if(this.selectedProductFamily !== undefined){
      if(this.productFamilySpMap.has(this.selectedProductFamily.softwareProductFamily)){
        this.productFamilySpMap.get(this.selectedProductFamily.softwareProductFamily).forEach((family) => {
          productFamilySpFilter.add(family.toLowerCase());
        });
      }
    }

    this.productsMap.forEach((value, key) => {
      if((this.selectedProject === undefined || projectsProductsFilter.has(key) || projectsProductsFilter.has(value.regularSoftwareProduct.toLowerCase())) 
          && (this.selectedProductFamily === undefined || productFamilySpFilter.has(key))){
            productsFiltered.push(value);
      }
    });

    /*this.productsRegularMap.forEach((value, key) => {
      if((this.selectedProject === undefined || projectsProductsFilter.has(key)) 
          && (this.selectedProductFamily === undefined || productFamilySpFilter.has(key))){
            productsFiltered.push(value);
      }
    });*/
    this.productsSelectItems = this.utils.getSelectItems(productsFiltered, "softwareProduct").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
  }

  getAvailbleCustomers(){
    if(!this.app.sharedData.visibility.software.showCustomerFilter){
      return;
    }
    QLogger.LogInfo(this.logSrc, "Get Available Customers");
    this.customers = [];
    this.selectedCustomer = undefined;
    this.loadingCustomers = true;
    let response: Observable<QPMResponse>;
    response = this.softwareCatalogClient.getCustomersAll();
    response.subscribe(
    (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
        QLogger.LogInfo(this.logSrc, "Get Available Customers - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()) {
            QLogger.LogInfo(this.logSrc, "Get Available Customers - Success");
            let obj = JSON.parse(data.getData());
            if (obj !== undefined || obj !== null) {
                this.customers =  obj as Customer[];
                this.customersSelectItems = this.utils.getSelectItems(this.customers, "name");
            }
        }
        else{
            QLogger.LogError(this.logSrc, "Get Available Customers - Failed");
            QLogger.LogError(this.logSrc, "Get Available Customers - Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingCustomers = false;
    });
  }

  getAvailableProducts() {
    QLogger.LogInfo(this.logSrc, "Get Available Software Products");
    this.loadingProduct = true;
    let partyId: number = undefined;
    if(this.selectedCustomer === undefined){
      partyId = this.app.sharedData.userInfo.info.partyId;
    }
    else{      
      partyId = this.selectedCustomer.partyId;
    }
    let response: Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareProductsByParty(partyId);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          //QLogger.LogInfo(this.logSrc, "Get Available Software Products - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Software Products - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.products = obj.softwareProducts;
            this.productsSelectItems = this.utils.getSelectItems(this.products, "softwareProduct");
            this.productsMap = new Map<string, SoftwareProduct>();
            this.productsRegularMap =  new Map<string, SoftwareProduct>();
            this.products.forEach((product) => {
              this.productsMap.set((product.softwareProduct).toLowerCase(), product);
              this.productsRegularMap.set((product.regularSoftwareProduct).toLowerCase(), product);
            });
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Available Software Products - Failed");
          QLogger.LogError(this.logSrc, "Get Available Software Products - Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingProduct = false;
      });
  }

  getAvailableProductFamily() {
    QLogger.LogInfo(this.logSrc, "Get Available Software Product Family");
    this.loadingProductFamily = true;
    let partyId: number = undefined;
    if(this.selectedCustomer === undefined){
      partyId = this.app.sharedData.userInfo.info.partyId;
    }
    else{      
      partyId = this.selectedCustomer.partyId;
    }
    let response: Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareProductFamilyByParty(partyId);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          //QLogger.LogInfo(this.logSrc, "Get Available Software Product Family - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Software Product Family - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.productFamily = obj.spfMappings;
            this.productFamilySelectItems = this.utils.getSelectItems(this.productFamily, "softwareProductFamily").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
            this.productFamilyMap = new Map<string, SoftwareProductFamily>();
            this.productFamilySpMap = new Map<string, string[]>();
            this.spProductFamilyMap = new Map<string, string[]>();
            this.productFamily.forEach((family) => {
              this.productFamilyMap.set(family.softwareProductFamily, family);
              this.productFamilySpMap.set(family.softwareProductFamily, family.softwareProducts);
              family.softwareProducts.forEach((softwareProduct)=>{
                if(this.spProductFamilyMap.has(softwareProduct.toLowerCase())){
                  this.spProductFamilyMap.get(softwareProduct.toLowerCase()).push(family.softwareProductFamily);
                }
                else{
                  this.spProductFamilyMap.set(softwareProduct.toLowerCase(),[]);
                  this.spProductFamilyMap.get(softwareProduct.toLowerCase()).push(family.softwareProductFamily);
                }
              });
            });
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Available Software Product Family - Failed");
          QLogger.LogError(this.logSrc, "Get Available Software Product Family - Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingProductFamily = false;
      });
  }

  getAvailableDistros(softwareProduct: string) {
    QLogger.LogInfo(this.logSrc, "Get Available Software Product "+softwareProduct+" Distros");
    this.productDistros = [];
    let response : Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareProductDistros(softwareProduct);
    response.subscribe(
      (data:QPMResponse) => { 
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Response : " +JSON.stringify(data));
        }
        if(data.isSuccess()){
          QLogger.LogInfo(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Success");
          
          let obj = JSON.parse(data.getData());
          if(obj !== undefined || obj !== null){
            this.productDistros = obj.distros;
          }
        }
        else{
          QLogger.LogError(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Failed");
          QLogger.LogError(this.logSrc,  "Get Available Software Product "+softwareProduct+" Distro - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
      });
  }

  getAvailableProjects(partyNum: number) {
    QLogger.LogInfo(this.logSrc, "Get Available Software Projects, PartyNum: " + partyNum);
    this.projects = [];
    this.projectsSelectItems = []
    this.projectsMap = new Map<String, CustomerProject>(); 
    this.projectsProductsMap = new Map<string, String[]>();
    this.spProducProjectsMap = new Map<string, string[]>();
    this.loadingProject = true;
    let response: Observable<QPMResponse>;
    response = this.softwareCatalogClient.getCustomerProjectsByParty(partyNum);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          //QLogger.LogInfo(this.logSrc, "Get Available Software Projects, PartyNum: " + partyNum + " - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Software Projects, PartyNum: " + partyNum + " - Success");
          
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.projects = obj.customerProjects;
            this.projectsSelectItems = this.utils.getSelectItems(this.projects, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
            this.projectsMap = new Map<String, CustomerProject>(); 
            this.projectsProductsMap = new Map<string, String[]>();
            this.spProducProjectsMap = new Map<string, string[]>();
            this.projects.forEach((project) => {
              this.projectsMap.set(project.customerProject, project);

              if(this.projectsProductsMap.has(project.customerProject)){
                this.projectsProductsMap.get(project.customerProject).push(project.softwareProduct);
              }
              else{
                this.projectsProductsMap.set(project.customerProject,[]);
                this.projectsProductsMap.get(project.customerProject).push(project.softwareProduct);
              }

              if(this.spProducProjectsMap.has(project.softwareProduct)){
                this.spProducProjectsMap.get(project.softwareProduct).push(project.customerProject);
              }
              else{
                this.spProducProjectsMap.set(project.softwareProduct,[]);
                this.spProducProjectsMap.get(project.softwareProduct).push(project.customerProject);
              }

              if(project.softwareProductFamily !== null){
                if(this.projectsSpfMap.has(project.customerProject)){
                  this.projectsSpfMap.get(project.customerProject).push(project.softwareProductFamily);
                }
                else{
                  this.projectsSpfMap.set(project.customerProject,[]);
                  this.projectsSpfMap.get(project.customerProject).push(project.softwareProductFamily);
                }

                if(this.spfProjectsMap.has(project.softwareProductFamily)){
                  this.spfProjectsMap.get(project.softwareProductFamily).push(project.customerProject);
                }
                else{
                  this.spfProjectsMap.set(project.softwareProductFamily,[]);
                  this.spfProjectsMap.get(project.softwareProductFamily).push(project.customerProject);
                }
              }
            });

            let projectsFiltered: CustomerProject[] = [];
            if(this.selectedProduct !== undefined && this.selectedProduct !== null){
              this.projectsSelectItems = [];
              
              let projects: string[];
              if(this.spProducProjectsMap.has(this.selectedProduct.softwareProduct)){
                projects = this.spProducProjectsMap.get(this.selectedProduct.softwareProduct);
              }
              else if(this.spProducProjectsMap.has(this.selectedProduct.regularSoftwareProduct)){
                projects = this.spProducProjectsMap.get(this.selectedProduct.regularSoftwareProduct);
              }
              if(projects != undefined){
                let selectedProject: string = this.selectedProjectName;
                this.selectedProject = undefined;
                this.selectedProjectName = '';
                projects.forEach((project) => {
                  if(this.projectsMap.has(project)){
                    projectsFiltered.push(this.projectsMap.get(project));
                  }
                  if(selectedProject === project){
                    this.selectedProject = this.projectsMap.get(project);
                    this.selectedProjectName = project;
                  }
                });
                this.projectsSelectItems = this.utils.getSelectItems(projectsFiltered, "customerProject").sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
              }
            }
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Available Software Projects, PartyNum: " + partyNum + " - Failed");
          QLogger.LogError(this.logSrc, "Get Available Software Projects, PartyNum: " + partyNum + " - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingProject = false;
      });
  }

  getDownloadedProducts() {
    QLogger.LogInfo(this.logSrc, "Get Downloaded Software Products");
    //this.downloadedProducts = SoftwareData.downloadedSoftwares;
    //this.downloadedProductsTreeNodes = SoftwareData.getDownloadedProducts();
    this.downloadedBuilds = SoftwareData.downloadedBuilds as Map<string, SoftwareProductBuildLocal>;
    let response: Observable<QPMResponse>;
    if (this.distributionClient === null) return;
    response = this.distributionClient.getDownloadedSoftware();
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Downloaded Software data Response : " + JSON.stringify(data));
        }
        if (data.isSuccess()) {
          this.downloadedProductsTreeNodes = [];
          this.downloadedBuilds = new Map<string, SoftwareProductBuildLocal>();
          /*if (!data.isSuccess) {
            this.errorMessage = "No Downloaded Software Product found"
          }*/
          let obj = null;
          try 
          {
            obj = JSON.parse(data.getData())
          } 
          catch (e) {
            QLogger.LogError(this.logSrc, "Get Downloaded Software data error : " + data.getData());
          }  
          
          if (obj !== undefined && obj !== null) {
            this.downloadedProducts = obj.products as SoftwareProductLocal[];
            let treeNodes: TreeNode[];
            treeNodes = [];
            this.downloadedProducts.forEach(product => {
              let productNode: TreeNode;
              productNode = {
                label: product.product,
                key: product.product,
                data: product,
                expandedIcon: "pi pi-folder-open",
                collapsedIcon: "pi pi-folder",
                children: []
              };
              productNode.data.type = "product";
              product.productbuilds.forEach(build => {
                build.product = product.product;
                let buildNode: TreeNode = {
                  label: build.tag,
                  key: build.product + build.tag,
                  data: build,
                  expandedIcon: "pi pi-folder-open",
                  collapsedIcon: "pi pi-folder",
                  children: []
                };
                build.distros.forEach(distro => {
                  distro.product = product.product;
                  distro.productBuildId = build.productBuildId;
                  distro.tag = build.tag;
                  let distroNode: TreeNode = {
                    label: distro.distro,
                    key: build.product + build.tag + distro.distro,
                    data: distro,
                    icon: "pi pi-file"
                  };
                  distroNode.data.type = "distro";
                  buildNode.children.push(distroNode);
                });
                if(this.app.sharedData.appInfo.isElectronMode){
                  this.downloadedBuilds.set(build.tag, build);
                }
                buildNode.data.type = "build";
                productNode.children.push(buildNode);
              });
              treeNodes.push(productNode);
            })
            this.downloadedProductsTreeNodes = treeNodes;
          }
        }
      });
  }

  getAvailableReleaseData(softwareProduct: string) {
    if(softwareProduct === '' || softwareProduct === undefined) return;
    QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds");
    this.loadingReleases = true;
    //this.versions = SoftwareData.getAvailableReleaseData();
    let partyId: number = undefined;
    if(this.selectedCustomer === undefined){
      partyId = this.app.sharedData.userInfo.info.partyId;
    }
    else{      
      partyId = this.selectedCustomer.partyId;
    }
    let response: Observable<QPMResponse>;
    response = this.softwareCatalogClient.getSoftwareProductBuildsByParty(softwareProduct, partyId);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          //QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Response : " +JSON.stringify(data));
        }
        this.softwareProductReleases = [];
        this.versions = [];
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Success");
          let obj = JSON.parse(data.getData());
          if (obj !== undefined || obj !== null) {
            this.softwareProductReleases = obj.softwareProductReleases;
            let groupedData = new Map();
            let data = this.softwareProductReleases;
            data.forEach((build) => {
              build.isBuild = true;
              build.distroDownloads = [];
              if(this.app.sharedData.appInfo.isElectronMode && this.downloadedBuilds !== undefined){
                if (this.downloadedBuilds.has(build.tag)) {
                  build.isDownloaded = true;
                  build.distroDownloads = this.downloadedBuilds.get(build.tag).distros;
                  build.distroDownloadsNames = "";
                  build.distroDownloadsDisplay = "";
                  let distroNames = [];
                  build.distroDownloads.forEach((distro) => {
                    distroNames.push(distro.distro);
                  });
                  build.distroDownloadsNames = distroNames.join(',');
                  build.distroDownloadsDisplay = build.distroDownloadsNames.length > 16 ? build.distroDownloadsNames.substr(0, 16) + "..." : build.distroDownloadsNames;
                }
              }
              let tagSplit = build.tag.split(".");
              tagSplit.pop();
              let tag = tagSplit.join(".");
              this.versions.push(build);
            });
          }
        }
        else{
          QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Failed");
          QLogger.LogError(this.logSrc, "Get Available Software Product " + softwareProduct + " Builds - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
        }
        this.loadingReleases = false;
      });
  }

  updateAvailableDeltaBuilds(softwareProductBuild: SoftwareProductRelease)
  {
    QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild.softwareProductBuild );
    let response: Observable<QPMResponse>;
    this.diffDownloadProcess.availableBaseBuilds=[];
    let availableBuilds = new BehaviorSubject<SoftwareProductRelease[]>(this.diffDownloadProcess.availableBaseBuilds);
    this.diffDownloadProcess.loadingBaseBuilds = true;
    this.diffDownloadProcess.shipFailed = false;
    let entitledDistroList:SoftwareProductDistro[] =[];
    let  baseBuildShipDate     = new Date(softwareProductBuild.shipDate);
    response = this.webClient.getSoftwareProductDiffBuilds(softwareProductBuild.softwareProductBuild);
    response.subscribe(
      (data: QPMResponse) => {
        if(this.app.sharedData.appInfo.logResponse){
          QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild.softwareProductBuild + " - Response : " +JSON.stringify(data));
        }
        if (data.isSuccess()) {
          QLogger.LogInfo(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild.softwareProductBuild + " - Success");
          let availabeDiffBuildResponse:AvaliableDiffBuildsResponse;
          availabeDiffBuildResponse = JSON.parse(data.getData());
          response = this.softwareCatalogClient.getSoftwareProductBuildDistros(softwareProductBuild.softwareProductBuild);
          response.subscribe(
            (data:QPMResponse) => { 
              if(this.app.sharedData.appInfo.logResponse){
                QLogger.LogInfo(this.logSrc,  "Get Software Product Build "+softwareProductBuild.softwareProductBuild +" Distro Status - Response : " +JSON.stringify(data));
              }
              if(data.isSuccess()){
                let obj = JSON.parse(data.getData());
                if(obj !== undefined || obj !== null)
                {
                  entitledDistroList = obj.distros;
                  if (availabeDiffBuildResponse !== undefined || availabeDiffBuildResponse !== null) {
                    this.availableDiffBuilds = availabeDiffBuildResponse.diffTargetBuildAndDistroList
                    this.availableDiffBuilds?.forEach((availableDiffBuild)=> {
                      let availableDistros :SoftwareProductDistro[];
                      availableDistros =this.app.getAvailableDistrosForDownload(availableDiffBuild.targetSoftwareProductBuild,this.availableDiffBuilds,entitledDistroList);
                      this.softwareProductReleases?.forEach((availableBuild) => {
                             if(availableBuild.softwareProductBuild == availableDiffBuild.targetSoftwareProductBuild)
                             {
                              let  targetBuildShipDate     = new Date(availableBuild.shipDate);
                              if(baseBuildShipDate.getTime() > targetBuildShipDate.getTime()) 
                               {
                                  availableDistros.forEach((availableDistro)=>
                                  {
                                    let downloadableDiffBuild: SoftwareProductRelease = new SoftwareProductRelease();
                                    downloadableDiffBuild.softwareProduct = availableBuild.softwareProduct;
                                    downloadableDiffBuild.softwareProductBuild = availableBuild.softwareProductBuild;
                                    downloadableDiffBuild.shipDate = availableBuild.shipDate;
                                    downloadableDiffBuild.tag = availableBuild.tag;
                                    downloadableDiffBuild.branch = availableBuild.branch;  
                                    downloadableDiffBuild.distroAvailable = availableDistro;
                                    if(availableDistro.status=="Failed")
                                      this.diffDownloadProcess.shipFailed = true;
                                    this.diffDownloadProcess.availableBaseBuilds = [...this.diffDownloadProcess.availableBaseBuilds,downloadableDiffBuild];
                                  });
                                }
                             }
                        });
                    });
        
                    if(this.diffDownloadProcess.availableBaseBuilds.length === 1 && this.diffDownloadProcess.availableBaseBuilds[0].distroAvailable.uploadComplete===2){
                      this.diffDownloadProcess.baseBuildSelected = this.diffDownloadProcess.availableBaseBuilds[0];
                    }
                  }
                  availableBuilds.next( this.diffDownloadProcess.availableBaseBuilds);
                  this.diffDownloadProcess.loadingBaseBuilds = false;
                }
              }
            });
      }
      else{
        QLogger.LogError(this.logSrc, "Get Available Diff Builds for Software Product " + softwareProductBuild + "  - Failed Error : " + data.getError() + " - " + data.getErrorDetail());
      }

    });
  }
  //#endregion
  //#region Helpers
  //#endregion
}