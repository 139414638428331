import isElectron from 'is-electron';
import { LimeService } from './LimeService';
import { RestService } from './RestService';
import { DataServiceFactory } from './DataServiceFactory';
import { Injectable } from '@angular/core';

export enum ApiType{
    Client = 0,
    WebClient,
    CatalogClient,
    SoftwareCatalogClient,
    DistributionClient,
    LoggingClient,
    TelematicsClient
};

@Injectable()
export class DataServiceProducer{
    static service : DataServiceFactory;
    constructor(private limeService : LimeService, private restService : RestService){

    }
    public getServiceInstance(type : ApiType){
        if(DataServiceProducer.service === undefined){
            if(isElectron()){
                DataServiceProducer.service = this.limeService;
            }
            else{
                DataServiceProducer.service = this.restService;
            }
        }
        switch(type){
            case ApiType.Client:
                return DataServiceProducer.service.createClientService();
                break;
            case ApiType.WebClient:
                return DataServiceProducer.service.createWebClientService();
                break;
            case ApiType.CatalogClient:
                return DataServiceProducer.service.createCatalogClientService();
                break;
            case ApiType.SoftwareCatalogClient:
                return DataServiceProducer.service.createSoftwareCatalogClientService();
                break;
            case ApiType.DistributionClient:
                return DataServiceProducer.service.createDistributionClientService();
                break;
            case ApiType.LoggingClient:
                return DataServiceProducer.service.createLoggingService();
                break;
            case ApiType.TelematicsClient:
                return DataServiceProducer.service.createTelematicsClientService();
                break;
        }
    }
}